import {withStyles} from '@material-ui/core';
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import styles from './styles';

const StopIdLabel = (props) => {

  const {stop, classes} = props;

  if (!stop) return <Fragment/>

  return (
    <NavLink
      className={classes.root}
      onClick={e => e.stopPropagation()}
      to={`/stop-detail/${stop.tourStopId}?isPreview=false`}
    >
      {stop.tourStopId || ''}
    </NavLink>
  );
}

StopIdLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  stop: PropTypes.shape({tourStopId: PropTypes.number.isRequired}).isRequired,
}

export default withStyles(styles)(StopIdLabel);
