export default theme => ({
  root: {},
  toursWithoutStopsRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  stopTable: {
    border: `1px solid ${theme.palette.border}`,
  },
  lastTableCell: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
});

