import React from 'react'
import {Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Draggable} from 'react-beautiful-dnd'
import {DndHelperElementTypes, DndHelperService} from '../../../../../services/dndHelper/dndHelperService';
import {AcUnitRounded as FreezeIcon, DragIndicatorOutlined as DragIcon} from '@material-ui/icons'
import {roundCapacityValues} from '../../../../../services/util/helperFuncs';

function HubBasketElement(props) {

  const {classes, element, index, uniqueBasketId, t, className} = props;

  const rootClassName = classNames((element.type === DndHelperElementTypes.Tour ? classes.tour : classes.stop), className);
  let uniqueElementId = DndHelperService.getUniqueTourId(uniqueBasketId, element.id);
  if (element.type === DndHelperElementTypes.Stop) {
    uniqueElementId = DndHelperService.getUniqueStopId(uniqueElementId, element.elementData.id);
  }

  const capacities = element.capacities;


  return (
    <Draggable
      draggableId={uniqueElementId}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <div
            className={classNames(snapshot.isDragging ? classes.dragging : rootClassName, element.type === DndHelperElementTypes.Tour)}
            style={element.type === DndHelperElementTypes.Tour ? {backgroundColor: element.color, border: `2px solid ${element.color}`} : {}}
          >
            <div
              className={classes.elementSectionSmall}
              {...provided.dragHandleProps}
            >
              <DragIcon/>
            </div>
            <div className={classes.elementSection}>
              <Typography>
                <strong>{element.type === DndHelperElementTypes.Tour ? t('previewTourID') : t('previewStopID')}: {element.elementData.id}</strong>
              </Typography>
              <Typography>
                <strong>{t('stopCapacity')}</strong>: {`${roundCapacityValues(capacities.boxAmount)} [${t('boxes')}] | ${roundCapacityValues(capacities.weight)} [kg] | ${roundCapacityValues(capacities.volume)}[l]`}
              </Typography>
            </div>
            <div
              className={classes.elementSectionSmall}
              style={!element.type === DndHelperElementTypes.Tour || !element.frozen ? {visibility: 'hidden'} : {}}
            >
              <FreezeIcon/>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}


HubBasketElement.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  uniqueBasketId: PropTypes.string.isRequired
};

export default compose(withStyles(styles), withTranslation())(HubBasketElement);
