export default theme => ({
  root: {
    padding: theme.spacing(0.5)
  },
  rootDisabled: {
    backgroundColor: theme.palette.common.muted,
    padding: theme.spacing(0.5)
  },
  tourHeader: {
    display: 'flex',
    paddingLeft: '0px',
    paddingRight: '5px'
  },
  headerSection: {
    flexBasis: '16%',
    flexShrink: 0,
    overflowX: 'hidden'
  },
  headerSectionSmall: {
    flexBasis: '15%',
    flexShrink: 0,
    overflowX: 'hidden'
  },
  stopContainer: {
    width: '100%'
  },
  stop: {
    borderRadius: '5px',
    marginTop: theme.spacing(0.5),
  },
  iconButton: {
    padding: '0'
  }
});

