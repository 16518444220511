import React, {Fragment, useCallback, useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {CustomerDetail, CustomerFilter, CustomerTable, LoadingWrapper} from '../../../components'
import SideNavLayout from '../../../layouts/SideNavLayout';
import useDebounce from '../../../hooks/useDebounce';
import customersService from '../../../services/backend/customersService';
import useShipperOptions from '../../../hooks/useShipperOptions';
import searchCustomerService from '../../../services/searchServices/searchCustomerService';
import {cloneDeep} from 'lodash'
import {getDayNumberByDayOfWeek} from '../../../services/enums/dayOfWeekHelper';
import {displayModes} from '../../../services/enums/displayModes';
import useCarriersWithHubs from '../../../hooks/useCarriersWithHubs';

function CustomerCatalog(props) {

  const {classes, t} = props;
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [filter, setFilter] = useState({
    carrierName: '',
    shipperName: '',
    microHubName: '',
    dayOfWeek: '',
  });
  const [backendLoading, setBackendLoading] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const debouncedSearchInput = useDebounce(searchInput, 250);
  const [filteredCustomers, setFilteredCustomers] = useState([]);

  const {shipperOptions} = useShipperOptions();
  const {carriersWithHubs} = useCarriersWithHubs();

  const loadCustomers = useCallback(async () => {
    setBackendLoading(true);
    try {
      const response = await customersService.getCustomersByFilter(filter.shipperName, filter.carrierName, filter.microHubName, getDayNumberByDayOfWeek(filter.dayOfWeek));
      setCustomers(response);
      setSelectedCustomer(prevState => {
        if (!prevState) return prevState;
        const index = response.findIndex(customer => prevState && customer.id === prevState.id);
        return index < 0 ? null : response[index];
      })
      setBackendLoading(false);
    } catch (e) {
      alert(`${t('errorLoadingCustomers')}, ${e}`);
      setBackendLoading(false);
    }
  }, [filter, t]);

  useEffect(() => {
    loadCustomers()
  }, [loadCustomers]);

  useEffect(
    () => {
      const result = searchCustomerService.searchCustomers(customers, debouncedSearchInput);
      setFilteredCustomers(result);
    },
    [debouncedSearchInput, customers]
  );

  const deleteCustomerById = async (customerId) => {
    try {
      await customersService.deleteCustomerById(customerId);
      const index = customers.findIndex(c => c.id === customerId);
      if (index >= 0) {
        const newCustomers = cloneDeep(customers);
        newCustomers.splice(index, 1);
        setCustomers(newCustomers);
      }
      if (selectedCustomer.id === customerId) setSelectedCustomer(null);
    } catch (e) {
      alert(`${t('errorDeletingCustomer')}, ${e}`);
      loadCustomers();
    }
  };

  const updateCustomer = async (customer) => {
    try {
      const response = await customersService.updateCustomer(customer.id, customer);
      const newCustomers = JSON.parse(JSON.stringify(customers));
      const index = newCustomers.findIndex(c => c.id === customer.id)
      if (index >= 0) {
        newCustomers[index] = response;
        setCustomers(newCustomers);
        setSelectedCustomer(response);
      } else {
        loadCustomers();
      }
    } catch (e) {
      alert(`${t('errorUpdatingCustomer')}, ${e}`);
      loadCustomers();
    }
  }

  return (
    <SideNavLayout title={t('customerCatalog')}>
      <div className={classes.root}>
        <CustomerFilter
          carriersWithMicroHubs={carriersWithHubs}
          filter={filter}
          searchInput={searchInput}
          setFilter={setFilter}
          setSearchInput={setSearchInput}
          shipperOptions={shipperOptions}
        />
        <LoadingWrapper loading={backendLoading}>
          <Fragment>
            <CustomerTable
              className={classes.content}
              customers={filteredCustomers}
              onDeleteCustomer={deleteCustomerById}
              onSelectCustomer={setSelectedCustomer}
              searchInput={searchInput}
              selectedCustomer={selectedCustomer}
              setSearchInpt={setSearchInput}
              displayMode={displayModes.admin}
            />
            {selectedCustomer &&
            <CustomerDetail
              className={classes.content}
              closeDetailView={() => setSelectedCustomer(null)}
              customer={selectedCustomer}
              displayMode={displayModes.admin}
              updateCustomer={updateCustomer}
            />
            }
          </Fragment>
        </LoadingWrapper>
      </div>
    </SideNavLayout>
  );
}


CustomerCatalog.propTypes = {
  classes: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(CustomerCatalog);
