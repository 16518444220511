import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react'
import {Button, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {
  EmptiesTable,
  LoadingWrapper,
  PortletContent,
  PortletHeader,
  ProductTable,
  StopIdLabel,
  StoragePlaces,
  TourIdLabel,
  TrendNumber
} from '../../../../components';
import {
  Close as CloseIcon,
  Edit as EditIcon,
  History as HistoryIcon,
  TrackChanges as TrackAndTrace,
  Warning as ProblemNotificationIcon
} from '@material-ui/icons';
import {AuthService} from '../../../../services/auth/authService';
import {userPermissions} from '../../../../services/auth/userPermissions';
import {getStopStatusString} from '../../../../services/enums/StopStatus';
import {DateService} from '../../../../services/util/DateService';
import {getProductTypeString, ProductType} from '../../../../services/enums/ProductType';
import 'moment-duration-format';
import {userRoles} from '../../../../services/auth/userRoles';
import {displayAddress, displayShipperAndShipperShortName, roundCapacityValues} from '../../../../services/util/helperFuncs';
import {PreviewToursService} from '../../../../services/backend/previewToursService';
import {TourService} from '../../../../services/backend/tourService';
import StopHistoryDialog from '../StopHistoryDialog';
import TrackAndTraceInfoDialog from '../TrackAndTraceInfoDialog';
import {ProblemNotificationDialogContext} from '../../../../context';
import {ProblemNotificationType} from '../../../../services/enums/ProblemNotificationType';
import {getAgeVerificationStatusString} from "../../../../services/enums/AgeVerificationStatus";

function StopDetailShow(props) {

  const {classes, className, stop, edit, t, readOnly, closeDetailView, trackingStop, trackingStopLoading, shallowTour} = props;

  const rootClassName = classNames(classes.root, className);
  const [matchingPreviewStop, setMatchingPreviewStop] = useState(null);
  const [trackAndTraceInfoDialogOpen, setTrackAndTraceInfoDialogOpen] = useState(false);
  const [stopHistoryDialogOpen, setStopHistoryDialogOpen] = useState(false);
  const [stopHistory, setStopHistory] = useState([]);

  const lastTrackingStep = !trackingStopLoading && trackingStop ? trackingStop.trackingSteps.sort((a, b) => new Date(b.eventTimeStamp) - new Date(a.eventTimeStamp))[0] : null;

  const {open: openProblemNotificationDialog} = useContext(ProblemNotificationDialogContext);

  const loadMatchingPreviewStop = async (previewStopIdentifier) => {
    try {
      const response = await PreviewToursService.getPreviewStopByPreviewStopIdentifier(previewStopIdentifier);
      setMatchingPreviewStop(response)
    } catch (e) {
      alert(t('errorLoadingMatchingPreviewStop'));
    }
  };

  const loadStopChangeHistory = useCallback(() => {
    TourService.getStopHistoryById(stop.tourStopId)
      .then(response => response.json())
      .then(response => setStopHistory(response), () => alert(t('errorLoadingStopHistory')))
  }, [stop, t]);

  const handleStopHistoryClick = () => {
    setStopHistoryDialogOpen(true);
  };

  useEffect(() => {
    loadStopChangeHistory();
  }, [loadStopChangeHistory]);


  return (
    <Fragment>
      <PortletHeader className={rootClassName}>
        <Fragment>
          <div><strong>{t('tourStopId')}: </strong><StopIdLabel stop={stop}/></div>
          <div>
            {AuthService.getUserRole() !== userRoles.shipper &&
            <Button
              className={classes.warnButton}
              onClick={() => openProblemNotificationDialog(ProblemNotificationType.FinalStop, stop.tourStopId.toString(), stop.assignedCarrierName)}
              title={t('sendProblemNotification')}
              variant="contained"
            >
              <ProblemNotificationIcon/>
            </Button>
            }
            <Button
              className={classes.button}
              color="secondary"
              disabled={!stopHistory || stopHistory.length === 0}
              onClick={() => handleStopHistoryClick()}
              title={t('stopHistory')}
              variant="contained"
            >
              <HistoryIcon/>
            </Button>
            <Button
              className={classes.button}
              color="secondary"
              disabled={!stop.trackingLink}
              onClick={() => setTrackAndTraceInfoDialogOpen(true)}
              title={t('trackAndTraceHistory')}
              variant="contained"
            >
              <TrackAndTrace/>
            </Button>
            {closeDetailView &&
            <Button
              className={classes.button}
              color="default"
              onClick={closeDetailView}
              title={t('close')}
              variant="contained"
            >
              <CloseIcon/>
            </Button>
            }
            {((AuthService.getUserPermission() !== userPermissions.user) && !readOnly) &&
            <Button
              className={classes.button}
              color="primary"
              disabled={!stop.editable}
              onClick={edit}
              title={stop.editable ? t('stopEdit') : t('stopEditNotAllowed')}
              variant="contained"
            >
              <EditIcon/>
            </Button>
            }
          </div>
        </Fragment>
      </PortletHeader>
      <PortletContent noPadding>
        <div className={classes.wrapper}>
          <div className={classes.wrapperChild}>
            <div className={classes.stopDetail}>
              <strong>{t('generalInformation')}:</strong>
              <div className={classes.stopDetail}><strong>{t('shipper')}</strong>: {displayShipperAndShipperShortName(stop)}</div>
              <div className={classes.stopDetail}><strong>{t('carrier')}</strong>: {stop.assignedCarrierName}</div>
              <div className={classes.stopDetail}><strong>{t('microHub')}</strong>: {stop.assignedMicroHubName}</div>
              <div className={classes.stopDetail}><strong>{t('tourId')}</strong>: <TourIdLabel tour={shallowTour ? shallowTour : {tourId: stop.tourId}}/></div>
              <div className={classes.stopDetail}><strong>{t('previewOrderLong')}</strong>: {stop.previewOrder}</div>
              <div className={classes.stopDetail}><strong>{t('storagePlace')}</strong>:&nbsp;
                <StoragePlaces stop={stop}/>
              </div>
            </div>
            <br/>
            <div className={classes.stopDetail}>
              <strong>{t('customerData')}:</strong>
              <div className={classes.stopDetail}><strong>{t('firstName')}</strong>: {stop.firstName}</div>
              <div className={classes.stopDetail}><strong>{t('lastName')}</strong>: {stop.lastName}</div>
              <div className={classes.stopDetail}><strong>{t('internalCustomerId')}</strong>: {stop.internalCustomerId}</div>
              <div className={classes.stopDetail}><strong>{t('customerId')}</strong>: {stop.customerId}</div>
              <div className={classes.stopDetail}><strong>{t('address')}</strong>: {displayAddress(stop.address)}</div>
              <div className={classes.stopDetail}><strong>{t('stairMoney')}</strong>: {stop.stairMoney ? t('yes') : t('no')}</div>
              <div className={classes.stopDetail}><strong>{t('key')}</strong>: {stop.key ? t('yes') : t('no')}</div>
              <div className={classes.stopDetail}><strong>{t('keyCode')}</strong>: {stop.keyCode ? stop.keyCode : '-'}</div>
              <div className={classes.stopDetail}><strong>{t('additionalStopDeliveryTime')}</strong>: {Math.round(stop.additionalDeliveryTime / 60) || '-'}
              </div>
              {stop.contact && (stop.contact.name || stop.contact.phoneNumber || stop.contact.eMail) &&
              <div className={classes.stopDetail}>
                <strong>{t('contactPerson')}</strong>:
                <div className={classes.stopDetail}><strong>{t('name')}</strong>: {stop.contact.name}</div>
                <div className={classes.stopDetail}><strong>{t('phoneNumber')}</strong>: {stop.contact.phoneNumber}</div>
                {AuthService.getUserRole() !== userRoles.carrier &&
                <div className={classes.stopDetail}><strong>{t('email')}</strong>: {stop.contact.eMail}</div>}
              </div>
              }
              <div className={classes.stopDetail}>
                <strong>{t('customerInfo')}:</strong>
                <div className={classes.stopDetail}>{stop.customerInfo ? stop.customerInfo : '-'}</div>
              </div>
            </div>
          </div>
          <div className={classes.wrapperChild}>
            <div className={classes.stopDetail}>
              <strong>{t('state')}:</strong>
              <div className={classes.stopDetail}>
                <LoadingWrapper
                  loading={trackingStopLoading}
                  noPadding
                >
                  <strong>{t('estimatedDeliveryWindow')}</strong>: {lastTrackingStep ? `${DateService.displayTime(lastTrackingStep?.deliveryWindow?.from)} - ${DateService.displayTime(lastTrackingStep?.deliveryWindow?.to)}` : '-'}
                </LoadingWrapper>
              </div>
              <div className={classes.stopDetail}><strong>{t('planedDeliveryDateShipper')}</strong>: {DateService.displayDate(stop.planedDeliveryDateShipper)}</div>
              <div className={classes.stopDetail}><strong>{t('deliveryTimeWindowShipper')}</strong>: {stop.deliveryWindowShipper? `${DateService.displayTime(stop?.deliveryWindowShipper?.from)} - ${DateService.displayTime(stop?.deliveryWindowShipper?.to)}` : '-'}</div>
              <div className={classes.stopDetail}><strong>{t('planedDeliveryDateCarrier')}</strong>: {DateService.displayDate(stop.planedDeliveryDateCarrier)}</div>
              <div className={classes.stopDetail}><strong>{t('stopStatus')}</strong>: {t(getStopStatusString(stop.stopStatus))}</div>
              <div className={classes.stopDetail}><strong>{t('deliveryTime')}</strong>: {DateService.displayDateTime(stop.deliverTime)}</div>
              <div className={classes.stopDetail}><strong>{t('ageVerificationStatus')}</strong>:  {t(getAgeVerificationStatusString(stop.ageVerificationStatus))}</div>
              <div className={classes.stopDetail}><strong>{t('ageVerificationMinimumAge')}</strong>:  {stop.ageVerificationMinimumAge ? stop.ageVerificationMinimumAge : '-'}</div>
            </div>
            <br/>
            <div className={classes.stopDetail}>
              <strong>{t('driverInfo')}:</strong>
              <div className={classes.stopDetail}>
                <strong>{t('driver')}</strong>: {`${shallowTour?.driverDisplayName ? shallowTour.driverDisplayName : '-'} (${shallowTour?.driverEmail ? shallowTour.driverEmail : '-'})`}
              </div>
              <div className={classes.stopDetail}><strong>{t('infoForShipper')}</strong>: {stop.driverInfo ? stop.driverInfo : '-'}</div>
              {(AuthService.getUserRole() === userRoles.administration || AuthService.getUserRole() === userRoles.carrier) &&
              <div className={classes.stopDetail}>
                <strong>{t('hintForDrivers')}</strong>: {stop.driverHint ? stop.driverHint : '-'}
              </div>
              }
              <div className={classes.stopDetail}><strong>{t('infoForCustomerService')}</strong>: {stop.messageToShipper ? stop.messageToShipper : '-'}</div>
            </div>
            <br/>
            <div className={classes.stopDetail}>
              <strong>{t('stopCapacity')}:</strong>
              {stop.stopCapacities &&
              <Fragment>
                <div className={classes.stopDetail}><strong>{t('amountOfBoxes')}</strong>: {roundCapacityValues(stop.stopCapacities.boxAmount)}
                  {matchingPreviewStop &&
                  <Typography className={classes.previewData}>
                    {t('preview')}:&nbsp;
                    <TrendNumber
                      decimalPlaces={2}
                      number={roundCapacityValues(matchingPreviewStop.stopCapacities.boxAmount)}
                      reference={roundCapacityValues(stop.stopCapacities.boxAmount)}
                    />
                  </Typography>
                  }
                </div>
                <div className={classes.stopDetail}><strong>{t('weight')}</strong>: {roundCapacityValues(stop.stopCapacities.weight)}
                  {matchingPreviewStop &&
                  <Typography className={classes.previewData}>
                    {t('preview')}:&nbsp;
                    <TrendNumber
                      decimalPlaces={2}
                      number={roundCapacityValues(matchingPreviewStop.stopCapacities.weight)}
                      reference={roundCapacityValues(stop.stopCapacities.weight)}
                    />
                  </Typography>
                  }
                </div>
                <div className={classes.stopDetail}><strong>{t('volume')}</strong>: {roundCapacityValues(stop.stopCapacities.volume)}
                  {matchingPreviewStop &&
                  <Typography className={classes.previewData}>
                    {t('preview')}:&nbsp;
                    <TrendNumber
                      decimalPlaces={2}
                      number={roundCapacityValues(matchingPreviewStop.stopCapacities.volume)}
                      reference={roundCapacityValues(stop.stopCapacities.volume)}
                    />
                  </Typography>
                  }
                </div>
                <Button
                  color={'secondary'}
                  disabled={!stop.previewStopIdentifier}
                  onClick={() => loadMatchingPreviewStop(stop.previewStopIdentifier)}
                >
                  {t('compareCapacitiesToPreviewCapacities')}
                </Button>
              </Fragment>
              }
            </div>
          </div>
          <div className={classes.wrapperChild}>
            <div><strong>{`${t(getProductTypeString(ProductType.Box))} / ${t(getProductTypeString(ProductType.BeverageBox))}`}</strong>:</div>
            <ProductTable
              className={classes.stopDetail}
              products={stop.products.filter(product => product.productType === ProductType.Box || product.productType === ProductType.BeverageBox)}
              productType={ProductType.Box}
            />
            <br/>
            <div><strong>{t(getProductTypeString(ProductType.PackageInserts))}</strong>:</div>
            <ProductTable
              className={classes.stopDetail}
              products={stop.packageInserts}
              productType={ProductType.PackageInserts}
            />
            <br/>
            <div><strong>{t(getProductTypeString(ProductType.Other))}</strong>:</div>
            <ProductTable
              className={classes.stopDetail}
              products={stop.products.filter(product => product.productType === ProductType.Other)}
              productType={ProductType.Other}
            />
            <br/>
            <div><strong>{t('empties')}</strong>:</div>
            <EmptiesTable
              className={classes.stopDetail}
              empties={stop.empties}
            />
            <br/>
          </div>
        </div>
      </PortletContent>
      <TrackAndTraceInfoDialog
        handleClose={() => setTrackAndTraceInfoDialogOpen(false)}
        open={trackAndTraceInfoDialogOpen}
        stopId={stop.tourStopId}
        trackingStop={trackingStop}
        trackingStopLoading={trackingStopLoading}
      />
      <StopHistoryDialog
        dialogOpen={stopHistoryDialogOpen}
        onClose={() => setStopHistoryDialogOpen(false)}
        stopHistory={stopHistory}
      />
    </Fragment>
  );
}


StopDetailShow.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  closeDetailView: PropTypes.func,
  edit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  shallowTour: PropTypes.object,
  stop: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  trackingStop: PropTypes.object,
  trackingStopLoading: PropTypes.bool,
};

export default compose(withStyles(styles), withTranslation())(StopDetailShow);
