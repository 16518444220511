import React, {Fragment, useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {MicroHubPlanWithToursView, TextPlaceholderWrapper} from '../../../components';
import {getDayOfWeekFromDate} from '../../../services/enums/dayOfWeekHelper';
import classNames from 'classnames';
import {groupBy} from '../../../services/util/helperFuncs';
import {ProductType} from '../../../services/enums/ProductType';


function MicroHubPlanWithTours(props) {

  const {t, classes, className, microHubPlans, toursWithStops, otherStops, filter, search} = props;

  const [hubPlan, setHubPlan] = useState(null);
  const [filteredToursWithStops, setFilteredToursWithStops] = useState([]);
  const [filteredOtherStops, setFilteredOtherStops] = useState([]);
  const [capacityAll, setCapacityAll] = useState({});
  const [capacityShipper, setCapacityShipper] = useState();
  const [displayBeverageInsertZones, setDisplayBeverageInsertZones] = useState(false);


  useEffect(() => {
    if (microHubPlans) {
      const potentialPlans = microHubPlans.filter(plan => plan.dayOfWeek === getDayOfWeekFromDate(filter.fromDateTime));
      if (potentialPlans.length > 0) {
        setHubPlan(potentialPlans[0]);
      } else {
        setHubPlan(null);
      }
    }
  }, [microHubPlans, filter]);

  useEffect(() => {
    if (!hubPlan) return;

    // calculate overall capacity of hub
    const toursCapacity = toursWithStops ? toursWithStops.reduce((accumulator, tour) => accumulator + tour.tourCapacities.volume, 0) : 0;
    const stopCapacity = otherStops ? otherStops.reduce((accumulator, stop) => accumulator + stop.stopCapacities.volume, 0) : 0;
    setCapacityAll({current: toursCapacity + stopCapacity, max: hubPlan ? hubPlan.maxVolume : 0});

    // Calculate capacity per shipper
    const shipperToCapacityDict = {};
    // loop over box zones and calculate maxCapacity for each shipper in hub
    hubPlan.boxZones.forEach(zone => {
      const zoneVolume = Math.floor((zone.width * zone.depth * zone.stackHeight) / 1000);
      zone.shipperInfos.forEach(info => {
        if (shipperToCapacityDict[info.name]) {
          shipperToCapacityDict[info.name].max += zoneVolume;
        } else {
          shipperToCapacityDict[info.name] = {current: 0, max: zoneVolume};
        }
      })
    });

    // loop over all stops at hub and calculate current capacity for each shipper at the hub.
    const allStops = toursWithStops.map(tour => tour.stops).flat().concat(otherStops);
    const shipperGroups = groupBy(allStops, 'shipperName');
    Object.getOwnPropertyNames(shipperGroups).forEach(groupName => {
      const shipperCapacity = shipperGroups[groupName].reduce((accumulator, stop) => accumulator + stop.stopCapacities.volume, 0);
      if (shipperToCapacityDict[groupName]) {
        shipperToCapacityDict[groupName].current = shipperCapacity;
      }
    });
    setCapacityShipper(shipperToCapacityDict);

    // store if any of the stops we loop over in the following have beverageboxes or packageinserts so we display the corresponding zones
    let anyBeverageInsertProducts = false;

    // filter Stops with filter and search infos
    let toursWithStopsFiltered = JSON.parse(JSON.stringify(toursWithStops.filter(tour => !search.tourId || tour.tourId === parseInt(search.tourId))));
    toursWithStopsFiltered.forEach(tour => {
      const newStops = tour.stops
        .filter(stop => !search.stopId || stop.tourStopId === parseInt(search.stopId))
        .filter(stop => !filter.shipperName || stop.shipperName === filter.shipperName);
      //check if stops contains beverage boxes or package inserts
      if (!anyBeverageInsertProducts &&
        (newStops.some(stop => stop.packageInserts.length > 0 ||
          stop.products.some(product => product.productType === ProductType.BeverageBox ||
            product.productType === ProductType.PackageInserts)))) {
        anyBeverageInsertProducts = true;
      }
      tour.stops = newStops;
    });
    toursWithStopsFiltered = toursWithStopsFiltered.filter(tour => tour.stops.length > 0);
    setFilteredToursWithStops(toursWithStopsFiltered);

    let otherStopsFiltered = otherStops
      .filter(stop => !search.stopId || stop.tourStopId === parseInt(search.stopId))
      .filter(stop => !filter.shipperName || stop.shipperName === filter.shipperName);
    setFilteredOtherStops(JSON.parse(JSON.stringify(otherStopsFiltered)));
    //check if stops contains beverage boxes or package inserts
    if (!anyBeverageInsertProducts &&
      (otherStopsFiltered.some(stop => stop.packageInserts.length > 0 ||
        stop.products.some(product => product.productType === ProductType.BeverageBox ||
          product.productType === ProductType.PackageInserts)))) {
      anyBeverageInsertProducts = true;
    }

    setDisplayBeverageInsertZones(anyBeverageInsertProducts);

  }, [hubPlan, toursWithStops, otherStops, filter, search]);

  const rootClassName = classNames(classes.root, className);

  return (
    <Fragment>
      <TextPlaceholderWrapper
        active={!hubPlan}
        text={t('noMicroHubPlanForThisDay')}
      >
        <MicroHubPlanWithToursView
          capacitiesShippers={capacityShipper ? capacityShipper : {}}
          capacityAll={capacityAll}
          className={rootClassName}
          displayBeverageInsertZones={displayBeverageInsertZones}
          microHubPlan={hubPlan}
          otherStops={filteredOtherStops}
          shipperFilter={filter.shipperName}
          toursWithStops={filteredToursWithStops}
        />
      </TextPlaceholderWrapper>
    </Fragment>
  );

}

MicroHubPlanWithTours.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  microHubPlans: PropTypes.array.isRequired,
  otherStops: PropTypes.array.isRequired,
  search: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  toursWithStops: PropTypes.array.isRequired,
};


export default compose(withStyles(styles), withTranslation())(MicroHubPlanWithTours);
