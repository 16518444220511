import React, {Fragment, useRef, useState} from 'react'
import debounce from 'lodash.debounce';
import {Box, Collapse, TableCell, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Draggable} from 'react-beautiful-dnd';
import {DndHelperService} from '../../../../../../../services/dndHelper/dndHelperService';
import {TableCellWithConfig, TourStopTableSmallDetails} from '../../../../../../../components';
import Columns from '../columns';

function TourStopTableRowSmall(props) {

  const {
    classes,
    t,
    stop,
    className,
    deleteStopFromTour,
    index,
    uniqueTourId,
    columnConfigs,
  } = props;
  const [open, setOpen] = useState(false);

  const rootClassName = classNames(className, classes.root);

  const debouncedDelete = useRef(debounce(() => deleteStopFromTour(stop.tourStopId), 1000)).current;

  return (
    <Draggable
      draggableId={DndHelperService.getUniqueStopId(uniqueTourId, stop.tourStopId)}
      index={index}
    >
      {(provided, snapshot) => (
        <Fragment>
          <TableRow
            className={classNames(rootClassName, (snapshot.isDragging ? classes.dragging : ''))}
            hover
            selected={open}
            {...provided.draggableProps}
            ref={provided.innerRef}
          >
            {columnConfigs.map(columnConfig => (
              <TableCellWithConfig
                key={columnConfig.order}
                config={columnConfig}
                className={classes.smallRow}
              >
                {Columns[columnConfig.columnName].body({
                  stop,
                  classes,
                  t,
                  dragHandleProps: provided.dragHandleProps,
                  debouncedDelete,
                  setOpen,
                  open
                })}
              </TableCellWithConfig>
            ))}
          </TableRow>
          <TableRow style={snapshot.isDragging ? {display: 'none'} : {}}>
            <TableCell
              className={classes.largeTableCell}
              colSpan={columnConfigs.filter(val => !val.hidden).length}
            >
              <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
              >
                <Box margin={1}>
                  <TourStopTableSmallDetails stop={stop}/>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </Fragment>
      )}
    </Draggable>
  );
}

TourStopTableRowSmall.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  columnConfigs: PropTypes.array.isRequired,
  deleteStopFromTour: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  stop: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  uniqueTourId: PropTypes.string.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TourStopTableRowSmall);
