import React, {useCallback, useEffect, useRef} from 'react'
import {Button, Typography, useMediaQuery, useTheme, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Map, Marker, TileLayer} from 'react-leaflet';
import {getBoundingBox,} from '../../../../../../services/util/mapUtils';
import * as L from 'leaflet';
import {GestureHandling} from 'leaflet-gesture-handling';
import {Refresh} from '@material-ui/icons';
import iconService from './iconService';

function TrackingMap(props) {

  const {classes, className, driverPosition, stopPosition, stopsToGo, onRefresh} = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const rootClassName = classNames(classes.root, className);

  const map = useRef(null);
  L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

  const driverCoordinates = [driverPosition.latitude, driverPosition.longitude];
  const stopCoordinates = [stopPosition.latitude, stopPosition.longitude];

  const fitMapBoundsToFG = useCallback(() => {
    const coordinates = [driverCoordinates, stopCoordinates];
    if (coordinates.length <= 1) return;
    const boundingBox = getBoundingBox(coordinates);
    map.current.leafletElement.fitBounds(boundingBox, {padding: [30, 30]});
  }, [driverCoordinates, stopCoordinates]);

  useEffect(() => {
    fitMapBoundsToFG();
  }, [fitMapBoundsToFG]);

  return (
    <div className={rootClassName}>
      <div className={classes.headingContainer}>
        <Typography variant={'h3'}>Wo ist meine Kiste?</Typography>
        <Typography
          color={'primary'}
          variant={'subtitle2'}
        >
          {`${stopsToGo} Stopps entfernt`}
        </Typography>
      </div>
      <Map
        boundsOptions={{padding: [30, 30]}}
        center={[stopPosition.latitude, stopPosition.longitude]}
        className={classes.map}
        gestureHandling
        ref={map}
        zoom={17}
        zoomSnap={0.25}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker
          icon={iconService.getStopIcon(theme.palette.primary.main)}
          position={stopCoordinates}
        />
        <Marker
          icon={iconService.getDriverIcon()}
          position={driverCoordinates}
        />
      </Map>
      <div className={classes.refreshButtonContainer}>
        <Button
          color={'primary'}
          onClick={onRefresh}
        >
          <Typography
            className={classes.refreshButtonContent}
            variant={'subtitle2'}
          >
            <Refresh fontSize={matches ? 'default' : 'small'}/>aktualisieren
          </Typography>
        </Button>
      </div>
    </div>
  );
}

TrackingMap.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  driverPosition: PropTypes.shape({latitude: PropTypes.number, longitude: PropTypes.number}).isRequired,
  onRefresh: PropTypes.func.isRequired,
  stopPosition: PropTypes.shape({latitude: PropTypes.number, longitude: PropTypes.number}).isRequired,
  stopsToGo: PropTypes.number,
};


export default withStyles(styles)(TrackingMap);
