import { ShipperLabel } from 'components';
import React from 'react';

const Columns = {
  stopNumber: {
    name: 'stopNumber',
    header: ({ t }) => t('stopNumber'),
    body: ({ stop }) => stop.stopNumber
  },
  tourStopId: {
    name: 'tourStopId',
    header: ({ t }) => t('tourStopId'),
    body: ({ stop }) => stop.id
  },
  shipper: {
    name: 'shipper',
    header: ({ t }) => t('shipper'),
    body: ({ stop }) => <ShipperLabel stop={stop} />
  },
  lastName: {
    name: 'lastName',
    header: ({ t }) => t('lastName'),
    body: ({ stop }) => stop.lastName
  },
  streetAndNumber: {
    name: 'streetAndNumber',
    header: ({ t }) => t('streetAndNumber'),
    body: ({ stop }) => stop.address.streetAndNumber
  },
  zipcode: {
    name: 'zipcode',
    header: ({ t }) => t('zipcode'),
    body: ({ stop }) => stop.address.zipcode
  },
}

export default Columns;