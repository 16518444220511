import React, {Fragment} from 'react'
import {FormControl, Input, InputLabel, MenuItem, Select, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import FormGroup from '@material-ui/core/FormGroup';

function MicroHubSensorConfigEdit(props) {

  const {classes, className, editedMicroHubSensorConfig, t, setEditedMicroHubSensorConfig, microHubNames} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <Fragment>
      <div className={rootClassName}>
        <FormGroup
          className={classes.wrapperChild}
        >
          <FormControl className={classes.formControl}>
            <InputLabel
              htmlFor="microHubSensorConfigSensorId"
              required
            >{t('sensorId')}</InputLabel>
            <Input
              error={!editedMicroHubSensorConfig.sensorId}
              id="microHubSensorConfigSensorId"
              onChange={(event) => {
                setEditedMicroHubSensorConfig({...editedMicroHubSensorConfig, sensorId: event.target.value})
              }}
              value={editedMicroHubSensorConfig.sensorId}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel
              htmlFor="microHubSensorConfigMicroHubName"
              required
            >{t('microHubName')}</InputLabel>
            <Select
              error={!editedMicroHubSensorConfig.microHubName}
              input={<Input id="microHubSensorConfigMicroHubName"/>}
              onChange={event => setEditedMicroHubSensorConfig({...editedMicroHubSensorConfig, microHubName: event.target.value})}
              value={editedMicroHubSensorConfig.microHubName}
            >
              {microHubNames.sort().map((microHubName, index) => {
                return (
                    <MenuItem
                        key={index}
                        value={microHubName}
                    >
                      {microHubName}
                    </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </FormGroup>
      </div>
    </Fragment>
  );
}


MicroHubSensorConfigEdit.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  editedMicroHubSensorConfig: PropTypes.object.isRequired,
  setEditedMicroHubSensorConfig: PropTypes.func.isRequired,
  microHubNames: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(MicroHubSensorConfigEdit);
