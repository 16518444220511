import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, withStyles} from '@material-ui/core';
import styles from './styles';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import PortletContent from '../../../PortletContent';
import Portlet from '../../../Portlet';
import TableBody from '@material-ui/core/TableBody';
import {DateService} from '../../../../services/util/DateService';

function StopHistoryDialog(props) {

  const {classes, t, dialogOpen, onClose, stopHistory} = props;

  return (
    <Dialog
      className={classes.dialog}
      fullWidth
      maxWidth={'lg'}
      onClose={onClose}
      open={dialogOpen}
      scroll={'paper'}
    >
      <DialogTitle>{t('stopHistoryDialogTitle')}</DialogTitle>
      <DialogContent
        className={classes.dialogContent}
      >
        <Fragment>
          {stopHistory && stopHistory.length > 0 &&
          <Portlet>
            <PortletContent
              className={classes.portletContent}
              noPadding
            >
              <Table
                className={classes.stopHistoryTable}
                size="small"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.alignLeft}>{t('stopHistoryTime')}</TableCell>
                    <TableCell className={classes.alignLeft}>{t('stopHistoryIdentifier')}</TableCell>
                    <TableCell className={classes.alignLeft}>{t('stopHistoryBy')}</TableCell>
                    <TableCell className={classes.alignLeft}>{t('stopHistoryObject')}</TableCell>
                    <TableCell className={classes.alignLeft}>{t('stopHistoryFrom')}</TableCell>
                    <TableCell className={classes.alignLeft}>{t('stopHistoryTo')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stopHistory.sort((a, b) => {
                    return new Date(a.modifiedTime).getTime() - new Date(b.modifiedTime).getTime()
                  }).reverse().map((stopHistory, index) => (
                    <Fragment
                      key={index}
                    >
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          {DateService.displayDateTime(stopHistory.modifiedTime)}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {stopHistory.stopId}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {stopHistory.modifiedBy}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {stopHistory.modificationObject}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {stopHistory.modificationFrom}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {stopHistory.modificationTo}
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </PortletContent>
          </Portlet>
          }
        </Fragment>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
        >
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

StopHistoryDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  stopHistory: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired
}

export default compose(withStyles(styles), withTranslation())(StopHistoryDialog);
