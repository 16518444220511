import React from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import {InfoIcon, Portlet, PortletContent} from '../../../index';

function OperatingTime(props) {
  const {classes, className, times, title, t} = props;

  const rootClassName = classNames(classes.root, className);
  return (
    <Portlet className={rootClassName}>
      <PortletContent>
        <div className={classes.iconWrapper}>
          <Typography variant="h3">
            {title}
          </Typography>
          <InfoIcon
            className={classes.iconMargin}
            descriptionKey={'31'}
          />
        </div>
        <hr/>
        <div>{t('monday')}: {times.monday ? t('yes') : t('no')}</div>
        <div>{t('tuesday')}: {times.tuesday ? t('yes') : t('no')}</div>
        <div>{t('wednesday')}: {times.wednesday ? t('yes') : t('no')}</div>
        <div>{t('thursday')}: {times.thursday ? t('yes') : t('no')}</div>
        <div>{t('friday')}: {times.friday ? t('yes') : t('no')}</div>
        <div>{t('saturday')}: {times.saturday ? t('yes') : t('no')}</div>
        <div>{t('sunday')}: {times.sunday ? t('yes') : t('no')}</div>
      </PortletContent>
    </Portlet>
  );
}

OperatingTime.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  times: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};


export default compose(withStyles(styles), withTranslation())(OperatingTime);
