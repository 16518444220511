import {roundCapacityValues} from './helperFuncs';

export class CapacityHelperService {
  static getBoxAmountOfHub(hub) {
    return roundCapacityValues(hub.tours ? hub.tours.reduce((accumulatorTour, tour) => accumulatorTour + (tour.stops ? tour.stops.reduce((accumulatorStop, stop) => accumulatorStop + stop.stopCapacities.boxAmount, 0) : 0), 0) : 0)
  }

  static getWeightOfHub(hub) {
    return roundCapacityValues(hub.tours ? hub.tours.reduce((accumulatorTour, tour) => accumulatorTour + (tour.stops ? tour.stops.reduce((accumulatorStop, stop) => accumulatorStop + stop.stopCapacities.weight, 0) : 0), 0) : 0)
  }

  static getVolumeOfHub(hub) {
    return roundCapacityValues(hub.tours ? hub.tours.reduce((accumulatorTour, tour) => accumulatorTour + (tour.stops ? tour.stops.reduce((accumulatorStop, stop) => accumulatorStop + stop.stopCapacities.volume, 0) : 0), 0) : 0)
  }

  static getStopAmountOfHub(hub) {
    return roundCapacityValues(hub.tours ? hub.tours.reduce((accumulator, tour) => accumulator + (tour.stops ? tour.stops.length : 0), 0) : 0);
  }

  static getBoxAmountOfTour(tour) {
    return roundCapacityValues(tour.stops ? tour.stops.reduce((accumulator, stop) => accumulator + stop.stopCapacities.boxAmount, 0) : 0);
  }

  static getWeightOfTour(tour) {
    return roundCapacityValues(tour.stops ? tour.stops.reduce((accumulator, stop) => accumulator + stop.stopCapacities.weight, 0) : 0);
  }

  static getVolumeOfTour(tour) {
    return roundCapacityValues(tour.stops ? tour.stops.reduce((accumulator, stop) => accumulator + stop.stopCapacities.volume, 0) : 0);
  }

  static getBoxAmountOfStopList(list) {
    if (!list) return 0;
    return roundCapacityValues(list.reduce((accumulator, stop) => accumulator + stop.stopCapacities.boxAmount, 0));
  }

  static getWeightOfStopList(list) {
    if (!list) return 0;
    return roundCapacityValues(list.reduce((accumulator, stop) => accumulator + stop.stopCapacities.weight, 0));
  }

  static getVolumeOfStopList(list) {
    if (!list) return 0;
    return roundCapacityValues(list.reduce((accumulator, stop) => accumulator + stop.stopCapacities.volume, 0));
  }
}
