import React from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    CarrierAndMicroHubDropdownSelect,
    DateTimeRangeFilter,
} from '../../../components';

function MicroHubMeasurementFilter(props) {

    const {classes, className, filter, setFilter, microHubOptions, carriersWithMicroHubs, disabled} = props;
    const rootClassName = classNames(classes.root, className);

    return (
        <div className={rootClassName}>
            <form className={classes.rowWrapper}>
                <DateTimeRangeFilter
                    className={classes.rowWrapper}
                    fromDescriptionKey={'18'}
                    fromError={!filter.dateTimeFrom}
                    fromRequired
                    fromValue={filter.dateTimeFrom}
                    setValue={(fromValue, toValue) => setFilter({...filter, dateTimeFrom: fromValue, dateTimeTo: toValue})}
                    toDescriptionKey={'18'}
                    toError={!filter.dateTimeTo}
                    toRequired
                    toValue={filter.dateTimeTo}
                />
                <CarrierAndMicroHubDropdownSelect
                    carriersWithMicroHubs={carriersWithMicroHubs}
                    carrierValue={filter.carrierName}
                    className={classes.rowWrapper}
                    disabled={disabled}
                    microHubDescriptionKey={'5'}
                    microHubOptions={microHubOptions}
                    microHubValue={filter.microHubName}
                    setCarrierValue={(value) => setFilter({...filter, carrierName: value, microHubName: ''})}
                    setMicroHubValue={(value) => setFilter({...filter, microHubName: value})}
                    />
            </form>
        </div>
    );
}

MicroHubMeasurementFilter.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    filter: PropTypes.object.isRequired,
    microHubOptions: PropTypes.array,
    carriersWithMicroHubs: PropTypes.object,
    setFilter: PropTypes.func.isRequired,
};

export default compose(withStyles(styles))(MicroHubMeasurementFilter);
