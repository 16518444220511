import React, {useEffect, useState} from 'react'
import {Button, Checkbox, FormControl, FormControlLabel, Input, InputLabel, MenuItem, Select, Tooltip, withStyles,} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function StartAlgoControl(props) {

  const {classes, className, t, notPlanedStops, startAlgoWithStops} = props;
  const rootClassName = classNames(classes.root, className);

  const [doNotMixShipperOnTour, setDoNotMixShipperOnTour] = useState(true);
  const [useAlgorithm, setUseAlgorithm] = useState('null');
  const [useTourTemplates, setUseTourTemplates] = useState(true);
  const [selectedStopsForAlgo, setSelectedStopsForAlgo] = useState([]);

  useEffect(() => {
    setSelectedStopsForAlgo(notPlanedStops.map(s => s.tourStopId));
  }, [notPlanedStops]);

  return (
    <div className={rootClassName}>
      <Button
        className={classes.buttonLarge}
        color="primary"
        disabled={!selectedStopsForAlgo || !selectedStopsForAlgo.length}
        onClick={() => startAlgoWithStops(selectedStopsForAlgo, doNotMixShipperOnTour, useAlgorithm === 'null' ? null : useAlgorithm, useTourTemplates)}
        variant="contained"
      >
        {t('planNewTours')}
      </Button>
      <FormControl className={classes.formControl}>
        <InputLabel
          htmlFor="stopIds"
        >{t('stopIds')}</InputLabel>
        <Select
          input={<Input id="stopIds"/>}
          MenuProps={MenuProps}
          multiple
          onChange={event => setSelectedStopsForAlgo(event.target.value)}
          renderValue={selected => (
            <div
              className={classes.chips}
            >
              <Typography variant={'body2'}>
                {selected.length > 7 ? selected.slice(0, 6).join(', ') + '...' : selected.join(', ')}
              </Typography>
            </div>
          )}
          required
          value={selectedStopsForAlgo}
        >
          {notPlanedStops.map(stop => (
            <MenuItem
              key={stop.tourStopId}
              value={stop.tourStopId}
            >
              {stop.tourStopId}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Tooltip
        placement={'left'}
        title={t('useAlgorithmInfo')}
      >
        <FormControl className={classes.formControlSelect}>
          <InputLabel>{t('useAlgorithm')}</InputLabel>
          <Select
            onChange={(event) => setUseAlgorithm(event.target.value)}
            value={useAlgorithm}
          >
            <MenuItem
              value={'null'}
            >
              {t('default')}
            </MenuItem>
            <MenuItem
              value
            >
              {t('yes')}
            </MenuItem>
            <MenuItem
              value={false}
            >
              {t('no')}
            </MenuItem>
            })}
          </Select>
        </FormControl>
      </Tooltip>
      <Tooltip title={t('useTourTemplatesInfo')}>
        <FormControlLabel
          className={classes.formControlCheckbox}
          control={
            <Checkbox
              checked={useTourTemplates}
              color="secondary"
              onChange={(event) =>
                setUseTourTemplates(event.target.checked)
              }
              value={useTourTemplates}
            />
          }
          label={t('useTourTemplates')}
        />
      </Tooltip>
      <Tooltip title={t('doNotMixShippersOnTourInfo')}>
        <FormControlLabel
          className={classes.formControlCheckbox}
          control={
            <Checkbox
              checked={doNotMixShipperOnTour}
              color="secondary"
              onChange={(event) =>
                setDoNotMixShipperOnTour(event.target.checked)
              }
              value={doNotMixShipperOnTour}
            />
          }
          label={t('doNotMixShippersOnTour')}
        />
      </Tooltip>
    </div>
  );
}

StartAlgoControl.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  notPlanedStops: PropTypes.array.isRequired,
  startAlgoWithStops: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(StartAlgoControl);
