export default theme => ({
  root: {
    overflow: 'auto'
  },
  paper: {
    padding: theme.spacing(1),
  },
  deleteButton: {
    color: theme.palette.danger.main
  }
});

