import React, {Fragment, useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Stepper from '@material-ui/core/Stepper';
import {Prompt} from 'react-router';
import {DataImport, DataImportMethodSelection, ImportDataOverview, SimulationResultOverview} from './Components';
import RunSimulation from './Components/RunSimulation';
import {displayModes} from '../../services/enums/displayModes';


function SimulateTourBuildingComponent(props) {

  const {classes, t, microHubs, displayMode, carriers, microHubOptions, carriersWithMicroHubs, filter, setFilter, shippers} = props;
  const steps = [t('chooseDataKindForSimulation'), t('uploadDataForSimulation'), t('inspectUploadedData'), t('startSimulation'), t('inspectSimulationResults')];

  const [stops, setStops] = useState([]);
  const [unassignedStops, setUnassignedStops] = useState([]);
  const [tours, setTours] = useState([]);
  const [useExcelAsImportMethod, setUseExcelAsImportMethod] = useState(true);
  const [activeStep, setActiveStep] = useState(0);


  return (
    <Fragment>
      <Prompt
        message={t('confirmNavigateAway')}
        when={stops && stops.length > 0}
      />
      <div className={classes.root}>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {(() => {
                  switch (index) {
                    case 0:
                      return (
                        <DataImportMethodSelection
                          setActiveStep={setActiveStep}
                          setUseExcelAsImportMethod={setUseExcelAsImportMethod}
                        />
                      )
                    case 1:
                      return (
                        <DataImport
                          carriers={carriers}
                          carriersWithMicroHubs={carriersWithMicroHubs}
                          displayMode={displayMode}
                          filter={filter}
                          microHubOptions={microHubOptions}
                          microHubs={microHubs}
                          setActiveStep={setActiveStep}
                          setFilter={setFilter}
                          setStops={setStops}
                          shipperOptions={shippers}
                          stops={stops}
                          useExcelAsImportMethod={useExcelAsImportMethod}
                        />
                      );
                    case 2:
                      return (
                        <ImportDataOverview
                          displayMode={displayMode}
                          microHubs={microHubs}
                          setActiveStep={setActiveStep}
                          stops={stops}
                        />
                      );
                    case 3:
                      return (
                        <RunSimulation
                          setActiveStep={setActiveStep}
                          setTours={setTours}
                          setUnassignedStops={setUnassignedStops}
                          stops={stops}
                          tours={tours}
                          unassignedStops={unassignedStops}
                        />
                      );
                    case 4:
                      return (
                        <SimulationResultOverview
                          displayMode={displayMode}
                          microHubs={microHubs}
                          setActiveStep={setActiveStep}
                          tours={tours}
                          unassignedStops={unassignedStops}
                        />
                      );
                    default:
                      return (<div>error</div>);
                  }
                })()}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </div>
    </Fragment>
  );
}

SimulateTourBuildingComponent.propTypes = {
  carriers: PropTypes.array,
  carriersWithMicroHubs: PropTypes.object,
  classes: PropTypes.object.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  filter: PropTypes.object.isRequired,
  microHubOptions: PropTypes.array,
  microHubs: PropTypes.array,
  setFilter: PropTypes.func.isRequired,
  shippers: PropTypes.array,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(SimulateTourBuildingComponent);
