import React from 'react'
import {ButtonGroup, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import Button from '@material-ui/core/Button';
import {MicroHubElementTypes} from '../../../../../services/hubAdministrationHelper/hubAdministrationHelper';
import classNames from 'classnames'
import {
  AllInbox as BoxZoneIcon,
  Block as BlockerIcon,
  Cancel as CancelIcon,
  LocalDrink as BeverageInsertIcon,
  MeetingRoom as DoorIcon,
  Pets as HubBaseIcon,
  Remove as WallIcon,
  Save as SaveIcon,
  SettingsOverscan as AutoCenterIcon,
} from '@material-ui/icons';
import InfoTooltip from "../../../../ELearning/InfoTooltip";

function MicroHubPlanEditControls(props) {

  const {classes, t, hubData, save, cancelEdit, autoCenter, createElement, className, setDragAndDropMode, dragAndDropMode} = props;
  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <ButtonGroup
        className={classes.controlButton}
        color="primary"
        variant="contained"
      >
        <Button
          className={classes.buttonGroupMember}
          onClick={cancelEdit}
          color="default"
        >
          <CancelIcon className={classes.controlButtonIcon}/>&nbsp;{t('cancel')}
        </Button>
        <Button
          className={classes.buttonGroupMember}
          onClick={save}
        >
          <SaveIcon className={classes.controlButtonIcon}/>&nbsp;{t('save')}
        </Button>
      </ButtonGroup>
      <Button
        className={classes.controlButton}
        color="primary"
        disabled={!!hubData.hubBase}
        onClick={() => {
          createElement(MicroHubElementTypes.hubBase)
        }}
        title={t('microHubPlanHubBaseAdd')}
        variant="contained"
      >
        <HubBaseIcon className={classes.controlButtonIcon}/>&nbsp;{t('microHubPlanHubBaseAdd')}
      </Button>
      <Button
        className={classes.controlButton}
        color="primary"
        disabled={!hubData.hubBase || !!hubData.hubBase.door}
        onClick={() => {
          createElement(MicroHubElementTypes.door)
        }}
        title={t('microHubPlanHubDoorAdd')}
        variant="contained"
      >
        <DoorIcon className={classes.controlButtonIcon}/>&nbsp;{t('microHubPlanHubDoorAdd')}
      </Button>
      <Button
        className={classes.controlButton}
        color="primary"
        disabled={!hubData.hubBase}
        onClick={() => {
          createElement(MicroHubElementTypes.wall)
        }}
        title={t('microHubPlanWallAdd')}
        variant="contained"
      >
        <WallIcon className={classes.controlButtonIcon}/>&nbsp;{t('microHubPlanWallAdd')}
      </Button>
      <Button
        className={classes.controlButton}
        color="primary"
        disabled={!hubData.hubBase}
        onClick={() => {
          createElement(MicroHubElementTypes.blockerZone)
        }}
        title={t('microHubPlanBlockerZoneAdd')}
        variant="contained"
      >
        <BlockerIcon className={classes.controlButtonIcon}/>&nbsp;{t('microHubPlanBlockerZoneAdd')}
      </Button>
      <Button
        className={classes.controlButton}
        color="primary"
        disabled={!hubData.hubBase}
        onClick={() => {
          createElement(MicroHubElementTypes.boxZone)
        }}
        title={t('microHubPlanBoxZoneAdd')}
        variant="contained"
      >
        <BoxZoneIcon className={classes.controlButtonIcon}/>&nbsp;{t('microHubPlanBoxZoneAdd')}
      </Button>
      <Button
        className={classes.controlButton}
        color="primary"
        disabled={!hubData.hubBase}
        onClick={() => {
          createElement(MicroHubElementTypes.beverageInsertZone)
        }}
        title={t('microHubPlanBeverageInsertZoneAdd')}
        variant="contained"
      >
        <BeverageInsertIcon className={classes.controlButtonIcon}/>&nbsp;{t('microHubPlanBeverageInsertZoneAdd')}
      </Button>
      <Button
        className={classes.controlButton}
        color="secondary"
        onClick={autoCenter}
        title={t('autoCenter')}
        variant="contained"
      >
        <AutoCenterIcon className={classes.controlButtonIcon}/>&nbsp;{t('autoCenter')}
      </Button>
      <InfoTooltip descriptionKey={'100_preFormat'}>
        <ButtonGroup
          className={classes.controlButton}
          color="secondary"
          size={'small'}
        >
          <Button
            className={classes.buttonGroupMember}
            onClick={() => setDragAndDropMode(false)}
            variant={dragAndDropMode ? '' : 'contained'}
          >
            {t('microHubPlanDragAndDropModePlan')}
          </Button>
          <Button
            className={classes.buttonGroupMember}
            onClick={() => setDragAndDropMode(true)}
            variant={dragAndDropMode ? 'contained' : ''}
          >
            {t('microHubPlanDragAndDropModeZone')}
          </Button>
        </ButtonGroup>
      </InfoTooltip>
    </div>
  );
}

MicroHubPlanEditControls.propTypes = {
  autoCenter: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  createElement: PropTypes.func.isRequired,
  dragAndDropMode: PropTypes.bool.isRequired,
  hubData: PropTypes.object.isRequired,
  save: PropTypes.func.isRequired,
  setDragAndDropMode: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(MicroHubPlanEditControls);
