import {useSignalr} from '@known-as-bmf/react-signalr';
import {useEffect} from 'react';
import {LogLevel} from '@aspnet/signalr';
import {AuthService} from '../services/auth/authService';

export function useStopHub(messageHandler) {
  const {on} = useSignalr(`${process.env.REACT_APP_API_URI}tour-ws/stop-hub`, {
    logger: LogLevel.Error,
    accessTokenFactory() {
      return AuthService.getAuthToken();
    }
  });

  useEffect(() => {
    const sub = on('deletestop').subscribe(next =>
      messageHandler(next)
    );
    return () => sub.unsubscribe();
  }, [on, messageHandler]);
  useEffect(() => {
    const sub = on('updatestop').subscribe(next =>
      messageHandler(next)
    );
    return () => sub.unsubscribe();
  }, [on, messageHandler]);
  useEffect(() => {
    const sub = on('createstop').subscribe(next =>
      messageHandler(next)
    );
    return () => sub.unsubscribe();
  }, [on, messageHandler]);
}
