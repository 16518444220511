// eslint-disable-next-line no-unused-vars
export default theme => ({
  dialog: {},
  tableCell: {
    whiteSpace: 'nowrap',
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  alignLeft: {
    align: 'left',
  },
  noCapacityMatch: {
    backgroundColor: theme.palette.danger.light
  },
  selected: {
    backgroundColor: theme.palette.primary.selected
  },
  dangerColor: {
    color: theme.palette.danger.main
  },
  alignText: {
    display: 'flex',
    alignItems: 'center'
  }
});
