export default theme => ({
  root: {
    overflow: 'auto',
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '5px'
  },
  tableCell: {
    whiteSpace: 'nowrap'
  },
  alignLeft: {
    align: 'left',
  },
  formControl: {
    minWidth: '200px',
  },
  deleteButton: {
    padding: 0,
    color: theme.palette.danger.main
  }
});

