import React from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import SideNavLayout from '../../../layouts/SideNavLayout';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {CarrierProfile, DataLoadingWrapper} from '../../../components/';
import {clientTypes} from '../../../services/client/clientTypes';
import {ClientService} from '../../../services/client/clientService';
import {AuthService} from '../../../services/auth/authService';
import {useQuery} from '@apollo/react-hooks';
import {GeneralInfoIcon} from '../../../components';
import {CarrierQueryService} from '../../../services/backend/carrierQueryService';


const client = ClientService.getClient(clientTypes.carrier);

function Profile(props) {
  const {classes, t} = props;

  const query = CarrierQueryService.getCarrierProfileQuery(AuthService.getUserOrganization());
  const {loading, error, data} = useQuery(query, {client: client});

  return (
    <SideNavLayout title={t('account')}>
      <div className={classes.root}>
        <div className={classes.helpWrapper}>
          <GeneralInfoIcon/></div>
        <DataLoadingWrapper
          error={error}
          isLoading={loading}
        >
          {
            data && data.carriers &&
            <CarrierProfile
              carrier={data.carriers[0]}
              readonly
            />
          }
        </DataLoadingWrapper>
      </div>
    </SideNavLayout>
  );
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(Profile);
