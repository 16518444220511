import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import {Draggable} from 'react-beautiful-dnd';
import FluentTourTableRowStop from '../FluentTourTableRowStop';
import FluentTourTableRowTour from '../FluentTourTableRowTour';
import {DndHelperElementTypes} from '../../../../../../services/dndHelper/dndHelperService';


function FluentTourTableRow(props) {
  const {
    className,
    columnConfigs,
    element,
    elementType,
    index,
    elementId,
    vehicleInfos,
    selectVehicle,
    deleteTour,
    openAddTourPopover,
    toggleShowTour,
    hideRow,
    editTourName
  } = props;

  const uniqueDraggableId = JSON.stringify({
    shipperName: element.shipperName,
    id: elementId,
    type: elementType
  });

  return (
    <Draggable
      draggableId={uniqueDraggableId}
      index={index}
    >
      {(provided, snapshot) => (
        <Fragment>
          {elementType === DndHelperElementTypes.Stop ?
            <FluentTourTableRowStop
              className={className}
              columnConfigs={columnConfigs}
              fitsOnVehicle={vehicleInfos}
              hideRow={hideRow}
              openAddTourPopover={event => openAddTourPopover(event, index)}
              provided={provided}
              snapshot={snapshot}
              stop={element}
            />
            :
            <FluentTourTableRowTour
              canDelete={index !== 0}
              className={className}
              colSpan={columnConfigs.filter(val => !val.hidden).length}
              deleteTour={() => deleteTour(element, index)}
              editTourName={editTourName}
              openAddTourPopover={event => openAddTourPopover(event, index)}
              provided={provided}
              selectVehicle={selectVehicle}
              snapshot={snapshot}
              toggleShowTour={toggleShowTour}
              tour={element}
              tourCollapsed={hideRow}
              vehicleCapacities={vehicleInfos}
            />
          }
        </Fragment>
      )}
    </Draggable>
  );
}

FluentTourTableRow.propTypes = {
  className: PropTypes.string,
  columnConfigs: PropTypes.arrayOf(PropTypes.shape({
    hidden: PropTypes.bool.isRequired,
    optional: PropTypes.bool.isRequired
  })).isRequired,
  deleteTour: PropTypes.func.isRequired,
  editTourName: PropTypes.func.isRequired,
  element: PropTypes.object.isRequired,
  elementId: PropTypes.number.isRequired,
  elementType: PropTypes.oneOf([DndHelperElementTypes.Stop, DndHelperElementTypes.Tour]),
  hideRow: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  openAddTourPopover: PropTypes.func.isRequired,
  selectVehicle: PropTypes.func.isRequired,
  toggleShowTour: PropTypes.func.isRequired,
  vehicleInfos: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.shape({volume: PropTypes.number.isRequired, weight: PropTypes.number.isRequired})]),
};

export default FluentTourTableRow;
