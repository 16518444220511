export default theme => ({
  root: {},
  navRight: {
    float: 'right'
  },
  navLeft: {
    float: 'left'
  },
  horizontalContainer: {
    display:'flex',
    justifyContent: 'space-between'
  }
});