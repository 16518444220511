import React from 'react';
import {displayModes} from '../../services/enums/displayModes';

// Implementation as well as Logic can be found in the globalDialogProvider (components/dialog)
const ProblemNotificationDialogContext = React.createContext({
  dialogState: {open: false, typePreset: '', identifierPreset: ''},
  open: () => {
  },
  close: () => {
  },
  displayMode: displayModes.admin,
});

export default ProblemNotificationDialogContext;
