import React, {Fragment, useState} from 'react'
import {FormControl, IconButton, Input, InputLabel, MenuItem, Select, Tooltip, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {
  DoorOrientations,
  getOrientationString,
  MicroHubElementTypes,
  MicroHubPlanPresetService,
} from '../../../../../services/hubAdministrationHelper/hubAdministrationHelper';
import {Add as AddRowIcon, Delete as DeleteRowIcon, WarningRounded as WarnIcon} from '@material-ui/icons';
import classNames from 'classnames';
import {InfoIcon} from '../../../../../components';
import {ShipperAllowedOnHubService} from '../../../../../services/util/shipperAllowedOnHubHelper';

function MicroHubPlanElementEdit(props) {

  const {classes, t, setElement, element, shipperOptions, setShipperToAdd, shipperToAdd, elementType, hubData, selectedDayOfWeek, selectedCarrierName, hubCarrierData} = props;

  const [alreadyUsedOrders, setAlreadyUsedOrders] = useState([]);
  const [addShipperOpen, setAddShipperOpen] = useState(false);

  if (elementType === MicroHubElementTypes.beverageInsertZone && alreadyUsedOrders && element && hubData.beverageInsertZones.length > 0 && alreadyUsedOrders && alreadyUsedOrders.length === 0) {
    // make shure the zone of this element is not included in the list by filter
    const orders = hubData.beverageInsertZones.filter(zone => zone.tmpId !== element.tmpId).map(zone => zone.order)
    if (orders.length > 0) {
      setAlreadyUsedOrders(orders);
    }
  }

  const calculateAlreadyUsedOrderNumbersForBoxZoneByShipperName = (shipperName) => {
    // make shure the zone of this element is not included in the list by filter but add it later with concat so the list is correctly updated after removing and adding shipper
    return hubData.boxZones
      .filter(zone => zone.tmpId !== element.tmpId)
      .concat(element)
      .map(zone => zone.shipperInfos)
      .flat()
      .filter(info => info.name === shipperName)
      .map(info => info.order)
      .sort((a, b) => a - b);
  };

  const addShipperToShipperInfos = () => {
    setElement({...element, shipperInfos: element.shipperInfos.concat(shipperToAdd)});
    setShipperToAdd(MicroHubPlanPresetService.getBoxZoneShipperPreset());
    setAlreadyUsedOrders([]);
    setAddShipperOpen(false);
  };

  const deleteShipperFromShipperInfos = (shipperName) => {
    const index = element.shipperInfos.findIndex(shipperInfo => shipperInfo.name === shipperName);
    if (index < 0) return;
    const newShipperInfos = element.shipperInfos.slice();
    newShipperInfos.splice(index, 1);
    setElement({...element, shipperInfos: newShipperInfos});
  };

  return (
    <Fragment>
      {(() => {
        switch (elementType) {
          case MicroHubElementTypes.hubBase:
            return (
              <form className={classes.container}>
                <div className={classes.rowWrapper}>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      htmlFor="microHubElementWidth"
                      required
                    >{t('microHubElementWidth')}</InputLabel>
                    <Input
                      error={isNaN(element.width) || parseInt(element.width) < 100 || parseInt(element.width) > 15000}
                      id="microHubElementWidth"
                      onChange={(event) => {
                        setElement({
                          ...element,
                          width: isNaN(parseInt(event.target.value)) ? event.target.value : parseInt(event.target.value)
                        });
                      }}
                      onClick={event => event.target.select()}
                      onWheel={event => event.target.blur()}
                      type="number"
                      value={element.width}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      htmlFor="microHubElementDepth"
                      required
                    >{t('microHubElementDepth')}</InputLabel>
                    <Input
                      error={isNaN(element.depth) || parseInt(element.depth) < 100 || parseInt(element.depth) > 15000}
                      id="microHubElementDepth"
                      onChange={(event) => {
                        setElement({
                          ...element,
                          depth: isNaN(parseInt(event.target.value)) ? event.target.value : parseInt(event.target.value)
                        });
                      }}
                      onClick={event => event.target.select()}
                      onWheel={event => event.target.blur()}
                      type="number"
                      value={element.depth}
                    />
                  </FormControl>
                </div>
              </form>
            );
          case MicroHubElementTypes.door:
            return (
              <form className={classes.container}>
                <div className={classes.rowWrapper}>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      htmlFor="microHubElementDoorSize"
                      required
                    >{t('microHubElementDoorSize')}</InputLabel>
                    <Input
                      error={isNaN(element.doorSize) || parseInt(element.doorSize) <= 25 || parseInt(element.doorSize) > 15000}
                      id="microHubElementDoorSize"
                      onChange={(event) => {
                        setElement({
                          ...element,
                          doorSize: isNaN(parseInt(event.target.value)) ? event.target.value : parseInt(event.target.value)
                        });
                      }}
                      onClick={event => event.target.select()}
                      onWheel={event => event.target.blur()}
                      type="number"
                      value={element.doorSize}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      htmlFor="microHubElementOrientation"
                      required
                    >{t('microHubElementOrientation')}</InputLabel>
                    <Select
                      input={<Input id="microHubElementOrientation"/>}
                      onChange={(event) => setElement({...element, orientation: event.target.value})}
                      value={element.orientation}
                    >
                      {Object.getOwnPropertyNames(DoorOrientations).map(propName => DoorOrientations[propName]).map((orientation, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={orientation}
                          >
                            {t(getOrientationString(orientation))}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </form>
            );
          case MicroHubElementTypes.boxZone:
            return (
              <form className={classes.container}>
                <div className={classes.rowWrapper}>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      htmlFor="microHubElementWidth"
                      required
                    >{t('microHubElementWidth')}</InputLabel>
                    <Input
                      error={isNaN(element.width) || parseInt(element.width) < 25 || parseInt(element.width) > 15000}
                      id="microHubElementWidth"
                      onChange={(event) => {
                        setElement({
                          ...element,
                          width: isNaN(parseInt(event.target.value)) ? event.target.value : parseInt(event.target.value)
                        });
                      }}
                      onClick={event => event.target.select()}
                      onWheel={event => event.target.blur()}
                      type="number"
                      value={element.width}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      htmlFor="microHubElementDepth"
                      required
                    >{t('microHubElementDepth')}</InputLabel>
                    <Input
                      error={isNaN(element.depth) || parseInt(element.depth) < 25 || parseInt(element.depth) > 15000}
                      id="microHubElementDepth"
                      onChange={(event) => {
                        setElement({
                          ...element,
                          depth: isNaN(parseInt(event.target.value)) ? event.target.value : parseInt(event.target.value)
                        });
                      }}
                      onClick={event => event.target.select()}
                      onWheel={event => event.target.blur()}
                      type="number"
                      value={element.depth}
                    />
                  </FormControl>
                </div>
                <div className={classes.rowWrapper}>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      htmlFor="microHubElementStackHeight"
                      required
                    >{t('microHubElementStackHeight')}</InputLabel>
                    <Input
                      error={isNaN(element.stackHeight) || parseInt(element.stackHeight) < 25}
                      id="microHubElementStackHeight"
                      onChange={(event) => {
                        setElement({
                          ...element,
                          stackHeight: isNaN(parseInt(event.target.value)) ? event.target.value : parseInt(event.target.value)
                        });
                      }}
                      onClick={event => event.target.select()}
                      onWheel={event => event.target.blur()}
                      type="number"
                      value={element.stackHeight}
                    />
                  </FormControl>
                </div>
                <div className={classes.rowWrapper}>
                  <Typography style={{width: '100%'}}>
                    <strong>{t('shipperOnZone')}</strong>
                  </Typography>
                  <br/>
                  <br/>
                  <table>
                    <thead>
                    <tr>
                      <td className={classes.formControl}>{t('shipper')}</td>
                      <td className={classes.formControl}>{t('microHubElementOrder')}&nbsp;
                        <InfoIcon
                          className={classes.infoIcon}
                          descriptionKey={'10'}
                        />
                      </td>
                      <td/>
                    </tr>
                    </thead>
                    <tbody>
                    {element.shipperInfos.map((info, index) => {
                      return (
                        <tr key={index}>
                          <td className={classes.formControl}>{info.name}</td>
                          <td className={classes.formControl}>{info.order}</td>
                          <td>
                            <IconButton
                              className={classes.buttonRight}
                              color="primary"
                              onClick={() => deleteShipperFromShipperInfos(info.name)}
                              title={t('deleteShipperFromZone')}
                            >
                              <DeleteRowIcon className={classes.redIcon}/>
                            </IconButton>
                          </td>
                        </tr>
                      );
                    })}
                    {addShipperOpen
                      ?
                      <tr>
                        <td>
                          <FormControl className={classes.formControl}>
                            <InputLabel
                              htmlFor="shipper"
                              required
                            >{t('shipper')}</InputLabel>
                            <Select
                              error={!shipperToAdd.name}
                              input={<Input id="shipper"/>}
                              onChange={(event) => {
                                setAlreadyUsedOrders(calculateAlreadyUsedOrderNumbersForBoxZoneByShipperName(event.target.value));
                                setShipperToAdd({
                                  ...shipperToAdd,
                                  name: event.target.value,
                                  shortName: shipperOptions[event.target.value]
                                });
                              }}
                              value={shipperToAdd.name}
                            >
                              {Object.keys(shipperOptions).filter(shipperName => !element.shipperInfos.map(info => info.name).includes(shipperName)).map((shipperName, index) => {
                                return (
                                  !ShipperAllowedOnHubService.checkShipperAllowedDayString(hubCarrierData, selectedCarrierName, selectedDayOfWeek, shipperName) ?
                                    <Tooltip title={t('shipperNotAllowed')}>
                                      <div>
                                        <MenuItem
                                          disabled
                                          key={index}
                                          value={shipperName}
                                        >
                                          <Typography
                                            className={classes.alignText}
                                            variant={'body2'}
                                          >

                                            <WarnIcon className={classes.dangerColor}/>
                                            &nbsp;{shipperName}
                                          </Typography>
                                        </MenuItem>
                                      </div>
                                    </Tooltip>
                                    :
                                    <MenuItem
                                      key={index}
                                      value={shipperName}
                                    >
                                      <Typography
                                        className={classes.alignText}
                                        variant={'body2'}
                                      >
                                        {shipperName}
                                      </Typography>
                                    </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </td>
                        <td>
                          <FormControl className={classes.formControl}>
                            <InputLabel
                              htmlFor="microHubElementOrder"
                              required
                            >{t('microHubElementOrder')}</InputLabel>
                            <Input
                              error={isNaN(parseInt(shipperToAdd.order)) || parseInt(shipperToAdd.order) <= 0 || alreadyUsedOrders.includes(parseInt(shipperToAdd.order))}
                              id="microHubElementOrder"
                              onChange={(event) => {
                                setShipperToAdd({
                                  ...shipperToAdd,
                                  order: isNaN(parseInt(event.target.value)) ? event.target.value : parseInt(event.target.value)
                                });
                              }}
                              onClick={event => event.target.select()}
                              onWheel={event => event.target.blur()}
                              type="number"
                              value={shipperToAdd.order}
                            />
                          </FormControl>
                        </td>
                        <td>
                          <IconButton
                            className={classes.buttonRight}
                            color="primary"
                            disabled={isNaN(parseInt(shipperToAdd.order)) || parseInt(shipperToAdd.order) <= 0 || !shipperToAdd.name || alreadyUsedOrders.includes(parseInt(shipperToAdd.order))}
                            onClick={addShipperToShipperInfos}
                            title={t('addEditedShipperToZoneIconTitle')}
                          >
                            <AddRowIcon/>
                          </IconButton>
                        </td>
                      </tr>
                      :
                      <tr>
                        <td>
                          <IconButton
                            className={classes.buttonRight}
                            color="primary"
                            onClick={() => setAddShipperOpen(true)}
                            title={t('addShipperToZone')}
                          >
                            <AddRowIcon/>
                          </IconButton>
                        </td>
                      </tr>
                    }
                    </tbody>
                  </table>
                  {alreadyUsedOrders && alreadyUsedOrders.length > 0 &&
                  <div>
                    <Typography>
                      <WarnIcon className={classes.warningColor}/>&nbsp;{`${t('alreadyUsedOrders')}: ${alreadyUsedOrders.join(', ')}`}
                    </Typography>
                  </div>
                  }
                </div>
              </form>
            );
          case MicroHubElementTypes.beverageInsertZone:
            return (
              <form className={classes.container}>
                <div className={classes.rowWrapper}>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      htmlFor="microHubElementWidth"
                      required
                    >{t('microHubElementWidth')}</InputLabel>
                    <Input
                      error={isNaN(element.width) || parseInt(element.width) < 25 || parseInt(element.width) > 15000}
                      id="microHubElementWidth"
                      onChange={(event) => {
                        setElement({
                          ...element,
                          width: isNaN(parseInt(event.target.value)) ? event.target.value : parseInt(event.target.value)
                        });
                      }}
                      onClick={event => event.target.select()}
                      onWheel={event => event.target.blur()}
                      type="number"
                      value={element.width}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      htmlFor="microHubElementDepth"
                      required
                    >{t('microHubElementDepth')}</InputLabel>
                    <Input
                      error={isNaN(element.depth) || parseInt(element.depth) < 25 || parseInt(element.depth) > 15000}
                      id="microHubElementDepth"
                      onChange={(event) => {
                        setElement({
                          ...element,
                          depth: isNaN(parseInt(event.target.value)) ? event.target.value : parseInt(event.target.value)
                        });
                      }}
                      onClick={event => event.target.select()}
                      onWheel={event => event.target.blur()}
                      type="number"
                      value={element.depth}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      htmlFor="microHubElementOrder"
                      required
                    >
                      {t('microHubElementOrder')}
                      &nbsp;
                      <InfoIcon
                        className={classes.infoIcon}
                        descriptionKey={'10'}
                      />
                    </InputLabel>
                    <Input
                      error={isNaN(parseInt(element.order)) || parseInt(element.order) <= 0 || alreadyUsedOrders.includes(parseInt(element.order))}
                      id="microHubElementOrder"
                      onChange={(event) => {
                        setElement({
                          ...element,
                          order: isNaN(parseInt(event.target.value)) ? event.target.value : parseInt(event.target.value)
                        });
                      }}
                      onClick={event => event.target.select()}
                      onWheel={event => event.target.blur()}
                      type="number"
                      value={element.order}
                    />
                  </FormControl>
                </div>
                {alreadyUsedOrders && alreadyUsedOrders.length > 0 &&
                <div className={classNames(classes.rowWrapper, classes.rowWrapperNoMarginTop)}>
                  <Typography>
                    <WarnIcon className={classes.warningColor}/>&nbsp;{`${t('alreadyUsedOrders')}: ${alreadyUsedOrders.join(', ')}`}
                  </Typography>
                </div>
                }
              </form>
            );
          case MicroHubElementTypes.blockerZone:
            return (
              <form className={classes.container}>
                <div className={classes.rowWrapper}>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      htmlFor="microHubElementWidth"
                      required
                    >{t('microHubElementWidth')}</InputLabel>
                    <Input
                      error={isNaN(element.width) || parseInt(element.width) < 25 || parseInt(element.width) > 15000}
                      id="microHubElementWidth"
                      onChange={(event) => {
                        setElement({
                          ...element,
                          width: isNaN(parseInt(event.target.value)) ? event.target.value : parseInt(event.target.value)
                        });
                      }}
                      onClick={event => event.target.select()}
                      onWheel={event => event.target.blur()}
                      type="number"
                      value={element.width}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      htmlFor="microHubElementDepth"
                      required
                    >{t('microHubElementDepth')}</InputLabel>
                    <Input
                      error={isNaN(element.depth) || parseInt(element.depth) < 25 || parseInt(element.depth) > 15000}
                      id="microHubElementDepth"
                      onChange={(event) => {
                        setElement({
                          ...element,
                          depth: isNaN(parseInt(event.target.value)) ? event.target.value : parseInt(event.target.value)
                        });
                      }}
                      onClick={event => event.target.select()}
                      onWheel={event => event.target.blur()}
                      type="number"
                      value={element.depth}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      htmlFor="microHubElementText"
                    >
                      {t('microHubElementText')}
                    </InputLabel>
                    <Input
                      id="microHubElementText"
                      onChange={(event) => {
                        setElement({
                          ...element,
                          text: event.target.value
                        });
                      }}
                      type="text"
                      value={element.text}
                    />
                  </FormControl>
                </div>
              </form>
            );
          case MicroHubElementTypes.wall:
            return (
              <form className={classes.container}>
                <div className={classes.rowWrapper}>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      htmlFor="microHubElementWallSize"
                      required
                    >{t('microHubElementWallSize')}</InputLabel>
                    <Input
                      error={isNaN(element.wallSize) || parseInt(element.wallSize) <= 25 || parseInt(element.wallSize) > 15000}
                      id="microHubElementWallSize"
                      onChange={(event) => {
                        setElement({
                          ...element,
                          wallSize: isNaN(parseInt(event.target.value)) ? event.target.value : parseInt(event.target.value)
                        });
                      }}
                      onClick={event => event.target.select()}
                      onWheel={event => event.target.blur()}
                      type="number"
                      value={element.wallSize}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      htmlFor="microHubElementOrientation"
                      required
                    >{t('microHubElementOrientation')}</InputLabel>
                    <Select
                      input={<Input id="microHubElementOrientation"/>}
                      onChange={(event) => setElement({...element, orientation: event.target.value})}
                      value={element.orientation}
                    >
                      {Object.getOwnPropertyNames(DoorOrientations).map(propName => DoorOrientations[propName]).map((orientation, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={orientation}
                          >
                            {t(getOrientationString(orientation))}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </form>
            );
          default :
            return (
              <div/>
            );
        }
      })()
      }
    </Fragment>
  );
}

MicroHubPlanElementEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
  elementType: PropTypes.string.isRequired,
  hubData: PropTypes.object.isRequired,
  setElement: PropTypes.func.isRequired,
  setShipperToAdd: PropTypes.func.isRequired,
  shipperOptions: PropTypes.object.isRequired,
  shipperToAdd: PropTypes.object.isRequired,
  selectedDayOfWeek: PropTypes.string.isRequired,
  selectedCarrierName: PropTypes.string.isRequired,
  hubCarrierData: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(MicroHubPlanElementEdit);
