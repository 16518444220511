import React, {useEffect, useState} from 'react'
import {Button, FormControl, Input, InputLabel, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {TemplateStopService} from '../../../../services/backend/templateStopService';
import {KeyboardTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {DropdownSelect} from '../../../index';
import {getTemplateStopTypeString, TemplateStopType} from '../../../../services/enums/TemplateStopType';

function TemplateStopEditShipper(props) {
  const {
    classes,
    className,
    editedTemplateStop,
    editedRelatedTemplateStops,
    t,
    setEditedTemplateStop,
    setEditedRelatedTemplateStops,
  } = props;

  const rootClassName = classNames(classes.root, className);

  const [templateStopsPickup, setTemplateStopsPickup] = useState([]);
  const [templateStopsDelivery, setTemplateStopsDelivery] = useState([]);

  useEffect(() => {
    TemplateStopService.getTemplateStopsWithFilter().then((data) => {
      const templateStopsPickup = data.filter((templateStop) => templateStop.stopType === TemplateStopType.Pickup);
      const templateStopsDelivery = data.filter((templateStop) => templateStop.stopType === TemplateStopType.Delivery);
      setTemplateStopsPickup(templateStopsPickup);
      setTemplateStopsDelivery(templateStopsDelivery);
    });
  }, []);

  const addRelatedTemplateStop = () => {
    setEditedRelatedTemplateStops([...editedRelatedTemplateStops, {id: ''}]);
  };

  const removeRelatedTemplateStop = (id) => {
    setEditedRelatedTemplateStops(editedRelatedTemplateStops.filter(relatedTemplateStop => relatedTemplateStop.id !== id));
  };

  function onAddressChange() {
    return (event, key) => {
      let newAddress = {...editedTemplateStop.address};
      newAddress[key] = event.target.value;
      setEditedTemplateStop({
        ...editedTemplateStop, address: newAddress
      });
    };
  }

  return (<div className={rootClassName}>
      <form>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <label htmlFor="customerData"><strong>{t('customerData')}</strong></label>
          <div id="customerData" className={classes.rowWrapper}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="name">{t('name')}</InputLabel>
              <Input
                id="name"
                onChange={(event) => {
                  setEditedTemplateStop({...editedTemplateStop, name: event.target.value})
                }}
                value={editedTemplateStop.name ?? ''}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel
                htmlFor="streetAndNumber"
                required
              >{t('streetAndNumber')}</InputLabel>
              <Input
                error={!editedTemplateStop.address.streetAndNumber}
                id="houseNumber"
                onChange={(event) => onAddressChange(event, 'streetAndNumber')}
                required
                value={editedTemplateStop.address.streetAndNumber ?? ''}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel
                htmlFor="zipcode"
                required
              >{t('zipcode')}</InputLabel>
              <Input
                error={!editedTemplateStop.address.zipcode}
                id="zipcode"
                onChange={(event) => onAddressChange(event, 'zipcode')}
                required
                value={editedTemplateStop.address.zipcode ?? ''}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel
                htmlFor="city"
                required
              >{t('city')}</InputLabel>
              <Input
                error={!editedTemplateStop.address.city}
                id="city"
                onChange={(event) => onAddressChange(event, 'city')}
                required
                value={editedTemplateStop.address.city ?? ''}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel
                htmlFor="country"
                required
              >{t('country')}</InputLabel>
              <Input
                error={!editedTemplateStop.address.country}
                id="country"
                onChange={(event) => onAddressChange(event, 'country')}
                required
                value={editedTemplateStop.address.country}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="customerId">{t('customerId')}</InputLabel>
              <Input
                id="customerId"
                onChange={(event) => {
                  setEditedTemplateStop({...editedTemplateStop, customerId: event.target.value})
                }}
                value={editedTemplateStop.customerId ?? ''}
              />
            </FormControl>
          </div>
          <div className={classes.rowWrapper}>
            <FormControl className={classes.formControl}>
              <KeyboardTimePicker
                cancelLabel={t('dialogCancel')}
                id="deliveryWindowFrom"
                label={t('deliveryWindowFrom')}
                marging="normal"
                ampm={false}
                onChange={(event) => {
                  setEditedTemplateStop({
                    ...editedTemplateStop, deliveryWindow: {
                      from: new Date(event), to: editedTemplateStop.deliveryWindow?.to
                    }
                  })
                }}
                value={editedTemplateStop.deliveryWindow?.from ? editedTemplateStop.deliveryWindow.from : null}
              />
              <KeyboardTimePicker
                cancelLabel={t('dialogCancel')}
                id="deliveryWindowTo"
                label={t('deliveryWindowTo')}
                marging="normal"
                ampm={false}
                error={new Date(editedTemplateStop.deliveryWindow?.to).getTime() < new Date(editedTemplateStop.deliveryWindow?.from).getTime()}
                onChange={(event) => {
                  setEditedTemplateStop({
                    ...editedTemplateStop, deliveryWindow: {
                      from: editedTemplateStop.deliveryWindow?.from, to: new Date(event)
                    }
                  })
                }}
                value={editedTemplateStop.deliveryWindow?.to ? editedTemplateStop.deliveryWindow.to : null}
              />
            </FormControl>
          </div>
          <div className={classes.rowWrapper}>
            <FormControl className={classes.formControl}>
              <label htmlFor="customerInfo"><strong>{t('customerInfo')}</strong></label>
              <textarea
                className={classes.textarea}
                cols={'70'}
                id="customerInfo"
                onChange={(event) => setEditedTemplateStop({...editedTemplateStop, customerInfo: event.target.value})}
                rows={'7'}
                value={editedTemplateStop.customerInfo ? editedTemplateStop.customerInfo : ''}
              />
            </FormControl>
          </div>
          <label htmlFor="pickupDeliveryRelation"><strong>{t('pickupDeliveryRelation')}</strong></label>
          <div id="pickupDeliveryRelation">
            {editedRelatedTemplateStops.map((relatedTemplateStop, index) => (
              <div className={classes.rowWrapper} key={index}>
                <FormControl className={classes.formControl}>
                  <DropdownSelect
                    hideOnEmptyOptions
                    required
                    name={t(getTemplateStopTypeString(editedTemplateStop.stopType === TemplateStopType.Delivery ? TemplateStopType.Pickup : TemplateStopType.Delivery))}
                    options={editedTemplateStop.stopType === TemplateStopType.Pickup ? templateStopsDelivery.map((templateStop) => {
                      return {value: templateStop.id, name: templateStop.name};
                    }) : templateStopsPickup.map((templateStop) => {
                      return {value: templateStop.id, name: templateStop.name};
                    })}
                    setValue={(value) => {
                      const newRelatedTemplateStops = [...editedRelatedTemplateStops];
                      if (editedTemplateStop.stopType === TemplateStopType.Pickup) {
                        newRelatedTemplateStops[index] = templateStopsDelivery.find((templateStop) => templateStop.id === value);
                      } else {
                        newRelatedTemplateStops[index] = templateStopsPickup.find((templateStop) => templateStop.id === value);
                      }
                      setEditedRelatedTemplateStops(newRelatedTemplateStops);
                    }}
                    sort
                    value={relatedTemplateStop.id}
                  />
                  <Button className={classes.formControl}
                          onClick={() => removeRelatedTemplateStop(relatedTemplateStop.id)}>{t('remove')}
                  </Button>
                </FormControl>
              </div>))}
            <Button onClick={addRelatedTemplateStop}>{t('add')}</Button>
          </div>
        </MuiPickersUtilsProvider>
      </form>
    </div>
  );
}


TemplateStopEditShipper.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  editedTemplateStop: PropTypes.object.isRequired,
  editedRelatedTemplateStops: PropTypes.array.isRequired,
  i18n: PropTypes.object.isRequired,
  setEditedTemplateStop: PropTypes.func.isRequired,
  setEditedRelatedTemplateStops: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TemplateStopEditShipper);
