export default theme => ({
  root: {
    padding: theme.spacing(3)
  },
  table: {
    border: `1px solid ${theme.palette.border}`
  },
  filter: {
    marginTop: '8px',
    marginBottom: '8px',
  },
  warning: {
    color: theme.palette.warning.main
  },
  button: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2)
  },
  spacing: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2)
  }
});
