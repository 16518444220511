export const EmptyType = {
  Other: 1,
  TrackedBox: 2
};

export function getEmptyTypeString(type) {
  switch (type) {
    case EmptyType.Other:
      return 'emptyTypeOther';
    case EmptyType.TrackedBox:
      return 'emptyTypeTrackedBox';
    default:
      return 'emptyTypeUnknown'
  }
}
