import React, {Fragment, useState} from 'react'
import {Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PreviewTourTableRow from '../PreviewTourTableRow';
import {KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon} from '@material-ui/icons';
import PreviewStopTable from '../PreviewStopTable';
import {roundCapacityValues} from '../../../../../services/util/helperFuncs';


function PreviewTourTable(props) {

  const {classes, t, toursWithStops, className, freezeTour, changeStopAssignment, dataCarrierForChangeAssignment, deleteStop, stopsWithoutTour, microHubsData} = props;

  const rootClassName = classNames(className, classes.root);
  const [stopsWithoutTourOpen, setStopsWithoutTourOpen] = useState(false);

  return (
    <Table
      className={rootClassName}
      size="small"
    >
      <TableHead>
        <TableRow>
          <TableCell className={classes.alignRight}>{t('freeze')}</TableCell>
          <TableCell className={classes.alignRight}>{t('previewTourID')}</TableCell>
          <TableCell className={classes.alignRight}>{t('deliveryDate')}</TableCell>
          <TableCell className={classes.alignRight}>{t('stops')}</TableCell>
          <TableCell className={classes.alignRight}>{t('boxAmount')}</TableCell>
          <TableCell className={classes.alignRight}>{t('weight')}</TableCell>
          <TableCell className={classes.alignRight}>{t('volume')}</TableCell>
          <TableCell/>
        </TableRow>
      </TableHead>
      <TableBody>
        {toursWithStops.map((tourWithStops, index) => (
          <PreviewTourTableRow
            changeStopAssignment={changeStopAssignment}
            className={classes.previewTourTableRow}
            dataCarrierForChangeAssignment={dataCarrierForChangeAssignment}
            deleteStop={deleteStop}
            freezeTour={freezeTour}
            key={index}
            microHubsData={microHubsData}
            tourWithStops={tourWithStops}
          />
        ))}
        {stopsWithoutTour && stopsWithoutTour.length > 0 &&
        <Fragment>
          <TableRow
            className={classNames(classes.previewTourTableRow, classes.toursWithoutStopsRow)}
            hover
            selected={stopsWithoutTourOpen}
          >
            <TableCell
              className={classes.alignRight}
              component="th"
              scope="row"
            >
              <Typography>
                <strong>{t('previewStopsWithoutTour')}</strong>
              </Typography>
            </TableCell>
            <TableCell className={classes.alignRight}/>
            <TableCell className={classes.alignRight}/>
            <TableCell className={classes.alignRight}>{stopsWithoutTour.length}</TableCell>
            <TableCell className={classes.alignRight}>{roundCapacityValues(stopsWithoutTour.reduce((accumulator, stop) => accumulator + stop.stopCapacities.boxAmount, 0))}</TableCell>
            <TableCell className={classes.alignRight}>{roundCapacityValues(stopsWithoutTour.reduce((accumulator, stop) => accumulator + stop.stopCapacities.weight, 0))}</TableCell>
            <TableCell className={classes.alignRight}>{roundCapacityValues(stopsWithoutTour.reduce((accumulator, stop) => accumulator + stop.stopCapacities.volume, 0))}</TableCell>
            <TableCell>
              <IconButton
                onClick={() => setStopsWithoutTourOpen(!stopsWithoutTourOpen)}
                size="small"
              >
                {stopsWithoutTourOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
              </IconButton>
            </TableCell>
          </TableRow>
          < TableRow>
            < TableCell
              colSpan={8}
              style={{paddingBottom: 0, paddingTop: 0}}
            >
              <Collapse
                in={stopsWithoutTourOpen}
                timeout="auto"
                unmountOnExit
              >
                <Box margin={1}>
                  <PreviewStopTable
                    changeAssignment={changeStopAssignment}
                    className={classes.previewStopTable}
                    dataCarrierForChangeAssignment={dataCarrierForChangeAssignment}
                    deleteStop={deleteStop}
                    microHubsData={microHubsData}
                    stops={stopsWithoutTour}
                  />
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </Fragment>
        }
      </TableBody>
    </Table>
  );
}

PreviewTourTable.propTypes = {
  changeStopAssignment: PropTypes.func,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  dataCarrierForChangeAssignment: PropTypes.object,
  deleteStop: PropTypes.func,
  freezeTour: PropTypes.func,
  microHubsData: PropTypes.array.isRequired,
  stopsWithoutTour: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  toursWithStops: PropTypes.array.isRequired,
};

export default compose(withStyles(styles), withTranslation())(PreviewTourTable);
