export default theme => ({
  root: {},
  deleteButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.danger.main,
    '&:hover': {
      backgroundColor: theme.palette.danger.dark,
      color: theme.palette.common.white,
    }
  },
  elementDetail: {
    margin: theme.spacing(1),
  },
  controlButton: {
    display: 'block',
    width: '100%',
    textAlign: 'left',
    marginTop: theme.spacing(1)
  }
});
