import React, {Fragment, useState} from 'react';
import * as PropTypes from 'prop-types';
import {Drawer, withStyles, withWidth} from '@material-ui/core';
import compose from 'recompose/compose'
import styles from './style'
import {Footer, SidebarAdmin, SidebarCarrier, SidebarDriver, SidebarShipper, Topbar} from './components'
import classNames from 'classnames'
import {AuthService} from '../../services/auth/authService';
import {userRoles} from '../../services/auth/userRoles';


function SideNavLayout(props) {

  const {classes, children, title, width} = props;

  const isMobile = ['xs', 'sm', 'md', 'lg'].includes(width);
  const [isOpen, setIsOpen] = useState(!isMobile);


  const handleClose = () => {
    setIsOpen(false);
  };

  const handleToggleOpen = () => {
    setIsOpen(prev => !prev);
  };

  const shiftTopbar = isOpen && !isMobile;
  const shiftContent = isOpen && !isMobile;

  let sidebar;
  switch (AuthService.getUserRole()) {
    case userRoles.shipper:
      sidebar = (<SidebarShipper className={classes.sidebar}/>);
      break;
    case userRoles.carrier:
      sidebar = (<SidebarCarrier className={classes.sidebar}/>);
      break;
    case userRoles.administration:
      sidebar = (<SidebarAdmin className={classes.sidebar}/>);
      break;
    case userRoles.deliverer:
      sidebar = (<SidebarDriver className={classes.sidebar}/>);
      break;
    default:
      return (<div>Invalid Role</div>)
  }

  return (
    <Fragment>
      <Topbar
        className={classNames(classes.topbar, {
          [classes.topbarShift]: shiftTopbar
        })}
        isSidebarOpen={isOpen}
        onToggleSidebar={handleToggleOpen}
        title={title}
      />
      <Drawer
        anchor="left"
        classes={{paper: classes.drawerPaper}}
        onClose={handleClose}
        open={isOpen}
        variant={isMobile ? 'temporary' : 'persistent'}
      >
        {sidebar}
      </Drawer>
      <main
        className={classNames(classes.content, {
          [classes.contentShift]: shiftContent
        })}
      >
        {children}
        <Footer className={classes.footer}/>
      </main>
    </Fragment>
  );
}


SideNavLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default compose(withStyles(styles), withWidth())(SideNavLayout)
