import React, {Fragment, useEffect, useState} from 'react'
import {Tab, Tabs, withStyles} from '@material-ui/core';
import {SideNavLayout} from '../../../layouts';
import {DriverDetails, DriverTable, VehicleDetails, VehicleTable} from '../../../components'
import {displayModes} from '../../../services/enums/displayModes';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {ClientService} from '../../../services/client/clientService';
import {clientTypes} from '../../../services/client/clientTypes';
import DataLoadingWrapper from '../../../components/DataLoadingWrapper';
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import {MicroHubDetails, MicroHubFilter, MicroHubTable} from './components';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import styles from './styles'
import {VehicleTypeEnumHelperService, VehicleTypes} from '../../../services/enums/vehicleTypeEnumHelperService';
import omitDeep from 'omit-deep';
import {DriverQueryService} from '../../../services/backend/driverQueryService';
import {VehicleQueryService} from '../../../services/backend/vehicleQueryService';
import {MicroHubQueryService} from '../../../services/backend/microHubQueryService';
import {ShipperQueryService} from '../../../services/backend/shipperQueryService';
import useCarriersWithHubs from '../../../hooks/useCarriersWithHubs';

const clientDrivers = ClientService.getClient(clientTypes.driver);
const clientVehicle = ClientService.getClient(clientTypes.vehicle);
const clientMicroHub = ClientService.getClient(clientTypes.microHub);
const clientShipper = ClientService.getClient(clientTypes.shipper);

function CarrierOverview(props) {
  const {classes, t} = props;

  const [tabState, setTabState] = useState(0);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [selectedMicroHub, setSelectedMicroHub] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [filter, setFilter] = useState({
    microHubName: '',
    carrierName: ''
  });
  const {carriersWithHubs} = useCarriersWithHubs();

  const queryDrivers = DriverQueryService.getDriversByCarrierNameAndMicroHubListQuery(filter.carrierName, filter.microHubName ? [filter.microHubName] : []);
  const {loading: loadingDriver, error: errorDriver, data: dataDriver, refetch: refetchDriver} = useQuery(queryDrivers, {client: clientDrivers});

  const queryVehicles = VehicleQueryService.getVehiclesByCarrierNameAndMicroHubListQuery(filter.carrierName, filter.microHubName ? [filter.microHubName] : []);
  const {loading: loadingVehicle, error: errorVehicle, data: dataVehicle} = useQuery(queryVehicles, {client: clientVehicle});

  const queryMicroHubs = MicroHubQueryService.getHubsByHubNameListQuery(filter.microHubName ? [filter.microHubName] : []);
  const {loading: loadingMicroHub, error: errorMicroHub, data: dataMicroHub, refetch: refetchMicroHub} = useQuery(queryMicroHubs, {client: clientMicroHub});

  const queryShipperNames = ShipperQueryService.getAllShipperNames();
  const {data: shipperNames} = useQuery(queryShipperNames, {client: clientShipper});

  useEffect(() => {
    setSelectedMicroHub(null);
    setSelectedVehicle(null);
    setSelectedDriver(null);
  }, [filter]);

  const updateHubQuery = MicroHubQueryService.updatedMicroHubshipperRelationAndOperatingTimeAndDisponent();
  const [updateHub] = useMutation(updateHubQuery, {client: clientMicroHub});
  const updateHubFunc = (updatedHub) => {
    let formattedUpdatedHub = omitDeep(updatedHub, '__typename');
    let carrierRelations = formattedUpdatedHub.carriers.map(ctm => {
      const obj = {}
      obj.carrierName = ctm.carrier.name;
      obj.weekDayShipperLists = ctm.weekDayShipperLists;
      obj.disponent = ctm.disponent;
      return obj;
    });
    updateHub({variables: {microHubId: formattedUpdatedHub.id, carrierRelations: carrierRelations}}).then(() => {
      refetchMicroHub().then();
      setSelectedMicroHub(null);
    }, () => {
      alert(t('errorHubUpdate'));
    });
  };

  const updateDriverDonationButtonQuery = DriverQueryService.updateDriverDonationButton();
  const [updateDriverDonationButton] = useMutation(updateDriverDonationButtonQuery, {client: clientDrivers});
  const updateDriverDonationButtonFunc = (driverId, donationButton) => {
    updateDriverDonationButton({variables: {driverId: driverId, donationButton: donationButton}}).then((result) => {
      refetchDriver().then();
      setSelectedDriver(result?.data?.updateDriverDonationButton);
    }, (error) => {
      alert((error.toString()));
    });
  }

  const displayDriverTypes = (bicycle, car, truck) => {
    const array = [];
    if (bicycle) {
      array.push(t(VehicleTypeEnumHelperService.getVehicleTypeString(VehicleTypes.Bicycle)));
    }
    if (car) {
      array.push(t(VehicleTypeEnumHelperService.getVehicleTypeString(VehicleTypes.Car)));
    }
    if (truck) {
      array.push(t(VehicleTypeEnumHelperService.getVehicleTypeString(VehicleTypes.Truck)));
    }
    return array.join(', ');
  };

  return (
    <SideNavLayout title={t('carrierOverview')}>
      <AppBar
        className={classes.appBar}
        color={'secondary'}
        position={'relative'}
      >
        <Tabs
          onChange={(event, newValue) => {
            setTabState(newValue);
          }}
          value={tabState}
        >
          <Tab
            label={t('microHubs')}
          />
          <Tab
            label={t('driver')}
          />
          <Tab
            label={t('vehicles')}
          />
        </Tabs>
      </AppBar>
      <div className={classes.root}>
        <MicroHubFilter
          carriersWithMicroHubs={carriersWithHubs}
          className={''}
          filter={filter}
          setFilter={setFilter}
        />
        {(() => {
          switch (tabState) {
            case 0:
              return (
                <DataLoadingWrapper
                  error={errorMicroHub}
                  isLoading={loadingMicroHub}
                >
                  {dataMicroHub && dataMicroHub.microhubs &&
                  <Fragment>
                    <MicroHubTable
                      className={classes.content}
                      microHubs={dataMicroHub.microhubs.filter(hub => !filter.carrierName || hub.carriers.map(carrier => carrier.carrier.name).includes(filter.carrierName))}
                      selectedMicroHub={selectedMicroHub}
                      selectMicroHub={setSelectedMicroHub}
                    />
                    {selectedMicroHub &&
                    <MicroHubDetails
                      className={classes.content}
                      closeDetail={() => setSelectedMicroHub(null)}
                      microHub={selectedMicroHub}
                      shipperNames={shipperNames}
                      updateMicroHub={updateHubFunc}
                    />
                    }
                  </Fragment>
                  }
                </DataLoadingWrapper>
              );
            case 1:
              return (
                <DataLoadingWrapper
                  error={errorDriver}
                  isLoading={loadingDriver}
                >
                  {dataDriver && dataDriver.getDriversByCarrierNameAndMicroHubList &&
                  <Fragment>
                    <DriverTable
                      className={classes.content}
                      displayDriverTypes={displayDriverTypes}
                      drivers={dataDriver.getDriversByCarrierNameAndMicroHubList}
                      selectDriver={setSelectedDriver}
                      selectedDriver={selectedDriver}
                      tableDisplayMode={displayModes.admin}
                    />
                    {selectedDriver &&
                    <DriverDetails
                      className={classes.content}
                      closeDetail={() => setSelectedDriver(null)}
                      displayDriverTypes={displayDriverTypes}
                      driver={selectedDriver}
                      readonly
                      updateDonationButton={updateDriverDonationButtonFunc}
                    />
                    }
                  </Fragment>
                  }
                </DataLoadingWrapper>
              );
            case 2:
              return (
                <DataLoadingWrapper
                  error={errorVehicle}
                  isLoading={loadingVehicle}
                >
                  {dataVehicle && dataVehicle.getVehiclesByCarrierNameAndMicroHubList &&
                  <Fragment>
                    <VehicleTable
                      className={classes.content}
                      selectedVehicle={selectedVehicle}
                      selectVehicle={setSelectedVehicle}
                      tableDisplayMode={displayModes.admin}
                      vehicles={dataVehicle.getVehiclesByCarrierNameAndMicroHubList}
                    />
                    {selectedVehicle &&
                    <VehicleDetails
                      className={classes.content}
                      closeDetail={() => setSelectedVehicle(null)}
                      readonly
                      vehicle={selectedVehicle}
                    />
                    }
                  </Fragment>
                  }
                </DataLoadingWrapper>
              );
            default:
              return (<div>error</div>);
          }
        })()}
      </div>
    </SideNavLayout>
  );
}

CarrierOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(CarrierOverview);
