export default theme => ({
  root: {},
  controlButton: {
    display: 'block',
    width: '100%',
    textAlign: 'left',
    marginTop: theme.spacing(1)
  },
  masterButton: {
    float: 'right'
  },
  buttonGroupMember: {
    width: '50%'
  },
  controlButtonIcon: {
    verticalAlign: 'middle'
  }
});
