import React, {Fragment, useEffect, useState} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import {
  PortletContent,
  PortletHeader,
  TemplateStopInstanceEditAdmin,
  TemplateStopInstanceEditCarrier,
  TemplateStopInstanceEditShipper
} from 'components';
import {displayModes} from 'services/enums/displayModes';

function TemplateStopInstanceDetailEdit(props) {

  const {
    classes,
    className,
    editedTemplateStopInstance,
    setEditMode,
    t,
    updateTemplateStopInstance,
    setEditedTemplateStopInstance,
    editDisplayMode
  } = props;

  const rootClassName = classNames(classes.root, className);

  const [readyForUpdate, setReadyForUpdate] = useState(false);

  useEffect(() => {
    if (readyForUpdate) {
      updateTemplateStopInstance();
    }
  }, [readyForUpdate, updateTemplateStopInstance])

  const onUpdate = () => {
    setEditedTemplateStopInstance({
      ...editedTemplateStopInstance
    });
    // handle update via this callback, since the setEditedTemplateStopInstance called above has to be processed before the update is written!
    setReadyForUpdate(true);
  };

  const onCancel = () => {
    setEditMode(false)
  }


  const isSaveDisabled = () => {
    return (
      !editedTemplateStopInstance.address.streetAndNumber || !editedTemplateStopInstance.address.city || !editedTemplateStopInstance.address.zipcode ||
      !editedTemplateStopInstance.address.country || !editedTemplateStopInstance.name || !editedTemplateStopInstance.shipperName ||
      new Date(editedTemplateStopInstance.deliveryWindow?.to).getTime() < new Date(editedTemplateStopInstance.deliveryWindow?.from).getTime()
    );
  }

  return (
    <Fragment>
      <PortletHeader className={rootClassName}>
        <Fragment>
          <div><strong>{t('templateStopInstanceId')}: {editedTemplateStopInstance.id}</strong></div>
          <div>
            <Button
              className={classes.button}
              color="default"
              onClick={onCancel}
              title={t('templateStopInstanceCancel')}
              variant="contained"
            >
              <CancelIcon/>&nbsp;{t('cancel')}
            </Button>
            <Button
              className={classes.button}
              color="primary"
              disabled={isSaveDisabled()}
              onClick={onUpdate}
              title={t('templateStopInstanceSave')}
              variant="contained"
            >
              <SaveIcon/>
            </Button>
          </div>
        </Fragment>
      </PortletHeader>
      <PortletContent noPadding>
        {(() => {
          switch (editDisplayMode) {
            case displayModes.admin:
              return (
                <TemplateStopInstanceEditAdmin
                  className={classes.wrapper}
                  editedTemplateStopInstance={editedTemplateStopInstance}
                  setEditedTemplateStopInstance={setEditedTemplateStopInstance}
                />
              );
            case displayModes.carrier:
              return (
                <TemplateStopInstanceEditCarrier
                  className={classes.wrapper}
                  editedTemplateStopInstance={editedTemplateStopInstance}
                  setEditedTemplateStopInstance={setEditedTemplateStopInstance}
                />
              );
            case displayModes.shipper:
              return (
                <TemplateStopInstanceEditShipper
                  className={classes.wrapper}
                  editedTemplateStopInstance={editedTemplateStopInstance}
                  setEditedTemplateStopInstance={setEditedTemplateStopInstance}
                />
              );
            default:
              return <></>
          }
        })()
        }
      </PortletContent>
    </Fragment>
  );
}

TemplateStopInstanceDetailEdit.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  editDisplayMode: PropTypes.string.isRequired,
  editedTemplateStopInstance: PropTypes.object.isRequired,
  setEditMode: PropTypes.func.isRequired,
  setEditedTemplateStopInstance: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateTemplateStopInstance: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TemplateStopInstanceDetailEdit);
