export default theme => ({
  root: {
    padding: theme.spacing(3)
  },
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: '200px',
  },
  appBar: {
    left: '2px',
    width: 'calc(100% - 2px)',
    zIndex: 'unset'
  },
});
