export default theme => ({
  root: {
    padding: theme.spacing(1)
  },
  buttonRight: {
    marginTop: theme.spacing(1),
  },
  helpWrapper: {
    float: 'right',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  tourMap: {
    minWidth: '100%',
    minHeight: 'calc(-195px + 100vh)',
    border: '1px solid lightgrey',
    borderRadius: '3px'
  },
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    border: `1px solid ${theme.palette.border}`
  },
  problemNotificationButtonContainer: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  problemNotificationButton: {
    float: 'right',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  }
});
