import React, {Fragment} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {Group, Layer, Rect, Stage, Text, useStrictMode} from 'react-konva';
import {
  calculateBeverageInsertZoneText,
  calculateBlockerZoneText,
  calculateBoxZoneText,
  DoorOrientations,
  Offset
} from '../../../../../services/hubAdministrationHelper/hubAdministrationHelper';
import classNames from 'classnames'

useStrictMode(true);

function MicroHubPlanCanvas(props) {

  const {classes, className, t, microHubPlan, selectedZoneInfos, setSelectedZoneInfos, zonesWithToursAndStops, shipperFilter, displayBeverageInsertZones} = props;
  const rootClassName = classNames(classes.root, className);

  return (
    <Stage
      className={rootClassName}
      height={microHubPlan.hubBase.depth + Offset.x * 2}
      id={'stage'}
      width={microHubPlan.hubBase.width + Offset.y * 2}
    >
      <Layer>
        {
          microHubPlan.hubBase &&
          <Fragment>
            <Rect
              height={microHubPlan.hubBase.depth}
              name={'hubBase'}
              stroke={'black'}
              strokeWidth={10}
              width={microHubPlan.hubBase.width}
              x={Offset.x}
              y={Offset.y}
            />
            {
              microHubPlan.hubBase.door &&
              <Rect
                height={microHubPlan.hubBase.door.orientation === DoorOrientations.vertical ? microHubPlan.hubBase.door.doorSize : 10}
                name={'hubDoor'}
                stroke={'red'}
                strokeWidth={10}
                width={microHubPlan.hubBase.door.orientation === DoorOrientations.horizontal ? microHubPlan.hubBase.door.doorSize : 10}
                x={microHubPlan.hubBase.door.positionX}
                y={microHubPlan.hubBase.door.positionY}
              />
            }
          </Fragment>
        }
      </Layer>
      <Layer>
        {
          microHubPlan.beverageInsertZones && microHubPlan.beverageInsertZones.map((zone, index) => {
            //center text and calculate how much of the text and how big can be rendered
            const [text, heightOfWrittenText, fontSize] = calculateBeverageInsertZoneText(zone.width, zone.depth, zone.order, t);
            return (
              <Group
                height={zone.depth}
                key={index}
                name={zone.tmpId}
                width={zone.width}
                x={zone.positionX}
                y={zone.positionY}
              >
                <Rect
                  fill={displayBeverageInsertZones ? 'black' : 'lightgrey'}
                  height={zone.depth}
                  width={zone.width}
                />
                <Text
                  align={'center'}
                  fill={'white'}
                  fontSize={fontSize}
                  text={displayBeverageInsertZones ? text : ''}
                  width={zone.width}
                  y={(zone.depth - heightOfWrittenText) / 2}
                />
              </Group>
            )
          })
        }
      </Layer>
      <Layer>
        {
          microHubPlan.boxZones && microHubPlan.boxZones.map((zone, index) => {
            //center text and calculate how much of the text can be rendered
            const [text, heightOfWrittenText, fontSize] = calculateBoxZoneText(zone.width - 10, zone.depth, zone.shipperInfos.filter(info => !shipperFilter || shipperFilter === info.name));
            //calculate content of zone
            const zoneNameParts = zone.shipperInfos.map(info => `${info.shortName}-${info.order}`);
            const toursAndStopsOnZone = zonesWithToursAndStops ? zoneNameParts.map(zoneName => {
              const zoneData = zonesWithToursAndStops[zoneName];
              return zoneData && zoneData.toursWithStops ? zoneData.toursWithStops : [];
            }).flat() : [];
            const stopsWithoutTourOnZone = zonesWithToursAndStops ? zoneNameParts.map(zoneName => {
              const zoneData = zonesWithToursAndStops[zoneName];
              return zoneData && zoneData.stopsWithoutTour ? zoneData.stopsWithoutTour : [];
            }).flat() : [];
            //other zone parameter
            const heightPerTour = zone.depth / Math.max(toursAndStopsOnZone.length, 1);
            const zoneNotEmpty = toursAndStopsOnZone.length > 0 || stopsWithoutTourOnZone.length > 0;
            const zoneSelected = selectedZoneInfos && selectedZoneInfos.zone.tmpId === zone.tmpId;
            return (
              <Group
                key={index}
                name={zone.tmpId}
                onClick={() => zoneNotEmpty ? setSelectedZoneInfos({
                  zone: zone,
                  toursWithStops: toursAndStopsOnZone,
                  stopsWithoutTour: stopsWithoutTourOnZone
                }) : {}}
                x={zone.positionX}
                y={zone.positionY}
              >
                <Rect
                  fill={zoneNotEmpty ? zoneSelected ? 'green' : 'black' : 'lightgrey'}
                  height={zone.depth}
                  width={zone.width}
                />
                <Text
                  align={'center'}
                  fill={'white'}
                  fontSize={fontSize}
                  text={zoneNotEmpty ? text : ''}
                  width={zoneNotEmpty ? zone.width - 10 : zone.width}
                  y={(zone.depth - heightOfWrittenText) / 2}
                  x={zoneNotEmpty ? 10 : 0}
                />
                {toursAndStopsOnZone.map((tour, index) =>
                  <Rect
                    fill={tour.color}
                    height={heightPerTour}
                    key={index}
                    width={10}
                    y={index * heightPerTour}
                  />
                )}
              </Group>
            )
          })
        }
      </Layer>
      <Layer>
        {
          microHubPlan.blockerZones && microHubPlan.blockerZones.map((zone, index) => {
            //center text and calculate how much of the text can be rendered
            const [text, heightOfWrittenText, fontSize] = calculateBlockerZoneText(zone.width, zone.depth, zone.text);
            return (
              <Group
                key={index}
                name={zone.tmpId}
                x={zone.positionX}
                y={zone.positionY}
              >
                <Rect
                  fill={'grey'}
                  height={zone.depth}
                  width={zone.width}
                />
                <Text
                  align={'center'}
                  fill={'white'}
                  fontSize={fontSize}
                  text={text}
                  width={zone.width}
                  y={(zone.depth - heightOfWrittenText) / 2}
                />
              </Group>
            )
          })
        }
      </Layer>
      <Layer>
        {
          microHubPlan.walls && microHubPlan.walls.map((wall, index) => {
            return (
              <Rect
                height={wall.orientation === DoorOrientations.vertical ? wall.wallSize : 10}
                key={index}
                name={wall.tmpId}
                stroke={'black'}
                strokeWidth={10}
                width={wall.orientation === DoorOrientations.horizontal ? wall.wallSize : 10}
                x={wall.positionX}
                y={wall.positionY}
              />
            );
          })
        }
      </Layer>
    </Stage>
  );
}

MicroHubPlanCanvas.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  displayBeverageInsertZones: PropTypes.bool.isRequired,
  microHubPlan: PropTypes.object.isRequired,
  selectedZoneInfos: PropTypes.object,
  setSelectedZoneInfos: PropTypes.func.isRequired,
  shipperFilter: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  zonesWithToursAndStops: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withTranslation())(MicroHubPlanCanvas);
