export default theme => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: '200px',
    width: '200px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
});
