import {ShipperLabel, StopIdLabel, TourIdLabel} from '../../index';
import {DateService} from '../../../services/util/DateService';
import StopStatusLabel from '../StopStatusLabel';
import React, {Fragment} from 'react';
import {Button} from '@material-ui/core';
import {
  DeleteForever as DeleteIcon,
  DeleteSweep as DeleteCarrierIcon,
  Edit as ChangeAssignmentIcon
} from '@material-ui/icons';
import classNames from 'classnames';

const Columns = {
  tourStopId: {
    name: 'tourStopId',
    header: ({t}) => t('tourStopId'),
    body: ({stop}) => <StopIdLabel stop={stop}/>
  },
  tourId: {
    name: 'tourId',
    header: ({t}) => t('tourId'),
    body: ({stop}) => <TourIdLabel tour={{tourId: stop?.tourId}}/>
  },
  planedDeliveryDate: {
    name: 'planedDeliveryDate',
    header: ({t}) => t('planedDeliveryDate'),
    body: ({stop}) => DateService.displayDate(stop?.planedDeliveryDateCarrier ? stop?.planedDeliveryDateCarrier : stop?.planedDeliveryDateShipper)
  },
  carrier: {
    name: 'carrier',
    header: ({t}) => t('carrier'),
    body: ({stop}) => stop?.assignedCarrierName
  },
  microHub: {
    name: 'microHub',
    header: ({t}) => t('microHub'),
    body: ({stop}) => stop?.assignedMicroHubName
  },
  shipper: {
    name: 'shipper',
    header: ({t}) => t('shipper'),
    body: ({stop}) => <ShipperLabel stop={stop}/>
  },
  lastName: {
    name: 'lastName',
    header: ({t}) => t('lastName'),
    body: ({stop}) => stop?.lastName
  },
  streetAndNumber: {
    name: 'streetAndNumber',
    header: ({t}) => t('streetAndNumber'),
    body: ({stop}) => stop?.address.streetAndNumber
  },
  zipcode: {
    name: 'zipcode',
    header: ({t}) => t('zipcode'),
    body: ({stop}) => stop?.address.zipcode
  },
  estimatedDeliveryWindow: {
    name: 'estimatedDeliveryWindow',
    header: ({t}) => t('estimatedDeliveryWindow'),
    body: ({stop}) => `${stop?.estimatedDeliveryWindow && DateService.displayTime(stop?.estimatedDeliveryWindow.from)} - ${stop?.estimatedDeliveryWindow && DateService.displayTime(stop?.estimatedDeliveryWindow.to)}`
  },
  stopStatus: {
    name: 'stopStatus',
    header: ({t}) => t('stopStatus'),
    body: ({stop}) => <StopStatusLabel stop={stop}/>
  },
  deliveryTime: {
    name: 'deliveryTime',
    header: ({t}) => t('deliveryTime'),
    body: ({stop}) => stop.deliveryTime ?? '-'
  },
  deliveryTimeWindowShipper: {
    name: 'deliveryTimeWindowShipper',
    header: ({t}) => t('deliveryTimeWindowShipper'),
    body: ({stop}) => stop.deliveryWindowShipper ? `${DateService.displayTime(stop?.deliveryWindowShipper?.from)} - ${DateService.displayTime(stop?.deliveryWindowShipper?.to)}` : '-'
  },
  options: {
    name: 'options',
    header: ({t}) => t('options'),
    body: ({
      stop,
      classes,
      t,
      changeAssignment,
      setChangeAssignmentDialogState,
      changeAssignmentDialogState,
      deleteCarrierFromStop,
      setDeleteCarrierFromStopDialogState,
      deleteCarrierFromStopDialogState,
      deleteStop,
      setDeleteStopDialogState,
      deleteStopDialogState
    }) => <Fragment>
      {changeAssignment &&
        <Button
          className={classes.tableButton}
          color="secondary"
          onClick={(event) => {
            event.stopPropagation();
            setChangeAssignmentDialogState({...changeAssignmentDialogState, open: true, stop: stop})
          }}
          title={t('changeStopAssignment')}
        >
          <ChangeAssignmentIcon/>
        </Button>
      }
      {deleteCarrierFromStop &&
        <Button
          className={classNames(classes.tableButton, classes.warningButton)}
          color="primary"
          disabled={!stop.assignedCarrierName}
          onClick={(event) => {
            event.stopPropagation();
            setDeleteCarrierFromStopDialogState({
              ...deleteCarrierFromStopDialogState,
              open: true,
              stop: stop
            })
          }}
          title={t('stopRemoveCarrier')}
        >
          <DeleteCarrierIcon/>
        </Button>
      }
      {deleteStop &&
        <Button
          className={classNames(classes.tableButton, classes.deleteButton)}
          onClick={(event) => {
            event.stopPropagation();
            setDeleteStopDialogState({...deleteStopDialogState, open: true, stop: stop})
          }}
          title={t('stopDelete')}
        >
          <DeleteIcon/>
        </Button>
      }
    </Fragment>
  }
}

export default Columns;