import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types';

function ImageWithFallback(props) {

  const {className, imageName, fallbackImageName, alt, ...rest} = props;

  const [src, setSrc] = useState(imageName);
  const [error, setError] = useState(false);

  useEffect(() => {
    setSrc(imageName);
    setError(false);
  }, [imageName])

  const onError = (e) => {
    if (!error) {
      e.target.onerror = null;
      setError(true);
      setSrc(fallbackImageName);
    }
  }

  return (
    <img
      className={className}
      {...rest}
      alt={alt}
      onError={(e) => onError(e)}
      src={`${process.env.PUBLIC_URL}/TrackingAssets/${src}`}
    />
  );
}

ImageWithFallback.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  fallbackImageName: PropTypes.string.isRequired,
  imageName: PropTypes.string,
};


export default ImageWithFallback;
