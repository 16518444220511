import React, {useState} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import ViewCarrier from './ViewCarrier';
import EditCarrier from './EditCarrier';
import Typography from '@material-ui/core/Typography';
import useCollapse from 'react-collapsed';
import classNames from 'classnames';
import {useMutation} from '@apollo/react-hooks';
import {ClientService} from '../../../../services/client/clientService';
import {clientTypes} from '../../../../services/client/clientTypes';
import {CarrierQueryService} from '../../../../services/backend/carrierQueryService';
import {Add as AddIcon} from '@material-ui/icons';
import {HubInfo} from '../../Microhub';

const clientCarrier = ClientService.getClient(clientTypes.carrier);


function CarrierProfile(props) {
  const {classes, t, carrier, className, addHubDialogOpen, refetchCarrier, readonly} = props;

  const {getCollapseProps, getToggleProps, isOpen, toggleOpen} = useCollapse({defaultOpen: false});

  const rootClassName = classNames(classes.root, className);

  const updateCarrierMutation = CarrierQueryService.updateCarrierById();
  const deleteCarrierMutation = CarrierQueryService.deleteCarrierById();
  const [updateCarrier] = useMutation(updateCarrierMutation, {client: clientCarrier});
  const [deleteCarrier] = useMutation(deleteCarrierMutation, {client: clientCarrier});

  const [editCarrierState, setEditCarrierState] = useState(false);

  const onEditClick = () => {
    setEditCarrierState(true);
  };

  const saveClick = (info, address, billingAddress, contact, disponent) => {
    setEditCarrierState(false);
    const locCarrier = {
      id: carrier.id,
      name: info.name,
      useAlgorithmForTourPlanning: info.useAlgorithmForTourPlanning,
      email: info.email,
      address: address,
      billingAddress: billingAddress,
      contact: contact,
      disponent: disponent,
    };
    updateCarrier({variables: {carrier: locCarrier}}).then(() => {
      refetchCarrier();
    }, () => {
      alert(t('errorUpdateCarrier'));
    });
  };

  const cancelClick = () => {
    setEditCarrierState(false);
  };

  const deleteClick = () => {
    setEditCarrierState(false);
    toggleOpen();
    deleteCarrier({variables: {carrierId: carrier.id}}).then(() => {
      refetchCarrier();
    }, () => {
      alert(t('errorDeleteCarrier'));
    });
  };

  return (
    <div className={rootClassName}>
      <div
        {...getToggleProps()}
        className={'noselect cursorPointer'}
      >
        <Typography variant="h3">
          {carrier.name}
          <i className="material-icons md-48">{isOpen ? 'expand_less' : 'expand_more'}</i>
        </Typography>
      </div>
      <div {...getCollapseProps()}>
        {!editCarrierState ?
          <ViewCarrier
            carrier={carrier}
            onEditClick={onEditClick}
            readonly={readonly}
          />
          :
          <EditCarrier
            cancelClick={cancelClick}
            carrier={carrier}
            deleteClick={deleteClick}
            saveClick={saveClick}
          />
        }
        {!readonly &&
        <Button
          color="primary"
          onClick={() => addHubDialogOpen(carrier.hubs.map(h => h.name), carrier.name)}
          variant="contained"
        >
          <AddIcon/>&nbsp;{t('addHub')}
        </Button>
        }
        {
          carrier.hubs.map((hub, index) => {
            return (
              <HubInfo
                carrierName={carrier.name}
                hubName={hub.name}
                key={index}
                readonly={Boolean(readonly)}
                refetchCarrier={refetchCarrier}
              />
            );
          })
        }
      </div>
    </div>
  );
}

CarrierProfile.propTypes = {
  addHubDialogOpen: PropTypes.func,
  carrier: PropTypes.object.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  readonly: PropTypes.bool,
  refetchCarrier: PropTypes.func,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(CarrierProfile);
