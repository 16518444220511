import React, {Fragment} from 'react'
import {Table, TableBody, TableHead, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {ProductType} from '../../../../services/enums/ProductType';
import ProductHeaderEdit from './Product/header';
import ProductBodyEdit from './Product/body';
import InsertHeaderEdit from './Insert/header';
import InsertBodyEdit from './Insert/body';
import ProductNewList from './Product/ProductNewList';
import InsertNewList from './Insert/InsertNewList';

function ProductTableEdit(props) {

  const {classes, className, productType, products, deleteProduct, addItem, productsToAdd, updateItem, otherProductsValid} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <Table
      className={rootClassName}
      size="small"
    >
      <TableHead>
        {(() => {
          switch (productType) {
            case ProductType.Box:
            case ProductType.BeverageBox:
            case ProductType.Other:
              return <ProductHeaderEdit/>;
            case ProductType.PackageInserts:
              return <InsertHeaderEdit/>;
            default :
              return <ProductHeaderEdit/>;
          }
        })()
        }
      </TableHead>
      <TableBody>
        <Fragment>
          {products.map((product, index) =>
            (() => {
              switch (productType) {
                case ProductType.Box:
                case ProductType.BeverageBox:
                case ProductType.Other:
                  return (
                    <ProductBodyEdit
                      deleteProduct={deleteProduct}
                      key={index}
                      product={product}
                    />
                  );
                case ProductType.PackageInserts:
                  return (
                    <InsertBodyEdit
                      deleteProduct={deleteProduct}
                      key={index}
                      product={product}
                    />
                  );
                default :
                  return (
                    <ProductBodyEdit
                      deleteProduct={deleteProduct}
                      key={index}
                      product={product}
                    />
                  );
              }
            })()
          )}
        </Fragment>
        <Fragment>
          {(() => {
            switch (productType) {
              case ProductType.Box:
              case ProductType.BeverageBox:
              case ProductType.Other:
                return (
                  <ProductNewList
                    addItem={addItem}
                    deleteProduct={deleteProduct}
                    otherProductsValid={otherProductsValid}
                    productsToAdd={productsToAdd}
                    updateItem={updateItem}
                  />
                );
              case ProductType.PackageInserts:
                return (
                  <InsertNewList
                    addItem={addItem}
                    deleteProduct={deleteProduct}
                    otherProductsValid={otherProductsValid}
                    productsToAdd={productsToAdd}
                    updateItem={updateItem}
                  />
                );
              default :
                return (
                  <ProductNewList
                    addItem={addItem}
                    deleteProduct={deleteProduct}
                    otherProductsValid={otherProductsValid}
                    productsToAdd={productsToAdd}
                    updateItem={updateItem}
                  />
                );
            }
          })()}
        </Fragment>
      </TableBody>
    </Table>
  );
}


ProductTableEdit.propTypes = {
  addItem: PropTypes.func.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deleteProduct: PropTypes.func.isRequired,
  otherProductsValid: PropTypes.bool.isRequired,
  productType: PropTypes.number.isRequired,
  products: PropTypes.array.isRequired,
  productsToAdd: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
}
;

export default compose(withStyles(styles), withTranslation())(ProductTableEdit);
