import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {SideNavLayout} from '../../../layouts';
import {TourService} from '../../../services/backend/tourService';
import {AuthService} from '../../../services/auth/authService';
import {StopStatus} from '../../../services/enums/StopStatus';
import {
  GeneralInfoIcon,
  LoadingIndicator,
  LoadingWrapper,
  Portlet,
  RefreshButton,
  TourAndStopMap,
  TourDetail,
  TourFilter,
  TourTable,
} from '../../../components';
import {useTourHub} from '../../../hooks/useTourHub';
import {
  stopMessageRelevantForTourFilter,
  tourMessageRelevantForFilter
} from '../../../services/util/signalRMessageHelper';
import {useStopHub} from '../../../hooks/useStopHub';
import {DatePickerDefaultValueContext, ProblemNotificationDialogContext} from '../../../context';
import {displayModes} from '../../../services/enums/displayModes';
import {Warning} from '@material-ui/icons';
import useLoadTourByIdCache from '../../../hooks/useLoadTourByIdCache';
import useMicroHubOptionsOfCarrier from '../../../hooks/useMicroHubOptionsOfCarrier';

function TourOverview(props) {
  const {classes, t} = props;
  const {fromDateDefault, toDateDefault, updateDefaultDate} = useContext(DatePickerDefaultValueContext);
  const {selectedTour, loadingSelectedTour, resetLoadTourByIdCache, getTourById, resetSelectedTour} = useLoadTourByIdCache();
  const [refresh, setRefresh] = useState(false);
  const [tours, setTours] = useState([]);
  const [notPlanedStops, setNotPlanedStops] = useState([]);
  const [filter, setFilter] = useState({
    carrierName: AuthService.getUserOrganization(),
    shipperName: '',
    microHubName: '',
    tourIds: [],
    tourStatus: '',
    fromDateTime: new Date(fromDateDefault.getFullYear(), fromDateDefault.getMonth(), fromDateDefault.getDate(), 6, 0, 0),
    toDateTime: new Date(toDateDefault.getFullYear(), toDateDefault.getMonth(), toDateDefault.getDate(), 19, 0, 0)
  });
  const {open: openProblemNotificationDialog} = useContext(ProblemNotificationDialogContext);
  useEffect(() => updateDefaultDate(filter.fromDateTime), [filter.fromDateTime, updateDefaultDate]);

  const [backendLoadingTours, setBackendLoadingTours] = useState(false);
  const [backendLoadingStops, setBackendLoadingStops] = useState(false);
  const [mapTabState, setMapTabState] = useState(undefined);
  const {microHubOptions, microHubData} = useMicroHubOptionsOfCarrier(AuthService.getUserOrganization(), true);

  useTourHub(useCallback(event => {
    if (tourMessageRelevantForFilter(event, filter)) {
      setRefresh(true);
    }
  }, [filter]));

  useStopHub(useCallback(event => {
    if (stopMessageRelevantForTourFilter(event, filter)) {
      setRefresh(true);
    }
  }, [filter]));

  const filterTours = (tours) => {
    let filtered = tours;
    if (filter.tourIds && filter.tourIds.length > 0) {
      filtered = filtered.filter(tour => filter.tourIds.includes(tour.tourId));
    }
    if (filter.tourStatus) {
      filtered = filtered.filter(tour => tour.tourStatus === filter.tourStatus);
    }
    return filtered;
  };

  const loadTours = useCallback(() => {
    setBackendLoadingTours(true);
    resetLoadTourByIdCache();
    TourService.getToursWithFilter(filter.carrierName, filter.shipperName, filter.microHubName, filter.fromDateTime, filter.toDateTime).then(response => response.json()).then(response => {
      setTours(response);
      setBackendLoadingTours(false);
      setRefresh(false);
    }, () => {
      alert(t('errorLoadingTours'));
      setBackendLoadingTours(false);
      setRefresh(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, t]);

  const loadStops = useCallback((noLoadingIndicator) => {
    if (!noLoadingIndicator) {
      setBackendLoadingStops(true);
    }
    TourService.getStopsWithFilterIncludeStoragePlaces(AuthService.getUserOrganization(), null, null, StopStatus.NotPlaned, null, filter.toDateTime)
      .then(response => response.json())
      .then(response => {
        setNotPlanedStops(response);
        if (!noLoadingIndicator) setBackendLoadingStops(false);
        setRefresh(false);
      }, () => {
        alert(t('errorLoadingStops'));
        if (!noLoadingIndicator) setBackendLoadingStops(false);
        setRefresh(false);
      });
  }, [filter, t]);

  useEffect(() => {
    loadTours();
    loadStops();
  }, [loadTours, loadStops]);

  return (
    <SideNavLayout title={t('tourOverview')}>
      <div className={classes.root}>
        <div className={classes.helpWrapper}>
          <GeneralInfoIcon/>
          <RefreshButton
            className={classes.buttonRight}
            refresh={refresh}
            refreshFunc={() => {
              loadTours();
              loadStops()
            }}
          />
        </div>
        <TourFilter
          filter={filter}
          microHubOptions={microHubOptions}
          setFilter={setFilter}
          tours={tours}
        />
        <LoadingWrapper loading={backendLoadingTours || backendLoadingStops}>
          <Fragment>
            <TourAndStopMap
              className={classes.tourMap}
              microHubs={microHubData}
              notPlanedStops={notPlanedStops ? notPlanedStops : []}
              persistedTabState={mapTabState}
              setPersistedTabState={setMapTabState}
              tours={filterTours(tours)}
            />
            <TourTable
              className={classes.content}
              selectedTour={selectedTour}
              showDetails={(tour) => getTourById(tour.tourId)}
              tableDisplayMode={displayModes.carrier}
              tours={filterTours(tours)}
            />
            <div className={classes.problemNotificationButtonContainer}>
              <Button
                className={classes.problemNotificationButton}
                onClick={() => openProblemNotificationDialog()}
              >
                <Warning/>&nbsp;{t('sendProblemNotification')}
              </Button>
            </div>
            {loadingSelectedTour &&
            <Portlet className={classes.content}>
              <LoadingIndicator/>
            </Portlet>
            }
            {selectedTour &&
            <TourDetail
              className={classes.content}
              closeTourDetail={() => resetSelectedTour()}
              displayMode={displayModes.carrier}
              readOnly
              tour={selectedTour}
            />
            }
          </Fragment>
        </LoadingWrapper>
      </div>
    </SideNavLayout>
  );
}

TourOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(TourOverview);
