import {Button, withStyles} from '@material-ui/core';
import {Add as AddIcon, PlaylistAdd as CreateInstancesIcon} from '@material-ui/icons';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {
  CreateTemplateStopDialog,
  CreateTemplateStopInstancesDialog,
  LoadingIndicator,
  Portlet,
  TemplateStopDetail,
  TemplateStopMap,
  TemplateStopTable
} from 'components';
import React, {Fragment, useCallback, useEffect, useState} from 'react';
import useLoadTemplateStopByIdCache from 'hooks/useLoadTemplateStopByIdCache';
import {TemplateStopService} from 'services/backend/templateStopService';
import {SearchTemplateStopService} from 'services/searchServices/searchTemplateStopService';
import useDebounce from 'hooks/useDebounce';
import {displayModes} from 'services/enums/displayModes';
import {areArraysEqual} from 'services/util/helperFuncs';


function TemplateStopOverview(props) {
  const {classes, t, filter, searchInput, displayMode, shipperOptions} = props;
  const [templateStops, setTemplateStops] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [createTemplateStopDialogState, setCreateTemplateStopDialogState] = useState({
    open: false,
  });
  const [createTemplateStopInstancesDialogState, setCreateTemplateStopInstancesDialogState] = useState({
    open: false,
  });

  const {
    selectedTemplateStop,
    selectedRelatedTemplateStops,
    loadingSelectedTemplateStop,
    resetLoadTemplateStopByIdCache,
    getTemplateStopById,
    resetSelectedTemplateStop,
    updateCache
  } = useLoadTemplateStopByIdCache();

  const loadTemplateStops = useCallback((selectedTemplateStop) => {
    resetLoadTemplateStopByIdCache();
    TemplateStopService.getTemplateStopsWithFilter(filter)
      .then(response => {
        setTemplateStops(response);
        const index = response.findIndex(templateStop => selectedTemplateStop
          && templateStop.id === selectedTemplateStop.id);

        if (index >= 0) {
          getTemplateStopById(selectedTemplateStop.id)
        } else {
          resetSelectedTemplateStop();
        }
      }, () => {
        alert(t('errorLoadingTemplateStops'));
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, t]);

  useEffect(() => {
    loadTemplateStops()
  }, [loadTemplateStops]);

  // use Debounce to prevent to many search operations, delay is in ms
  const debouncedSearchInput = useDebounce(searchInput, 250);

  // do search if debounceSearchInput changes
  useEffect(
    () => {
      const result = SearchTemplateStopService.searchTemplateStops(templateStops, debouncedSearchInput);
      setSearchResult(result);
    },
    [debouncedSearchInput, templateStops]
  );

  const handleTemplateStopUpdate = (templateStop, relatedTemplateStops) => {
    const index = templateStops.findIndex(x => x.id === templateStop.id);
    const newTemplateStops = JSON.parse(JSON.stringify(templateStops));
    newTemplateStops[index] = templateStop;
    setTemplateStops(newTemplateStops);
    updateCache(templateStop, relatedTemplateStops);
  };

  const showDetails = (templateStop) => {
    getTemplateStopById(templateStop.id);
  };

  const hideDetails = () => {
    resetSelectedTemplateStop();
  };

  const deleteTemplateStop = (templateStopId) => {
    TemplateStopService.deleteTemplateStopById(templateStopId).then(() => {
      const newTemplateStops = JSON.parse(JSON.stringify(templateStops));
      const index = newTemplateStops.findIndex(s => s.id === templateStopId);
      if (index > -1) {
        newTemplateStops.splice(index, 1);
      }
      setTemplateStops(newTemplateStops);
      if (selectedTemplateStop?.id === templateStopId) resetSelectedTemplateStop();
    }, () => {
      alert(t('errorDeletingTemplateStop'));
      loadTemplateStops(selectedTemplateStop);
    });
  };

  const updateTemplateStop = (templateStop, oldRelatedTemplateStops, newRelatedTemplateStops) => {
    let relatedTemplateStopsChanged = !areArraysEqual(oldRelatedTemplateStops, newRelatedTemplateStops);
    TemplateStopService.updateTemplateStop(templateStop, templateStop.id)
      .then((response) => {
        handleTemplateStopUpdate(response, newRelatedTemplateStops);
      }, () => {
        loadTemplateStops(selectedTemplateStop);
        alert(t('errorUpdatingTemplateStop'));
      });
    if (relatedTemplateStopsChanged) {
      TemplateStopService.updatePickupDeliveryRelations(templateStop, oldRelatedTemplateStops, newRelatedTemplateStops);
    }
  };

  const onCreateTemplateStop = (address, shipperName, customerId, stopType, name, customerInfo, deliveryWeekDay, deliveryWindow, pickupTemplateStopId) => {
    setCreateTemplateStopDialogState({ ...createTemplateStopDialogState, open: false });
    const templateStop = {
      address,
      shipperName,
      customerId,
      stopType,
      name,
      customerInfo,
      deliveryWeekDay,
      deliveryWindow,
      pickupTemplateStopId: pickupTemplateStopId === '' ? null : pickupTemplateStopId
    };
    TemplateStopService.createTemplateStop(templateStop).then(() => {
      reloadTemplateStop();
    }, () => {
      alert(t('errorCreatingTemplateStop'));
    });
  };

  const onCreateTemplateStopInstances = (date, shipperName) => {
    setCreateTemplateStopInstancesDialogState({ ...createTemplateStopInstancesDialogState, open: false });
    TemplateStopService.templateStopCreation(date, shipperName).then(() => {
      alert((t('templateStopInstancesCreated')))
    }, () => {
      alert(t('errorCreateTemplateStopInstances'));
    });
  }

  const onCreateTemplateStopCancel = () => {
    setCreateTemplateStopDialogState({ ...createTemplateStopDialogState, open: false });
  };

  const onCreateTemplateStopInstancesCancel = () => {
    setCreateTemplateStopInstancesDialogState({ ...createTemplateStopInstancesDialogState, open: false });
  }

  const reloadTemplateStop = () => {
    loadTemplateStops(selectedTemplateStop);
  }

  return (
    <div>
      <div className={classes.root}>
        <br />
        <Fragment>
          <div className={classes.buttons}>
            <Button
              className={classes.addButton}
              color="primary"
              onClick={() => {
                setCreateTemplateStopDialogState({...createTemplateStopDialogState, open: true})
              }}
              variant="contained"
            >
              <AddIcon/>&nbsp;{t('addTemplateStop')}
            </Button>
            {displayMode === displayModes.admin &&
              <Button
                className={classes.addButton}
                color="primary"
                onClick={() => {
                  setCreateTemplateStopInstancesDialogState({...createTemplateStopInstancesDialogState, open: true})
                }}
                variant="contained"
              >
                <CreateInstancesIcon/>&nbsp;{t('addTemplateStopInstances')}
              </Button>}
          </div>
          <TemplateStopTable
            className={classes.content}
            deleteTemplateStop={deleteTemplateStop}
            selectedTemplateStop={selectedTemplateStop}
            showDetails={showDetails}
            templateStops={searchResult}
            tableDisplayMode={displayMode}
          />
          {loadingSelectedTemplateStop &&
            <Portlet className={classes.content}>
              <LoadingIndicator />
            </Portlet>
          }
          {selectedTemplateStop &&
            <TemplateStopDetail
              className={classes.content}
              closeDetailView={hideDetails}
              editDisplayMode={displayMode}
              selectTemplateStop={showDetails}
              templateStop={selectedTemplateStop}
              relatedTemplateStops={selectedRelatedTemplateStops}
              updateTemplateStop={updateTemplateStop}
            />
          }
          <TemplateStopMap
            className={classes.map}
            noControls={false}
            stops={templateStops}
            isTemplateStopInstance={false}
          />
        </Fragment>
      </div>
      <CreateTemplateStopDialog
        createTemplateStopDialogState={createTemplateStopDialogState}
        onCancel={onCreateTemplateStopCancel}
        onCreate={onCreateTemplateStop}
        displayMode={displayMode}
        shipperOptions={shipperOptions}
      />
      <CreateTemplateStopInstancesDialog
        createTemplateStopInstancesDialogState={createTemplateStopInstancesDialogState}
        onCancel={onCreateTemplateStopInstancesCancel}
        onCreate={onCreateTemplateStopInstances}
        shipperOptions={shipperOptions}
      />
    </div>
  );
}

TemplateStopOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  filter: PropTypes.object,
  searchInput: PropTypes.string,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  shipperOptions: PropTypes.array,
};

export default compose(withStyles(styles), withTranslation())(TemplateStopOverview);