import {v4 as uuidv4} from 'uuid';
import {AuthService} from "../auth/authService";

export const DoorOrientations = {
  vertical: 'VERTICAL',
  horizontal: 'HORIZONTAL',
};

export function getOrientationString(orientation) {
  switch (orientation) {
    case DoorOrientations.vertical:
      return 'orientationVertical';
    case DoorOrientations.horizontal:
      return 'orientationHorizontal';
    default:
      return 'orientationUnknown'
  }
}

export const MicroHubElementTypes = {
  boxZone: 'boxZone',
  hubBase: 'hubBase',
  door: 'door',
  beverageInsertZone: 'beverageInsertZone',
  wall: 'wall',
  blockerZone: 'blockerZone'
};

export function getMicroHubElementTypeString(type) {
  switch (type) {
    case MicroHubElementTypes.boxZone:
      return 'microHubElementTypeBoxZone';
    case MicroHubElementTypes.beverageInsertZone:
      return 'microHubElementTypeBeverageInsertZone';
    case MicroHubElementTypes.hubBase:
      return 'microHubElementTypeHubBase';
    case MicroHubElementTypes.door:
      return 'microHubElementTypeDoor';
    case MicroHubElementTypes.wall:
      return 'microHubElementTypeWall';
    case MicroHubElementTypes.blockerZone:
      return 'microHubElementTypeBlockerZone';
    default:
      return 'microHubElementTypeUnknown'
  }
}

export const Offset = {
  x: 20,
  y: 20,
};

export class MicroHubPlanPresetService {
  static getHubPlanPreset() {
    return {
      modifiedBy: AuthService.getUserEmail(),
      hubBase: null,
      boxZones: [],
      beverageInsertZones: [],
      walls: [],
      blockerZones: [],
    };
  }

  static getDoorPreset() {
    return {
      positionX: Offset.x,
      positionY: Offset.y,
      doorSize: 0,
      orientation: DoorOrientations.horizontal,
      type: MicroHubElementTypes.door
    }
  }

  static getHubBasePreset() {
    return {
      width: 0,
      depth: 0,
      door: null,
      type: MicroHubElementTypes.hubBase,
    }
  }

  static getBoxZonePreset() {
    return {
      tmpId: uuidv4(),
      positionX: Offset.x,
      positionY: Offset.y,
      width: 0,
      depth: 0,
      stackHeight: 0,
      shipperInfos: [],
      type: MicroHubElementTypes.boxZone,
    }
  }

  static getBoxZoneShipperPreset() {
    return {
      name: '',
      shortName: '',
      order: 0,
    }
  }

  static getBeverageInsertZonePreset() {
    return {
      tmpId: uuidv4(),
      positionX: Offset.x,
      positionY: Offset.y,
      width: 0,
      depth: 0,
      order: 0,
      type: MicroHubElementTypes.beverageInsertZone,
    }
  }

  static getWallPreset() {
    return {
      tmpId: uuidv4(),
      positionX: Offset.x * 2,
      positionY: Offset.y * 2,
      wallSize: 0,
      orientation: DoorOrientations.horizontal,
      type: MicroHubElementTypes.wall
    }
  }

  static getBlockerZonePreset() {
    return {
      tmpId: uuidv4(),
      positionX: Offset.x,
      positionY: Offset.y,
      width: 0,
      depth: 0,
      text: '',
      type: MicroHubElementTypes.blockerZone,
    }
  }
}

const textSizes = {
  20: {widthPerChar: 14, heightPerChar: 17},
  15: {widthPerChar: 12, heightPerChar: 15},
  10: {widthPerChar: 8, heightPerChar: 10}
};


const textSizeOptions = [20, 15, 10];

export function calculateBoxZoneText(width, depth, shipperInfos) {
  if (shipperInfos && shipperInfos.length > 0) {
    const zoneNameParts = shipperInfos.map(info => info.shortName + '-' + info.order);
    // incrementally reduce name length
    for (let i = zoneNameParts.length; i > 0; i--) {
      const text = zoneNameParts.slice(0, i).join(' / ');
      // check if current name length can fit in box for different fontSizes
      for (let j = 0; j < textSizeOptions.length; j++) {
        const textSize = textSizeOptions[j];
        const charsPerLine = Math.floor(width / textSizes[textSize].widthPerChar);
        const linesPerBox = Math.floor(depth / textSizes[textSize].heightPerChar);
        const maxCharInBox = charsPerLine * linesPerBox;
        if (text.length <= maxCharInBox) {
          const heightOfWrittenText = (Math.ceil(text.length / charsPerLine)) * textSizes[textSize].heightPerChar;
          return [text, heightOfWrittenText, textSize];
        }
      }
    }
  }

  return ['', 0, 1];

}

export function calculateBeverageInsertZoneText(width, depth, order, t) {

  for (let j = 0; j < textSizeOptions.length; j++) {
    const textSize = textSizeOptions[j];
    const charsPerLine = Math.floor(width / textSizes[textSize].widthPerChar);
    const linesPerBox = Math.floor(depth / textSizes[textSize].heightPerChar);
    const maxCharInBox = charsPerLine * linesPerBox;

    // First try to fit long name
    const longName = `${t(getMicroHubElementTypeString(MicroHubElementTypes.beverageInsertZone))}-${order}`;
    if (maxCharInBox >= longName.length) {
      const heightOfWrittenText = (Math.ceil(longName.length / charsPerLine)) * textSizes[textSize].heightPerChar;
      return [longName, heightOfWrittenText, textSize];
    }

    // Try shorter Name
    const shortName = `${t('microHubPlanBevInsShort')}-${order}`;
    if (maxCharInBox >= shortName.length) {
      const heightOfWrittenText = (Math.ceil(shortName.length / charsPerLine)) * textSizes[textSize].heightPerChar;
      return [shortName, heightOfWrittenText, textSize];
    }
  }

  return ['', 0, 1]
}

export function calculateBlockerZoneText(width, depth, text) {
  for (let j = 0; j < textSizeOptions.length; j++) {
    const textSize = textSizeOptions[j];
    const charsPerLine = Math.floor(width / textSizes[textSize].widthPerChar);
    const linesPerBox = Math.floor(depth / textSizes[textSize].heightPerChar);
    const maxCharInBox = charsPerLine * linesPerBox;
    if (text.length <= maxCharInBox) {
      const heightOfWrittenText = (Math.ceil(text.length / charsPerLine)) * textSizes[textSize].heightPerChar;
      return [text, heightOfWrittenText, textSize];
    }
  }

  return ['', 0, 1];
}
