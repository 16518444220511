export default theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  alignRight: {
    align: 'right',
  },
  wrapper: {
    display: 'flex',
    margin: theme.spacing(2)
  },
  wrapperChild: {
    flex: 1,
  },
  changeStopAssignmentButton: {
    float: 'right',
  },
  deleteButton: {
    color: theme.palette.danger.main
  },
});

