import React, {useState} from 'react'
import {Table, TableBody, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {
  Pagination,
  Portlet,
  PortletContent,
  TableCellWithConfig,
  TableSettings,
  TextPlaceholderWrapper,
} from '../../../components'
import classNames from 'classnames';
import {displayModes} from '../../../services/enums/displayModes';
import ChangeAssignmentDialog from '../ChangeAssignmentDialog';
import DeleteStopDialog from '../DeleteStopDialog';
import DeleteCarrierFromStopDialog from '../DeleteCarrierFromStopDialog';
import Columns from './columns'
import DefaultConfig from './config';
import useTableConfig from '../../../hooks/useTableConfig';
import {getTableKey} from '../../../services/util/tableConfigHelper';
import {TableKind} from '../../../services/enums/TableKind';
import useTablePagination from '../../../hooks/useTablePagination';

function StopTable(props) {

  const [changeAssignmentDialogState, setChangeAssignmentDialogState] = useState({open: false, stop: null});
  const [deleteStopDialogState, setDeleteStopDialogState] = useState({open: false, stop: null});
  const [deleteCarrierFromStopDialogState, setDeleteCarrierFromStopDialogState] = useState({open: false, stop: null});

  const {
    classes,
    t,
    showDetails,
    stops,
    className,
    deleteStop,
    deleteCarrierFromStop,
    tableDisplayMode,
    selectedStop,
    changeAssignment,
    carriersWithHubs,
    microHubsData
  } = props;

  const {
    config,
    columnConfigs,
    updateConfig
  } = useTableConfig(
    DefaultConfig,
    tableDisplayMode,
    getTableKey(TableKind.StopTable, tableDisplayMode),
    {
      // Only show options column, if one of the options functions is present
      [Columns.options.name]: Boolean(deleteStop || changeAssignment || deleteCarrierFromStop)
    },
  )

  const {paginationProps, pagedObjects} = useTablePagination(stops);

  const changeAssignmentDialogOK = (stopId, carrier, microHub) => {
    setChangeAssignmentDialogState({...changeAssignmentDialogState, open: false, stop: null});
    changeAssignment(stopId, carrier, microHub);
  };

  const deleteStopDialogOk = (stopId) => {
    setDeleteStopDialogState({...deleteStopDialogState, open: false, stop: null});
    deleteStop(stopId);
  };

  const deleteCarrierFromStopDialogOK = (stopId) => {
    setDeleteCarrierFromStopDialogState({...deleteCarrierFromStopDialogState, open: false, stop: null});
    deleteCarrierFromStop(stopId);
  };

  const rootClassName = classNames(classes.root, className);


  return (
    <TextPlaceholderWrapper
      active={!stops?.length}
      text={t('noStopsAvailable')}
    >
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <TableSettings
            updateConfig={updateConfig}
            tableConfig={config}
            columns={Columns}
          />
          <Table size="medium">
            <TableHead>
              <TableRow>
                {columnConfigs.map(columnConfig => (
                  <TableCellWithConfig
                    key={columnConfig.order}
                    config={columnConfig}
                  >
                    {Columns[columnConfig.columnName].header({t})}
                  </TableCellWithConfig>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedObjects.map((stop, index) => (
                <TableRow
                  className={classNames(classes.tableRow, 'cursorPointer')}
                  hover
                  key={index}
                  onClick={() => showDetails(stop)}
                  selected={selectedStop && stop.tourStopId === selectedStop.tourStopId}
                >
                  {columnConfigs.map(columnConfig => (
                    <TableCellWithConfig
                      key={columnConfig.order}
                      config={columnConfig}
                      className={classes.tableCell}
                    >
                      {Columns[columnConfig.columnName].body({
                        stop,
                        classes,
                        t,
                        changeAssignment,
                        setChangeAssignmentDialogState,
                        changeAssignmentDialogState,
                        deleteCarrierFromStop,
                        setDeleteCarrierFromStopDialogState,
                        deleteCarrierFromStopDialogState,
                        deleteStop,
                        setDeleteStopDialogState,
                        deleteStopDialogState,
                      })}
                    </TableCellWithConfig>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination paginationProps={paginationProps}/>
        </PortletContent>
      </Portlet>
      <ChangeAssignmentDialog
        carriersWithHubs={carriersWithHubs}
        dialogState={changeAssignmentDialogState}
        handleCancel={() => setChangeAssignmentDialogState({...changeAssignmentDialogState, open: false, stop: null})}
        handleClose={changeAssignmentDialogOK}
        microHubsData={microHubsData}
      />
      <DeleteStopDialog
        dialogState={deleteStopDialogState}
        handleCancel={() => setDeleteStopDialogState({...deleteStopDialogState, open: false, stop: null})}
        handleClose={deleteStopDialogOk}
        needsConfirm={tableDisplayMode !== displayModes.admin && tableDisplayMode !== displayModes.shipper}
      />
      <DeleteCarrierFromStopDialog
        dialogState={deleteCarrierFromStopDialogState}
        handleCancel={() => setDeleteCarrierFromStopDialogState({
          ...deleteCarrierFromStopDialogState,
          open: false,
          stop: null
        })}
        handleClose={deleteCarrierFromStopDialogOK}
      />
    </TextPlaceholderWrapper>
  );
}


StopTable.propTypes = {
  carriersWithHubs: PropTypes.object,
  changeAssignment: PropTypes.func,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deleteCarrierFromStop: PropTypes.func,
  deleteStop: PropTypes.func,
  microHubsData: PropTypes.array,
  selectedStop: PropTypes.object,
  showDetails: PropTypes.func.isRequired,
  stops: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  tableDisplayMode: PropTypes.string.isRequired
}
;

export default compose(withStyles(styles), withTranslation())(StopTable);
