export default theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    border: `1px solid ${theme.palette.border}`
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
  refreshButton: {
    marginRight: theme.spacing(1)
  },
  refreshButtonActivated: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.warning.main
  },
  helpWrapper: {
    float: 'right'
  },
  timer: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
    border: `1px solid ${theme.palette.border}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    padding: theme.spacing(1),
    display: 'inline-block',
  },
  downloadButtonPreview: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  downloadButtonMhLocationMap: {
    flex: 1,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  verticalContainer: {
    display: 'flex',
    flexDirection: 'row',
  }
});
