import {compose} from 'recompose';
import {Tab, Tabs, withStyles} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import styles from './styles'
import classNames from 'classnames';
import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {TemplateStopTourMap} from '../../../index';
import {TemplateStopType} from '../../../../services/enums/TemplateStopType';
import {TemplateStopLegendInteractive} from '../../MapComponents';

const templateStopAndTourMapState = {
  tourOverview: 0,
  stopType: 1,
}

const TemplateStopAndTourMap = props => {

  const {classes, className, tours, t, notPlanedStopInstances} = props;
  const [legendCheckboxes, setLegendCheckboxes] = useState({
    displayDeliveryStops: true,
    displayPickupStops: true,
    displayNotPlanedStops: true
  });

  const [tabState, setTabState] = useState(templateStopAndTourMapState.tourOverview);

  const rootClassName = classNames(classes.root, className);

  const displayStopType = tabState === templateStopAndTourMapState.stopType;
  const filteredTours = JSON.parse(JSON.stringify(tours));
  filteredTours.forEach(
    tour => tour.templateStopInstances = tour.templateStopInstances.filter(
      stop => {
        switch (stop.stopType) {
          case TemplateStopType.Pickup: {
            return legendCheckboxes.displayPickupStops || !displayStopType;
          }
          case TemplateStopType.Delivery: {
            return legendCheckboxes.displayDeliveryStops || !displayStopType;
          }
          default: {
            return false;
          }
        }
      }
    )
  );

  return (
    <Fragment>
      <div className={classes.legendControl}>
        <div className={classes.tabCheckbox}>
          <Tabs
            onChange={(event, newValue) => {
              setTabState(newValue);
            }}
            value={tabState}
          >
            <Tab
              label={t('tourOverview')}
              value={templateStopAndTourMapState.tourOverview}
            />
            <Tab
              label={t('stopType')}
              value={templateStopAndTourMapState.stopStatus}
            />
          </Tabs>
        </div>
        <TemplateStopLegendInteractive
          showStopType={displayStopType}
          showConnections={false}
          showNotPlanedStops={!displayStopType}
          legendCheckboxesState={legendCheckboxes}
          setLegendCheckboxesState={setLegendCheckboxes}
        />
      </div>
      <TemplateStopTourMap
        className={rootClassName}
        displayNotPlanedStops={legendCheckboxes.displayNotPlanedStops}
        displayStopType={displayStopType}
        notPlanedStops={notPlanedStopInstances}
        toursWithStops={filteredTours}
      />
    </Fragment>
  );
};

TemplateStopAndTourMap.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  notPlanedStops: PropTypes.array,
  t: PropTypes.func.isRequired,
  tours: PropTypes.array,
};


export default compose(withStyles(styles), withTranslation())(TemplateStopAndTourMap);

