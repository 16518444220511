import React, {Fragment} from 'react'
import {Popover, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {
  ContactSupport as UndefinedIcon,
  LinkOff as TourTemplateAutoCutIcon,
  LowPriority as TemplateIcon,
  PanTool as ManualIcon,
  Settings as AlgorithmIcon
} from '@material-ui/icons';
import classNames from 'classnames'
import {getTourTypeString, TourType} from '../../services/enums/TourType';
import {DateService} from '../../services/util/DateService';


function TourMetadataIcon(props) {

  const {classes, className, t, metadata} = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const rootClassName = classNames(classes.root, className);

  const onClickIcon = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const onClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Fragment>
      {(() => {
        switch (metadata?.tourType) {
          case TourType.Template:
            return <TemplateIcon
              className={classNames('cursorPointer', rootClassName)}
              color={'secondary'}
              fontSize={'small'}
              onClick={onClickIcon}
              titleAccess={t(getTourTypeString(metadata?.tourType))}
            />;
          case TourType.TemplateWithAutoCut:
            return <TourTemplateAutoCutIcon
              className={classNames('cursorPointer', rootClassName)}
              color={'secondary'}
              fontSize={'small'}
              onClick={onClickIcon}
              titleAccess={t(getTourTypeString(metadata?.tourType))}
            />;
          case TourType.Algorithm:
            return <AlgorithmIcon
              className={classNames('cursorPointer', rootClassName)}
              color={'secondary'}
              fontSize={'small'}
              onClick={onClickIcon}
              titleAccess={t(getTourTypeString(metadata?.tourType))}
            />;
          case TourType.Manual:
            return <ManualIcon
              className={classNames('cursorPointer', rootClassName)}
              color={'secondary'}
              fontSize={'small'}
              onClick={onClickIcon}
              titleAccess={t(getTourTypeString(metadata?.tourType))}
            />;
          default:
            return <UndefinedIcon
              className={classNames('cursorPointer', rootClassName)}
              color={'secondary'}
              fontSize={'small'}
              onClick={onClickIcon}
              titleAccess={t(getTourTypeString(metadata?.tourType))}
            />;
        }
      })()}
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        id={'metadataPopover'}
        onClose={onClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.popoverRoot}>
          <Typography variant={'body2'}><strong>{t('tourType')}</strong>: {t(getTourTypeString(metadata?.tourType))}</Typography>
          {metadata?.tourTemplateId && <Typography variant={'body2'}><strong>{t('tourTemplateIdFull')}</strong>: {metadata.tourTemplateId}</Typography>}
          {metadata?.tourTemplateName && <Typography variant={'body2'}><strong>{t('tourTemplateNameFull')}</strong>: {metadata.tourTemplateName}</Typography>}
          {metadata?.vehicleLicensePlate && <Typography variant={'body2'}><strong>{t('vehicleName')}</strong>: {metadata.vehicleLicensePlate}</Typography>}
          {metadata?.lastModified &&
          <Typography variant={'body2'}><strong>{t('lastModified')}</strong>: {DateService.displayDateTime(metadata.lastModified)}</Typography>}
          {metadata?.modifiedBy && <Typography variant={'body2'}><strong>{t('modifiedBy')}</strong>: {metadata.modifiedBy}</Typography>}
        </div>
      </Popover>
    </Fragment>
  );
}


TourMetadataIcon.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  metadata: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TourMetadataIcon);
