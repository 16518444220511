export class SearchTemplateStopService {

  static searchTemplateStops(templateStops, searchInput) {
    if (!templateStops || templateStops.length <= 0) {
      return [];
    }

    if (!searchInput) {
      return templateStops;
    }

    return templateStops.filter(
      (templateStop) => {
        return templateStop?.id?.toString().includes(searchInput.toLowerCase()) ||
          (templateStop?.name?.toLowerCase().includes(searchInput.toLowerCase())) ||
          (templateStop?.address?.streetAndNumber?.toLowerCase().includes(searchInput.toLowerCase())) ||
          (templateStop?.address?.zipcode?.toLowerCase().includes(searchInput.toLowerCase())) ||
          (templateStop?.customerId?.toLowerCase().includes(searchInput.toLowerCase())) ||
          (templateStop?.internalCustomerId?.toString().toLowerCase().includes(searchInput.toLowerCase()));
      }
    );
  }
}
