export default theme => ({
  root: {
    width: 20, height: 20,
    padding: 0,
    margin: 0
  },
  icon: {
    width: 20, height: 20,
    color: theme.palette.secondary.main
  },
});
