import React, {useState} from 'react'
import {Box, Collapse, IconButton, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon} from '@material-ui/icons';
import {displayShipperShortName, roundCapacityValues} from '../../../../../services/util/helperFuncs';
import StopTableDetails from '../../../StopTableDetails';
import {PreviewStopIdLabel} from '../../../../index';

function Stop(props) {

  const {classes, stop, t, className} = props;

  const [open, setOpen] = useState(false);

  const rootClassName = classNames(stop.editable ? classes.root : classes.rootDisabled, className);

  return (
    <div className={rootClassName}>
      <div className={classes.stopHeader}>
        <Typography className={classes.headerSectionFirst}>
          <strong>{t('stopNumber')}: {stop.stopNumber}</strong>
        </Typography>
        <Typography className={classes.headerSection}>
          <strong>{t('previewStopID')}</strong>: <PreviewStopIdLabel stop={stop}/>
        </Typography>
        <Typography className={classes.headerSection}>
          <strong>{t('shipper')}</strong>: {displayShipperShortName(stop)}
        </Typography>
        <Typography className={classes.headerSection}>
          <strong>{t('boxAmount')}</strong>: {roundCapacityValues(stop.stopCapacities.boxAmount)}
        </Typography>
        <Typography className={classes.headerSection}>
          <strong>{t('weight')}</strong>: {roundCapacityValues(stop.stopCapacities.weight)}
        </Typography>
        <Typography className={classNames(classes.headerSection, classes.lastHeaderSection)}>
          <strong>{t('volume')}</strong>: {roundCapacityValues(stop.stopCapacities.volume)}
        </Typography>
        <IconButton
          className={classes.iconButton}
          onClick={() => setOpen(!open)}
          size="small"
        >
          {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
        </IconButton>
      </div>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
      >
        <Box
          margin={1}
        >
          <StopTableDetails
            stop={stop}
          />
        </Box>
      </Collapse>
    </div>
  );
}


Stop.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  stop: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default compose(withStyles(styles), withTranslation())(Stop);
