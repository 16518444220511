import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Close as CloseIcon, NavigateBefore as PrevIcon, NavigateNext as NextIcon} from '@material-ui/icons';

function GeneralInfoDialog(props) {

  const {classes, className, t, dialogState, translatedInfo, handleClose} = props;
  const [page, setPage] = React.useState(1);
  const rootClassName = classNames(classes.root, className);

  const handleOk = () => {
    handleClose();
    setPage(1);
  };

  const autoBreakText = (text) => {
    const MAX_CHAR_PER_LINE = 130;
    const paragraphs = text.split('\n\n');
    let finalText = paragraphs[0] + '\n\n';
    for (let j = 1; j < paragraphs.length; j++) {
      const words = paragraphs[j].split(' ');
      let finalParagraph = '';
      let tmpLine = '';
      let finalLine = '';
      for (let i = 0; i < words.length; i++) {
        tmpLine += words[i] + ' ';
        if (getCharLengthOfString(tmpLine) > MAX_CHAR_PER_LINE) {
          finalParagraph += finalLine + '\n';
          tmpLine = '';
          finalLine = '';
          i--;
        }
        finalLine = tmpLine;
      }
      finalText += finalParagraph + finalLine + '\n\n';
    }
    return finalText;
  };

  const getCharLengthOfString = (str) => {
    return [...str].length;
  };

  return (
    <Dialog
      className={rootClassName}
      fullWidth
      maxWidth={'md'}
      onClose={handleOk}
      open={dialogState.open}
    >
      <DialogTitle>
        {t('generalInfo_dialogTitle')}
        <IconButton
          className={classes.navRight}
          onClick={handleOk}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.minHeight}>
        <Typography
          className={classes.infoTypography}
          variant={'body1'}
        >
          {translatedInfo[page - 1].autoBreak ? autoBreakText(translatedInfo[page - 1].text) : translatedInfo[page - 1].text}
        </Typography>
      </DialogContent>
      <DialogActions>
        <div className={classes.navContainer}>
          <Button
            className={classes.navLeft}
            color="primary"
            disabled={page <= 1}
            onClick={() => setPage(page - 1)}
          >
            <PrevIcon/>&nbsp;{t('back')}
          </Button>
          <div className={classes.navCenter}>
            <Typography
              variant={'body1'}
            >
              {page}&nbsp;/&nbsp;{translatedInfo.length}
            </Typography>
          </div>
          <Button
            className={classes.navRight}
            color="primary"
            disabled={page >= (translatedInfo.length)}
            onClick={() => setPage(page + 1)}
          >
            {t('next')}&nbsp;<NextIcon/>
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}


GeneralInfoDialog.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  dialogState: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  translatedInfo: PropTypes.array.isRequired
};

export default compose(withStyles(styles), withTranslation())(GeneralInfoDialog);
