import React from 'react';
import {Doughnut} from 'react-chartjs-2';
import PropTypes from 'prop-types';
import palette from '../../../../../theme/palette';
import {withTranslation} from 'react-i18next';
import {getStopStatusString, StopStatus} from '../../../../../services/enums/StopStatus';
import {
  canNotDeliverIconColor,
  deliveredIconColor,
  inDeliveryIconColor,
  notPlanedIconColor,
  planedIconColor,
  redeliveryIconColor
} from '../../../../../services/icon/iconService';

import {options} from '../chart';
import {TextPlaceholderWrapper} from '../../../../index';


function StopDoughnut(props) {

  const {stops, t, className} = props;

  const notPlaned = stops.filter(stop => stop.stopStatus === StopStatus.NotPlaned);
  const planed = stops.filter(stop => stop.stopStatus === StopStatus.Planed);
  const inDelivery = stops.filter(stop => stop.stopStatus === StopStatus.InDelivery);
  const delivered = stops.filter(stop => stop.stopStatus === StopStatus.Delivered);
  const canNotDeliver = stops.filter(stop => stop.stopStatus === StopStatus.CanNotDeliver);
  const redelivery = stops.filter(stop => stop.stopStatus === StopStatus.Redelivery);

  const data = {
    datasets: [
      {
        data: [
          notPlaned.length,
          planed.length,
          inDelivery.length,
          delivered.length,
          canNotDeliver.length,
          redelivery.length
        ],
        backgroundColor: [
          notPlanedIconColor,
          planedIconColor,
          inDeliveryIconColor,
          deliveredIconColor,
          canNotDeliverIconColor,
          redeliveryIconColor

        ],
        hoverBorderColor: palette.common.white,
        borderColor: palette.common.white
      }
    ],
    labels: [
      t(getStopStatusString(StopStatus.NotPlaned)),
      t(getStopStatusString(StopStatus.Planed)),
      t(getStopStatusString(StopStatus.InDelivery)),
      t(getStopStatusString(StopStatus.Delivered)),
      t(getStopStatusString(StopStatus.CanNotDeliver)),
      t(getStopStatusString(StopStatus.Redelivery))
    ]
  };

  return (
    <div className={className}>
      <TextPlaceholderWrapper
        active={!stops?.length}
        text={t('noStopsAvailableDashboard')}
      >
        <Doughnut
          data={data}
          options={options}
        />
      </TextPlaceholderWrapper>
    </div>
  );
}

StopDoughnut.propTypes = {
  className: PropTypes.string,
  stops: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(StopDoughnut);
