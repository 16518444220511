import React from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import {Portlet, PortletContent} from '../../../index';

function Address(props) {
  const {classes, className, address, title} = props;

  const rootClassName = classNames(classes.root, className);
  return (
    <Portlet className={rootClassName}>
      <PortletContent>
        <Typography variant="h3">
          {title}
        </Typography>
        <hr/>
        <Typography variant="body1">
          {address.street} {address.houseNumber} <br/>
          {address.zipcode} {address.city} <br/>
          {address.country}
        </Typography>
      </PortletContent>
    </Portlet>
  );
}

Address.propTypes = {
  address: PropTypes.object.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};


export default compose(withStyles(styles), withTranslation())(Address);
