import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import SideNavLayout from '../../../layouts/SideNavLayout';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {
  DataLoadingWrapper,
  GeneralInfoIcon,
  MicroHubAdministrationEditWithControls,
  MicroHubAdministrationFilter,
  TextPlaceholderWrapper
} from '../../../components';
import {MicroHubElementTypes, Offset} from '../../../services/hubAdministrationHelper/hubAdministrationHelper';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {ClientService} from '../../../services/client/clientService';
import {clientTypes} from '../../../services/client/clientTypes';
import {v4 as uuidv4} from 'uuid';
import omitDeep from 'omit-deep';
import {AuthService} from '../../../services/auth/authService';
import {MicroHubQueryService} from '../../../services/backend/microHubQueryService';
import useShipperOptions from '../../../hooks/useShipperOptions';
import useMicroHubOptionsOfCarrier from '../../../hooks/useMicroHubOptionsOfCarrier';

const clientMicroHub = ClientService.getClient(clientTypes.microHub);

function MicroHubAdministration(props) {

  const {classes, t} = props;
  const [data, setData] = useState([]);
  const [disableFilter, setDisableFilter] = useState(false);
  const {shipperOptions} = useShipperOptions(true);
  const [filter, setFilter] = useState({
    carrierName: AuthService.getUserOrganization(),
    microHubName: '',
    shipperName: '',
  });
  const {microHubOptions} = useMicroHubOptionsOfCarrier(AuthService.getUserOrganization());

  const microHubDataQuery = MicroHubQueryService.getMicroHubsByCarrierNameAndMicroHubNameList(AuthService.getUserOrganization(), [filter.microHubName]);
  const {loading: hubLoading, error: hubError, data: hubData} = useQuery(microHubDataQuery, {client: clientMicroHub});

  const microHubPlanQuery = MicroHubQueryService.getMicroHubPlansByCarrierNameAndMicroHubNameQuery(filter.carrierName, filter.microHubName);
  const {error, loading, data: dataMicroHubPlans, refetch: refetchMicroHubPlans} = useQuery(microHubPlanQuery, {
    client: clientMicroHub,
    skip: !filter.carrierName || !filter.microHubName
  });

  const createOrUpdatePlansQuery = MicroHubQueryService.createOrUpdateMicroHubPlanQuery();
  const [createOrUpdatePlans] = useMutation(createOrUpdatePlansQuery, {client: clientMicroHub});

  const deleteHubPlanQuery = MicroHubQueryService.deleteMicroHubPlan();
  const [deleteHubPlan] = useMutation(deleteHubPlanQuery, {client: clientMicroHub});

  useEffect(() => {
    if (dataMicroHubPlans && dataMicroHubPlans.plans && dataMicroHubPlans.plans.hubPlans) {
      dataMicroHubPlans.plans.hubPlans.forEach(plan => {
        if (plan.hubBase) {
          plan.hubBase.type = MicroHubElementTypes.hubBase;

          if (plan.hubBase.door) {
            plan.hubBase.door.positionX = plan.hubBase.door.positionX + Offset.x;
            plan.hubBase.door.positionY = plan.hubBase.door.positionY + Offset.y;
            plan.hubBase.door.type = MicroHubElementTypes.door;
          }
        }

        if (plan.boxZones) {
          plan.boxZones.forEach(zone => {
            zone.tmpId = uuidv4();
            zone.positionX = zone.positionX + Offset.x;
            zone.positionY = zone.positionY + Offset.y;
            zone.type = MicroHubElementTypes.boxZone;
          });
        }

        if (plan.beverageInsertZones) {
          plan.beverageInsertZones.forEach(zone => {
            zone.tmpId = uuidv4();
            zone.positionX = zone.positionX + Offset.x;
            zone.positionY = zone.positionY + Offset.y;
            zone.type = MicroHubElementTypes.beverageInsertZone;
          });
        }

        if (plan.walls) {
          plan.walls.forEach(wall => {
            wall.tmpId = uuidv4();
            wall.positionX = wall.positionX + Offset.x;
            wall.positionY = wall.positionY + Offset.y;
            wall.type = MicroHubElementTypes.wall;
          });
        }

        if (plan.blockerZones) {
          plan.blockerZones.forEach(zone => {
            zone.tmpId = uuidv4();
            zone.positionX = zone.positionX + Offset.x;
            zone.positionY = zone.positionY + Offset.y;
            zone.type = MicroHubElementTypes.blockerZone;
          });
        }
      });
      setData(dataMicroHubPlans.plans.hubPlans);
    }
  }, [dataMicroHubPlans]);

  const updateBackendPlanForDays = (planToUpdate, daysOfWeek) => {
    const formattedPlan = omitDeep(planToUpdate, ['__typename', 'type', 'tmpId', 'lastModified', 'maxVolume']);
    formattedPlan.modifiedBy = AuthService.getUserEmail();
    if (formattedPlan.hubBase && formattedPlan.hubBase.door) {
      formattedPlan.hubBase.door.positionX = formattedPlan.hubBase.door.positionX - Offset.x;
      formattedPlan.hubBase.door.positionY = formattedPlan.hubBase.door.positionY - Offset.y;
    }

    if (formattedPlan.boxZones && formattedPlan.boxZones.length > 0) {
      formattedPlan.boxZones.forEach(zone => {
        zone.positionX = zone.positionX - Offset.x;
        zone.positionY = zone.positionY - Offset.y;
      });
    }

    if (formattedPlan.beverageInsertZones && formattedPlan.beverageInsertZones.length > 0) {
      formattedPlan.beverageInsertZones.forEach(zone => {
        zone.positionX = zone.positionX - Offset.x;
        zone.positionY = zone.positionY - Offset.y;
      });
    }

    if (formattedPlan.walls && formattedPlan.walls.length > 0) {
      formattedPlan.walls.forEach(wall => {
        wall.positionX = wall.positionX - Offset.x;
        wall.positionY = wall.positionY - Offset.y;
      });
    }

    if (formattedPlan.blockerZones && formattedPlan.blockerZones.length > 0) {
      formattedPlan.blockerZones.forEach(zone => {
        zone.positionX = zone.positionX - Offset.x;
        zone.positionY = zone.positionY - Offset.y;
      });
    }

    createOrUpdatePlans({
      variables: {
        planToUpdate: formattedPlan,
        carrierName: filter.carrierName,
        microHubName: filter.microHubName,
        daysOfWeek: daysOfWeek
      }
    }).then(() => {
      refetchMicroHubPlans();
    }, () => {
      alert(t('errorUpdatingMicroHubPlan'))
    });
  };

  const deleteMicroHubPlan = (dayOfWeek) => {
    deleteHubPlan({variables: {dayOfWeek: dayOfWeek, carrierName: filter.carrierName, microHubName: filter.microHubName}}).then(() => {
      refetchMicroHubPlans();
    }, () => {
      alert(t('errorDeletingMicroHubPlan'))
    });
  };

  return (
    <SideNavLayout title={t('microHubAdministration')}>
      <div className={classes.root}>
        <div className={classes.filterWrapper}>
          <MicroHubAdministrationFilter
            className={classes.filter}
            disabled={disableFilter}
            filter={filter}
            microHubOptions={microHubOptions}
            setFilter={setFilter}
            shipperOptions={shipperOptions ? Object.keys(shipperOptions) : []}
          />
          <div className={classes.helpWrapper}>
            <GeneralInfoIcon/>
          </div>
        </div>
        <TextPlaceholderWrapper
          active={!filter.carrierName || !filter.microHubName}
          text={t('microHubAdministrationNoMicroHubSelected')}
        >
          <DataLoadingWrapper
            error={error || hubError}
            isLoading={loading || hubLoading}
          >
            <MicroHubAdministrationEditWithControls
              deleteMicroHubPlan={deleteMicroHubPlan}
              disableFilter={disableFilter}
              filter={filter}
              hubCarrierData={hubData ? hubData.microhubs[0] : null}
              microHubPlanData={data ? data : null}
              setDisableFilter={setDisableFilter}
              shipperOptions={shipperOptions}
              updateBackendMicroHubPlansForDays={updateBackendPlanForDays}
            />
          </DataLoadingWrapper>
        </TextPlaceholderWrapper>
      </div>
    </SideNavLayout>
  );

}

MicroHubAdministration.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(MicroHubAdministration);
