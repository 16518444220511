import React, {Fragment, useState} from 'react'
import {Collapse, IconButton, Table, TableBody, TableCell, TableRow, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classNames from 'classnames'
import {CapacityHelperService} from '../../../../../../services/util/capacityHelper';
import {KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon} from '@material-ui/icons';
import StopTableRow from '../StopTableRow';
import {TourIdLabel} from '../../../../../index';


function TourTableRow(props) {

  const {classes, className, t, tour} = props;
  const rootClassName = classNames(classes.root, className);
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <TableRow
        className={rootClassName}
        hover
        selected={open}
      >
        <TableCell
          className={classNames(classes.tableCell, classes.firstTableCell)}
          style={{boxShadow: `inset 10px 0px 0px 0px ${tour.color}`}}
        >
          <Typography variant="body1"><strong style={{display: 'flex'}}>{`${t('tourId')}:`}&nbsp;<TourIdLabel tour={tour}/></strong></Typography>
        </TableCell>
        <TableCell className={classes.tableCell}><Typography variant="body1">{`${CapacityHelperService.getBoxAmountOfTour(tour)} [${t('boxes')}]`}</Typography></TableCell>
        <TableCell className={classes.tableCell}><Typography variant="body1">{`${CapacityHelperService.getWeightOfTour(tour)} [kg]`}</Typography></TableCell>
        <TableCell className={classes.tableCell}><Typography variant="body1">{`${CapacityHelperService.getVolumeOfTour(tour)} [l]`}</Typography></TableCell>
        <TableCell>
          <IconButton
            onClick={() => setOpen(!open)}
            size="small"
          >
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={5}
          style={{paddingBottom: 0, paddingTop: 0}}
        >
          <Collapse
            in={open}
            timeout="auto"
          >
            <Table
              size={'small'}
            >
              <TableBody>
                {tour.stops && tour.stops.map(stop =>
                  <StopTableRow
                    key={stop.tourStopId}
                    stop={stop}
                  />
                )}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

TourTableRow.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  tour: PropTypes.object,
};

export default compose(withStyles(styles), withTranslation())(TourTableRow);
