import React from 'react';
import PropTypes from 'prop-types';
import {getStopStatusString} from '../../../services/enums/StopStatus';
import {useTranslation} from 'react-i18next';


const StopStatusLabel = (props) => {

  const {stop} = props;
  const {t} = useTranslation();

  return (
    <div>{t(getStopStatusString(stop.stopStatus))}</div>
  );

}

StopStatusLabel.propTypes = {
  stop: PropTypes.shape({stopStatus: PropTypes.number.isRequired}).isRequired
}

export default StopStatusLabel;
