import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {Step, StepContent, StepLabel, Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import React, {useState} from 'react';
import {StepperIcon} from '../index';
import classNames from 'classnames';
import {DateService} from '../../../../../services/util/DateService';
import moment from 'moment';
import {ExpandLess, ExpandMore} from '@material-ui/icons';


const TourStartStep = (props) => {
  const {className, classes, t, tour} = props;

  const [active, setActive] = useState(false);
  const rootClassName = classNames(classes.root, className);

  return (
    <Step
      active={active}
      className={rootClassName}
      completed
    >
      <StepLabel
        classes={{error: classes.noErrorClass}}
        StepIconComponent={StepperIcon}
      >
        <div
          className={classes.stepperLabel}
          onClick={() => setActive(!active)}
        >
          <Typography
            variant={'subtitle1'}
          >
            {`[${DateService.displayDateTime(tour.tourStart)}] - ${t('tourStart')}`}
          </Typography>
          {active ? <ExpandLess/> : <ExpandMore/>}
        </div>
      </StepLabel>
      <StepContent>
        <Typography>
          <strong>{t('timeToPack')}:</strong> {moment.duration(tour.timeToPack, 'seconds').format('h:mm:ss', {trim: false})}
        </Typography>
        <Typography>
          <strong>{t('differenceActualAndPlannedTourStart')}:</strong> {moment.duration(moment(tour.tourStart).diff(moment(tour.planedTourDelivery))).format('h:mm:ss', {trim: false})}
        </Typography>
      </StepContent>
    </Step>
  );
};


TourStartStep.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  tour: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TourStartStep);
