import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,} from '@material-ui/core';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {onCloseWithBackdropCurry} from '../../../../../services/util/helperFuncs';

function EmptyDialog(props) {
  const {t, emptiesDialogState, setEmptiesDialogState, setEmpties, empties} = props;

  const closeEmptyDialog = () => {
    setEmptiesDialogState({...emptiesDialogState, open: false, empty: {name: ''}});
  };

  const addEmptyDialog = () => {
    setEmpties([...empties, emptiesDialogState.empty]);
    setEmptiesDialogState({...emptiesDialogState, open: false, empty: {name: ''}});
  };


  return (
    <Dialog
      disableEscapeKeyDown
      onClose={onCloseWithBackdropCurry(closeEmptyDialog)}
      open={emptiesDialogState.open}
    >
      <DialogTitle id="form-dialog-title">{t('addEmpty')}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          id="empty"
          label={t('empty')}
          margin="dense"
          onChange={(event) => {
            setEmptiesDialogState({...emptiesDialogState, empty: {name: event.target.value}});
          }}
          onKeyPress={(event) => {
            if (event.key === 'Enter' && emptiesDialogState.empty.name !== '') {
              addEmptyDialog();
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={closeEmptyDialog}
        >
          {t('cancel')}
        </Button>
        <Button
          color="primary"
          disabled={emptiesDialogState.empty === ''}
          onClick={addEmptyDialog}
        >
          {t('add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}


EmptyDialog.propTypes = {
  empties: PropTypes.array.isRequired,
  emptiesDialogState: PropTypes.object.isRequired,
  setEmpties: PropTypes.func.isRequired,
  setEmptiesDialogState: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default withTranslation()(EmptyDialog);
