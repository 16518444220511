export default theme => ({
  root: {},
  tableRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  wrapper: {
    display: 'flex',
    margin: theme.spacing(0)
  },
  wrapperChild: {
    flex: 1,
  },
  productDetails: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(0),
  },
  largeTableCell: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5)
  }
});

