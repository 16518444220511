export default theme => ({
  root: {
    minWidth: '350px'
  },
  portletHeader: {
    height: '40px'
  },
  doughnut: {
    height: 'calc(40vh - 45px)',
    position: 'relative'
  },
  tourMap: {
    minWidth: '100%',
    minHeight: 'calc(100% + 4px)',
    borderRadiusBottomRight: '3px',
  },
});
