import React, {useCallback, useContext, useEffect, useState} from 'react'
import {Grid, withStyles} from '@material-ui/core';
import styles from './styles';
import PropTypes from 'prop-types';
import SideNavLayout from '../../../layouts/SideNavLayout';
import {compose} from 'recompose';
import {
  DashboardFilter,
  DeadlineCard,
  DeclinedStopsCard,
  DriverInfoCard,
  GeneralInfoIcon,
  ProblemNotificationCard,
  RefreshButton,
  StopAndTourCard
} from '../../../components';
import {withTranslation} from 'react-i18next';
import {ClientService} from '../../../services/client/clientService';
import {clientTypes} from '../../../services/client/clientTypes';
import {ShipperQueryService} from '../../../services/backend/shipperQueryService';
import {useQuery} from '@apollo/react-hooks';
import {displayModes} from '../../../services/enums/displayModes';
import {AuthService} from '../../../services/auth/authService';
import {TourService} from '../../../services/backend/tourService';
import {useStopHub} from '../../../hooks/useStopHub';
import {messageRelevantForDashboardFilter} from '../../../services/util/signalRMessageHelper';
import moment from 'moment';
import useCarriersWithHubs from '../../../hooks/useCarriersWithHubs';
import {DatePickerDefaultValueContext} from '../../../context';
import useMicroHubData from '../../../hooks/useMicroHubData';

const clientShipper = ClientService.getClient(clientTypes.shipper);

function Dashboard(props) {

  const {classes, t} = props;
  const {fromDateDefault, toDateDefault, updateDefaultDate} = useContext(DatePickerDefaultValueContext);

  const [refresh, setRefresh] = useState(false);
  const [filter, setFilter] = useState({
    carrierName: '',
    shipperName: AuthService.getUserOrganization(),
    microHubName: '',
    fromDate: fromDateDefault,
    toDate: toDateDefault
  });
  useEffect(() => updateDefaultDate(filter.fromDate), [filter.fromDate, updateDefaultDate]);
  const [backendLoadingStops, setBackendLoadingStops] = useState(false);
  const [stops, setStops] = useState([]);
  const [problemNotifications, setProblemNotifications] = useState([]);
  const [backendLoadingProblemNotifications, setBackendLoadingProblemNotifications] = useState(false);
  const [driverNotificationsData, setDriverNotificationsData] = useState([]);
  const [backendLoadingDriverNotifications, setBackendLoadingDriverNotifications] = useState(false);

  const {carriersWithHubs} = useCarriersWithHubs();
  const {microHubData} = useMicroHubData();

  useStopHub(useCallback(event => {
    if (messageRelevantForDashboardFilter(event, filter)) {
      setRefresh(true);
    }
  }, [filter]));


  const shipperDeadlineQuery = ShipperQueryService.getShipperDeadlinesByShipperNameQuery(filter.shipperName);
  const {loading: loadingShipperDeadlines, error: errorShipperDeadlines, data: dataShipperDeadlines} = useQuery(shipperDeadlineQuery, {client: clientShipper});

  const loadData = useCallback(() => {
    setBackendLoadingStops(true);
    TourService.getStopsWithFilter(filter.carrierName, filter.shipperName, filter.microHubName, null, filter.fromDate, filter.toDate)
      .then(response => response.json())
      .then(response => {
        setStops(response);
        setBackendLoadingStops(false);
        setRefresh(false);
      }, () => {
        alert(t('errorLoadingStops'));
        setBackendLoadingStops(false);
        setRefresh(false);
      });
  }, [filter, t]);

  const loadProblemNotifications = useCallback(() => {
    setBackendLoadingProblemNotifications(true);
    const fromDate = moment(filter.toDate).subtract(7, 'days');
    TourService.getProblemNotifications(filter.carrierName, filter.shipperName, fromDate, filter.toDate)
      .then(response => response.json())
      .then((response) => {
        setProblemNotifications(response);
        setBackendLoadingProblemNotifications(false);
      }, () => {
        alert(t('errorLoadingProblemNotifications'));
        setBackendLoadingProblemNotifications(false);
      })
  }, [filter.carrierName, filter.toDate, filter.shipperName, t]);

  const loadDriverNotifications = useCallback(async () => {
    setBackendLoadingDriverNotifications(true);
    const fromDate = moment(filter.toDate).subtract(7, 'days');
    try {
      const data = await TourService.getDriverNotificationsByFilter(filter.carrierName, filter.shipperName, fromDate, filter.toDate);
      setDriverNotificationsData(data);
      setBackendLoadingDriverNotifications(false)
    } catch (e) {
      setBackendLoadingDriverNotifications(false)
      alert(`${t('errorLoadingDriverNotifications')}: ${e}`)
    }
  }, [filter.carrierName, filter.toDate, filter.shipperName, t]);


  useEffect(() => {
    loadDriverNotifications();
  }, [loadDriverNotifications]);

  useEffect(() => {
    loadProblemNotifications()
  }, [loadProblemNotifications])

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    loadData();
  }, [filter, loadData]);

  return (
    <SideNavLayout title={t('dashboard')}>
      <div className={classes.root}>
        <div className={classes.helpWrapper}>
          <GeneralInfoIcon/>
          <RefreshButton
            className={classes.buttonRight}
            refresh={refresh}
            refreshFunc={loadData}
          />
        </div>
        <DashboardFilter
          carriersWithMicroHubs={carriersWithHubs}
          filter={filter}
          setFilter={setFilter}
        />
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            lg={8}
            sm={12}
            xl={8}
            xs={12}
          >
            <StopAndTourCard
              className={classes.component}
              dataMicroHubs={microHubData}
              displayMode={displayModes.shipper}
              isLoading={backendLoadingStops}
              stops={stops}
              tours={[]}
            />
          </Grid>
          <Grid
            item
            lg={4}
            sm={12}
            xl={4}
            xs={12}
          >
            <DeadlineCard
              className={classes.component}
              deadlineData={dataShipperDeadlines && dataShipperDeadlines.shippers ? dataShipperDeadlines.shippers.filter(shipper => shipper.name === AuthService.getUserOrganization()) : []}
              displayMode={displayModes.shipper}
              error={errorShipperDeadlines}
              isLoading={loadingShipperDeadlines}
            />
          </Grid>
          <Grid
            item
            lg={8}
            sm={12}
            xl={8}
            xs={12}
          >
            <DeclinedStopsCard
              className={classes.component}
              displayMode={displayModes.shipper}
              shipperName={filter.shipperName}
            />
          </Grid>
          <Grid
            item
            lg={4}
            sm={12}
            xl={4}
            xs={12}
          >
            <DriverInfoCard
              className={classes.component}
              isLoading={backendLoadingDriverNotifications}
              messages={driverNotificationsData}
            />
          </Grid>
          <Grid
            item
            lg={4}
            sm={12}
            xl={4}
            xs={12}
          >
            <ProblemNotificationCard
              className={classes.component}
              displayMode={displayModes.shipper}
              isLoading={backendLoadingProblemNotifications}
              problems={problemNotifications}
            />
          </Grid>
        </Grid>
      </div>
    </SideNavLayout>
  );

}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(Dashboard);
