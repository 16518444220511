import React from 'react'
import {List, ListItem, ListItemIcon, ListItemText, Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {FiberManualRecord as BulletPoint} from '@material-ui/icons';

function WeekDayShipperListShow(props) {

  const {classes, className, weekDayShipperList, t} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <div><strong>{t(weekDayShipperList.weekDay.toLowerCase())}</strong></div>
      <div><strong>{weekDayShipperList.allowList ? t('allowedShippers') : t('forbiddenShippers')}</strong>:</div>
      <List>
        {weekDayShipperList.shippers.map((shipper, index) =>
          <ListItem
            key={index}
          >
            <ListItemIcon
              className={classes.bulletPoint}
            >
              <BulletPoint
                style={{width: '12px'}}
              />
            </ListItemIcon>
            <ListItemText primary={shipper.name}/>
          </ListItem>
        )}
        {!weekDayShipperList.shippers.length &&
        <Typography>
          {t('nothingToSee')}
        </Typography>
        }
      </List>
    </div>
  );
}


WeekDayShipperListShow.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  weekDayShipperList: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(WeekDayShipperListShow);
