import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, Typography, IconButton, Collapse, Table, TableBody, withStyles } from '@material-ui/core';
import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon } from '@material-ui/icons';
import {DateService} from "../../../../../services/util/DateService";
import styles from './styles';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";

function MicroHubMeasurementRow(props) {
  const {t, classes, microHubMeasurementData } = props;

  const [expand, setExpand] = useState(false);

  const handleExpandClick = () => {
    setExpand(!expand);
  };

  return (
      <Fragment>
        <TableRow>
          <TableCell>
            <Typography><strong>{microHubMeasurementData.microHubName}</strong></Typography>
          </TableCell>
          <TableCell>
            <Typography><strong>{microHubMeasurementData.temperature}°C</strong></Typography>
          </TableCell>
          <TableCell>
            <IconButton onClick={handleExpandClick} size="small">
              {expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.innerTableCell} colSpan={3}>
            <Collapse in={expand} timeout="auto" unmountOnExit>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell><Typography>{t('humidity')}</Typography></TableCell>
                    <TableCell><Typography>{microHubMeasurementData.humidity}%</Typography></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><Typography>{t('timeStamp')}</Typography></TableCell>
                    <TableCell><Typography>{DateService.displayDateTime(microHubMeasurementData.timeStamp)}</Typography></TableCell>
                  </TableRow>
                  {microHubMeasurementData.batteryPercentage !== null && 
                      <TableRow>
                        <TableCell><Typography>{t('batteryStatus')}</Typography></TableCell>
                        <TableCell><Typography>{microHubMeasurementData.batteryPercentage}%</Typography></TableCell>
                      </TableRow>
                    }
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
  );
}

MicroHubMeasurementRow.propTypes = {
  classes: PropTypes.object.isRequired,
  microHubMeasurementData: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(MicroHubMeasurementRow);
