import React, {useState} from 'react';
import * as PropTypes from 'prop-types';
import {ProblemNotificationDialogContext} from '../../../../context';
import {displayModes} from '../../../../services/enums/displayModes';
import {userRoles} from '../../../../services/auth/userRoles';
import {AuthService} from '../../../../services/auth/authService';


const ProblemNotificationDialogContextProvider = (props) => {

  const {children} = props;

  const [dialogState, setDialogState] = useState({open: false, typePreset: '', identifierPreset: '', carrierPreset: ''});

  const close = () => {
    setDialogState({open: false, typePreset: '', identifierPreset: '', carrierPreset: ''});
  };

  const open = (typePreset = '', identifierPreset = '', carrierPreset = '') => {
    setDialogState({open: true, typePreset: typePreset, identifierPreset: identifierPreset, carrierPreset: carrierPreset});
  }

  const displayMode = AuthService.getUserRole() === userRoles.carrier ? displayModes.carrier : displayModes.admin;

  return (
    <ProblemNotificationDialogContext.Provider
      value={{
        dialogState: dialogState,
        displayMode: displayMode,
        close: close,
        open: open,
      }}
    >
      {children}
    </ProblemNotificationDialogContext.Provider>
  );
};


ProblemNotificationDialogContextProvider.propTypes = {
  children: PropTypes.node,
};

export default ProblemNotificationDialogContextProvider;
