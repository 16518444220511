import React, {useEffect, useState} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import SideNavLayout from '../../../layouts/SideNavLayout';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {TourService} from '../../../services/backend/tourService';
import DeadlineEdit from './DeadlineEdit';
import DonationButtonApproveDialog from './DonationButtonApproveDialog';
import {AppVersionView} from './AppVersion';
import DummyTrackingEmailDialog from './DummyTrackingEmailDialog';
import ScheduleDeadline from './ScheduleDeadline';
import TourFinalization from './TourFinalization';
import SendPreviewToPcg from './SendPreviewToPcg';
import CreateNewsToUserDialog from './CreateNewsToUserDialog';


function Administration(props) {

  const {classes, t} = props;
  const [dummyTrackingEmailDialogOpen, setDummyTrackingEmailDialogOpen] = useState(false);
  const [newsToUserToCreateOpen, setNewsToUserToCreateOpen] = useState(false);
  const [donationButtonApproveDialogState, setDonationButtonApproveDialogState] = useState({open: false,});
  const [appVersion, setAppVersion] = useState(null);

  useEffect(() => {
    TourService.getAppVersion().then(result => {
      setAppVersion(result);
    })
  }, [])

  const saveAppVersion = (minVersion, currentVersion) => {
    TourService.setAppVersion(minVersion, currentVersion).then(result => {
      setAppVersion(result);
    })
  }

  return (
    <SideNavLayout title={t('administration')}>
      <div className={classes.root}>
        <TourFinalization/>
        <br/>
        <ScheduleDeadline/>
        <br/>
        <DeadlineEdit/>
        <br/>
        <SendPreviewToPcg/>

        <Button
          className={classes.button}
          color="primary"
          onClick={() => setDonationButtonApproveDialogState({...donationButtonApproveDialogState, open: true})}
          variant="contained"
        >
          {t('donationButtonApproveDialog')}
        </Button>
        <DonationButtonApproveDialog
          dialogState={donationButtonApproveDialogState}
          handleClose={() => setDonationButtonApproveDialogState({...donationButtonApproveDialogState, open: false})}
        />
        <br/>
        <Button
          className={classes.button}
          color="primary"
          onClick={() => setDummyTrackingEmailDialogOpen(true)}
          variant="contained"
        >
          {t('sendDummyTrackingEmail')}
        </Button>
        
        <DummyTrackingEmailDialog
          close={() => setDummyTrackingEmailDialogOpen(false)}
          open={dummyTrackingEmailDialogOpen}
        />
        <br/>
        <Button
          className={classes.button}
          color="primary"
          onClick={() => setNewsToUserToCreateOpen(true)}
          variant="contained"
        >
          {t('createNewMessage')}
        </Button>
        
        <CreateNewsToUserDialog
          close = {() => setNewsToUserToCreateOpen(false)}
          open = {newsToUserToCreateOpen}
        />

        {appVersion &&
          <AppVersionView
            appVersion={appVersion}
            saveAppVersion={saveAppVersion}
          />
        }
      </div>
    </SideNavLayout>
  );

}

Administration.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(Administration);
