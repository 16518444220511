export default theme => ({
  header: {},
  content: {
    minHeight: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    paddingBottom: '420px',
    [theme.breakpoints.up('md')]: {
      paddingBottom: '315px',
    }
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  }
});

