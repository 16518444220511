import React, {Fragment, useCallback, useEffect, useState} from 'react'
import {Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {TourService} from '../../../services/backend/tourService';
import {GeneralInfoIcon, LoadingWrapper, PreviewStopDetail, PreviewTourMap, RefreshButton, StopDetails, StopMap} from '../../../components';
import {useStopHub} from '../../../hooks/useStopHub';
import {SideNavLayout} from '../../../layouts';
import {displayModes} from '../../../services/enums/displayModes';
import {userRoles} from '../../../services/auth/userRoles';
import {AuthService} from '../../../services/auth/authService';
import useQueryParams from '../../../hooks/useQueryParams';
import {usePreviewStopHub} from '../../../hooks/usePreviewStopHub';
import {PreviewToursService} from '../../../services/backend/previewToursService';
import useMicroHubOptionsOfCarrier from '../../../hooks/useMicroHubOptionsOfCarrier';

function StopDetailPage(props) {

  const {classes, t} = props;
  const {stopId} = useParams();
  const query = useQueryParams();

  const [finalStopId, setFinalStopId] = useState(null);
  const [previewStopId, setPreviewStopId] = useState(null);

  const [loadingFinalStop, setLoadingFinalStop] = useState(false);
  const [loadingPreviewStop, setLoadingPreviewStop] = useState(false);
  const [finalStop, setFinalStop] = useState(null);
  const [previewStop, setPreviewStop] = useState(null);
  const [refresh, setRefresh] = useState(false);
  // use the hook for carrier with empty organization since the carrier name needs to be pressent if this page is accessed by a carrier
  const {microHubData} = useMicroHubOptionsOfCarrier(AuthService.getUserRole() === userRoles.carrier ? AuthService.getUserOrganization() : null, true);

  const displayMode = AuthService.getUserRole() === userRoles.administration ? displayModes.admin : (AuthService.getUserRole() === userRoles.carrier ? displayModes.carrier : displayModes.shipper);

  useStopHub(useCallback(event => {
    const eventData = JSON.parse(event.toString());
    if (finalStop?.tourStopId === eventData.stopId) {
      setRefresh(true);
    }
  }, [finalStop]));
  usePreviewStopHub(useCallback(event => {
    const eventData = JSON.parse(event.toString());
    if (previewStop?.id === eventData.stopId) {
      setRefresh(true);
    }
  }, [previewStop]))
  // TODO use better query parsing!!
  useEffect(() => {
    if (stopId == null) return; // if there is no stopId there is nothing to load
    const isPreview = query.get('isPreview');
    if (isPreview !== null && isPreview !== undefined) {
      if (isPreview === 'true') {
        setPreviewStopId(stopId)
      } else {
        setFinalStopId(stopId)
      }
    } else {
      // if isPreview is not there assume it is a final stop
      setFinalStopId(stopId);
    }
  }, [stopId, query])

  const updateFinalStop = (stop) => {
    setLoadingFinalStop(true);
    TourService.updateStop(stop, stop.tourStopId).then((response) => response.json()).then((response) => {
      setFinalStop(response);
      setLoadingFinalStop(false);
      setRefresh(false);
    }, () => {
      setLoadingFinalStop(false);
      setRefresh(false);
      alert(t('errorUpdatingStop'));
    });
  };

  const loadFinalStop = useCallback(async () => {
    if (finalStopId == null || finalStop) return;
    setLoadingFinalStop(true);
    try {
      const response = await TourService.getStopById(finalStopId);
      setFinalStop(response);
      // load preview stop async if not already present
      if (response.previewStopIdentifier && !previewStop) loadPreviewStopByIdentifier(response.previewStopIdentifier);
      setLoadingFinalStop(false);
      setRefresh(false);
    } catch (e) {
      setLoadingFinalStop(false);
      setRefresh(false);
      alert(t('errorLoadingStop'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalStopId, t])

  const loadPreviewStop = useCallback(async () => {
    if (previewStopId == null || previewStop) return;
    setLoadingFinalStop(true);
    try {
      const response = await PreviewToursService.getStopById(previewStopId);
      setPreviewStop(response);
      // load final stop async if not already present
      if (!finalStop) loadFinalStopByIdentifier(response.identifier);
      setLoadingPreviewStop(false);
      setRefresh(false);
    } catch (e) {
      setLoadingPreviewStop(false);
      setRefresh(false);
      alert(t('errorLoadingPreviewStop'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewStopId, t])

  const loadFinalStopByIdentifier = async (identifier) => {
    setLoadingFinalStop(true);
    try {
      const response = await TourService.getStopByPreviewStopIdentifier(identifier);
      setFinalStop(response);
      setFinalStopId(response.tourStopId);
      setLoadingFinalStop(false);
      setRefresh(false);
    } catch (e) {
      setLoadingFinalStop(false);
      setRefresh(false);
      console.warn(`No final stop with identifier ${identifier} found`)
    }
  };

  const loadPreviewStopByIdentifier = async (identifier) => {
    setLoadingFinalStop(true);
    try {
      const response = await PreviewToursService.getPreviewStopByPreviewStopIdentifier(identifier);
      setPreviewStop(response);
      setPreviewStopId(response.id);
      setLoadingPreviewStop(false);
      setRefresh(false);
    } catch (e) {
      setLoadingPreviewStop(false);
      setRefresh(false);
      console.warn(`No preview stop with identifier ${identifier} found`)
    }
  };

  useEffect(() => {
    loadFinalStop();
  }, [loadFinalStop])

  useEffect(() => {
    loadPreviewStop();
  }, [loadPreviewStop])

  const performRefresh = () => {
    if (previewStopId) loadPreviewStop();
    if (finalStopId) loadFinalStop();
  };

  return (
    <SideNavLayout title={t('stopDetails')}>
      <div className={classes.root}>
        <div className={classes.helpWrapper}>
          <RefreshButton
            refresh={refresh}
            refreshFunc={performRefresh}
          />
          <GeneralInfoIcon/>
        </div>
        <LoadingWrapper loading={loadingFinalStop}>
          {finalStop !== null &&
          <Fragment>
            <Typography
              className={classes.heading}
              variant={'h3'}
            >{t('final')}</Typography>
            <StopDetails
              className={classes.pageElement}
              editDisplayMode={displayMode}
              microHubSelectOptions={microHubData}
              readOnly={displayMode === displayModes.shipper}
              stop={finalStop}
              updateStop={displayMode !== displayModes.shipper ? updateFinalStop : () => {
              }}
            />
          </Fragment>
          }
        </LoadingWrapper>
        <LoadingWrapper loading={loadingPreviewStop}>
          {previewStop !== null &&
          <Fragment>
            <Typography
              className={classes.heading}
              variant={'h3'}
            >{t('preview')}</Typography>
            <PreviewStopDetail
              className={classes.pageElement}
              stop={previewStop}
            />
          </Fragment>
          }
        </LoadingWrapper>
        <LoadingWrapper loading={loadingPreviewStop || loadingFinalStop}>
          <Fragment>
            {finalStop &&
            <StopMap
              className={classes.map}
              microHubs={microHubData.filter(hub => hub.name === finalStop.assignedMicroHubName)}
              stops={[finalStop]}
              stopStatusFilter={finalStop.stopStatus}
            />
            }
            {(previewStop && !finalStop) &&
            <PreviewTourMap
              className={classes.map}
              microHubs={microHubData.filter(hub => hub.name === previewStop.assignedMicroHubName)}
              setSelectedStop={() => {
              }}
              stopsWithoutTour={[previewStop]}
              tours={[]}
            />
            }
          </Fragment>
        </LoadingWrapper>
      </div>
    </SideNavLayout>
  );
}

StopDetailPage.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(StopDetailPage);
