import React, {Fragment} from 'react'
import {Table, TableBody, TableCell, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {
  Portlet,
  PortletContent,
  TableCellWithConfig,
  TableSettings,
  TextPlaceholderWrapper,
} from '../../../../../../components'
import {Droppable} from 'react-beautiful-dnd';
import {DndHelperElementTypes, DndHelperService} from '../../../../../../services/dndHelper/dndHelperService';
import {ArrowForward as DropIcon} from '@material-ui/icons'
import Columns from './columns';
import useTableConfig from '../../../../../../hooks/useTableConfig';
import DefaultConfig from './config';
import {displayModes} from '../../../../../../services/enums/displayModes';
import {getTableKey} from '../../../../../../services/util/tableConfigHelper';
import {TableKind} from '../../../../../../services/enums/TableKind';

function TourTableSmall(props) {

  const {classes, className, tours, t, selectTour, selectedTour} = props;

  const {
    config,
    columnConfigs,
    updateConfig
  } = useTableConfig(DefaultConfig, displayModes.carrier, getTableKey(TableKind.TourTableSmall, displayModes.carrier))


  const rootClassName = classNames(classes.root, className);

  return (
    <TextPlaceholderWrapper
      active={!tours?.length}
      text={t('noToursAvailable')}
    >
      <div style={{position: 'relative'}}>
        <TableSettings
          updateConfig={updateConfig}
          tableConfig={config}
          columns={Columns}
          className={classes.tableSettings}
          buttonOptic
        />
        <Portlet className={rootClassName}>
          <PortletContent noPadding>
            <div style={{overflow: 'auto', maxHeight: '300px'}}>
              <Table
                size="small"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    {columnConfigs.map(columnConfig => (
                      <TableCellWithConfig
                        key={columnConfig.order}
                        config={columnConfig}
                        className={classes.tableHeader}
                      >
                        {Columns[columnConfig.columnName].header({t})}
                      </TableCellWithConfig>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tours.map(tour => (
                    <Droppable
                      droppableId={DndHelperService.getUniqueTourId('{"isTourTable": true}', tour.tourId)}
                      key={tour.tourId}
                      type={DndHelperElementTypes.Tour}
                    >
                      {(provided, snapshot) => (
                        <TableRow
                          className={classNames(classes.tableRow, 'cursorPointer')}
                          hover
                          id={`Tour-${tour.tourId}`}
                          key={tour.tourId}
                          onClick={() => selectTour(tour)}
                          ref={provided.innerRef}
                          selected={selectedTour && selectedTour.tourId === tour.tourId}
                          style={{boxShadow: `inset 10px 0px 0px 0px ${tour.color}`}}
                          {...provided.droppableProps}
                        >
                          {snapshot.isDraggingOver ?
                            <TableCell
                              className={classes.tableCell}
                              colSpan={7}
                              style={{backgroundColor: tour.color}}
                            >
                              <DropIcon/>
                            </TableCell>
                            :
                            <Fragment>
                              {columnConfigs.map(columnConfig => (
                                <TableCellWithConfig
                                  key={columnConfig.order}
                                  config={columnConfig}
                                >
                                  {Columns[columnConfig.columnName].body({tour, classes})}
                                </TableCellWithConfig>
                              ))}
                            </Fragment>
                          }
                        </TableRow>
                      )}
                    </Droppable>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PortletContent>
        </Portlet>
      </div>
    </TextPlaceholderWrapper>
  );
}


TourTableSmall.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  selectTour: PropTypes.func,
  selectedTour: PropTypes.object,
  t: PropTypes.func.isRequired,
  tours: PropTypes.array.isRequired,
};

TourTableSmall.defaultProps = {
  tours: [],
};


export default compose(withStyles(styles), withTranslation())(TourTableSmall);
