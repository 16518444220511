import React, {useState} from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {Cancel as CancelIcon, PlayArrow as SaveIcon} from '@material-ui/icons';
import {onCloseWithBackdropCurry} from '../../../../../services/util/helperFuncs';

function RerunAlgorithmForHubDialog(props) {

  const {classes, t, dialogState, handleCancel, handleClose} = props;
  const [useTourTemplates, setUseTourTemplates] = useState(true);
  const [useAlgorithm, setUseAlgorithm] = useState('null');

  const dialogOk = () => {
    handleClose(dialogState.hub, useTourTemplates, useAlgorithm === 'null' ? null : useAlgorithm);
  };

  const handleNotOk = () => {
    handleCancel();
  };

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={dialogState.open}
    >
      <DialogTitle>{`${dialogState.hub?.microHubName} ${t('runAlgoForHub')}`}</DialogTitle>
      <DialogContent>
        <Typography>{t('rerunAlgoDescription')}</Typography>
        <div className={classes.controlContainer}>
          <Tooltip
            placement={'left'}
            title={t('useAlgorithmInfo')}
          >
            <FormControl className={classes.formControlSelect}>
              <InputLabel>{t('useAlgorithm')}</InputLabel>
              <Select
                onChange={(event) => setUseAlgorithm(event.target.value)}
                value={useAlgorithm}
              >
                <MenuItem
                  value={'null'}
                >
                  {t('default')}
                </MenuItem>
                <MenuItem
                  value
                >
                  {t('yes')}
                </MenuItem>
                <MenuItem
                  value={false}
                >
                  {t('no')}
                </MenuItem>
                })}
              </Select>
            </FormControl>
          </Tooltip>
          <Tooltip title={t('useTourTemplatesInfo')}>
            <FormControlLabel
              className={classes.formControlCheckbox}
              control={
                <Checkbox
                  checked={useTourTemplates}
                  color="secondary"
                  onChange={(event) =>
                    setUseTourTemplates(event.target.checked)
                  }
                  value={useTourTemplates}
                />
              }
              label={t('useTourTemplates')}
            />
          </Tooltip>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={handleNotOk}
        >
          <CancelIcon/>&nbsp;{t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          onClick={dialogOk}
        >
          <SaveIcon/>&nbsp;{t('ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

RerunAlgorithmForHubDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogState: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(RerunAlgorithmForHubDialog);
