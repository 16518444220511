import React, {Fragment} from 'react'
import {Button, Divider, FormControl, Input, InputLabel, MenuItem, Select, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {PortletContent, PortletHeader} from '../../../../../../components';
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import WeekDayShipperListEdit from '../WeekDayShipperListEdit';
import {dayOfWeekSorterFunction} from '../../../../../../services/enums/dayOfWeekHelper';
import OperatingTimeEdit from '../OperatingTimeEdit';

function MicroHubDetailEdit(props) {

  const {classes, className, editedMicroHub, setEditMode, t, updateMicroHub, setEditedMicroHub, selectedCarrier, setSelectedCarrier, shipperNames} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <Fragment>
      <PortletHeader className={rootClassName}>
        <Fragment>
          <div><strong>{editedMicroHub.name}</strong></div>
          <div>
            <Button
              className={classes.button}
              color="default"
              onClick={() => setEditMode(false)}
              title={t('cancel')}
              variant="contained"
            >
              <CancelIcon/>&nbsp;{t('cancel')}
            </Button>
            <Button
              className={classes.button}
              color="primary"
              onClick={updateMicroHub}
              title={t('microHubSave')}
              variant="contained"
            >
              <SaveIcon/>
            </Button>
          </div>
        </Fragment>
      </PortletHeader>
      <PortletContent noPadding>
        <div className={classes.wrapper}>
          <div className={classes.wrapperChild}>
            <div className={classes.vehicleDetail}><strong>{t('microHubName')}</strong>: {editedMicroHub.name}</div>
            <div className={classes.vehicleDetail}><strong>{t('email')}</strong>: {editedMicroHub.email}</div>
            <div className={classes.vehicleDetail}><strong>{t('carrier')}</strong>: {editedMicroHub.carriers.map(carrier => carrier.carrier.name).join(' ,')}
            </div>
            <div className={classes.vehicleDetail}><strong>{'B2B'}</strong>: {t(editedMicroHub.b2B ? t('yes') : t('no'))}</div>
            <div className={classes.vehicleDetail}><strong>{'B2C'}</strong>: {t(editedMicroHub.b2C ? t('yes') : t('no'))}</div>
          </div>
          <div className={classes.wrapperChild}>
            <div className={classes.vehicleDetail}>
              <div><strong>{t('address')}</strong>:</div>
              {editedMicroHub.address &&
              <Fragment>
                <div className={classes.vehicleDetail}>
                  <strong>{t('streetAndNumber')}</strong>: {`${editedMicroHub.address.street} ${editedMicroHub.address.houseNumber}`}
                </div>
                <div className={classes.vehicleDetail}><strong>{t('zipcode')}</strong>: {editedMicroHub.address.zipcode}</div>
                <div className={classes.vehicleDetail}><strong>{t('city')}</strong>: {editedMicroHub.address.city}</div>
                <div className={classes.vehicleDetail}><strong>{t('country')}</strong>: {editedMicroHub.address.country}</div>
              </Fragment>
              }
            </div>
          </div>
          <div className={classes.wrapperChild}>
            <div className={classes.vehicleDetail}>
              <div><strong>{t('contact')}</strong>:</div>
              {editedMicroHub.contact &&
              <Fragment>
                <div className={classes.vehicleDetail}><strong>{t('name')}</strong>: {editedMicroHub.contact.name}</div>
                <div className={classes.vehicleDetail}><strong>{t('email')}</strong>: {editedMicroHub.contact.email}</div>
                <div className={classes.vehicleDetail}><strong>{t('phoneNumber')}</strong>: {editedMicroHub.contact.phoneNumber}</div>
              </Fragment>
              }
            </div>
          </div>
        </div>
        <Divider/>
        <div className={classes.wrapper}>
          <div className={classes.wrapperChild}>
            <FormControl className={classes.formControl}>
              <InputLabel id="detail-select-carrier">{t('carrier')}</InputLabel>
              <Select
                labelId="detail-select-carrier"
                onChange={(event) => {
                  setSelectedCarrier(event.target.value)
                }}
                value={selectedCarrier}
              >
                {editedMicroHub.carriers.map((carrier, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={index}
                    >
                      {carrier.carrier.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <br/>
            <Button
              className={classes.button}
              color="secondary"
              onClick={() => setEditedMicroHub({
                ...editedMicroHub,
                carriers: editedMicroHub.carriers.map((c, i) => i === selectedCarrier ? {
                  ...c,
                  weekDayShipperLists: c.weekDayShipperLists.map(l => true && {...l, microHubIsOpen: false})
                } : c)
              })}
              title={t('closeMicroHub')}
              variant="contained"
            >
              {t('closeMicroHub')}
            </Button>
          </div>
          <OperatingTimeEdit
            className={classes.wrapperChild}
            editedMicroHub={editedMicroHub}
            selectedCarrier={selectedCarrier}
            setEditedMicroHub={setEditedMicroHub}
          />
          <div className={classes.wrapperChild}>
            {editedMicroHub.carriers[selectedCarrier] &&
            <div className={classes.vehicleDetail}>
              <div><strong>{t('disponent')}</strong>:</div>
              {editedMicroHub.carriers[selectedCarrier].disponent &&
              <div>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="name">{t('name')}</InputLabel>
                  <Input
                    id="name"
                    onChange={(event) => {
                      setEditedMicroHub({
                        ...editedMicroHub,
                        carriers: editedMicroHub.carriers.map((c, i) => i === selectedCarrier ? {
                          ...c,
                          disponent: {...c.disponent, name: event.target.value}
                        } : c)
                      })
                    }}
                    value={editedMicroHub.carriers[selectedCarrier].disponent.name}
                  />
                </FormControl>
                <br/>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="email">{t('email')}</InputLabel>
                  <Input
                    id="email"
                    onChange={(event) => {
                      setEditedMicroHub({
                        ...editedMicroHub,
                        carriers: editedMicroHub.carriers.map((c, i) => i === selectedCarrier ? {
                          ...c,
                          disponent: {...c.disponent, email: event.target.value}
                        } : c)
                      })
                    }}
                    value={editedMicroHub.carriers[selectedCarrier].disponent.email}
                  />
                </FormControl>
                <br/>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="phoneNumber">{t('phoneNumber')}</InputLabel>
                  <Input
                    id="phoneNumber"
                    onChange={(event) => {
                      setEditedMicroHub({
                        ...editedMicroHub,
                        carriers: editedMicroHub.carriers.map((c, i) => i === selectedCarrier ? {
                          ...c,
                          disponent: {...c.disponent, phoneNumber: event.target.value}
                        } : c)
                      })
                    }}
                    value={editedMicroHub.carriers[selectedCarrier].disponent.phoneNumber}
                  />
                </FormControl>
              </div>
              }
            </div>}
          </div>
        </div>
        <div className={classes.wrapper}>
          {editedMicroHub.carriers[selectedCarrier] &&
          editedMicroHub.carriers[selectedCarrier].weekDayShipperLists.sort((a, b) => dayOfWeekSorterFunction(a.weekDay, b.weekDay)).map((list, index) =>
            <WeekDayShipperListEdit
              className={classes.wrapperChild}
              editedMicroHub={editedMicroHub}
              key={index}
              selectedCarrier={selectedCarrier}
              setEditedMicroHub={setEditedMicroHub}
              shipperNames={shipperNames}
              weekDayId={index}
            />
          )
          }
        </div>
      </PortletContent>
    </Fragment>
  );
}


MicroHubDetailEdit.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  editedMicroHub: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  setEditMode: PropTypes.func.isRequired,
  setEditedMicroHub: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  selectedCarrier: PropTypes.number.isRequired,
  setSelectedCarrier: PropTypes.func.isRequired,
  updateMicroHub: PropTypes.func.isRequired,
  shipperNames: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withTranslation())(MicroHubDetailEdit);
