import {compose} from 'recompose';
import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import L from 'leaflet';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import {useLeaflet} from 'react-leaflet';

L.PolylineClusterable = L.Polyline.extend({
  _originalInitialize: L.Polyline.prototype.initialize,

  initialize: function (latlngs, options) {
    this._originalInitialize(latlngs, options);
    this._latlng = this.getBounds().getCenter();
  },

  getLatLng: function () {
    return this._latlng;
  },

  setLatLng: function () {
  }
});

const TourMarkerCluster = (props) => {

  const {stops, polyline, tour, t, getClusterIcon, getTourStopMarkerAndAddToMcg, useCluster} = props;

  const mcg = L.markerClusterGroup({
    iconCreateFunction: function () {
      return getClusterIcon(tour);
    },
    maxClusterRadius: 1000,
    showCoverageOnHover: false,
    disableClusteringAtZoom: useCluster ? 14 : 1,
    spiderfyOnMaxZoom: false
  });


  const {map} = useLeaflet();

  useEffect(() => {
    mcg.clearLayers();
    new L.PolylineClusterable(polyline.positions, {color: polyline.color})
      .addTo(mcg);
    stops.forEach(stop => getTourStopMarkerAndAddToMcg(tour, stop, mcg, t /*useSTopStatus!*/));
    map.addLayer(mcg);
    return function cleanup() {
      map.removeLayer(mcg);
    }
  }, [stops, polyline, getTourStopMarkerAndAddToMcg, tour, t, mcg, map]);

  return null;
};

TourMarkerCluster.propTypes = {
  getClusterIcon: PropTypes.func.isRequired,
  getTourStopMarkerAndAddToMcg: PropTypes.func.isRequired,
  polyline: PropTypes.object.isRequired,
  stops: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  tour: PropTypes.object.isRequired,
  useCluster: PropTypes.bool.isRequired,
};

export default compose(withTranslation())(TourMarkerCluster);
