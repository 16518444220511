import React from 'react'
import {FormControl, IconButton, InputAdornment, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {DropdownSelect, DropdownSelectSimple, InfoTooltip} from '../../../components';
import {Today as DateIcon} from '@material-ui/icons';

function TourFilterSmall(props) {

  const {
    classes, className, t, filter, setFilter, microHubOptions, disabled, setTourTemplateFilter, tourTemplateFilter, tourTemplateOptions, microHubRequired
  } = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <form>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className={classes.rowWrapper}>
            <InfoTooltip descriptionKey={'18'}>
              <FormControl className={classes.formControlMedium}>
                <DatePicker
                  cancelLabel={t('dialogCancel')}
                  disabled={disabled}
                  error={!filter.fromDateTime}
                  format="dd.MM.yyyy"
                  id="tourDeliveryDateFrom"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton><DateIcon/> </IconButton>
                      </InputAdornment>
                    )
                  }}
                  label={t('date')}
                  margin="normal"
                  onChange={(event) => {
                    setFilter({
                      ...filter,
                      fromDateTime: new Date(event),
                      toDateTime: new Date(new Date(event).getFullYear(), new Date(event).getMonth(), new Date(event).getDate(), filter.toDateTime.getHours(), filter.toDateTime.getMinutes())
                    })
                  }}
                  required
                  value={filter.fromDateTime}
                />
              </FormControl>
            </InfoTooltip>
            <DropdownSelectSimple
              descriptionKey={'5'}
              disabled={disabled}
              error={microHubRequired && !filter.microHubName}
              hideOnEmptyOptions
              name={t('microHub')}
              options={microHubOptions}
              required={microHubRequired}
              setValue={(value) => setFilter({...filter, microHubName: value})}
              sort
              value={filter.microHubName}
            />
            <DropdownSelect
              disabled={!filter.microHubName}
              hideOnEmptyOptions
              name={t('tourTemplate')}
              options={tourTemplateOptions.map(t => ({
                value: t.tourTemplateId,
                name: `${t.tourTemplateId}-${t.tourTemplateName}`
              }))}
              setValue={setTourTemplateFilter}
              value={tourTemplateFilter}
            />
          </div>
        </MuiPickersUtilsProvider>
      </form>
    </div>
  );
}


TourFilterSmall.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  filter: PropTypes.object.isRequired,
  microHubOptions: PropTypes.array,
  microHubRequired: PropTypes.bool,
  setFilter: PropTypes.func.isRequired,
  setTourTemplateFilter: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  tourTemplateFilter: PropTypes.any.isRequired,
  tourTemplateOptions: PropTypes.array.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TourFilterSmall);
