export default theme => ({
  root:{
    '& > *': {
      borderBottom: 'unset',
    }
  },
  lastTableCell: {
    paddingRight: theme.spacing(0),
  },
  buttonLeft: {
    paddingLeft: theme.spacing(1)
  },
  buttonRight: {
    float: 'right',
    paddingRight: theme.spacing(1)
  },
  largeTableCell: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5)
  },
  dangerColor: {
    color: theme.palette.danger.main
  },
  alignText: {
    display: 'flex',
    alignItems: 'center'
  }
});
