import React, {useCallback, useContext, useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import SideNavLayout from '../../../layouts/SideNavLayout';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {
  DataLoadingWrapper,
  GeneralInfoIcon,
  MicroHubPlanAndTourFilter,
  MicroHubPlanWithTours,
  RefreshButton,
  TextPlaceholderWrapper
} from '../../../components';
import {MicroHubElementTypes, Offset} from '../../../services/hubAdministrationHelper/hubAdministrationHelper';
import {useQuery} from '@apollo/react-hooks';
import {ClientService} from '../../../services/client/clientService';
import {clientTypes} from '../../../services/client/clientTypes';
import {v4 as uuidv4} from 'uuid';
import {AuthService} from '../../../services/auth/authService';
import {MicroHubQueryService} from '../../../services/backend/microHubQueryService';
import {TourService} from '../../../services/backend/tourService';
import {StopStatus} from '../../../services/enums/StopStatus';
import {TourStatus} from '../../../services/enums/TourStatus';
import {useStopHub} from '../../../hooks/useStopHub';
import {messageRelevantForMicroHubOverviewFilter} from '../../../services/util/signalRMessageHelper';
import {useTourHub} from '../../../hooks/useTourHub';
import useShipperOptions from '../../../hooks/useShipperOptions';
import {DatePickerDefaultValueContext} from '../../../context';
import useMicroHubOptionsOfCarrier from '../../../hooks/useMicroHubOptionsOfCarrier';

const clientMicroHub = ClientService.getClient(clientTypes.microHub);

function MicroHubOverview(props) {

  const {classes, t} = props;
  const {fromDateDefault, toDateDefault, updateDefaultDate} = useContext(DatePickerDefaultValueContext);

  const [refresh, setRefresh] = useState(false);
  const [microHubPlans, setMicroHubPlans] = useState([]);
  const [tours, setTours] = useState([]);
  const [notPlanedStops, setNotPlanedStops] = useState([]);
  const [canNotDeliverStops, setCanNotDeliverStops] = useState([]);
  const {shipperOptions} = useShipperOptions(true);
  const [filter, setFilter] = useState({
    carrierName: AuthService.getUserOrganization(),
    microHubName: '',
    shipperName: '',
    fromDateTime: fromDateDefault,
    toDateTime: toDateDefault,
    tourStatus: TourStatus.Planed,
  });
  useEffect(() => updateDefaultDate(filter.fromDateTime), [filter.fromDateTime, updateDefaultDate]);
  const [backendLoadingTours, setBackendLoadingTours] = useState(false);
  const [search, setSearch] = useState({
    tourId: null,
    stopId: null,
  });
  const {microHubOptions} = useMicroHubOptionsOfCarrier(AuthService.getUserOrganization());

  const microHubPlanQuery = MicroHubQueryService.getMicroHubPlansByCarrierNameAndMicroHubNameQuery(filter.carrierName, filter.microHubName);
  const {error, loading, data: dataMicroHubPlans} = useQuery(microHubPlanQuery, {
    client: clientMicroHub,
    skip: !filter.carrierName || !filter.microHubName
  });

  useStopHub(useCallback(event => {
    if (messageRelevantForMicroHubOverviewFilter(event, filter)) {
      setRefresh(true);
    }
  }, [filter]));

  useTourHub(useCallback(event => {
    if (messageRelevantForMicroHubOverviewFilter(event, filter)) {
      setRefresh(true);
    }
  }, [filter]));

  const loadData = useCallback(() => {
    if (filter.carrierName && filter.microHubName) {
      setBackendLoadingTours(true);
      TourService.getToursWithFilter(filter.carrierName, null, filter.microHubName, filter.fromDateTime, filter.toDateTime).then(response => response.json()).then(response => {
        setTours(response);
        setBackendLoadingTours(false);
        setRefresh(false);
      }, () => {
        alert(t('errorLoadingTours'));
        setBackendLoadingTours(false);
        setRefresh(false);
      });
      TourService.getStopsWithFilterIncludeStoragePlaces(filter.carrierName, null, filter.microHubName, StopStatus.NotPlaned, filter.fromDateTime, filter.toDateTime)
        .then(response => response.json())
        .then(response => {
          setNotPlanedStops(response);
        }, () => {
          alert(t('errorLoadingStops'));
        });
      TourService.getCanNotDeliverStopsWithEndedTourWithFilter(filter.carrierName, null, filter.microHubName, filter.fromDateTime, filter.toDateTime)
        .then(response => response.json())
        .then(response => {
          setCanNotDeliverStops(response);
        }, () => {
          alert(t('errorLoadingStops'));
        });
    }
  }, [filter, t]);

  useEffect(() => {
    loadData()
  }, [filter, loadData]);

  useEffect(() => {
    if (dataMicroHubPlans && dataMicroHubPlans.plans && dataMicroHubPlans.plans.hubPlans) {
      dataMicroHubPlans.plans.hubPlans.forEach(plan => {
        if (plan.hubBase) {
          plan.hubBase.type = MicroHubElementTypes.hubBase;

          if (plan.hubBase.door) {
            plan.hubBase.door.positionX = plan.hubBase.door.positionX + Offset.x;
            plan.hubBase.door.positionY = plan.hubBase.door.positionY + Offset.y;
            plan.hubBase.door.type = MicroHubElementTypes.door;
          }
        }

        if (plan.boxZones) {
          plan.boxZones.forEach(zone => {
            zone.tmpId = uuidv4();
            zone.positionX = zone.positionX + Offset.x;
            zone.positionY = zone.positionY + Offset.y;
            zone.type = MicroHubElementTypes.boxZone;
          });
        }

        if (plan.beverageInsertZones) {
          plan.beverageInsertZones.forEach(zone => {
            zone.tmpId = uuidv4();
            zone.positionX = zone.positionX + Offset.x;
            zone.positionY = zone.positionY + Offset.y;
            zone.type = MicroHubElementTypes.beverageInsertZone;
          });
        }

        if (plan.walls) {
          plan.walls.forEach(wall => {
            wall.tmpId = uuidv4();
            wall.positionX = wall.positionX + Offset.x;
            wall.positionY = wall.positionY + Offset.y;
            wall.type = MicroHubElementTypes.wall;
          });
        }

        if (plan.blockerZones) {
          plan.blockerZones.forEach(zone => {
            zone.tmpId = uuidv4();
            zone.positionX = zone.positionX + Offset.x;
            zone.positionY = zone.positionY + Offset.y;
            zone.type = MicroHubElementTypes.blockerZone;
          });
        }
      });
      setMicroHubPlans(dataMicroHubPlans.plans.hubPlans);
    }
  }, [dataMicroHubPlans]);


  return (
    <SideNavLayout title={t('microHubOverview')}>
      <div className={classes.root}>
        <div className={classes.helpWrapper}>
          <GeneralInfoIcon/>
          <RefreshButton
            className={classes.buttonRight}
            refresh={refresh}
            refreshFunc={loadData}
          />
        </div>
        <MicroHubPlanAndTourFilter
          filter={filter}
          microHubOptions={microHubOptions}
          otherStops={notPlanedStops}
          search={search}
          setFilter={setFilter}
          setSearch={setSearch}
          shipperOptions={shipperOptions ? Object.keys(shipperOptions) : []}
          toursWithStop={tours}
        />
        <TextPlaceholderWrapper
          active={!filter.carrierName || !filter.microHubName}
          text={t('microHubOverviewNoMicroHubSelected')}
        >
          <DataLoadingWrapper
            error={error}
            isLoading={loading || backendLoadingTours}
          >
            <MicroHubPlanWithTours
              filter={filter}
              microHubPlans={microHubPlans}
              otherStops={filter.tourStatus ? canNotDeliverStops.concat(notPlanedStops) : notPlanedStops}
              search={search}
              toursWithStops={filter.tourStatus ? tours.filter(tour => tour.tourStatus === TourStatus.Planed) : tours}
            />
          </DataLoadingWrapper>
        </TextPlaceholderWrapper>
      </div>
    </SideNavLayout>
  );

}

MicroHubOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(MicroHubOverview);
