import React from 'react';


const DatePickerDefaultValueContext = React.createContext({
  fromDateDefault: new Date(),
  toDateDefault: new Date(),
  updateDefaultDate: () => {
  },
});

export default DatePickerDefaultValueContext;
