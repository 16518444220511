import React, {Fragment, useState} from 'react'
import {Button, ButtonGroup, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import {Hub} from './components'
import {RefreshButton, TextPlaceholderWrapper} from '../../../components';

function PreviewToursEditOrder(props) {

  const {classes, hubsWithToursAndStops, t, className, cancelEdit, saveEdit, setDisableFilter, refresh, refreshFunc} = props;
  const [dirty, setDirty] = useState(false);
  const [editedData, setEditedData] = useState(null);

  // initialize Data if not yet done
  if (!dirty && hubsWithToursAndStops && JSON.stringify(editedData) !== JSON.stringify(hubsWithToursAndStops)) {
    setEditedData(JSON.parse(JSON.stringify(hubsWithToursAndStops)));
    setDirty(false);
    setDisableFilter(false);
  }

  const rootClassName = classNames(classes.root, className);

  const cancel = () => {
    cancelEdit();
    setEditedData(null);
    setDirty(false);
    setDisableFilter(false);
  };

  const save = () => {
    saveEdit(editedData);
    setEditedData(null);
    setDirty(false);
    setDisableFilter(false);
  };

  const toursOfHubChanged = (affectedHub, newTours) => {
    const newData = JSON.parse(JSON.stringify(editedData));
    const hubIndex = newData.findIndex(hub => hub.microHubName === affectedHub.microHubName && hub.carrierName === affectedHub.carrierName);
    if (hubIndex >= 0) {
      newData[hubIndex].tours = newTours;
      setEditedData(newData);
      setDirty(true);
      setDisableFilter(true);
    }
  };

  return (
    <div className={rootClassName}>
      <div className={classes.controlButtonContainer}>
        <RefreshButton
          className={classes.refreshButton}
          disabled={dirty}
          refresh={refresh}
          refreshFunc={refreshFunc}
        />
        <ButtonGroup
          className={classes.controlButtons}
          color="primary"
          disabled={!dirty}
          variant="contained"
        >
          <Button
            onClick={cancel}
          >
            <CancelIcon/>&nbsp;{t('cancel')}
          </Button>
          <Button
            onClick={save}
          >
            <SaveIcon/>&nbsp;{t('save')}
          </Button>
        </ButtonGroup>
      </div>
      <br/>
      <TextPlaceholderWrapper
        active={!editedData?.length}
        text={t('noFrozenPreviewDataAvailable')}
      >
        {editedData?.length &&
        <Fragment>
          {editedData.map(hub => (
            <Hub
              className={classes.content}
              hub={hub}
              key={hub.microHubName}
              toursOfHubChanged={toursOfHubChanged}
            />
          ))}
        </Fragment>
        }
      </TextPlaceholderWrapper>
    </div>
  );
}


PreviewToursEditOrder.propTypes = {
  cancelEdit: PropTypes.func.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  hubsWithToursAndStops: PropTypes.array.isRequired,
  i18n: PropTypes.object.isRequired,
  refresh: PropTypes.bool.isRequired,
  refreshFunc: PropTypes.func.isRequired,
  saveEdit: PropTypes.func.isRequired,
  setDisableFilter: PropTypes.func,
  t: PropTypes.func.isRequired
};

export default compose(withStyles(styles), withTranslation())(PreviewToursEditOrder);
