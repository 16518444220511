export default theme => ({
  root: {},
  previewTourTable: {
    border: `1px solid ${theme.palette.border}`,
  },
  hr: {
    borderColor: theme.palette.divider
  },
  headerSection: {
    flexBasis: '14%',
    flexShrink: 0,
    overflowX: 'hidden'
  },
  headerSectionWithAlgoButton: {
    flexBasis: '12%',
    flexShrink: 0,
    overflowX: 'hidden'
  },
  headerSectionLarge: {
    flexBasis: '15%',
    flexShrink: 0,
    overflowX: 'hidden'
  },
  headerSectionWithAlgoButtonLarge: {
    flexBasis: '14%',
    flexShrink: 0,
    overflowX: 'hidden'
  },
  button: {
    maxWidth: '150px'
  }, 
  accordionDetail: {
    overflow: 'auto'
  }
});

