import React from 'react'
import {Grid, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {format} from 'date-fns';
import {ImageWithFallback} from '../../../index';
import {getCustomerFriendlyTrackingStepTypeName, TrackingStepType} from '../../../../../../services/enums/TrackingStepType';


function CurrentStatus(props) {

  const {classes, className, lastTrackingStep, logo, marketingImage} = props;

  const rootClassName = classNames(classes.root, className);

  const getStatusText = (trackingStep) => {
    switch (trackingStep.trackingStepType) {
      case TrackingStepType.Delivered:
        return trackingStep.deliverySuccessful ? 'Zugestellt' : 'Nicht Zustellbar';
      case TrackingStepType.Problem:
        return getCustomerFriendlyTrackingStepTypeName(TrackingStepType.Problem);
      case TrackingStepType.PreviewStopUploaded:
      case TrackingStepType.PreviewDeadlineExpired:
      case TrackingStepType.FinalDeadlineExpired:
      case TrackingStepType.TourApproved:
      case TrackingStepType.TourStarted:
      case TrackingStepType.PreviousStopsDelivered:
      default:
        return `${format(new Date(trackingStep.deliveryWindow.from), 'HH:mm')} - ${format(new Date(trackingStep.deliveryWindow.to), 'HH:mm')}`;
    }
  }

  const hasProblem = lastTrackingStep.trackingStepType === TrackingStepType.Problem
    || (lastTrackingStep.trackingStepType === TrackingStepType.Delivered && !lastTrackingStep.deliverySuccessful)

  return (
    <div className={rootClassName}>
      <Grid container>
        <Grid
          item
          md={6}
          xs={12}
        >
          <div className={classes.timeAndLogoContainer}>
            <div className={classes.timeContainer}>
              <Typography variant={'body1'}>Erwartete Ankunft:</Typography>
              <Typography
                color={'primary'}
                variant={'subtitle2'}
              >
                {format(new Date(lastTrackingStep.deliveryWindow.from), 'dd.MM.yyyy')}
              </Typography>
              <Typography
                color={hasProblem ? 'error' : 'primary'}
                variant={'h2'}
              >
                {getStatusText(lastTrackingStep)}
              </Typography>
            </div>
            <ImageWithFallback
              alt={'Versender Logo'}
              className={classes.logo}
              fallbackImageName={'GslLogo.png'}
              imageName={logo}
            />
          </div>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <ImageWithFallback
            alt={'Marketing-Bild des Versenders.'}
            fallbackImageName={'DefaultMarketingImage.png'}
            height={'100%'}
            imageName={marketingImage}
            width={'100%'}
          />
        </Grid>
      </Grid>
    </div>
  );
}

CurrentStatus.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  lastTrackingStep: PropTypes.object.isRequired,
  logo: PropTypes.string,
  marketingImage: PropTypes.string
};


export default withStyles(styles)(CurrentStatus);
