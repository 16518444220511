export default theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dialog: {},
  wrapper: {
    display: 'flex',
    margin: theme.spacing(2)
  },
});
