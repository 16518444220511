import { AppBar, Tab, Tabs, withStyles } from '@material-ui/core';
import styles from './styles'
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { SideNavLayout } from 'layouts';
import React, { useContext, useState } from 'react';
import {
  TemplateStopFilter,
  TemplateStopInstanceFilter,
  TemplateStopInstanceOverview as TemplateStopInstanceOverviewComponent,
  TemplateStopOverview as TemplateStopOverviewComponent,
  TemplateStopTourOverview,
  TemplateStopTourOverviewFilter,
  TemplateStopTourTemplatesFilter,
} from 'components';
import useShipperOptions from 'hooks/useShipperOptions';
import useCarriersWithHubs from 'hooks/useCarriersWithHubs';
import { displayModes } from 'services/enums/displayModes';
import TemplateStopTourTemplatesOverview from 'components/TemplateStopTourTemplates/TemplateStopTourTemplatesOverview';
import { TourFilter } from 'services/enums/TourFilter';
import { DatePickerDefaultValueContext } from 'context';

const tabStates = {
  templateStop: 0,
  templateStopInstance: 1,
  tourTemplates: 2,
  tourOverview: 3
}

function TemplateStopOverview(props) {
  const { classes, t } = props;
  const [tabState, setTabState] = useState(tabStates.templateStop)
  const { shipperOptions } = useShipperOptions();
  const { carriersWithHubs } = useCarriersWithHubs();

  const { fromDateDefault, toDateDefault } = useContext(DatePickerDefaultValueContext);
  const [filter, setFilter] = useState({
    shipperName: '',
    carrierName: '',
    microHubName: '',
    dayOfWeek: '',
    tourFilter: TourFilter.Empty,
    fromDate: fromDateDefault,
    toDate: toDateDefault,
  });
  const [searchInput, setSearchInput] = useState('');

  return (
    <SideNavLayout title={t('templateStopOverview')}>
      <AppBar
        className={classes.appBar}
        color={'secondary'}
        position={'relative'}
      >
        <Tabs
          onChange={(event, newValue) => {
            setTabState(newValue);
          }}
          value={tabState}
        >
          <Tab
            label={t('templateStops')}
          />
          <Tab
            label={t('templateStopInstances')}
          />
          <Tab
            label={t('tourTemplates')} />
          <Tab
            label={t('templateStopTourOverview')}
          />
        </Tabs>
      </AppBar>
      <div className={classes.root}>
        {(() => {
          switch (tabState) {
            case tabStates.templateStop:
              return (
                <div>
                  <TemplateStopFilter
                    filter={filter}
                    searchInput={searchInput}
                    setFilter={setFilter}
                    setSearchInput={setSearchInput}
                    shipperOptions={shipperOptions}
                    useSearch
                  />
                  <br />
                  <TemplateStopOverviewComponent
                    filter={filter}
                    searchInput={searchInput}
                    displayMode={displayModes.admin}
                    shipperOptions={shipperOptions} />
                </div>
              );
            case tabStates.templateStopInstance:
              return (
                <div>
                  <TemplateStopInstanceFilter
                    filter={filter}
                    searchInput={searchInput}
                    setFilter={setFilter}
                    setSearchInput={setSearchInput}
                    shipperOptions={shipperOptions}
                    carriersWithHubs={carriersWithHubs}
                    useSearch
                    displayMode={displayModes.admin}
                  />
                  <br />
                  <TemplateStopInstanceOverviewComponent
                    filter={filter}
                    searchInput={searchInput}
                    displayMode={displayModes.admin} />
                </div>
              );
            case tabStates.tourTemplates:
              return (
                <div>
                  <TemplateStopTourTemplatesFilter
                    className={classes.filter}
                    filter={filter}
                    setFilter={setFilter}
                    shipperOptions={shipperOptions}
                    carriersWithMicroHubs={carriersWithHubs}
                    displayMode={displayModes.admin}
                  />
                  <br />
                  <TemplateStopTourTemplatesOverview
                    filter={filter}
                    displayMode={displayModes.admin} />
                </div>
              );
            case tabStates.tourOverview:
              return (
                <div>
                  <TemplateStopTourOverviewFilter
                    carriersWithMicroHubs={carriersWithHubs}
                    filter={filter}
                    setFilter={setFilter}
                    shipperOptions={shipperOptions}
                    displayMode={displayModes.admin}
                  />
                  <TemplateStopTourOverview
                    filter={filter} 
                    displayMode={displayModes.admin} />
                </div>
              )
            default:
              return (<div>Error</div>);
          }
        })()}
      </div>
    </SideNavLayout>
  );
}

TemplateStopOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TemplateStopOverview);