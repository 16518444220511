import React, {useState} from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Input,
  InputLabel,
  Typography,
  withStyles
} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {Portlet, PortletContent} from '../../../index';
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import {onCloseWithBackdropCurry} from '../../../../services/util/helperFuncs';
import {CreateAddress, CreatePerson} from '../../Shared';

function CreateHubDialog(props) {

  const {classes, t, onCancel, onCreate, CreateHubDialogState} = props;

  const [address, setAddress] = useState({
    street: '',
    houseNumber: '',
    zipcode: '',
    city: '',
    country: '',
  });

  const [contact, setContact] = useState({
    name: '',
    email: '',
    phoneNumber: '',
  });

  const [newHub, setNewHub] = useState({
    hubName: '',
    email: '',
    b2b: false,
    b2c: false,
  });

  const handleClose = () => {
    onCancel();
  };

  const activateOk = () => {
    return newHub.hubName &&
      address.street && address.houseNumber && address.zipcode && address.city && address.country;
  };

  const handleCreate = () => {
    onCreate(newHub, address, contact);
    setAddress({street: '', houseNumber: '', zipcode: '', city: '', country: '',});
    setContact({name: '', email: '', phoneNumber: '',});
    setNewHub({hubName: '', email: '', b2b: false, b2c: false});
  };

  return (
    <Dialog
      disableEscapeKeyDown
      onClose={onCloseWithBackdropCurry(handleClose)}
      open={CreateHubDialogState.open}
    >
      <DialogTitle id="simple-dialog-title">{t('createHub')}</DialogTitle>
      <DialogContent>
        <form className={classes.container}>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              sm={6}
              xs={12}
            >
              <Portlet className={classes.content}>
                <PortletContent>
                  <Typography variant="h3">
                    {t('info')}
                  </Typography>
                  <hr/>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      htmlFor="text-simple"
                      required
                    >{t('hubName')}</InputLabel>
                    <Input
                      error={!newHub.hubName}
                      id="hubName"
                      onChange={(event) => {
                        setNewHub({...newHub, hubName: event.target.value})
                      }}
                      required
                      value={newHub.hubName}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="text-simple">{t('email')}</InputLabel>
                    <Input
                      id="email"
                      onChange={(event) => {
                        setNewHub({...newHub, email: event.target.value})
                      }}
                      value={newHub.email}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <FormLabel component="legend">{t('roles')}</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={newHub.b2b}
                            color="primary"
                            onChange={(event) => {
                              setNewHub({...newHub, b2b: event.target.checked})
                            }}
                            value="b2b"
                          />
                        }
                        label="B2B"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={newHub.b2c}
                            color="primary"
                            onChange={(event) => {
                              setNewHub({...newHub, b2c: event.target.checked})
                            }}
                            value="b2c"
                          />
                        }
                        label="B2C"
                      />
                    </FormGroup>
                  </FormControl>
                </PortletContent>
              </Portlet>
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
            >
              <CreateAddress
                address={address}
                className={classes.content}
                required
                setAddress={setAddress}
                title={t('address')}
              />
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
            >
              <CreatePerson
                className={classes.content}
                person={contact}
                setPerson={setContact}
                title={t('contact')}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={handleClose}
        >
          <CancelIcon/>&nbsp;{t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          disabled={!activateOk()}
          onClick={handleCreate}
        >
          <SaveIcon/>&nbsp;{t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CreateHubDialog.propTypes = {
  CreateHubDialogState: PropTypes.object.isRequired,
  classes: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(CreateHubDialog);
