import React from 'react'
import {Table, TableBody, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles';
import classNames from 'classnames';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {
  Portlet,
  PortletContent,
  TableCellWithConfig,
  TableSettings,
  TextPlaceholderWrapper
} from '../../../../components';
import StopAddTableRow from './StopAddTableRow';
import useTableConfig from '../../../../hooks/useTableConfig';
import DefaultConfig from './config';
import {getTableKey} from '../../../../services/util/tableConfigHelper';
import {TableKind} from '../../../../services/enums/TableKind';
import Columns from './columns';
import {displayModes} from '../../../../services/enums/displayModes';

function StopAddTable(props) {

  const {
    classes,
    className,
    t,
    stopsToAdd,
    expectedHub,
    expectedHubData,
    tourDeliveryDate,
    stopsToDisplay,
    addOrRemoveStop
  } = props;

  const {
    config,
    columnConfigs,
    updateConfig
  } = useTableConfig(
    DefaultConfig,
    displayModes.carrier,
    getTableKey(TableKind.StopAddTable, displayModes.carrier),
  )

  const rootClassName = classNames(classes.root, className);

  return (
    <TextPlaceholderWrapper
      active={!stopsToDisplay?.length}
      text={t('noStopsAvailable')}
    >
      <div style={{position: 'relative'}}>
        <TableSettings
          updateConfig={updateConfig}
          tableConfig={config}
          columns={Columns}
          className={classes.tableSettings}
          buttonOptic
        />
        <Portlet className={rootClassName}>
          <PortletContent noPadding>
            <Table
              size={'small'}
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {columnConfigs.map(columnConfig => (
                    <TableCellWithConfig
                      key={columnConfig.order}
                      config={columnConfig}
                    >
                      {Columns[columnConfig.columnName].header({t})}
                    </TableCellWithConfig>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {stopsToDisplay.map((stop, index) => (
                  <StopAddTableRow
                    addOrRemoveStop={addOrRemoveStop}
                    expectedHub={expectedHub}
                    expectedHubData={expectedHubData}
                    key={index}
                    stop={stop}
                    stopsToAdd={stopsToAdd}
                    stopsToDisplay={stopsToDisplay}
                    tourDeliveryDate={tourDeliveryDate}
                    columnConfigs={columnConfigs}
                  />
                ))}
              </TableBody>
            </Table>
          </PortletContent>
        </Portlet>
      </div>
    </TextPlaceholderWrapper>
  );
}

StopAddTable.propTypes = {
  addOrRemoveStop: PropTypes.func.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  expectedHub: PropTypes.string,
  expectedHubData: PropTypes.object,
  stopsToAdd: PropTypes.array.isRequired,
  stopsToDisplay: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  tourDeliveryDate: PropTypes.string,
};


export default compose(withStyles(styles), withTranslation())(StopAddTable);
