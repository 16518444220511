import React, {Fragment, useState} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {StopDetails, StopMap, StopTable} from '../../../index';
import {displayModes} from '../../../../services/enums/displayModes';


function SimulateTourBuildingComponent(props) {

  const {classes, t, microHubs, displayMode, setActiveStep, stops} = props;

  const [selectedStop, setSelectedStop] = useState(null);


  return (
    <Fragment>
      <StopTable
        className={classes.content}
        selectedStop={selectedStop}
        showDetails={(stop) => setSelectedStop(stop)}
        stops={stops}
        tableDisplayMode={displayMode}
      />
      {selectedStop &&
      <StopDetails
        className={classes.content}
        closeDetailView={() => setSelectedStop(null)}
        editDisplayMode={displayMode}
        readOnly
        stop={selectedStop}
      />
      }
      <StopMap
        className={classes.tourMap}
        microHubs={microHubs ? microHubs : []}
        stops={stops}
      />
      <div className={classes.actionsContainer}>
        <Button
          className={classes.navButton}
          color="primary"
          disabled={!stops || stops.length === 0}
          onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
          variant="contained"
        >
          {t('next')}
        </Button>
      </div>
    </Fragment>
  );

}

SimulateTourBuildingComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  microHubs: PropTypes.array,
  setActiveStep: PropTypes.func.isRequired,
  stops: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(SimulateTourBuildingComponent);
