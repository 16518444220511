import {gql} from 'apollo-boost';

export class VehicleQueryService {


  static deleteVehicleByIdQuery() {
    return gql`
      mutation ($vehicleId: Int!) {
        deleteVehicle(vehicleId: $vehicleId)
      }
    `
  }

  static updateVehicleQuery() {
    return gql`
      mutation ($updatedVehicle: VehicleInputType!) {
        updateVehicle(updatedVehicle: $updatedVehicle) {
          capacity
          carrierName
          description
          id
          licensePlate
          microhubs {
            id
            monday
            tuesday
            wednesday
            friday
            name
            thursday
            saturday
            sunday
          }
          payloadWeight
          type
          volume
          averageSpeedKmh
        }
      }
    `
  }

  static createVehicleQuery() {
    return gql`
      mutation ($vehicleToCreate: VehicleInputType!) {
        createVehicle(vehicleToCreate: $vehicleToCreate) {
          capacity
          carrierName
          description
          id
          licensePlate
          microhubs {
            id
            monday
            tuesday
            wednesday
            friday
            name
            thursday
            saturday
            sunday
          }
          payloadWeight
          type
          volume
          averageSpeedKmh
        }
      }
    `
  }

  static getVehiclesByCarrierNameAndMicroHubListQuery(carrier, microHubNames) {
    const startSymbol = microHubNames && microHubNames.length > 0 ? '"' : '';
    return gql`
     {
      getVehiclesByCarrierNameAndMicroHubList(carrierName: "${carrier}", microhubNames: [${startSymbol}${microHubNames.join('", "')}${startSymbol}]) {
        capacity
        carrierName
        description
        id
        licensePlate
        microhubs {
          name
          id
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
        payloadWeight
        type
        volume
        averageSpeedKmh
      }
    }
    `;
  }

  static getVehiclesByCarrierNameAndMicroHubListForPreviewQuery(carrier, microHubNames) {
    const startSymbol = microHubNames && microHubNames.length > 0 ? '"' : '';
    return gql`
     {
      getVehiclesByCarrierNameAndMicroHubList(carrierName: "${carrier}", microhubNames: [${startSymbol}${microHubNames.join('", "')}${startSymbol}]) {
        capacity
        microhubs {
          name
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
        payloadWeight
        volume
      }
    }
    `;
  }

}
