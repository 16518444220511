import React, {Fragment, useContext} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Portlet, PortletContent, PortletHeader} from '../../../components';
import {Close as CloseIcon, Warning as ProblemNotificationIcon,} from '@material-ui/icons';
import {DateService} from '../../../services/util/DateService';
import 'moment-duration-format';
import {displayAddress, displayShipperAndShipperShortName, roundCapacityValues} from '../../../services/util/helperFuncs';
import PreviewStopIdLabel from '../PreviewStopIdLabel';
import {AuthService} from '../../../services/auth/authService';
import {userRoles} from '../../../services/auth/userRoles';
import {ProblemNotificationType} from '../../../services/enums/ProblemNotificationType';
import {ProblemNotificationDialogContext} from '../../../context';

function PreviewStopDetail(props) {

  const {classes, className, stop, t, closeDetailView} = props;

  const rootClassName = classNames(classes.root, className);
  const {open: openProblemNotificationDialog} = useContext(ProblemNotificationDialogContext);

  return (
    <Portlet className={rootClassName}>
      <PortletHeader>
        <Fragment>
          <div><strong>{t('previewStopID')}: </strong><PreviewStopIdLabel stop={stop}/></div>
          <div>
            {AuthService.getUserRole() !== userRoles.shipper &&
            <Button
              className={classes.warnButton}
              onClick={() => openProblemNotificationDialog(ProblemNotificationType.PreviewStop, stop.id.toString(), stop.assignedCarrierName)}
              title={t('sendProblemNotification')}
              variant="contained"
            >
              <ProblemNotificationIcon/>
            </Button>
            }
            {closeDetailView &&
            <Button
              className={classes.button}
              color="default"
              onClick={closeDetailView}
              title={t('close')}
              variant="contained"
            >
              <CloseIcon/>
            </Button>
            }
          </div>
        </Fragment>
      </PortletHeader>
      <PortletContent noPadding>
        <div className={classes.wrapper}>
          <div className={classes.wrapperChild}>
            <div className={classes.stopDetail}>
              <strong>{t('generalInformation')}:</strong>
              <div className={classes.stopDetail}><strong>{t('shipper')}</strong>: {displayShipperAndShipperShortName(stop)}</div>
              <div className={classes.stopDetail}><strong>{t('carrier')}</strong>: {stop.assignedCarrierName}</div>
              <div className={classes.stopDetail}><strong>{t('microHub')}</strong>: {stop.assignedMicroHubName}</div>
              <div className={classes.stopDetail}><strong>{t('deliveryDate')}</strong>: {DateService.displayDate(stop.deliveryDate)}</div>
              <div className={classes.stopDetail}><strong>{t('previewOrder')}</strong>: {stop.previewOrder}</div>
              <div className={classes.stopDetail}><strong>{t('stopNumber')}</strong>: {stop.stopNumber}</div>
            </div>
          </div>
          <div className={classes.wrapperChild}>
            <div className={classes.stopDetail}>
              <strong>{t('customerData')}:</strong>
              <div className={classes.stopDetail}><strong>{t('firstName')}</strong>: {stop.firstName}</div>
              <div className={classes.stopDetail}><strong>{t('lastName')}</strong>: {stop.lastName}</div>
              <div className={classes.stopDetail}><strong>{t('customerId')}</strong>: {stop.customerId}</div>
              <div className={classes.stopDetail}><strong>{t('internalCustomerId')}</strong>: {stop.internalCustomerId}</div>
              <div className={classes.stopDetail}><strong>{t('address')}</strong>: {displayAddress(stop.address)}</div>
              {AuthService.getUserRole() !== userRoles.carrier && <div className={classes.stopDetail}><strong>{t('email')}</strong>: {stop.eMail}</div>}
            </div>
          </div>
          <div className={classes.wrapperChild}>
            <div className={classes.stopDetail}>
              <strong>{t('stopCapacity')}:</strong>
              <div className={classes.stopDetail}><strong>{t('boxAmount')}</strong>: {roundCapacityValues(stop.stopCapacities.boxAmount)}</div>
              <div className={classes.stopDetail}><strong>{t('weight')}</strong>: {roundCapacityValues(stop.stopCapacities.weight)}</div>
              <div className={classes.stopDetail}><strong>{t('volume')}</strong>: {roundCapacityValues(stop.stopCapacities.volume)}</div>
            </div>
            <br/>
            <div className={classes.stopDetail}>
              <strong>{t('estimatedDeliveryWindow')}</strong>: {stop.estimatedDeliveryWindow && DateService.displayTime(stop.estimatedDeliveryWindow.from)} - {stop.estimatedDeliveryWindow && DateService.displayTime(stop.estimatedDeliveryWindow.to)}
            </div>
            <div className={classes.stopDetail}>
              <strong>{t('editableDeadline')}</strong>: {DateService.displayDateTime(stop.editDeadline)}
            </div>
          </div>
        </div>
      </PortletContent>
    </Portlet>
  );
}


PreviewStopDetail.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  closeDetailView: PropTypes.func,
  stop: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(PreviewStopDetail);
