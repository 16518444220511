import React, {useState} from 'react'
import {Table, TableBody, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Columns from './columns'
import DefaultConfig from './config';
import useTableConfig from 'hooks/useTableConfig';
import { getTableKey } from 'services/util/tableConfigHelper';
import useTablePagination from 'hooks/useTablePagination';
import { Pagination, Portlet, PortletContent, TableCellWithConfig, TableSettings, TextPlaceholderWrapper } from 'components';
import { TableKind } from 'services/enums/TableKind';
import DeleteTemplateStopDialog from '../DeleteTemplateStopDialog';
import { displayModes } from 'services/enums/displayModes';

function TemplateStopTable(props) {

  const [deleteTemplateStopDialogState, setDeleteTemplateStopDialogState] = useState({open: false, templateState: null});

  const {
    classes,
    t,
    showDetails,
    templateStops,
    className,
    deleteTemplateStop,
    tableDisplayMode,
    selectedTemplateStop,
  } = props;

  const {
    config,
    columnConfigs,
    updateConfig
  } = useTableConfig(
    DefaultConfig,
    tableDisplayMode,
    getTableKey(TableKind.TemplateStopTable, tableDisplayMode),
    {
      // Only show options column, if one of the options functions is present
      [Columns.options.name]: Boolean(deleteTemplateStop)
    },
  )

  const {paginationProps, pagedObjects} = useTablePagination(templateStops);

  const deleteTemplateStopDialogOk = (templateStopId) => {
    setDeleteTemplateStopDialogState({...deleteTemplateStopDialogState, open: false, templateStop: null});
    deleteTemplateStop(templateStopId);
  };

  const rootClassName = classNames(classes.root, className);


  return (
    <TextPlaceholderWrapper
      active={!templateStops?.length}
      text={t('noTemplateStopsAvailable')}
    >
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <TableSettings
            updateConfig={updateConfig}
            tableConfig={config}
            columns={Columns}
          />
          <Table size="medium">
            <TableHead>
              <TableRow>
                {columnConfigs.map(columnConfig => (
                  <TableCellWithConfig
                    key={columnConfig.order}
                    config={columnConfig}
                  >
                    {Columns[columnConfig.columnName].header({t})}
                  </TableCellWithConfig>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedObjects.map((templateStop, index) => (
                <TableRow
                  className={classNames(classes.tableRow, 'cursorPointer')}
                  hover
                  key={index}
                  onClick={() => showDetails(templateStop)}
                  selected={selectedTemplateStop && templateStop.id === selectedTemplateStop.templateStop}
                >
                  {columnConfigs.map(columnConfig => (
                    <TableCellWithConfig
                      key={columnConfig.order}
                      config={columnConfig}
                      className={classes.tableCell}
                    >
                      {Columns[columnConfig.columnName].body({
                        templateStop,
                        classes,
                        t,
                        deleteTemplateStop,
                        setDeleteTemplateStopDialogState,
                        deleteTemplateStopDialogState,
                      })}
                    </TableCellWithConfig>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination paginationProps={paginationProps}/>
        </PortletContent>
      </Portlet>
      <DeleteTemplateStopDialog
        dialogState={deleteTemplateStopDialogState}
        handleCancel={() => setDeleteTemplateStopDialogState({...deleteTemplateStopDialogState, open: false, templateStop: null})}
        handleClose={deleteTemplateStopDialogOk}
        needsConfirm={tableDisplayMode !== displayModes.admin && tableDisplayMode !== displayModes.shipper}
      />
    </TextPlaceholderWrapper>
  );
}


TemplateStopTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deleteTemplateStop: PropTypes.func,
  selectedTemplateStop: PropTypes.object,
  showDetails: PropTypes.func.isRequired,
  templateStops: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  tableDisplayMode: PropTypes.string.isRequired
}
;

export default compose(withStyles(styles), withTranslation())(TemplateStopTable);
