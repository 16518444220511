import React, {Fragment} from 'react'
import {FormControl, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InfoTooltip from '../../ELearning/InfoTooltip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';


function DropdownSelectAutocomplete(props) {

  const {classes, className, options, name, setValue, value, disabled, descriptionKey, required, error} = props;

  const rootClassName = classNames(classes.formControl, className);

  return (
    <Fragment>
      <InfoTooltip descriptionKey={descriptionKey}>
        <FormControl
          className={rootClassName}
          error={error}
          required={required}
        >
          <Autocomplete
            clearOnBlur
            disabled={disabled}
            onChange={(event, newValue) => setValue(newValue)}
            options={options}
            renderInput={(params) =>
              <TextField
                {...params}
                label={name}
              />
            }
            selectOnFocus
            value={value}
          />
        </FormControl>
      </InfoTooltip>
    </Fragment>
  );
}

DropdownSelectAutocomplete.defaultProps = {
  options: [],
  name: '',
  value: '',
  descriptionKey: '',
  error: false,
  required: false,
};

DropdownSelectAutocomplete.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  descriptionKey: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  required: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
};

export default withStyles(styles)(DropdownSelectAutocomplete);
