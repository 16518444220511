export default theme => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  messageFromCarrierContainer: {
    margin: theme.spacing(1)
  },
  warnButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
    '&:disabled': {
      backgroundColor: theme.palette.common.neutral,
    }
  },
  infoText: {
    whiteSpace: 'pre-wrap',
    margin: theme.spacing(1),
    marginBottom: 0,
  },
});
