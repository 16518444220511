export default theme => ({
  dialog: {},
  tableCell: {
    whiteSpace: 'nowrap',
  },
  dangerColor: {
    color: theme.palette.danger.main
  },
  alignText: {
    display: 'flex',
    alignItems: 'center'
  }
});