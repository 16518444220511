import React, {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Menu,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import {Edit as TableSettingsIcon} from '@material-ui/icons';
import PropTypes from 'prop-types';
import styles from './styles';
import classNames from 'classnames';

const TableSettings = (props) => {

  const {tableConfig, updateConfig, columns, useNamesAsTranslateString, className, classes, buttonOptic} = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const {t} = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChange = (checked, columnKey, columnConfig) => {
    updateConfig(columnKey, {...columnConfig, hidden: !checked});
  }

  return (
    <Fragment>
      {buttonOptic
        ?
        <Button
          variant={'text'}
          size={'small'}
          onClick={handleClick}
          className={classNames(classes.button, className)}
          name={t('editTableColumns')}
        >
          <TableSettingsIcon/>&nbsp;{t('editTableColumns')}
        </Button>
        :
        <Tooltip title={t('editTableColumns')}>
          <IconButton
            onClick={handleClick}
            className={classNames(classes.iconButton, className)}
          >
            <TableSettingsIcon/>
          </IconButton>
        </Tooltip>
      }
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <div className={classes.menu}>
          <Typography variant={'h5'}>{t('editTableColumns')}</Typography>
          <FormGroup>
            {Object
              .keys(tableConfig.columns)
              .sort((a, b) => a.order - b.order)
              .map(key => {
                const columnConfig = tableConfig.columns[key];
                const label = useNamesAsTranslateString ? t(columnConfig.columnName) : columns[columnConfig.columnName].header({t});
                return (
                  <FormControlLabel
                    key={columnConfig.order}
                    control={
                      <Checkbox
                        checked={!columnConfig.hidden}
                        value={!columnConfig.hidden}
                        onChange={(_, checked) => onChange(checked, key, columnConfig)}
                      />
                    }
                    disabled={!columnConfig.optional}
                    label={label}
                  />
                )
              })}
          </FormGroup>
        </div>
      </Menu>
    </Fragment>
  )
}

TableSettings.propTypes = {
  buttonOptic: PropTypes.bool,
  columns: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  updateConfig: PropTypes.func.isRequired,
  useNamesAsTranslateString: PropTypes.bool,
  tableConfig: PropTypes.shape({columns: PropTypes.object.isRequired}).isRequired
}

export default withStyles(styles)(TableSettings)