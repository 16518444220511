import React, {useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Portlet} from '../../index'
import {default as DriverDetailEdit} from './DriverDetailEdit';
import {default as DriverDetailShow} from './DriverDetailShow';

function DriverDetails(props) {

  const [editMode, setEditMode] = useState(false);
  const [editedDriver, setEditedDriver] = useState(false);
  const {classes, className, driver, updateDriver, displayDriverTypes, microHubOptions, readonly, closeDetail, updateDonationButton} = props;

  const editDriver = () => {
    setEditedDriver(JSON.parse(JSON.stringify(driver)));
    setEditMode(true);
  };

  const update = () => {
    setEditMode(false);
    updateDriver(editedDriver);
  };

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet className={rootClassName}>
      {editMode ?
        <DriverDetailEdit
          editedDriver={editedDriver}
          microHubOptions={microHubOptions}
          setEditedDriver={setEditedDriver}
          setEditMode={setEditMode}
          updateDriver={update}
        />
        :
        <DriverDetailShow
          closeDetail={closeDetail}
          displayDriverTypes={displayDriverTypes}
          driver={driver}
          edit={editDriver}
          readonly={readonly}
          updateDonationButton={updateDonationButton}
        />
      }
    </Portlet>
  );
}


DriverDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  closeDetail: PropTypes.func.isRequired,
  displayDriverTypes: PropTypes.func.isRequired,
  driver: PropTypes.object.isRequired,
  microHubOptions: PropTypes.array,
  readonly: PropTypes.bool,
  t: PropTypes.func.isRequired,
  updateDonationButton: PropTypes.func,
  updateDriver: PropTypes.func,
}
;

export default compose(withStyles(styles), withTranslation())(DriverDetails);
