import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core';
import styles from './styles'
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import { CarrierAndMicroHubDropdownSelect, DateRangeFilter, DropdownSelect, DropdownSelectSimple, SearchInput } from 'components';
import { TourFilter } from 'services/enums/TourFilter';
import { displayModes } from 'services/enums/displayModes';

function TemplateStopInstanceFilter(props) {

  const {
    classes,
    className,
    t,
    filter,
    setFilter,
    shipperOptions,
    carriersWithHubs,
    microHubs,
    searchInput,
    setSearchInput,
    useSearch,
    displayMode
  } = props;

  const rootClassName = classNames(classes.root, className);

  const hasTourOptions = [{ value: TourFilter.NoTour, name: t('noTour') }, { value: TourFilter.HasTour, name: t('tour') }];

  return (
    <div className={rootClassName}>
      <form>
      <DateRangeFilter
            fromError={!filter.fromDate}
            fromRequired
            fromValue={filter.fromDate}
            setValue={(fromValue, toValue) => setFilter({ ...filter, fromDate: fromValue, toDate: toValue })}
            toError={!filter.toDate}
            toRequired
            toValue={filter.toDate}
          />
        <div className={classNames(classes.rowWrapper, classes.searchWrapper)}>
          <DropdownSelectSimple
            hideOnEmptyOptions
            name={t('shipper')}
            options={shipperOptions}
            setValue={(value) => setFilter({ ...filter, shipperName: value })}
            sort
            value={filter.shipperName}
          />{
            displayMode === displayModes.admin &&
            <CarrierAndMicroHubDropdownSelect
              carriersWithMicroHubs={carriersWithHubs}
              carrierValue={filter.carrierName}
              className={classes.rowWrapper}
              microHubValue={filter.microHubName}
              setCarrierValue={(value) => setFilter({ ...filter, carrierName: value, microHubName: '' })}
              setMicroHubValue={(value) => setFilter({ ...filter, microHubName: value })}
            />
          }
          {displayMode === displayModes.carrier &&
            <DropdownSelectSimple
              hideOnEmptyOptions
              name={t('microHub')}
              options={microHubs}
              setValue={(value) => setFilter({ ...filter, microHubName: value })}
              sort
              value={filter.microHubName}
            />
          }
          <DropdownSelect
            hideOnEmptyOptions
            name={t('tourFilter')}
            options={hasTourOptions}
            setValue={(value) => setFilter({ ...filter, tourFilter: value === '' ? TourFilter.Empty : value })}
            sort
            value={filter.tourFilter === TourFilter.Empty ? '' : filter.tourFilter}
          />

          {useSearch &&
            <Fragment>
              <SearchInput
                setValue={(value) => setSearchInput(value)}
                value={searchInput}
                descriptionKey={'2'}
              />
            </Fragment>
          }
        </div>
      </form>
    </div>
  );
}


TemplateStopInstanceFilter.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  searchInput: PropTypes.string,
  setFilter: PropTypes.func.isRequired,
  setSearchInput: PropTypes.func,
  shipperOptions: PropTypes.array,
  carriersWithHubs: PropTypes.object,
  microHubs: PropTypes.array,
  t: PropTypes.func.isRequired,
  useSearch: PropTypes.bool,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
};

export default compose(withStyles(styles), withTranslation())(TemplateStopInstanceFilter);
