import React, {useState} from 'react'
import {Button, Checkbox, FormControl, FormControlLabel, Input, InputLabel, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {getProductTypeString, ProductType} from '../../../../services/enums/ProductType';
import {EmptiesTableEdit, InfoTooltip, ProductTableEdit} from '../../../../components';
import EditStopStatusDialog from '../EditStopStatusDialog';
import {getStopStatusString} from '../../../../services/enums/StopStatus';
import {DateService} from '../../../../services/util/DateService';

function StopEditCarrierRestricted(props) {

  const {classes, className, editedStop, t, setEditedStop, shipperEmptiesSelectOptions, stopEditTableHook} = props;

  const {
    existingProducts,
    existingPackageInserts,
    existingEmpties,
    newProducts,
    newPackageInserts,
    newEmpties,
    addEmpty,
    addProduct,
    addPackageInsert,
    updateProduct,
    updatePackageInsert,
    updateEmpty,
    deleteProduct,
    deletePackageInsert,
    deleteEmpty,
    areProductsValid,
    arePackageInsertsValid,
    areEmptiesValid
  } = stopEditTableHook;

  const [stopStatusEditDialogOpen, setStopStatusEditDialogOpen] = useState(false);

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <form>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <label htmlFor="customerData"><strong>{t('customerData')}</strong></label>
          <div className={classes.rowWrapper}>
            <FormControl className={classes.formControl}>
              <InputLabel
                htmlFor="streetAndNumber"
                required
              >{t('streetAndNumber')}</InputLabel>
              <Input
                error={!editedStop.address.streetAndNumber}
                id="houseNumber"
                onChange={(event) => {
                  setEditedStop({
                    ...editedStop, address:
                      {
                        streetAndNumber: event.target.value,
                        zipcode: editedStop.address.zipcode,
                        city: editedStop.address.city,
                        country: editedStop.address.country,
                        longitude: editedStop.address.longitude,
                        latitude: editedStop.address.latitude,
                      }
                  })
                }}
                required
                value={editedStop.address.streetAndNumber}
              />
            </FormControl>
          </div>
          <label htmlFor="stopData"><strong>{t('stopData')}</strong></label>
          <div className={classes.rowWrapper}>
            <FormControl className={classes.formControl}>
              <InfoTooltip descriptionKey={'36'}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editedStop.stairMoney}
                      color="secondary"
                      onChange={(event) => setEditedStop({...editedStop, stairMoney: event.target.checked})}
                      value={editedStop.stairMoney}
                    />
                  }
                  label={t('stairMoney')}
                />
              </InfoTooltip>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editedStop.key}
                    color="secondary"
                    onChange={(event) => setEditedStop({...editedStop, key: event.target.checked, keyCode: event.target.checked ? editedStop.keyCode : ''})}
                    value={editedStop.key}
                  />
                }
                label={t('key')}
              />
              <FormControl>
                <InputLabel htmlFor="keyCode">{t('keyCode')}</InputLabel>
                <Input
                  disabled={!editedStop.key}
                  id="keyCode"
                  onChange={(event) => {
                    setEditedStop({...editedStop, keyCode: event.target.value})
                  }}
                  value={editedStop.keyCode ?? ''}
                />
              </FormControl>
            </FormControl>
            <div className={classes.box}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="contactFirstName">{t('contactName')}</InputLabel>
                <Input
                  id="contactFirstName"
                  onChange={(event) => {
                    setEditedStop({
                      ...editedStop, contact: {
                        name: event.target.value,
                        phoneNumber: editedStop.contact.phoneNumber,
                      }
                    })
                  }}
                  value={editedStop.contact.name}
                />
              </FormControl>
              <br/>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="contactPhoneNumber">{t('contactPhoneNumber')}</InputLabel>
                <Input
                  id="contactPhoneNumber"
                  onChange={(event) => {
                    setEditedStop({
                      ...editedStop, contact: {
                        name: editedStop.contact.name,
                        phoneNumber: event.target.value,
                      }
                    })
                  }}
                  value={editedStop.contact.phoneNumber}
                />
              </FormControl>
            </div>
            <div className={classes.box}>
              <Typography><strong>{t('stopStatus')}</strong>: {t(getStopStatusString(editedStop.stopStatus))}</Typography>
              <Typography><strong>{t('deliveryTime')}</strong>: {DateService.displayDateTime(editedStop.deliverTime)}</Typography>
              <Button
                color="secondary"
                onClick={() => setStopStatusEditDialogOpen(true)}
                variant="contained"
              >
                {t('editStopStatus')}
              </Button>
            </div>
          </div>
          <div className={classes.rowWrapper}>
            <FormControl className={classes.formControl}>
              <label htmlFor="driverInfo"><strong>{t('driverInfo')}</strong></label>
              <textarea
                className={classes.textarea}
                cols={'70'}
                id="driverInfo"
                onChange={(event) => setEditedStop({...editedStop, driverInfo: event.target.value})}
                rows={'7'}
                value={editedStop.driverInfo ? editedStop.driverInfo : ''}
              />
            </FormControl>
          </div>
          <div>
            <strong>{`${t(getProductTypeString(ProductType.Box))} / ${t(getProductTypeString(ProductType.BeverageBox))} / ${t(getProductTypeString(ProductType.Other))}`}</strong>:
          </div>
          <div className={classes.rowWrapper}>
            <ProductTableEdit
              addItem={addProduct}
              className={classes.stopDetail}
              deleteProduct={deleteProduct}
              otherProductsValid={areProductsValid()}
              products={existingProducts}
              productsToAdd={newProducts}
              productType={ProductType.Box}
              updateItem={updateProduct}
            />
          </div>
          <div><strong>{t(getProductTypeString(ProductType.PackageInserts))}</strong>:</div>
          <div className={classes.rowWrapper}>
            <ProductTableEdit
              addItem={addPackageInsert}
              className={classes.stopDetail}
              deleteProduct={deletePackageInsert}
              otherProductsValid={arePackageInsertsValid()}
              products={existingPackageInserts}
              productsToAdd={newPackageInserts}
              productType={ProductType.PackageInserts}
              updateItem={updatePackageInsert}
            />
          </div>
          <div><strong>{t('empties')}</strong>:</div>
          <div className={classes.rowWrapper}>
            <EmptiesTableEdit
              addItem={addEmpty}
              className={classes.stopDetail}
              deleteItem={deleteEmpty}
              empties={existingEmpties}
              itemsToAdd={newEmpties}
              otherEmptiesValid={areEmptiesValid()}
              shipperEmptiesSelectOptions={shipperEmptiesSelectOptions}
              updateItem={updateEmpty}
            />
          </div>
        </MuiPickersUtilsProvider>
      </form>
      <EditStopStatusDialog
        dialogState={stopStatusEditDialogOpen}
        editedStop={editedStop}
        handleCancel={() => setStopStatusEditDialogOpen(false)}
        handleClose={() => setStopStatusEditDialogOpen(false)}
        setEditedStop={setEditedStop}
      />
    </div>
  );
}


StopEditCarrierRestricted.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  editedStop: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  setEditedStop: PropTypes.func.isRequired,
  shipperEmptiesSelectOptions: PropTypes.array.isRequired,
  stopEditTableHook: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(StopEditCarrierRestricted);
