export default theme => ({
  root: {
    border: '1px solid lightgrey',
    borderRadius: '3px'
  },
  tableHeader: {
    backgroundColor: theme.palette.common.white,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  tableHeaderSmall: {
    paddingRight: theme.spacing(0),
  },
  tableSettings: {
    right: '0px',
    position: 'absolute'
  },
});

