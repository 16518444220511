import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import SideNavLayout from '../../../layouts/SideNavLayout';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {SelectMap, SelectMapFilter} from '../../../components';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {ClientService} from '../../../services/client/clientService';
import {clientTypes} from '../../../services/client/clientTypes';
import DataLoadingWrapper from '../../../components/DataLoadingWrapper';
import {MicroHubQueryService} from '../../../services/backend/microHubQueryService';
import {ShipperQueryService} from '../../../services/backend/shipperQueryService';
import useMicroHubData from '../../../hooks/useMicroHubData';

const client = ClientService.getClient(clientTypes.microHub);
const shipperClient = ClientService.getClient(clientTypes.shipper);

function AreaSelection(props) {

  const {classes, t} = props;
  const [polygons, setPolygons] = useState([]);
  const [dirty, setDirty] = useState(false);
  const [newPolygonsSet, setNewPolygonsSet] = useState(false);
  const [unusedNames, setUnusedNames] = useState([]);
  const [filter, setFilter] = useState({
    carrierName: '',
    microHubList: [],
    shipperName: '',
  });
  const {microHubData} = useMicroHubData();

  const query = MicroHubQueryService.getHubAreasByNameListQuery(filter.microHubList, filter.carrierName, filter.shipperName);
  const {error, loading, data} = useQuery(query, {client: client});

  const mutation = MicroHubQueryService.setHubAreasByNameQuery();
  const [updateBackendCoordinates] = useMutation(mutation, {client: client});

  const queryShipperNames = ShipperQueryService.getAllShipperNames();
  const {data: shipperNames} = useQuery(queryShipperNames, {client: shipperClient});

  useEffect(() => {
    if (data?.microhubs) {
      const assemblePolygons = [];
      const assembleUnusedNames = [];
      data.microhubs.forEach(hub => {
        hub.carriers.forEach(carrier => {
          if (carrier.coordinates.length > 0) {
            const coordinates = [];
            carrier.coordinates.sort((c1, c2) => (c1.order > c2.order) ? 1 : ((c2.order > c1.order) ? -1 : 0));
            carrier.coordinates.forEach(coordinate => coordinates.push([coordinate.latitude, coordinate.longitude]));
            assemblePolygons.push({name: [hub.name, ' - ', carrier.carrier.name], coordinates: coordinates})
          } else {
            assembleUnusedNames.push([hub.name, ' - ', carrier.carrier.name]);
          }
        });
      });
      setPolygons(assemblePolygons);
      setUnusedNames(assembleUnusedNames);
    }
  }, [data]);


  const polygonsChanged = (newPolygons) => {
    setDirty(true);
    setPolygons(newPolygons);
    setNewPolygonsSet(true);
  };

  const onSave = (newPolygons) => {
    const allMicroHubCoordinates = [];
    const allMicroHubNames = [];
    newPolygons.forEach((polygon) => {
      allMicroHubNames.push(polygon.name);
      const microHubCoordinates = [];
      polygon.coordinates.forEach((coord, index) => {
        microHubCoordinates.push({latitude: coord[0], longitude: coord[1], order: index})
      });
      allMicroHubCoordinates.push(microHubCoordinates);
    });
    const hubNames = allMicroHubNames.map(name => name[0]);
    const carrierNames = allMicroHubNames.map(name => name[2]);
    data.microhubs.forEach(hub => {
      if (!hubNames.includes(hub.name)) {
        hub.carriers.forEach(carrier => {
          hubNames.push(hub.name);
          carrierNames.push(carrier.carrier.name);
          allMicroHubCoordinates.push([]);
        });
      } else {
        hub.carriers.forEach(carrier => {
          const hubIndices = hubNames.map((name, index) => [name, index]).filter(val => val[0] === hub.name).map(val => val[1]);
          const cNames = hubIndices.map(index => carrierNames[index]);
          if (!cNames.includes(carrier.carrier.name)) {
            hubNames.push(hub.name);
            carrierNames.push(carrier.carrier.name);
            allMicroHubCoordinates.push([]);
          }
        });
      }
    });
    updateBackendCoordinates({variables: {microHubNames: hubNames, carrierNames: carrierNames, microHubCoordinates: allMicroHubCoordinates}}).then(() => {
      window.location.reload();
    }, () => {
      alert(t('errorUpdatingData'))
    });

  };

  return (
    <SideNavLayout title={t('areaSelection')}>
      <div className={classes.root}>
        <SelectMapFilter
          dataMicroHubs={microHubData}
          filter={filter}
          setFilter={setFilter}
          shipperOptions={shipperNames ? shipperNames.shippers.map(s => s.name) : []}
        />
        <DataLoadingWrapper
          error={error}
          isLoading={loading}
        >
          {
            data && data.microhubs &&
            <SelectMap
              className={classes.map}
              dirty={dirty}
              microHubs={microHubData}
              newPolygonsSet={newPolygonsSet}
              onSave={onSave}
              polygons={polygons}
              polygonsChanged={polygonsChanged}
              readonly={false}
              setDirty={setDirty}
              setNewPolygonsSet={setNewPolygonsSet}
              unusedNames={unusedNames}
            />
          }
        </DataLoadingWrapper>
      </div>
    </SideNavLayout>
  );

}

AreaSelection.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(AreaSelection);
