import React, {Fragment, useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {AccessTime as TimeIcon, Today as DateIcon} from '@material-ui/icons';
import moment from 'moment';
import {onCloseWithBackdropCurry} from '../../../../../services/util/helperFuncs';

function FinishTourDeliveryDialog(props) {

  const {classes, t, dialogState, handleCancel, handleClose} = props;
  const [tourEnd, setTourEnd] = useState(new Date());

  const handleOk = () => {
    handleClose(dialogState.tour, tourEnd);
  };

  const handleNotOk = () => {
    handleCancel();
  };

  const validDate = dialogState.tour && (moment(tourEnd) > moment(dialogState.tour.tourStart));

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      maxWidth={'sm'}
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={dialogState.open}
    >
      <DialogTitle>{t('finishTourDelivery')}</DialogTitle>
      <DialogContent>
        <Typography>
          {t('finishTourDeliveryDescription')}
        </Typography>
        <Fragment>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              cancelLabel={t('dialogCancel')}
              error={!validDate}
              format="dd.MM.yyyy"
              id="tourEndDate"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton><DateIcon/> </IconButton>
                  </InputAdornment>
                )
              }}
              label={t('date')}
              margin="normal"
              onChange={(event) => {
                setTourEnd(new Date(event))
              }}
              value={tourEnd}
            />
            <br/>
            <TimePicker
              ampm={false}
              cancelLabel={t('dialogCancel')}
              error={!validDate}
              id="tourEndTime"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton><TimeIcon/> </IconButton>
                  </InputAdornment>
                )
              }}
              label={t('time')}
              margin="normal"
              onChange={(event) => {
                setTourEnd(new Date(event))
              }}
              value={tourEnd}
            />
          </MuiPickersUtilsProvider>
        </Fragment>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleNotOk}
        >
          {t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          disabled={!validDate}
          onClick={handleOk}
        >
          {t('dialogOk')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FinishTourDeliveryDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogState: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(FinishTourDeliveryDialog);
