import React, {Fragment, useCallback, useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {LoadingButton, LoadingWrapper, StatisticFilter} from '../../../components'
import {TourService} from '../../../services/backend/tourService';
import SideNavLayout from '../../../layouts/SideNavLayout';
import FileSaver from 'file-saver';
import {GetApp as DownloadIcon} from '@material-ui/icons';
import {displayModes} from '../../../services/enums/displayModes';
import {InvoiceTable} from 'components';
import {AuthService} from '../../../services/auth/authService';
import {DateService} from '../../../services/util/DateService';
import useCarriersWithHubs from '../../../hooks/useCarriersWithHubs';
import AggregatedCard from "../../../components/Statistic/AggregatedCard";

function StatisticsOverviewShipper(props) {

  const {classes, t} = props;

  const today = new Date()
  const [filter, setFilter] = useState({
    carrierName: '',
    shipperName: AuthService.getUserOrganization(),
    microHubName: '',
    stopStatus: '',
    driverEmail: '',
    fromDate: new Date(today.getFullYear(), today.getMonth() - 1, 1, 0, 0, 1),
    toDate: new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59)
  });

  const {carriersWithHubs} = useCarriersWithHubs();

  const [invoices, setInvoices] = useState([]);
  const [aggregatedInvoice, setAggregatedInvoices] = useState(null);

  const [backendLoading, setBackendLoading] = useState(false);
  const [backendLoadingExcel, setBackendLoadingExcel] = useState(false);

  const loadData = useCallback(async () => {
    setBackendLoading(true);
    try {
      const invoiceTask = TourService.getInvoicesWithFilter(filter);
      const invoiceResult = await invoiceTask;
      setInvoices(invoiceResult.stopInvoices);
      setAggregatedInvoices(invoiceResult.aggregated);
    } catch (e) {
      alert(`${t('errorLoadingTourStatisticsAndInvoices')}, ${e}`)
    }
    setBackendLoading(false);
  }, [filter, t]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const downloadInvoicesAsExcel = () => {
    if (invoices.length !== 0) {
      setBackendLoadingExcel(true);
      TourService.downloadStopInvoicesExcel(filter)
        .then(response => {
          let filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
          setBackendLoadingExcel(false);
          FileSaver.saveAs(response.data, filename)
        }, () => {
          alert(t('errorDownloadingExcel'))
          setBackendLoadingExcel(false);
        });
    } else {
      alert(`${t('noInvoiceDataAvailable')}: ${DateService.displayDate(filter.fromDate)}-${DateService.displayDate(filter.toDate)}`);
    }
  };

  return (
    <SideNavLayout title={t('statisticsOverview')}>
      <div className={classes.root}>
        <div className={classes.verticalContainer}>
          <LoadingButton
            className={classes.button}
            color="secondary"
            loading={backendLoadingExcel}
            onClick={downloadInvoicesAsExcel}
            variant="contained"
          >
            <DownloadIcon/>&nbsp;{t('downloadInvoice')}
          </LoadingButton>
        </div>
        <br/>
        <Fragment>
          <StatisticFilter
            carriersMicroHubsDrivers={carriersWithHubs}
            displayMode={displayModes.shipper}
            driverDisplayNames={{}}
            filter={filter}
            setFilter={setFilter}
            shipperOptions={[]}
          />
        </Fragment>
        <br/>
        <LoadingWrapper loading={backendLoading}>
          <Fragment>
            <AggregatedCard
              aggregatedData={aggregatedInvoice}
              tabState={0}
            />
            <InvoiceTable
              aggregated={aggregatedInvoice}
              className={classes.content}
              invoices={invoices}
              tableDisplayMode={displayModes.shipper}
            />
          </Fragment>
        </LoadingWrapper>
      </div>
    </SideNavLayout>
  );
}

StatisticsOverviewShipper.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(StatisticsOverviewShipper);
