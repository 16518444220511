export default theme => ({
  root: {},
  hr: {
    borderColor: theme.palette.divider,
    marginBottom: '0'
  },
  headerSection: {
    flexBasis: '15%',
    flexShrink: 0,
    overflowX: 'hidden'
  },
  tourContainer: {
    width: '100%',
    paddingTop: '0',
    paddingBottom: '0'
  },
  tour: {
    border: `2px solid ${theme.palette.common.white}`,
    borderRadius: '5px',
    marginTop: theme.spacing(1),
  }
});

