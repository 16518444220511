export default theme => ({
  root: {
    overflow: 'auto'
  },
  tableCell: {
    whiteSpace: 'nowrap'
  },
  idTableCell: {
    display: 'flex',
    alignItems: 'center'
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  alignLeft: {
    align: 'left',
  },
  paper: {
    padding: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonCell: {
    whiteSpace: 'nowrap',
    paddingRight: 0
  },
  secondaryColor: {
    color: theme.palette.secondary.main,
    verticalAlign: 'middle'
  },
  seenStatus: {
    marginLeft: 'auto',
    alignSelf: 'center'
  },
  warnButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
      color: theme.palette.common.white,
    }
  },
});

