import React, {Fragment, useCallback, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Map, TileLayer} from 'react-leaflet';
import * as L from 'leaflet';
import {GestureHandling} from 'leaflet-gesture-handling';
import {
  filterMicroHubsForCoords,
  filterStopsForCoords,
  filterStopsForDuplicate,
  filterToursForCoords,
  filterToursForDuplicate,
  getBoundingBox,
  getBoundingBoxCoords,
  getElementsWithSamePosition,
  getNumStopsOnMap,
  getPolyLines,
} from '../../../../services/util/mapUtils';
import {MapControls, MicroHubMarker, OverlappingMarkerCluster, StopMarkerCluster, TourMarkerCluster} from '../../MapComponents';
import useMapControls from '../../../../hooks/useMapControls';

const BaseTourMap = props => {

  const {
    className,
    tours,
    microHubs,
    stops,
    filterToursForCoords,
    filterMicroHubsForCoords,
    filterStopsForCoords,
    getPolyLines,
    displayStops,
    displayMicroHubs,
    getStopClusterIcon,
    getStopMarkerAndAddToMcg,
    getTourStopMarkerAndAddToMcg,
    getTourClusterIcon,
    getOverLappingMarkerClusterIcon,
    displayControls,
    displayToursInFront,
    preventClustering
  } = props;

  const map = useRef(null);

  const toursWithCoords = filterToursForCoords(tours);
  const stopsWithCoords = displayStops ? filterStopsForCoords(stops) : [];
  const microHubsWithCoords = displayMicroHubs ? filterMicroHubsForCoords(microHubs) : [];

  const stopsOnMap = getNumStopsOnMap(toursWithCoords, stopsWithCoords);
  const {useCluster, setUseCluster, useDuplicateSpiderfy, setUseDuplicateSpiderfy, highLiteDuplicates, setHighLiteDuplicates} = useMapControls(stopsOnMap);

  const polyLines = getPolyLines(toursWithCoords, microHubsWithCoords);

  const overlappingElementGroups = useDuplicateSpiderfy ? getElementsWithSamePosition(toursWithCoords, stopsWithCoords) : [];
  const keysOfOverlappingElements = overlappingElementGroups.map(g => g[0].key);

  const filteredTours = filterToursForDuplicate(toursWithCoords, keysOfOverlappingElements);
  const filteredStops = filterStopsForDuplicate(stopsWithCoords, keysOfOverlappingElements);

  L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

  const fitMapBoundsToFG = useCallback(() => {
    const coordinates = getBoundingBoxCoords(polyLines, stopsWithCoords, microHubsWithCoords);
    if (coordinates.length <= 1) return;
    const boundingBox = getBoundingBox(coordinates);
    map.current.leafletElement.fitBounds(boundingBox, {padding: [30, 30]});
    // eslint-disable-next-line
  }, [microHubs, displayMicroHubs, displayStops]);

  useEffect(() => {
    fitMapBoundsToFG();
  }, [fitMapBoundsToFG]);

  const renderStops = () => (
    <StopMarkerCluster
      getClusterIcon={getStopClusterIcon}
      getStopMarkerAndAddToMcg={getStopMarkerAndAddToMcg}
      stops={filteredStops}
      useCluster={preventClustering ? false : useCluster}
    />
  );

  const renderTours = () => (
    <Fragment>
      {filteredTours.map((tour, index) => (
        <TourMarkerCluster
          getClusterIcon={getTourClusterIcon}
          getTourStopMarkerAndAddToMcg={getTourStopMarkerAndAddToMcg}
          key={index}
          polyline={polyLines[index]}
          stops={tour.stops}
          tour={tour}
          useCluster={preventClustering ? false : useCluster}
        />
      ))}
    </Fragment>
  );

  return (
    <Fragment>
      <Map
        boundsOptions={{padding: [10, 10]}}
        center={[50, 10.5]}
        className={className}
        gestureHandling
        ref={map}
        zoom={6}
        zoomSnap={0.25}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Fragment>
          {microHubsWithCoords.map((hub, index) => {
            return (
              <MicroHubMarker
                key={index}
                microHub={hub}
              />
            );
          })}
        </Fragment>
        {displayToursInFront ?
          <Fragment>
            {renderStops()}
            {renderTours()}
          </Fragment>
          :
          <Fragment>
            {renderTours()}
            {renderStops()}
          </Fragment>
        }
        {overlappingElementGroups.map((elementGroup, index) => (
          <OverlappingMarkerCluster
            getClusterIcon={getOverLappingMarkerClusterIcon}
            getStopMarkerAndAddToMcg={getStopMarkerAndAddToMcg}
            getTourStopMarkerAndAddToMcg={getTourStopMarkerAndAddToMcg}
            highLiteDuplicates={highLiteDuplicates}
            key={index}
            overlappingElements={elementGroup}
          />
        ))}
      </Map>
      {displayControls &&
      <MapControls
        clusteringDisabled={preventClustering}
        highLiteDuplicates={highLiteDuplicates}
        setHighLiteDuplicates={setHighLiteDuplicates}
        setUseCluster={setUseCluster}
        setUseDuplicateSpiderfy={setUseDuplicateSpiderfy}
        useCluster={useCluster}
        useDuplicateSpiderfy={useDuplicateSpiderfy}
      />
      }
    </Fragment>
  );
};

BaseTourMap.defaultProps = {
  filterToursForCoords: filterToursForCoords,
  filterStopsForCoords: filterStopsForCoords,
  filterMicroHubsForCoords: filterMicroHubsForCoords,
  getPolyLines: getPolyLines,
  displayControls: true,
  displayToursInFront: false,
  preventClustering: false,
};

BaseTourMap.propTypes = {
  className: PropTypes.string,
  displayControls: PropTypes.bool,
  displayMicroHubs: PropTypes.bool.isRequired,
  displayStops: PropTypes.bool.isRequired,
  displayToursInFront: PropTypes.bool,
  filterMicroHubsForCoords: PropTypes.func,
  filterStopsForCoords: PropTypes.func,
  filterToursForCoords: PropTypes.func,
  getOverLappingMarkerClusterIcon: PropTypes.func.isRequired,
  getPolyLines: PropTypes.func,
  getStopClusterIcon: PropTypes.func.isRequired,
  getStopMarkerAndAddToMcg: PropTypes.func.isRequired,
  getTourClusterIcon: PropTypes.func.isRequired,
  getTourStopMarkerAndAddToMcg: PropTypes.func.isRequired,
  microHubs: PropTypes.array,
  preventClustering: PropTypes.bool,
  stops: PropTypes.array.isRequired,
  tours: PropTypes.array,
};


export default BaseTourMap;

