import React, {useEffect, useState} from 'react'
import {Box, Grid, List, ListItem, Tooltip, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import CalendarHeatmap from 'react-calendar-heatmap';
import {DropdownSelect} from '../../index';
import {roundCapacityValues} from '../../../services/util/helperFuncs';
import {DateService} from '../../../services/util/DateService';
import PropTypes from 'prop-types';
import 'react-calendar-heatmap/dist/styles.css';

const fieldFormatter = {
  boxes: Math.round,
  stops: Math.round,
  length: roundCapacityValues,
  duration: x => DateService.displayTimeFromSeconds(x * 60),
  timeToPack: x => DateService.displayTimeFromSeconds(x),
  avgBoxesPStop: roundCapacityValues,
  avgDistPStop: roundCapacityValues,
  avgTimeToPackPBox: x => `${Math.ceil(x)} s`,
  avgStopsPHour: roundCapacityValues,
  avgStopsPHourWTimeToPack: roundCapacityValues,
}

function CalendarHeatMapDiagram(props) {

  const {dateStatistic, t, classes, filter} = props;

  const [dateStatsMaxima, setDateStatsMaxima] = useState({});
  const [selectedCalendarMetric, setSelectedCalendarMetric] = useState('boxes');
  const nColors = 4;
  const calendarMetrices = Object.keys(fieldFormatter).map(m => ({value: m, name: t(m)}));

  useEffect(() => {
    if (dateStatistic && dateStatistic?.length) {
      const maxima = {}
      calendarMetrices.forEach(metric => {
        maxima[metric.value] = dateStatistic.reduce((pre, cur) => Math.max(pre, cur[metric.value]), 1);
      });
      setDateStatsMaxima(maxima);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateStatistic])


  const colorPicker = (val) => {
    if (!val) {
      return classes['colorEmpty'];
    } else {
      const relativeVal = val[selectedCalendarMetric] / dateStatsMaxima[selectedCalendarMetric];
      const color = Math.min(Math.floor(relativeVal * nColors) + 1, nColors);
      return classes[`colorScale${color}`];
    }
  };

  const addTooltip = (element, value) => {
    if (value) {
      const day = new Date(value.date).toLocaleDateString();
      const formattedVal = fieldFormatter[selectedCalendarMetric](value[selectedCalendarMetric])
      return (
        <Tooltip
          children={element}
          key={day}
          title={`${t(selectedCalendarMetric)}: ${formattedVal} - [${day}] `}
        />
      );
    }
    return element
  }

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={9}
      >
        <CalendarHeatmap
          classForValue={colorPicker}
          endDate={new Date(filter.fromDate.getFullYear(), filter.fromDate.getMonth() + 7, filter.fromDate.getDay())}
          showWeekdayLabels
          startDate={filter.fromDate}
          transformDayElement={addTooltip}
          values={dateStatistic}
          weekdayLabels={[t('sundayShort'), t('mondayShort'), t('tuesdayShort'), t('wednesdayShort'), t('thursdayShort'), t('fridayShort'), t('saturdayShort')]}
        />
      </Grid>
      <Grid
        item
        xs={2}
      >
        <List>
          <DropdownSelect
            name={'Metrik'}
            options={calendarMetrices}
            required
            setValue={setSelectedCalendarMetric}
            value={selectedCalendarMetric}
          />
          {(() => {
            const maxVal = dateStatsMaxima[selectedCalendarMetric];
            const formatter = fieldFormatter[selectedCalendarMetric];
            const lowerBound = val => formatter(val * maxVal / nColors);
            const upperBound = val => formatter((val + 1) * maxVal / nColors);
            const listItems = [];
            for (let i = 0; i < nColors; i++) {
              listItems.push(
                <ListItem key={i}>
                  <Box
                    className={classes[`colorScale${i + 1}`]}
                    sx={{width: 40, height: 20, marginRight: '1em'}}
                  />
                  {`${lowerBound(i)} - ${upperBound(i)}`}
                </ListItem>
              );
            }
            return listItems;
          })()}
        </List>
      </Grid>
    </Grid>
  );

}


CalendarHeatMapDiagram.propTypes = {
  classes: PropTypes.object.isRequired,
  dateStatistic: PropTypes.array.isRequired,
  filter: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(CalendarHeatMapDiagram);
