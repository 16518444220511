import React from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {Portlet, PortletContent} from '../../../index';
import classNames from 'classnames';

function Person(props) {
  const {classes, className, person, title} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet className={rootClassName}>
      <PortletContent>
        <Typography variant="h3">
          {title}
        </Typography>
        <hr/>
        <Typography variant="body1">
          {person?.name || '-'}<br/>
          {person?.email || '-'}<br/>
          {person?.phoneNumber || '-'}
        </Typography>
      </PortletContent>
    </Portlet>
  );
}

Person.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};


export default compose(withStyles(styles), withTranslation())(Person);
