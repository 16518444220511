import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {LinearProgress, Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import {LoadingWrapper, Portlet, PortletContent, PortletHeader, PortletLabel, TextPlaceholderWrapper, TourIdLabel} from '../../../components';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import {StopStatus} from '../../../services/enums/StopStatus';
import {AccessTimeRounded as TourStartIcon, FaceRounded as DriverIcon, MotorcycleRounded as VehicleIcon,} from '@material-ui/icons';
import {TourStatus} from '../../../services/enums/TourStatus';
import Grid from '@material-ui/core/Grid';
import {DateService} from '../../../services/util/DateService';


function ToursInDeliveryCard(props) {

  const {t, classes, className, isLoading, tours} = props;

  const rootClassName = classNames(classes.root, className);

  const toursInDelivery = !tours || tours.length <= 0 ? [] : tours.filter(tour => tour.tourStatus === TourStatus.InDelivery);

  return (
    <Portlet
      className={rootClassName}
    >
      <PortletHeader className={classes.portletHeader}>
        <PortletLabel title={t('dashboardToursInDelivery')}/>
      </PortletHeader>
      <PortletContent
        className={classes.portletContentStyle}
        noPadding
      >
        <LoadingWrapper loading={isLoading}>
          <TextPlaceholderWrapper
            active={!toursInDelivery?.length}
            text={t('noInDeliveryToursAvailableDashboard')}
          >
            <div className={classes.contentContainer}>
              {toursInDelivery.map(tour => {
                const alreadyDeliveredStops = tour.stops.filter(stop => stop.stopStatus === StopStatus.CanNotDeliver || stop.stopStatus === StopStatus.Delivered).length;
                const allStops = tour.stops.length;
                return (
                  <Grid
                    className={classes.item}
                    container
                    key={tour.tourId}
                  >
                    <Grid
                      item
                      xs={2}
                    >
                      <Typography>
                        <strong><TourIdLabel tour={tour}/></strong>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                    >
                      <div className={classes.flexRow}>
                        <div>
                          <DriverIcon className={classes.textIcon}/>&nbsp;{tour.driverDisplayName ? tour.driverDisplayName : '-'}
                        </div>
                        <div>
                          <VehicleIcon className={classes.textIcon}/>&nbsp;{tour.vehicleLicensePlate ? tour.vehicleLicensePlate : '-'}
                        </div>
                        <div>
                          <TourStartIcon className={classes.textIcon}/>&nbsp;{DateService.displayTime(tour.tourStart)}
                        </div>
                      </div>
                      <div className={classes.flexRow}>
                        <div className={classes.indicatorWrapper}>
                          <LinearProgress
                            className={classes.indicator}
                            value={Math.min(((alreadyDeliveredStops / allStops) * 100), 100)}
                            variant="determinate"
                          />
                        </div>
                        <Typography>
                          {`${alreadyDeliveredStops} / ${allStops}`}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                );
              })}
            </div>
          </TextPlaceholderWrapper>
        </LoadingWrapper>
      </PortletContent>
    </Portlet>
  );
}

ToursInDeliveryCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  tours: PropTypes.array.isRequired,
};

export default compose(withStyles(styles), withTranslation())(ToursInDeliveryCard);
