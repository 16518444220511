import React, {Fragment, useEffect} from 'react'
import {Button, Grid, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {useLocation, withRouter} from 'react-router-dom';
import classNames from 'classnames';
import {AuthService} from '../../services/auth/authService';
import {PrivacyConfirmationDialog} from '../../components';

function Login(props) {

  const {updateUser, t, classes, className, onPrivacyConfirmDialogClose, privacyConfirmDialogOpen} = props;

  let location = useLocation();

  useEffect(() => {
    updateUser(location.pathname.substr('/login/'.length) + location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUser]);

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <Grid
        alignItems="center"
        className={classes.grid}
        container
        justifyContent="center"
      >
        <Grid
          className={classes.content}
          item
        >
          <Grid
            alignItems="center"
            container
            direction="column"
            justifyContent="center"
            spacing={3}
          >
            <Fragment>
              <Grid item>
                <Typography variant="h4">
                  {t('pleaseLogIn')}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  className={classes.button}
                  color="primary"
                  onClick={AuthService.login}
                  size="large"
                  variant="contained"
                >
                  {t('login')}
                </Button>
              </Grid>
            </Fragment>
          </Grid>
        </Grid>
      </Grid>
      <PrivacyConfirmationDialog
        handleClose={onPrivacyConfirmDialogClose}
        open={privacyConfirmDialogOpen}
      />
    </div>
  );

}

Login.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onPrivacyConfirmDialogClose: PropTypes.func.isRequired,
  privacyConfirmDialogOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation(), withRouter)(Login);
