import React, {Fragment} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Step, StepContent, StepLabel, Stepper, Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {Close as CloseIcon, Link as TrackingLink} from '@material-ui/icons';
import {getTrackingStepTypeString, TrackingStepType} from '../../../../services/enums/TrackingStepType';
import {DateService} from '../../../../services/util/DateService';
import {LoadingWrapper, TextPlaceholderWrapper} from '../../../index';
import {onCloseWithBackdropCurry} from '../../../../services/util/helperFuncs';

function TrackAndTraceInfoDialog(props) {

  const {classes, t, open, handleClose, trackingStop, trackingStopLoading} = props;

  const handleOk = () => {
    handleClose();
  };

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      maxWidth={'md'}
      onClose={onCloseWithBackdropCurry(handleClose)}
      open={open}
    >
      <DialogTitle>{t('trackAndTraceHistory')}</DialogTitle>
      <DialogContent>
        <LoadingWrapper loading={trackingStopLoading}>
          <TextPlaceholderWrapper
            active={!trackingStop}
            text={t('noTrackingInfosFound')}
          >
            {trackingStop &&
            <Fragment>
              <Stepper orientation="vertical">
                {trackingStop.trackingSteps.sort((a, b) => new Date(a.eventTimeStamp) - new Date(b.eventTimeStamp)).map((step) => (
                  <Step
                    active
                    key={step.id}
                  >
                    <StepLabel>{t(getTrackingStepTypeString(step.trackingStepType))}</StepLabel>
                    <StepContent>
                      <Typography><strong>{t('timeStamp')}</strong>:&nbsp;{DateService.displayDateTime(step.timeStamp)}</Typography>
                      <Typography><strong>{t('eventTimeStamp')}</strong>:&nbsp;{DateService.displayDateTime(step.eventTimeStamp)}</Typography>
                      <Typography><strong>{t('eventUser')}</strong>:&nbsp;{step.eventUser}</Typography>
                      {step.trackingStepType !== TrackingStepType.Problem &&
                      <Fragment>
                        <Typography>
                          <strong>{t('estimatedDeliveryWindowFrom')}</strong>:&nbsp;{DateService.displayDateTime(step?.deliveryWindow?.from)}
                        </Typography>
                        <Typography><strong>{t('estimatedDeliveryWindowTo')}</strong>:&nbsp;{DateService.displayDateTime(step?.deliveryWindow?.to)}</Typography>
                      </Fragment>
                      }
                      {step.trackingStepType === TrackingStepType.Delivered &&
                      <Typography><strong>{t('deliveredSuccessfully')}</strong>:&nbsp;{step.deliverySuccessful ? t('yes') : t('no')}</Typography>
                      }
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Fragment>
            }
          </TextPlaceholderWrapper>
        </LoadingWrapper>
      </DialogContent>
      <DialogActions>
        {trackingStop &&
        <Button
          color="secondary"
          onClick={() => window.open(trackingStop.trackingLink, '_blank').focus()}
        >
          <TrackingLink/>&nbsp;{t('trackingLink')}
        </Button>
        }
        <Button
          color="primary"
          onClick={handleOk}
        >
          <CloseIcon/>&nbsp;{t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

TrackAndTraceInfoDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  stopId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  trackingStop: PropTypes.object,
  trackingStopLoading: PropTypes.bool,
};


export default compose(withStyles(styles), withTranslation())(TrackAndTraceInfoDialog);

// public string TrackingLink { get; set; }
