import React, {Fragment} from 'react'
import {FormControl, Input, InputLabel, MenuItem, Select, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InfoTooltip from '../../ELearning/InfoTooltip';


function SimpleDropdownSelect(props) {

  const {classes, className, options, name, setValue, value, disabled, descriptionKey, hideOnEmptyOptions, required, error, sort} = props;

  const rootClassName = classNames(classes.formControl, className);

  return (
    <Fragment>
      {(!hideOnEmptyOptions || options?.length > 0) &&
      <InfoTooltip descriptionKey={descriptionKey}>
        <FormControl className={rootClassName}>
          <InputLabel
            id={name}
            required={required}
          >
            {name}
          </InputLabel>
          <Select
            disabled={disabled}
            error={error}
            input={<Input id={name}/>}
            onChange={(event) => setValue(event.target.value)}
            value={value}
          >
            {!required &&
            <MenuItem
              key={-1}
              value={''}
            >
              {'-'}
            </MenuItem>
            }
            {(sort ? options.sort() : options).map((option, index) => {
              return (
                <MenuItem
                  key={index}
                  value={option}
                >
                  {option}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </InfoTooltip>
      }
    </Fragment>
  );
}

SimpleDropdownSelect.defaultProps = {
  options: [],
  name: '',
  value: '',
  descriptionKey: '',
  error: false,
  required: false,
  noEmpty: false,
};

SimpleDropdownSelect.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  descriptionKey: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  hideOnEmptyOptions: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  sort: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

export default withStyles(styles)(SimpleDropdownSelect);
