import React, {Fragment} from 'react'
import {Tooltip, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  CheckCircle as ReadyForMigrationIcon,
  Error as AdditionalMigrationRequiredIcon,
  Error as DeclinedStopIcon,
  NotInterested as MigrationNotPossibleIcon,
  TimerOff as PreviewDeadlineNotElapsedIcon,
  VerifiedUser as MigratedIcon,
  Warning as MigrationPossibleIcon
} from '@material-ui/icons'

function MigrationStatus(props) {

  const {
    classes,
    t,
    className,
    previewDeadlineNotElapsed,
    migrationNotPossible,
    migrated,
    readyForMigration,
    migrationPossible,
    additionalMigrationRequired,
    finalDeadlineElapsed,
    declinedStop
  } = props;

  const rootClassName = classNames(className, classes.root);

  return (
    <Fragment>
      {migrated &&
      <Tooltip title={t('migrationStatusMigrated')}>
        <MigratedIcon className={classNames(rootClassName, classes.primaryIcon)}/>
      </Tooltip>
      }
      {readyForMigration &&
      <Tooltip title={t('migrationStatusReadyForMigration')}>
        <ReadyForMigrationIcon className={classNames(rootClassName, classes.secondaryIcon)}/>
      </Tooltip>
      }
      {migrationPossible &&
      <Tooltip title={t('migrationStatusMigrationPossible')}>
        <MigrationPossibleIcon className={classNames(rootClassName, classes.warnIcon)}/>
      </Tooltip>
      }
      {additionalMigrationRequired &&
      <Tooltip title={t('migrationStatusAdditionalMigrationRequired')}>
        <AdditionalMigrationRequiredIcon className={classNames(rootClassName, classes.dangerIcon)}/>
      </Tooltip>
      }
      {previewDeadlineNotElapsed &&
      <Tooltip title={t('migrationStatusPreviewDeadlineNotElapsed')}>
        <PreviewDeadlineNotElapsedIcon className={classNames(rootClassName, classes.defaultIcon)}/>
      </Tooltip>
      }
      {finalDeadlineElapsed &&
      <Tooltip title={t('migrationStatusFinalDeadlineElapsed')}>
        <PreviewDeadlineNotElapsedIcon className={classNames(rootClassName, classes.defaultIcon)}/>
      </Tooltip>
      }
      {migrationNotPossible &&
      <Tooltip title={t('migrationStatusNotPossible')}>
        <MigrationNotPossibleIcon className={classNames(rootClassName, classes.mutedIcon)}/>
      </Tooltip>
      }
      {declinedStop &&
      <Tooltip title={t('migrationStatusDeclinedStop')}>
        <DeclinedStopIcon className={classNames(rootClassName, classes.dangerIcon)}/>
      </Tooltip>
      }
    </Fragment>
  );
}

MigrationStatus.propTypes = {
  additionalMigrationRequired: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  declinedStop: PropTypes.bool,
  finalDeadlineElapsed: PropTypes.bool,
  migrated: PropTypes.bool,
  migrationNotPossible: PropTypes.bool,
  migrationPossible: PropTypes.bool,
  previewDeadlineNotElapsed: PropTypes.bool,
  readyForMigration: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(MigrationStatus);
