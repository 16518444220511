export default theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    height: '64px',
    zIndex: theme.zIndex.appBar
  },
  toolbar: {
    minHeight: 'auto',
    width: '100%'
  },
  title: {
    marginLeft: theme.spacing(1),
    color: theme.palette.common.white
  },
  menuButton: {
    marginLeft: '-4px',
    color: theme.palette.common.white
  },
  profileButton: {
    marginLeft: 'auto',
    color: theme.palette.common.white
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.common.white
  },
  popoverNotificationList: {
    width: '360px',
    maxHeight: '400px',
    color: theme.palette.common.white
  }
});
