export default theme => ({
    root: {
      overflow: 'auto'
    },
    tableRow: {
      height: '50px'
    },
    tableCell: {
      whiteSpace: 'nowrap'
    },
    tableCellInner: {
      display: 'flex',
      alignItems: 'center'
    },
    alignLeft: {
      align: 'left',
    },
  });
  
  