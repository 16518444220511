export default theme => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  helpWrapper: {
    display: 'inline-block',
    float: 'right'
  },
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    border: `1px solid ${theme.palette.border}`
  },
  addButton: {
    float: 'right'
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-end',
    gap: '30px'
  },
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: '200px',
  },
});
