export default theme => ({
  root: {
    padding: theme.spacing(3)
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  buttonRight: {
    marginTop: theme.spacing(1),
  },
  helpWrapper: {
    float: 'right',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    border: `1px solid ${theme.palette.border}`
  },
  warnColor: {
    color: theme.palette.warning.main
  },
  dispositionTiming: {
    overflow: 'auto',
  },
  problemNotificationButtonContainer: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  problemNotificationButton: {
    float: 'right',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  approveAllTours: {
    float: 'right',
    marginLeft: theme.spacing(1)
  }
});
