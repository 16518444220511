import {TourService} from '../backend/tourService';
import FileSaver from 'file-saver';

export class ExcelTemplateService {
  static downloadTemplateStopsShipper(shipperName) {
    TourService.downloadExcelTemplateShipperStops(shipperName)
      .then(response => {
        let filename = response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0];
        response.blob().then(blob =>
          FileSaver.saveAs(blob, filename)
        );
      });
  }

  static downloadTemplateTourShipper(shipperName) {
    TourService.downloadExcelTemplateShipperTour(shipperName)
      .then(response => {
        let filename = response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0];
        response.blob().then(blob =>
          FileSaver.saveAs(blob, filename)
        );
      });
  }

  static downloadTemplateStopCarrier(carrierName) {
    TourService.downloadExcelTemplateCarrierStops(carrierName)
      .then(response => {
        let filename = response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0];
        response.blob().then(blob =>
          FileSaver.saveAs(blob, filename)
        );
      });
  }
}
