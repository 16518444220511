import React, {Fragment} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {Check, Close as CloseIcon, NavigateBefore as PrevIcon, NavigateNext as NextIcon} from '@material-ui/icons';
import identityService from '../../../services/backend/identityService';
import {onCloseWithBackdropCurry} from '../../../services/util/helperFuncs';

function NewsToUserDialog(props) {
  const {dialogState, onMarkNewsAsRead, onClose, classes, t} = props;
  const [page, setPage] = React.useState(1);


  const markRead = async (newsToUserId) => {
    await identityService.markReadNewsToUser(newsToUserId);
    // check if there is another news to display
    if (dialogState.newsToUser.length === 1) {
      onClose();
    } else {
      onMarkNewsAsRead(newsToUserId);
      // since we remove the message we automatically jump to the next one
      // --> handle if this message was the last one in the array
      if (dialogState.newsToUser.length === page) {
        setPage(page - 1);
      }
    }
  }

  return (
    <Dialog
      onClose={onCloseWithBackdropCurry(onClose)}
      fullWidth
      maxWidth={'md'}
      open={dialogState.open}
    >
      <DialogTitle>
        {t('newsToUserDialogTitle')}: {page}. {t('NewsToUser')}
        <IconButton
          onClick={onClose}
          className={classes.navRight}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Fragment>
          <div>
            {dialogState.newsToUser[page - 1]?.message}
          </div>
          <br />
        </Fragment>
      </DialogContent>
      <DialogActions
        className = {classes.horizontalContainer}
      >
        <Button
          color="primary"
          onClick={() => markRead(dialogState.newsToUser[page - 1].id)}
        >
          <Check/>&nbsp;{t('markAsRead')}
        </Button>

        <div
          className={classes.navRight}
        >
          <Button
            color="primary"
            disabled={page <= 1}
            onClick={() => setPage(page - 1)}
          >
            <PrevIcon/>&nbsp;{t('back')}
          </Button>
          <Button
            color="primary"
            disabled={page >= (dialogState.newsToUser.length)}
            onClick={() => setPage(page + 1)}
          >
            {t('next')}&nbsp;<NextIcon/>
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

NewsToUserDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogState: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    newsToUser: PropTypes.array.isRequired
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onMarkNewsAsRead: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(NewsToUserDialog);
