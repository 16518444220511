import React from 'react';
import {IconButton, Toolbar, Typography, withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';
import classNames from 'classnames';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import {Close as CloseIcon, Input as InputIcon, Menu as MenuIcon, PersonOutline as ProfileIcon} from '@material-ui/icons'
import {withRouter} from 'react-router-dom';
import {AuthService} from '../../../../services/auth/authService';

function Topbar(props) {

  const {classes, className, isSidebarOpen, onToggleSidebar, title} = props;

  const rootClassName = classNames(classes.root, className);


  const handleLogout = () => {
    AuthService.logout();
  };

  const handleVisitProfile = () => {
    window.location = process.env.REACT_APP_AUTH_AUTHORITY + '/Identity/Manage/Index';
  };

  return (
    <div className={rootClassName}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          className={classes.menuButton}
          onClick={onToggleSidebar}
          variant="text"
        >
          {isSidebarOpen ? <CloseIcon/> : <MenuIcon/>}
        </IconButton>
        <Typography
          className={classes.title}
          variant="h3"
        >{title}
        </Typography>
        <IconButton
          className={classes.profileButton}
          onClick={handleVisitProfile}
        >
          <ProfileIcon/>
          <Typography
            className={classes.profileButton}
            variant="caption"
          >
            {AuthService.getUserEmail()}
          </Typography>
        </IconButton>
        <IconButton
          className={classes.signOutButton}
          onClick={handleLogout}
        >
          <InputIcon/>
        </IconButton>
      </Toolbar>
    </div>
  );
}

Topbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  isSidebarOpen: PropTypes.bool,
  onToggleSidebar: PropTypes.func,
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default compose(withStyles(styles), withTranslation(), withRouter)(Topbar);
