export default theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  flexBetweenCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  largeTableCell: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5)
  },
  stopDoesNotFitOnVehicle: {
    backgroundColor: `${theme.palette.danger.medium} !important`,
  },
  dragging: {
    backgroundColor: `${theme.palette.primary.selected} !important`,
  },
  rowHidden: {
    display: 'none'
  },
  showDetailsButton: {
    float: 'right'
  },
  cellWithMultipleChildren: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});
