import React from 'react'
import {Button} from '@material-ui/core';
import {DeleteForever as DeleteIcon,} from '@material-ui/icons';
import {VehicleTypeEnumHelperService} from '../../../services/enums/vehicleTypeEnumHelperService';
import {roundCapacityValues} from '../../../services/util/helperFuncs';

const Columns = {
  vehicleName: {
    name: 'vehicleName',
    header: ({t}) => t('vehicleName'),
    body: ({vehicle}) => vehicle.licensePlate
  },
  vehicleType: {
    name: 'vehicleType',
    header: ({t}) => t('vehicleType'),
    body: ({vehicle, t}) => t(VehicleTypeEnumHelperService.getVehicleTypeString(vehicle.type))
  },
  vehicleCapacity: {
    name: 'vehicleCapacity',
    header: ({t}) => t('vehicleCapacity'),
    body: ({vehicle}) => roundCapacityValues(vehicle.capacity)
  },
  vehicleVolume: {
    name: 'vehicleVolume',
    header: ({t}) => t('vehicleVolume'),
    body: ({vehicle}) => roundCapacityValues(vehicle.volume)
  },
  vehiclePayloadWeight: {
    name: 'vehiclePayloadWeight',
    header: ({t}) => t('vehiclePayloadWeight'),
    body: ({vehicle}) => roundCapacityValues(vehicle.payloadWeight)
  },
  carrier: {
    name: 'carrier',
    header: ({t}) => t('carrier'),
    body: ({vehicle}) => vehicle.carrierName
  },
  vehicleDelete: {
    name: 'vehicleDelete',
    header: ({t}) => t('vehicleDelete'),
    body: ({vehicle, deleteVehicle, t, classes}) => (
      <Button
        className={classes.deleteButton}
        color="primary"
        onClick={(event) => {
          if (window.confirm(t('confirmVehicleDelete'))) deleteVehicle(vehicle, event)
        }}
        style={{padding: 0}}
        title={t('vehicleDelete')}
      >
        <DeleteIcon/>
      </Button>
    )
  }
}

export default Columns;