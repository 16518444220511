import {displayModes} from '../../../services/enums/displayModes';
import Columns from './columns';

// CARE: ALWAYS INCREMENT THE VERSION IF THE SETTINGS FOR A COLUMN CHANGE!
// Config explanation
// optional: determines if the hidden status can be changed by the user
// hidden: determines if the field is shown or hidden
// conditional: indicates that the hidden status can be overriden using the conditions dictionary of the useTableConfig hook
const DefaultConfig = {
  [displayModes.admin]: {
    version: 2,
    columns: {
      tourStopId: {
        columnName: Columns.tourStopId.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 1,
      },
      tourId: {
        columnName: Columns.tourId.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 2
      },
      shipper: {
        columnName: Columns.shipper.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 3
      },
      carrier: {
        columnName: Columns.carrier.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 4
      },
      microHub: {
        columnName: Columns.microHub.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 5
      },
      lastName: {
        columnName: Columns.lastName.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 6
      },
      planedDeliveryDate: {
        columnName: Columns.planedDeliveryDate.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 7
      },
      stopStatus: {
        columnName: Columns.stopStatus.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 8
      },
      deliveryTime: {
        columnName: Columns.deliveryTime.name,
        optional: true,
        hidden: false,
        conditional: false,
        order: 9
      },
      deliveryTimeWindowShipper: {
        columnName: Columns.deliveryTimeWindowShipper.name,
        optional: true,
        hidden: true,
        conditional: false,
        order: 10
      },
      options: {
        columnName: Columns.options.name,
        optional: true,
        hidden: false,
        conditional: true,
        order: 11
      },
    }
  },
  [displayModes.carrier]: {
    version: 2,
    columns: {
      tourStopId: {
        columnName: Columns.tourStopId.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 1,
      },
      tourId: {
        columnName: Columns.tourId.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 2
      },
      planedDeliveryDate: {
        columnName: Columns.planedDeliveryDate.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 3
      },
      shipper: {
        columnName: Columns.shipper.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 4
      },
      microHub: {
        columnName: Columns.microHub.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 5
      },
      lastName: {
        columnName: Columns.lastName.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 6
      },
      streetAndNumber: {
        columnName: Columns.streetAndNumber.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 7
      },
      zipcode: {
        columnName: Columns.zipcode.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 8
      },
      stopStatus: {
        columnName: Columns.stopStatus.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 9
      },
      deliveryTime: {
        columnName: Columns.deliveryTime.name,
        optional: true,
        hidden: false,
        conditional: false,
        order: 10
      },
      deliveryTimeWindowShipper: {
        columnName: Columns.deliveryTimeWindowShipper.name,
        optional: true,
        hidden: true,
        conditional: false,
        order: 11
      },
      options: {
        columnName: Columns.options.name,
        optional: true,
        hidden: true,
        conditional: true,
        order: 12
      },
    }
  },
  [displayModes.shipper]: {
    version: 2,
    columns: {
      tourStopId: {
        columnName: Columns.tourStopId.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 1,
      },
      planedDeliveryDate: {
        columnName: Columns.planedDeliveryDate.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 2
      },
      carrier: {
        columnName: Columns.carrier.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 3
      },
      microHub: {
        columnName: Columns.microHub.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 4
      },
      lastName: {
        columnName: Columns.lastName.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 5
      },
      streetAndNumber: {
        columnName: Columns.streetAndNumber.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 6
      },
      zipcode: {
        columnName: Columns.zipcode.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 7
      },
      estimatedDeliveryWindow: {
        columnName: Columns.estimatedDeliveryWindow.name,
        optional: true,
        hidden: true,
        conditional: false,
        order: 8
      },
      stopStatus: {
        columnName: Columns.stopStatus.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 9
      },
      deliveryTime: {
        columnName: Columns.deliveryTime.name,
        optional: true,
        hidden: false,
        conditional: false,
        order: 10
      },
      deliveryTimeWindowShipper: {
        columnName: Columns.deliveryTimeWindowShipper.name,
        optional: true,
        hidden: true,
        conditional: false,
        order: 11
      },
      options: {
        columnName: Columns.options.name,
        optional: false,
        hidden: false,
        conditional: true,
        order: 12
      },
    }
  },
}

export default DefaultConfig;