import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {markerTypes} from '../../../../../services/util/mapUtils';
import L from 'leaflet';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import {useLeaflet} from 'react-leaflet';
import {withTranslation} from 'react-i18next';

const OverlappingMarkerCluster = (props) => {

  const {getStopMarkerAndAddToMcg, getToAddStopMarkerAndAddToMcg, getTourStopMarkerAndAddToMcg, overlappingElements, getClusterIcon, t, highLiteDuplicates} = props;

  const mcg = L.markerClusterGroup({
    iconCreateFunction: function () {
      return getClusterIcon(overlappingElements, highLiteDuplicates);
    },
    maxClusterRadius: 50,
    showCoverageOnHover: false,
    disableClusteringAtZoom: 20,
    spiderfyOnMaxZoom: true
  });

  const {map} = useLeaflet();

  useEffect(() => {
    mcg.clearLayers();
    overlappingElements.forEach((el) => {
      switch (el.type) {
        case markerTypes.stop:
          if (getStopMarkerAndAddToMcg) getStopMarkerAndAddToMcg(el.stop, mcg, t);
          break;
        case markerTypes.tour:
          if (getTourStopMarkerAndAddToMcg) getTourStopMarkerAndAddToMcg(el.tour, el.stop, mcg, t);
          break;
        case markerTypes.toAddStop:
          if (getToAddStopMarkerAndAddToMcg) getToAddStopMarkerAndAddToMcg(el.stop, mcg, t);
          break;
        default:
          console.warn(`Encountered marker type where no marker was specified: ${el.type}`)
      }
    });
    map.addLayer(mcg);
    return function cleanup() {
      map.removeLayer(mcg);
    }
  }, [overlappingElements, t, mcg, map, getStopMarkerAndAddToMcg, getToAddStopMarkerAndAddToMcg, getTourStopMarkerAndAddToMcg]);

  return null;
};

OverlappingMarkerCluster.propTypes = {
  getClusterIcon: PropTypes.func.isRequired,
  getStopMarkerAndAddToMcg: PropTypes.func,
  getToAddStopMarkerAndAddToMcg: PropTypes.func,
  getTourStopMarkerAndAddToMcg: PropTypes.func,
  highLiteDuplicates: PropTypes.bool.isRequired,
  overlappingElements: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(OverlappingMarkerCluster);
