import {compose} from 'recompose';
import {Checkbox, FormControl, FormControlLabel, Tab, Tabs, Tooltip, withStyles} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import styles from './styles'
import classNames from 'classnames';
import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import LegendInteractive from '../../MapComponents/LegendInteractive';
import {StopStatus} from '../../../../services/enums/StopStatus';
import {DeliveryOrderTourMap} from '../../../index';
import {InfoOutlined as InfoIconSymbol} from '@material-ui/icons';

const tourDetailMapTabState = {
  tourOverview: 0,
  deliveryOrder: 1,
}

const TourDetailsMap = props => {

  const {classes, className, tours, t, microHubs, hideNotPlannedStops} = props;
  const [legendCheckboxes, setLegendCheckboxes] = useState({
    displayNotPlanedStops: true,
    displayPlanedStops: true,
    displayInDeliveryStops: true,
    displayDeliveredStops: true,
    displayCanNotDeliverStops: true,
    displayRedeliveryStops: true,
    displayMicroHubs: true
  });

  const [tabState, setTabState] = useState(tourDetailMapTabState.tourOverview);
  const [displayStopStatus, setDisplayStopStatus] = useState(false);

  const displayDeliveryOrder = tabState === tourDetailMapTabState.deliveryOrder;

  const rootClassName = classNames(classes.root, className);

  const filteredTours = JSON.parse(JSON.stringify(tours));
  filteredTours.forEach(
    tour => tour.stops = tour.stops.filter(
      stop => {
        switch (stop.stopStatus) {
          case StopStatus.NotPlaned: {
            return legendCheckboxes.displayNotPlanedStops;
          }
          case StopStatus.Planed: {
            return legendCheckboxes.displayPlanedStops || !displayStopStatus;
          }
          case StopStatus.InDelivery: {
            return legendCheckboxes.displayInDeliveryStops || !displayStopStatus;
          }
          case StopStatus.Delivered: {
            return legendCheckboxes.displayDeliveredStops || !displayStopStatus;
          }
          case StopStatus.CanNotDeliver: {
            return legendCheckboxes.displayCanNotDeliverStops || !displayStopStatus;
          }
          case StopStatus.Redelivery: {
            return legendCheckboxes.displayRedeliveryStops || !displayStopStatus;
          }
          default: {
            return false;
          }
        }
      }
    )
  );

  return (
    <Fragment>
      <div className={classes.legendControl}>
        <div className={classes.tabCheckbox}>
          <Tabs
            onChange={(event, newValue) => {
              setTabState(newValue)
            }}
            value={tabState}
          >
            <Tab
              label={t('tourOverview')}
              value={tourDetailMapTabState.tourOverview}
            />
            <Tab
              icon={
                <Tooltip
                  title={t('deliveryOrderDescription')}
                >
                  <InfoIconSymbol className={classes.infoTab}/>
                </Tooltip>
              }
              label={t('deliveryOrder')}
              value={tourDetailMapTabState.stopStatus}
            />
          </Tabs>
          <FormControl className={classes.formControlAlign}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={displayStopStatus}
                  color="secondary"
                  onChange={(event) => setDisplayStopStatus(event.target.checked)}
                  value={displayStopStatus}
                />
              }
              label={t('displayStopStatus')}
            />
          </FormControl>
        </div>
        <LegendInteractive
          hideNotPlannedStops={hideNotPlannedStops}
          hideStopStatus={!displayStopStatus}
          legendCheckboxesState={legendCheckboxes}
          setLegendCheckboxesState={setLegendCheckboxes}
        />
      </div>
      <DeliveryOrderTourMap
        className={rootClassName}
        displayDeliveryOrder={displayDeliveryOrder}
        displayMicroHubs={legendCheckboxes.displayMicroHubs}
        displayStopStatus={displayStopStatus}
        microHubs={microHubs}
        toursWithStops={filteredTours}
      />
    </Fragment>
  );
};

TourDetailsMap.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  hideNotPlannedStops: PropTypes.bool,
  microHubs: PropTypes.array,
  t: PropTypes.func.isRequired,
  tours: PropTypes.array,
};


export default compose(withStyles(styles), withTranslation())(TourDetailsMap);

