import React from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {roundCapacityValues} from '../../../../../../services/util/helperFuncs';

const DisplayCapacity = (props) => {

  const {capacity, vehicleCapacity, classes} = props;

  const bad = vehicleCapacity ? vehicleCapacity < capacity : false;

  return (
    <span
      className={classNames({[classes.bad]: bad})}
    >
      {roundCapacityValues(capacity)}{vehicleCapacity ? `/${roundCapacityValues(vehicleCapacity)}` : ''}
    </span>
  )
};

DisplayCapacity.propTypes = {
  capacity: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  vehicleCapacity: PropTypes.number,
};

export default withStyles(styles)(DisplayCapacity);
