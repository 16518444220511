import React, {useState} from 'react'
import {Button, Tooltip, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import administrationService from '../../../../services/backend/administrationService';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import useShipperOptions from '../../../../hooks/useShipperOptions';
import {DropdownSelectSimple, LoadingIndicator} from '../../../../components';


function TourFinalization(props) {

  const {classes, t} = props;
  const [backendIsLoading, setBackendIsLoading] = useState(false);
  const [algorithmDate, setAlgorithmDate] = useState(new Date());
  const [displayError, setDisplayError] = useState(false);
  const [algorithmResult, setAlgorithmResult] = useState(null);
  const {shipperOptions} = useShipperOptions();
  const [selectedShipper, setSelectedShipper] = useState('');

  const runAlgorithmForDay = async () => {
    setBackendIsLoading(true);
    try {
      const result = await administrationService.runAlgorithm(new Date(Date.UTC(algorithmDate.getFullYear(), algorithmDate.getMonth(), algorithmDate.getDate())).toISOString(), selectedShipper);
      setDisplayError(false);
      setAlgorithmResult(result);
      setBackendIsLoading(false);
    } catch (e) {
      setDisplayError(true);
      setAlgorithmResult(null);
      setBackendIsLoading(false);
    }
  };


  if (backendIsLoading) {
    return (
      <div>
        <LoadingIndicator/>
      </div>
    );
  }

  return (
    <div>
      {displayError &&
        <div>
          <Typography
            color={'error'}
            variant={'h5'}
          >
            {t('tourMigrationFailed')}
          </Typography>
          <Typography variant={'body1'}>
            {t('algorithmRunFailedTechnicalError')}
          </Typography>
        </div>
      }
      {algorithmResult &&
        <div>
          <Typography
            color={'primary'}
            variant={'h5'}
          >
            {t('tourMigrationSuccessfully')}
          </Typography>
          <Typography variant={'body1'}>
            {`${t('toursFromPreviewService')}: ${algorithmResult.migratedTourIds ? algorithmResult.migratedTourIds.length : 0} (${algorithmResult.migratedStopIds ? algorithmResult.migratedStopIds.length : 0} ${t('stops')})`}
          </Typography>
          <Typography variant={'h6'}>
            {t('tourOptimizationResult')}
          </Typography>
          <div className={classes.details}>
            <Typography variant={'body1'}>
              {`${t('usedStops')}: ${algorithmResult.usedStops}`}
            </Typography>
            <Typography variant={'body1'}>
              {`${t('buildTours')}: ${algorithmResult.tourIds ? algorithmResult.tourIds.length : 0}`}
            </Typography>
            <Typography variant={'body1'}>
              {`${t('unassignedStops')}: ${algorithmResult.unassignedStops ? algorithmResult.unassignedStops.length : 0}`}
            </Typography>
            {(algorithmResult.unassignedStops && algorithmResult.unassignedStops.length > 0) &&
              <Typography variant={'body1'}>
                {t('unassignedStopsReasons')}
              </Typography>
            }
          </div>
        </div>
      }
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Tooltip title={t('runAlgoForDayInfo')}>
          <div style={{display: 'inline-block'}}>
            <Button
              className={classes.button}
              color="primary"
              disabled={!selectedShipper}
              onClick={runAlgorithmForDay}
              variant="contained"
            >
              {t('runAlgoForDay')}
            </Button>
          </div>
        </Tooltip>
        <KeyboardDatePicker
          cancelLabel={t('dialogCancel')}
          className={classes.picker}
          format="dd.MM.yyyy"
          id="DatePicker"

          margin="normal"
          onChange={(date) => setAlgorithmDate(date)}
          value={algorithmDate}
        />
        <DropdownSelectSimple
          className={classes.formControl}
          name={t('shipper')}
          options={shipperOptions}
          setValue={(value) => setSelectedShipper(value)}
          sort
          value={selectedShipper}
        />
      </MuiPickersUtilsProvider>
    </div>
  );

}

TourFinalization.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(TourFinalization);
