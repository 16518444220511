import {
  notPlanedIconColor,
  templateStopConnectionColor,
  templateStopDeliveryIconColor,
  templateStopPickupIconColor
} from '../../../../services/icon/iconService';

export default theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  legendAlign: {
    display: 'flex',
    alignItems: 'baseline'
  },
  legendIcon: {
    height: '25px',
    padding: '0px 5px'
  },
  materialIcon: {
    paddingLeft: '5px',
    marginBottom: '-3px',
    width: '24px',
    height: '24px',
  },
  iconDelivery: {
    color: templateStopDeliveryIconColor
  },
  iconConnections: {
    color: templateStopConnectionColor
  },
  iconPickup: {
    color: templateStopPickupIconColor
  },
  iconNotPlanned: {
    color: notPlanedIconColor
  },
  checkbox: {
    margin: '0 0 0 16px',
    padding: '0px'
  }
});
