import React, {Fragment} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {PortletContent, PortletHeader} from '../../../index';
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import BoxTypeEdit from '../../BoxTypeEdit';

function BoxTypeDetailEdit(props) {

  const {classes, className, editedBoxType, setEditMode, t, updateBoxType, setEditedBoxType, shipperShortName} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <Fragment>
      <PortletHeader className={rootClassName}>
        <Fragment>
          <div><strong>{`${shipperShortName}-${editedBoxType.identifier}`}</strong></div>
          <div>
            <Button
              className={classes.button}
              color="default"
              onClick={() => setEditMode(false)}
              title={t('cancel')}
              variant="contained"
            >
              <CancelIcon/>&nbsp;{t('cancel')}
            </Button>
            <Button
              className={classes.button}
              color="primary"
              disabled={
                !editedBoxType.identifier
                || !editedBoxType.kind
                || editedBoxType.volume === '' || isNaN(editedBoxType.volume) || parseFloat(editedBoxType.volume) <= 0
                || editedBoxType.height === '' || isNaN(editedBoxType.height) || parseFloat(editedBoxType.height) <= 0
                || editedBoxType.width === '' || isNaN(editedBoxType.width) || parseFloat(editedBoxType.width) <= 0
                || editedBoxType.depth === '' || isNaN(editedBoxType.depth) || parseFloat(editedBoxType.depth) <= 0
                || !shipperShortName //should not happen but just to prevent bad data going to Backend
              }
              onClick={updateBoxType}
              title={t('boxTypeSave')}
              variant="contained"
            >
              <SaveIcon/>
            </Button>
          </div>
        </Fragment>
      </PortletHeader>
      <PortletContent noPadding>
        <BoxTypeEdit
          className={classes.wrapper}
          editedBoxType={editedBoxType}
          setEditedBoxType={setEditedBoxType}
          shipperShortName={shipperShortName}
        />
      </PortletContent>
    </Fragment>
  );
}


BoxTypeDetailEdit.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  editedBoxType: PropTypes.object.isRequired,
  setEditMode: PropTypes.func.isRequired,
  setEditedBoxType: PropTypes.func.isRequired,
  shipperShortName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  updateBoxType: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(BoxTypeDetailEdit);
