import React from 'react';
import {IconButton, Tooltip, Typography} from '@material-ui/core';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  WarningRounded as WarnIcon
} from '@material-ui/icons';
import {StopStatus} from '../../../../services/enums/StopStatus';
import {ShipperAllowedOnHubService} from '../../../../services/util/shipperAllowedOnHubHelper';
import {ShipperLabel, StopIdLabel, StoragePlaces} from '../../../index';
import {roundCapacityValues} from '../../../../services/util/helperFuncs';
import {DateService} from '../../../../services/util/DateService';

const Columns = {
  tourStopId: {
    name: 'tourStopId',
    header: ({t}) => t('tourStopId'),
    body: ({stop, classes, t}) => (
      <Typography
        className={classes.alignText}
        variant={'body2'}
      >
        {stop.stopStatus === StopStatus.CanNotDeliver &&
          <Tooltip
            title={`${t('stopCouldNotBeDelivered')}: ${stop.driverInfo ? stop.driverInfo : t('noDriverInfoAvailable')}`}>
            <WarnIcon className={classes.dangerColor}/>
          </Tooltip>
        }
        &nbsp;<StopIdLabel stop={stop}/>
      </Typography>
    )
  },
  shipper: {
    name: 'shipper',
    header: ({t}) => t('shipper'),
    body: ({stop, expectedHubData, tourDeliveryDate, classes, t}) => (
      <Typography
        className={classes.alignText}
        component={'div'}
        variant={'body2'}
      >
        {!ShipperAllowedOnHubService.checkShipperAllowedStop(expectedHubData, stop, () => tourDeliveryDate) &&
          <Tooltip title={t('shipperNotAllowedStop')}>
            <WarnIcon className={classes.dangerColor}/>
          </Tooltip>
        }
        &nbsp;<ShipperLabel stop={stop}/>
      </Typography>
    )
  },
  microHub: {
    name: 'microHub',
    header: ({t}) => t('microHub'),
    body: ({stop, t, classes, expectedHub}) => (
      <Typography
        className={classes.alignText}
        variant={'body2'}
      >
        {expectedHub && stop.assignedMicroHubName && expectedHub !== stop.assignedMicroHubName &&
          <Tooltip title={t('microHubsDoNotMatch')}>
            <WarnIcon className={classes.warningColor}/>
          </Tooltip>
        }
        &nbsp;{stop.assignedMicroHubName}
      </Typography>
    )
  },
  planedDeliveryDateShipper: {
    name: 'planedDeliveryDateShipper',
    header: ({t}) => t('planedDeliveryDateShipper'),
    body: ({stop}) => DateService.displayDate(stop.planedDeliveryDateShipper)
  },
  deliveryTimeWindowShipper: {
    name: 'deliveryTimeWindowShipper',
    header: ({t}) => t('deliveryTimeWindowShipper'),
    body: ({stop}) => stop.deliveryWindowShipper ? `${DateService.displayTime(stop?.deliveryWindowShipper?.from)} - ${DateService.displayTime(stop?.deliveryWindowShipper?.to)}` : '-'
  },
  streetAndNumber: {
    name: 'streetAndNumber',
    header: ({t}) => t('streetAndNumber'),
    body: ({stop}) => stop.address.streetAndNumber
  },
  storagePlace: {
    name: 'storagePlace',
    header: ({t}) => t('storagePlace'),
    body: ({stop}) => (
      <StoragePlaces
        maxDisplay={3}
        stop={stop}
      />
    )
  },
  boxAmount: {
    name: 'boxAmount',
    header: ({t}) => t('boxAmount'),
    body: ({stop}) => roundCapacityValues(stop.stopCapacities.boxAmount)
  },
  weight: {
    name: 'weight',
    header: ({t}) => t('weight'),
    body: ({stop}) => roundCapacityValues(stop.stopCapacities.weight)
  },
  volume: {
    name: 'volume',
    header: ({t}) => t('volume'),
    body: ({stop, open, setOpen, classes}) => (
      <div>
        {roundCapacityValues(stop.stopCapacities.volume)}
        <IconButton
          className={classes.buttonRight}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpen(!open)
          }}
          size="small"
        >
          {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
        </IconButton>
      </div>
    )
  },
}

export default Columns;