export default theme => ({
  root: {
    overflowY: 'auto',
    maxHeight: '74%'
  },
  tourTable: {
    marginLeft: theme.spacing(1),
    marinTop: theme.spacing(1),
    width: '95%'
  }
});
