import React, {Fragment, useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {AccessTime as TimeIcon, Today as DateIcon} from '@material-ui/icons';
import {onCloseWithBackdropCurry} from '../../../../../services/util/helperFuncs';

function PlanedTourStartSelectDialog(props) {

  const {classes, t, selectPlanedTourStartDialogState, handleCancel, handleClose} = props;
  const [planedTourStart, setPlanedTourStart] = useState(null);
  if (planedTourStart == null && selectPlanedTourStartDialogState.tour) {
    setPlanedTourStart(selectPlanedTourStartDialogState.tour.planedTourDelivery)
  }

  const handleOk = () => {
    handleClose(planedTourStart);
    setPlanedTourStart(null);
  };

  const handleNotOk = () => {
    handleCancel();
    setPlanedTourStart(null);
  };

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      maxWidth={'lg'}
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={selectPlanedTourStartDialogState.open}
    >
      <DialogTitle>{t('selectPlanedTourStartDialogTitle')}</DialogTitle>
      <DialogContent>
        <Fragment>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              cancelLabel={t('dialogCancel')}
              format="dd.MM.yyyy"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton><DateIcon/> </IconButton>
                  </InputAdornment>
                )
              }}
              label={t('date')}
              margin="normal"
              onChange={(event) => {
                setPlanedTourStart(new Date(event))
              }}
              value={planedTourStart}
            />
            <br/>
            <TimePicker
              ampm={false}
              cancelLabel={t('dialogCancel')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton><TimeIcon/> </IconButton>
                  </InputAdornment>
                )
              }}
              label={t('time')}
              margin="normal"
              onChange={(event) => {
                setPlanedTourStart(new Date(event))
              }}
              value={planedTourStart}
            />
          </MuiPickersUtilsProvider>
        </Fragment>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleNotOk}
        >
          {t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          onClick={handleOk}
        >
          {t('dialogOk')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PlanedTourStartSelectDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectPlanedTourStartDialogState: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(PlanedTourStartSelectDialog);
