import React from 'react'
import {LinearProgress, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import classNames from 'classnames';


function ProgressDisplay(props) {

  const {classes, className, currentValue, maxValue, width} = props;

  const percentage = (currentValue / maxValue) * 100;

  const rootClassName = classNames(className, classes.root);

  return (
    <div className={rootClassName}>
      <div className={classes.indicatorWrapper}>
        <LinearProgress
          className={classes.indicator}
          color={'primary'}
          style={width && {
            width: width,
          }}
          value={Math.min(percentage, 100)}
          variant={'determinate'}
        />
      </div>
      <p>{`${currentValue} / ${maxValue}`}</p>
    </div>
  );
}


ProgressDisplay.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  currentValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  width: PropTypes.string,
};

export default withStyles(styles)(ProgressDisplay);
