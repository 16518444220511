import React, {Fragment, useState} from 'react'
import {Tab, Tabs, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {MicroHubAdministrationEdit, TextPlaceholderWrapper} from '../../../components';
import {DayOfWeek, getDayOfWeekString} from '../../../services/enums/dayOfWeekHelper';
import {MicroHubPlanPresetService} from '../../../services/hubAdministrationHelper/hubAdministrationHelper';
import classNames from 'classnames';

function MicroHubAdministrationEditWithControls(props) {

  const {
    classes,
    t,
    className,
    microHubPlanData,
    disableFilter,
    setDisableFilter,
    updateBackendMicroHubPlansForDays,
    deleteMicroHubPlan,
    filter,
    shipperOptions,
    hubCarrierData
  } = props;
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(DayOfWeek.monday);

  const rootClassName = classNames(classes.root, className);

  const createMicroHubPlan = () => {
    updateBackendMicroHubPlansForDays(MicroHubPlanPresetService.getHubPlanPreset(), selectedDayOfWeek);
  };

  const updatePlansForDays = (planToUpdate, days) => {
    updateBackendMicroHubPlansForDays(planToUpdate, days);
  };

  const deletePlan = () => {
    deleteMicroHubPlan(selectedDayOfWeek);
  };

  const downloadMicroHubPlan = (url) => {
    const link = document.createElement('a');
    link.download = `${filter.carrierName}_${filter.microHubName}_${t(getDayOfWeekString(selectedDayOfWeek))}.png`;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getPlanForDayOfWeek = (dayOfWeek) => {
    if (!microHubPlanData || microHubPlanData.length <= 0) {
      return null;
    }
    const index = microHubPlanData.findIndex(plan => plan.dayOfWeek === dayOfWeek);
    return index < 0 ? null : microHubPlanData[index];
  };

  return (
    <div className={rootClassName}>
      <TextPlaceholderWrapper
        active={!microHubPlanData}
        text={t('microHubPlansNotAvailable')}
      >
        <Fragment>
          <Tabs
            className={classes.tabs}
            disabled
            onChange={(event, newValue) => {
              setSelectedDayOfWeek(DayOfWeek[Object.getOwnPropertyNames(DayOfWeek)[newValue]])
            }}
            value={Object.getOwnPropertyNames(DayOfWeek).map(name => DayOfWeek[name]).findIndex(x => x === selectedDayOfWeek)}
          >
            {
              Object.getOwnPropertyNames(DayOfWeek).map((day, index) => {
                return (
                  <Tab
                    className={getPlanForDayOfWeek(DayOfWeek[day]) ? classes.tabWithData : classes.tabsWithoutData}
                    disabled={disableFilter}
                    key={index}
                    label={t(getDayOfWeekString(DayOfWeek[day]))}
                  />
                );
              })
            }
          </Tabs>
          <MicroHubAdministrationEdit
            createMicroHubPlan={createMicroHubPlan}
            deleteMicroHubPlan={deletePlan}
            downloadMicroHubPlan={downloadMicroHubPlan}
            hubCarrierData={hubCarrierData}
            microHubData={microHubPlanData}
            microHubPlan={getPlanForDayOfWeek(selectedDayOfWeek)}
            selectedCarrierName={filter.carrierName}
            selectedDayOfWeek={selectedDayOfWeek}
            setDisableFilter={setDisableFilter}
            shipperFilter={filter.shipperName}
            shipperOptions={shipperOptions}
            updatePlansAtDays={updatePlansForDays}
          />
        </Fragment>
      </TextPlaceholderWrapper>
    </div>
  );

}

MicroHubAdministrationEditWithControls.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deleteMicroHubPlan: PropTypes.func.isRequired,
  disableFilter: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  microHubPlanData: PropTypes.array,
  setDisableFilter: PropTypes.func.isRequired,
  shipperOptions: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  updateBackendMicroHubPlansForDays: PropTypes.func.isRequired,
  hubCarrierData: PropTypes.object.isRequired
};

export default compose(withStyles(styles), withTranslation())(MicroHubAdministrationEditWithControls);
