import React, {useEffect, useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {Cancel as CancelIcon, DeleteForever as DeleteIcon, Save as SaveIcon} from '@material-ui/icons';
import {onCloseWithBackdropCurry} from '../../../../services/util/helperFuncs';

function DonationButtonDialog(props) {

  const {classes, t, donationButton, handleClose, handleCancel, open} = props;

  const [editedDonationButton, setEditedDonationButton] = useState('');

  useEffect(() => {
    if (open) {
      setEditedDonationButton(donationButton || '');
    }
  }, [open, donationButton]);

  const handleOk = () => {
    handleClose(editedDonationButton ? editedDonationButton : null);
    setEditedDonationButton('');
  };

  const handleNotOk = () => {
    handleCancel();
    setEditedDonationButton('');
  };


  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      fullWidth
      maxWidth={'sm'}
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={open}
    >
      <DialogTitle>{t('updateDonationButton')}</DialogTitle>
      <DialogContent>
        <div className={classes.container}>
          <TextField
            className={classes.formControl}
            focused
            label={t('donationButton')}
            onChange={(e) => setEditedDonationButton(e.target.value)}
            value={editedDonationButton}
          />
          <div className={classes.buttonContainer}>
            <Button
              className={classes.deleteButton}
              onClick={() => setEditedDonationButton('')}
              title={t('delete')}
            >
              <DeleteIcon/>
            </Button>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={handleNotOk}
        >
          <CancelIcon/>&nbsp;{t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          onClick={handleOk}
        >
          <SaveIcon/>&nbsp;{t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DonationButtonDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  donationButton: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(DonationButtonDialog);
