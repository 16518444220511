export default theme => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  card: {},
  map: {
    marginTop: theme.spacing(1),
    width: '100%',
    minHeight: 'calc(-195px + 100vh)',
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});
