export default theme => ({
    root: {},
    button: {
      marginLeft: theme.spacing(1)
    },
    wrapper: {
      display: 'flex',
      margin: theme.spacing(2)
    },
    wrapperChild: {
      flex: 1,
    },
    vehicleDetail: {
      margin: theme.spacing(1)
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '170px',
      width: '170px',
    },
    bulletPoint: {
      color: theme.palette.common.black,
      paddingRight: theme.spacing(1),
      minWidth: 0
    }
  });
  