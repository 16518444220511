export default theme => ({
  actionsContainer: {
    marginTop: theme.spacing(2),
  },
  buttonWrapper: {
    marginBottom: theme.spacing(1),
  },
  button: {
    float: 'right',
    display: 'inline-block'
  },
  navButton: {
    marginRight: theme.spacing(1)
  },
  infoDiv: {
    margin: theme.spacing(1),
  },
  carrierSelectForm: {
    margin: theme.spacing(1),
    minWidth: '250px'
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  iconMargin: {
    marginRight: '8px'
  }
})
;
