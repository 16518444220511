import {gql} from 'apollo-boost';

export class CarrierQueryService {

  static getCarrierProfileQuery(carrier) {
    return gql`
      {
        carriers(carrierName: "${carrier}") {
          id
          name
          useAlgorithmForTourPlanning
          address {
            street
            houseNumber
            city
            country
            zipcode
          }
          billingAddress {
            street
            houseNumber
            city
            zipcode
            country
          }
          contact {
            email
            name
            phoneNumber
          }
          vehicles {
            licensePlate
          }
          drivers {
            email
          }
          disponent {
            email
            name
            phoneNumber
          }
          hubs {
            name
          }
        }
      }
    `;
  }

  static getAllCarrierWithHubNames(carrierName) {
    return gql`
      {
        carriers(carrierName: "${carrierName}") {
          name
          hubs {
            name
          }
        }
      }
    `;
  }

  static getAllCarrierQuery() {
    return gql`
      {
        carriers {
          id
          useAlgorithmForTourPlanning
          name
          address {
            street
            houseNumber
            city
            country
            zipcode
          }
          billingAddress {
            street
            houseNumber
            city
            zipcode
            country
          }
          contact {
            email
            name
            phoneNumber
          }
          vehicles {
            licensePlate
          }
          disponent {
            email
            name
            phoneNumber
          }
          hubs {
            name
          }
        }
      }
    `;
  }

  static getAllMicroHubsOfCarrier(carrier) {
    return gql`
      {
        carriers(carrierName: "${carrier}") {
          name
          hubs {
            name
          }
        }
      }
    `;
  }

  static updateCarrierById() {
    return gql`
      mutation($carrier: CarrierInputType!) {
        updateCarrier(carrier: $carrier) {
          name
        }
      }
    `;
  }

  static deleteCarrierById() {
    return gql`
      mutation ($carrierId: Int!) {
        deleteCarrier(carrierId: $carrierId)
      }
    `;
  }

  static createCarrier() {
    return gql`
      mutation($carrier: CarrierInputType!) {
        createCarrier(carrier: $carrier) {
          name
        }
      }
    `;
  }

}
