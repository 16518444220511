import React, {Fragment, useState} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import SideNavLayout from '../../../layouts/SideNavLayout';
import {CreateVehicleDialog, DataLoadingWrapper, GeneralInfoIcon, VehicleDetails, VehicleTable} from '../../../components';
import {ClientService} from '../../../services/client/clientService';
import {clientTypes} from '../../../services/client/clientTypes';
import {AuthService} from '../../../services/auth/authService';
import {useMutation, useQuery} from '@apollo/react-hooks';
import omitDeep from 'omit-deep'
import {Add as AddIcon} from '@material-ui/icons';
import {displayModes} from '../../../services/enums/displayModes';
import {VehicleQueryService} from '../../../services/backend/vehicleQueryService';
import tourTemplatesService from '../../../services/backend/tourTemplatesService';
import useMicroHubOptionsOfCarrier from '../../../hooks/useMicroHubOptionsOfCarrier';

const clientVehicle = ClientService.getClient(clientTypes.vehicle);

function Vehicles(props) {

  const {classes, t} = props;

  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [createVehicleDialogState, setCreateVehicleDialogState] = useState({
    open: false,
  });

  const {microHubOptions} = useMicroHubOptionsOfCarrier(AuthService.getUserOrganization());

  const queryVehicles = VehicleQueryService.getVehiclesByCarrierNameAndMicroHubListQuery(AuthService.getUserOrganization(), microHubOptions);
  const {loading, error, data, refetch: refetchVehicles} = useQuery(queryVehicles, {client: clientVehicle, skip: microHubOptions.length === 0});

  const deleteVehicleQuery = VehicleQueryService.deleteVehicleByIdQuery();
  const [deleteVehicle] = useMutation(deleteVehicleQuery, {client: clientVehicle});

  const updateVehicleQuery = VehicleQueryService.updateVehicleQuery();
  const [updateVehicle] = useMutation(updateVehicleQuery, {client: clientVehicle});

  const createVehicleQuery = VehicleQueryService.createVehicleQuery();
  const [createVehicle] = useMutation(createVehicleQuery, {client: clientVehicle});

  const updateVehicleCallback = (updatedVehicle) => {
    const formattedUpdatedVehicle = omitDeep(updatedVehicle, '__typename');
    updateVehicle({variables: {updatedVehicle: formattedUpdatedVehicle}}).then(() => {
      refetchVehicles().then();
      setSelectedVehicle(null);
    }, () => {
      alert(t('errorUpdatingData'))
    });
  };

  const deleteVehicleCallback = (vehicle, event) => {
    event.stopPropagation();
    deleteVehicle({variables: {vehicleId: vehicle.id}}).then(() => {
      refetchVehicles().then();
      setSelectedVehicle(null);
    }, () => {
      alert(t('errorDeletingVehicle'))
    });
  };

  const handleCreateVehicleCancel = () => {
    setCreateVehicleDialogState({...createVehicleDialogState, open: false});
  };

  const handleCreateVehicleClose = (vehicle) => {
    createVehicle({variables: {vehicleToCreate: vehicle}}).then(() => {
      refetchVehicles().then();
    }, () => {
      alert(t('errorCreatingVehicle'))
    });
    setCreateVehicleDialogState({...createVehicleDialogState, open: false});
  };

  const getAutoCutTemplatesForVehicleAndMicroHubs = async (microHubNames, vehicleLicensePlate) => {
    try {
      return await tourTemplatesService.getAutoCutTourTemplatesForVehicleAndDay(AuthService.getUserOrganization(), microHubNames, vehicleLicensePlate)
    } catch (e) {
      alert((`${t('errorGettingAutoCutTemplatesForVehicles')}: ${e}`));
      return [];
    }
  };

  return (
    <SideNavLayout title={t('vehicles')}>
      <div className={classes.root}>
        <div className={classes.helpWrapper}>
          <GeneralInfoIcon/></div>
        <Button
          className={classes.addButton}
          color="primary"
          onClick={() => setCreateVehicleDialogState({...createVehicleDialogState, open: true})}
          title={t('createVehicle')}
          variant="contained"
        >
          <AddIcon/>&nbsp;{t('createVehicle')}
        </Button>
        <DataLoadingWrapper
          error={error}
          isLoading={loading}
        >
          {data && data.getVehiclesByCarrierNameAndMicroHubList &&
          <Fragment>
            <VehicleTable
              className={classes.content}
              deleteVehicle={deleteVehicleCallback}
              selectedVehicle={selectedVehicle}
              selectVehicle={setSelectedVehicle}
              tableDisplayMode={displayModes.carrier}
              vehicles={data.getVehiclesByCarrierNameAndMicroHubList}
            />
            {selectedVehicle &&
            <VehicleDetails
              className={classes.content}
              closeDetail={() => setSelectedVehicle(null)}
              getAutoCutTemplatesForVehicleAndMicroHubs={getAutoCutTemplatesForVehicleAndMicroHubs}
              microHubOptions={microHubOptions}
              updateVehicle={updateVehicleCallback}
              vehicle={selectedVehicle}
            />
            }
          </Fragment>
          }
          <CreateVehicleDialog
            createVehicleDialogState={createVehicleDialogState}
            handleCancel={handleCreateVehicleCancel}
            handleClose={handleCreateVehicleClose}
            microHubOptions={microHubOptions}
          />
        </DataLoadingWrapper>
      </div>
    </SideNavLayout>
  );
}


Vehicles.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(Vehicles);
