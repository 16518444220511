import React, {useContext, useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import SideNavLayout from '../../../layouts/SideNavLayout';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import SimulateTourBuildingComponent from '../../../components/SimulateTourBuilding';
import {AuthService} from '../../../services/auth/authService';
import {displayModes} from '../../../services/enums/displayModes';
import {GeneralInfoIcon} from '../../../components';
import useShipperOptions from '../../../hooks/useShipperOptions';
import {DatePickerDefaultValueContext} from '../../../context';
import useMicroHubOptionsOfCarrier from '../../../hooks/useMicroHubOptionsOfCarrier';

function SimulateTourBuilding(props) {

  const {classes, t} = props;
  const {microHubOptions, microHubData} = useMicroHubOptionsOfCarrier(AuthService.getUserOrganization(), true);
  const {fromDateDefault, toDateDefault, updateDefaultDate} = useContext(DatePickerDefaultValueContext);

  const [filter, setFilter] = useState({
    carrierName: AuthService.getUserOrganization(),
    shipperName: '',
    microHubName: '',
    stopStatus: '',
    fromDate: fromDateDefault,
    toDate: toDateDefault,
    boxId: ''
  });
  useEffect(() => updateDefaultDate(filter.fromDate), [filter.fromDate, updateDefaultDate]);
  const {shipperOptions} = useShipperOptions();

  return (
    <SideNavLayout title={t('simulateTourBuilding')}>
      <div className={classes.root}>
        <div className={classes.helpWrapper}>
          <GeneralInfoIcon/></div>
        <div style={{marginTop: '-35px'}}>
          <SimulateTourBuildingComponent
            carriers={[AuthService.getUserOrganization()]}
            displayMode={displayModes.carrier}
            filter={filter}
            microHubOptions={microHubOptions}
            microHubs={microHubData}
            setFilter={setFilter}
            shippers={shipperOptions}
          />
        </div>
      </div>
    </SideNavLayout>
  );

}

SimulateTourBuilding.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(SimulateTourBuilding);
