import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField, Typography, withStyles } from '@material-ui/core';
import styles from './styles'
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { Cancel as CancelIcon, Save as SaveIcon } from '@material-ui/icons';
import { getDayOfWeekFromNumber, getDayOfWeekString } from '../../../../../services/enums/dayOfWeekHelper';
import { DropdownSelectSimple, InfoIcon } from '../../../../index';
import CapacitySelection from './CapacitySelection';
import { onCloseWithBackdropCurry, roundCapacityValues } from '../../../../../services/util/helperFuncs';
import classNames from 'classnames';

function EditTourTemplateDialog(props) {

  const { classes, t, handleCancel, handleClose, templateToEdit, dialogState, vehicles, templateStopMode } = props;
  const [template, setTemplate] = useState(templateToEdit);

  useEffect(() => {
    if (dialogState.open && templateToEdit && vehicles) {
      const vehicleOptions = vehicles
        .filter(v => v.microhubs.some(hub => hub.name === templateToEdit.microHub && hub[getDayOfWeekString(getDayOfWeekFromNumber(templateToEdit.dayOfWeek))] === true))
        .map(v => v.licensePlate);
      const vehicle = vehicleOptions.includes(templateToEdit.vehicle) ? templateToEdit.vehicle : '';
      setTemplate({ ...templateToEdit, vehicle: vehicle });
    }
  }, [dialogState, templateToEdit, vehicles]);

  const dialogOk = () => {
    handleClose(template);
  };

  const handleNotOk = () => {
    handleCancel();
  };

  const canSave = () => {
    return (templateStopMode || (!template.isAutoCutTemplate &&
      (template.applyAtMin.volume === null || template.maxApplicable.volume === null || template.applyAtMin.volume <= template.maxApplicable.volume) &&
      (template.applyAtMin.weight === null || template.maxApplicable.weight === null || template.applyAtMin.weight <= template.maxApplicable.weight)
    ) || (template.isAutoCutTemplate && template.vehicle))
  };

  const vehicleOptions = vehicles
    .filter(v => v.microhubs.some(hub => hub.name === template.microHub && hub[getDayOfWeekString(getDayOfWeekFromNumber(template.dayOfWeek))] === true))
    .map(v => v.licensePlate);

  const selectedVehicle = template?.vehicle ? vehicles.find(vh => vh.licensePlate === template.vehicle) : null;

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      fullWidth
      maxWidth={'sm'}
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={dialogState.open}
    >
      <DialogTitle>{t('editTourTemplate')}
      </DialogTitle>
      <DialogContent>
        {template &&
          <form>
            <div className={classes.rowWrapper}>
              <TextField
                className={classes.formControl}
                label={t('tourTemplateName')}
                onChange={(e) => setTemplate({ ...template, templateName: e.target.value })}
                value={template.templateName}
              />
              <FormControlLabel
                className={classes.disabledCheckbox}
                control={
                  <Checkbox
                    checked={template.disabled}
                    color="primary"
                    onChange={(event) => setTemplate({ ...template, disabled: event.target.checked })}
                    value={template.disabled}
                  />
                }
                label={t('tourTemplateDisabled')}
              />
            </div>
            {!templateStopMode && <div className={classes.rowWrapper}>
              <div>
                <Typography
                  className={classes.capacityLabel}
                  gutterBottom
                >
                  {t('tourTemplateApplyAtMin')}&nbsp;
                  <InfoIcon
                    className={classes.infoIcon}
                    descriptionKey={'50'}
                  />
                </Typography>
                <CapacitySelection
                  capacity={template.applyAtMin}
                  className={''}
                  setCapacity={(newCapacity) => setTemplate({ ...template, applyAtMin: newCapacity })}
                />
              </div>
              {!template.isAutoCutTemplate &&
                <div>
                  <Typography
                    className={classes.capacityLabel}
                    gutterBottom
                  >
                    {t('tourTemplateMaxApplicable')}&nbsp;
                    <InfoIcon
                      className={classes.infoIcon}
                      descriptionKey={'60'}
                    />
                  </Typography>
                  <CapacitySelection
                    capacity={template.maxApplicable}
                    className={''}
                    setCapacity={(newCapacity) => setTemplate({ ...template, maxApplicable: newCapacity })}
                  />
                </div>
              }
            </div>
            }
            {template.isAutoCutTemplate && !templateStopMode &&
              <div className={classes.rowWrapper}>
                <div>
                  <DropdownSelectSimple
                    error={!template.vehicle}
                    name={(t('vehicle'))}
                    options={vehicleOptions}
                    required
                    setValue={(value) => setTemplate({ ...template, vehicle: value })}
                    sort
                    value={template.vehicle}
                  />
                  {selectedVehicle &&
                    <div className={classNames(classes.formControl, classes.vehicleCapacities)}>
                      <Typography>{t('vehiclePayloadWeight')}:&nbsp;{roundCapacityValues(selectedVehicle.payloadWeight)}</Typography>
                      <Typography>{t('vehicleVolume')}:&nbsp;{roundCapacityValues(selectedVehicle.volume)}</Typography>
                    </div>
                  }
                </div>
              </div>
            }
          </form>
        }
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={handleNotOk}
        >
          <CancelIcon />&nbsp;{t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          disabled={!canSave()}
          onClick={dialogOk}
        >
          <SaveIcon />&nbsp;{t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EditTourTemplateDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogState: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  templateToEdit: PropTypes.object.isRequired,
  vehicles: PropTypes.array.isRequired,
  templateStopMode: PropTypes.bool,
};


export default compose(withStyles(styles), withTranslation())(EditTourTemplateDialog);
