export default theme => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: '200px',
    display: 'flex',
    flexDirection: 'row',
  },
  formControlAlign: {
    margin: '0 0 0 16px',
    minWidth: '200px',
    display: 'flex',
    flexDirection: 'row'
  },
  legendControl: {
    margin: theme.spacing(1),
    minWidth: '200px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline'
  },
  tabCheckbox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoTab: {
    marginRight: '8px !important',
    marginTop: '5px'
  },
});
