export default {
  h1: {
    //styleName: H1
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: '0em',
    textAlign: 'left'
  },
  h2: {
    //styleName: H2;
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: '0em',
    textAlign: 'left'
  },
  h3: {
    //styleName: H3
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: '0em',
    textAlign: 'left'
  },
  body1: {
    //styleName": Paragraph
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0em',
    textAlign: 'left'
  },
  subtitle1: {
    //styleName": Subline
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  subtitle2: {
    //styleName: Info
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0em',
    textAlign: 'left'
  },
};

