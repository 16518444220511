import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from '@material-ui/core';
import styles from './styles';
import {
  DataLoadingWrapper,
  InfoIcon,
  Portlet,
  PortletContent,
  PortletHeader,
  PortletLabel, TextPlaceholderWrapper,
} from '../../../components';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import {displayModes} from '../../../services/enums/displayModes';
import MicroHubMeasurementCardRow from './components/MicroHubMeasurementCardRow';


function MicroHubMeasurementCard(props) {

  const {t, classes, className, isLoading, microHubMeasurementData, displayMode} = props;

  const rootClassName = classNames(classes.root, className);
  
  return (
    <Portlet
      className={rootClassName}
    >
      <PortletHeader className={classes.portletHeader}>
        <PortletLabel title={t('dashboardMicroHubMeasurementCard')}/>
        <InfoIcon descriptionKey={'50'}/>
      </PortletHeader>
      <PortletContent
        className={classes.portletContentStyle}
        noPadding
      >
        <TextPlaceholderWrapper
          active={microHubMeasurementData.length <= 0}
          text={t('noMicroHubMeasurementsAvailable')}
        >
          <DataLoadingWrapper
            isLoading={isLoading}
          >
            {(() => {
              switch (displayMode) {
                case displayModes.carrier:
                case displayModes.admin:
                  return (
                    <div className={classes.contentContainer}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell><Typography><strong>{t('microHub')}</strong></Typography></TableCell>
                              <TableCell colSpan={2}><Typography><strong>{t('temperature')}</strong></Typography></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {microHubMeasurementData && microHubMeasurementData.map((microHubData, index) =>
                              <MicroHubMeasurementCardRow
                                microHubMeasurementData={microHubData}
                                key={index}
                              />
                            )}
                          </TableBody>
                        </Table>
                    </div>
                  );
                case displayModes.shipper:
                default:
                  return (<div>Error: invalid displayMode</div>)
              }
            })()}
          </DataLoadingWrapper>
        </TextPlaceholderWrapper>
      </PortletContent>
    </Portlet>
  );
}

MicroHubMeasurementCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  microHubMeasurementData: PropTypes.array.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  error: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(MicroHubMeasurementCard);
