import React, {useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Portlet} from '../../index'
import {default as VehicleDetailEdit} from './VehicleDetailEdit';
import {default as VehicleDetailShow} from './VehicleDetailShow';

function VehicleDetails(props) {

  const [editMode, setEditMode] = useState(false);
  const [editedVehicle, setEditedVehicle] = useState(false);
  const [potentiallyAffectedTemplates, setPotentiallyAffectedTemplates] = useState([]);
  const {classes, className, vehicle, updateVehicle, microHubOptions, readonly, closeDetail, getAutoCutTemplatesForVehicleAndMicroHubs} = props;

  const editVehicle = async () => {
    setEditedVehicle(JSON.parse(JSON.stringify(vehicle)));
    setEditMode(true);
    if (getAutoCutTemplatesForVehicleAndMicroHubs) {
      const templates = await getAutoCutTemplatesForVehicleAndMicroHubs(vehicle.microhubs.map(m => m.name), vehicle.licensePlate);
      setPotentiallyAffectedTemplates(templates);
    }
  };

  const update = () => {
    setEditMode(false);
    updateVehicle(editedVehicle);
  };

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet className={rootClassName}>
      {editMode ?
        <VehicleDetailEdit
          editedVehicle={editedVehicle}
          microHubOptions={microHubOptions}
          potentiallyAffectedTemplates={potentiallyAffectedTemplates}
          setEditedVehicle={setEditedVehicle}
          setEditMode={setEditMode}
          updateVehicle={update}
        />
        :
        <VehicleDetailShow
          closeDetail={closeDetail}
          edit={editVehicle}
          readonly={readonly}
          vehicle={vehicle}
        />
      }
    </Portlet>
  );
}


VehicleDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  closeDetail: PropTypes.func.isRequired,
  getAutoCutTemplatesForVehicleAndMicroHubs: PropTypes.func,
  microHubOptions: PropTypes.array,
  readonly: PropTypes.bool,
  t: PropTypes.func.isRequired,
  updateVehicle: PropTypes.func,
  vehicle: PropTypes.object.isRequired,
}
;

export default compose(withStyles(styles), withTranslation())(VehicleDetails);
