import React, {Fragment} from 'react'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles
} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {PortletContent, PortletHeader} from '../../../../components';
import {
  Add as PlusIcon,
  Cancel as CancelIcon,
  DeleteForever as DeleteIcon,
  Remove as MinusIcon,
  Save as SaveIcon
} from '@material-ui/icons';
import {displayModes} from '../../../../services/enums/displayModes';

function CustomerDetailEdit(props) {

  const {classes, className, editedCustomer, setEditedCustomer, setEditMode, t, updateCustomer, displayMode} = props;

  const rootClassName = classNames(classes.root, className);

  const updateCarrierAndMhOfCustomer = (carrierWithMicroHub) => {
    const newCarrierWithMicroHub = editedCustomer.carrierWithMicroHub.filter(cm => cm.microHub !== carrierWithMicroHub.microHub || cm.carrier !== carrierWithMicroHub.carrier);
    setEditedCustomer({...editedCustomer, carrierWithMicroHub: newCarrierWithMicroHub});
  };

  return (
    <Fragment>
      <PortletHeader className={rootClassName}>
        <Fragment>
          <div><strong>{t('internalCustomerId')}: {editedCustomer.id}</strong></div>
          <div>
            <Button
              className={classes.button}
              color="default"
              onClick={() => setEditMode(false)}
              title={t('cancel')}
              variant="contained"
            >
              <CancelIcon/>&nbsp;{t('cancel')}
            </Button>
            <Button
              className={classes.button}
              color="primary"
              onClick={updateCustomer}
              title={t('save')}
              variant="contained"
            >
              <SaveIcon/>
            </Button>
          </div>
        </Fragment>
      </PortletHeader>
      <PortletContent noPadding>
        {(() => {
          switch (displayMode) {
            case displayModes.carrier:
              return (
                <div className={classes.wrapper}>
                  <div className={classes.wrapperChild}>
                    <div>
                      {t('additionalStopDeliveryTime')}:
                      <IconButton
                        color="secondary"
                        onClick={() => setEditedCustomer({...editedCustomer, additionalStopDeliveryTime: editedCustomer.additionalStopDeliveryTime - 5 * 60})}
                      >
                        <MinusIcon/>
                      </IconButton>
                      <strong>{Math.round(editedCustomer.additionalStopDeliveryTime / 60)}</strong>
                      <IconButton
                        color="secondary"
                        onClick={() => setEditedCustomer({...editedCustomer, additionalStopDeliveryTime: editedCustomer.additionalStopDeliveryTime + 5 * 60})}
                      >
                        <PlusIcon/>
                      </IconButton>
                    </div>
                  </div>
                  <div className={classes.wrapperChild}>
                    <FormControl className={classNames(classes.formControl, classes.textArea)}>
                      <InputLabel>{t('driverHint')}</InputLabel>
                      <Input
                        multiline
                        onChange={(event) => {
                          setEditedCustomer({...editedCustomer, driverHint: event.target.value})
                        }}
                        rowsMax={10}
                        value={editedCustomer.driverHint}
                      />
                    </FormControl>
                  </div>
                  <div className={classes.wrapperChild}>
                    <Table
                      className={classes.table}
                      size="small"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>{t('customerCarrierAndMicroHub')}</TableCell>
                          <TableCell>{t('delete')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {editedCustomer.carrierWithMicroHub.map((cm, index) => (
                          <TableRow key={index}>
                            <TableCell>{`${cm.carrier} - ${cm.microHub}`}</TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => updateCarrierAndMhOfCustomer(cm)}
                                title={t('delete')}
                              >
                                <DeleteIcon className={classes.redIcon}/>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              );
            case displayModes.admin:
              return (
                <div className={classes.wrapper}>
                  <div className={classes.wrapperChild}>
                    <FormControl className={classNames(classes.formControl, classes.textArea)}>
                      <InputLabel>{t('email')}</InputLabel>
                      <Input
                        onChange={(event) => {
                          setEditedCustomer({...editedCustomer, email: event.target.value})
                        }}
                        value={editedCustomer.email}
                      />
                    </FormControl>
                    <FormControlLabel
                      className={classes.formControl}
                      control={
                        <Checkbox
                          checked={editedCustomer.emailNotifications}
                          color="primary"
                          onChange={(event) => setEditedCustomer({...editedCustomer, emailNotifications: event.target.checked})}
                          value={editedCustomer.emailNotifications}
                        />
                      }
                      label={t('emailNotifications')}
                    />
                  </div>
                  <div className={classes.wrapperChild}>
                    <FormControl className={classNames(classes.formControl, classes.textArea)}>
                      <InputLabel>{t('driverHint')}</InputLabel>
                      <Input
                        multiline
                        onChange={(event) => {
                          setEditedCustomer({...editedCustomer, driverHint: event.target.value})
                        }}
                        rowsMax={10}
                        value={editedCustomer.driverHint}
                      />
                    </FormControl>
                    <div>
                      {t('additionalStopDeliveryTime')}:
                      <IconButton
                        color="secondary"
                        onClick={() => setEditedCustomer({...editedCustomer, additionalStopDeliveryTime: editedCustomer.additionalStopDeliveryTime - 5 * 60})}
                      >
                        <MinusIcon/>
                      </IconButton>
                      <strong>{Math.round(editedCustomer.additionalStopDeliveryTime / 60)}</strong>
                      <IconButton
                        color="secondary"
                        onClick={() => setEditedCustomer({...editedCustomer, additionalStopDeliveryTime: editedCustomer.additionalStopDeliveryTime + 5 * 60})}
                      >
                        <PlusIcon/>
                      </IconButton>
                    </div>
                  </div>
                  <div className={classes.wrapperChild}>
                    <Table
                      className={classes.table}
                      size="small"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>{t('customerCarrierAndMicroHub')}</TableCell>
                          <TableCell>{t('delete')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {editedCustomer.carrierWithMicroHub.map((cm, index) => (
                          <TableRow key={index}>
                            <TableCell>{`${cm.carrier} - ${cm.microHub}`}</TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => updateCarrierAndMhOfCustomer(cm)}
                                title={t('delete')}
                              >
                                <DeleteIcon className={classes.redIcon}/>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              );
            case displayModes.shipper:
              return (
                <div className={classes.wrapper}>
                  <div className={classes.wrapperChild}>
                    <FormControl className={classNames(classes.formControl, classes.textArea)}>
                      <InputLabel>{t('email')}</InputLabel>
                      <Input
                        onChange={(event) => {
                          setEditedCustomer({...editedCustomer, email: event.target.value})
                        }}
                        value={editedCustomer.email}
                      />
                    </FormControl>
                    <FormControlLabel
                      className={classes.formControl}
                      control={
                        <Checkbox
                          checked={editedCustomer.emailNotifications}
                          color="primary"
                          onChange={(event) => setEditedCustomer({...editedCustomer, emailNotifications: event.target.checked})}
                          value={editedCustomer.emailNotifications}
                        />
                      }
                      label={t('emailNotifications')}
                    />
                  </div>
                  <div className={classes.wrapperChild}/>
                  <div className={classes.wrapperChild}/>
                </div>
              );
            default:
              return (<div>Error: invalid displayMode</div>)
          }
        })()}
      </PortletContent>
    </Fragment>
  );
}


CustomerDetailEdit.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  editedCustomer: PropTypes.object.isRequired,
  setEditMode: PropTypes.func.isRequired,
  setEditedCustomer: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateCustomer: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(CustomerDetailEdit);
