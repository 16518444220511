import {gql} from 'apollo-boost';

export class MicroHubQueryService {

  static getMicrohubNames() {
    return gql`
  {
    microhubs {
      name
    }
  }
  `;
  }

  static getHubAreasByNameListQuery(hubNames, carrierName, shipperName) {
    const startSymbol = hubNames && hubNames.length > 0 ? '"' : '';
    return gql`
    {
      microhubs(microHubNames: [${startSymbol}${hubNames.join('", "')}${startSymbol}], carrierName: "${carrierName}", shipperName: "${shipperName}") {
        name
        carriers {
          carrier {
            name
          }
          weekDayShipperLists {
            weekDay
            microHubIsOpen
            allowList
            shippers {
              name
            }
          }
          coordinates {
            longitude
            latitude
            order
          }
        }
      }
    }
    `;
  }

  static getAllMicroHubsQuery() {
    return this.getMicroHubsByCarrierNameAndMicroHubNameList('', []);
  }

  static getAllMicroHubsOfCarrierQuery(carrierName) {
    return this.getMicroHubsByCarrierNameAndMicroHubNameList(carrierName, []);
  }

  static getHubsByHubNameListQuery(microHubList) {
    return this.getMicroHubsByCarrierNameAndMicroHubNameList('', microHubList);
  }

  static getMicroHubsByCarrierNameAndMicroHubNameList(carrierName, microHubNames) {
    const startSymbol = microHubNames && microHubNames.length > 0 ? '"' : '';
    return gql`
     {
      microhubs(carrierName: "${carrierName}", microHubNames: [${startSymbol}${microHubNames.join('", "')}${startSymbol}]) {
        b2B
        b2C
        address {
          city
          country
          zipcode
          street
          houseNumber
          lat
          lng
        }
        contact {
          email
          name
          phoneNumber
        }
        carriers {
          carrier {
            name
          }
          weekDayShipperLists {
            weekDay
            microHubIsOpen
            allowList
            shippers {
              name
            }
          }
          disponent {
            email
            name
            phoneNumber
          }
        }
        email
        name
        color
        id
      }
    }
    `;
  }

  static setHubAreasByNameQuery() {
    return gql`
    mutation ($microHubNames: [String]!, $carrierNames: [String]!, $microHubCoordinates: [[CoordinateInputType]]!) {
      updateMicroHubCoordinates(microHubNames: $microHubNames, carrierNames: $carrierNames, microHubCoordinates: $microHubCoordinates) {
      name
      carriers {
        carrier {
          name
        }
        coordinates {
          latitude
          longitude
          order
        }
      }
    }
   }
  `
  }

  static addMicroHubToCarrier() {
    return gql`
      mutation ($microHubName: String!, $carrierName: String!) {
        addMicroHubToCarrier(microHubName: $microHubName, carrierName: $carrierName) {
          name
        }
      }
    `;
  }

  static createMicroHub() {
    return gql`
      mutation ($microHub: MicrohubInputType!, $carrierName: String!) {
        createMicroHub(microHub: $microHub, carrierName: $carrierName) {
          name
        }
      }
    `;
  }

  static updateMicroHub() {
    return gql`
      mutation ($microHub: MicrohubInputType!) {
        updateMicroHub(microHub: $microHub) {
          name
        }
      }
    `;
  }

  static removeMicroHubFromCarrierQuery() {
    return gql`
      mutation ($microHubId: Int!, $carrierName: String!) {
        removeMicroHubFromCarrier(microHubId: $microHubId, carrierName: $carrierName)
      }
    `;
  }

  static deleteMicroHubQuery() {
    return gql`
       mutation ($microHubId: Int!) {
        deleteMicroHub(microHubId: $microHubId)
      }
    `;
  }

  static getMicroHubMaxVolume(carrierName, microHubName) {
    return gql`
    {
      multipleHubsAndCarrierPlans(carrierName: "${carrierName}", hubName: "${microHubName}") {
        carrierName
        microhubName
        hubPlans {
          maxVolume
          dayOfWeek
        }
      }
    }
    `
  }

  static getMicroHubPlansByCarrierNameAndMicroHubNameQuery(carrierName, microHubName) {
    return gql`
    {
      plans(carrierName: "${carrierName}", hubName: "${microHubName}") {
        carrierName
        microhubName
        hubPlans {
          maxVolume
          modifiedBy
          lastModified
          dayOfWeek
          description
          hubBase {
            depth
            width
            door {
              doorSize
              orientation
              positionX
              positionY
            }
          }
          beverageInsertZones {
            depth
            width
            positionX
            positionY
            order
          }
          boxZones {
            depth
            width
            positionX
            positionY
            stackHeight
            shipperInfos {
              name
              shortName
              order
            }
          }
          walls {
            positionX
            positionY
            orientation
            wallSize
          }
          blockerZones {
            positionX
            positionY
            width
            depth
            text
          }
        }
      }
    }
    `
  }

  static createOrUpdateMicroHubPlanQuery() {
    return gql`
      mutation ($planToUpdate: MicrohubPlanInputType!, $carrierName: String!, $microHubName: String!, $daysOfWeek: [DayOfWeek]) {
      createOrUpdatePlans(carrierName: $carrierName, hubName: $microHubName, daysOfWeek: $daysOfWeek, plan: $planToUpdate) {
        carrierName
        microhubName
        hubPlans {
          modifiedBy
          lastModified
          dayOfWeek
          description
          hubBase {
            depth
            width
            door {
              doorSize
              orientation
              positionX
              positionY
            }
          }
          beverageInsertZones {
            depth
            width
            positionX
            positionY
            order
          }
          boxZones {
            depth
            width
            positionX
            positionY
            stackHeight
            shipperInfos {
              name
              shortName
              order
            }
          }
          walls {
            positionX
            positionY
            orientation
            wallSize
          }
          blockerZones {
            positionX
            positionY
            width
            depth
            text
          }
        }
      }
    }
    `
  }

  static deleteMicroHubPlan() {
    return gql`
    mutation ($carrierName: String!, $microHubName: String!, $dayOfWeek: DayOfWeek!) {
      deletePlan(carrierName: $carrierName, hubName: $microHubName, dayOfWeek: $dayOfWeek)
    }
    `
  }

  static updatedMicroHubshipperRelationAndOperatingTimeAndDisponent() {
    return gql `
      mutation($microHubId: Int!, $carrierRelations: [CarrierRelationInputType]!) {
        updateMicroHubShipperRelationAndOperationTimeAndDisponent(microHubId: $microHubId, carrierRelations: $carrierRelations) {
          name
        }
      }
    `
  }
}
