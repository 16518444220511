import React, {Fragment} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {PortletContent, PortletHeader} from '../../../index';
import {Close as CloseIcon, Edit as EditIcon} from '@material-ui/icons';

function MicroHubSensorConfigDetailsShow(props) {

  const {classes, className, microHubSensorConfig, edit, t, readonly, closeDetail} = props;

  const rootClassName = classNames(classes.root, className);
  
  return (
    <Fragment>
      <PortletHeader className={rootClassName}>
        <Fragment>
          <div>
            <Fragment>
              <Button
                className={classes.button}
                color="default"
                onClick={closeDetail}
                title={t('close')}
                variant="contained"
              >
                <CloseIcon/>&nbsp;{t('cancel')}
              </Button>
              {!readonly &&
                < Button
                  className={classes.button}
                  color="primary"
                  onClick={edit}
                  title={t('microHubSensorConfigEdit')}
                  variant="contained"
                >
                  <EditIcon/>
                </Button>
              }
            </Fragment>
          </div>
        </Fragment>
      </PortletHeader>
      <PortletContent noPadding>
        <div className={classes.wrapper}>
          <div className={classes.wrapperChild}>
            <div className={classes.microHubSensorConfigDetail}><strong>{t('sensorId')}</strong>: {microHubSensorConfig.sensorId}</div>
            <div className={classes.microHubSensorConfigDetail}>
              <strong>{t('microHubName')}</strong>: {microHubSensorConfig.microHubName}
            </div>
          </div>
        </div>
      </PortletContent>
    </Fragment>
  );
}


MicroHubSensorConfigDetailsShow.propTypes = {
  microHubSensorConfig: PropTypes.object.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  closeDetail: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
  t: PropTypes.func.isRequired
};

export default compose(withStyles(styles), withTranslation())(MicroHubSensorConfigDetailsShow);
