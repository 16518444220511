export default theme => ({
  root: {},
  deliveryWindow: {
    marginLeft: theme.spacing(2),
  },
  redIcon: {
    color: theme.palette.danger.main,
  },
  greenIcon: {
    color: theme.palette.primary.main,
  },
  timeDisplay: {
    display: 'flex',
    alignItems: 'center',
  }
});
