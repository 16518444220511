import React from 'react'
import {Table, TableBody, TableCell, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Pagination, Portlet, PortletContent, TextPlaceholderWrapper} from '../../../../../../components'
import useTablePagination from '../../../../../../hooks/useTablePagination';

function SendEmptiesTable(props) {

  const {classes, className, sentEmpties, t} = props;
  
  const {paginationProps, pagedObjects} = useTablePagination(sentEmpties);
  const rootClassName = classNames(classes.root, className);

  return (
    <TextPlaceholderWrapper
      active={!sentEmpties}
      text={t('noSentEmptiesFound')}
    >
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell className={classes.alignLeft}>{t('date')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('shipper')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('externalCustomerId')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('empties')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedObjects.map((sentEmpties, index) => (
                <TableRow
                  className={classes.tableRow}
                  hover
                  key={index}
                >
                  <TableCell className={classes.tableCell}>
                    {(new Date(sentEmpties.createdAt)).toLocaleString()}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {sentEmpties.internalCustomerId}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {sentEmpties.externalCustomerId}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {sentEmpties.emptyInfos}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination paginationProps={paginationProps}/>
        </PortletContent>
      </Portlet>
    </TextPlaceholderWrapper>
  );
}


SendEmptiesTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  sentEmpties: PropTypes.array.isRequired,
};

SendEmptiesTable.defaultProps = {
  boxToShipperMappings: [],
};


export default compose(withStyles(styles), withTranslation())(SendEmptiesTable);
