import {AuthService} from '../auth/authService';
import axios from 'axios';

const tourBaseUri = `${process.env.REACT_APP_API_URI}routing`;
const previewBaseUri = `${process.env.REACT_APP_API_URI}preview`;

const runAlgorithm = async (dateToRun, shipperName) => {
  const response = await axios.get(`${tourBaseUri}/algorithm/run/${dateToRun}/${shipperName}`,
    {
      headers: {
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const runScheduler = async () => {
  const response = await axios.get(`${tourBaseUri}/algorithm/schedule`,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const runPreviewScheduler = async () => {
  const response = await axios.get(`${previewBaseUri}/schedule/schedule`,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const runEmptiesScheduler = async () => {
  const response = await axios.get(`${tourBaseUri}/algorithm/schedule/empties`,
        {
            headers: {
                'Content-Type': 'application/json',
                authorization: AuthService.getAuthHeader()
            }
        });
    return response.data;
};

const runAlgorithmForCarrierStops = async (dateToRun, stopIds, doNotMixShippersOnTour, useAlgorithm, useTourTemplates) => {
  const date = new Date(Date.UTC(new Date(dateToRun).getFullYear(), new Date(dateToRun).getMonth(), new Date(dateToRun).getDate())).toISOString();
  const response = await axios.put(`${tourBaseUri}/algorithm/runForCarrierStops/${date}`,
    {
      stopIds: stopIds,
      doNotMixShippersOnTour: doNotMixShippersOnTour,
      useAlgorithm: useAlgorithm,
      useTourTemplates: useTourTemplates
    },
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const getFinalStopDeadline = async () => {
  const response = await axios.get(`${tourBaseUri}/TourEditDeadline`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: AuthService.getAuthHeader()
    }
  });
  return response.data;
};

const updateFinalStopDeadline = async (seconds) => {
  const response = await axios.put(`${tourBaseUri}/TourEditDeadline/${seconds}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

export default {runAlgorithm, runScheduler, runPreviewScheduler, runAlgorithmForCarrierStops, runEmptiesScheduler, getFinalStopDeadline, updateFinalStopDeadline};
