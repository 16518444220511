export class SearchStopService {

  static searchStops(stops, searchInput) {
    if (!stops || stops.length <= 0) {
      return [];
    }

    if (!searchInput) {
      return stops;
    }

    return stops.filter(
      (stop) => {
        return stop.tourStopId.toString().includes(searchInput.toLowerCase()) ||
          (stop.firstName ? stop.firstName.toLowerCase().includes(searchInput.toLowerCase()) : false) ||
          (stop.lastName ? stop.lastName.toLowerCase().includes(searchInput.toLowerCase()) : false) ||
          (stop.address && stop.address.streetAndNumber ? stop.address.streetAndNumber.toLowerCase().includes(searchInput.toLowerCase()) : false) ||
          (stop.address && stop.address.zipcode ? stop.address.zipcode.toLowerCase().includes(searchInput.toLowerCase()) : false) ||
          (stop.customerId ? stop.customerId.toLowerCase().includes(searchInput.toLowerCase()) : false) ||
          (stop.internalCustomerId ? stop.internalCustomerId.toString().toLowerCase().includes(searchInput.toLowerCase()) : false);
      }
    );
  }
}
