import {
  canNotDeliverIconColor,
  deliveredIconColor,
  inDeliveryIconColor,
  notPlanedIconColor,
  planedIconColor,
  redeliveryIconColor
} from '../icon/iconService';

export const StopStatus = {
  NotPlaned: 0,
  Planed: 1,
  InDelivery: 2,
  Delivered: 3,
  CanNotDeliver: 4,
  Redelivery: 5,
};

export function getStopStatusString(status) {
  switch (status) {
    case StopStatus.NotPlaned:
      return 'stopStatusNotPlaned';
    case StopStatus.Planed:
      return 'stopStatusPlaned';
    case StopStatus.InDelivery:
      return 'stopStatusInDelivery';
    case StopStatus.Delivered:
      return 'stopStatusDelivered';
    case StopStatus.CanNotDeliver:
      return 'stopStatusCanNotDeliver';
    case StopStatus.Redelivery:
      return 'stopStatusRedelivery';
    default:
      return 'stopStatusUndefined'
  }
}

export function getColorByStatus(status) {
  switch (status.toString()) {
    case `${StopStatus.NotPlaned}`:
      return notPlanedIconColor;
    case `${StopStatus.Planed}`:
      return planedIconColor;
    case `${StopStatus.InDelivery}`:
      return inDeliveryIconColor;
    case `${StopStatus.Delivered}`:
      return deliveredIconColor;
    case `${StopStatus.CanNotDeliver}`:
      return canNotDeliverIconColor;
    case `${StopStatus.Redelivery}`:
      return redeliveryIconColor;
    default:
      return planedIconColor;
  }
}

export function getAllowedStatusChanges(status) {
  switch (status) {
    case StopStatus.NotPlaned:
      return [];
    case StopStatus.Planed:
      return [];
    case StopStatus.InDelivery:
      return [StopStatus.Delivered, StopStatus.CanNotDeliver];
    case StopStatus.Delivered:
      return [StopStatus.CanNotDeliver];
    case StopStatus.CanNotDeliver:
      return [StopStatus.Delivered];
    case StopStatus.Redelivery:
      return [StopStatus.Delivered, StopStatus.CanNotDeliver];
    default:
      return [];
  }
}
