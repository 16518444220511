import React from 'react'
import {Table, TableBody, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {
  Pagination,
  Portlet,
  PortletContent,
  TableCellWithConfig,
  TableSettings,
  TextPlaceholderWrapper
} from '../../index'
import useTablePagination from '../../../hooks/useTablePagination';
import useTableConfig from '../../../hooks/useTableConfig';
import DefaultConfig from './config';
import {getTableKey} from '../../../services/util/tableConfigHelper';
import {TableKind} from '../../../services/enums/TableKind';
import {displayModes} from '../../../services/enums/displayModes';
import Columns from './columns';

function BoxTypeTable(props) {

  const {classes, className, boxTypes, t, selectBoxType, deleteBoxType, selectedBoxType, displayMode} = props;

  const {
    config,
    columnConfigs,
    updateConfig
  } = useTableConfig(
    DefaultConfig,
    displayMode,
    getTableKey(TableKind.BoxTypeTable, displayMode),
  )

  const {paginationProps, pagedObjects} = useTablePagination(boxTypes);

  const rootClassName = classNames(classes.root, className);

  return (
    <TextPlaceholderWrapper
      active={!boxTypes?.length}
      text={t('noBoxTypesFound')}
    >
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <TableSettings
            updateConfig={updateConfig}
            tableConfig={config}
            columns={Columns}
          />
          <Table size="medium">
            <TableHead>
              <TableRow>
                {columnConfigs.map(columnConfig => (
                  <TableCellWithConfig
                    key={columnConfig.order}
                    config={columnConfig}
                  >
                    {Columns[columnConfig.columnName].header({t})}
                  </TableCellWithConfig>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedObjects.map((boxType, index) => (
                <TableRow
                  className={classNames(classes.tableRow, 'cursorPointer')}
                  hover
                  key={index}
                  onClick={() => selectBoxType(boxType)}
                  selected={selectedBoxType && selectedBoxType.boxTypeId === boxType.boxTypeId}
                >
                  {columnConfigs.map(columnConfig => (
                    <TableCellWithConfig
                      key={columnConfig.order}
                      config={columnConfig}
                      className={classes.tableCell}
                    >
                      {Columns[columnConfig.columnName].body({
                        boxType, classes, t, deleteBoxType
                      })}
                    </TableCellWithConfig>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination paginationProps={paginationProps}/>
        </PortletContent>
      </Portlet>
    </TextPlaceholderWrapper>
  );
}


BoxTypeTable.propTypes = {
  boxTypes: PropTypes.array.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deleteBoxType: PropTypes.func,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  selectBoxType: PropTypes.func.isRequired,
  selectedBoxType: PropTypes.object,
  t: PropTypes.func.isRequired,
};

BoxTypeTable.defaultProps = {
  BoxTypes: [],
};


export default compose(withStyles(styles), withTranslation())(BoxTypeTable);
