import React, {Fragment} from 'react'
import {Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {DateService} from '../../../../services/util/DateService';

function SchedulerResult(props) {

  const {classes, t, result, error} = props;

  return (
    <div>
      {error &&
        <div>
          <Typography
            color={'error'}
            variant={'h5'}
          >
            {t('schedulingFailed')}
          </Typography>
        </div>
      }
      {result &&
        <div>
          <Typography
            color={'primary'}
            variant={'h5'}
          >
            {t('schedulingSuccessfully')}
          </Typography>
          <Typography variant={'body1'}>
            {`${t('serverTime')}: ${new Date(result.serverTime).toLocaleString()}`}
          </Typography>
          <Typography variant={'h6'}>
            {t('shipperTimings')}:
          </Typography>
          <div className={classes.details}>
            {result.shipperTimings && result.shipperTimings.map((timing, index) => {
              return (
                <Fragment key={index}>
                  <Typography variant={'body1'}>
                    {`${t('shipper')}: ${timing.shipper}`}
                  </Typography>
                  <Typography variant={'body1'}>
                    {`${t('triggerTime')}: ${new Date(timing.triggerTime).toLocaleString()}`}
                  </Typography>
                  <Typography variant={'body1'}>
                    {`${t('deliveryDate')}: ${DateService.displayDate(new Date(timing.deliveryDate))}`}
                  </Typography>
                  <br/>
                </Fragment>
              )
            })}
          </div>
          <Typography variant={'h6'}>
            {t('shipperBeyondDeadline')}:
          </Typography>
          <div className={classes.details}>
            {result.shippersBeyondDeadline && result.shippersBeyondDeadline.map((shipper, index) => {
              return (
                <Typography key={index} variant={'body1'}>
                  {shipper}
                </Typography>
              )
            })
            }
          </div>
        </div>
      }
    </div>
  );

}

SchedulerResult.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.bool,
  result: PropTypes.object,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(SchedulerResult);
