// eslint-disable-next-line no-unused-vars
export default theme => ({
  wrapper: {
    display: 'flex',
    margin: theme.spacing(0)
  },
  wrapperChild: {
    flex: 1,
  },
});
