import React, {useCallback, useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {SideNavLayout} from '../../../layouts';
import MicroHubMeasurementQueryService from "../../../services/backend/microHubMeasurementQueryService";
import {
  BatteryStatus,
  LoadingWrapper,
  MicroHubMeasurementFilter, SensorDataGraph,
  TextPlaceholderWrapper
} from "../../../components";
import useCarriersWithHubs from "../../../hooks/useCarriersWithHubs";

function MicroHubMeasurements(props) {
  const {classes, t} = props;
  
  const [backendLoading, setBackendLoading] = useState(false);
  const [sensorData, setSensorData] = useState([]);
  const [filter, setFilter] = useState({
    dateTimeFrom: new Date(),
    dateTimeTo: new Date(),
    carrierName: '',
    microHubName: ''
  });
  
  const {carriersWithHubs} = useCarriersWithHubs();
  
  const loadMicroHubMeasurements = useCallback(async () => {
    if(!filter.microHubName || !filter.dateTimeFrom || !filter.dateTimeTo) {
      return;
    }
    setBackendLoading(true);
    try {
      const microHubMeasurements = await MicroHubMeasurementQueryService.getMicroHubMeasurements(filter.microHubName, filter.dateTimeFrom, filter.dateTimeTo);
      setSensorData(microHubMeasurements);
      setBackendLoading(false);
    } catch (error) {
      alert(`${t('errorLoadingMicroHubMeasurements')}, ${error}`);
      setBackendLoading(false);
    }
  }, [filter.dateTimeFrom, filter.dateTimeTo, filter.microHubName, t]);

  useEffect(() => {
    loadMicroHubMeasurements();
  }, [loadMicroHubMeasurements, filter]);
  
  return (
      <SideNavLayout title={t('microHubMeasurements')}>
        <div className={classes.root}>
          <div className={classes.filterWrapper}>
            <MicroHubMeasurementFilter
                filter={filter}
                setFilter={setFilter}
                carriersWithMicroHubs={carriersWithHubs}
            />
          </div>
        </div>
        <div>
          <LoadingWrapper loading={backendLoading}>
            <div>
              <TextPlaceholderWrapper
                  active={sensorData && sensorData.length <= 0}
                  text={t('noMicroHubMeasurementsAvailable')}
              >
                {sensorData && sensorData.length > 0 && (
                    <>
                      <BatteryStatus
                        className={classes.batteryStatus}
                        sensorData={sensorData}
                      />
                      <SensorDataGraph
                        sensorData={sensorData}
                      />
                    </>
                )}
              </TextPlaceholderWrapper>
            </div>
          </LoadingWrapper>
        </div>
      </SideNavLayout>
  );
}

MicroHubMeasurements.propTypes = {
  classes: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(MicroHubMeasurements);