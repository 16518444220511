export default theme => ({
  root: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    verticalAlign: 'middle'
  },
  popoverRoot: {
    padding: theme.spacing(1)
  }
});
