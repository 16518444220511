import React, {Fragment} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {PortletContent, PortletHeader} from '../../../index';
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import {default as VehicleEdit} from '../../VehicleEdit'
import {DayOfWeek, getDayOfWeekFromNumber, getDayOfWeekString} from '../../../../services/enums/dayOfWeekHelper';

function VehicleDetailEdit(props) {

  const {classes, className, editedVehicle, setEditMode, t, updateVehicle, setEditedVehicle, microHubOptions, potentiallyAffectedTemplates} = props;

  const rootClassName = classNames(classes.root, className);


  const save = () => {
    // iterate over all templates and check if they are affected by the mh change
    const affectedTemplates = [];
    potentiallyAffectedTemplates.forEach(template => {
      const vehicleMHForTemplate = (editedVehicle.microhubs.filter(mh => mh.name === template.microHub))[0];
      if (!vehicleMHForTemplate) return;
      const inactiveVehicleDays = Object.values(DayOfWeek).filter(dw => !vehicleMHForTemplate[getDayOfWeekString(dw)]);
      if (inactiveVehicleDays.includes(getDayOfWeekFromNumber(template.dayOfWeek))) {
        affectedTemplates.push(`${template.id}${template.templateName ? ` - ${template.templateName}` : ''}`)
      }
    });

    if (affectedTemplates.length === 0) {
      updateVehicle();
    } else if (window.confirm(t('tourTemplatesAffectedByVehicleChangeConfirm') + '\n' + affectedTemplates.join('\n'))) {
      updateVehicle()
    } else {
      setEditMode(false)
    }
  };

  return (
    <Fragment>
      <PortletHeader className={rootClassName}>
        <Fragment>
          <div><strong>{editedVehicle.licensePlate}</strong></div>
          <div>
            <Button
              className={classes.button}
              color="default"
              onClick={() => setEditMode(false)}
              title={t('vehicleCancel')}
              variant="contained"
            >
              <CancelIcon/>&nbsp;{t('cancel')}
            </Button>
            <Button
              className={classes.button}
              color="primary"
              disabled={!editedVehicle.licensePlate || editedVehicle.microhubs.length === 0 || !editedVehicle.type || editedVehicle.licensePlate.length > 15}
              onClick={save}
              title={t('vehicleSave')}
              variant="contained"
            >
              <SaveIcon/>
            </Button>
          </div>
        </Fragment>
      </PortletHeader>
      <PortletContent noPadding>
        <VehicleEdit
          className={classes.wrapper}
          editedVehicle={editedVehicle}
          microHubOptions={microHubOptions}
          setEditedVehicle={setEditedVehicle}
        />
      </PortletContent>
    </Fragment>
  );
}


VehicleDetailEdit.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  editedVehicle: PropTypes.object.isRequired,
  microHubOptions: PropTypes.array.isRequired,
  potentiallyAffectedTemplates: PropTypes.array.isRequired,
  setEditMode: PropTypes.func.isRequired,
  setEditedVehicle: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateVehicle: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(VehicleDetailEdit);
