import React, {Fragment} from 'react'
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {DropdownSelect, DropdownSelectSimple} from '../../index';
import {displayModes} from '../../../services/enums/displayModes';


function CarrierMicroHubAndDriverDropdownSelect(props) {

  const {
    className,
    t,
    carrierValue,
    microHubValue,
    driverValue,
    setCarrierValue,
    setMicroHubValue,
    setDriverValue,
    carriersMicroHubsDrivers,
    driverDisplayNames,
    disabled,
    carrierDescriptionKey,
    microHubDescriptionKey,
    driverDescriptionKey,
    errorCarrier,
    errorMicroHub,
    errorDriver,
    carrierRequired,
    microHubRequired,
    driverRequired,
    displayMode,
  } = props;


  let microHubOptionsCalculated = [];
  const carrierOptionsCalculated = [];
  const driverOptionsCalculated = [];

  // populate carrier
  Object.keys(carriersMicroHubsDrivers).forEach(carrier => {
    carrierOptionsCalculated.push(carrier);
    if (!carrierValue || carrierValue === carrier) {
      if (Array.isArray(carriersMicroHubsDrivers[carrier])) {
        microHubOptionsCalculated = microHubOptionsCalculated.concat(carriersMicroHubsDrivers[carrier])
      } else {
        // populate microhubs
        Object.keys(carriersMicroHubsDrivers[carrier]).forEach(microhub => {
          if (!microHubOptionsCalculated.includes(microhub)) {
            microHubOptionsCalculated.push(microhub);
          }
          if (carriersMicroHubsDrivers[carrier][microhub]) {
            carriersMicroHubsDrivers[carrier][microhub].forEach(driver => {
              if (!driverOptionsCalculated.includes(driver)) {
                driverOptionsCalculated.push(driver);
              }
            });
          }
        });
      }
    }
  });

  const driversWithNamesCalculated = driverOptionsCalculated.map(driver => {
    return {
      name: driverDisplayNames[driver],
      value: driver
    }
  });

  return (
    <div className={className}>
      {displayMode !== displayModes.carrier &&
      <DropdownSelectSimple
        descriptionKey={carrierDescriptionKey}
        disabled={disabled}
        error={errorCarrier}
        hideOnEmptyOptions
        name={t('carrier')}
        options={carrierOptionsCalculated}
        required={carrierRequired}
        setValue={setCarrierValue}
        sort
        value={carrierValue}
      />
      }
      {displayMode !== displayModes.shipper &&
      <Fragment>
        <DropdownSelectSimple
          descriptionKey={microHubDescriptionKey}
          disabled={disabled}
          error={errorMicroHub}
          hideOnEmptyOptions
          name={t('microHub')}
          options={microHubOptionsCalculated}
          required={microHubRequired}
          setValue={setMicroHubValue}
          sort
          value={microHubValue}
        />
        <DropdownSelect
          descriptionKey={driverDescriptionKey}
          disabled={disabled}
          error={errorDriver}
          hideOnEmptyOptions
          name={t('driver')}
          options={driversWithNamesCalculated}
          required={driverRequired}
          setValue={setDriverValue}
          sort
          value={driverValue}
        />
      </Fragment>
      }
    </div>
  );
}


CarrierMicroHubAndDriverDropdownSelect.propTypes = {
  carrierDescriptionKey: PropTypes.string,
  carrierRequired: PropTypes.bool,
  carrierValue: PropTypes.string.isRequired,
  carriersMicroHubsDrivers: PropTypes.object.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  driverDescriptionKey: PropTypes.string,
  driverDisplayNames: PropTypes.object.isRequired,
  driverRequired: PropTypes.bool,
  driverValue: PropTypes.string.isRequired,
  errorCarrier: PropTypes.bool,
  errorDriver: PropTypes.bool,
  errorMicroHub: PropTypes.bool,
  microHubDescriptionKey: PropTypes.string,
  microHubRequired: PropTypes.bool,
  microHubValue: PropTypes.string.isRequired,
  setCarrierValue: PropTypes.func.isRequired,
  setDriverValue: PropTypes.func.isRequired,
  setMicroHubValue: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,

};

export default withTranslation()(CarrierMicroHubAndDriverDropdownSelect);
