import React, {useState} from 'react'
import {FormControl, Input, InputLabel, MenuItem, Select, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {DropdownSelectSimple} from '../../index';

function SelectMapFilter(props) {

  const {classes, className, t, filter, setFilter, dataMicroHubs, shipperOptions} = props;

  const carrierOptions = [];
  let microHubOptions = [];
  const [microHubList, setMicroHubList] = useState(filter.microHubList);

  if (dataMicroHubs) {
    dataMicroHubs.forEach(hub => {
      if (!filter.carrierName || hub.carriers.map(c => c.carrier.name).includes(filter.carrierName)) {
        microHubOptions.push(hub.name);
      }
      hub.carriers.forEach(carrier => {
        if (!carrierOptions.includes(carrier.carrier.name)) {
          carrierOptions.push(carrier.carrier.name);
        }
      })
    });
  }

  const rootClassName = classNames(classes.root, className);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <div className={rootClassName}>
      <form className={classes.rowWrapper}>
        <DropdownSelectSimple
          hideOnEmptyOptions
          name={t('carrier')}
          options={carrierOptions}
          setValue={(value) => {
            setFilter({...filter, carrierName: value, microHubList: []});
            setMicroHubList([])
          }}
          sort
          value={filter.carrierName}
        />
        {microHubOptions && microHubOptions.length > 0 &&
        <FormControl className={classes.formControl}>
          <InputLabel
            htmlFor="microHubs"
          >{t('microHubs')}</InputLabel>
          <Select
            input={<Input id="microHubs"/>}
            MenuProps={MenuProps}
            multiple
            onChange={event => setMicroHubList(event.target.value)}
            onClose={() => setFilter({...filter, microHubList: microHubList})}
            renderValue={selected => (
              <div
                className={classes.chips}>
                <Typography variant={'body2'}>
                  {selected.length > 2 ? selected.slice(0, 2).join(', ') + '...' : selected.join(', ')}
                </Typography>
              </div>
            )}
            value={microHubList}
          >
            {microHubOptions.sort().map(name => (
              <MenuItem
                key={name}
                value={name}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        }
        <DropdownSelectSimple
          hideOnEmptyOptions
          name={t('shipper')}
          options={shipperOptions}
          setValue={(value) => setFilter({...filter, shipperName: value})}
          sort
          value={filter.shipperName}
        />
      </form>
    </div>
  );
}


SelectMapFilter.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  dataMicroHubs: PropTypes.array,
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  shipperOptions: PropTypes.array,
  t: PropTypes.func.isRequired
};

export default compose(withStyles(styles), withTranslation())(SelectMapFilter);
