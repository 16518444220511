import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Paper, withStyles} from '@material-ui/core';
import styles from './styles'

const CustomPaper = (props) => {
  const {classes, className, outlined, squared, children, ...rest} = props;

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.squared]: squared,
      [classes.outlined]: outlined
    },
    className
  );

  return (
    <Paper
      {...rest}
      className={rootClassName}
    >
      {children}
    </Paper>
  );
};

CustomPaper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  elevation: PropTypes.number,
  outlined: PropTypes.string,
  squared: PropTypes.string
};

CustomPaper.defaultProps = {
  squared: 'false',
  outlined: 'true',
  elevation: 0
};

export default withStyles(styles)(CustomPaper);
