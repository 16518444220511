import {Tooltip, withStyles} from '@material-ui/core';
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {WarningRounded as WarnIcon} from '@material-ui/icons';
import {compose} from 'recompose';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import Typography from '@material-ui/core/Typography';

const TourStoragePlaces = (props) => {

  const {tour, maxDisplay, classes, t} = props;

  if (tour?.storagePlaces && tour.stops && tour.stops.length > 0) {
    return (
      <Fragment>
        {tour.stops.some(stop => !stop.storagePlaces || stop.storagePlaces.length === 0) &&
        <Fragment>
          <Tooltip title={t('noStoragePlaceAvailableTour')}>
            <WarnIcon className={classes.warningColor}/>
          </Tooltip>&nbsp;
        </Fragment>
        }
        {(!maxDisplay || tour.storagePlaces.length <= maxDisplay)
          ? tour.storagePlaces.join(', ')
          :
          <Tooltip title={tour.storagePlaces.join(', ')}>
            <Typography>
              {tour.storagePlaces.slice(0, maxDisplay).join(', ') + '...'}
            </Typography>
          </Tooltip>
        }
      </Fragment>
    )
  }

  return ('-');

}

TourStoragePlaces.propTypes = {
  classes: PropTypes.object.isRequired,
  maxDisplay: PropTypes.number,
  t: PropTypes.func.isRequired,
  tour: PropTypes.shape({storagePlaces: PropTypes.array.isRequired, stops: PropTypes.array.isRequired}).isRequired
}

export default compose(withStyles(styles), withTranslation())(TourStoragePlaces);
