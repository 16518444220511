import React, {forwardRef} from 'react';
import classNames from 'classnames'
import {NavLink} from 'react-router-dom'
import {Divider, List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import styles from './styles'
import {GetApp as DownloadAppIcon,} from '@material-ui/icons'
import {withStyles} from '@material-ui/styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';

const linksInGroups = {
  downloadApp: '/get-app',
};

function Sidebar(props) {

  const {classes, className, t} = props;

  const rootClassName = classNames(classes.root, className);

  const MyNavLink = forwardRef((props, ref) => (
    <NavLink
      innerRef={ref}
      {...props}
    />
  ));

  return (
    <nav className={rootClassName}>
      <div className={classes.logoWrapper}>
        <img
          alt=""
          height="54px"
          src="/images/logo100px.png"
        />
      </div>
      <Divider className={classes.logoDivider}/>
      <List
        component="div"
        disablePadding
      >
        <ListItem
          activeClassName={classes.activeListItem}
          className={classes.listItem}
          component={MyNavLink}
          to={linksInGroups.downloadApp}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <DownloadAppIcon/>
          </ListItemIcon>
          <ListItemText
            classes={{primary: classes.listItemText}}
            primary={t('appDownload')}
          />
        </ListItem>
      </List>
    </nav>
  );
}

Sidebar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(Sidebar);
