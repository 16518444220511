export default theme => ({
  root: {},
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '3px',
  },
});

