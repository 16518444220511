import React, {Fragment} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Close as CloseIcon, Edit as EditIcon,} from '@material-ui/icons';
import 'moment-duration-format';
import {PortletContent, PortletHeader} from 'components';
import {AuthService} from 'services/auth/authService';
import {DateService} from 'services/util/DateService';
import {userPermissions} from 'services/auth/userPermissions';
import {displayAddress, displayShipperAndShipperShortName} from 'services/util/helperFuncs';
import {getTemplateStopTypeString, TemplateStopType} from '../../../../services/enums/TemplateStopType';
import Paper from '@material-ui/core/Paper';

function TemplateStopInstanceDetailShow(props) {

  const {
    classes,
    className,
    templateStopInstance,
    includeRelatedTemplateStopInstances,
    relatedTemplateStopInstances,
    edit,
    t,
    readOnly,
    closeDetailView,
    selectTemplateStopInstance
  } = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <Fragment>
      <PortletHeader className={rootClassName}>
        <Fragment>
          <div><strong>{t('templateStopInstanceId')}: {templateStopInstance.id}</strong></div>
          <div>
            {closeDetailView &&
              <Button
                className={classes.button}
                color="default"
                onClick={closeDetailView}
                title={t('close')}
                variant="contained"
              >
                <CloseIcon />
              </Button>
            }
            {((AuthService.getUserPermission() !== userPermissions.user) && !readOnly) &&
              <Button
                className={classes.button}
                color="primary"
                onClick={edit}
                title={t('templateStopInstanceEdit')}
                variant="contained"
              >
                <EditIcon />
              </Button>
            }
          </div>
        </Fragment>
      </PortletHeader>
      <PortletContent noPadding>
        <div className={classes.wrapper}>
          <div className={classes.wrapperChild}>
            <div className={classes.templateStopInstanceDetail}>
              <strong>{t('generalInformation')}:</strong>
              <div className={classes.templateStopInstanceDetail}>
                <strong>{t('stopType')}</strong>: {t(getTemplateStopTypeString(templateStopInstance.stopType))}</div>
              <div className={classes.templateStopInstanceDetail}>
                <strong>{t('shipper')}</strong>: {displayShipperAndShipperShortName(templateStopInstance)}</div>
              <div className={classes.templateStopInstanceDetail}>
                <strong>{t('carrier')}</strong>: {templateStopInstance.carrierName}</div>
              <div className={classes.templateStopInstanceDetail}>
                <strong>{t('microHub')}</strong>: {templateStopInstance.microHubName}</div>
              <div className={classes.templateStopInstanceDetail}><strong>{t('tourId')}</strong>: {templateStopInstance.tourId}</div>
              <div className={classes.templateStopInstanceDetail}>
                <strong>{t('deliveryDate')}</strong>: {DateService.displayDate(templateStopInstance.deliveryDate)}</div>
            </div>
            <br />
            <div className={classes.templateStopInstanceDetail}>
              <strong>{t('customerData')}:</strong>
              <div className={classes.templateStopInstanceDetail}>
                <strong>{t('name')}</strong>: {templateStopInstance.name}</div>
              <div className={classes.templateStopInstanceDetail}>
                <strong>{t('internalCustomerId')}</strong>: {templateStopInstance.internalCustomerId}</div>
              <div className={classes.templateStopInstanceDetail}>
                <strong>{t('customerId')}</strong>: {templateStopInstance.customerId}</div>
              <div className={classes.templateStopInstanceDetail}>
                <strong>{t('address')}</strong>: {displayAddress(templateStopInstance.address)}</div>
              <div className={classes.templateStopInstanceDetail}>
                <strong>{t('customerInfo')}:</strong>{templateStopInstance.customerInfo}</div>
              <div className={classes.templateStopInstanceDetail}>
                <strong>{t('deliveryWindow')}</strong>: {templateStopInstance.deliveryWindow ? `${DateService.displayTime(templateStopInstance?.deliveryWindow?.from)} - ${DateService.displayTime(templateStopInstance?.deliveryWindow?.to)}` : '-'}
              </div>
            </div>
            <br />
          </div>
          {includeRelatedTemplateStopInstances &&
            <div className={classes.wrapperChild}>
              <div className={classes.templateStopInstanceDetail}>
                {
                  templateStopInstance.stopType === TemplateStopType.Pickup ?
                    <strong>{t('relatedDeliveryStops')}:</strong> :
                    <strong>{t('relatedPickupStops')}:</strong>
                }
                {
                  relatedTemplateStopInstances.map((relatedTemplateStopInstance) =>
                    <Paper
                      className={classNames(classes.templateStopInstanceDetail, classes.relatedTemplateStopWrapper)}
                      key={relatedTemplateStopInstance.id}
                      onClick={() => selectTemplateStopInstance({ id: relatedTemplateStopInstance.id })}>
                      <div className={classes.templateStopInstanceDetail}>
                        <strong>{t('name')}</strong>: {relatedTemplateStopInstance?.name}</div>
                      <div className={classes.templateStopInstanceDetail}>
                        <strong>{t('address')}</strong>: {displayAddress(relatedTemplateStopInstance?.address)}</div>
                    </Paper>
                  )
                }
              </div>
            </div>
          }
        </div>
      </PortletContent>
    </Fragment>
  );
}

TemplateStopInstanceDetailShow.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  closeDetailView: PropTypes.func,
  edit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  includeRelatedTemplateStopInstances: PropTypes.bool.isRequired,
  relatedTemplateStopInstances: PropTypes.array.isRequired,
  selectTemplateStopInstance: PropTypes.func.isRequired,
  templateStopInstance: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TemplateStopInstanceDetailShow);
