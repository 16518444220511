import React from 'react'
import {FormControl, Input, InputLabel, MenuItem, Select, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InfoTooltip from '../../ELearning/InfoTooltip';
import {DayOfWeek, getDayOfWeekString} from '../../../services/enums/dayOfWeekHelper';

function DayOfWeekSelect(props) {

  const {classes, className, t, value, setValue, disabled, descriptionKey, error, required} = props;

  const rootClassName = classNames(classes.formControl, className);

  return (
    <InfoTooltip descriptionKey={descriptionKey}>
      <FormControl className={rootClassName}>
        <InputLabel
          id="dayOfWeek"
          required={required}
        >
          {t('dayOfWeek')}
        </InputLabel>
        <Select
          disabled={disabled}
          error={error}
          input={<Input id="dayOfWeek"/>}
          onChange={(event) => setValue(event.target.value)}
          value={value}
        >
          {!required &&
          <MenuItem
            key={-1}
            value={''}
          >
            {'-'}
          </MenuItem>
          }
          {Object.getOwnPropertyNames(DayOfWeek).map((day, index) => (
            <MenuItem
              key={index}
              value={DayOfWeek[day]}
            >
              {t(getDayOfWeekString(DayOfWeek[day]))}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </InfoTooltip>
  );
}

DayOfWeekSelect.defaultProps = {
  descriptionKey: '',
  required: false,
  error: false,
};

DayOfWeekSelect.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  descriptionKey: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default compose(withStyles(styles), withTranslation())(DayOfWeekSelect);
