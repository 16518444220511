import React, {useContext, useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import SideNavLayout from '../../../layouts/SideNavLayout';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {GeneralInfoIcon, SimulateTourBuildingComponent} from '../../../components';
import {displayModes} from '../../../services/enums/displayModes';
import {AuthService} from '../../../services/auth/authService';
import useCarriersWithHubs from '../../../hooks/useCarriersWithHubs';
import {DatePickerDefaultValueContext} from '../../../context';
import useMicroHubData from '../../../hooks/useMicroHubData';

function SimulateTourBuilding(props) {

  const {classes, t} = props;
  const {fromDateDefault, toDateDefault, updateDefaultDate} = useContext(DatePickerDefaultValueContext);

  const [filter, setFilter] = useState({
    carrierName: '',
    shipperName: AuthService.getUserOrganization(),
    microHubName: '',
    stopStatus: '',
    fromDate: fromDateDefault,
    toDate: toDateDefault,
    boxId: ''
  });
  useEffect(() => updateDefaultDate(filter.fromDate), [filter.fromDate, updateDefaultDate]);
  const {carrierOptions, carriersWithHubs} = useCarriersWithHubs();
  const {microHubData} = useMicroHubData();

  return (
    <SideNavLayout title={t('simulateTourBuilding')}>
      <div className={classes.root}>
        <div className={classes.helpWrapper}>
          <GeneralInfoIcon/>
        </div>
        <div style={{marginTop: '-35px'}}>
          <SimulateTourBuildingComponent
            carriers={carrierOptions}
            carriersWithMicroHubs={carriersWithHubs}
            displayMode={displayModes.shipper}
            filter={filter}
            microHubs={microHubData}
            setFilter={setFilter}
            shippers={[AuthService.getUserOrganization()]}
          />
        </div>
      </div>
    </SideNavLayout>
  );

}

SimulateTourBuilding.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(SimulateTourBuilding);
