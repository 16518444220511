export default theme => ({
  root: {},
  timeBar: {
    width: '100%',
    height: theme.spacing(11),
    position: 'relative',
  },
  timeSpan: {
    position: 'absolute',
    bottom: 0,
  },
  pointInTimeRound: {
    position: 'absolute',
    borderRadius: '50%',
    border: '2px solid white',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.white,
    fontSize: 12
  },
  pointInTime: {
    position: 'absolute',
    bottom: 0,
  }
});

