export default theme => ({
  root: {
    minWidth: '350px'
  },
  portletHeader: {
    height: '40px'
  },
  portletContent: {
    maxHeight: '90%',
    overflow: 'auto',
  },
  listItemText: {
    flex: '1 1 auto',
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'space-around'
  },
});
