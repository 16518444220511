import React, {useCallback, useEffect, useState} from 'react'
import {Table, TableBody, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import pcGaernerApiService from '../../../../../services/backend/pcGaernerApiService';
import {
  DropdownSelect, DropdownSelectSimple,
  LoadingWrapper,
  Pagination,
  Portlet,
  PortletContent,
  TableCellWithConfig,
  TableSettings,
  TextPlaceholderWrapper
} from '../../../../../components';
import Columns from './columns';
import useTableConfig from '../../../../../hooks/useTableConfig';
import DefaultConfig from './config';
import {getTableKey} from '../../../../../services/util/tableConfigHelper';
import {TableKind} from '../../../../../services/enums/TableKind';
import {displayModes} from '../../../../../services/enums/displayModes';
import useTablePagination from '../../../../../hooks/useTablePagination';

function TourImport(props) {

  const {classes, t, selectedShipper, setSelectedShipper, shipperOptions} = props;

  const [backendIsLoading, setBackendIsLoading] = useState(false);
  const [tourImporting, setTourImporting] = useState(null);
  const [tours, setTours] = useState([]);
  const [showErrorTours, setShowErrorTours] = useState('true');

  const {
    paginationProps,
    pagedObjects
  } = useTablePagination(showErrorTours === 'true' ? tours : tours.filter(t => !t.error), [50, 100, 500]);

  const {
    config,
    columnConfigs,
    updateConfig
  } = useTableConfig(
    DefaultConfig,
    displayModes.admin,
    getTableKey(TableKind.PcgTourImportTable, displayModes.admin),
  )

  const loadTours = useCallback(async () => {
    setBackendIsLoading(true);
    try {
      const result = await pcGaernerApiService.getPcgToursByShipperName(selectedShipper);
      setTours(result);
      setBackendIsLoading(false);
    } catch (e) {
      setBackendIsLoading(false);
      alert(`${t('errorLoadingPcgTours')} ${e}`);
    }
  }, [selectedShipper, t]);

  const importTour = useCallback(async (tourId, tourInstance, shipper) => {
    try {
      setTourImporting(tourInstance);
      await pcGaernerApiService.importPcgTourByShipperNameAndId(tourId, tourInstance, shipper);
      alert(t('pcgTourImportedSuccessfully'))
      setTourImporting(null);
    } catch (e) {
      setTourImporting(null);
      alert(`${t('errorImportingPcgTour')} ${e}`);
    }
  }, [t]);

  useEffect(() => {
    if (selectedShipper) {
      loadTours();
    }
  }, [selectedShipper, loadTours])

  useEffect(() => {
    setTours(prev => showErrorTours ? prev : prev.filter(t => !t.error))
  }, [showErrorTours])

  return (
    <div className={classes.root}>
      <DropdownSelectSimple
          error={!selectedShipper}
          name={t('shipper')}
          options={shipperOptions}
          required
          setValue={(value) => setSelectedShipper(value)}
          sort
          value={selectedShipper}
      />
      <DropdownSelect
        disabled={!tours?.length}
        value={showErrorTours}
        required
        options={[
          {value: 'true', name: t('yes')},
          {value: 'false', name: t('no')}
        ]}
        setValue={setShowErrorTours}
        name={t('showErrorTours')}
        descriptionKey={'1'}
      />
      <LoadingWrapper loading={backendIsLoading}>
        <TextPlaceholderWrapper
          active={!tours || !selectedShipper}
          text={t('noPcgToursForShipperFound')}
        >
          <Portlet className={classes.table}>
            <PortletContent noPadding>
              <TableSettings
                updateConfig={updateConfig}
                tableConfig={config}
                columns={Columns}
              />
              <Table size="medium">
                <TableHead>
                  <TableRow>
                    {columnConfigs.map(columnConfig => (
                      <TableCellWithConfig
                        key={columnConfig.order}
                        config={columnConfig}
                      >
                        {Columns[columnConfig.columnName].header({t})}
                      </TableCellWithConfig>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pagedObjects.map((tour, index) => (
                    <TableRow
                      hover
                      key={index}
                    >
                      {columnConfigs.map(columnConfig => (
                        <TableCellWithConfig
                          key={columnConfig.order}
                          config={columnConfig}
                          className={classes.tableCell}
                        >
                          {Columns[columnConfig.columnName].body({
                            tour, t, classes, importTour, selectedShipper, tourImporting
                          })}
                        </TableCellWithConfig>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Pagination paginationProps={paginationProps}/>
            </PortletContent>
          </Portlet>
        </TextPlaceholderWrapper>
      </LoadingWrapper>
    </div>
  );

}

TourImport.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedShipper: PropTypes.string.isRequired,
  setSelectedShipper: PropTypes.func.isRequired,
  shipperOptions: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(TourImport);
