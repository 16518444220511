import React, {Fragment, useState} from 'react'
import {Button, Container, Typography, useMediaQuery, useTheme, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {NavLink} from 'react-router-dom';
import trackAndTraceService from '../../../../services/backend/trackAndTraceService';
import {LoadingIndicator} from '../../../../components';


function UnsubscribeMain(props) {

  const {classes, className, trackingData, loading, error} = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const rootClassName = classNames(classes.root, className);
  const [successfullyUnsubscribed, setSuccessfullyUnsubscribed] = useState(false);
  const [loadingInternal, setLoadingInternal] = useState(false);

  const deactivateEmailNotifications = async (internalCustomerId) => {
    setLoadingInternal(true);
    try {
      await trackAndTraceService.deactivateEmailNotificationsByInternalCustomerId(internalCustomerId);
      setSuccessfullyUnsubscribed(true);
      setLoadingInternal(false);
    } catch (e) {
      alert('Beim Abbestellen der Mitteilungen ist ein Fehler aufgetreten.')
      setLoadingInternal(false);
    }
  }

  if (loading || loadingInternal) {
    return (
      <div className={rootClassName}>
        <Container
          disableGutters={matches}
          maxWidth="md"
        >
          <LoadingIndicator/>
        </Container>
      </div>
    );
  }

  if (trackingData && !error) {
    return (
      <div className={rootClassName}>
        <Container
          disableGutters={matches}
          maxWidth="md"
        >
          {successfullyUnsubscribed ?
            <Fragment>
              <Typography
                className={classes.spacing}
                variant={'body1'}
              >Geschafft! Die Benachrichtigungen werden ab der nächsten Lieferung gestoppt.</Typography>
              <Typography
                className={classes.spacing}
                color={'textSecondary'}
                variant={'subtitle2'}
              >
                Bei Lust und Laune nach Wiederaufnahme freuen wir uns auf eine Mail an&nbsp;
                <a
                  className={classes.a}
                  href={'mailto:info@zukunftsangelegenheiten.de'}
                >info@zukunftsangelegenheiten.de
                </a>
              </Typography>
            </Fragment>
            :
            <Fragment>
              <Typography
                className={classes.spacing}
                variant={'body1'}
              >Sollen alle Mitteilungen zu Änderungen im Lieferstatus deaktiviert werden?</Typography>
              <Typography
                className={classes.spacing}
                color={'textSecondary'}
                variant={'subtitle2'}
              >Bei Deaktivierung können Sendungen nicht mehr verfolgt werden.</Typography>
              <div className={classNames(classes.buttonContainer, classes.spacing)}>
                <Button
                  color={'primary'}
                  component={NavLink}
                  to={'.'}
                >
                  Abbrechen
                </Button>
                <Button
                  className={classes.deactivateNotificationButton}
                  color={'primary'}
                  onClick={() => deactivateEmailNotifications(trackingData?.trackingStop?.customer?.internalCustomerId)}
                  variant={'contained'}
                >
                  Mitteilungen deaktivieren
                </Button>
              </div>
            </Fragment>
          }
        </Container>
      </div>
    );
  }

  if (error) {
    return (
      <div className={rootClassName}>
        <Container
          disableGutters={matches}
          maxWidth="md"
        >
          <div className={classes.center}>
            <div>
              <Typography variant={'h3'}>Fehler</Typography>
              <Typography variant={'body1'}>Beim Laden der Tracking-Informationen ist ein Fehler aufgetreten.</Typography>
              <Typography variant={'body1'}>Bitte stellen Sie sicher, dass der Link korrekt ist oder versuchen Sie es später erneut.</Typography>
            </div>
          </div>
        </Container>
      </div>
    );
  }

  //default
  return (<div/>)


}

UnsubscribeMain.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  error: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  trackingData: PropTypes.object,
};


export default withStyles(styles)(UnsubscribeMain);
