import React from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {roundCapacityValues} from '../../../../../../services/util/helperFuncs';

const DisplayUtilization = (props) => {

  const {capacity, vehicleCapacity, classes} = props;

  if (!vehicleCapacity) {
    return (
      <span>-</span>
    )
  }

  const percentVolume = (capacity.volume / vehicleCapacity.volume) * 100;
  const percentWeight = (capacity.weight / vehicleCapacity.weight) * 100;
  const percent = percentVolume > percentWeight ? percentVolume : percentWeight;
  const good = percent && percent >= 80 && percent <= 100;
  const medium = percent && percent > 50 && percent < 80;
  const bad = percent && !medium && !good;

  return (
    <span
      className={classNames({[classes.good]: good, [classes.medium]: medium, [classes.bad]: bad})}
    >
      {roundCapacityValues(percent)}%
    </span>
  )
};

DisplayUtilization.propTypes = {
  capacity: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  vehicleCapacity: PropTypes.object,
};

export default withStyles(styles)(DisplayUtilization);
