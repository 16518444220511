import React from 'react'
import {Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {TrackingStepType} from '../../../../../../services/enums/TrackingStepType';

function TrackingBar(props) {

  const {classes, className, lastTrackingStep, lastNonErrorTrackingStep, stopsToGo} = props;

  const rootClassName = classNames(classes.root, className);

  const delivered = lastNonErrorTrackingStep.trackingStepType === TrackingStepType.Delivered;
  const canNotDeliver = delivered && !lastNonErrorTrackingStep.deliverySuccessful;
  const almostThere = delivered || (stopsToGo !== null && stopsToGo <= 3);
  const inDelivery = almostThere || lastNonErrorTrackingStep.trackingStepType === TrackingStepType.TourStarted || lastNonErrorTrackingStep.trackingStepType === TrackingStepType.PreviousStopsDelivered;
  const planed = inDelivery || true;
  const problem = lastTrackingStep.trackingStepType === TrackingStepType.Problem;

  const stages = [
    {name: 'geplant', active: planed, currentStep: planed && !inDelivery, problem: problem},
    {name: 'unterwegs', active: inDelivery, currentStep: inDelivery && !almostThere, problem: problem},
    {name: 'gleich da', active: almostThere, currentStep: almostThere && !delivered, problem: problem},
    {name: 'zugestellt', active: delivered, currentStep: delivered, problem: canNotDeliver || problem}
  ];

  return (
    <div className={rootClassName}>
      {stages.map((stage, index) => (
        <div
          className={classNames(classes.trackingBar, {[classes.marginLeft]: index > 0})}
          key={index}
        >
          <Typography
            color={stage.currentStep ? 'textPrimary' : 'textSecondary'}
            style={{textTransform: 'uppercase'}}
            variant={'subtitle2'}
          >
            {stage.name}
          </Typography>
          <div
            className={classNames(classes.indicator, {
              [classes.active]: stage.active,
              [classes.problem]: stage.problem && stage.currentStep,
            })}
          />
        </div>
      ))}
    </div>
  );
}

TrackingBar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  lastNonErrorTrackingStep: PropTypes.object.isRequired,
  lastTrackingStep: PropTypes.object.isRequired,
  stopsToGo: PropTypes.number,
};


export default withStyles(styles)(TrackingBar);
