import React, {Fragment, useState} from 'react'
import {Box, Button, Collapse, IconButton, TableCell, TableRow, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon} from '@material-ui/icons';

function DonationButtonDialogTableRow(props) {

  const {classes, t, driver, handleApprove} = props;

  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          {driver.firstname}
        </TableCell>
        <TableCell>
          {driver.lastname}
        </TableCell>
        <TableCell>
          {driver.displayName}
        </TableCell>
        <TableCell>
          {driver.phoneNumber}
        </TableCell>
        <TableCell>
          {driver.email}
        </TableCell>
        <TableCell>
          {driver.carrierName}
        </TableCell>
        <TableCell>
          <div className={classes.flexContainer}>
            <Button
              className={classes.button}
              color="primary"
              onClick={() => handleApprove(driver.id)}
              variant="contained"
            >
              {t('donationButtonApprove')}
            </Button>
            <IconButton
              className={classes.iconButton}
              onClick={() => setOpen(!open)}
              size="small"
            >
              {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
      <TableRow className={classes.noPadding}>
        <TableCell
          className={classes.noPadding}
          colSpan={7}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <Box margin={1}>
              <code className={classes.donationButton}>
                {driver.donationButton}
              </code>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}


DonationButtonDialogTableRow.propTypes = {
  classes: PropTypes.object.isRequired,
  driver: PropTypes.object.isRequired,
  handleApprove: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(DonationButtonDialogTableRow);
