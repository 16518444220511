import { withStyles } from '@material-ui/core';
import styles from './styles'
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import {
  LoadingIndicator,
  Portlet,
  TemplateStopInstanceDetail,
  TemplateStopInstanceTable,
  TemplateStopMap
} from 'components';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import useLoadTemplateStopInstanceByIdCache from 'hooks/useLoadTemplateStopInstanceByIdCache';
import useDebounce from 'hooks/useDebounce';
import { SearchTemplateStopInstanceService } from 'services/searchServices/searchTemplateStopInstanceService';
import { displayModes } from 'services/enums/displayModes';
import { TemplateStopService } from '../../../services/backend/templateStopService';
import { TourFilter } from 'services/enums/TourFilter';


function TemplateStopInstanceOverview(props) {
  const { classes, t, searchInput, filter, displayMode } = props;

  const [templateStopInstances, setTemplateStopInstances] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [tourFilterResult, setTourFilterResult] = useState([]);

  const {
    selectedTemplateStopInstance,
    selectedRelatedTemplateStopInstances,
    loadingSelectedTemplateStopInstance,
    resetLoadTemplateStopInstanceByIdCache,
    getTemplateStopInstanceById,
    resetSelectedTemplateStopInstance,
  } = useLoadTemplateStopInstanceByIdCache();

  const loadTemplateStopInstances = useCallback((selectedTemplateStopInstance) => {
    resetLoadTemplateStopInstanceByIdCache();
    TemplateStopService.getTemplateStopInstancesWithFilter(filter)
      .then(response => {
        setTemplateStopInstances(response);
        const index = response.findIndex(templateStopInstance => selectedTemplateStopInstance
          && templateStopInstance.id === selectedTemplateStopInstance.id);

        if (index >= 0) {
          getTemplateStopInstanceById(selectedTemplateStopInstance.id)
        } else {
          resetSelectedTemplateStopInstance();
        }
      }, () => {
        alert(t('errorLoadingTemplateStopInstances'));
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, t]);

  useEffect(() => {
    loadTemplateStopInstances()
  }, [loadTemplateStopInstances]);

  // use Debounce to prevent to many search operations, delay is in ms
  const debouncedSearchInput = useDebounce(searchInput, 250);

  // do search if debounceSearchInput changes
  useEffect(
    () => {
      const result = SearchTemplateStopInstanceService.searchTemplateStopInstances(templateStopInstances, debouncedSearchInput);
      setSearchResult(result);
    },
    [debouncedSearchInput, templateStopInstances]
  );

  useEffect(
    () => {
      let tourFilterResult;
      switch (filter.tourFilter) {
        case TourFilter.NoTour:
          tourFilterResult = searchResult.filter(templateStopInstance => templateStopInstance.tourId === '' || templateStopInstance.tourId === null);
          setTourFilterResult(tourFilterResult);
          break;
        case TourFilter.HasTour:
          tourFilterResult = searchResult.filter(templateStopInstance => templateStopInstance.tourId);
          setTourFilterResult(tourFilterResult);
          break;
        default:
          tourFilterResult = searchResult;
          setTourFilterResult(searchResult);
      }
    },
    [searchResult, filter.tourFilter, setTourFilterResult]
  )

  const showDetails = (templateStopInstance) => {
    getTemplateStopInstanceById(templateStopInstance.id);
  };

  const hideDetails = () => {
    resetSelectedTemplateStopInstance();
  };

  const deleteTemplateStopInstance = (templateStopInstanceId) => {
    TemplateStopService.deleteTemplateStopInstanceById(templateStopInstanceId).then(() => {
      const newTemplateStopInstances = JSON.parse(JSON.stringify(templateStopInstances));
      const index = newTemplateStopInstances.findIndex(s => s.id === templateStopInstanceId);
      if (index > -1) {
        newTemplateStopInstances.splice(index, 1);
      }
      setTemplateStopInstances(newTemplateStopInstances);
      if (selectedTemplateStopInstance?.id === templateStopInstanceId) resetSelectedTemplateStopInstance();
    }, () => {
      alert(t('errorDeletingTemplateStopInstance'));
      loadTemplateStopInstances(selectedTemplateStopInstance);
    });
  };

  return (
    <div>
      <div className={classes.root}>
        <br />
        <Fragment>
          <TemplateStopInstanceTable
            className={classes.content}
            deleteTemplateStopInstance={deleteTemplateStopInstance}
            selectedTemplateStopInstance={selectedTemplateStopInstance}
            showDetails={showDetails}
            templateStopInstances={tourFilterResult}
            tableDisplayMode={displayMode}
          />
          {loadingSelectedTemplateStopInstance &&
            <Portlet className={classes.content}>
              <LoadingIndicator />
            </Portlet>
          }
          {selectedTemplateStopInstance &&
            <TemplateStopInstanceDetail
              className={classes.content}
              closeDetailView={hideDetails}
              readOnly
              relatedTemplateStopInstances={selectedRelatedTemplateStopInstances}
              selectTemplateStopInstance={showDetails}
              editDisplayMode={displayMode}
              templateStopInstance={selectedTemplateStopInstance}
            />
          }
          <TemplateStopMap
            className={classes.map}
            noControls={false}
            stops={tourFilterResult}
            isTemplateStopInstance={true}
          />
        </Fragment>
      </div>
    </div>
  );
}

TemplateStopInstanceOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  filter: PropTypes.object,
  searchInput: PropTypes.string,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
};

export default compose(withStyles(styles), withTranslation())(TemplateStopInstanceOverview);