import React from 'react'
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {DropdownSelectSimple} from '../../index';


function CarrierAndMicroHubDropdownSelect(props) {

  const {
    className,
    t,
    carrierValue,
    microHubValue,
    setCarrierValue,
    setMicroHubValue,
    carriersWithMicroHubs,
    microHubOptions,
    disabled,
    carrierDescriptionKey,
    microHubDescriptionKey,
    errorCarrier,
    errorMicroHub,
    carrierRequired,
    microHubRequired
  } = props;

  const carrierOptions = [];
  const microHubOptionsCalculated = microHubOptions && microHubOptions.length > 0 ? microHubOptions : [];

  if (carriersWithMicroHubs) {
    Object.keys(carriersWithMicroHubs).forEach((carrier) => {
      carrierOptions.push(carrier);
      if (!carrierValue || carrier === carrierValue) {
        carriersWithMicroHubs[carrier].forEach((hub) => {
          if (!microHubOptionsCalculated.includes(hub)) {
            microHubOptionsCalculated.push(hub);
          }
        })
      }
    });
  }


  return (
    <div className={className}>
      <DropdownSelectSimple
        descriptionKey={carrierDescriptionKey}
        disabled={disabled}
        error={errorCarrier}
        hideOnEmptyOptions
        name={t('carrier')}
        options={carrierOptions}
        required={carrierRequired}
        setValue={(value) => setCarrierValue(value)}
        sort
        value={carrierValue}
      />
      <DropdownSelectSimple
        descriptionKey={microHubDescriptionKey}
        disabled={disabled}
        error={errorMicroHub}
        hideOnEmptyOptions
        name={t('microHub')}
        options={microHubOptionsCalculated}
        required={microHubRequired}
        setValue={setMicroHubValue}
        sort
        value={microHubValue}
      />
    </div>
  );
}


CarrierAndMicroHubDropdownSelect.propTypes = {
  carrierDescriptionKey: PropTypes.string,
  carrierRequired: PropTypes.bool,
  carrierValue: PropTypes.string.isRequired,
  carriersWithMicroHubs: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorCarrier: PropTypes.bool,
  errorMicroHub: PropTypes.bool,
  microHubDescriptionKey: PropTypes.string,
  microHubOptions: PropTypes.array,
  microHubRequired: PropTypes.bool,
  microHubValue: PropTypes.string.isRequired,
  setCarrierValue: PropTypes.func.isRequired,
  setMicroHubValue: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(CarrierAndMicroHubDropdownSelect);
