export default theme => ({
  root: {
    padding: theme.spacing(0),
  },
  gridItem: {
    padding: theme.spacing(0),
    minWidth: '350px',
    minHeight: 'calc(-195px + 100vh)',
  },
  tourTable: {
    marginBottom: theme.spacing(2),
  },
  button: {
    float: 'right',
    marginLeft: theme.spacing(1)
  },
  redButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.danger.main,
    '&:hover': {
      backgroundColor: theme.palette.danger.dark,
      color: theme.palette.common.white,
    }
  },
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    border: `1px solid ${theme.palette.border}`
  },
  displaySelectedTourCheckboxAndLegend: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tourBuildingControl: {
    marginBottom: theme.spacing(1),
  },
  controlsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
});
