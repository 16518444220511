import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {CheckCircle, Error, PlayCircleFilled} from '@material-ui/icons';
import styles from './styles';


function StepperIcon(props) {

  const {completed, classes, error} = props;

  if (error && completed) {
    return (
      <Error className={classes.errorCompleted}/>
    )
  }

  if (completed) {
    return (
      <CheckCircle className={classes.completed}/>
    )
  }

  return (
    <PlayCircleFilled className={error ? classes.error : classes.active}/>
  )
}

StepperIcon.propTypes = {
  active: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  completed: PropTypes.bool,
  error: PropTypes.bool.isRequired
};

export default withStyles(styles)(StepperIcon);
