import {compose} from 'recompose';
import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import L from 'leaflet';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import {useLeaflet} from 'react-leaflet';

L.PolylineClusterable = L.Polyline.extend({
  _originalInitialize: L.Polyline.prototype.initialize,

  initialize: function (latlngs, options) {
    this._originalInitialize(latlngs, options);
    this._latlng = this.getBounds().getCenter();
  },

  getLatLng: function () {
    return this._latlng;
  },

  setLatLng: function () {
  }
});

const TemplateStopMarkerCluster = (props) => {

  const {stops, polylines, t, getClusterIcon, getStopMarkerAndAddToMcg, useCluster, clusterIconColor} = props;

  const mcg = L.markerClusterGroup({
    iconCreateFunction: function () {
      return getClusterIcon(clusterIconColor);
    },
    maxClusterRadius: 50,
    showCoverageOnHover: false,
    disableClusteringAtZoom: useCluster ? 16 : 1,
    spiderfyOnMaxZoom: false
  });


  const {map} = useLeaflet();

  useEffect(() => {
    mcg.clearLayers();
    polylines.forEach(pl => {
      new L.PolylineClusterable(pl.positions, {color: pl.color})
        .addTo(mcg);
    });
    stops.forEach(stop => getStopMarkerAndAddToMcg(stop, mcg, t));
    map.addLayer(mcg);
    return function cleanup() {
      map.removeLayer(mcg);
    }
  }, [stops, polylines, t, mcg, map, getStopMarkerAndAddToMcg]);

  return null;
};

TemplateStopMarkerCluster.propTypes = {
  clusterIconColor: PropTypes.string,
  getClusterIcon: PropTypes.func.isRequired,
  getStopMarkerAndAddToMcg: PropTypes.func.isRequired,
  stops: PropTypes.array.isRequired,
  polylines: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  useCluster: PropTypes.bool.isRequired
};

export default compose(withTranslation())(TemplateStopMarkerCluster);
