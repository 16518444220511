export default theme => ({
  root: {},
  button: {
    marginLeft: theme.spacing(1)
  },
  wrapper: {
    display: 'flex',
    margin: theme.spacing(2)
  }
});
