import React from 'react'
import {Container, Typography, useMediaQuery, useTheme, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {CurrentStatus, CustomerInfo, TrackingBar, TrackingDetails, TrackingMap, VirtualTip} from './components';
import {LoadingIndicator} from '../../../../components';

function Main(props) {

  const {classes, className, trackingData, loading, error, onRefresh} = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const rootClassName = classNames(classes.root, className);

  if (loading) {
    return (
      <div className={rootClassName}>
        <Container
          disableGutters={matches}
          maxWidth="md"
        >
          <LoadingIndicator/>
        </Container>
      </div>
    );
  }

  if (trackingData && !error) {
    return (
      <div className={rootClassName}>
        <Container
          disableGutters={matches}
          maxWidth="md"
        >
          <CurrentStatus
            className={classes.component}
            lastTrackingStep={trackingData.lastTrackingStep}
            logo={trackingData.shipperInfos?.logoName}
            marketingImage={trackingData.shipperInfos?.marketingImageName}
          />
          <TrackingBar
            className={classes.component}
            lastNonErrorTrackingStep={trackingData.lastNonErrorTrackingStep}
            lastTrackingStep={trackingData.lastTrackingStep}
            stopsToGo={trackingData.stopsToGo}
          />
          <TrackingDetails
            className={classes.component}
            problemMessage={trackingData.shipperInfos?.problemMessage}
            trackingSteps={trackingData.trackingStop.trackingSteps}
          />
          <VirtualTip
            className={classes.component}
            donationButton={trackingData.driverDonateButton}
            driverHasNoDonationButton={trackingData.driverHasNoDonationButton}
            noDriverForTour={trackingData.noDriverForTour}
          />
          {trackingData.tourCurrentlyInDelivery &&
            <TrackingMap
            className={classes.component}
            driverPosition={{latitude: trackingData.driverPositionLatitude, longitude: trackingData.driverPositionLongitude}}
            onRefresh={onRefresh}
            stopPosition={{latitude: trackingData.trackingStop.address.latitude, longitude: trackingData.trackingStop.address.longitude}}
            stopsToGo={trackingData.stopsToGo}
            />
          }
          <CustomerInfo
            className={classes.component}
            logo={trackingData.shipperInfos?.logoName}
            shipperName={trackingData.trackingStop.shipperName}
            stopAddress={trackingData.trackingStop.address}
            stopCustomer={trackingData.trackingStop.customer}
          />
        </Container>
      </div>
    );
  }

  if (error) {
    return (
      <div className={rootClassName}>
        <Container
          disableGutters={matches}
          maxWidth="md"
        >
          <div className={classes.center}>
            <div>
              <Typography variant={'h3'}>Fehler</Typography>
              <Typography variant={'body1'}>Beim Laden der Tracking-Informationen ist ein Fehler aufgetreten.</Typography>
              <Typography variant={'body1'}>Bitte stellen Sie sicher, dass der Link korrekt ist oder versuchen Sie es später erneut.</Typography>
            </div>
          </div>
        </Container>
      </div>
    );
  }

  //default
  return (<div/>)


}

Main.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  error: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onRefresh: PropTypes.func.isRequired,
  trackingData: PropTypes.object,
};


export default withStyles(styles)(Main);
