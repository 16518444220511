export default theme => ({
  root: {
    minHeight: '250px'
  },
  center: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  spacing: {
    marginBottom: theme.spacing(2)
  },
  a: {
    color: theme.palette.text.secondary
  },
  deactivateNotificationButton: {
    marginLeft: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    }
  }
});

