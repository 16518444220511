export default theme => ({
  root: {},
  center: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center',
  },
  component: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
});

