export default theme => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  buttonRight: {
    marginTop: theme.spacing(1),
  },
  helpWrapper: {
    float: 'right',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  filterWrapper: {
    marginBottom: theme.spacing(2),
  },
});
