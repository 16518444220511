export default theme => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: '200px',
    width: '200px',
  },
  rowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    margin: theme.spacing(0)
  },
  searchWrapper: {
    alignItems: 'flex-end'
  },
  stopDetail: {
    margin: theme.spacing(1)
  },
  searchInputPlaceholder: {
    '&::placeholder': {
      fontSize: '14px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    }
  }
});
