import React, {Fragment} from 'react'
import {Button, Grid, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {InfoIcon, Portlet, PortletContent} from '../../../../index';
import Typography from '@material-ui/core/Typography';
import {Edit as EditIcon} from '@material-ui/icons';
import {Address, Person} from '../../../Shared';


function ViewCarrier(props) {
  const {classes, t, carrier, onEditClick, readonly} = props;

  return (
    <Fragment>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          xs={12}
        >
          {!readonly &&
          <Button
            className={classes.button}
            color="primary"
            onClick={onEditClick}
            title={t('editCarrier')}
            variant="contained"
          >
            <EditIcon/>
          </Button>
          }
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Portlet className={classes.content}>
            <PortletContent>
              <Typography variant="h3">
                {t('info')}
              </Typography>
              <hr/>
              <Typography variant="h4">
                {t('name')}
              </Typography>
              <Typography variant="body1">
                {carrier.name}
              </Typography>
              <div className={classes.iconWrapper}>
                <Typography variant="h4">
                  {t('useAlgorithmForTourPlanning')}
                </Typography>
                <InfoIcon
                  className={classes.iconMargin}
                  descriptionKey={'35'}
                />
              </div>
              <Typography variant="body1">
                {carrier.useAlgorithmForTourPlanning ? t('yes') : t('no')}
              </Typography>
            </PortletContent>
          </Portlet>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Address
            address={carrier.address}
            className={classes.content}
            title={t('address')}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Address
            address={carrier.billingAddress}
            className={classes.content}
            title={t('billingAddress')}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Person
            className={classes.content}
            person={carrier.contact}
            title={t('contact')}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Person
            className={classes.content}
            person={carrier.disponent}
            title={t('disponent')}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}

ViewCarrier.propTypes = {
  carrier: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(ViewCarrier);
