import React from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {DateService} from "../../../services/util/DateService";
import {Line} from "react-chartjs-2";

function SensorDataGraph(props) {

    const {classes, className, t, sensorData} = props;
    const rootClassName = classNames(classes.root, className);

    const chartData = {
        labels: sensorData && sensorData.map(value => DateService.displayDateTime(value.timeStamp)),
        datasets: [
            {
                id: "temperature",
                label: `${t('temperature')}`,
                data: sensorData && sensorData.map(value => value.temperature),
                borderColor: 'rgb(255, 100, 100)',
                fill: false,
                yAxisId: "temperature",
            },
            {
                id: "humidity",
                label: `${t('humidity')}`,
                data: sensorData && sensorData.map(value => value.humidity),
                borderColor: 'rgb(100, 100, 255)',
                fill: false,
                yAxisId: "humidity",
            }],
    };

    const chartOptions = {
        tooltips: {
            mode: 'index',
            intersect: false,
            position: 'average',
            callbacks: {
                label: function(tooltipItem, data) {
                    const units = ["°C", "%"]
                    var label = data.datasets[tooltipItem.datasetIndex].label || '';
                    label += " " + tooltipItem.yLabel + units[tooltipItem.datasetIndex];
                    return label;
                }
            }
        },
        scales: {
            yAxes: [
                {
                    id: "temperature",
                    type: 'linear',
                    position: 'left',
                    ticks: {
                        beginAtZero: true,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: `${t('temperature')} (°C) / ${t('humidity')} (%)`,
                    },
                },
            ],
        },
    };

    return (
        <div className={rootClassName}>
            <Line
                data={chartData}
                options={chartOptions}
                redraw={true}
            />
        </div>
    );
}

SensorDataGraph.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    sensorData: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(SensorDataGraph);