import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import React from 'react';

function DeclinedStopsTableSmallDetails(props){
    
  const {classes, t, declinedStop} = props;

  return (
    <div className={classes.wrapper}>
      <div className={classes.wrapperChild}>
        <div className={classes.stopDetail}><strong>{t('previewStopIdentifier')}</strong>: {declinedStop.previewStopIdentifier}</div>
        <div className={classes.stopDetail}><strong>{t('declinedStopsReason')}</strong>: {declinedStop.declinedReason}</div>
      </div>
    </div>
  );
  
}

DeclinedStopsTableSmallDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  declinedStop: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(DeclinedStopsTableSmallDetails);