export default theme => ({
  root: {
    minWidth: '350px'
  },
  portletHeader: {
    height: '40px'
  },
  portletContentStyle: {
    maxHeight: '90%',
    overflow: 'auto'
  },
  contentContainer: {
    padding: theme.spacing(1),
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  item: {
    marginBottom: theme.spacing(1),
  },
  indicatorWrapper: {
    width: '85%',
    marginRight: theme.spacing(1)
  },
  indicator: {
    borderRadius: 4,
    height: '17px'
  },
  textIcon: {
    verticalAlign: 'middle',
  }
});
