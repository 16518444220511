import React, {Fragment} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {StopStatus} from '../../../services/enums/StopStatus';
import {Cancel as CancelIcon, DeleteSweep as DeleteAssignment, Info as InformationIcon, WarningRounded as WarnIcon} from '@material-ui/icons';
import {onCloseWithBackdropCurry} from '../../../services/util/helperFuncs';

function DeleteCarrierFromStopDialog(props) {

  const {classes, t, dialogState, handleCancel, handleClose} = props;


  const handleOk = () => {
    handleClose(dialogState.stop.tourStopId);
  };

  const handleNotOk = () => {
    handleCancel();
  };

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      maxWidth={'md'}
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={dialogState.open}
    >
      <DialogTitle>{t('stopRemoveCarrier')}</DialogTitle>
      <DialogContent>
        {dialogState.stop &&
        <Fragment>
          <InformationIcon/>
          <Typography>
            {t('deleteCarrierFromStop1')}
          </Typography>
          <Typography>
            {t('deleteCarrierFromStop2')}
          </Typography>
          <div className={classes.container}>
            {dialogState.stop.stopStatus === StopStatus.Planed &&
            <Typography>
              <WarnIcon className={classes.warningColor}/>&nbsp;{t('changeAssignmentStopPlaned')}
            </Typography>
            }
            {dialogState.stop.stopStatus === StopStatus.InDelivery &&
            <Typography>
              <WarnIcon className={classes.warningColor}/>&nbsp;{t('changeAssignmentStopInDelivery')}
            </Typography>
            }
            {dialogState.stop.stopStatus === StopStatus.Delivered &&
            <Typography>
              <WarnIcon className={classes.warningColor}/>&nbsp;{t('changeAssignmentStopDelivered')}
            </Typography>
            }
            {dialogState.stop.stopStatus === StopStatus.CanNotDeliver &&
            <Typography>
              <WarnIcon className={classes.warningColor}/>&nbsp;{t('changeAssignmentStopCanNotDeliver')}
            </Typography>
            }
          </div>
        </Fragment>
        }
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={handleNotOk}
        >
          <CancelIcon/>&nbsp;{t('dialogCancel')}
        </Button>
        <Button
          className={classes.warningColor}
          color="primary"
          disabled={!dialogState.stop}
          onClick={handleOk}
        >
          <DeleteAssignment/>&nbsp;{t('stopRemoveCarrier')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteCarrierFromStopDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogState: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(DeleteCarrierFromStopDialog);
