export default theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(3),
    border: `1px solid ${theme.palette.border}`,
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'auto',
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    width: 'auto',
  },
  deleteButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.danger.main,
    '&:hover': {
      backgroundColor: theme.palette.danger.dark,
      color: theme.palette.common.white,
    }
  },
  deleteAssignmentButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
      color: theme.palette.common.white,
    }
  }
});
