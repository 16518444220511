export default theme => ({
  root: {
    width: 35, height: 35,
    padding: 0,
    margin: 0
  },
  icon: {
    width: 35, height: 35,
  },
});
