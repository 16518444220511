export default theme => ({
  dialog: {},
  dialogContent: {
    margin: 0,
    padding: theme.spacing(2),
    paddingTop: 0,
    overflow: 'auto',
  },
  stopHistoryTable: {
    maxWidth: '100%',
  },
  tableCell: {
    whiteSpace: 'nowrap'
  },
  alignLeft: {
    align: 'left',
  },
});
