import React, {Fragment, useState} from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  withStyles
} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {getCustomerFriendlyTrackingStepTypeName, TrackingStepType} from '../../../../../../services/enums/TrackingStepType';
import {format} from 'date-fns';
import CustomStepperIcon from './CustomStepperIcon';
import {ExpandMore as ExpandMoreIcon} from '@material-ui/icons';

function TrackingDetails(props) {

  const {classes, className, trackingSteps, problemMessage} = props;

  const [open, setOpen] = useState(false);

  const rootClassName = classNames(classes.root, className);

  return (
    <Accordion
      classes={{
        root: classes.MuiAccordionroot
      }}
      className={rootClassName}
      elevation={0}
      expanded={open}
      onChange={() => setOpen(!open)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
        <Typography variant={'body1'}>
          Detaillierter Sendungsverlauf
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stepper
          className={classes.stepper}
          orientation="vertical"
        >
          {trackingSteps.sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp)).map((step, index) => (
            <Step
              active
              completed={index !== 0}
              key={step.id}
              test={index}
            >
              <StepLabel
                classes={{error: classes.noErrorClass}}
                error={
                  step.trackingStepType === TrackingStepType.Problem
                  || (step.trackingStepType === TrackingStepType.Delivered && !step.deliverySuccessful)
                }
                StepIconComponent={CustomStepperIcon}
              >
                <Typography
                  style={{textTransform: 'uppercase'}}
                  variant={'subtitle1'}
                >
                  {getCustomerFriendlyTrackingStepTypeName(step.trackingStepType)}
                </Typography>
              </StepLabel>
              <StepContent>
                {step.trackingStepType === TrackingStepType.Problem ?
                  <Typography
                    color={'textSecondary'}
                    variant={'subtitle2'}
                  >
                    {problemMessage}
                  </Typography>
                  :
                  <Fragment>
                    <Typography
                      color={'textSecondary'}
                      variant={'subtitle2'}
                    >
                      Vorraussichtliches
                      Lieferzeitfenster: {format(new Date(step.deliveryWindow.from), 'HH:mm')} - {format(new Date(step.deliveryWindow.to), 'HH:mm')} Uhr
                    </Typography>
                    {
                      step.trackingStepType === TrackingStepType.Delivered &&
                      <Typography
                        color={'textSecondary'}
                        variant={'subtitle2'}
                      >
                        {step.deliverySuccessful ? 'Zustellung erfolgreich' : 'Zustellung nicht erfolgreich'}
                      </Typography>
                    }
                  </Fragment>
                }

              </StepContent>
            </Step>
          ))}
        </Stepper>
      </AccordionDetails>
    </Accordion>
  );
}

TrackingDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  problemMessage: PropTypes.string,
  trackingSteps: PropTypes.array.isRequired
};


export default withStyles(styles)(TrackingDetails);
