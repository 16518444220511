export class DndHelperService {
  static getUniqueHubId(microHubName) {
    return JSON.stringify({
      type: DndHelperElementTypes.Hub,
      microHubName: microHubName,
    });
  }

  static getUniqueTourId(uniqueHubId, tourId) {
    const hubInfos = JSON.parse(uniqueHubId);
    hubInfos.type = DndHelperElementTypes.Tour;
    hubInfos.tourId = tourId;
    return JSON.stringify(hubInfos);
  }

  static getUniqueStopId(uniqueTourId, stopId) {
    const tourInfos = JSON.parse(uniqueTourId);
    tourInfos.type = DndHelperElementTypes.Stop;
    tourInfos.stopId = stopId;
    return JSON.stringify(tourInfos);
  }

  static getUniqueBasketId(basketName) {
    return JSON.stringify({
      type: DndHelperElementTypes.Basket,
      microHubName: basketName
    });
  }

  static parseUniqueId(uniqueId) {
    return JSON.parse(uniqueId);
  }

  static reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }
}

export const DndHelperElementTypes = {
  Hub: 'HUB',
  Tour: 'TOUR',
  Stop: 'STOP',
  Basket: 'BASKET',
  Empty: 'Empty'
};
