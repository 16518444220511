import React from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {CarrierAndMicroHubDropdownSelect, DateFilter, DropdownSelectSimple} from '../../../components';

function FinalDeadlineMigrationFilter(props) {

  const {classes, className, t, filter, setFilter, carriersWithMicroHubs, microHubOptions, shipperOptions} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <form className={classes.rowWrapper}>
        <DateFilter
          className={classes.formControl}
          error={!filter.date}
          required
          setValue={(value) => {
            setFilter({...filter, date: value})
          }}
          value={filter.date}
        />
        <DropdownSelectSimple
          className={classes.formControl}
          error={!filter.shipperName}
          name={t('shipper')}
          options={shipperOptions}
          required
          setValue={(value) => setFilter({...filter, shipperName: value})}
          sort
          value={filter.shipperName}
        />
        <CarrierAndMicroHubDropdownSelect
          carriersWithMicroHubs={carriersWithMicroHubs}
          carrierValue={filter.carrierName}
          className={classes.rowWrapper}
          microHubOptions={microHubOptions}
          microHubValue={filter.microHubName}
          setCarrierValue={(value) => setFilter({...filter, carrierName: value, microHubName: ''})}
          setMicroHubValue={(value) => setFilter({...filter, microHubName: value})}
        />
      </form>
    </div>
  );
}


FinalDeadlineMigrationFilter.propTypes = {
  carriersWithMicroHubs: PropTypes.object,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  microHubOptions: PropTypes.array,
  setFilter: PropTypes.func.isRequired,
  shipperOptions: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(FinalDeadlineMigrationFilter);
