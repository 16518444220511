import React, {Fragment} from 'react'
import {Paper, Tooltip, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

function TourAmPmTag(props) {

  const {classes, className, t, estimatedTourDeliveryWindow, elevation} = props;

  const rootClassName = classNames(classes.root, className);

  if (!estimatedTourDeliveryWindow) {
    return <Fragment/>
  }

  const am = moment(estimatedTourDeliveryWindow.from).isBefore(moment(estimatedTourDeliveryWindow.from).startOf('day').add(13, 'hour'));
  const pm = moment(estimatedTourDeliveryWindow.to).isAfter(moment(estimatedTourDeliveryWindow.to).startOf('day').add(13, 'hour'));

  const amText = am ? t('am') : '';
  const amDescription = am ? t('amDescription') : '';
  const pmText = pm ? t('pm') : '';
  const pmDescription = pm ? t('pmDescription') : '';
  const separator = am && pm ? ' / ' : '';

  if (!(am || pm)) {
    return <Fragment/>
  }

  return (
    <Tooltip title={`${amDescription}${separator}${pmDescription}`}>
      <Paper
        className={rootClassName}
        elevation={elevation}
      >
        <strong>{`${amText}${separator}${pmText}`}</strong>
      </Paper>
    </Tooltip>
  );
}

TourAmPmTag.defaultProps = {
  elevation: 1,
}

TourAmPmTag.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  elevation: PropTypes.number,
  estimatedTourDeliveryWindow: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TourAmPmTag);
