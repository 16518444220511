import {useEffect, useState} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {ClientService} from '../services/client/clientService';
import {clientTypes} from '../services/client/clientTypes';
import {MicroHubQueryService} from '../services/backend/microHubQueryService';


const clientMicroHub = ClientService.getClient(clientTypes.microHub);

export default function useMicroHubData() {

  const [microHubData, setMicroHubData] = useState([]);


  const queryMicroHubs = MicroHubQueryService.getAllMicroHubsQuery();
  const {data: dataMicroHub} = useQuery(queryMicroHubs, {client: clientMicroHub});


  useEffect(() => {
    if (dataMicroHub && dataMicroHub.microhubs && dataMicroHub.microhubs.length > 0) {
      setMicroHubData(dataMicroHub.microhubs);
    }
  }, [dataMicroHub]);


  return {microHubData};
}
