import {compose} from 'recompose';
import {IconButton, TableCell, TableRow, withStyles} from '@material-ui/core';
import styles from '../../styles';
import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {Add as AddIcon} from '@material-ui/icons';
import ProductAddRow from './ProductAddRow';

function ProductNewList(props) {
  const {classes, productsToAdd, updateItem, addItem, deleteProduct, otherProductsValid} = props;

  return(
    <Fragment>
      {productsToAdd.map((value, index) => {
        return(
          <ProductAddRow
            deleteProduct={deleteProduct}
            key={index}
            productToAdd={value}
            updateItem={updateItem}
          />
        )
      })}
      <TableRow className={classes.addRow}>
        <TableCell colSpan={8}>
          <IconButton
            color="primary"
            disabled={!otherProductsValid}
            onClick={() => {
              addItem();
            }}
          >
            <AddIcon/>
          </IconButton>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}
ProductNewList.propTypes = {
  addItem: PropTypes.func.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deleteProduct: PropTypes.func.isRequired,
  otherProductsValid: PropTypes.bool.isRequired,
  productsToAdd: PropTypes.array.isRequired,
  t: PropTypes.func,
  updateItem: PropTypes.func.isRequired,
}

export default compose(withStyles(styles))(ProductNewList);
