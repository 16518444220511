export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    margin: theme.spacing(0)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '200px',
    width: '200px',
  },
  rowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    margin: theme.spacing(1)
  },
  batteryStatus: {
    width: '90px',
    height: '90px',
    marginRight: '45px',
    marginTop: '-150px',
    transform: 'rotate(-90deg)'
  }
});
