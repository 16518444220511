import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {FilePond, registerPlugin} from 'react-filepond';
import FilepondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';
import {withTranslation} from 'react-i18next';

import {AuthService} from '../../services/auth/authService';

import 'filepond/dist/filepond.min.css';
import './styles.css';
import palette from '../../theme/palette';

const PondUploader = (props) => {
  const [forceUpload, setForceUpload] = useState(false);
  const [err, setErr] = useState('');
  const [files, setFiles] = useState([]);
  const {t, url, fileName, uploadType, onLoadCallback, uploaderOrganization, disabled, resetFiles, onResetFile} = props;
  const baseUri = `${process.env.REACT_APP_API_URI}routing`;
  registerPlugin(FilepondPluginFileValidateType);
  registerPlugin(FilePondPluginFileMetadata);
  for (let button of document.getElementsByClassName('filepond--action-retry-item-processing')) {
    button.addEventListener('click', () => setForceUpload(true))
  }
  if (resetFiles && files && files.length > 0) {
    setFiles([]);
    onResetFile();
  }
  return (
    <FilePond
      acceptedFileTypes={['application/msexcel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']}
      allowMultiple
      allowProcess
      disabled={disabled}
      files={files}
      fileValidateTypeLabelExpectedTypes={t('pondUploaderFileValidateTypeLabelExpectedTypes')}
      fileValidateTypeLabelExpectedTypesMap={
        {
          'application/msexcel': '.xls',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
          'application/vnd.ms-excel': null
        }
      }
      labelButtonAbortItemLoad={t('pondUploaderLabelButtonAbortItemLoad')}
      labelButtonAbortItemProcessing={t('pondUploaderLabelButtonAbortItemProcessing')}
      labelButtonProcessItem={t('pondUploaderLabelButtonProcessItem')}
      labelButtonRemoveItem={t('pondUploaderLabelButtonRemoveItem')}
      labelButtonRetryItemLoad={t('pondUploaderLabelButtonRetryItemLoad')}
      labelButtonRetryItemProcessing={t('pondUploaderLabelButtonRetryItemProcessing')}
      labelButtonUndoItemProcessing={t('pondUploaderLabelButtonUndoItemProcessing')}
      labelFileLoadError={t('pondUploaderLabelFileLoadError')}
      labelFileLoading={t('pondUploaderLabelFileLoading')}
      labelFileProcessing={t('pondUploaderLabelFileProcessing')}
      labelFileProcessingAborted={t('pondUploaderLabelFileProcessingAborted')}
      labelFileProcessingComplete={t('pondUploaderLabelFileProcessingComplete')}
      labelFileProcessingError={() => {
        return err;
      }}
      labelFileProcessingRevertError={t('pondUploaderLabelFileProcessingRevertError')}
      labelFileRemoveError={t('pondUploaderLabelFileRemoveError')}
      labelFileSizeNotAvailable={t('pondUploaderLabelFileSizeNotAvailable')}
      labelFileTypeNotAllowed={t('pondUploaderLabelFileTypeNotAllowed')}
      labelFileWaitingForSize={t('pondUploaderLabelFileWaitingForSize')}
      labelIdle={t('pondUploaderLabelIdle')}
      labelInvalidField={t('pondUploaderL')}
      labelTapToCancel={t('pondUploaderLabelTapToCancel')}
      labelTapToRetry={t('pondUploaderLabelTapToRetry')}
      labelTapToUndo={t('pondUploaderLabelTapToUndo')}
      name={fileName}
      onupdatefiles={fileItems => {
        setFiles([fileItems[0]])
      }}
      server={{
        url: baseUri,
        process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {

          // fieldName is the name of the input field
          // file is the actual file object to send
          const formData = new FormData();
          formData.append(fieldName, file, file.name);
          formData.append(uploadType, uploaderOrganization);
          formData.append('timeZone', Intl.DateTimeFormat().resolvedOptions().timeZone);
          formData.append('forceUpload', forceUpload);
          setForceUpload(false);

          const request = new XMLHttpRequest();
          request.open('POST', baseUri + url);

          request.setRequestHeader('authorization', AuthService.getAuthHeader());

          request.withCredentials = false;

          // Should call the progress method to update the progress to 100% before calling load
          // Setting computable to false switches the loading indicator to infinite mode
          request.upload.onprogress = (e) => {
            progress(e.lengthComputable, e.loaded, e.total);
          };

          // Should call the load method when done and pass the returned server file id
          // this server file id is then used later on when reverting or restoring a file
          // so your server knows which file to return without exposing that info to the client
          request.onload = function () {
            for (let item of document.getElementsByClassName('filepond--item-panel')) {
              item.style.backgroundColor = '';
            }
            if (onLoadCallback) {
              onLoadCallback(request.responseText)
            }
            if (request.status >= 200 && request.status < 300) {
              // the load method accepts either a string (id) or an object
              load(request.responseText);
            } else {
              if (request.status === 409) {
                for (let item of document.getElementsByClassName('filepond--item-panel')) {
                  item.style.backgroundColor = palette.warning.main;
                }
                setErr(request.responseText);
                error(request.responseText);
              } else {
                // Can call the error method if something is wrong, should exit after
                setErr(request.responseText);
                error(request.responseText);
              }
            }
          };

          request.send(formData);

          // Should expose an abort method so the request can be cancelled
          return {
            abort: () => {
              // This function is entered if the user has tapped the cancel button
              request.abort();

              // Let FilePond know the request has been cancelled
              abort();
            }
          };
        }
      }}
    />
  )
};

PondUploader.propTypes = {
  disabled: PropTypes.bool,
  fileName: PropTypes.string.isRequired,
  onLoadCallback: PropTypes.func,
  onResetFile: PropTypes.func,
  resetFiles: PropTypes.bool,
  t: PropTypes.func.isRequired,
  uploadType: PropTypes.oneOf(['shipper', 'carrier']).isRequired,
  uploaderOrganization: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default withTranslation()(PondUploader)
