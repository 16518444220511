import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react'
import {Tab, Tabs, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import SideNavLayout from '../../../layouts/SideNavLayout';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {TourService} from '../../../services/backend/tourService';
import {PreviewToursService} from '../../../services/backend/previewToursService';
import {DeletedStopDetails, DeletedStopsFilter, DeletedStopsTable, LoadingWrapper} from '../../../components';
import AppBar from '@material-ui/core/AppBar';
import {AuthService} from '../../../services/auth/authService';
import useShipperOptions from '../../../hooks/useShipperOptions';
import {DatePickerDefaultValueContext} from '../../../context';
import useMicroHubOptionsOfCarrier from '../../../hooks/useMicroHubOptionsOfCarrier';


function DeletedStops(props) {

  const {classes, t} = props;
  const {fromDateDefault, toDateDefault, updateDefaultDate} = useContext(DatePickerDefaultValueContext);

  const [finalStops, setFinalStops] = useState([]);
  const [previewStops, setPreviewStops] = useState([]);
  const [selectedFinalStop, setSelectedFinalStop] = useState(null);
  const [selectedPreviewStop, setSelectedPreviewStops] = useState(null);
  const [backendIsLoadingFinalStops, setBackendIsLoadingFinalStops] = useState(false);
  const [backendIsLoadingPreviewStops, setBackendIsLoadingPreviewStops] = useState(false);
  const {shipperOptions} = useShipperOptions();
  const [tabState, setTabState] = useState(0);
  const [filter, setFilter] = useState({
    carrierName: AuthService.getUserOrganization(),
    shipperName: '',
    microHubName: '',
    fromDate: fromDateDefault,
    toDate: toDateDefault
  });
  useEffect(() => updateDefaultDate(filter.fromDate), [filter.fromDate, updateDefaultDate]);

  const {microHubOptions} = useMicroHubOptionsOfCarrier(AuthService.getUserOrganization());

  const loadDataFinal = useCallback(() => {
    setBackendIsLoadingFinalStops(true);
    TourService.getDeletedStopsByFilter(filter.carrierName, filter.shipperName, filter.microHubName, filter.fromDate, filter.toDate)
      .then(response => response.json())
      .then(
        response => {
          setFinalStops(response);
          const index = response.findIndex(stop => selectedFinalStop && stop.stopId === selectedFinalStop.stopId);
          setSelectedFinalStop(index < 0 ? null : response[index]);
          setBackendIsLoadingFinalStops(false);
        }
        , () => {
          alert(t('errorLoadingDeletedFinalStops'));
          setBackendIsLoadingFinalStops(false);
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, t]);

  const loadDataPreview = useCallback(() => {
    setBackendIsLoadingPreviewStops(true);
    PreviewToursService.getDeletedStopsByFilter(filter.carrierName, filter.shipperName, filter.microHubName, filter.fromDate, filter.toDate)
      .then(response => response.json())
      .then(
        response => {
          setPreviewStops(response);
          const index = response.findIndex(stop => selectedPreviewStop && stop.stopId === selectedPreviewStop.stopId);
          setSelectedPreviewStops(index < 0 ? null : response[index]);
          setBackendIsLoadingPreviewStops(false);
        }
        , () => {
          alert(t('errorLoadingDeletedPreviewStops'));
          setBackendIsLoadingPreviewStops(false);
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, t]);

  useEffect(() => {
    loadDataFinal();
    loadDataPreview();
  }, [filter, loadDataFinal, loadDataPreview]);

  return (
    <SideNavLayout title={t('deletedStops')}>
      <AppBar
        className={classes.appBar}
        color={'secondary'}
        position={'relative'}
      >
        <Tabs
          onChange={(event, newValue) => {
            setTabState(newValue);
          }}
          value={tabState}
        >
          <Tab
            label={t('finalStops')}
          />
          <Tab
            label={t('previewStops')}
          />
        </Tabs>
      </AppBar>
      <div className={classes.root}>
        <DeletedStopsFilter
          filter={filter}
          microHubOptions={microHubOptions}
          setFilter={setFilter}
          shipperOptions={shipperOptions}
        />
        {(() => {
          switch (tabState) {
            case 0:
              return (
                <LoadingWrapper loading={backendIsLoadingFinalStops}>
                  <Fragment>
                    <DeletedStopsTable
                      className={classes.content}
                      deletedStops={finalStops}
                      selectedStop={selectedFinalStop}
                      setSelectedStop={setSelectedFinalStop}
                    />
                    {selectedFinalStop &&
                    <DeletedStopDetails
                      className={classes.content}
                      closeDetailView={() => setSelectedFinalStop(null)}
                      stop={selectedFinalStop}
                    />
                    }
                  </Fragment>
                </LoadingWrapper>
              );
            case 1:
              return (
                <LoadingWrapper loading={backendIsLoadingPreviewStops}>
                  <Fragment>
                    <DeletedStopsTable
                      className={classes.content}
                      deletedStops={previewStops}
                      selectedStop={selectedPreviewStop}
                      setSelectedStop={setSelectedPreviewStops}
                    />
                    {selectedPreviewStop &&
                    <DeletedStopDetails
                      className={classes.content}
                      closeDetailView={() => setSelectedPreviewStops(null)}
                      stop={selectedPreviewStop}
                    />
                    }
                  </Fragment>
                </LoadingWrapper>
              );
            default:
              return (<div>error</div>);
          }
        })()}
      </div>
    </SideNavLayout>
  );

}

DeletedStops.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(DeletedStops);
