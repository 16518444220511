import React from 'react'
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core';
import styles from './styles';
import classNames from 'classnames';

function TextPlaceholderWrapper(props) {
  const {active, text, children, classes, className} = props;

  const rootClassName = classNames(classes.root, className);

  if (active) {
    return (
      <div className={rootClassName}>
        {text}
      </div>
    );
  }

  return (
    children
  );

}

TextPlaceholderWrapper.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
};


export default withStyles(styles)(TextPlaceholderWrapper);
