import React, {useContext, useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import SideNavLayout from '../../../layouts/SideNavLayout';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {SimulateTourBuildingComponent} from '../../../components';
import {displayModes} from '../../../services/enums/displayModes';
import useShipperOptions from '../../../hooks/useShipperOptions';
import useCarriersWithHubs from '../../../hooks/useCarriersWithHubs';
import {DatePickerDefaultValueContext} from '../../../context';
import useMicroHubData from '../../../hooks/useMicroHubData';

function SimulateTourBuilding(props) {

  const {t} = props;
  const {fromDateDefault, toDateDefault, updateDefaultDate} = useContext(DatePickerDefaultValueContext);

  const [filter, setFilter] = useState({
    carrierName: '',
    shipperName: '',
    microHubName: '',
    stopStatus: '',
    fromDate: fromDateDefault,
    toDate: toDateDefault,
    boxId: ''
  });
  useEffect(() => updateDefaultDate(filter.fromDate), [filter.fromDate, updateDefaultDate]);
  const {shipperOptions} = useShipperOptions();
  const {carriersWithHubs, carrierOptions} = useCarriersWithHubs();
  const {microHubData} = useMicroHubData();

  return (
    <SideNavLayout title={t('simulateTourBuilding')}>
      <SimulateTourBuildingComponent
        carriers={carrierOptions}
        carriersWithMicroHubs={carriersWithHubs}
        displayMode={displayModes.admin}
        filter={filter}
        microHubs={microHubData}
        setFilter={setFilter}
        shippers={shipperOptions}
      />
    </SideNavLayout>
  );

}

SimulateTourBuilding.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(SimulateTourBuilding);
