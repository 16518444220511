export default theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  rowDisabled: {
    backgroundColor: theme.palette.common.muted,
  },
  alignLeft: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  lastTableCell: {
    paddingRight: theme.spacing(0),
    display: 'flex',
    justifyContent: 'space-around'
  },
  firstTableCell: {
    paddingLeft: theme.spacing(0),
    alignItems: 'center',
    display: 'flex'
  },
  dragging: {
    backgroundColor: theme.palette.primary.selected,
  },
  dragIcon: {
    height: '22px',
    maxHeight: '22px',
    paddingLeft: 0,
    marginRight: theme.spacing(1),
  },
  buttonLeft: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.danger.main,
  },
  buttonRight: {
    float: 'right',
    paddingRight: theme.spacing(1)
  },
  largeTableCell: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
  },
});

