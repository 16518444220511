import {Box, Container, Typography, withStyles} from '@material-ui/core';
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './styles'


function Privacy(props) {

  const {classes} = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <Box
        className={classes.box}
      >
        <Typography
          className={classes.header}
          color="inherit"
          variant={'h1'}
        >
          Datenschutz
        </Typography>
      </Box>
      <Container maxWidth="md">
        <div>
          <Typography className={classes.section}>
            Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten
            (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten
            werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
            <br/>
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
            lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
            <br/>
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter
            Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche
            Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
          </Typography>
          <Typography className={classes.section}>
            Sentry
            <br/>
            Wir verwenden den Dienst Sentry (Sentry, 1501 Mariposa St #408, San Francisco, CA 94107, USA), um die technische Stabilität unseres Dienstes durch
            Überwachung der Systemstabilität und Ermittlung von Codefehlern zu verbessern. Sentry dient alleine diesen Zielen und wertet keine Daten zu
            Werbezwecken aus. Die Daten der Nutzer, wie z.B. Angaben zum Gerät oder Fehlerzeitpunkt werden pseudonymisiert an Sentry weitergegeben und nach
            abgeschlossener Fehleranalyse gelöscht Weitere Informationen hierzu finden Sie in
            der <a href="https://sentry.io/privacy/">Datenschutzerklärung</a> von Sentry.
          </Typography>
        </div>
      </Container>
    </main>
  );

}

Privacy.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Privacy);
