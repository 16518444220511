export default theme => ({
  root: {
    minWidth: '350px'
  },
  portletHeader: {
    height: '40px'
  },
  capacityAllWrapper: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  capacityAllIndicatorWrapper: {
    width: '100%',
  },
  capacityAllIndicator: {
    borderRadius: 4,
    height: '30px'
  },
  progressBarRed: {
    color: theme.palette.danger.main,
    backgroundColor: theme.palette.danger.main,
  },
  doughnut: {
    height: '25vh',
    position: 'relative'
  },
});
