import React from 'react'
import {Button, CircularProgress, Tooltip} from '@material-ui/core';
import {DateService} from '../../../../../services/util/DateService';
import {Warning} from '@material-ui/icons'

const Columns = {
  tourInstanceId: {
    name: 'tourInstanceId',
    header: ({t}) => t('tourInstanceId'),
    body: ({tour}) => tour.tourInstanceId
  },
  pcgTourId: {
    name: 'pcgTourId',
    header: ({t}) => t('pcgTourId'),
    body: ({tour}) => tour.tourId
  },
  packingDate: {
    name: 'packingDate',
    header: ({t}) => t('packingDate'),
    body: ({tour}) => DateService.displayDate(tour.packingDate)
  },
  deliveryDate: {
    name: 'deliveryDate',
    header: ({t}) => t('deliveryDate'),
    body: ({tour}) => DateService.displayDate(tour.deliveryDate),
  },
  descriptionInternal: {
    name: 'descriptionInternal',
    header: ({t}) => t('descriptionInternal'),
    body: ({tour}) => tour.descriptionInternal
  },
  note: {
    name: 'note',
    header: ({t}) => t('note'),
    body: ({tour}) => tour.note
  },
  numCustomers: {
    name: 'numCustomers',
    header: ({t}) => t('numCustomers'),
    body: ({tour}) => `${tour.customers}`
  },
  numOrders: {
    name: 'numOrders',
    header: ({t}) => t('numOrders'),
    body: ({tour}) => `${tour.orders}`
  },
  import: {
    name: 'import',
    header: ({t}) => t('import'),
    body: ({tour, t, classes, importTour, selectedShipper, tourImporting}) => {
      if (tour.error)
        return (
          <Tooltip title={t(tour.error)}>
            <Warning className={classes.warning}/>
          </Tooltip>
        );

      if (tourImporting === tour.tourInstanceId) {
        return (<CircularProgress size={25}/>)
      }

      return (
        <Button
          className={classes.deleteButton}
          color="primary"
          disabled={Boolean(tour.error)}
          onClick={() => importTour(tour.tourId, tour.tourInstanceId, selectedShipper)}
          title={t('importPcgTour')}
        >
          {t('importPcgTour')}
        </Button>
      );
    }
  },
}

export default Columns;