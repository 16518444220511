export default theme => ({
  root: {
    border: `2px solid ${theme.palette.border}`,
    borderRadius: '5px'
  },
  controlGrid: {
    borderLeft: `2px solid ${theme.palette.border}`,
  },
  controlElement: {
    padding: theme.spacing(1),
  },
  capacityAllWrapper: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  capacityAllIndicatorWrapper: {
    marginRight: theme.spacing(1),
    width: '75%',
  },
  capacityAllIndicator: {
    borderRadius: 4,
    height: '30px'
  },
  controlButton: {
    display: 'block',
    width: '100%',
    textAlign: 'left',
    marginTop: theme.spacing(1),
  },
  controlButtonIcon: {
    verticalAlign: 'middle'
  },
  zoneDetails: {
    display: 'block',
    width: '100%',
    marginTop: theme.spacing(2),
    overflowY: 'auto'
  }
});
