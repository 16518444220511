import React, {Fragment, useState} from 'react'
import {IconButton, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Help as HelpIcon} from '@material-ui/icons';
import {AuthService} from '../../../services/auth/authService';
import GeneralInfoDialog from '../GeneralInfoDialog';

function GeneralInfoIcon(props) {

  const {classes, className, t} = props;
  const [dialogState, setDialogState] = useState({open: false});


  // Find e-learning information for this component
  // Keys must always follow this pattern: 'generalInfo_<RoleName>_<pageNameFromURL>_<numberOfInfoStartingBy0>'
  // By appending '_autoBreak' the text will automatically break to the right width. You still need to add paragraphs using '\n\n'
  const generalKey = `generalInfo_${AuthService.getUserRole()}_${window.location.pathname.substr(1)}_`;
  const translatedInfo = [];
  let numInfo = 0;
  while (t(`${generalKey}${numInfo}`) !== `${generalKey}${numInfo}` || t(`${generalKey}${numInfo}_autoBreak`) !== `${generalKey}${numInfo}_autoBreak`) {
    if (t(`${generalKey}${numInfo}`) !== `${generalKey}${numInfo}`) {
      translatedInfo.push(
        {
          text: t(`${generalKey}${numInfo}`),
          autoBreak: false
        }
      );
    } else {
      translatedInfo.push(
        {
          text: t(`${generalKey}${numInfo}_autoBreak`),
          autoBreak: true
        }
      );
    }
    numInfo++;
  }
  // If no e-learning keys were found display a default message
  if (numInfo === 0) {
    translatedInfo.push(t('generalInfo_noELearning'));
  }

  const rootClassName = classNames(classes.root, className);

  return (
    <Fragment>
      <IconButton
        className={rootClassName}
        color="secondary"
        onClick={() => setDialogState({...dialogState, open: true})}
      >
        <HelpIcon className={classes.icon}/>
      </IconButton>
      <GeneralInfoDialog
        dialogState={dialogState}
        handleClose={() => setDialogState({...dialogState, open: false})}
        translatedInfo={translatedInfo}
      />
    </Fragment>
  );
}

GeneralInfoDialog.defaultProps = {
  autoBreak: false,
};

GeneralInfoIcon.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default compose(withStyles(styles), withTranslation())(GeneralInfoIcon);
