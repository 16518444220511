export default theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '250px',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dialog: {},
  addAvatar: {
    backgroundColor: theme.palette.primary.main,
  }
});
