export default theme => ({
  root:{
    '& > *': {
      borderBottom: 'unset',
    }
  },
  listAvatarError: {
    color: theme.palette.danger.main,
    backgroundColor: theme.palette.danger.light,
  },
  listAvatarSuccess: {
    color: theme.palette.success.main,
    backgroundColor: theme.palette.success.light,
  },
  listAvatarWarning: {
    color: theme.palette.warning.main,
    backgroundColor: theme.palette.warning.light,
  },
  listAvatarInfo: {
    color: theme.palette.common.muted,
    backgroundColor: theme.palette.common.neutral,
  },
  nested: {
    paddingLeft: theme.spacing(9)
  }
});
