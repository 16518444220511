import React, {Fragment, useState} from 'react'
import {Box, Collapse, TableCell, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {TableCellWithConfig, TourStopTableSmallDetails} from '../../../../../components';
import Columns from '../columns';


function StopAddTableRow(props) {

  const {
    classes,
    className,
    t,
    stopsToAdd,
    expectedHub,
    expectedHubData,
    tourDeliveryDate,
    stop,
    addOrRemoveStop,
    columnConfigs
  } = props;
  const [open, setOpen] = useState(false);

  const rootClassName = classNames(className, classes.root);

  return (
    <Fragment>
      <TableRow
        className={classNames(classes.tableRow, rootClassName, 'cursorPointer', stopsToAdd.some(presentStop => presentStop.tourStopId === stop.tourStopId) ? classes.selected : '')}
        hover={!stopsToAdd.some(presentStop => presentStop.tourStopId === stop.tourStopId)}
        onClick={() => addOrRemoveStop(stop)}
      >
        {columnConfigs.map(columnConfig => (
          <TableCellWithConfig
            key={columnConfig.order}
            config={columnConfig}
            className={classes.tableCell}
          >
            {Columns[columnConfig.columnName].body({
              stop,
              classes,
              t,
              expectedHubData,
              tourDeliveryDate,
              expectedHub,
              open,
              setOpen
            })}
          </TableCellWithConfig>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          className={classes.largeTableCell}
          colSpan={columnConfigs.filter(val => !val.hidden).length}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <Box margin={1}>
              <TourStopTableSmallDetails stop={stop}/>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

StopAddTableRow.propTypes = {
  addOrRemoveStop: PropTypes.func.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  columnConfigs: PropTypes.array.isRequired,
  expectedHub: PropTypes.string,
  expectedHubData: PropTypes.object,
  stop: PropTypes.object.isRequired,
  stopsToAdd: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  tourDeliveryDate: PropTypes.string,
};

export default compose(withStyles(styles), withTranslation())(StopAddTableRow);
