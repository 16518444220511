// eslint-disable-next-line no-unused-vars

export default theme => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '200px'
  },
  horizontalContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});
