import React from 'react'
import SideNavLayout from '../../../layouts/SideNavLayout';
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {AuthService} from '../../../services/auth/authService';
import {useQuery} from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import {DataLoadingWrapper, GeneralInfoIcon, ShipperProfile,} from '../../../components';
import {ClientService} from '../../../services/client/clientService';
import {clientTypes} from '../../../services/client/clientTypes';
import {ShipperQueryService} from '../../../services/backend/shipperQueryService';

const client = ClientService.getClient(clientTypes.shipper);

function Profile(props) {
  const {classes, t} = props;

  const query = ShipperQueryService.getShipperProfileQuery(AuthService.getUserOrganization());
  const {loading, error, data} = useQuery(query, {client: client});

  return (
    <SideNavLayout title={t('account')}>
      <div className={classes.root}>
        <div className={classes.helpWrapper}>
          <GeneralInfoIcon/>
        </div>
        <DataLoadingWrapper
          error={error}
          isLoading={loading}
        >
          {
            data && data.shippers &&
            <ShipperProfile
              readonly
              shipper={data.shippers[0]}
            />
          }
        </DataLoadingWrapper>
      </div>
    </SideNavLayout>
  );
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(Profile);
