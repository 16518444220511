import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core';
import styles from './styles';
import {
  DispositionTiming,
  LoadingWrapper,
  Portlet,
  PortletContent,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  TextPlaceholderWrapper
} from '../../../components';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import moment from 'moment';


function TimeDispositionCard(props) {

  const {t, tours, classes, className, filter, isLoading} = props;

  const rootClassName = classNames(classes.root, className);
  const approvedTours = tours.filter(tour => tour.approved);


  return (
    <Portlet
      className={rootClassName}
    >
      <PortletHeader className={classes.portletHeader}>
        <PortletLabel title={t('dashboardTimeDispositionCard')}/>
        <PortletToolbar/>
      </PortletHeader>
      <PortletContent
        className={classes.portletContent}
        noPadding
      >
        <LoadingWrapper loading={isLoading}>
          <TextPlaceholderWrapper
            active={!tours?.length}
            text={t('noToursAvailableDashboard')}
          >
            <DispositionTiming
              approvedTours={approvedTours}
              className={classes.dispositionTiming}
              rerender={isLoading}
              startFrom={moment(filter.fromDate).startOf('day').add(6, 'hours').toDate()}
              startTo={moment(filter.fromDate).startOf('day').add(19, 'hours').toDate()}
            />
          </TextPlaceholderWrapper>
        </LoadingWrapper>
      </PortletContent>
    </Portlet>
  );
}

TimeDispositionCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  tours: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TimeDispositionCard);
