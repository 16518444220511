export default theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dialog: {},
  wrapper: {
    display: 'flex',
    margin: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '200px',
    width: '200px',
  },
});
