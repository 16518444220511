export default theme => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  helpWrapper: {
    display: 'inline-block',
    float: 'right'
  },
  filter: {
    display: 'inline-block',
  },
  filterWrapper: {
    marginBottom: theme.spacing(2),
  },
  appBar: {
    left: '2px',
    width: 'calc(100% - 2px)',
    zIndex: 'unset'
  }
});
