import React, {Fragment, useCallback, useEffect, useState} from 'react'
import {FormControl, IconButton, Input, InputLabel, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import administrationService from '../../../../services/backend/administrationService';
import moment from 'moment';
import {Cancel, Edit, Save} from '@material-ui/icons';


function DeadlineEdit(props) {

  const {classes, t} = props;
  const [stopEditDeadline, setStopEditDeadline] = useState('');
  const [editMode, setEditMode] = useState(false);

  const loadDeadline = useCallback(async () => {
    try {
      const response = await administrationService.getFinalStopDeadline();
      setStopEditDeadline(response / (60 * 60));
    } catch (error) {
      alert(`${t('errorLoadingStopEditDeadline')}, ${error}`);
    }
  }, [t]);

  const updateEditStopDeadline = useCallback(async (deadline) => {
    try {
      await administrationService.updateFinalStopDeadline(deadline * (60 * 60));
      await loadDeadline();
    } catch (error) {
      alert(`${t('errorUpdatingStopEditDeadline')}, ${error}`);
    }
  }, [t, loadDeadline]);

  useEffect(() => {
    loadDeadline();
  }, [loadDeadline]);

  const updateStopEditDeadline = async () => {
    setEditMode(false);
    await updateEditStopDeadline(stopEditDeadline);
  };

  const cancel = async () => {
    await loadDeadline();
    setEditMode(false);
  };

  if (stopEditDeadline === '') {
    return (
      <div className={classes.root}>
        <Typography variant="h5">{t('stopEditDeadline')}</Typography>
        <Typography variant="body1">{t('stopEditDeadlineNotFound')}</Typography>
      </div>
    );
  }

  const deadline = moment().startOf('day').seconds(stopEditDeadline * (60 * 60) - (new Date().getTimezoneOffset() * 60));
  return (
    <div className={classes.root}>
      <div className={classes.headerLine}>
        <Typography variant="h5">{t('stopEditDeadline')}</Typography>
        {editMode ?
          <Fragment>
            <IconButton
              className={classes.button}
              color="primary"
              onClick={cancel}
              title={t('cancel')}
            >
              <Cancel/>
            </IconButton>
            <IconButton
              className={classes.button}
              color="primary"
              disabled={stopEditDeadline === '' || isNaN(stopEditDeadline)}
              onClick={updateStopEditDeadline}
              title={t('save')}
            >
              <Save/>
            </IconButton>
          </Fragment>
          :
          <IconButton
            className={classes.button}
            color="primary"
            onClick={() => setEditMode(true)}
            title={t('edit')}
          >
            <Edit/>
          </IconButton>
        }
      </div>
      {editMode &&
      <FormControl className={classes.formControl}>
        <InputLabel
          htmlFor="stopEditDeadline"
          required
        >{t('stopEditDeadline')}</InputLabel>
        <Input
          error={stopEditDeadline === '' || isNaN(stopEditDeadline)}
          id="stopEditDeadline"
          onChange={(event) => setStopEditDeadline(event.target.valueAsNumber)}
          onClick={event => event.target.select()}
          onWheel={event => event.target.blur()}
          type="number"
          value={stopEditDeadline}
        />
      </FormControl>
      }
      <Typography variant="body1">
        {`${((stopEditDeadline - (new Date().getTimezoneOffset() / 60))).toLocaleString()}h
              (${t('pointInTime')}: ${(deadline.clone().startOf('day').diff(moment().startOf('day'), 'days') < 0 ?
          't' : 't+') + deadline.clone().startOf('day').diff(moment().startOf('day'), 'days')} 
              ${deadline.format('HH:mm')})`}
      </Typography>
    </div>
  );

}

DeadlineEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(DeadlineEdit);
