// eslint-disable-next-line no-unused-vars
export default theme => ({
  dialog: {},
  map: {
    width: '100%',
    height: '75vh'
  },
  table: {
    overflowY: 'auto',
    maxHeight: '75vh',
    width: '100%',
    border: `1px solid ${theme.palette.border}`,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    paddingRight: theme.spacing(3)
  },
  warningColor: {
    color: theme.palette.warning.main,
    verticalAlign: 'bottom'
  },
});
