import React, {useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import {default as ViewShipper} from './ViewShipper';
import useCollapse from 'react-collapsed';
import classNames from 'classnames';
import EditShipper from './EditShipper';
import {useMutation} from '@apollo/react-hooks';
import {clientTypes} from '../../../../services/client/clientTypes';
import {ClientService} from '../../../../services/client/clientService';
import {ShipperQueryService} from '../../../../services/backend/shipperQueryService';


const clientShipper = ClientService.getClient(clientTypes.shipper);

function ShipperProfile(props) {
  const {classes, t, shipper, className, refetchShipper, readonly} = props;

  const {getCollapseProps, getToggleProps, isOpen, toggleOpen} = useCollapse({defaultOpen: false});

  const rootClassName = classNames(classes.root, className);

  const updateShipperMutation = ShipperQueryService.updateShipperById();
  const deleteShipperMutation = ShipperQueryService.deleteShipperById();
  const [updateShipper] = useMutation(updateShipperMutation, {client: clientShipper});
  const [deleteShipper] = useMutation(deleteShipperMutation, {client: clientShipper});

  const [editShipperState, setEditShipperState] = useState(false);

  const onEditClick = () => {
    setEditShipperState(true);
  };

  const saveClick = (info, empties, address, billingAddress, contact, serviceContact, emergencyContact, disponent, deadlines, trackingNotifications, trackingLinkInfos, stopBonus) => {
    setEditShipperState(false);
    const timeZoneOffset = new Date().getTimezoneOffset() * 60;
    const locShipper = {
      id: shipper.id,
      name: info.name,
      shortName: info.shortName,
      signature: info.signature,
      showEmergencyContact: info.showEmergencyContact,
      previewDeadline: Math.round((deadlines.previewDeadline * 60 * 60) + timeZoneOffset),
      finalDeadline: Math.round((deadlines.finalDeadline * 60 * 60) + timeZoneOffset),
      emptiesDeadline: Math.round((deadlines.emptiesDeadline * 60 * 60) + timeZoneOffset),
      empties: empties.map((e, i) => (
        {name: e.name, order: i}
      )),
      address: address,
      billingAddress: billingAddress,
      contact: contact,
      serviceContact: serviceContact,
      emergencyContact: emergencyContact,
      disponent: disponent,
      trackingNotifications: trackingNotifications,
      trackingLinkInfos: trackingLinkInfos,
      stopBonus: stopBonus
    };
    updateShipper({variables: {shipper: locShipper}}).then(() => {
      refetchShipper();
    }, (e) => {
      alert(t('errorUpdateShipper'));
    });
  };

  const cancelClick = () => {
    setEditShipperState(false);
  };

  const deleteClick = () => {
    setEditShipperState(false);
    toggleOpen();
    deleteShipper({variables: {shipperId: shipper.id}}).then(() => {
      refetchShipper();
    }, () => {
      alert(t('errorDeleteShipper'));
    });
  };

  return (
    <div className={rootClassName}>
      <div
        {...getToggleProps()}
        className={'noselect cursorPointer'}
      >
        <Typography variant="h3">
          {`${shipper.name} (${shipper.shortName})`}
          <i className="material-icons md-48">{isOpen ? 'expand_less' : 'expand_more'}</i>
        </Typography>
      </div>
      <div {...getCollapseProps()}>
        {!editShipperState ?
          <ViewShipper
            onEditClick={onEditClick}
            readonly={readonly}
            shipper={shipper}
          />
          :
          <EditShipper
            cancelClick={cancelClick}
            deleteClick={deleteClick}
            saveClick={saveClick}
            shipper={shipper}
          />
        }
      </div>
    </div>
  );
}

ShipperProfile.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  readonly: PropTypes.bool,
  refetchShipper: PropTypes.func.isRequired,
  shipper: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(ShipperProfile);
