import React, {Fragment, useState} from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
  withStyles
} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {InfoIcon, InfoTooltip} from '../../../components';
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import {onCloseWithBackdropCurry} from '../../../services/util/helperFuncs';


function TourCreateDialog(props) {

  const {classes, t, createTourDialogState, handleCancel, handleClose, shipperOptions, microHubOptions, tourPreset} = props;
  const [tour, setTour] = useState(tourPreset);
  const [toursToCreate, setToursToCreate] = useState(1);

  const dialogOk = () => {
    handleClose(tour, toursToCreate);
    setTour(tourPreset);
  };

  const handleNotOk = () => {
    handleCancel();
    setTour(tourPreset);
  };

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={createTourDialogState.open}
    >
      <DialogTitle>{t('createTour')}
        <InfoIcon
          className={classes.iconMargin}
          descriptionKey={'22'}
        />
      </DialogTitle>
      <DialogContent>
        <form className={classes.container}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Fragment>
              <div className={classes.rowWrapper}>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    htmlFor="toursToCreate"
                    required
                  >{t('amountTours')}</InputLabel>
                  <Input
                    error={toursToCreate < 1}
                    id="toursToCreate"
                    onChange={(event) => setToursToCreate((parseInt(event.target.value) && parseInt(event.target.value) >= 1) ? parseInt(event.target.value) : 1)}
                    onClick={event => event.target.select()}
                    onWheel={event => event.target.blur()}
                    type="number"
                    value={toursToCreate}
                  />
                </FormControl>
              </div>
              <div className={classes.rowWrapper}>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    htmlFor="text-simple"
                    required
                  >{t('microHub')}</InputLabel>
                  <Select
                    error={!tour.microHubName}
                    input={<Input id="text-simple"/>}
                    onChange={(event) => setTour({...tour, microHubName: event.target.value})}
                    required
                    value={tour.microHubName}
                  >
                    {microHubOptions.map((hubName, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={hubName}
                        >
                          {hubName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <KeyboardDatePicker
                    cancelLabel={t('dialogCancel')}
                    format="dd.MM.yyyy"
                    id="deliveryDate"
                    label={t('planedTourDelivery')}
                    margin="normal"
                    onChange={(event) => {
                      setTour({...tour, planedTourDelivery: new Date(event)})
                    }}
                    value={tour.planedTourDelivery}
                  />
                </FormControl>
              </div>
              <FormControl className={classes.formControl}>
                <InfoTooltip
                  descriptionKey={'22'}
                  placement={'bottom'}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tour.fix}
                        color="primary"
                        onChange={(event) =>
                          setTour({...tour, fix: event.target.checked, shipperName: event.target.checked ? tour.shipperName : ''})
                        }
                        value={tour.fix}
                      />
                    }
                    label={t('fix')}
                  />
                </InfoTooltip>
              </FormControl>


              {tour.fix &&
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="text-simple">{t('shipper')}</InputLabel>
                <Select
                  input={<Input id="text-simple"/>}
                  onChange={(event) => setTour({...tour, shipperName: event.target.value})}
                  value={tour.shipperName}
                >
                  {shipperOptions.map((shipper, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={shipper}
                      >
                        {shipper}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              }
            </Fragment>
          </MuiPickersUtilsProvider>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={handleNotOk}
        >
          <CancelIcon/>&nbsp;{t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          disabled={!tour.microHubName || (tour.fix && !tour.shipperName)}
          onClick={dialogOk}
        >
          <SaveIcon/>&nbsp;{t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

TourCreateDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  createTourDialogState: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  microHubOptions: PropTypes.array.isRequired,
  shipperOptions: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  tourPreset: PropTypes.object.isRequired,
};


export default compose(withStyles(styles), withTranslation())(TourCreateDialog);
