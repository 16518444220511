export default theme => ({
  root: {},
  paper: {
    padding: theme.spacing(1),
  },
  sidebarHeader: {
    color: 'white',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  dateHeader: {
    backgroundColor: theme.palette.primary.main,
  },
  tourItem: {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main
  }
});

