import React from 'react'
import {Button, Link, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import SideNavLayout from '../../../layouts/SideNavLayout';
import Paper from '@material-ui/core/Paper';
import {GetApp} from '@material-ui/icons';


function AppDownload(props) {

  const {classes, t} = props;

  return (
    <SideNavLayout title={t('appDownload')}>
      <div className={classes.center}>
        <Paper className={classes.paper}>
          <Typography variant="h4">
            {t('getApp')}
          </Typography>
          <Button
            className={classes.button}
            color={'primary'}
            component={Link}
            href={process.env.REACT_APP_DOWNLOAD_APP}
            rel={'noopener, noreferrer'}
            target={'_blank'}
            variant={'contained'}
          >
            <GetApp/>
          </Button>
        </Paper>
      </div>
    </SideNavLayout>
  );
}


AppDownload.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(AppDownload);
