import {Tooltip} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const ShipperLabel = (props) => {

  const {stop} = props;

  if (stop?.shipperShortName) {
    return (
      <Tooltip title={stop.shipperName}>
        <div>{stop.shipperShortName ? stop.shipperShortName : stop.shipperName}</div>
      </Tooltip>
    );
  }

  return (
    <div>{stop?.shipperName || ''}</div>
  );

}

ShipperLabel.propTypes = {
  stop: PropTypes.shape({shipperShortName: PropTypes.string, shipperName: PropTypes.string.isRequired}).isRequired
}

export default ShipperLabel;
