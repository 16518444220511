import React from 'react';
import PropTypes from 'prop-types';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, withStyles} from '@material-ui/core';
import styles from './styles';
import {compose} from 'recompose';
import JSONPretty from 'react-json-pretty';
import {withTranslation} from 'react-i18next';

function DeclinedStopsDialog(props) {

  const {classes, t, dialogState, onClose} = props;

  return (
    <Dialog
      className={classes.dialog}
      onClose={onClose}
      open={dialogState.open}
      scroll={'paper'}
    >
      <DialogTitle>{t('declinedStopsDialogTitle')}</DialogTitle>
      <DialogContent
        className={classes.dialogContent}
      >
        <JSONPretty
          data={dialogState.source}
          id="json-pretty"
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
        >
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeclinedStopsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogState: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default compose(withStyles(styles), withTranslation())(DeclinedStopsDialog);