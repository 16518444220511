import React, {useState} from 'react'
import {Button, Card, CardContent, FormControl, Input, InputLabel, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import {isNumber} from 'lodash';

function AppVersionCardEdit(props) {

  const {classes, t, appVersion, title, onSaveClick, onCancelClick} = props;

  const [version, setVersion] = useState(appVersion)

  const activateOk = version.major !== '' && !isNaN(version.major) && version.apk !== '' && !isNaN(version.apk) && (!isNumber(appVersion.codePush) || (version.codePush !== '' && !isNaN(version.codePush)));

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.buttonContainer}>
            <Typography
              variant={'h4'}
            >
              {title}
            </Typography>
            <Button
              className={classes.button}
              color="primary"
              disabled={!activateOk}
              onClick={() => {
                onSaveClick(version);
                setVersion(appVersion);
              }}
              title={t('save')}
              variant="contained"
            >
              <SaveIcon/>
            </Button>
            <Button
              className={classes.button}
              color="default"
              onClick={() => {
                onCancelClick();
                setVersion(appVersion);
              }}
              title={t('cancel')}
              variant="contained"
            >
              <CancelIcon/>
            </Button>
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <InputLabel>
                {t('majorVersion')}
              </InputLabel>
              <Input
                onChange={event => {
                  setVersion({...version, major: event.target.value})
                }}
                onClick={event => event.target.select()}
                value={version.major}
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <InputLabel>
                {t('apkVersion')}
              </InputLabel>
              <Input
                onChange={(event) => {
                  setVersion({...version, apk: event.target.value})
                }}
                onClick={event => event.target.select()}
                required
                value={version.apk}
              />
            </FormControl>
          </div>
          {isNumber(appVersion.codePush) &&
          <div>
            <FormControl className={classes.formControl}>
              <InputLabel>
                {t('codePushVersion')}
              </InputLabel>
              <Input
                onChange={(event) => {
                  setVersion({...version, codePush: event.target.value})
                }}
                onClick={event => event.target.select()}
                required
                value={version.codePush}
              />
            </FormControl>
          </div>
          }
        </CardContent>
      </Card>
    </div>
  );
}

AppVersionCardEdit.propTypes = {
  appVersion: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};


export default compose(withStyles(styles), withTranslation())(AppVersionCardEdit);
