import React, {Fragment, useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Input, InputLabel, MenuItem, Select, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import {onCloseWithBackdropCurry} from '../../../services/util/helperFuncs';

function PreviewTourCreateDialog(props) {

  const {classes, t, createTourDialogState, handleCancel, handleClose, microHubOptions, tourPreset} = props;
  const [tour, setTour] = useState(tourPreset);
  const [toursToCreate, setToursToCreate] = useState(1);

  const dialogOk = () => {
    handleClose(tour, toursToCreate);
    setTour(tourPreset);
  };

  const handleNotOk = () => {
    handleCancel();
    setTour(tourPreset);
  };

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={createTourDialogState.open}
    >
      <DialogTitle>{t('createTour')}
      </DialogTitle>
      <DialogContent>
        <form className={classes.container}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Fragment>
              <div className={classes.rowWrapper}>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    htmlFor="toursToCreate"
                    required
                  >{t('amountTours')}</InputLabel>
                  <Input
                    error={toursToCreate < 1}
                    id="toursToCreate"
                    onChange={(event) => setToursToCreate((parseInt(event.target.value) && parseInt(event.target.value) >= 1) ? parseInt(event.target.value) : 1)}
                    onClick={event => event.target.select()}
                    onWheel={event => event.target.blur()}
                    type="number"
                    value={toursToCreate}
                  />
                </FormControl>
              </div>
              <div className={classes.rowWrapper}>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    htmlFor="text-simple"
                    required
                  >{t('microHub')}</InputLabel>
                  <Select
                    error={!tour.microHubName}
                    input={<Input id="text-simple"/>}
                    onChange={(event) => setTour({...tour, microHubName: event.target.value})}
                    required
                    value={tour.microHubName}
                  >
                    {microHubOptions.map((hubName, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={hubName}
                        >
                          {hubName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <KeyboardDatePicker
                    cancelLabel={t('dialogCancel')}
                    format="dd.MM.yyyy"
                    id="deliveryDate"
                    label={t('planedTourDelivery')}
                    margin="normal"
                    onChange={(event) => {
                      setTour({...tour, deliveryDate: new Date(event)})
                    }}
                    value={tour.deliveryDate}
                  />
                </FormControl>
              </div>
            </Fragment>
          </MuiPickersUtilsProvider>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={handleNotOk}
        >
          <CancelIcon/>&nbsp;{t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          disabled={!tour.microHubName || toursToCreate < 1}
          onClick={dialogOk}
        >
          <SaveIcon/>&nbsp;{t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PreviewTourCreateDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  createTourDialogState: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  microHubOptions: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  tourPreset: PropTypes.object.isRequired,
};


export default compose(withStyles(styles), withTranslation())(PreviewTourCreateDialog);
