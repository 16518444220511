export default theme => ({
  root: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0)
  },
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    border: `1px solid ${theme.palette.border}`
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
  appBar: {
    left: '2px',
    width: 'calc(100% - 2px)',
    zIndex: 'unset'
  },
  refreshButton: {
    marginRight: theme.spacing(1)
  },
  helpWrapper: {
    marginTop: theme.spacing(3),
    float: 'right'
  },
  excelDownload: {
    marginTop: theme.spacing(1),
  }
});
