export default theme => ({
  root: {
    minWidth: '350px'
  },
  portletHeader: {
    height: '40px'
  },
  dispositionTiming: {
    maxWidth: '100%',
  },
  portletContent: {
    maxHeight: '90%',
    overflow: 'auto',
  }
});
