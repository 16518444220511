export default theme => ({
  root: {
    overflow: 'auto'
  },
  tableCell: {
    whiteSpace: 'nowrap'
  },
  idTableCell: {
    display: 'flex',
    alignItems: 'center'
  },
  secondaryColor: {
    color: theme.palette.secondary.main,
    verticalAlign: 'middle'
  },
  aggregated: {
    background: theme.palette.secondary.main,
    color: theme.palette.common.white
  },
  iconMargin: {
    marginRight: '8px'
  },
});

