import moment from 'moment';
import {StopStatus} from '../enums/StopStatus';
import {TourStatus} from '../enums/TourStatus';

function replaceClockTime(date, hours, minutes, seconds) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, seconds)
}

export function stopMessageRelevantForFilter(event, filter) {
  let data = JSON.parse(event.toString());
  let fromDate = replaceClockTime(filter.fromDate, 0, 0, 0);
  let toDate = replaceClockTime(filter.toDate, 23, 59, 59);

  return moment(new Date(data.deliveryDate)).isBetween(fromDate, toDate)
    && (filter.carrierName === '' || data.carrier === filter.carrierName)
    && (filter.microHubName === '' || data.hub === filter.microHubName)
    && (filter.shipperName === '' || data.shipper === filter.shipperName)
    && (filter.stopStatus === '' || data.stopStatus === filter.stopStatus)
}

export function previewStopMessageRelevantForFilter(event, filter) {
  let data = JSON.parse(event.toString())

  return moment(new Date(data.deliveryDate)).isSame(filter.date, 'day')
    && (!filter.carrierName || filter.carrierName === '' || data.carrier === filter.carrierName)
    && (filter.shipperName === '' || data.shipper === filter.shipperName)
    && (filter.microHubList.length === 0 || filter.microHubList.findIndex(x => x === data.hub) >= 0)
}

export function previewTourMessageRelevantForFilter(event, filter) {
  let data = JSON.parse(event.toString())

  return moment(new Date(data.deliveryDate)).isSame(filter.date, 'day')
    && (!filter.carrierName || filter.carrierName === '' || data.carrier === filter.carrierName)
    && (filter.microHubList.length === 0 || filter.microHubList.findIndex(x => x === data.hub) >= 0)
}

export function stopMessageRelevantForTourFilter(event, filter) {
  let data = JSON.parse(event.toString())
  let fromDate = replaceClockTime(filter.fromDateTime, 0, 0, 0);
  let toDate = replaceClockTime(filter.toDateTime, 23, 59, 59);
  return ( // assigned stops
    moment(new Date(data.deliveryDate)).isBetween(fromDate, toDate)
    && (filter.carrierName === '' || data.carrier === filter.carrierName)
    && (filter.microHubName === '' || data.hub === filter.microHubName)
    && (filter.shipperName === '' || data.shipper === filter.shipperName)
  ) || ( // unassigned stops
    moment(new Date(data.deliveryDate)).isBefore(toDate)
    && (data.stopStatus === StopStatus.NotPlaned || data.stopStatus === StopStatus.CanNotDeliver)
  )
}

export function tourMessageRelevantForFilter(event, filter) {
  let data = JSON.parse(event.toString())
  let fromDate = replaceClockTime(filter.fromDateTime, 0, 0, 0);
  let toDate = replaceClockTime(filter.toDateTime, 23, 59, 59);
  return moment(new Date(data.deliveryDate)).isBetween(fromDate, toDate)
    && (filter.carrierName === '' || data.carrier === filter.carrierName)
    && (filter.microHubName === '' || data.hub === filter.microHubName)
    && (filter.shipperName === '' || data.shipper === filter.shipperName)
}

export function messageRelevantForDashboardFilter(event, filter) {
  let data = JSON.parse(event.toString());
  let fromDate = replaceClockTime(filter.fromDate, 0, 0, 0);
  let toDate = replaceClockTime(filter.toDate, 23, 59, 59);
  return moment(new Date(data.deliveryDate)).isBetween(fromDate, toDate)
    && (filter.carrierName === '' || data.carrier === filter.carrierName)
    && (filter.microHubName === '' || data.hub === filter.microHubName)
    && (filter.shipperName === '' || data.shipper === filter.shipperName)
}

export function messageRelevantForMicroHubOverviewFilter(event, filter) {
  let data = JSON.parse(event.toString());
  let fromDate = replaceClockTime(filter.fromDateTime, 0, 0, 0);
  let toDate = replaceClockTime(filter.toDateTime, 23, 59, 59);
  return moment(new Date(data.deliveryDate)).isBetween(fromDate, toDate)
    && (filter.carrierName === '' || data.carrier === filter.carrierName)
    && (filter.microHubName === '' || data.hub === filter.microHubName)
    && (filter.shipperName === '' || data.shipper === filter.shipperName)
    && (filter.tourStatus !== TourStatus.Planed ||  // If tourStatus is set to Planed check if event is stop and is not already delivered or in delivery
      data.stopStatus
        ?   // check if event is stop
        (data.stopStatus === StopStatus.Planed ||
          data.stopStatus === StopStatus.NotPlaned ||
          data.stopStatus === StopStatus.CanNotDeliver)
        :
        true
    )
}


export function messageRelevantForFinalDeadlineMigrationFinalStops(event, filter) {
  let data = JSON.parse(event.toString());
  return moment(new Date(data.deliveryDate)).isSame(filter.date, 'day')
    && (filter.shipperName === '' || data.shipper === filter.shipperName)
}

export function messageRelevantForFinalDeadlineMigrationPreviewStop(event, filter) {
  let data = JSON.parse(event.toString())

  return moment(new Date(data.deliveryDate)).isSame(filter.date, 'day')
    && (!filter.carrierName || filter.carrierName === '' || data.carrier === filter.carrierName)
    && (filter.shipperName === '' || data.shipper === filter.shipperName);
}

export function messageRelevantForFinalDeadlineMigrationPreviewTour(event, filter) {
  let data = JSON.parse(event.toString())

  return moment(new Date(data.deliveryDate)).isSame(filter.date, 'day')
    && (!filter.carrierName || filter.carrierName === '' || data.carrier === filter.carrierName);
}
