// eslint-disable-next-line no-unused-vars
export default theme => ({
  dialog: {},
  warningColor: {
    color: theme.palette.warning.main,
    verticalAlign: 'middle'
  },
  container: {
    marginBottom: theme.spacing(1)
  },
  infoContainer: {
    marginBottom: theme.spacing(1)
  }
});
