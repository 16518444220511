import React, {Fragment} from 'react'
import {Table, TableBody, TableCell, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Portlet, PortletContent, TextPlaceholderWrapper, TourMetadataIcon} from '../../../../../../components'
import {CapacityHelperService} from '../../../../../../services/util/capacityHelper';
import {Droppable} from 'react-beautiful-dnd';
import {DndHelperElementTypes, DndHelperService} from '../../../../../../services/dndHelper/dndHelperService';
import {AcUnitRounded as FreezeIcon, ArrowForward as DropIcon} from '@material-ui/icons'
import palette from '../../../../../../theme/palette';

function TourTableSmallPreview(props) {

  const {classes, className, tours, t, selectTour, selectedTour} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <TextPlaceholderWrapper
      active={!tours?.length}
      text={t('noToursAvailable')}
    >
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <div style={{overflow: 'auto', maxHeight: '300px'}}>
            <Table
              size="small"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeader}>{t('tourId')}</TableCell>
                  <TableCell className={classes.tableHeader}>{t('microHub')}</TableCell>
                  <TableCell className={classes.tableHeader}>{t('numStops')}</TableCell>
                  <TableCell className={classes.tableHeader}>{t('boxAmount')}</TableCell>
                  <TableCell className={classes.tableHeader}>{t('weight')}</TableCell>
                  <TableCell className={classes.tableHeader}>{t('volume')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tours.map(tour => (
                  <Droppable
                    droppableId={DndHelperService.getUniqueTourId('{"isTourTable": true}', tour.id)}
                    isDropDisabled={!tour.editable}
                    key={tour.id}
                    type={DndHelperElementTypes.Tour}
                  >
                    {(provided, snapshot) => (
                      <TableRow
                        className={classNames(classes.tableRow, 'cursorPointer')}
                        hover
                        id={`Tour-${tour.id}`}
                        key={tour.id}
                        onClick={() => selectTour(tour)}
                        ref={provided.innerRef}
                        selected={selectedTour && selectedTour.id === tour.id}
                        style={tour.editable ? {boxShadow: `inset 10px 0px 0px 0px ${tour.color}`} : {
                          backgroundColor: palette.common.muted,
                          boxShadow: `inset 10px 0px 0px 0px ${tour.color}`
                        }}
                        {...provided.droppableProps}
                      >
                        {snapshot.isDraggingOver ?
                          <TableCell
                            className={classes.tableCell}
                            colSpan={7}
                            style={{backgroundColor: tour.color}}
                          >
                            <DropIcon/>
                          </TableCell>
                          :
                          <Fragment>
                            <TableCell className={classes.tableCell}>
                              <TourMetadataIcon metadata={tour.tourMetaData}/>
                              {tour.id}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {tour.microHubName}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {tour.stops && tour.stops.length > 0 ? tour.stops.length : 0}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {CapacityHelperService.getBoxAmountOfTour(tour)}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {CapacityHelperService.getWeightOfTour(tour)}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {CapacityHelperService.getVolumeOfTour(tour)}
                              <FreezeIcon style={{float: 'right'}}/>
                            </TableCell>
                          </Fragment>
                        }
                      </TableRow>
                    )}
                  </Droppable>
                ))}
              </TableBody>
            </Table>
          </div>
        </PortletContent>
      </Portlet>
    </TextPlaceholderWrapper>
  );
}


TourTableSmallPreview.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  selectTour: PropTypes.func,
  selectedTour: PropTypes.object,
  t: PropTypes.func.isRequired,
  tours: PropTypes.array.isRequired
};

TourTableSmallPreview.defaultProps = {
  tours: [],
};


export default compose(withStyles(styles), withTranslation())(TourTableSmallPreview);
