export default theme => ({
  iconButton: {
    position: 'absolute',
    right: '21px'
  },
  button: {
    textTransform: 'none',
    color: theme.palette.text.secondary
  },
  menu: {
    padding: theme.spacing(1)
  }
});

