import React, {Fragment, useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {MapDispositionPreviewPlanning} from './components';
import {LoadingWrapper, PreviewTourCreateDialog, TextPlaceholderWrapper} from '../../index';
import {presetsService} from '../../../services/presets/presetService';
import {AuthService} from '../../../services/auth/authService';
import AddStopsWithoutTourDialog from './components/Planning/AddStopsWithoutToursDialog';

function MapDispositionPreview(props) {
  const {
    classes,
    t,
    backendIsLoading,
    updateTourOrder,
    changeTourOfStop,
    createTour,
    hubsWithToursAndStops,
    microHubs,
    microHubOptions,
    deleteAllTours,
    deleteStopFromTour,
    deleteTour
  } = props;
  const [selectedTour, setSelectedTour] = useState(null);
  const [createTourDialogState, setCreateTourDialogState] = useState({
    open: false,
  });
  const [addStopsToTourDialogState, setAddStopsToTourDialogState] = useState({
    open: false,
    tour: null,
  });


  const handleCreateTourCancel = () => {
    setCreateTourDialogState({...createTourDialogState, open: false});
  };

  const handleCreateTourClose = (newTour, numTimesToCreate) => {
    createTour(newTour, numTimesToCreate);
    setCreateTourDialogState({...createTourDialogState, open: false});
  };

  const handleAddStopsToTourCancel = () => {
    setAddStopsToTourDialogState({...addStopsToTourDialogState, open: false, tour: null})
  };

  const handleAddStopsToTourClose = (stopIds, autoOrderOnAdd) => {
    changeTourOfStop(addStopsToTourDialogState.tour.id, stopIds, autoOrderOnAdd);
    setAddStopsToTourDialogState({...addStopsToTourDialogState, open: false, tour: null})
  };

  useEffect(() => {
    if (selectedTour) {
      const index = hubsWithToursAndStops.findIndex(hub => hub.carrierName === selectedTour.carrierName && hub.microHubName === selectedTour.microHubName);
      if (index >= 0) {
        const tourIndex = hubsWithToursAndStops[index].tours.findIndex(tour => tour.id === selectedTour.id);
        if (tourIndex >= 0) {
          setSelectedTour(hubsWithToursAndStops[index].tours[tourIndex]);
        } else {
          setSelectedTour(null);
        }
      } else {
        setSelectedTour(null);
      }
    }
  }, [hubsWithToursAndStops, selectedTour]);


  return (
    <div className={classes.root}>
      <LoadingWrapper loading={backendIsLoading}>
        <TextPlaceholderWrapper
          active={!hubsWithToursAndStops?.length}
          text={t('noFrozenPreviewDataAvailableMapDispo')}
        >
          <Fragment>
            <MapDispositionPreviewPlanning
              addStopsToTour={tour => setAddStopsToTourDialogState({...addStopsToTourDialogState, open: true, tour: tour})}
              changeTourOfStop={changeTourOfStop}
              createTour={() => setCreateTourDialogState({...createTourDialogState, open: true})}
              deleteAllTours={deleteAllTours}
              deleteStopFromTour={deleteStopFromTour}
              deleteTour={deleteTour}
              microHubs={microHubs}
              selectedTour={selectedTour}
              setSelectedTour={setSelectedTour}
              stopsWithoutTour={hubsWithToursAndStops.map(hub => hub.stopsWithoutTour).flat()}
              tours={hubsWithToursAndStops.map(hub => hub.tours).flat()}
              updateTourOrder={updateTourOrder}
            />
            <PreviewTourCreateDialog
              createTourDialogState={createTourDialogState}
              handleCancel={handleCreateTourCancel}
              handleClose={handleCreateTourClose}
              microHubOptions={microHubOptions}
              tourPreset={presetsService.getPreviewTourPresetWithCarrier(AuthService.getUserOrganization())}
            />
            <AddStopsWithoutTourDialog
              dialogState={addStopsToTourDialogState}
              handleCancel={handleAddStopsToTourCancel}
              handleClose={handleAddStopsToTourClose}
              microHubs={microHubs}
              stopsWithoutTour={hubsWithToursAndStops.map(hub => hub.stopsWithoutTour).flat().filter(stop => stop.editable)}
              tours={hubsWithToursAndStops.map(hub => hub.tours).flat()}
            />
          </Fragment>
        </TextPlaceholderWrapper>
      </LoadingWrapper>
    </div>
  );
}

MapDispositionPreview.propTypes = {
  backendIsLoading: PropTypes.bool.isRequired,
  changeTourOfStop: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  createTour: PropTypes.func.isRequired,
  deleteAllTours: PropTypes.func.isRequired,
  deleteStopFromTour: PropTypes.func.isRequired,
  deleteTour: PropTypes.func.isRequired,
  hubsWithToursAndStops: PropTypes.array.isRequired,
  microHubOptions: PropTypes.array.isRequired,
  microHubs: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  updateTourOrder: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(MapDispositionPreview);
