import {compose} from 'recompose';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';


function SchedulerConfirmationDialog(props){
  const {classes, t, schedulerConfirmationDialogState, handleCancel, handleClose} = props;

  const handleOk = () => {
    handleClose();
  };

  const handleNotOk = () => {
    handleCancel();
  };

  return(
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      maxWidth={'lg'}
      onClose={handleCancel}
      open={schedulerConfirmationDialogState}
    >
      <DialogTitle>{t('schedulingWarningTitle')}</DialogTitle>
      <DialogContent>
        <Typography>
          {t('schedulingWarning')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={handleNotOk}
        >
          {t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          onClick={handleOk}
        >
          {t('dialogOk')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SchedulerConfirmationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  schedulerConfirmationDialogState: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
}

export default compose(withStyles(styles), withTranslation())(SchedulerConfirmationDialog);
