import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core';
import styles from './styles'
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import { DropdownSelectSimple, SearchInput } from 'components';


function TemplateStopFilter(props) {

  const {
    classes,
    className,
    t,
    filter,
    setFilter,
    shipperOptions,
    searchInput,
    setSearchInput,
    useSearch
  } = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <form>
        <div className={classNames(classes.rowWrapper, classes.searchWrapper)}>
          <DropdownSelectSimple
            hideOnEmptyOptions
            name={t('shipper')}
            options={shipperOptions}
            setValue={(value) => setFilter({ ...filter, shipperName: value })}
            sort
            value={filter.shipperName}
          />

          {useSearch &&
            <Fragment>
              <SearchInput
                setValue={(value) => setSearchInput(value)}
                value={searchInput}
                descriptionKey={'1'}
              />
            </Fragment>
          }
        </div>
      </form>
    </div>
  );
}


TemplateStopFilter.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  searchInput: PropTypes.string,
  setFilter: PropTypes.func.isRequired,
  setSearchInput: PropTypes.func,
  shipperOptions: PropTypes.array,
  t: PropTypes.func.isRequired,
  useSearch: PropTypes.bool
};

export default compose(withStyles(styles), withTranslation())(TemplateStopFilter);
