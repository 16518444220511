import React from 'react'
import {TableCell, TableRow, withStyles} from '@material-ui/core';
import styles from '../styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {roundCapacityValues} from '../../../../../services/util/helperFuncs';

function InsertBody(props) {

  const {className, product} = props;

  return (
    <TableRow className={className}>
      <TableCell colSpan={2}>{product.description}</TableCell>
      <TableCell>{roundCapacityValues(product.amount)}</TableCell>
      <TableCell>{roundCapacityValues(product.weight)}</TableCell>
    </TableRow>
  );
}


InsertBody.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default compose(withStyles(styles), withTranslation())(InsertBody);
