import {createTheme} from '@material-ui/core';
import typography from './typography';
import palette from './palette';

const theme = createTheme({
  typography,
  palette,
  breakpoints: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920
  }
});

// Responsive fonts

//styleName: H1
theme.typography.h1 = {
  ...theme.typography.h1,
  lineHeight: '42px',
  fontSize: 28,
  [theme.breakpoints.up('md')]: {
    lineHeight: '69px',
    fontSize: 46,
  }
}

//sylteName: H2
theme.typography.h2 = {
  ...theme.typography.h2,
  lineHeight: '33px',
  fontSize: 22,
  [theme.breakpoints.up('md')]: {
    lineHeight: '45px',
    fontSize: 30,
  }
}


//styleName: H3
theme.typography.h3 = {
  ...theme.typography.h3,
  lineHeight: '24px',
  fontSize: 16,
  [theme.breakpoints.up('md')]: {
    lineHeight: '36px',
    fontSize: 24,
  }
}

//styleName": Paragraph
theme.typography.body1 = {
  ...theme.typography.body1,
  lineHeight: '21px',
  fontSize: 14,
  [theme.breakpoints.up('md')]: {
    lineHeight: '27px',
    fontSize: 18
  }
}

//styleName": Subline
theme.typography.subtitle1 = {
  ...theme.typography.subtitle1,
  lineHeight: '20px',
  fontSize: 13,
  [theme.breakpoints.up('md')]: {
    lineHeight: '26px',
    fontSize: 17,
  }
}

//styleName: Info
theme.typography.subtitle2 = {
  ...theme.typography.subtitle2,
  lineHeight: '18px',
  fontSize: 12,
  [theme.breakpoints.up('md')]: {
    lineHeight: '24px',
    fontSize: 16,
  }
}


export default theme;
