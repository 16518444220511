import React from 'react'
import {Table, TableBody, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Droppable} from 'react-beautiful-dnd';
import TemplateTableRow from './TemplateTableRow';
import Columns from './columns';
import DefaultConfig from './config';
import CapacityModeDefaultConfig from './capacityModeConfig';
import TemplateStopModeConfig from './templateStopModeConfig';
import useTableConfig from 'hooks/useTableConfig';
import {getTableKey} from 'services/util/tableConfigHelper';
import {Portlet, PortletContent, TableCellWithConfig, TableSettings, TextPlaceholderWrapper} from 'components';
import {TableKind} from 'services/enums/TableKind';
import {DndHelperElementTypes, DndHelperService} from 'services/dndHelper/dndHelperService';
import {displayModes} from 'services/enums/displayModes';


function TemplateTable(props) {

  const {
    classes,
    className,
    templates,
    t,
    selectTemplate,
    selectedTemplate,
    selectedDayOfWeek,
    filter,
    capacityMode,
    displayMode,
    templateStopMode
  } = props;

  const {
    config,
    columnConfigs,
    updateConfig
  } = useTableConfig(DefaultConfig, displayMode, getTableKey(TableKind.TemplateTable, displayMode))

  const {
    config: configCapacityMode,
    columnConfigs: columnConfigsCapacityMode,
    updateConfig: updateConfigCapacityMode
  } = useTableConfig(CapacityModeDefaultConfig, displayMode, getTableKey(TableKind.TemplateTable, displayMode, 'capacity_mode'))

  const {
    config: configTemplateStopMode,
    columnConfigs: columnConfigsTemplateStopMode,
    updateConfig: updateConfigTemplateStopMode
  } = useTableConfig(TemplateStopModeConfig, displayMode, getTableKey(TableKind.TemplateTable, displayMode, 'template_stop_mode'))


  const rootClassName = classNames(classes.root, className);
  const uniqueHubId = DndHelperService.getUniqueHubId('tourTable');

  const currentColumnConfigs = templateStopMode ? columnConfigsTemplateStopMode : capacityMode ? columnConfigsCapacityMode : columnConfigs;

  return (
    <TextPlaceholderWrapper
      active={!templates?.length}
      text={t('noTourTemplatesAvailable')}
    >
      <div style={{ position: 'relative' }}>
        <TableSettings
          updateConfig={templateStopMode ? updateConfigTemplateStopMode : capacityMode ? updateConfigCapacityMode : updateConfig}
          tableConfig={templateStopMode ? configTemplateStopMode : capacityMode ? configCapacityMode : config}
          columns={Columns}
          className={classes.tableSettings}
          buttonOptic
          useNamesAsTranslateString
        />
        <Portlet className={rootClassName}>
          <PortletContent noPadding>
            <div style={{ overflow: 'auto', maxHeight: '300px' }}>
              <Table
                size="small"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    {currentColumnConfigs.map(columnConfig => (
                      <TableCellWithConfig
                        key={columnConfig.order}
                        config={columnConfig}
                        className={classes.tableHeader}
                      >
                        {Columns[columnConfig.columnName].header({ t, classes })}
                      </TableCellWithConfig>
                    ))}
                  </TableRow>
                </TableHead>
                <Droppable
                  droppableId={uniqueHubId}
                  type={DndHelperElementTypes.Tour}
                >
                  {(provided) => (
                    <TableBody
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {templates.map((template, index) => (
                        <TemplateTableRow
                          columnConfigs={currentColumnConfigs}
                          filter={filter}
                          index={index}
                          key={template.id}
                          selectedDayOfWeek={selectedDayOfWeek}
                          selectedTemplate={selectedTemplate}
                          selectTemplate={selectTemplate}
                          template={template}
                          uniqueTemplateId={DndHelperService.getUniqueTourId(uniqueHubId, template.id)}
                        />
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </Table>
            </div>
          </PortletContent>
        </Portlet>
      </div>
    </TextPlaceholderWrapper>
  );
}


TemplateTable.propTypes = {
  capacityMode: PropTypes.bool.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  filter: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  selectTemplate: PropTypes.func,
  selectedDayOfWeek: PropTypes.string.isRequired,
  selectedTemplate: PropTypes.object,
  t: PropTypes.func.isRequired,
  templates: PropTypes.array.isRequired,
  templateStopMode: PropTypes.bool
};

TemplateTable.defaultProps = {
  tours: [],
  templateStopMode: false
};


export default compose(withStyles(styles), withTranslation())(TemplateTable);
