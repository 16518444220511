import React, {useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {AuthService} from '../../../services/auth/authService';
import {default as EditVehicle} from '../VehicleEdit';
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import {onCloseWithBackdropCurry} from '../../../services/util/helperFuncs';

function CreateVehicleDialog(props) {

  const [newVehicle, setNewVehicle] = useState({
    capacity: 0,
    carrierName: AuthService.getUserOrganization(),
    description: '',
    licensePlate: '',
    microhubs: [],
    payloadWeight: 0,
    type: '',
    volume: 0,
    averageSpeedKmh: 15.0
  });

  const {classes, t, createVehicleDialogState, microHubOptions, handleClose, handleCancel} = props;

  const resetState = () => {
    setNewVehicle({
      capacity: 0,
      carrierName: AuthService.getUserOrganization(),
      description: '',
      licensePlate: '',
      microhubs: [],
      payloadWeight: 0,
      type: '',
      volume: 0,
      averageSpeedKmh: 15.0
    });
  };

  const handleOk = () => {
    handleClose(newVehicle);
    resetState();
  };

  const handleCancelInner = () => {
    handleCancel();
    resetState();
  };

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={createVehicleDialogState.open}
    >
      <DialogTitle>{t('createVehicle')}</DialogTitle>
      <DialogContent>
        <EditVehicle
          className={classes.container}
          editedVehicle={newVehicle}
          microHubOptions={microHubOptions}
          setEditedVehicle={setNewVehicle}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={handleCancelInner}
        >
          <CancelIcon/>&nbsp;{t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          disabled={!newVehicle.licensePlate || newVehicle.microhubs.length === 0 || !newVehicle.type || newVehicle.licensePlate.length > 15 || !newVehicle.payloadWeight || !newVehicle.volume || !newVehicle.averageSpeedKmh}
          onClick={handleOk}
        >
          <SaveIcon/>&nbsp;{t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CreateVehicleDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  createVehicleDialogState: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  microHubOptions: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(CreateVehicleDialog);
