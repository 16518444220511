import React from 'react'
import { withStyles } from '@material-ui/core';
import styles from './styles'
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CarrierAndMicroHubDropdownSelect, DayOfWeekSelect, DropdownSelectSimple } from '../../index';
import { displayModes } from 'services/enums/displayModes';

function TemplateStopTourTemplatesFilter(props) {

  const { classes, className, t, filter, setFilter, disabled, shipperOptions, carriersWithMicroHubs, microHubs, displayMode } = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <form className={classes.rowWrapper}>
        <DayOfWeekSelect
          disabled={disabled}
          error={filter.dayOfWeek === ''}
          required
          setValue={(value) => setFilter({ ...filter, dayOfWeek: value })}
          value={filter.dayOfWeek}
        />
        <DropdownSelectSimple
          disabled={disabled}
          hideOnEmptyOptions
          name={t('shipper')}
          options={shipperOptions}
          setValue={(value) => setFilter({ ...filter, shipperName: value })}
          sort
          required
          error={filter.shipperName === ''}
          value={filter.shipperName}
        />
        {displayMode === displayModes.admin &&
          <CarrierAndMicroHubDropdownSelect
            carriersWithMicroHubs={carriersWithMicroHubs}
            carrierValue={filter.carrierName}
            className={classes.rowWrapper}
            disabled={disabled}
            microHubValue={filter.microHubName}
            setCarrierValue={(value) => setFilter({ ...filter, carrierName: value, microHubName: '' })}
            setMicroHubValue={(value) => setFilter({ ...filter, microHubName: value })}
          />
        }{displayMode === displayModes.carrier &&
          <DropdownSelectSimple
            hideOnEmptyOptions
            name={t('microHub')}
            options={microHubs}
            setValue={(value) => setFilter({ ...filter, microHubName: value })}
            sort
            value={filter.microHubName}
          />
        }
      </form>
    </div>
  );
}


TemplateStopTourTemplatesFilter.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  filter: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  shipperOptions: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  carriersWithMicroHubs: PropTypes.object,
  microHubs: PropTypes.object,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
};

export default compose(withStyles(styles), withTranslation())(TemplateStopTourTemplatesFilter);
