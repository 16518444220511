import React from 'react'
import {Table, TableBody, TableCell, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import StopTableRow from '../StopTableRow';

function StopTable(props) {

  const {classes, t, stops, className, previewDeadlineElapsed, finalDeadlineElapsed} = props;
  const rootClassName = classNames(className, classes.root);

  return (
    <Table
      className={rootClassName}
      size="small"
    >
      <TableHead>
        <TableRow>
          <TableCell>{t('stopNumber')}</TableCell>
          <TableCell>{t('previewStopID')}</TableCell>
          <TableCell>{t('migrationStatus')}</TableCell>
          <TableCell>{t('lastName')}</TableCell>
          <TableCell>{t('streetAndNumber')}</TableCell>
          <TableCell>{t('customerId')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {stops.map((stop, index) => {
          return (
            <StopTableRow
              finalDeadlineElapsed={finalDeadlineElapsed}
              key={index}
              previewDeadlineElapsed={previewDeadlineElapsed}
              stop={stop}
            />
          );
        })}
      </TableBody>
    </Table>
  );
}

StopTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  finalDeadlineElapsed: PropTypes.bool.isRequired,
  previewDeadlineElapsed: PropTypes.bool.isRequired,
  stops: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(StopTable);
