export default theme => ({
  root: {},
  deleteButton: {
    color: theme.palette.danger.main
  },
  flexBetweenCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dragging: {
    backgroundColor: `${theme.palette.primary.selected} !important`,
  },
});
