export default theme => ({
  root: {},
  primaryIcon: {
    color: theme.palette.primary.main,
  },
  secondaryIcon: {
    color: theme.palette.secondary.main,
  },
  warnIcon: {
    color: theme.palette.warning.main,
  },
  dangerIcon: {
    color: theme.palette.danger.main
  },
  mutedIcon: {
    color: theme.palette.common.muted,
  },
  defaultIcon: {
    color: theme.palette.common.black,
  }
});

