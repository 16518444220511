// eslint-disable-next-line no-unused-vars
export default theme => ({
  dialog: {},
  warningColor: {
    color: theme.palette.warning.main,
    verticalAlign: 'middle'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '200px',
    width: '200px',
  },
  dangerColor: {
    color: theme.palette.danger.main,
    verticalAlign: 'middle'

  },
  alignText: {
    display: 'flex',
    alignItems: 'center'
  },
  warningText: {
    width: '400px',
  }
});
