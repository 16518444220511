import React, {useState} from 'react'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {getProductTypeString, ProductType} from '../../../../services/enums/ProductType';
import {WarningRounded as WarnIcon} from '@material-ui/icons';
import {EmptiesTableEdit, InfoTooltip, ProductTableEdit} from '../../../../components';
import {ShipperAllowedOnHubService} from '../../../../services/util/shipperAllowedOnHubHelper';
import {roundCapacityValues} from '../../../../services/util/helperFuncs';
import {getStopStatusString} from '../../../../services/enums/StopStatus';
import {DateService} from '../../../../services/util/DateService';
import EditStopStatusDialog from '../EditStopStatusDialog';

function StopEditAdmin(props) {

  const {
    classes,
    className,
    editedStop,
    t,
    setEditedStop,
    microHubOptions,
    shipperEmptiesSelectOptions,
    stopEditTableHook,
  } = props;

  const [stopStatusEditDialogOpen, setStopStatusEditDialogOpen] = useState(false);

  const {
    existingProducts,
    existingPackageInserts,
    existingEmpties,
    newProducts,
    newPackageInserts,
    newEmpties,
    addEmpty,
    addProduct,
    addPackageInsert,
    updateProduct,
    updatePackageInsert,
    updateEmpty,
    deleteProduct,
    deletePackageInsert,
    deleteEmpty,
    areProductsValid,
    arePackageInsertsValid,
    areEmptiesValid
  } = stopEditTableHook;

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <form>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <label htmlFor="customerData"><strong>{t('customerData')}</strong></label>
          <div className={classes.rowWrapper}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="firstName">{t('firstName')}</InputLabel>
              <Input
                  id="firstName"
                  onChange={(event) => {
                    setEditedStop({...editedStop, firstName: event.target.value})
                  }}
                  value={editedStop.firstName}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel
                  htmlFor="lastName"
                  required
              >{t('lastName')}</InputLabel>
              <Input
                  error={!editedStop.lastName}
                  id="lastName"
                  onChange={(event) => {
                    setEditedStop({...editedStop, lastName: event.target.value})
                  }}
                  required
                  value={editedStop.lastName}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel
                  htmlFor="streetAndNumber"
                  required
              >{t('streetAndNumber')}</InputLabel>
              <Input
                  error={!editedStop.address.streetAndNumber}
                  id="houseNumber"
                  onChange={(event) => {
                    setEditedStop({
                      ...editedStop, address:
                          {
                            streetAndNumber: event.target.value,
                            zipcode: editedStop.address.zipcode,
                            city: editedStop.address.city,
                            country: editedStop.address.country,
                            longitude: editedStop.address.longitude,
                            latitude: editedStop.address.latitude,
                          }
                    })
                  }}
                  required
                  value={editedStop.address.streetAndNumber}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel
                  htmlFor="zipcode"
                  required
              >{t('zipcode')}</InputLabel>
              <Input
                  error={!editedStop.address.zipcode}
                  id="zipcode"
                  onChange={(event) => {
                    setEditedStop({
                      ...editedStop, address:
                          {
                            streetAndNumber: editedStop.address.streetAndNumber,
                            zipcode: event.target.value,
                            city: editedStop.address.city,
                            country: editedStop.address.country,
                            longitude: editedStop.address.longitude,
                            latitude: editedStop.address.latitude,
                          }
                    })
                  }}
                  required
                  value={editedStop.address.zipcode}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel
                  htmlFor="city"
                  required
              >{t('city')}</InputLabel>
              <Input
                  error={!editedStop.address.city}
                  id="city"
                  onChange={(event) => {
                    setEditedStop({
                      ...editedStop, address:
                          {
                            streetAndNumber: editedStop.address.streetAndNumber,
                            zipcode: editedStop.address.zipcode,
                            city: event.target.value,
                            country: editedStop.address.country,
                            longitude: editedStop.address.longitude,
                            latitude: editedStop.address.latitude,
                          }
                    })
                  }}
                  required
                  value={editedStop.address.city}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel
                  htmlFor="country"
                  required
              >{t('country')}</InputLabel>
              <Input
                  error={!editedStop.address.country}
                  id="country"
                  onChange={(event) => {
                    setEditedStop({
                      ...editedStop, address:
                          {
                            streetAndNumber: editedStop.address.streetAndNumber,
                            zipcode: editedStop.address.zipcode,
                            city: editedStop.address.city,
                            country: event.target.value,
                            longitude: editedStop.address.longitude,
                            latitude: editedStop.address.latitude,
                          }
                    })
                  }}
                  required
                  value={editedStop.address.country}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="customerId">{t('customerId')}</InputLabel>
              <Input
                  id="customerId"
                  onChange={(event) => {
                    setEditedStop({...editedStop, customerId: event.target.value})
                  }}
                  value={editedStop.customerId}
              />
            </FormControl>
          </div>
          <label htmlFor="stopData"><strong>{t('stopData')}</strong></label>
          <div className={classes.rowWrapper}>
            <FormControl className={classes.formControl}>
              <KeyboardDatePicker
                  cancelLabel={t('dialogCancel')}
                  error={!editedStop.planedDeliveryDateShipper}
                  format="dd.MM.yyyy"
                  id="deliveryDateShipper"
                  label={t('planedDeliveryDateShipper')}
                  margin="normal"
                  onChange={(event) => {
                    setEditedStop({...editedStop, planedDeliveryDateShipper: new Date(event)})
                  }}
                  required
                  value={editedStop.planedDeliveryDateShipper}
              />
              <KeyboardDatePicker
                  cancelLabel={t('dialogCancel')}
                  format="dd.MM.yyyy"
                  id="deliveryDateCarrier"
                  label={t('planedDeliveryDateCarrier')}
                  margin="normal"
                  onChange={(event) => {
                    setEditedStop({...editedStop, planedDeliveryDateCarrier: new Date(event)})
                  }}
                  value={editedStop.planedDeliveryDateCarrier}
              />
              <KeyboardTimePicker
                  cancelLabel={t('dialogCancel')}
                  id="deliveryTimeWindowShipperFrom"
                  label={t('deliveryTimeWindowShipperFrom')}
                  marging="normal"
                  ampm={false}
                  onChange={(event) => {
                    setEditedStop({...editedStop, deliveryWindowShipper: {
                        from: new Date(event),
                        to: editedStop.deliveryWindowShipper?.to
                      }
                    })
                  }}
                  value={editedStop.deliveryWindowShipper?.from? editedStop.deliveryWindowShipper.from: null}
              />
              <KeyboardTimePicker
                  cancelLabel={t('dialogCancel')}
                  id="deliveryTimeWindowShipperTo"
                  label={t('deliveryTimeWindowShipperTo')}
                  marging="normal"
                  ampm={false}
                  error={new Date(editedStop.deliveryWindowShipper?.to).getTime() < new Date(editedStop.deliveryWindowShipper?.from).getTime()}
                  onChange={(event) => {
                    setEditedStop({...editedStop, deliveryWindowShipper: {
                        from: editedStop.deliveryWindowShipper?.from,
                        to: new Date(event)
                      }
                    })
                  }}
                  value={editedStop.deliveryWindowShipper?.to? editedStop.deliveryWindowShipper.to: null}
              />
            </FormControl>
            <div>
              <InfoTooltip descriptionKey={'35'}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="amountOfBoxes">{t('amountOfBoxes')}</InputLabel>
                  <Input
                      id="amountOfBoxes"
                      onChange={(event) => {
                        setEditedStop({
                          ...editedStop, stopCapacities: {
                            boxAmount: (parseInt(event.target.value) && parseInt(event.target.value) >= 0) ? parseInt(event.target.value) : 0,
                            weight: editedStop.stopCapacities.weight,
                            volume: editedStop.stopCapacities.volume,
                          }
                        })
                      }}
                      onClick={event => event.target.select()}
                      onWheel={event => event.target.blur()}
                      type="number"
                      value={editedStop.stopCapacities.boxAmount}
                  />
                </FormControl>
              </InfoTooltip>
              <br/>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="weight">{t('weight')}</InputLabel>
                <Input
                    id="weight"
                    onChange={(event) => {
                      setEditedStop({
                        ...editedStop, stopCapacities: {
                          boxAmount: editedStop.stopCapacities.boxAmount,
                          weight: (parseFloat(event.target.value) && parseFloat(event.target.value) >= 0) ? parseFloat(event.target.value) : 0,
                          volume: editedStop.stopCapacities.volume,
                        }
                      })
                    }}
                    onClick={event => event.target.select()}
                    onWheel={event => event.target.blur()}
                    type="number"
                    value={roundCapacityValues(editedStop.stopCapacities.weight)}
                />
              </FormControl>
              <br/>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="volume">{t('volume')}</InputLabel>
                <Input
                    id="volume"
                    onChange={(event) => {
                      setEditedStop({
                        ...editedStop, stopCapacities: {
                          boxAmount: editedStop.stopCapacities.boxAmount,
                          weight: editedStop.stopCapacities.weight,
                          volume: (parseFloat(event.target.value) && parseFloat(event.target.value) >= 0) ? parseFloat(event.target.value) : 0,
                        }
                      })
                    }}
                    onClick={event => event.target.select()}
                    onWheel={event => event.target.blur()}
                    type="number"
                    value={roundCapacityValues(editedStop.stopCapacities.volume)}
                />
              </FormControl>
            </div>
            <FormControl className={classes.formControl}>
              <InputLabel
                  htmlFor="microHub"
              >{t('microHub')}</InputLabel>
              <Select
                  input={<Input id={'microHub'}/>}
                  onChange={(event) => setEditedStop({...editedStop, assignedMicroHubName: event.target.value})}
                  value={editedStop.assignedMicroHubName ?? ''}
              >
                <MenuItem
                    key={'none'}
                    value={''}
                >
                  {t('noMicroHub')}
                </MenuItem>
                {microHubOptions.map((hub, index) => {
                  return (
                      <MenuItem
                          key={index}
                          value={hub.name}
                      >
                        <Typography
                            className={classes.alignText}
                            variant={'body2'}
                        >
                          {!ShipperAllowedOnHubService.checkShipperAllowedStop(hub, editedStop, stop => stop.planedDeliveryDateShipper) &&
                              <Tooltip title={t('shipperNotAllowedStop')}>
                                <WarnIcon className={classes.dangerColor}/>
                              </Tooltip>
                          }&nbsp;{hub.name}
                        </Typography>
                      </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InfoTooltip descriptionKey={'36'}>
                <FormControlLabel
                    control={
                      <Checkbox
                          checked={editedStop.stairMoney}
                          color="secondary"
                          onChange={(event) => setEditedStop({...editedStop, stairMoney: event.target.checked})}
                          value={editedStop.stairMoney}
                      />
                    }
                    label={t('stairMoney')}
                />
              </InfoTooltip>
              <FormControlLabel
                  control={
                    <Checkbox
                        checked={editedStop.key}
                        color="secondary"
                        onChange={(event) => setEditedStop({
                          ...editedStop,
                          key: event.target.checked,
                          keyCode: event.target.checked ? editedStop.keyCode : ''
                        })}
                        value={editedStop.key}
                    />
                  }
                  label={t('key')}
              />
              <FormControl>
                <InputLabel htmlFor="keyCode">{t('keyCode')}</InputLabel>
                <Input
                    disabled={!editedStop.key}
                    id="keyCode"
                    onChange={(event) => {
                      setEditedStop({...editedStop, keyCode: event.target.value})
                    }}
                    value={editedStop.keyCode ?? ''}
                />
              </FormControl>
            </FormControl>
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="contactFirstName">{t('contactName')}</InputLabel>
                <Input
                    id="contactFirstName"
                    onChange={(event) => {
                      setEditedStop({
                        ...editedStop, contact: {
                          name: event.target.value,
                          phoneNumber: editedStop.contact.phoneNumber,
                        }
                      })
                    }}
                    value={editedStop.contact.name}
                />
              </FormControl>
              <br/>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="contactPhoneNumber">{t('contactPhoneNumber')}</InputLabel>
                <Input
                    id="contactPhoneNumber"
                    onChange={(event) => {
                      setEditedStop({
                        ...editedStop, contact: {
                          name: editedStop.contact.name,
                          phoneNumber: event.target.value,
                        }
                      })
                    }}
                    value={editedStop.contact.phoneNumber}
                />
              </FormControl>
            </div>
          </div>
          <div className={classes.rowWrapper}>
            <div>
              <Typography><strong>{t('stopStatus')}</strong>: {t(getStopStatusString(editedStop.stopStatus))}
              </Typography>
              <Typography><strong>{t('deliveryTime')}</strong>: {DateService.displayDateTime(editedStop.deliverTime)}
              </Typography>
              <Button
                  color="secondary"
                  onClick={() => setStopStatusEditDialogOpen(true)}
                  variant="contained"
              >
                {t('editStopStatus')}
              </Button>
            </div>
          </div>
          <div className={classes.rowWrapper}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="ageVerificationMinimumAge">{t('ageVerificationMinimumAge')}</InputLabel>
              <Input
                  id="ageVerificationMinimumAge"
                  onChange={(event) => {
                      setEditedStop({
                          ...editedStop, ageVerificationMinimumAge: (parseInt(event.target.value) && parseInt(event.target.value) >= 0) ? parseInt(event.target.value) : 0
                      })
                  }}
                  onClick={event => event.target.select()}
                  onWheel={event => event.target.blur()}
                  type="number"
                  value={editedStop.ageVerificationMinimumAge}
              />
            </FormControl>
          </div>
          <div className={classes.rowWrapper}>
            <FormControl className={classes.formControl}>
              <label htmlFor="customerInfo"><strong>{t('customerInfo')}</strong></label>
              <textarea
                  className={classes.textarea}
                  cols={'70'}
                  id="customerInfo"
                  onChange={(event) => setEditedStop({...editedStop, customerInfo: event.target.value})}
                  rows={'7'}
                  value={editedStop.customerInfo ? editedStop.customerInfo : ''}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <label htmlFor="driverInfo"><strong>{t('driverInfo')}</strong></label>
              <textarea
                  className={classes.textarea}
                  cols={'70'}
                  id="driverInfo"
                  onChange={(event) => setEditedStop({...editedStop, driverInfo: event.target.value})}
                  rows={'7'}
                  value={editedStop.driverInfo ? editedStop.driverInfo : ''}
              />
            </FormControl>
          </div>
          <div>
            <strong>{`${t(getProductTypeString(ProductType.Box))} / ${t(getProductTypeString(ProductType.BeverageBox))} / ${t(getProductTypeString(ProductType.Other))}`}</strong>:
          </div>
          <div className={classes.rowWrapper}>
            <ProductTableEdit
                addItem={addProduct}
                className={classes.stopDetail}
                deleteProduct={deleteProduct}
                otherProductsValid={areProductsValid()}
                products={existingProducts}
                productsToAdd={newProducts}
                productType={ProductType.Box}
                updateItem={updateProduct}
            />
          </div>
          <div><strong>{t(getProductTypeString(ProductType.PackageInserts))}</strong>:</div>
          <div className={classes.rowWrapper}>
            <ProductTableEdit
                addItem={addPackageInsert}
                className={classes.stopDetail}
                deleteProduct={deletePackageInsert}
                otherProductsValid={arePackageInsertsValid()}
                products={existingPackageInserts}
                productsToAdd={newPackageInserts}
                productType={ProductType.PackageInserts}
                updateItem={updatePackageInsert}
            />
          </div>
          <div><strong>{t('empties')}</strong>:</div>
          <div className={classes.rowWrapper}>
            <EmptiesTableEdit
                addItem={addEmpty}
                className={classes.stopDetail}
                deleteItem={deleteEmpty}
                empties={existingEmpties}
                itemsToAdd={newEmpties}
                otherEmptiesValid={areEmptiesValid()}
                shipperEmptiesSelectOptions={shipperEmptiesSelectOptions}
                updateItem={updateEmpty}
            />
          </div>
        </MuiPickersUtilsProvider>
      </form>
      <EditStopStatusDialog
          dialogState={stopStatusEditDialogOpen}
          editedStop={editedStop}
          handleCancel={() => setStopStatusEditDialogOpen(false)}
          handleClose={() => setStopStatusEditDialogOpen(false)}
          setEditedStop={setEditedStop}
      />
    </div>
  );
}


StopEditAdmin.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  editedStop: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  microHubOptions: PropTypes.array.isRequired,
  setEditedStop: PropTypes.func.isRequired,
  shipperEmptiesSelectOptions: PropTypes.array.isRequired,
  stopEditTableHook: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(StopEditAdmin);
