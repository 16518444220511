export default theme => ({
  root: {
    minWidth: '350px'
  },
  portletHeader: {
    height: '40px'
  },
  portletContentStyle: {
    maxHeight: '90%',
    overflow: 'auto'
  },
  contentContainer: {
    maxHeight: '100%',
    overflow: 'auto',
  },
  listAvatarError: {
    color: theme.palette.danger.main,
    backgroundColor: theme.palette.danger.light,
  },
});
