import React, {Fragment, useState} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {PortletContent, PortletHeader} from '../../../index';
import {Close as CloseIcon, Edit as EditIcon, Payment as DonationButtonIcon} from '@material-ui/icons';
import {AuthService} from '../../../../services/auth/authService';
import {userPermissions} from '../../../../services/auth/userPermissions';
import DonationButtonDialog from '../DonationButtonDialog';

function DriverDetailsShow(props) {

  const {classes, className, driver, edit, t, displayDriverTypes, readonly, closeDetail, updateDonationButton} = props;

  const rootClassName = classNames(classes.root, className);

  const [donationButtonDialogOpen, setDonationButtonDialogOpen] = useState(false);

  const update = (donationButton) => {
    updateDonationButton(driver.id, donationButton);
    setDonationButtonDialogOpen(false);
  }

  return (
    <Fragment>
      <PortletHeader className={rootClassName}>
        <Fragment>
          <div><strong>{driver.firstname} {driver.lastname}</strong></div>
          <div>
            {AuthService.getUserPermission() !== userPermissions.user &&
            <Fragment>
              {updateDonationButton &&
              <Button
                className={classes.button}
                color="secondary"
                onClick={() => setDonationButtonDialogOpen(true)}
                title={t('updateDonationButton')}
                variant="contained"
              >
                <DonationButtonIcon/>
              </Button>
              }
              <Button
                className={classes.button}
                color="default"
                onClick={closeDetail}
                title={t('close')}
                variant="contained"
              >
                <CloseIcon/>
              </Button>
              {!readonly &&
              < Button
                className={classes.button}
                color="primary"
                onClick={edit}
                title={t('driverEdit')}
                variant="contained"
              >
                <EditIcon/>
              </Button>
              }
            </Fragment>
            }
          </div>
        </Fragment>
      </PortletHeader>
      <PortletContent noPadding>
        <div className={classes.wrapper}>
          <div className={classes.wrapperChild}>
            <div className={classes.driverDetail}><strong>{t('driverDisplayName')}</strong>: {driver.displayName}</div>
            <div className={classes.driverDetail}><strong>{t('driverFirstName')}</strong>: {driver.firstname}</div>
            <div className={classes.driverDetail}><strong>{t('driverLastName')}</strong>: {driver.lastname}</div>
            <div className={classes.driverDetail}><strong>{t('driverPhoneNumber')}</strong>: {driver.phoneNumber}</div>
            <div className={classes.driverDetail}><strong>{t('driverEmail')}</strong>: {driver.email}</div>
            <div className={classes.driverDetail}><strong>{t('driverType')}</strong>: {displayDriverTypes(driver.bicycle, driver.car, driver.truck)}</div>
          </div>
          <div className={classes.wrapperChild}>
            <div className={classes.driverDetail}><strong>{t('carrier')}</strong>: {driver.carrierName} </div>
            <div className={classes.driverDetail}><strong>{t('microHubs')}</strong>:</div>
            {driver.microhubs.map((hub, index) => {
              return (
                <div
                  className={classes.driverDetail}
                  key={index}
                >{hub.name}
                </div>
              );
            })}
          </div>
          <div className={classes.wrapperChild}>
            <div className={classes.wrapperChild}>
              <div className={classNames(classes.driverDetail, classes.donationButton)}><strong>{t('donationButton')}</strong>:&nbsp;
                {(driver.donationButton && driver.donationButtonConfirmed)
                  ?
                  // eslint-disable-next-line react/no-danger
                  (<span dangerouslySetInnerHTML={{__html: driver.donationButton}}/>)
                  :
                  t('donationButtonNotApproved')
                }</div>
            </div>
          </div>
        </div>
      </PortletContent>
      <DonationButtonDialog
        donationButton={driver.donationButton}
        handleCancel={() => setDonationButtonDialogOpen(false)}
        handleClose={update}
        open={donationButtonDialogOpen}
      />
    </Fragment>
  );
}


DriverDetailsShow.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  closeDetail: PropTypes.func.isRequired,
  displayDriverTypes: PropTypes.func.isRequired,
  driver: PropTypes.object.isRequired,
  edit: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
  t: PropTypes.func.isRequired,
  updateDonationButton: PropTypes.func,
};

export default compose(withStyles(styles), withTranslation())(DriverDetailsShow);
