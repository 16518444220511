import React, {Fragment, useState} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {Button, ButtonGroup, Grid, IconButton, withStyles} from '@material-ui/core';
import styles from './styles';
import {
  LoadingWrapper,
  Portlet,
  PortletContent,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
} from '../../../components';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import {PopoutDialog, StopAndTourMap, StopDoughnut, TourDoughnut} from './components'
import {displayModes} from '../../../services/enums/displayModes';
import {Fullscreen} from '@material-ui/icons';


function StopAndTourCard(props) {

  const {t, classes, className, isLoading, stops, tours, dataMicroHubs, displayMode} = props;

  const [popoutDialogState, setPopoutDialogState] = useState({open: false});
  const rootClassName = classNames(classes.root, className);
  const [displayTours, setDisplayTours] = useState(false);

  return (
    <Fragment>
      <Portlet
        className={rootClassName}
      >
        <PortletHeader className={classes.portletHeader}>
          <PortletLabel title={displayMode !== displayModes.shipper ? t('dashboardStopAndTourCard') : t('stopOverview')}/>
          <PortletToolbar>
            {displayMode !== displayModes.shipper &&
            <ButtonGroup
              color="secondary"
              size={'small'}
            >
              <Button
                onClick={() => setDisplayTours(false)}
                variant={displayTours ? '' : 'contained'}
              >{t('stops')}</Button>
              <Button
                onClick={() => setDisplayTours(true)}
                variant={displayTours ? 'contained' : ''}
              >{t('tours')}</Button>
            </ButtonGroup>
            }
            <IconButton
              onClick={() => setPopoutDialogState({...popoutDialogState, open: true})}
              title={t('mapFullscreen')}
            >
              <Fullscreen/>
            </IconButton>
          </PortletToolbar>
        </PortletHeader>
        <PortletContent noPadding>
          <LoadingWrapper loading={isLoading}>
            <Grid
              container
              spacing={0}
            >
              <Grid
                item
                lg={5}
                sm={5}
                xl={5}
                xs={5}
              >
                {displayTours
                  ?
                  <TourDoughnut
                    className={classes.doughnut}
                    tours={tours}
                  />
                  :
                  <StopDoughnut
                    className={classes.doughnut}
                    stops={stops}
                  />
                }
              </Grid>
              <Grid
                item
                lg={7}
                sm={7}
                xl={7}
                xs={7}
              >
                <StopAndTourMap
                  className={classes.tourMap}
                  dataMicroHubs={dataMicroHubs}
                  displayTours={displayTours}
                  stops={stops}
                  tours={tours}
                />
              </Grid>
            </Grid>
          </LoadingWrapper>
        </PortletContent>
      </Portlet>
      <PopoutDialog
        dataMicroHubs={dataMicroHubs}
        dialogState={popoutDialogState}
        displayTours={displayTours}
        handleClose={() => setPopoutDialogState({...popoutDialogState, open: false})}
        stops={stops}
        tours={tours}
      />
    </Fragment>
  );
}

StopAndTourCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  dataMicroHubs: PropTypes.array.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  isLoading: PropTypes.bool.isRequired,
  stops: PropTypes.array.isRequired,
  tours: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(StopAndTourCard);
