import React, {Fragment} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {MicroHubSensorConfigEdit, PortletContent, PortletHeader} from '../../../index';
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';

function MicroHubSensorConfigDetailEdit(props) {

  const {classes, className, editedMicroHubSensorConfig, setEditMode, t, updateMicroHubSensorConfig, setEditedMicroHubSensorConfig, microHubNames} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <Fragment>
      <PortletHeader className={rootClassName}>
        <Fragment>
          <div>
            <Button
              className={classes.button}
              color="default"
              onClick={() => setEditMode(false)}
              title={t('cancel')}
              variant="contained"
            >
              <CancelIcon/>&nbsp;{t('cancel')}
            </Button>
            <Button
              className={classes.button}
              color="primary"
              disabled={
                 editedMicroHubSensorConfig.sensorId === 0 || isNaN(editedMicroHubSensorConfig.sensorId) 
                 || editedMicroHubSensorConfig.microHubName === ''
              }
              onClick={updateMicroHubSensorConfig}
              title={t('MicroHubSensorConfigSave')}
              variant="contained"
            >
              <SaveIcon/>
            </Button>
          </div>
        </Fragment>
      </PortletHeader>
      <PortletContent noPadding>
        <MicroHubSensorConfigEdit
          className={classes.wrapper}
          editedMicroHubSensorConfig={editedMicroHubSensorConfig}
          setEditedMicroHubSensorConfig={setEditedMicroHubSensorConfig}
          microHubNames={microHubNames}
        />
      </PortletContent>
    </Fragment>
  );
}


MicroHubSensorConfigDetailEdit.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  editedMicroHubSensorConfig: PropTypes.object.isRequired,
  setEditMode: PropTypes.func.isRequired,
  setEditedMicroHubSensorConfig: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateMicroHubSensorConfig: PropTypes.func.isRequired,
  microHubNames: PropTypes.array.isRequired
};

export default compose(withStyles(styles), withTranslation())(MicroHubSensorConfigDetailEdit);
