import React from 'react'
import {FormControl, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {InfoTooltip} from '../../index';


function DateRangeFilter(props) {

  const {classes, className, t, fromValue, toValue, setValue, fromDescriptionKey, toDescriptionKey, fromRequired, toRequired, fromError, toError} = props;

  const handleFromChange = (value) => {
    if (value > toValue) {
      setValue(value, new Date(value.getFullYear(), value.getMonth(), value.getDate(), 23, 59, 59));
    } else {
      setValue(value, toValue);
    }
  }

  const handleToChange = (value) => {
    if (value < fromValue) {
      setValue(new Date(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0), value);
    } else {
      setValue(fromValue, value);
    }
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={className}>
        <InfoTooltip descriptionKey={fromDescriptionKey}>
          <FormControl className={classes.formControl}>
            <KeyboardDatePicker
              cancelLabel={t('dialogCancel')}
              error={fromError}
              format="dd.MM.yyyy"
              id="deliveryDateFrom"
              label={t('dateFrom')}
              margin="normal"
              onChange={(event) => handleFromChange(new Date(event))}
              required={fromRequired}
              value={fromValue}
            />
          </FormControl>
        </InfoTooltip>
        <InfoTooltip descriptionKey={toDescriptionKey}>
          <FormControl className={classes.formControl}>
            <KeyboardDatePicker
              cancelLabel={t('dialogCancel')}
              error={toError}
              format="dd.MM.yyyy"
              id="deliveryDateTo"
              label={t('dateTo')}
              margin="normal"
              onChange={(event) => handleToChange(new Date(event))}
              required={toRequired}
              value={toValue}
            />
          </FormControl>
        </InfoTooltip>
      </div>
    </MuiPickersUtilsProvider>
  );
}

DateRangeFilter.defaultProps = {
  fromDescriptionKey: '',
  toDescriptionKey: '',
  fromError: false,
  toError: false,
  fromRequired: false,
  toRequired: false,
};


DateRangeFilter.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  fromDescriptionKey: PropTypes.string,
  fromError: PropTypes.bool,
  fromRequired: PropTypes.bool,
  fromValue: PropTypes.instanceOf(Date).isRequired,
  setValue: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  toDescriptionKey: PropTypes.string,
  toError: PropTypes.bool,
  toRequired: PropTypes.bool,
  toValue: PropTypes.instanceOf(Date).isRequired,
};

export default compose(withStyles(styles), withTranslation())(DateRangeFilter);
