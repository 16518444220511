import React, {useState} from 'react'
import {Box, Container, ThemeProvider, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import theme from './theme';
import Content from './Content';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import Unsubscribe from './Unsubscribe';

function TrackingLink(props) {

  const {classes} = props;

  const [myTheme, setMyTheme] = useState(theme);

  const updateTheme = (primary, primaryText, primaryContrastText, secondary, secondaryText) => {
    setMyTheme(myTheme => ({
      ...myTheme, palette: {
        ...myTheme.palette,
        primary: {
          ...myTheme.palette.primary,
          main: primary,
          contrastText: primaryContrastText,
        },
        secondary: {
          ...myTheme.palette.secondary,
          main: secondary,
        },
        text: {
          ...myTheme.palette.text,
          primary: primaryText,
          secondary: secondaryText,
        }
      }
    }))
  }

  let {path} = useRouteMatch();

  return (
    <ThemeProvider theme={myTheme}>
      <div className={classes.body}>
        <Container
          className={classes.container}
          disableGutters
          maxWidth={'lg'}
        >
          <Box
            boxShadow={2}
            className={classes.box}
          >
            <Switch>
              <Route
                exact
                path={`${path}/:guid`}
                render={(props) => (
                  <Content
                    {...props}
                    updateTheme={updateTheme}
                  />
                )}
              />
              <Route
                exact
                path={`${path}/:guid/unsubscribe`}
                render={(props) => (
                  <Unsubscribe
                    {...props}
                    updateTheme={updateTheme}
                  />
                )}
              />
            </Switch>
          </Box>
        </Container>
      </div>
    </ThemeProvider>
  );
}

TrackingLink.propTypes = {
  classes: PropTypes.object,
};


export default withStyles(styles)(TrackingLink);
