export default () => ({
  root: {},
  button: {},
  headerLine: {
    display: 'flex',
    alignItems: 'center'
  },
  formControl: {
    width: '300px'
  }
});
