export default theme => ({
  root: {
    overflow: 'auto'
  },
  tableButton: {
    padding: 0,
  },
  deleteButton: {
    color: theme.palette.danger.main
  },
});

