export default theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(3),
    border: `1px solid ${theme.palette.border}`,
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    margin: 0,
  },
  dndContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    width: 'auto',
  },
  draggableItem: {
    width: '100%'
  },
  chip: {
    margin: 2,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  iconMargin: {
    marginLeft: '8px'
  },
  deleteButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.danger.main,
    '&:hover': {
      backgroundColor: theme.palette.danger.dark,
      color: theme.palette.common.white,
    }
  },
  iconButton: {
    display: 'flex',
  }
});

