import {compose} from 'recompose';
import {Tooltip, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import Grid from '@material-ui/core/Grid';

function InvoiceContent(props){
  const {t, classes, aggregatedData} = props;

  return(
    <Grid
      container
    >
      <Grid
        item
        xs={4}
      >
        <div className={classes.aggregatedInformation}>
          <strong>{t('amountStops')}</strong>: {aggregatedData.stops}
        </div>
      </Grid>
      <Grid
        item
        xs={4}
      >
        <div className={classes.aggregatedInformation}>
          <strong>{t('boxes')}</strong>: {aggregatedData.boxes}
        </div>
      </Grid>
      <Grid
        item
        xs={4}
      >
        <div className={classes.aggregatedInformation}>
          <strong>{t('invoiceWaterBox')}</strong>: {aggregatedData.waterBoxes}
        </div>
      </Grid>
      <Grid
        item
        xs={4}
      >
        <Tooltip title={t('stairMoneyTooltip')}>
          <div className={classes.aggregatedInformation}>
            <strong>{t('stairMoney')}</strong>: {aggregatedData.stairMoney}
          </div>
        </Tooltip>
      </Grid>
      <Grid
        item
        xs={4}
      >
        <Tooltip title={t('bonusBoxTooltip')}>
          <div className={classes.aggregatedInformation}>
            <strong>{t('bonusBox')}</strong>: {aggregatedData.bonus}
          </div>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

InvoiceContent.propTypes = {
  aggregatedData: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}


export default compose(withStyles(styles), withTranslation())(InvoiceContent);

