import React, {useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import AppVersionCard from '../AppVersionCard';
import AppVersionCardEdit from '../AppVersionCardEdit';

function AppVersionView(props) {

  const {classes, t, appVersion, saveAppVersion} = props;

  const [editMinVersion, setEditMinVersion] = useState(false);
  const [editCurrentVersion, setEditCurrentVersion] = useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.versionContainer}>
        {
          !editMinVersion ?
            <AppVersionCard
              appVersion={{
                major: appVersion.allowedVersions.minMajorVersion,
                apk: appVersion.allowedVersions.minApkVersion
              }}
              onEditClick={() => setEditMinVersion(true)}
              title={t('minAppVersion')}
            />
            :
            <AppVersionCardEdit
              appVersion={{
                major: appVersion.allowedVersions.minMajorVersion,
                apk: appVersion.allowedVersions.minApkVersion
              }}
              onCancelClick={() => setEditMinVersion(false)}
              onSaveClick={(version) => {
                setEditMinVersion(false)
                saveAppVersion({
                  minMajorVersion: parseInt(version.major),
                  minApkVersion: parseInt(version.apk)
                }, appVersion.currentVersion)
              }}
              title={t('minAppVersion')}
            />
        }
        {
          !editCurrentVersion ?
            <AppVersionCard
              appVersion={{
                major: appVersion.currentVersion.majorVersion,
                apk: appVersion.currentVersion.apkVersion,
                codePush: appVersion.currentVersion.codePushVersion
              }}
              onEditClick={() => setEditCurrentVersion(true)}
              title={t('currentAppVersion')}
            />
            :
            <AppVersionCardEdit
              appVersion={{
                major: appVersion.currentVersion.majorVersion,
                apk: appVersion.currentVersion.apkVersion,
                codePush: appVersion.currentVersion.codePushVersion
              }}
              onCancelClick={() => setEditCurrentVersion(false)}
              onSaveClick={(version) => {
                setEditCurrentVersion(false)
                saveAppVersion(appVersion.allowedVersions, {
                  majorVersion: parseInt(version.major),
                  apkVersion: parseInt(version.apk),
                  codePushVersion: parseInt(version.codePush)
                })
              }}
              title={t('currentAppVersion')}
            />
        }
      </div>
    </div>
  );
}

AppVersionView.propTypes = {
  appVersion: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  saveAppVersion: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(AppVersionView);
