import React, {Fragment} from 'react';
import {Button} from '@material-ui/core';
import {DeleteForever as DeleteIcon,} from '@material-ui/icons';
import classNames from 'classnames';
import {ShipperLabel} from 'components';
import {displayDeliveryWindow} from 'services/util/helperFuncs';
import {getTemplateStopTypeString} from '../../../services/enums/TemplateStopType';

const Columns = {
  templateStopInstanceId: {
    name: 'templateStopInstanceId',
    header: ({ t }) => t('templateStopInstanceId'),
    body: ({ templateStopInstance }) => templateStopInstance?.id
  },
  shipper: {
    name: 'shipper',
    header: ({ t }) => t('shipper'),
    body: ({ templateStopInstance }) => <ShipperLabel stop={templateStopInstance} />
  },
  tourId: {
    name: 'tourId',
    header: ({ t }) => t('tourId'),
    body: ({ templateStopInstance }) => templateStopInstance?.tourId
  },
  carrier: {
    name: 'carrier',
    header: ({ t }) => t('carrier'),
    body: ({ templateStopInstance }) => templateStopInstance?.carrierName
  },
  microHub: {
    name: 'microHub',
    header: ({ t }) => t('microHub'),
    body: ({ templateStopInstance }) => templateStopInstance?.microHubName
  },
  deliveryDate: {
    name: 'deliveryDate',
    header: ({ t }) => t('deliveryDate'),
    body: ({ templateStopInstance }) => templateStopInstance?.deliveryDate
  },
  stopType: {
    name: 'stopType',
    header: ({ t }) => t('stopType'),
    body: ({templateStopInstance, t}) => t(getTemplateStopTypeString(templateStopInstance.stopType))
  },
  name: {
    name: 'name',
    header: ({ t }) => t('name'),
    body: ({ templateStopInstance }) => templateStopInstance?.name
  },
  streetAndNumber: {
    name: 'streetAndNumber',
    header: ({ t }) => t('streetAndNumber'),
    body: ({ templateStopInstance }) => templateStopInstance?.address.streetAndNumber
  },
  zipcode: {
    name: 'zipcode',
    header: ({ t }) => t('zipcode'),
    body: ({ templateStopInstance }) => templateStopInstance?.address.zipcode
  },
  deliveryWindow: {
    name: 'deliveryWindow',
    header: ({ t }) => t('deliveryWindow'),
    body: ({ templateStopInstance }) => displayDeliveryWindow(templateStopInstance?.deliveryWindow)
  },
  options: {
    name: 'options',
    header: ({ t }) => t('options'),
    body: ({
      templateStopInstance,
      classes,
      t,
      deleteTemplateStopInstance,
      setDeleteTemplateStopInstanceDialogState,
      deleteTemplateStopInstanceDialogState
    }) => <Fragment>
        {deleteTemplateStopInstance &&
          <Button
            className={classNames(classes.tableButton, classes.deleteButton)}
            onClick={(event) => {
              event.stopPropagation();
              setDeleteTemplateStopInstanceDialogState({ ...deleteTemplateStopInstanceDialogState, open: true, templateStopInstance: templateStopInstance })
            }}
            title={t('stopDelete')}
          >
            <DeleteIcon />
          </Button>
        }
      </Fragment>
  }
}

export default Columns;