import React, {Fragment, useEffect, useState} from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {Cancel as CancelIcon, Info as InformationIcon, Save as SaveIcon, WarningRounded as WarnIcon} from '@material-ui/icons';
import {ShipperAllowedOnHubService} from '../../../../../services/util/shipperAllowedOnHubHelper';
import {onCloseWithBackdropCurry} from '../../../../../services/util/helperFuncs';

function ChangeAssignmentDialog(props) {

  const {classes, t, dialogState, handleCancel, handleClose, carriersWithHubs, microHubsData} = props;
  const [selectedMicroHub, setSelectedMicroHub] = useState('');
  const [selectedCarrier, setSelectedCarrier] = useState('');

  const carrierOptions = [];
  const microHubOptions = [];

  if (carriersWithHubs) {
    Object.keys(carriersWithHubs).forEach(carrier => {
      carrierOptions.push(carrier);
      if (!selectedCarrier || carrier === selectedCarrier) {
        carriersWithHubs[carrier].forEach(hub => {
          if (!microHubOptions.includes(hub)) {
            microHubOptions.push(hub);
          }
        })
      }
    });
  }

  useEffect(() => {
    if (dialogState.stop) {
      setSelectedCarrier(dialogState.stop.assignedCarrierName ? dialogState.stop.assignedCarrierName : '');
      setSelectedMicroHub(dialogState.stop.assignedMicroHubName ? dialogState.stop.assignedMicroHubName : '');
    }
  }, [dialogState]);

  const handleOk = () => {
    handleClose(dialogState.stop.id, selectedCarrier, selectedMicroHub);
    setSelectedCarrier('');
    setSelectedMicroHub('');
  };

  const handleNotOk = () => {
    handleCancel();
    setSelectedCarrier('');
    setSelectedMicroHub('');
  };

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      maxWidth={'md'}
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={dialogState.open}
    >
      <DialogTitle>{t('changeStopAssignment')}</DialogTitle>
      <DialogContent>
        {dialogState.stop &&
        <Fragment>
          <InformationIcon/>
          <Typography>
            {t('changePreviewStopAssignmentInfo1')}
          </Typography>
          <Typography>
            {t('changePreviewStopAssignmentInfo2')}
          </Typography>
          {carrierOptions && carrierOptions.length > 0 &&
          <FormControl className={classes.formControl}>
            <InputLabel
              id="carrier"
              required
            >
              {t('carrier')}
            </InputLabel>
            <Select
              error={!selectedCarrier}
              input={<Input id="carrier"/>}
              onChange={(event) => {
                setSelectedCarrier(event.target.value);
                setSelectedMicroHub('');
              }}
              value={selectedCarrier}
            >
              <MenuItem
                key={-1}
                value={''}
              >
                {'-'}
              </MenuItem>
              {carrierOptions.map((carrier, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={carrier}
                  >
                    {carrier}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          }
          {microHubOptions && microHubOptions.length > 0 &&
          <FormControl className={classes.formControl}>
            <InputLabel
              id="microhub"
              required
            >
              {t('microHub')}
            </InputLabel>
            <Select
              error={!selectedMicroHub}
              input={<Input id="microhub"/>}
              onChange={(event) => {
                setSelectedMicroHub(event.target.value)
              }}
              value={selectedMicroHub}
            >
              <MenuItem
                key={-1}
                value={''}
              >
                {'-'}
              </MenuItem>
              {microHubOptions.map((hub, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={hub}
                  >
                    <Typography
                      className={classes.alignText}
                      variant={'body2'}
                    >
                      {!ShipperAllowedOnHubService.checkShipperAllowedManual(microHubsData.find(h => h.name === hub), selectedCarrier, new Date(dialogState.stop.deliveryDate), dialogState.stop.shipperName) &&
                      <Tooltip title={t('shipperNotAllowedStop')}>
                        <WarnIcon className={classes.dangerColor}/>
                      </Tooltip>
                      }&nbsp;{hub}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          }
          {!ShipperAllowedOnHubService.checkShipperAllowedManual(microHubsData.find(hub => hub.name === selectedMicroHub), selectedCarrier, new Date(dialogState.stop.deliveryDate), dialogState.stop.shipperName) &&
          <Typography className={classes.warningText}>
            <WarnIcon className={classes.dangerColor}/>
            {t('shipperNotAllowedDialog')}
          </Typography>
          }
        </Fragment>
        }
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={handleNotOk}
        >
          <CancelIcon/>&nbsp;{t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          disabled={!selectedCarrier || !selectedMicroHub || (selectedCarrier === dialogState.stop.assignedCarrierName && selectedMicroHub === dialogState.stop.assignedMicroHubName)}
          onClick={handleOk}
        >
          <SaveIcon/>&nbsp;{t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ChangeAssignmentDialog.propTypes = {
  carriersWithHubs: PropTypes.object,
  classes: PropTypes.object.isRequired,
  dialogState: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  microHubsData: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(ChangeAssignmentDialog);
