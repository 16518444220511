import React from 'react'
import {IconButton, TableCell, TableRow, withStyles} from '@material-ui/core';
import styles from '../styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {DeleteForever as DeleteRowIcon} from '@material-ui/icons';
import {getProductTypeString} from '../../../../../services/enums/ProductType';
import {roundCapacityValues} from '../../../../../services/util/helperFuncs';

function ProductBodyEdit(props) {

  const {classes, t, product, deleteProduct} = props;

  return (
    <TableRow className={classes.addRow}>
      <TableCell>{t(getProductTypeString(product.productType))}</TableCell>
      <TableCell>{product.boxType}</TableCell>
      <TableCell>{product.boxId}</TableCell>
      <TableCell>{roundCapacityValues(product.amount)}</TableCell>
      <TableCell>{roundCapacityValues(product.volume)}</TableCell>
      <TableCell>{roundCapacityValues(product.weight)}</TableCell>
      <TableCell>{product.description}</TableCell>
      <TableCell>
        <IconButton
          className={classes.buttonRight}
          color="primary"
          onClick={() => deleteProduct(product)}
          title={t('delete')}
        >
          <DeleteRowIcon className={classes.redIcon}/>
        </IconButton>
      </TableCell>
    </TableRow>
  );
}


ProductBodyEdit.propTypes = {
  canExpand: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deleteProduct: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default compose(withStyles(styles), withTranslation())(ProductBodyEdit);
