import {AuthService} from '../auth/authService';
import axios from 'axios';

const identityBaseUri = `${process.env.REACT_APP_AUTH_AUTHORITY}/identity`;

const confirmPrivacy = async (confirmation) => {
  const response = await axios.put(`${identityBaseUri}/privacy/confirmPrivacy/${confirmation}`,
    {},
    {
      headers: {
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const getNewsForUser = async () => {
  const response = await axios.get(`${identityBaseUri}/newsToUser/newsToUser`,
    {
      headers: {
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
}

const createNewsToUser = async (newsToUser) => {
  const response = await axios.post(`${identityBaseUri}/newsToUser/createNewsToUser`,
    newsToUser,
    {
      headers: {
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
}

const markReadNewsToUser = async (newsToUserId) => {
  const response = await axios.put(`${identityBaseUri}/newsToUser/markAsRead/${newsToUserId}`,
    {},
    {
      headers: {
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data
}
export default {confirmPrivacy, getNewsForUser, createNewsToUser, markReadNewsToUser}

