export default (theme) => ({
  root: {},
  grid: {
    minHeight: '30vh'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  paper: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.warning.main
  }
});

