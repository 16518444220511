import React, {useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import {onCloseWithBackdropCurry} from 'services/util/helperFuncs';
import {DropdownSelectSimple} from 'components';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

function CreateTemplateStopInstancesDialog(props) {

  const {classes, t, onCancel, onCreate, createTemplateStopInstancesDialogState, shipperOptions} = props;

  const [shipperName, setShipperName] = useState('');
  const [date, setDate] = useState(Date.now());

  const handleClose = () => {
    onCancel();
  };

  const handleCreate = () => {
    onCreate(date, shipperName);
    setShipperName('');
    setDate(Date.now());
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <React.Fragment>
        <Dialog
          disableEscapeKeyDown
          onClose={onCloseWithBackdropCurry(handleClose)}
          open={createTemplateStopInstancesDialogState.open}
        >
          <DialogTitle id="simple-dialog-title">{t('createTemplateStopInstances')}</DialogTitle>
          <DialogContent>
            <form className={classes.container}>
              <FormControl className={classes.formControl}>
                <DropdownSelectSimple
                  required
                  className={classes.dropdown}
                  hideOnEmptyOptions
                  name={t('shipper')}
                  options={shipperOptions}
                  setValue={(value) => setShipperName(value)}
                  sort
                  value={shipperName}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <KeyboardDatePicker
                  cancelLabel={t('dialogCancel')}
                  format="dd.MM.yyyy"
                  id="deliveryDate"
                  required
                  label={t('deliveryDate')}
                  margin="normal"
                  onChange={(event) => {
                    setDate(new Date(event))
                  }}
                  value={date}
                />
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              color="default"
              onClick={handleClose}
            >
              <CancelIcon/>&nbsp;{t('dialogCancel')}
            </Button>
            <Button
              color="primary"
              disabled={!shipperName || !date}
              onClick={handleCreate}
            >
              <SaveIcon/>&nbsp;{t('save')}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </MuiPickersUtilsProvider>
  )
    ;
}

CreateTemplateStopInstancesDialog.propTypes = {
  createTemplateStopInstancesDialogState: PropTypes.object.isRequired,
  classes: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  shipperOptions: PropTypes.array,
};


export default compose(withStyles(styles), withTranslation())(CreateTemplateStopInstancesDialog);
