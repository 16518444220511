import React, {useCallback, useEffect, useState} from 'react'
import {Button, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {SideNavLayout} from '../../../layouts';
import {LoadingWrapper,} from '../../../components';
import customersService from '../../../services/backend/customersService';
import useShipperOptions from '../../../hooks/useShipperOptions';
import useCarriersWithHubs from '../../../hooks/useCarriersWithHubs';
import SpecialDeliveryDayFilter from '../../../components/SpecialDeliveryDay/Filter';
import SpecialDeliveryDayTable from '../../../components/SpecialDeliveryDay/SpecialDeliveryDayTable';
import {displayModes} from '../../../services/enums/displayModes';
import {Add as AddIcon} from '@material-ui/icons';
import CreateSpecialDeliveryDayDialog from '../../../components/SpecialDeliveryDay/SpecialDeliveryDayCreateDialog';

function SpecialDeliveryDays(props) {
  const {classes, t} = props;

  const [backendLoading, setBackendLoading] = useState(false);
  const [specialDeliveryDays, setSpecialDeliveryDays] = useState([]);
  const [filter, setFilter] = useState({
    shipperName: '',
    carrierName: '',
    microHubName: '',
  });
  const {shipperOptions} = useShipperOptions();
  const {carriersWithHubs} = useCarriersWithHubs();
  const [createSpecialDeliveryDayDialogState, setCreateSpecialDeliveryDayDialogState] = useState(false)


  const loadSpecialDeliveryDays = useCallback(async () => {
    setBackendLoading(true);
    try {
      const specialDeliveryDays = await customersService.getSpecialDeliveryDaysByFilter(filter.shipperName, filter.carrierName, filter.microHubName);
      setSpecialDeliveryDays(specialDeliveryDays);
      setBackendLoading(false);
    } catch (error) {
      alert(`${t('errorLoadingSpecialDeliveryDays')}, ${error}`);
      setBackendLoading(false);
    }
  }, [filter.carrierName, filter.microHubName, filter.shipperName, t]);


  useEffect(() => {
    loadSpecialDeliveryDays();
  }, [loadSpecialDeliveryDays]);

  const handleCreateBoxTypeCancel = () => {
    setCreateSpecialDeliveryDayDialogState(false);
  };

  const handleCreateSpecialDeliveryDayClose = (specialDeliveryDay) => {
    customersService.createSpecialDeliveryDaysForMultipleHubs(specialDeliveryDay).then(() => loadSpecialDeliveryDays(), () => alert(t('errorUpdatingData')))
    setCreateSpecialDeliveryDayDialogState(false);
  };

  const handleDeleteSpecialDeliveryDay = (specialDeliveryDay) => {
    customersService.deleteSpecialDeliveryDayById(specialDeliveryDay.id).then(() => loadSpecialDeliveryDays(), () => alert(t('errorDeletingSpecialDeliveryDay')))
  }

  return (
    <SideNavLayout title={t('specialDeliveryDays')}>
      <div className={classes.root}>
        <div className={classes.filterWrapper}>
          <SpecialDeliveryDayFilter
            carriersWithMicroHubs={carriersWithHubs}
            filter={filter}
            setFilter={setFilter}
            shipperOptions={shipperOptions}
          />
        </div>
        <div className={classes.headerWrapper}>
          <Typography>
            {t('specialDeliveryDayExplanation')}
          </Typography>
          <Button
            className={classes.addButton}
            color="primary"
            onClick={() => setCreateSpecialDeliveryDayDialogState(true)}
            title={t('createBoxType')}
            variant="contained"
          >
            <AddIcon/>&nbsp;{t('createSpecialDeliveryDay')}
          </Button>
          <br/>
          <br/>
        </div>
        <LoadingWrapper loading={backendLoading}>
          {specialDeliveryDays &&
            <SpecialDeliveryDayTable
              className={classes.content}
              deleteSpecialDeliveryDay={handleDeleteSpecialDeliveryDay}
              displayMode={displayModes.admin}
              specialDeliveryDays={specialDeliveryDays}
            />
          }
        </LoadingWrapper>
        <CreateSpecialDeliveryDayDialog
          carriersWithMicroHubs={carriersWithHubs}
          createSpecialDeliveryDayDialogState={createSpecialDeliveryDayDialogState}
          handleCancel={handleCreateBoxTypeCancel}
          handleClose={handleCreateSpecialDeliveryDayClose}
          shipperOptions={shipperOptions}
        />
      </div>
    </SideNavLayout>
  );
}

SpecialDeliveryDays.propTypes = {
  classes: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(SpecialDeliveryDays);
