export default theme => ({
  dialog: {},
  formControl: {
    minWidth: '200px',
    width: '200px',
  },
  textHeader: {
    marginTop: theme.spacing(2)
  }
});
