export default theme => ({
    root: {
        padding: theme.spacing(3)
      },
      helpWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
      }
});
