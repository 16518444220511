import React, {useEffect, useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {Cancel as CancelIcon, Warning} from '@material-ui/icons';
import {AuthService} from '../../../services/auth/authService';
import {
  getProblemNotificationTypeDescriptionString,
  getProblemNotificationTypeString,
  ProblemNotificationType
} from '../../../services/enums/ProblemNotificationType'
import {DateFilter, DropdownSelect, DropdownSelectAutocomplete, DropdownSelectSimple} from '../../index';
import {PreviewToursService} from '../../../services/backend/previewToursService';
import {TourService} from '../../../services/backend/tourService';
import moment from 'moment';
import {DriverQueryService} from '../../../services/backend/driverQueryService';
import {useQuery} from '@apollo/react-hooks';
import {VehicleQueryService} from '../../../services/backend/vehicleQueryService';
import {ClientService} from '../../../services/client/clientService';
import {clientTypes} from '../../../services/client/clientTypes';
import {displayModes} from '../../../services/enums/displayModes';
import useCarriersWithHubs from '../../../hooks/useCarriersWithHubs';
import {onCloseWithBackdropCurry} from '../../../services/util/helperFuncs';


function ProblemNotificationDialog(props) {

  const {classes, t, dialogState, handleClose, displayMode} = props;

  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [type, setType] = useState(dialogState.typePreset);
  const [identifier, setIdentifier] = useState(dialogState.identifierPreset);
  const [options, setOptions] = useState([]);
  const [message, setMessage] = useState('');
  const [carrier, setCarrier] = useState(displayMode === displayModes.carrier ? AuthService.getUserOrganization() : dialogState.carrierPreset);
  const [microHubs, setMicroHubs] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const {carriersWithHubs} = useCarriersWithHubs(displayMode === displayModes.carrier ? AuthService.getUserOrganization() : null);

  const queryDrivers = DriverQueryService.getDriversByCarrierNameAndMicroHubListQuery(carrier, microHubs);
  const {data: dataDrivers} = useQuery(queryDrivers, {client: clientDriver, skip: !microHubs?.length || !carrier});

  const queryVehicle = VehicleQueryService.getVehiclesByCarrierNameAndMicroHubListQuery(carrier, microHubs);
  const {data: dataVehicle} = useQuery(queryVehicle, {client: clientVehicle, skip: !microHubs?.length || !carrier});

  useEffect(() => {
    const carrierOptions = [];
    const microHubOptions = [];
    const carrierName = carrier;

    if (carriersWithHubs) {
      Object.keys(carriersWithHubs).forEach(carrier => {
        carrierOptions.push(carrier);
        if (carrier === carrierName) {
          carriersWithHubs[carrier].forEach(hub => {
            if (!microHubOptions.includes(hub)) {
              microHubOptions.push(hub);
            }
          })
        }
      })
    }
    setCarriers(carrierOptions);
    setMicroHubs(microHubOptions);
  }, [carriersWithHubs, carrier, displayMode])


  useEffect(() => {
    const fromDate = moment(date).startOf('day').toDate();
    const toDate = moment(date).endOf('day').toDate();
    switch (type) {
      case ProblemNotificationType.PreviewStop: {
        const loadPreviewStops = async () => {
          setIsLoading(true);
          setOptions([]);
          try {
            const result = await PreviewToursService.getMicroHubsWithToursByFilter(carrier, null, [], date, null);
            setIsLoading(false);
            setOptions(result.map(mh => mh.tours.map(t => t.stops.map(s => s.id)).flat()).flat());
            if (!dialogState.identifierPreset) setIdentifier('');
          } catch (e) {
            setIsLoading(false);
            setIdentifier('');
            alert(`${t('errorLoadingPreviewTours')}: ${e}`)
          }
        };
        loadPreviewStops();
        break;
      }
      case ProblemNotificationType.FinalStop: {
        setIsLoading(true);
        setOptions([]);
        TourService.getStopsWithFilter(carrier, null, null, null, fromDate, toDate)
          .then(result => result.json())
          .then((result) => {
            setIsLoading(false);
            setOptions(result.map(s => s.tourStopId));
            if (!dialogState.identifierPreset) setIdentifier('');
          }, () => {
            setIsLoading(false);
            setIdentifier('');
            alert(t('errorLoadingStop'))
          });
        break;
      }
      case ProblemNotificationType.PreviewTour: {
        const loadPreviewTours = async () => {
          setIsLoading(true);
          setOptions([]);
          try {
            const result = await PreviewToursService.getMicroHubsWithToursByFilter(carrier, null, [], date, null);
            setIsLoading(false);
            setOptions(result.map(mh => mh.tours.map(t => t.id)).flat());
            if (!dialogState.identifierPreset) setIdentifier('');
          } catch (e) {
            setIsLoading(false);
            setIdentifier('');
            alert(`${t('errorLoadingPreviewTours')}: ${e}`)
          }
        };
        loadPreviewTours();
        break;
      }
      case ProblemNotificationType.FinalTour: {
        setIsLoading(true);
        setOptions([]);
        TourService.getToursWithFilter(carrier, null, null, fromDate, toDate)
          .then(result => result.json())
          .then((result) => {
            setIsLoading(false);
            setOptions(result.map(t => t.tourId));
            if (!dialogState.identifierPreset) setIdentifier('');
          }, () => {
            setIsLoading(false);
            setIdentifier('');
            alert(t('errorLoadingTours'))
          });
        break;
      }
      case ProblemNotificationType.FinalMicroHub:
      case ProblemNotificationType.PreviewFinalMicroHub: {
        if (!microHubs.length) {
          setIsLoading(true);
          setOptions([]);
        } else {
          setIsLoading(false);
          if (!dialogState.identifierPreset) setIdentifier('');
          setOptions(microHubs);
        }
        break;
      }
      case ProblemNotificationType.Driver: {
        if (!dataDrivers?.getDriversByCarrierNameAndMicroHubList) {
          setIsLoading(true);
          setOptions([]);
        } else {
          setIsLoading(false);
          if (!dialogState.identifierPreset) setIdentifier('');
          setOptions(dataDrivers.getDriversByCarrierNameAndMicroHubList.map(d => d.email));
        }
        break;
      }
      case ProblemNotificationType.Vehicle: {
        if (!dataDrivers?.getVehiclesByCarrierNameAndMicroHubList) {
          setIsLoading(true);
          setOptions([]);
        } else {
          setIsLoading(false);
          if (!dialogState.identifierPreset) setIdentifier('');
          setOptions(dataDrivers.getDriversByCarrierNameAndMicroHubList.map(v => v.licensePlate));
        }
        break;
      }
      default :
        break;
    }
  }, [type, microHubs, dataDrivers, dataVehicle, date, t, carrier, dialogState.identifierPreset]);

  const handleOk = () => {
    setIsLoading(true);
    TourService.notifyProblem(carrier, type, date, identifier, message).then(() => {
      setIsLoading(false);
      close();
    }, () => {
      alert(t('errorSavingProblemNotification'));
      setIsLoading(false);
    });
  };

  const close = () => {
    setType('');
    setIdentifier('');
    setIsLoading(false);
    setMessage('');
    setCarrier(displayMode === displayModes.carrier ? AuthService.getUserOrganization() : '');
    handleClose();
  }

  useEffect(() => {
    if (!isLoading) {
      const el = document.getElementById('messageFromCarrier');
      if (el) el.focus();
    }
  }, [isLoading]);

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      maxWidth={'sm'}
      onClose={onCloseWithBackdropCurry(close)}
      onEntered={() => {

      }}
      open={dialogState.open}
    >
      <DialogTitle>{t('sendProblemNotification')}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography className={classes.infoText}>{t('problemNotificationDescription')}</Typography>
        <DateFilter
          setValue={value => setDate(value)}
          value={date}
        />
        {displayMode === displayModes.admin &&
        <DropdownSelectSimple
          error={!carrier}
          name={t('carrier')}
          options={carriers}
          required
          setValue={v => setCarrier(v)}
          sort
          value={carrier}
        />
        }
        <DropdownSelect
          error={!type}
          name={t('problemNotificationType')}
          options={Object.values(ProblemNotificationType).map(val => ({
            value: val,
            name: t(getProblemNotificationTypeString(val))
          }))}
          required
          setValue={value => setType(value)}
          value={type}
        />
        {type && <Typography className={classes.infoText}>{t(getProblemNotificationTypeDescriptionString(type))}</Typography>}
        <DropdownSelectAutocomplete
          disabled={!type}
          error={!identifier}
          name={t('problemNotificationIdentifier')}
          options={options.map(o => o.toString())}
          required
          setValue={value => setIdentifier(value === null ? '' : value)}
          value={identifier}
        />
        <div className={classes.messageFromCarrierContainer}>
          <Typography>{t('messageFromCarrier')}</Typography>
          <textarea
            className={classes.textarea}
            cols={'65'}
            id="messageFromCarrier"
            onChange={(event) => setMessage(event.target.value)}
            rows={'4'}
            value={message}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={close}
        >
          <CancelIcon/>&nbsp;{t('dialogCancel')}
        </Button>
        <Button
          className={classes.warnButton}
          color="primary"
          disabled={!identifier || !type || !carrier}
          onClick={handleOk}
        >
          <Warning/>&nbsp;{t('sendProblemNotification')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}


const clientDriver = ClientService.getClient(clientTypes.driver);

const clientVehicle = ClientService.getClient(clientTypes.vehicle);

ProblemNotificationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogState: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    typePreset: PropTypes.oneOf(['', ...Object.values(ProblemNotificationType)]),
    identifierPreset: PropTypes.string.isRequired,
    carrierPreset: PropTypes.string.isRequired,
  }).isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(ProblemNotificationDialog);
