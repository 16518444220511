import React, { useState } from 'react'
import { Table, TableBody, TableHead, TableRow, withStyles } from '@material-ui/core';
import styles from './styles'
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Columns from './columns'
import DefaultConfig from './config';
import { Pagination, Portlet, PortletContent, TableCellWithConfig, TableSettings, TextPlaceholderWrapper } from 'components';
import useTableConfig from 'hooks/useTableConfig';
import { getTableKey } from 'services/util/tableConfigHelper';
import useTablePagination from 'hooks/useTablePagination';
import { TableKind } from 'services/enums/TableKind';
import { displayModes } from 'services/enums/displayModes';
import DeleteTemplateStopInstanceDialog from '../DeleteTemplateStopInstanceDialog';

function TemplateStopInstanceTable(props) {

  const [deleteTemplateStopInstanceDialogState, setDeleteTemplateStopInstanceDialogState] = useState({ open: false, templateState: null });

  const {
    classes,
    t,
    showDetails,
    templateStopInstances,
    className,
    deleteTemplateStopInstance,
    tableDisplayMode,
    selectedTemplateStopInstance,
  } = props;

  const {
    config,
    columnConfigs,
    updateConfig
  } = useTableConfig(
    DefaultConfig,
    tableDisplayMode,
    getTableKey(TableKind.TemplateStopInstanceTable, tableDisplayMode),
    {
      // Only show options column, if one of the options functions is present
      [Columns.options.name]: Boolean(deleteTemplateStopInstance)
    },
  )

  const { paginationProps, pagedObjects } = useTablePagination(templateStopInstances);

  const deleteTemplateStopInstanceDialogOk = (templateStopInstanceId) => {
    setDeleteTemplateStopInstanceDialogState({ ...deleteTemplateStopInstanceDialogState, open: false, templateStopInstance: null });
    deleteTemplateStopInstance(templateStopInstanceId);
  };

  const rootClassName = classNames(classes.root, className);


  return (
    <TextPlaceholderWrapper
      active={!templateStopInstances?.length}
      text={t('noTemplateStopInstancesAvailable')}
    >
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <TableSettings
            updateConfig={updateConfig}
            tableConfig={config}
            columns={Columns}
          />
          <Table size="medium">
            <TableHead>
              <TableRow>
                {columnConfigs.map(columnConfig => (
                  <TableCellWithConfig
                    key={columnConfig.order}
                    config={columnConfig}
                  >
                    {Columns[columnConfig.columnName].header({ t })}
                  </TableCellWithConfig>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedObjects.map((templateStopInstance, index) => (
                <TableRow
                  className={classNames(classes.tableRow, 'cursorPointer')}
                  hover
                  key={index}
                  onClick={() => showDetails(templateStopInstance)}
                  selected={selectedTemplateStopInstance && templateStopInstance.id === selectedTemplateStopInstance.templateStopInstance}
                >
                  {columnConfigs.map(columnConfig => (
                    <TableCellWithConfig
                      key={columnConfig.order}
                      config={columnConfig}
                      className={classes.tableCell}
                    >
                      {Columns[columnConfig.columnName].body({
                        templateStopInstance,
                        classes,
                        t,
                        deleteTemplateStopInstance,
                        setDeleteTemplateStopInstanceDialogState,
                        deleteTemplateStopInstanceDialogState,
                      })}
                    </TableCellWithConfig>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination paginationProps={paginationProps} />
        </PortletContent>
      </Portlet>
      <DeleteTemplateStopInstanceDialog
        dialogState={deleteTemplateStopInstanceDialogState}
        handleCancel={() => setDeleteTemplateStopInstanceDialogState({ ...deleteTemplateStopInstanceDialogState, open: false, templateStopInstance: null })}
        handleClose={deleteTemplateStopInstanceDialogOk}
        needsConfirm={tableDisplayMode !== displayModes.admin && tableDisplayMode !== displayModes.shipper}
      />
    </TextPlaceholderWrapper>
  );
}


TemplateStopInstanceTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deleteTemplateStopInstance: PropTypes.func,
  selectedTemplateStopInstance: PropTypes.object,
  showDetails: PropTypes.func.isRequired,
  templateStopInstances: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  tableDisplayMode: PropTypes.string.isRequired
}
  ;

export default compose(withStyles(styles), withTranslation())(TemplateStopInstanceTable);
