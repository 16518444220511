export default theme => ({
  root: {},
  button: {
    marginLeft: theme.spacing(1)
  },
  wrapper: {
    display: 'flex',
    margin: theme.spacing(2)
  },
  wrapperChild: {
    flex: 1,
  },
  templateStopInstanceDetail: {
    margin: theme.spacing(1)
  },
  relatedTemplateStopWrapper: {
    border: `1px solid ${theme.palette.border}`,
    borderRadius: theme.spacing(1),
    cursor: 'pointer'
  }
});
