import React, {Fragment, useContext, useEffect, useState} from 'react'
import {Button, Tooltip, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {
  Portlet,
  PortletContent,
  PortletHeader,
  TourMetadataIcon,
  TourStopTable,
  TourStoragePlaces,
  TourSummaryDialog
} from '../../index'
import {
  Close as CloseIcon,
  FormatListBulleted as TourSummaryIcon,
  InfoOutlined as InfoIconSymbol,
  Warning as ProblemNotificationIcon
} from '@material-ui/icons';
import StopDetails from '../../Stop/StopDetail';
import {DateService} from '../../../services/util/DateService';
import {getTourStatusSting, TourStatus} from '../../../services/enums/TourStatus';
import moment from 'moment';
import 'moment-duration-format';
import {displayShipperAndShipperShortName, roundCapacityValues} from '../../../services/util/helperFuncs';
import {StopStatus} from '../../../services/enums/StopStatus';
import {TourAmPmTag} from '../../../components';
import {ProblemNotificationType} from '../../../services/enums/ProblemNotificationType';
import {ProblemNotificationDialogContext} from '../../../context';
import TourIdLabel from '../TourIdLabel';
import trackAndTraceService from '../../../services/backend/trackAndTraceService';
import {displayModes} from '../../../services/enums/displayModes';

function TourDetail(props) {

  const {
    classes,
    className,
    t,
    tour,
    closeTourDetail,
    approveTour,
    finishTourDelivery,
    trackingStopsLoading,
    trackingStops,
    displayMode
  } = props;
  const [selectedStop, setSelectedStop] = useState(null);
  const [trackingStopsInternal, setTrackingStopsInternal] = useState([]);
  const [trackingStopsLoadingInternal, setTrackingStopsLoadingInternal] = useState(false);
  const [tourSummaryDialogOpen, setTourSummaryDialogOpen] = useState(false);

  useEffect(() => {
    // if there is an external tracking stop use it
    if (trackingStops?.length > 0 && !trackingStopsLoading) {
      setTrackingStopsInternal(trackingStops);
      setTrackingStopsLoadingInternal(false);
      return;
    }
    // if external tracking stop is loading, wait for it
    if (trackingStopsLoading) {
      setTrackingStopsLoadingInternal(true);
      return;
    }
    // if there is no external tracking stop loading load it yourself
    if (!trackingStops?.length > 0 && !trackingStopsLoading) {
      loadTrackingStops(tour.stops.map(s => s.tourStopId));
    }
  }, [tour, trackingStops, trackingStopsLoading])

  const showStopDetails = (stop) => {
    setSelectedStop(stop)
  };

  const loadTrackingStops = async (stopIds) => {
    try {
      setTrackingStopsLoadingInternal(true);
      const response = await trackAndTraceService.getTrackAndTraceInfoByStopIds(stopIds);
      setTrackingStopsInternal(response);
      setTrackingStopsLoadingInternal(false);
    } catch (e) {
      setTrackingStopsLoadingInternal(false);
      console.warn('Error loading track and trace information for tour details', e)
    }
  };

  useEffect(() => {
    tour.stops.sort((s1, s2) => (s1.stopNumber > s2.stopNumber) ? 1 : ((s2.stopNumber > s1.stopNumber) ? -1 : 0));
    setSelectedStop(null);
  }, [tour]);

  const rootClassName = classNames(classes.root, className);

  const {open: openProblemNotificationDialog} = useContext(ProblemNotificationDialogContext);

  const criticalTourTiming = (moment(tour.planedTourDelivery) - moment.now() - 60 * 60 * 1000) <= 0;
  const tourInDelivery = tour.tourStatus === TourStatus.InDelivery;
  const tourAlreadyDelivered = Boolean(tour.tourEnd);
  let disapproveToolTip = t('revokeApprove');
  if (criticalTourTiming) disapproveToolTip = t('criticalDisapproveTiming');
  if (tourInDelivery) disapproveToolTip = t('tourAlreadyInDelivery');
  if (tourAlreadyDelivered) disapproveToolTip = t('tourAlreadyDelivered');

  const canFinishTourDelivery = !tour.tourEnd && !tour.stops.some(stop => stop.stopStatus !== StopStatus.CanNotDeliver && stop.stopStatus !== StopStatus.Delivered);

  return (
    <Fragment>
      <Portlet className={rootClassName}>
        <PortletHeader style={{boxShadow: `inset 10px 0px 0px 0px ${tour.color}`}}>
          <Fragment>
            <div>
              <strong>{t('tour')}</strong>: <TourIdLabel tour={tour}/>
              <TourMetadataIcon metadata={tour.tourMetaData}/>
            </div>
            <TourAmPmTag estimatedTourDeliveryWindow={tour.estimatedTourDeliveryWindow}/>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.warnButton}
                onClick={() => openProblemNotificationDialog(ProblemNotificationType.FinalTour, tour.tourId.toString(), tour.carrierName)}
                title={t('sendProblemNotification')}
                variant="contained"
              >
                <ProblemNotificationIcon/>
              </Button>
              {finishTourDelivery && canFinishTourDelivery &&
                <Tooltip title={t('finishTourDeliveryDescription')}>
                  <div>
                    <Button
                      className={classes.button}
                      color="secondary"
                      onClick={() => finishTourDelivery(tour)}
                      variant="contained"
                    >
                      {t('finishTourDelivery')}
                    </Button>
                  </div>
                </Tooltip>
              }
              {approveTour && tour.approved &&
                <Tooltip title={disapproveToolTip}>
                  <div>
                    <Button
                      className={classNames(classes.button, {
                        [classes.warnButton]: criticalTourTiming,
                        [classes.errorButton]: tourInDelivery
                      })}
                      color="secondary"
                      disabled={tourAlreadyDelivered}
                      onClick={() => approveTour(tour, false)}
                      variant="contained"
                    >
                      {t('revokeApprove')}
                    </Button>
                  </div>
                </Tooltip>
              }
              <Button
                className={classes.button}
                color="secondary"
                disabled={!tour.approved}
                onClick={() => setTourSummaryDialogOpen(true)}
                title={t('tourSummary')}
                variant="contained"
              >
                <TourSummaryIcon/>
              </Button>
              {closeTourDetail &&
                <Button
                  className={classes.button}
                  color="default"
                  onClick={closeTourDetail}
                  title={t('close')}
                  variant="contained"
                >
                  <CloseIcon/>
                </Button>
              }
            </div>
          </Fragment>
        </PortletHeader>
        <PortletContent noPadding>
          <Fragment>
            <div className={classes.wrapper}>
              <div className={classes.wrapperChild}>
                <div>
                  <div><strong>{t('generalInformation')}</strong>:</div>
                  <div className={classes.detail}>
                    <strong>{t('shipper')}</strong>: {displayShipperAndShipperShortName(tour)}</div>
                  <div className={classes.detail}><strong>{t('fix')}</strong>: {tour.fix ? t('yes') : t('no')}</div>
                  <div className={classes.detail}><strong>{t('length')}</strong>: {tour.length}
                    <Tooltip
                      title={<div style={{whiteSpace: 'pre-wrap'}}>{t('tourLengthDescription')}</div>}
                    >
                      <InfoIconSymbol className={classes.infoIcon}/>
                    </Tooltip>
                  </div>
                  <div className={classes.detail}><strong>{t('duration')}</strong>: {tour.duration}
                    <Tooltip
                      title={<div style={{whiteSpace: 'pre-wrap'}}>{t('tourDurationDescription')}</div>}
                    >
                      <InfoIconSymbol className={classes.infoIcon}/>
                    </Tooltip>
                  </div>
                  <div className={classes.detail}>
                    <strong>{t('packUpOrder')}</strong>: {tour.tourNumber ? (tour.tourNumber + (tour.tourCutNumber ? `-${tour.tourCutNumber}` : '')) : '-'}
                  </div>
                  <div className={classes.detail}><strong>{t('storagePlace')}</strong>:&nbsp;
                    <TourStoragePlaces tour={tour}/>
                  </div>
                </div>
                <br/>
                <div>
                  <div><strong>{t('tourCapacity')}</strong>:</div>
                  {tour.tourCapacities &&
                    <Fragment>
                      <div className={classes.detail}>
                        <strong>{t('amountOfBoxes')}</strong>: {roundCapacityValues(tour.tourCapacities.boxAmount)}
                      </div>
                      <div className={classes.detail}>
                        <strong>{t('weight')}</strong>: {roundCapacityValues(tour.tourCapacities.weight)}</div>
                      <div className={classes.detail}>
                        <strong>{t('volume')}</strong>: {roundCapacityValues(tour.tourCapacities.volume)}</div>
                    </Fragment>
                  }
                </div>
              </div>
              <div className={classes.wrapperChild}>
                <div>
                  <div><strong>{t('tourAssignment')}</strong>:</div>
                  <div className={classes.detail}><strong>{t('carrier')}</strong>: {tour.carrierName}</div>
                  <div className={classes.detail}><strong>{t('microHub')}</strong>: {tour.microHubName}</div>
                  <div className={classes.detail}>
                    <strong>{t('driver')}</strong>: {`${tour.driverDisplayName ? tour.driverDisplayName : '-'} (${tour.driverEmail ? tour.driverEmail : '-'})`}
                  </div>
                  <div className={classes.detail}>
                    <strong>{t('vehicle')}</strong>: {tour.vehicleLicensePlate ? tour.vehicleLicensePlate : '-'}</div>
                </div>
              </div>
              <div className={classes.wrapperChild}>
                <div>
                  <div><strong>{t('state')}</strong>:</div>
                  <div className={classes.detail}>
                    <strong>{t('tourStatus')}</strong>: {t(getTourStatusSting(tour.tourStatus))}</div>
                  <div className={classes.detail}>
                    <strong>{t('planedTourDelivery')}</strong>: {DateService.displayDate(tour.planedTourDelivery)}&nbsp;{DateService.displayTime(tour.planedTourDelivery)}
                  </div>
                  <div className={classes.detail}>
                    <strong>{t('estimatedTourDeliveryWindow')}</strong>: {tour.estimatedTourDeliveryWindow && DateService.displayTime(tour.estimatedTourDeliveryWindow.from)} - {tour.estimatedTourDeliveryWindow && DateService.displayTime(tour.estimatedTourDeliveryWindow.to)}
                  </div>
                  <div className={classes.detail}><strong>{t('approved')}</strong>: {tour.approved ? t('yes') : t('no')}
                  </div>
                  <div className={classes.detail}>
                    <strong>{t('timeToPack')}</strong>: {tour.timeToPack ? moment.duration(tour.timeToPack, 'seconds').format('h:mm:ss', {trim: false}) : '-'}
                  </div>
                  <div className={classes.detail}>
                    <strong>{t('tourStart')}</strong>: {DateService.displayDate(tour.tourStart)}&nbsp;{DateService.displayTime(tour.tourStart)}
                  </div>
                  <div className={classes.detail}>
                    <strong>{t('tourEnd')}</strong>: {DateService.displayDate(tour.tourEnd)}&nbsp;{DateService.displayTime(tour.tourEnd)} {(tour.tourAutomaticallySetToDelivered && '(' + t('tourAutomaticallySeToFinished') + ')')}
                  </div>
                </div>
              </div>
            </div>
            <TourStopTable
              className={classNames(classes.tourStopTable, rootClassName)}
              displayMode={displayMode}
              selectedStop={selectedStop}
              showStopDetails={showStopDetails}
              tour={tour}
              trackingStops={trackingStopsInternal}
              trackingStopsLoading={trackingStopsLoadingInternal}
            />
            {selectedStop &&
              <StopDetails
                className={classNames(classes.tourStopTable, rootClassName)}
                closeDetailView={() => setSelectedStop(null)}
                readOnly
                stop={selectedStop}
                trackingStop={trackingStopsInternal.length > 0 ? trackingStopsInternal.filter(ts => ts.finalStopId === selectedStop.tourStopId)[0] : null}
                trackingStopLoading={trackingStopsLoadingInternal}
              />
            }
          </Fragment>
        </PortletContent>
      </Portlet>
      <TourSummaryDialog
        handleClose={() => setTourSummaryDialogOpen(false)}
        loading={trackingStopsLoadingInternal}
        open={tourSummaryDialogOpen}
        tour={tour}
        trackingStops={trackingStopsInternal}
      />
    </Fragment>
  );
}


TourDetail.propTypes = {
  approveTour: PropTypes.func,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  closeTourDetail: PropTypes.func,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  finishTourDelivery: PropTypes.func,
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  tour: PropTypes.object.isRequired,
  trackingStops: PropTypes.array,
  trackingStopsLoading: PropTypes.bool,
};

export default compose(withStyles(styles), withTranslation())(TourDetail);
