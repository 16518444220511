import React from 'react'
import {Table, TableBody, TableCell, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles';
import classNames from 'classnames';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {Portlet, PortletContent, TextPlaceholderWrapper} from '../../../../../../../components';
import StopAddTableRow from './StopAddTableRow';

function StopAddTable(props) {

  const {classes, className, t, dialogState, stopsWithoutTour, microHubs, addOrRemoveStop, stopsToAdd} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <TextPlaceholderWrapper
      active={!stopsWithoutTour?.length}
      text={t('noStopsWithoutToursAvailable')}
    >
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <Table
            size={'small'}
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes.alignLeft}>{t('tourStopId')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('shipper')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('microHub')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('deliveryDate')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('streetAndNumber')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('boxAmount')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('weight')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('volume')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dialogState.tour && stopsWithoutTour.map((stop, index) => (
                <StopAddTableRow
                  addOrRemoveStop={addOrRemoveStop}
                  dialogState={dialogState}
                  key={index}
                  microHubs={microHubs}
                  stop={stop}
                  stopsToAdd={stopsToAdd}
                />
              ))}
            </TableBody>
          </Table>
        </PortletContent>
      </Portlet>
    </TextPlaceholderWrapper>
  );
}

StopAddTable.propTypes = {
  addOrRemoveStop: PropTypes.func.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  dialogState: PropTypes.object.isRequired,
  microHubs: PropTypes.array.isRequired,
  stopsToAdd: PropTypes.array.isRequired,
  stopsWithoutTour: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(StopAddTable);
