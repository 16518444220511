import {DateService} from './DateService';

export function groupBy(arr, key) {
  return arr.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function roundCapacityValues(number) {
  if (typeof number === 'number') {
    return Math.round((number + Number.EPSILON) * 10) / 10;
  }
  return number;
}

export function roundToFixed(number, decimalPlaces) {
  if (typeof number === 'number') {
    return number.toFixed(decimalPlaces);
  }
  return number;
}

export function displayAddress(address) {
  if (!address) return '-';
  return `${address.streetAndNumber} | ${address.zipcode} ${address.city}`
}

export function displayDeliveryWindow(deliveryWindow) {
  return `${deliveryWindow?.from ? DateService.displayTime(deliveryWindow.from) : ''} 
  - ${deliveryWindow?.to ? DateService.displayTime(deliveryWindow.to) : ''}`
}

export function displayDeliveryWeekDay(t, deliveryWeekDays) {
  let weekDayString = (deliveryWeekDays.monday ? `${t('monday')}, ` : '') +
    (deliveryWeekDays.tuesday ? `${t('tuesday')}, ` : '') +
    (deliveryWeekDays.wednesday ? `${t('wednesday')}, ` : '') +
    (deliveryWeekDays.thursday ? `${t('thursday')},  ` : '') +
    (deliveryWeekDays.friday ? `${t('friday')}, ` : '') +
    (deliveryWeekDays.saturday ? `${t('saturday')}, ` : '') +
    (deliveryWeekDays.sunday ? `${t('sunday')}, ` : '');
  weekDayString = weekDayString.slice(0, -1);
  return weekDayString;
}

export function formatTemplateCapacityConstraint(capacity) {
  if (!capacity) return '-';
  const weight = capacity.weight === null ? '-' : parseInt(capacity.weight);
  const volume = capacity.volume === null ? '-' : parseInt(capacity.volume);
  return `${weight} kg | ${volume} l`
}

export function displayShipperShortName(stop) {
  return stop?.shipperShortName ? stop.shipperShortName : (stop.shipperName || '');
}

export function displayShipperAndShipperShortName(stop) {
  const shipperShortName = stop.shipperShortName ? ` (${stop.shipperShortName})` : '';
  const shipperName = stop.shipperName ? stop.shipperName : '-';
  return shipperName + shipperShortName;
}

export function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function blink(id) {
  let element = document.getElementById(id);
  if (element) element.classList.add('blinkSelect');
  setTimeout(() => {
    let element = document.getElementById(id);
    if (element) element.classList.remove('blinkSelect');
  }, 1000);
}

export function scrollToElement(id, withBlink = false) {
  let element = document.getElementById(id);
  if (element) element.scrollIntoViewIfNeeded();
  if (withBlink) blink(id);
}

export function onCloseWithBackdropCurry(onCloseFunc) {
  return (event, reason) => {
    if (reason !== 'backdropClick') {
      onCloseFunc();
    }
  }
}

export function containsInjectionChars(str) {
  const regExp = new RegExp('.*[\'"<>`/].*');
  return regExp.test(str);
}

export function areArraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;

  const idSet = new Set(arr1.map(obj => obj.id));
  return arr2.every(obj => idSet.has(obj.id));
}