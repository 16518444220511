import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {LoadingButton, LoadingIndicator, LoadingWrapper, Portlet, RefreshButton, StopDetails, StopFilter, StopMap, StopTable} from '../../../components'
import {TourService} from '../../../services/backend/tourService';
import SideNavLayout from '../../../layouts/SideNavLayout';
import {displayModes} from '../../../services/enums/displayModes';
import FileSaver from 'file-saver';
import useDebounce from '../../../hooks/useDebounce';
import {SearchStopService} from '../../../services/searchServices/searchStopService';
import {stopMessageRelevantForFilter} from '../../../services/util/signalRMessageHelper';
import {useStopHub} from '../../../hooks/useStopHub';
import {DateService} from '../../../services/util/DateService';
import {GetApp as DownloadIcon} from '@material-ui/icons';
import useShipperOptions from '../../../hooks/useShipperOptions';
import useLoadStopByIdCache from '../../../hooks/useLoadStopByIdCache';
import useCarriersWithHubs from '../../../hooks/useCarriersWithHubs';
import {DatePickerDefaultValueContext} from '../../../context';
import useMicroHubData from '../../../hooks/useMicroHubData';

function StopOverview(props) {

  const {classes, t} = props;

  const {fromDateDefault, toDateDefault, updateDefaultDate} = useContext(DatePickerDefaultValueContext);

  const [refresh, setRefresh] = useState(false);
  const [stops, setStops] = useState([]);
  const {shipperOptions} = useShipperOptions();
  const {selectedStop, loadingSelectedStop, resetLoadStopByIdCache, getStopById, resetSelectedStop, updateCache} = useLoadStopByIdCache();
  const [searchResult, setSearchResult] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [filter, setFilter] = useState({
    carrierName: '',
    shipperName: '',
    microHubName: '',
    stopStatus: '',
    fromDate: fromDateDefault,
    toDate: toDateDefault,
    boxId: ''
  });
  useEffect(() => updateDefaultDate(filter.fromDate), [filter.fromDate, updateDefaultDate]);
  const [backendLoading, setBackendLoading] = useState(false);
  const [backendLoadingExcel, setBackendLoadingExcel] = useState(false);
  const {carriersWithHubs} = useCarriersWithHubs();
  const {microHubData} = useMicroHubData();

  useStopHub(useCallback(event => {
    if (stopMessageRelevantForFilter(event, filter)) {
      setRefresh(true);
    }
  }, [filter]));

  const loadStops = useCallback((selectedStop, noLoadingIndicator = false) => {
    if (!noLoadingIndicator) setBackendLoading(true);
    resetLoadStopByIdCache();
    TourService.getStopsWithFilter(filter.carrierName, filter.shipperName, filter.microHubName, filter.stopStatus === '' ? null : filter.stopStatus, filter.fromDate, filter.toDate, filter.boxId)
      .then(response => response.json())
      .then(response => {
        setStops(response);
        const index = response.findIndex(stop => selectedStop && stop.tourStopId === selectedStop.tourStopId);

        if (index >= 0) {
          getStopById(selectedStop.tourStopId)
        } else {
          resetSelectedStop();
        }

        if (!noLoadingIndicator) setBackendLoading(false);
        setRefresh(false);
      }, () => {
        alert(t('errorLoadingStops'));
        if (!noLoadingIndicator) setBackendLoading(false);
        setRefresh(false);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, t]);

  useEffect(() => {
    loadStops()
  }, [loadStops]);

  // use Debounce to prevent to many search operations, delay is in ms
  const debouncedSearchInput = useDebounce(searchInput, 250);

  // do search if debounceSearchInput changes
  useEffect(
    () => {
      const result = SearchStopService.searchStops(stops, debouncedSearchInput);
      setSearchResult(result);
    },
    [debouncedSearchInput, stops]
  );

  const handleStopUpdate = (stop, neededUpdateBefore) => {
    if (neededUpdateBefore) {
      loadStops(setStops, true);
    } else {
      const index = stops.findIndex(x => x.tourStopId === stop.tourStopId);
      const newStops = JSON.parse(JSON.stringify(stops));
      newStops[index] = stop;
      setStops(newStops);
      updateCache(stop);
      setRefresh(false);
    }
  };

  const showDetails = (stop) => {
    getStopById(stop.tourStopId);
  };

  const hideDetails = () => {
    resetSelectedStop();
  };

  const deleteStop = (stopId) => {
    TourService.deleteStoById(stopId).then(() => {
      const newStops = JSON.parse(JSON.stringify(stops));
      const index = newStops.findIndex(s => s.tourStopId === stopId);
      if (index > -1) {
        newStops.splice(index, 1);
      }
      setStops(newStops);
      if (selectedStop?.tourStopId === stopId) resetSelectedStop();
    }, () => {
      alert(t('errorDeletingStop'));
      loadStops(selectedStop);
    });
  };

  const removeCarrier = (stopId) => {
    const needsRefresh = JSON.parse(JSON.stringify(refresh));
    TourService.deleteCarrierFromStopById(stopId).then(response => response.json())
      .then(response => {
        handleStopUpdate(response, needsRefresh);
      }, () => {
        loadStops(selectedStop);
        alert(t('errorRemovingCarrier'));
      });
  };

  const changeAssignment = (stopId, carrier, microHub) => {
    const needsRefresh = JSON.parse(JSON.stringify(refresh));
    TourService.changeStopAssignment(stopId, carrier, microHub).then(response => response.json())
      .then(response => {
        handleStopUpdate(response, needsRefresh);
      }, () => {
        loadStops(selectedStop);
        alert(t('errorChangingStopAssignment'));
      });
  };

  const downloadStopsAsExcel = () => {
    if (stops.length !== 0) {
      setBackendLoadingExcel(true);
      TourService.downloadExcelZa(filter.carrierName, filter.shipperName, filter.microHubName, filter.stopStatus === '' ? null : filter.stopStatus, filter.fromDate, filter.toDate)
        .then(response => {
          let filename = response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0];
          setBackendLoadingExcel(false);
          response.blob().then(blob =>
            FileSaver.saveAs(blob, filename)
          );
        }, () => {
          alert(t('errorDownloadingExcel'))
          setBackendLoadingExcel(false);
        });
    } else {
      alert(`${t('noStopDataAvailable')}: ${DateService.displayDate(filter.fromDate)}-${DateService.displayDate(filter.toDate)}`);
    }
  };


  const downloadEmptiesForStopsAsExcel = () => {
    if (stops.length !== 0) {
      if (stops.some(s => s.editable)) alert(t('notAllStopsFinalEmpties'));
      setBackendLoadingExcel(true);
      TourService.downloadEmptiesForStopsAsExcelZa(filter.carrierName, filter.shipperName, filter.microHubName, filter.stopStatus === '' ? null : filter.stopStatus, filter.fromDate, filter.toDate)
        .then(response => {
          setBackendLoadingExcel(false);
          if (response.status === 204) {
            alert(`${t('noStopsWithEmptiesFound')}: ${DateService.displayDate(filter.fromDate)}-${DateService.displayDate(filter.toDate)}`);
          } else {
            let filename = response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0];

            response.blob().then(blob =>
              FileSaver.saveAs(blob, filename)
            );
          }
        }, () => {
          alert(t('errorDownloadingExcel'))
          setBackendLoadingExcel(false);
        });
    } else {
      alert(`${t('noStopDataAvailable')}: ${DateService.displayDate(filter.fromDate)}-${DateService.displayDate(filter.toDate)}`);
    }
  };

  const updateStop = (stop) => {
    const needsRefresh = JSON.parse(JSON.stringify(refresh));
    TourService.updateStop(stop, stop.tourStopId).then((response) => response.json()).then((response) => {
      handleStopUpdate(response, needsRefresh);
    }, () => {
      loadStops(selectedStop);
      alert(t('errorUpdatingStop'));
    });
  };

  return (
    <SideNavLayout title={t('stopOverview')}>
      <div className={classes.root}>
        <div className={classes.verticalContainer}>
          <LoadingButton
            className={classes.button}
            color="secondary"
            loading={backendLoadingExcel}
            onClick={downloadStopsAsExcel}
            variant="contained"
          >
            <DownloadIcon/>&nbsp;{t('downloadStopOverviewAsExcel')}
          </LoadingButton>
          <LoadingButton
            className={classes.emptiesDownloadButton}
            color="secondary"
            loading={backendLoadingExcel}
            onClick={downloadEmptiesForStopsAsExcel}
            variant="contained"
          >
            <DownloadIcon/>&nbsp;{t('emptiesDownload')}
          </LoadingButton>
        </div>
        <RefreshButton
          className={classes.buttonRight}
          refresh={refresh}
          refreshFunc={() => loadStops(selectedStop)}
        />
        <br/>
        <StopFilter
          carriersWithMicroHubs={carriersWithHubs}
          filter={filter}
          searchInput={searchInput}
          setFilter={setFilter}
          setSearchInput={setSearchInput}
          shipperOptions={shipperOptions}
          useSearch
        />
        <br/>
        <LoadingWrapper loading={backendLoading}>
          <Fragment>
            <StopTable
              carriersWithHubs={carriersWithHubs}
              changeAssignment={changeAssignment}
              className={classes.content}
              deleteCarrierFromStop={removeCarrier}
              deleteStop={deleteStop}
              microHubsData={microHubData}
              selectedStop={selectedStop}
              showDetails={showDetails}
              stops={searchResult}
              tableDisplayMode={displayModes.admin}
            />
            {loadingSelectedStop &&
            <Portlet className={classes.content}>
              <LoadingIndicator/>
            </Portlet>
            }
            {selectedStop &&
            <StopDetails
              className={classes.content}
              closeDetailView={hideDetails}
              editDisplayMode={displayModes.admin}
              microHubSelectOptions={microHubData}
              stop={selectedStop}
              updateStop={updateStop}
            />
            }
            {searchResult &&
            <StopMap
              className={classes.tourMap}
              microHubs={microHubData}
              stops={searchResult}
              stopStatusFilter={filter.stopStatus}
            />
            }
          </Fragment>
        </LoadingWrapper>
      </div>
    </SideNavLayout>
  );
}


StopOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(StopOverview);
