export class SearchTemplateStopInstanceService {

  static searchTemplateStopInstances(templateStopInstances, searchInput) {
    if (!templateStopInstances || templateStopInstances.length <= 0) {
      return [];
    }

    if (!searchInput) {
      return templateStopInstances;
    }

    return templateStopInstances.filter(
      (templateStopInstance) => {
        return templateStopInstance.id.toString().includes(searchInput.toLowerCase()) ||
          (templateStopInstance.name ? templateStopInstance.name.toLowerCase().includes(searchInput.toLowerCase()) : false) ||
          (templateStopInstance.address && templateStopInstance.address.streetAndNumber ? templateStopInstance.address.streetAndNumber.toLowerCase().includes(searchInput.toLowerCase()) : false) ||
          (templateStopInstance.address && templateStopInstance.address.zipcode ? templateStopInstance.address.zipcode.toLowerCase().includes(searchInput.toLowerCase()) : false) ||
          (templateStopInstance.customerId ? templateStopInstance.customerId.toLowerCase().includes(searchInput.toLowerCase()) : false) ||
          (templateStopInstance.internalCustomerId ? templateStopInstance.internalCustomerId.toString().toLowerCase().includes(searchInput.toLowerCase()) : false);
      }
    );
  }
}
