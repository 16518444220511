import {TemplateType} from '../enums/TemplateTypes';

const filterTemplatesByType = (templates, type) => {
  return templates.filter(t => !type ||
    (type === TemplateType.TourTemplate && !t.isAutoCutTemplate) ||
    (type === TemplateType.AutoCutTemplate && t.isAutoCutTemplate)
  )
}

export default {filterTemplatesByType}
