import {withStyles} from '@material-ui/core';
import styles from './styles'
import classNames from 'classnames';
import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Map, TileLayer} from 'react-leaflet';
import * as L from 'leaflet';
import {GestureHandling} from 'leaflet-gesture-handling';
import {
  MapControls,
  MarkerCreateFuncs,
  OverlappingMarkerCluster,
  OverlappingMarkerCreateFuncs,
  TemplateStopLegendInteractive
} from '../../MapComponents';
import {IconService} from '../../../../services/icon/iconService';
import {groupBy} from 'lodash';
import {
  filterStopsForCoords,
  filterStopsForDuplicate,
  getBoundingBox,
  getBoundingBoxCoords,
  getElementsWithSamePosition,
  getNumStopsOnMap,
  getPolyLinesFromTemplateStops,
} from '../../../../services/util/mapUtils';
import useMapControls from '../../../../hooks/useMapControls';
import {getColorByType, TemplateStopType} from '../../../../services/enums/TemplateStopType';
import TemplateStopMarkerCluster from '../../MapComponents/Marker/TemplateStopMarkerCluster';


const TemplateStopMap = React.memo(props => {

  const {classes, className, stops, noControls, isTemplateStopInstance} = props;
  const [legendCheckboxes, setLegendCheckboxes] = useState({
    displayDeliveryStops: true,
    displayPickupStops: true,
    displayPickupDeliveryConnections: false,
  });

  const rootClassName = classNames(classes.root, className);
  const stopsWithCoords = filterStopsForCoords(stops).filter(
    stop => {
      switch (stop.stopType) {
        case TemplateStopType.Delivery: {
          return legendCheckboxes.displayDeliveryStops;
        }
        case TemplateStopType.Pickup: {
          return legendCheckboxes.displayPickupStops;
        }
        default: {
          return false;
        }
      }
    }
  );

  const stopsOnMap = getNumStopsOnMap([], stopsWithCoords);
  const {
    useCluster,
    setUseCluster,
    useDuplicateSpiderfy,
    setUseDuplicateSpiderfy,
    highLiteDuplicates,
    setHighLiteDuplicates
  } = useMapControls(stopsOnMap);

  const overlappingElementGroups = useDuplicateSpiderfy ? getElementsWithSamePosition([], stopsWithCoords) : [];
  const keysOfOverlappingElements = overlappingElementGroups.map(g => g[0].key);

  const filteredStops = filterStopsForDuplicate(stopsWithCoords, keysOfOverlappingElements);

  const map = useRef(null);

  const stopsGroupedByType = groupBy(filteredStops, stop => stop.stopType);

  const polylines = legendCheckboxes.displayPickupDeliveryConnections ?
    getPolyLinesFromTemplateStops(stopsWithCoords, isTemplateStopInstance)
    : [];

  L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

  const fitMapBoundsToFG = useCallback(() => {
    const coordinates = getBoundingBoxCoords([], stopsWithCoords, []);
    if (coordinates.length <= 1) return;
    const boundingBox = getBoundingBox(coordinates);
    map.current.leafletElement.fitBounds(boundingBox, {padding: [30, 30]});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stops]);

  useEffect(() => {
    fitMapBoundsToFG();
  }, [fitMapBoundsToFG]);

  return (
    <Fragment>
      {!noControls &&
        <Fragment>
          {!noControls &&
            <div className={classes.legendWrapper}>
              <TemplateStopLegendInteractive
                showStopType
                showConnections
                legendCheckboxesState={legendCheckboxes}
                setLegendCheckboxesState={setLegendCheckboxes}
              />
            </div>
          }
        </Fragment>
      }
      <Map
        boundsOptions={{padding: [10, 10]}}
        center={filteredStops.length === 1 ? [filteredStops[0].address.latitude, filteredStops[0].address.longitude] : [50, 10.5]}
        className={rootClassName}
        gestureHandling
        ref={map}
        zoom={filteredStops.length === 1 ? 14 : 6}
        zoomSnap={0.25}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {Object.keys(stopsGroupedByType).map((key, index) => {
          return (
            <TemplateStopMarkerCluster
              clusterIconColor={getColorByType(parseInt(key))}
              getClusterIcon={(iconColor) => IconService.getMarkerClusterIcon(iconColor)}
              getStopMarkerAndAddToMcg={(stop, mcg, t) => isTemplateStopInstance
                ? MarkerCreateFuncs.getTemplateStopServiceInstanceMarker(stop, mcg, t, undefined)
                : MarkerCreateFuncs.getTemplateStopServiceMarker(stop, mcg, t, undefined)}
              key={index}
              polylines={parseInt(key) === TemplateStopType.Pickup ? polylines : []}
              stops={stopsGroupedByType[key]}
              useCluster={useCluster}
            />
          );
        })}
        {overlappingElementGroups.map((elementGroup, index) => (
          <OverlappingMarkerCluster
            getClusterIcon={(overlappingElementGroups, highLiteDuplicates) => OverlappingMarkerCreateFuncs.getForTemplateStopMap(overlappingElementGroups, highLiteDuplicates)}
            getStopMarkerAndAddToMcg={(stop, mcg, t) => isTemplateStopInstance
              ? MarkerCreateFuncs.getTemplateStopServiceInstanceMarker(stop, mcg, t, undefined)
              : MarkerCreateFuncs.getTemplateStopServiceMarker(stop, mcg, t, undefined)}
            highLiteDuplicates={highLiteDuplicates}
            key={index}
            overlappingElements={elementGroup}
          />
        ))
        }
      </Map>
      {!noControls &&
        <MapControls
          highLiteDuplicates={highLiteDuplicates}
          setHighLiteDuplicates={setHighLiteDuplicates}
          setUseCluster={setUseCluster}
          setUseDuplicateSpiderfy={setUseDuplicateSpiderfy}
          useCluster={useCluster}
          useDuplicateSpiderfy={useDuplicateSpiderfy}
        />
      }
    </Fragment>
  );
});

TemplateStopMap.displayName = 'TemplateStopMap';

TemplateStopMap.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  noControls: PropTypes.bool,
  stops: PropTypes.array,
  isTemplateStopInstance: PropTypes.bool.isRequired,
};


export default withStyles(styles)(TemplateStopMap);

