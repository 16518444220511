import {useEffect, useState} from 'react';

const MAX_STOPS_FOR_DUPLICATE_SPIDERFY = 1000;

export default function useMapControls(numStops) {

  const [useCluster, setUseCluster] = useState(true);
  const [useDuplicateSpiderfy, setUseDuplicateSpiderfy] = useState(true);
  const [highLiteDuplicates, setHighLiteDuplicates] = useState(false);


  useEffect(() => {
    if (numStops > MAX_STOPS_FOR_DUPLICATE_SPIDERFY) {
      setUseDuplicateSpiderfy(false);
    }
  }, [numStops]);

  return {useCluster, setUseCluster, useDuplicateSpiderfy, setUseDuplicateSpiderfy, highLiteDuplicates, setHighLiteDuplicates};
}
