export default theme => ({
  good: {
    color: theme.palette.primary.main,
  },
  medium: {
    color: theme.palette.warning.main,
  },
  bad: {
    color: theme.palette.danger.main,
  },
});
