import React, {Fragment, useCallback, useEffect, useState} from 'react'
import {Button, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import pcGaernerApiService from "../../../../../services/backend/pcGaernerApiService";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {DropdownSelectSimple} from "../../../../../components";
import SentEmpties from "./SentEmpties";



function Synchronization(props) {

    const {selectedShipper, setSelectedShipper, shipperOptions, classes, t} = props;

    const [date, setDate] = useState(new Date());
    const [sentEmpties, setSentEmpties] = useState(null);
    
    const sendEmptiesToPcg = async () => {
        if(!selectedShipper || !date) {
            return;
        }
        try {
            const result = await pcGaernerApiService.sendEmptiesToPcG(selectedShipper, date);
            alert(t('receivedSendEmpties', {shipperName: selectedShipper, count: (result.data?.boxes?.count ?? 0) + (result.data?.empties?.count ?? 0)}))
        } catch (error) {
            alert(`${t('errorSendingEmptiesToPcG')}, ${error}`);
        }
    };
    
    const fetchEmptyOptionsForShipper = async () => {
        if(!selectedShipper || !date) {
            return;
        }
        try {
            const result = await pcGaernerApiService.fetchEmptyOptionsForShipper(selectedShipper);
            alert(t('receivedEmptyOptions', {shipperName: selectedShipper, count: result.data?.length ?? 0}))
        } catch (error) {
            alert(`${t('errorSendingEmptiesToPcG')}, ${error}`);
        }
    };

    const GetSentEmptiesByDateAndShipper = useCallback(async () => {
        if(!selectedShipper || !date) {
            return;
        }
        try {
            const result = await pcGaernerApiService.GetSentEmptiesByDateAndShipper(date, selectedShipper);
            result.data.sort((a, b) => new Date(a.date) - new Date(b.date));
            setSentEmpties(result.data);
        } catch (error) {
            alert(`${t('errorGetSentEmptiesByDateAndShipper')}, ${error}`);
        }
    }, [date, selectedShipper, setSentEmpties, t]);

    useEffect(() => {
        GetSentEmptiesByDateAndShipper();
    }, [date, selectedShipper, GetSentEmptiesByDateAndShipper]);

    return (
        <div className={classes.root}>
            <div>
            <DropdownSelectSimple
                error={!selectedShipper}
                name={t('shipper')}
                options={shipperOptions}
                required
                setValue={(value) => setSelectedShipper(value)}
                sort
                value={selectedShipper}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    cancelLabel={t('dialogCancel')}
                    format="dd.MM.yyyy"
                    id="deliveryDate"
                    label={t('deliveryDate')}
                    margin="normal"
                    onChange={(event) => {
                        setDate(new Date(event))
                    }}
                    value={date}
                    className={classes.filter}
                />
            </MuiPickersUtilsProvider>
            </div>
            <Fragment>
                <Button
                    className={classes.button}
                    color="primary"
                    onClick={() => {
                        if (window.confirm(t('confirmSendEmptiesToPcg', {shipperName: selectedShipper, date: date.toLocaleDateString()}))) sendEmptiesToPcg()
                    }}
                    variant="contained"
                    disabled={!selectedShipper || !date}
                >
                    {t('sendEmptiesForShipperAndDate')}
                </Button>
                <br/>
                <Button
                    className={classes.button}
                    color="primary"
                    onClick={() => {
                        if (window.confirm(t('confirmFetchEmptyOptions', {shipperName: selectedShipper}))) fetchEmptyOptionsForShipper()
                    }}
                    variant="contained"
                    disabled={!selectedShipper}
                >
                    {t('fetchEmptyOptionsForShipper')}
                </Button>
            </Fragment>
            <Fragment>
                { sentEmpties &&
                    <>
                        <Typography variant={'h4'} className={classes.spacing}>
                            {t('sentEmpties')}
                        </Typography>
                        <br/>
                        <SentEmpties
                            className={classes.spacing}
                            sentEmpties={sentEmpties}
                        />
                    </>
                }
            </Fragment>
        </div>
    );

}

Synchronization.propTypes = {
    selectedShipper: PropTypes.string.isRequired,
    setSelectedShipper: PropTypes.func.isRequired,
    shipperOptions: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(Synchronization);
