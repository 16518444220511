import { displayModes } from 'services/enums/displayModes';
import Columns from './columns';

// CARE: ALWAYS INCREMENT THE VERSION IF THE SETTINGS FOR A COLUMN CHANGE!
// Config explanation
// optional: determines if the hidden status can be changed by the user
// hidden: determines if the field is shown or hidden
// conditional: indicates that the hidden status can be overriden using the conditions dictionary of the useTableConfig hook
const DefaultConfig = {
  [displayModes.admin]: {
    version: 2,
    columns: {
      templateStopId: {
        columnName: Columns.templateStopId.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 1,
      },
      stopType: {
        columnName: Columns.stopType.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 2
      },
      shipper: {
        columnName: Columns.shipper.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 3
      },
      name: {
        columnName: Columns.name.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 4
      },
      address: {
        columnName: Columns.address.name,
        optional: true,
        hidden: false,
        conditional: false,
        order: 5
      },
      deliveryWindow: {
        columnName: Columns.deliveryWindow.name,
        optional: true,
        hidden: true,
        conditional: false,
        order: 6
      },
      options: {
        columnName: Columns.options.name,
        optional: false,
        hidden: false,
        conditional: true,
        order: 7
      },
    }
  },
  [displayModes.shipper]: {
    version: 2,
    columns: {
      templateStopId: {
        columnName: Columns.templateStopId.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 1,
      },
      name: {
        columnName: Columns.name.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 2
      },
      address: {
        columnName: Columns.address.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 3,
      },
      deliveryWindow: {
        columnName: Columns.deliveryWindow.name,
        optional: true,
        hidden: true,
        conditional: false,
        order: 4
      },
      options: {
        columnName: Columns.options.name,
        optional: false,
        hidden: false,
        conditional: true,
        order: 5
      },
    }
  },
}

export default DefaultConfig;