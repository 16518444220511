import React, {Fragment} from 'react';
import {Box, Collapse, IconButton, TableCell, TableRow, withStyles} from '@material-ui/core';
import {
  CheckCircle as OkIcon,
  Error as ErrorIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@material-ui/icons';
import {compose} from 'recompose';
import styles from './styles';
import PropTypes from 'prop-types';
import {DateService} from '../../../../../services/util/DateService';
import ShipperApiEventSmallDetail from '../ShipperApiEventSmallDetail';
import {getShipperApiEventTypeString} from '../../../../../services/enums/shipperApiEventType';
import {withTranslation} from 'react-i18next';


function ShipperApiEventTableRow(props) {

  const {classes, shipperApiEvent, t} = props;
  const [open, setOpen] = React.useState(false);
  const isErrorEvent = shipperApiEvent.statusCode > 299;

  return (
    <Fragment>
      <TableRow
        className={classes.root}
        hover
        selected={open}
      >
        <TableCell>
          <div className={classes.statusDisplay}>{isErrorEvent ? <ErrorIcon className={classes.error}/> :
            <OkIcon className={classes.ok}/>}{DateService.displayTime(shipperApiEvent.timeStamp)}</div>
        </TableCell>
        <TableCell>
          {DateService.displayDate(shipperApiEvent.deliveryDate)}
        </TableCell>
        <TableCell>
          {shipperApiEvent.shipperShortName ?? shipperApiEvent.internalShipperName ?? shipperApiEvent.shipper}
        </TableCell>
        <TableCell>
          {t(getShipperApiEventTypeString(shipperApiEvent.eventType))}
        </TableCell>
        <TableCell>
          <span className={isErrorEvent ? classes.error : classes.ok}>
            <strong>{shipperApiEvent.statusCode}</strong>
          </span>
        </TableCell>
        <TableCell>
          {shipperApiEvent.objectCount}
        </TableCell>

        <TableCell className={classes.lastTableCell}>
          <IconButton
            className={classes.buttonRight}
            onClick={() => setOpen(!open)}
            size="small"
          >
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          className={classes.largeTableCell}
          colSpan={7}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <Box margin={1}>
              <ShipperApiEventSmallDetail shipperApiEvent={shipperApiEvent}/>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

ShipperApiEventTableRow.propTypes = {
  classes: PropTypes.object.isRequired,
  shipperApiEvent: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(ShipperApiEventTableRow);
