import React, {useEffect, useState} from 'react'
import {Button, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MicroHubWrapper from './MicroHubWrapper';
import moment from 'moment';
import FinalStoppsWithoutPreviewStops from './FinalStoppsWithoutPreviewStops';
import {MigrationEventType} from '../../services/enums/migrationEventType';
import {PreviewToursService} from '../../services/backend/previewToursService';
import {TourService} from '../../services/backend/tourService';
import {DateService} from '../../services/util/DateService';
import {AuthService} from '../../services/auth/authService';
import {userRoles} from '../../services/auth/userRoles';
import {TextPlaceholderWrapper} from '../index';

function FinalDeadlineMigrationView(props) {

  const {
    classes,
    className,
    filter,
    finalPreviewStopIdentifiersAndMigrationState,
    finalStopsWithoutPreviewStop,
    declinedStops,
    previewData,
    runMigration,
    finalStopsWithoutPreviewStopMigrationState,
    t
  } = props;

  const rootClassName = classNames(classes.root, className);

  const [previewFinalData, setPreviewFinalData] = useState([]);
  const [finalStopsWithoutPreview, setFinalStopsWithoutPreview] = useState([]);
  const [previewDeadline, setPreviewDeadline] = useState(null);
  const [finalDeadline, setFinalDeadline] = useState(null);

  // load deadlines
  useEffect(() => {
    if (!filter.shipperName) return;
    PreviewToursService.getDeadlineForPreview(filter.shipperName, filter.date)
      .then(response => response.json())
      .then(response => setPreviewDeadline(new Date(response)));

    TourService.getDeadlineForFinal(filter.shipperName, filter.date)
      .then(response => response.json())
      .then(response => setFinalDeadline(new Date(response)));
  }, [filter.date, filter.shipperName]);

  // join final and preview stops to get migration status
  useEffect(() => {
    const finalDataMap = finalPreviewStopIdentifiersAndMigrationState.reduce((map, keyValuePair) => {
      // key = previewStopIdentifier, value = isMigrated;
      map[keyValuePair.key] = keyValuePair.value;
      return map;
    }, {});
    const declinedStopsMap = declinedStops.reduce((map, declinedStops) => {
      map[declinedStops.previewStopIdentifier] = declinedStops;
      return map;
    }, {});

    previewData.forEach(carrierHub => {
      carrierHub.tours.forEach(tour => {
        tour.stops.forEach(stop => {
          stop['hasFinalStop'] = finalDataMap[stop.identifier] !== undefined;
          stop['isMigrated'] = finalDataMap[stop.identifier];
          stop['hasDeclinedStop'] = declinedStopsMap[stop.identifier] !== undefined;
        })
      });
      carrierHub.stopsWithoutTour.forEach(stop => {
        stop['hasFinalStop'] = finalDataMap[stop.identifier] !== undefined;
        stop['isMigrated'] = finalDataMap[stop.identifier];
        stop['hasDeclinedStop'] = declinedStopsMap[stop.identifier] !== undefined;
      })
    });
    setPreviewFinalData(previewData);
  }, [previewData, finalPreviewStopIdentifiersAndMigrationState, declinedStops]);
  // get migration status of final stopps without preview data

  useEffect(() => {
    if (!finalStopsWithoutPreviewStop?.length) return;
    const finalDataMap = finalStopsWithoutPreviewStopMigrationState.reduce((map, keyValuePair) => {
      // key = tourStopId, value = isMigrated;
      map[keyValuePair.key] = keyValuePair.value;
      return map;
    }, {});
    finalStopsWithoutPreviewStop.forEach(stop => {
      stop['isMigrated'] = finalDataMap[stop.tourStopId];
    });
    setFinalStopsWithoutPreview(finalStopsWithoutPreviewStop);
  }, [finalStopsWithoutPreviewStop, finalStopsWithoutPreviewStopMigrationState])

  const migrateTour = async (tourId) => {
    const request = {
      migrationEventType: MigrationEventType.Tour,
      migrationDate: filter.date,
      previewTourId: tourId,
      shipperName: filter.shipperName,
    }
    await runMigration(request);
  };

  const migrateHub = async (carrierName, microHubName) => {
    const request = {
      migrationEventType: MigrationEventType.MicroHub,
      migrationDate: filter.date,
      shipperName: filter.shipperName,
      carrierName: carrierName,
      microHubName: microHubName
    }
    await runMigration(request);
  }

  const migrateShipper = async () => {
    const request = {
      migrationEventType: MigrationEventType.Shipper,
      migrationDate: filter.date,
      shipperName: filter.shipperName,
    }
    await runMigration(request);
  }

  const migrateStopsWithoutPreview = async () => {
    const request = {
      migrationEventType: MigrationEventType.StopsWithoutPreview,
      migrationDate: filter.date,
      shipperName: filter.shipperName,
    }
    await runMigration(request);
  }

  const finalDeadlineElapsed = Boolean(finalDeadline && moment(new Date(finalDeadline)).isBefore(moment.now()));
  const previewDeadlineElapsed = Boolean(previewDeadline && moment(new Date(previewDeadline)).isBefore(moment.now()));

  return (
    <div className={rootClassName}>
      <div className={classes.deadlineRow}>
        <div className={classes.deadlineRow}>
          {!previewDeadlineElapsed &&
          <Typography className={classNames(classes.deadlineInfo, {[classes.deadlineWarn]: !previewDeadlineElapsed})}>{t('canMigrateFrom')}:&nbsp;{DateService.displayDateTime(previewDeadline)}</Typography>
          }
          {
            previewDeadlineElapsed && !finalDeadlineElapsed &&
            <Typography className={classNames(classes.deadlineInfo, {[classes.deadlineWarn]: finalDeadlineElapsed})}>{t('canMigrateUntil')}:&nbsp;{DateService.displayDateTime(finalDeadline)}</Typography>
          }
          {
            finalDeadlineElapsed &&
            <Typography className={classNames(classes.deadlineInfo, classes.deadlineWarn)}>{t('migrationNotPossibleDeadline')}</Typography>
          }
        </div>
        <Button
          color="primary"
          disabled={!previewDeadlineElapsed || finalDeadlineElapsed}
          onClick={migrateShipper}
          style={AuthService.getUserRole() !== userRoles.administration ? {display: 'none'} : {}}
          variant={'contained'}
        >
          {t('migrate')}
        </Button>
      </div>
      <TextPlaceholderWrapper
        active={!filter.carrierName}
        text={t('finalDeadlineMigrationNoCarrierSelected')}
      >
        {previewFinalData.map((hubWithToursAndStops, index) =>
          (
            <MicroHubWrapper
              className={classes.microHubWrapper}
              finalDeadlineElapsed={finalDeadlineElapsed}
              key={index}
              microHubWithToursAndStops={hubWithToursAndStops}
              migrateHub={migrateHub}
              migrateTour={migrateTour}
              previewDeadlineElapsed={previewDeadlineElapsed}
            />
          ))
        }
      </TextPlaceholderWrapper>
      {finalStopsWithoutPreview?.length > 0 &&
      <FinalStoppsWithoutPreviewStops
        className={classes.microHubWrapper}
        finalDeadlineElapsed={finalDeadlineElapsed}
        finalStopsWithoutPreview={finalStopsWithoutPreview}
        migrate={migrateStopsWithoutPreview}
        previewDeadlineElapsed={previewDeadlineElapsed}
      />
      }
    </div>
  );
}


FinalDeadlineMigrationView.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  declinedStops: PropTypes.array.isRequired,
  filter: PropTypes.object.isRequired,
  finalPreviewStopIdentifiersAndMigrationState: PropTypes.array.isRequired,
  finalStopsWithoutPreviewStop: PropTypes.array,
  finalStopsWithoutPreviewStopMigrationState: PropTypes.array,
  previewData: PropTypes.array.isRequired,
  runMigration: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(FinalDeadlineMigrationView);
