import React, {Fragment, useState} from 'react'
import {Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon} from '@material-ui/icons';
import StopTable from '../StopTable';
import TourTableRow from '../TourTableRow';
import MigrationStatus from '../MigrationStatus';
import ProgressDisplay from '../ProgressDisplay';
import {getMigrationStatus} from '../../../services/util/migrationStatusHelper';


function TourTable(props) {

  const {classes, t, toursWithStops, className, stopsWithoutTour, migrateTour, previewDeadlineElapsed, finalDeadlineElapsed} = props;

  const rootClassName = classNames(className, classes.root);
  const [stopsWithoutTourOpen, setStopsWithoutTourOpen] = useState(false);

  // calculate counts
  const totalStopsCount = stopsWithoutTour.length;
  const migratedStopsCount = stopsWithoutTour.filter(s => s.isMigrated).length;
  const stopsWithFinalStopCount = stopsWithoutTour.filter(s => s.hasFinalStop).length;
  const stopWithOnlyDeclinedStopCount = stopsWithoutTour.filter(s => s.hasDeclinedStop && !s.hasFinalStop).length;

  const {
    migrationNotPossible,
    migrated,
    readyForMigration,
    migrationPossible,
    additionalMigrationRequired,
    declinedStopsPresent
  } = getMigrationStatus(totalStopsCount, migratedStopsCount, stopsWithFinalStopCount, previewDeadlineElapsed, finalDeadlineElapsed, stopWithOnlyDeclinedStopCount);


  return (
    <Table
      className={rootClassName}
      size="small"
    >
      <TableHead>
        <TableRow>
          <TableCell>{t('previewTourID')}</TableCell>
          <TableCell>{t('migrationStatus')}</TableCell>
          <TableCell>{t('migrationStoppsPreview')}</TableCell>
          <TableCell>{t('migrationStoppsMigrated')}</TableCell>
          <TableCell>{t('migrationStoppsFinal')}</TableCell>
          <TableCell>{t('migration')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {toursWithStops.map((tourWithStops, index) => (
          <TourTableRow
            finalDeadlineElapsed={finalDeadlineElapsed}
            key={index}
            migrateTour={migrateTour}
            previewDeadlineElapsed={previewDeadlineElapsed}
            tourWithStops={tourWithStops}
          />
        ))}
        {stopsWithoutTour && stopsWithoutTour.length > 0 &&
        <Fragment>
          <TableRow
            className={classes.toursWithoutStopsRow}
            hover={previewDeadlineElapsed && !stopsWithoutTourOpen && !finalDeadlineElapsed}
            selected={stopsWithoutTourOpen && previewDeadlineElapsed && !finalDeadlineElapsed}
          >
            <TableCell>
              <Typography>
                <strong>{t('previewStopsWithoutTour')}</strong>
              </Typography>
            </TableCell>
            <TableCell>
              <MigrationStatus
                additionalMigrationRequired={additionalMigrationRequired}
                declinedStop={declinedStopsPresent}
                finalDeadlineElapsed={finalDeadlineElapsed}
                migrated={migrated}
                migrationNotPossible={migrationNotPossible}
                migrationPossible={migrationPossible}
                previewDeadlineNotElapsed={!previewDeadlineElapsed}
                readyForMigration={readyForMigration}
              />
            </TableCell>
            <TableCell>{totalStopsCount}</TableCell>
            <TableCell>{migratedStopsCount}</TableCell>
            <TableCell>
              <ProgressDisplay
                currentValue={stopsWithFinalStopCount}
                maxValue={totalStopsCount}
              />
            </TableCell>
            <TableCell className={classes.lastTableCell}>
              <div/>
              <IconButton
                onClick={() => setStopsWithoutTourOpen(!stopsWithoutTourOpen)}
                size="small"
              >
                {stopsWithoutTourOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              colSpan={8}
              style={{paddingBottom: 0, paddingTop: 0}}
            >
              <Collapse
                in={stopsWithoutTourOpen}
                timeout="auto"
                unmountOnExit
              >
                <Box margin={1}>
                  <StopTable
                    className={classes.stopTable}
                    finalDeadlineElapsed={finalDeadlineElapsed}
                    previewDeadlineElapsed={previewDeadlineElapsed}
                    stops={stopsWithoutTour}
                  />
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </Fragment>
        }
      </TableBody>
    </Table>
  );
}

TourTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  finalDeadlineElapsed: PropTypes.bool.isRequired,
  migrateTour: PropTypes.func.isRequired,
  previewDeadlineElapsed: PropTypes.bool.isRequired,
  stopsWithoutTour: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  toursWithStops: PropTypes.array.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TourTable);
