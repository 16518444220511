import React, {Fragment, useState} from 'react'
import {Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Tooltip, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {DayOfWeek, getDayOfWeekString} from '../../../../../services/enums/dayOfWeekHelper';
import {ShipperAllowedOnHubService} from '../../../../../services/util/shipperAllowedOnHubHelper';
import {Cancel as CancelIcon, Save as SaveIcon, WarningRounded as WarnIcon} from '@material-ui/icons';
import {onCloseWithBackdropCurry} from '../../../../../services/util/helperFuncs';

function UpdatePlansAtDaysDialog(props) {

  const {classes, t, handleCancel, handleClose, updatePlansAtDaysDialogState, allMicroHubData, hubCarrierData, selectedCarrierName, editedMicroHubPlan} = props;
  const [days, setDays] = useState(null);

  if (!days && updatePlansAtDaysDialogState.open) {
    const newDays = {};
    Object.getOwnPropertyNames(DayOfWeek).forEach((day => {
      newDays[day] = getDayOfWeekString(DayOfWeek[day]) === getDayOfWeekString(updatePlansAtDaysDialogState.preselectedDay);
    }));
    setDays(newDays);
  }

  const handleOk = () => {
    const selectedDays = Object.getOwnPropertyNames(days).filter(day => days[day] === true).map(day => DayOfWeek[day]);
    handleClose(selectedDays);
    setDays(null);
  };

  const handleNotOk = () => {
    handleCancel();
    setDays(null);
  };

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      maxWidth={'sm'}
      onClose={onCloseWithBackdropCurry(handleNotOk)}
      open={updatePlansAtDaysDialogState.open}
    >
      <DialogTitle>{t('microHubPlanSelectDaysToUpdatePlan')}</DialogTitle>
      <DialogContent>
        {
          days &&
          <Fragment>
            {
              Object.getOwnPropertyNames(days).map((day, index) => {
                return (
                  <FormControl
                    className={classes.formControl}
                    key={index}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={days[day]}
                          color="primary"
                          disabled={getDayOfWeekString(DayOfWeek[day]) === getDayOfWeekString(updatePlansAtDaysDialogState.preselectedDay) ||
                            editedMicroHubPlan.boxZones.some(zone => zone.shipperInfos.some(shipper => !ShipperAllowedOnHubService.checkShipperAllowedDayStringIgnoreOpen(hubCarrierData, selectedCarrierName, DayOfWeek[day], shipper.name)))}
                          onChange={(event) => {
                            const newDays = JSON.parse(JSON.stringify(days));
                            newDays[day] = event.target.checked;
                            setDays(newDays);
                          }}
                        />
                      }
                      label={t(getDayOfWeekString(DayOfWeek[day]))}
                    />
                    {(editedMicroHubPlan ? editedMicroHubPlan.boxZones.some(zone => zone.shipperInfos.some(shipper => !ShipperAllowedOnHubService.checkShipperAllowedDayStringIgnoreOpen(hubCarrierData, selectedCarrierName, DayOfWeek[day], shipper.name))) : false) ?
                      <Tooltip title={t('shipperNotAllowed')}>
                        <WarnIcon className={classes.dangerColor}/>
                      </Tooltip>
                      :
                      allMicroHubData.some(plan => plan.dayOfWeek === DayOfWeek[day]) && getDayOfWeekString(DayOfWeek[day]) !== getDayOfWeekString(updatePlansAtDaysDialogState.preselectedDay) &&
                      <Tooltip title={t('microHubPlanWillBeOverridden')}>
                        <WarnIcon className={classes.warningColor}/>
                      </Tooltip>
                    }
                  </FormControl>
                )
              })
            }
          </Fragment>
        }
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={handleNotOk}
        >
          <CancelIcon/>&nbsp;{t('cancel')}
        </Button>
        <Button
          color="primary"
          disabled={!days || Object.getOwnPropertyNames(days).filter(day => days[day] === true).length < 1}
          onClick={handleOk}
        >
          <SaveIcon/>&nbsp;{t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

UpdatePlansAtDaysDialog.propTypes = {
  allMicroHubData: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updatePlansAtDaysDialogState: PropTypes.object.isRequired,
  hubCarrierData: PropTypes.object.isRequired,
  selectedCarrierName: PropTypes.string.isRequired,
  editedMicroHubPlan: PropTypes.object
};


export default compose(withStyles(styles), withTranslation())(UpdatePlansAtDaysDialog);
