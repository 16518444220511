export default theme => ({
  root: {},
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  button: {
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(3),
  },
  iconSpacing: {
    marginRight: theme.spacing(1),
  }
});
