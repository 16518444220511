export default theme => ({
  dndContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
    listStyle: 'none',
  },
  draggableItem: {
    width: '100%'
  },
  chip: {
    margin: 2,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  iconButton: {
    display: 'flex',
  }
});

