import React from 'react'
import {Table, TableBody, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {
    Pagination,
    Portlet,
    PortletContent,
    TableCellWithConfig,
    TableSettings,
    TextPlaceholderWrapper
} from '../../index'
import useTablePagination from '../../../hooks/useTablePagination';
import useTableConfig from '../../../hooks/useTableConfig';
import DefaultConfig from './config';
import {getTableKey} from '../../../services/util/tableConfigHelper';
import {TableKind} from '../../../services/enums/TableKind';
import {displayModes} from '../../../services/enums/displayModes';
import Columns from './columns';

function MicroHubSensorConfigTable(props) {

    const {classes, className, microHubSensorConfigs, t, selectMicroHubSensorConfig, deleteMicroHubSensorConfig, selectedMicroHubSensorConfig, displayMode} = props;

    const {
        config,
        columnConfigs,
        updateConfig
    } = useTableConfig(
        DefaultConfig,
        displayMode,
        getTableKey(TableKind.microHubSensorConfigTable, displayMode),
    )

    const {paginationProps, pagedObjects} = useTablePagination(microHubSensorConfigs);

    const rootClassName = classNames(classes.root, className);

    return (
        <TextPlaceholderWrapper
            active={!microHubSensorConfigs?.length}
            text={t('noMicroHubSensorConfigFound')}
        >
            <Portlet className={rootClassName}>
                <PortletContent noPadding>
                    <TableSettings
                        updateConfig={updateConfig}
                        tableConfig={config}
                        columns={Columns}
                    />
                    <Table size="medium">
                        <TableHead>
                            <TableRow>
                                {columnConfigs.map(columnConfig => (
                                    <TableCellWithConfig
                                        key={columnConfig.order}
                                        config={columnConfig}
                                    >
                                        {Columns[columnConfig.columnName].header({t})}
                                    </TableCellWithConfig>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pagedObjects.map((microHubSensorConfig, index) => (
                                <TableRow
                                    className={classNames(classes.tableRow, 'cursorPointer')}
                                    hover
                                    key={index}
                                    onClick={() => selectMicroHubSensorConfig(microHubSensorConfig)}
                                    selected={selectedMicroHubSensorConfig && selectedMicroHubSensorConfig.id === microHubSensorConfig.id}
                                >
                                    {columnConfigs.map(columnConfig => (
                                        <TableCellWithConfig
                                            key={columnConfig.order}
                                            config={columnConfig}
                                            className={classes.tableCell}
                                        >
                                            {Columns[columnConfig.columnName].body({
                                                microHubSensorConfig, classes, t, deleteMicroHubSensorConfig
                                            })}
                                        </TableCellWithConfig>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Pagination paginationProps={paginationProps}/>
                </PortletContent>
            </Portlet>
        </TextPlaceholderWrapper>
    );
}


MicroHubSensorConfigTable.propTypes = {
    microHubSensorConfigs: PropTypes.array.isRequired,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    deleteMicroHubSensorConfig: PropTypes.func,
    displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
    selectMicroHubSensorConfig: PropTypes.func.isRequired,
    selectedMicroHubSensorConfig: PropTypes.object,
    t: PropTypes.func.isRequired,
};

MicroHubSensorConfigTable.defaultProps = {
    microHubSensorConfigs: [],
};

export default compose(withStyles(styles), withTranslation())(MicroHubSensorConfigTable);
