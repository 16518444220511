export default theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  lastTableCell: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  deadlinesInvalid: {
    backgroundColor: theme.palette.common.muted,
  },
  warnIcon: {
    color: theme.palette.warning.main,
  },
  dangerIcon: {
    color: theme.palette.danger.main
  },
  neutralIcon: {
    color: theme.palette.common.muted,
  }
});

