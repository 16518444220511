import React, {Fragment, useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import moment from 'moment';
import Timeline, {DateHeader, SidebarHeader, TimelineHeaders, TimelineMarkers, TodayMarker} from 'react-calendar-timeline'
// make sure you include the timeline stylesheet or the timeline will not be styled
import 'react-calendar-timeline/lib/Timeline.css'

class VehicleDriverPair {
  constructor(vehicleLicensePlate, driverDisplayName) {
    this.vehicle = vehicleLicensePlate;
    this.driver = driverDisplayName;
    this.tours = []
  }
}

function DispositionTiming(props) {

  const {classes, t, className, approvedTours, startFrom, startTo, rerender} = props;

  const [groups, setGroups] = useState([]);
  const [items, setItems] = useState([]);

  const rootClassName = classNames(classes.root, className);

  useEffect(() => {
    const vehicleDriverPairs = [];
    approvedTours.forEach(tour => {
      const matchingPairs = vehicleDriverPairs.filter(pair => pair.vehicle === tour.vehicleLicensePlate && pair.driver === tour.driverDisplayName);
      if (matchingPairs && matchingPairs.length > 0) {
        matchingPairs[0].tours.push(tour);
      } else {
        const newPair = new VehicleDriverPair(tour.vehicleLicensePlate, tour.driverDisplayName);
        newPair.tours.push(tour);
        vehicleDriverPairs.push(newPair);
      }
    });

    const newItems = [];
    const newGroups = [];

    vehicleDriverPairs.forEach((pair, index) => {
      newGroups.push({
        id: index,
        title: pair.driver + ' / ' + pair.vehicle,
      });
      pair.tours.forEach((tour) => {
        const tourTimingCritical = tour.approved && new Date(tour.planedTourDelivery) < new Date() && !tour.tourStart;
        newItems.push({
          id: tour.tourId,
          group: index,
          itemProps: {
            className: classes.tourItem,
            style: {
              background: tour.color,
              border: 'inherited',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              animation: tourTimingCritical ? 'blinkOpacity 2s infinite' : ''
            }
          },
          title: tour.tourName ? tour.tourName : tour.tourId.toString(),
          start_time: moment(tour.planedTourDelivery),
          end_time: moment(tour.planedTourDelivery).add(tour.duration, 'minutes').toDate()
        })
      });
    });

    setGroups(newGroups);
    setItems(newItems);
    // eslint-disable-next-line
  }, [approvedTours]);


  return (
    <Fragment>
      {
        !rerender &&
        <div className={rootClassName}>
          <Timeline
            canMove={false}
            canResize={false}
            defaultTimeEnd={moment(startTo)}
            defaultTimeStart={moment(startFrom)}
            groups={groups}
            items={items}
            itemTouchSendsClick={false}
            showCursorLine
            sidebarWidth={300}
            stackItems
            timeSteps={{
              second: 1,
              minute: 15,
              hour: 1,
              day: 1,
              month: 1,
              year: 1
            }}
          >
            <TimelineHeaders className="sticky">
              <SidebarHeader>
                {({getRootProps}) => {
                  return (
                    <div
                      className={classes.sidebarHeader}
                      {...getRootProps()}
                    >
                      {t('driversAndVehicles')}
                    </div>
                  );
                }}
              </SidebarHeader>
              <DateHeader
                className={classes.dateHeader}
                unit="primaryHeader"
              />
              <DateHeader/>
            </TimelineHeaders>
            <TimelineMarkers>
              {/*Today marker updates every 10 seconds*/}
              <TodayMarker interval={10000}/>
            </TimelineMarkers>
          </Timeline>
        </div>
      }
    </Fragment>
  );
}


DispositionTiming.propTypes = {
  approvedTours: PropTypes.array.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  rerender: PropTypes.bool,
  startFrom: PropTypes.object.isRequired,
  startTo: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(DispositionTiming);
