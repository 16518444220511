import {TourAmPmTag, TourIdLabel, TourStoragePlaces} from '../../index';
import React from 'react';
import {Button, Checkbox, Tooltip} from '@material-ui/core';
import moment from 'moment';
import {TourStatus} from '../../../services/enums/TourStatus';
import classNames from 'classnames';
import {
  DoneAll as SeenIcon,
  FaceOutlined as DriverIcon,
  MotorcycleOutlined as VehicleIcon,
  Schedule as TourStartIcon
} from '@material-ui/icons';
import {DateService} from '../../../services/util/DateService';

const Columns = {
  tourId: {
    name: 'tourId',
    header: ({t}) => t('tourId'),
    body: ({tour, classes}) =>
      <div className={classes.idTableCell}>
        <TourAmPmTag
          className={classes.amPmTag}
          estimatedTourDeliveryWindow={tour.estimatedTourDeliveryWindow}
        />
        <TourIdLabel tour={tour}/>
      </div>
  },
  microHub: {
    name: 'microHub',
    header: ({t}) => t('microHub'),
    body: ({tour}) => tour.microHubName
  },
  numStops: {
    name: 'numStops',
    header: ({t}) => t('numStops'),
    body: ({tour}) => tour.stops.length
  },
  storagePlace: {
    name: 'storagePlace',
    header: ({t}) => t('storagePlace'),
    body: ({tour}) => <TourStoragePlaces
      maxDisplay={4}
      tour={tour}
    />
  },
  duration: {
    name: 'duration',
    header: ({t}) => t('duration'),
    body: ({tour}) => tour.duration
  },
  driver: {
    name: 'driver',
    header: ({t}) => t('driver'),
    body: ({tour, selectDriver, classes}) => (
      <Button
        className={classes.button}
        color="primary"
        disabled={tour.approved}
        onClick={(event) => selectDriver(tour, event)}
        size="small"
        startIcon={<DriverIcon/>}
        style={{margin: 0}}
        variant="contained"
      >
        {tour.driverDisplayName ? tour.driverDisplayName : '-'}
      </Button>
    )
  },
  vehicle: {
    name: 'vehicle',
    header: ({t}) => t('vehicle'),
    body: ({tour, classes, selectVehicle}) => (
      <Button
        className={classes.button}
        color="primary"
        disabled={tour.approved}
        onClick={(event) => selectVehicle(tour, event)}
        size="small"
        startIcon={<VehicleIcon/>}
        style={{margin: 0}}
        variant="contained"
      >
        {tour.vehicleLicensePlate ? tour.vehicleLicensePlate : '-'}
      </Button>
    )
  },
  planedTourDelivery: {
    name: 'planedTourDelivery',
    header: ({t}) => t('planedTourDelivery'),
    body: ({tour, selectTourStart, classes}) => {
      const tourStartInPast = moment(tour.planedTourDelivery).isBefore(moment.now());
      return (
        <Button
          className={classNames(classes.button, {[classes.warnButton]: tourStartInPast})}
          color="primary"
          disabled={tour.approved}
          onClick={(event) => selectTourStart(tour, event)}
          size="small"
          startIcon={<TourStartIcon/>}
          style={{margin: 0}}
          variant="contained"
        >
          {tour.planedTourDelivery ? `${DateService.displayDate(tour.planedTourDelivery)} ${DateService.displayTime(tour.planedTourDelivery)}` : '-'}
        </Button>
      );
    },
  },
  approved: {
    name: 'approved',
    header: ({t}) => t('approved'),
    body: ({tour, classes, t, approveTour}) => {
      const canDisapproveInTable = (moment(tour.planedTourDelivery) - moment.now() - 60 * 60 * 1000) > 0 && tour.tourStatus === TourStatus.Planed;
      const canApprove = tour.microHubName && tour.driverEmail && tour.vehicleLicensePlate && tour.planedTourDelivery;
      let approveToolTip = '';
      if (tour.approved && canDisapproveInTable) approveToolTip = t('revokeApprove');
      if (tour.approved && !canDisapproveInTable) approveToolTip = t('canNotDisapproveTourInTable');
      if (!tour.approved && canApprove) approveToolTip = t('approve');
      if (!tour.approved && !canApprove) approveToolTip = t('missingApproveRequirement');

      return (
        <div className={classes.approvedTableCell}>
          <Tooltip
            title={approveToolTip}
          >
            <div>
              <Checkbox
                checked={tour.approved}
                className={classNames(classes.button, classes.secondaryColor)}
                color="default"
                disabled={(tour.approved && !canDisapproveInTable) || (!tour.approved && !canApprove)}
                onChange={(event) => {
                  approveTour(tour, event.target.checked, event)
                }}
                onClick={event => event.stopPropagation()}
                style={{margin: 0}}
              />
            </div>
          </Tooltip>
          {tour.seenByDriver &&
            <Tooltip title={t('seenByDriver')}>
              <SeenIcon
                className={classes.seenStatus}
                color="primary"
              />
            </Tooltip>
          }
        </div>
      )
    }
  },
}

export default Columns;