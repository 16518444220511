import React, {Fragment} from 'react'
import {Button, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {
  getMicroHubElementTypeString,
  getOrientationString,
  MicroHubElementTypes
} from '../../../../../services/hubAdministrationHelper/hubAdministrationHelper';
import classNames from 'classnames'
import {ControlPointDuplicate as DuplicateElementIcon, Delete as DeleteIcon, Edit as EditElementIcon} from '@material-ui/icons';


function MicroHubPlanElementView(props) {

  const {classes, t, selectedElement, className, readonly, deleteElement, canDeleteElement, updateSelectedElement, duplicateSelectedElement} = props;
  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      {(() => {
        switch (selectedElement.type) {
          case MicroHubElementTypes.hubBase:
            return (
              <Fragment>
                <Typography variant="h5">
                  <strong>{t(getMicroHubElementTypeString(selectedElement.type))}</strong>
                </Typography>
                <Typography variant="body1">
                  <strong className={classes.elementDetail}>{t('microHubElementWidth')}</strong>:&nbsp;{selectedElement.width}<br/>
                  <strong className={classes.elementDetail}>{t('microHubElementDepth')}</strong>:&nbsp;{selectedElement.depth}
                </Typography>
              </Fragment>
            );
          case MicroHubElementTypes.door:
            return (
              <Fragment>
                <Typography variant="h5">
                  <strong>{t(getMicroHubElementTypeString(selectedElement.type))}</strong>
                </Typography>
                <Typography variant="body1">
                  <strong className={classes.elementDetail}>{t('microHubElementDoorSize')}</strong>:&nbsp;{selectedElement.doorSize}<br/>
                  <strong className={classes.elementDetail}>{t('microHubElementOrientation')}</strong>:&nbsp;{t(getOrientationString(selectedElement.orientation))}
                </Typography>
              </Fragment>
            );
          case MicroHubElementTypes.boxZone:
            return (
              <Fragment>
                <Typography variant="h5">
                  <strong>{`${t(getMicroHubElementTypeString(selectedElement.type))} ${selectedElement.shipperInfos.map(info => `${info.shortName}-${info.order}`).join(' / ')}`}</strong>
                </Typography>
                <Typography variant="body1">
                  <strong className={classes.elementDetail}>{t('microHubElementWidth')}</strong>:&nbsp;{selectedElement.width}<br/>
                  <strong className={classes.elementDetail}>{t('microHubElementDepth')}</strong>:&nbsp;{selectedElement.depth}<br/>
                  <strong className={classes.elementDetail}>{t('microHubElementStackHeight')}</strong>:&nbsp;{selectedElement.stackHeight}<br/>
                  <br/>
                  <strong className={classes.elementDetail}>{t('shipperOnZone')}</strong>
                </Typography>
                {selectedElement.shipperInfos.map((info, index) => {
                  return (
                    <Typography
                      className={classes.elementDetail}
                      key={index}
                      variant="body1"
                    >
                      <strong className={classes.elementDetail}>{t('name')}</strong>:&nbsp;{info.name}<br/>
                      <strong className={classes.elementDetail}>{t('shortNameWithoutHelp')}</strong>:&nbsp;{info.shortName}<br/>
                      <strong className={classes.elementDetail}>{t('microHubElementOrder')}</strong>:&nbsp;{info.order}<br/>
                    </Typography>
                  );
                })}
              </Fragment>
            );
          case MicroHubElementTypes.beverageInsertZone:
            return (
              <Fragment>
                <Typography variant="h5">
                  <strong>{`${t(getMicroHubElementTypeString(selectedElement.type))} ${selectedElement.order}`}</strong>
                </Typography>
                <Typography variant="body1">
                  <strong className={classes.elementDetail}>{t('microHubElementWidth')}</strong>:&nbsp;{selectedElement.width}<br/>
                  <strong className={classes.elementDetail}>{t('microHubElementDepth')}</strong>:&nbsp;{selectedElement.depth}<br/>
                  <strong className={classes.elementDetail}>{t('microHubElementOrder')}</strong>:&nbsp;{selectedElement.order}
                </Typography>
              </Fragment>
            );
          case MicroHubElementTypes.wall:
            return (
              <Fragment>
                <Typography variant="h5">
                  <strong>{t(getMicroHubElementTypeString(selectedElement.type))}</strong>
                </Typography>
                <Typography variant="body1">
                  <strong className={classes.elementDetail}>{t('microHubElementWallSize')}</strong>:&nbsp;{selectedElement.wallSize}<br/>
                  <strong className={classes.elementDetail}>{t('microHubElementOrientation')}</strong>:&nbsp;{t(getOrientationString(selectedElement.orientation))}
                </Typography>
              </Fragment>
            );
          case MicroHubElementTypes.blockerZone:
            return (
              <Fragment>
                <Typography variant="h5">
                  <strong>{t(getMicroHubElementTypeString(selectedElement.type))}</strong>
                </Typography>
                <Typography variant="body1">
                  <strong className={classes.elementDetail}>{t('microHubElementWidth')}</strong>:&nbsp;{selectedElement.width}<br/>
                  <strong className={classes.elementDetail}>{t('microHubElementDepth')}</strong>:&nbsp;{selectedElement.depth}<br/>
                  <strong className={classes.elementDetail}>{t('microHubElementText')}</strong>:&nbsp;{selectedElement.text}
                </Typography>
              </Fragment>
            );
          default :
            return (
              <div>{t('microHubElementTypeUnknown')}</div>
            );
        }
      })()
      }
      {!readonly &&
      <Fragment>
        <Button
          className={classes.controlButton}
          color="primary"
          onClick={updateSelectedElement}
          title={t('microHubPlanUpdateSelectedElement')}
          variant="contained"
        >
          <EditElementIcon/>&nbsp;{t('microHubPlanUpdateSelectedElement')}
        </Button>
        <Button
          className={classes.controlButton}
          color="primary"
          disabled={selectedElement.type === MicroHubElementTypes.hubBase || selectedElement.type === MicroHubElementTypes.door}
          onClick={duplicateSelectedElement}
          title={t('microHubPlanDuplicateSelectedElement')}
          variant="contained"
        >
          <DuplicateElementIcon/>&nbsp;{t('microHubPlanDuplicateSelectedElement')}
        </Button>
        <Button
          className={classNames(classes.controlButton, classes.deleteButton)}
          color="primary"
          disabled={!canDeleteElement}
          onClick={() => {
            deleteElement(selectedElement)
          }}
          title={t('microHubPlanDeleteSelectedElement')}
          variant="contained"
        >
          <DeleteIcon/>&nbsp;{t('microHubPlanDeleteSelectedElement')}
        </Button>
      </Fragment>
      }
    </div>
  );

}

MicroHubPlanElementView.propTypes = {
  canDeleteElement: PropTypes.bool.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deleteElement: PropTypes.func.isRequired,
  duplicateSelectedElement: PropTypes.func.isRequired,
  readonly: PropTypes.bool.isRequired,
  selectedElement: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  updateSelectedElement: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(MicroHubPlanElementView);
