import React from 'react'
import {IconButton} from '@material-ui/core';
import {
  DragIndicatorOutlined as DragIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon
} from '@material-ui/icons';
import {StoragePlaces} from '../../../../../../components';
import {roundCapacityValues} from '../../../../../../services/util/helperFuncs';
import {DateService} from '../../../../../../services/util/DateService';

const Columns = {
  previewOrder: {
    name: 'previewOrder',
    header: ({t}) => t('previewOrder'),
    body: ({stop, classes, dragHandleProps}) => (
      <div className={classes.flexBetweenCenter}>
        <div
          {...dragHandleProps}
          className={classes.dragIcon}
        >
          <DragIcon/>
        </div>
        {stop.previewOrder !== null ? stop.previewOrder : '-'}
      </div>
    )
  },
  stopNumber: {
    name: 'stopNumber',
    header: ({t}) => t('stopNumber'),
    body: ({stop}) => stop.stopNumber
  },
  storagePlace: {
    name: 'storagePlace',
    header: ({t}) => t('storagePlace'),
    body: ({stop}) => (
      <StoragePlaces
        maxDisplay={2}
        stop={stop}
      />
    )
  },
  address: {
    name: 'address',
    header: ({t}) => t('address'),
    body: ({stop}) => stop.address?.streetAndNumber || '-'
  },
  deliveryTimeWindowShipper: {
    name: 'deliveryTimeWindowShipper',
    header: ({t}) => t('deliveryTimeWindowShipper'),
    body: ({stop}) => stop.deliveryWindowShipper ? `${DateService.displayTime(stop?.deliveryWindowShipper?.from)} - ${DateService.displayTime(stop?.deliveryWindowShipper?.to)}` : '-'
  },
  boxAmount: {
    name: 'boxAmount',
    header: ({t}) => t('boxAmount'),
    body: ({stop}) => roundCapacityValues(stop.stopCapacities.boxAmount)
  },
  weight: {
    name: 'weight',
    header: ({t}) => t('weight'),
    body: ({stop}) => roundCapacityValues(stop.stopCapacities.weight)
  },
  volume: {
    name: 'volume',
    header: ({t, classes, handleShowOrHideAll, hiddenTourIds, updateConfig, config, columns}) => (
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        {t('volume')}
        <IconButton
          className={classes?.toggleButton}
          onClick={(event) => {
            event.stopPropagation();
            handleShowOrHideAll();
          }}
          size="small"
          title={hiddenTourIds.length === 0 ? t('hideAllFluentTableRows') : t('showAllFluentTableRows')}
        >
          {hiddenTourIds.length === 0 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
        </IconButton>
        {/*<TableSettings*/}
        {/*  updateConfig={updateConfig}*/}
        {/*  tableConfig={config}*/}
        {/*  columns={columns}*/}
        {/*  useNamesAsTranslateString*/}
        {/*  className={classes.tableSettings}*/}
        {/*/>*/}
      </div>
    ),
    body: ({stop, classes, snapshot, setOpen, t, open}) => (
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        {roundCapacityValues(stop.stopCapacities.volume)}
        {!snapshot.isDragging &&
          <IconButton
            className={classes.showDetailsButton}
            onClick={(event) => {
              event.stopPropagation();
              setOpen(!open);
            }}
            size="small"
            title={open ? t('hideStoppDetails') : t('showStoppDetails')}
          >
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton>
        }
      </div>
    )
  },
}

export default Columns;