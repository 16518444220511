import React, {Fragment} from 'react'
import {FormControl, Input, InputLabel, MenuItem, Select, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import {BoxTypeKind, getBoxTypeKindString} from '../../../services/enums/BoxTypeKinds';

function BoxTypeEdit(props) {

  const {classes, className, editedBoxType, t, setEditedBoxType, shipperShortName} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <Fragment>
      <div className={rootClassName}>
        <FormGroup
          className={classes.wrapperChild}
        >
          <FormControl className={classes.formControl}>
            <InputLabel
              htmlFor="boxTypeIdentifier"
              required
            >{t('boxTypeIdentifier')}</InputLabel>
            <Input
              error={!editedBoxType.identifier}
              id="boxTypeIdentifier"
              onChange={(event) => {
                setEditedBoxType({...editedBoxType, identifier: event.target.value})
              }}
              startAdornment={
                <p style={{margin: 0, paddingBottom: '3px'}}>{shipperShortName + '-'}</p>
              }
              value={editedBoxType.identifier}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel
              htmlFor="boxTypeKind"
              required
            >{t('boxTypeKind')}</InputLabel>
            <Select
              error={!editedBoxType.kind}
              input={<Input id="boxTypeKind"/>}
              onChange={event => setEditedBoxType({...editedBoxType, kind: event.target.value})}
              value={editedBoxType.kind}
            >
              {Object.getOwnPropertyNames(BoxTypeKind).map(propertyName => BoxTypeKind[propertyName]).map((boxTypeKind, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={boxTypeKind}
                  >
                    {t(getBoxTypeKindString(boxTypeKind))}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <FormControlLabel
              className={classes.formControl}
              control={
                <Checkbox
                  checked={editedBoxType.stackable}
                  color="secondary"
                  onChange={(event) => setEditedBoxType({...editedBoxType, stackable: event.target.checked})}
                  value={editedBoxType.stackable}
                />
              }
              label={t('boxTypeStackable')}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel
              htmlFor="boxTypeVolume"
              required
            >{t('boxTypeVolume')}</InputLabel>
            <Input
              error={editedBoxType.volume === '' || isNaN(editedBoxType.volume) || parseFloat(editedBoxType.volume) <= 0}
              id="boxTypeVolume"
              onChange={(event) => {
                setEditedBoxType({
                  ...editedBoxType,
                  volume: event.target.valueAsNumber
                });
              }}
              onClick={event => event.target.select()}
              onWheel={event => event.target.blur()}
              type="number"
              value={editedBoxType.volume}
            />
            {
              editedBoxType.height && editedBoxType.width && editedBoxType.depth &&
              <Typography variant="body1">
                {`${t('calculatedBoxTypeVolume')}: ${Math.round(100 * (((editedBoxType.height / 10) * (editedBoxType.width / 10) * (editedBoxType.depth / 10)) + Number.EPSILON)) / 100}`}
              </Typography>
            }
          </FormControl>
        </FormGroup>

        <FormGroup className={classes.wrapperChild}>
          <FormControl className={classes.formControl}>
            <InputLabel
              htmlFor="boxTypeDepths"
              required
            >{t('boxTypeDepths')}</InputLabel>
            <Input
              error={editedBoxType.depth === '' || isNaN(editedBoxType.depth) || parseFloat(editedBoxType.depth) <= 0}
              id="boxTypeDepths"
              onChange={(event) => {
                setEditedBoxType({
                  ...editedBoxType,
                  depth: event.target.valueAsNumber
                });
              }}
              onClick={event => event.target.select()}
              onWheel={event => event.target.blur()}
              type="number"
              value={editedBoxType.depth}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel
              htmlFor="boxTypeWidth"
              required
            >{t('boxTypeWidth')}</InputLabel>
            <Input
              error={editedBoxType.width === '' || isNaN(editedBoxType.width) || parseFloat(editedBoxType.width) <= 0}
              id="boxTypeWidth"
              onChange={(event) => {
                setEditedBoxType({
                  ...editedBoxType,
                  width: event.target.valueAsNumber
                });
              }}
              onClick={event => event.target.select()}
              onWheel={event => event.target.blur()}
              type="number"
              value={editedBoxType.width}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel
              htmlFor="boxTypeHeight"
              required
            >{t('boxTypeHeight')}</InputLabel>
            <Input
              error={editedBoxType.height === '' || isNaN(editedBoxType.height) || parseFloat(editedBoxType.height) <= 0}
              id="boxTypeHeight"
              onChange={(event) => {
                setEditedBoxType({
                  ...editedBoxType,
                  height: event.target.valueAsNumber
                });
              }}
              onClick={event => event.target.select()}
              onWheel={event => event.target.blur()}
              type="number"
              value={editedBoxType.height}
            />
          </FormControl>
        </FormGroup>
        <FormGroup className={classes.wrapperChild}>
          <FormControl className={classes.formControl}>
            <label htmlFor="boxTypeDescription"><strong>{t('boxTypeDescription')}</strong></label>
            <textarea
              className={classes.textarea}
              cols={'70'}
              id="boxTypeDescription"
              onChange={(event) => setEditedBoxType({...editedBoxType, description: event.target.value})}
              rows={'7'}
              value={editedBoxType.description}
            />
          </FormControl>
        </FormGroup>
      </div>
    </Fragment>
  );
}


BoxTypeEdit.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  editedBoxType: PropTypes.object.isRequired,
  setEditedBoxType: PropTypes.func.isRequired,
  shipperShortName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(BoxTypeEdit);
