import {Tooltip, withStyles} from '@material-ui/core';
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {WarningRounded as WarnIcon} from '@material-ui/icons';
import {compose} from 'recompose';
import styles from './styles';
import {withTranslation} from 'react-i18next';

const StoragePlaces = (props) => {

  const {stop, maxDisplay, classes, t} = props;

  if (stop.storagePlaces && stop.storagePlaces.length > 0) {
    return (
      <Fragment>
        {(!maxDisplay || stop.storagePlaces.length <= maxDisplay)
          ? stop.storagePlaces.join(', ')
          :
          <Tooltip title={stop.storagePlaces.join(', ')}>
            <div>
              {stop.storagePlaces.slice(0, maxDisplay).join(', ') + '...'}
            </div>
          </Tooltip>
        }
      </Fragment>
    )
  }

  return (
    <Tooltip title={t('noStoragePlaceAvailableStop')}>
      <WarnIcon className={classes.warningColor}/>
    </Tooltip>
  );

}

StoragePlaces.propTypes = {
  classes: PropTypes.object.isRequired,
  maxDisplay: PropTypes.number,
  stop: PropTypes.shape({storagePlaces: PropTypes.array.isRequired}).isRequired,
  t: PropTypes.func.isRequired
}

export default compose(withStyles(styles), withTranslation())(StoragePlaces);
