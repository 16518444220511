import Columns from './columns';
import {displayModes} from '../../../../../../services/enums/displayModes';

// CARE: ALWAYS INCREMENT THE VERSION IF THE SETTINGS FOR A COLUMN CHANGE!
// Config explanation
// optional: determines if the hidden status can be changed by the user
// hidden: determines if the field is shown or hidden
// conditional: indicates that the hidden status can be overriden using the conditions dictionary of the useTableConfig hook
const DefaultConfig = {
  [displayModes.admin]: {
    version: 1,
    columns: {}
  },
  [displayModes.carrier]: {
    version: 2,
    columns: {
      tourId: {
        columnName: Columns.tourId.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 1,
      },
      microHub: {
        columnName: Columns.microHub.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 2
      },
      numStops: {
        columnName: Columns.numStops.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 3
      },
      storagePlace: {
        columnName: Columns.storagePlace.name,
        optional: true,
        hidden: true,
        conditional: false,
        order: 4
      },
      boxAmount: {
        columnName: Columns.boxAmount.name,
        optional: true,
        hidden: false,
        conditional: false,
        order: 5
      },
      weight: {
        columnName: Columns.weight.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 6
      },
      volume: {
        columnName: Columns.volume.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 7
      }
    }
  },
  [displayModes.shipper]: {
    version: 1,
    columns: {}
  },
}

export default DefaultConfig;