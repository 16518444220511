import React, {useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {MicroHubElementTypes, MicroHubPlanPresetService,} from '../../../../../services/hubAdministrationHelper/hubAdministrationHelper';
import {MicroHubPlanElementEdit} from '../index';
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import {onCloseWithBackdropCurry} from '../../../../../services/util/helperFuncs';

function CreateMicroHubPlanElementDialog(props) {

  const {classes, t, handleCancel, handleClose, createMicroHubPlanElementDialogState, shipperOptions, hubData, selectedDayOfWeek, selectedCarrierName, hubCarrierData} = props;
  const [newElement, setNewElement] = useState(null);
  const [shipperToAdd, setShipperToAdd] = useState(MicroHubPlanPresetService.getBoxZoneShipperPreset());

  if (!newElement) {
    switch (createMicroHubPlanElementDialogState.elementType) {
      case MicroHubElementTypes.hubBase:
        setNewElement(MicroHubPlanPresetService.getHubBasePreset());
        break;
      case MicroHubElementTypes.door:
        setNewElement(MicroHubPlanPresetService.getDoorPreset());
        break;
      case MicroHubElementTypes.boxZone:
        setNewElement(MicroHubPlanPresetService.getBoxZonePreset());
        break;
      case MicroHubElementTypes.beverageInsertZone:
        setNewElement(MicroHubPlanPresetService.getBeverageInsertZonePreset());
        break;
      case MicroHubElementTypes.wall:
        setNewElement(MicroHubPlanPresetService.getWallPreset());
        break;
      case MicroHubElementTypes.blockerZone:
        setNewElement(MicroHubPlanPresetService.getBlockerZonePreset());
        break;
      default : //NOP
    }
  }

  const handleOk = () => {
    handleClose(newElement);
    setNewElement(null);
    setShipperToAdd(MicroHubPlanPresetService.getBoxZoneShipperPreset());
  };

  const handleNotOk = () => {
    handleCancel();
    setNewElement(null);
    setShipperToAdd(MicroHubPlanPresetService.getBoxZoneShipperPreset());
  };

  const dataNotOk = () => {
    if (!newElement) return true;
    switch (createMicroHubPlanElementDialogState.elementType) {
      case MicroHubElementTypes.hubBase:
        return isNaN(parseInt(newElement.depth)) || parseInt(newElement.depth) < 100 || parseInt(newElement.depth) > 15000 || isNaN(parseInt(newElement.width)) || parseInt(newElement.width) < 100 || parseInt(newElement.width) > 15000;
      case MicroHubElementTypes.door:
        return isNaN(parseInt(newElement.doorSize)) || parseInt(newElement.doorSize) <= 25 || parseInt(newElement.doorSize) > 15000;
      case MicroHubElementTypes.boxZone:
        return isNaN(parseInt(newElement.depth)) || parseInt(newElement.depth) < 25 || parseInt(newElement.depth) > 15000 || isNaN(parseInt(newElement.width)) || parseInt(newElement.width) < 25 || parseInt(newElement.width) > 15000 || newElement.shipperInfos.length <= 0 || isNaN(newElement.stackHeight) || parseInt(newElement.stackHeight) < 25;
      case MicroHubElementTypes.beverageInsertZone:
        return isNaN(parseInt(newElement.depth)) || parseInt(newElement.depth) < 25 || parseInt(newElement.depth) > 15000 || isNaN(parseInt(newElement.width)) || parseInt(newElement.width) < 25 || parseInt(newElement.width) > 15000 || isNaN(parseInt(newElement.order)) || parseInt(newElement.order) <= 0 || hubData.beverageInsertZones.filter(zone => zone.tmpId !== newElement.tmpId).map(zone => zone.order).includes(parseInt(newElement.order));
      case MicroHubElementTypes.wall:
        return isNaN(parseInt(newElement.wallSize)) || parseInt(newElement.wallSize) < 25 || parseInt(newElement.wallSize) > 15000;
      case MicroHubElementTypes.blockerZone:
        return isNaN(parseInt(newElement.depth)) || parseInt(newElement.depth) < 25 || parseInt(newElement.depth) > 15000 || isNaN(parseInt(newElement.width)) || parseInt(newElement.width) < 25 || parseInt(newElement.width) > 15000;
      default :
        return true;
    }
  };

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      maxWidth={'sm'}
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={createMicroHubPlanElementDialogState.open}
    >
      <DialogTitle>{t('createHubPlanElement')}</DialogTitle>
      <DialogContent>
        {newElement &&
        <MicroHubPlanElementEdit
          element={newElement}
          elementType={createMicroHubPlanElementDialogState.elementType}
          hubData={hubData}
          setElement={setNewElement}
          setShipperToAdd={setShipperToAdd}
          shipperOptions={shipperOptions}
          shipperToAdd={shipperToAdd}
          selectedDayOfWeek={selectedDayOfWeek}
          selectedCarrierName={selectedCarrierName}
          hubCarrierData={hubCarrierData}
        />
        }
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={handleNotOk}
        >
          <CancelIcon/>&nbsp;{t('cancel')}
        </Button>
        <Button
          color="primary"
          disabled={dataNotOk()}
          onClick={handleOk}
        >
          <SaveIcon/>&nbsp;{t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CreateMicroHubPlanElementDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  createMicroHubPlanElementDialogState: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  hubData: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  shipperOptions: PropTypes.object.isRequired,
  selectedDayOfWeek: PropTypes.string.isRequired,
  selectedCarrierName: PropTypes.string.isRequired,
  hubCarrierData: PropTypes.object,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(CreateMicroHubPlanElementDialog);
