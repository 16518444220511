export default theme => ({
  root: {
    border: '1px solid green',

  },
  basketHeader: {
    padding: theme.spacing(0.5)
  },
  basketBody: {
    height: '250px',
    padding: theme.spacing(0.5),
    overflowY: 'auto'
  },
  basketBodyDraggingOver: {
    backgroundColor: theme.palette.primary.light,
    height: '250px',
    padding: theme.spacing(0.5),
    borderRadius: '5px',
    border: '2px dashed #bbb',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  draggingOverWithForbidden: {
    backgroundColor: theme.palette.danger.light,
    height: '250px',
    padding: theme.spacing(0.5),
    borderRadius: '5px',
    border: '2px dashed #bbb',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  dropIcon: {
    transform: 'rotate(180deg)',
    color: theme.palette.border,
    height: '75px',
    width: '75px'
  }
});

