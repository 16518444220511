import React from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Button, Tooltip, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {ExpandMore as ExpandMoreIcon} from '@material-ui/icons';
import classNames from 'classnames';
import PreviewTourTable from '../PreviewTourTable';
import {CapacityHelperService} from '../../../../../services/util/capacityHelper';


function MicroHubWrapper(props) {

  const {classes, t, microHubWithToursAndStops, className, rerunAlgoForHub, freezeTour, maxVolume, changeStopAssignment, dataCarrierForChangeAssignment, deleteStop, microHubsData} = props;

  const rootClassName = classNames(className, classes.root);

  const onRerunAlgo = (event) => {
    event.stopPropagation();
    rerunAlgoForHub(microHubWithToursAndStops);
  };

  return (
    <Accordion className={rootClassName}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
      >
        <Typography className={rerunAlgoForHub ? classes.headerSectionWithAlgoButtonLarge : classes.headerSectionLarge}>
          <strong>{t('microHub')}: {microHubWithToursAndStops.microHubName}</strong>
        </Typography>
        <Typography className={rerunAlgoForHub ? classes.headerSectionWithAlgoButtonLarge : classes.headerSectionLarge}>
          <strong>{t('carrier')}</strong>: {microHubWithToursAndStops.carrierName}
        </Typography>
        <Typography className={rerunAlgoForHub ? classes.headerSectionWithAlgoButton : classes.headerSection}>
          <strong>{t('tours')}</strong>: {microHubWithToursAndStops.tours ? microHubWithToursAndStops.tours.length : 0}
        </Typography>
        <Typography className={rerunAlgoForHub ? classes.headerSectionWithAlgoButton : classes.headerSection}>
          <strong>{t('stops')}</strong>: {CapacityHelperService.getStopAmountOfHub(microHubWithToursAndStops) + microHubWithToursAndStops.stopsWithoutTour.length}
        </Typography>
        <Typography className={rerunAlgoForHub ? classes.headerSectionWithAlgoButton : classes.headerSection}>
          <strong>{t('boxAmount')}</strong>: {CapacityHelperService.getBoxAmountOfHub(microHubWithToursAndStops) + CapacityHelperService.getBoxAmountOfStopList(microHubWithToursAndStops.stopsWithoutTour)}
        </Typography>
        <Typography className={rerunAlgoForHub ? classes.headerSectionWithAlgoButton : classes.headerSection}>
          <strong>{t('weight')}</strong>: {CapacityHelperService.getWeightOfHub(microHubWithToursAndStops) + CapacityHelperService.getWeightOfStopList(microHubWithToursAndStops.stop)}
        </Typography>
        <Typography className={rerunAlgoForHub ? classes.headerSectionWithAlgoButton : classes.headerSection}>
          <strong>{t('volume')}</strong>: {`${Math.round(CapacityHelperService.getVolumeOfHub(microHubWithToursAndStops) + CapacityHelperService.getVolumeOfStopList(microHubWithToursAndStops.stopsWithoutTour))} / ${Math.round(maxVolume)}`}
        </Typography>
        {rerunAlgoForHub &&
        <Tooltip title={t('runAlgoForHubInfo')}>
          <Button
            className={classNames(classes.headerSectionWithAlgoButton, classes.button)}
            color="secondary"
            disabled={microHubWithToursAndStops.tours.every(t => !t.editable) && microHubWithToursAndStops.stopsWithoutTour.every(s => !s.editable)}
            onClick={onRerunAlgo}
            size="small"
            variant="contained"
          >
            {t('runAlgoForPreviewTour')}
          </Button>
        </Tooltip>
        }
      </AccordionSummary>
      <hr className={classes.hr}/>
      <AccordionDetails className={classes.accordionDetail}>
        {((microHubWithToursAndStops.tours && microHubWithToursAndStops.tours.length) || (microHubWithToursAndStops.stopsWithoutTour && microHubWithToursAndStops.stopsWithoutTour.length)) ?
          <PreviewTourTable
            changeStopAssignment={changeStopAssignment}
            className={classes.previewTourTable}
            dataCarrierForChangeAssignment={dataCarrierForChangeAssignment}
            deleteStop={deleteStop}
            freezeTour={freezeTour}
            microHubsData={microHubsData}
            stopsWithoutTour={microHubWithToursAndStops.stopsWithoutTour}
            toursWithStops={microHubWithToursAndStops.tours}
          />
          :
          <Typography>
            {t('noPreviewToursForThisHub')}
          </Typography>
        }
      </AccordionDetails>
    </Accordion>
  );
}


MicroHubWrapper.propTypes = {
  changeStopAssignment: PropTypes.func,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  dataCarrierForChangeAssignment: PropTypes.object,
  deleteStop: PropTypes.func,
  freezeTour: PropTypes.func,
  maxVolume: PropTypes.number.isRequired,
  microHubWithToursAndStops: PropTypes.object.isRequired,
  microHubsData: PropTypes.array.isRequired,
  rerunAlgoForHub: PropTypes.func,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(MicroHubWrapper);
