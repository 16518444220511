import React from 'react'
import {FormControl, Input, InputLabel, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import classNames from 'classnames';

function NumberInput(props) {
  const {classes, className, value, setValue, error, required, name} = props;

  const rootClassName = classNames(classes.root, className);

  if (name) {
    return (
      <FormControl className={rootClassName}>
        <InputLabel
          htmlFor={name}
          required={required}
        >{name}</InputLabel>
        <Input
          error={error}
          id={name}
          onChange={(event) => setValue(event.target.valueAsNumber)}
          onWheel={event => event.target.blur()}
          type="number"
          value={value}
        />
      </FormControl>
    );
  }

  return (
    <Input
      className={rootClassName}
      error={error}
      onChange={(event) => setValue(event.target.valueAsNumber)}
      onWheel={event => event.target.blur()}
      type="number"
      value={value}
    />
  );
}


NumberInput.defaultProps = {
  error: false,
  required: false,
  name: '',
};

NumberInput.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  error: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};


export default withStyles(styles)(NumberInput);
