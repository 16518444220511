const Columns = {
  tourId: {
    name: 'tourId',
    header: ({ t }) => t('tourId'),
    body: ({ tour }) => tour.id
  },
  carrier: {
    name: 'carrier',
    header: ({ t }) => t('carrier'),
    body: ({ tour }) => tour.carrierName
  },
  microHub: {
    name: 'microHub',
    header: ({ t }) => t('microHub'),
    body: ({ tour }) => tour.microHubName
  },
  numStops: {
    name: 'numStops',
    header: ({ t }) => t('numStops'),
    body: ({ tour }) => tour.templateStopInstances.length
  },
}

export default Columns;