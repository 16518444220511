import {AuthService} from '../auth/authService';
import axios from 'axios';

const baseUri = `${process.env.REACT_APP_API_URI}shipper-api`;

const getTransferRulesForShipper = async (shipperName) => {
  const response = await axios.get(`${baseUri}/administration/v1/shipperApiConfig/transferRules/${shipperName}/`,
    {
      headers: {
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const createTransferRuleForShipper = async (ruleToCreate) => {
  const response = await axios.post(`${baseUri}/administration/v1/shipperApiConfig/transferRules/`,
    ruleToCreate,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
}

const deleteTransferRuleForShipper = async (ruleToDeleteId) => {
  const response = await axios.delete(`${baseUri}/administration/v1/shipperApiConfig/transferRules/${ruleToDeleteId}/`,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const getAllStandardsForShippers = async () => {
  const response = await axios.get(`${baseUri}/administration/v1/shipperApiConfig/shipperStandards/all/`,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const createStandardForShipper = async (standardToCreate) => {
  const response = await axios.post(`${baseUri}/administration/v1/shipperApiConfig/shipperStandards/`,
    standardToCreate,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
}

const deleteStandardForShipper = async (standardToDeleteId) => {
  const response = await axios.delete(`${baseUri}/administration/v1/shipperApiConfig/shipperStandards/${standardToDeleteId}/`,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

export default {
  createTransferRuleForShipper,
  createStandardForShipper,
  deleteTransferRuleForShipper,
  deleteStandardForShipper,
  getTransferRulesForShipper,
  getAllStandardsForShippers
};