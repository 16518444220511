export default theme => ({
  controlContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    justifyContent: 'space-between'
  },
  formControlCheckbox: {
    verticalAlign: 'middle',
    display: 'flex'
  },
  formControlSelect: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: '150px',
    width: '150px',
    verticalAlign: 'middle'
  },
});
