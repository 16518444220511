export const BoxTypeKind = {
  Box: 'BOX',
  BeverageBox: 'BEVERAGE_BOX',
};

export function getBoxTypeKindString(kind) {
  switch (kind) {
    case BoxTypeKind.Box:
      return 'boxTypeKindBox';
    case BoxTypeKind.BeverageBox:
      return 'boxTypeKindBeverageBox';
    default:
      return 'boxTypeKindUndefined'
  }
}
