import React, {Fragment, useEffect, useState} from 'react';
import styles from './styles';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core';
import classNames from 'classnames';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';

function CountdownTimer(props) {

  const {deadlineDate, classes, className} = props;
  const rootClassName = classNames(classes.root, className);

  const calculateLeftoverTime = () => {

    const difference = deadlineDate - new Date();
    let timeLeft = null;

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60))),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }
    return timeLeft;
  };

  const formatTime = (time) => {
    return time.toLocaleString(undefined, {minimumIntegerDigits: 2})
  };

  const [uploadTimeLeft, setUploadTimeLeft] = useState(calculateLeftoverTime());
  const [timerInterval, setTimerInterval] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setUploadTimeLeft(calculateLeftoverTime());
    }, 1000);
    setTimerInterval(interval);
    return () => {
      clearInterval(timerInterval);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      {
        uploadTimeLeft ?
          <div className={rootClassName}>
            {`${formatTime(uploadTimeLeft.hours)} : ${formatTime(uploadTimeLeft.minutes)} : ${formatTime(uploadTimeLeft.seconds)}`}
          </div>
          :
          <div/>
      }
    </Fragment>
  );
}

CountdownTimer.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deadlineDate: PropTypes.instanceOf(Date),
  t: PropTypes.func.isRequired
}

export default compose(withStyles(styles), withTranslation())(CountdownTimer);
