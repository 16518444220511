import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {onCloseWithBackdropCurry} from '../../../services/util/helperFuncs';
import {Cancel, Check} from '@material-ui/icons'


function PrivacyConfirmationDialog(props) {

  const {classes, t, open, handleClose} = props;

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth={'sm'}
      onClose={onCloseWithBackdropCurry(() => handleClose(false))}
      open={open}
    >
      <DialogTitle>{t('privacyConfirmation')}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography>
          {t('privacyConfirmationDescription')}
          <a
            className={classes.link}
            href={process.env.PUBLIC_URL + '/privacy'}
            rel="noopener noreferrer"
            target="_blank"
          >{t('here')}.
          </a>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={() => handleClose(false)}
        >
          <Cancel/>&nbsp;{t('doNotConsent')}
        </Button>
        <Button
          color="primary"
          onClick={() => handleClose(true)}
        >
          <Check/>&nbsp;{t('doConsent')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PrivacyConfirmationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(PrivacyConfirmationDialog);
