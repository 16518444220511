import React, {Fragment, useEffect, useState} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import SideNavLayout from '../../../layouts/SideNavLayout';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {
  BoxTypeDetail,
  BoxTypeFilter,
  BoxTypeTable,
  CreateBoxTypeDialog,
  DataLoadingWrapper,
  GeneralInfoIcon
} from '../../../components';
import {Add as AddIcon} from '@material-ui/icons';
import {AuthService} from '../../../services/auth/authService';
import {useMutation, useQuery} from '@apollo/react-hooks';
import omitDeep from 'omit-deep';
import {ClientService} from '../../../services/client/clientService';
import {clientTypes} from '../../../services/client/clientTypes';
import {ShipperQueryService} from '../../../services/backend/shipperQueryService';
import {displayModes} from '../../../services/enums/displayModes';


const client = ClientService.getClient(clientTypes.shipper);

function BoxAdministration(props) {


  const {classes, t} = props;
  const [filter, setFilter] = useState({
    kind: '',
    stackable: ''
  });
  const [selectedBoxType, setSelectedBoxType] = useState(null);
  const [createBoxTypeDialogState, setCreateBoxTypeDialogState] = useState({
    open: false,
    shipperShortName: '',
  });
  const [shipperData, setShipperData] = useState(null);


  const queryBoxTypes = ShipperQueryService.getShipperWithBoxTypesQuery(AuthService.getUserOrganization());
  const {loading, error, data, refetch} = useQuery(queryBoxTypes, {client: client});
  useEffect(() => {
    if (data && data.shippers[0]) {
      setShipperData(data.shippers[0]);
    }
  }, [data]);


  const deleteBoxTypeQuery = ShipperQueryService.deleteBoxTypeByIdIdentifier();
  const [deleteBoxType] = useMutation(deleteBoxTypeQuery, {client: client});

  const updateBoxTypeQuery = ShipperQueryService.updateBoxTypeByIdentifierQuery();
  const [updateBoxType] = useMutation(updateBoxTypeQuery, {client: client});

  const createBoxTypeQuery = ShipperQueryService.createBoxTypeQuery();
  const [createBoxType] = useMutation(createBoxTypeQuery, {client: client});

  const updateBoxTypeCallback = (updatedBoxType, originalIdentifier) => {
    const formattedUpdatedBoxType = omitDeep(updatedBoxType, '__typename');
    updateBoxType({variables: {identifier: originalIdentifier, updatedBoxType: formattedUpdatedBoxType}}).then(() => {
      refetch().then(() => setSelectedBoxType(null))
    }, () => {
      alert(t('errorUpdatingData'))
    });
  };

  const deleteBoxTypeCallback = (boxType, event) => {
    event.stopPropagation();
    deleteBoxType({variables: {identifier: boxType.identifier}}).then(() => {
      refetch().then(() => setShipperData(null));
      setSelectedBoxType(null);
    }, () => {
      alert(t('errorDeletingBoxType'))
    });
  };


  const handleCreateBoxTypeCancel = () => {
    setCreateBoxTypeDialogState({...createBoxTypeDialogState, open: false});
  };

  const handleCreateBoxTypeClose = (boxType) => {
    createBoxType({variables: {shipperName: AuthService.getUserOrganization(), boxTypeToCreate: boxType}}).then(() => {
      refetch().then(() => setShipperData(null));
    }, () => {
      alert(t('errorCreatingBoxType'))
    });
    setCreateBoxTypeDialogState({...createBoxTypeDialogState, open: false});
  };

  const filterBoxTypes = (boxTypes) => {
    return boxTypes
      .filter(boxType => !filter.kind || boxType.kind === filter.kind)
      .filter(boxTypes => filter.stackable === '' || boxTypes.stackable === filter.stackable);
  };

  return (
    <SideNavLayout title={t('boxAdministration')}>
      <div className={classes.root}>
        <div className={classes.helpWrapper}>
          <GeneralInfoIcon/>
        </div>
        <BoxTypeFilter
          filter={filter}
          setFilter={setFilter}
          setSelectedBoxType={setSelectedBoxType}
        />
        <div>
          <Button
            className={classes.addButton}
            color="primary"
            disabled={!shipperData}
            onClick={() => setCreateBoxTypeDialogState({
              ...createBoxTypeDialogState,
              open: true,
              shipperShortName: shipperData.shortName
            })}
            title={t('createBoxType')}
            variant="contained"
          >
            <AddIcon/>&nbsp;{t('createBoxType')}
          </Button>
          <br/>
          <br/>
        </div>
        <DataLoadingWrapper
          error={error}
          isLoading={loading}
        >
          {shipperData &&
            <Fragment>
              <BoxTypeTable
                boxTypes={filterBoxTypes(shipperData.boxTypes)}
                className={classes.content}
                deleteBoxType={deleteBoxTypeCallback}
                selectBoxType={setSelectedBoxType}
                selectedBoxType={selectedBoxType}
                displayMode={displayModes.shipper}
              />
              {selectedBoxType &&
                <BoxTypeDetail
                  boxType={selectedBoxType}
                  className={classes.content}
                  closeDetail={() => setSelectedBoxType(null)}
                  shipperShortName={shipperData.shortName}
                  updateBoxType={updateBoxTypeCallback}
                />
              }
            </Fragment>
          }
        </DataLoadingWrapper>
        <CreateBoxTypeDialog
          createBoxTypeDialogState={createBoxTypeDialogState}
          handleCancel={handleCreateBoxTypeCancel}
          handleClose={handleCreateBoxTypeClose}
        />
      </div>
    </SideNavLayout>
  );

}

BoxAdministration.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(BoxAdministration);
