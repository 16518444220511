export default theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
  },
  timeAndLogoContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
      flexDirection: 'column-reverse',
    }
  },
  timeContainer: {
    marginTop: 0,
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(5),
    }
  },
  logo: {
    height: 65,
    [theme.breakpoints.up('md')]: {
      height: 109,
      margin: 'auto'
    }
  }
});

