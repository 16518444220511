import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {ExpandMore as ExpandMoreIcon} from '@material-ui/icons';
import classNames from 'classnames';
import MigrationStatus from '../MigrationStatus';
import {getMigrationStatus} from '../../../services/util/migrationStatusHelper';
import {StopIdLabel} from '../../index';


function FinalStoppsWithoutPreviewStops(props) {

  const {classes, className, t, finalStopsWithoutPreview, previewDeadlineElapsed, finalDeadlineElapsed, migrate} = props;

  const rootClassName = classNames(className, classes.root);

  // calculate counts
  const totalStopsCount = finalStopsWithoutPreview.length;
  const migratedStopsCount = finalStopsWithoutPreview.filter(s => s.isMigrated).length;
  const stopsWithFinalStopCount = finalStopsWithoutPreview.length;
  const stopWithOnlyDeclinedStopCount = finalStopsWithoutPreview.filter(s => s.hasDeclinedStop && !s.hasFinalStop).length;

  const {
    migrationNotPossible,
    migrated,
    readyForMigration,
    migrationPossible,
    additionalMigrationRequired,
    declinedStopsPresent
  } = getMigrationStatus(totalStopsCount, migratedStopsCount, stopsWithFinalStopCount, previewDeadlineElapsed, finalDeadlineElapsed, stopWithOnlyDeclinedStopCount);

  return (
    <Accordion className={rootClassName}>
      <AccordionSummary
        classes={
          {content: classes.accordionSummaryContent}
        }
        expandIcon={<ExpandMoreIcon/>}
      >
        <Typography>
          <strong>{t('finalStopsWithoutPreviewStops')}</strong>
        </Typography>
        <Typography className={classes.statusDisplay}>
          <strong>{t('migrationStatus')}:</strong>&nbsp;
          <MigrationStatus
            additionalMigrationRequired={additionalMigrationRequired}
            declinedStop={declinedStopsPresent}
            finalDeadlineElapsed={finalDeadlineElapsed}
            migrated={migrated}
            migrationNotPossible={migrationNotPossible}
            migrationPossible={migrationPossible}
            previewDeadlineNotElapsed={!previewDeadlineElapsed}
            readyForMigration={readyForMigration}
          />
        </Typography>
        <Typography><strong>{t('migrationStoppsPreview')}:</strong>&nbsp;{0}</Typography>
        <Typography><strong>{t('migrationStoppsMigrated')}:</strong>&nbsp;{migratedStopsCount}</Typography>
        <Typography><strong>{t('migrationStoppsFinal')}:</strong>&nbsp;{totalStopsCount}</Typography>
        <Typography>
          <Button
            color="primary"
            disabled={migrated || migrationNotPossible || !previewDeadlineElapsed || finalDeadlineElapsed}
            onClick={migrate}
            variant={'contained'}
          >
            {t('migrate')}
          </Button>
        </Typography>
      </AccordionSummary>
      <hr className={classes.hr}/>
      <AccordionDetails className={classes.accordionDetail}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('tourStopId')}</TableCell>
              <TableCell>{t('migrationStatus')}</TableCell>
              <TableCell>{t('lastName')}</TableCell>
              <TableCell>{t('streetAndNumber')}</TableCell>
              <TableCell>{t('customerId')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {finalStopsWithoutPreview.map((stop, index) => {
              const migrated = stop.isMigrated && previewDeadlineElapsed && !finalDeadlineElapsed;
              return (
                <TableRow
                  className={classNames({[classes.deadlinesInvalid]: !previewDeadlineElapsed || finalDeadlineElapsed})}
                  hover={previewDeadlineElapsed && !finalDeadlineElapsed}
                  key={index}
                >
                  <TableCell><StopIdLabel stop={stop}/></TableCell>
                  <TableCell>
                    <MigrationStatus
                      finalDeadlineElapsed={finalDeadlineElapsed}
                      migrated={migrated}
                      previewDeadlineNotElapsed={!previewDeadlineElapsed}
                      readyForMigration={!migrated}
                    />
                  </TableCell>
                  <TableCell>{stop.lastName}</TableCell>
                  <TableCell>{stop.address.streetAndNumber}</TableCell>
                  <TableCell>{stop.customerId}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
}


FinalStoppsWithoutPreviewStops.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  finalDeadlineElapsed: PropTypes.bool.isRequired,
  finalStopsWithoutPreview: PropTypes.array.isRequired,
  migrate: PropTypes.func.isRequired,
  previewDeadlineElapsed: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(FinalStoppsWithoutPreviewStops);
