import {createHttpLink} from 'apollo-link-http';
import {ApolloClient, InMemoryCache} from 'apollo-boost';
import {setContext} from 'apollo-link-context';
import {AuthService} from '../auth/authService';


export class ClientService {
  static getClient(clientType) {
    const baseUri = process.env.REACT_APP_API_URI;
    const uri = baseUri + clientType;

    const authLink = setContext((_, {headers}) => {
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: AuthService.getAuthHeader()
        }
      }
    });

    const link = createHttpLink(
      {
        uri: uri,
        useGETForQueries: false // DO NOT DELETE THIS; OTHERWISE NO METHOD IS SET AND GATEWAY BLOCKS REQUESTS!
      }
    );
    return new ApolloClient({
      link: authLink.concat(link),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'ignore',
        },
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
      }
    });
  }
}





