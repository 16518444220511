import React, {useEffect, useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import StopEditCarrier from '../StopEdit/CarrierFull';
import StopEditShipper from '../StopEdit/Shipper';
import {displayModes} from '../../../services/enums/displayModes';
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import {onCloseWithBackdropCurry} from '../../../services/util/helperFuncs';
import {useStopEditTableItems} from '../StopDetail/StopDetailEdit/useStopEditTableItems';
import {ShipperQueryService} from '../../../services/backend/shipperQueryService';
import {useQuery} from '@apollo/react-hooks';
import {ClientService} from '../../../services/client/clientService';
import {clientTypes} from '../../../services/client/clientTypes';

const clientShipper = ClientService.getClient(clientTypes.shipper);

function StopCreateDialog(props) {

  const {classes, t, createStopDialogState, handleCancel, handleClose, microHubSelectOptions, newStopPreset, editDisplayMode} = props;

  const [newStop, setNewStop] = useState(null);

  const queryShipper = ShipperQueryService.getAllShipperEmptiesQuery();
  const {data: dataShipper} = useQuery(queryShipper, {client: clientShipper});

  const stopEditTableHook = useStopEditTableItems(newStopPreset);
  const [productsReadyForUpdate, setProductsReadyForUpdate] = useState(false);

  if (!newStop) {
    setNewStop(newStopPreset);
  }

  useEffect(() => {
    if (productsReadyForUpdate) {
      handleClose(newStop);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsReadyForUpdate])

  const onUpdate = () => {
    setNewStop({
      ...newStop,
      products: [...stopEditTableHook.existingProducts, ...stopEditTableHook.newProducts],
      packageInserts: [...stopEditTableHook.existingPackageInserts, ...stopEditTableHook.newPackageInserts],
      empties: [...stopEditTableHook.existingEmpties, ...stopEditTableHook.newEmpties]
    });
    stopEditTableHook.resetItems();
    // handle update via this callback, since the setEditedStop called above has to be processed before the update is written!
    setProductsReadyForUpdate(true);
  };

  const handleNotOk = () => {
    handleCancel();
    setNewStop(null);
  };

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      maxWidth={'lg'}
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={createStopDialogState.open}
    >
      <DialogTitle>{t('createStop')}</DialogTitle>
      <DialogContent>
        {(() => {
          switch (editDisplayMode) {
            case displayModes.shipper:
              return (
                <StopEditShipper
                  className={classes.wrapper}
                  editedStop={newStop}
                  setEditedStop={setNewStop}
                />);
            case displayModes.carrier:
              return (
                <StopEditCarrier
                  allShipperSelectOptions={dataShipper}
                  className={classes.wrapper}
                  editedStop={newStop}
                  microHubOptions={microHubSelectOptions}
                  setEditedStop={setNewStop}
                  stopEditTableHook={stopEditTableHook}
                />);
            case displayModes.admin:
              return (
                <div>TODO: implement if needed</div>);
            default :
              return (
                <StopEditCarrier
                  allShipperSelectOptions={dataShipper}
                  className={classes.wrapper}
                  editedStop={newStop}
                  microHubOptions={microHubSelectOptions}
                  setEditedStop={setNewStop}
                  stopEditTableHook={stopEditTableHook}
                />);
          }
        })()
        }
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={handleNotOk}
        >
          <CancelIcon/>&nbsp;{t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          disabled={!newStop || !newStop.planedDeliveryDateCarrier || !newStop.planedDeliveryDateShipper || !newStop.address.streetAndNumber || !newStop.address.city || !newStop.address.zipcode || !(newStop.firstName || newStop.lastName) || !newStop.shipperName || !newStop.assignedMicroHubName}
          onClick={onUpdate}
        >
          <SaveIcon/>&nbsp;{t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

StopCreateDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  createStopDialogState: PropTypes.object.isRequired,
  editDisplayMode: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  microHubSelectOptions: PropTypes.array.isRequired,
  newStopPreset: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(StopCreateDialog);
