import React from 'react'
import {StopIdLabel} from '../../index';

const Columns = {
  stopId: {
    name: 'stopId',
    header: ({t}) => t('stopId'),
    body: ({invoice, classes}) => (
      <div className={classes.idTableCell}>
        {<StopIdLabel stop={invoice}/>}
      </div>
    )
  },
  shipper: {
    name: 'shipper',
    header: ({t}) => t('shipper'),
    body: ({invoice, t}) => invoice.shipperName || '-'
  },
  carrier: {
    name: 'carrier',
    header: ({t}) => t('carrier'),
    body: ({invoice}) => invoice.carrierName || '-'
  },
  boxes: {
    name: 'boxes',
    header: ({t}) => t('boxes'),
    body: ({invoice, t}) => invoice.boxes
  },
  invoiceWaterBox: {
    name: 'invoiceWaterBox',
    header: ({t}) => t('invoiceWaterBox'),
    body: ({invoice}) => invoice.waterBoxes
  },
  stairMoney: {
    name: 'stairMoney',
    header: ({t}) => t('stairMoney'),
    body: ({invoice}) => invoice.stairMoney
  },
  bonusBox: {
    name: 'bonusBox',
    header: ({t}) => t('bonusBox'),
    body: ({invoice}) => invoice.bonus
  },
}

export default Columns;