import {DeclinedStopsTableSmallDetails} from '../index';
import React, {Fragment} from 'react';
import {Box, Button, Collapse, IconButton, TableCell, TableRow, withStyles} from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  WarningRounded as WarnIcon
} from '@material-ui/icons';
import {compose} from 'recompose';
import styles from './styles';
import PropTypes from 'prop-types';
import {displayModes} from '../../../../../services/enums/displayModes';
import {DateService} from '../../../../../services/util/DateService';
import Typography from '@material-ui/core/Typography';

function DeclinedStopsTableRow(props) {

  const {classes, declinedStop, handleClick, shipperShortNames, displayMode} = props;
  const [open, setOpen] = React.useState(false);

  return (
    <Fragment>
      <TableRow
        className={classes.root}
        hover
        selected={open}
      >
        <TableCell>
          {DateService.displayTime(declinedStop.declinedTime)}
        </TableCell>
        <TableCell>
          <Typography className={classes.alignText}>
            <WarnIcon className={classes.dangerColor}/>
            &nbsp;{declinedStop.systemId}
          </Typography>
        </TableCell>
        {displayMode !== displayModes.shipper &&
        <TableCell>
          {shipperShortNames[declinedStop.shipperName] || declinedStop.shipperName}
        </TableCell>
        }
        <TableCell>
          {declinedStop.customerId}
        </TableCell>
        <TableCell>
          <Button
            className={classes.buttonLeft}
            onClick={() => handleClick(declinedStop)}
          >
            <VisibilityOutlinedIcon/>
          </Button>
          <IconButton
            className={classes.buttonRight}
            onClick={() => setOpen(!open)}
            size="small"
          >
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          className={classes.largeTableCell}
          colSpan={displayMode === displayModes.shipper ? 4 : 5}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <Box margin={1}>
              <DeclinedStopsTableSmallDetails declinedStop={declinedStop}/>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

DeclinedStopsTableRow.propTypes = {
  classes: PropTypes.object.isRequired,
  declinedStop: PropTypes.object.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  handleClick: PropTypes.func.isRequired,
  shipperShortNames: PropTypes.object,
};

export default compose(withStyles(styles))(DeclinedStopsTableRow);
