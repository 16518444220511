import React from 'react'
import {Check as CheckIcon, Close as CrossIcon, DeleteForever as DeleteIcon} from '@material-ui/icons';
import {Button} from '@material-ui/core';
import {DateService} from '../../../services/util/DateService';

const Columns = {
  specialDeliveryDayShipperName: {
    name: 'specialDeliveryDayShipperName',
    header: ({t}) => t('shipper'),
    body: ({specialDeliveryDay}) => specialDeliveryDay.shipperName
  },
  specialDeliveryDayCarrierName: {
    name: 'specialDeliveryDayCarrierName',
    header: ({t}) => t('carrier'),
    body: ({specialDeliveryDay}) => specialDeliveryDay.carrierName
  },
  specialDeliveryDayMicroHubName: {
    name: 'specialDeliveryDayMicroHubName',
    header: ({t}) => t('microHub'),
    body: ({specialDeliveryDay}) => specialDeliveryDay.microHubName
  },
  specialDeliveryDayDay: {
    name: 'specialDeliveryDayDay',
    header: ({t}) => t('day'),
    body: ({specialDeliveryDay}) => DateService.displayDate(specialDeliveryDay.day)
  },
  specialDeliveryDayHasBeenHandled: {
    name: 'specialDeliveryDayHasBeenHandled',
    header: ({t}) => t('hasBeenHandled'),
    body: ({specialDeliveryDay}) => specialDeliveryDay.hasBeenHandled ? <CheckIcon/> : <CrossIcon/>
  },
  deleteSpecialDeliveryDay: {
    name: 'deleteSpecialDeliveryDay',
    header: ({t}) => t('deleteSpecialDeliveryDay'),
    body: ({specialDeliveryDay, t, classes, deleteSpecialDeliveryDay}) => (
      <Button
        className={classes.deleteColor}
        color="primary"
        onClick={() => {
          if (window.confirm(t('confirmDeleteSpecialDeliveryDay'))) deleteSpecialDeliveryDay(specialDeliveryDay)
        }}
        style={{padding: 0}}
        title={t('deleteSpecialDeliveryDay')}
      >
        <DeleteIcon/>
      </Button>
    )
  },
}

export default Columns;