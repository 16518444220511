import React, {Fragment} from 'react'
import {Button, IconButton, TableCell, TableRow, withStyles,} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Delete as DeleteIcon,
  DragIndicatorOutlined as DragIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  LocalOffer as EditTourNameIcon,
  MotorcycleOutlined as VehicleIcon
} from '@material-ui/icons';
import {TourIdLabel, TourMetadataIcon} from '../../../../../../components';
import {CapacityHelperService} from '../../../../../../services/util/capacityHelper';
import DisplayCapacity from '../DisplayCapacity';
import DisplayUtilization from '../DisplayUtilization';
import {roundCapacityValues} from '../../../../../../services/util/helperFuncs';

function FluentTourTableRowTour(props) {
  const {
    classes,
    className,
    colSpan,
    t,
    tour,
    provided,
    snapshot,
    vehicleCapacities,
    selectVehicle,
    deleteTour,
    canDelete,
    openAddTourPopover,
    toggleShowTour,
    tourCollapsed,
    editTourName
  } = props;

  const rootClassName = classNames(classes.root, className);

  const tourCapacity = {
    volume: CapacityHelperService.getVolumeOfTour(tour),
    weight: CapacityHelperService.getWeightOfTour(tour),
    boxAmount: CapacityHelperService.getBoxAmountOfTour(tour),
  }

  return (
    <TableRow
      className={classNames(rootClassName, { [classes.dragging]: snapshot.isDragging })}
      hover
      onClick={openAddTourPopover}
      title={t('clickToAddTour')}
      {...provided.draggableProps}
      ref={provided.innerRef}
    >
      <TableCell
        colSpan={colSpan}
        style={{boxShadow: `inset 10px 0px 0px 0px ${tour.color}`}}
      >
        <div className={classes.flexBetweenCenter}>
          <div className={classes.flexBetweenCenter}>
            <div
              {...provided.dragHandleProps}
              className={classes.dragIcon}
            >
              {canDelete && !tourCollapsed && <DragIcon />}
            </div>
            <TourMetadataIcon metadata={tour.tourMetaData} />
            <TourIdLabel tour={tour} />
          </div>
          {!snapshot.isDragging &&
            <Fragment>
              <Button
                color="primary"
                onClick={(event) => {
                  event.stopPropagation();
                  selectVehicle(tour, tourCapacity);
                }}
                size="small"
                startIcon={<VehicleIcon />}
                style={{ margin: 0 }}
                title={t('selectVehicleDialogTitle')}
                variant="contained"
              >
                {tour.vehicleLicensePlate || '-'}
              </Button>
              <div><strong>{t('tourUtilization')}</strong>:&nbsp;
                <DisplayUtilization
                  capacity={tourCapacity}
                  vehicleCapacity={vehicleCapacities}
                />
              </div>
              <div><strong>{t('boxes')}</strong>:&nbsp;{roundCapacityValues(tourCapacity.boxAmount)}</div>
              <div><strong>{t('weight')}</strong>:&nbsp;
                <DisplayCapacity
                  capacity={tourCapacity.weight}
                  vehicleCapacity={vehicleCapacities?.weight}
                />
              </div>
              <div><strong>{t('volume')}</strong>:&nbsp;
                <DisplayCapacity
                  capacity={tourCapacity.volume}
                  vehicleCapacity={vehicleCapacities?.volume}
                />
              </div>
              <div>
                <IconButton
                  color="primary"
                  onClick={(event) => {
                    event.stopPropagation()
                    editTourName(tour);
                  }}
                  size="small"
                  title={t('renameTour')}
                  variant="contained"
                >
                  <EditTourNameIcon />
                </IconButton>
                <IconButton
                  className={classes.deleteButton}
                  disabled={!canDelete && tour.stops.length !== 0}
                  onClick={(event) => {
                    event.stopPropagation();
                    deleteTour();
                  }}
                  size="small"
                  title={t('deleteTour')}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    toggleShowTour(tour.tourId);
                  }}
                  size="small"
                  title={tourCollapsed ? t('showFluentTableRow') : t('hideFluentTableRow')}
                >
                  {tourCollapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                </IconButton>
              </div>
            </Fragment>
          }
        </div>
      </TableCell>
    </TableRow>
  );
}

FluentTourTableRowTour.propTypes = {
  canDelete: PropTypes.bool.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  colSpan: PropTypes.number.isRequired,
  deleteTour: PropTypes.func.isRequired,
  editTourName: PropTypes.func.isRequired,
  openAddTourPopover: PropTypes.func.isRequired,
  provided: PropTypes.object.isRequired,
  selectVehicle: PropTypes.func.isRequired,
  snapshot: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  toggleShowTour: PropTypes.func.isRequired,
  tour: PropTypes.object,
  tourCollapsed: PropTypes.bool.isRequired,
  vehicleCapacities: PropTypes.shape({ volume: PropTypes.number.isRequired, weight: PropTypes.number.isRequired }),
};

export default compose(withStyles(styles), withTranslation())(FluentTourTableRowTour);
