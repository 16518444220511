export default theme => ({
  root: {},
  addRow: {
    height: '55px'
  },
  redIcon: {
    color: theme.palette.danger.main,
  },
  buttonRight: {
    float: 'right',
  }
});

