export default theme => ({
  root: {
    padding: theme.spacing(2)
  },
  helpWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pageElement: {
    marginBottom: theme.spacing(3),
    border: `1px solid ${theme.palette.border}`
  },
  map: {
    minWidth: '100%',
    minHeight: 'calc(-195px + 100vh)',
    border: '1px solid lightgrey',
    borderRadius: '3px'
  },
});
