export class DateService {

  static padStringLeft(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }

  static padTowNumbers(number) {
    return this.padStringLeft(String(number), '0', 2);
  }

  static displayDate(inputDate) {
    if (inputDate == null) {
      return '-';
    }
    const date = new Date(inputDate);
    let day = date.getDate();
    let month = date.getMonth();
    month = month + 1;

    day = this.padTowNumbers(day);
    month = this.padTowNumbers(month);

    return `${day}.${month}.${date.getFullYear()}`;
  }

  static displayTime(inputDateTime) {
    if (inputDateTime == null) {
      return '-';
    }
    const dateTime = new Date(inputDateTime);
    let hour = dateTime.getHours();
    let minutes = dateTime.getMinutes();
    minutes = this.padTowNumbers(minutes);
    return `${hour}:${minutes}`;
  }

  static displayDateTime(inputDateTime) {
    return `${DateService.displayDate(inputDateTime)} ${DateService.displayTime(inputDateTime)}`
  }

  static displayTimeFromSeconds(seconds) {
    let remaining = seconds;
    const hours = Math.floor(remaining / 3600);
    remaining = remaining - hours * 3600;
    const minutes = Math.floor(remaining / 60);
    remaining = remaining - minutes * 60;
    const sec = Math.floor(remaining);
    return `${hours}:${this.padTowNumbers(minutes)}:${this.padTowNumbers(sec)}`
  }
}
