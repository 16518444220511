import React, {useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {DataLoadingWrapper} from '../../../index';
import Typography from '@material-ui/core/Typography';
import {ClientService} from '../../../../services/client/clientService';
import {clientTypes} from '../../../../services/client/clientTypes';
import useCollapse from 'react-collapsed';
import {useMutation, useQuery} from '@apollo/react-hooks';
import EditHub from './EditHub'
import ViewHub from './ViewHub'
import {MicroHubQueryService} from '../../../../services/backend/microHubQueryService';
import {userRoles} from '../../../../services/auth/userRoles';
import {AuthService} from '../../../../services/auth/authService';

const client = ClientService.getClient(clientTypes.microHub);

function HubInfo(props) {
  const {className, classes, t, hubName, carrierName, refetchCarrier, readonly} = props;
  const {getCollapseProps, getToggleProps, isOpen, mountChildren, toggleOpen} = useCollapse({defaultOpen: false});

  const query = MicroHubQueryService.getMicroHubsByCarrierNameAndMicroHubNameList(AuthService.getUserRole() === userRoles.carrier ? AuthService.getUserOrganization() : '', [hubName]);
  const {loading: loadingHub, error: errorHub, data: dataHub, refetch: refetchHub} = useQuery(query, {client: client, skip: !isOpen});

  const updateHubQuery = MicroHubQueryService.updateMicroHub();
  const [updateHub] = useMutation(updateHubQuery, {client: client});

  const removeHubFormCarrierQuery = MicroHubQueryService.removeMicroHubFromCarrierQuery();
  const [removeHubFromCarrier] = useMutation(removeHubFormCarrierQuery, {client: client});

  const deleteHubQuery = MicroHubQueryService.deleteMicroHubQuery();
  const [deleteHub] = useMutation(deleteHubQuery, {client: client});

  const rootClassName = classNames(classes.root, className);

  const [editHubState, setEditHubState] = useState(false);

  const onEditClick = () => {
    setEditHubState(true);
  };

  const saveClick = (info, address, contact) => {
    const microHub = {
      id: dataHub.microhubs[0].id,
      name: info.hubName, email: info.email, b2B: info.b2b, b2C: info.b2c,
      address: address,
      contact: contact,
    };
    setEditHubState(false);
    updateHub({variables: {microHub: microHub}}).then(() => {
      if (dataHub.microhubs[0].name !== microHub.name) {
        refetchCarrier();
      }
      refetchHub().then();
    }, () => {
      alert(t('errorHubUpdate'));
    });
  };

  const cancelClick = () => {
    setEditHubState(false);
  };

  const removeFromCarrierClick = () => {
    setEditHubState(false);
    toggleOpen();
    removeHubFromCarrier({variables: {microHubId: dataHub.microhubs[0].id, carrierName: carrierName}}).then(() => {
      refetchCarrier();
    }, () => {
      alert(t('errorRemoveHubFromCarrier'));
    });
  };

  const deleteClick = () => {
    setEditHubState(false);
    toggleOpen();
    deleteHub({variables: {microHubId: dataHub.microhubs[0].id}}).then(() => {
      refetchCarrier();
    }, () => {
      alert(t('errorDeleteHub'));
    });
  };

  return (
    <div className={rootClassName}>
      <DataLoadingWrapper
        error={errorHub}
        isLoading={loadingHub}
      >
        <div
          {...getToggleProps()}
          className={'noselect cursorPointer'}
        >
          <Typography variant="h3">
            {hubName}
            <i className="material-icons md-48">{isOpen ? 'expand_less' : 'expand_more'}</i>
          </Typography>
        </div>
        <div {...getCollapseProps()}>
          {mountChildren && dataHub && dataHub.microhubs && (
            editHubState ?
              <EditHub
                cancelClick={cancelClick}
                carrierName={carrierName}
                deleteClick={deleteClick}
                microHub={dataHub.microhubs[0] ? dataHub.microhubs[0] : null}
                removeFromCarrierClick={removeFromCarrierClick}
                saveClick={saveClick}
              />
              :
              <ViewHub
                carrierName={carrierName}
                microHub={dataHub.microhubs[0] ? dataHub.microhubs[0] : null}
                onEditClick={onEditClick}
                readonly={readonly}
              />
          )}
        </div>
      </DataLoadingWrapper>
    </div>
  );
}

HubInfo.propTypes = {
  carrierName: PropTypes.string.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  hubName: PropTypes.string.isRequired,
  readonly: PropTypes.bool.isRequired,
  refetchCarrier: PropTypes.func,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(HubInfo);
