import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {DateService} from '../../../../../services/util/DateService';
import {Portlet, PortletContent, ShipperLabel, StopIdLabel, TextPlaceholderWrapper} from '../../../../../components';
import {WarningRounded as WarnIcon} from '@material-ui/icons';
import {getStopStatusString, StopStatus} from '../../../../../services/enums/StopStatus';
import {onCloseWithBackdropCurry, roundCapacityValues} from '../../../../../services/util/helperFuncs';

function StopsWithoutStoragePlaceDialog(props) {

  const {classes, t, dialogState, handleClose, stops} = props;

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      maxWidth={'lg'}
      onClose={onCloseWithBackdropCurry(handleClose)}
      open={dialogState.open}
    >
      <DialogTitle>{t('stopsWithoutStoragePlace')}</DialogTitle>
      <DialogContent>
        <TextPlaceholderWrapper
          active={!stops?.length}
          text={t('noStopsAvailable')}
        >
          <Portlet>
            <PortletContent noPadding>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.alignLeft}>{t('tourStopId')}</TableCell>
                    <TableCell className={classes.alignLeft}>{t('tourId')}</TableCell>
                    <TableCell className={classes.alignLeft}>{t('shipper')}</TableCell>
                    <TableCell className={classes.alignLeft}>{t('planedDeliveryDateShipper')}</TableCell>
                    <TableCell className={classes.alignLeft}>{t('stopStatus')}</TableCell>
                    <TableCell className={classes.alignLeft}>{t('boxAmount')}</TableCell>
                    <TableCell className={classes.alignLeft}>{t('weight')}</TableCell>
                    <TableCell className={classes.alignLeft}>{t('volume')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stops.map((stop, index) => (
                    <TableRow
                      hover
                      key={index}
                    >
                      <TableCell className={classes.tableCell}>
                        <StopIdLabel stop={stop}/>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {stop.tourId}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <ShipperLabel stop={stop}/>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {DateService.displayDate(stop.planedDeliveryDateShipper)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Typography
                          variant={'body2'}
                        >
                          {stop.stopStatus === StopStatus.CanNotDeliver &&
                          <Tooltip
                            title={`${t('stopCouldNotBeDelivered')}: ${stop.driverInfo ? stop.driverInfo : t('noDriverInfoAvailable')}`}
                          >
                            <WarnIcon className={classes.dangerColor}/>
                          </Tooltip>
                          }
                          &nbsp;{t(getStopStatusString(stop.stopStatus))}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {roundCapacityValues(stop.stopCapacities.boxAmount)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {roundCapacityValues(stop.stopCapacities.weight)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {roundCapacityValues(stop.stopCapacities.volume)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </PortletContent>
          </Portlet>
        </TextPlaceholderWrapper>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleClose}
        >
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

StopsWithoutStoragePlaceDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogState: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  stops: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(StopsWithoutStoragePlaceDialog);
