import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {Step, StepContent, StepLabel, Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import React, {Fragment, useState} from 'react';
import {StepperIcon, TrackingStepEvaluation} from '../index';
import classNames from 'classnames';
import {DateService} from '../../../../../services/util/DateService';
import moment from 'moment';
import {getStopStatusString, StopStatus} from '../../../../../services/enums/StopStatus';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import {StopIdLabel} from '../../../../index';

const StopInDeliveryStep = (props) => {
  const {className, classes, t, stop, trackingStop, plannedStopDelivery} = props;

  const [active, setActive] = useState(false);
  trackingStop.trackingSteps.sort((a, b) => new Date(a.eventTimeStamp) - new Date(b.eventTimeStamp));
  const lastTrackingStep = trackingStop.trackingSteps[trackingStop.trackingSteps.length - 1];

  const rootClassName = classNames(classes.root, className);

  return (
    <Step
      active={active}
      className={rootClassName}
    >
      <StepLabel
        classes={{error: classes.noErrorClass}}
        StepIconComponent={StepperIcon}
      >
        <div
          className={classes.stepperLabel}
          onClick={() => setActive(!active)}
        >
          <Typography
            variant={'subtitle1'}
          >
            {`[${DateService.displayDateTime(new Date())}] - ${t(getStopStatusString(StopStatus.InDelivery))}: ${stop.stopNumber}`}
          </Typography>
          {active ? <ExpandLess/> : <ExpandMore/>}
        </div>
      </StepLabel>
      <StepContent>
        <Fragment>
          <Typography><strong>{t('tourStopId')}:</strong> <StopIdLabel stop={stop}/></Typography>
          <Typography><strong>{t('lastName')}:</strong> {stop.lastName}</Typography>
          <Typography><strong>{t('address')}:</strong> {stop.address.streetAndNumber}</Typography>
          <Typography><strong>{t('plannedStopDelivery')}:</strong> {DateService.displayDateTime(plannedStopDelivery)}</Typography>
          <Typography>
            <strong>{t('currentDifferenceToPlannedStopDelivery')}:</strong> {moment.duration(moment().diff(moment(plannedStopDelivery))).format('h:mm:ss', {trim: false})}
          </Typography>
          <Typography><strong>{t('estimatedDeliveryWindow')}:</strong> {lastTrackingStep ? `${DateService.displayTime(lastTrackingStep?.deliveryWindow?.from)} - ${DateService.displayTime(lastTrackingStep?.deliveryWindow?.to)}` : '-'}
          </Typography>
          <Typography><strong>{t('trackingEvaluation')}</strong></Typography>
          <div className={classes.trackingEvaluation}>
            {trackingStop.trackingSteps.map((step, index) =>
              <TrackingStepEvaluation
                deliveryTime={new Date()}
                key={index}
                trackingStep={step}
              />
            )}
          </div>
        </Fragment>
      </StepContent>
    </Step>
  );
};


StopInDeliveryStep.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  plannedStopDelivery: PropTypes.string.isRequired,
  stop: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  trackingStop: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withTranslation())(StopInDeliveryStep);
