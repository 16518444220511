import React from 'react'
import {Table, TableBody, TableCell, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles';
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {DateService} from '../../services/util/DateService';


function DataUpload(props) {

  const {excels, classes, t} = props;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.alignLeft}>{t('fileName')}</TableCell>
          <TableCell className={classes.alignLeft}>{t('uploadDate')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {excels.map((excel, index) => (
          <TableRow
            hover
            key={index}
          >
            <TableCell className={classes.tableCell}>
              {excel.name}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {DateService.displayDateTime(excel.uploadDate)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

}

DataUpload.propTypes = {
  excels: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(DataUpload);
