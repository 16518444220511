import React, {useState} from 'react'
import {Box, Collapse, IconButton, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Draggable} from 'react-beautiful-dnd'
import {DndHelperService} from '../../../../../services/dndHelper/dndHelperService';
import {DragIndicatorOutlined as DragIcon, KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon} from '@material-ui/icons';
import {roundCapacityValues} from '../../../../../services/util/helperFuncs';
import StopTableDetails from '../../../StopTableDetails';
import {PreviewStopIdLabel} from '../../../../index';

function Stop(props) {

  const {classes, stop, index, uniqueTourId, t, className, tourFrozen} = props;

  const [open, setOpen] = useState(false);

  const rootClassName = classNames(stop.editable ? classes.root : classes.rootDisabled, className);
  const uniqueStopId = DndHelperService.getUniqueStopId(uniqueTourId, stop.id);

  return (
    <Draggable
      draggableId={uniqueStopId}
      index={index}
      isDragDisabled={tourFrozen || !stop.editable}
    >
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <div
            className={classNames(rootClassName, snapshot.isDragging ? classes.dragging : '')}
          >
            <div className={classes.stopHeader}>
              <div
                {...provided.dragHandleProps}
                style={{height: '22px', maxHeight: '22px'}}
              >
                <DragIcon style={tourFrozen || !stop.editable ? {visibility: 'hidden'} : {}}/>
              </div>
              <Typography className={classes.headerSectionSmall}>
                <strong>{t('stopNumber')}</strong>: {stop.stopNumber}
              </Typography>
              <Typography className={classes.headerSectionLarge}>
                <strong>{t('previewStopID')}: <PreviewStopIdLabel stop={stop}/></strong>
              </Typography>
              <Typography className={classes.headerSection}>
                <strong>{t('boxAmount')}</strong>: {roundCapacityValues(stop.stopCapacities.boxAmount)}
              </Typography>
              <Typography className={classes.headerSection}>
                <strong>{t('weight')}</strong>: {roundCapacityValues(stop.stopCapacities.weight)}
              </Typography>
              <Typography className={classNames(classes.headerSection, classes.lastHeaderSection)}>
                <strong>{t('volume')}</strong>: {roundCapacityValues(stop.stopCapacities.volume)}
              </Typography>
              <IconButton
                className={classes.iconButton}
                onClick={() => setOpen(!open)}
                size="small"
                style={snapshot.isDragging ? {display: 'none'} : {}}
              >
                {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
              </IconButton>
            </div>
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
            >
              <Box
                margin={1}
                style={snapshot.isDragging ? {display: 'none'} : {}}
              >
                <StopTableDetails
                  stop={stop}
                />
              </Box>
            </Collapse>
          </div>
        </div>
      )}
    </Draggable>
  );
}


Stop.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  stop: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  tourFrozen: PropTypes.bool.isRequired,
  uniqueTourId: PropTypes.string.isRequired
};

export default compose(withStyles(styles), withTranslation())(Stop);
