export default theme => ({
  root: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0)
  },
  hubBaskets: {},
  hubList: {},
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '3px',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  wrapperList: {
    width: '55%',
    marginRight: theme.spacing(2)
  },
  wrapperBasket: {
    width: '45%'
  },
  controlButtons: {
    marginLeft: theme.spacing(3),
  },
  refreshButton: {},
  controlButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: -theme.spacing(8),
    marginRight: -theme.spacing(1),
  }
});

