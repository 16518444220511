import React, {useState} from 'react'
import {
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  withStyles
} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {AddCircle as AddCircleIcon, DeleteForever as DeleteForeverIcon, FiberManualRecord as BulletPoint} from '@material-ui/icons';

function WeekDayShipperListEdit(props) {

  const {classes, className, editedMicroHub, setEditedMicroHub, selectedCarrier, shipperNames, weekDayId, t} = props;

  const rootClassName = classNames(classes.root, className);

  const [selectedShipper, setSelectedShipper] = useState('');

  return (
    <div className={rootClassName}>
      <div className={classes.vehicleDetail}><strong>{t(editedMicroHub.carriers[selectedCarrier].weekDayShipperLists[weekDayId].weekDay.toLowerCase())}</strong>
      </div>
      <div><strong>
        <FormControl className={classes.button}>
          <RadioGroup
            onChange={(event) => {
              if (window.confirm(t('changeAllowList'))) {
                setEditedMicroHub({
                  ...editedMicroHub,
                  carriers: editedMicroHub.carriers.map((c, i) => i === selectedCarrier ? {
                    ...c,
                    weekDayShipperLists: c.weekDayShipperLists.map((l, j) => j === weekDayId ? {
                      ...l,
                      allowList: event.target.value === 'true',
                      shippers: []
                    } : l)
                  } : c)
                });
              }
            }
            }
            value={editedMicroHub.carriers[selectedCarrier].weekDayShipperLists[weekDayId].allowList}
          >
            <FormControlLabel
              control={<Radio/>}
              label={t('allowedShippers')}
              value
            />
            <FormControlLabel
              control={<Radio/>}
              label={t('forbiddenShippers')}
              value={false}
            />
          </RadioGroup>
        </FormControl>
      </strong></div>
      <List
        className={classes.shipperList}
      >
        {editedMicroHub.carriers[selectedCarrier].weekDayShipperLists[weekDayId].shippers.map((shipper, index) =>
          <ListItem
            key={index}
          >
            <ListItemIcon
              className={classes.bulletPoint}
            >
              <BulletPoint
                style={{width: '12px'}}
              />
            </ListItemIcon>
            <ListItemText
              className={classes.shipperName}
              primary={shipper.name}
            />
            <ListItemSecondaryAction>
              <IconButton
                className={classes.deleteButton}
                onClick={() => setEditedMicroHub({
                  ...editedMicroHub,
                  carriers: editedMicroHub.carriers.map((c, i) => i === selectedCarrier ? {
                    ...c,
                    weekDayShipperLists: c.weekDayShipperLists.map((l, j) => j === weekDayId ? {...l, shippers: l.shippers.filter((_, k) => k !== index)} : l)
                  } : c)
                })}
              >
                <DeleteForeverIcon/>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        )}
      </List>
      <FormControl className={classes.formControl}>
        <Select
          onChange={(event) => {
            setSelectedShipper(event.target.value)
          }}
          value={selectedShipper}
        >
          {shipperNames.shippers.filter(shipper => !editedMicroHub.carriers[selectedCarrier].weekDayShipperLists[weekDayId].shippers.map(x => x.name).includes(shipper.name)).map((shipper, index) => {
            return (
              <MenuItem
                key={index}
                value={shipper.name}
              >
                {shipper.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <IconButton
        className={classes.iconButton}
        color="primary"
        disabled={selectedShipper === ''}
        onClick={() => {
          setEditedMicroHub({
            ...editedMicroHub,
            carriers: editedMicroHub.carriers.map((c, i) => i === selectedCarrier ? {
              ...c,
              weekDayShipperLists: c.weekDayShipperLists.map((l, j) => j === weekDayId ? {...l, shippers: [...l.shippers, {name: selectedShipper}]} : l)
            } : c)
          });
          setSelectedShipper('');
        }}
      >
        <AddCircleIcon/>
      </IconButton>
    </div>
  );
}


WeekDayShipperListEdit.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  editedMicroHub: PropTypes.object.isRequired,
  setEditedMicroHub: PropTypes.func.isRequired,
  selectedCarrier: PropTypes.number.isRequired,
  shipperNames: PropTypes.object.isRequired,
  weekDayId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(WeekDayShipperListEdit);
