import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Redirect, Route, Switch, useLocation, withRouter} from 'react-router-dom';

import {
  AreaSelectionCarrier,
  CustomerCatalogCarrier,
  DashboardCarrier,
  DataUploadCarrier,
  DeletedStopsCarrier,
  DriversCarrier,
  FinalDeadlineMigrationCarrier,
  MapDispositionCarrier,
  MicroHubAdministrationCarrier,
  MicroHubMeasurementsCarrier,
  MicroHubOverviewCarrier,
  PreviewToursCarrier,
  ProfileCarrier,
  SimulateTourBuildingCarrier,
  SpecialDeliveryDayCarrier,
  StatisticsOverviewCarrier,
  StopOverviewCarrier,
  TemplateStopOverviewCarrier,
  TimeDispositionCarrier,
  TourOverviewCarrier,
  TourTemplatesCarrier,
  VehiclesCarrier
} from './views/carrier';
import {
  AdministrationAdmin,
  AreaSelectionAdmin,
  BoxAdministrationAdmin,
  CarrierOverview,
  CustomerCatalogAdmin,
  DashboardAdmin,
  DeletedStopsAdmin,
  FinalDeadlineMigrationAdmin,
  MicroHubAdministrationAdmin,
  MicroHubMeasurementAdmin,
  MicroHubOverviewAdmin,
  PCGaertnerConfigAdmin,
  PreviewToursAdmin,
  ProfileAdmin,
  SensorConfigAdmin,
  ShipperApiConfigAdmin,
  SimulateTourBuildingAdmin,
  SpecialDeliveryDayAdmin,
  StatisticsOverviewAdmin,
  StopOverviewAdmin,
  TemplateStopOverviewAdmin,
  TourOverviewAdmin,
  TourTemplatesAdmin
} from './views/administration'
import {
  AreaSelectionShipper,
  BoxAdministrationShipper,
  CustomerCatalogShipper,
  DashboardShipper,
  DataUploadShipper,
  DeletedStopsShipper,
  OrderOverviewShipper,
  PreviewToursShipper,
  ProfileShipper,
  SimulateTourBuildingShipper,
  StatisticsOverviewShipper,
  TemplateStopOverviewShipper
} from './views/shipper'
import {AppDownload, StopDetailPage, TourDetailPage} from './views/shared';
import NotFound from './views/NotFound';
import Login from './views/Login';
import TrackingLink from './views/TrackingLink';
import Privacy from './views/Privacy';
import Impressum from './views/Impressum';
import {AuthService} from './services/auth/authService';
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {userRoles} from './services/auth/userRoles';
import identityService from './services/backend/identityService';
import {NewsToUserDialogContext} from './context';


function Routes(props) {

  const [loggedIn, setLoggedIn] = useState(false);
  const [privacyConfirmed, setPrivacyConfirmed] = useState(false);
  const [privacyConfirmDialogOpen, setPrivacyConfirmDialogOpen] = useState(false);
  const [newsToUserAlreadyShown, setNewsToUserAlreadyShown] = useState(false);

  let location = useLocation();

  const { open } = useContext(NewsToUserDialogContext);

  const loadNewsToUser = useCallback(async () => {
    const irrelevantRoutes = ['login', 'not-found', 'impressum', 'tracking', 'privacy', 'gsl-info', 'tracking']
    const currentLocation = location.pathname.split('/')[0]
    if (loggedIn && !newsToUserAlreadyShown && !irrelevantRoutes.includes(currentLocation)) {
      const newsToUser = await identityService.getNewsForUser();
      if (newsToUser.length > 0) {
        open(newsToUser);
        setNewsToUserAlreadyShown(true);
      }
    }
  }, [loggedIn, location, newsToUserAlreadyShown, open])

  useEffect(() => {
    loadNewsToUser();
  }, [loadNewsToUser])


  const handleConfirmation = async (confirmed) => {
    const confirmation = await identityService.confirmPrivacy(confirmed);
    setPrivacyConfirmDialogOpen(false);
    if (!confirmation) {
      await AuthService.logout();
    } else {
      setLoggedIn(false);
      await AuthService.signinSilent();
      setLoggedIn(true);
      setPrivacyConfirmed(true);
    }
  }

  const confirmPrivacy = async () => {
    if (!AuthService.getPrivacyConfirmation()) {
      if (privacyConfirmDialogOpen) return;
      setPrivacyConfirmDialogOpen(true);
    } else {
      setPrivacyConfirmed(true);
    }
  }

  const updateUser = (encodedRedirectLocation) => {
    try {
      const redirectLocation = decodeURIComponent(encodedRedirectLocation);
      const redirect = window.localStorage.getItem('redirectUrlKey');
      AuthService.loadUser().then(() => {
        try {
          const loggedIn = AuthService.isLoggedIn();
          setLoggedIn(loggedIn);
          const { history } = props;
          if (loggedIn) {
            // Prompt user with dialog to confirm privacy
            if (!privacyConfirmed) {
              confirmPrivacy();
              return;
            }

            document.cookie = 'WS-Authorization=' + AuthService.getAuthToken() + '; path=/; samesite=strict; secure';
            if (redirect) {
              history.push(redirect)
            } else if (redirectLocation && redirectLocation !== '/login') {
              history.push(redirectLocation)
            } else {
              history.push('/dashboard')
            }
            window.localStorage.removeItem('redirectUrlKey');
          } else {
            window.localStorage.setItem('redirectUrlKey', redirectLocation);
            AuthService.signinSilent().then(() => {
              setLoggedIn(AuthService.isLoggedIn());
            });
          }
        } catch {
          window.localStorage.removeItem('redirectUrlKey');
        }
      });
    } catch {
      window.localStorage.removeItem('redirectUrlKey');
    }
  };

  if (!loggedIn) {
    return (
      <Switch>
        <Route
          exact
          path="/login/:redirectUrl"
          render={(props) =>
            <Login
              {...props}
              onPrivacyConfirmDialogClose={handleConfirmation}
              privacyConfirmDialogOpen={privacyConfirmDialogOpen}
              updateUser={updateUser}
            />}
        />
        <Route
          component={TrackingLink}
          path={'/tracking'}
        />
        <Route
          component={Impressum}
          exact
          path={'/impressum'}
        />
        <Route
          component={Privacy}
          exact
          path={'/privacy'}
        />
        <Route
          component={() => {
            window.location.href = 'https://www.xn--grnestadtlogistik-32b.de';
            return null;
          }}
          exact
          path="/gsl-info"
        />
        <Redirect
          to={`/login/${encodeURIComponent(location.pathname + location.search)}`}
        />
      </Switch>
    );
  }

  if (AuthService.getUserRole() === userRoles.administration) {
    return (
      <Switch>
        <Route
          component={TrackingLink}
          exact
          path={'/tracking/:guid'}
        />
        <Redirect
          exact
          from="/"
          to="/dashboard"
        />
        <Route
          exact
          path="/login/:redirectUrl"
          render={(props) =>
            <Login
              {...props}
              onPrivacyConfirmDialogClose={handleConfirmation}
              privacyConfirmDialogOpen={privacyConfirmDialogOpen}
              updateUser={updateUser}
            />}
        />
        <Route
          component={DashboardAdmin}
          exact
          path="/dashboard"
        />
        <Route
          component={ProfileAdmin}
          exact
          path="/profile"
        />
        <Route
          component={AreaSelectionAdmin}
          exact
          path="/area"
        />
        <Route
          component={TourOverviewAdmin}
          exact
          path="/tour-overview"
        />
        <Route
          component={StatisticsOverviewAdmin}
          exact
          path="/statistics-overview"
        />
        <Route
          component={StopOverviewAdmin}
          exact
          path="/stop-overview"
        />
        <Route
          component={TemplateStopOverviewAdmin}
          exact
          path="/template-stop"
        />
        <Route
          component={AdministrationAdmin}
          exact
          path="/administration"
        />
        <Route
          component={SimulateTourBuildingAdmin}
          exact
          path="/simulateTourBuilding"
        />
        <Route
          component={PreviewToursAdmin}
          exact
          path="/previewTours"
        />
        <Route
          component={CarrierOverview}
          exact
          path="/carrierOverview"
        />
        <Route
          component={MicroHubAdministrationAdmin}
          exact
          path="/microHubAdministration"
        />
        <Route
          component={BoxAdministrationAdmin}
          exact
          path="/boxAdministration"
        />
        <Route
          component={MicroHubOverviewAdmin}
          exact
          path="/microHubOverview"
        />
        <Route
          component={ShipperApiConfigAdmin}
          exact
          path="/shipperApiConfig"
        />
        <Route
          component={PCGaertnerConfigAdmin}
          exact
          path="/pcGaertnerConfig"
        />
        <Route
          component={DeletedStopsAdmin}
          exact
          path="/deletedStops"
        />
        <Route
          component={TourTemplatesAdmin}
          exact
          path="/tourTemplates"
        />
        <Route
          component={CustomerCatalogAdmin}
          exact
          path="/customerCatalog"
        />
        <Route
          component={FinalDeadlineMigrationAdmin}
          exact
          path="/finalDeadlineMigration"
        />
        <Route
          component={TourDetailPage}
          exact
          path="/tour-detail/:tourId"
        />
        <Route
          component={StopDetailPage}
          exact
          path="/stop-detail/:stopId"
        />
        <Route
          component={NotFound}
          exact
          path="/not-found"
        />
        <Route
          component={Impressum}
          exact
          path={'/impressum'}
        />
        <Route
          component={Privacy}
          exact
          path={'/privacy'}
        />
        <Route
          component={AppDownload}
          exact
          path={'/get-app'}
        />
        <Route
          component={SpecialDeliveryDayAdmin}
          exact
          path={'/specialDeliveryDays'}
        />
        <Route
          component={MicroHubMeasurementAdmin}
          exact
          path={'/microHubMeasurements'}
        />
        <Route
          component={SensorConfigAdmin}
          exact
          path={'/sensorConfig'}
        />
        <Route
          component={() => {
            window.location.href = 'https://www.xn--grnestadtlogistik-32b.de';
            return null;
          }}
          exact
          path="/gsl-info"
        />
        <Redirect to="/not-found" />
      </Switch>
    );
  }

  if (AuthService.getUserRole() === userRoles.carrier) {
    return (
      <Switch>
        <Route
          component={TrackingLink}
          exact
          path={'/tracking/:guid'}
        />
        <Redirect
          exact
          from="/"
          to="/dashboard"
        />
        <Route
          exact
          path="/login/:redirectUrl"
          render={(props) =>
            <Login
              {...props}
              onPrivacyConfirmDialogClose={handleConfirmation}
              privacyConfirmDialogOpen={privacyConfirmDialogOpen}
              updateUser={updateUser}
            />}
        />
        <Route
          component={DashboardCarrier}
          exact
          path="/dashboard"
        />
        <Route
          component={StopOverviewCarrier}
          exact
          path="/stop-overview"
        />
        <Route
        component={TemplateStopOverviewCarrier}
        exact
        path="/template-stop"
        />
        <Route
          component={StatisticsOverviewCarrier}
          exact
          path="/statistics-overview"
        />
        <Route
          component={TimeDispositionCarrier}
          exact
          path="/time_disposition"
        />
        <Route
          component={MapDispositionCarrier}
          exact
          path="/map_disposition"
        />
        <Route
          component={TourOverviewCarrier}
          exact
          path="/tour-overview"
        />
        <Route
          component={VehiclesCarrier}
          exact
          path="/vehicles"
        />
        <Route
          component={DriversCarrier}
          exact
          path="/drivers"
        />
        <Route
          component={ProfileCarrier}
          exact
          path="/profile"
        />
        <Route
          component={AreaSelectionCarrier}
          exact
          path="/area"
        />
        <Route
          component={DataUploadCarrier}
          exact
          path="/dataUpload"
        />
        <Route
          component={SimulateTourBuildingCarrier}
          exact
          path="/simulateTourBuilding"
        />
        <Route
          component={PreviewToursCarrier}
          exact
          path="/previewTours"
        />
        <Route
          component={MicroHubAdministrationCarrier}
          exact
          path="/microHubAdministration"
        />
        <Route
          component={MicroHubOverviewCarrier}
          exact
          path="/microHubOverview"
        />
        <Route
          component={DeletedStopsCarrier}
          exact
          path="/deletedStops"
        />
        <Route
          component={TourTemplatesCarrier}
          exact
          path="/tourTemplates"
        />
        <Route
          component={CustomerCatalogCarrier}
          exact
          path="/customerCatalog"
        />
        <Route
          component={FinalDeadlineMigrationCarrier}
          exact
          path="/finalDeadlineMigration"
        />
        <Route
          component={TourDetailPage}
          exact
          path="/tour-detail/:tourId"
        />
        <Route
          component={StopDetailPage}
          exact
          path="/stop-detail/:stopId"
        />
        <Route
          component={NotFound}
          exact
          path="/not-found"
        />
        <Route
          component={AppDownload}
          exact
          path={'/get-app'}
        />
        <Route
          component={Impressum}
          exact
          path={'/impressum'}
        />
        <Route
          component={Privacy}
          exact
          path={'/privacy'}
        />
        <Route
          component={SpecialDeliveryDayCarrier}
          exact
          path={'/specialDeliveryDays'}
        />
        <Route
          component={MicroHubMeasurementsCarrier}
          exact
          path={'/microHubMeasurements'}
        />
        <Route
          component={() => {
            window.location.href = 'https://www.xn--grnestadtlogistik-32b.de';
            return null;
          }}
          exact
          path="/gsl-info"
        />
        <Redirect to="/not-found" />
      </Switch>
    );
  }

  if (AuthService.getUserRole() === userRoles.shipper) {
    return (
      <Switch>
        <Route
          component={TrackingLink}
          exact
          path={'/tracking/:guid'}
        />
        <Redirect
          exact
          from="/"
          to="/dashboard"
        />
        <Route
          exact
          path="/login/:redirectUrl"
          render={(props) =>
            <Login
              {...props}
              onPrivacyConfirmDialogClose={handleConfirmation}
              privacyConfirmDialogOpen={privacyConfirmDialogOpen}
              updateUser={updateUser}
            />}
        />
        <Route
          component={DashboardShipper}
          exact
          path="/dashboard"
        />
        <Route
          component={DataUploadShipper}
          exact
          path="/dataUpload"
        />
        <Route
          component={OrderOverviewShipper}
          exact
          path="/order"
        />
        <Route
          component={ProfileShipper}
          exact
          path="/profile"
        />
        <Route
          component={SimulateTourBuildingShipper}
          exact
          path="/simulateTourBuilding"
        />
        <Route
          component={PreviewToursShipper}
          exact
          path="/previewTours"
        />
        <Route
          component={BoxAdministrationShipper}
          exact
          path="/boxAdministration"
        />
        <Route
          component={StatisticsOverviewShipper}
          exact
          path="/statistics-overview"
        />
        <Route
          component={TemplateStopOverviewShipper}
          exact
          path="/template-stop"
        />
        <Route
          component={DeletedStopsShipper}
          exact
          path="/deletedStops"
        />
        <Route
          component={AreaSelectionShipper}
          exact
          path="/area"
        />
        <Route
          component={CustomerCatalogShipper}
          exact
          path="/customerCatalog"
        />
        <Route
          component={TourDetailPage}
          exact
          path="/tour-detail/:tourId"
        />
        <Route
          component={StopDetailPage}
          exact
          path="/stop-detail/:stopId"
        />
        <Route
          component={NotFound}
          exact
          path="/not-found"
        />
        <Route
          component={Impressum}
          exact
          path={'/impressum'}
        />
        <Route
          component={Privacy}
          exact
          path={'/privacy'}
        />
        <Route
          component={() => {
            window.location.href = 'https://www.xn--grnestadtlogistik-32b.de';
            return null;
          }}
          exact
          path="/gsl-info"
        />
        <Redirect to="/not-found" />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route
        component={AppDownload}
        exact
        path={'/get-app'}
      />
      <Route
        component={Impressum}
        exact
        path={'/impressum'}
      />
      <Route
        component={Privacy}
        exact
        path={'/privacy'}
      />
      <Route
        component={() => {
          window.location.href = 'https://www.xn--grnestadtlogistik-32b.de';
          return null;
        }}
        exact
        path="/gsl-info"
      />
      <Redirect to="/get-app" />
    </Switch>
  );
}

Login.propTypes = {
  history: PropTypes.object.isRequired,
};

export default compose(withRouter)(Routes);
