import Columns from './columns';
import {displayModes} from '../../../../../../services/enums/displayModes';

// CARE: ALWAYS INCREMENT THE VERSION IF THE SETTINGS FOR A COLUMN CHANGE!
// Config explanation
// optional: determines if the hidden status can be changed by the user
// hidden: determines if the field is shown or hidden
// conditional: indicates that the hidden status can be overriden using the conditions dictionary of the useTableConfig hook
const DefaultConfig = {
  [displayModes.admin]: {
    version: 1,
    columns: {
      internalCustomerId: {
        columnName: Columns.internalCustomerId.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 1,
      },
      streetAndNumber: {
        columnName: Columns.streetAndNumber.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 2
      },
      zipcode: {
        columnName: Columns.zipcode.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 3
      },
      boxAmount: {
        columnName: Columns.boxAmount.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 4
      },
      weight: {
        columnName: Columns.weight.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 5
      },
      volume: {
        columnName: Columns.volume.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 6
      },
    }
  },
  [displayModes.carrier]: {
    version: 1,
    columns: {
      internalCustomerId: {
        columnName: Columns.internalCustomerId.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 1,
      },
      streetAndNumber: {
        columnName: Columns.streetAndNumber.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 2
      },
      zipcode: {
        columnName: Columns.zipcode.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 3
      },
      boxAmount: {
        columnName: Columns.boxAmount.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 4
      },
      weight: {
        columnName: Columns.weight.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 5
      },
      volume: {
        columnName: Columns.volume.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 6
      },
    }
  },
  [displayModes.shipper]: {
    version: 1,
    columns: {}
  },
}

export default DefaultConfig;