import {Typography} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import {Marker, Popup} from 'react-leaflet';
import {IconService} from '../../../../../services/icon/iconService';


function MicroHubMarker(props) {

  const {t, className, microHub, useMicroHubColor} = props;

  return (
    <Marker
      className={className}
      icon={IconService.getMicroHubIcon(useMicroHubColor ? microHub.color : null)}
      position={[microHub.address.lat, microHub.address.lng]}
    >
      <Popup>
        <Typography variant="body1">
          <strong>{t('name')}</strong>: {microHub.name}<br/>
          <strong>{t('streetAndNumber')}</strong>: {microHub.address.street + ' ' + microHub.address.houseNumber}<br/>
          <strong>{t('zipcode')}</strong>: {microHub.address.zipcode}<br/>
          <strong>{t('city')}</strong>: {microHub.address.city}<br/>
          <strong>{t('carrier')}</strong>: {microHub.carriers.map(c => c.carrier.name).join(', ')}<br/>
        </Typography>
      </Popup>
    </Marker>
  );
}

MicroHubMarker.propTypes = {
  className: PropTypes.string,
  microHub: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  useMicroHubColor: PropTypes.bool,
};


export default withTranslation()(MicroHubMarker);
