import React, {useState} from 'react'
import {Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Input, InputLabel, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {Portlet, PortletContent} from '../../../../index';
import {Cancel as CancelIcon, DeleteForever as DeleteForeverIcon, DeleteSweep as DeleteIcon, Save as SaveIcon} from '@material-ui/icons';
import classNames from 'classnames';
import {AllowedShippers, OperatingTime} from '../../index';
import {CreateAddress, CreatePerson, Person} from '../../../Shared';

function EditHub(props) {
  const {classes, t, microHub, saveClick, cancelClick, removeFromCarrierClick, deleteClick, carrierName} = props;

  const [address, setAddress] = useState({
    street: microHub.address.street,
    houseNumber: microHub.address.houseNumber,
    zipcode: microHub.address.zipcode,
    city: microHub.address.city,
    country: microHub.address.country,
  });

  const [contact, setContact] = useState({
    name: microHub.contact.name,
    email: microHub.contact.email,
    phoneNumber: microHub.contact.phoneNumber,
  });

  const [newHub, setNewHub] = useState({
    hubName: microHub.name,
    email: microHub.email,
    b2b: microHub.b2B,
    b2c: microHub.b2C,
  });

  const activateOk = () => {
    return newHub.hubName &&
      address.street && address.houseNumber && address.zipcode && address.city && address.country;
  };

  return (
    <form className={classes.container}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          xs={12}
        >
          <Button
            className={classes.button}
            color="default"
            onClick={cancelClick}
            title={t('cancel')}
            variant="contained"
          >
            <CancelIcon/>&nbsp;{t('cancel')}
          </Button>
          <Button
            className={classNames(classes.button, classes.deleteAssignmentButton)}
            color="primary"
            onClick={
              () => {
                if (window.confirm(t('confirmDeleteHubFromCarrier'))) {
                  removeFromCarrierClick()
                }
              }
            }
            title={t('deleteHubFromCarrier')}
            variant="contained"
          >
            <DeleteIcon/>
          </Button>
          <Button
            className={classNames(classes.button, classes.deleteButton)}
            color="primary"
            onClick={
              () => {
                if (window.confirm(t('confirmDeleteHub'))) {
                  deleteClick()
                }
              }
            }
            title={t('deleteHub')}
            variant="contained"
          >
            <DeleteForeverIcon/>
          </Button>
          <Button
            className={classes.button}
            color="primary"
            disabled={!activateOk()}
            onClick={() => saveClick(newHub, address, contact)}
            title={t('save')}
            variant="contained"
          >
            <SaveIcon/>
          </Button>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Portlet className={classes.content}>
            <PortletContent>
              <Typography variant="h3">
                {t('info')}
              </Typography>
              <hr/>
              <Typography variant="h4">
                {t('name')}
              </Typography>
              <Typography variant="body1">
                {newHub.hubName}
              </Typography>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="text-simple">{t('email')}</InputLabel>
                <Input
                  id="email"
                  onChange={(event) => {
                    setNewHub({...newHub, email: event.target.value})
                  }}
                  value={newHub.email}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <FormLabel component="legend">{t('roles')}</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newHub.b2b}
                        color="primary"
                        onChange={(event) => {
                          setNewHub({...newHub, b2b: event.target.checked})
                        }}
                        value="b2b"
                      />
                    }
                    label="B2B"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newHub.b2c}
                        color="primary"
                        onChange={(event) => {
                          setNewHub({...newHub, b2c: event.target.checked})
                        }}
                        value="b2c"
                      />
                    }
                    label="B2C"
                  />
                </FormGroup>
              </FormControl>
            </PortletContent>
          </Portlet>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <OperatingTime
            className={classes.content}
            times={microHub.carriers.filter(c => c.carrier.name === carrierName)[0].weekDayShipperLists.reduce((acc, day) => {
              acc[day.weekDay.toLowerCase()] = day.microHubIsOpen;
              return acc;
            }, {})}
            title={t('operatingTime')}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <CreateAddress
            address={address}
            className={classes.content}
            required
            setAddress={setAddress}
            title={t('address')}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <CreatePerson
            className={classes.content}
            person={contact}
            setPerson={setContact}
            title={t('contact')}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Person
            className={classes.content}
            person={microHub.carriers.filter(c => c.carrier.name === carrierName)[0].disponent}
            title={t('disponent')}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <AllowedShippers
            className={classes.content}
            shipperLists={microHub.carriers.filter(c => c.carrier.name === carrierName)[0].weekDayShipperLists}
          />
        </Grid>
      </Grid>
    </form>
  );
}

EditHub.propTypes = {
  cancelClick: PropTypes.func.isRequired,
  carrierName: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  deleteClick: PropTypes.func.isRequired,
  microHub: PropTypes.object.isRequired,
  removeFromCarrierClick: PropTypes.func.isRequired,
  saveClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(EditHub);
