export default theme => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    margin: theme.spacing(1),
    width: 'auto',
  },
  dropdown: {
    margin: theme.spacing(0)
  }
});
  
