import React from 'react'
import {Tooltip, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {InfoOutlined as InfoIconSymbol} from '@material-ui/icons';
import {AuthService} from '../../../services/auth/authService';


function InfoIcon(props) {

  const {classes, className, t, descriptionKey, style} = props;

  const rootClassName = classNames(classes.root, className);

  // Find e-learning information for this component
  // Keys must always follow this pattern: 'iconInfo_<RoleName>_<pageNameFromURL>_<descriptionProvidedInDescriptionKey>'
  const generalKey = `iconInfo_${AuthService.getUserRole()}_${window.location.pathname.substr(1)}_`;
  // If no e-learning key was found display a default message
  // You may add "_preFormat" to your key so you are able to preFormat the text
  let translatedTooltip = t('iconInfo_noELearning');
  let preFormat = false;
  if (t(`${generalKey}${descriptionKey}`) !== `${generalKey}${descriptionKey}`) {
    translatedTooltip = t(`${generalKey}${descriptionKey}`);
    if (descriptionKey.split('_').includes('preFormat')) {
      preFormat = true;
    }
  }

  return (
    <Tooltip
      title={<div style={preFormat ? {whiteSpace: 'pre'} : {}}>{translatedTooltip}</div>}
    >
      <InfoIconSymbol
        className={classNames(rootClassName, classes.icon)}
        style={style}
      />
    </Tooltip>
  );
}


InfoIcon.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  descriptionKey: PropTypes.string.isRequired,
  style: PropTypes.object,
  t: PropTypes.func.isRequired
};

export default compose(withStyles(styles), withTranslation())(InfoIcon);
