export default theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    overflowX: 'auto',
    scrollbar: 'hidden',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-around',
    }
  },
  trackingBar: {
    minWidth: 73,
    width: '25%',
    maxWidth: 128,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  indicator: {
    width: '100%',
    height: 9,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '5px',
    [theme.breakpoints.up('md')]: {
      height: 16,
    }
  },
  active: {
    backgroundColor: theme.palette.primary.main,
  },
  problem: {
    backgroundColor: theme.palette.error.main,
  },
  marginLeft: {
    marginLeft: theme.spacing(2)
  }
});

