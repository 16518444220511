export default theme => ({
  root: {
    border: '1px solid lightgrey',
    borderRadius: '3px'
  },
  tableHeader: {
    backgroundColor: theme.palette.common.white,
  },
  infoIcon: {
    verticalAlign: 'middle'
  },
  tableSettings: {
    position: 'absolute',
    right: 0,
    top: theme.spacing(-4),
  }
});

