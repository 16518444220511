import React, {Fragment, useState} from 'react'
import {FormControl, Input, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Select, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import {dayOfWeekSorterFunction} from '../../../../services/enums/dayOfWeekHelper';
import {FiberManualRecord as BulletPoint} from '@material-ui/icons';
import {Portlet, PortletContent} from '../../../index';

function AllowedShippers(props) {
  const {classes, className, shipperLists, t} = props;

  const rootClassName = classNames(classes.root, className);

  const [selectedList, setSelectedList] = useState(shipperLists.find(l => l.weekDay === 'MONDAY'));

  return (
    <Portlet className={rootClassName}>
      <PortletContent>
        <Typography variant="h3">
          {selectedList.allowList ? t('allowedShippers') : t('forbiddenShippers')}
        </Typography>
        <hr/>
        <FormControl className={classes.formControl}>
          <InputLabel id="dayOfWeek">{t('dayOfWeek')}</InputLabel>
          <Select
            input={<Input id="dayOfWeek"/>}
            onChange={(event) => {
              setSelectedList(event.target.value)
            }}
            value={selectedList}
          >
            {shipperLists.sort((a, b) => dayOfWeekSorterFunction(a.weekDay, b.weekDay)).map((list, index) => {
              return (
                <MenuItem
                  key={index}
                  value={list}
                >
                  {t(list.weekDay.toLowerCase())}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <List>
          <Fragment>
            {selectedList.shippers.map((shipper, index) =>
              <ListItem
                key={index}
              >
                <ListItemIcon
                  className={classes.bulletPoint}
                >
                  <BulletPoint
                    style={{width: '12px'}}
                  />
                </ListItemIcon>
                <ListItemText primary={shipper.name}/>
              </ListItem>
            )}
            {!selectedList.shippers.length &&
            <Typography className={classes.noEntries}>
              {t('nothingToSee')}
            </Typography>
            }
          </Fragment>
        </List>
      </PortletContent>
    </Portlet>
  );
}

AllowedShippers.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  shipperLists: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(AllowedShippers);
