import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import React from 'react';

function ShipperApiEventSmallDetail(props){

  const {classes, t, shipperApiEvent} = props;

  return (
    <div className={classes.wrapper}>
      <div className={classes.wrapperChild}>
        <div className={classes.stopDetail}><strong>{t('errorMessage')}</strong>: {shipperApiEvent.errorMessage}</div>
      </div>
    </div>
  );

}

ShipperApiEventSmallDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  shipperApiEvent: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(ShipperApiEventSmallDetail);
