import React, {Fragment, useState} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import SideNavLayout from '../../../layouts/SideNavLayout';
import {CreateDriverDialog, DataLoadingWrapper, DriverDetails, DriverTable, GeneralInfoIcon} from '../../../components';
import {ClientService} from '../../../services/client/clientService';
import {clientTypes} from '../../../services/client/clientTypes';
import {AuthService} from '../../../services/auth/authService';
import {useMutation, useQuery} from '@apollo/react-hooks';
import omitDeep from 'omit-deep'
import {Add as AddIcon} from '@material-ui/icons';
import {displayModes} from '../../../services/enums/displayModes';
import {VehicleTypeEnumHelperService, VehicleTypes} from '../../../services/enums/vehicleTypeEnumHelperService';
import {DriverQueryService} from '../../../services/backend/driverQueryService';
import useMicroHubOptionsOfCarrier from '../../../hooks/useMicroHubOptionsOfCarrier';

const clientDrivers = ClientService.getClient(clientTypes.driver);

function Drivers(props) {

  const {classes, t} = props;

  const [selectedDriver, setSelectedDriver] = useState(null);
  const [createDriverDialogState, setCreateDriverDialogState] = useState({
    open: false,
  });
  const {microHubOptions} = useMicroHubOptionsOfCarrier(AuthService.getUserOrganization());


  const queryDrivers = DriverQueryService.getDriversByCarrierNameAndMicroHubListQuery(AuthService.getUserOrganization(), microHubOptions);
  const {loading, error, data, refetch: refetchDrivers} = useQuery(queryDrivers, {client: clientDrivers, skip: microHubOptions.length === 0});

  const deleteDriverQuery = DriverQueryService.deleteDriverByIdQuery();
  const [deleteDriver] = useMutation(deleteDriverQuery, {client: clientDrivers});

  const updateDriverQuery = DriverQueryService.updateDriverQuery();
  const [updateDriver] = useMutation(updateDriverQuery, {client: clientDrivers});

  const createDriverQuery = DriverQueryService.createDriverQuery();
  const [createDriver] = useMutation(createDriverQuery, {client: clientDrivers});

  const updateDriverDonationButtonQuery = DriverQueryService.updateDriverDonationButton();
  const [updateDriverDonationButton] = useMutation(updateDriverDonationButtonQuery, {client: clientDrivers});

  const updateDriverDonationButtonFunc = (driverId, donationButton) => {
    updateDriverDonationButton({variables: {driverId: driverId, donationButton: donationButton}}).then((result) => {
      refetchDrivers().then();
      setSelectedDriver(result?.data?.updateDriverDonationButton);
    }, (error) => {
      alert((error.toString()));
    });
  }

  const updateDriverCallback = (updatedDriver) => {
    const formattedUpdatedDriver = omitDeep(updatedDriver, ['__typename', 'donationButton', 'donationButtonConfirmed']);
    updateDriver({variables: {updatedDriver: formattedUpdatedDriver}}).then(() => {
      refetchDrivers().then();
      setSelectedDriver(null);
    }, () => {
      alert(t('errorUpdatingData'))
    });
  };

  const deleteDriverCallback = (driver, event) => {
    event.stopPropagation();
    deleteDriver({variables: {driverId: driver.id}}).then(() => {
      refetchDrivers().then();
      setSelectedDriver(null);
    }, () => {
      alert(t('errorDeletingDriver'))
    });
  };

  const displayDriverTypes = (bicycle, car, truck) => {
    const array = [];
    if (bicycle) {
      array.push(t(VehicleTypeEnumHelperService.getVehicleTypeString(VehicleTypes.Bicycle)));
    }
    if (car) {
      array.push(t(VehicleTypeEnumHelperService.getVehicleTypeString(VehicleTypes.Car)));
    }
    if (truck) {
      array.push(t(VehicleTypeEnumHelperService.getVehicleTypeString(VehicleTypes.Truck)));
    }
    return array.join(', ');
  };


  const handleCreateDriverCancel = () => {
    setCreateDriverDialogState({...createDriverDialogState, open: false});
  };

  const handleCreateDriverClose = (driver) => {
    createDriver({variables: {driverToCreate: driver}}).then(() => {
      refetchDrivers().then();
    }, () => {
      alert(t('errorCreatingDriver'))
    });
    setCreateDriverDialogState({...createDriverDialogState, open: false});
  };

  return (
    <SideNavLayout title={t('drivers')}>
      <div className={classes.root}>
        <div className={classes.helpWrapper}>
          <GeneralInfoIcon/></div>
        <Button
          className={classes.addButton}
          color="primary"
          onClick={() => setCreateDriverDialogState({...createDriverDialogState, open: true})}
          title={t('createDriver')}
          variant="contained"
        >
          <AddIcon/>&nbsp;{t('createDriver')}
        </Button>
        <DataLoadingWrapper
          error={error}
          isLoading={loading}
        >
          {data && data.getDriversByCarrierNameAndMicroHubList &&
          <Fragment>
            <DriverTable
              className={classes.content}
              deleteDriver={deleteDriverCallback}
              displayDriverTypes={displayDriverTypes}
              drivers={data.getDriversByCarrierNameAndMicroHubList}
              selectDriver={setSelectedDriver}
              selectedDriver={selectedDriver}
              tableDisplayMode={displayModes.carrier}
            />
            {selectedDriver &&
            <DriverDetails
              className={classes.content}
              closeDetail={() => setSelectedDriver(null)}
              displayDriverTypes={displayDriverTypes}
              driver={selectedDriver}
              microHubOptions={microHubOptions}
              updateDonationButton={updateDriverDonationButtonFunc}
              updateDriver={updateDriverCallback}
            />
            }
          </Fragment>
          }
        </DataLoadingWrapper>
        <CreateDriverDialog
          createDriverDialogState={createDriverDialogState}
          handleCancel={handleCreateDriverCancel}
          handleClose={handleCreateDriverClose}
          microHubOptions={microHubOptions}
        />
      </div>
    </SideNavLayout>
  );
}


Drivers.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(Drivers);
