import {useState} from 'react';
import {TourService} from '../services/backend/tourService';
import {useTranslation} from 'react-i18next';

export default function useLoadTourByIdCache() {
  const [loadingSelectedTour, setLoadingSelectedTour] = useState(false);
  const [selectedTour, setSelectedTour] = useState(null);
  const [cache, setCache] = useState({});

  const {t} = useTranslation();

  const getTourById = (tourId) => {
    resetSelectedTour();
    if (cache[tourId.toString()]) {
      setSelectedTour(cache[tourId.toString()]);
    } else {
      setLoadingSelectedTour(true);
      TourService.getTourById(tourId).then(response => response.json()).then(response => {
        response.stops = response.stops.sort((a, b) => a.stopNumber - b.stopNumber);
        setSelectedTour(response);
        setCache({...cache, [response.tourId.toString()]: response});
        setLoadingSelectedTour(false);
      }, () => {
        setLoadingSelectedTour(false);
        alert(t('errorLoadingTour'))
      })
    }
  };

  const resetLoadTourByIdCache = () => {
    setCache({});
  };

  const resetSelectedTour = () => {
    setSelectedTour(null);
  };

  const updateCache = (tour) => {
    tour.stops = tour.stops.sort((a, b) => a.stopNumber - b.stopNumber);
    setCache({...cache, [tour.tourId.toString()]: tour});
    if (selectedTour && selectedTour.tourId === tour.tourId) {
      setSelectedTour(tour);
    }
  };

  const updateMultiple = (tours) => {
    const newCache = JSON.parse(JSON.stringify(cache));
    tours.forEach(tour => {
      tour.stops = tour.stops.sort((a, b) => a.stopNumber - b.stopNumber);
      newCache[tour.tourId.toString()] = tour;
      if (selectedTour && selectedTour.tourId === tour.tourId) {
        setSelectedTour(tour);
      }
    });
    setCache(newCache);
  };

  return {selectedTour, loadingSelectedTour, getTourById, resetLoadTourByIdCache, resetSelectedTour, updateCache, updateMultiple};
}
