import React, {useState} from 'react'
import {Button, Tooltip, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import useShipperOptions from '../../../../hooks/useShipperOptions';
import {DropdownSelectSimple, LoadingIndicator} from '../../../../components';
import pcGaernerApiService from "../../../../services/backend/pcGaernerApiService";


function SendPreviewToPcg(props) {

  const {classes, t} = props;
  const [backendIsLoading, setBackendIsLoading] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [displayError, setDisplayError] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const {shipperOptions} = useShipperOptions();
  const [selectedShipper, setSelectedShipper] = useState('');

  const sendPreviewToPcg = async () => {
    setBackendIsLoading(true);
    try {
      await pcGaernerApiService.sendPreviewToPcg(selectedShipper, deliveryDate)
      setDisplayError(false);
      setDisplaySuccess(true);
      setBackendIsLoading(false);
    } catch (e) {
      setDisplayError(true);
      setDisplaySuccess(false);
      setBackendIsLoading(false);
    }
  };


  if (backendIsLoading) {
    return (
      <div>
        <LoadingIndicator/>
      </div>
    );
  }

  return (
    <div>
      {displaySuccess &&
        <Typography
          color={'primary'}
          variant={'h5'}
        >
          {t('successSendingPreviewToPcg')}
        </Typography>
      }
      {displayError &&
        <Typography
          color={'error'}
          variant={'h5'}
        >
          {t('errorSendingPreviewToPcg')}
        </Typography>
      }
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Tooltip title={t('sendPreviewToPcgInfo')}>
          <div style={{display: 'inline-block'}}>
            <Button
              className={classes.button}
              color="primary"
              disabled={!selectedShipper}
              onClick={sendPreviewToPcg}
              variant="contained"
            >
              {t('sendPreviewToPcg')}
            </Button>
          </div>
        </Tooltip>
        <KeyboardDatePicker
          cancelLabel={t('dialogCancel')}
          className={classes.picker}
          format="dd.MM.yyyy"
          id="DatePicker"

          margin="normal"
          onChange={(date) => setDeliveryDate(date)}
          value={deliveryDate}
        />
        <DropdownSelectSimple
          className={classes.formControl}
          name={t('shipper')}
          options={shipperOptions}
          setValue={(value) => setSelectedShipper(value)}
          sort
          value={selectedShipper}
        />
      </MuiPickersUtilsProvider>
    </div>
  );

}

SendPreviewToPcg.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(SendPreviewToPcg);
