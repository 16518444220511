import React, {useEffect} from 'react'
import {Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import classNames from 'classnames';

const donationDivId = 'donation';

function VirtualTip(props) {

  const {classes, className, donationButton, driverHasNoDonationButton, noDriverForTour} = props;

  const rootClassName = classNames(classes.root, className);

  useEffect(() => {
    if (window.location.href.includes(donationDivId)) {
      const element = document.getElementById(donationDivId);
      if (element) element.scrollIntoView();
    }
  }, []);

  return (
    <div
      className={rootClassName}
      id={donationDivId}
    >
      <div className={classes.container}>
        <div>
          <Typography
            variant={'h3'}
          >
            Virtuelles Trinkgeld
          </Typography>
          {donationButton &&
          <Typography variant={'body1'}>
            <strong>Danke sagen!</strong> Hier digitales Trinkgeld an den Fahrer senden.
          </Typography>
          }
          {noDriverForTour &&
          <Typography variant={'body1'}>
            Der Fahrer hat die virtuelle Trinkgeld Funktion nicht aktiviert.
          </Typography>
          }
          {!noDriverForTour && driverHasNoDonationButton &&
          <Typography variant={'body1'}>
            Der Fahrer hat die virtuelle Trinkgeld Funktion nicht aktiviert.
          </Typography>
          }
        </div>
        {donationButton &&
        <div className={classes.donationButtonContainer}>
          {/* ## welcome to the danger Zone ##*/}
          {/*eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{__html: donationButton}}/>
        </div>
        }
      </div>
    </div>
  );
}

VirtualTip.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  donationButton: PropTypes.string,
  driverHasNoDonationButton: PropTypes.bool.isRequired,
  noDriverForTour: PropTypes.bool.isRequired,
};


export default withStyles(styles)(VirtualTip);
