export default theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',
    alignItems: 'center',
  },
  indicatorWrapper: {
    width: '100%',
    marginRight: theme.spacing(1),
  },
  indicator: {
    borderRadius: 4,
    height: '20px'
  }
});

