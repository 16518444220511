import React, {Fragment} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {PortletContent, PortletHeader} from '../../../index';
import {Close as CloseIcon, Edit as EditIcon} from '@material-ui/icons';
import {getBoxTypeKindString} from '../../../../services/enums/BoxTypeKinds';

function BoxTypeDetailsShow(props) {

  const {classes, className, boxType, edit, t, readonly, closeDetail} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <Fragment>
      <PortletHeader className={rootClassName}>
        <Fragment>
          <div><strong>{boxType.identifier}</strong></div>
          <div>
            <Fragment>
              <Button
                className={classes.button}
                color="default"
                onClick={closeDetail}
                title={t('close')}
                variant="contained"
              >
                <CloseIcon/>&nbsp;{t('cancel')}
              </Button>
              {!readonly &&
                < Button
                  className={classes.button}
                  color="primary"
                  onClick={edit}
                  title={t('boxTypeEdit')}
                  variant="contained"
                >
                  <EditIcon/>
                </Button>
              }
            </Fragment>
          </div>
        </Fragment>
      </PortletHeader>
      <PortletContent noPadding>
        <div className={classes.wrapper}>
          <div className={classes.wrapperChild}>
            <div className={classes.boxTypeDetail}><strong>{t('boxTypeIdentifier')}</strong>: {boxType.identifier}</div>
            <div className={classes.boxTypeDetail}>
              <strong>{t('boxTypeKind')}</strong>: {t(getBoxTypeKindString(boxType.kind))}</div>
            <div className={classes.boxTypeDetail}>
              <strong>{t('boxTypeStackable')}</strong>: {boxType.stackable ? t('yes') : t('no')}</div>
            <div className={classes.boxTypeDetail}><strong>{t('boxTypeVolume')}</strong>: {boxType.volume}</div>
          </div>
          <div className={classes.wrapperChild}>
            <div className={classes.boxTypeDetail}><strong>{t('boxTypeDimensions')}</strong>:</div>
            <div className={classes.boxTypeDetail}>
              <div className={classes.boxTypeDetail}><strong>{t('boxTypeDepths')}</strong>: {boxType.depth}</div>
              <div className={classes.boxTypeDetail}><strong>{t('boxTypeWidth')}</strong>: {boxType.width}</div>
              <div className={classes.boxTypeDetail}><strong>{t('boxTypeHeight')}</strong>: {boxType.height}</div>
            </div>
          </div>
          <div className={classes.wrapperChild}>
            <div className={classes.boxTypeDetail}><strong>{t('boxTypeDescription')}</strong>:</div>
            <div className={classes.boxTypeDetail}>
              <div className={classes.boxTypeDetail}>{boxType.description}</div>
            </div>
          </div>
        </div>
      </PortletContent>
    </Fragment>
  );
}


BoxTypeDetailsShow.propTypes = {
  boxType: PropTypes.object.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  closeDetail: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
  t: PropTypes.func.isRequired
};

export default compose(withStyles(styles), withTranslation())(BoxTypeDetailsShow);
