export default theme => ({
  root: {},
  tableRow: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.selected,
    }
  },
  warningColor: {
    color: theme.palette.warning.main,
    verticalAlign: 'middle',
  },
});
