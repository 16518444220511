import {
  canNotDeliverIconColor,
  deliveredIconColor,
  inDeliveryIconColor,
  notPlanedIconColor,
  planedIconColor,
  redeliveryIconColor
} from '../../../../services/icon/iconService';

export default theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  legendIcon: {
    height: '25px'
  },
  iconNotPlanned: {
    color: notPlanedIconColor
  },
  iconPlanned: {
    color: planedIconColor
  },
  iconInDelivery: {
    color: inDeliveryIconColor
  },
  iconDelivered: {
    color: deliveredIconColor
  },
  iconCanNotDeliver: {
    color: canNotDeliverIconColor
  },
  iconRedelivery: {
    color: redeliveryIconColor
  },
});
