import React, {Fragment, useState} from 'react'
import {Box, Collapse, TableCell, TableRow, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {ArrowForward as DropIcon,} from '@material-ui/icons';
import {Draggable, Droppable} from 'react-beautiful-dnd';
import {getDayOfWeekFromNumber, getDayOfWeekString} from '../../../../../../services/enums/dayOfWeekHelper';
import {DndHelperElementTypes} from '../../../../../../services/dndHelper/dndHelperService';
import {DateService} from '../../../../../../services/util/DateService';
import {TableCellWithConfig} from '../../../../../index';
import Columns from '../columns';

function TemplateTableRow(props) {

  const {
    classes,
    t,
    template,
    className,
    index,
    uniqueTemplateId,
    selectTemplate,
    selectedTemplate,
    filter,
    columnConfigs,
  } = props;

  const rootClassName = classNames(className, classes.root);
  const [open, setOpen] = useState(false);

  const toggleOpen = (event) => {
    event.stopPropagation();
    setOpen(!open);
  };

  return (
    <Draggable
      draggableId={uniqueTemplateId}
      index={index}
      isDragDisabled={filter.shipperName || filter.templateType}
    >
      {(provided, snapshot) => {
        return (
          <Fragment>
            <TableRow
              className={classNames(rootClassName, 'cursorPointer', {
                [classes.dragging]: snapshot.isDragging,
                [classes.disabled]: template.disabled
              })}
              hover={!template.disabled}
              onClick={() => selectTemplate(template)}
              selected={selectedTemplate && template.id === selectedTemplate.id}
              {...provided.draggableProps}
              ref={provided.innerRef}
            >
              {/*Since the templates are dragable (to reorder them) as well as droppable 
              (to add or remove template stopps) the first column needs to be rendered fix
               and can not be selected dynamically using the conifg*/}
              <Fragment>
                {columnConfigs
                  // Use only the tourTemplateNumber column
                  .filter(config => config.columnName === Columns.tourTemplateNumber.name)
                  .map(columnConfig => (
                    <TableCellWithConfig
                      key={columnConfig.order}
                      config={columnConfig}
                      className={classNames(classes.tableCell, classes.firstTableCell)}
                    >
                      {Columns[columnConfig.columnName].body({
                        template, provided, classes, filter, snapshot, t
                      })}
                    </TableCellWithConfig>
                  ))}
              </Fragment>
              {!snapshot.isDragging &&
                <Droppable
                  droppableId={uniqueTemplateId}
                  isDropDisabled={Boolean(selectedTemplate && (template.id === selectedTemplate.id || template.shipper !== selectedTemplate.shipper))}
                  type={DndHelperElementTypes.Stop}
                >
                  {(provided, snapshot) => (
                    // Display row with droppable icon instead of rendering the columns
                    snapshot.isDraggingOver ?
                      <TableCell
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={classes.dropTableCell}
                        //-1 because of the draggable handler above
                        colSpan={columnConfigs.filter(x => !x.hidden).length - 1}
                      >
                        <DropIcon/>
                      </TableCell>
                      :
                      <Fragment>
                        {columnConfigs
                          // Ignore column with the draggable handler above
                          .filter(config => config.columnName !== Columns.tourTemplateNumber.name)
                          .map(columnConfig => (
                            <TableCellWithConfig
                              key={columnConfig.order}
                              config={columnConfig}
                              className={classes.tableCell}
                            >
                              {Columns[columnConfig.columnName].body({
                                template, classes, provided, t, toggleOpen, open
                              })}
                            </TableCellWithConfig>
                          ))}
                      </Fragment>
                  )}
                </Droppable>
              }
            </TableRow>
            <TableRow style={snapshot.isDragging ? {display: 'none'} : {}}>
              <TableCell
                className={classes.largeTableCell}
                colSpan={columnConfigs.filter(x => !x.hidden).length}
              >
                <Collapse
                  in={open}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box margin={1}>
                    <div className={classes.infoWrapper}>
                      <Typography><strong>{t('tourTemplateDisabled')}</strong>:&nbsp;{template.disabled ? t('yes') : t('no')}
                      </Typography>
                      <Typography><strong>{t('tourTemplateWeekday')}</strong>:&nbsp;{t(getDayOfWeekString(getDayOfWeekFromNumber(template.dayOfWeek)))}
                      </Typography>
                    </div>
                    {template.isAutoCutTemplate &&
                      <Fragment>
                        <Typography><strong>{t('vehicle')}</strong>: {template.vehicle}</Typography>
                      </Fragment>
                    }
                    <div className={classes.infoWrapper}>
                      <Typography>
                        <strong>{t('lastModified')}</strong>:&nbsp;{template.tourTemplateMetaData?.lastModified ? DateService.displayDateTime(new Date(template.tourTemplateMetaData?.lastModified)) : '-'}
                      </Typography>
                      <Typography><strong>{t('modifiedBy')}</strong>:&nbsp;{template.tourTemplateMetaData.modifiedBy}
                      </Typography>
                    </div>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </Fragment>
        );
      }}
    </Draggable>
  );
}

TemplateTableRow.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  columnConfigs: PropTypes.array.isRequired,
  filter: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedDayOfWeek: PropTypes.string.isRequired,
  selectedTemplate: PropTypes.object,
  t: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
  uniqueTemplateId: PropTypes.string.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TemplateTableRow);
