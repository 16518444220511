import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {FormControl, IconButton, Input, MenuItem, Select, TableCell, TableRow, withStyles} from '@material-ui/core';
import styles from '../../../styles';
import {withTranslation} from 'react-i18next';
import React, {Fragment} from 'react';
import {getProductTypeString, ProductType} from '../../../../../../../services/enums/ProductType';
import {roundCapacityValues} from '../../../../../../../services/util/helperFuncs';
import {DeleteForever as DeleteRowIcon} from '@material-ui/icons';


function ProductAddRow(props) {

  const {classes, t, productToAdd, updateItem, deleteProduct} = props;

  return (
    <TableRow className={classes.addRow}>
      <Fragment>
        <TableCell>
          <FormControl>
            <Select
              input={<Input id="productType"/>}
              onChange={(event) => {
                if (event.target.value === ProductType.Other) {
                  updateItem(productToAdd.tempId, 'boxType', '');
                }
                updateItem(productToAdd.tempId, 'productType', event.target.value);
              }}
              value={productToAdd.productType}
            >
              {Object.getOwnPropertyNames(ProductType).map(property => ProductType[property]).filter(value => value !== ProductType.PackageInserts).map((value, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={value}
                  >
                    {t(getProductTypeString(value))}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell>
          <FormControl>
            <Input
              disabled={productToAdd.productType === ProductType.Other}
              error={productToAdd.boxType === '' && productToAdd.productType !== ProductType.Other}
              id="addProductBoxType"
              onChange={(event) =>
                updateItem(productToAdd.tempId, 'boxType', event.target.value)
              }
              value={productToAdd.boxType}
            />
          </FormControl>
        </TableCell>
        <TableCell>
          <FormControl>
            <Input
              error={!productToAdd.boxId}
              id="addProductBoxId"
              onChange={(event) =>
                updateItem(productToAdd.tempId, 'boxId', event.target.value)
              }
              value={productToAdd.boxId}
            />
          </FormControl>
        </TableCell>
        <TableCell>
          <FormControl>
            <Input
              error={productToAdd.amount <= 0}
              id="addProductAmount"
              onChange={(event) =>
                updateItem(productToAdd.tempId, 'amount', (parseInt(event.target.value) && parseInt(event.target.value) >= 0) ? parseInt(event.target.value) : 0)
              }
              onClick={event => event.target.select()}
              onWheel={event => event.target.blur()}
              type="number"
              value={productToAdd.amount}
            />
          </FormControl>
        </TableCell>
        <TableCell>
          <FormControl>
            <Input
              error={productToAdd.volume <= 0}
              id="addProductVolume"
              onChange={(event) =>
                updateItem(productToAdd.tempId, 'volume', (parseFloat(event.target.value) && parseFloat(event.target.value) >= 0) ? parseFloat(event.target.value) : 0)
              }
              onClick={event => event.target.select()}
              onWheel={event => event.target.blur()}
              type="number"
              value={roundCapacityValues(productToAdd.volume)}
            />
          </FormControl>
        </TableCell>
        <TableCell>
          <FormControl>
            <Input
              error={productToAdd.weight <= 0}
              id="addProductWeight"
              onChange={(event) =>
                updateItem(productToAdd.tempId, 'weight', (parseFloat(event.target.value) && parseFloat(event.target.value) >= 0) ? parseFloat(event.target.value) : 0)
              }
              onClick={event => event.target.select()}
              onWheel={event => event.target.blur()}
              type="number"
              value={productToAdd.weight}
            />
          </FormControl>
        </TableCell>
        <TableCell>
          <FormControl>
            <Input
              id="addProductDescription"
              onChange={(event) =>
                updateItem(productToAdd.tempId, 'description', event.target.value)
              }
              value={productToAdd.description}
            />
          </FormControl>
        </TableCell>
        <TableCell>
          <IconButton
            className={classes.buttonRight}
            color="primary"
            onClick={() => deleteProduct(productToAdd)}
            title={t('delete')}
          >
            <DeleteRowIcon className={classes.redIcon}/>
          </IconButton>
        </TableCell>
      </Fragment>
    </TableRow>
  );
}

ProductAddRow.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deleteProduct: PropTypes.func.isRequired,
  productToAdd: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(ProductAddRow);
