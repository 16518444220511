import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {List, withStyles} from '@material-ui/core';
import styles from './styles';
import {LoadingWrapper, Portlet, PortletContent, PortletHeader, PortletLabel, TextPlaceholderWrapper} from '../../../components';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import DriverInfoListItem from './DriverInfoListItem';


function DriverInfoCard(props) {

  const {t, classes, className, isLoading, messages} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet
      className={rootClassName}
    >
      <PortletHeader className={classes.portletHeader}>
        <PortletLabel title={t('dashboardDriverInfo')}/>
      </PortletHeader>
      <PortletContent
        className={classes.portletContentStyle}
        noPadding
      >
        <LoadingWrapper loading={isLoading}>
          <TextPlaceholderWrapper
            active={!messages || messages.length <= 0}
            text={t('noDriverInfosAvailableDashboard')}
          >
            <div className={classes.messageListContainer}>
              <List>
                {messages.map((msg, index) => {
                  return (
                    <DriverInfoListItem
                      key={index}
                      msg={msg}
                    />
                  );
                })}
              </List>
            </div>
          </TextPlaceholderWrapper>
        </LoadingWrapper>
      </PortletContent>
    </Portlet>
  );
}

DriverInfoCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  messages: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(DriverInfoCard);
