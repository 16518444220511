export default theme => ({
  button: {
    marginLeft: theme.spacing(1)
  },
  tableCell: {
    whiteSpace: 'nowrap',
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  alignLeft: {
    align: 'left',
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  iconMargin: {
    marginLeft: '8px !important'
  },
  tableWidth: {
    tableLayout: 'fixed'
  },
  tableButton: {
    padding: 0,
  }
});
