export default theme => ({
  root: {padding: theme.spacing(3)},
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    border: `1px solid ${theme.palette.border}`
  },
  appBar: {
    left: '2px',
    width: 'calc(100% - 2px)',
    zIndex: 'unset'
  }
});
