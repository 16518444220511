// eslint-disable-next-line no-unused-vars
export default theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
  bulletPoint: {
    color: theme.palette.common.black,
    paddingRight: theme.spacing(1),
    minWidth: 0
  },
  noEntries: {
    paddingLeft: theme.spacing(1),
  }
});
