import React from 'react';
import PropTypes from 'prop-types';
import {MarkerCreateFuncs, OverlappingMarkerCreateFuncs} from '../../MapComponents';
import {IconService, notPlanedIconColor} from '../../../../services/icon/iconService';
import BaseTourMap from '../../BaseMaps/BaseTourMap';

const TourMap = React.memo(props => {

  const {
    className,
    toursWithStops,
    microHubs,
    notPlanedStops,
    displayStopStatus,
    displayNotPlanedStops,
    displayMicroHubs,
    setSelectedStop,
    noControls,
    forceDisplayPreviewOrder
  } = props;

  return (
    <BaseTourMap
      className={className}
      displayControls={!noControls}
      displayMicroHubs={displayMicroHubs}
      displayStops={displayNotPlanedStops}
      getOverLappingMarkerClusterIcon={(overlappingElements, highLiteDuplicates) => displayStopStatus
        ? OverlappingMarkerCreateFuncs.getForTourWithStopStatusMap(overlappingElements, highLiteDuplicates)
        : OverlappingMarkerCreateFuncs.getForTourMap(overlappingElements, highLiteDuplicates)}
      getStopClusterIcon={() => IconService.getMarkerClusterIcon(notPlanedIconColor)}
      getStopMarkerAndAddToMcg={(stop, mcg, t) => MarkerCreateFuncs.getStopMarker(stop, mcg, t, setSelectedStop)}
      getTourClusterIcon={(tour) => IconService.getMarkerClusterIcon(tour.color, tour.tourId)}
      getTourStopMarkerAndAddToMcg={(tour, stop, mcg, t) => MarkerCreateFuncs.getTourStopMarker(tour, stop, mcg, t, setSelectedStop, displayStopStatus, forceDisplayPreviewOrder)}
      microHubs={displayMicroHubs ? microHubs : []}
      stops={notPlanedStops}
      tours={toursWithStops}
    />
  );
});

TourMap.displayName = 'TourMapComponent';

TourMap.propTypes = {
  className: PropTypes.string,
  displayMicroHubs: PropTypes.bool,
  displayNotPlanedStops: PropTypes.bool,
  displayStopStatus: PropTypes.bool,
  forceDisplayPreviewOrder: PropTypes.bool,
  microHubs: PropTypes.array,
  noControls: PropTypes.bool,
  notPlanedStops: PropTypes.array,
  setSelectedStop: PropTypes.func,
  toursWithStops: PropTypes.array,
};

export default TourMap;

