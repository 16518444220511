export const MigrationEventType = {
  Tour: 0,
  MicroHub: 1,
  Shipper: 2,
  StopsWithoutPreview: 3
};

export function getMigrationEventTypeString(type) {
  switch (type) {
    case MigrationEventType.Tour:
      return 'migrationEventTypeTour';
    case MigrationEventType.MicroHub:
      return 'migrationEventTypeMicroHub';
    case MigrationEventType.Shipper:
      return 'migrationEventTypeShipper';
    case MigrationEventType.StopsWithoutPreview:
      return 'migrationEventTypeStopsWithoutPreview';
    default:
      return 'migrationEventTypeUndefined'
  }
}
