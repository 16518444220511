import React from 'react';
import PropTypes from 'prop-types';
import {MarkerCreateFuncs, OverlappingMarkerCreateFuncs} from '../../MapComponents';
import {IconService, notPlanedIconColor} from '../../../../services/icon/iconService';
import BaseTourMap from '../../BaseMaps/BaseTourMap';

const PreviewTourMap = React.memo(props => {

  const {className, tours, microHubs, setSelectedStop, stopsWithoutTour} = props;

  return (
    <BaseTourMap
      className={className}
      displayControls
      displayMicroHubs
      displayStops
      getOverLappingMarkerClusterIcon={(overlappingElements, highLiteDuplicates) => OverlappingMarkerCreateFuncs.getForTourMap(overlappingElements, highLiteDuplicates)}
      getStopClusterIcon={() => IconService.getMarkerClusterIcon(notPlanedIconColor)}
      getStopMarkerAndAddToMcg={(stop, mcg, t) => MarkerCreateFuncs.getPreviewStopMarker(stop, mcg, t, undefined, notPlanedIconColor)}
      getTourClusterIcon={(tour) => IconService.getMarkerClusterIcon(tour.color, tour.id)}
      getTourStopMarkerAndAddToMcg={(tour, stop, mcg, t) => MarkerCreateFuncs.getPreviewTourStopMarker(tour, stop, mcg, t, setSelectedStop)}
      microHubs={microHubs}
      stops={stopsWithoutTour}
      tours={tours}
    />
  );
});

PreviewTourMap.displayName = 'PreviewTourMap';

PreviewTourMap.propTypes = {
  className: PropTypes.string,
  microHubs: PropTypes.array,
  setSelectedStop: PropTypes.func,
  stopsWithoutTour: PropTypes.array.isRequired,
  tours: PropTypes.array,
};


export default PreviewTourMap;

