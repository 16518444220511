export default theme => ({
  dialog: {},
  formControl: {
    minWidth: '400px',
  },
  deleteButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.danger.main,
    '&:hover': {
      backgroundColor: theme.palette.danger.dark,
      color: theme.palette.common.white,
    }
  },
  container: {
    display: 'flex',
    margin: theme.spacing(2),
    justifyContent: 'space-between'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
});
