import React from 'react'
import {Button, Grid, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {Portlet, PortletContent} from '../../../../index';
import Typography from '@material-ui/core/Typography';
import {Edit as EditIcon,} from '@material-ui/icons'
import {Address, Person} from '../../../Shared';
import {AllowedShippers, OperatingTime} from '../../index';

function ViewHub(props) {
  const {classes, t, microHub, onEditClick, carrierName, readonly} = props;

  return (
    <Grid
      container
      spacing={4}
    >
      <Grid
        item
        xs={12}
      >
        {!readonly &&
        <Button
          className={classes.button}
          color="primary"
          onClick={onEditClick}
          title={t('hubEdit')}
          variant="contained"
        >
          <EditIcon/>
        </Button>
        }
      </Grid>
      <Grid
        item
        lg={3}
        sm={6}
        xl={3}
        xs={12}
      >
        <Portlet className={classes.content}>
          <PortletContent>
            <Typography variant="h3">
              {t('info')}
            </Typography>
            <hr/>
            <Typography variant="h4">
              {t('name')}
            </Typography>
            <Typography variant="body1">
              {microHub.name}
            </Typography>
            <Typography variant="h4">
              {t('email')}
            </Typography>
            <Typography variant="body1">
              {microHub.email}
            </Typography>
            <Typography variant="h4">
              {t('roles')}
            </Typography>
            <Typography variant="body1">
              {microHub.b2B ? 'B2B' : ''}{microHub.b2B && microHub.b2B ? ' / ' : ''}{microHub.b2C ? 'B2C' : ''}
            </Typography>
          </PortletContent>
        </Portlet>
      </Grid>
      <Grid
        item
        lg={3}
        sm={6}
        xl={3}
        xs={12}
      >
        <OperatingTime
          className={classes.content}
          times={microHub.carriers.filter(c => c.carrier.name === carrierName)[0].weekDayShipperLists.reduce((acc, day) => {
            acc[day.weekDay.toLowerCase()] = day.microHubIsOpen;
            return acc;
          }, {})}
          title={t('operatingTime')}
        />
      </Grid>
      <Grid
        item
        lg={3}
        sm={6}
        xl={3}
        xs={12}
      >
        <Address
          address={microHub.address}
          className={classes.content}
          title={t('address')}
        />
      </Grid>
      <Grid
        item
        lg={3}
        sm={6}
        xl={3}
        xs={12}
      >
        <Person
          className={classes.content}
          person={microHub.contact}
          title={t('contact')}
        />
      </Grid>
      <Grid
        item
        lg={3}
        sm={6}
        xl={3}
        xs={12}
      >
        <Person
          className={classes.content}
          person={microHub.carriers.filter(c => c.carrier.name === carrierName)[0].disponent}
          title={t('disponent')}
        />
      </Grid>
      <Grid
        item
        lg={3}
        sm={6}
        xl={3}
        xs={12}
      >
        <AllowedShippers
          className={classes.content}
          shipperLists={microHub.carriers.filter(c => c.carrier.name === carrierName)[0].weekDayShipperLists}
        />
      </Grid>
    </Grid>

  );
}

ViewHub.propTypes = {
  carrierName: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  microHub: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(ViewHub);
