import React from 'react';
import PropTypes from 'prop-types';
import {MarkerCreateFuncs, OverlappingMarkerCreateFuncs} from '../../MapComponents';
import {IconService, notPlanedIconColor} from '../../../../services/icon/iconService';
import BaseTourMap from '../../BaseMaps/BaseTourMap';
import {cloneDeep} from 'lodash';

const DeliveryOrderTourMap = React.memo(props => {

  const {
    className,
    toursWithStops,
    microHubs,
    displayStopStatus,
    displayMicroHubs,
    setSelectedStop,
    noControls,
    displayDeliveryOrder
  } = props;


  let stops = [];
  let tours = [];

  if (displayDeliveryOrder) {
    const clonedTours = cloneDeep(toursWithStops);
    clonedTours.forEach(tour => {
      const deliveredStops = tour.stops.filter(s => s.deliverTime).sort((a, b) => new Date(a.deliverTime) - new Date(b.deliverTime));
      deliveredStops.forEach((stop, index) => {
        stop.stopNumber = index + 1;
      });
      const notDeliveredStops = tour.stops.filter(s => !s.deliverTime);
      tour.stops = deliveredStops;
      stops = stops.concat(notDeliveredStops);
    });
    tours = clonedTours;
  } else {
    tours = toursWithStops;
  }

  return (
    <BaseTourMap
      className={className}
      displayControls={!noControls}
      displayMicroHubs={displayMicroHubs}
      displayStops
      getOverLappingMarkerClusterIcon={(overlappingElements, highLiteDuplicates) => displayStopStatus
        ? OverlappingMarkerCreateFuncs.getForTourWithStopStatusMap(overlappingElements, highLiteDuplicates, true)
        : OverlappingMarkerCreateFuncs.getForTourMap(overlappingElements, highLiteDuplicates)}
      getStopClusterIcon={() => IconService.getMarkerClusterIcon(notPlanedIconColor)}
      getStopMarkerAndAddToMcg={(stop, mcg, t) => MarkerCreateFuncs.getStopMarker(stop, mcg, t, setSelectedStop, !displayStopStatus ? notPlanedIconColor : '', !displayDeliveryOrder)}
      getTourClusterIcon={(tour) => IconService.getMarkerClusterIcon(tour.color, tour.tourId)}
      getTourStopMarkerAndAddToMcg={(tour, stop, mcg, t) => MarkerCreateFuncs.getTourStopMarker(tour, stop, mcg, t, setSelectedStop, displayStopStatus)}
      microHubs={displayMicroHubs ? microHubs : []}
      preventClustering
      stops={stops}
      tours={tours}
    />
  );
});

DeliveryOrderTourMap.displayName = 'DeliveryOrderTourMapComponent';

DeliveryOrderTourMap.propTypes = {
  className: PropTypes.string,
  displayDeliveryOrder: PropTypes.bool,
  displayMicroHubs: PropTypes.bool,
  displayStopStatus: PropTypes.bool,
  microHubs: PropTypes.array,
  noControls: PropTypes.bool,
  setSelectedStop: PropTypes.func,
  toursWithStops: PropTypes.array,
};

export default DeliveryOrderTourMap;

