export default theme => ({
  formControl: {
    marginBottom: theme.spacing(1),
    minWidth: '250px',
  },
  rowWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  hidden: {
    display: 'none'
  }
});
