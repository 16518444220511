import React, {Fragment, useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Input, InputLabel, Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {StopStatus} from '../../../services/enums/StopStatus';
import {Cancel as CancelIcon, DeleteForever as DeleteIcon, Info as InformationIcon, WarningRounded as WarnIcon} from '@material-ui/icons';
import {onCloseWithBackdropCurry} from '../../../services/util/helperFuncs';

function DeleteStopDialog(props) {

  const {classes, t, dialogState, handleCancel, handleClose, needsConfirm} = props;
  const [confirmPhrase, setConfirmPhrase] = useState('');


  const handleOk = () => {
    handleClose(dialogState.stop.tourStopId);
    setConfirmPhrase('');
  };

  const handleNotOk = () => {
    handleCancel();
    setConfirmPhrase('');
  };

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      maxWidth={'md'}
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={dialogState.open}
    >
      <DialogTitle>{t('stopDelete')}</DialogTitle>
      <DialogContent>
        {dialogState.stop &&
        <Fragment>
          <InformationIcon/>
          <Typography>
            {t('deleteStop1')}
          </Typography>
          <Typography>
            {t('deleteStop2')}
          </Typography>
          <div className={classes.container}>
            {dialogState.stop.stopStatus === StopStatus.Planed &&
            <Typography>
              <WarnIcon className={classes.warningColor}/>&nbsp;{t('changeAssignmentStopPlaned')}
            </Typography>
            }
            {dialogState.stop.stopStatus === StopStatus.InDelivery &&
            <Typography>
              <WarnIcon className={classes.warningColor}/>&nbsp;{t('changeAssignmentStopInDelivery')}
            </Typography>
            }
            {dialogState.stop.stopStatus === StopStatus.Delivered &&
            <Typography>
              <WarnIcon className={classes.warningColor}/>&nbsp;{t('changeAssignmentStopDelivered')}
            </Typography>
            }
            {dialogState.stop.stopStatus === StopStatus.CanNotDeliver &&
            <Typography>
              <WarnIcon className={classes.warningColor}/>&nbsp;{t('changeAssignmentStopCanNotDeliver')}
            </Typography>
            }
          </div>
          {needsConfirm &&
          <Fragment>
            <Typography>
              {t('confirmDeleteStopIdInput')}
            </Typography>
            <br/>
            <Typography>
              {t('tourStopId')}:&nbsp;<strong>{dialogState.stop.tourStopId}</strong>
            </Typography>
            <FormControl className={classes.formControl}>
              <InputLabel
                htmlFor="stopId"
                required
              >{t('tourStopId')}</InputLabel>
              <Input
                error={confirmPhrase !== dialogState.stop.tourStopId.toString()}
                id="stopId"
                onChange={(event) => {
                  setConfirmPhrase(event.target.value)
                }}
                value={confirmPhrase}
              />
            </FormControl>
          </Fragment>
          }
        </Fragment>
        }
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={handleNotOk}
        >
          <CancelIcon/>&nbsp;{t('dialogCancel')}
        </Button>
        <Button
          className={classes.dangerColor}
          color="primary"
          disabled={!dialogState.stop || (needsConfirm && confirmPhrase !== dialogState.stop.tourStopId.toString())}
          onClick={handleOk}
        >
          <DeleteIcon/>{t('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteStopDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogState: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  needsConfirm: PropTypes.bool,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(DeleteStopDialog);
