import React from 'react'
import {Table, TableBody, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import DefaultConfig from './config';
import Columns from './columns';
import { Pagination, Portlet, PortletContent, TableCellWithConfig, TableSettings, TextPlaceholderWrapper } from 'components';
import useTableConfig from 'hooks/useTableConfig';
import { getTableKey } from 'services/util/tableConfigHelper';
import useTablePagination from 'hooks/useTablePagination';
import { TableKind } from 'services/enums/TableKind';

function TourTable(props) {

  const {
    classes,
    className,
    tours,
    t,
    showDetails,
    tableDisplayMode,
    selectedTour
  } = props;

  const {
    config,
    columnConfigs,
    updateConfig
  } = useTableConfig(DefaultConfig, tableDisplayMode, getTableKey(TableKind.TourTable, tableDisplayMode))

  const {paginationProps, pagedObjects} = useTablePagination(tours);

  const rootClassName = classNames(classes.root, className);

  return (
    <TextPlaceholderWrapper
      active={!tours?.length}
      text={t('noToursAvailable')}
    >
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <TableSettings
            updateConfig={updateConfig}
            tableConfig={config}
            columns={Columns}
          />
          <Table size="medium">
            <TableHead>
              <TableRow>
                {columnConfigs.map(columnConfig => (
                  <TableCellWithConfig
                    key={columnConfig.order}
                    config={columnConfig}
                  >
                    {Columns[columnConfig.columnName].header({t})}
                  </TableCellWithConfig>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedObjects.map(tour => (
                <TableRow
                  className={classNames(classes.tableRow, 'cursorPointer')}
                  hover
                  key={tour.id}
                  onClick={() => showDetails(tour)}
                  selected={selectedTour && selectedTour.id === tour.id}
                  style={{boxShadow: `inset 10px 0px 0px 0px ${tour.color}`}}
                >
                  {columnConfigs.map(columnConfig => (
                    <TableCellWithConfig
                      key={columnConfig.order}
                      config={columnConfig}
                      className={classes.tableCell}
                    >
                      {Columns[columnConfig.columnName].body({tour, classes, t})}
                    </TableCellWithConfig>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination paginationProps={paginationProps}/>
        </PortletContent>
      </Portlet>
    </TextPlaceholderWrapper>
  );
}


TourTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  selectedTour: PropTypes.object,
  showDetails: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  tableDisplayMode: PropTypes.string.isRequired,
  tours: PropTypes.array.isRequired
};

TourTable.defaultProps = {
  tours: [],
};


export default compose(withStyles(styles), withTranslation())(TourTable);
