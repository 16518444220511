import React, {Fragment, useState} from 'react'
import {Box, Collapse, IconButton, TableCell, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  DeleteForever as DeleteIcon,
  Edit as ChangeAssignmentIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon
} from '@material-ui/icons';
import {DateService} from '../../../../../services/util/DateService';
import {roundCapacityValues} from '../../../../../services/util/helperFuncs';
import StopTableDetails from '../../../StopTableDetails';
import {PreviewStopIdLabel, ShipperLabel} from '../../../../index';


function PreviewStopTableRow(props) {

  const {classes, t, stop, className, changeStopAssignment, deleteStop} = props;
  const [open, setOpen] = useState(false);

  const rootClassName = classNames(className, classes.root);

  return (
    <Fragment>
      <TableRow
        className={rootClassName}
        hover
        selected={open}
      >
        <TableCell className={classes.alignRight}>{stop.stopNumber}</TableCell>
        <TableCell
          className={classes.alignRight}
          component="th"
          scope="row"
        ><PreviewStopIdLabel stop={stop}/></TableCell>
        <TableCell className={classes.alignRight}>{DateService.displayDate(stop.deliveryDate)}</TableCell>
        <TableCell className={classes.alignRight}><ShipperLabel stop={stop}/></TableCell>
        <TableCell className={classes.alignRight}>{stop.lastName}</TableCell>
        <TableCell className={classes.alignRight}>{roundCapacityValues(stop.stopCapacities.boxAmount)}</TableCell>
        <TableCell className={classes.alignRight}>{roundCapacityValues(stop.stopCapacities.weight)}</TableCell>
        <TableCell className={classes.alignRight}>{roundCapacityValues(stop.stopCapacities.volume)}</TableCell>
        <TableCell>
          <IconButton
            onClick={() => setOpen(!open)}
            size="small"
          >
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton>
          {deleteStop &&
          <IconButton
            className={classNames(classes.changeStopAssignmentButton, classes.deleteButton)}
            onClick={() => deleteStop(stop)}
            size="small"
            title={t('stopDelete')}
          >
            <DeleteIcon/>
          </IconButton>
          }
          {changeStopAssignment &&
          <IconButton
            className={classes.changeStopAssignmentButton}
            color={'secondary'}
            disabled={!stop.editable}
            onClick={() => changeStopAssignment(stop)}
            size="small"
            title={t('changeStopAssignment')}
          >
            <ChangeAssignmentIcon/>
          </IconButton>
          }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={9}
          style={{paddingBottom: 0, paddingTop: 0}}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <Box margin={1}>
              <StopTableDetails
                stop={stop}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

PreviewStopTableRow.propTypes = {
  changeStopAssignment: PropTypes.func,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deleteStop: PropTypes.func,
  stop: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(PreviewStopTableRow);
