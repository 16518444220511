import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import {DateService} from '../../../../services/util/DateService';
import {roundCapacityValues} from '../../../../services/util/helperFuncs';
import {InfoIcon} from '../../../index';

function StatisticContent(props){
  const {t, classes, aggregatedData} = props;

  return(
    <Grid
      container
    >
      <Grid item xs={4}>
        <div className={classes.aggregatedInformation}>
          <strong>{t('length')}</strong>: {aggregatedData.length.toFixed(2)}
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={classes.aggregatedInformation}>
          <strong>{t('statisticsDuration')}</strong>: {DateService.displayTimeFromSeconds(aggregatedData.duration * 60)}
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={classes.aggregatedInformation}>
          <strong>{t('stops')}</strong>: {aggregatedData.stops}
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={classes.aggregatedInformation}>
          <strong>{t('boxes')}</strong>: {aggregatedData.boxes}
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={classes.aggregatedInformation}>
          <strong>{t('tourPacking')}</strong>: {DateService.displayTimeFromSeconds(aggregatedData.timeToPack)}
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={classes.aggregatedInformation}>
          <strong>{t('avgTimeToPackPBox')}</strong>: {Math.ceil(aggregatedData.avgTimeToPackPBox)}
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={classes.aggregatedInformation}>
          <strong>{t('avgBoxesPStop')}</strong>: {roundCapacityValues(aggregatedData.avgBoxesPStop)}
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={classes.aggregatedInformation}>
          <strong>{t('avgDistPStop')}</strong>: {roundCapacityValues(aggregatedData.avgDistPStop)}
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={classes.aggregatedInformation}>
          <strong>{t('avgStopsPHour')}</strong>: {roundCapacityValues(aggregatedData.avgStopsPHour)}
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={classes.aggregatedInformation}>
          <strong>{t('avgStopsPHourWTimeToPack')}</strong>: {roundCapacityValues(aggregatedData.avgStopsPHourWTimeToPack)}
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={classes.aggregatedInformation}>
          <strong>{t('aggPercentBikeUsageVolume')}</strong>: {roundCapacityValues(aggregatedData.percentBikeUsageVolume)}
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={classes.aggregatedInformation}>
          <strong>{t('aggPercentBikeUsageWeight')}</strong>: {roundCapacityValues(aggregatedData.percentBikeUsageWeight)}
          <InfoIcon
              className={classes.iconMargin}
              descriptionKey={'2'}
          />
        </div>
      </Grid>
    </Grid>
  )
}

StatisticContent.propTypes = {
  aggregatedData: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}


export default compose(withStyles(styles), withTranslation())(StatisticContent);
