import React, {useEffect, useState} from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
  withStyles
} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import {onCloseWithBackdropCurry} from 'services/util/helperFuncs';
import {DropdownSelect, DropdownSelectSimple, Portlet, PortletContent} from 'components';
import CreateAddress from '../CreateAddress';
import {KeyboardTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {TemplateStopService} from 'services/backend/templateStopService';
import {getTemplateStopTypeString, TemplateStopType} from '../../../services/enums/TemplateStopType';
import {displayModes} from '../../../services/enums/displayModes';
import {AuthService} from '../../../services/auth/authService';

function CreateTemplateStopDialog(props) {

  const {classes, t, onCancel, onCreate, createTemplateStopDialogState, shipperOptions, displayMode} = props;

  const [address, setAddress] = useState({
    streetAndNumber: '',
    zipcode: '',
    city: '',
    country: '',
  });
  const [shipperName, setShipperName] = useState(displayMode === displayModes.shipper ? AuthService.getUserOrganization() : '');
  const [customerId, setCustomerId] = useState('');
  const [stopType, setStopType] = useState(0);
  const [name, setName] = useState('');
  const [customerInfo, setCustomerInfo] = useState('');
  const [deliveryWeekDay, setDeliveryWeekDay] = useState({
    monday: false, tuesday: false, wednesday: false,
    thursday: false, friday: false, saturday: false, sunday: false
  });
  const [templateStopPickup, setTemplateStopPickup] = useState('');
  const [deliveryWindow, setDeliveryWindow] = useState({from: null, to: null});

  const [templateStopsPickup, setTemplateStopsPickup] = useState([]);

  useEffect(() => {
    if (!createTemplateStopDialogState.open) {
      return
    }

    TemplateStopService.getTemplateStopsWithFilter({stopType: 0, shipperName: shipperName}).then((data) => {
      const templateStops = data.map((templateStop) => {
        return {value: templateStop.id, name: templateStop.name}
      });
      return setTemplateStopsPickup(templateStops);
    })
  }, [shipperName, createTemplateStopDialogState]);

  const handleClose = () => {
    onCancel();
  };

  const activateOk = () => {
    return address.streetAndNumber && address.zipcode && address.city && address.country
  };


  const handleCreate = () => {
    onCreate(address, shipperName, customerId, stopType, name, customerInfo, deliveryWeekDay, deliveryWindow, templateStopPickup);
    setAddress({streetAndNumber: '', zipcode: '', city: '', country: '',});
    setShipperName('');
    setCustomerId('');
    setStopType(0);
    setName('');
    setCustomerInfo('');
    setDeliveryWeekDay({
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false
    });
    setDeliveryWindow({from: null, to: null});
    setTemplateStopPickup('')
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <React.Fragment>
        <Dialog
          disableEscapeKeyDown
          onClose={onCloseWithBackdropCurry(handleClose)}
          open={createTemplateStopDialogState.open}
        >
          <DialogTitle id="simple-dialog-title">{t('createTemplateStop')}</DialogTitle>
          <DialogContent>
            <form className={classes.container}>
              <Grid container spacing={2} alignItems="flex-start">
                <Grid item xs={6}>
                  <Portlet className={classes.content}>
                    <PortletContent>
                      <Typography variant="h3">
                        {t('generalInformation')}
                      </Typography>
                      <hr/>
                      <FormControl className={classes.formControl}>
                        <DropdownSelectSimple
                          className={classes.dropdown}
                          required
                          hideOnEmptyOptions
                          name={t('shipper')}
                          options={shipperOptions}
                          setValue={(value) => setShipperName(value)}
                          sort
                          value={shipperName}
                        />
                      </FormControl>
                      <FormControl className={classes.formControl} style={{minWidth: '200px'}}>
                        <InputLabel
                        >{t('customerId')}</InputLabel>
                        <Input
                          onChange={(event) => {
                            setCustomerId(event.target.value)
                          }}
                          value={customerId}
                        />
                      </FormControl>
                      <FormControl className={classes.formControl}>
                        <FormLabel>{t('stopType')}</FormLabel>
                        <RadioGroup
                          value={stopType}
                          onChange={(event, value) => {
                            const number = parseInt(value);
                            setStopType(isNaN(number) ? 0 : number)
                          }}
                        >
                          <FormControlLabel value={0} control={<Radio/>} label={t('pickup')}/>
                          <FormControlLabel value={1} control={<Radio/>} label={t('delivery')}/>
                        </RadioGroup>
                      </FormControl>
                      {
                        stopType === 1 &&
                        (
                          <DropdownSelect
                            hideOnEmptyOptions
                            required
                            name={t(getTemplateStopTypeString(TemplateStopType.Pickup))}
                            options={templateStopsPickup}
                            setValue={(value) => setTemplateStopPickup(value)}
                            sort
                            value={templateStopPickup}/>
                        )
                      }
                      <FormControl className={classes.formControl}>
                        <InputLabel
                          required
                        >{t('name')}</InputLabel>
                        <Input
                          error={!name}
                          onChange={(event) => {
                            setName(event.target.value)
                          }}
                          required
                          value={name}
                        />
                      </FormControl>
                      <FormControl className={classes.formControl}>
                        <InputLabel
                        >{t('customerInfo')}</InputLabel>
                        <Input
                          onChange={(event) => {
                            setCustomerInfo(event.target.value)
                          }}
                          value={customerInfo}
                        />
                      </FormControl>
                    </PortletContent>
                  </Portlet>
                </Grid>
                <Grid item xs={6}>
                  <CreateAddress
                    address={address}
                    className={classes.content}
                    required
                    setAddress={setAddress}
                    title={t('address')}
                  />
                </Grid>
                <Grid item xl={6}>
                  <Portlet className={classes.content}>
                    <PortletContent>
                      <Typography variant="h3">
                        {t('deliveryInformation')}
                      </Typography>
                      <hr/>
                      <FormControl className={classes.formControl}>
                        <FormLabel>{t('deliveryWindow')}</FormLabel>
                        <FormGroup>
                          <KeyboardTimePicker
                            cancelLabel={t('dialogCancel')}
                            id="deliveryWindowFrom"
                            label={t('timeFrom')}
                            marging="normal"
                            ampm={false}
                            onChange={(event) => {
                              setDeliveryWindow({
                                  from: new Date(event),
                                  to: deliveryWindow?.to
                                }
                              )
                            }}
                            value={deliveryWindow?.from ? deliveryWindow.from : null}
                          />
                          <KeyboardTimePicker
                            cancelLabel={t('dialogCancel')}
                            id="deliveryWindowTo"
                            label={t('timeTo')}
                            marging="normal"
                            ampm={false}
                            error={new Date(deliveryWindow?.to).getTime() < new Date(deliveryWindow?.from).getTime()}
                            onChange={(event) => {
                              setDeliveryWindow({
                                from: deliveryWindow?.from,
                                to: new Date(event)
                              })
                            }}
                            value={deliveryWindow?.to ? deliveryWindow.to : null}
                          />
                        </FormGroup>
                      </FormControl>
                    </PortletContent>
                  </Portlet>
                </Grid>
                <Grid item xl={6}>
                  <Portlet className={classes.content}>
                    <PortletContent>
                      <Typography variant="h3">
                        {t('deliveryDays')}
                      </Typography>
                      <hr/>
                      <FormControl className={classes.formControl}>
                        <FormLabel>{t('deliveryWeekDay')}</FormLabel>
                        <FormGroup>
                          <FormControlLabel control={<Checkbox/>} label={t('monday')}
                                            checked={deliveryWeekDay.monday} onChange={(event, checked) => {
                            setDeliveryWeekDay({...deliveryWeekDay, monday: checked})
                          }}/>
                          <FormControlLabel control={<Checkbox/>} label={t('tuesday')}
                                            checked={deliveryWeekDay.tuesday} onChange={(event, checked) => {
                            setDeliveryWeekDay({...deliveryWeekDay, tuesday: checked})
                          }}/>
                          <FormControlLabel control={<Checkbox/>} label={t('wednesday')}
                                            checked={deliveryWeekDay.wednesday} onChange={(event, checked) => {
                            setDeliveryWeekDay({...deliveryWeekDay, wednesday: checked})
                          }}/>
                          <FormControlLabel control={<Checkbox/>} label={t('thursday')}
                                            checked={deliveryWeekDay.thursday} onChange={(event, checked) => {
                            setDeliveryWeekDay({...deliveryWeekDay, thursday: checked})
                          }}/>
                          <FormControlLabel control={<Checkbox/>} label={t('friday')}
                                            checked={deliveryWeekDay.friday} onChange={(event, checked) => {
                            setDeliveryWeekDay({...deliveryWeekDay, friday: checked})
                          }}/>
                          <FormControlLabel control={<Checkbox/>} label={t('saturday')}
                                            checked={deliveryWeekDay.saturday} onChange={(event, checked) => {
                            setDeliveryWeekDay({...deliveryWeekDay, saturday: checked})
                          }}/>
                          <FormControlLabel control={<Checkbox/>} label={t('sunday')}
                                            checked={deliveryWeekDay.sunday} onChange={(event, checked) => {
                            setDeliveryWeekDay({...deliveryWeekDay, sunday: checked})
                          }}/>
                        </FormGroup>
                      </FormControl>
                    </PortletContent>
                  </Portlet>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              color="default"
              onClick={handleClose}
            >
              <CancelIcon/>&nbsp;{t('dialogCancel')}
            </Button>
            <Button
              color="primary"
              disabled={!activateOk()}
              onClick={handleCreate}
            >
              <SaveIcon/>&nbsp;{t('save')}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </MuiPickersUtilsProvider>
  );
}

CreateTemplateStopDialog.propTypes = {
  createTemplateStopDialogState: PropTypes.object.isRequired,
  classes: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  shipperOptions: PropTypes.array,
};


export default compose(withStyles(styles), withTranslation())(CreateTemplateStopDialog);
