import {compose} from 'recompose';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Input, InputLabel, MenuItem, Select, withStyles} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import styles from './styles'
import classNames from 'classnames';
import React, {Fragment, useCallback, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {FeatureGroup, Map, Polygon, Popup, TileLayer} from 'react-leaflet';
import {EditControl} from 'react-leaflet-draw';
import {Save as SaveIcon} from '@material-ui/icons';
import MicroHubMarker from '../../MapComponents/Marker/MicroHubMarker';
import {ColorService} from '../../../../services/color/colorService';
import {onCloseWithBackdropCurry} from '../../../../services/util/helperFuncs';

let dict = {};

class DictManager {
  static get() {
    return dict;
  }

  static getValue(key) {
    return dict[key];
  }

  static put(key, value) {
    dict[key] = value;
  }

  static reset() {
    dict = {};
  }

  static findKeyByValue(searchValue) {
    let result = undefined;
    Object.getOwnPropertyNames(dict).forEach(key => {
      let value = dict[key];
      if (value === searchValue) {
        result = key;
      }
    });
    return result;
  }
}

function coordinateEquals(c1, c2) {
  if (c1.length !== c2.length) {
    return false;
  }
  for (let i = 0; i < c1.length; i++) {
    if (c1[i][0] !== c2[i][0]) {
      return false;
    }
    if (c1[i][1] !== c2[i][1]) {
      return false;
    }
  }
  return true;
}

function filterNames(names) {
  const filteredNames = [];
  names.forEach(name => {
    if (!DictManager.findKeyByValue(name)) {
      filteredNames.push(name);
    }
  });
  return filteredNames;
}

function SelectMap(props) {

  const {classes, t, className, polygons, readonly, polygonsChanged, onSave, unusedNames, microHubs, dirty, setDirty, newPolygonsSet, setNewPolygonsSet} = props;
  const rootClassName = classNames(classes.root, className);
  const [drawHandler, setDrawHandler] = useState();
  const [dialogState, setDialogState] = useState({open: false, name: ''});
  const [, updateState] = React.useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const editableFG = useRef(null);
  const map = useRef(null);
  const microHubsWithCoords = microHubs.filter(hub => hub.address && hub.address.lng && hub.address.lat);

  const [zoom, setZoom] = useState(6);
  const [center, setCenter] = useState([50, 10.5]);

  if (editableFG.current && (!dirty || newPolygonsSet)) {
    DictManager.reset();
    const leafletPolygons = editableFG.current.leafletElement._layers;
    Object.getOwnPropertyNames(leafletPolygons).forEach(lp => {
      const coordinates = leafletPolygons[lp]._latlngs[0].map(l => {
        return [l.lat, l.lng];
      });

      const leafletId = leafletPolygons[lp]._leaflet_id;
      const index = polygons.findIndex(p => {
        const coordinatesOfP = p.coordinates.slice(0);
        if (coordinatesOfP[0][0] === coordinatesOfP[coordinatesOfP.length - 1][0] && coordinatesOfP[0][1] === coordinatesOfP[coordinatesOfP.length - 1][1]) {
          coordinatesOfP.pop();
        }
        return coordinateEquals(coordinatesOfP, coordinates)
      });
      if (index !== -1) {
        const name = polygons[index].name;
        DictManager.put(leafletId, name);
      }
    });
    setNewPolygonsSet(false);
  }


  const fitMapBoundsToFG = () => {
    //TODO remove try catch and check leafletElement for valid polygons
    try {
      map.current.leafletElement.fitBounds(editableFG.current.leafletElement.getBounds());
    } catch (e) {
      const coordinates = microHubsWithCoords.map(hub => {
        return [hub.address.lat, hub.address.lng];
      });
      const latMax = Math.max(...coordinates.map(coordinateTuple => coordinateTuple[0]));
      const latMin = Math.min(...coordinates.map(coordinateTuple => coordinateTuple[0]));
      const lngMax = Math.max(...coordinates.map(coordinateTuple => coordinateTuple[1]));
      const lngMin = Math.min(...coordinates.map(coordinateTuple => coordinateTuple[1]));
      let boundingBox = [];
      if (Math.abs(latMin - latMax) < 0.001 || Math.abs(lngMin - lngMax) < 0.001) {
        boundingBox = [
          [latMin - 0.001, lngMin - 0.001],
          [latMax + 0.001, lngMax + 0.001]]
      } else {
        boundingBox = [
          [latMin, lngMin],
          [latMax, lngMax]]
      }
      map.current.leafletElement.fitBounds(boundingBox, {padding: [30, 30]});
    }
  };

  const onMounted = (context) => {
    if (!readonly) {
      setDrawHandler(context._toolbars.draw._modes.polygon.handler);
    }
  };

  const onDrawStart = () => {
    setDialogState({name: '', open: true});
  };

  const onEditStop = () => {
    setDirty(true);
  };

  const onDeleteStop = () => {
    setDirty(true);
  };


  const onCreated = (e) => {
    const name = dialogState.name;
    const coordinates = e.layer._latlngs[0].map(l => {
      return [l.lat, l.lng];
    });
    const newPolygons = [];
    editableFG.current.leafletElement.removeLayer(e.layer);
    polygons.forEach(p => newPolygons.push(p));
    newPolygons.push({name: name, coordinates: coordinates, color: ColorService.getColorByNumber(Math.floor(Math.random() * 100))});
    polygonsChanged(newPolygons);
    forceUpdate();
  };

  const onDeleted = () => {
    forceUpdate();
  };

  const saveState = () => {
    const newPolygons = [];
    const leafletPolygons = editableFG.current.leafletElement._layers;
    Object.getOwnPropertyNames(leafletPolygons).forEach(lp => {
      const coordinates = leafletPolygons[lp]._latlngs[0].map(l => {
        return [l.lat, l.lng];
      });
      const leafletId = leafletPolygons[lp]._leaflet_id;
      newPolygons.push({
        coordinates: coordinates,
        name: DictManager.getValue(leafletId)
      });
    });
    onSave(newPolygons);
  };

  const handleChangeDialog = event => {
    setDialogState({...dialogState, name: (event.target.value)});
  };

  const handleCancelDialog = () => {
    setDialogState({...dialogState, open: false});
    drawHandler.disable();
  };

  const handleCloseDialog = () => {
    const selectedHubs = microHubsWithCoords.filter(hub => hub.name === dialogState.name[0]);
    if (selectedHubs && selectedHubs.length > 0) {
      const selectedHub = selectedHubs[0];
      setCenter([selectedHub.address.lat, selectedHub.address.lng]);
      setZoom(13);
    }
    setDialogState({...dialogState, open: false});
  };

  return (
    <Fragment>
      {!readonly ?
        <Button
          className={classes.button}
          color="primary"
          disabled={!dirty}
          onClick={saveState}
          variant="contained"
        >
          <SaveIcon className={classes.iconSpacing}/>
          {t('save')}
        </Button>
        : null
      }
      <Map
        boundsOptions={{padding: [0, 0]}}
        center={center}
        className={rootClassName}
        ref={map}
        whenReady={fitMapBoundsToFG}
        zoom={zoom}
        zoomSnap={0.25}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {microHubsWithCoords.map((hub, index) => {
          return (
            <MicroHubMarker
              key={index}
              microHub={hub}
            />
          );
        })
        }
        <FeatureGroup
          ref={editableFG}
        >
          <EditControl
            draw={readonly ? {
              polyline: false,
              line: false,
              circle: false,
              circlemarker: false,
              rectangle: false,
              marker: false,
              polygon: false
            } : {
              polyline: false,
              line: false,
              circle: false,
              circlemarker: false,
              rectangle: false,
              marker: false,
            }}
            edit={readonly ? {edit: false, remove: false} : {
              edit: {
                selectedPathOptions: {
                  maintainColor: true,
                  opacity: 0.8
                }
              }, remove: true
            }}
            onCreated={onCreated}
            onDeleted={onDeleted}
            onDeleteStop={onDeleteStop}
            onDrawStart={onDrawStart}
            onEditStop={onEditStop}
            onMounted={onMounted}
            position="topright"
          />
          {polygons && polygons.map((poly, index) => {
            return (
              <Polygon
                color={ColorService.getColorByNumber(index)}
                key={index}
                positions={poly.coordinates}
              >
                <Popup>
                  {poly.name}
                </Popup>
              </Polygon>
            );
          })}
        </FeatureGroup>
      </Map>

      <Dialog
        disableEscapeKeyDown
        onClose={onCloseWithBackdropCurry(handleCancelDialog)}
        open={dialogState.open}
      >
        <DialogTitle>{t('selectCarrierOrMicroHub')}</DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel
                htmlFor="text-simple"
                required
              >{t('carrierOrMicroHub')}</InputLabel>
              <Select
                error={!dialogState.name}
                input={<Input id="text-simple"/>}
                onChange={handleChangeDialog}
                required
                value={dialogState.name}
              >
                {unusedNames && filterNames(unusedNames).map((name, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={name}
                    >
                      {name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleCancelDialog}
          >
            {t('dialogCancel')}
          </Button>
          <Button
            color="primary"
            disabled={!dialogState.name}
            onClick={handleCloseDialog}
          >
            {t('dialogOk')}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

SelectMap.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  dirty: PropTypes.bool.isRequired,
  microHubs: PropTypes.array,
  newPolygonsSet: PropTypes.bool,
  onSave: PropTypes.func,
  polygons: PropTypes.array.isRequired,
  polygonsChanged: PropTypes.func,
  readonly: PropTypes.bool,
  setDirty: PropTypes.func,
  setNewPolygonsSet: PropTypes.func,
  t: PropTypes.func.isRequired,
  unusedNames: PropTypes.array,
};


export default compose(withStyles(styles), withTranslation())(SelectMap);
