import {compose} from 'recompose';
import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import L from 'leaflet';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import {useLeaflet} from 'react-leaflet';

const StopMarkerCluster = (props) => {

  const {stops, t, getClusterIcon, getStopMarkerAndAddToMcg, useCluster, clusterIconColor} = props;

  const mcg = L.markerClusterGroup({
    iconCreateFunction: function () {
      return getClusterIcon(clusterIconColor);
    },
    maxClusterRadius: 50,
    showCoverageOnHover: false,
    disableClusteringAtZoom: useCluster ? 16 : 1,
    spiderfyOnMaxZoom: false
  });


  const {map} = useLeaflet();

  useEffect(() => {
    mcg.clearLayers();
    stops.forEach(stop => getStopMarkerAndAddToMcg(stop, mcg, t));
    map.addLayer(mcg);
    return function cleanup() {
      map.removeLayer(mcg);
    }
  }, [stops, t, mcg, map, getStopMarkerAndAddToMcg]);

  return null;
};

StopMarkerCluster.propTypes = {
  clusterIconColor: PropTypes.string,
  getClusterIcon: PropTypes.func.isRequired,
  getStopMarkerAndAddToMcg: PropTypes.func.isRequired,
  stops: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  useCluster: PropTypes.bool.isRequired
};

export default compose(withTranslation())(StopMarkerCluster);
