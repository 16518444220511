import React, {useState} from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  withStyles
} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import {onCloseWithBackdropCurry} from '../../../services/util/helperFuncs';
import FormGroup from '@material-ui/core/FormGroup';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import SimpleDropdownSelect from '../../BasicComponents/SimpleDropdownSelect';
import MultiSelectDropdownSelect from '../../BasicComponents/MultiSelectDropdownSelect';

function CreateSpecialDeliveryDayDialog(props) {

  const {classes, t, carriersWithMicroHubs, microHubOptions, shipperOptions, createSpecialDeliveryDayDialogState, handleClose, handleCancel} = props;

  const [newSpecialDeliveryDay, setNewSpecialDeliveryDay] = useState({
    shipperName: '',
    carrierName: '',
    microHubNames: [],
    day: null,
  });


  const resetState = () => {
    setNewSpecialDeliveryDay({
      shipperName: '',
      carrierName: '',
      microHubNames: [],
      day: null,
    });
  };

  const handleOk = () => {
    handleClose(newSpecialDeliveryDay);
    resetState();
  };

  const handleCancelInner = () => {
    handleCancel();
    resetState();
  };

  const getMicroHubsForCarrier = () => {
    if (carriersWithMicroHubs) {
      return carriersWithMicroHubs[newSpecialDeliveryDay.carrierName] ? carriersWithMicroHubs[newSpecialDeliveryDay.carrierName] : []
    }
    return microHubOptions
  }

  const carrierOptions = carriersWithMicroHubs ? Object.keys(carriersWithMicroHubs) : []
  const calculatedMicroHubOptions = getMicroHubsForCarrier()


  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={createSpecialDeliveryDayDialogState}
    >
      <DialogTitle>{t('createSpecialDeliveryDay')}</DialogTitle>
      <DialogContent>
        <div className={classes.container}>
          <FormGroup
            className={classes.wrapperChild}
          >
            <SimpleDropdownSelect
              className={classes.formControl}
              name={t('shipper')}
              options={shipperOptions}
              setValue={(value) => setNewSpecialDeliveryDay({...newSpecialDeliveryDay, shipperName: value})}
              value={newSpecialDeliveryDay.shipperName}
            />
            <SimpleDropdownSelect
              className={classes.formControl}
              hideOnEmptyOptions
              name={t('carrier')}
              options={carrierOptions}
              setValue={(value) => setNewSpecialDeliveryDay({...newSpecialDeliveryDay, carrierName: value})}
              value={newSpecialDeliveryDay.carrierName}
            />
            <MultiSelectDropdownSelect
              className={classes.formControl}
              disabled={!calculatedMicroHubOptions}
              name={t('microHubs')}
              options={calculatedMicroHubOptions}
              setValue={(value) => setNewSpecialDeliveryDay({...newSpecialDeliveryDay, microHubNames: value})}
              value={newSpecialDeliveryDay.microHubNames}
            />

            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  cancelLabel={t('dialogCancel')}
                  format="dd.MM.yyyy"
                  id="specialDeliveryDayDay"
                  label={t('day')}
                  margin="normal"
                  onChange={(event) => setNewSpecialDeliveryDay({...newSpecialDeliveryDay, day: new Date(event)})}
                  required
                  value={newSpecialDeliveryDay.day}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </FormGroup>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={handleCancelInner}
        >
          <CancelIcon/>&nbsp;{t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          disabled={
            newSpecialDeliveryDay.day === null ||
            newSpecialDeliveryDay.shipperName === null || newSpecialDeliveryDay.shipperName === '' ||
            (carriersWithMicroHubs && (newSpecialDeliveryDay.carrierName === null || newSpecialDeliveryDay.carrierName === '')) ||
            newSpecialDeliveryDay.microHubNames.length === 0
          }
          onClick={handleOk}
        >
          <SaveIcon/>&nbsp;{t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CreateSpecialDeliveryDayDialog.propTypes = {
  carriersWithMicroHubs: PropTypes.object,
  classes: PropTypes.object.isRequired,
  createSpecialDeliveryDayDialogState: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  microHubOptions: PropTypes.array,
  shipperOptions: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(CreateSpecialDeliveryDayDialog);
