import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import GlobalDialogContainer from '../GlobalDialogContainer';
import ProblemNotificationDialogContextProvider from '../ProblemNotificationDialog/provider';
import NewsToUserDialogContextProvider from '../NewsToUserDialog/provider';


function GlobalDialogProvider(props) {

  return (
    <Fragment>
      <NewsToUserDialogContextProvider>
        <ProblemNotificationDialogContextProvider>
          {props.children}
          <GlobalDialogContainer/>
        </ProblemNotificationDialogContextProvider>
      </NewsToUserDialogContextProvider>
    </Fragment>
  )
}


GlobalDialogProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};


export default (GlobalDialogProvider);
