import React from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {CarrierAndMicroHubDropdownSelect, DropdownSelectSimple} from '../../index';

function MicroHubAdministrationFilter(props) {

  const {classes, className, t, filter, setFilter, carrierWithMicroHubs, microHubOptions, disabled, shipperOptions} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <form className={classes.rowWrapper}>
        <CarrierAndMicroHubDropdownSelect
          carrierDescriptionKey={'20'}
          carrierRequired
          carriersWithMicroHubs={carrierWithMicroHubs}
          carrierValue={filter.carrierName}
          className={classes.rowWrapper}
          disabled={disabled}
          errorCarrier={!filter.carrierName}
          errorMicroHub={!filter.microHubName}
          microHubDescriptionKey={'30'}
          microHubOptions={microHubOptions}
          microHubRequired
          microHubValue={filter.microHubName}
          setCarrierValue={(value) => setFilter({...filter, carrierName: value, microHubName: ''})}
          setMicroHubValue={(value) => setFilter({...filter, microHubName: value})}
        />
        <DropdownSelectSimple
          descriptionKey={'40'}
          disabled={disabled}
          hideOnEmptyOptions
          name={t('shipper')}
          options={shipperOptions}
          setValue={(value) => setFilter({...filter, shipperName: value})}
          sort
          value={filter.shipperName}
        />
      </form>
    </div>
  );
}


MicroHubAdministrationFilter.propTypes = {
  carrierWithMicroHubs: PropTypes.object,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  filter: PropTypes.object.isRequired,
  microHubOptions: PropTypes.array,
  setFilter: PropTypes.func.isRequired,
  shipperOptions: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(MicroHubAdministrationFilter);
