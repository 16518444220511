import {AuthService} from '../auth/authService';
import {StopStatus} from '../enums/StopStatus';
import axios from 'axios';

const baseUri = `${process.env.REACT_APP_API_URI}routing`;

export class TourService {


  static async getTourByIdShallow(tourId) {
    const response = await axios.get(`${baseUri}/tours/${tourId}/shallow`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        }
      });
    return response.data;
  }

  static async getPlannedStopDeliveries(tourId) {
    const response = await axios.get(`${baseUri}/tours/plannedStopDelivery/${tourId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        }
      });
    return response.data;
  }

  static async getByProblemAffectedStopsExcel(shipperName, problemNotificationId) {
    return await axios.put(`${baseUri}/excelExport/downloadStopsWithProblems`,
      {
        shipperName: shipperName,
        problemNotificationId: problemNotificationId,
        ianaTimeZoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        }
      });
  }

  static async downloadStopInvoicesExcel(filter) {
    return await axios.put(`${baseUri}/excelExport/downloadStopInvoicesByFilter`,
      {
        ...filter,
        ianaTimeZoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        }
      });
  }

  static async getDriverNotificationsByFilter(carrierName, shipperName, fromDate, toDate) {
    const response = await axios.put(`${baseUri}/stops/driver-notifications`,
      {
        carrierName: carrierName,
        shipperName: shipperName,
        fromDate: fromDate,
        toDate: toDate
      },
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        }
      });
    return response.data;
  }

  static async getMigrationsForDate(date, shipper, carrier, hubList) {
    const response = await axios.put(`${baseUri}/TourMigration/byFilter`,
      {
        migrationDate: date,
        shipperName: shipper,
        carrierName: carrier,
        microHubList: hubList
      },
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        }
      });
    return response.data;
  }

  static async runFinalMigration(migrationData) {
    const response = await axios.post(`${baseUri}/TourMigration`,
      {
        ...migrationData,
        migrationDate: new Date(Date.UTC(migrationData.migrationDate.getFullYear(), migrationData.migrationDate.getMonth(), migrationData.migrationDate.getDate())),
      },
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        }
      });
    return response.data;
  }

  static async getFinalDeadlineMigrationData(date, shipperName, withStopsWithoutPreviewStop = false) {
    const response = await axios.put(`${baseUri}/stops/finalDataInfo/${withStopsWithoutPreviewStop}`,
      {
        date: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
        shipperName: shipperName
      },
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        }
      });
    return response.data;
  }

  static async updateMasterDisposition(update) {
    const response = await axios.put(`${baseUri}/tours/master-disposition`, update, {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
    return response.data;
  }

  static getStopsPerHour(fromDate, toDate, carrierName) {
    return fetch(`${baseUri}/kpi/stopsPerHour/byFilter`, {
      method: 'PUT',
      body: JSON.stringify({
        carrierName: carrierName,
        from: fromDate,
        to: toDate
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  }

  static getTimeToPackSecondsPerBox(fromDate, toDate, carrierName) {
    return fetch(`${baseUri}/kpi/timeToPackSecondsPerBox/byFilter`, {
      method: 'PUT',
      body: JSON.stringify({
        carrierName: carrierName,
        from: fromDate,
        to: toDate
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  }


  static async getStopById(stopId) {
    const response = await axios.get(`${baseUri}/stops/${stopId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        }
      });
    return response.data;
  }

  static async getStopByPreviewStopIdentifier(identifier) {
    const response = await axios.get(`${baseUri}/stops/previewStopIdentifier/${identifier}`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        }
      });
    return response.data;
  }

  static getStopHistoryById(stopId) {
    return fetch(`${baseUri}/stops/stopHistory/${stopId}`, {
      headers: {
        authorization: AuthService.getAuthHeader()
      }
    })
  }

  static createStop(stopToCreate) {
    let deliveryDate = new Date(stopToCreate.deliveryDate);
    stopToCreate.deliveryDate = new Date(Date.UTC(deliveryDate.getFullYear(), deliveryDate.getMonth(), deliveryDate.getDate()));
    return fetch(`${baseUri}/stops`, {
      method: 'POST',
      body: JSON.stringify(stopToCreate),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    })
  }

  static updateStop(stopToUpdate, stopId) {
    return fetch(`${baseUri}/stops/${stopId}`, {
      method: 'Put',
      body:
        JSON.stringify(stopToUpdate),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    })
  }

  static deleteStoById(stopId) {
    return fetch(`${baseUri}/stops/${stopId}`, {
      method: 'DELETE',
      body: JSON.stringify(
        {
          email: AuthService.getUserEmail(),
          organization: AuthService.getUserOrganization(),
          permission: AuthService.getUserPermission(),
          role: AuthService.getUserRole()
        }),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    })
  }

  static deleteStopFromTourById(stopId, tourId) {
    return fetch(`${baseUri}/tours/${tourId}/${stopId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    })
  }

  static getNoPlanedNoCarrierStops() {
    return fetch(`${baseUri}/stops/notPlaned/noCarrier`, {
      headers: {
        authorization: AuthService.getAuthHeader()
      }
    })
  }

  static getStopsWithFilterBase(endpoint, carrierName, shipperName, microHubName, stopStatus, fromDate, toDate, boxId = null, controller = 'stops') {
    return fetch(`${baseUri}/${controller}/${endpoint}/`, {
      method: 'PUT',
      body: JSON.stringify({
        carrierName: carrierName,
        shipperName: shipperName,
        microHubName: microHubName,
        stopStatus: stopStatus,
        fromDate: fromDate,
        toDate: toDate,
        boxId: boxId
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  }

  static getStopsWithFilter(carrierName, shipperName, microHubName, stopStatus, fromDate, toDate, boxId = null) {
    return this.getStopsWithFilterBase('byFilter', carrierName, shipperName, microHubName, stopStatus, fromDate, toDate, boxId);
  }

  static getStopsWithFilterIncludeStoragePlaces(carrierName, shipperName, microHubName, stopStatus, fromDate, toDate, boxId = null) {
    return this.getStopsWithFilterBase('ByFilterWithStoragePlaces', carrierName, shipperName, microHubName, stopStatus, fromDate, toDate, boxId);
  }

  static getCanNotDeliverStopsWithEndedTourWithFilter(carrierName, shipperName, microHubName, fromDate, toDate) {
    return fetch(`${baseUri}/stops/byFilter/canNotDeliver`, {
      method: 'PUT',
      body: JSON.stringify({
        carrierName: carrierName,
        shipperName: shipperName,
        microHubName: microHubName,
        stopStatus: StopStatus.CanNotDeliver,
        fromDate: fromDate,
        toDate: toDate
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  }

  static deleteCarrierFromStopById(stopId) {
    return fetch(`${baseUri}/stops/${stopId}/carrier`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    })
  }

  static getTourById(tourId) {
    return fetch(`${baseUri}/tours/${tourId}`, {
      headers: {
        authorization: AuthService.getAuthHeader()
      }
    })
  }

  static getToursWithFilter(carrierName, shipperName, microHubName, fromDate, toDate) {
    return fetch(`${baseUri}/tours/byFilter/`, {
      method: 'PUT',
      body: JSON.stringify({
        carrierName: carrierName,
        shipperName: shipperName,
        microHubName: microHubName,
        fromDate: fromDate,
        toDate: toDate
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  }

  static async getStatisticsWithFilter(filter) {
    const response = await axios.put(`${baseUri}/invoice/tourStatistics/byFilter/`,
      filter,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        }
      }
    );
    return response.data
  }

  static async getDateStatisticsWithFilter(filter) {
    const response = await axios.put(`${baseUri}/invoice/dateStatistics/byFilter/`,
      filter,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        }
      }
    );
    return response.data
  }

  static async getInvoicesWithFilter(filter) {
    const response = await axios.put(`${baseUri}/invoice/byFilter/`,
      filter,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        }
      }
    );
    return response.data
  }

  static changeStopOrderOnTour(tour, tourId) {
    return fetch(`${baseUri}/tours/${tourId}/changeStopOrder`, {
      method: 'PUT',
      body: JSON.stringify(tour),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    })
  }

  static updateTour(tour, tourId) {
    return fetch(`${baseUri}/tours/${tourId}`, {
      method: 'PUT',
      body: JSON.stringify(tour),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    })
  }

  static updateTourName(tourId, tourName) {
    return fetch(`${baseUri}/tours/updateName/${tourId}/${tourName}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    })
  }

  static moveStopsToTour(tourIdDestination, stopIds) {
    return fetch(`${baseUri}/tours/move/to/${tourIdDestination}`, {
      method: 'PUT',
      body: JSON.stringify(stopIds),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    })
  }

  static moveStopsToTourByClickOrder(tourIdDestination, stopIds) {
    return fetch(`${baseUri}/tours/move/in-click-order/to/${tourIdDestination}`, {
      method: 'PUT',
      body: JSON.stringify(stopIds),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    })
  }

  static updateDriverByTourId(driverEmail, driverDisplayName, tourId) {
    return fetch(`${baseUri}/tours/${tourId}/driver`, {
      method: 'PUT',
      body: JSON.stringify({
        driverEmail,
        driverDisplayName
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    })
  }

  static updateVehicleByTourId(vehicleLicensePlate, averageSpeedKmh, tourId) {
    return fetch(`${baseUri}/tours/${tourId}/vehicle`, {
      method: 'PUT',
      body: JSON.stringify({
        vehicleLicensePlate,
        averageSpeed: averageSpeedKmh,
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    })
  }

  static updatePlanedTourStartByTourId(planedTourStart, tourId) {
    return fetch(`${baseUri}/tours/${tourId}/planedTourStart`, {
      method: 'PUT',
      body: JSON.stringify({tourStart: new Date(planedTourStart)}),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    })
  }

  static approveTourById(approved, tourId) {
    return fetch(`${baseUri}/tours/${tourId}/approveTour/${approved}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    })
  }

  static approveAllTours(tourIds) {
    return fetch(`${baseUri}/tours/approveAllTours`, {
      method: 'PUT',
      body: JSON.stringify(tourIds),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  }

  static createTours(tour, numTimesToCreate = 1) {
    let deliveryDate = new Date(tour.tourDeliveryDate);
    tour.tourDeliveryDate = new Date(Date.UTC(deliveryDate.getFullYear(), deliveryDate.getMonth(), deliveryDate.getDate()));
    return fetch(`${baseUri}/tours/${numTimesToCreate}`, {
      method: 'POST',
      body: JSON.stringify(tour),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    })
  }

  static deleteTour(tourId) {
    return fetch(`${baseUri}/tours/${tourId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    })
  }

  static deleteAllTours(tourIds) {
    return fetch(`${baseUri}/tours/toDelete`, {
      method: 'PUT',
      body: JSON.stringify(tourIds),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  }

  static downloadExcelBase(endpoint, carrierName, shipperName, microHubName, stopStatus, fromDate, toDate) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return fetch(`${baseUri}/ExcelExport/${endpoint}/`, {
      method: 'PUT',
      body: JSON.stringify({
        carrierName: carrierName,
        shipperName: shipperName,
        microHubName: microHubName,
        stopStatus: stopStatus,
        fromDate: fromDate,
        toDate: toDate,
        ianaTimeZoneName: timeZone
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  }

  static downloadExcelCarrier(carrierName, shipperName, microHubName, stopStatus, fromDate, toDate) {
    return this.downloadExcelBase('DownloadStopsByFilterForCarrier', carrierName, shipperName, microHubName, stopStatus, fromDate, toDate);
  }

  static downloadExcelZa(carrierName, shipperName, microHubName, stopStatus, fromDate, toDate) {
    return this.downloadExcelBase('DownloadStopsByFilterForZa', carrierName, shipperName, microHubName, stopStatus, fromDate, toDate);
  }

  static downloadExcelShipper(carrierName, shipperName, microHubName, stopStatus, fromDate, toDate) {
    return this.downloadExcelBase('DownloadStopsByFilterForShipper', carrierName, shipperName, microHubName, stopStatus, fromDate, toDate);
  }

  static downloadEmptiesForStopsAsExcelCarrier(carrierName, shipperName, microHubName, stopStatus, fromDate, toDate) {
    return this.downloadExcelBase('DownloadEmptiesByFilterForCarrier', carrierName, shipperName, microHubName, stopStatus, fromDate, toDate);
  }

  static downloadEmptiesForStopsAsExcelZa(carrierName, shipperName, microHubName, stopStatus, fromDate, toDate) {
    return this.downloadExcelBase('DownloadEmptiesByFilterForZa', carrierName, shipperName, microHubName, stopStatus, fromDate, toDate);
  }

  static downloadEmptiesForStopsAsExcelShipper(carrierName, shipperName, microHubName, stopStatus, fromDate, toDate) {
    return this.downloadExcelBase('DownloadEmptiesByFilterForShipper', carrierName, shipperName, microHubName, stopStatus, fromDate, toDate);
  }

  static downloadExcelTemplateShipperStops(shipperName) {
    return fetch(`${baseUri}/ExcelTemplate/Shipper/StopUpload/${shipperName}`, {
      method: 'GET',
      headers: {
        authorization: AuthService.getAuthHeader(),
      }
    });
  }

  static downloadExcelTemplateShipperTour(shipperName) {
    return fetch(`${baseUri}/ExcelTemplate/Shipper/TourUpload/${shipperName}`, {
      method: 'GET',
      headers: {
        authorization: AuthService.getAuthHeader(),
      }
    });
  }

  static downloadExcelTemplateCarrierStops(carrierName) {
    return fetch(`${baseUri}/ExcelTemplate/Carrier/StopUpload/${carrierName}`, {
      method: 'GET',
      headers: {
        authorization: AuthService.getAuthHeader(),
      }
    });
  }

  static startSimulation(stops, runDate, configuration) {
    return fetch(`${baseUri}/simulateTourBuilding/algorithm/run`, {
      method: 'POST',
      body: JSON.stringify({stops: stops, simulationDate: runDate, configuration: configuration}),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader(),
      }
    });
  }

  static getUploadedExcels(type, days) {
    const org = AuthService.getUserOrganization();
    return fetch(`${baseUri}/Utility/UploadedExcels/${type}/${days}/${org}`, {
      method: 'GET',
      headers: {
        authorization: AuthService.getAuthHeader(),
      }
    });
  }

  static async getAppVersion() {
    const response = await axios.get(`${baseUri}/utility/app-version`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        }
      });
    return response.data;
  }

  static async setAppVersion(minVersion, currentVersion) {
    const response = await axios.post(`${baseUri}/utility/app-version`,
      {
        allowedVersions: minVersion,
        currentVersion
      },
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        }
      });
    return response.data;
  }

  static downloadMhPlanExcel(shipper, microHubs, date) {
    return fetch(`${baseUri}/Stops/MhLocationMapForShipper`, {
      method: 'POST',
      body: JSON.stringify({
        shipper: shipper,
        MicroHubs: microHubs,
        DeliveryDate: date ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) : null
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader(),
      }
    });
  }

  static getDeadlineForFinal(shipperName, date) {
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString();
    return fetch(`${baseUri}/Stops/deadline/${shipperName}/${date}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  }

  static changeStopAssignment(stopId, carrierName, microHubName) {
    return fetch(`${baseUri}/stops/changeAssignment`, {
      method: 'PUT',
      body: JSON.stringify({
        stopId: stopId,
        microHubName: microHubName,
        carrierName: carrierName
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    })
  }

  static getDeletedStopsByFilter(carrierName, shipperName, microHubName, fromDate, toDate) {
    return fetch(`${baseUri}/stops/deletedStops/byFilter/`, {
      method: 'PUT',
      body: JSON.stringify({
        carrierName: carrierName,
        shipperName: shipperName,
        microHubName: microHubName,
        fromDate: fromDate,
        toDate: toDate
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  }

  static endTourDelivery(tourId, endDate) {
    return fetch(`${baseUri}/app/end-tour-delivery/${tourId}`, {
      method: 'PUT',
      body: JSON.stringify({dateTime: endDate}),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    })
  }

  static notifyProblem(carrierName, problemType, deliveryDate, identifier, message) {
    return fetch(`${baseUri}/problems`, {
      method: 'POST',
      body: JSON.stringify({
        carrierName: carrierName,
        deliveryDate: deliveryDate,
        problemNotificationType: problemType,
        identifier: identifier,
        message: message,
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    })
  }

  static getProblemNotifications(carrierName, shipperName, fromDate, toDate) {
    return fetch(`${baseUri}/problems/get-notifications`, {
      method: 'POST',
      body: JSON.stringify({
        carrierName: carrierName,
        shipperName: shipperName,
        fromDate: fromDate,
        toDate: toDate,
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    })
  }
}
