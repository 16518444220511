import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react'
import {Button, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {SideNavLayout} from '../../../layouts';
import {TourService} from '../../../services/backend/tourService';
import {StopStatus} from '../../../services/enums/StopStatus';
import {
  LoadingIndicator,
  LoadingWrapper,
  Portlet,
  RefreshButton,
  StopDetails,
  StopTable,
  TextPlaceholderWrapper,
  TourAndStopMap,
  TourDetail,
  TourFilter,
  TourTable
} from '../../../components';
import {displayModes} from '../../../services/enums/displayModes';
import {
  stopMessageRelevantForTourFilter,
  tourMessageRelevantForFilter
} from '../../../services/util/signalRMessageHelper';
import {useStopHub} from '../../../hooks/useStopHub';
import {useTourHub} from '../../../hooks/useTourHub';
import useLoadTourByIdCache from '../../../hooks/useLoadTourByIdCache';
import useLoadStopByIdCache from '../../../hooks/useLoadStopByIdCache';
import {Warning} from '@material-ui/icons';
import useCarriersWithHubs from '../../../hooks/useCarriersWithHubs';
import {DatePickerDefaultValueContext, ProblemNotificationDialogContext} from '../../../context';
import useMicroHubData from '../../../hooks/useMicroHubData';
import useCollapse from 'react-collapsed';

function TourOverview(props) {
  const {classes, t} = props;
  const {fromDateDefault, toDateDefault, updateDefaultDate} = useContext(DatePickerDefaultValueContext);

  const [refresh, setRefresh] = useState(false);
  const [tours, setTours] = useState([]);
  const [notPlanedStops, setNotPlanedStops] = useState([]);
  const {selectedStop, loadingSelectedStop, resetLoadStopByIdCache, getStopById, resetSelectedStop} = useLoadStopByIdCache();
  const [tourFilter, setTourFilter] = useState({
    carrierName: '',
    shipperName: '',
    microHubName: '',
    tourIds: [],
    tourStatus: '',
    fromDateTime: new Date(fromDateDefault.getFullYear(), fromDateDefault.getMonth(), fromDateDefault.getDate(), 6, 0, 0),
    toDateTime: new Date(toDateDefault.getFullYear(), toDateDefault.getMonth(), toDateDefault.getDate(), 19, 0, 0)
  });
  useEffect(() => updateDefaultDate(tourFilter.fromDateTime), [tourFilter.fromDateTime, updateDefaultDate]);
  const [backendLoadingTours, setBackendLoadingTours] = useState(false);
  const [backendLoadingStops, setBackendLoadingStops] = useState(false);
  const [mapTabState, setMapTabState] = useState(undefined);
  const {carriersWithHubs} = useCarriersWithHubs();
  const {microHubData} = useMicroHubData();
  const {open: openProblemNotificationDialog} = useContext(ProblemNotificationDialogContext);

  const {selectedTour, loadingSelectedTour, resetLoadTourByIdCache, getTourById, resetSelectedTour} = useLoadTourByIdCache();

  const {getCollapseProps, getToggleProps, isOpen} = useCollapse({defaultOpen: false});

  const filterTours = (tours) => {
    let filtered = tours;
    if (tourFilter.tourIds && tourFilter.tourIds.length > 0) {
      filtered = filtered.filter(tour => tourFilter.tourIds.includes(tour.tourId));
    }
    if (tourFilter.tourStatus) {
      filtered = filtered.filter(tour => tour.tourStatus === tourFilter.tourStatus);
    }
    return filtered;
  };

  useStopHub(useCallback(event => {
    if (stopMessageRelevantForTourFilter(event, tourFilter)) {
      setRefresh(true);
    }
  }, [tourFilter]));

  useTourHub(useCallback(event => {
    if (tourMessageRelevantForFilter(event, tourFilter)) {
      setRefresh(true);
    }
  }, [tourFilter]));

  const loadData = useCallback(() => {
    setBackendLoadingTours(true);
    setBackendLoadingStops(true);
    resetLoadTourByIdCache();
    resetLoadStopByIdCache();
    TourService.getToursWithFilter(tourFilter.carrierName, tourFilter.shipperName, tourFilter.microHubName, tourFilter.fromDateTime, tourFilter.toDateTime)
      .then(response => response.json())
      .then(response => {
        setTours(response);
        const index = response.findIndex(stop => selectedTour && stop.tourId === selectedTour.tourId);
        if (index >= 0) {
          getTourById(selectedTour.tourId)
        } else {
          resetSelectedTour()
        }
        setBackendLoadingTours(false);
        setRefresh(false);
      }, () => {
        alert(t('errorLoadingTours'));
        setBackendLoadingTours(false);
        setRefresh(false);
      });
    TourService.getStopsWithFilter(null, null, null, StopStatus.NotPlaned, null, tourFilter.toDateTime)
      .then(response => response.json())
      .then(response => {
        setNotPlanedStops(response);
        const index = response.findIndex(stop => selectedStop && stop.tourStopId === selectedStop.tourStopId);
        if (index >= 0) {
          getStopById(selectedStop.tourStopId)
        } else {
          resetSelectedStop();
        }
        setBackendLoadingStops(false);
        setRefresh(false);
      }, () => {
        alert(t('errorLoadingStops'));
        setBackendLoadingStops(false);
        setRefresh(false);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourFilter, t]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const showDetails = (tour) => {
    getTourById(tour.tourId);
  };

  const showDetailsNotPlanedStops = (stop) => {
    getStopById(stop.tourStopId);
  };


  return (
    <SideNavLayout title={t('tourOverview')}>
      <div className={classes.root}>
        <Typography
          variant="h3"
        >
          {t('toursInSystem')}
        </Typography>
        <br/>
        <RefreshButton
          className={classes.buttonRight}
          refresh={refresh}
          refreshFunc={loadData}
        />
        <TourFilter
          carriersWithMicroHubs={carriersWithHubs}
          filter={tourFilter}
          setFilter={setTourFilter}
          tours={tours}
        />
        <LoadingWrapper loading={backendLoadingTours || backendLoadingStops}>
          <Fragment>
            <Fragment>
              <TourTable
                className={classes.content}
                selectedTour={selectedTour}
                showDetails={showDetails}
                tableDisplayMode={displayModes.admin}
                tours={filterTours(tours)}
              />
              <div className={classes.problemNotificationButtonContainer}>
                <Button
                  className={classes.problemNotificationButton}
                  onClick={() => openProblemNotificationDialog()}
                >
                  <Warning/>&nbsp;{t('sendProblemNotification')}
                </Button>
              </div>
              {loadingSelectedTour &&
                <Portlet className={classes.content}>
                  <LoadingIndicator/>
                </Portlet>
              }
              {selectedTour &&
                <TourDetail
                  className={classes.content}
                  closeTourDetail={() => resetSelectedTour()}
                  displayMode={displayModes.admin}
                  readOnly
                  tour={selectedTour}
                />
              }
            </Fragment>
            <Fragment>
              <div
                {...getToggleProps()}
                className={'noselect cursorPointer'}
              >
                <Typography
                  className={classes.notPlannedStopsDropDown}
                  variant="h3"
                >
                  {t('notPlanedStops')}
                  &nbsp;
                  ({notPlanedStops.length})
                  <i className="material-icons md-48">{isOpen ? 'expand_less' : 'expand_more'}</i>
                </Typography>
              </div>
              <div {...getCollapseProps()}>
                <TextPlaceholderWrapper
                  active={!notPlanedStops?.length}
                  text={t('noNotPlanedStopsFound')}
                >
                  <Fragment>
                    <StopTable
                      className={classes.content}
                      selectedStop={selectedStop}
                      showDetails={showDetailsNotPlanedStops}
                      stops={notPlanedStops}
                      tableDisplayMode={displayModes.admin}
                    />
                    {loadingSelectedStop &&
                      <Portlet className={classes.content}>
                        <LoadingIndicator/>
                      </Portlet>
                    }
                    {selectedStop &&
                      <StopDetails
                        className={classes.content}
                        closeDetailView={() => resetSelectedStop()}
                        readOnly
                        stop={selectedStop}
                      />
                    }
                  </Fragment>
                </TextPlaceholderWrapper>
              </div>
            </Fragment>
            <Fragment>
              <Typography
                variant="h3"
              >
                {t('mapOverview')}
              </Typography>
              <TourAndStopMap
                className={classes.tourMap}
                microHubs={microHubData}
                notPlanedStops={notPlanedStops && notPlanedStops.length > 0 ? notPlanedStops : []}
                persistedTabState={mapTabState}
                setPersistedTabState={setMapTabState}
                tours={filterTours(tours)}
              />
            </Fragment>
          </Fragment>
        </LoadingWrapper>
      </div>
    </SideNavLayout>
  );
}

TourOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(TourOverview);
