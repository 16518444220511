import {useState} from 'react';
import {TourService} from '../services/backend/tourService';
import {useTranslation} from 'react-i18next';

export default function useLoadStopByIdCache() {
  const [loadingSelectedStop, setLoadingSelectedStop] = useState(false);
  const [selectedStop, setSelectedStop] = useState(null);
  const [cache, setCache] = useState({});

  const {t} = useTranslation();

  const getStopById = async (tourStopId) => {
    resetSelectedStop();
    if (cache[tourStopId.toString()]) {
      setSelectedStop(cache[tourStopId.toString()]);
    } else {
      setLoadingSelectedStop(true);
      try {
        const response = await TourService.getStopById(tourStopId);
        setSelectedStop(response);
        setCache({...cache, [response.tourStopId.toString()]: response});
        setLoadingSelectedStop(false);
      } catch {
        setLoadingSelectedStop(false);
        alert(t('errorLoadingStop'))
      }
    }
  };

  const resetLoadStopByIdCache = () => {
    setCache({});
  };

  const resetSelectedStop = () => {
    setSelectedStop(null);
  };

  const updateCache = (Stop) => {
    setCache({...cache, [Stop.tourStopId.toString()]: Stop});
    if (selectedStop && selectedStop.tourStopId === Stop.tourStopId) {
      setSelectedStop(Stop);
    }
  };

  const updateMultiple = (Stops) => {
    const newCache = JSON.parse(JSON.stringify(cache));
    Stops.forEach(Stop => {
      newCache[Stop.tourStopId.toString()] = Stop;
      if (selectedStop && selectedStop.tourStopId === Stop.tourStopId) {
        setSelectedStop(Stop);
      }
    });
    setCache(newCache);
  };

  return {selectedStop, loadingSelectedStop, getStopById, resetLoadStopByIdCache, resetSelectedStop, updateCache, updateMultiple};
}
