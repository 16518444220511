export default theme => ({
  root: {
    border: '1px solid lightgrey',
    borderRadius: '3px'
  },
  tableSettings: {
    position: 'absolute',
    left: 0,
    top: theme.spacing(-4),
  },
  tableHeader: {
    backgroundColor: theme.palette.common.white,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: 0
  },
  tableRow: {
    backgroundColor: theme.palette.common.white,
    cursor: 'pointer',
  },
  popoverPaper: {
    borderRadius: '50%'
  },
  toggleButton: {
    float: 'right'
  }
});
