import React from 'react';
import {Doughnut} from 'react-chartjs-2';
import PropTypes from 'prop-types';
import palette from '../../../../../theme/palette';
import {withTranslation} from 'react-i18next';
import {deliveredIconColor, inDeliveryIconColor, planedIconColor} from '../../../../../services/icon/iconService';
import {options} from '../chart';
import {getTourStatusSting, TourStatus} from '../../../../../services/enums/TourStatus';
import {TextPlaceholderWrapper} from '../../../../index';


function TourDoughnut(props) {

  const {tours, t, className} = props;

  const planed = tours.filter(tour => tour.tourStatus === TourStatus.Planed);
  const inDelivery = tours.filter(tour => tour.tourStatus === TourStatus.InDelivery);
  const delivered = tours.filter(tour => tour.tourStatus === TourStatus.Delivered);

  const data = {
    datasets: [
      {
        data: [
          planed.length,
          inDelivery.length,
          delivered.length,
        ],
        backgroundColor: [
          planedIconColor,
          inDeliveryIconColor,
          deliveredIconColor,
        ],
        hoverBorderColor: palette.common.white,
        borderColor: palette.common.white
      }
    ],
    labels: [
      t(getTourStatusSting(TourStatus.Planed)),
      t(getTourStatusSting(TourStatus.InDelivery)),
      t(getTourStatusSting(TourStatus.Delivered)),
    ]
  };


  return (
    <div className={className}>
      <TextPlaceholderWrapper
        active={!tours?.length}
        text={t('noToursAvailableDashboard')}
      >
        <Doughnut
          data={data}
          options={options}
        />
      </TextPlaceholderWrapper>
    </div>
  );
}

TourDoughnut.propTypes = {
  className: PropTypes.string,
  tours: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(TourDoughnut);
