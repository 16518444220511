export const VehicleTypes = {
  Car: 'Car',
  Truck: 'Truck',
  Bicycle: 'Bicycle',
};

export class VehicleTypeEnumHelperService {
  static getVehicleTypeString(vehicleType) {
    switch (vehicleType.toLowerCase()) {
      case VehicleTypes.Car.toLowerCase():
        return 'vehicleTypeCar';
      case VehicleTypes.Truck.toLowerCase():
        return 'vehicleTypeTruck';
      case VehicleTypes.Bicycle.toLowerCase():
        return 'vehicleTypeBicycle';
      default:
        return 'vehicleTypeUndefined'
    }
  }
}
