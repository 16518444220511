import React, {useState} from 'react'
import {Chip, IconButton, Paper, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {AddCircle as AddCircleIcon} from '@material-ui/icons';
import classNames from 'classnames';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {DndHelperElementTypes, DndHelperService} from '../../../../services/dndHelper/dndHelperService';
import {default as EmptiesDialog} from './EmptiesDialog';

function EditShipper(props) {
  const {classes, t, empties, setEmpties} = props;

  const [emptiesDialogState, setEmptiesDialogState] = useState({
    open: false,
    empty: '',
  });


  const onDragEnd = (event) => {
    if (!event.destination) {
      return;
    }
    if (event.destination.index === event.source.index) {
      return;
    }
    setEmpties(DndHelperService.reorder(empties, event.source.index, event.destination.index));
  }

  return (
    <React.Fragment>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          direction={'vertical'}
          droppableId={'empties'}
          type={DndHelperElementTypes.Empty}
        >
          {(provided) => (
            <Paper
              className={classNames(classes.paper, classes.dndContainer)}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {empties.map((data, i) => (
                <Draggable
                  draggableId={`${data.id}${data.name}`}
                  index={i}
                  key={`${data.id}${data.name}`}
                >
                  {(providedDraggable) => (
                    <li
                      className={classes.draggableItem}
                      key={`${data.id}${data.name}`}
                    >
                      <Chip
                        className={classes.chip}
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                        label={data.name}
                        onDelete={() => {
                          setEmpties(empties.filter((_, k) => k !== i));
                        }}
                      />
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <IconButton
                aria-label={t('addEmpty')}
                className={classes.iconButton}
                color="primary"
                label={t('addEmpty')}
                onClick={() => {
                  setEmptiesDialogState({...emptiesDialogState, open: true});
                }}
              >
                <AddCircleIcon/>
              </IconButton>
            </Paper>
          )}
        </Droppable>
      </DragDropContext>
      <EmptiesDialog
        empties={empties}
        emptiesDialogState={emptiesDialogState}
        setEmpties={setEmpties}
        setEmptiesDialogState={setEmptiesDialogState}
      />
    </React.Fragment>
  );
}


EditShipper.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  empties: PropTypes.array.isRequired,
  setEmpties: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(EditShipper);
