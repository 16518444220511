export const TemplateType = {
  TourTemplate: 1,
  AutoCutTemplate: 2,
};

export function getTemplateTypeString(templateType) {
  switch (templateType) {
    case TemplateType.TourTemplate:
      return 'tourTypeTemplate';
    case TemplateType.AutoCutTemplate:
      return 'tourTypeAutoCutTemplate';
    default:
      return 'templateTypeUnknown';
  }
}

export function getTemplateTypesAsOptions(t) {
  return Object.values(TemplateType).map(type => ({
    value: type,
    name: t(getTemplateTypeString(type))
  }))
}
