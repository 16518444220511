import axios from 'axios';
import {AuthService} from '../auth/authService';

const baseUri = `${process.env.REACT_APP_API_URI}Microhub/measurement`;

const getMicroHubMeasurements = async (microHubName, dateTimeFrom, dateTimeTo) => {
    const response = await axios.post(`${baseUri}/by-microhub`, 
        {
                microHubName: microHubName,
                dateTimeFrom: new Date(dateTimeFrom.getTime() - (dateTimeFrom.getTimezoneOffset() * 60000)),
                dateTimeTo: new Date(dateTimeTo.getTime() - (dateTimeTo.getTimezoneOffset() * 60000)),
        },
        {
            headers: {
                'Content-Type': 'application/json',
                authorization: AuthService.getAuthHeader()
            },
        });
    if (response.status === 204) return null;
    return response.data;
};

const getLastMicroHubMeasurementForMicroHubs = async (microHubNames) => {
    const response = await axios.post(`${baseUri}/last-by-microhubs`,
        {
                microHubNames: microHubNames,
        },
        {
            headers: {
                'Content-Type': 'application/json',
                authorization: AuthService.getAuthHeader()
            },
        });
    if (response.status === 204) return null;
    return response.data;
};

const getMicroHubSensorConfig = async (carrierName) => {
    const response = await axios.get(`${baseUri}/sensor/${carrierName}`,
        {
            headers: {
                'Content-Type': 'application/json',
                authorization: AuthService.getAuthHeader()
            },
        });
    if (response.status === 204) return null;
    return response.data;
};

const deleteMicroHubSensorConfig = async (sensorId, microHubName) => {
    const response = await axios.delete(`${baseUri}/sensor/${sensorId}`, {
        data: {
            sensorId: sensorId,
            microHubName: microHubName
        },
        headers: {
            'Content-Type': 'application/json',
            authorization: AuthService.getAuthHeader()
        },
    });
    if (response.status === 204) return null;
    return response.data;
};

const createMicroHubSensorConfig = async (sensorId, microHubName) => {
    const response = await axios.post(`${baseUri}/sensor`, {
            sensorId: sensorId,
            microHubName: microHubName
        }, {
            headers: {
                'Content-Type': 'application/json',
                authorization: AuthService.getAuthHeader()
            }});
    if (response.status === 204) return null;
    return response.data;
};

const updateMicroHubSensorConfig = async (sensorId, microHubName, id) => {
    const response = await axios.put(`${baseUri}/sensor/${sensorId}`, {
        sensorId: sensorId,
        microHubName: microHubName,
        id: id
    }, {
        headers: {
            'Content-Type': 'application/json',
            authorization: AuthService.getAuthHeader()
        }});
    if (response.status === 204) return null;
    return response.data;
};

export default{
    getMicroHubMeasurements,
    getLastMicroHubMeasurementForMicroHubs,
    getMicroHubSensorConfig,
    deleteMicroHubSensorConfig,
    createMicroHubSensorConfig,
    updateMicroHubSensorConfig
}