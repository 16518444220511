import {templateStopDeliveryIconColor, templateStopPickupIconColor,} from '../icon/iconService';

export const TemplateStopType = {
  Pickup: 0,
  Delivery: 1,
};

export function getTemplateStopTypeString(status) {
  switch (status) {
    case TemplateStopType.Pickup:
      return 'templateStopPickup';
    case TemplateStopType.Delivery:
      return 'templateStopDelivery';
    default:
      return 'unknownTemplateStopType'
  }
}

export function getColorByType(type) {
  switch (type) {
    case TemplateStopType.Pickup:
      return templateStopPickupIconColor;
    case TemplateStopType.Delivery:
      return templateStopDeliveryIconColor;
    default:
      return templateStopPickupIconColor
  }
}