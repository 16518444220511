import React from 'react'
import {Button} from '@material-ui/core';
import {DeleteForever as DeleteIcon,} from '@material-ui/icons';

const Columns = {
  sensorId: {
    name: 'sensorId',
    header: ({t}) => t('sensorId'),
    body: ({microHubSensorConfig}) => microHubSensorConfig.sensorId
  },
  microHubName: {
    name: 'microHubName',
    header: ({t}) => t('microHubName'),
    body: ({microHubSensorConfig}) => microHubSensorConfig.microHubName
  },
  deleteMicroHubSensorConfig: {
    name: 'deleteMicroHubSensorConfig',
    header: ({t}) => t('deleteMicroHubSensorConfig'),
    body: ({microHubSensorConfig, t, classes, deleteMicroHubSensorConfig}) => (
      <Button
        className={classes.deleteColor}
        color="primary"
        onClick={(event) => {
          if (window.confirm(t('confirmDeleteMicroHubSensorConfig'))) deleteMicroHubSensorConfig(microHubSensorConfig, event)
        }}
        style={{padding: 0}}
        title={t('deleteMicroHubSensorConfig')}
      >
        <DeleteIcon/>
      </Button>
    )
  },
}

export default Columns;