import React, {Fragment} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import {InfoIcon, Portlet, PortletContent} from '../../../../index';


function StopBonusView(props) {
  const {classes, className, t, stopBonus} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet className={rootClassName}>
      <PortletContent>
        <div className={classes.iconWrapper}>
          <Typography variant="h3">
            {t('boxBonus')}
          </Typography>
          <InfoIcon
            className={classes.iconMargin}
            descriptionKey={'60'}
          />
        </div>
        <hr/>
        <Typography variant="body1">
          <strong>{t('boxBonusEnabled')}</strong>: {stopBonus.enabled ? t('yes') : t('no')}<br/>
          {stopBonus.enabled &&
          <Fragment>
            <strong>{t('boxBonusThreshold')}</strong>: {stopBonus.threshold}<br/>
            <strong>{t('boxBonusDivider')}</strong>: {stopBonus.divider}
          </Fragment>}
        </Typography>
      </PortletContent>
    </Portlet>
  );
}

StopBonusView.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  stopBonus: PropTypes.shape({enabled: PropTypes.bool.isRequired, threshold: PropTypes.number.isRequired, divider: PropTypes.number.isRequired}).isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(StopBonusView);
