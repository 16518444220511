import React from 'react'
import { FormControl, Input, InputLabel, Typography, withStyles } from '@material-ui/core';
import styles from './styles'
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Portlet, PortletContent } from 'components';

function CreateAddress(props) {
  const { t, classes, className, address, setAddress, title, required } = props;

  const rootClassName = classNames(classes.root, className);
  return (
    <Portlet className={rootClassName}>
      <PortletContent>
        <Typography variant="h3">
          {title}
        </Typography>
        <hr />
        <FormControl className={classes.formControl}>
          <InputLabel
            required={required}
          >{t('streetAndNumber')}</InputLabel>
          <Input
            error={required && !address.streetAndNumber}
            onChange={(event) => {
              setAddress({ ...address, streetAndNumber: event.target.value })
            }}
            required={required}
            value={address.streetAndNumber}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel
            required={required}
          >{t('city')}</InputLabel>
          <Input
            error={required && !address.city}
            onChange={(event) => {
              setAddress({ ...address, city: event.target.value })
            }}
            required={required}
            value={address.city}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel
            required={required}
          >{t('zipcode')}</InputLabel>
          <Input
            error={required && !address.zipcode}
            onChange={(event) => {
              setAddress({ ...address, zipcode: event.target.value })
            }}
            required={required}
            value={address.zipcode}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel
            required={required}
          >{t('country')}</InputLabel>
          <Input
            error={required && !address.country}
            onChange={(event) => {
              setAddress({ ...address, country: event.target.value })
            }}
            required={required}
            value={address.country}
          />
        </FormControl>
      </PortletContent>
    </Portlet>
  );
}

CreateAddress.propTypes = {
  address: PropTypes.object.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  required: PropTypes.bool,
  setAddress: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};


export default compose(withStyles(styles), withTranslation())(CreateAddress);
