import React from 'react'
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {getTrackingStepTypeString, TrackingStepType} from '../../../../../services/enums/TrackingStepType';
import {InfoIcon, Portlet, PortletContent} from '../../../../index';

function TrackingNotificationView(props) {
  const {className, t, trackingNotifications} = props;


  return (
    <Portlet className={className}>
      <PortletContent>
        <Typography variant="h3">
          {t('trackingNotifications')}
        </Typography>
        <hr/>
        {trackingNotifications ?
          <Typography variant="body1">
            <strong>{t('emailNotificationsDefaultValue')}</strong>&nbsp;<InfoIcon
            descriptionKey={'50'}
            style={{marginBottom: '-5px'}}
          />: &nbsp;{trackingNotifications.emailNotificationsDefaultValue ? t('yes') : t('no')}<br/>
            <strong>{t('trackingShipperSenderEmail')}</strong>: &nbsp;{trackingNotifications.senderEmail}<br/>
            <strong>{t(getTrackingStepTypeString(TrackingStepType.PreviewStopUploaded))}</strong>: &nbsp;{trackingNotifications.previewStopUploadedNotifications ? t('yes') : t('no')}<br/>
            <strong>{t(getTrackingStepTypeString(TrackingStepType.PreviewDeadlineExpired))}</strong>: &nbsp;{trackingNotifications.previewDeadlineExpiredNotifications ? t('yes') : t('no')}<br/>
            <strong>{t(getTrackingStepTypeString(TrackingStepType.FinalDeadlineExpired))}</strong>: &nbsp;{trackingNotifications.finalDeadlineExpiredNotifications ? t('yes') : t('no')}<br/>
            <strong>{t(getTrackingStepTypeString(TrackingStepType.TourApproved))}</strong>: &nbsp;{trackingNotifications.tourApprovedNotifications ? t('yes') : t('no')}<br/>
            <strong>{t(getTrackingStepTypeString(TrackingStepType.TourStarted))}</strong>: &nbsp;{trackingNotifications.tourStartedNotifications ? t('yes') : t('no')}<br/>
            <strong>{t(getTrackingStepTypeString(TrackingStepType.PreviousStopsDelivered))}</strong>: &nbsp;{trackingNotifications.previousStopsDeliveredNotifications ? t('yes') : t('no')}<br/>
            <strong>{t(getTrackingStepTypeString(TrackingStepType.Delivered))}</strong>: &nbsp;{trackingNotifications.deliveredNotifications ? t('yes') : t('no')}<br/>
            <strong>{t(getTrackingStepTypeString(TrackingStepType.Problem))}</strong>: &nbsp;{trackingNotifications.problemNotifications ? t('yes') : t('no')}<br/>
            <strong>{t('problemNotificationsForShipper')}</strong>: &nbsp;{trackingNotifications.problemNotificationsForShipper ? t('yes') : t('no')}<br/>
            <strong>{t('problemNotificationText')}</strong>: &nbsp;{trackingNotifications.problemNotificationText}
          </Typography>
          :
          <Typography variant="body1">-</Typography>
        }
      </PortletContent>
    </Portlet>
  );
}

TrackingNotificationView.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
  trackingNotifications: PropTypes.object.isRequired,
};


export default withTranslation()(TrackingNotificationView);
