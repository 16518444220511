import {getDayOfWeekFromDate} from '../enums/dayOfWeekHelper';

export class ShipperAllowedOnHubService {
  static checkShipperAllowedStop(microHub, stop, dateSelector = (stop) => stop.deliveryDate) {
    const carrier = microHub ? microHub.carriers.find(c => c.carrier.name === stop.assignedCarrierName) : null;
    const shipperList = carrier ? carrier.weekDayShipperLists.find(x => x.weekDay === getDayOfWeekFromDate(new Date(dateSelector(stop)))) : null;
    return shipperList ? shipperList.microHubIsOpen && shipperList.allowList === shipperList.shippers.map(x => x.name).includes(stop.shipperName) : true;
  }

  static checkShipperAllowedTour (microHub, tour) {
    const carrier = microHub ? microHub.carriers.find(c => c.carrier.name === tour.carrierName) : null;
    const shipperList = carrier ? carrier.weekDayShipperLists.find(x => x.weekDay === getDayOfWeekFromDate(new Date(tour.deliveryDate))) : null;
    return shipperList ? shipperList.microHubIsOpen && tour.stops.every(stop => shipperList.allowList === shipperList.shippers.map(x => x.name).includes(stop.shipperName)) : true;
  }

  static checkShipperAllowedManual(microHub, carrierName, date, shipperName) {
    const carrier = microHub ? microHub.carriers.find(c => c.carrier.name === carrierName) : null;
    const shipperList = carrier ? carrier.weekDayShipperLists.find(x => x.weekDay === getDayOfWeekFromDate(date)) : null;
    return shipperList ? shipperList.microHubIsOpen && shipperList.allowList === shipperList.shippers.map(x => x.name).includes(shipperName) : true;
  }

  static checkShipperAllowedAnyDateIgnoreOpen(microHub, carrierName, shipperName) {
    const carrier = microHub ? microHub.carriers.find(c => c.carrier.name === carrierName) : null;
    return carrier ? carrier.weekDayShipperLists.some(shipperList => shipperList.allowList === shipperList.shippers.map(x => x.name).includes(shipperName)) : true;
  }

  static checkShipperAllowedDayString(microHub, carrierName, day, shipperName) {
    const carrier = microHub ? microHub.carriers.find(c => c.carrier.name === carrierName) : null;
    const shipperList = carrier ? carrier.weekDayShipperLists.find(x => x.weekDay === day) : null;
    return shipperList ? shipperList.microHubIsOpen && shipperList.allowList === shipperList.shippers.map(x => x.name).includes(shipperName) : true;
  }
  
  static checkShipperAllowedDayStringIgnoreOpen(microHub, carrierName, day, shipperName) {
    const carrier = microHub ? microHub.carriers.find(c => c.carrier.name === carrierName) : null;
    const shipperList = carrier ? carrier.weekDayShipperLists.find(x => x.weekDay === day) : null;
    return shipperList ? shipperList.allowList === shipperList.shippers.map(x => x.name).includes(shipperName) : true;
  }
}
