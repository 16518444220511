export default theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '3px'
  },
  unassignedStopsTable: {
    border: `1px solid ${theme.palette.border}`
  },
  headerSection: {
    flexBasis: '15%',
    flexShrink: 0,
    overflowX: 'hidden'
  },
  map: {
    minWidth: '100%',
    minHeight: 'calc(-195px + 100vh)',
    border: '1px solid lightgrey',
    borderRadius: '3px'
  },
});

