import React, {Fragment, useState} from 'react'
import {Box, Collapse, IconButton, TableCell, TableRow, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Delete as DeleteIcon,
  DragIndicatorOutlined as DragIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon
} from '@material-ui/icons';
import {DateService} from '../../../../../../../services/util/DateService';
import {Draggable} from 'react-beautiful-dnd';
import {DndHelperService} from '../../../../../../../services/dndHelper/dndHelperService';
import {roundCapacityValues} from '../../../../../../../services/util/helperFuncs';
import StopTableDetails from '../../../../../StopTableDetails';
import {PreviewStopIdLabel, ShipperLabel} from '../../../../../../index';


function TourStopTableRowSmallPreview(props) {

  const {classes, t, stop, className, index, uniqueTourId, deleteStopFromTour, capacityMode} = props;
  const [open, setOpen] = useState(false);

  const rootClassName = classNames(className, classes.root);

  return (
    <Draggable
      draggableId={DndHelperService.getUniqueStopId(uniqueTourId, stop.id)}
      index={index}
      isDragDisabled={!stop.editable}
    >
      {(provided, snapshot) => (
        <Fragment>
          <TableRow
            className={classNames(rootClassName, (snapshot.isDragging ? classes.dragging : (stop.editable ? '' : classes.rowDisabled)))}
            hover
            selected={open}
            {...provided.draggableProps}
            ref={provided.innerRef}
          >
            <TableCell className={classNames(classes.alignLeft, classes.firstTableCell)}>
              <Fragment>
                <div
                  {...provided.dragHandleProps}
                  className={classes.dragIcon}
                >
                  <DragIcon style={stop.editable ? {} : {visibility: 'hidden'}}/>
                </div>
                {snapshot.isDragging
                  ?
                  <Typography variant={'body1'}>
                    <strong>{t('stopNumber')}:</strong> {stop.stopNumber + ' | '}
                    <strong>{t('tourStopId')}:</strong> {stop.id + ' | '}
                    <strong>{t('boxAmount')}:</strong> {roundCapacityValues(stop.stopCapacities.boxAmount) + ' | '}
                    <strong>{t('volume')}:</strong> {roundCapacityValues(stop.stopCapacities.weight) + ' | '}
                    <strong>{t('weight')}:</strong> {roundCapacityValues(stop.stopCapacities.volume)}
                  </Typography>
                  :
                  stop.stopNumber
                }
              </Fragment>
            </TableCell>
            {!snapshot.isDragging &&
            <Fragment>
              {!capacityMode
                ?
                <Fragment>
                  <TableCell className={classes.alignLeft}><PreviewStopIdLabel stop={stop}/></TableCell>
                  <TableCell className={classes.alignLeft}><ShipperLabel stop={stop}/></TableCell>
                  <TableCell className={classes.alignLeft}>{stop.assignedMicroHubName}</TableCell>
                  <TableCell className={classes.alignLeft}>{DateService.displayDate(stop.deliveryDate)}</TableCell>
                </Fragment>
                :
                <Fragment>
                  <TableCell className={classes.alignLeft}>{stop.address.streetAndNumber}</TableCell>
                  <TableCell className={classes.alignLeft}>{roundCapacityValues(stop.stopCapacities.boxAmount)}</TableCell>
                  <TableCell className={classes.alignLeft}>{roundCapacityValues(stop.stopCapacities.weight)}</TableCell>
                  <TableCell className={classes.alignLeft}>{roundCapacityValues(stop.stopCapacities.volume)}</TableCell>
                </Fragment>
              }
              <TableCell className={classNames(classes.alignLeft, classes.lastTableCell)}>
                <IconButton
                  className={classes.buttonLeft}
                  onClick={() => deleteStopFromTour(stop.id)}
                  size="small"
                  title={t('deleteStopFromTour')}
                >
                  <DeleteIcon/>
                </IconButton>
                <IconButton
                  className={classes.buttonRight}
                  onClick={() => setOpen(!open)}
                  size="small"
                >
                  {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                </IconButton>
              </TableCell>
            </Fragment>
            }
          </TableRow>
          <TableRow style={snapshot.isDragging ? {display: 'none'} : {}}>
            <TableCell
              className={classes.largeTableCell}
              colSpan={8}
            >
              <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
              >
                <Box margin={1}>
                  <StopTableDetails
                    stop={stop}
                  />
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </Fragment>
      )}
    </Draggable>
  );
}

TourStopTableRowSmallPreview.propTypes = {
  capacityMode: PropTypes.bool.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deleteStopFromTour: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  stop: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  uniqueTourId: PropTypes.string.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TourStopTableRowSmallPreview);
