import React, {useEffect, useState} from 'react'
import {ButtonGroup, Checkbox, FormControlLabel, Grid, Typography, withStyles,} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {TextPlaceholderWrapper, TourMap, VehicleSelectDialog} from '../../../../../components';
import ShipperTabs from './ShipperTabs';
import Button from '@material-ui/core/Button';
import {Cancel as CancelIcon, Save as SaveIcon, WarningRounded as WarnIcon} from '@material-ui/icons';
import FluentTourTable from './FluentTourTable';
import {cloneDeep, sortBy, sortedUniqBy, uniqBy} from 'lodash';
import {DndHelperElementTypes} from '../../../../../services/dndHelper/dndHelperService';
import {
  buildIndexToElementMap,
  getToursWithStopsAfterStopDrop,
  getToursWithStopsAfterTourAdd,
  getToursWithStopsAfterTourDrop,
  getTourWithStopsOfDeletedTour,
  isDropAllowedForStop,
  isDropAllowedForTour,
} from '../../../../../services/util/fluidTourTableDndUtils';
import {getRandomColor, scrollToElement} from '../../../../../services/util/helperFuncs';
import {TourType} from '../../../../../services/enums/TourType';
import {AuthService} from '../../../../../services/auth/authService';


function MapDispositionOptimization(props) {
  const {
    classes,
    className,
    t,
    tours,
    microHubs,
    updateTours,
    vehicles,
    filter,
    selectedShipperStorage,
    setSelectedShipperStorage,
    tourTemplateFilter,
  } = props;

  const [edited, setEdited] = useState(false);
  const [toursWithShipper, setToursWithShipper] = useState([]);
  const [selectedShipperOption, setSelectedShipperOption] = useState(null);
  const [shipperOptions, setShipperOptions] = useState([]);
  const [vehicleSelectDialogState, setVehicleSelectDialogState] = useState({open: false, tour: null, tourCapacities: null});
  const [displayPreviewOrderOnMap, setDisplayPreviewOrderOnMap] = useState(false);
  const [mixToursPresent, setMixToursPresent] = useState(false);

  const rootClassName = classNames(classes.root, className);

  const filterToursForShipperAndTourTemplate = (tours) => {
    // filter for selected shipper
    let filteredTours = selectedShipperOption ? tours.filter(t => t.shipperName === selectedShipperOption.shipperName) : [];
    // filter for selected tourTemplate
    filteredTours = tourTemplateFilter ? filteredTours.filter(t => t.tourMetaData?.tourTemplateId === tourTemplateFilter) : filteredTours;
    return filteredTours;
  };

  const toursToDisplay = filterToursForShipperAndTourTemplate(toursWithShipper);

  useEffect(() => {
    // refresh internal states with latest data
    if (!edited) {
      const filteredTours = tours.filter(t => t.shipperName);
      filteredTours.forEach(t => {
        t.stops = t.stops.sort((a, b) => b.stopNumber - a.stopNumber);
      })
      setMixToursPresent(tours?.length !== filteredTours.length);
      setToursWithShipper(filteredTours);
      const options = sortedUniqBy(sortBy(filteredTours, t => t.shipperName), t => t.shipperName).map((t, index) => ({
        shipperName: t.shipperName,
        tabIndex: index,
        shipperShortName: t.shipperShortName
      }));
      setShipperOptions(options);

      setSelectedShipperOption(() => {
        if (!options?.length) return null;
        const indexOfLastSelectedOption = options.findIndex(o => o.shipperName === selectedShipperStorage);
        return indexOfLastSelectedOption > 0 ? options[indexOfLastSelectedOption] : options[0];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tours, edited]);


  const onSave = () => {
    updateTours(toursWithShipper);
    setEdited(false);
  };

  const onCancel = () => {
    setEdited(false);
  }

  const getUpdatedToursByTourIdsWithStops = (tourIdsWithStops, toursToUpdate) => {
    const newTours = cloneDeep(toursToUpdate);
    // update affected tours
    tourIdsWithStops.forEach(t => {
      const indexOfTour = newTours.findIndex(x => x.tourId === t.id);
      if (indexOfTour < 0) return;
      // update tour meta data if still stops on tour
      const stopMetaDataOnTour = uniqBy(t.stops.map(s => s.stopMetaData).filter(s => s), m => m.tourTemplateId);
      newTours[indexOfTour].tourMetaData.tourType = TourType.Manual;
      newTours[indexOfTour].tourMetaData.lastModified = new Date();
      newTours[indexOfTour].tourMetaData.modifiedBy = AuthService.getUserEmail();
      newTours[indexOfTour].tourMetaData.tourTemplateId = stopMetaDataOnTour.length === 1 ? stopMetaDataOnTour[0].tourTemplateId : null;
      newTours[indexOfTour].tourMetaData.tourTemplateName = stopMetaDataOnTour.length === 1 ? stopMetaDataOnTour[0].tourTemplateName : null;
      // update stop order
      let stopNumber = t.stops.length;
      t.stops.forEach(s => {
        s.stopNumber = stopNumber;
        stopNumber--;
      });
      newTours[indexOfTour].stops = t.stops;
    })
    return newTours;
  }

  const onDragEnd = (event) => {
    if (!event.destination) {
      return;
    }

    if (event.destination.index === event.source.index) {
      return;
    }

    const destinationIndex = event.destination.index;
    const sourceIndex = event.source.index;
    const element = JSON.parse(event.draggableId);
    const map = buildIndexToElementMap(toursToDisplay);

    if (element.type === DndHelperElementTypes.Tour) {
      if (!isDropAllowedForTour(map, sourceIndex, destinationIndex)) return;
      const tourIdsWithStopsToUpdate = getToursWithStopsAfterTourDrop(map, sourceIndex, destinationIndex);
      const updatedTours = getUpdatedToursByTourIdsWithStops(tourIdsWithStopsToUpdate, toursWithShipper);
      setEdited(true);
      setToursWithShipper(updatedTours);
      return;
    }

    if (element.type === DndHelperElementTypes.Stop) {
      if (!isDropAllowedForStop(map, sourceIndex, destinationIndex)) return;
      const tourIdsWithStopsToUpdate = getToursWithStopsAfterStopDrop(map, sourceIndex, destinationIndex);
      const updatedTours = getUpdatedToursByTourIdsWithStops(tourIdsWithStopsToUpdate, toursWithShipper);
      setEdited(true);
      setToursWithShipper(updatedTours);
      return;
    }

    console.warn('Unknown element type encountered during dragging in FluidTourTable: ', element);
  }

  const selectVehicle = (vehicle) => {
    const newTours = cloneDeep(toursWithShipper);
    const tourIndex = newTours.findIndex(t => t.tourId === vehicleSelectDialogState.tour.tourId);
    if (tourIndex >= 0) {
      newTours[tourIndex].vehicleLicensePlate = vehicle.licensePlate;
      setToursWithShipper(newTours);
      setEdited(true);
    }
    setVehicleSelectDialogState({open: false, tour: null, tourCapacities: null});
  }

  const deleteTour = (tour, index) => {
    // update previous tour with new stops
    const map = buildIndexToElementMap(toursToDisplay);
    const tourIdsWithStopsToUpdate = getTourWithStopsOfDeletedTour(map, index);
    const updatedTours = getUpdatedToursByTourIdsWithStops(tourIdsWithStopsToUpdate, toursWithShipper);
    // remove deleted tour
    const tourIndex = updatedTours.findIndex(t => t.tourId === tour.tourId);
    if (tourIndex >= 0) updatedTours.splice(tourIndex, 1);
    setEdited(true);
    setToursWithShipper(updatedTours);
  };

  const editTourName = (tourId, tourName) => {
    const newTours = cloneDeep(toursWithShipper);
    const tourIndex = newTours.findIndex(t => t.tourId === tourId);
    if (tourIndex < 0) return;
    newTours[tourIndex].tourName = tourName;
    setEdited(true);
    setToursWithShipper(newTours);
  }

  const addTour = (index, shipperName) => {
    // create new tour
    const newTourId = Math.min(...toursWithShipper.map(t => t.tourId), 0) - 1;
    const newTour = {
      tourId: newTourId,
      tourName: `${t('newTourName')}${newTourId}`,
      vehicleLicensePlate: null,
      stops: [],
      shipperName: shipperName,
      carrierName: filter.carrierName,
      microHubName: filter.microHubName,
      color: getRandomColor()
    }

    // calculate stop updates
    const map = buildIndexToElementMap(toursToDisplay);
    const tourIdsWithStopsToUpdate = getToursWithStopsAfterTourAdd(map, index, newTour.tourId);

    // add new tour at right position
    const previousTourId = tourIdsWithStopsToUpdate.map(t => t.id).filter(id => id !== newTour.tourId)[0];
    const newTours = [...toursWithShipper];
    const tourIndex = newTours.findIndex(t => t.tourId === previousTourId);
    if (tourIndex < 0) return;
    // set metadata of new tour
    newTour.tourMetaData = {
      tourType: TourType.Manual,
      lastModified: new Date(),
      modifiedBy: AuthService.getUserEmail(),
      tourTemplateName: newTours[tourIndex].tourMetadata?.tourTemplateName,
      tourTemplateId: newTours[tourIndex].tourMetaData?.tourTemplateId
    }
    // add ne tour to tours
    newTours.splice(tourIndex + 1, 0, newTour);

    // update stops of affected tours
    const updatedTours = getUpdatedToursByTourIdsWithStops(tourIdsWithStopsToUpdate, newTours);
    setEdited(true);
    setToursWithShipper(updatedTours);
  }


  return (
    <div className={rootClassName}>
      <div className={classes.controlContainer}>
        <ShipperTabs
          options={shipperOptions}
          setValue={value => {
            setSelectedShipperOption(value);
            setSelectedShipperStorage(value.shipperName);
          }}
          value={selectedShipperOption}
        />
        {mixToursPresent &&
        <Typography>
          <WarnIcon className={classes.warningColor}/><strong>&nbsp;{t('mixToursPresent')}</strong>
        </Typography>
        }
        <ButtonGroup
          color="primary"
          disabled={!edited}
          variant="contained"
        >
          <Button
            className={classes.buttonGroupMember}
            color="default"
            onClick={onCancel}
          >
            <CancelIcon className={classes.controlButtonIcon}/>&nbsp;{t('cancel')}
          </Button>
          <Button
            className={classes.buttonGroupMember}
            onClick={onSave}
          >
            <SaveIcon className={classes.controlButtonIcon}/>&nbsp;{t('save')}
          </Button>
        </ButtonGroup>
      </div>
      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          lg={6}
          md={5}
          sm={12}
          xl={6}
          xs={12}
        >
          <TourMap
            className={classes.gridItem}
            displayMicroHubs
            displayNotPlanedStops={false}
            displayStopStatus={false}
            forceDisplayPreviewOrder={displayPreviewOrderOnMap}
            microHubs={microHubs ? microHubs : []}
            notPlanedStops={[]}
            setSelectedStop={(stop) => scrollToElement(stop.tourStopId, true)}
            toursWithStops={toursToDisplay}
          />
          <FormControlLabel
            className={classes.previewOrderCheckbox}
            control={
              <Checkbox
                checked={displayPreviewOrderOnMap}
                color="secondary"
                onChange={(event) => setDisplayPreviewOrderOnMap(event.target.checked)}
                value={displayPreviewOrderOnMap}
              />
            }
            label={t('displayPreviewOrderOnMap')}
          />
        </Grid>
        <Grid
          item
          lg={6}
          md={7}
          sm={12}
          xl={6}
          xs={12}
        >
          <div
            className={classes.gridItem}
          >
            <TextPlaceholderWrapper
              active={!toursWithShipper?.length}
              text={t('noToursAvailable')}
            >
              <FluentTourTable
                addTour={addTour}
                deleteTour={deleteTour}
                editTourName={editTourName}
                onDragEnd={onDragEnd}
                selectVehicle={(tour, tourCapacity) => setVehicleSelectDialogState({open: true, tour: tour, tourCapacities: tourCapacity})}
                shipperName={selectedShipperOption?.shipperName}
                tours={toursToDisplay}
                vehicles={vehicles}
              />
              <VehicleSelectDialog
                handleCancel={() => setVehicleSelectDialogState({open: false, tour: null, tourCapacities: null})}
                handleClose={selectVehicle}
                vehicles={vehicles}
                vehicleSelectDialogState={vehicleSelectDialogState}
              />
            </TextPlaceholderWrapper>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

MapDispositionOptimization.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  microHubs: PropTypes.array.isRequired,
  selectedShipperStorage: PropTypes.string,
  setSelectedShipperStorage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  tourTemplateFilter: PropTypes.any.isRequired,
  tours: PropTypes.array.isRequired,
  updateTours: PropTypes.func.isRequired,
  vehicles: PropTypes.array.isRequired,
};

export default compose(withStyles(styles), withTranslation())(MapDispositionOptimization);
