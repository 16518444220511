import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core';
import styles from './styles'
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import useLoadTemplateStopTourByIdCache from 'hooks/useLoadTemplateStopTourByIdCache';
import {
  LoadingIndicator,
  LoadingWrapper,
  Portlet,
  TemplateStopAndTourMap,
} from 'components';
import { displayModes } from 'services/enums/displayModes';
import TourTable from './TourTable';
import TourDetail from './TourDetail';
import { TemplateStopService } from '../../services/backend/templateStopService';
import { withTranslation } from 'react-i18next';

function TemplateStopTourOverview(props) {
  const { classes, t, filter } = props;

  const [tours, setTours] = useState([]);
  const [backendLoadingTours, setBackendLoadingTours] = useState(false);
  const [templateStopInstancesWithoutTour, setTemplateStopInstancesWithoutTour] = useState([]);

  const {
    selectedTour,
    loadingSelectedTour,
    resetLoadTourByIdCache,
    getTourById,
    resetSelectedTour
  } = useLoadTemplateStopTourByIdCache();

  const loadData = useCallback(() => {
    setBackendLoadingTours(true);
    resetLoadTourByIdCache();
    TemplateStopService.getToursWithFilter({
      shipperName: filter.shipperName,
      carrierName: filter.carrierName,
      microHubName: filter.microHubName,
      fromDate: filter.fromDate,
      toDate: filter.toDate
    })
      .then(response => {
        setTours(response);
        const index = response.findIndex(stop => selectedTour && stop.tourId === selectedTour.id);
        if (index >= 0) {
          getTourById(selectedTour.id)
        } else {
          resetSelectedTour()
        }
        setBackendLoadingTours(false);
      }, () => {
        alert(t('errorLoadingTours'));
        setBackendLoadingTours(false);
      });

    TemplateStopService.getTemplateStopInstancesWithoutTourWithFilter({
      shipperName: filter.shipperName,
      carrierName: filter.carrierName,
      microHubName: filter.microHubName,
      fromDate: filter.fromDate,
      toDate: filter.toDate
    }).then(response => {
      setTemplateStopInstancesWithoutTour(response);
    }, () => {
      alert(t('errorLoadingTours'));
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, t]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const showDetails = (tour) => {
    getTourById(tour.id);
  };


  return (
    <div>
      <div className={classes.root}>
        <LoadingWrapper loading={backendLoadingTours}>
          <Fragment>
            <TourTable
              className={classes.content}
              selectedTour={selectedTour}
              showDetails={showDetails}
              tableDisplayMode={displayModes.admin}
              tours={tours}
            />
            {loadingSelectedTour &&
              <Portlet className={classes.content}>
                <LoadingIndicator />
              </Portlet>
            }
            {selectedTour &&
              <TourDetail
                className={classes.content}
                closeTourDetail={() => resetSelectedTour()}
                displayMode={displayModes.admin}
                readOnly
                tour={selectedTour}
              />
            }
            <TemplateStopAndTourMap
              className={classes.map}
              notPlanedStopInstances={templateStopInstancesWithoutTour}
              tours={tours}
            />
          </Fragment>
        </LoadingWrapper>
      </div>
    </div>
  );
}

TemplateStopTourOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
};


export default compose(withStyles(styles), withTranslation())(TemplateStopTourOverview);
