import React from 'react'
import {FormControl, IconButton, InputAdornment, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {Today as DateIcon} from '@material-ui/icons';
import {InfoTooltip} from '../../index';


function DateFilter(props) {

  const {classes, className, t, value, setValue, descriptionKey, required, error} = props;

  const rootClassName = classNames(classes.formControl, className);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <InfoTooltip descriptionKey={descriptionKey}>
        <FormControl className={rootClassName}>
          <DatePicker
            cancelLabel={t('dialogCancel')}
            error={error}
            format="dd.MM.yyyy"
            id="date"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton><DateIcon/> </IconButton>
                </InputAdornment>
              )
            }}
            label={t('date')}
            margin="normal"
            onChange={(event) => setValue(new Date(event))}
            required={required}
            value={value}
          />
        </FormControl>
      </InfoTooltip>
    </MuiPickersUtilsProvider>
  );
}

DateFilter.defaultProps = {
  descriptionKey: '',
  error: false,
  required: false,
};


DateFilter.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  descriptionKey: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date).isRequired
};

export default compose(withStyles(styles), withTranslation())(DateFilter);
