import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {Avatar, IconButton, ListItem, ListItemAvatar, Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import React from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import {StopStatus} from '../../../../services/enums/StopStatus';
import {
  DoneRounded as StopDriverSuccessInfoIcon,
  ErrorRounded as StopDriverErrorInfoIcon,
  InfoRounded as StopDriverInfoIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  WarningRounded as StopDriverWarningInfoIcon
} from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import classNames from 'classnames';
import {withTranslation} from 'react-i18next';
import {DateService} from '../../../../services/util/DateService';
import {StopIdLabel} from '../../../index';


function DriverInfoListItem(props) {
  const {t, classes, className, msg} = props;
  const [open, setOpen] = React.useState(false);


  const rootClassName = classNames(classes.root, className);

  return (
    <div
      className={rootClassName}
    >
      <ListItem>
        <ListItemAvatar>
          {(() => {
            switch (msg.stopStatus) {
              case StopStatus.InDelivery:
                return (
                  <Avatar className={classes.listAvatarWarning}>
                    <StopDriverWarningInfoIcon/>
                  </Avatar>
                );
              case StopStatus.Delivered:
                return (
                  <Avatar className={classes.listAvatarSuccess}>
                    <StopDriverSuccessInfoIcon/>
                  </Avatar>
                );
              case StopStatus.CanNotDeliver:
                return (
                  <Avatar className={classes.listAvatarError}>
                    <StopDriverErrorInfoIcon/>
                  </Avatar>
                );
              default :
                return (
                  <Avatar className={classes.listAvatarInfo}>
                    <StopDriverInfoIcon/>
                  </Avatar>
                );
            }
          })()}
        </ListItemAvatar>
        <ListItemText
          primary={<span><strong>{DateService.displayDate(msg.plannedDeliveryDateCarrier)}</strong> | {msg.driverName} - {msg.customerId}</span>}
          secondary={msg.message}
        />
        <IconButton
          onClick={() => setOpen(!open)}
          size="small"
        >
          {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
        </IconButton>
      </ListItem>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
      >
        <div className={classes.nested}>
          <Typography variant={'body2'}>
            <strong>{t('tourId')}:</strong>&nbsp;{msg.tourId}<br/>
            <strong>{t('tourStopId')}:</strong>&nbsp;<StopIdLabel stop={{tourStopId: msg.stopId}}/><br/>
            <strong>{t('streetAndNumber')}:</strong>&nbsp;{msg.address.streetAndNumber}<br/>
            <strong>{t('shipper')}:</strong>&nbsp;{msg.shipperName}<br/>
            <strong>{t('carrier')}:</strong>&nbsp;{msg.carrierName}<br/>
            <strong>{t('microHub')}:</strong>&nbsp;{msg.microHubName}<br/>
          </Typography>
        </div>
      </Collapse>
    </div>
  );
}

DriverInfoListItem.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  msg: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(DriverInfoListItem);
