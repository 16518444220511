import axios from 'axios';
import {AuthService} from '../auth/authService';

const baseUri = `${process.env.REACT_APP_API_URI}customer`;

const getCustomersByFilter = async (shipperName, carrierName, microHubName, dayOfWeek) => {
  const response = await axios.put(`${baseUri}/customer/get-by-filter`,
    {
      shipper: shipperName,
      carrier: carrierName,
      microHub: microHubName,
      dayOfWeek: dayOfWeek
    },
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const deleteCustomerById = async (customerId) => {
  const response = await axios.delete(`${baseUri}/customer/${customerId}`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: AuthService.getAuthHeader()
    }
  });
  return response.data;
};

const updateCustomer = async (customerId, customer) => {
  const response = await axios.put(`${baseUri}/customer/${customerId}`, customer,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const getSpecialDeliveryDaysByFilter = async (shipperName, carrierName, microHubName) => {
  const response = await axios.put(`${baseUri}/SpecialDeliveryDay/get-by-filter`, {
    shipper: shipperName,
    carrier: carrierName,
    microHub: microHubName
  },
  {
    headers: {
      'Content-Type': 'application/json',
      authorization: AuthService.getAuthHeader()
    }
  });
  return response.data;
}

const deleteSpecialDeliveryDayById = async (id) => {
  const response = await axios.delete(`${baseUri}/SpecialDeliveryDay/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: AuthService.getAuthHeader()
    }
  });
  return response.data;
};

const createSpecialDeliveryDaysForMultipleHubs = async (specialDeliveryDay) => {
  const response = await axios.post(`${baseUri}/SpecialDeliveryDay`, specialDeliveryDay, {
    headers: {
      'Content-Type': 'application/json',
      authorization: AuthService.getAuthHeader()
    }
  });
  return response.data;
};

export default {
  getCustomersByFilter,
  deleteCustomerById,
  updateCustomer,
  getSpecialDeliveryDaysByFilter,
  deleteSpecialDeliveryDayById,
  createSpecialDeliveryDaysForMultipleHubs
};
