export default theme => ({
  root: {},
  button: {
    marginLeft: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100px',
  },
  wrapper: {
    display: 'flex',
    margin: theme.spacing(2)
  },
  wrapperChild: {
    flex: 1,
  },
  driverDetail: {
    margin: theme.spacing(1)
  },
  warningColor: {
    color: theme.palette.warning.main,
    verticalAlign: 'middle'
  },
});
