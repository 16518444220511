export default theme => ({
  root: {
    minWidth: '350px'
  },
  portletHeader: {
    height: '40px'
  },
  messageListContainer: {
    maxHeight: '100%',
    overflow: 'auto',
  },
  portletContentStyle: {
    maxHeight: '90%'
  },
  listAvatarError: {
    color: theme.palette.danger.main,
    backgroundColor: theme.palette.danger.light,
  },
  listAvatarSuccess: {
    color: theme.palette.success.main,
    backgroundColor: theme.palette.success.light,
  },
  listAvatarWarning: {
    color: theme.palette.warning.main,
    backgroundColor: theme.palette.warning.light,
  },
  listAvatarInfo: {
    color: theme.palette.common.muted,
    backgroundColor: theme.palette.common.neutral,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  }
});
