import React from 'react'
import {Table, TableBody, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Pagination,
  Portlet,
  PortletContent,
  TableCellWithConfig,
  TableSettings,
  TextPlaceholderWrapper
} from '../../index';
import useTablePagination from '../../../hooks/useTablePagination';
import useTableConfig from '../../../hooks/useTableConfig';
import DefaultConfig from './config';
import {getTableKey} from '../../../services/util/tableConfigHelper';
import {TableKind} from '../../../services/enums/TableKind';
import Columns from './columns';


function VehicleTable(props) {

  const {classes, className, vehicles, t, selectVehicle, deleteVehicle, selectedVehicle, tableDisplayMode} = props;

  const {paginationProps, pagedObjects} = useTablePagination(vehicles);

  const {
    config,
    columnConfigs,
    updateConfig
  } = useTableConfig(
    DefaultConfig,
    tableDisplayMode,
    getTableKey(TableKind.VehicleTable, tableDisplayMode),
  )

  const rootClassName = classNames(classes.root, className);

  return (
    <TextPlaceholderWrapper
      active={!vehicles?.length}
      text={t('noVehiclesFound')}
    >
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <TableSettings
            updateConfig={updateConfig}
            tableConfig={config}
            columns={Columns}
            useNamesAsTranslateString
          />
          <Table size="medium">
            <TableHead>
              <TableRow>
                {columnConfigs.map(columnConfig => (
                  <TableCellWithConfig
                    key={columnConfig.order}
                    config={columnConfig}
                  >
                    {Columns[columnConfig.columnName].header({t, classes})}
                  </TableCellWithConfig>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedObjects.map((vehicle, index) => (
                <TableRow
                  className={classNames(classes.tableRow, 'cursorPointer')}
                  hover
                  key={index}
                  onClick={() => selectVehicle(vehicle)}
                  selected={selectedVehicle && selectedVehicle.licensePlate === vehicle.licensePlate}
                >
                  {columnConfigs.map(columnConfig => (
                    <TableCellWithConfig
                      key={columnConfig.order}
                      config={columnConfig}
                      className={classes.tableCell}
                    >
                      {Columns[columnConfig.columnName].body({
                        vehicle, classes, deleteVehicle, t
                      })}
                    </TableCellWithConfig>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination paginationProps={paginationProps}/>
        </PortletContent>
      </Portlet>
    </TextPlaceholderWrapper>
  );
}

VehicleTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deleteVehicle: PropTypes.func,
  selectVehicle: PropTypes.func.isRequired,
  selectedVehicle: PropTypes.object,
  t: PropTypes.func.isRequired,
  tableDisplayMode: PropTypes.string.isRequired,
  vehicles: PropTypes.array.isRequired
};

VehicleTable.defaultProps = {
  vehicles: [],
};

export default compose(withStyles(styles), withTranslation())(VehicleTable);
