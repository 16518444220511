export default theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    border: `1px solid ${theme.palette.border}`,
  },
  dialog: {}
});
