import React from 'react'
import {FormControl, Input, InputLabel, MenuItem, Select, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {getTourStatusSting, TourStatus} from '../../../services/enums/TourStatus';
import {CarrierAndMicroHubDropdownSelect, DateTimeRangeFilter, MultiSelectDropdownSelect} from '../../../components';

function TourFilter(props) {

  const {classes, className, t, filter, setFilter, carriersWithMicroHubs, microHubOptions, noTourStatusFilter, tours, disabled} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <form className={classes.rowWrapper}>
        <DateTimeRangeFilter
          className={classes.rowWrapper}
          fromDescriptionKey={'18'}
          fromError={!filter.fromDateTime}
          fromRequired
          fromValue={filter.fromDateTime}
          setValue={(fromValue, toValue) => setFilter({...filter, fromDateTime: fromValue, toDateTime: toValue})}
          toDescriptionKey={'18'}
          toError={!filter.toDateTime}
          toRequired
          toValue={filter.toDateTime}
        />
        <CarrierAndMicroHubDropdownSelect
          carriersWithMicroHubs={carriersWithMicroHubs}
          carrierValue={filter.carrierName}
          className={classes.rowWrapper}
          disabled={disabled}
          microHubDescriptionKey={'5'}
          microHubOptions={microHubOptions}
          microHubValue={filter.microHubName}
          setCarrierValue={(value) => setFilter({...filter, carrierName: value, microHubName: ''})}
          setMicroHubValue={(value) => setFilter({...filter, microHubName: value})}
        />
        {!noTourStatusFilter &&
        <FormControl className={classes.formControl}>
          <InputLabel id="tourStatus">{t('tourStatus')}</InputLabel>
          <Select
            disabled={disabled}
            input={<Input id="tourStatus"/>}
            onChange={(event) => setFilter({...filter, tourStatus: event.target.value})}
            value={filter.tourStatus}
          >
            <MenuItem
              key={-1}
              value={''}
            >
              {'-'}
            </MenuItem>
            {Object.getOwnPropertyNames(TourStatus).map((status, index) => {
              return (
                <MenuItem
                  key={index}
                  value={TourStatus[status]}
                >
                  {t(getTourStatusSting(TourStatus[status]))}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        }
        <MultiSelectDropdownSelect
          disabled={disabled}
          hideOnEmptyOptions
          maxItemsDisplayed={3}
          name={t('tourId')}
          options={tours.length ? tours.map(t => t.tourId).sort() : []}
          setValue={(value) => setFilter({...filter, tourIds: value})}
          value={filter.tourIds}
        />
      </form>
    </div>
  );
}


TourFilter.propTypes = {
  carriersWithMicroHubs: PropTypes.object,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  filter: PropTypes.object.isRequired,
  microHubOptions: PropTypes.array,
  noTourStatusFilter: PropTypes.bool,
  setFilter: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  tours: PropTypes.array
};

export default compose(withStyles(styles), withTranslation())(TourFilter);
