export default theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    border: `1px solid ${theme.palette.border}`,
    minHeight: '200px'
  },
  button: {
    float: 'right',
  },
  addButton: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  appBar: {
    left: '2px',
    width: 'calc(100% - 2px)',
    zIndex: 'unset'
  },
});

