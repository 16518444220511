import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import {CountDownTimer,} from '../../../../../components';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import moment from 'moment';
import {getMigrationEventTypeString, MigrationEventType} from '../../../../../services/enums/migrationEventType';
import {KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon} from '@material-ui/icons';
import {DateService} from '../../../../../services/util/DateService';


function DeadlineCardRow(props) {

  const {t, classes, shipper, date, migrationEvents} = props;

  const [expand, setExpand] = useState(false);

  const getMigrationTarget = migrationEvent => {
    switch (migrationEvent.migrationEventType) {
      case MigrationEventType.Tour:
        return `${t('previewTourID')} ${migrationEvent.previewTourId}`;
      case MigrationEventType.MicroHub:
        return `${migrationEvent.carrierName} - ${migrationEvent.microHubName}`;
      default:
        return migrationEvent.shipperName;
    }
  };

  const timeZoneOffsetSec = new Date().getTimezoneOffset() * 60;
  const finalDeadline = moment(date).startOf('day').add(shipper.finalDeadline - timeZoneOffsetSec, 'seconds').toDate();
  const finalDeadlineOver = moment(finalDeadline).diff(moment()) <= 0;
  const previewDeadline = moment(date).startOf('day').add(shipper.previewDeadline - timeZoneOffsetSec, 'seconds').toDate();
  const previewDeadlineOver = moment(previewDeadline).diff(moment()) <= 0;

  return (
    <Fragment>
      <TableRow className={classes.root}>
        <TableCell><Typography><strong>{shipper.shortName}</strong></Typography></TableCell>
        <TableCell>
          {previewDeadlineOver ? t('deadlineReached') :
            <CountDownTimer
              className={classes.inlineBlock}
              deadlineDate={previewDeadline}
            />
          }
        </TableCell>
        <TableCell className={classes.lastTableCell}>
          {finalDeadlineOver ? t('deadlineReached') :
            <CountDownTimer
              className={classes.inlineBlock}
              deadlineDate={finalDeadline}
            />
          }
          {migrationEvents.length > 0 &&
          <IconButton
            onClick={() => setExpand(prevState => !prevState)}
            size="small"
          >
            {expand ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton>
          }
        </TableCell>
      </TableRow>
      <TableRow className={classes.outerTableRow}>
        <TableCell
          className={classes.innerTableCell}
          colSpan={4}
        >
          <Collapse in={expand}>
            <Table
              className={classes.innerTable}
              size={'small'}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography><strong>{t('timeStamp')}</strong></Typography>
                  </TableCell>
                  <TableCell>
                    <Typography><strong>{t('migrationEventType')}</strong></Typography>
                  </TableCell>
                  <TableCell>
                    <Typography><strong>{t('migrationTarget')}</strong></Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {migrationEvents.map((migrationEvent, index) =>
                  <Tooltip
                    key={index}
                    placement={'left'}
                    title={migrationEvent.migratedBy || t('systemEvent')}
                  >
                    <TableRow key={migrationEvent.id}>
                      <TableCell><Typography>{DateService.displayDateTime(migrationEvent.timeStamp)}</Typography></TableCell>
                      <TableCell><Typography>{t(getMigrationEventTypeString(migrationEvent.migrationEventType))}</Typography></TableCell>
                      <TableCell>
                        <Typography>
                          {getMigrationTarget(migrationEvent)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </Tooltip>
                )}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

DeadlineCardRow.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
  migrationEvents: PropTypes.array.isRequired,
  shipper: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(DeadlineCardRow);
