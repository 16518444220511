import React, {useCallback, useState} from 'react';
import * as PropTypes from 'prop-types';
import DatePickerDefaultValueContext from './context';

const localstorageDefaultDateValueKey = 'defaultDateValue';

const DatePickerDefaultValueContextProvider = (props) => {

  const {children} = props;

  const getInitialDate = () => {
    try {
      const localStorageEntry = window.localStorage.getItem(localstorageDefaultDateValueKey);
      if (!localStorageEntry) return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);
      const localStorageObject = JSON.parse(localStorageEntry);
      if (new Date(localStorageObject.timeStamp).toDateString() !== new Date().toDateString()) {
        return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);
      }
      return new Date(localStorageObject.fromDate);
    } catch {
      return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);
    }
  };

  const [from, setFrom] = useState(getInitialDate());

  const updateDefaultDate = useCallback((newValue) => {
    const newFrom = new Date(newValue.getFullYear(), newValue.getMonth(), newValue.getDate(), 0, 0, 0);
    setFrom(newFrom);
    const newLocalStorageEntry = {
      fromDate: newFrom,
      timeStamp: new Date(),
    };
    window.localStorage.setItem(localstorageDefaultDateValueKey, JSON.stringify(newLocalStorageEntry))
  }, []);

  return (
    <DatePickerDefaultValueContext.Provider
      value={{
        fromDateDefault: from,
        toDateDefault: new Date(from.getFullYear(), from.getMonth(), from.getDate(), 23, 59, 59),
        updateDefaultDate: updateDefaultDate,
      }}
    >
      {children}
    </DatePickerDefaultValueContext.Provider>
  );
};


DatePickerDefaultValueContextProvider.propTypes = {
  children: PropTypes.node,
};

export default DatePickerDefaultValueContextProvider;
