import {withTranslation} from 'react-i18next';
import React, {Fragment, useCallback, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Map, TileLayer} from 'react-leaflet';
import * as L from 'leaflet';
import {GestureHandling} from 'leaflet-gesture-handling';
import {MapControls, MarkerCreateFuncs, MicroHubMarker, OverlappingMarkerCluster, OverlappingMarkerCreateFuncs, StopMarkerCluster} from '../../MapComponents';
import {groupBy} from 'lodash';
import {
  filterMicroHubsForCoords,
  filterStopsForCoords,
  filterStopsForDuplicate,
  getBoundingBox,
  getBoundingBoxCoords,
  getElementsWithSamePosition,
  getNumStopsOnMap,
} from '../../../../services/util/mapUtils';
import {IconService} from '../../../../services/icon/iconService';
import useMapControls from '../../../../hooks/useMapControls';


const HubDistributionMap = React.memo(props => {

  const {className, hubsWithToursAndStops, unassignedStops, microHubs} = props;

  const map = useRef(null);

  const microHubsWithCoords = filterMicroHubsForCoords(microHubs);
  const stopsFromTours = hubsWithToursAndStops.map(hub => hub.tours).flat().map(tour => tour.stops).flat();
  const stopsOnHubsWithoutTour = hubsWithToursAndStops.map(hub => hub.stopsWithoutTour).flat();
  const stopsWithCoords = filterStopsForCoords(stopsFromTours.concat(stopsOnHubsWithoutTour).concat(unassignedStops));

  const stopsOnMap = getNumStopsOnMap([], stopsWithCoords);
  const {useCluster, setUseCluster, useDuplicateSpiderfy, setUseDuplicateSpiderfy, highLiteDuplicates, setHighLiteDuplicates} = useMapControls(stopsOnMap);

  const overlappingElementGroups = useDuplicateSpiderfy ? getElementsWithSamePosition([], stopsWithCoords) : [];
  const keysOfOverlappingElements = overlappingElementGroups.map(g => g[0].key);

  const filteredStops = filterStopsForDuplicate(stopsWithCoords, keysOfOverlappingElements);

  const stopsGroupedByHub = groupBy(filteredStops, stop => stop.assignedMicroHubName);


  const getMicroHubColorForStop = (stop) => {
    const index = microHubs.findIndex(hub => hub.name === stop.assignedMicroHubName);
    if (index < 0) {
      return '#000000';
    }
    return microHubs[index].color;
  };

  L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

  const fitMapBoundsToFG = useCallback(() => {
    const coordinates = getBoundingBoxCoords([], stopsWithCoords, microHubsWithCoords);
    if (coordinates.length <= 1) return;
    const boundingBox = getBoundingBox(coordinates);
    map.current.leafletElement.fitBounds(boundingBox, {padding: [30, 30]});
    // eslint-disable-next-line
  }, [unassignedStops, hubsWithToursAndStops, microHubs]);

  useEffect(() => {
    fitMapBoundsToFG();
  }, [fitMapBoundsToFG]);

  return (
    <Fragment>
      <Map
        boundsOptions={{padding: [10, 10]}}
        center={[50, 10.5]}
        className={className}
        gestureHandling
        ref={map}
        zoom={6}
        zoomSnap={0.25}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {microHubsWithCoords.map((hub, index) => {
          return (
            <MicroHubMarker
              key={index}
              microHub={hub}
              useMicroHubColor
            />
          );
        })}
        {Object.keys(stopsGroupedByHub).map((key, index) => (
          <StopMarkerCluster
            clusterIconColor={getMicroHubColorForStop(stopsGroupedByHub[key][0])}
            getClusterIcon={(iconColor) => IconService.getMarkerClusterIcon(iconColor)}
            getStopMarkerAndAddToMcg={(stop, mcg, t) => MarkerCreateFuncs.getPreviewStopMarker(stop, mcg, t, undefined, getMicroHubColorForStop(stop))}
            key={index}
            stops={stopsGroupedByHub[key]}
            useCluster={useCluster}
          />
        ))}
        {overlappingElementGroups.map((elementGroup, index) => (
          <OverlappingMarkerCluster
            getClusterIcon={(overlappingElementGroups, highLiteDuplicates) => OverlappingMarkerCreateFuncs.getForHubDistributionMap(overlappingElementGroups, highLiteDuplicates, (stop) => getMicroHubColorForStop(stop))}
            getStopMarkerAndAddToMcg={(stop, mcg, t) => MarkerCreateFuncs.getPreviewStopMarker(stop, mcg, t, undefined, getMicroHubColorForStop(stop))}
            highLiteDuplicates={highLiteDuplicates}
            key={index}
            overlappingElements={elementGroup}
          />
        ))}
      </Map>
      <MapControls
        highLiteDuplicates={highLiteDuplicates}
        setHighLiteDuplicates={setHighLiteDuplicates}
        setUseCluster={setUseCluster}
        setUseDuplicateSpiderfy={setUseDuplicateSpiderfy}
        useCluster={useCluster}
        useDuplicateSpiderfy={useDuplicateSpiderfy}
      />
    </Fragment>
  );
});

HubDistributionMap.displayName = 'HubDistributionMap';

HubDistributionMap.propTypes = {
  className: PropTypes.string,
  hubsWithToursAndStops: PropTypes.array,
  microHubs: PropTypes.array,
  t: PropTypes.func.isRequired,
  unassignedStops: PropTypes.array,
};


export default withTranslation()(HubDistributionMap);

