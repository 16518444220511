export default theme => ({
  root: {},
  table: {
    border: `1px solid ${theme.palette.border}`,
  },
  hr: {
    borderColor: theme.palette.divider
  },
  accordionSummaryContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  accordionDetail: {
    overflow: 'auto'
  },
  statusDisplay: {
    display: 'flex',
    alignItems: 'center'
  },
  deadlinesInvalid: {
    backgroundColor: theme.palette.common.muted,
  },
});

