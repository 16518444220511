export default theme => ({
    root: {},
    button: {
    },
    wrapper: {
      display: 'flex',
      margin: theme.spacing(2)
    },
    wrapperChild: {
      flex: 1,
    },
    vehicleDetail: {
      margin: theme.spacing(1)
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '150px',
      width: '150px',
    },
    iconButton: {
      marginLeft: theme.spacing(-1)
    },
    shipperList:{
      width: 'fit-content'
    },
    shipperName: {
      paddingRight: theme.spacing(1)
    },
    deleteButton: {
      color: theme.palette.danger.main,
      '&:hover': {
        color: theme.palette.danger.main,
      }
    },
    bulletPoint: {
      color: theme.palette.common.black,
      paddingRight: theme.spacing(1),
      minWidth: 0
    }
  });
  