// eslint-disable-next-line no-unused-vars
export default theme => ({
  formControl: {
    width: '250px'
  },
  container: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});

