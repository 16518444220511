import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import {AddStopsToTourBaseMap, InfoTooltip} from '../../../index';
import {filterTemplatesForCoords, getPolyLinesFromTemplates} from '../../../../services/util/mapUtils';
import {customerIconColor, IconService} from '../../../../services/icon/iconService';
import {useTranslation} from 'react-i18next';
import {MarkerCreateFuncs, OverlappingMarkerCreateFuncs} from '../../MapComponents'

function AddStopsToTourMap(props) {

  const {className, templates, microHubs, customers, onSelectCustomer, selectedTemplate, addedCustomers, displayAllTemplates, setDisplayAllTemplates} = props;

  const {t} = useTranslation();

  return (
    <AddStopsToTourBaseMap
      addedStops={addedCustomers}
      className={className}
      displayAllTours={displayAllTemplates}
      displayAllToursLabel={
        <Fragment>
          <InfoTooltip descriptionKey={'80'}>
            <Typography>
              {t('displayAllTemplates')}
            </Typography>
          </InfoTooltip>
        </Fragment>
      }
      displayControls
      filterToursForCoords={filterTemplatesForCoords}
      getOverLappingMarkerClusterIcon={(overlappingElements, highLiteDuplicates) => OverlappingMarkerCreateFuncs.getForAddCustomerToTemplateMap(overlappingElements, highLiteDuplicates, () => {
        return selectedTemplate ? selectedTemplate.color : customerIconColor
      })}
      getPolyLines={getPolyLinesFromTemplates}
      getStopClusterIcon={() => IconService.getMarkerClusterIcon(customerIconColor)}
      getStopMarkerAndAddToMcg={(customer, mcg, t) => MarkerCreateFuncs.getCustomerMarker(customer, mcg, t, onSelectCustomer)}
      getToAddStopMarkerAndAddToMcg={(customer, mcg, t) => MarkerCreateFuncs.getCustomerMarker(customer, mcg, t, onSelectCustomer, selectedTemplate ? selectedTemplate.color : customerIconColor)}
      getTourClusterIcon={(tour) => IconService.getMarkerClusterIcon(tour.color, tour.id)}
      getTourStopMarkerAndAddToMcg={(template, stop, mcg, t) => MarkerCreateFuncs.getTemplateStopMarker(template, stop, mcg, t)}
      microHubs={microHubs}
      notAddedStops={customers}
      setDisplayAllTours={setDisplayAllTemplates}
      tours={templates}
    />
  );
}

AddStopsToTourMap.propTypes = {
  addedCustomers: PropTypes.array.isRequired,
  className: PropTypes.string,
  customers: PropTypes.array.isRequired,
  displayAllTemplates: PropTypes.bool.isRequired,
  microHubs: PropTypes.array.isRequired,
  onSelectCustomer: PropTypes.func.isRequired,
  selectedTemplate: PropTypes.object.isRequired,
  setDisplayAllTemplates: PropTypes.func.isRequired,
  templates: PropTypes.array.isRequired,
};


export default AddStopsToTourMap;

