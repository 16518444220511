export default theme => ({
  root: {
    padding: theme.spacing(3)
  },
  button: {
    marginBottom: theme.spacing(2)
  },
  buttonRight: {
    float: 'right',
  },
  helpWrapper: {
    float: 'right',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  iconMargin: {
    marginLeft: '8px'
  }
});
