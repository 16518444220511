export default theme => ({
  root: {},
  tableCell: {
    whiteSpace: 'nowrap',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  firstTableCell: {
    paddingLeft: 0,
  },
  firstTableCellContent: {
    alignItems: 'center',
    display: 'flex'
  },
  dragging: {
    '& > *': {
      borderBottom: 'unset',
    },
    backgroundColor: theme.palette.primary.selected,
  },
  dragIcon: {
    height: '22px',
    maxHeight: '22px',
    paddingLeft: 0,
    marginRight: theme.spacing(1),
  },
  deleteButton: {
    color: theme.palette.danger.main,
  }
});

