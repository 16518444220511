import {AuthService} from '../auth/authService';

const feedbackMail = 'julian@zukunftsangelegenheiten.de';
const bugBodyTemplate = "Hallo," +
  "%0A" +
  "mir ist ein Fehler auf der Plattform Grüne Stadtlogistik aufgefallen." +
  "%0A" +
  "%0A" +
  "Als der Bug aufgetreten ist, wollte ich Folgendes machen:" +
  "%0A" +
  "%0A" +
  "%0A" +
  "Stattdessen ist Folgendes passiert:" +
  "%0A" +
  "%0A" +
  "%0A" +
  "Weitere Informationen:" +
  "%0A" +
  "%0A" +
  "%0A" +
  "Einen Screenshot habe ich angehängt: Ja [ ]  Nein [ ]" +
  "%0A" +
  "%0A";
const feedbackBodyTemplate = "Hallo," +
  "%0A" +
  "ich möchte Feedback zur Plattform Grüne Stadtlogistik geben." +
  "%0A" +
  "%0A" +
  "Auf einer Skala von 1 bis 5, wie zufrieden bist du mit dem Produkt Grüne Stadtlogistik?" +
  "%0A" +
  "%0A" +
  "%0A" +
  "Welche Features hast du genutzt? (z.B. Disposition; Excel Upload von Stopps/Touren; manuelles Hinzufügen von Stopps;…)" +
  "%0A" +
  "%0A" +
  "%0A" +
  "Was gefällt dir an dem Produkt?" +
  "%0A" +
  "%0A" +
  "%0A" +
  "Was hat dich am Produkt gestört?" +
  "%0A" +
  "%0A" +
  "%0A" +
  "Was können wir am Produkt verbessern?" +
  "%0A" +
  "%0A" +
  "%0A" +
  "Was möchtest du uns sonst noch mitteilen?" +
  "%0A" +
  "%0A" +
  "%0A" +
  "%0A";

export class EmailTemplateService {

  static GetBugMailToString() {
    const devInfos = `------------------Technical Information------------------%0ALocation: ${window.location.href}%0AUser: ${AuthService.getUserEmail()}%0ARole: ${AuthService.getUserRole()}%0AOrganization: ${AuthService.getUserOrganization()}%0ADateTime: ${new Date().toISOString()}%0AUserAgent: ${navigator.userAgent}`;
    return `mailto:${feedbackMail}?subject=[Bug] &body=${bugBodyTemplate}${devInfos}`;
  }

  static GetFeedbackEmailToString() {
    const devInfos = `------------------Technical Information------------------%0ALocation: ${window.location.href}%0AUser: ${AuthService.getUserEmail()}%0ARole: ${AuthService.getUserRole()}%0AOrganization: ${AuthService.getUserOrganization()}%0ADateTime: ${new Date().toISOString()}%0AUserAgent: ${navigator.userAgent}`;
    return `mailto:${feedbackMail}?subject=[Feedback] &body=${feedbackBodyTemplate}${devInfos}`;
  }
}
