export default theme => ({
  root: {},
  legendControl: {
    margin: theme.spacing(1),
    minWidth: '200px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    float: 'right'
  },
});
