export default theme => ({
  root: {},
  previewTourTable: {
    border: `1px solid ${theme.palette.border}`,
  },
  hr: {
    borderColor: theme.palette.divider
  },
  accordionSummaryContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  accordionDetail: {
    overflow: 'auto'
  },
  statusDisplay: {
    display: 'flex',
    alignItems: 'center'
  }
});

