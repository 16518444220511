import React, {useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {onCloseWithBackdropCurry} from '../../../../services/util/helperFuncs';
import {DateTimeRangeFilter, MultiSelectDropdownSelect} from '../../../../components';
import {userRoles} from '../../../../services/auth/userRoles';
import IdentityService from '../../../../services/backend/identityService';
import useCarriersWithHubs from '../../../../hooks/useCarriersWithHubs';
import useShipperOptions from '../../../../hooks/useShipperOptions';
import moment from 'moment';

const emptyNews = {
  message: '',
  displayFrom: new Date(),
  displayTo: moment(new Date()).add(1, 'hour').toDate(),
  roles: [],
  organizations: []
}

function CreateNewsToUserDialog(props) {

  const {close, t, classes, open} = props;

  const sendNews = async () => {
    try {
      // Sanitize roles to use backend role value and not translated role values
      const newsToUserToSend = {...newsToUserToCreate}
      newsToUserToSend.roles = newsToUserToSend.roles.map(role => {
          const translatedRoleValue = role.role;
          const backendRoleValue = Object.keys(userRoles).filter(r => t(userRoles[r]) === translatedRoleValue)[0]
          return {role: backendRoleValue};
        }
      );
      await IdentityService.createNewsToUser(newsToUserToSend);
      setNewsToUserToCreate(emptyNews);
      close();
    } catch (e) {
      window.alert(`${t('errorCreatingNewsToUser')}, ${e}`)
    }
  }

  const onClose = () => {
    setNewsToUserToCreate(emptyNews);
    close();
  }

  const [newsToUserToCreate, setNewsToUserToCreate] = useState(emptyNews);

  const {carrierOptions} = useCarriersWithHubs();
  const {shipperOptions} = useShipperOptions();
  const administration = ['Zukunftsangelegenheiten GmbH'];

  let [organisationsOptions, setOrganisationsOptions] = useState([]);

  const roleChange = (activeRoles) => {
    const newNewsToUserToCreate = {...newsToUserToCreate};
    //1. Set New User Roles
    newNewsToUserToCreate.roles = activeRoles.map(val => ({role: val}));
    //2. Determine new User Organizations
    const roles = activeRoles;
    let newOrganisations = [];
    if (roles.includes(t(userRoles.administration))) {
      newOrganisations = [...newOrganisations, ...administration];
    }
    if (roles.includes(t(userRoles.carrier)) || roles.includes(t(userRoles.deliverer))) {
      newOrganisations = [...newOrganisations, ...carrierOptions];
    }
    if (roles.includes(t(userRoles.shipper))) {
      newOrganisations = [...newOrganisations, ...shipperOptions];
    }
    setOrganisationsOptions(newOrganisations);
    //4. Clean up invalid Organizations
    newNewsToUserToCreate.organizations = newsToUserToCreate.organizations.filter(org => newOrganisations.includes(org.organization));
    setNewsToUserToCreate(newNewsToUserToCreate);
  }

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth={'md'}
      onClose={onCloseWithBackdropCurry(() => close())}
      open={open}
    >
      <DialogTitle>{t('createNewMessage')}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <br />
        <textarea
          className={!newsToUserToCreate.message ? classes.textFieldError : ''}
          onChange={event => setNewsToUserToCreate({...newsToUserToCreate, message: event.target.value})}
          placeholder={t('typeNewsToUser')}
          required
          rows="12"
          value={newsToUserToCreate.message}
        />

        <DateTimeRangeFilter
          fromError={!newsToUserToCreate.displayFrom}
          fromRequired
          fromValue={newsToUserToCreate.displayFrom}
          setValue={(fromValue, toValue) => setNewsToUserToCreate({
            ...newsToUserToCreate,
            displayFrom: fromValue,
            displayTo: toValue
          })}
          toError={!newsToUserToCreate.displayTo}
          toRequired
          toValue={newsToUserToCreate.displayTo}
        />

        <MultiSelectDropdownSelect
          className={classes.wideDropdown}
          descriptionKey={'1'}
          maxItemsDisplayed={4}
          name={t('newsToUserRoles')}
          options={Object.keys(userRoles).map(role => t(userRoles[role]))}
          setValue={roleChange}
          value={newsToUserToCreate.roles.map(role => role.role)}

        />


        <MultiSelectDropdownSelect
          className={classes.wideDropdown}
          descriptionKey={'2'}
          maxItemsDisplayed={4}
          name={t('newsToUserOrganization')}
          options ={organisationsOptions}
          setValue={(value) => setNewsToUserToCreate({
            ...newsToUserToCreate,
            organizations: value.map(val => ({organization: val}))
          })}
          value={newsToUserToCreate.organizations.map(organization => organization.organization)}
        />
        <br />

      </DialogContent>

      <DialogActions
        className={classes.horizontalContainer}
      >
        <Button
          className={classes.navLeft}
          color="default"
          onClick={onClose}
        >
          {t('dialogCancel')}
        </Button>
        <Button
          className={classes.button}
          color="primary"
          disabled={!newsToUserToCreate.message || !newsToUserToCreate.displayFrom || !newsToUserToCreate.displayTo}
          onClick={sendNews}
        >
          {t('sendNewMessage')}
        </Button>
      </DialogActions>
    </Dialog>
  )
  ;
}

CreateNewsToUserDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
};

export default compose(withStyles(styles), withTranslation())(CreateNewsToUserDialog);