import React from 'react'
import {Checkbox, FormControlLabel, FormGroup, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {dayOfWeekSorterFunction} from '../../../../../../services/enums/dayOfWeekHelper';

function OperatingTimeEdit(props) {

  const {classes, className, editedMicroHub, setEditedMicroHub, selectedCarrier, t} = props;

  const rootClassName = classNames(classes.root, className);  

  return (
    <div className={rootClassName}>
      <div><strong>{t('operatingTime')}</strong>:</div>
      <FormGroup>
        {editedMicroHub.carriers[selectedCarrier].weekDayShipperLists.sort((a, b) => dayOfWeekSorterFunction(a.weeekDay, b.weekDay)).map((_, weekDayId) =>
          <FormControlLabel
            control={
              <Checkbox
                checked={editedMicroHub.carriers[selectedCarrier].weekDayShipperLists[weekDayId].microHubIsOpen}
                color="secondary"
                onChange={(event) => setEditedMicroHub({
                  ...editedMicroHub,
                  carriers: editedMicroHub.carriers.map((c, i) => i === selectedCarrier ? {
                    ...c,
                    weekDayShipperLists: c.weekDayShipperLists.map((l, j) => j === weekDayId ? {...l, microHubIsOpen: event.target.checked} : l)
                  } : c)
                })}
                value={editedMicroHub.carriers[selectedCarrier].weekDayShipperLists[weekDayId].microHubIsOpen}
              />
            }
            key={weekDayId}
            label={t(editedMicroHub.carriers[selectedCarrier].weekDayShipperLists[weekDayId].weekDay.toLowerCase())}
          />
        )}
      </FormGroup>
    </div>
  );
}


OperatingTimeEdit.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  editedMicroHub: PropTypes.object.isRequired,
  setEditedMicroHub: PropTypes.func.isRequired,
  selectedCarrier: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(OperatingTimeEdit);
