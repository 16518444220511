export default theme => ({
  root: {},
  button: {
    marginLeft: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100px',
  },
  textarea: {
    padding: theme.spacing(1),
    borderRadius: '5px',
    border: '1px, solicd #ccc',
  },
  rowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    margin: theme.spacing(2)
  },
  stopDetail: {
    margin: theme.spacing(1)
  },
  smallFormControl: {
    minWidth: '100px',
    width: '100px',
  },
  timePicker: {
    minWidth: '225px',
    widht: '225px',
    maxWidth: '225px'
  },
  infoIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  dangerColor: {
    color: theme.palette.danger.main
  },
  alignText: {
    display: 'flex',
    alignItems: 'center'
  }
});
