import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {CheckCircle, Error, PlayCircleFilled} from '@material-ui/icons';
import styles from './styles';


function CustomStepperIcon(props) {

  const {completed, classes, error} = props;

  if (error && completed) {
    return (
      <div>
        <Error className={classes.errorCompleted}/>
      </div>
    )
  }

  if (completed) {
    return (
      <div>
        <CheckCircle className={classes.completed}/>
      </div>
    )
  }

  return (
    <div>
      <PlayCircleFilled className={error ? classes.error : classes.active}/>
    </div>
  )
}

CustomStepperIcon.propTypes = {
  active: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  completed: PropTypes.bool,
  error: PropTypes.bool.isRequired
};

export default withStyles(styles)(CustomStepperIcon);
