export default theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '200px',
  },
  dialog: {},
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  rowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    margin: theme.spacing(2)
  },
  rowWrapperNoMarginTop: {
    marginTop: -theme.spacing(2)
  },
  redIcon: {
    color: theme.palette.danger.main,
  },
  buttonRight: {},
  warningColor: {
    color: theme.palette.warning.main,
    verticalAlign: 'middle'
  },
  infoIcon: {
    verticalAlign: 'middle'
  },
  dangerColor: {
    color: theme.palette.danger.main
  },
  alignText: {
    display: 'flex',
    alignItems: 'center'
  }
});
