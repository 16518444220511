import React, {useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Portlet} from '../../../../../components'
import {default as MicroHubDetailEdit} from './MicoHubDetailEdit';
import {default as MicroHubDetailShow} from './MicroHubDetailShow';

function MicroHubDetails(props) {

  const [editMode, setEditMode] = useState(false);
  const [editedMicroHub, setEditedMicroHub] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState(0);
  const {classes, className, microHub, updateMicroHub, closeDetail, readonly, shipperNames} = props;

  const editMicroHub = () => {
    setEditedMicroHub(JSON.parse(JSON.stringify(microHub)));
    setEditMode(true);
  };

  const update = () => {
    setEditMode(false);
    updateMicroHub(editedMicroHub);
  };

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet className={rootClassName}>
      {editMode ?
        <MicroHubDetailEdit
          editedMicroHub={editedMicroHub}
          setEditedMicroHub={setEditedMicroHub}
          setEditMode={setEditMode}
          updateMicroHub={update}
          selectedCarrier={selectedCarrier}
          setSelectedCarrier={setSelectedCarrier}
          shipperNames={shipperNames}
        />
        :
        <MicroHubDetailShow
          closeDetail={closeDetail}
          edit={editMicroHub}
          microHub={microHub}
          readonly={readonly}
          selectedCarrier={selectedCarrier}
          setSelectedCarrier={setSelectedCarrier}
        />
      }
    </Portlet>
  );
}


MicroHubDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  closeDetail: PropTypes.func.isRequired,
  microHub: PropTypes.object.isRequired,
  readonly: PropTypes.bool,
  t: PropTypes.func.isRequired,
  updateMicroHub: PropTypes.func.isRequired,
  shipperNames: PropTypes.object.isRequired,
}
;

export default compose(withStyles(styles), withTranslation())(MicroHubDetails);
