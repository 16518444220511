import React, {Fragment, useState} from 'react'
import {
  Button,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {InfoIcon, LoadingIndicator, PondUploader, StopFilter} from '../../../index';
import {TourService} from '../../../../services/backend/tourService';
import {ExcelTemplateService} from '../../../../services/excelTemplateService/excelTemplateService';
import {displayModes} from '../../../../services/enums/displayModes';


function DataImport(props) {

  const {
    classes,
    t,
    displayMode,
    carriers,
    microHubOptions,
    carriersWithMicroHubs,
    filter,
    setFilter,
    shipperOptions,
    setActiveStep,
    stops,
    setStops,
    useExcelAsImportMethod
  } = props;


  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState('');
  const [selectedShipper, setSelectedShipper] = useState('');

  if (!selectedCarrier && carriers && carriers.length === 1) {
    setSelectedCarrier(carriers[0])
  }

  if (!selectedShipper && shipperOptions && shipperOptions.length === 1) {
    setSelectedShipper(shipperOptions[0]);
  }

  const loadStops = () => {
    setShowLoadingIndicator(true);
    TourService.getStopsWithFilter(filter.carrierName, filter.shipperName, filter.microHubName, filter.stopStatus === '' ? null : filter.stopStatus, filter.fromDate, filter.toDate, filter.boxId)
      .then(response => response.json())
      .then(
        response => {
          setStops(response);
          setShowLoadingIndicator(false);
        }
        , () => {
          alert(t('errorLoadingStops'));
          setShowLoadingIndicator(false);
        })
  };

  const uploadStopsCallback = (response) => {
    const parsedResponse = JSON.parse(response);
    if (typeof (parsedResponse) === 'object') {
      setStops(stops.concat(parsedResponse));
    }
  };

  if (useExcelAsImportMethod) {
    return (
      <Fragment>
        {(displayMode === displayModes.shipper || displayMode === displayModes.admin) &&
        <Fragment>
          <div className={classes.buttonWrapper}>
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                lg={6}
                xs={12}
              >
                <div className={classes.iconWrapper}>
                  <Typography
                    className={classes.iconMargin}
                    variant={'h5'}
                  >
                    {t('shipperStopUpload')}
                  </Typography>
                  <InfoIcon
                    className={classes.iconMargin}
                    descriptionKey={'2'}
                  />
                </div>
                {!(shipperOptions && shipperOptions.length === 1) &&
                <Tooltip
                  placement={'top'}
                  title={t('selectShipperForSimulation')}
                >
                  <FormControl className={classes.carrierSelectForm}>
                    <InputLabel id="shipper">{t('shipper')}</InputLabel>
                    <Select
                      input={<Input id="shipper"/>}
                      onChange={(event) => setSelectedShipper(event.target.value)}
                      value={selectedShipper}
                    >
                      {shipperOptions.map((shipper, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={shipper}
                          >
                            {shipper}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Tooltip>
                }
              </Grid>
              <Grid
                item
                lg={6}
                xs={12}
              >
                <Button
                  className={classes.button}
                  color="secondary"
                  disabled={!selectedShipper}
                  onClick={() => ExcelTemplateService.downloadTemplateStopsShipper(selectedShipper)}
                  variant="contained"
                >
                  {t('downloadStopTemplate')}
                </Button>
              </Grid>
            </Grid>
          </div>
          <PondUploader
            className={classes.content}
            disabled={!selectedShipper}
            fileName={'stops'}
            onLoadCallback={uploadStopsCallback}
            uploaderOrganization={selectedShipper}
            uploadType="shipper"
            url={`/simulateTourBuilding/uploadExcel/stops/shipper/${stops.length}`}
          />
          <br/>
        </Fragment>
        }
        {(displayMode === displayModes.carrier || displayMode === displayModes.admin) &&
        <Fragment>
          <div className={classes.buttonWrapper}>
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                lg={6}
                xs={12}
              >
                <div className={classes.iconWrapper}>
                  <Typography
                    className={classes.iconMargin}
                    variant={'h5'}
                  >
                    {t('carrierStopUpload')}
                  </Typography>
                  <InfoIcon
                    className={classes.iconMargin}
                    descriptionKey={'2'}
                  />
                </div>
                {!(carriers && carriers.length !== 1) &&
                <Tooltip
                  placement={'top'}
                  title={t('selectCarrierForSimulation')}
                >
                  <FormControl className={classes.carrierSelectForm}>
                    <InputLabel id="carrier">{t('carrier')}</InputLabel>
                    <Select
                      input={<Input id="carrier"/>}
                      onChange={(event) => setSelectedCarrier(event.target.value)}
                      value={selectedCarrier}
                    >
                      {carriers.map((carrier, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={carrier}
                          >
                            {carrier}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Tooltip>
                }
              </Grid>
              <Grid
                item
                lg={6}
                xs={12}
              >
                <Button
                  className={classes.button}
                  color="secondary"
                  disabled={!selectedCarrier}
                  onClick={() => ExcelTemplateService.downloadTemplateStopCarrier(selectedCarrier)}
                  variant="contained"
                >
                  {t('downloadStopTemplate')}
                </Button>
              </Grid>
            </Grid>
          </div>
          <PondUploader
            className={classes.content}
            disabled={!selectedCarrier}
            fileName={'stops'}
            onLoadCallback={uploadStopsCallback}
            uploaderOrganization={selectedCarrier}
            uploadType="carrier"
            url={`/simulateTourBuilding/uploadExcel/stops/carrier/${stops.length}`}
          />
        </Fragment>
        }
        <div className={classes.actionsContainer}>
          <Button
            className={classes.navButton}
            color="primary"
            disabled={!stops || stops.length === 0}
            onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
            variant="contained"
          >
            {t('next')}
          </Button>
        </div>
      </Fragment>
    );
  }

  if (showLoadingIndicator) {
    return (
      <LoadingIndicator/>
    )
  }

  return (
    <Fragment>
      <div className={classes.buttonWrapper}>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            lg={6}
            xs={12}
          >
            <div className={classes.iconWrapper}>
              <Typography
                className={classes.iconMargin}
                variant={'h5'}
              >
                {t('dataImport')}
              </Typography>
              <InfoIcon
                className={classes.iconMargin}
                descriptionKey={'2'}
              />
            </div>
            {stops && !(stops.length === 0) &&
            <div className={classes.infoDiv}>
              <Typography
                color={'primary'}
                variant={'h5'}
              >
                {t('simulationAmountOfImportedStops')}:&nbsp;{stops.length}
              </Typography>
            </div>
            }
            <StopFilter
              carriersWithMicroHubs={carriersWithMicroHubs}
              filter={filter}
              microHubOptions={microHubOptions}
              setFilter={setFilter}
              shipperOptions={shipperOptions}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.actionsContainer}>
        {stops?.length ?
          <Button
            className={classes.navButton}
            color="primary"
            disabled={!stops || stops.length === 0}
            onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
            variant="contained"
          >
            {t('next')}
          </Button>
          :
          <Button
            className={classes.navButton}
            color="primary"
            disabled={!filter}
            onClick={loadStops}
            variant="contained"
          >
            {t('importData')}
          </Button>
        }
      </div>
    </Fragment>
  );

}

DataImport.propTypes = {
  carriers: PropTypes.array,
  carriersWithMicroHubs: PropTypes.object,
  classes: PropTypes.object.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  filter: PropTypes.object.isRequired,
  microHubOptions: PropTypes.array,
  microHubs: PropTypes.array,
  setActiveStep: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  setStops: PropTypes.func.isRequired,
  shipperOptions: PropTypes.array,
  stops: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  useExcelAsImportMethod: PropTypes.bool.isRequired,
};


export default compose(withStyles(styles), withTranslation())(DataImport);
