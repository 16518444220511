// eslint-disable-next-line no-unused-vars
export default theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: 'auto',
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  iconMargin: {
    marginLeft: '8px'
  }
});

