import React from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {CarrierMicroHubAndDriverDropdownSelect, DateRangeFilter, DropdownSelectSimple} from '../../../components';
import {displayModes} from '../../../services/enums/displayModes';


function StatisticFilter(props) {

  const {
    classes,
    className,
    t,
    filter,
    setFilter,
    carriersMicroHubsDrivers,
    shipperOptions,
    driverDisplayNames,
    displayMode,
  } = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <form>
        <DateRangeFilter
          className={classes.rowWrapper}
          fromDescriptionKey={'18'}
          fromValue={filter.fromDate}
          setValue={(fromValue, toValue) => setFilter({...filter, fromDate: fromValue, toDate: toValue})}
          toDescriptionKey={'18'}
          toValue={filter.toDate}
        />
        <div className={classes.rowWrapper}>
          <DropdownSelectSimple
            descriptionKey={'19'}
            hideOnEmptyOptions
            name={t('shipper')}
            options={shipperOptions}
            setValue={(value) => setFilter({...filter, shipperName: value})}
            sort
            value={filter.shipperName}
          />
          <CarrierMicroHubAndDriverDropdownSelect
            carrierDescriptionKey={'4'}
            carriersMicroHubsDrivers={carriersMicroHubsDrivers}
            carrierValue={filter.carrierName}
            displayMode={displayMode}
            driverDisplayNames={driverDisplayNames}
            driverValue={filter.driverEmail}
            microHubValue={filter.microHubName}
            setCarrierValue={(value) => setFilter({...filter, carrierName: value, microHubName: '', driverEmail: ''})}
            setDriverValue={(value) => setFilter({...filter, driverEmail: value})}
            setMicroHubValue={(value) => setFilter({...filter, microHubName: value, driverEmail: ''})}
          />
        </div>
      </form>
    </div>
  );
}


StatisticFilter.propTypes = {
  carriersMicroHubsDrivers: PropTypes.object.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  driverDisplayNames: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  shipperOptions: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(StatisticFilter);
