export default theme => ({
  root: {
  },
  toursWithoutStopsRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  alignRight: {
    align: 'right',
  },
  previewTourTableRow: {},
  previewStopTable: {
    border: `1px solid ${theme.palette.border}`,
  },
});

