import React, {useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles';
import classNames from 'classnames';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {Portlet, PortletContent, TextPlaceholderWrapper} from '../../../../../components';
import {onCloseWithBackdropCurry} from '../../../../../services/util/helperFuncs';

function DriverSelectDialog(props) {

  const {classes, t, driverSelectDialogState, handleCancel, handleClose, drivers} = props;
  const [selectedDriver, setSelectedDriver] = useState(null);

  const tourMicroHubName = driverSelectDialogState.tour ? driverSelectDialogState.tour.microHubName : null;
  const driversToDisplay = drivers.filter(driver => driver.microhubs.map(hub => hub.name).includes(tourMicroHubName));

  const handleOk = () => {
    handleClose(selectedDriver.email, selectedDriver.displayName);
    setSelectedDriver(null);
  };

  const handleNotOk = () => {
    handleCancel();
    setSelectedDriver(null);
  };

  const displayDriverTypes = (bicycle, car, truck) => {
    const array = [];
    if (bicycle) {
      array.push(t('bicycle'));
    }
    if (car) {
      array.push(t('car'));
    }
    if (truck) {
      array.push(t('truck'));
    }
    return array.join(', ');
  };

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      maxWidth={'lg'}
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={driverSelectDialogState.open}
    >
      <DialogTitle>{t('selectDriverDialogTitle')}</DialogTitle>
      <DialogContent>
        <TextPlaceholderWrapper
          active={!driversToDisplay?.length}
          text={t('noDriversFoundForTour')}
        >
          <Portlet className={classes.stopTable}>
            <PortletContent noPadding>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.alignLeft}>{t('email')}</TableCell>
                    <TableCell className={classes.alignLeft}>{t('driverDisplayName')}</TableCell>
                    <TableCell className={classes.alignLeft}>{t('firstName')}</TableCell>
                    <TableCell className={classes.alignLeft}>{t('lastName')}</TableCell>
                    <TableCell className={classes.alignLeft}>{t('driverType')}</TableCell>
                    <TableCell className={classes.alignLeft}>{t('microHubs')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {driversToDisplay.map((driver, index) => (
                    <TableRow
                      className={classNames(classes.tableRow, 'cursorPointer')}
                      hover
                      key={index}
                      onClick={() => setSelectedDriver(driver)}
                      selected={selectedDriver && driver.email === selectedDriver.email}
                    >
                      <TableCell className={classes.tableCell}>
                        {driver.email}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {driver.displayName}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {driver.firstname}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {driver.lastname}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {displayDriverTypes(driver.bicycle, driver.car, driver.truck)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {driver.microhubs.map(hub => hub.name).join(', ')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </PortletContent>
          </Portlet>
        </TextPlaceholderWrapper>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleNotOk}
        >
          {t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          disabled={!selectedDriver}
          onClick={handleOk}
        >
          {t('dialogOk')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DriverSelectDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  driverSelectDialogState: PropTypes.object.isRequired,
  drivers: PropTypes.array.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(DriverSelectDialog);
