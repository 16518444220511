import React, {useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import BoxTypeEdit from '../BoxTypeEdit';
import {BoxTypeKind} from '../../../services/enums/BoxTypeKinds';
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import {onCloseWithBackdropCurry} from '../../../services/util/helperFuncs';

function CreateBoxTypeDialog(props) {

  const [newBoxType, setNewBoxType] = useState({
    depth: 0,
    height: 0,
    identifier: '',
    kind: BoxTypeKind.Box,
    stackable: true,
    volume: 0,
    width: 0,
    description: '',
  });

  const {classes, t, createBoxTypeDialogState, handleClose, handleCancel} = props;

  const resetState = () => {
    setNewBoxType({
      depth: 0,
      height: 0,
      identifier: '',
      kind: BoxTypeKind.Box,
      stackable: true,
      volume: 0,
      width: 0,
      description: '',
    });
  };

  const handleOk = () => {
    handleClose({...newBoxType, identifier: `${createBoxTypeDialogState.shipperShortName}-${newBoxType.identifier}`});
    resetState();
  };

  const handleCancelInner = () => {
    handleCancel();
    resetState();
  };


  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={createBoxTypeDialogState.open}
    >
      <DialogTitle>{t('createBoxType')}</DialogTitle>
      <DialogContent>
        <div className={classes.container}>
          <BoxTypeEdit
            className={classes.container}
            editedBoxType={newBoxType}
            setEditedBoxType={setNewBoxType}
            shipperShortName={createBoxTypeDialogState.shipperShortName}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={handleCancelInner}
        >
          <CancelIcon/>&nbsp;{t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          disabled={
            !newBoxType.identifier
            || !newBoxType.kind
            || newBoxType.volume === '' || isNaN(newBoxType.volume) || parseFloat(newBoxType.volume) <= 0
            || newBoxType.height === '' || isNaN(newBoxType.height) || parseFloat(newBoxType.height) <= 0
            || newBoxType.width === '' || isNaN(newBoxType.width) || parseFloat(newBoxType.width) <= 0
            || newBoxType.depth === '' || isNaN(newBoxType.depth) || parseFloat(newBoxType.depth) <= 0
            || !createBoxTypeDialogState.shipperShortName // should not happen but just to prevent bad data going to backend
          }
          onClick={handleOk}
        >
          <SaveIcon/>&nbsp;{t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CreateBoxTypeDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  createBoxTypeDialogState: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(CreateBoxTypeDialog);
