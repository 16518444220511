import React, {Fragment, useState} from 'react'
import {Box, Button, Collapse, IconButton, TableCell, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon,} from '@material-ui/icons'
import {TextPlaceholderWrapper, TourMetadataIcon} from '../../index';
import StopTable from '../StopTable';
import ProgressDisplay from '../ProgressDisplay';
import MigrationStatus from '../MigrationStatus';
import {getMigrationStatus} from '../../../services/util/migrationStatusHelper';

function TourTableRow(props) {

  const {classes, t, tourWithStops, className, migrateTour, previewDeadlineElapsed, finalDeadlineElapsed} = props;
  const [open, setOpen] = useState(false);

  // calculate counts
  const totalStopsCount = tourWithStops.stops.length;
  const migratedStopsCount = tourWithStops.stops.filter(s => s.isMigrated).length;
  const stopsWithFinalStopCount = tourWithStops.stops.filter(s => s.hasFinalStop).length;
  const stopWithOnlyDeclinedStopCount = tourWithStops.stops.filter(s => s.hasDeclinedStop && !s.hasFinalStop).length;

  const {
    migrationNotPossible,
    migrated,
    readyForMigration,
    migrationPossible,
    additionalMigrationRequired,
    declinedStopsPresent
  } = getMigrationStatus(totalStopsCount, migratedStopsCount, stopsWithFinalStopCount, previewDeadlineElapsed, finalDeadlineElapsed, stopWithOnlyDeclinedStopCount);


  const rootClassName = classNames(className, classes.root, {[classes.deadlinesInvalid]: !previewDeadlineElapsed || finalDeadlineElapsed});

  return (
    <Fragment>
      <TableRow
        className={rootClassName}
        hover={previewDeadlineElapsed && !open && !finalDeadlineElapsed}
        selected={open && previewDeadlineElapsed && !finalDeadlineElapsed}
        style={{boxShadow: `inset 10px 0px 0px 0px ${tourWithStops.color}`}}
      >
        <TableCell className={classes.idTableCell}>
          {tourWithStops.id}
          <TourMetadataIcon metadata={tourWithStops.tourMetaData}/>
        </TableCell>
        <TableCell>
          <MigrationStatus
            additionalMigrationRequired={additionalMigrationRequired}
            declinedStop={declinedStopsPresent}
            finalDeadlineElapsed={finalDeadlineElapsed}
            migrated={migrated}
            migrationNotPossible={migrationNotPossible}
            migrationPossible={migrationPossible}
            previewDeadlineNotElapsed={!previewDeadlineElapsed}
            readyForMigration={readyForMigration}
          />
        </TableCell>
        <TableCell>{totalStopsCount}</TableCell>
        <TableCell>{migratedStopsCount}</TableCell>
        <TableCell>
          <ProgressDisplay
            currentValue={stopsWithFinalStopCount}
            maxValue={totalStopsCount}
          />
        </TableCell>
        <TableCell className={classes.lastTableCell}>
          <Button
            color="primary"
            disabled={migrated || migrationNotPossible || !previewDeadlineElapsed || finalDeadlineElapsed}
            onClick={() => migrateTour(tourWithStops.id)}
            variant={'contained'}
          >
            {t('migrate')}
          </Button>
          <IconButton
            onClick={() => setOpen(!open)}
            size="small"
          >
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={8}
          style={{paddingBottom: 0, paddingTop: 0}}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <Box margin={1}>
              <TextPlaceholderWrapper
                active={!tourWithStops.stops?.length}
                text={t('noPreviewStopsForThisPreviewTour')}
              >
                <StopTable
                  className={classes.previewStopTable}
                  finalDeadlineElapsed={finalDeadlineElapsed}
                  previewDeadlineElapsed={previewDeadlineElapsed}
                  stops={tourWithStops.stops}
                />
              </TextPlaceholderWrapper>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

TourTableRow.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  finalDeadlineElapsed: PropTypes.bool.isRequired,
  migrateTour: PropTypes.func.isRequired,
  previewDeadlineElapsed: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  tourWithStops: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TourTableRow);
