import React from 'react';
import {Tab, Tabs,} from '@material-ui/core';
import PropTypes from 'prop-types';


function ShipperTabs(props) {
  const {options, value, setValue, className} = props;

  return (
    <Tabs
      className={className}
      onChange={(event, newValue) =>
        setValue({
          shipperName: options[newValue].shipperName,
          tabIndex: newValue,
          shipperShortName: options[newValue].shipperShortName
        })}
      value={value?.tabIndex}
    >
      {options.map(so =>
        <Tab
          key={so.tabIndex}
          label={so.shipperShortName}
          value={so.tabIndex}
        />
      )}
    </Tabs>
  );
}

ShipperTabs.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    shipperName: PropTypes.string.isRequired,
    shipperShortName: PropTypes.string.isRequired,
    tabIndex: PropTypes.number.isRequired
  })).isRequired,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.shape({
    shipperName: PropTypes.string.isRequired,
    shipperShortName: PropTypes.string.isRequired,
    tabIndex: PropTypes.number.isRequired
  }),
};

export default ShipperTabs;
