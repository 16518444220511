export default theme => ({
  root: {
    minWidth: '350px'
  },
  timerTableCell: {
    minWidth: '60px',
  },
  portletHeader: {
    height: '40px'
  },
  grid: {
    height: '100%'
  },
  gridItem: {
    height: '50%',
    border: `1px solid ${theme.palette.border}`
  },
  centerHorizontal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(1)
  },
  centerHorizontalAndVertical: {
    height: '70%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  flexRowDense: {
    display: 'flex',
    flexDirection: 'row'
  },
  contentContainer: {
    padding: theme.spacing(1),
  },
  inlineBlock: {
    display: 'inline-block',
    fontSize: '0.9rem'
  },
  separator: {
    marginBlockStart: '0.25em',
    marginBlockEnd: '0.25em'
  },
  deadlineWrapper: {
    flexBasis: '50%',
    color: '#12161B',
    fontSize: '14px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '21px',
    letterSpacing: '-0.05px',
  },
  portletContentStyle: {
    maxHeight: '90%',
    overflow: 'auto'
  },
});
