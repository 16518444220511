import {compose} from 'recompose';
import {Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import Paper from '@material-ui/core/Paper';
import InvoiceContent from './InvoiceContent';
import StatisticContent from './StatisticContent';


function AggregatedCard(props){
  const {t, classes, aggregatedData, tabState} = props;

  const renderContent = (tabState) => {
    switch(tabState) {
      case 0:
        return <InvoiceContent aggregatedData={aggregatedData}/>
      case 1:
        return <StatisticContent aggregatedData={aggregatedData}/>
      default:
        return;
    }
  }

  return(
    <Paper
      className={classes.paper}
      elevation={1}
      outlined={'true'}
      squared={'true'}
    >
      <Typography
        className={classes.aggregatedTitle}
        variant="h4"
      >
        {t('aggregatedCardTitle')}
      </Typography>
      {aggregatedData && renderContent(tabState)}
    </Paper>
  )
}

AggregatedCard.propTypes = {
  aggregatedData: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  tabState: PropTypes.number.isRequired,
};


export default compose(withStyles(styles), withTranslation())(AggregatedCard);