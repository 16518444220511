import React from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Droppable} from 'react-beautiful-dnd'
import Tour from '../Tour';
import {DndHelperElementTypes, DndHelperService} from '../../../../../services/dndHelper/dndHelperService';
import {ExpandMore as ExpandMoreIcon} from '@material-ui/icons';
import {CapacityHelperService} from '../../../../../services/util/capacityHelper';

function Hub(props) {

  const {classes, hub, t, className} = props;

  const rootClassName = classNames(classes.root, className);
  const uniqueHubId = DndHelperService.getUniqueHubId(hub.microHubName, hub.carrierName);

  return (
    <Accordion className={rootClassName}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
      >
        <Typography className={classes.headerSectionLarge}>
          <strong>{t('microHub')}: {hub.microHubName}</strong>
        </Typography>
        <Typography className={classes.headerSection}>
          <strong>{t('tours')}</strong>: {hub.tours ? hub.tours.length : 0}
        </Typography>
        <Typography className={classes.headerSection}>
          <strong>{t('stops')}</strong>: {CapacityHelperService.getStopAmountOfHub(hub)}
        </Typography>
        <Typography className={classes.headerSection}>
          <strong>{t('boxAmount')}</strong>: {CapacityHelperService.getBoxAmountOfHub(hub)}
        </Typography>
        <Typography className={classes.headerSection}>
          <strong>{t('weight')}</strong>: {CapacityHelperService.getWeightOfHub(hub)}
        </Typography>
        <Typography className={classes.headerSection}>
          <strong>{t('volume')}</strong>: {CapacityHelperService.getVolumeOfHub(hub)}
        </Typography>
      </AccordionSummary>
      <hr className={classes.hr}/>
      <AccordionDetails>
        <Droppable
          droppableId={uniqueHubId}
          isDropDisabled
          type={DndHelperElementTypes.Hub}
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={classes.tourContainer}
            >
              {hub.tours.map((tour, index) => {
                return (
                  <Tour
                    className={classes.tour}
                    index={index}
                    key={tour.id}
                    tour={tour}
                    uniqueHubId={uniqueHubId}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </AccordionDetails>
    </Accordion>
  );
}


Hub.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  hub: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(Hub);
