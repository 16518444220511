import React, {Component} from 'react';
import {Router} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/styles';
import {createBrowserHistory} from 'history';
import theme from './theme';
import Routes from './Routes';
import {ApolloProvider} from 'react-apollo';
import {ApolloClient, InMemoryCache} from 'apollo-boost';
import {createHttpLink} from 'apollo-link-http';
import {GlobalDialogProvider} from './components';
import {DatePickerDefaultValueContextProvider} from './context';


const browserHistory = createBrowserHistory();


const link = createHttpLink(
  {
    uri: process.env.REACT_APP_API_URI,
  }
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});


export default class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <DatePickerDefaultValueContextProvider>
            <GlobalDialogProvider>
              <Router history={browserHistory}>
                <Routes/>
              </Router>
            </GlobalDialogProvider>
          </DatePickerDefaultValueContextProvider>
        </ApolloProvider>
      </ThemeProvider>
    )
  }
}
