import React, {Fragment, useState} from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  Typography,
  withStyles
} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {Cancel as CancelIcon, DeleteForever as DeleteIcon, Info as InformationIcon} from '@material-ui/icons';
import {onCloseWithBackdropCurry} from 'services/util/helperFuncs';

function DeleteTemplateStopDialog(props) {

  const { classes, t, dialogState, handleCancel, handleClose, needsConfirm } = props;
  const [confirmPhrase, setConfirmPhrase] = useState('');


  const handleOk = () => {
    handleClose(dialogState.templateStopInstance.id);
    setConfirmPhrase('');
  };

  const handleNotOk = () => {
    handleCancel();
    setConfirmPhrase('');
  };

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      maxWidth={'md'}
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={dialogState.open}
    >
      <DialogTitle>{t('templateStopInstanceDelete')}</DialogTitle>
      <DialogContent>
        {dialogState.templateStopInstance &&
          <Fragment>
            <InformationIcon />
            <Typography>
              {t('deleteTemplateStopInstance')}
            </Typography>
            {needsConfirm &&
              <Fragment>
                <Typography>
                  {t('confirmDeleteTemplateStopInstanceIdInput')}
                </Typography>
                <br />
                <Typography>
                  {t('templateStopInstanceId')}:&nbsp;<strong>{dialogState.templateStopInstance.id}</strong>
                </Typography>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    htmlFor="templateStopInstanceId"
                    required
                  >{t('templateStopInstanceId')}</InputLabel>
                  <Input
                    error={confirmPhrase !== dialogState.templateStopInstance.id.toString()}
                    id="templateStopInstanceId"
                    onChange={(event) => {
                      setConfirmPhrase(event.target.value)
                    }}
                    value={confirmPhrase}
                  />
                </FormControl>
              </Fragment>
            }
          </Fragment>
        }
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={handleNotOk}
        >
          <CancelIcon />&nbsp;{t('dialogCancel')}
        </Button>
        <Button
          className={classes.dangerColor}
          color="primary"
          disabled={!dialogState.templateStopInstance || (needsConfirm && confirmPhrase !== dialogState.templateStopInstance.id.toString())}
          onClick={handleOk}
        >
          <DeleteIcon />{t('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteTemplateStopDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogState: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  needsConfirm: PropTypes.bool,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(DeleteTemplateStopDialog);
