export const ProblemNotificationType = {
  PreviewStop: 1,
  FinalStop: 2,
  PreviewTour: 3,
  FinalTour: 4,
  FinalMicroHub: 5,
  PreviewFinalMicroHub: 6,
  Driver: 7,
  Vehicle: 8,
}


export function getProblemNotificationTypeString(type) {
  switch (type) {
    case ProblemNotificationType.PreviewStop:
      return 'problemNotificationTypePreviewStop';
    case ProblemNotificationType.FinalStop:
      return 'problemNotificationTypeFinalStop';
    case ProblemNotificationType.PreviewTour:
      return 'problemNotificationTypePreviewTour';
    case ProblemNotificationType.FinalTour:
      return 'problemNotificationTypeFinalTour';
    case ProblemNotificationType.FinalMicroHub:
      return 'problemNotificationTypeFinalMicroHub';
    case ProblemNotificationType.PreviewFinalMicroHub:
      return 'problemNotificationTypePreviewFinalMicroHub';
    case ProblemNotificationType.Driver:
      return 'problemNotificationTypeDriver';
    case ProblemNotificationType.Vehicle:
      return 'problemNotificationTypeVehicle';
    default:
      return 'problemNotificationTypeUndefined'
  }
}

export function getProblemNotificationTypeDescriptionString(type) {
  switch (type) {
    case ProblemNotificationType.PreviewStop:
      return 'problemNotificationTypePreviewStopDescription';
    case ProblemNotificationType.FinalStop:
      return 'problemNotificationTypeFinalStopDescription';
    case ProblemNotificationType.PreviewTour:
      return 'problemNotificationTypePreviewTourDescription';
    case ProblemNotificationType.FinalTour:
      return 'problemNotificationTypeFinalTourDescription';
    case ProblemNotificationType.FinalMicroHub:
      return 'problemNotificationTypeFinalMicroHubDescription';
    case ProblemNotificationType.PreviewFinalMicroHub:
      return 'problemNotificationTypePreviewFinalMicroHubDescription';
    case ProblemNotificationType.Driver:
      return 'problemNotificationTypeDriverDescription';
    case ProblemNotificationType.Vehicle:
      return 'problemNotificationTypeVehicleDescription';
    default:
      return 'problemNotificationTypeUndefined'
  }
}
