export default theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
    backgroundColor: (props) => props.tourWithStops.editable ? theme.palette.common.white : theme.palette.common.muted,
  },
  alignRight: {
    align: 'right',
  },
  previewStopTable: {
    border: `1px solid ${theme.palette.border}`,
  },
  iconButtonActivated: {
    padding: '0',
    '&:disabled': {
      color: theme.palette.primary.main
    }
  },
  iconButton: {
    padding: '0'
  }
});

