export default () => ({
  errorWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  },
  errorIcon: {
    textAlign: 'center'
  },
});

