export function getMigrationStatus(totalStopsCount, migratedStopsCount, stopsWithFinalStopCount, previewDeadlineElapsed, finalDeadlineElapsed, stopWithOnlyDeclinedStopCount) {

  const migrationNotPossible = previewDeadlineElapsed && !finalDeadlineElapsed && stopsWithFinalStopCount === 0; // migration is first possible when preview deadline is elapsed and at least one preview stop has a final stop
  const migrated = migratedStopsCount === stopsWithFinalStopCount && migratedStopsCount > 0 && !migrationNotPossible; // all preview stops are successfully migrated
  const readyForMigration = stopsWithFinalStopCount === totalStopsCount && migratedStopsCount === 0 && !migrated; // all preview stops have a final stop, but there has been no migration yet
  const migrationPossible = stopsWithFinalStopCount > 0 && migratedStopsCount === 0 && !readyForMigration; // there are final stops for some preview stops but not for all preview stops
  const additionalMigrationRequired = migratedStopsCount > 0 && stopsWithFinalStopCount > migratedStopsCount && !migrationPossible; // there already was a migration but there are more not migrated preview stops with a final stop now
  const declinedStopsPresent = stopWithOnlyDeclinedStopCount > 0;

  return {
    migrationNotPossible,
    migrated,
    readyForMigration,
    migrationPossible,
    additionalMigrationRequired,
    declinedStopsPresent
  }
}
