import React, {Fragment} from 'react'
import {IconButton, Table, TableBody, TableCell, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Add as AddIcon, DeleteForever as DeleteRowIcon} from '@material-ui/icons';
import EmptyAddRow from './EmptyAddRow';
import {getEmptyTypeString} from '../../../../services/enums/EmptyType';


function EmptiesTableEdit(props) {

  const {
    classes,
    t,
    className,
    empties,
    shipperEmptiesSelectOptions,
    itemsToAdd,
    updateItem,
    addItem,
    deleteItem,
    otherEmptiesValid,
    allowCustomEmptyTypes
  } = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <Table
      className={rootClassName}
      size="small"
    >
      <TableHead>
        <TableRow>
          <TableCell>{t('emptyName')}</TableCell>
          <TableCell>{t('amount')}</TableCell>
          <TableCell>{t('emptyType')}</TableCell>
          <TableCell/>
        </TableRow>
      </TableHead>
      <TableBody>
        {empties.map((empty, index) =>
          <TableRow
            className={classes.addRow}
            key={index}
          >
            <TableCell>{empty.name}</TableCell>
            <TableCell>{empty.amount}</TableCell>
            <TableCell>{t(getEmptyTypeString(empty.emptyType))}</TableCell>
            <TableCell>
              <IconButton
                className={classes.buttonRight}
                color="primary"
                onClick={() => deleteItem(empty)}
                title={t('delete')}
              >
                <DeleteRowIcon className={classes.redIcon}/>
              </IconButton>
            </TableCell>
          </TableRow>
        )}
        {itemsToAdd.map((value, index) => {
          return(
            <EmptyAddRow
              allowCustomEmptyTypes={allowCustomEmptyTypes}
              deleteItem={deleteItem}
              itemToAdd={value}
              key={index}
              shipperEmptiesSelectOptions={shipperEmptiesSelectOptions}
              updateItem={updateItem}
            />
          )
        })}
        <TableRow className={classes.addRow}>
          <Fragment>
            <TableCell colSpan={3}>
              <IconButton
                color="primary"
                disabled={!otherEmptiesValid}
                onClick={addItem}
              >
                <AddIcon/>
              </IconButton>
            </TableCell>
          </Fragment>
        </TableRow>
      </TableBody>
    </Table>
  );
}

EmptiesTableEdit.propTypes = {
  addItem: PropTypes.func.isRequired,
  allowCustomEmptyTypes: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deleteItem: PropTypes.func.isRequired,
  empties: PropTypes.array.isRequired,
  i18n: PropTypes.object.isRequired,
  itemsToAdd: PropTypes.array.isRequired,
  otherEmptiesValid: PropTypes.bool.isRequired,
  shipperEmptiesSelectOptions: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
}
;

export default compose(withStyles(styles), withTranslation())(EmptiesTableEdit);
