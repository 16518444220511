import React from 'react'
import {Checkbox, FormControlLabel, withStyles,} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {NumberInput} from '../../../../../index';
import {compose} from 'recompose';
import styles from './styles';

function CapacitySelection(props) {

  const {classes, className, t, capacity, setCapacity} = props;

  return (
    <div className={className}>
      <div className={classes.row}>
        <FormControlLabel
          className={className}
          control={
            <Checkbox
              checked={capacity.weight !== null}
              color="secondary"
              onChange={(event) => setCapacity({...capacity, weight: event.target.checked ? 0 : null})}
              value={capacity.weight !== null}
            />
          }
          label={t('weight')}
        />
        {capacity.weight !== null ?
          <NumberInput
            setValue={(value) => setCapacity({...capacity, weight: value})}
            value={capacity.weight}
          />
          :
          <div style={{width: '100px'}}/>
        }
      </div>
      <div className={classes.row}>
        <FormControlLabel
          className={className}
          control={
            <Checkbox
              checked={capacity.volume !== null}
              color="secondary"
              onChange={(event) => setCapacity({...capacity, volume: event.target.checked ? 0 : null})}
              value={capacity.volume !== null}
            />
          }
          label={t('volume')}
        />
        {capacity.volume !== null ?
          <NumberInput
            setValue={(value) => setCapacity({...capacity, volume: value})}
            value={capacity.volume}
          />
          :
          <div style={{width: '100px'}}/>
        }
      </div>
    </div>
  );
}

CapacitySelection.propTypes = {
  capacity: PropTypes.object.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  setCapacity: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(CapacitySelection);
