export const TrackingStepType = {
  PreviewStopUploaded: 1,
  PreviewDeadlineExpired: 2,
  FinalDeadlineExpired: 3,
  TourApproved: 4,
  TourStarted: 5,
  PreviousStopsDelivered: 6,
  Delivered: 7,
  Problem: 99,
};

export function getTrackingStepTypeString(trackingStepType) {
  switch (trackingStepType) {
    case TrackingStepType.PreviewStopUploaded:
      return 'trackingStepTypePreviewStopUploaded';
    case TrackingStepType.PreviewDeadlineExpired:
      return 'trackingStepTypePreviewDeadlineExpired';
    case TrackingStepType.FinalDeadlineExpired:
      return 'trackingStepTypeFinalDeadlineExpired';
    case TrackingStepType.TourApproved:
      return 'trackingStepTypeTourApproved';
    case TrackingStepType.TourStarted:
      return 'trackingStepTypeTourStarted';
    case TrackingStepType.PreviousStopsDelivered:
      return 'trackingStepTypePreviousStopsDelivered';
    case TrackingStepType.Delivered:
      return 'trackingStepTypeDelivered';
    case TrackingStepType.Problem:
      return 'trackingStepTypeProblem';
    default:
      return 'trackingStepTypeUndefined';
  }
}

export function getCustomerFriendlyTrackingStepTypeName(trackingStepType) {
  switch (trackingStepType) {
    case TrackingStepType.PreviewStopUploaded:
      return 'Bestellung eingegangen';
    case TrackingStepType.PreviewDeadlineExpired:
      return 'Bestellungseingang abgeschlossen';
    case TrackingStepType.FinalDeadlineExpired:
      return 'Lieferung bestätigt';
    case TrackingStepType.TourApproved:
      return 'Tour an Fahrer übermittelt';
    case TrackingStepType.TourStarted:
      return 'Tour gestartet';
    case TrackingStepType.PreviousStopsDelivered:
      return 'In Zustellung';
    case TrackingStepType.Delivered:
      return 'Zustellung';
    case TrackingStepType.Problem:
      return 'Verzögerung'
    default:
      return 'Unbekannter Tracking-Schritt';
  }
}
