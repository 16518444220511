import React, {Fragment} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {DateService} from '../../../services/util/DateService';
import {getProductTypeString, ProductType} from '../../../services/enums/ProductType';
import {ProductTable, StopIdLabel, StoragePlaces} from '../../../components';
import {displayShipperAndShipperShortName, roundCapacityValues} from '../../../services/util/helperFuncs';


function TourStopTableSmallDetails(props) {

  const {classes, t, stop} = props;

  return (
    <div className={classes.wrapper}>
      <div className={classes.wrapperChild}>
        <div className={classes.stopDetail}><strong>{t('stopId')}</strong>: <StopIdLabel stop={stop}/></div>
        <div className={classes.stopDetail}><strong>{t('firstName')}</strong>: {stop.firstName}</div>
        <div className={classes.stopDetail}><strong>{t('lastName')}</strong>: {stop.lastName}</div>
        <div className={classes.stopDetail}><strong>{t('internalCustomerId')}</strong>: {stop.internalCustomerId}</div>
        <div className={classes.stopDetail}><strong>{t('customerId')}</strong>: {stop.customerId}</div>
        <div className={classes.stopDetail}>
          <strong>{t('address')}</strong>: {stop.address.streetAndNumber + ' | ' + stop.address.zipcode + ' ' + stop.address.city}
        </div>
        <div className={classes.stopDetail}><strong>{t('shipper')}</strong>: {displayShipperAndShipperShortName(stop)}
        </div>
        <div className={classes.stopDetail}><strong>{t('microHub')}</strong>: {stop.assignedMicroHubName}</div>
        <div className={classes.stopDetail}><strong>{t('previewOrderLong')}</strong>: {stop.previewOrder}</div>
        <div className={classes.stopDetail}>
          <strong>{t('planedDeliveryDateShipper')}</strong>: {DateService.displayDate(stop.planedDeliveryDateShipper)}
        </div>
        <div className={classes.stopDetail}>
          <strong>{t('deliveryTimeWindowShipper')}</strong>: {stop.deliveryWindowShipper ? `${DateService.displayTime(stop?.deliveryWindowShipper?.from)} - ${DateService.displayTime(stop?.deliveryWindowShipper?.to)}` : '-'}
        </div>
        <div className={classes.stopDetail}>
          <strong>{t('planedDeliveryDateCarrier')}</strong>: {DateService.displayDate(stop.planedDeliveryDateCarrier)}
        </div>
        <div className={classes.stopDetail}><strong>{t('storagePlace')}</strong>:&nbsp;
          <StoragePlaces
            maxDisplay={3}
            stop={stop}
          />
        </div>
        <div className={classes.stopDetail}>
          <strong>{t('estimatedDeliveryWindow')}</strong>: {stop.estimatedDeliveryWindow && DateService.displayTime(stop.estimatedDeliveryWindow.from)} - {stop.estimatedDeliveryWindow && DateService.displayTime(stop.estimatedDeliveryWindow.to)}
        </div>
      </div>
      <div className={classes.wrapperChild}>
        <div className={classes.stopDetail}>
          <div><strong>{t('stopCapacity')}</strong>:</div>
          {stop.stopCapacities &&
          <Fragment>
            <div className={classes.stopDetail}><strong>{t('amountOfBoxes')}</strong>: {roundCapacityValues(stop.stopCapacities.boxAmount)}</div>
            <div className={classes.stopDetail}><strong>{t('weight')}</strong>: {roundCapacityValues(stop.stopCapacities.weight)}</div>
            <div className={classes.stopDetail}><strong>{t('volume')}</strong>: {roundCapacityValues(stop.stopCapacities.volume)}</div>
          </Fragment>
          }
        </div>
        <br/>
        <div><strong>{t('customerInfo')}</strong>:</div>
        <div className={classes.stopDetail}>{stop.customerInfo}</div>
      </div>
      <div className={classes.wrapperChild}>
        <div className={classes.stopDetail}>
          <strong>{`${t(getProductTypeString(ProductType.Box))} / ${t(getProductTypeString(ProductType.BeverageBox))}`}</strong>:
        </div>
        <ProductTable
          className={classes.stopDetail}
          notExpandable
          products={stop.products.filter(product => product.productType === ProductType.Box || product.productType === ProductType.BeverageBox)}
          productType={ProductType.Box}
        />
        <br/>
        <div className={classes.stopDetail}><strong>{t(getProductTypeString(ProductType.PackageInserts))}</strong>:</div>
        <ProductTable
          className={classes.stopDetail}
          notExpandable
          products={stop.packageInserts}
          productType={ProductType.PackageInserts}
        />
        <br/>
        <div className={classes.stopDetail}><strong>{t(getProductTypeString(ProductType.Other))}</strong>:</div>
        <ProductTable
          className={classes.stopDetail}
          notExpandable
          products={stop.products.filter(product => product.productType === ProductType.Other)}
          productType={ProductType.Other}
        />
        <br/>
      </div>
    </div>
  );
}

TourStopTableSmallDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  stop: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TourStopTableSmallDetails);
