import React, {useState} from 'react';
import * as PropTypes from 'prop-types';
import {NewsToUserDialogContext} from '../../../../context';

const NewsToUserDialogContextProvider = (props) => {
  const {children} = props;
  const [dialogState, setDialogState] = useState({open: false, newsToUser: []});
  const close = () => {
    setDialogState({open: false, newsToUser: []});
  };
  const open = (newsToUser) => {
    setDialogState({open: true, newsToUser: newsToUser});
  };
  const markNewsAsRead = (newsToUserId) => {
    setDialogState({...dialogState, newsToUser: dialogState.newsToUser.filter(news => news.id !== newsToUserId)});
  }

  return (
    <NewsToUserDialogContext.Provider
      value={{
        dialogState: dialogState,
        close: close,
        open: open,
        markNewsAsRead: markNewsAsRead
      }}
    >
      {children}
    </NewsToUserDialogContext.Provider>
  )
}

NewsToUserDialogContextProvider.propTypes = {
  children: PropTypes.node,
}

export default NewsToUserDialogContextProvider;