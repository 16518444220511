import React from 'react'
import {FormControl, Input, InputLabel, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {Portlet, PortletContent} from '../../../index';
import classNames from 'classnames';

function CreatePerson(props) {
  const {t, classes, className, person, setPerson, title, required} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet className={rootClassName}>
      <PortletContent>
        <Typography variant="h3">
          {title}
        </Typography>
        <hr/>
        <FormControl className={classes.formControl}>
          <InputLabel>{t('name')}</InputLabel>
          <Input
            onChange={(event) => {
              setPerson({...person, name: event.target.value})
            }}
            value={person.name}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel
            required={required}
          >{t('email')}</InputLabel>
          <Input
            error={required && !person.email}
            onChange={(event) => {
              setPerson({...person, email: event.target.value})
            }}
            required={required}
            value={person.email}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel
            required={required}
          >{t('phoneNumber')}</InputLabel>
          <Input
            error={required && !person.phoneNumber}
            onChange={(event) => {
              setPerson({...person, phoneNumber: event.target.value})
            }}
            required={required}
            value={person.phoneNumber}
          />
        </FormControl>
      </PortletContent>
    </Portlet>
  );
}

CreatePerson.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  required: PropTypes.bool,
  setPerson: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};


export default compose(withStyles(styles), withTranslation())(CreatePerson);
