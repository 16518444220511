import React, {Fragment} from 'react'
import {Table, TableBody, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import Columns from './columns';
import DefaultConfig from './config';
import useTableConfig from 'hooks/useTableConfig';
import { getTableKey } from 'services/util/tableConfigHelper';
import { TableKind } from 'services/enums/TableKind';
import { Portlet, PortletContent, PortletHeader, TableCellWithConfig, TableSettings } from 'components';
import { displayModes } from 'services/enums/displayModes';

function TourStopTable(props) {

  const {
    classes,
    className,
    tour,
    showStopDetails,
    selectedStop,
    t,
    displayMode
  } = props;

  const rootClassName = classNames(classes.root, className);

  const {
    config,
    columnConfigs,
    updateConfig
  } = useTableConfig(DefaultConfig, displayMode, getTableKey(TableKind.TourStopTable, displayMode))

  return (
    <Fragment>
      <Portlet className={classNames(classes.tourStopTable, rootClassName)}>
        <PortletHeader>
          <Fragment>
            <div><strong>{t('stops')}</strong></div>
            <div><strong>{t('stopAmount')}</strong>: {tour.templateStopInstances.length}</div>
          </Fragment>
        </PortletHeader>
        <PortletContent noPadding>
          <TableSettings
            updateConfig={updateConfig}
            tableConfig={config}
            columns={Columns}
          />
          <Table size="medium">
            <TableHead>
              <TableRow>
                {columnConfigs.map(columnConfig => (
                  <TableCellWithConfig
                    key={columnConfig.order}
                    config={columnConfig}
                  >
                    {Columns[columnConfig.columnName].header({t})}
                  </TableCellWithConfig>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tour.templateStopInstances.map((stop, index) => {
                return (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={index}
                    onClick={() => showStopDetails(stop)}
                    selected={selectedStop && stop.id === selectedStop.id}
                  >
                    {columnConfigs.map(columnConfig => (
                      <TableCellWithConfig
                        key={columnConfig.order}
                        config={columnConfig}
                        className={classes.tableCell}
                      >
                        {Columns[columnConfig.columnName].body({
                          stop,
                          classes,
                          t,
                        })}
                      </TableCellWithConfig>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </PortletContent>
      </Portlet>
    </Fragment>
  );
}


TourStopTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  selectedStop: PropTypes.object,
  showStopDetails: PropTypes.func,
  t: PropTypes.func.isRequired,
  tour: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TourStopTable);
