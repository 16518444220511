import PropTypes from 'prop-types';
import React, {Fragment, useCallback, useEffect, useState} from 'react';
import SideNavLayout from '../../../layouts/SideNavLayout';
import TransferRulesForShippersTable from './components/TransferRulesForShippers';
import styles from './styles';
import {Typography, withStyles} from '@material-ui/core';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import shipperApiConfigService from '../../../services/backend/shipperApiConfigService';
import StandardsForShippersTable from './components/StandardsForShippers';
import useShipperOptions from '../../../hooks/useShipperOptions';
import {DropdownSelectSimple, LoadingWrapper} from '../../../components';
import useMicroHubData from '../../../hooks/useMicroHubData';

function ShipperApiConfig(props) {

  const {classes, t} = props;

  const [backendIsLoadingTransferRulesForShippers, setBackendIsLoadingTransferRulesForShippers] = useState(false);
  const [backendIsLoadingStandardsForShippers, setBackendIsLoadingStandardsForShippers] = useState(false);

  const [transferRulesForShippers, setTransferRulesForShippers] = useState([]);
  const [standardsForShippers, setStandardsForShippers] = useState([]);

  const {shipperOptions} = useShipperOptions();
  const {microHubData} = useMicroHubData();
  const [selectedShipper, setSelectedShipper] = useState('');

  const loadTransferRulesForShippers = useCallback(async (selectedShipper) => {
    setBackendIsLoadingTransferRulesForShippers(true);
    try {
      const result = await shipperApiConfigService.getTransferRulesForShipper(selectedShipper);
      setTransferRulesForShippers(result);
      setBackendIsLoadingTransferRulesForShippers(false);
    } catch (e) {
      setBackendIsLoadingTransferRulesForShippers(false);
      alert(`${t('errorLoadingTransferRulesForShippers')}${e}`);
    }
  }, [t]);

  const loadStandardsForShippers = useCallback(async () => {
    setBackendIsLoadingStandardsForShippers(true);
    try {
      const result = await shipperApiConfigService.getAllStandardsForShippers();
      setStandardsForShippers(result);
      setBackendIsLoadingStandardsForShippers(false);
    } catch (e) {
      setBackendIsLoadingStandardsForShippers(false);
      alert(`${t('errorLoadingStandardsForShippers')}${e}`);
    }
  }, [t]);

  const createTransferRuleForShipper = async (transferRule) => {
    try {
      await shipperApiConfigService.createTransferRuleForShipper(transferRule);
      loadTransferRulesForShippers(selectedShipper);
    } catch (e) {
      alert(`${t('errorCreatingTransferRulesForShipper')}${e}`);
    }
  };

  const deleteTransferRuleForShipper = async (transferRule) => {
    try {
      await shipperApiConfigService.deleteTransferRuleForShipper(transferRule.systemId);
      loadTransferRulesForShippers(selectedShipper);
    } catch (e) {
      alert(`${t('errorDeletingTransferRulesForShipper')}${e}`);
    }
  };

  const createStandardForShipper = async (shipperStandard) => {
    try {
      await shipperApiConfigService.createStandardForShipper(shipperStandard);
      loadStandardsForShippers();
    } catch (e) {
      alert(`${t('errorCreatingStandardForShipper')}${e}`);
    }
  };

  const deleteStandardForShipper = async (shipperStandard) => {
    try {
      await shipperApiConfigService.deleteStandardForShipper(shipperStandard.systemId);
      loadStandardsForShippers();
    } catch (e) {
      alert(`${t('errorDeletingStandardForShipper')}${e}`);
    }
  };

  useEffect(() => {
    if (selectedShipper) {
      loadTransferRulesForShippers(selectedShipper);
    }
  }, [selectedShipper, loadTransferRulesForShippers]);

  useEffect(() => {
    loadStandardsForShippers()
  }, [loadStandardsForShippers]);

  return (
    <SideNavLayout title={t('shipperApiConfig')}>
      <div className={classes.root}>
        <DropdownSelectSimple
          error={!selectedShipper}
          name={t('shipper')}
          options={shipperOptions}
          required
          setValue={(value) => setSelectedShipper(value)}
          sort
          value={selectedShipper}
        />
        <br/>
        <Fragment>
          <Typography variant={'h4'}>
            {t('transferRulesForShippers')}
          </Typography>
          <br/>
          <LoadingWrapper loading={backendIsLoadingTransferRulesForShippers}>
            <TransferRulesForShippersTable
              createTransferRuleForShipper={createTransferRuleForShipper}
              deleteTransferRuleForShipper={deleteTransferRuleForShipper}
              microHubs={microHubData}
              selectedShipper={selectedShipper}
              transferRulesForShippers={transferRulesForShippers && transferRulesForShippers.length > 0 ? transferRulesForShippers : []}
            />
          </LoadingWrapper>
          <br/>
          <Typography variant={'h4'}>
            {t('standardsForShippers')}
          </Typography>
          <br/>
          <LoadingWrapper loading={backendIsLoadingStandardsForShippers}>
            <StandardsForShippersTable
              createStandardForShipper={createStandardForShipper}
              deleteStandardForShipper={deleteStandardForShipper}
              shipperOptions={shipperOptions}
              standardsForShippers={standardsForShippers}
            />
          </LoadingWrapper>
        </Fragment>
      </div>
    </SideNavLayout>
  );
}

ShipperApiConfig.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}

export default compose(withStyles(styles), withTranslation())(ShipperApiConfig);
