import React, {useCallback, useContext, useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {SideNavLayout} from '../../../layouts';
import {TourService} from '../../../services/backend/tourService';
import {
  messageRelevantForFinalDeadlineMigrationFinalStops,
  messageRelevantForFinalDeadlineMigrationPreviewStop,
  messageRelevantForFinalDeadlineMigrationPreviewTour,
} from '../../../services/util/signalRMessageHelper';
import {useStopHub} from '../../../hooks/useStopHub';
import useShipperOptions from '../../../hooks/useShipperOptions';
import {usePreviewStopHub} from '../../../hooks/usePreviewStopHub';
import {usePreviewTourHub} from '../../../hooks/usePreviewTourHub';
import {FinalDeadlineMigrationFilter, FinalDeadlineMigrationView, LoadingWrapper, RefreshButton, TextPlaceholderWrapper} from '../../../components';
import {PreviewToursService} from '../../../services/backend/previewToursService';
import useCarriersWithHubs from '../../../hooks/useCarriersWithHubs';
import shipperDeclinedStopsService from '../../../services/backend/shipperDeclinedStopsService';
import moment from 'moment';
import {DatePickerDefaultValueContext} from '../../../context';

function FinalDeadlineMigration(props) {
  const {classes, t} = props;
  const {fromDateDefault, updateDefaultDate} = useContext(DatePickerDefaultValueContext);

  const [refresh, setRefresh] = useState(false);
  const [finalData, setFinalData] = useState(null);
  const [previewData, setPreviewData] = useState([]);
  const [declinedStops, setDeclinedStops] = useState([]);
  const [isBackendLoading, setIsBackendLoading] = useState(false);
  const [filter, setFilter] = useState({
    carrierName: '',
    microHubName: '',
    shipperName: '',
    date: fromDateDefault,
  });
  useEffect(() => updateDefaultDate(filter.date), [filter.date, updateDefaultDate]);

  const {shipperOptions} = useShipperOptions();
  const {carriersWithHubs} = useCarriersWithHubs();

  useStopHub(useCallback(event => {
    if (messageRelevantForFinalDeadlineMigrationFinalStops(event, filter)) {
      setRefresh(true);
    }
  }, [filter]));
  usePreviewStopHub(useCallback(event => {
    if (messageRelevantForFinalDeadlineMigrationPreviewStop(event, filter)) {
      setRefresh(true);
    }
  }, [filter]));
  usePreviewTourHub(useCallback(event => {
    if (messageRelevantForFinalDeadlineMigrationPreviewTour(event, filter)) {
      setRefresh(true);
    }
  }, [filter]));

  const loadFinalData = useCallback(async (showLoadingIndicator = true) => {
    if (!filter.shipperName) return;
    if (showLoadingIndicator) setIsBackendLoading(true);
    try {
      const response = await TourService.getFinalDeadlineMigrationData(filter.date, filter.shipperName, true);
      setFinalData(response);
    } catch (e) {
      alert(`${t('errorLoadingFinalDeadlineMigrationData')}: ${e}`)
      setIsBackendLoading(false);
      setRefresh(false);
    }
    setIsBackendLoading(false);
    setRefresh(false);
  }, [filter.shipperName, filter.date, t]);

  useEffect(() => {
    loadFinalData();
  }, [loadFinalData]);

  const loadPreviewData = useCallback(async (showLoadingIndicator = true) => {
    if (!filter.shipperName || !filter.carrierName) return;
    if (showLoadingIndicator) setIsBackendLoading(true);
    try {
      const response = await PreviewToursService.getMicroHubsWithToursByFilter(filter.carrierName, filter.shipperName, filter.microHubName ? [filter.microHubName] : [], filter.date, null);
      setPreviewData(response);
    } catch (e) {
      alert(`${t('errorLoadingFinalDeadlineMigrationData')}: ${e}`)
      setIsBackendLoading(false);
      setRefresh(false);
    }
    setIsBackendLoading(false);
    setRefresh(false);
  }, [filter.carrierName, filter.shipperName, filter.microHubName, filter.date, t]);

  useEffect(() => {
    loadPreviewData();
  }, [loadPreviewData]);

  const loadDeclinedStops = useCallback(async () => {
    setIsBackendLoading(true);
    try {
      const result = await shipperDeclinedStopsService.getDeclinedStopsByFilter(filter.shipperName, moment(filter.date).toDate(), 2, 1);
      setDeclinedStops(result)
      setIsBackendLoading(false);
    } catch (e) {
      alert(t('errorLoadingDeclinedStopsData'));
      setIsBackendLoading(false);
    }
  }, [filter.shipperName, filter.date, t])

  useEffect(() => {
    loadDeclinedStops()
  }, [loadDeclinedStops])

  const runMigration = async (migrationData) => {
    if (!window.confirm(t('confirmMigration'))) return;
    try {
      setIsBackendLoading(true);
      await TourService.runFinalMigration(migrationData);
      await loadFinalData();
    } catch (e) {
      alert(`${t('errorRunningFinalMigration')}: ${e}`)
      await loadFinalData();
    }
  }

  return (
    <SideNavLayout title={t('finalDeadlineMigration')}>
      <div className={classes.root}>
        <div className={classes.filterRow}>
          <FinalDeadlineMigrationFilter
            carriersWithMicroHubs={carriersWithHubs}
            filter={filter}
            setFilter={setFilter}
            shipperOptions={shipperOptions}
          />
          <RefreshButton
            refresh={refresh}
            refreshFunc={() => {
              loadFinalData();
              loadPreviewData();
            }}
          />
        </div>
        <TextPlaceholderWrapper
          active={!filter.shipperName}
          text={t('finalDeadlineMigrationNoShipperSelected')}
        >
          <LoadingWrapper loading={isBackendLoading}>
            <FinalDeadlineMigrationView
              declinedStops={declinedStops}
              filter={filter}
              finalPreviewStopIdentifiersAndMigrationState={finalData?.previewStopIdentifiersAndMigrationState ?? []}
              finalStopsWithoutPreviewStop={finalData?.stopsWithoutPreviewStop ?? []}
              finalStopsWithoutPreviewStopMigrationState={finalData?.stopsWithoutPreviewStopsMigrationState ?? []}
              previewData={previewData}
              runMigration={runMigration}
            />
          </LoadingWrapper>
        </TextPlaceholderWrapper>
      </div>
    </SideNavLayout>
  );
}

FinalDeadlineMigration.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(FinalDeadlineMigration);
