import React from 'react'
import {Table, TableBody, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {getMicroHubElementTypeString} from '../../../../../services/hubAdministrationHelper/hubAdministrationHelper';
import classNames from 'classnames'
import TourTableRow from './TourTableRow';
import StopTableRow from './StopTableRow';


function ZoneDetails(props) {

  const {classes, className, t, selectedZoneInfos} = props;
  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <Typography variant="h5">
        <strong>{`${t(getMicroHubElementTypeString(selectedZoneInfos.zone.type))} ${selectedZoneInfos.zone.shipperInfos.map(info => `${info.shortName}-${info.order}`).join(' / ')}`}</strong>
      </Typography>
      <br/>
      <Typography variant="h5">
        <strong>{t('tours')}</strong>
      </Typography>
      <Table
        className={classes.tourTable}
        size="small"
      >
        <TableBody>
          {selectedZoneInfos.toursWithStops && selectedZoneInfos.toursWithStops.map(tour =>
            <TourTableRow
              key={tour.tourId}
              tour={tour}
            />
          )}
        </TableBody>
      </Table>
      <br/>
      <Typography variant="h5">
        <strong>{t('notPlanedStops')}</strong>
      </Typography>
      <Table
        className={classes.tourTable}
        size="small"
      >
        <TableBody>
          {selectedZoneInfos.stopsWithoutTour && selectedZoneInfos.stopsWithoutTour.map(stop =>
            <StopTableRow
              key={stop.tourStopId}
              stop={stop}
            />
          )}
        </TableBody>
      </Table>
    </div>
  );
}

ZoneDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  selectedZoneInfos: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(ZoneDetails);
