import React from 'react'
import {Table, TableBody, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from '../styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Pagination, Portlet, PortletContent, TableCellWithConfig, TableSettings, TextPlaceholderWrapper} from '../..'
import useTablePagination from '../../../hooks/useTablePagination';
import {displayModes} from '../../../services/enums/displayModes';
import useTableConfig from '../../../hooks/useTableConfig';
import DefaultConfig from './config';
import {getTableKey} from '../../../services/util/tableConfigHelper';
import {TableKind} from '../../../services/enums/TableKind';
import Columns from './columns';

function StatisticsTable(props) {

  const {
    classes,
    className,
    statistics,
    t,
    displayMode
  } = props;

  const {
    config,
    columnConfigs,
    updateConfig
  } = useTableConfig(
    DefaultConfig,
    displayMode,
    getTableKey(TableKind.StatisticsTable, displayMode),
  )

  const {paginationProps, pagedObjects} = useTablePagination(statistics);

  const rootClassName = classNames(classes.root, className);

  return (
    <TextPlaceholderWrapper
      active={!statistics?.length}
      text={t('noStatisticsAvailable')}
    >
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <TableSettings
            updateConfig={updateConfig}
            tableConfig={config}
            columns={Columns}
          />
          <Table size="medium">
            <TableHead>
              <TableRow>
                {columnConfigs.map(columnConfig => (
                  <TableCellWithConfig
                    key={columnConfig.order}
                    config={columnConfig}
                  >
                    {Columns[columnConfig.columnName].header({t})}
                  </TableCellWithConfig>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedObjects.map(statistic => (
                <TableRow
                  className={classes.tableRow}
                  key={statistic.tourId}
                  style={{boxShadow: `inset 10px 0px 0px 0px ${statistic.color}`}}
                >
                  {columnConfigs.map(columnConfig => (
                    <TableCellWithConfig
                      key={columnConfig.order}
                      config={columnConfig}
                      className={classes.tableCell}
                    >
                      {Columns[columnConfig.columnName].body({
                        statistic, classes
                      })}
                    </TableCellWithConfig>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination paginationProps={paginationProps}/>
        </PortletContent>
      </Portlet>
    </TextPlaceholderWrapper>
  );
}


StatisticsTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  statistics: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired
};

StatisticsTable.defaultProps = {
  statistics: [],
};


export default compose(withStyles(styles), withTranslation())(StatisticsTable);
