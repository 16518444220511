export default theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
  },
  section: {
    marginBottom: theme.spacing(1),
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  logo: {
    height: 66,
    [theme.breakpoints.up('md')]: {
      height: 234,
    }
  }
});

