export default theme => ({
  importButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  actionsContainerImport: {
    marginTop: theme.spacing(3),
  },
  buttonWrapper: {
    marginBottom: theme.spacing(1),
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  iconMargin: {
    marginRight: '8px'
  }
});
