import React, {Fragment} from 'react'
import {FormControl, Input, InputLabel, MenuItem, Select, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography';
import {InfoTooltip} from '../../index';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function MultiSelectDropdownSelect(props) {

  const {
    classes,
    className,
    value,
    setValue,
    disabled,
    options,
    descriptionKey,
    name,
    maxItemsDisplayed,
    sort,
    hideOnEmptyOptions
  } = props;

  const rootClassName = classNames(classes.formControl, className);

  return (
    <Fragment>
      {(!hideOnEmptyOptions || options?.length > 0) &&
        <InfoTooltip descriptionKey={descriptionKey}>
          <FormControl className={rootClassName}>
            <InputLabel
              htmlFor={name}
            >
              {name}
            </InputLabel>
            <Select
              disabled={disabled}
              input={<Input id={name}/>}
              MenuProps={MenuProps}
              multiple
              onChange={event => setValue(event.target.value)}
              renderValue={selected => (
                <div
                  className={classes.chips}
                >
                  <Typography variant={'body2'}>
                    {selected.length > maxItemsDisplayed ? selected.slice(0, maxItemsDisplayed).join(', ') + '...' : selected.join(', ')}
                  </Typography>
                </div>
              )}
              value={value}
            >
              {(sort ? options.sort() : options).map(opt => (
                <MenuItem
                  key={opt}
                  value={opt}
                >
                  {opt}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </InfoTooltip>
      }
    </Fragment>
  );
}

MultiSelectDropdownSelect.defaultProps = {
  maxItemsDisplayed: 2,
  descriptionKey: ''
};

MultiSelectDropdownSelect.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  descriptionKey: PropTypes.string,
  disabled: PropTypes.bool,
  hideOnEmptyOptions: PropTypes.bool,
  maxItemsDisplayed: PropTypes.number,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  setValue: PropTypes.func.isRequired,
  sort: PropTypes.bool,
  value: PropTypes.array.isRequired,
};

export default withStyles(styles)(MultiSelectDropdownSelect);
