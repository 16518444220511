export default theme => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1)
  },
  helpWrapper: {
    float: 'right',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  component: {
    border: `2px solid ${theme.palette.border}`,
    height: '40vh'
  },
  componentSmall: {
    border: `2px solid ${theme.palette.border}`,
    height: '40vh'
  },
  buttonRight: {
    marginTop: theme.spacing(1),
  }
});
