// eslint-disable-next-line no-unused-vars

export default theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  noPadding: {
    padding: 0,
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});
