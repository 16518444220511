import React from 'react'
import {Button, Card, CardContent, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {Edit as EditIcon} from '@material-ui/icons';
import {isNumber} from 'lodash';

function AppVersionCard(props) {

  const {classes, t, appVersion, title, onEditClick} = props;

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.buttonContainer}>
            <Typography
              variant={'h4'}
            >
              {title}
            </Typography>
            <Button
              className={classes.button}
              color="primary"
              onClick={onEditClick}
              title={t('editAppVersion')}
              variant="contained"
            >
              <EditIcon/>
            </Button>
          </div>
          <div>
            <Typography>
              {t('majorVersion')}: {appVersion.major}
            </Typography>
          </div>
          <div>
            <Typography>
              {t('apkVersion')}: {appVersion.apk}
            </Typography>
          </div>
          {isNumber(appVersion.codePush) &&
            <div>
              <Typography>
                {t('codePushVersion')}: {appVersion.codePush}
              </Typography>
            </div>
          }
        </CardContent>
      </Card>
    </div>
  );
}

AppVersionCard.propTypes = {
  appVersion: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};


export default compose(withStyles(styles), withTranslation())(AppVersionCard);
