import React, {Fragment, useEffect, useState} from 'react'
import {FormControl, Input, InputLabel, MenuItem, Select, withStyles} from '@material-ui/core';
import {AllInboxOutlined as BoxIdSearchIcon} from '@material-ui/icons';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {getStopStatusString, StopStatus} from '../../../services/enums/StopStatus';
import {CarrierAndMicroHubDropdownSelect, DateRangeFilter, DropdownSelectSimple, InfoTooltip, SearchInput} from '../../../components';
import useDebounce from '../../../hooks/useDebounce';


function StopFilter(props) {

  const {
    classes,
    className,
    t,
    filter,
    setFilter,
    carriersWithMicroHubs,
    shipperOptions,
    microHubOptions,
    searchInput,
    setSearchInput,
    useSearch
  } = props;

  const rootClassName = classNames(classes.root, className);
  const [boxId, setBoxId] = useState('');

  // use Debounce to prevent to many search operations, delay is in ms
  const debouncedBoxId = useDebounce(boxId, 750);

  useEffect(() => {
    setFilter(oldVal => ({...oldVal, boxId: debouncedBoxId}));
  }, [debouncedBoxId, setFilter]);

  return (
    <div className={rootClassName}>
      <form>
        <DateRangeFilter
          className={classes.rowWrapper}
          fromDescriptionKey={'18'}
          fromError={!filter.fromDate}
          fromRequired
          fromValue={filter.fromDate}
          setValue={(fromValue, toValue) => setFilter({...filter, fromDate: fromValue, toDate: toValue})}
          toDescriptionKey={'18'}
          toError={!filter.toDate}
          toRequired
          toValue={filter.toDate}
        />
        <div className={classNames(classes.rowWrapper, classes.searchWrapper)}>
          <DropdownSelectSimple
            descriptionKey={'19'}
            hideOnEmptyOptions
            name={t('shipper')}
            options={shipperOptions}
            setValue={(value) => setFilter({...filter, shipperName: value})}
            sort
            value={filter.shipperName}
          />
          <CarrierAndMicroHubDropdownSelect
            carrierDescriptionKey={'4'}
            carriersWithMicroHubs={carriersWithMicroHubs}
            carrierValue={filter.carrierName}
            className={classes.row}
            microHubDescriptionKey={'5'}
            microHubOptions={microHubOptions}
            microHubValue={filter.microHubName}
            setCarrierValue={(value) => setFilter({...filter, carrierName: value, microHubName: ''})}
            setMicroHubValue={(value) => setFilter({...filter, microHubName: value})}
          />
          <InfoTooltip descriptionKey={'6'}>
            <FormControl className={classes.formControl}>
              <InputLabel id="stopStatus"> {t('stopStatus')} </InputLabel>
              <Select
                input={<Input id="stopStatus"/>}
                onChange={(event) => setFilter({...filter, stopStatus: event.target.value})}
                value={filter.stopStatus}
              >
                <MenuItem
                  key={-1}
                  value={''}
                >
                  {'-'}
                </MenuItem>
                {Object.getOwnPropertyNames(StopStatus).map((status, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={StopStatus[status]}
                    >
                      {t(getStopStatusString(StopStatus[status]))}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </InfoTooltip>
          {useSearch &&
            <Fragment>
              <SearchInput
                icon={<BoxIdSearchIcon/>}
                placeHolder={t('productBoxNumber')}
                setValue={(value) => setBoxId(value)}
                value={boxId}
              />
              <SearchInput
                descriptionKey={'1'}
                setValue={(value) => setSearchInput(value)}
                value={searchInput}
              />
            </Fragment>
          }
        </div>
      </form>
    </div>
  );
}


StopFilter.propTypes = {
  carriersWithMicroHubs: PropTypes.object,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  microHubOptions: PropTypes.array,
  searchInput: PropTypes.string,
  setFilter: PropTypes.func.isRequired,
  setSearchInput: PropTypes.func,
  shipperOptions: PropTypes.array,
  t: PropTypes.func.isRequired,
  useSearch: PropTypes.bool
};

export default compose(withStyles(styles), withTranslation())(StopFilter);
