import {UserManager} from 'oidc-client';

const settings = {
  client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
  redirect_uri: `${process.env.REACT_APP_AUTH_CLIENT_CALLBACK_ROOT}${process.env.REACT_APP_SIGN_IN_CALLBACK}`,
  post_logout_redirect_uri: process.env.REACT_APP_AUTH_CLIENT_ROOT,
  response_type: 'id_token token',
  scope: 'openid profile za-api email',
  authority: process.env.REACT_APP_AUTH_AUTHORITY,
  tokenEndpointUrl: process.env.REACT_APP_AUTH_AUTHORITY,
  discoveryEndpointUrl: `${process.env.REACT_APP_AUTH_AUTHORITY}.well-known/openid-configuration`,
  silent_redirect_uri: `${process.env.REACT_APP_AUTH_CLIENT_CALLBACK_ROOT}silent-renew.html`,
  automaticSilentRenew: true,
  checkSessionInterval: 2000,
  check_session_iframe: `${process.env.REACT_APP_AUTH_AUTHORITY}/connect/checksession`,
  loadUserInfo: true,
  monitorSession: true,
  getClaimsFromUserInfoEndpoint: true,
  metadata: {
    issuer: `${process.env.REACT_APP_AUTH_AUTHORITY}`,
    jwks_uri: `${process.env.REACT_APP_AUTH_AUTHORITY}/.well-known/openid-configuration/jwks`,
    end_session_endpoint: `${process.env.REACT_APP_AUTH_AUTHORITY}/connect/endsession`,
    authorization_endpoint: `${process.env.REACT_APP_AUTH_AUTHORITY}/connect/authorize`,
    check_session_iframe: `${process.env.REACT_APP_AUTH_AUTHORITY}/connect/checksession`,
    userinfo_endpoint: `${process.env.REACT_APP_AUTH_AUTHORITY}/connect/userinfo`
  }
};

export const MyUserManager = new UserManager(settings);
