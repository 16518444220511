export default theme => ({
  root: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  },
  noPadding: {
    padding: 0
  },
  loadingIndicator: {
    width: '180px'
  },
});

