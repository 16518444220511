import React, {Fragment, useEffect, useState} from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  withStyles
} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {AccessTime as TimeIcon, Cancel as CancelIcon, Today as DateIcon} from '@material-ui/icons';
import {getAllowedStatusChanges, getStopStatusString, StopStatus} from '../../../../services/enums/StopStatus';
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {onCloseWithBackdropCurry} from '../../../../services/util/helperFuncs';

function EditStopStatusDialog(props) {

  const {classes, t, dialogState, handleCancel, handleClose, editedStop, setEditedStop} = props;
  const [stopStatus, setStopStatus] = useState(0);
  const [deliveryTime, setDeliveryTime] = useState(null);

  useEffect(() => {
    setStopStatus(editedStop.stopStatus);
  }, [editedStop]);


  const handleOk = () => {
    if (deliveryTime) {
      setEditedStop({...editedStop, stopStatus: stopStatus, deliverTime: new Date(deliveryTime)})
    } else {
      setEditedStop({...editedStop, stopStatus: stopStatus});
    }
    setStopStatus(editedStop.stopStatus);
    setDeliveryTime(null);
    handleClose();
  };

  const handleNotOk = () => {
    setStopStatus(editedStop.stopStatus);
    setDeliveryTime(null);
    handleCancel();
  };

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      maxWidth={'md'}
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={dialogState}
    >
      <DialogTitle>{t('editStopStatus')}</DialogTitle>
      <DialogContent>
        <FormControl className={classes.formControl}>
          <InputLabel id="stopStatusChange">{t('stopStatus')}</InputLabel>
          <Select
            labelId="stopStatusChange"
            onChange={(event) => {
              if (event.target.value === StopStatus.CanNotDeliver || event.target.value === StopStatus.Delivered) setDeliveryTime(Date.now());
              if (event.target.value === editedStop.stopStatus) setDeliveryTime(null);
              setStopStatus(event.target.value)
            }}
            value={stopStatus}
          >
            <MenuItem
              key={editedStop.stopStatus}
              value={editedStop.stopStatus}
            >
              {t(getStopStatusString(editedStop.stopStatus))}
            </MenuItem>
            {getAllowedStatusChanges(editedStop.stopStatus).map((status) => {
              return (
                <MenuItem
                  key={status}
                  value={status}
                >
                  {t(getStopStatusString(status))}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {deliveryTime &&
        <Fragment>
          <Typography className={classes.textHeader}><strong>{t('deliveryTime')}</strong></Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              cancelLabel={t('dialogCancel')}
              format="dd.MM.yyyy"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton><DateIcon/> </IconButton>
                  </InputAdornment>
                )
              }}
              label={t('date')}
              margin="normal"
              onChange={(event) => {
                setDeliveryTime(new Date(event))
              }}
              value={deliveryTime}
            />
            <br/>
            <TimePicker
              ampm={false}
              cancelLabel={t('dialogCancel')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton><TimeIcon/> </IconButton>
                  </InputAdornment>
                )
              }}
              label={t('time')}
              margin="normal"
              onChange={(event) => {
                setDeliveryTime(new Date(event))
              }}
              value={deliveryTime}
            />
          </MuiPickersUtilsProvider>
        </Fragment>
        }
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={handleNotOk}
        >
          <CancelIcon/>&nbsp;{t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          disabled={stopStatus === editedStop?.stopStatus}
          onClick={handleOk}
          variant={'contained'}
        >
          {t('dialogOk')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EditStopStatusDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogState: PropTypes.bool.isRequired,
  editedStop: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  setEditedStop: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(EditStopStatusDialog);
