import React from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {CarrierAndMicroHubDropdownSelect, DateFilter, DropdownSelectSimple} from '../../index';


function DashboardFilter(props) {

  const {classes, className, t, filter, setFilter, carriersWithMicroHubs, shipperOptions, microHubOptions} = props;

  const rootClassName = classNames(classes.root, className);


  return (
    <div className={rootClassName}>
      <form className={classes.rowWrapper}>
        <DateFilter
          error={!filter.fromDate}
          required
          setValue={(value) => {
            setFilter({
              ...filter,
              fromDate: value,
              toDate: new Date(value.getFullYear(), value.getMonth(), value.getDate(), filter.toDate.getHours(), filter.toDate.getMinutes(), filter.toDate.getSeconds())
            });
          }}
          value={filter.fromDate}
        />
        <DropdownSelectSimple
          hideOnEmptyOptions
          name={t('shipper')}
          options={shipperOptions}
          setValue={(value) => setFilter({...filter, shipperName: value})}
          sort
          value={filter.shipperName}
        />
        <CarrierAndMicroHubDropdownSelect
          carriersWithMicroHubs={carriersWithMicroHubs}
          carrierValue={filter.carrierName}
          className={classes.rowWrapper}
          microHubOptions={microHubOptions}
          microHubValue={filter.microHubName}
          setCarrierValue={(value) => setFilter({...filter, carrierName: value, microHubName: ''})}
          setMicroHubValue={(value) => setFilter({...filter, microHubName: value})}
        />
      </form>
    </div>
  );
}


DashboardFilter.propTypes = {
  carriersWithMicroHubs: PropTypes.object,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  microHubOptions: PropTypes.array,
  setFilter: PropTypes.func.isRequired,
  shipperOptions: PropTypes.array,
  t: PropTypes.func.isRequired
};

export default compose(withStyles(styles), withTranslation())(DashboardFilter);
