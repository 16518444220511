import React from 'react'
import {Button, Dialog, DialogActions, DialogTitle, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import {Add as AddIcon, Cancel as CancelIcon, Pets as HomeIcon} from '@material-ui/icons';
import {onCloseWithBackdropCurry} from '../../../../services/util/helperFuncs';

function AddHubDialog(props) {

  const {t, onCancel, onSelect, AddHubDialogState, onNewHub, classes} = props;

  const handleClose = () => {
    onCancel();
  };

  const handleListItemClick = () => {
    onSelect();
  };

  return (
    <Dialog
      disableEscapeKeyDown
      onClose={onCloseWithBackdropCurry(onCancel)}
      open={AddHubDialogState.open}
    >
      <DialogTitle id="simple-dialog-title">{t('addHub')}</DialogTitle>
      <List style={{overflow: 'auto'}}>
        <ListItem
          button
          key={'newHub'}
          onClick={onNewHub}
        >
          <ListItemAvatar>
            <Avatar className={classes.addAvatar}>
              <AddIcon/>
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={t('createHub')}/>
        </ListItem>
        {AddHubDialogState.allHubs.map(hub => (
          <ListItem
            button
            key={hub}
            onClick={() => {
              AddHubDialogState.selectedHub = hub;
              handleListItemClick()
            }}
          >
            <ListItemAvatar>
              <Avatar>
                <HomeIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={hub}/>
          </ListItem>
        ))}
      </List>
      <DialogActions>
        <Button
          color="default"
          onClick={handleClose}
        >
          <CancelIcon/>&nbsp;{t('dialogCancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddHubDialog.propTypes = {
  AddHubDialogState: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onNewHub: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(AddHubDialog);
