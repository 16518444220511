// Palette
import palette from '../palette';

export default {
  root: {
    '&$selected': {
      backgroundColor: palette.primary.selected,
      '&$hover': {
        '&:hover': {
          backgroundColor: palette.primary.selected,
        }
      },
      '&:hover': {
        backgroundColor: palette.primary.selected,
      }
    },
    '&$hover': {
      '&:hover': {
        backgroundColor: palette.primary.light,
      }
    }
  }
};
