import React, {useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Portlet} from '../../../components'
import {default as CustomerDetailEdit} from './DetailEdit';
import {default as CustomerDetailShow} from './DetailShow';
import {cloneDeep} from 'lodash'
import {displayModes} from '../../../services/enums/displayModes';

function CustomerDetails(props) {

  const [editMode, setEditMode] = useState(false);
  const [editedCustomer, setEditedCustomer] = useState(null);
  const {classes, className, customer, updateCustomer, readOnly, closeDetailView, displayMode} = props;

  const editCustomer = () => {
    const newCustomer = cloneDeep(customer);
    setEditedCustomer(newCustomer);
    setEditMode(true);
  };

  const update = () => {
    setEditMode(false);
    updateCustomer(editedCustomer);
  };

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet className={rootClassName}>
      {editMode ?
        <CustomerDetailEdit
          displayMode={displayMode}
          editedCustomer={editedCustomer}
          setEditedCustomer={setEditedCustomer}
          setEditMode={setEditMode}
          updateCustomer={update}
        />
        :
        <CustomerDetailShow
          closeDetailView={closeDetailView}
          customer={customer}
          displayMode={displayMode}
          edit={editCustomer}
          readOnly={readOnly}
        />
      }
    </Portlet>
  );
}


CustomerDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  closeDetailView: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  readOnly: PropTypes.bool,
  updateCustomer: PropTypes.func,
};

export default withStyles(styles)(CustomerDetails);
