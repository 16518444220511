import Columns from './columns';
import {displayModes} from '../../../services/enums/displayModes';

// CARE: ALWAYS INCREMENT THE VERSION IF THE SETTINGS FOR A COLUMN CHANGE!
// Config explanation
// optional: determines if the hidden status can be changed by the user
// hidden: determines if the field is shown or hidden
// conditional: indicates that the hidden status can be overriden using the conditions dictionary of the useTableConfig hook
const DefaultConfig = {
  [displayModes.admin]: {
    version: 1,
    columns: {
      stopId: {
        columnName: Columns.stopId.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 1,
      },
      shipper: {
        columnName: Columns.shipper.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 2
      },
      carrier: {
        columnName: Columns.carrier.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 3
      },
      boxes: {
        columnName: Columns.boxes.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 4
      },
      invoiceWaterBox: {
        columnName: Columns.invoiceWaterBox.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 5
      },
      stairMoney: {
        columnName: Columns.stairMoney.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 6
      },
      bonusBox: {
        columnName: Columns.bonusBox.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 7
      },
    }
  },
  [displayModes.carrier]: {
    version: 1,
    columns: {
      stopId: {
        columnName: Columns.stopId.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 1,
      },
      shipper: {
        columnName: Columns.shipper.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 2
      },
      boxes: {
        columnName: Columns.boxes.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 3
      },
      invoiceWaterBox: {
        columnName: Columns.invoiceWaterBox.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 4
      },
      stairMoney: {
        columnName: Columns.stairMoney.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 5
      },
      bonusBox: {
        columnName: Columns.bonusBox.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 6
      },
    }
  },
  [displayModes.shipper]: {
    version: 1,
    columns: {
      stopId: {
        columnName: Columns.stopId.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 1,
      },
      shipper: {
        columnName: Columns.carrier.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 2
      },
      boxes: {
        columnName: Columns.boxes.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 3
      },
      invoiceWaterBox: {
        columnName: Columns.invoiceWaterBox.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 4
      },
      stairMoney: {
        columnName: Columns.stairMoney.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 5
      },
      bonusBox: {
        columnName: Columns.bonusBox.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 6
      },
    }
  },
}

export default DefaultConfig;