import React, {useEffect, useState} from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles
} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {onCloseWithBackdropCurry} from '../../../../services/util/helperFuncs';
import {DriverQueryService} from '../../../../services/backend/driverQueryService';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {ClientService} from '../../../../services/client/clientService';
import {clientTypes} from '../../../../services/client/clientTypes';
import {DataLoadingWrapper, Pagination, Portlet, PortletContent} from '../../../../components';
import DonationButtonDialogTableRow from './DonationButtonDialogTableRow';
import useTablePagination from '../../../../hooks/useTablePagination';

const clientDrivers = ClientService.getClient(clientTypes.driver);

function DonationButtonApproveDialog(props) {

  const {classes, t, handleClose, dialogState} = props;
  const queryDrivers = DriverQueryService.getDriversByCarrierNameAndMicroHubListQuery(null, [], false);
  const {loading, error, data, refetch} = useQuery(queryDrivers, {client: clientDrivers, skip: !dialogState.open});
  const approveDonationButtonMutation = DriverQueryService.approveDonationButton();
  const [approveButton] = useMutation(approveDonationButtonMutation, {client: clientDrivers});
  const [drivers, setDrivers] = useState([]);

  useEffect(() => {
    if (data && data.getDriversByCarrierNameAndMicroHubList) {
      setDrivers(data.getDriversByCarrierNameAndMicroHubList)
    }
  }, [loading, data, drivers]);

  const {paginationProps, pagedObjects} = useTablePagination(drivers);

  const handleApprove = (driverId) => {
    approveButton({variables: {driverId: driverId, approved: true}}).then(() => {
      refetch();
    })
  };


  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      maxWidth={'xl'}
      onClose={onCloseWithBackdropCurry(() => handleClose())}
      open={dialogState.open}
    >
      <DialogTitle>{t('donationButtonApproveDialog')}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DataLoadingWrapper
          error={error}
          isLoading={loading}
        >
          <Portlet>
            <PortletContent noPadding>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('driverFirstName')}</TableCell>
                    <TableCell>{t('driverLastName')}</TableCell>
                    <TableCell>{t('driverDisplayName')}</TableCell>
                    <TableCell>{t('driverPhoneNumber')}</TableCell>
                    <TableCell>
                      <div className={classes.iconWrapper}>
                        {t('driverEmail')}
                      </div>
                    </TableCell>
                    <TableCell>{t('carrier')}</TableCell>
                    <TableCell>{t('donationButtonApprove')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pagedObjects.map((driver, index) => (
                    <DonationButtonDialogTableRow
                      driver={driver}
                      handleApprove={handleApprove}
                    />
                  ))}
                </TableBody>
              </Table>
              <Pagination paginationProps={paginationProps}/>
            </PortletContent>
          </Portlet>
        </DataLoadingWrapper>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => handleClose()}
        >
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DonationButtonApproveDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogState: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(DonationButtonApproveDialog);
