import {useEffect, useState} from 'react';

export default function useTablePagination(objectsToPage, rowsPerPageOptions = [25, 50, 100]) {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [count, setCount] = useState(0);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (objectsToPage && objectsToPage.length !== count) {
      setCount(objectsToPage.length);
      setPage(0);
    }
  }, [objectsToPage, count])


  return {
    paginationProps: {
      page,
      count,
      onPageChange:
      handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
      rowsPerPage,
      rowsPerPageOptions
    },
    pagedObjects: objectsToPage.slice((page * rowsPerPage), ((page + 1) * rowsPerPage)),
  }
}