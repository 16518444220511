import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react'
import {Tab, Tabs, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import SideNavLayout from '../../../layouts/SideNavLayout';
import {useQuery} from '@apollo/react-hooks';
import {PreviewToursService} from '../../../services/backend/previewToursService';
import {
  GeneralInfoIcon,
  LoadingWrapper,
  MapDispositionPreview,
  PreviewToursEditHubAssignment,
  PreviewToursEditOrder,
  PreviewToursFilter,
  PreviewToursView,
  RefreshButton
} from '../../../components';
import {AuthService} from '../../../services/auth/authService';
import {ClientService} from '../../../services/client/clientService';
import {clientTypes} from '../../../services/client/clientTypes';
import AppBar from '@material-ui/core/AppBar';
import {MicroHubQueryService} from '../../../services/backend/microHubQueryService';
import {VehicleQueryService} from '../../../services/backend/vehicleQueryService';
import {getDayOfWeekFromDate} from '../../../services/enums/dayOfWeekHelper';
import {usePreviewStopHub} from '../../../hooks/usePreviewStopHub';
import {previewStopMessageRelevantForFilter, previewTourMessageRelevantForFilter} from '../../../services/util/signalRMessageHelper';
import {usePreviewTourHub} from '../../../hooks/usePreviewTourHub';
import {RerunAlgorithmForHubDialog} from './Components';
import useShipperOptions from '../../../hooks/useShipperOptions';
import {DatePickerDefaultValueContext} from '../../../context';
import useMicroHubOptionsOfCarrier from '../../../hooks/useMicroHubOptionsOfCarrier';

const clientMicroHub = ClientService.getClient(clientTypes.microHub);
const vehicleClient = ClientService.getClient(clientTypes.vehicle);

function PreviewToursCarrier(props) {

  const {classes, t} = props;
  const {fromDateDefault, updateDefaultDate} = useContext(DatePickerDefaultValueContext);

  const [previewData, setPreviewData] = useState([]);
  const [filter, setFilter] = useState({
    shipperName: '',
    microHubList: [],
    date: fromDateDefault,
    frozen: '',
    carrierName: AuthService.getUserOrganization()
  });
  const [mapTabState, setMapTabState] = useState(undefined);
  useEffect(() => updateDefaultDate(filter.date), [filter.date, updateDefaultDate]);
  const [disableFilterPreviewTourOrder, setDisableFilterPreviewTourOrder] = useState(false);
  const [disableFilterMicroHubAssignment, setDisableFilterMicroHubAssignment] = useState(false);
  const [backendLoading, setBackendLoading] = useState(false);
  const [microHubPlansForDay, setMicroHubPlansForDay] = useState([]);
  const {shipperOptions} = useShipperOptions();
  const [rerunAlgorithmForHubDialgoState, setRerunAlgorithmForHubDialogState] = useState({hub: null, open: false});

  const [tabState, setTabState] = useState(0);
  const [refresh, setRefresh] = useState(false);
  /*Remove this until the order of the stops matches the order calculated at the shipperAPI*/
  // const [backendLoadingExcel, setBackendLoadingExcel] = useState(false);

  const {microHubOptions, microHubData} = useMicroHubOptionsOfCarrier(AuthService.getUserOrganization(), true);

  usePreviewStopHub(useCallback(event => {
    if (previewStopMessageRelevantForFilter(event, filter)) {
      setRefresh(true);
    }
  }, [filter]));
  usePreviewTourHub(useCallback(event => {
    if (previewTourMessageRelevantForFilter(event, filter)) {
      setRefresh(true);
    }
  }, [filter]));

  const queryMicroHubPlans = MicroHubQueryService.getMicroHubMaxVolume(AuthService.getUserOrganization(), '');
  const {data: dataMicroHubPlans} = useQuery(queryMicroHubPlans, {client: clientMicroHub});

  /*Remove this until the order of the stops matches the order calculated at the shipperAPI*/
  // const downloadStopsAsExcel = () => {
  //   const microHubs = previewData ? previewData.map(hub => hub.microHubName) : [];
  //   const stopsWithoutTour = previewData ? previewData.map(hub => hub.stopsWithoutTour).map(stopWithoutTour => stopWithoutTour.map(stop => stop.id)).flat() : [];
  //
  //   if (microHubs.length !== 0 || stopsWithoutTour.length !== 0) {
  //     setBackendLoadingExcel(true);
  //     PreviewToursService.downloadPreviewAsExcelAsCarrier(filter.carrierName, filter.shipperName, microHubs, stopsWithoutTour, filter.date)
  //       .then(response => {
  //         setBackendLoadingExcel(false);
  //         let filename = response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0];
  //
  //         response.blob().then(blob =>
  //           FileSaver.saveAs(blob, filename)
  //         );
  //       }, () => {
  //         alert(t('errorDownloadingExcel'))
  //         setBackendLoadingExcel(false);
  //       });
  //   } else {
  //     alert(`${t('noPreviewDataAvailable')}: ${DateService.displayDate(filter.date)}`);
  //   }
  // };

  const queryVehicles = VehicleQueryService.getVehiclesByCarrierNameAndMicroHubListForPreviewQuery(AuthService.getUserOrganization(), microHubOptions);
  const {data: dataVehicles} = useQuery(queryVehicles, {client: vehicleClient, skip: microHubOptions.length === 0});

  const loadData = useCallback(async (noLoadingIndicator) => {
    if (!(filter.date instanceof Date && !isNaN(filter.date) && (filter.microHubList.length || microHubOptions.length))) {
      return;
    }
    const microHubs = filter.microHubList.length === 0 ? microHubOptions : filter.microHubList;
    if (!noLoadingIndicator) setBackendLoading(true);

    try {
      const response = await PreviewToursService.getMicroHubsWithToursByFilter(filter.carrierName, filter.shipperName, microHubs, filter.date, filter.frozen === '' ? null : filter.frozen)
      setPreviewData(response);
      if (!noLoadingIndicator) setBackendLoading(false);
      setRefresh(false);
    } catch (e) {
      alert(`${t('errorLoadingPreviewTours')}: ${e}`);
      if (!noLoadingIndicator) setBackendLoading(false);
      setRefresh(false);
    }
  }, [filter, t, microHubOptions]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (dataMicroHubPlans && dataMicroHubPlans.multipleHubsAndCarrierPlans) {
      const hubPlansForCarrierAndHub = JSON.parse(JSON.stringify(dataMicroHubPlans.multipleHubsAndCarrierPlans));
      hubPlansForCarrierAndHub.forEach((hubPlanForCarrierAndHub) => {
        hubPlanForCarrierAndHub.hubPlans = hubPlanForCarrierAndHub.hubPlans.filter(plan => plan.dayOfWeek === getDayOfWeekFromDate(filter.date))
      });
      setMicroHubPlansForDay(hubPlansForCarrierAndHub);
    }
  }, [dataMicroHubPlans, filter]);

  const saveHubAssignment = (editedAssignment) => {
    const newAssignments = {
      deliveryDate: filter.date ? new Date(Date.UTC(filter.date.getFullYear(), filter.date.getMonth(), filter.date.getDate())).toISOString() : null,
      microHubs: editedAssignment.map(hub => {
        return {
          microHubName: hub.microHubName,
          carrierName: hub.carrierName,
          changedTourIds: hub.tours.map(tour => tour.id),
          changedStopIds: hub.stops.map(stop => stop.id),
        };
      })
    };
    PreviewToursService.changeMicroHubAssignment(newAssignments)
      .then(response => response.ok ? loadData() : alert(t('errorChangingMicroHubAssignment')));
  };

  const saveOrder = (editedOrder) => {
    let newOrder = [];
    editedOrder.forEach(hub => {
      const toursOfHub = hub.tours.map((tour, index) => {
        return {
          tourId: tour.id,
          tourNumber: index + 1,
          stops: tour.stops.map((stop, index) => {
            return {
              stopId: stop.id,
              stopNumber: index + 1,
            };
          })
        };
      });
      newOrder = newOrder.concat(toursOfHub);
    });
    PreviewToursService.changeTourOrder(newOrder)
      .then(response => response.ok ? loadData() : alert(t('errorChangingOrder')));
  };

  const cancelEdit = () => {
    loadData()
  };

  const rerunAlgoForHub = (hub, useTourTemplates, useAlgorithm) => {
    setRerunAlgorithmForHubDialogState({...rerunAlgorithmForHubDialgoState, open: false, hub: null});
    PreviewToursService.rerunAlgoForHub(filter.date, hub.carrierName, hub.microHubName, useTourTemplates, useAlgorithm)
      .then(response => response.ok ? loadData() : alert(t('errorRerunningAlgo')));
  };

  const freezeTour = (tourId, isFrozen) => {
    PreviewToursService.freezeTour(tourId, isFrozen)
      .then(response => response.ok ? loadData(true) : alert(t('errorFreezingTour')));
  };

  const updateTourOrder = (tourWithStops) => {
    tourWithStops.stops.forEach((stop, index) => {
      stop.stopNumber = index + 1;
    });
    PreviewToursService.changeStopOrderOnTour(tourWithStops, tourWithStops.id).then(response => response.json()).then((tour) => {
      const newHubsWithTours = JSON.parse(JSON.stringify(previewData));
      const index = newHubsWithTours.findIndex(hub => hub.carrierName === tour.carrierName && hub.microHubName === tour.microHubName);
      if (index >= 0) {
        newHubsWithTours[index].tours.push(tour);
      }
      setPreviewData(newHubsWithTours);
      loadData(true);
    }, () => {
      alert(t('errorUpdatingTourData'));
      loadData(true);
    })
  };

  const changeTourOfStops = (destinationTourId, stopIds, autoOrderOnAdd = true) => {
    if (autoOrderOnAdd) {
      PreviewToursService.moveStopsToTour(destinationTourId, stopIds).then(response => {
        if (response.ok) {
          loadData(true)
        } else {
          alert(t('errorUpdatingTourData'));
          loadData(true);
        }
      });
    } else {
      PreviewToursService.moveStopsToTourByClickOrder(destinationTourId, stopIds).then(response => {
        if (response.ok) {
          loadData(true)
        } else {
          alert(t('errorUpdatingTourData'));
          loadData(true);
        }
      });
    }
  };

  const createTour = (newTour, numTimesToCreate) => {
    PreviewToursService.addFixedTours(newTour, numTimesToCreate).then(response => response.json()).then(tours => {
      if (tours && tours.length > 0) {
        const newHubsWithTours = JSON.parse(JSON.stringify(previewData));
        const index = newHubsWithTours.findIndex(hub => hub.carrierName === tours[0].carrierName && hub.microHubName === tours[0].microHubName);
        if (index >= 0) {
          newHubsWithTours[index].tours = newHubsWithTours[index].tours.concat(tours);
        }
        setPreviewData(newHubsWithTours);
      }
      loadData(false);
    }, () => {
      alert(t('errorCreatingTour'));
      loadData(false);
    });
  };

  const deleteTour = (tourId) => {
    PreviewToursService.deleteTourById(tourId).then(response => {
      if (response.ok) {
        loadData(false);
      } else {
        alert(t('errorDeletingTour'));
        loadData(false);
      }
    });
  };

  const deleteStopFromTour = (tourId, stopId) => {
    PreviewToursService.deleteStopFromTourById(tourId, stopId).then(response => response.json()).then(() => {
      loadData(true);
    }, () => {
      alert(t('errorDeletingStopFromTour'));
      loadData(false);
    });
  };

  const deleteAllTours = (tours) => {
    const filteredToursById = tours.map(tour => tour.id);
    PreviewToursService.deleteAllTours(filteredToursById).then(() => {
      loadData(false);
    }, () => {
      alert(t('errorDeletingAllTours'));
      loadData(false);
    })
  };


  return (
    <SideNavLayout title={t('previewTours')}>
      <AppBar
        className={classes.appBar}
        color={'secondary'}
        position={'relative'}
      >
        <Tabs
          onChange={(event, newValue) => {
            setTabState(newValue);
            setFilter({...filter, shipperName: '', microHubOptions: [], frozen: newValue === 2 || newValue === 3 ? true : ''})
          }}
          value={tabState}
        >
          <Tab
            label={t('tourPreviewView')}
          />
          <Tab
            label={t('tourPreviewEditHubAssignment')}
          />
          <Tab
            label={t('tourPreviewEditOrder')}
          />
          <Tab
            label={t('editTours')}
          />
        </Tabs>
      </AppBar>
      {(() => {
        switch (tabState) {
          case 0:
            return (
              <div className={classes.root}>
                <div className={classes.helpWrapper}>
                  <RefreshButton
                    className={classes.refreshButton}
                    refresh={refresh}
                    refreshFunc={loadData}
                  />
                  <GeneralInfoIcon/>
                </div>
                {/*Remove this until the order of the stops matches the order calculated at the shipperAPI*/}
                {/*<LoadingButton*/}
                {/*  className={classes.excelDownload}*/}
                {/*  color="secondary"*/}
                {/*  loading={backendLoadingExcel}*/}
                {/*  onClick={downloadStopsAsExcel}*/}
                {/*  variant="contained"*/}
                {/*>*/}
                {/*  <DownloadIcon/>&nbsp;{t('downloadPreviewAsExcel')}*/}
                {/*</LoadingButton>*/}
                <PreviewToursFilter
                  filter={filter}
                  microHubOptions={microHubOptions}
                  setFilter={setFilter}
                  shipperOptions={shipperOptions}
                />
                <LoadingWrapper loading={backendLoading}>
                  <Fragment>
                    <PreviewToursView
                      freezeTour={freezeTour}
                      hubsWithToursAndStops={previewData}
                      mapTabState={mapTabState}
                      microHubPlanData={microHubPlansForDay}
                      microHubs={microHubData}
                      rerunAlgoForHub={(hub) => setRerunAlgorithmForHubDialogState({...rerunAlgorithmForHubDialgoState, open: true, hub: hub})}
                      setMapTabState={setMapTabState}
                      unassignedStops={[]}
                    />
                    <RerunAlgorithmForHubDialog
                      dialogState={rerunAlgorithmForHubDialgoState}
                      handleCancel={() => setRerunAlgorithmForHubDialogState({...rerunAlgorithmForHubDialgoState, open: false, hub: null})}
                      handleClose={rerunAlgoForHub}
                    />
                  </Fragment>
                </LoadingWrapper>
              </div>
            );
          case 1:
            return (
              <div className={classes.root}>
                <PreviewToursFilter
                  disabled={disableFilterMicroHubAssignment}
                  filter={filter}
                  microHubOptions={[]}
                  setFilter={setFilter}
                  shipperOptions={[]}
                />
                <PreviewToursEditHubAssignment
                  allMicroHubs={microHubOptions}
                  cancelEdit={cancelEdit}
                  hubsWithToursAndStops={previewData}
                  microHubData={microHubData}
                  microHubPlanData={microHubPlansForDay}
                  refresh={refresh}
                  refreshFunc={loadData}
                  saveEdit={saveHubAssignment}
                  setDisableFilter={setDisableFilterMicroHubAssignment}
                  vehicles={(dataVehicles && dataVehicles.getVehiclesByCarrierNameAndMicroHubList) ? dataVehicles.getVehiclesByCarrierNameAndMicroHubList : []}
                />
              </div>
            );
          case 2:
            return (
              <div className={classes.root}>
                <PreviewToursFilter
                  disabled={disableFilterPreviewTourOrder}
                  filter={filter}
                  hideFreezeFilter
                  microHubOptions={microHubOptions}
                  setFilter={setFilter}
                  shipperOptions={[]}
                />
                <PreviewToursEditOrder
                  cancelEdit={cancelEdit}
                  hubsWithToursAndStops={previewData}
                  refresh={refresh}
                  refreshFunc={loadData}
                  saveEdit={saveOrder}
                  setDisableFilter={setDisableFilterPreviewTourOrder}
                />
              </div>
            );
          case 3:
            return (
              <div className={classes.root}>
                <div className={classes.helpWrapper}>
                  <RefreshButton
                    className={classes.refreshButton}
                    refresh={refresh}
                    refreshFunc={loadData}
                  />
                </div>
                <PreviewToursFilter
                  filter={filter}
                  hideFreezeFilter
                  microHubOptions={microHubOptions}
                  setFilter={setFilter}
                  shipperOptions={[]}
                />
                <MapDispositionPreview
                  backendIsLoading={backendLoading}
                  changeTourOfStop={changeTourOfStops}
                  createTour={createTour}
                  deleteAllTours={deleteAllTours}
                  deleteStopFromTour={deleteStopFromTour}
                  deleteTour={deleteTour}
                  hubsWithToursAndStops={previewData}
                  microHubOptions={microHubOptions}
                  microHubs={microHubData}
                  updateTourOrder={updateTourOrder}
                />
              </div>
            );
          default:
            return (<div>error</div>);
        }
      })()}
    </SideNavLayout>
  );
}


PreviewToursCarrier.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(PreviewToursCarrier);
