import React, {Fragment, useEffect, useState} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import {
  PortletContent,
  PortletHeader,
  TemplateStopEditAdmin,
  TemplateStopEditCarrier,
  TemplateStopEditShipper
} from 'components';
import {displayModes} from 'services/enums/displayModes';

function TemplateStopDetailEdit(props) {

  const {
    classes,
    className,
    editedTemplateStop,
    editedRelatedTemplateStops,
    setEditMode,
    t,
    updateTemplateStop,
    setEditedTemplateStop,
    setEditedRelatedTemplateStops,
    editDisplayMode
  } = props;

  const rootClassName = classNames(classes.root, className);

  const [readyForUpdate, setReadyForUpdate] = useState(false);

  useEffect(() => {
    if (readyForUpdate) {
      updateTemplateStop();
    }
  }, [readyForUpdate, updateTemplateStop])

  const onUpdate = () => {
    setEditedTemplateStop({ ...editedTemplateStop });
    setEditedRelatedTemplateStops(editedRelatedTemplateStops);
    // handle update via this callback, since the setEditedTemplateStop called above has to be processed before the update is written!
    setReadyForUpdate(true);
  };

  const onCancel = () => {
    setEditMode(false)
  }


  const isSaveDisabled = () => {
    return (
      !editedTemplateStop.address.streetAndNumber || !editedTemplateStop.address.city || !editedTemplateStop.address.zipcode ||
      !editedTemplateStop.address.country || !editedTemplateStop.name || !editedTemplateStop.shipperName ||
      new Date(editedTemplateStop.deliveryWindow?.to).getTime() < new Date(editedTemplateStop.deliveryWindow?.from).getTime()
    );
  }

  return (
    <Fragment>
      <PortletHeader className={rootClassName}>
        <Fragment>
          <div><strong>{t('templateStopId')}: {editedTemplateStop.id}</strong></div>
          <div>
            <Button
              className={classes.button}
              color="default"
              onClick={onCancel}
              title={t('templateStopCancel')}
              variant="contained"
            >
              <CancelIcon />&nbsp;{t('cancel')}
            </Button>
            <Button
              className={classes.button}
              color="primary"
              disabled={isSaveDisabled()}
              onClick={onUpdate}
              title={t('templateStopSave')}
              variant="contained"
            >
              <SaveIcon />
            </Button>
          </div>
        </Fragment>
      </PortletHeader>
      <PortletContent noPadding>
        {(() => {
          switch (editDisplayMode) {
            case displayModes.admin:
              return (
                <TemplateStopEditAdmin
                  className={classes.wrapper}
                  editedTemplateStop={editedTemplateStop}
                  editedRelatedTemplateStops={editedRelatedTemplateStops}
                  setEditedTemplateStop={setEditedTemplateStop}
                  setEditedRelatedTemplateStops={setEditedRelatedTemplateStops}
                />
              );
            case displayModes.shipper:
              return (
                <TemplateStopEditShipper
                  className={classes.wrapper}
                  editedTemplateStop={editedTemplateStop}
                  editedRelatedTemplateStops={editedRelatedTemplateStops}
                  setEditedTemplateStop={setEditedTemplateStop}
                  setEditedRelatedTemplateStops={setEditedRelatedTemplateStops}
                />);
            case displayModes.carrier:
              return (
                <TemplateStopEditCarrier
                  className={classes.wrapper}
                />
              );
            default: return <></>
          }
        })()
        }
      </PortletContent>
    </Fragment>
  );
}

TemplateStopDetailEdit.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  editDisplayMode: PropTypes.string.isRequired,
  editedTemplateStop: PropTypes.object.isRequired,
  editedRelatedTemplateStops: PropTypes.array.isRequired,
  setEditMode: PropTypes.func.isRequired,
  setEditedTemplateStop: PropTypes.func.isRequired,
  setEditedRelatedTemplateStops: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateTemplateStop: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TemplateStopDetailEdit);
