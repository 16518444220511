import React, {Fragment} from 'react'
import {FormControl, Input, InputLabel, MenuItem, Select, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import Chip from '@material-ui/core/Chip';
import {InfoTooltip} from '../../../components';
import {VehicleTypeEnumHelperService, VehicleTypes} from '../../../services/enums/vehicleTypeEnumHelperService';

function EditVehicle(props) {

  const {classes, className, editedVehicle, t, setEditedVehicle, microHubOptions} = props;

  const selectedMicroHubNames = editedVehicle.microhubs.map(hub => hub.name);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const changeSelectedMicroHubNames = (event) => {
    const updatedMicroHubOptions = event.target.value.map(name => {
      const index = editedVehicle.microhubs.findIndex(h => h.name === name);
      if (index >= 0) {
        return editedVehicle.microhubs[index];
      }
      return {name: name, monday: true, tuesday: true, friday: true, wednesday: true, thursday: true, saturday: true, sunday: true}
    });
    setEditedVehicle({...editedVehicle, microhubs: updatedMicroHubOptions})
  };

  const changeMicroHubDayOfWeek = (value, day, hub) => {
    const newMicroHubs = JSON.parse(JSON.stringify(editedVehicle.microhubs));
    const index = newMicroHubs.findIndex(h => h.name === hub.name);
    if (index >= 0) {
      newMicroHubs[index][day] = value;
    }
    setEditedVehicle({...editedVehicle, microhubs: newMicroHubs});
  };

  const rootClassName = classNames(classes.root, className);

  return (
    <Fragment>
      <div className={rootClassName}>
        <FormGroup
          className={classes.wrapperChild}
        >
          <InfoTooltip
            descriptionKey={'30'}
            placement={'right'}
          >
            <FormControl className={classes.formControl}>
              <InputLabel
                htmlFor="vehicleName"
                required
              >{t('vehicleName')}</InputLabel>
              <Input
                error={!editedVehicle.licensePlate || editedVehicle.licensePlate.length > 15}
                id="vehicleName"
                onChange={(event) => {
                  setEditedVehicle({...editedVehicle, licensePlate: event.target.value})
                }}
                required
                value={editedVehicle.licensePlate}
              />
            </FormControl>
          </InfoTooltip>
          <FormControl className={classes.formControl}>
            <InputLabel
              htmlFor="vehicleType"
              required
            >{t('vehicleType')}</InputLabel>
            <Select
              error={!editedVehicle.type}
              input={<Input id="shipper"/>}
              onChange={(event) => setEditedVehicle({...editedVehicle, type: event.target.value})}
              required
              value={editedVehicle.type}
            >
              {Object.getOwnPropertyNames(VehicleTypes).map(vehicleTypeName => VehicleTypes[vehicleTypeName]).map((type, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={type}
                  >
                    {t(VehicleTypeEnumHelperService.getVehicleTypeString(type))}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <InfoTooltip
            descriptionKey={'40'}
            placement={'right'}
          >
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="vehicleCapacity">{t('vehicleCapacity')}</InputLabel>
              <Input
                id="vehicleCapacity"
                onChange={(event) => {
                  setEditedVehicle({
                    ...editedVehicle,
                    capacity: (parseFloat(event.target.value) && parseFloat(event.target.value) >= 0) ? parseFloat(event.target.value) : 0
                  })
                }}
                onClick={event => event.target.select()}
                onWheel={event => event.target.blur()}
                type="number"
                value={editedVehicle.capacity}
              />
            </FormControl>
          </InfoTooltip>
          <InfoTooltip
            descriptionKey={'32'}
            placement={'right'}
          >
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="vehicleVolume">{t('vehicleVolume')}</InputLabel>
              <Input
                id="vehicleVolume"
                required
                error={!editedVehicle.volume}
                onChange={(event) => {
                  setEditedVehicle({
                    ...editedVehicle,
                    volume: (parseFloat(event.target.value) && parseFloat(event.target.value) >= 0) ? parseFloat(event.target.value) : 0
                  })
                }}
                onClick={event => event.target.select()}
                onWheel={event => event.target.blur()}
                type="number"
                value={editedVehicle.volume}
              />
            </FormControl>
          </InfoTooltip>
          <InfoTooltip
            descriptionKey={'41'}
            placement={'right'}
          >
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="vehiclePayloadWeight">{t('vehiclePayloadWeight')}</InputLabel>
              <Input
                id="vehiclePayloadWeight"
                required
                error={!editedVehicle.payloadWeight}
                onChange={(event) => {
                  setEditedVehicle({
                    ...editedVehicle,
                    payloadWeight: (parseFloat(event.target.value) && parseFloat(event.target.value) >= 0) ? parseFloat(event.target.value) : 0
                  })
                }}
                onClick={event => event.target.select()}
                onWheel={event => event.target.blur()}
                type="number"
                value={editedVehicle.payloadWeight}
              />
            </FormControl>
          </InfoTooltip>
          <InfoTooltip
            descriptionKey={'33'}
            placement={'right'}
          >
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="vehicleAverageSpeedKmh">{t('vehicleAverageSpeedKmh')}</InputLabel>
              <Input
                id="vehicleAverageSpeedKmh"
                required
                error={!editedVehicle.averageSpeedKmh}
                onChange={(event) => {
                  setEditedVehicle({
                    ...editedVehicle,
                    averageSpeedKmh: (parseFloat(event.target.value) && parseFloat(event.target.value) >= 0) ? parseFloat(event.target.value) : 0
                  })
                }}
                onClick={event => event.target.select()}
                onWheel={event => event.target.blur()}
                type="number"
                value={editedVehicle.averageSpeedKmh}
              />
            </FormControl>
          </InfoTooltip>
        </FormGroup>

        <FormGroup className={classes.wrapperChild}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="vehicleDescription">{t('vehicleDescription')}</InputLabel>
            <Input
              id="vehicleDescription"
              onChange={(event) => {
                setEditedVehicle({...editedVehicle, description: event.target.value})
              }}
              value={editedVehicle.description}
            />
          </FormControl>
        </FormGroup>

        <FormGroup className={classes.wrapperChild}>
          <InfoTooltip
            className={classes.iconMargin}
            descriptionKey={'34'}
          >
            <FormControl className={classes.formControl}>
              <InputLabel
                htmlFor="microHubs"
                required
              >{t('microHubs')}</InputLabel>
              <Select
                error={!(selectedMicroHubNames && selectedMicroHubNames.length > 0)}
                input={<Input id="microHubs"/>}
                MenuProps={MenuProps}
                multiple
                onChange={changeSelectedMicroHubNames}
                renderValue={selected => (
                  <div
                    className={classes.chips}
                  >
                    {selected.map(value => (
                      <Chip
                        className={classes.chip}
                        key={value}
                        label={value}
                      />
                    ))}
                  </div>
                )}
                value={selectedMicroHubNames}
              >
                {microHubOptions.sort().map(name => (
                  <MenuItem
                    key={name}
                    value={name}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </InfoTooltip>
          {editedVehicle.microhubs.map((hub, index) => {
            return (
              <div
                className={classes.vehicleDetail}
                key={index}
              >
                <strong>{hub.name}</strong>
                <br/>
                {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day, index) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hub[day]}
                          color="secondary"
                          onChange={(event) => changeMicroHubDayOfWeek(event.target.checked, day, hub)}
                          value={hub[day]}
                        />
                      }
                      key={index}
                      label={t(day + 'Short')}
                    />
                  );
                })}
                <br/>
              </div>
            );
          })}
        </FormGroup>
      </div>
    </Fragment>
  );
}


EditVehicle.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  editedVehicle: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  microHubOptions: PropTypes.array.isRequired,
  setEditedVehicle: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(EditVehicle);
