export default theme => ({
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    width: '350px',
  },
  details: {
    paddingLeft: theme.spacing(1),
  }
});
