export default theme => ({
  root: {
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  buttonLarge: {
    marginLeft: theme.spacing(1)
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    minWidth: '300px',
    width: '300px',
    verticalAlign: 'middle'
  },
  formControlCheckbox: {
    verticalAlign: 'middle',
    display: 'flex'
  },
  formControlSelect: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: '150px',
    width: '150px',
    verticalAlign: 'middle'
  },
});
