import React, {Fragment} from 'react'
import {FormControl, Input, InputLabel, MenuItem, Select, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import Chip from '@material-ui/core/Chip';
import {InfoTooltip} from '../../../components';

function DriverEdit(props) {

  const {classes, className, editedDriver, t, setEditedDriver, microHubOptions} = props;

  const selectedMicroHubNames = editedDriver.microhubs.map(hub => hub.name);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const changeSelectedMicroHubNames = (event) => {
    const updatedMicroHubOptions = event.target.value.map(name => {
      return {name: name}
    });
    setEditedDriver({...editedDriver, microhubs: updatedMicroHubOptions})
  };

  const rootClassName = classNames(classes.root, className);

  return (
    <Fragment>
      <div className={rootClassName}>
        <FormGroup
          className={classes.wrapperChild}
        >
          <InfoTooltip
            className={classes.iconMargin}
            descriptionKey={'30_preFormat'}
          >
            <FormControl className={classes.formControl}>
              <InputLabel
                htmlFor="driverDisplayName"
                required
              > {t('driverDisplayName')} </InputLabel>
              <Input
                error={!editedDriver.displayName || editedDriver.displayName.length > 10}
                id="driverDisplayName"
                onChange={(event) => {
                  setEditedDriver({...editedDriver, displayName: event.target.value})
                }}
                required
                value={editedDriver.displayName}
              />
            </FormControl>
          </InfoTooltip>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="driverFirstName">{t('driverFirstName')}</InputLabel>
            <Input
              id="driverFirstName"
              onChange={(event) => {
                setEditedDriver({...editedDriver, firstname: event.target.value})
              }}
              value={editedDriver.firstname}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="driverLastName">{t('driverLastName')}</InputLabel>
            <Input
              id="driverLastName"
              onChange={(event) => {
                setEditedDriver({...editedDriver, lastname: event.target.value})
              }}
              value={editedDriver.lastname}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="driverPhoneNumber">{t('driverPhoneNumber')}</InputLabel>
            <Input
              id="driverPhoneNumber"
              onChange={(event) => {
                setEditedDriver({...editedDriver, phoneNumber: event.target.value})
              }}
              value={editedDriver.phoneNumber}
            />
          </FormControl>
          <InfoTooltip
            className={classes.iconMargin}
            descriptionKey={'29'}
          >
            <FormControl className={classes.formControl}>
              <InputLabel
                htmlFor="driverEmail"
                required
              > {t('driverEmail')} </InputLabel>
              <Input
                error={!editedDriver.email}
                id="driverEmail"
                onChange={(event) => {
                  setEditedDriver({...editedDriver, email: event.target.value})
                }}
                required
                value={editedDriver.email}
              />
            </FormControl>
          </InfoTooltip>
          <FormControlLabel
            className={classes.formControl}
            control={
              <Checkbox
                checked={editedDriver.car}
                color="secondary"
                onChange={(event) => setEditedDriver({...editedDriver, car: event.target.checked})}
                value={editedDriver.car}
              />
            }
            label={t('driverCar')}
          />
          <FormControlLabel
            className={classes.formControl}
            control={
              <Checkbox
                checked={editedDriver.truck}
                color="secondary"
                onChange={(event) => setEditedDriver({...editedDriver, truck: event.target.checked})}
                value={editedDriver.truck}
              />
            }
            label={t('driverTruck')}
          />
          <FormControlLabel
            className={classes.formControl}
            control={
              <Checkbox
                checked={editedDriver.bicycle}
                color="secondary"
                onChange={(event) => setEditedDriver({...editedDriver, bicycle: event.target.checked})}
                value={editedDriver.bicycle}
              />
            }
            label={t('driverBicycle')}
          />
        </FormGroup>

        <FormGroup className={classes.wrapperChild}>
          <FormControl className={classes.formControl}>
            <InputLabel
              htmlFor="microHubs"
              required
            >{t('microHubs')}</InputLabel>
            <Select
              error={!selectedMicroHubNames || !(selectedMicroHubNames.length > 0)}
              input={<Input id="microHubs"/>}
              MenuProps={MenuProps}
              multiple
              onChange={changeSelectedMicroHubNames}
              renderValue={selected => (
                <div
                  className={classes.chips}
                >
                  {selected.map(value => (
                    <Chip
                      className={classes.chip}
                      key={value}
                      label={value}
                    />
                  ))}
                </div>
              )}
              required
              value={selectedMicroHubNames}
            >
              {microHubOptions.sort().map(name => (
                <MenuItem
                  key={name}
                  value={name}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormGroup>
      </div>
    </Fragment>
  );
}


DriverEdit.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  editedDriver: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  microHubOptions: PropTypes.array.isRequired,
  setEditedDriver: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(DriverEdit);
