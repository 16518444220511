export default theme => ({
  root: {},
  capacityAllWrapper: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  capacityAllIndicatorWrapper: {
    marginRight: theme.spacing(1),
    width: '75%',
  },
  capacityAllIndicator: {
    borderRadius: 4,
    height: '10px'
  },
  progressBarRed: {
    color: theme.palette.danger.main,
    backgroundColor: theme.palette.danger.main,
  }
});
