import React from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {BoolDropdownSelect, CarrierAndMultipleMicroHubFilter, DropdownSelectSimple, KeyboardDateFilter} from '../../../components';

function PreviewToursFilter(props) {

  const {classes, className, t, filter, setFilter, carriersWithMicroHubs, shipperOptions, microHubOptions, disabled, hideFreezeFilter} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <form className={classes.rowWrapper}>
        <KeyboardDateFilter
          descriptionKey={'10'}
          error={!filter.date}
          required
          setValue={(value) => setFilter({...filter, date: value})}
          value={filter.date}
        />
        <CarrierAndMultipleMicroHubFilter
          carrierDescriptionKey={'20'}
          carriersWithMicroHubs={carriersWithMicroHubs}
          carrierValue={filter.carrierName}
          className={classes.rowWrapper}
          disabled={disabled}
          microHubDescriptionKey={'30'}
          microHubOptions={microHubOptions}
          microHubValue={filter.microHubList}
          setCarrierValue={(value) => setFilter({...filter, carrierName: value, microHubList: []})}
          setMicroHubValue={(value) => setFilter({...filter, microHubList: value})}
        />
        <DropdownSelectSimple
          descriptionKey={'35'}
          hideOnEmptyOptions
          name={t('shipper')}
          options={shipperOptions}
          setValue={(value) => setFilter({...filter, shipperName: value})}
          sort
          value={filter.shipperName}
        />
        {!hideFreezeFilter &&
        <BoolDropdownSelect
          descriptionKey={'40'}
          disabled={disabled}
          name={t('frozen')}
          setValue={(value) => setFilter({...filter, frozen: value})}
          value={filter.frozen}
        />
        }
      </form>
    </div>
  );
}


PreviewToursFilter.propTypes = {
  carriersWithMicroHubs: PropTypes.object,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  filter: PropTypes.object.isRequired,
  hideFreezeFilter: PropTypes.bool,
  microHubOptions: PropTypes.array,
  setFilter: PropTypes.func.isRequired,
  shipperOptions: PropTypes.array,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(PreviewToursFilter);
