export default theme => ({
  root: {},
  infoTypography: {
    whiteSpace: 'pre',
    textAlign: 'left',
    verticalAlign: 'middle'
  },
  navRight: {
    float: 'right'
  },
  navCenter: {
    display: 'inline-block',
    margin: '0 auto',
  },
  navLeft: {
    float: 'left'
  },
  navContainer: {
    marginTop: theme.spacing(2),
    width: '100%',
    textAlign: 'center'
  },
  minHeight: {
    minHeight: '400px'
  }
});
