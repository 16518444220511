export default theme => ({
  root: {},
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  map: {
    height: 195,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      height: 470,
    }
  },
  refreshButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  refreshButtonContent: {textTransform: 'none', display: 'flex'}
});

