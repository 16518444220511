import React, {Fragment} from 'react'
import {
  DragIndicatorOutlined as DragIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  LinkOff as TourTemplateAutoCutIcon,
  LowPriority as TemplateIcon,
} from '@material-ui/icons';
import {InfoIcon} from '../../../../index';
import {IconButton, Tooltip, Typography} from '@material-ui/core';
import {formatTemplateCapacityConstraint, roundCapacityValues} from '../../../../../services/util/helperFuncs';
import {NoCapacitiesAvailableWarning} from '../index';

const Columns = {
  tourTemplateNumber: {
    name: 'tourTemplateNumber',
    header: ({t, classes}) => (
      <div>
        {t('tourTemplateNumber')}&nbsp;
        <InfoIcon
          className={classes.infoIcon}
          descriptionKey={'70'}
        />
      </div>
    ),
    body: ({template, provided, classes, filter, snapshot, t}) => (
      <Fragment>
        <div
          {...provided.dragHandleProps}
          className={classes.dragIcon}
        >
          {!filter.shipperName && !filter.templateType && <DragIcon/>}
        </div>
        {snapshot.isDragging
          ?
          <Typography variant={'body1'}>
            <strong>{t('tourTemplateNumber')}:</strong> {template.tourTemplateNumber + ' | '}
            <strong>{t('tourTemplateId')}:</strong> {template.id + ' | '}
            <strong>{t('tourTemplateName')}:</strong> {template.templateName}
          </Typography>
          :
          template.tourTemplateNumber
        }
      </Fragment>
    )
  },
  tourTemplateId: {
    name: 'tourTemplateId',
    header: ({t}) => t('tourTemplateId'),
    body: ({template, classes, provided, t}) => (
      <div
        ref={provided.innerRef}
        {...provided.droppableProps}
      >
        {template.id}
        {template.isAutoCutTemplate ?
          <Tooltip title={t('tourTypeAutoCutTemplate')}>
            <TourTemplateAutoCutIcon
              className={classes.autoCutIcon}
              color={'secondary'}
            />
          </Tooltip>
          :
          <Tooltip title={t('tourTypeTemplate')}>
            <TemplateIcon
              className={classes.autoCutIcon}
              color={'secondary'}
            />
          </Tooltip>
        }
      </div>
    )
  },
  tourTemplateName: {
    name: 'tourTemplateName',
    header: ({t}) => t('tourTemplateName'),
    body: ({template, provided}) => (
      <div
        ref={provided.innerRef}
        {...provided.droppableProps}
      >
        {template.templateName}
      </div>
    )
  },
  carrier: {
    name: 'carrier',
    header: ({t}) => t('carrier'),
    body: ({template, provided}) => (
      <div
        ref={provided.innerRef}
        {...provided.droppableProps}
      >
        {template.carrier}
      </div>
    )
  },
  microHub: {
    name: 'microHub',
    header: ({t}) => t('microhub'),
    body: ({template, provided}) => (
      <div
        ref={provided.innerRef}
        {...provided.droppableProps}
      >
        {template.microHub}
      </div>
    )
  },
  tourTemplateCustomers: {
    name: 'tourTemplateCustomers',
    header: ({t}) => t('tourTemplateCustomers'),
    body: ({template, provided}) => (
      <div
        ref={provided.innerRef}
        {...provided.droppableProps}
      >
        {template.stops?.length || 0}
      </div>
    )
  },
  boxAmount: {
    name: 'boxAmount',
    header: ({t}) => t('boxAmount'),
    body: ({template, provided}) => (
      <div
        ref={provided.innerRef}
        {...provided.droppableProps}
      >
        {roundCapacityValues(template.capacities?.boxAmount)}&nbsp;
        {template.capacities?.stopsWithoutCapacities > 0 &&
          <NoCapacitiesAvailableWarning
            stopsWithoutCapacities={template.capacities.stopsWithoutCapacities}/>
        }
      </div>
    )
  },
  weight: {
    name: 'weight',
    header: ({t}) => t('weight'),
    body: ({template, provided}) => (
      <div
        ref={provided.innerRef}
        {...provided.droppableProps}
      >
        {roundCapacityValues(template.capacities?.weight)}&nbsp;
        {template.capacities?.stopsWithoutCapacities > 0 &&
          <NoCapacitiesAvailableWarning
            stopsWithoutCapacities={template.capacities.stopsWithoutCapacities}/>
        }
      </div>
    )
  },
  volume: {
    name: 'volume',
    header: ({t}) => t('volume'),
    body: ({template, provided, toggleOpen, open, classes}) => (
      <div
        ref={provided.innerRef}
        {...provided.droppableProps}
      >
        {roundCapacityValues(template.capacities?.volume)}&nbsp;
        {template.capacities?.stopsWithoutCapacities > 0 &&
          <NoCapacitiesAvailableWarning
            stopsWithoutCapacities={template.capacities.stopsWithoutCapacities}/>
        }
        <IconButton
          className={classes.iconButton}
          onClick={toggleOpen}
          size="small"
        >
          {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
        </IconButton>
      </div>
    )
  },
  shipper: {
    name: 'shipper',
    header: ({t}) => t('shipper'),
    body: ({template, provided}) => (
      <div
        ref={provided.innerRef}
        {...provided.droppableProps}
      >
        {template.shipper}
      </div>
    )
  },
  tourTemplateApplyAtMin: {
    name: 'tourTemplateApplyAtMin',
    header: ({t, classes}) => (
      <div>
        {t('tourTemplateApplyAtMin')}&nbsp;
        <InfoIcon
          className={classes.infoIcon}
          descriptionKey={'50'}
        />
      </div>
    ),
    body: ({template, provided}) => (
      <div
        ref={provided.innerRef}
        {...provided.droppableProps}
      >
        {formatTemplateCapacityConstraint(template.applyAtMin)}
      </div>
    )
  },
  tourTemplateMaxApplicable: {
    name: 'tourTemplateMaxApplicable',
    header: ({t, classes}) => (
      <div>
        {t('tourTemplateMaxApplicable')}&nbsp;
        <InfoIcon
          className={classes.infoIcon}
          descriptionKey={'60'}
        />
      </div>
    ),
    body: ({template, provided, toggleOpen, open, classes}) => (
      <div
        ref={provided.innerRef}
        {...provided.droppableProps}
      >
        {template.isAutoCutTemplate ? '-' : formatTemplateCapacityConstraint(template.maxApplicable)}
        <IconButton
          className={classes.iconButton}
          onClick={toggleOpen}
          size="small"
        >
          {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
        </IconButton>
      </div>
    )
  },
}

export default Columns;