export default theme => ({
  root: {
    overflow: 'auto'
  },
  paper: {
    padding: theme.spacing(1),
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  iconMargin: {
    marginLeft: '8px'
  },
  deleteButton: {
    color: theme.palette.danger.main,
  }
});

