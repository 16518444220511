import React, {useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {MicroHubElementTypes, MicroHubPlanPresetService,} from '../../../../../services/hubAdministrationHelper/hubAdministrationHelper';
import {MicroHubPlanElementEdit} from '../index';
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import {onCloseWithBackdropCurry} from '../../../../../services/util/helperFuncs';

function UpdateMicroHubPlanElementDialog(props) {

  const {classes, t, handleCancel, handleClose, updateMicroHubPlanElementDialogState, shipperOptions, hubData} = props;
  const [updatedElement, setUpdatedElement] = useState(null);
  const [shipperToAdd, setShipperToAdd] = useState(MicroHubPlanPresetService.getBoxZoneShipperPreset());

  if (!updatedElement && updateMicroHubPlanElementDialogState.open) {
    setUpdatedElement(JSON.parse(JSON.stringify(updateMicroHubPlanElementDialogState.element)));
  }

  const handleOk = () => {
    handleClose(updatedElement);
    setUpdatedElement(null);
    setShipperToAdd(MicroHubPlanPresetService.getBoxZoneShipperPreset());
  };

  const handleNotOk = () => {
    handleCancel();
    setUpdatedElement(null);
    setShipperToAdd(MicroHubPlanPresetService.getBoxZoneShipperPreset());
  };

  const dataNotOk = () => {
    if (!updatedElement) return true;
    switch (updateMicroHubPlanElementDialogState.elementType) {
      case MicroHubElementTypes.hubBase:
        return isNaN(parseInt(updatedElement.depth)) || parseInt(updatedElement.depth) < 100 || parseInt(updatedElement.depth) > 15000 || isNaN(parseInt(updatedElement.width)) || parseInt(updatedElement.width) < 100 || parseInt(updatedElement.width) > 15000;
      case MicroHubElementTypes.door:
        return isNaN(parseInt(updatedElement.doorSize)) || parseInt(updatedElement.doorSize) <= 25 || parseInt(updatedElement.doorSize) > 15000;
      case MicroHubElementTypes.boxZone:
        return isNaN(parseInt(updatedElement.depth)) || parseInt(updatedElement.depth) < 25 || parseInt(updatedElement.depth) > 15000 || isNaN(parseInt(updatedElement.width)) || parseInt(updatedElement.width) < 25 || parseInt(updatedElement.width) > 15000 || updatedElement.shipperInfos.length <= 0 || isNaN(updatedElement.stackHeight) || parseInt(updatedElement.stackHeight) < 25;
      case MicroHubElementTypes.beverageInsertZone:
        return isNaN(parseInt(updatedElement.depth)) || parseInt(updatedElement.depth) < 25 || parseInt(updatedElement.depth) > 15000 || isNaN(parseInt(updatedElement.width)) || parseInt(updatedElement.width) < 25 || parseInt(updatedElement.width) > 15000 || isNaN(parseInt(updatedElement.order)) || parseInt(updatedElement.order) <= 0 || hubData.beverageInsertZones.filter(zone => zone.tmpId !== updatedElement.tmpId).map(zone => zone.order).includes(parseInt(updatedElement.order));
      case MicroHubElementTypes.wall:
        return isNaN(parseInt(updatedElement.wallSize)) || parseInt(updatedElement.wallSize) < 25 || parseInt(updatedElement.wallSize) > 15000;
      case MicroHubElementTypes.blockerZone:
        return isNaN(parseInt(updatedElement.depth)) || parseInt(updatedElement.depth) < 25 || parseInt(updatedElement.depth) > 15000 || isNaN(parseInt(updatedElement.width)) || parseInt(updatedElement.width) < 25 || parseInt(updatedElement.width) > 15000;
      default :
        return true;
    }
  };

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      maxWidth={'sm'}
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={updateMicroHubPlanElementDialogState.open}
    >
      <DialogTitle>{t('microHubPlanUpdateSelectedElement')}</DialogTitle>
      <DialogContent>
        {updatedElement &&
        <MicroHubPlanElementEdit
          element={updatedElement}
          elementType={updateMicroHubPlanElementDialogState.elementType}
          hubData={hubData}
          setElement={setUpdatedElement}
          setShipperToAdd={setShipperToAdd}
          shipperOptions={shipperOptions}
          shipperToAdd={shipperToAdd}
        />
        }
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={handleNotOk}
        >
          <CancelIcon/>&nbsp;{t('cancel')}
        </Button>
        <Button
          color="primary"
          disabled={dataNotOk()}
          onClick={() => handleOk(updatedElement)}
        >
          <SaveIcon/>&nbsp;{t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

UpdateMicroHubPlanElementDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  hubData: PropTypes.object.isRequired,
  shipperOptions: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  updateMicroHubPlanElementDialogState: PropTypes.object.isRequired,
};


export default compose(withStyles(styles), withTranslation())(UpdateMicroHubPlanElementDialog);
