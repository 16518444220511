import React, {Fragment} from 'react';
import {Grid, Typography, withStyles} from '@material-ui/core';
import styles from './style';
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import Paper from '@material-ui/core/Paper';


function DashboardPlaceholder(props) {

  const {classes, t} = props;


  return (
    <Grid
      alignItems="center"
      className={classes.grid}
      container
      justifyContent="center"
    >
      <Grid
        className={classes.content}
        item
      >
        <Grid
          alignItems="center"
          container
          direction="column"
          justifyContent="center"
          spacing={3}
        >
          <Fragment>
            <Grid item>
              <Paper className={classes.paper}>
                <Typography variant="h4">
                  {t('underImplementation')}
                </Typography>
              </Paper>
            </Grid>
          </Fragment>
        </Grid>
      </Grid>
    </Grid>
  );
}

DashboardPlaceholder.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default compose(withStyles(styles), withTranslation())(DashboardPlaceholder);
