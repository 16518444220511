export default theme => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: '200px',
    width: '200px',
  },
  rowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: theme.spacing(0)
  }
});
