import * as L from 'leaflet';
import {uniq} from 'lodash';
import DOMPurify from 'dompurify';

export const planedIconColor = '#0767DB';
export const notPlanedIconColor = '#a6a6a6';
export const inDeliveryIconColor = '#DBDB00';
export const deliveredIconColor = '#00E600';
export const canNotDeliverIconColor = '#ff4646';
export const redeliveryIconColor = '#ffa900';
export const customerIconColor = '#ffffff';
export const templateStopPickupIconColor = '#00E600'
export const templateStopDeliveryIconColor = '#ff4646'
export const templateStopConnectionColor = '#000000'

export class IconService {

  static getStopSvgTemplate(color, stopNumber, title = 'Marker', innerColor = '#ffffff',) {
    const col = parseInt(color.substring(1), 16);
    const darkerColorHex = (col & 0xfefefe) >> 1;
    const darkerColor = `#${darkerColorHex.toString(16)}`;

    let textX = 9.99293;
    let circleRadius = 3.5;
    if (stopNumber.toString().length > 0) {
      circleRadius = 5;
    }
    if (stopNumber.toString().length > 1) {
      textX = 6.99293;
    }

    return DOMPurify.sanitize(`<svg width="25" height="41.00000000000001" xmlns="http://www.w3.org/2000/svg">
   <g>
      <title>${title}</title>
      <g transform="matrix(1.473906276860094,0,0,1.3917062763174055,-188.15489375044095,-67.46723861305563) " id="svg_2">
         <path d="m130.232118,51.586914c-1.25816,1.34277 -2.04623,3.29881 -2.01563,5.13867c0.0639,3.84476 1.79693,5.3002 4.56836,10.59179c0.99832,2.32851 2.04027,4.79237 3.03125,8.87305c0.13772,0.60193 0.27203,1.16104 0.33416,1.20948c0.0621,0.0485 0.19644,-0.51262 0.33416,-1.11455c0.99098,-4.08068 2.03293,-6.54258 3.03125,-8.87109c2.77143,-5.29159 4.50444,-6.74704 4.56836,-10.5918c0.0306,-1.83986 -0.75942,-3.79785 -2.01758,-5.14062c-1.43724,-1.53389 -3.60504,-2.66908 -5.91619,-2.71655c-2.31115,-0.0475 -4.4809,1.08773 -5.91814,2.62162z" stroke-miterlimit="4" stroke="${darkerColor}" fill="${color}" id="svg_3"/>
         <circle r="${circleRadius}" cy="56.868504" cx="136.150268" stroke-width="0" fill="${innerColor}" id="svg_4"/>
      </g>
      <text fill="#000000" stroke-width="1" x="${textX}" y="13.92401" id="svg_5" font-size="9" font-family="Helvetica, Arial, sans-serif" xml:space="preserve" transform="matrix(1.07302725315094,0,0,1.0934563875198364,-0.9698932059109211,-0.5475960206240416) " stroke="#000">${stopNumber}</text>
   </g>
</svg>`)
  }

  static getMicroHubSvgTemplate(color) {
    return DOMPurify.sanitize(`
    <svg width="40" height="52" viewBox="0 0 22 27" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
    <g>
      <mask id="path-2-inside-1" fill="white">
        <path
          d="M10.2249 18.7714C9.8661 18.7535 9.507 18.7999 9.16425 18.9083C8.15989 19.1812 7.1536 19.2193 6.18126 18.7773C4.30452 17.9245 3.92898 15.9079 5.36904 14.4252C5.65046 14.1319 5.9639 13.8766 6.26763 13.6096C6.86583 13.0856 7.38843 12.4799 7.82026 11.8101C9.18463 9.69082 12.1637 9.84828 13.2797 12.1035C13.662 12.8771 14.153 13.5372 14.8537 14.0468C15.2036 14.3096 15.5018 14.636 15.7328 15.0091C16.8546 16.7499 16.0744 18.6932 14.0618 19.1548C13.2393 19.3513 12.378 19.3041 11.5815 19.0188C11.1371 18.8604 10.6926 18.7157 10.2249 18.7714Z"/>
      </mask>
      <path
        d="M10.2249 18.7714C9.8661 18.7535 9.507 18.7999 9.16425 18.9083C8.15989 19.1812 7.1536 19.2193 6.18126 18.7773C4.30452 17.9245 3.92898 15.9079 5.36904 14.4252C5.65046 14.1319 5.9639 13.8766 6.26763 13.6096C6.86583 13.0856 7.38843 12.4799 7.82026 11.8101C9.18463 9.69082 12.1637 9.84828 13.2797 12.1035C13.662 12.8771 14.153 13.5372 14.8537 14.0468C15.2036 14.3096 15.5018 14.636 15.7328 15.0091C16.8546 16.7499 16.0744 18.6932 14.0618 19.1548C13.2393 19.3513 12.378 19.3041 11.5815 19.0188C11.1371 18.8604 10.6926 18.7157 10.2249 18.7714Z"
        fill="${color}" stroke="black" mask="url(#path-2-inside-1)"/>
      <mask id="path-3-inside-2" fill="white">
        <path
          d="M18.9987 11.1417C18.9628 12.4004 18.4058 13.3539 17.2986 13.9427C16.303 14.4718 15.2501 13.9974 14.9163 12.9089C14.7321 12.3121 14.7644 11.6688 15.0075 11.0938C15.3627 10.2136 15.8275 9.40091 16.532 8.74664C17.221 8.10802 17.9352 8.19212 18.4214 8.997C18.8163 9.65127 18.9745 10.376 18.9987 11.1417Z"/>
      </mask>
      <path
        d="M18.9987 11.1417C18.9628 12.4004 18.4058 13.3539 17.2986 13.9427C16.303 14.4718 15.2501 13.9974 14.9163 12.9089C14.7321 12.3121 14.7644 11.6688 15.0075 11.0938C15.3627 10.2136 15.8275 9.40091 16.532 8.74664C17.221 8.10802 17.9352 8.19212 18.4214 8.997C18.8163 9.65127 18.9745 10.376 18.9987 11.1417Z"
        fill="${color}" stroke="black" mask="url(#path-3-inside-2)"/>
      <mask id="path-4-inside-3" fill="white">
        <path
          d="M11.3335 6.85795C11.4228 5.95722 11.4402 4.97533 11.8527 4.06874C12.2408 3.20909 12.8444 3.02327 13.6508 3.50151C15.3626 4.51763 16.2155 6.71125 15.5625 8.39142C15.3727 8.87995 15.0556 9.30773 14.6445 9.62955C13.8216 10.2887 12.695 10.0872 12.0147 9.1699C11.5227 8.50487 11.3393 7.74204 11.3335 6.85795Z"/>
      </mask>
      <path
        d="M11.3335 6.85795C11.4228 5.95722 11.4402 4.97533 11.8527 4.06874C12.2408 3.20909 12.8444 3.02327 13.6508 3.50151C15.3626 4.51763 16.2155 6.71125 15.5625 8.39142C15.3727 8.87995 15.0556 9.30773 14.6445 9.62955C13.8216 10.2887 12.695 10.0872 12.0147 9.1699C11.5227 8.50487 11.3393 7.74204 11.3335 6.85795Z"
        fill="${color}" stroke="black" mask="url(#path-4-inside-3)"/>
      <mask id="path-5-inside-4" fill="white">
        <path
          d="M9.974 6.51666C10.0224 7.25361 9.85359 7.98854 9.4888 8.6291C9.08706 9.32347 8.54169 9.81833 7.68484 9.82322C6.82798 9.82811 6.28262 9.29805 5.9032 8.60368C5.15793 7.24134 5.43838 5.66972 6.62614 4.34945C7.02885 3.90153 7.46941 3.50153 8.03224 3.25899C8.7416 2.95386 9.24911 3.13381 9.56643 3.85361C9.92451 4.65555 9.97788 5.51423 9.974 6.51666Z"/>
      </mask>
      <path
        d="M9.974 6.51666C10.0224 7.25361 9.85359 7.98854 9.4888 8.6291C9.08706 9.32347 8.54169 9.81833 7.68484 9.82322C6.82798 9.82811 6.28262 9.29805 5.9032 8.60368C5.15793 7.24134 5.43838 5.66972 6.62614 4.34945C7.02885 3.90153 7.46941 3.50153 8.03224 3.25899C8.7416 2.95386 9.24911 3.13381 9.56643 3.85361C9.92451 4.65555 9.97788 5.51423 9.974 6.51666Z"
        fill="${color}" stroke="black" mask="url(#path-5-inside-4)"/>
      <mask id="path-6-inside-5" fill="white">
        <path
          d="M6.21575 11.7244C6.22504 12.0215 6.17896 12.3177 6.0799 12.5977C5.65584 13.8007 4.43023 14.1576 3.37444 13.3889C1.83346 12.2691 1.66267 9.5699 3.05421 8.24767C3.49962 7.82322 3.98773 7.75867 4.43702 8.16943C5.46355 9.07819 6.10102 10.3523 6.21575 11.7244Z"/>
      </mask>
      <path
        d="M6.21575 11.7244C6.22504 12.0215 6.17896 12.3177 6.0799 12.5977C5.65584 13.8007 4.43023 14.1576 3.37444 13.3889C1.83346 12.2691 1.66267 9.5699 3.05421 8.24767C3.49962 7.82322 3.98773 7.75867 4.43702 8.16943C5.46355 9.07819 6.10102 10.3523 6.21575 11.7244Z"
        fill="${color}" stroke="black" mask="url(#path-6-inside-5)"/>
    </g>
  </g>
</svg>`);
  }

  static getMarkerClusterSvgTemplate(color, text) {
    return DOMPurify.sanitize(text && text !== '' ?
      `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50">
    <circle r="18" cx="25" cy="25" fill="${color}" fill-opacity="0.6" id="svg_6"/>
    <circle r="14" cx="25" cy="25" fill="${color}" fill-opacity="0.8" id="svg_7"/>
    <circle r="10" cx="25" cy="25" stroke-width="1" stroke="#000000" fill="${color}" fill-opacity="1" id="svg_8"/>
    <circle r="8" cx="25" cy="25" stroke-width="1" fill="#ffffff" fill-opacity="1" id="svg_8"/>
    <text text-anchor="middle" id="svg_5" font-size="11" font-family="Helvetica, Arial, sans-serif" font-weight="bold">
    <tspan y="28" x="25">${text}</tspan>
    </text>
    </svg>`
      :
      `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50">
    <circle r="18" cx="25" cy="25" fill="${color}" fill-opacity="0.6" id="svg_6"/>
    <circle r="14" cx="25" cy="25" fill="${color}" fill-opacity="0.8" id="svg_7"/>
    <circle r="10" cx="25" cy="25" stroke-width="1" stroke="#000000" fill="${color}" fill-opacity="1" id="svg_8"/>
    </svg>`)
  }

  static getOverlapMarkerSvgTemplate(colors = [], numOverlaps) {
    const verticalTranslate = 25 / colors.length;
    const markerInner = colors.map((color, index) =>
      `<path
        transform="translate(0 ${verticalTranslate * index})"
        d="m130.232118,51.586914c-1.25816,1.34277 -2.04623,3.29881 -2.01563,5.13867c0.0639,3.84476 1.79693,5.3002 4.56836,10.59179c0.99832,2.32851 2.04027,4.79237 3.03125,8.87305c0.13772,0.60193 0.27203,1.16104 0.33416,1.20948c0.0621,0.0485 0.19644,-0.51262 0.33416,-1.11455c0.99098,-4.08068 2.03293,-6.54258 3.03125,-8.87109c2.77143,-5.29159 4.50444,-6.74704 4.56836,-10.5918c0.0306,-1.83986 -0.75942,-3.79785 -2.01758,-5.14062c-1.43724,-1.53389 -3.60504,-2.66908 -5.91619,-2.71655c-2.31115,-0.0475 -4.4809,1.08773 -5.91814,2.62162z"
        fill="${color}"/>`)
      .join('');

    return DOMPurify.sanitize(`<svg width="25" height="41.00000000000001" xmlns="http://www.w3.org/2000/svg">
       <g>
          <title>${'Überlappungen ' + numOverlaps}</title>
          <g transform="matrix(1.473906276860094,0,0,1.3917062763174055,-188.15489375044095,-67.46723861305563) " id="svg_2">
             <mask id="markerOutline" fill="white">
                <path
                  d="m130.232118,51.586914c-1.25816,1.34277 -2.04623,3.29881 -2.01563,5.13867c0.0639,3.84476 1.79693,5.3002 4.56836,10.59179c0.99832,2.32851 2.04027,4.79237 3.03125,8.87305c0.13772,0.60193 0.27203,1.16104 0.33416,1.20948c0.0621,0.0485 0.19644,-0.51262 0.33416,-1.11455c0.99098,-4.08068 2.03293,-6.54258 3.03125,-8.87109c2.77143,-5.29159 4.50444,-6.74704 4.56836,-10.5918c0.0306,-1.83986 -0.75942,-3.79785 -2.01758,-5.14062c-1.43724,-1.53389 -3.60504,-2.66908 -5.91619,-2.71655c-2.31115,-0.0475 -4.4809,1.08773 -5.91814,2.62162z"/>
             </mask>
             <g mask="url(#markerOutline)">
                ${markerInner}
             </g>
             <path
               d="m130.232118,51.586914c-1.25816,1.34277 -2.04623,3.29881 -2.01563,5.13867c0.0639,3.84476 1.79693,5.3002 4.56836,10.59179c0.99832,2.32851 2.04027,4.79237 3.03125,8.87305c0.13772,0.60193 0.27203,1.16104 0.33416,1.20948c0.0621,0.0485 0.19644,-0.51262 0.33416,-1.11455c0.99098,-4.08068 2.03293,-6.54258 3.03125,-8.87109c2.77143,-5.29159 4.50444,-6.74704 4.56836,-10.5918c0.0306,-1.83986 -0.75942,-3.79785 -2.01758,-5.14062c-1.43724,-1.53389 -3.60504,-2.66908 -5.91619,-2.71655c-2.31115,-0.0475 -4.4809,1.08773 -5.91814,2.62162z"
               fill-opacity="0"
               stroke="#000"
               stroke-width="0.4"
             />
             <circle r="5" cy="56.868504" cx="136.150268" stroke-width="0" fill="#fff" id="svg_4"/>
          </g>
          <text fill="#000000" stroke-width="1" x="7.8" y="17.5" id="svg_5" font-size="18" font-family="Helvetica, Arial, sans-serif" xml:space="preserve"
                transform="matrix(1.07302725315094,0,0,1.0934563875198364,-0.9698932059109211,-0.5475960206240416) " stroke="#000">+</text>
       </g>
    </svg>`);
  }

  static getCustomerMarkerIcon(color, title) {
    return L.divIcon({
      className: '',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [0, -38],
      html: this.getStopSvgTemplate(color, '', title, '#000000')
    });
  }

  static getTemplateStopMarkerIcon(color, title) {
    return L.divIcon({
      className: '',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [0, -38],
      html: this.getStopSvgTemplate(color, '', title)
    });
  }

  static getTourStopMarkerIcon(color, stopNumber, title) {

    if (stopNumber === null) {
      stopNumber = '';
    }

    return L.divIcon({
      className: '',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [0, -38],
      html: this.getStopSvgTemplate(color, stopNumber, title)
    });
  }

  static getMicroHubIcon(color) {
    return L.divIcon({
      className: '',
      iconSize: [25, 41],
      iconAnchor: [15, 31],
      popupAnchor: [0, -25],
      html: this.getMicroHubSvgTemplate(color === null ? '#000000' : color)
    });
  }

  static getMarkerClusterIcon(color, text = '') {
    return L.divIcon({
      className: '',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [0, -38],
      html: this.getMarkerClusterSvgTemplate(color === null ? '#000000' : color, text)
    });
  }

  static getOverlappingMarkersIcon(colors, numElements) {
    //Sort colors and make sure to take different looking colors
    const tmpColors = uniq(colors).sort();
    let colorsForMaker = tmpColors.splice(0, 1);
    colorsForMaker = colorsForMaker.concat(tmpColors.splice(Math.floor(tmpColors.length / 2), 1));
    colorsForMaker = colorsForMaker.concat(tmpColors.splice(tmpColors.length - 1, 1));
    colorsForMaker = colorsForMaker.concat(tmpColors.splice(1, 1));
    colorsForMaker = colorsForMaker.concat(tmpColors.splice(tmpColors.length - 2, 1));
    colorsForMaker = uniq(colorsForMaker);
    return L.divIcon({
      className: '',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [0, -38],
      html: this.getOverlapMarkerSvgTemplate(colorsForMaker, numElements)
    });
  }

}
