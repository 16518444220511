export default theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
  },
  container: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }
  },
  donationButtonContainer: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    }
  }
});

