export default theme => ({
  root: {},
  button: {
    marginLeft: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '200px',
    width: '200px',
  },
  wrapper: {
    display: 'flex',
    margin: theme.spacing(2),
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  wrapperChild: {
    flex: 1,
  },
  vehicleDetail: {
    margin: theme.spacing(1)
  },
});
