export default theme => ({
  root: {},
  button: {
    marginLeft: theme.spacing(1)
  },
  wrapper: {
    display: 'flex',
    margin: theme.spacing(2)
  },
  wrapperChild: {
    flex: 1,
  },
  stopDetail: {
    margin: theme.spacing(1)
  },
  previewData: {
    display: 'flex'
  },
  warnButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
      color: theme.palette.common.white,
    }
  },
});
