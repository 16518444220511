import { AppBar, Tab, Tabs, withStyles } from '@material-ui/core';
import styles from './styles'
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { SideNavLayout } from 'layouts';
import React, { useContext, useState } from 'react';
import { TemplateStopInstanceFilter, TemplateStopInstanceOverview, TemplateStopTourOverview, TemplateStopTourOverviewFilter } from 'components';
import useShipperOptions from 'hooks/useShipperOptions';
import { AuthService } from 'services/auth/authService';
import { displayModes } from 'services/enums/displayModes';
import useCarriersWithHubs from 'hooks/useCarriersWithHubs';
import { TourFilter } from 'services/enums/TourFilter';
import { DatePickerDefaultValueContext } from 'context';

const tabStates = {
  templateStopInstance: 0,
  tourOverview: 1,
}

function TemplateStopOverview(props) {
  const { classes, t } = props;
  const [tabState, setTabState] = useState(tabStates.templateStopInstance)
  const { shipperOptions } = useShipperOptions();
  const { fromDateDefault, toDateDefault } = useContext(DatePickerDefaultValueContext);
  const [filter, setFilter] = useState({
    shipperName: '',
    carrierName: AuthService.getUserOrganization(),
    microHubName: '',
    dayOfWeek: '',
    tourFilter: TourFilter.Empty,
    fromDate: fromDateDefault,
    toDate: toDateDefault,
  });
  const [searchInput, setSearchInput] = useState('');

  const { microHubOptions } = useCarriersWithHubs(filter.carrierName);

  return (
    <SideNavLayout title={t('templateStopOverview')}>
      <div>Test</div>
      <AppBar
        className={classes.appBar}
        color={'secondary'}
        position={'relative'}
      >
        <Tabs
          onChange={(event, newValue) => {
            setTabState(newValue);
          }}
          value={tabState}
        >
          <Tab
            label={t('templateStopInstances')}
          />
          <Tab
            label={t('templateStopTourOverview')}
          />
        </Tabs>
      </AppBar>
      <div className={classes.root}>
        {(() => {
          switch (tabState) {
            case tabStates.templateStopInstance:
              return (
                <div>
                  <TemplateStopInstanceFilter
                    filter={filter}
                    searchInput={searchInput}
                    setFilter={setFilter}
                    setSearchInput={setSearchInput}
                    shipperOptions={shipperOptions}
                    microHubs={microHubOptions}
                    useSearch
                    displayMode={displayModes.carrier}
                  />
                  <br />
                  <TemplateStopInstanceOverview
                    filter={filter}
                    searchInput={searchInput}
                    displayMode={displayModes.carrier}
                  />
                </div>
              );
            case tabStates.tourOverview:
              return (
                <div>
                  <TemplateStopTourOverviewFilter
                    microHubs={microHubOptions}
                    filter={filter}
                    setFilter={setFilter}
                    shipperOptions={shipperOptions}
                    displayMode={displayModes.carrier}
                  />
                  <TemplateStopTourOverview filter={filter} displayMode={displayModes.carrier} />
                </div>
              )
            default:
              return (<div>Error</div>);
          }
        })()}
      </div>
    </SideNavLayout>
  );
}

TemplateStopOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TemplateStopOverview);