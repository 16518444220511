import React from 'react'
import {Button} from '@material-ui/core';
import {DeleteForever as DeleteIcon,} from '@material-ui/icons';
import {InfoIcon} from '../../index';

const Columns = {
  driverFirstName: {
    name: 'driverFirstName',
    header: ({t}) => t('driverFirstName'),
    body: ({driver}) => driver.firstname
  },
  driverLastName: {
    name: 'driverLastName',
    header: ({t}) => t('driverLastName'),
    body: ({driver}) => driver.lastname
  },
  driverDisplayName: {
    name: 'driverDisplayName',
    header: ({t}) => t('driverDisplayName'),
    body: ({driver}) => driver.displayName
  },
  driverPhoneNumber: {
    name: 'driverPhoneNumber',
    header: ({t}) => t('driverPhoneNumber'),
    body: ({driver}) => driver.phoneNumber
  },
  driverEmail: {
    name: 'driverEmail',
    header: ({t, classes}) => (
      <div className={classes.iconWrapper}>
        {t('driverEmail')}
        <InfoIcon
          className={classes.iconMargin}
          descriptionKey={'29'}
        />
      </div>
    ),
    body: ({driver}) => driver.email
  },
  driverType: {
    name: 'driverType',
    header: ({t}) => t('driverType'),
    body: ({driver, displayDriverTypes}) => displayDriverTypes(driver.bicycle, driver.car, driver.truck)
  },
  carrier: {
    name: 'carrier',
    header: ({t}) => t('carrier'),
    body: ({driver}) => driver.carrierName
  },
  donationButton: {
    name: 'donationButton',
    header: ({t}) => t('donationButton'),
    body: ({
      driver,
      t
    }) => driver.donationButton ? (driver.donationButtonConfirmed ? t('yes') : t('donationButtonNotApproved')) : t('no')
  },
  driverDelete: {
    name: 'driverDelete',
    header: ({t}) => t('driverDelete'),
    body: ({driver, t, deleteDriver, classes}) => (
      <Button
        className={classes.deleteButton}
        color="primary"
        onClick={(event) => {
          if (window.confirm(t('confirmDriverDelete'))) deleteDriver(driver, event)
        }}
        style={{padding: 0}}
        title={t('driverDelete')}
      >
        <DeleteIcon/>
      </Button>
    )
  },
}

export default Columns;