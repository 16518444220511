// eslint-disable-next-line no-unused-vars
export default theme => ({
  root: {
    overflow: 'auto'
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  wrapper: {
    display: 'flex',
    margin: theme.spacing(2)
  },
  wrapperChild: {
    flex: 1,
  },
  stopDetail: {
    margin: theme.spacing(1)
  },
});
