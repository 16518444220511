export default theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  tableCell: {
    padding: 0,
    margin: 0,
  },
  firstTableCell: {
    paddingLeft: theme.spacing(2),
  }
});
