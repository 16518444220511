export default theme => ({
  root: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0)
  },
  buttonRight: {
    marginRight: theme.spacing(1),
  },
  appBar: {
    left: '2px',
    width: 'calc(100% - 2px)',
    zIndex: 'unset'
  },
  helpWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  warningColor: {
    color: theme.palette.warning.main,
    verticalAlign: 'middle'
  },
  filterRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});

