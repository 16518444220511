// eslint-disable-next-line no-unused-vars
export default theme => ({
  dialog: {},
  warningColor: {
    color: theme.palette.warning.main,
    verticalAlign: 'middle'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '200px',
    width: '200px',
  },
  container: {
    marginBottom: theme.spacing(1)
  },
  dangerColor: {
    color: theme.palette.danger.main,
    verticalAlign: 'middle'
  },
});
