import React from 'react'
import {TableCell, TableRow, Tooltip, withStyles} from '@material-ui/core';
import styles from '../styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';


function OtherHeader(props) {

  const {className, t, canExpand} = props;

  return (
    <TableRow className={className}>
      <TableCell style={canExpand ? {} : {fontSize: 'inherit'}}>{t('productDescription')}</TableCell>
      <TableCell style={canExpand ? {} : {fontSize: 'inherit'}}>{t('productNumber')}</TableCell>
      <TableCell style={canExpand ? {} : {fontSize: 'inherit'}}>{t('productAmount')}</TableCell>
      <Tooltip title={t('productPerUnit')}>
        <TableCell style={canExpand ? {} : {fontSize: 'inherit'}}>{t('productVolume')}</TableCell>
      </Tooltip>
      {canExpand &&
      <TableCell style={canExpand ? {} : {fontSize: 'inherit'}}/>
      }
    </TableRow>
  );
}


OtherHeader.propTypes = {
  canExpand: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}
;

export default compose(withStyles(styles), withTranslation())(OtherHeader);
