import axios from 'axios';
import {AuthService} from '../auth/authService';

const baseUri = `${process.env.REACT_APP_API_URI}trackAndTrace`;

const getTrackAndTraceInfoByStopId = async (stopId) => {
  const response = await axios.get(`${baseUri}/tracking/stopId/${stopId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  if (response.status === 204) return null;
  return response.data;
};

const getTrackAndTraceInfoByStopIds = async (stopIds) => {
  const response = await axios.put(`${baseUri}/tracking/stopIds`,
    stopIds,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const getTrackAndTraceTrackingLinkInfosByGuid = async (guid) => {
  const response = await axios.get(`${baseUri}/trackingLink/${guid}`,
    {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  return response.data;
}

const deactivateEmailNotificationsByInternalCustomerId = async (internalCustomerId) => {
  return await axios.put(`${baseUri}/trackingLink/deactivate-email-notifications/${internalCustomerId}`,
    null,
    {
      headers: {
        'Content-Type': 'application/json',
      }
    });
}

const getTrackingEstimateForCustomerByVarianceAndMean = async (variance, mean) => {
  const response = await axios.get(`${baseUri}/distribution/confidenceInterval/${variance}/${mean}`,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
}

const sendDummyTrackingEmail = async (shipper, emailData) => {
  return await axios.post(`${baseUri}/test/testEmail/${shipper}`,
    emailData,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    })
}


export default {
  getTrackAndTraceInfoByStopId,
  getTrackAndTraceTrackingLinkInfosByGuid,
  deactivateEmailNotificationsByInternalCustomerId,
  getTrackAndTraceInfoByStopIds,
  getTrackingEstimateForCustomerByVarianceAndMean,
  sendDummyTrackingEmail
};
