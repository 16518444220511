import React from 'react'
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {DropdownSelectSimple, MultiSelectDropdownSelect} from '../../index';

function CarrierAndMultipleMicroHubFilter(props) {

  const {
    className,
    t,
    carrierValue,
    microHubValue,
    setCarrierValue,
    setMicroHubValue,
    carriersWithMicroHubs,
    microHubOptions,
    disabled,
    carrierDescriptionKey,
    microHubDescriptionKey
  } = props;

  const carrierOptions = [];
  const microHubOptionsCalculated = microHubOptions && microHubOptions.length > 0 ? microHubOptions : [];

  if (carriersWithMicroHubs) {
    Object.keys(carriersWithMicroHubs).forEach(carrier => {
      carrierOptions.push(carrier);
      carriersWithMicroHubs[carrier].forEach(hub => {
        if (!microHubOptionsCalculated.includes(hub)) {
          microHubOptionsCalculated.push(hub);
        }
      })
    })
  }

  return (
    <div className={className}>
      <DropdownSelectSimple
        descriptionKey={carrierDescriptionKey}
        disabled={disabled}
        hideOnEmptyOptions
        name={t('carrier')}
        options={carrierOptions}
        setValue={(value) => setCarrierValue(value)}
        sort
        value={carrierValue}
      />
      <MultiSelectDropdownSelect
        descriptionKey={microHubDescriptionKey}
        disabled={disabled}
        hideOnEmptyOptions
        maxItemsDisplayed={2}
        name={t('microHubs')}
        options={microHubOptionsCalculated}
        setValue={setMicroHubValue}
        value={microHubValue}
      />
    </div>
  );
}


CarrierAndMultipleMicroHubFilter.propTypes = {
  carrierDescriptionKey: PropTypes.string,
  carrierValue: PropTypes.string.isRequired,
  carriersWithMicroHubs: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  microHubDescriptionKey: PropTypes.string,
  microHubOptions: PropTypes.array,
  microHubValue: PropTypes.array.isRequired,
  setCarrierValue: PropTypes.func.isRequired,
  setMicroHubValue: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(CarrierAndMultipleMicroHubFilter);
