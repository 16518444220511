import {withStyles} from '@material-ui/core';
import styles from './styles'
import classNames from 'classnames';
import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Map, TileLayer} from 'react-leaflet';
import * as L from 'leaflet';
import {GestureHandling} from 'leaflet-gesture-handling';
import {
  LegendInteractive,
  MapControls,
  MarkerCreateFuncs,
  MicroHubMarker,
  OverlappingMarkerCluster,
  OverlappingMarkerCreateFuncs,
  StopMarkerCluster
} from '../../MapComponents';
import {IconService} from '../../../../services/icon/iconService';
import {getColorByStatus, StopStatus} from '../../../../services/enums/StopStatus';
import {groupBy} from 'lodash';
import {
  filterMicroHubsForCoords,
  filterStopsForCoords,
  filterStopsForDuplicate,
  getBoundingBox,
  getBoundingBoxCoords,
  getElementsWithSamePosition,
  getNumStopsOnMap,
} from '../../../../services/util/mapUtils';
import useMapControls from '../../../../hooks/useMapControls';


const StopMap = React.memo(props => {

  const {classes, className, stops, microHubs, noControls, stopStatusFilter} = props;
  const [legendCheckboxes, setLegendCheckboxes] = useState({
    displayNotPlanedStops: true,
    displayNotPlanedStopsDisabled: false,
    displayPlanedStops: true,
    displayPlanedStopsDisabled: false,
    displayInDeliveryStops: true,
    displayInDeliveryStopsDisabled: false,
    displayDeliveredStops: true,
    displayDeliveredStopsDisabled: false,
    displayCanNotDeliverStops: true,
    displayCanNotDeliverStopsDisabled: false,
    displayRedeliveryStops: true,
    displayRedeliveryStopsDisabled: false,
    displayMicroHubs: true
  });

  useEffect(() => {
    if (stopStatusFilter !== '' && stopStatusFilter !== null && stopStatusFilter !== undefined) {
      setLegendCheckboxes({
        ...legendCheckboxes,
        displayNotPlanedStops: stopStatusFilter === StopStatus.NotPlaned,
        displayNotPlanedStopsDisabled: true,
        displayPlanedStops: stopStatusFilter === StopStatus.Planed,
        displayPlanedStopsDisabled: true,
        displayInDeliveryStops: stopStatusFilter === StopStatus.InDelivery,
        displayInDeliveryStopsDisabled: true,
        displayDeliveredStops: stopStatusFilter === StopStatus.Delivered,
        displayDeliveredStopsDisabled: true,
        displayCanNotDeliverStops: stopStatusFilter === StopStatus.CanNotDeliver,
        displayCanNotDeliverStopsDisabled: true,
        displayRedeliveryStops: stopStatusFilter === StopStatus.Redelivery,
        displayRedeliveryStopsDisabled: true,
      });
    } else {
      setLegendCheckboxes({
        ...legendCheckboxes,
        displayNotPlanedStopsDisabled: false,
        displayPlanedStopsDisabled: false,
        displayInDeliveryStopsDisabled: false,
        displayDeliveredStopsDisabled: false,
        displayCanNotDeliverStopsDisabled: false,
        displayRedeliveryStopsDisabled: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopStatusFilter]);

  const rootClassName = classNames(classes.root, className);
  const stopsWithCoords = filterStopsForCoords(stops).filter(
    stop => {
      switch (stop.stopStatus) {
        case StopStatus.NotPlaned: {
          return legendCheckboxes.displayNotPlanedStops;
        }
        case StopStatus.Planed: {
          return legendCheckboxes.displayPlanedStops;
        }
        case StopStatus.InDelivery: {
          return legendCheckboxes.displayInDeliveryStops;
        }
        case StopStatus.Delivered: {
          return legendCheckboxes.displayDeliveredStops;
        }
        case StopStatus.CanNotDeliver: {
          return legendCheckboxes.displayCanNotDeliverStops;
        }
        case StopStatus.Redelivery: {
          return legendCheckboxes.displayRedeliveryStops;
        }
        default: {
          return false;
        }
      }
    }
  );

  const stopsOnMap = getNumStopsOnMap([], stopsWithCoords);
  const {useCluster, setUseCluster, useDuplicateSpiderfy, setUseDuplicateSpiderfy, highLiteDuplicates, setHighLiteDuplicates} = useMapControls(stopsOnMap);

  const overlappingElementGroups = useDuplicateSpiderfy ? getElementsWithSamePosition([], stopsWithCoords) : [];
  const keysOfOverlappingElements = overlappingElementGroups.map(g => g[0].key);

  const filteredStops = filterStopsForDuplicate(stopsWithCoords, keysOfOverlappingElements);

  const microHubsWithCoords = filterMicroHubsForCoords(microHubs);
  const map = useRef(null);

  const stopsGroupedbyStatus = groupBy(filteredStops, stop => stop.stopStatus);

  L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);


  const fitMapBoundsToFG = useCallback(() => {
    const coordinates = getBoundingBoxCoords([], stopsWithCoords, legendCheckboxes.displayMicroHubs ? microHubsWithCoords : []);
    if (coordinates.length <= 1) return;
    const boundingBox = getBoundingBox(coordinates);
    map.current.leafletElement.fitBounds(boundingBox, {padding: [30, 30]});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [microHubs, stops, legendCheckboxes.displayMicroHubs]);

  useEffect(() => {
    fitMapBoundsToFG();
  }, [fitMapBoundsToFG]);

  return (
    <Fragment>
      {!noControls &&
      <Fragment>
        {!noControls &&
        <div className={classes.legendWrapper}>
          <LegendInteractive
            hideStopStatus={false}
            legendCheckboxesState={legendCheckboxes}
            setLegendCheckboxesState={setLegendCheckboxes}
          />
        </div>
        }
      </Fragment>
      }
      <Map
        boundsOptions={{padding: [10, 10]}}
        center={filteredStops.length === 1 ? [filteredStops[0].address.latitude, filteredStops[0].address.longitude] : [50, 10.5]}
        className={rootClassName}
        gestureHandling
        ref={map}
        zoom={filteredStops.length === 1 ? 14 : 6}
        zoomSnap={0.25}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {Object.keys(stopsGroupedbyStatus).map((key, index) => (
          <StopMarkerCluster
            clusterIconColor={getColorByStatus(key)}
            getClusterIcon={(iconColor) => IconService.getMarkerClusterIcon(iconColor)}
            getStopMarkerAndAddToMcg={(stop, mcg, t) => MarkerCreateFuncs.getStopMarker(stop, mcg, t, undefined)}
            key={index}
            stops={stopsGroupedbyStatus[key]}
            useCluster={useCluster}
          />
        ))}
        {overlappingElementGroups.map((elementGroup, index) => (
          <OverlappingMarkerCluster
            getClusterIcon={(overlappingElementGroups, highLiteDuplicates) => OverlappingMarkerCreateFuncs.getForStopMap(overlappingElementGroups, highLiteDuplicates)}
            getStopMarkerAndAddToMcg={(stop, mcg, t) => MarkerCreateFuncs.getStopMarker(stop, mcg, t, undefined)}
            highLiteDuplicates={highLiteDuplicates}
            key={index}
            overlappingElements={elementGroup}
          />
        ))
        }
        {legendCheckboxes.displayMicroHubs && microHubsWithCoords.map((hub, index) => {
          return (
            <MicroHubMarker
              key={index}
              microHub={hub}
            />
          );
        })
        }
      </Map>
      {!noControls &&
      <MapControls
        highLiteDuplicates={highLiteDuplicates}
        setHighLiteDuplicates={setHighLiteDuplicates}
        setUseCluster={setUseCluster}
        setUseDuplicateSpiderfy={setUseDuplicateSpiderfy}
        useCluster={useCluster}
        useDuplicateSpiderfy={useDuplicateSpiderfy}
      />
      }
    </Fragment>
  );
});

StopMap.displayName = 'StopMap';

StopMap.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  microHubs: PropTypes.array,
  noControls: PropTypes.bool,
  stopStatusFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stops: PropTypes.array,
};


export default withStyles(styles)(StopMap);

