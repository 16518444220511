const colors = ['#689B59', '#66B9D3', '#8C80D9', '#E95BAD', '#F6896A', '#F6BA6A', '#00A651', '#0066B3',
  '#8441B6', '#E80689', '#ED1C24', '#F7941E', '#9CCC44', '#2EB3FF', '#9E98C5', '#FF95D8',
  '#FF637E', '#DE991C', '#04FF7F', '#0995FF', '#9207FB', '#FF0696', '#FF050F', '#FF8C03',
  '#AEF598', '#7EDFFE', '#A496FF', '#FF67BF', '#FDAB94', '#FFCC87', '#8BA163', '#608FA9',
  '#5A4DAA', '#B85C96', '#B14558', '#D29E3E', '#009976', '#317FC9', '#9B61B4', '#D23991',
  '#DE5C69', '#F6BA0D', '#9ED439', '#069EF4', '#B161ED', '#FE45B0', '#EE294B', '#F0A33D',
  '#4AF4CD', '#7BA1C5', '#CA77EE', '#EAAFD1', '#BF747C', '#F1CE6A', '#92DD09', '#329CD9',
  '#8E44C7', '#DB65A9', '#96646D', '#CBAC84'];

export class ColorService {
  static getColorByNumber(number) {
    let colorIndex = number % colors.length;
    return colors[colorIndex]
  }
}
