import React from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {CarrierAndMicroHubDropdownSelect} from '../../../../../components';

function MicroHubFilter(props) {

  const {classes, className, filter, setFilter, carriersWithMicroHubs} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <form>
        <CarrierAndMicroHubDropdownSelect
          carriersWithMicroHubs={carriersWithMicroHubs}
          carrierValue={filter.carrierName}
          className={classes.row}
          microHubOptions={[]}
          microHubValue={filter.microHubName}
          setCarrierValue={(value) => setFilter({...filter, carrierName: value, microHubName: ''})}
          setMicroHubValue={(value) => setFilter({...filter, microHubName: value})}
        />
      </form>
    </div>
  );
}


MicroHubFilter.propTypes = {
  carriersWithMicroHubs: PropTypes.object,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default withStyles(styles)(MicroHubFilter);
