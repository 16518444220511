export default theme => ({
  root: {
    minWidth: '350px'
  },
  lastTableCell: {
    paddingRight: theme.spacing(0),
  },
  buttonLeft: {
    paddingLeft: theme.spacing(1)
  },
  buttonRight: {
    float: 'right',
    paddingRight: theme.spacing(1)
  },
  portletHeader: {
    height: '40px'
  },
  portletContent: {
    maxHeight: '90%',
    overflow: 'auto',
  },
  declinedStopsTable:{
    maxWidth: '100%',
  },
  largeTableCell: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5)
  },
});
