export default theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
  },
  stepper: {
    backgroundColor: theme.palette.secondary.main,
  },
  MuiAccordionroot: {
    '&:before': {
      backgroundColor: 'white',
      color: 'white'
    }
  },
  noErrorClass: {
    color: 'inherit !important'
  }
});

