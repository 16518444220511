import React from 'react'
import {Table, TableBody, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Pagination,
  Portlet,
  PortletContent,
  TableCellWithConfig,
  TableSettings,
  TextPlaceholderWrapper
} from '../../../../../components';
import useTablePagination from '../../../../../hooks/useTablePagination';
import useTableConfig from '../../../../../hooks/useTableConfig';
import DefaultConfig from './config';
import {getTableKey} from '../../../../../services/util/tableConfigHelper';
import {TableKind} from '../../../../../services/enums/TableKind';
import Columns from './columns';
import {displayModes} from '../../../../../services/enums/displayModes';


function MicroHubTable(props) {

  const {classes, className, microHubs, t, selectedMicroHub, selectMicroHub} = props;

  const {
    config,
    columnConfigs,
    updateConfig
  } = useTableConfig(
    DefaultConfig,
    displayModes.admin,
    getTableKey(TableKind.MicroHubTable, displayModes.admin),
  )

  const {paginationProps, pagedObjects} = useTablePagination(microHubs);

  const rootClassName = classNames(classes.root, className);

  return (
    <TextPlaceholderWrapper
      active={!microHubs?.length}
      text={t('noMicroHubsFound')}
    >
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <TableSettings
            updateConfig={updateConfig}
            tableConfig={config}
            columns={Columns}
          />
          <Table size="medium">
            <TableHead>
              <TableRow>
                {columnConfigs.map(columnConfig => (
                  <TableCellWithConfig
                    key={columnConfig.order}
                    config={columnConfig}
                  >
                    {Columns[columnConfig.columnName].header({t})}
                  </TableCellWithConfig>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedObjects.map((hub, index) => (
                <TableRow
                  className={classNames(classes.tableRow, 'cursorPointer')}
                  hover
                  key={index}
                  onClick={() => selectMicroHub(hub)}
                  selected={selectedMicroHub && selectedMicroHub.name === hub.name}
                >
                  {columnConfigs.map(columnConfig => (
                    <TableCellWithConfig
                      key={columnConfig.order}
                      config={columnConfig}
                      className={classes.tableCell}
                    >
                      {Columns[columnConfig.columnName].body({
                        hub
                      })}
                    </TableCellWithConfig>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination paginationProps={paginationProps}/>
        </PortletContent>
      </Portlet>
    </TextPlaceholderWrapper>
  );
}

MicroHubTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  microHubs: PropTypes.array.isRequired,
  selectMicroHub: PropTypes.func.isRequired,
  selectedMicroHub: PropTypes.object,
  t: PropTypes.func.isRequired,
};

MicroHubTable.defaultProps = {
  microHubs: [],
};

export default compose(withStyles(styles), withTranslation())(MicroHubTable);
