export default theme => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    border: `1px solid ${theme.palette.border}`,
  },
  formControl: {
    margin: theme.spacing(1),
    width: 'auto',
  },
  chip: {
    margin: 2,
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  iconMargin: {
    marginLeft: '8px'
  },
  checkBox: {
    marginTop: theme.spacing(1),
  }
});
  
