export default theme => ({
  root: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0)
  },
  filterRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});
