export default theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '250px',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  rowWrapper: {
    display: 'flex',
    alignItems: 'baseline'
  },
  infoIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  iconMargin: {
    marginLeft: '8px !important'
  }
});
