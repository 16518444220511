export default theme => ({
  root: {
    border: `2px solid ${theme.palette.border}`,
    borderRadius: '5px'
  },
  controlGrid: {
    borderLeft: `2px solid ${theme.palette.border}`,
  },
  controlElement: {
    padding: theme.spacing(1),
  },
  descriptionForm: {
    width: '100%',
    marginTop: theme.spacing(2)
  },
  dragAndDopButton: {
    position: 'relative',
    top: theme.spacing(1),
    right: theme.spacing(1)
  },
  hubPlanDescription: {
    whiteSpace: 'pre-wrap',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
});
