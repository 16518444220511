import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {FormControl, IconButton, Input, MenuItem, Select, TableCell, TableRow, withStyles} from '@material-ui/core';
import styles from '../styles';
import {withTranslation} from 'react-i18next';
import React, {Fragment} from 'react';
import {DeleteForever as DeleteRowIcon} from '@material-ui/icons';
import {DropdownSelect} from '../../../../index';
import {EmptyType, getEmptyTypeString} from '../../../../../services/enums/EmptyType';

function EmptyAddRow(props) {
  const {classes, t, itemToAdd, updateItem, deleteItem, shipperEmptiesSelectOptions, allowCustomEmptyTypes} = props;

  return (
    <TableRow className={classes.addRow}>
      <Fragment>
        <TableCell>
          {(allowCustomEmptyTypes || itemToAdd.emptyType === EmptyType.TrackedBox) ?
            <FormControl className={classes.smallFormControl}>
              <Input
                error={!itemToAdd.name}
                id="name"
                onChange={(event) => {
                  updateItem(itemToAdd.tempId, 'name', event.target.value)
                }}
                value={itemToAdd.name}
              />
            </FormControl>
            :
            <FormControl>
              <Select
                error={!itemToAdd.name}
                onChange={(event) => {
                  updateItem(itemToAdd.tempId, 'name', event.target.value)
                }}
                value={itemToAdd.name}
              >
                {shipperEmptiesSelectOptions.map(e => e.name).map((value, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={value}
                    >
                      {value}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          }
        </TableCell>
        <TableCell>
          <FormControl className={classes.smallFormControl}>
            <Input
              error={itemToAdd.amount <= 0}
              id="emptyAmount"
              onChange={(event) => {
                updateItem(itemToAdd.tempId, 'amount', (parseInt(event.target.value) && parseInt(event.target.value) >= 0) ? parseInt(event.target.value) : 0)
              }}
              onClick={event => event.target.select()}
              onWheel={event => event.target.blur()}
              type="number"
              value={itemToAdd.amount}
            />
          </FormControl>
        </TableCell>
        <TableCell>
          <DropdownSelect
            error={!itemToAdd.emptyType}
            name={t('emptyType')}
            options={Object.values(EmptyType).map(val => ({
              value: val,
              name: t(getEmptyTypeString(val))
            }))}
            required
            setValue={value => updateItem(itemToAdd.tempId, 'emptyType', value)}
            value={itemToAdd.emptyType}
          />
        </TableCell>
        <TableCell>
          <IconButton
            className={classes.buttonRight}
            color="primary"
            onClick={() => deleteItem(itemToAdd)}
            title={t('delete')}
          >
            <DeleteRowIcon className={classes.redIcon}/>
          </IconButton>
        </TableCell>
      </Fragment>
    </TableRow>
  )

}

EmptyAddRow.propTypes = {
  allowCustomEmptyTypes: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deleteItem: PropTypes.func.isRequired,
  itemToAdd: PropTypes.object.isRequired,
  shipperEmptiesSelectOptions: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(EmptyAddRow);
