export default theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100vh',
  },
  grid: {
    minHeight: '80vh'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  avatar: {
    marginLeft: 'auto',
    align: 'center',
    height: '150px',
    width: '150px',
    flexShrink: 0,
    flexGrow: 0
  },
});
