import React from 'react'
import {Grid, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import HubBasket from '../HubBasket';

function HubBaskets(props) {

  const {classes, hubBasketsData, className, getCapacitiesForHub, draggingItemHub, microHubPlanData} = props;

  const rootClassName = classNames(classes.root, className);


  return (
    <div className={rootClassName}>
      <Grid
        container
        spacing={1}
      >
        {hubBasketsData.map(hubBasket => {
          const planDataForHub = microHubPlanData ? microHubPlanData.filter(plan => plan.carrierName === hubBasket.carrierName && plan.microhubName === hubBasket.microHubName) : [];
          const hubPlan = planDataForHub && planDataForHub.length > 0 && planDataForHub[0].hubPlans && planDataForHub[0].hubPlans.length > 0 ? planDataForHub[0].hubPlans[0] : null;
          return (
            <Grid
              item
              key={hubBasket.microHubName}
              md={6}
              xs={12}
            >
              <HubBasket
                className={classes.hubBasket}
                draggingItemHub={draggingItemHub}
                getCapacitiesForHub={getCapacitiesForHub}
                hubBasketData={hubBasket}
                maxVolume={hubPlan ? hubPlan.maxVolume : 0}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}


HubBaskets.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  draggingItemHub: PropTypes.string,
  getCapacitiesForHub: PropTypes.func.isRequired,
  hubBasketsData: PropTypes.array.isRequired,
  i18n: PropTypes.object.isRequired,
  microHubPlanData: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(HubBaskets);
