export default theme => ({
  root: {},
  grid: {
    minHeight: '80vh'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  paper: {
    padding: theme.spacing(3),
  }
});
