// eslint-disable-next-line no-unused-vars
export default theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  selected: {
    backgroundColor: theme.palette.primary.selected,
  },
  buttonRight: {
    padding: 0,
    marginLeft: theme.spacing(2)
  },
  largeTableCell: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5)
  },
  warningColor: {
    color: theme.palette.warning.main,
    verticalAlign: 'middle'
  },
  tableCell: {
    whiteSpace: 'nowrap',
  },
  dangerColor: {
    color: theme.palette.danger.main
  },
  alignText: {
    display: 'flex',
    alignItems: 'center'
  },
});
