export default theme => ({
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    width: '350px',
  },
  picker: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    margin: 0,
    marginLeft: theme.spacing(2),
    minWidth: '200px',
    width: '200px',
  },
  details: {
    paddingLeft: theme.spacing(1),
  }
});
