import {TourAmPmTag, TourIdLabel, TourStoragePlaces} from '../../index';
import React from 'react';
import {Tooltip} from '@material-ui/core';
import {DoneAll as SeenIcon} from '@material-ui/icons';
import moment from 'moment';

const Columns = {
  tourId: {
    name: 'tourId',
    header: ({t}) => t('tourId'),
    body: ({tour, classes}) =>
      <div className={classes.idTableCell}>
        <TourAmPmTag
          className={classes.amPmTag}
          estimatedTourDeliveryWindow={tour.estimatedTourDeliveryWindow}
        />
        <TourIdLabel tour={tour}/>
      </div>
  },
  carrier: {
    name: 'carrier',
    header: ({t}) => t('carrier'),
    body: ({tour}) => tour.carrierName
  },
  microHub: {
    name: 'microHub',
    header: ({t}) => t('microHub'),
    body: ({tour}) => tour.microHubName
  },
  numStops: {
    name: 'numStops',
    header: ({t}) => t('numStops'),
    body: ({tour}) => tour.stops.length
  },
  storagePlace: {
    name: 'storagePlace',
    header: ({t}) => t('storagePlace'),
    body: ({tour}) => <TourStoragePlaces
      maxDisplay={4}
      tour={tour}
    />
  },
  plannedDuration: {
    name: 'plannedDuration',
    header: ({t}) => t('plannedDuration'),
    body: ({tour}) => tour.duration
  },
  actualDuration: {
    name: 'actualDuration',
    header: ({t}) => t('actualDuration'),
    body: ({tour}) => tour.tourEnd ? Math.round(moment.duration(moment(tour.tourEnd).diff(moment(tour.tourStart))).asMinutes()) : '-'
  },
  driver: {
    name: 'driver',
    header: ({t}) => t('driver'),
    body: ({tour}) => tour.driverDisplayName ? tour.driverDisplayName : '-'
  },
  vehicle: {
    name: 'vehicle',
    header: ({t}) => t('vehicle'),
    body: ({tour}) => tour.vehicleLicensePlate ? tour.vehicleLicensePlate : '-'
  },
  approved: {
    name: 'approved',
    header: ({t}) => t('approved'),
    body: ({tour, classes, t}) =>
      <div className={classes.approvedTableCell}>
        {tour.approved ? t('yes') : t('no')}
        {tour.seenByDriver &&
          <Tooltip title={t('seenByDriver')}>
            <SeenIcon
              className={classes.seenStatus}
              color="primary"
            />
          </Tooltip>
        }
      </div>
  },
}

export default Columns;