export default theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  tableCell: {
    padding: 0,
    margin: 0,
  },
});
