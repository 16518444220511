export const DayOfWeek = {
  monday: 'MONDAY',
  tuesday: 'TUESDAY',
  wednesday: 'WEDNESDAY',
  thursday: 'THURSDAY',
  friday: 'FRIDAY',
  saturday: 'SATURDAY',
  sunday: 'SUNDAY',
};

export function getDayOfWeekString(dayOfWeek) {
  switch (dayOfWeek) {
    case DayOfWeek.sunday:
      return 'sunday';
    case DayOfWeek.monday:
      return 'monday';
    case DayOfWeek.tuesday:
      return 'tuesday';
    case DayOfWeek.wednesday:
      return 'wednesday';
    case DayOfWeek.thursday:
      return 'thursday';
    case DayOfWeek.friday:
      return 'friday';
    case DayOfWeek.saturday:
      return 'saturday';
    default:
      return 'dayUnknown'
  }
}

export function getDayOfWeekStringShort(dayOfWeek) {
  return `${getDayOfWeekString(dayOfWeek)}short`
}

export function getDayNumberByDayOfWeek(dayOfWeek) {
  switch (dayOfWeek) {
    case DayOfWeek.sunday:
      return 0;
    case DayOfWeek.monday:
      return 1;
    case DayOfWeek.tuesday:
      return 2;
    case DayOfWeek.wednesday:
      return 3;
    case DayOfWeek.thursday:
      return 4;
    case DayOfWeek.friday:
      return 5;
    case DayOfWeek.saturday:
      return 6;
    default:
      console.warn(`Invalid day of Week: ${dayOfWeek}`);
      return NaN;
  }
}

export function getDayOfWeekFromDate(date) {
  switch (date.getDay()) {
    case 0:
      return DayOfWeek.sunday;
    case 1:
      return DayOfWeek.monday;
    case 2:
      return DayOfWeek.tuesday;
    case 3:
      return DayOfWeek.wednesday;
    case 4:
      return DayOfWeek.thursday;
    case 5:
      return DayOfWeek.friday;
    case 6:
      return DayOfWeek.saturday;
    default:
      return 'unknownDayOfWeek'
  }
}

export function getDayOfWeekStringFromDateString(date) {
  switch (new Date(date).getDay()) {
    case 0:
      return 'sunday';
    case 1:
      return 'monday';
    case 2:
      return 'tuesday';
    case 3:
      return 'wednesday';
    case 4:
      return 'thursday';
    case 5:
      return 'friday';
    case 6:
      return 'saturday';
    default:
      return 'dayUnknown'
  }
}

export function getDayOfWeekStringFromDateStringShort(date) {
  return `${getDayOfWeekStringFromDateString(date)}short`
}

export function getDayOfWeekFromNumber(number) {
  switch (number) {
    case 0:
      return DayOfWeek.sunday;
    case 1:
      return DayOfWeek.monday;
    case 2:
      return DayOfWeek.tuesday;
    case 3:
      return DayOfWeek.wednesday;
    case 4:
      return DayOfWeek.thursday;
    case 5:
      return DayOfWeek.friday;
    case 6:
      return DayOfWeek.saturday;
    default: {
      console.warn(`invalid day of week Number: ${number}`);
      return '';
    }
  }
}

export function dayOfWeekSorterFunction(a, b) {
  const sorter = {
    'MONDAY': 0,
    'TUESDAY': 1,
    'WEDNESDAY': 2,
    'THURSDAY': 3,
    'FRIDAY': 4,
    'SATURDAY': 5,
    'SUNDAY': 6
  };
  return sorter[a] - sorter[b];
}
