import React from 'react'
import {Checkbox, FormControl, FormControlLabel, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import {Portlet, PortletContent} from '../../../index';


function CreateOperatingTime(props) {
  const {classes, className, times, setTimes, title, t} = props;

  const rootClassName = classNames(classes.root, className);
  return (
    <Portlet className={rootClassName}>
      <PortletContent>
        <Typography variant="h3">
          {title}
        </Typography>
        <hr/>
        <FormControl>
          <FormControlLabel
            className={classes.formControl}
            control={
              <Checkbox
                checked={times.monday}
                color="primary"
                onChange={(event) => setTimes({...times, monday: event.target.checked})}
                value={times.monday}
              />
            }
            label={t('monday')}
          />
          <FormControlLabel
            className={classes.formControl}
            control={
              <Checkbox
                checked={times.tuesday}
                color="primary"
                onChange={(event) => setTimes({...times, tuesday: event.target.checked})}
                value={times.tuesday}
              />
            }
            label={t('tuesday')}
          />
          <FormControlLabel
            className={classes.formControl}
            control={
              <Checkbox
                checked={times.wednesday}
                color="primary"
                onChange={(event) => setTimes({...times, wednesday: event.target.checked})}
                value={times.wednesday}
              />
            }
            label={t('wednesday')}
          />
          <FormControlLabel
            className={classes.formControl}
            control={
              <Checkbox
                checked={times.thursday}
                color="primary"
                onChange={(event) => setTimes({...times, thursday: event.target.checked})}
                value={times.thursday}
              />
            }
            label={t('thursday')}
          />
          <FormControlLabel
            className={classes.formControl}
            control={
              <Checkbox
                checked={times.friday}
                color="primary"
                onChange={(event) => setTimes({...times, friday: event.target.checked})}
                value={times.friday}
              />
            }
            label={t('friday')}
          />
          <FormControlLabel
            className={classes.formControl}
            control={
              <Checkbox
                checked={times.saturday}
                color="primary"
                onChange={(event) => setTimes({...times, saturday: event.target.checked})}
                value={times.saturday}
              />
            }
            label={t('saturday')}
          />
          <FormControlLabel
            className={classes.formControl}
            control={
              <Checkbox
                checked={times.sunday}
                color="primary"
                onChange={(event) => setTimes({...times, sunday: event.target.checked})}
                value={times.sunday}
              />
            }
            label={t('sunday')}
          />
        </FormControl>
      </PortletContent>
    </Portlet>
  );
}

CreateOperatingTime.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  setTimes: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  times: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};


export default compose(withStyles(styles), withTranslation())(CreateOperatingTime);
