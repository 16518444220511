import {useEffect, useState} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {ClientService} from '../services/client/clientService';
import {clientTypes} from '../services/client/clientTypes';
import {CarrierQueryService} from '../services/backend/carrierQueryService';
import {MicroHubQueryService} from '../services/backend/microHubQueryService';
import {AuthService} from '../services/auth/authService';


const clientCarrier = ClientService.getClient(clientTypes.carrier);
const clientMicroHub = ClientService.getClient(clientTypes.microHub);

export default function useMicroHubOptionsOfCarrier(organization, withHubData) {

  const [microHubOptions, setMicroHubOptions] = useState([]);
  const [microHubData, setMicroHubData] = useState([]);


  const queryMicroHubs = MicroHubQueryService.getAllMicroHubsOfCarrierQuery(AuthService.getUserOrganization());
  const {data: dataMicroHub} = useQuery(queryMicroHubs, {client: clientMicroHub, skip: !withHubData});

  const queryCarrier = CarrierQueryService.getAllMicroHubsOfCarrier(organization);
  const {data: dataCarrier} = useQuery(queryCarrier, {client: clientCarrier, skip: withHubData});


  useEffect(() => {
    if (dataCarrier && dataCarrier.carriers && dataCarrier.carriers.length > 0) {
      const microHubNames = [];
      dataCarrier.carriers[0].hubs.forEach(hub => {
        microHubNames.push(hub.name);
      });
      setMicroHubOptions(microHubNames);
    }
  }, [dataCarrier]);

  useEffect(() => {
    if (dataMicroHub && dataMicroHub.microhubs && dataMicroHub.microhubs.length > 0) {
      setMicroHubData(dataMicroHub.microhubs);
      const microHubNames = [];
      dataMicroHub.microhubs.forEach(hub => {
        microHubNames.push(hub.name);
      });
      setMicroHubOptions(microHubNames);
    }
  }, [dataMicroHub]);


  return {microHubOptions, microHubData};
}
