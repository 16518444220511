export const ShipperApiEventTypes = {
  Unknown: 0,
  PostPreview: 1,
  GetPreview: 2,
  PostFinal: 3,
  GetHubs: 4,
  PostEmptyOptions: 5,
  GetEmptyOptions: 6,
  GetEmpties: 7,
  GetStopStatus: 8,
};

export function getShipperApiEventTypeString(type) {
  switch (type) {
    case ShipperApiEventTypes.Unknown:
      return 'shipperApiEventTypeUnknown'
    case ShipperApiEventTypes.PostPreview:
      return 'shipperApiEventTypePostPreview'
    case ShipperApiEventTypes.GetPreview:
      return 'shipperApiEventTypeGetPreview'
    case ShipperApiEventTypes.PostFinal:
      return 'shipperApiEventTypePostFinal'
    case ShipperApiEventTypes.GetHubs:
      return 'shipperApiEventTypeGetHubs'
    case ShipperApiEventTypes.PostEmptyOptions:
      return 'shipperApiEventTypePostEmptyOptions'
    case ShipperApiEventTypes.GetEmptyOptions:
      return 'shipperApiEventTypeGetEmptyOptions'
    case ShipperApiEventTypes.GetEmpties:
      return 'shipperApiEventTypeGetEmpties'
    case ShipperApiEventTypes.GetStopStatus:
      return 'shipperApiEventTypeGetStopStatus'
    default:
      return 'shipperApiEventTypeUnknown'
  }
}
