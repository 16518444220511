import React, {useState} from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  Typography,
  withStyles
} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {InfoTooltip, Portlet, PortletContent} from '../../../index';
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import {onCloseWithBackdropCurry} from '../../../../services/util/helperFuncs';
import CreateAddress from '../../Shared/CreateAddress';
import CreatePerson from '../../Shared/CreatePerson';

function CreateCarrierDialog(props) {

  const {classes, t, onCancel, onCreate, CreateCarrierDialogState} = props;

  const [info, setInfo] = useState({
    name: '',
    useAlgorithmForTourPlanning: true
  });

  const [address, setAddress] = useState({
    street: '',
    houseNumber: '',
    zipcode: '',
    city: '',
    country: '',
  });

  const [billingAddress, setBillingAddress] = useState({
    street: '',
    houseNumber: '',
    zipcode: '',
    city: '',
    country: '',
  });

  const [contact, setContact] = useState({
    name: '',
    email: '',
    phoneNumber: '',
  });

  const [disponent, setDisponent] = useState({
    name: '',
    email: '',
    phoneNumber: '',
  });

  const handleClose = () => {
    onCancel();
  };

  const activateOk = () => {
    return info.name && address.street && address.houseNumber && address.zipcode && address.city && address.country &&
      billingAddress.street && billingAddress.houseNumber && billingAddress.zipcode && billingAddress.city && billingAddress.country &&
      contact.email && contact.phoneNumber
  };


  const handleCreate = () => {
    onCreate(info, address, billingAddress, contact, disponent);
    setAddress({street: '', houseNumber: '', zipcode: '', city: '', country: '',});
    setBillingAddress({street: '', houseNumber: '', zipcode: '', city: '', country: '',});
    setContact({name: '', email: '', phoneNumber: '',});
    setDisponent({name: '', email: '', phoneNumber: '',})
    setInfo({name: '', email: '', useAlgorithmForTourPlanning: true});
  };

  return (
    <React.Fragment>
      <Dialog
        disableEscapeKeyDown
        onClose={onCloseWithBackdropCurry(handleClose)}
        open={CreateCarrierDialogState.open}
      >
        <DialogTitle id="simple-dialog-title">{t('createCarrier')}</DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                sm={6}
                xs={12}
              >
                <Portlet className={classes.content}>
                  <PortletContent>
                    <Typography variant="h3">
                      {t('info')}
                    </Typography>
                    <hr/>
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        htmlFor="name"
                        required
                      >{t('name')}</InputLabel>
                      <Input
                        error={!info.name}
                        id="name"
                        onChange={(event) => {
                          setInfo({...info, name: event.target.value});
                        }}
                        required
                        value={info.name}
                      />
                    </FormControl>
                    <InfoTooltip descriptionKey={'35'}>
                      <FormControlLabel
                        className={classes.checkBox}
                        control={
                          <Checkbox
                            checked={info.useAlgorithmForTourPlanning}
                            color="primary"
                            onChange={(event) => {
                              setInfo({...info, useAlgorithmForTourPlanning: event.target.checked})
                            }}
                            value={info.useAlgorithmForTourPlanning}
                          />
                        }
                        label={t('useAlgorithmForTourPlanning')}
                      />
                    </InfoTooltip>
                  </PortletContent>
                </Portlet>
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <CreateAddress
                  address={address}
                  className={classes.content}
                  required
                  setAddress={setAddress}
                  title={t('address')}
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <CreateAddress
                  address={billingAddress}
                  className={classes.content}
                  required
                  setAddress={setBillingAddress}
                  title={t('billingAddress')}
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <CreatePerson
                  className={classes.content}
                  person={contact}
                  required
                  setPerson={setContact}
                  title={t('contact')}
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <CreatePerson
                  className={classes.content}
                  person={disponent}
                  setPerson={setDisponent}
                  title={t('disponent')}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            onClick={handleClose}
          >
            <CancelIcon/>&nbsp;{t('dialogCancel')}
          </Button>
          <Button
            color="primary"
            disabled={!activateOk()}
            onClick={handleCreate}
          >
            <SaveIcon/>&nbsp;{t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

CreateCarrierDialog.propTypes = {
  CreateCarrierDialogState: PropTypes.object.isRequired,
  classes: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(CreateCarrierDialog);
