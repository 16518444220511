import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import styles from './styles'
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { Cancel as CancelIcon, Save as SaveIcon } from '@material-ui/icons';
import {
  CarrierAndMicroHubDropdownSelect,
  DayOfWeekSelect,
  DropdownSelect,
  DropdownSelectSimple,
  InfoIcon
} from '../../../../index';
import { getDayOfWeekString } from '../../../../../services/enums/dayOfWeekHelper';
import { onCloseWithBackdropCurry, roundCapacityValues } from '../../../../../services/util/helperFuncs';
import classNames from 'classnames';
import { getTemplateTypeString, TemplateType } from '../../../../../services/enums/TemplateTypes';


function CreateTourTemplateDialog(props) {

  const { classes, t, dialogState, handleCancel, handleClose, carrierWithMicroHubs, microHubs, templatePreset, vehicles, shipperOptions, templateStopMode } = props;
  const [template, setTemplate] = useState(templatePreset);

  const dialogOk = () => {
    handleClose(template);
    setTemplate(templatePreset);
  };

  const handleNotOk = () => {
    handleCancel();
    setTemplate(templatePreset);
  };

  const vehicleOptions = vehicles
    .filter(v => v.microhubs.some(hub => hub.name === template.microHub && hub[getDayOfWeekString(template.dayOfWeek)] === true))
    .map(v => v.licensePlate);

  const selectedVehicle = template?.vehicle ? vehicles.find(vh => vh.licensePlate === template.vehicle) : null;

  const templateTypeOptions = [
    {
      value: false,
      name: t(getTemplateTypeString(TemplateType.TourTemplate))
    },
    {
      value: true,
      name: t(getTemplateTypeString(TemplateType.AutoCutTemplate))
    }
  ]

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      fullWidth
      maxWidth={'sm'}
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={dialogState.open}
    >
      <DialogTitle>{t('createTourTemplate')}</DialogTitle>
      <DialogContent>
        <form>
          <div className={classes.rowWrapper}>
            {!templateStopMode &&
              <div>
                <div className={classNames(classes.formControl, classes.templateType)}>
                  {`${t('templateType')} `}
                  <InfoIcon descriptionKey={'90_preFormat'} />
                </div>
                <DropdownSelect
                  options={templateTypeOptions}
                  required
                  setValue={(value) => setTemplate({ ...template, isAutoCutTemplate: value, vehicle: '' })}
                  value={template.isAutoCutTemplate}
                />
              </div>
            }
          </div>
          <div className={classes.rowWrapper}>
            <TextField
              className={classes.formControl}
              label={t('tourTemplateName')}
              onChange={(e) => setTemplate({ ...template, templateName: e.target.value })}
              value={template.templateName}
            />
          </div>
          <CarrierAndMicroHubDropdownSelect
            carrierRequired
            carriersWithMicroHubs={carrierWithMicroHubs}
            carrierValue={template.carrier}
            className={classes.rowWrapper}
            errorCarrier={!template.carrier}
            errorMicroHub={!template.microHub}
            microHubOptions={carrierWithMicroHubs ? undefined : microHubs ? microHubs.map(hub => hub.name) : []}
            microHubRequired
            microHubValue={template.microHub}
            setCarrierValue={(value) => setTemplate({ ...template, carrier: value, microHub: '', vehicle: '' })}
            setMicroHubValue={(value) => setTemplate({ ...template, microHub: value, vehicle: '' })}
          />
          <div className={classes.rowWrapper}>
            <DayOfWeekSelect
              error={template.dayOfWeek === ''}
              required
              setValue={(value) => setTemplate({ ...template, dayOfWeek: value, vehicle: '' })}
              value={template.dayOfWeek}
            />
            <DropdownSelectSimple
              error={!template.shipper}
              hideOnEmptyOptions
              name={t('shipper')}
              options={shipperOptions}
              required
              setValue={(value) => setTemplate({ ...template, shipper: value })}
              sort
              value={template.shipper}
            />
          </div>
          {template.isAutoCutTemplate &&
            <div className={classes.rowWrapper}>
              <div>
                <DropdownSelectSimple
                  error={!template.vehicle}
                  name={(t('vehicle'))}
                  options={vehicleOptions}
                  required
                  setValue={(value) => setTemplate({ ...template, vehicle: value })}
                  sort
                  value={template.vehicle}
                />
                {selectedVehicle &&
                  <div className={classNames(classes.formControl, classes.vehicleCapacities)}>
                    <Typography>{t('vehiclePayloadWeight')}:&nbsp;{roundCapacityValues(selectedVehicle.payloadWeight)}</Typography>
                    <Typography>{t('vehicleVolume')}:&nbsp;{roundCapacityValues(selectedVehicle.volume)}</Typography>
                  </div>
                }
              </div>
            </div>
          }
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={handleNotOk}
        >
          <CancelIcon />&nbsp;{t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          disabled={!template.microHub || !template.carrier || !template.shipper || template.dayOfWeek === '' || (template.isAutoCutTemplate && !template.vehicle)}
          onClick={dialogOk}
        >
          <SaveIcon />&nbsp;{t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CreateTourTemplateDialog.propTypes = {
  carrierWithMicroHubs: PropTypes.object,
  classes: PropTypes.object.isRequired,
  dialogState: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  microHubs: PropTypes.array.isRequired,
  shipperOptions: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  templatePreset: PropTypes.object.isRequired,
  vehicles: PropTypes.array.isRequired,
  templateStopMode: PropTypes.bool,
};

export default compose(withStyles(styles), withTranslation())(CreateTourTemplateDialog);
