import React, {Fragment, useCallback, useEffect, useState} from 'react'
import {useTheme, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {Footer, Header, UnsubscribeMain} from '../components';
import {useParams} from 'react-router-dom';
import trackAndTraceService from '../../../services/backend/trackAndTraceService';

function Unsubscribe(props) {

  const {classes, updateTheme} = props;
  const {guid} = useParams();
  const [data, setData] = useState(null);
  const [backendIsLoading, setBackendIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    updateTheme(
      theme.palette.primary.main = data?.shipperInfos?.primaryColor || theme.palette.primary.main,
      theme.palette.text.primary = data?.shipperInfos?.primaryTextColor || theme.palette.text.primary,
      theme.palette.primary.contrastText = data?.shipperInfos?.primaryContrastTextColor || theme.palette.primary.contrastText,
      theme.palette.secondary.main = data?.shipperInfos?.secondaryColor || theme.palette.secondary.main,
      theme.palette.text.secondary = data?.shipperInfos?.secondaryTextColor || theme.palette.text.secondary
    );
    // eslint-disable-next-line
  }, [data])

  const loadTrackingData = useCallback(async (withLoadingIndicator) => {
    if (withLoadingIndicator) setBackendIsLoading(true);
    try {
      const response = await trackAndTraceService.getTrackAndTraceTrackingLinkInfosByGuid(guid);
      setData(response);
      if (withLoadingIndicator) setBackendIsLoading(false);
    } catch (e) {
      setError(true);
      if (withLoadingIndicator) setBackendIsLoading(false);
    }
  }, [guid]);

  useEffect(() => {
    loadTrackingData(true);
  }, [loadTrackingData]);

  return (
    <Fragment>
      <Header
        className={classes.header}
        title={'Mitteilungen'}
      />
      <main className={classes.content}>
        <UnsubscribeMain
          error={error}
          loading={backendIsLoading}
          trackingData={data}
        />
      </main>
      <Footer
        className={classes.footer}
        shipperInfos={data?.shipperInfos}
      />
    </Fragment>
  );
}

Unsubscribe.propTypes = {
  classes: PropTypes.object,
  updateTheme: PropTypes.func.isRequired,
};


export default withStyles(styles)(Unsubscribe);
