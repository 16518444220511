import React from 'react'
import {Paper, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Droppable} from 'react-beautiful-dnd'
import {DndHelperElementTypes, DndHelperService} from '../../../../../services/dndHelper/dndHelperService';
import HubBasketElement from '../HubBasketElement';
import {PresentToAllOutlined as DropDownIcon} from '@material-ui/icons';
import {roundCapacityValues} from '../../../../../services/util/helperFuncs';

function HubBasket(props) {

  const {classes, hubBasketData, t, className, getCapacitiesForHub, draggingItemHub, maxVolume} = props;

  const rootClassName = classNames(classes.root, className);
  const uniqueBasketId = DndHelperService.getUniqueBasketId(hubBasketData.microHubName);


  const getTourCapacityString = () => {
    const oldCapacities = hubBasketData.initialCapacities;
    const hubCapacities = getCapacitiesForHub(hubBasketData.microHubName);
    const bonusCapacity = {
      boxAmount: hubBasketData.toursAndStops.reduce((accumulator, elem) => accumulator + elem.capacities.boxAmount, 0),
      weight: hubBasketData.toursAndStops.reduce((accumulator, elem) => accumulator + elem.capacities.weight, 0),
      volume: hubBasketData.toursAndStops.reduce((accumulator, elem) => accumulator + elem.capacities.volume, 0),
    };

    const resultingCapacities = {
      boxAmount: hubCapacities.boxAmount + bonusCapacity.boxAmount,
      weight: hubCapacities.weight + bonusCapacity.weight,
      volume: hubCapacities.volume + bonusCapacity.volume
    };
    return `${roundCapacityValues(resultingCapacities.boxAmount)} ${resultingCapacities.boxAmount === oldCapacities.boxAmount ? '' : (resultingCapacities.boxAmount < oldCapacities.boxAmount ? '🠗' : '🠕')} [${t('boxes')}] |
     ${roundCapacityValues(resultingCapacities.weight)} ${resultingCapacities.weight === oldCapacities.weight ? '' : (resultingCapacities.weight < oldCapacities.weight ? '🠗' : '🠕')} [kg] |
      ${roundCapacityValues(resultingCapacities.volume)} ${resultingCapacities.volume === oldCapacities.volume ? '' : (resultingCapacities.volume < oldCapacities.volume ? '🠗' : '🠕')} [l]`
  };

  const getDraggingElementHubIsThisHub = (elem) => {
    if (!elem) return false;
    return DndHelperService.parseUniqueId(elem).microHubName === hubBasketData.microHubName;
  };

  return (
    <div className={rootClassName}>
      <Paper>
        <div className={classes.basketHeader}>
          <Typography>
            <strong>{t('microHub')}: {hubBasketData.microHubName}</strong>
          </Typography>
          <Typography>
            <strong>{t('newCapacity')}</strong>: {getTourCapacityString()}
          </Typography>
          <Typography>
            <strong>{t('microHubPlanMaxVolume')}</strong>: {roundCapacityValues(maxVolume)}
          </Typography>
        </div>
        <hr style={{marginBottom: 0}}/>
        <Droppable
          droppableId={uniqueBasketId}
          isDropDisabled={draggingItemHub ? draggingItemHub === hubBasketData.microHubName : false}
          type={DndHelperElementTypes.Hub}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={classes.tourContainer}
            >
              {(snapshot.isDraggingOver && !getDraggingElementHubIsThisHub(snapshot.draggingOverWith))
                ?
                <div className={classes.basketBodyDraggingOver}>
                  <DropDownIcon className={classes.dropIcon}/>
                </div>
                :
                <div
                  className={classes.basketBody}
                >
                  {hubBasketData.toursAndStops.map((elem, index) => (
                    <HubBasketElement
                      element={elem}
                      index={index}
                      key={index}
                      uniqueBasketId={uniqueBasketId}
                    />
                  ))}
                </div>
              }
            </div>
          )}
        </Droppable>
      </Paper>
    </div>
  );
}


HubBasket.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  draggingItemHub: PropTypes.string,
  getCapacitiesForHub: PropTypes.func.isRequired,
  hubBasketData: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  maxVolume: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(HubBasket);
