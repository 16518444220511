import React, {Fragment} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {PortletContent, PortletHeader} from '../../../index';
import {Close as CloseIcon, Edit as EditIcon} from '@material-ui/icons';
import {AuthService} from '../../../../services/auth/authService';
import {userPermissions} from '../../../../services/auth/userPermissions';
import {VehicleTypeEnumHelperService} from '../../../../services/enums/vehicleTypeEnumHelperService';
import {roundCapacityValues} from '../../../../services/util/helperFuncs';

function VehicleDetailsShow(props) {

  const {classes, className, vehicle, edit, t, readonly, closeDetail} = props;

  const rootClassName = classNames(classes.root, className);

  const displayDaysOfWeek = (monday, tuesday, wednesday, thursday, friday, saturday, sunday) => {
    const array = [];
    if (monday) {
      array.push(t('mondayShort'));
    }
    if (tuesday) {
      array.push(t('tuesdayShort'));
    }
    if (wednesday) {
      array.push(t('wednesdayShort'));
    }
    if (thursday) {
      array.push(t('thursdayShort'));
    }
    if (friday) {
      array.push(t('fridayShort'));
    }
    if (saturday) {
      array.push(t('saturdayShort'));
    }
    if (sunday) {
      array.push(t('sundayShort'));
    }
    return array.join(', ');
  };

  return (
    <Fragment>
      <PortletHeader className={rootClassName}>
        <Fragment>
          <div><strong>{vehicle.licensePlate}</strong></div>
          <div>
            {AuthService.getUserPermission() !== userPermissions.user &&
            <Fragment>
              <Button
                className={classes.button}
                color="default"
                onClick={closeDetail}
                title={t('close')}
                variant="contained"
              >
                <CloseIcon/>
              </Button>
              {!readonly &&
              <Button
                className={classes.button}
                color="primary"
                onClick={edit}
                title={t('vehicleEdit')}
                variant="contained"
              >
                <EditIcon/>
              </Button>
              }
            </Fragment>
            }
          </div>
        </Fragment>
      </PortletHeader>
      <PortletContent noPadding>
        <div className={classes.wrapper}>
          <div className={classes.wrapperChild}>
            <div className={classes.vehicleDetail}><strong>{t('vehicleName')}</strong>: {vehicle.licensePlate}</div>
            <div className={classes.vehicleDetail}><strong>{t('vehicleType')}</strong>: {t(VehicleTypeEnumHelperService.getVehicleTypeString(vehicle.type))}
            </div>
            <div className={classes.vehicleDetail}><strong>{t('vehicleAverageSpeedKmh')}</strong>: {vehicle.averageSpeedKmh}</div>
            <div className={classes.vehicleDetail}><strong>{t('vehicleDescription')}</strong>:</div>
            <div className={classes.vehicleDetail}>{vehicle.description}</div>
          </div>
          <div className={classes.wrapperChild}>
            <div className={classes.vehicleDetail}><strong>{t('vehicleCapacity')}</strong>: {roundCapacityValues(vehicle.capacity)}</div>
            <div className={classes.vehicleDetail}><strong>{t('vehicleVolume')}</strong>: {roundCapacityValues(vehicle.volume)}</div>
            <div className={classes.vehicleDetail}><strong>{t('vehiclePayloadWeight')}</strong>: {roundCapacityValues(vehicle.payloadWeight)}</div>
          </div>
          <div className={classes.wrapperChild}>
            <div className={classes.vehicleDetail}><strong>{t('carrier')}</strong>: {vehicle.carrierName} </div>
            <div className={classes.vehicleDetail}><strong>{t('microHubs')}</strong>:</div>
            {vehicle.microhubs.map((hub, index) => {
              return (
                <div
                  className={classes.vehicleDetail}
                  key={index}
                >
                  <div className={classes.vehicleDetail}>
                    <strong>{hub.name}</strong>:&nbsp;{displayDaysOfWeek(hub.monday, hub.tuesday, hub.wednesday, hub.thursday, hub.friday, hub.saturday, hub.sunday)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </PortletContent>
    </Fragment>
  );
}


VehicleDetailsShow.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  closeDetail: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
  t: PropTypes.func.isRequired,
  vehicle: PropTypes.object.isRequired
};

export default compose(withStyles(styles), withTranslation())(VehicleDetailsShow);
