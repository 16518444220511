import React, {Fragment} from 'react'
import {Table, TableBody, TableHead, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {ProductType} from '../../../../services/enums/ProductType';
import BoxHeader from './Box/header';
import BoxBody from './Box/body';
import InsertHeader from './Insert/header';
import InsertBody from './Insert/body';
import OtherHeader from './Other/header';
import OtherBody from './Other/body';

function ProductTable(props) {

  const {classes, className, productType, notExpandable, products} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <Fragment>
      {products && products.length > 0 &&
      <Fragment>
        <Table
          className={rootClassName}
          size="small"
        >
          <TableHead>
            {(() => {
              switch (productType) {
                case ProductType.Box:
                  return <BoxHeader canExpand={!notExpandable}/>;
                case ProductType.BeverageBox:
                  return <BoxHeader canExpand={!notExpandable}/>;
                case ProductType.PackageInserts:
                  return <InsertHeader canExpand={!notExpandable}/>;
                case ProductType.Other:
                  return <OtherHeader canExpand={!notExpandable}/>;
                default :
                  return <BoxHeader canExpand={!notExpandable}/>;
              }
            })()
            }
          </TableHead>
          <TableBody>
            {products.map((product, index) =>
              (() => {
                switch (productType) {
                  case ProductType.Box:
                    return (
                      <BoxBody
                        canExpand={!notExpandable}
                        className={notExpandable ? '' : classes.tableRow}
                        key={index}
                        product={product}
                      />
                    );
                  case ProductType.BeverageBox:
                    return (
                      <BoxBody
                        canExpand={!notExpandable}
                        className={notExpandable ? '' : classes.tableRow}
                        key={index}
                        product={product}
                      />
                    );
                  case ProductType.PackageInserts:
                    return (
                      <InsertBody
                        key={index}
                        product={product}
                      />
                    );
                  case ProductType.Other:
                    return (
                      <OtherBody
                        canExpand={!notExpandable}
                        className={notExpandable ? '' : classes.tableRow}
                        key={index}
                        product={product}
                      />
                    );
                  default :
                    return (
                      <BoxBody
                        canExpand={!notExpandable}
                        className={notExpandable ? '' : classes.tableRow}
                        key={index}
                        product={product}
                      />
                    );
                }
              })()
            )}
          </TableBody>
        </Table>
      </Fragment>
      }
    </Fragment>
  );
}


ProductTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  notExpandable: PropTypes.bool,
  productType: PropTypes.number.isRequired,
  products: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
}
;

export default compose(withStyles(styles), withTranslation())(ProductTable);
