import React, {Fragment, useState} from 'react'
import {AppBar, Button, Tab, Tabs, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import SideNavLayout from '../../../layouts/SideNavLayout';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {ClientService} from '../../../services/client/clientService';
import {clientTypes} from '../../../services/client/clientTypes';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {
  AddHubDialog,
  CarrierProfile,
  CreateCarrierDialog,
  CreateHubDialog,
  CreateShipperDialog,
  DataLoadingWrapper,
  ShipperProfile
} from '../../../components';
import Typography from '@material-ui/core/Typography';
import {ShipperQueryService} from '../../../services/backend/shipperQueryService';
import {CarrierQueryService} from '../../../services/backend/carrierQueryService';
import {MicroHubQueryService} from '../../../services/backend/microHubQueryService';
import {Add as AddIcon} from '@material-ui/icons';

const clientShipper = ClientService.getClient(clientTypes.shipper);
const clientCarrier = ClientService.getClient(clientTypes.carrier);
const clientMicrohub = ClientService.getClient(clientTypes.microHub);

function Profile(props) {
  const {classes, t} = props;

  const queryShipper = ShipperQueryService.getAllShipperQuery();
  const queryCarrier = CarrierQueryService.getAllCarrierQuery();
  const queryMicrhohubs = MicroHubQueryService.getMicrohubNames();
  const addMicrohubMutation = MicroHubQueryService.addMicroHubToCarrier();
  const createMicrohubMutation = MicroHubQueryService.createMicroHub();
  const createShipperMutation = ShipperQueryService.createShipper();
  const createCarrierMutation = CarrierQueryService.createCarrier();

  const {loading: loadingShipper, error: errorShipper, data: dataShipper, refetch: refetchShipper} = useQuery(queryShipper, {client: clientShipper});
  const {loading: loadingCarrier, error: errorCarrier, data: dataCarrier, refetch: refetchCarrier} = useQuery(queryCarrier, {client: clientCarrier});
  const {loading: loadingHubNames, error: errorHubNames, data: dataHubNames} = useQuery(queryMicrhohubs, {client: clientMicrohub});
  const [addMicrohub] = useMutation(addMicrohubMutation, {client: clientMicrohub});
  const [createMicroHub] = useMutation(createMicrohubMutation, {client: clientMicrohub});
  const [createShipper] = useMutation(createShipperMutation, {client: clientShipper});
  const [createCarrier] = useMutation(createCarrierMutation, {client: clientCarrier});

  const [forceUpdate, setForceUpdate] = useState(Boolean);

  const reloadShipper = () => {
    refetchShipper().then(() => {
      setForceUpdate(!forceUpdate);
    });
  };

  const reloadCarrier = () => {
    refetchCarrier().then(() => {
      setForceUpdate(!forceUpdate);
    });
  };

  const [tabState, setTabState] = useState(0);

  const [AddHubDialogState, setAddHubDialogState] = useState({
    open: false,
    allHubs: [],
    carrierName: '',
    selectedHub: '',
    loading: loadingHubNames,
    error: errorHubNames,
  });

  const [CreateHubDialogState, setCreateHubDialogState] = useState({
    open: false,
    carrierName: '',
  });

  const [CreateShipperDialogState, setCreateShipperDialogState] = useState({
    open: false,
  });

  const [CreateCarrierDialogState, setCreateCarrierDialogState] = useState({
    open: false,
  });

  const addHubDialogOpen = (alreadyHubs, carrierName) => {
    const hubs = dataHubNames.microhubs.map(hub => hub.name);
    const hubs2 = hubs.filter((el) => !alreadyHubs.includes(el));
    setAddHubDialogState({...AddHubDialogState, allHubs: hubs2, open: true, carrierName: carrierName});
  };

  const onSelect = () => {
    setAddHubDialogState({...AddHubDialogState, open: false});
    addMicrohub({
      variables: {
        microHubName: AddHubDialogState.selectedHub, carrierName: AddHubDialogState.carrierName,
      }
    }).then(() => {
      reloadCarrier();
    }, () => {
      alert(t('errorAddingHub'));
    });
  };

  const onAddCancel = () => {
    setAddHubDialogState({...AddHubDialogState, open: false});
  };

  const onNewHub = () => {
    setAddHubDialogState({...AddHubDialogState, open: false});
    setCreateHubDialogState({
      ...CreateHubDialog, carrierName: AddHubDialogState.carrierName, open: true
    });
  };

  const onCreateHubCancel = () => {
    setCreateHubDialogState({...CreateHubDialogState, open: false});

  };
  const onCreateHub = (newHub, address, contact) => {
    setCreateHubDialogState({...CreateHubDialogState, open: false});
    const createHub = {
      name: newHub.hubName, email: newHub.email, b2B: newHub.b2b, b2C: newHub.b2c,
      address: address,
      contact: contact,
    };
    createMicroHub({
      variables: {
        microHub: createHub, carrierName: CreateHubDialogState.carrierName,
      }
    }).then(() => {
      reloadCarrier();
    }, () => {
      alert(t('errorCreatingHub'))
    })
  };

  const onCreateShipper = (info, empties, address, billingAddress, contact, serviceContact, emergencyContact, disponent, deadlines, trackingNotifications, trackingLinkInfos, stopBonus) => {
    setCreateShipperDialogState({...CreateShipperDialogState, open: false});
    const timeZoneOffset = new Date().getTimezoneOffset() * 60;
    const shipper = {
      name: info.name,
      shortName: info.shortName,
      signature: info.signature,
      showEmergencyContact: info.showEmergencyContact,
      previewDeadline: Math.round((deadlines.previewDeadline * 60 * 60) + timeZoneOffset),
      finalDeadline: Math.round((deadlines.finalDeadline * 60 * 60) + timeZoneOffset),
      emptiesDeadline: Math.round((deadlines.finalDeadline * 60 * 60) + timeZoneOffset),
      empties: empties.map((e, i) => (
        {name: e.name, order: i}
      )),
      address: address,
      billingAddress: billingAddress,
      contact: contact,
      serviceContact: serviceContact,
      emergencyContact: emergencyContact,
      disponent: disponent,
      trackingLinkInfos: trackingLinkInfos,
      trackingNotifications: trackingNotifications,
      stopBonus: stopBonus,
    };
    createShipper({variables: {shipper: shipper}}).then(() => {
      reloadShipper();
    }, () => {
      alert(t('errorCreatingShipper'));
    });
  };
  const onCreateShipperCancel = () => {
    setCreateShipperDialogState({...CreateShipperDialogState, open: false});
  };

  const onCreateCarrier = (info, address, billingAddress, contact, disponent) => {
    setCreateCarrierDialogState({...CreateCarrierDialogState, open: false});
    const carrier = {
      name: info.name,
      address: address,
      billingAddress: billingAddress,
      contact: contact,
      disponent: disponent,
      useAlgorithmForTourPlanning: info.useAlgorithmForTourPlanning
    };
    createCarrier({variables: {carrier: carrier}}).then(() => {
      reloadCarrier();
    }, () => {
      alert(t('errorCreatingCarrier'));
    });
  };

  const onCreateCarrierCancel = () => {
    setCreateCarrierDialogState({...CreateCarrierDialogState, open: false});
  };
  
  if(dataShipper && dataShipper.shippers){
    dataShipper.shippers.sort((a,b) => a.name.localeCompare(b.name))
  }
  if(dataCarrier && dataCarrier.carriers){
    dataCarrier.carriers.sort((a,b) => a.name.localeCompare(b.name))
  }
  
  return (
    <SideNavLayout title={t('account')}>
      <AppBar
        className={classes.appBar}
        color={'secondary'}
        position={'relative'}
      >
        <Tabs
          onChange={(event, newValue) => {
            setTabState(newValue);
          }}
          value={tabState}
        >
          <Tab
            label={t('carrier')}
          />
          <Tab
            label={t('shipper')}
          />
        </Tabs>
      </AppBar>
      <div className={classes.root}>
        {(() => {
          switch (tabState) {
            case 0:
              return (
                <Fragment>
                  <Button
                    className={classes.addButton}
                    color="primary"
                    onClick={() => {
                      setCreateCarrierDialogState({...CreateCarrierDialogState, open: true})
                    }}
                    variant="contained"
                  >
                    <AddIcon/>&nbsp;{t('addCarrier')}
                  </Button>
                  <DataLoadingWrapper
                    error={errorCarrier}
                    isLoading={loadingCarrier}
                  >
                    <Typography variant="h2">
                      {t('carrier')}
                    </Typography>
                    {
                      dataCarrier &&
                      dataCarrier.carriers &&
                      dataCarrier.carriers.map((carrier, index) => {
                        return (
                          <CarrierProfile
                            addHubDialogOpen={addHubDialogOpen}
                            carrier={carrier}
                            key={index}
                            refetchCarrier={reloadCarrier}
                          />
                        );
                      })
                    }
                  </DataLoadingWrapper>
                </Fragment>
              );
            case 1:
              return (
                <Fragment>
                  <Button
                    className={classes.addButton}
                    color="primary"
                    onClick={() => {
                      setCreateShipperDialogState({...CreateShipperDialogState, open: true})
                    }}
                    variant="contained"
                  >
                    <AddIcon/>&nbsp;{t('addShipper')}
                  </Button>
                  <DataLoadingWrapper
                    error={errorShipper}
                    isLoading={loadingShipper}
                  >
                    <Typography variant="h2">
                      {t('shipper')}
                    </Typography>
                    {
                      dataShipper &&
                      dataShipper.shippers &&
                      dataShipper.shippers.map((shipper, index) => {
                        return (
                          <ShipperProfile
                            key={index}
                            refetchShipper={reloadShipper}
                            shipper={shipper}
                          />
                        );
                      })
                    }
                  </DataLoadingWrapper>
                </Fragment>
              );
            default:
              return (<div>Error</div>);
          }
        })()}
      </div>
      <CreateCarrierDialog
        CreateCarrierDialogState={CreateCarrierDialogState}
        onCancel={onCreateCarrierCancel}
        onCreate={onCreateCarrier}
      />
      <CreateShipperDialog
        CreateShipperDialogState={CreateShipperDialogState}
        onCancel={onCreateShipperCancel}
        onCreate={onCreateShipper}
      />
      <AddHubDialog
        AddHubDialogState={AddHubDialogState}
        onCancel={onAddCancel}
        onNewHub={onNewHub}
        onSelect={onSelect}
      />
      <CreateHubDialog
        CreateHubDialogState={CreateHubDialogState}
        onCancel={onCreateHubCancel}
        onCreate={onCreateHub}
      />
    </SideNavLayout>
  );
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(Profile);
