export default theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
  },
  a: {
    color: theme.palette.text.primary
  },
  grid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
  gridItem: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
  gslLogo: {
    height: 58,
    [theme.breakpoints.up('md')]: {
      height: 155,
    },
  },
  centerOnMobile: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
    },
  },
  linkAndCopyRightGridItem: {
    justifyContent: 'space-between',
  },
});

