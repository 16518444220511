import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import React from 'react';
import classNames from 'classnames';
import {DateService} from '../../../../../services/util/DateService';
import moment from 'moment';
import {getTrackingStepTypeString, TrackingStepType} from '../../../../../services/enums/TrackingStepType';
import {Cancel, CheckCircle} from '@material-ui/icons';

const TrackingStepEvaluation = (props) => {

  const {className, classes, t, trackingStep, deliveryTime} = props;
  const rootClassName = classNames(classes.root, className);

  const differenceFrom = moment.duration(moment(deliveryTime).diff(moment(trackingStep.deliveryWindow.from)));
  const isDifferenceFromOk = differenceFrom >= 0;
  const differenceTo = moment.duration(moment(deliveryTime).diff(moment(trackingStep.deliveryWindow.to)));
  const isDifferenceToOk = differenceTo <= 0

  if (trackingStep.trackingStepType === TrackingStepType.Delivered) {
    return (
      <div className={rootClassName}>
        <Typography><strong>{t(getTrackingStepTypeString(trackingStep.trackingStepType))}</strong></Typography>
        <div className={classes.deliveryWindow}>
          <Typography className={classes.timeDisplay}>
            <strong>{t('estimatedDeliveryWindowFrom')}:</strong> {DateService.displayTime(trackingStep.deliveryWindow.from)}
          </Typography>
          <Typography className={classes.timeDisplay}>
            <strong>{t('estimatedDeliveryWindowTo')}:</strong> {DateService.displayTime(trackingStep.deliveryWindow.to)}
          </Typography>
        </div>
      </div>
    )
  }

  return (
    <div className={rootClassName}>
      <Typography><strong>{t(getTrackingStepTypeString(trackingStep.trackingStepType))}</strong></Typography>
      <div className={classes.deliveryWindow}>
        <Typography className={classes.timeDisplay}>
          {isDifferenceFromOk ? <CheckCircle className={classes.greenIcon}/> : <Cancel className={classes.redIcon}/>}&nbsp;
          <strong>{t('estimatedDeliveryWindowFrom')}:</strong> {DateService.displayTime(trackingStep.deliveryWindow.from)}{!isDifferenceFromOk && ` | ${differenceFrom.format('h:mm:ss', {trim: false})} [h]`}
        </Typography>
        <Typography className={classes.timeDisplay}>
          {isDifferenceToOk ? <CheckCircle className={classes.greenIcon}/> : <Cancel className={classes.redIcon}/>}&nbsp;
          <strong>{t('estimatedDeliveryWindowTo')}:</strong> {DateService.displayTime(trackingStep.deliveryWindow.to)}{!isDifferenceToOk && ` | ${differenceTo.format('h:mm:ss', {trim: false})} [h]`}
        </Typography>
      </div>
    </div>
  )

}

TrackingStepEvaluation.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deliveryTime: PropTypes.any.isRequired,
  t: PropTypes.func.isRequired,
  trackingStep: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TrackingStepEvaluation);
