import Columns from './columns';
import {displayModes} from '../../../services/enums/displayModes';

// CARE: ALWAYS INCREMENT THE VERSION IF THE SETTINGS FOR A COLUMN CHANGE!
// Config explanation
// optional: determines if the hidden status can be changed by the user
// hidden: determines if the field is shown or hidden
// conditional: indicates that the hidden status can be overriden using the conditions dictionary of the useTableConfig hook
const DefaultConfig = {
  [displayModes.admin]: {
    version: 3,
    columns: {
      tourId: {
        columnName: Columns.tourId.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 1,
      },
      length: {
        columnName: Columns.length.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 2
      },
      statisticsDuration: {
        columnName: Columns.statisticsDuration.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 3
      },
      stops: {
        columnName: Columns.stops.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 4
      },
      boxes: {
        columnName: Columns.boxes.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 5
      },
      tourPacking: {
        columnName: Columns.tourPacking.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 6
      },
      avgTimeToPackPBox: {
        columnName: Columns.avgTimeToPackPBox.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 7
      },
      avgBoxesPStop: {
        columnName: Columns.avgBoxesPStop.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 8
      },
      avgDistPStop: {
        columnName: Columns.avgDistPStop.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 9
      },
      avgStopsPHour: {
        columnName: Columns.avgStopsPHour.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 10
      },
      avgStopsPHourWTimeToPack: {
        columnName: Columns.avgStopsPHourWTimeToPack.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 11
      },
      percentBikeUsageVolume: {
        columnName: Columns.percentBikeUsageVolume.name,
        optional: true,
        hidden: false,
        conditional:false,
        order: 12
      },
      percentBikeUsageWeight: {
        columnName: Columns.percentBikeUsageWeight.name,
        optional: true,
        hidden: false,
        conditional:false,
        order: 13
      },

    }
  },
  [displayModes.carrier]: {
    version: 3,
    columns: {
      tourId: {
        columnName: Columns.tourId.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 1,
      },
      length: {
        columnName: Columns.length.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 2
      },
      statisticsDuration: {
        columnName: Columns.statisticsDuration.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 3
      },
      stops: {
        columnName: Columns.stops.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 4
      },
      boxes: {
        columnName: Columns.boxes.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 5
      },
      tourPacking: {
        columnName: Columns.tourPacking.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 6
      },
      avgTimeToPackPBox: {
        columnName: Columns.avgTimeToPackPBox.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 7
      },
      avgBoxesPStop: {
        columnName: Columns.avgBoxesPStop.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 7
      },
      avgDistPStop: {
        columnName: Columns.avgDistPStop.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 8
      },
      avgStopsPHour: {
        columnName: Columns.avgStopsPHour.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 9
      },
      avgStopsPHourWTimeToPack: {
        columnName: Columns.avgStopsPHourWTimeToPack.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 10
      },
      percentBikeUsageVolume: {
        columnName: Columns.percentBikeUsageVolume.name,
        optional: true,
        hidden: false,
        conditional:false,
        order: 11
      },
      percentBikeUsageWeight: {
        columnName: Columns.percentBikeUsageWeight.name,
        optional: true,
        hidden: false,
        conditional:false,
        order: 12
      },

    }
  },
  [displayModes.shipper]: {
    version: 1,
    columns: {}
  },
}

export default DefaultConfig;