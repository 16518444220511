export default theme => ({
  body: {
    backgroundColor: theme.palette.background.default,
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    minHeight: '100vh',
  },
  box: {
    minHeight: '100vh',
    position: 'relative',
  },
});

