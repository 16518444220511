import React from 'react'
import PropTypes from 'prop-types';
import {LoadingIndicator} from '../../index';

function LoadingWrapper(props) {
  const {loading, children, noPadding} = props;

  if (loading) {
    return <LoadingIndicator noPadding={noPadding}/>
  }

  return (
    children
  );

}

LoadingWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
  noPadding: PropTypes.bool,
};


export default LoadingWrapper;
