export default theme => ({
  colorEmpty: {
    fill: '#eeeeee',
  },
  colorScale1: {
    fill: '#d6e685',
    backgroundColor: '#d6e685',
  },
  colorScale2: {
    fill: '#8cc665',
    backgroundColor: '#8cc665',
  },
  colorScale3: {
    fill: '#44a340',
    backgroundColor: '#44a340',
  },
  colorScale4: {
    fill: '#1e6823',
    backgroundColor: '#1e6823',
  },
});
