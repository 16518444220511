export default theme => ({
  root: {
    padding: theme.spacing(3)
  },
  table: {
    border: `1px solid ${theme.palette.border}`
  },
  filter: {
    display: 'flex',
    justifyItems: 'flex-end',
    marginLeft: 'auto',
    marginTop: -theme.spacing(12),
    marginBottom: theme.spacing(2),
  },
  warning: {
    color: theme.palette.warning.main
  }
});
