export default theme => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '200px'
  },
  horizontalContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  wideDropdown: {
    minWidth: '550px'
  },
  textFieldError: {
    border: '1px solid red'
  },
  navLeft: {
    float:'left'
  }
});
