export default theme => ({
  root: {},
  button: {
    marginLeft: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100px',
  },
  wrapper: {
    display: 'flex',
    margin: theme.spacing(2)
  },
  wrapperChild: {
    flex: 1,
  },
  textArea: {
    minWidth: '400px'
  },
  redIcon: {
    color: theme.palette.danger.main,
  },
  table: {
    border: `1px solid ${theme.palette.border}`
  }
});
