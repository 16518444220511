export default theme => ({
  dialog: {},
  formControl: {
    display: 'block'
  },
  warningColor: {
    color: theme.palette.warning.main,
    verticalAlign: 'middle'
  },
  dangerColor: {
    color: theme.palette.danger.main,
    verticalAlign: 'middle'
  },
});
