import {useEffect, useState} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {ClientService} from '../services/client/clientService';
import {clientTypes} from '../services/client/clientTypes';
import {CarrierQueryService} from '../services/backend/carrierQueryService';


const clientCarrier = ClientService.getClient(clientTypes.carrier);

export default function useCarriersWithHubs(carrierName) {

  const [microHubOptions, setMicroHubOptions] = useState([]);
  const [carrierOptions, setCarrierOptions] = useState([]);
  const [carriersWithHubs, setCarriersWithHubs] = useState({});

  const queryCarrier = CarrierQueryService.getAllCarrierWithHubNames(carrierName ? carrierName : '');
  const {data} = useQuery(queryCarrier, {client: clientCarrier});

  useEffect(() => {
    if (!data?.carriers?.length) return;

    const carriers = [];
    const microHubs = [];
    const carrierAndHubs = {};

    data.carriers.forEach(carrier => {
      carriers.push(carrier.name);
      carrierAndHubs[carrier.name] = [];
      carrier.hubs.forEach(hub => {
        if (!microHubs.includes(hub.name)) {
          microHubs.push(hub.name);
        }
        carrierAndHubs[carrier.name].push(hub.name);
      })
    })
    setMicroHubOptions(microHubs);
    setCarrierOptions(carriers);
    setCarriersWithHubs(carrierAndHubs);
  }, [data]);


  return {microHubOptions, carrierOptions, carriersWithHubs};
}
