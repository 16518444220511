export default {
  background: {
    default: '#E5E5E5',
    paper: '#ffffff'
  },
  primary: {
    main: '#29951D',
    contrastText: '#ffffff'
  },
  secondary: {
    main: '#F3FFF3'
  },
  text: {
    primary: '#141414',
    secondary: '#919796'
  }
}
