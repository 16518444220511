import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import SideNavLayout from '../../../layouts/SideNavLayout';
import DataLoadingWrapper from '../../../components/DataLoadingWrapper';
import {GeneralInfoIcon, SelectMap, SelectMapFilter} from '../../../components';
import React, {useEffect, useState} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {AuthService} from '../../../services/auth/authService';
import {ClientService} from '../../../services/client/clientService';
import {clientTypes} from '../../../services/client/clientTypes';
import {MicroHubQueryService} from '../../../services/backend/microHubQueryService';
import useMicroHubData from '../../../hooks/useMicroHubData';

const clientMicrohub = ClientService.getClient(clientTypes.microHub);

function AreaSelection(props) {

  const {classes, t} = props;
  const [polygons, setPolygons] = useState([]);
  const [filter, setFilter] = useState({
    shipperName: AuthService.getUserOrganization(),
    carrierName: '',
    microHubList: [],
  });
  const {microHubData} = useMicroHubData();

  const query = MicroHubQueryService.getHubAreasByNameListQuery(filter.microHubList, filter.carrierName, filter.shipperName);
  const {error, loading, data} = useQuery(query, {client: clientMicrohub});

  useEffect(() => {
    if (data?.microhubs) {
      const assemblePolygons = [];
      data.microhubs.forEach(hub => {
        hub.carriers.forEach(carrier => {
          if (carrier.coordinates.length > 0) {
            const coordinates = [];
            carrier.coordinates.sort((c1, c2) => (c1.order > c2.order) ? 1 : ((c2.order > c1.order) ? -1 : 0));
            carrier.coordinates.forEach(coordinate => coordinates.push([coordinate.latitude, coordinate.longitude]));
            assemblePolygons.push({name: [hub.name, ' - ', carrier.carrier.name], coordinates: coordinates})
          }
        });
      });
      setPolygons(assemblePolygons);
    }
  }, [data]);


  return (
    <SideNavLayout title={t('areaSelection')}>
      <div className={classes.root}>
        <GeneralInfoIcon className={classes.helpWrapper}/>
        <SelectMapFilter
          dataMicroHubs={microHubData}
          filter={filter}
          setFilter={setFilter}
        />
        <DataLoadingWrapper
          error={error}
          isLoading={loading}
        >
          {data?.microhubs &&
          <SelectMap
            className={classes.map}
            dirty={false}
            microHubs={microHubData}
            polygons={polygons}
            readonly
            setNewPolygonsSet={() => {
            }}
          />
          }
        </DataLoadingWrapper>
      </div>
    </SideNavLayout>
  );
}

AreaSelection.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(AreaSelection);
