import React from 'react'
import {IconButton} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  DragIndicatorOutlined as DragIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon
} from '@material-ui/icons';
import {ShipperLabel} from '../../../../../../components';
import {DateService} from '../../../../../../services/util/DateService';
import {roundCapacityValues} from '../../../../../../services/util/helperFuncs';

const Columns = {
  stopNumber: {
    name: 'stopNumber',
    header: ({t}) => t('stopNumber'),
    body: ({stop, classes, dragHandleProps}) => (
      <div className={classes.flexTableCell}>
        <div
          {...dragHandleProps}
          className={classes.dragIcon}
        >
          <DragIcon/>
        </div>
        {stop.stopNumber}
      </div>
    )
  },
  shipper: {
    name: 'shipper',
    header: ({t}) => t('shipper'),
    body: ({stop}) => <ShipperLabel stop={stop}/>
  },
  deliveryTimeWindowShipper: {
    name: 'deliveryTimeWindowShipper',
    header: ({t}) => t('deliveryTimeWindowShipper'),
    body: ({stop}) => stop.deliveryWindowShipper ? `${DateService.displayTime(stop?.deliveryWindowShipper?.from)} - ${DateService.displayTime(stop?.deliveryWindowShipper?.to)}` : '-'
  },
  lastName: {
    name: 'lastName',
    header: ({t}) => t('lastName'),
    body: ({stop}) => stop.lastName
  },
  streetAndNumber: {
    name: 'streetAndNumber',
    header: ({t}) => t('streetAndNumber'),
    body: ({stop}) => stop.address.streetAndNumber
  },
  boxAmount: {
    name: 'boxAmount',
    header: ({t}) => t('boxAmount'),
    body: ({stop}) => roundCapacityValues(stop.stopCapacities.boxAmount)
  },
  weight: {
    name: 'weight',
    header: ({t}) => t('weight'),
    body: ({stop}) => roundCapacityValues(stop.stopCapacities.weight)
  },
  volume: {
    name: 'volume',
    header: ({t}) => t('volume'),
    body: ({stop}) => roundCapacityValues(stop.stopCapacities.volume)
  },
  microHub: {
    name: 'microHub',
    header: ({t}) => t('microHub'),
    body: ({stop}) => stop.assignedMicroHubName
  },
  remove: {
    name: 'remove',
    header: ({t}) => t('remove'),
    body: ({classes, debouncedDelete, setOpen, open, t}) => (
      <div className={classes.flexTableCell}>
        <IconButton
          className={classes.buttonLeft}
          onClick={debouncedDelete}
          size="small"
          title={t('deleteStopFromTour')}
        >
          <DeleteIcon/>
        </IconButton>
        <IconButton
          className={classes.buttonRight}
          onClick={() => setOpen(!open)}
          size="small"
        >
          {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
        </IconButton>
      </div>
    )
  },
}

export default Columns;