export default theme => ({
  root: {},
  rowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    margin: theme.spacing(0)
  },
});
