import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {Step, StepContent, StepLabel, Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import React, {Fragment, useState} from 'react';
import {StepperIcon} from '../index';
import classNames from 'classnames';
import {DateService} from '../../../../../services/util/DateService';
import moment from 'moment';
import {ExpandLess, ExpandMore} from '@material-ui/icons';


const ApprovedTourStep = (props) => {
  const {className, classes, t, tour, timeStamp, isLastApproval, user} = props;

  const [active, setActive] = useState(false);
  const rootClassName = classNames(classes.root, className);

  return (
    <Step
      active={active}
      className={rootClassName}
      completed
    >
      <StepLabel
        classes={{error: classes.noErrorClass}}
        error={!isLastApproval}
        StepIconComponent={StepperIcon}
      >
        <div
          className={classes.stepperLabel}
          onClick={() => setActive(!active)}
        >
          <Typography
            variant={'subtitle1'}
          >
            {`[${DateService.displayDateTime(timeStamp)}] - ${t('tourApprove')}`}
          </Typography>
          {active ? <ExpandLess/> : <ExpandMore/>}
        </div>
      </StepLabel>
      <StepContent>
        {isLastApproval ?
          <Fragment>
            <Typography>
              <strong>{t('driver')}:</strong> {`${tour.driverDisplayName ? tour.driverDisplayName : '-'} (${tour.driverEmail ? tour.driverEmail : '-'})`}
            </Typography>
            <Typography><strong>{t('vehicleName')}:</strong> {tour.vehicleLicensePlate}</Typography>
            <Typography><strong>{t('planedTourDelivery')}:</strong> {DateService.displayDateTime(tour.planedTourDelivery)}</Typography>
            <Typography><strong>{t('plannedDuration')}:</strong> {tour.duration}</Typography>
            <Typography>
              <strong>{t('plannedTourEnd')}:</strong> {DateService.displayDateTime(moment(tour.planedTourDelivery).add(tour.duration, 'minutes').toDate().toJSON())}
            </Typography>
            <Typography><strong>{t('eventUser')}:</strong> {user}</Typography>
          </Fragment>
          :
          <Fragment>
            <Typography>{t('revokeTourApproval')}</Typography>
            <Typography><strong>{t('eventUser')}:</strong> {user}</Typography>
          </Fragment>
        }
      </StepContent>
    </Step>
  );
};


ApprovedTourStep.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  isLastApproval: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  timeStamp: PropTypes.string.isRequired,
  tour: PropTypes.object.isRequired,
  user: PropTypes.string.isRequired,
};

export default compose(withStyles(styles), withTranslation())(ApprovedTourStep);
