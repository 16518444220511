export default theme => ({
  root: {
    overflow: 'auto'
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  tourStopTable: {
    margin: theme.spacing(1),
    overflow: 'auto'
  },
  wrapper: {
    display: 'flex',
    margin: theme.spacing(2)
  },
  wrapperChild: {
    flex: 1,
  },
  detail: {
    margin: theme.spacing(1)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
    flexGrow: 1
  },
  infoIcon: {
    width: 20, height: 16,
    padding: 0,
    margin: 0,
    color: theme.palette.secondary.main
  }
});
