export default theme => ({
  root: {
    padding: theme.spacing(1)
  },
  buttonRight: {
    marginTop: theme.spacing(1),
  },
  buttonRightActivated: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.warning.main
  },
  helpWrapper: {
    float: 'right',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
});
