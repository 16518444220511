import React, {useCallback, useContext, useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import SideNavLayout from '../../../layouts/SideNavLayout';
import {useQuery} from '@apollo/react-hooks';
import {ClientService} from '../../../services/client/clientService';
import {clientTypes} from '../../../services/client/clientTypes';
import {PreviewToursService} from '../../../services/backend/previewToursService';
import {LoadingWrapper, PreviewToursFilter, PreviewToursView, RefreshButton} from '../../../components';
import {MicroHubQueryService} from '../../../services/backend/microHubQueryService';
import {getDayOfWeekFromDate} from '../../../services/enums/dayOfWeekHelper';
import {usePreviewStopHub} from '../../../hooks/usePreviewStopHub';
import {previewStopMessageRelevantForFilter, previewTourMessageRelevantForFilter} from '../../../services/util/signalRMessageHelper';
import {usePreviewTourHub} from '../../../hooks/usePreviewTourHub';
import useShipperOptions from '../../../hooks/useShipperOptions';
import useCarriersWithHubs from '../../../hooks/useCarriersWithHubs';
import {DatePickerDefaultValueContext} from '../../../context';
import useMicroHubData from '../../../hooks/useMicroHubData';


const clientMicroHub = ClientService.getClient(clientTypes.microHub);

function PreviewToursAdmin(props) {

  const {classes, t} = props;
  const {fromDateDefault, updateDefaultDate} = useContext(DatePickerDefaultValueContext);

  const [previewData, setPreviewData] = useState([]);
  const [unassignedStops, setUnassignedStops] = useState([]);
  const {shipperOptions: shipperNames} = useShipperOptions();
  const {carriersWithHubs} = useCarriersWithHubs();
  const {microHubData} = useMicroHubData();
  const [filter, setFilter] = useState({
    carrierName: '',
    shipperName: '',
    microHubList: [],
    date: fromDateDefault,
    frozen: '',
  });
  const [mapTabState, setMapTabState] = useState(undefined);
  useEffect(() => updateDefaultDate(filter.date), [filter.date, updateDefaultDate]);
  const [backendLoading, setBackendLoading] = useState(false);
  /*Remove this until the order of the stops matches the order calculated at the shipperAPI*/
  // const [backendLoadingExcel, setBackendLoadingExcel] = useState(false);
  const [microHubPlansForDay, setMicroHubPlansForDay] = useState([]);

  const queryMicroHubPlans = MicroHubQueryService.getMicroHubMaxVolume('', '');
  const {data: dataMicroHubPlans} = useQuery(queryMicroHubPlans, {client: clientMicroHub});

  /*Remove this until the order of the stops matches the order calculated at the shipperAPI*/
  // const downloadStopsAsExcel = () => {
  //   const microHubs = previewData ? previewData.map(hub => hub.microHubName) : [];
  //   const stopsWithoutMH = unassignedStops ? unassignedStops.map(stop => stop.id) : [];
  //
  //   if (microHubs.length !== 0 || stopsWithoutMH.length !== 0) {
  //     setBackendLoadingExcel(true);
  //     PreviewToursService.downloadPreviewAsExcel(filter.shipperName, microHubs, stopsWithoutMH, filter.date)
  //       .then(response => {
  //         setBackendLoadingExcel(false);
  //         let filename = response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0];
  //
  //         response.blob().then(blob =>
  //           FileSaver.saveAs(blob, filename)
  //         );
  //       }, () => {
  //         alert(t('errorDownloadingExcel'))
  //         setBackendLoadingExcel(false);
  //       });
  //   } else {
  //     alert(`${t('noPreviewDataAvailable')}: ${DateService.displayDate(filter.date)}`);
  //   }
  // };

  const changeStopAssignment = (stopId, carrierName, microHubName) => {
    PreviewToursService.changeStopAssignment(stopId, carrierName, microHubName).then(response => {
      if (response.ok) {
        PreviewToursService.rerunAlgoForHub(filter.date, carrierName, microHubName).then(response => response.ok ? loadData() : alert(t('errorRerunningAlgo')))
      } else {
        alert(t('errorChangingStopAssignment'));
      }
    });
  };

  const deleteStop = (stopId, carrierName, microHubName) => {
    PreviewToursService.deleteStopById(stopId).then(response => {
      if (response.ok) {
        if (carrierName && microHubName) {
          PreviewToursService.rerunAlgoForHub(filter.date, carrierName, microHubName).then(response => response.ok ? loadData() : alert(t('errorRerunningAlgo')))
        } else {
          loadData();
        }
      } else {
        alert(t('errorDeletingStop'))
      }
    })
  };

  const [refresh, setRefresh] = useState(false);
  usePreviewStopHub(useCallback(event => {
    if (previewStopMessageRelevantForFilter(event, filter)) {
      setRefresh(true);
    }
  }, [filter]));
  usePreviewTourHub(useCallback(event => {
    if (previewTourMessageRelevantForFilter(event, filter)) {
      setRefresh(true);
    }
  }, [filter]));


  const loadData = useCallback(async () => {
    if (!(filter.date instanceof Date && !isNaN(filter.date))) {
      return;
    }
    setBackendLoading(true);
    const unassignedStops = await PreviewToursService.getUnassignedStopsByFilter(null, null, filter.date);
    setUnassignedStops(unassignedStops)
    try {
      const response = await PreviewToursService.getMicroHubsWithToursByFilter(filter.carrierName, filter.shipperName, filter.microHubList, filter.date, filter.frozen ? filter.frozen : null);
      setPreviewData(response);
      setBackendLoading(false);
      setRefresh(false);
    } catch (e) {
      alert(`${t('errorLoadingPreviewTours')}: ${e}`);
      setBackendLoading(false);
      setRefresh(false);
    }
  }, [filter, t]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (dataMicroHubPlans && dataMicroHubPlans.multipleHubsAndCarrierPlans) {
      const hubPlansForCarrierAndHub = JSON.parse(JSON.stringify(dataMicroHubPlans.multipleHubsAndCarrierPlans));
      hubPlansForCarrierAndHub.forEach((hubPlanForCarrierAndHub) => {
        hubPlanForCarrierAndHub.hubPlans = hubPlanForCarrierAndHub.hubPlans.filter(plan => plan.dayOfWeek === getDayOfWeekFromDate(filter.date))
      });
      setMicroHubPlansForDay(hubPlansForCarrierAndHub);
    }
  }, [dataMicroHubPlans, filter]);

  return (
    <SideNavLayout title={t('previewTours')}>
      <div className={classes.root}>
        {/*Remove this until the order of the stops matches the order calculated at the shipperAPI*/}
        {/*<LoadingButton*/}
        {/*  color="secondary"*/}
        {/*  loading={backendLoadingExcel}*/}
        {/*  onClick={downloadStopsAsExcel}*/}
        {/*  variant="contained"*/}
        {/*>*/}
        {/*  <DownloadIcon/>&nbsp;{t('downloadPreviewAsExcel')}*/}
        {/*</LoadingButton>*/}
        <RefreshButton
          className={classes.refreshButton}
          refresh={refresh}
          refreshFunc={loadData}
        />
        <PreviewToursFilter
          carriersWithMicroHubs={carriersWithHubs}
          filter={filter}
          setFilter={setFilter}
          shipperOptions={shipperNames}
        />
        <LoadingWrapper loading={backendLoading}>
          <PreviewToursView
            changeStopAssignment={changeStopAssignment}
            dataCarrierForChangeAssignment={carriersWithHubs}
            deleteStop={deleteStop}
            hubsWithToursAndStops={previewData}
            mapTabState={mapTabState}
            microHubPlanData={microHubPlansForDay}
            microHubs={microHubData}
            setMapTabState={setMapTabState}
            unassignedStops={unassignedStops ? unassignedStops : []}
          />
        </LoadingWrapper>
      </div>
    </SideNavLayout>
  );
}


PreviewToursAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(PreviewToursAdmin);
