import React, {useState} from 'react'
import {Button, ButtonGroup, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import {DragDropContext} from 'react-beautiful-dnd';
import {HubBaskets, HubList} from './components';
import {DndHelperElementTypes, DndHelperService} from '../../../services/dndHelper/dndHelperService';
import {CapacityHelperService} from '../../../services/util/capacityHelper';
import {getDayOfWeekStringFromDateString} from '../../../services/enums/dayOfWeekHelper';
import {AuthService} from '../../../services/auth/authService';
import RefreshButton from '../../RefreshButton';
import {ShipperAllowedOnHubService} from '../../../services/util/shipperAllowedOnHubHelper';
import {TextPlaceholderWrapper} from '../../index';

function PreviewToursEditHubAssignment(props) {

  const {
    classes,
    hubsWithToursAndStops,
    t,
    className,
    cancelEdit,
    saveEdit,
    setDisableFilter,
    allMicroHubs,
    microHubData,
    microHubPlanData,
    refresh,
    refreshFunc,
    vehicles
  } = props;
  const [dirty, setDirty] = useState(false);
  const [hubListData, setHubListData] = useState(null);
  const [hubBasketsData, setHubBasketsData] = useState(null);
  const [draggingItemHub, setDraggingItemHub] = useState(null);

  const getCapacitiesForHubWithHubList = (hubName, hubList) => {
    const index = hubList.findIndex(hub => hub.microHubName === hubName);
    const result = {
      boxAmount: 0,
      weight: 0,
      volume: 0
    };
    if (index >= 0) {
      const hub = hubList[index];
      result.boxAmount = CapacityHelperService.getBoxAmountOfHub(hub);
      result.weight = CapacityHelperService.getWeightOfHub(hub);
      result.volume = CapacityHelperService.getVolumeOfHub(hub);
    }
    return result;
  };

  const getCapacitiesForHub = (hubName) => {
    return getCapacitiesForHubWithHubList(hubName, hubListData)
  };

  // initialize Data if not yet done
  if (!dirty && hubsWithToursAndStops && allMicroHubs && allMicroHubs.length > 0 && JSON.stringify(hubListData) !== JSON.stringify(hubsWithToursAndStops)) {
    setHubListData(JSON.parse(JSON.stringify(hubsWithToursAndStops)));
    const hubBaskets = allMicroHubs.map(hubName => {
      return {
        microHubName: hubName,
        carrierName: AuthService.getUserOrganization(),
        initialCapacities: getCapacitiesForHubWithHubList(hubName, hubsWithToursAndStops),
        toursAndStops: [],
      }
    });
    setHubBasketsData(hubBaskets);
    setDirty(false);
    setDisableFilter(false);
  }

  const rootClassName = classNames(classes.root, className);


  const cancel = () => {
    cancelEdit();
    setHubListData(null);
    setHubBasketsData(null);
    setDirty(false);
    setDisableFilter(false);
  };

  const save = () => {
    const changedHubAssignments = [];
    hubBasketsData.forEach(basket => {
      // Add data of all non empty baskets to changedHubAssignments
      if (basket.toursAndStops.length > 0) {
        const changedAssigment = {
          microHubName: basket.microHubName,
          carrierName: basket.carrierName,
          tours: basket.toursAndStops.filter(elem => elem.type === DndHelperElementTypes.Tour).map(elem => elem.elementData),
          stops: basket.toursAndStops.filter(elem => elem.type === DndHelperElementTypes.Stop).map(elem => elem.elementData),
        }
        changedHubAssignments.push(changedAssigment);
      }
    });
    saveEdit(changedHubAssignments);
    setHubListData(null);
    setHubBasketsData(null);
    setDirty(false);
    setDisableFilter(false);
  };

  const createBasketElementFromTour = (tour) => {
    return {
      type: DndHelperElementTypes.Tour,
      elementData: tour,
      color: tour.color,
      frozen: tour.frozen,
      capacities: {
        boxAmount: CapacityHelperService.getBoxAmountOfTour(tour),
        weight: CapacityHelperService.getWeightOfTour(tour),
        volume: CapacityHelperService.getVolumeOfTour(tour),
      },
      stopAmount: tour.stops.length
    }
  };

  const createBasketElementFromStop = (stop) => {
    return {
      type: DndHelperElementTypes.Stop,
      elementData: stop,
      capacities: stop.stopCapacities,
    }
  };

  const onDragStart = (event) => {
    const source = DndHelperService.parseUniqueId(event.source.droppableId);
    if (source.type === DndHelperElementTypes.Basket) {
      setDraggingItemHub(source.microHubName)
    }
  };

  const allStopsFitVehicle = (vehicles, stops) => {
    return stops.every(s =>
      vehicles.some(v =>
        v.payloadWeight >= s.stopCapacities.weight &&
        v.volume >= s.stopCapacities.volume
      )
    );
  }

  const onDragEnd = (event) => {
    if (draggingItemHub) {
      setDraggingItemHub(null);
    }

    if (!event.destination) {
      return;
    }

    if (event.destination === event.source) {
      return;
    }

    const source = DndHelperService.parseUniqueId(event.source.droppableId);
    const destination = DndHelperService.parseUniqueId(event.destination.droppableId);
    const newHubList = JSON.parse(JSON.stringify(hubListData));
    const newBasketData = JSON.parse(JSON.stringify(hubBasketsData));

    const destHub = microHubData.find(hub => hub.name === destination.microHubName);

    switch (source.type) {
      case DndHelperElementTypes.Hub: {
        // moved tour from hub to basket => remove tour from hubLIst and add to basket
        const hubIndexHubListData = newHubList.findIndex(hub => hub.microHubName === source.microHubName);
        const indexBasketData = newBasketData.findIndex(basket => basket.microHubName === destination.microHubName);
        if (hubIndexHubListData < 0 || indexBasketData < 0) return;

        const tour = newHubList[hubIndexHubListData].tours.splice(event.source.index, 1);

        if (!ShipperAllowedOnHubService.checkShipperAllowedTour(destHub, tour[0])) {
          alert(t('shipperNotAllowedTour'))
          return;
        }
        const hubVehicles = vehicles.filter(v => v.microhubs.some(m => m.name === destination.microHubName && m[getDayOfWeekStringFromDateString(tour[0].deliveryDate)]));

        if (!allStopsFitVehicle(hubVehicles, tour[0].stops)) {
          if (!window.confirm(t('confirmStopsDontFitVehicles'))) {
            return;
          }
        }

        newBasketData[indexBasketData].toursAndStops.splice(newBasketData[indexBasketData].toursAndStops.length, 0, createBasketElementFromTour(...tour));
        break;
      }
      case DndHelperElementTypes.Tour: {
        // moved stop from tour to basket => remove stop from hubList and add to basket
        const hubIndexHubListData = newHubList.findIndex(hub => hub.microHubName === source.microHubName);
        const tourIndexHubListData = newHubList[hubIndexHubListData].tours.findIndex(tour => tour.id === source.tourId);
        const indexBasketData = newBasketData.findIndex(basket => basket.microHubName === destination.microHubName);
        if (hubIndexHubListData < 0 || indexBasketData < 0 || tourIndexHubListData < 0) return;

        const stop = newHubList[hubIndexHubListData].tours[tourIndexHubListData].stops.splice(event.source.index, 1);

        if (!ShipperAllowedOnHubService.checkShipperAllowedStop(destHub, stop[0])) {
          alert(t('shipperNotAllowedStop'))
          return;
        }

        const hubVehicles = vehicles.filter(v => v.microhubs.some(m => m.name === destination.microHubName && m[getDayOfWeekStringFromDateString(stop[0].deliveryDate)]));

        if (!allStopsFitVehicle(hubVehicles, stop)) {
          if (!window.confirm(t('confirmStopsDontFitVehicles'))) {
            return;
          }
        }

        newBasketData[indexBasketData].toursAndStops.splice(newBasketData[indexBasketData].toursAndStops.length, 0, createBasketElementFromStop(...stop));
        break;
      }
      case DndHelperElementTypes.Basket: {
        // moved element form basket to basket
        const sourceBasketIndex = newBasketData.findIndex(basket => basket.microHubName === source.microHubName);
        const destinationBasketIndex = newBasketData.findIndex(basket => basket.microHubName === destination.microHubName);
        if (sourceBasketIndex < 0 || destinationBasketIndex < 0) return;

        const basketElement = newBasketData[sourceBasketIndex].toursAndStops.splice(event.source.index, 1);

        switch (basketElement[0].type) {
          case DndHelperElementTypes.Tour: {
            if (!ShipperAllowedOnHubService.checkShipperAllowedTour(destHub, basketElement[0].elementData)) {
              alert(t('shipperNotAllowedTour'))
              return;
            }

            const hubVehicles = vehicles.filter(v => v.microhubs.some(m => m.name === destination.microHubName && m[getDayOfWeekStringFromDateString(basketElement[0].elementData.deliveryDate)]));

            if (!allStopsFitVehicle(hubVehicles, basketElement[0].elementData.stops)) {
              if (!window.confirm(t('confirmStopsDontFitVehicles'))) {
                return;
              }
            }
            break;
          }
          case DndHelperElementTypes.Stop: {
            if (!ShipperAllowedOnHubService.checkShipperAllowedStop(destHub, basketElement[0].elementData)) {
              alert(t('shipperNotAllowedStop'))
              return;
            }

            const hubVehicles = vehicles.filter(v => v.microhubs.some(m => m.name === destination.microHubName && m[getDayOfWeekStringFromDateString(basketElement[0].elementData.deliveryDate)]));

            if (!allStopsFitVehicle(hubVehicles, [basketElement[0].elementData])) {
              if (!window.confirm(t('confirmStopsDontFitVehicles'))) {
                return;
              }
            }
            break;
          }
          default: {
            // we don't know the element type, so we can't do anything
            break;
          }
        }


        newBasketData[destinationBasketIndex].toursAndStops.splice(newBasketData[destinationBasketIndex].toursAndStops.length, 0, ...basketElement);
        break;
      }
      default: {
        // wrong element type
        return;
      }
    }
    setHubListData(newHubList);
    setHubBasketsData(newBasketData);
    setDirty(true);
    setDisableFilter(true);
  };

  return (
    <div className={rootClassName}>
      <div className={classes.controlButtonContainer}>
        <RefreshButton
          className={classes.refreshButton}
          disabled={dirty}
          refresh={refresh}
          refreshFunc={refreshFunc}
        />
        <ButtonGroup
          className={classes.controlButtons}
          color="primary"
          disabled={!dirty}
          variant="contained"
        >
          <Button
            onClick={cancel}
          >
            <CancelIcon/>&nbsp;{t('cancel')}
          </Button>
          <Button
            onClick={save}
          >
            <SaveIcon/>&nbsp;{t('save')}
          </Button>
        </ButtonGroup>
      </div>
      <br/>
      <TextPlaceholderWrapper
        active={!hubListData?.length}
        text={t('noPreviewDataAvailable')}
      >
        <DragDropContext
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
        >
          <div className={classes.wrapper}>
            <div className={classes.wrapperList}>
              <HubList
                className={classes.hubList}
                hubListData={hubListData}
              />
            </div>
            <div className={classes.wrapperBasket}>
              <HubBaskets
                className={classes.hubBaskets}
                draggingItemHub={draggingItemHub}
                getCapacitiesForHub={getCapacitiesForHub}
                hubBasketsData={hubBasketsData}
                microHubPlanData={microHubPlanData}
              />
            </div>
          </div>
        </DragDropContext>
      </TextPlaceholderWrapper>
    </div>
  );
}


PreviewToursEditHubAssignment.propTypes = {
  allMicroHubs: PropTypes.array.isRequired,
  cancelEdit: PropTypes.func.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  hubsWithToursAndStops: PropTypes.array.isRequired,
  i18n: PropTypes.object.isRequired,
  microHubPlanData: PropTypes.array.isRequired,
  refresh: PropTypes.bool.isRequired,
  refreshFunc: PropTypes.func.isRequired,
  saveEdit: PropTypes.func.isRequired,
  setDisableFilter: PropTypes.func,
  microHubData: PropTypes.array.isRequired,
  vehicles: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired
};

export default compose(withStyles(styles), withTranslation())(PreviewToursEditHubAssignment);
