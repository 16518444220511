import {AuthService} from '../auth/authService';
import axios from 'axios';

const baseUri = `${process.env.REACT_APP_API_URI}shipper-api`;

const getDeclinedStopsByFilter = async (shipperName, date, dayRangeFrom = 0, dayRangeTo = 1) => {
  const response = await axios.put(`${baseUri}/administration/v1/shipperDeclinedStops/declinedStops/byFilter/`,
    {
      ShipperName: shipperName,
      DateFilter: date,
      DayRangeFrom: dayRangeFrom,
      DayRangeTo: dayRangeTo,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const getShipperApiEventsByFilter = async (shipperName, date) => {
  const response = await axios.put(`${baseUri}/administration/v1/ShipperApiEvents/byFilter/`,
    {
      Shipper: shipperName,
      Date: date,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

export default {
  getDeclinedStopsByFilter,
  getShipperApiEventsByFilter
};
