import useShipperOptions from '../../../hooks/useShipperOptions';
import React, {Fragment, useCallback, useEffect, useState} from 'react'
import {Tab, Tabs, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {CalendarHeatMapDiagram, LoadingButton, LoadingWrapper, StatisticFilter} from '../../../components'
import {TourService} from '../../../services/backend/tourService';
import SideNavLayout from '../../../layouts/SideNavLayout';
import FileSaver from 'file-saver';
import {GetApp as DownloadIcon} from '@material-ui/icons';
import {displayModes} from '../../../services/enums/displayModes';
import {InvoiceTable, StatisticsTable} from 'components';
import useCarriersMicroHubsDrivers from 'hooks/useCarriersWithDrivers';
import {AuthService} from '../../../services/auth/authService';
import {DateService} from '../../../services/util/DateService';
import AggregatedCard from '../../../components/Statistic/AggregatedCard';

const tabStateOptions = {
  invoices: 0,
  statistics: 1,
};

function StatisticsOverviewCarrier(props) {

  const {classes, t} = props;

  const today = new Date()
  const [filter, setFilter] = useState({
    carrierName: AuthService.getUserOrganization(),
    shipperName: '',
    microHubName: '',
    stopStatus: '',
    driverEmail: '',
    fromDate: new Date(today.getFullYear(), today.getMonth() - 1, 1, 0, 0, 1),
    toDate: new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59)
  });

  const {driverNames, carriersMicroHubsDrivers} = useCarriersMicroHubsDrivers(AuthService.getUserOrganization());
  const {shipperOptions} = useShipperOptions();
  const [statistics, setStatistics] = useState([]);
  const [aggregatedStatistic, setAggregatedStatistics] = useState(null);
  
  const [dateStatistic, setDateStatistics] = useState(null);

  const [invoices, setInvoices] = useState([]);
  const [aggregatedInvoice, setAggregatedInvoices] = useState(null);

  const [tabState, setTabState] = useState(tabStateOptions.invoices);

  const [backendLoading, setBackendLoading] = useState(false);
  const [backendLoadingExcel, setBackendLoadingExcel] = useState(false);

  const loadData = useCallback(async () => {
    setBackendLoading(true);
    try {
      const statisticsTask = TourService.getStatisticsWithFilter(filter);
      const invoiceTask = TourService.getInvoicesWithFilter(filter);
      const dateStatisticsTask = TourService.getDateStatisticsWithFilter(filter);
      const statisticsResult = await statisticsTask;
      const invoiceResult = await invoiceTask;
      const dateStatisticResult = await dateStatisticsTask;

      setStatistics(statisticsResult.tourStatistics);
      setAggregatedStatistics(statisticsResult.aggregated);
      setInvoices(invoiceResult.stopInvoices);
      setAggregatedInvoices({...invoiceResult.aggregated});
      setDateStatistics(dateStatisticResult);
    } catch (e) {
      alert(`${t('errorLoadingTourStatisticsAndInvoices')}, ${e}`)
    }
    setBackendLoading(false);
  }, [filter, t]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const downloadInvoicesAsExcel = () => {
    if (invoices.length !== 0) {
      setBackendLoadingExcel(true);
      TourService.downloadStopInvoicesExcel(filter)
        .then(response => {
          let filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
          setBackendLoadingExcel(false);
          FileSaver.saveAs(response.data, filename)
        }, () => {
          alert(t('errorDownloadingExcel'))
          setBackendLoadingExcel(false);
        });
    } else {
      alert(`${t('noInvoiceDataAvailable')}: ${DateService.displayDate(filter.fromDate)}-${DateService.displayDate(filter.toDate)}`);
    }
  };

  return (
    <SideNavLayout title={t('statisticsOverview')}>
      <div className={classes.root}>
        <div className={classes.verticalContainer}>
          <LoadingButton
            className={classes.button}
            color="secondary"
            loading={backendLoadingExcel}
            onClick={downloadInvoicesAsExcel}
            variant="contained"
          >
            <DownloadIcon/>&nbsp;{t('downloadInvoice')}
          </LoadingButton>
        </div>
        <br/>
        <Fragment>
          <StatisticFilter
            carriersMicroHubsDrivers={carriersMicroHubsDrivers}
            displayMode={displayModes.carrier}
            driverDisplayNames={driverNames}
            filter={filter}
            setFilter={setFilter}
            shipperOptions={shipperOptions}
          />
          <Tabs
            onChange={(_, newValue) => {
              setTabState(newValue)
            }}
            value={tabState}
          >
            <Tab
              label={t('invoices')}
            />
            <Tab
              label={t('statistics')}
            />
          </Tabs>
        </Fragment>
        <br/>
        <LoadingWrapper loading={backendLoading}>
          <Fragment>
            {(() => {
              switch (tabState) {
                case tabStateOptions.invoices:
                  return (
                      <Fragment>
                        <AggregatedCard
                            aggregatedData={aggregatedInvoice}
                            tabState={tabState}
                        />
                        <InvoiceTable
                          className={classes.content}
                          invoices={invoices}
                          tableDisplayMode={displayModes.carrier}
                        />
                      </Fragment>
                  );
                case tabStateOptions.statistics:
                  return (
                      <Fragment>
                        <AggregatedCard
                            aggregatedData={aggregatedStatistic}
                            tabState={tabState}
                        />
                        <StatisticsTable
                          aggregated={aggregatedStatistic}
                          className={classes.content}
                          statistics={statistics}
                          displayMode={displayModes.admin}
                        />
                        <CalendarHeatMapDiagram
                            dateStatistic={dateStatistic}
                            filter={filter}
                        />
                      </Fragment>
                  );
                default:
                  return (<div>Error</div>);
              }
            })()}
          </Fragment>
        </LoadingWrapper>
      </div>
    </SideNavLayout>
  );
}

StatisticsOverviewCarrier.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(StatisticsOverviewCarrier);
