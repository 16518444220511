import React, {Fragment} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {PortletContent, PortletHeader} from '../../../index';
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import {default as DriverEdit} from '../../DriverEdit';

function DriverDetailEdit(props) {

  const {classes, className, editedDriver, setEditMode, t, updateDriver, setEditedDriver, microHubOptions} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <Fragment>
      <PortletHeader className={rootClassName}>
        <Fragment>
          <div><strong>{editedDriver.firstname} {editedDriver.lastname}</strong></div>
          <div>
            <Button
              className={classes.button}
              color="default"
              onClick={() => setEditMode(false)}
              title={t('driverCancel')}
              variant="contained"
            >
              <CancelIcon/>&nbsp;{t('cancel')}
            </Button>
            <Button
              className={classes.button}
              color="primary"
              disabled={!editedDriver.email || !editedDriver.displayName || editedDriver.displayName.length > 10 || editedDriver.microhubs.length === 0}
              onClick={updateDriver}
              title={t('driverSave')}
              variant="contained"
            >
              <SaveIcon/>
            </Button>
          </div>
        </Fragment>
      </PortletHeader>
      <PortletContent noPadding>
        <DriverEdit
          className={classes.wrapper}
          editedDriver={editedDriver}
          microHubOptions={microHubOptions}
          setEditedDriver={setEditedDriver}
        />
      </PortletContent>
    </Fragment>
  );
}


DriverDetailEdit.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  editedDriver: PropTypes.object.isRequired,
  microHubOptions: PropTypes.array.isRequired,
  setEditMode: PropTypes.func.isRequired,
  setEditedDriver: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateDriver: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(DriverDetailEdit);
