import React, {useState} from 'react'
import {AppBar, Tab, Tabs, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import SideNavLayout from '../../../layouts/SideNavLayout';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {PcGaertnerConfiguration, TourImport, Synchronization} from './Components';
import useShipperOptions from '../../../hooks/useShipperOptions';

const tabStates = {
  configuration: 0,
  tourImport: 1,
  synchronization: 2,  
}

function PCGaernterConfig(props) {

  const {classes, t} = props;
  const [tabState, setTabState] = useState(tabStates.configuration)
  const {shipperOptions} = useShipperOptions();
  const [selectedShipper, setSelectedShipper] = useState('');


  return (
    <SideNavLayout title={t('pcGaertnerConfig')}>
      <AppBar
        className={classes.appBar}
        color={'secondary'}
        position={'relative'}
      >
        <Tabs
          onChange={(event, newValue) => {
            setTabState(newValue);
          }}
          value={tabState}
        >
          <Tab
            label={t('pcGaertnerConfiguration')}
          />
          <Tab
            label={t('pcGaertnerTourImport')}
          />
          <Tab
            label={t('synchronization')}
          />  
        </Tabs>
      </AppBar>
      <div>
        {(() => {
          switch (tabState) {
            case tabStates.configuration:
              return (
                <PcGaertnerConfiguration 
                    selectedShipper={selectedShipper}
                    setSelectedShipper={setSelectedShipper}
                    shipperOptions={shipperOptions}
                />
              );
            case tabStates.tourImport:
              return (
                <TourImport 
                    selectedShipper={selectedShipper}
                    setSelectedShipper={setSelectedShipper}
                    shipperOptions={shipperOptions}
                />
              );
            case tabStates.synchronization:
              return (
                <Synchronization 
                    selectedShipper={selectedShipper}
                    setSelectedShipper={setSelectedShipper}
                    shipperOptions={shipperOptions}
                />
              );
            default:
              return (<div>Error</div>);
          }
        })()}
      </div>
    </SideNavLayout>
  );

}

PCGaernterConfig.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(PCGaernterConfig);
