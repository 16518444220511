import React from 'react'
import {FormControl, Input, InputLabel, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {InfoIcon, Portlet, PortletContent} from '../../../../index';


function StopBonusEdit(props) {
  const {classes, className, t, stopBonus, setStopBonus} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet className={rootClassName}>
      <PortletContent>
        <div className={classes.iconWrapper}>
          <Typography variant="h3">
            {t('boxBonus')}
          </Typography>
          <InfoIcon
            className={classes.iconMargin}
            descriptionKey={'60'}
          />
        </div>
        <hr/>
        <FormControlLabel
          control={
            <Checkbox
              checked={stopBonus.enabled}
              color="primary"
              onChange={event => {
                setStopBonus({
                  ...stopBonus,
                  enabled: event.target.checked
                });
              }}
              value={stopBonus.enabled}
            />
          }
          label={t('boxBonusEnabled')}
        /><br/>
        {stopBonus.enabled &&
        <FormControl className={classes.formControl}>
          <InputLabel
            htmlFor="bonusThreshold"
            required
          >
            {t('boxBonusThreshold')}
          </InputLabel>
          <Input
            error={isNaN(stopBonus.threshold) || parseInt(stopBonus.threshold) < 0}
            onChange={(event) => {
              setStopBonus({
                ...stopBonus,
                threshold: parseInt(event.target.value)
              });
            }}
            onClick={event => event.target.select()}
            onWheel={event => event.target.blur()}
            type="number"
            value={stopBonus.threshold}
          />
        </FormControl>}
        {stopBonus.enabled &&
        <FormControl className={classes.formControl}>
          <InputLabel
            htmlFor="bonusDivider"
            required
          >
            {t('boxBonusDivider')}
          </InputLabel>
          <Input
            error={isNaN(stopBonus.divider) || parseInt(stopBonus.divider) < 0}
            onChange={(event) => {
              setStopBonus({
                ...stopBonus,
                divider: parseInt(event.target.value)
              })
            }}
            onClick={event => event.target.select()}
            onWheel={event => event.target.blur()}
            type="number"
            value={stopBonus.divider}
          />
        </FormControl>
        }
      </PortletContent>
    </Portlet>
  );
}

StopBonusEdit.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  setStopBonus: PropTypes.func.isRequired,
  stopBonus: PropTypes.shape({enabled: PropTypes.bool.isRequired, threshold: PropTypes.number.isRequired, divider: PropTypes.number.isRequired}).isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(StopBonusEdit);
