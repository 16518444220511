export default theme => ({
  root: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  infosLeft: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  divider: {
    marginRight: '0.5em',
    marginLeft: '0.5em'
  },
  legalButtons: {
    color: 'gray'
  },
  feedbackButtons: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(4),
  },
  copyRight: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2)
  }
});
