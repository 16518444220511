import React, {Fragment, useState} from 'react'
import {Box, Collapse, IconButton, TableCell, TableRow, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {AcUnitRounded as FreezeIcon, KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon,} from '@material-ui/icons'
import PreviewStopTable from '../PreviewStopTable';
import {DateService} from '../../../../../services/util/DateService';
import {CapacityHelperService} from '../../../../../services/util/capacityHelper';
import {InfoTooltip, TourMetadataIcon} from '../../../../../components';


function PreviewTourTableRow(props) {

  const {classes, t, tourWithStops, className, freezeTour, changeStopAssignment, dataCarrierForChangeAssignment, deleteStop, microHubsData} = props;
  const [open, setOpen] = useState(false);

  const rootClassName = classNames(className, classes.root);

  return (
    <Fragment>
      <TableRow
        className={rootClassName}
        hover={tourWithStops.editable}
        selected={open}
        style={{boxShadow: `inset 10px 0px 0px 0px ${tourWithStops.color}`}}
      >
        <TableCell className={classes.alignRight}>
          <InfoTooltip descriptionKey={'70'}>
            <div>
              <IconButton
                className={tourWithStops.frozen ? classes.iconButtonActivated : classes.iconButton}
                color={tourWithStops.frozen ? 'primary' : 'default'}
                disabled={!freezeTour}
                onClick={() => freezeTour(tourWithStops.id, !tourWithStops.frozen)}
                variant="text"
              >
                <FreezeIcon/>
              </IconButton>
            </div>
          </InfoTooltip>
        </TableCell>
        <TableCell
          className={classes.alignRight}
          component="th"
          scope="row"
        >
          <TourMetadataIcon metadata={tourWithStops.tourMetaData}/>
          {tourWithStops.id}
        </TableCell>
        <TableCell className={classes.alignRight}>{DateService.displayDate(tourWithStops.deliveryDate)}</TableCell>
        <TableCell className={classes.alignRight}>{tourWithStops.stops ? tourWithStops.stops.length : 0}</TableCell>
        <TableCell className={classes.alignRight}>{CapacityHelperService.getBoxAmountOfTour(tourWithStops)}</TableCell>
        <TableCell className={classes.alignRight}>{CapacityHelperService.getWeightOfTour(tourWithStops)}</TableCell>
        <TableCell className={classes.alignRight}>{CapacityHelperService.getVolumeOfTour(tourWithStops)}</TableCell>
        <TableCell>
          <IconButton
            onClick={() => setOpen(!open)}
            size="small"
          >
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={8}
          style={{paddingBottom: 0, paddingTop: 0}}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <Box margin={1}>
              {(tourWithStops.stops && tourWithStops.stops.length > 0) ?
                <PreviewStopTable
                  changeAssignment={changeStopAssignment}
                  className={classes.previewStopTable}
                  dataCarrierForChangeAssignment={dataCarrierForChangeAssignment}
                  deleteStop={deleteStop}
                  microHubsData={microHubsData}
                  stops={tourWithStops.stops}
                />
                :
                <Typography>
                  {t('noPreviewStopsForThisPreviewTour')}
                </Typography>
              }
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

PreviewTourTableRow.propTypes = {
  changeStopAssignment: PropTypes.func,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  dataCarrierForChangeAssignment: PropTypes.object,
  deleteStop: PropTypes.func,
  freezeTour: PropTypes.func,
  t: PropTypes.func.isRequired,
  tourWithStops: PropTypes.object.isRequired,
  microHubsData: PropTypes.array.isRequired
};

export default compose(withStyles(styles), withTranslation())(PreviewTourTableRow);
