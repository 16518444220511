export default theme => ({
  dragging: {
    backgroundColor: theme.palette.primary.selected,
    padding: theme.spacing(0.5),
    border: `2px solid ${theme.palette.primary.selected}`,
    borderRadius: '5px',
    display: 'flex'
  },
  tour: {
    padding: theme.spacing(0.5),
    borderRadius: '5px',
    marginBottom: theme.spacing(0.5),
    display: 'flex'
  },
  elementSection: {
    flexBasis: '86%',
    flexShrink: 0,
    overflowX: 'hidden'
  },
  elementSectionSmall: {
    flexBasis: '7%',
    flexShrink: 0,
    overflowX: 'hidden'
  },
  stop: {
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.common.white,
    border: `2px solid ${theme.palette.border}`,
    borderRadius: '5px',
    marginBottom: theme.spacing(0.5),
    display: 'flex'
  }
});

