export default theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0.5)
  },
  rootDisabled: {
    backgroundColor: theme.palette.common.muted,
    padding: theme.spacing(0.5)
  },
  dragging: {
    backgroundColor: theme.palette.primary.selected,
  },
  stopHeader: {
    display: 'flex',
  },
  headerSection: {
    flexBasis: '16%',
    flexShrink: 0,
    overflowX: 'hidden'
  },
  headerSectionFirst: {
    flexBasis: '16%',
    flexShrink: 0,
    overflowX: 'hidden',
    paddingLeft: '20px'
  },
  lastHeaderSection: {
    flexBasis: '15%',
  },
  wrapper: {
    display: 'flex',
    margin: theme.spacing(2)
  },
  wrapperChild: {
    flex: 1,
  },
  iconButton: {
    padding: '0'
  }
});

