import React, {Fragment, useState} from 'react'
import {Button, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {StopDetails, StopTable, TourDetail, TourMapWithLegend, TourTable} from '../../../index';
import {displayModes} from '../../../../services/enums/displayModes';


function SimulationResultOverview(props) {

  const {classes, t, microHubs, displayMode, unassignedStops, tours, setActiveStep} = props;

  const [selectedUnassignedStops, setSelectedUnassignedStop] = useState(null);
  const [selectedTour, setSelectedTour] = useState(null);

  return (
    <Fragment>
      <TourTable
        className={classes.content}
        selectedTour={selectedTour}
        showDetails={(tour) => setSelectedTour(tour)}
        tableDisplayMode={displayMode}
        tours={tours}
      />
      {selectedTour &&
      <TourDetail
        className={classes.content}
        closeTourDetail={() => setSelectedTour(null)}
        displayMode={displayMode}
        readOnly
        tour={selectedTour}
      />
      }
      {(unassignedStops && unassignedStops.length > 0) &&
      <Fragment>
        <Typography variant={'h5'}>
          {t('notPlanedStops')}
        </Typography>
        <StopTable
          className={classes.content}
          selectedStop={selectedUnassignedStops}
          showDetails={(stop) => setSelectedUnassignedStop(stop)}
          stops={unassignedStops}
          tableDisplayMode={displayMode}
        />
        {selectedUnassignedStops &&
        <StopDetails
          className={classes.content}
          closeDetailView={() => setSelectedUnassignedStop(null)}
          editDisplayMode={displayMode}
          readOnly
          stop={selectedUnassignedStops}
        />
        }
      </Fragment>
      }
      <TourMapWithLegend
        className={classes.tourMap}
        microHubs={microHubs ? microHubs : []}
        notPlanedStops={unassignedStops}
        tours={tours}
      />
      <div className={classes.marginTop}>
        <Button
          className={classes.navButton}
          color="default"
          onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
        >
          {t('back')}
        </Button>
        <Button
          className={classes.navButton}
          color="primary"
          onClick={() => window.location.reload()}
          title={t('startNewSimulationInfo')}
          variant="contained"
        >
          {t('startNewSimulation')}
        </Button>
      </div>
    </Fragment>
  );
}

SimulationResultOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  microHubs: PropTypes.array,
  setActiveStep: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  tours: PropTypes.array.isRequired,
  unassignedStops: PropTypes.array.isRequired,
};


export default compose(withStyles(styles), withTranslation())(SimulationResultOverview);
