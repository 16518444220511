import React, {useCallback, useContext, useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import SideNavLayout from '../../../layouts/SideNavLayout';
import {useQuery} from '@apollo/react-hooks';
import {PreviewToursService} from '../../../services/backend/previewToursService';
import {CountDownTimer, GeneralInfoIcon, LoadingButton, LoadingWrapper, PreviewToursFilter, PreviewToursView, RefreshButton} from '../../../components';
import {AuthService} from '../../../services/auth/authService';
import {ClientService} from '../../../services/client/clientService';
import {clientTypes} from '../../../services/client/clientTypes';
import FileSaver from 'file-saver';
import {DateService} from '../../../services/util/DateService';
import {MicroHubQueryService} from '../../../services/backend/microHubQueryService';
import {TourService} from '../../../services/backend/tourService';
import moment from 'moment';
import {getDayOfWeekFromDate} from '../../../services/enums/dayOfWeekHelper';
import {usePreviewStopHub} from '../../../hooks/usePreviewStopHub';
import {previewStopMessageRelevantForFilter, previewTourMessageRelevantForFilter} from '../../../services/util/signalRMessageHelper';
import {usePreviewTourHub} from '../../../hooks/usePreviewTourHub';
import {GetApp as DownloadIcon} from '@material-ui/icons';
import useCarriersWithHubs from '../../../hooks/useCarriersWithHubs';
import {DatePickerDefaultValueContext} from '../../../context';
import useMicroHubData from '../../../hooks/useMicroHubData';

const clientMicroHub = ClientService.getClient(clientTypes.microHub);

function PreviewToursShipper(props) {
  const {classes, t} = props;
  const {fromDateDefault, updateDefaultDate} = useContext(DatePickerDefaultValueContext);

  const [previewDeadline, setPreviewDeadline] = useState(null);
  const [finalDeadline, setFinalDeadline] = useState(null);
  const [previewData, setPreviewData] = useState([]);
  const [unassignedStops, setUnassignedStops] = useState([])
  const [filter, setFilter] = useState({
    carrierName: '',
    microHubList: [],
    date: fromDateDefault,
    frozen: '',
    shipperName: AuthService.getUserOrganization(),
  });
  const [mapTabState, setMapTabState] = useState(undefined);
  useEffect(() => updateDefaultDate(filter.date), [filter.date, updateDefaultDate]);
  const [backendLoading, setBackendLoading] = useState(false);
  const [microHubPlansForDay, setMicroHubPlansForDay] = useState([]);
  const [backendLoadingExcel, setBackendLoadingExcel] = useState(false);
  const {carriersWithHubs} = useCarriersWithHubs();
  const {microHubData} = useMicroHubData();

  const queryMicroHubPlans = MicroHubQueryService.getMicroHubMaxVolume('', '');
  const {data: dataMicroHubPlans} = useQuery(queryMicroHubPlans, {client: clientMicroHub});

  const [refresh, setRefresh] = useState(false);
  usePreviewStopHub(useCallback(event => {
    if (previewStopMessageRelevantForFilter(event, filter)) {
      setRefresh(true);
    }
  }, [filter]));
  usePreviewTourHub(useCallback(event => {
    if (previewTourMessageRelevantForFilter(event, filter)) {
      setRefresh(true);
    }
  }, [filter]));


  const downloadMhPlanAsExcel = () => {
    const microHubs = previewData ? previewData.map(hub => hub.microHubName) : [];
    setBackendLoadingExcel(true);
    if (microHubs.length !== 0) {
      TourService.downloadMhPlanExcel(AuthService.getUserOrganization(), microHubs, filter.date)
        .then(response => {
          setBackendLoadingExcel(false);
          if (response.status === 204) {
            alert(`${t('noFinalStopDataAvailable')}: ${DateService.displayDate(filter.date)}`);
          } else {
            let filename = response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0];

            response.blob().then(blob =>
              FileSaver.saveAs(blob, filename)
            );
          }
        }, () => {
          alert(t('errorDownloadingExcel'))
          setBackendLoadingExcel(false);
        });
    } else {
      alert(`${t('noPreviewDataAvailable')}: ${DateService.displayDate(filter.date)}`);
    }
  };

  /*Remove this until the order of the stops matches the order calculated at the shipperAPI*/
  // const downloadStopsAsExcel = () => {
  //   const microHubs = previewData ? previewData.map(hub => hub.microHubName) : [];
  //   const stopsWithoutMH = unassignedStops ? unassignedStops.map(stop => stop.id) : [];
  //
  //   if (microHubs.length !== 0 || stopsWithoutMH.length !== 0) {
  //     setBackendLoadingExcel(true);
  //     PreviewToursService.downloadPreviewAsExcel(AuthService.getUserOrganization(), microHubs, stopsWithoutMH, filter.date)
  //       .then(response => {
  //         setBackendLoadingExcel(false);
  //         let filename = response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0];
  //
  //         response.blob().then(blob =>
  //           FileSaver.saveAs(blob, filename)
  //         );
  //       }, () => {
  //         alert(t('errorDownloadingExcel'))
  //         setBackendLoadingExcel(false);
  //       });
  //   } else {
  //     alert(`${t('noPreviewDataAvailable')}: ${DateService.displayDate(filter.date)}`);
  //   }
  // };

  const deleteStop = (stopId, carrierName, microHubName) => {
    PreviewToursService.deleteStopById(stopId).then(response => {
      if (response.ok) {
        if (carrierName && microHubName) {
          PreviewToursService.rerunAlgoForHub(filter.date, carrierName, microHubName).then(response => response.ok ? loadData() : alert(t('errorRerunningAlgo')))
        } else {
          loadData();
        }
      } else {
        alert(t('errorDeletingStop'))
      }
    })
  };

  const loadData = useCallback(async () => {
    PreviewToursService.getDeadlineForPreview(AuthService.getUserOrganization(), filter.date)
      .then(response => response.json())
      .then(response => setPreviewDeadline(new Date(response)));

    TourService.getDeadlineForFinal(AuthService.getUserOrganization(), filter.date)
      .then(response => response.json())
      .then(response => new Date(response))
      .then(response => setFinalDeadline(moment(response).add(15, 'minutes').toDate()));

    if (!(filter.date instanceof Date && !isNaN(filter.date))) {
      return;
    }

    try {
      setBackendLoading(true);
      const response = await PreviewToursService.getMicroHubsWithToursByFilter(filter.carrierName, filter.shipperName, filter.microHubList, filter.date, filter.frozen === '' ? null : filter.frozen)
      setPreviewData(response);
      setBackendLoading(false);
      setRefresh(false);
    } catch (e) {
      alert(`${t('errorLoadingPreviewTours')}: ${e}`);
      setBackendLoading(false);
      setRefresh(false);
    }
    const unassignedStops = await PreviewToursService.getUnassignedStopsByFilter(null, filter.shipperName, filter.date);
    setUnassignedStops(unassignedStops)
  }, [filter, t]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (dataMicroHubPlans && dataMicroHubPlans.multipleHubsAndCarrierPlans) {
      const hubPlansForCarrierAndHub = JSON.parse(JSON.stringify(dataMicroHubPlans.multipleHubsAndCarrierPlans));
      hubPlansForCarrierAndHub.forEach((hubPlanForCarrierAndHub) => {
        hubPlanForCarrierAndHub.hubPlans = hubPlanForCarrierAndHub.hubPlans.filter(plan => plan.dayOfWeek === getDayOfWeekFromDate(filter.date))
      });
      setMicroHubPlansForDay(hubPlansForCarrierAndHub);
    }
  }, [dataMicroHubPlans, filter]);

  return (
    <SideNavLayout title={t('previewTours')}>
      <div className={classes.root}>
        <div className={classes.helpWrapper}>
          <RefreshButton
            className={classes.refreshButton}
            refresh={refresh}
            refreshFunc={loadData}
          />
          <GeneralInfoIcon/>
        </div>
        <div className={classes.verticalContainer}>
          <div>
            {/*Remove this until the order of the stops matches the order calculated at the shipperAPI*/}
            {/*<LoadingButton*/}
            {/*  className={classes.downloadButtonPreview}*/}
            {/*  color="primary"*/}
            {/*  disabled={previewDeadline > new Date()}*/}
            {/*  loading={backendLoadingExcel}*/}
            {/*  onClick={downloadStopsAsExcel}*/}
            {/*  variant="contained"*/}
            {/*>*/}
            {/*  <DownloadIcon/>&nbsp;{t('previewToursDownload')}*/}
            {/*</LoadingButton>*/}
          </div>
          <div>
            <CountDownTimer
              className={classes.timer}
              deadlineDate={previewDeadline}
            />
          </div>
          <div>
            <LoadingButton
              className={classes.downloadButtonMhLocationMap}
              color="primary"
              disabled={finalDeadline > new Date()}
              loading={backendLoadingExcel}
              onClick={downloadMhPlanAsExcel}
              variant="contained"
            >
              <DownloadIcon/>&nbsp;{t('microHubLocationMapDownload')}
            </LoadingButton>
          </div>
          <div>
            <CountDownTimer
              className={classes.timer}
              deadlineDate={finalDeadline}
            />
          </div>
        </div>
        <PreviewToursFilter
          carriersWithMicroHubs={carriersWithHubs}
          filter={filter}
          setFilter={setFilter}
        />
        <LoadingWrapper loading={backendLoading}>
          <PreviewToursView
            deleteStop={deleteStop}
            hubsWithToursAndStops={previewData}
            mapTabState={mapTabState}
            microHubPlanData={microHubPlansForDay ? microHubPlansForDay : []}
            microHubs={microHubData}
            setMapTabState={setMapTabState}
            unassignedStops={unassignedStops ? unassignedStops : []}
          />
        </LoadingWrapper>
      </div>
    </SideNavLayout>
  );
}


PreviewToursShipper.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(PreviewToursShipper);
