import React, {Fragment, useState} from 'react'
import {Box, Collapse, IconButton, TableCell, TableRow, Tooltip, Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import classNames from 'classnames';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon, WarningRounded as WarnIcon} from '@material-ui/icons';
import {DateService} from '../../../../../../../../services/util/DateService';
import {roundCapacityValues} from '../../../../../../../../services/util/helperFuncs';
import palette from '../../../../../../../../theme/palette';
import {ShipperAllowedOnHubService} from '../../../../../../../../services/util/shipperAllowedOnHubHelper';
import StopTableDetails from '../../../../../../StopTableDetails';
import {PreviewStopIdLabel, ShipperLabel} from '../../../../../../../index';

function StopAddTableRow(props) {

  const {classes, className, t, dialogState, stop, microHubs, addOrRemoveStop, stopsToAdd} = props;
  const [open, setOpen] = useState(false);

  const rootClassName = classNames(className, classes.root);

  const stopIsSelected = stopsToAdd.some(presentStop => presentStop.id === stop.id);

  return (
    <Fragment>
      <TableRow
        className={classNames(rootClassName, stop.editable ? 'cursorPointer' : '', stopIsSelected ? classes.selected : '')}
        hover={!stopsToAdd.some(presentStop => presentStop.id === stop.id) && stop.editable}
        onClick={() => addOrRemoveStop(stop)}
        style={stop.editable || stopIsSelected ? {} : {backgroundColor: palette.common.muted}}
      >
        <TableCell className={classes.tableCell}>
          <Typography
            className={classes.alignText}
            variant={'body2'}
          >
            <PreviewStopIdLabel stop={stop}/>
          </Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
          {(dialogState.tour.stops.some(s => s.shipperName !== stop.shipperName) ||
            !ShipperAllowedOnHubService.checkShipperAllowedStop(microHubs.find(hub => hub.name === dialogState.tour.microHubName), stop)) &&
          <Tooltip
            title={
              dialogState.tour.stops.some(s => s.shipperName !== stop.shipperName) ? t('noShipperMixingInPreviewToursService') +
                !ShipperAllowedOnHubService.checkShipperAllowedStop(microHubs.find(hub => hub.name === dialogState.tour.microHubName), stop) ?
                ' & ' + t('shipperNotAllowedStop') : ''
                : t('shipperNotAllowedStop')}
          >
            <WarnIcon className={classes.dangerColor}/>
          </Tooltip>
          }
          &nbsp;<ShipperLabel stop={stop}/>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography
            className={classes.alignText}
            variant={'body2'}
          >
            {dialogState.tour.microHubName !== stop.assignedMicroHubName &&
            <Tooltip title={t('microHubsDoNotMatch')}>
              <WarnIcon className={classes.warningColor}/>
            </Tooltip>
            }
            &nbsp;{stop.assignedMicroHubName}
          </Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
          {DateService.displayDate(stop.deliveryDate)}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {stop.address.streetAndNumber}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {roundCapacityValues(stop.stopCapacities.boxAmount)}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {roundCapacityValues(stop.stopCapacities.weight)}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {roundCapacityValues(stop.stopCapacities.volume)}
          <IconButton
            className={classes.buttonRight}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(!open)
            }}
            size="small"
          >
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          className={classes.largeTableCell}
          colSpan={9}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <Box margin={1}>
              <StopTableDetails
                stop={stop}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

StopAddTableRow.propTypes = {
  addOrRemoveStop: PropTypes.func.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  dialogState: PropTypes.object.isRequired,
  microHubs: PropTypes.array.isRequired,
  stop: PropTypes.object.isRequired,
  stopsToAdd: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(StopAddTableRow);
