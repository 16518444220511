import React, {Fragment} from 'react'
import {Table, TableBody, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TourStopTableRowSmall from './TourStopTableRowSmall';
import {Portlet, PortletContent, TableCellWithConfig, TableSettings} from '../../../../../../components';
import {Droppable} from 'react-beautiful-dnd';
import {DndHelperElementTypes, DndHelperService} from '../../../../../../services/dndHelper/dndHelperService';
import Columns from './columns';
import DefaultConfig from './config';
import CapacityModeDefaultConfig from './capacityModeConfig';
import useTableConfig from '../../../../../../hooks/useTableConfig';
import {getTableKey} from '../../../../../../services/util/tableConfigHelper';
import {TableKind} from '../../../../../../services/enums/TableKind';
import {displayModes} from '../../../../../../services/enums/displayModes';


function TourStopTableSmall(props) {

  const {classes, t, tour, className, deleteStopFromTour, capacityMode} = props;
  const rootClassName = classNames(className, classes.root);
  const uniqueTourId = DndHelperService.getUniqueTourId('{}', tour.tourId);

  const {
    config,
    columnConfigs,
    updateConfig
  } = useTableConfig(DefaultConfig, displayModes.carrier, getTableKey(TableKind.TourStopTableSmall, displayModes.carrier))

  const {
    config: configCapacityMode,
    columnConfigs: columnConfigsCapacityMode,
    updateConfig: updateConfigCapacityMode
  } = useTableConfig(CapacityModeDefaultConfig, displayModes.carrier, getTableKey(TableKind.TourStopTableSmall, displayModes.carrier, 'capacity_mode'))

  const currentColumnConfig = capacityMode ? columnConfigsCapacityMode : columnConfigs;

  return (
    <Fragment>
      <TableSettings
        updateConfig={capacityMode ? updateConfigCapacityMode : updateConfig}
        tableConfig={capacityMode ? configCapacityMode : config}
        columns={Columns}
        className={classes.tableSettings}
        buttonOptic
        useNamesAsTranslateString
      />
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <div style={{overflow: 'auto', maxHeight: '326px'}}>
            <Table
              size="small"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {currentColumnConfig.map(columnConfig => (
                    <TableCellWithConfig
                      key={columnConfig.order}
                      config={columnConfig}
                      boxShadowColor={columnConfig.order === 1 ? tour.color : null}
                      className={classNames(classes.tableHeader, {
                        [classes.tableHeaderSmall]: columnConfig.columnName === Columns.boxAmount,
                        [classes.tableHeaderSmall]: columnConfig.columnName === Columns.weight,
                        [classes.tableHeaderSmall]: columnConfig.columnName === Columns.volume,
                      })}
                    >
                      {Columns[columnConfig.columnName].header({t})}
                    </TableCellWithConfig>
                  ))}
                </TableRow>
              </TableHead>
              <Droppable
                droppableId={uniqueTourId}
                type={DndHelperElementTypes.Tour}
              >
                {(provided) => (
                  <TableBody
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {tour.stops.map((stop, index) => {
                      return (
                        <TourStopTableRowSmall
                          columnConfigs={currentColumnConfig}
                          deleteStopFromTour={deleteStopFromTour}
                          index={index}
                          key={stop.tourStopId}
                          stop={stop}
                          uniqueTourId={uniqueTourId}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </Table>
          </div>
        </PortletContent>
      </Portlet>
    </Fragment>
  );
}

TourStopTableSmall.propTypes = {
  capacityMode: PropTypes.bool.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deleteStopFromTour: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  tour: PropTypes.object.isRequired
};

export default compose(withStyles(styles), withTranslation())(TourStopTableSmall);
