const searchCustomers = (customers, searchInput) => {
  if (!customers || customers.length <= 0) {
    return [];
  }

  if (!searchInput) {
    return customers;
  }

  return customers.filter(
    (customer) => {
      return customer.id.toString().includes(searchInput.toLowerCase()) ||
        (customer.externalCustomerId.toString().includes(searchInput.toLowerCase())) ||
        (customer.firstName ? customer.firstName.toLowerCase().includes(searchInput.toLowerCase()) : false) ||
        (customer.lastName ? customer.lastName.toLowerCase().includes(searchInput.toLowerCase()) : false) ||
        (customer.address?.streetAndNumber ? customer.address.streetAndNumber.toLowerCase().includes(searchInput.toLowerCase()) : false) ||
        (customer.address?.zipcode ? customer.address.zipcode.toLowerCase().includes(searchInput.toLowerCase()) : false)
    }
  );
};

export default {searchCustomers}
