import React from 'react'
import {FormControl, Input, InputLabel, MenuItem, Select, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {TourStatus} from '../../../services/enums/TourStatus';
import {CarrierAndMicroHubDropdownSelect, DateFilter, DropdownSelectAutocomplete, DropdownSelectSimple, InfoTooltip} from '../../../components';

function MicroHubPlanAndTourFilter(props) {

  const {
    classes,
    className,
    t,
    filter,
    setFilter,
    carrierWithMicroHubs,
    microHubOptions,
    disabled,
    shipperOptions,
    toursWithStop,
    otherStops,
    search,
    setSearch
  } = props;

  let tourIdsInternal = [];
  let stopIdsInternal = [];

  if (toursWithStop && toursWithStop.length > 0) {
    // Apply status filter to tours, so only tours with the correct status are a select option
    toursWithStop.filter(tour => !filter.tourStatus || tour.tourStatus === filter.tourStatus).forEach(tour => {
      let stopsToAdd = [];
      // Only stops of the selected tour (if any) should be a select option
      if (!search.tourId || parseInt(search.tourId) === tour.tourId) {
        // Filter stops for shipperName from filter, so only stops with the correct shipper are a select option
        stopsToAdd = tour.stops.filter(stop => !filter.shipperName || stop.shipperName === filter.shipperName).map(stop => stop.tourStopId.toString());
        stopIdsInternal = stopIdsInternal.concat(stopsToAdd);
      }
      // Only tours with at least 1 stop should be a select option
      if (stopsToAdd.length > 0) {
        tourIdsInternal.push(tour.tourId.toString());
      }
    })
  }

  if (!search.tourId) {
    stopIdsInternal = stopIdsInternal.concat(otherStops.map(stop => stop.tourStopId.toString()));
  }

  tourIdsInternal.sort();
  stopIdsInternal.sort();

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <form className={classes.rowWrapper}>
        <DateFilter
          descriptionKey={'10'}
          error={!filter.fromDateTime}
          required
          setValue={(value) => {
            setFilter({
              ...filter,
              fromDateTime: value,
              toDateTime: new Date(value.getFullYear(), value.getMonth(), value.getDate(), filter.toDateTime.getHours(), filter.toDateTime.getMinutes())
            });
            setSearch({...search, tourId: null, stopId: null});
          }}
          value={filter.fromDateTime}
        />
        <CarrierAndMicroHubDropdownSelect
          carrierDescriptionKey={'20'}
          carrierRequired
          carriersWithMicroHubs={carrierWithMicroHubs}
          carrierValue={filter.carrierName}
          className={classes.rowWrapper}
          disabled={disabled}
          errorCarrier={!filter.carrierName}
          errorMicroHub={!filter.microHubName}
          microHubDescriptionKey={'30'}
          microHubOptions={microHubOptions}
          microHubRequired
          microHubValue={filter.microHubName}
          setCarrierValue={(value) => {
            setFilter({...filter, carrierName: value, microHubName: ''});
            setSearch({...search, tourId: null, stopId: null});
          }}
          setMicroHubValue={(value) => {
            setFilter({...filter, microHubName: value});
            setSearch({...search, tourId: null, stopId: null});
          }}
        />
        <DropdownSelectSimple
          descriptionKey={'40'}
          disabled={disabled}
          hideOnEmptyOptions
          name={t('shipper')}
          options={shipperOptions}
          setValue={(value) => {
            setFilter({...filter, shipperName: value});
            setSearch({...search, tourId: null, stopId: null});
          }}
          sort
          value={filter.shipperName}
        />
        <InfoTooltip descriptionKey={'50'}>
          <FormControl className={classes.formControl}>
            <InputLabel id="microHubPlanStorageState">{t('microHubPlanStorageState')}</InputLabel>
            <Select
              disabled={disabled}
              input={<Input id="microHubPlanStorageState"/>}
              onChange={(event) => {
                setFilter({...filter, tourStatus: event.target.value > 0 ? event.target.value : ''});
                setSearch({...search, tourId: null, stopId: null});
              }}
              value={filter.tourStatus ? filter.tourStatus : -1}
            >
              <MenuItem
                value={-1}
              >
                {t('microHubPlanStorageStateDayOverview')}
              </MenuItem>
              <MenuItem
                value={TourStatus.Planed}
              >
                {t('microHubPlanStorageStateActual')}
              </MenuItem>
            </Select>
          </FormControl>
        </InfoTooltip>
        <DropdownSelectAutocomplete
          className={classes.formControlAutoComplete}
          descriptionKey={'60'}
          name={t('tourId')}
          options={tourIdsInternal}
          setValue={value => setSearch({...search, tourId: value, stopId: null})}
          value={search.tourId}
        />
        <DropdownSelectAutocomplete
          className={classes.formControlAutoComplete}
          descriptionKey={'70'}
          name={t('tourStopId')}
          options={stopIdsInternal}
          setValue={value => setSearch({...search, stopId: value})}
          value={search.stopId}
        />
      </form>
    </div>
  );
}


MicroHubPlanAndTourFilter.propTypes = {
  carrierWithMicroHubs: PropTypes.object,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  filter: PropTypes.object.isRequired,
  microHubOptions: PropTypes.array,
  otherStops: PropTypes.array.isRequired,
  search: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
  shipperOptions: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  toursWithStop: PropTypes.array.isRequired,

};

export default compose(withStyles(styles), withTranslation())(MicroHubPlanAndTourFilter);
