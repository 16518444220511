import React from 'react'
import {FormControl, InputAdornment, TextField, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {InfoTooltip} from '../../index';
import {Search} from '@material-ui/icons';


function SearchInput(props) {

  const {classes, className, t, value, setValue, descriptionKey, placeHolder, icon} = props;

  const rootClassName = classNames(classes.formControl, className);

  return (
    <InfoTooltip descriptionKey={descriptionKey}>
      <FormControl className={rootClassName}>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {icon ? icon : <Search/>}
              </InputAdornment>
            ),
            classes: {input: classes.searchInputPlaceholder}
          }}
          onChange={(event) => setValue(event.target.value)}
          placeholder={placeHolder ? placeHolder : t('search')}
          value={value}
        />
      </FormControl>
    </InfoTooltip>
  );
}

SearchInput.defaultProps = {
  descriptionKey: ''
};


SearchInput.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  descriptionKey: PropTypes.string,
  icon: PropTypes.node,
  placeHolder: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default compose(withStyles(styles), withTranslation())(SearchInput);
