export default theme => ({
  root: {},
  button: {
    marginLeft: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100px',
  },
  wrapper: {
    display: 'flex',
    margin: theme.spacing(2)
  },
  wrapperChild: {
    flex: 1,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
});
