import React from 'react'
import {Button, Grid, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {InfoIcon, Portlet, PortletContent} from '../../../../index';
import Typography from '@material-ui/core/Typography';
import {Edit as EditIcon,} from '@material-ui/icons';
import moment from 'moment';
import {Address, Person} from '../../../Shared';
import {TrackingLinkInfoView, TrackingNotificationsView} from '../../index';
import StopBonusView from '../../StopBonus/View';

function ViewShipper(props) {
  const {classes, t, shipper, onEditClick, readonly} = props;

  const previewDeadlineTime = moment().startOf('day').seconds(shipper.previewDeadline - (new Date().getTimezoneOffset() * 60));
  const finalDeadlineTime = moment().startOf('day').seconds(shipper.finalDeadline - (new Date().getTimezoneOffset() * 60));
  const emptiesDeadlineTime = moment().startOf('day').seconds(shipper.emptiesDeadline - (new Date().getTimezoneOffset() * 60));

  return (
    <Grid
      container
      spacing={4}
    >
      <Grid
        item
        xs={12}
      >
        {!readonly &&
        <Button
          className={classes.button}
          color="primary"
          onClick={onEditClick}
          title={t('editShipper')}
          variant="contained"
        >
          <EditIcon/>
        </Button>
        }
      </Grid>
      <Grid
        item
        lg={3}
        sm={6}
        xl={3}
        xs={12}
      >
        <Portlet className={classes.content}>
          <PortletContent>
            <Typography variant="h3">
              {t('info')}
            </Typography>
            <hr/>
            <Typography variant="h4">
              {t('empties')}
            </Typography>
            <Typography variant="body1">
              {shipper.empties.sort(function (a, b) {
                return a.order - b.order
              }).map(e => e.name).join(', ')}
            </Typography>
            <Typography variant="h4">
              {t('signature')}
            </Typography>
            <Typography variant="body1">
              {shipper.signature ? t('yes') : t('no')}
            </Typography>
            <Typography variant="h4">
              {t('showEmergencyContact')}
            </Typography>
            <Typography variant="body1">
              {shipper.showEmergencyContact ? t('yes') : t('no')}
            </Typography>
          </PortletContent>
        </Portlet>
      </Grid>
      <Grid
        item
        lg={3}
        sm={6}
        xl={3}
        xs={12}
      >
        <Address
          address={shipper.address}
          className={classes.content}
          title={t('address')}
        />
      </Grid>
      <Grid
        item
        lg={3}
        sm={6}
        xl={3}
        xs={12}
      >
        <Address
          address={shipper.billingAddress}
          className={classes.content}
          title={t('billingAddress')}
        />
      </Grid>
      <Grid
        item
        lg={3}
        sm={6}
        xl={3}
        xs={12}
      >
        <Person
          className={classes.content}
          person={shipper.contact}
          title={t('bossContact')}
        />
      </Grid>
      <Grid
        item
        lg={3}
        sm={6}
        xl={3}
        xs={12}
      >
        <Person
          className={classes.content}
          person={shipper.emergencyContact}
          title={t('emergencyContact')}
        />
      </Grid>
      <Grid
        item
        lg={3}
        sm={6}
        xl={3}
        xs={12}
      >
        <Person
          className={classes.content}
          person={shipper.serviceContact}
          title={t('serviceContact')}
        />
      </Grid>
      <Grid
        item
        lg={3}
        sm={6}
        xl={3}
        xs={12}
      >
        <Person
          className={classes.content}
          person={shipper.disponent}
          title={t('disponent')}
        />
      </Grid>
      <Grid
        item
        lg={3}
        sm={6}
        xl={3}
        xs={12}
      >
        <Portlet className={classes.content}>
          <PortletContent>
            <div className={classes.iconWrapper}>
              <Typography variant="h3">
                {t('deadlines')}
              </Typography>
              <InfoIcon
                className={classes.iconMargin}
                descriptionKey={'32'}
              />
            </div>
            <hr/>
            <div className={classes.iconWrapper}>
              <Typography variant="h4">
                {t('previewDeadline')}
              </Typography>
              <InfoIcon
                className={classes.iconMargin}
                descriptionKey={'33'}
              />
            </div>
            <Typography variant="body1">
              {`${((shipper.previewDeadline - (new Date().getTimezoneOffset() * 60)) / (60 * 60)).toLocaleString()}h 
              (${t('pointInTime')}: ${(previewDeadlineTime.clone().startOf('day').diff(moment().startOf('day'), 'days') < 0 ?
                't' : 't+') + previewDeadlineTime.clone().startOf('day').diff(moment().startOf('day'), 'days')} 
              ${previewDeadlineTime.format('HH:mm')})`}
            </Typography>
            <div className={classes.iconWrapper}>
              <Typography variant="h4">
                {t('finalDeadline')}
              </Typography>
              <InfoIcon
                className={classes.iconMargin}
                descriptionKey={'34'}
              />
            </div>
            <Typography variant="body1">
              {`${((shipper.finalDeadline - (new Date().getTimezoneOffset() * 60)) / (60 * 60)).toLocaleString()}h 
              (${t('pointInTime')}: ${(finalDeadlineTime.clone().startOf('day').diff(moment().startOf('day'), 'days') < 0 ?
                't' : 't+') + finalDeadlineTime.clone().startOf('day').diff(moment().startOf('day'), 'days')} 
              ${finalDeadlineTime.format('HH:mm')})`}
            </Typography>
            <div className={classes.iconWrapper}>
              <Typography variant="h4">
                {t('emptiesDeadline')}
              </Typography>
              <InfoIcon
                className={classes.iconMargin}
                descriptionKey={'35'}
              />
            </div>
            <Typography variant="body1">
              {`${((shipper.emptiesDeadline - (new Date().getTimezoneOffset() * 60)) / (60 * 60)).toLocaleString()}h 
              (${t('pointInTime')}: ${(emptiesDeadlineTime.clone().startOf('day').diff(moment().startOf('day'), 'days') < 0 ?
                't' : 't+') + emptiesDeadlineTime.clone().startOf('day').diff(moment().startOf('day'), 'days')} 
              ${emptiesDeadlineTime.format('HH:mm')})`}
            </Typography>
          </PortletContent>
        </Portlet>
      </Grid>
      <Grid
        item
        lg={3}
        sm={6}
        xl={3}
        xs={12}
      >
        <TrackingNotificationsView
          className={classes.content}
          trackingNotifications={shipper.trackingNotifications}
        />
      </Grid>
      <Grid
        item
        lg={3}
        sm={6}
        xl={3}
        xs={12}
      >
        <TrackingLinkInfoView
          className={classes.content}
          trackingLinkInfos={shipper.trackingLinkInfos}
        />

      </Grid>
      <Grid
        item
        lg={3}
        sm={6}
        xl={3}
        xs={12}
      >
        <StopBonusView
          className={classes.content}
          stopBonus={shipper.stopBonus}
        />
      </Grid>
    </Grid>
  );
}


ViewShipper.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
  shipper: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(ViewShipper);
