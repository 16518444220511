import React from 'react';
import PropTypes from 'prop-types';
import {Button, CircularProgress} from '@material-ui/core';

const LoadingButton = (props) => {
  const {loading, children, disabled, color, ...rest} = props;

  return (
    <Button
      color={color}
      disabled={disabled || loading}
      {...rest}
    >
      {loading
        ?
        <CircularProgress
          color={color}
          size={25}
        />
        :
        children
      }
    </Button>
  )
};

LoadingButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
};

export default LoadingButton;
