import React from 'react'
import {Table, TableBody, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {
  Pagination,
  Portlet,
  PortletContent,
  TableCellWithConfig,
  TableSettings,
  TextPlaceholderWrapper
} from '../../../components'
import classNames from 'classnames';
import useTablePagination from '../../../hooks/useTablePagination';
import {displayModes} from '../../../services/enums/displayModes';
import useTableConfig from '../../../hooks/useTableConfig';
import DefaultConfig from './config';
import {getTableKey} from '../../../services/util/tableConfigHelper';
import {TableKind} from '../../../services/enums/TableKind';
import Columns from './columns';

function CustomerTable(props) {

  const {classes, t, onSelectCustomer, customers, className, onDeleteCustomer, selectedCustomer, displayMode} = props;

  const {paginationProps, pagedObjects} = useTablePagination(customers);

  const {
    config,
    columnConfigs,
    updateConfig
  } = useTableConfig(
    DefaultConfig,
    displayMode,
    getTableKey(TableKind.CustomerTable, displayMode),
    {
      // Only show options column, if one of the options functions is present
      [Columns.delete.name]: Boolean(onDeleteCustomer)
    },
  )

  const deleteCustomer = (event, customerId) => {
    event.stopPropagation();
    if (window.confirm(t('confirmDeleteCustomer'))) {
      onDeleteCustomer(customerId);
    }
  };

  const rootClassName = classNames(classes.root, className);


  return (
    <TextPlaceholderWrapper
      active={!customers?.length}
      text={t('noCustomersAvailable')}
    >
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <TableSettings
            updateConfig={updateConfig}
            tableConfig={config}
            columns={Columns}
          />
          <Table size="medium">
            <TableHead>
              <TableRow>
                {columnConfigs.map(columnConfig => (
                  <TableCellWithConfig
                    key={columnConfig.order}
                    config={columnConfig}
                  >
                    {Columns[columnConfig.columnName].header({t})}
                  </TableCellWithConfig>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedObjects.map((customer, index) => (
                <TableRow
                  className={'cursorPointer'}
                  hover
                  key={index}
                  onClick={() => onSelectCustomer(customer)}
                  selected={selectedCustomer && selectedCustomer.id === customer.id}
                >
                  {columnConfigs.map(columnConfig => (
                    <TableCellWithConfig
                      key={columnConfig.order}
                      config={columnConfig}
                    >
                      {Columns[columnConfig.columnName].body({
                        customer, classes, deleteCustomer, t
                      })}
                    </TableCellWithConfig>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination paginationProps={paginationProps}/>
        </PortletContent>
      </Portlet>
    </TextPlaceholderWrapper>
  );
}


CustomerTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  customers: PropTypes.array.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  onDeleteCustomer: PropTypes.func,
  onSelectCustomer: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.object,
  t: PropTypes.func.isRequired
}
;

export default compose(withStyles(styles), withTranslation())(CustomerTable);
