import {Tooltip, withStyles} from '@material-ui/core';
import React, {Fragment} from 'react';
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import {compose} from 'recompose';
import styles from './styles';

const TourIdLabel = (props) => {

  const {tour, t, classes} = props;

  // this is required, so nobody clicks on the tmp tourIds on the masterDisposition
  if (tour?.tourId < 0) {
    return (
      <Fragment>
        {
          tour.tourName ?
            <Tooltip title={t('tourId') + ': ' + tour.tourId}>
              <div>{tour.tourName ? tour.tourName : tour.tourId}</div>
            </Tooltip>
            :
            <div>{tour?.tourId || ''}</div>
        }
      </Fragment>
    )
  }

  if (tour?.tourName) {
    return (
      <Tooltip title={t('tourId') + ': ' + tour.tourId}>
        <NavLink
          className={classes.root}
          onClick={e => e.stopPropagation()}
          to={`/tour-detail/${tour.tourId}`}
        >
          {tour.tourName ? tour.tourName : tour.tourId}
        </NavLink>
      </Tooltip>
    );
  }

  if (!tour?.tourId) {
    return <Fragment/>
  }

  return (
    <NavLink
      className={classes.root}
      onClick={e => e.stopPropagation()}
      to={`/tour-detail/${tour.tourId}`}
    >
      {tour.tourId || ''}
    </NavLink>
  );
}

TourIdLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  tour: PropTypes.shape({tourName: PropTypes.string, tourId: PropTypes.number}),
}

export default compose(withStyles(styles), withTranslation())(TourIdLabel);
