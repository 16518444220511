import React from 'react'
import {Container, Typography, useMediaQuery, useTheme, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Header(props) {

  const {classes, className, title} = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <Container
        disableGutters={matches}
        maxWidth="md"
      >
        <Typography variant={'h1'}>{title}</Typography>
      </Container>
    </div>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  title: PropTypes.string.isRequired,
};


export default withStyles(styles)(Header);
