import React from 'react'
import {Tooltip, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {WarningRounded as WarnIcon} from '@material-ui/icons';

function NoCapacitiesAvailableWarning(props) {

  const {classes, t, stopsWithoutCapacities} = props;

  if (stopsWithoutCapacities && stopsWithoutCapacities > 0) {
    return (
      <Tooltip title={`${stopsWithoutCapacities} ${t('noCapacitiesForSomeCustomerAvailable')}`}>
        <WarnIcon className={classes.warningColor}/>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={t('noCapacitiesForCustomerAvailable')}>
      <WarnIcon className={classes.warningColor}/>
    </Tooltip>
  );
}

NoCapacitiesAvailableWarning.propTypes = {
  classes: PropTypes.object.isRequired,
  stopsWithoutCapacities: PropTypes.number,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(NoCapacitiesAvailableWarning);
