import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {SideNavLayout} from '../../../layouts';
import {useQuery} from '@apollo/react-hooks';
import {TourService} from '../../../services/backend/tourService';
import {ClientService} from '../../../services/client/clientService';
import {clientTypes} from '../../../services/client/clientTypes';
import {AuthService} from '../../../services/auth/authService';
import {DriverSelectDialog, FinishTourDeliveryDialog, PlanedTourStartSelectDialog} from './components';
import {
  DispositionTiming,
  GeneralInfoIcon,
  LoadingIndicator,
  LoadingWrapper,
  Portlet,
  RefreshButton,
  TextPlaceholderWrapper,
  TimeDispositionTourTable,
  TourDetail,
  TourFilter,
  VehicleSelectDialog
} from '../../../components';
import {DriverQueryService} from '../../../services/backend/driverQueryService';
import {VehicleQueryService} from '../../../services/backend/vehicleQueryService';
import classNames from 'classnames';
import {useTourHub} from '../../../hooks/useTourHub';
import {
  stopMessageRelevantForTourFilter,
  tourMessageRelevantForFilter
} from '../../../services/util/signalRMessageHelper';
import {useStopHub} from '../../../hooks/useStopHub';
import {TourStatus} from '../../../services/enums/TourStatus';
import useLoadTourByIdCache from '../../../hooks/useLoadTourByIdCache';
import moment from 'moment';
import useShipperOptions from '../../../hooks/useShipperOptions';
import {Warning} from '@material-ui/icons';
import {DatePickerDefaultValueContext, ProblemNotificationDialogContext} from '../../../context';
import useMicroHubOptionsOfCarrier from '../../../hooks/useMicroHubOptionsOfCarrier';
import ApproveAllToursDialog from './components/ApproveAllToursDialog';
import {displayModes} from '../../../services/enums/displayModes';


const clientDriver = ClientService.getClient(clientTypes.driver);
const clientVehicle = ClientService.getClient(clientTypes.vehicle);

function TimeDisposition(props) {
  const {classes, t} = props;
  const {fromDateDefault, toDateDefault, updateDefaultDate} = useContext(DatePickerDefaultValueContext);

  const [refresh, setRefresh] = useState(false);
  const [tours, setTours] = useState([]);
  const {shipperOptions} = useShipperOptions();
  const [rerenderDispositionTiming, setRerenderDispositionTiming] = useState(false);
  const [tourTableDriverDialogState, setTourTableDriverDialogState] = useState({open: false, tour: null});
  const [tourTableVehicleDialogState, setTourTableVehicleDialogState] = useState({open: false, tour: null, tourCapacities: null});
  const [tourTablePlanedTourStartDialogState, setTourTablePlanedTourStartDialogState] = useState({open: false, tour: null});
  const [finishTourDeliveryDialogState, setFinishTourDeliveryDialogState] = useState({open: false, tour: null});
  const [approveAllToursDialogState, setApproveAllToursDialogState] = useState(false);
  const [tourFilter, setTourFilter] = useState({
    carrierName: AuthService.getUserOrganization(),
    shipperName: '',
    microHubName: '',
    tourIds: [],
    tourStatus: '',
    fromDateTime: new Date(fromDateDefault.getFullYear(), fromDateDefault.getMonth(), fromDateDefault.getDate(), 6, 0, 0),
    toDateTime: new Date(toDateDefault.getFullYear(), toDateDefault.getMonth(), toDateDefault.getDate(), 19, 0, 0)
  });
  useEffect(() => updateDefaultDate(tourFilter.fromDateTime), [tourFilter.fromDateTime, updateDefaultDate]);
  const [backendLoadingTours, setBackendLoadingTours] = useState(false);

  const {open: openProblemNotificationDialog} = useContext(ProblemNotificationDialogContext);

  const {selectedTour, loadingSelectedTour, resetLoadTourByIdCache, getTourById, resetSelectedTour, updateCache} = useLoadTourByIdCache();
  const {microHubOptions} = useMicroHubOptionsOfCarrier(AuthService.getUserOrganization());

  const handleTourUpdate = (tour, neededUpdateBefore) => {
    if (neededUpdateBefore) {
      loadData(selectedTour, true);
    } else {
      const index = tours.findIndex(x => x.tourId === tour.tourId);
      const newTours = JSON.parse(JSON.stringify(tours));
      newTours[index] = tour;
      setTours(newTours);
      updateCache(tour);
      setRefresh(false);
    }
  };

  const filterTours = (tours) => {
    let filtered = tours;
    if (tourFilter.tourIds && tourFilter.tourIds.length > 0) {
      filtered = filtered.filter(tour => tourFilter.tourIds.includes(tour.tourId));
    }
    if (tourFilter.tourStatus) {
      filtered = filtered.filter(tour => tour.tourStatus === tourFilter.tourStatus);
    }
    return filtered;
  };

  useTourHub(useCallback(event => {
    if (tourMessageRelevantForFilter(event, tourFilter)) {
      setRefresh(true);
    }
  }, [tourFilter]));

  useStopHub(useCallback(event => {
    if (stopMessageRelevantForTourFilter(event, tourFilter)) {
      setRefresh(true);
    }
  }, [tourFilter]));

  const queryDrivers = DriverQueryService.getDriversByCarrierNameAndMicroHubListQuery(AuthService.getUserOrganization(), microHubOptions);
  const {data: dataDrivers} = useQuery(queryDrivers, {client: clientDriver, skip: microHubOptions.length === 0});

  const queryVehicle = VehicleQueryService.getVehiclesByCarrierNameAndMicroHubListQuery(AuthService.getUserOrganization(), microHubOptions);
  const {data: dataVehicle} = useQuery(queryVehicle, {client: clientVehicle, skip: microHubOptions.length === 0});

  const toursForDispositionTiming = tours.filter(tour => tour.driverEmail && tour.vehicleLicensePlate && tour.planedTourDelivery);

  const loadData = useCallback((selectedTour, noLoadingIndicator) => {
    setRerenderDispositionTiming(true);
    if (!noLoadingIndicator) {
      setBackendLoadingTours(true);
    }
    resetLoadTourByIdCache();
    TourService.getToursWithFilter(tourFilter.carrierName, tourFilter.shipperName, tourFilter.microHubName, tourFilter.fromDateTime, tourFilter.toDateTime).then(response => response.json()).then(response => {
      setTours(response);
      const index = response.findIndex(tour => selectedTour && tour.tourId === selectedTour.tourId);
      if (index >= 0) {
        getTourById(selectedTour.tourId)
      } else {
        resetSelectedTour()
      }
      setRerenderDispositionTiming(false);
      if (!noLoadingIndicator) setBackendLoadingTours(false);
      setRefresh(false);
    }, () => {
      alert(t('errorLoadingTours'));
      if (!noLoadingIndicator) setBackendLoadingTours(false);
      setRefresh(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourFilter]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const showDetails = (tour) => {
    getTourById(tour.tourId);
  };

  const finishTourDelivery = (tour, tourEnd) => {
    const needsRefresh = JSON.parse(JSON.stringify(refresh));
    setFinishTourDeliveryDialogState({...finishTourDeliveryDialogState, open: false, tour: null});
    TourService.endTourDelivery(tour.tourId, tourEnd)
      .then(response => response.json())
      .then((response) => {
        handleTourUpdate(response, needsRefresh);
      }, () => {
        alert(t('errorFinishingTourDelivery'));
        loadData(selectedTour, false);
      });
  };

  const approveTour = (tour, approved, event = undefined) => {
    if (event) event.stopPropagation();
    if (approved && moment(tour.planedTourDelivery).isBefore(moment.now())) {
      if (!window.confirm(t('approveTourInPast'))) {
        return;
      }
    }
    if (!approved && tour.tourStatus === TourStatus.InDelivery) {
      if (!window.confirm(t('confirmDisapproveInDeliveryTour'))) {
        return;
      }
    } else if (!approved && (moment(tour.planedTourDelivery) - moment.now() - 60 * 60 * 1000) <= 0) {
      if (!window.confirm(t('confirmDisapproveCriticalTimingTour'))) {
        return;
      }
    }

    const needsRefresh = JSON.parse(JSON.stringify(refresh));
    TourService.approveTourById(approved, tour.tourId).then(response => response.json()).then((response) => {
      handleTourUpdate(response, needsRefresh);
    }, () => {
      alert(t('errorApprovingTour'));
      loadData(selectedTour, false);
    });
  };

  const approveAllTours = (tours) => {
    const filteredToursById = tours.map(tour => tour.tourId);
    TourService.approveAllTours(filteredToursById).then(() => {
      loadData(false);
    }, () => {
      alert(t('errorApprovingAllTours'));
      loadData(false);
    })
  };

  const selectDriver = (tour, event) => {
    event.stopPropagation();
    setTourTableDriverDialogState({open: true, tour: tour});
  };

  const selectVehicle = (tour, event) => {
    event.stopPropagation();
    setTourTableVehicleDialogState({open: true, tour: tour, tourCapacities: tour.tourCapacities});
  };

  const selectTourStart = (tour, event) => {
    event.stopPropagation();
    setTourTablePlanedTourStartDialogState({open: true, tour: tour});
  };

  const handleDriverOk = (driverEmail, driverDisplayName) => {
    const needsRefresh = JSON.parse(JSON.stringify(refresh));
    TourService.updateDriverByTourId(driverEmail, driverDisplayName, tourTableDriverDialogState.tour.tourId).then(response => response.json()).then((response) => {
      handleTourUpdate(response, needsRefresh);
    }, () => {
      alert(t('errorUpdatingTourData'));
      loadData(selectedTour, false);
    });
    setTourTableDriverDialogState({open: false, tour: null})
  };

  const handleVehicleOk = (vehicle) => {
    const needsRefresh = JSON.parse(JSON.stringify(refresh));
    TourService.updateVehicleByTourId(vehicle.licensePlate, vehicle.averageSpeedKmh, tourTableVehicleDialogState.tour.tourId).then(response => response.json()).then((response) => {
      handleTourUpdate(response, needsRefresh);
    }, () => {
      alert(t('errorUpdatingTourData'));
      loadData(selectedTour, false);
    });
    setTourTableVehicleDialogState({open: false, tour: null, tourCapacities: null})
  };

  const handlePlanedTourStartOk = (planedTourStart) => {
    const needsRefresh = JSON.parse(JSON.stringify(refresh));
    TourService.updatePlanedTourStartByTourId(planedTourStart, tourTablePlanedTourStartDialogState.tour.tourId).then(response => response.json()).then((response) => {
      handleTourUpdate(response, needsRefresh);
    }, () => {
      alert(t('errorUpdatingTourData'));
      loadData(selectedTour, false);
    });
    setTourTablePlanedTourStartDialogState({open: false, tour: null})
  };

  const handleApproveAllTours = () => {
    setApproveAllToursDialogState(false);
    const validTours = tours.filter(tour => tour.driverDisplayName
      && tour.driverEmail?.length
      && tour.vehicleLicensePlate?.length > 0
      && tour.tourStatus === TourStatus.Planed
      && !tour.approved
      && moment(new Date(tour.planedTourDelivery)).isAfter(moment())
    );
    if(validTours.length){
      approveAllTours(validTours);
    }else{
      alert(t('errorApprovingAllTours'));
    }
  }

  return (
    <SideNavLayout title={t('timeDisposition')}>
      <div className={classes.root}>
        <div className={classes.helpWrapper}>
          <GeneralInfoIcon/>
          <RefreshButton
            className={classes.buttonRight}
            refresh={refresh}
            refreshFunc={() => loadData(selectedTour)}
          />
        </div>
        <TourFilter
          filter={tourFilter}
          microHubOptions={microHubOptions}
          setFilter={setTourFilter}
          shipperOptions={shipperOptions}
          tours={tours}
        />
        {/*##############################################DispositionTiming#######################################################################*/}
        <Fragment>
          <TextPlaceholderWrapper
            active={!filterTours(toursForDispositionTiming).length}
            text={t('noToursDoneWithDisposition')}
          >
            <DispositionTiming
              approvedTours={filterTours(toursForDispositionTiming)}
              className={classNames(classes.content, classes.dispositionTiming)}
              rerender={rerenderDispositionTiming}
              startFrom={tourFilter.fromDateTime}
              startTo={tourFilter.toDateTime}
            />
          </TextPlaceholderWrapper>
        </Fragment>
        {/*###########################################Tour Table##########################################################################*/}
        <Fragment>
          <LoadingWrapper loading={backendLoadingTours}>
            <Fragment>
              <TimeDispositionTourTable
                approveTour={approveTour}
                className={classes.content}
                selectDriver={selectDriver}
                selectedTour={selectedTour}
                selectTourStart={selectTourStart}
                selectVehicle={selectVehicle}
                showDetails={showDetails}
                tours={filterTours(tours)}
              />
              <DriverSelectDialog
                drivers={dataDrivers && dataDrivers.getDriversByCarrierNameAndMicroHubList ? dataDrivers.getDriversByCarrierNameAndMicroHubList : []}
                driverSelectDialogState={tourTableDriverDialogState}
                handleCancel={() => {
                  setTourTableDriverDialogState({open: false, tour: null})
                }}
                handleClose={handleDriverOk}
              />
              <VehicleSelectDialog
                handleCancel={() => {
                  setTourTableVehicleDialogState({open: false, tour: null, tourCapacities: null})
                }}
                handleClose={handleVehicleOk}
                vehicles={dataVehicle && dataVehicle.getVehiclesByCarrierNameAndMicroHubList ? dataVehicle.getVehiclesByCarrierNameAndMicroHubList : []}
                vehicleSelectDialogState={tourTableVehicleDialogState}
              />
              <PlanedTourStartSelectDialog
                handleCancel={() => {
                  setTourTablePlanedTourStartDialogState({open: false, tour: null})
                }}
                handleClose={handlePlanedTourStartOk}
                selectPlanedTourStartDialogState={tourTablePlanedTourStartDialogState}
              />
              {loadingSelectedTour &&
              <Portlet className={classes.content}>
                <LoadingIndicator/>
              </Portlet>
              }
              {selectedTour &&
              <TourDetail
                approveTour={approveTour}
                className={classes.content}
                closeTourDetail={() => resetSelectedTour()}
                displayMode={displayModes.carrier}
                finishTourDelivery={(tour) => setFinishTourDeliveryDialogState({
                  ...finishTourDeliveryDialogState,
                  open: true,
                  tour: tour
                })}
                tour={selectedTour}
              />
              }
              <FinishTourDeliveryDialog
                dialogState={finishTourDeliveryDialogState}
                handleCancel={() => setFinishTourDeliveryDialogState({...finishTourDeliveryDialogState, open: false, tour: null})}
                handleClose={finishTourDelivery}
              />
              <ApproveAllToursDialog
                approveAllToursDialogState={approveAllToursDialogState}
                handleCancel={() => setApproveAllToursDialogState(false)}
                handleClose={handleApproveAllTours}
              />
            </Fragment>
          </LoadingWrapper>
        </Fragment>
        <div className={classes.problemNotificationButtonContainer}>
          <Button
            className={classes.problemNotificationButton}
            onClick={() => openProblemNotificationDialog()}
          >
            <Warning/>&nbsp;{t('sendProblemNotification')}
          </Button>
          <Button
            className={classes.approveAllTours}
            color="primary"
            disabled={!tours || !tours.length}
            onClick={() => setApproveAllToursDialogState(true)}
            variant="contained"
          >
            {t('approveAllTours')}
          </Button>
        </div>
      </div>
    </SideNavLayout>
  );
}

TimeDisposition.propTypes = {
  classes: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(TimeDisposition);
