import React from 'react'
import {FormControl, Input, InputLabel, MenuItem, Select, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {InfoTooltip} from '../../index';

function BoolDropdownSelect(props) {

  const {classes, className, t, value, setValue, name, descriptionKey, disabled} = props;


  const rootClassName = classNames(classes.formControl, className);


  return (
    <InfoTooltip descriptionKey={descriptionKey}>
      <FormControl className={rootClassName}>
        <InputLabel id={name}>{name}</InputLabel>
        <Select
          disabled={disabled}
          input={<Input id={name}/>}
          onChange={(event) => setValue(event.target.value)}
          value={value}
        >
          <MenuItem
            key={0}
            value={''}
          >
            {'-'}
          </MenuItem>
          <MenuItem
            key={1}
            value
          >
            {t('yes')}
          </MenuItem>
          <MenuItem
            key={2}
            value={false}
          >
            {t('no')}
          </MenuItem>
        </Select>
      </FormControl>
    </InfoTooltip>
  );
}

BoolDropdownSelect.defaultProps = {
  descriptionKey: ''
};

BoolDropdownSelect.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  descriptionKey: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
};

export default compose(withStyles(styles), withTranslation())(BoolDropdownSelect);
