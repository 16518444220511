export default theme => ({
  yellow: {
    color: theme.palette.warning.main,
  },
  red: {
    color: theme.palette.danger.main,
  },
  green: {
    color: theme.palette.success.dark,
  }
});
