export const TourType = {
  Template: 1,
  TemplateWithAutoCut: 2,
  Algorithm: 3,
  Manual: 4,
};

export function getTourTypeString(tourType) {
  switch (tourType) {
    case TourType.Template:
      return 'tourTypeTemplate';
    case TourType.TemplateWithAutoCut:
      return 'tourTypeAutoCutTemplate';
    case TourType.Algorithm:
      return 'tourTypeAlgorithm';
    case TourType.Manual:
      return 'tourTypeManual';
    default:
      return 'tourTypeUndefined';
  }
}
