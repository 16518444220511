import React from 'react'
import {Delete as DeleteIcon, DragIndicatorOutlined as DragIcon,} from '@material-ui/icons';
import {roundCapacityValues} from '../../../../../services/util/helperFuncs';
import {NoCapacitiesAvailableWarning} from '../index';
import {IconButton} from '@material-ui/core';

const Columns = {
  stopNumber: {
    name: 'stopNumber',
    header: ({t}) => t('stopNumber'),
    body: ({stop, classes, provided}) => (
      <div className={classes.firstTableCellContent}>
        <div
          {...provided.dragHandleProps}
          className={classes.dragIcon}
        >
          <DragIcon/>
        </div>
        {stop.stopNumber}
      </div>
    )
  },
  fullName: {
    name: 'fullName',
    header: ({t}) => t('fullName'),
    body: ({stop}) => `${stop.customer.firstName || ''} ${stop.customer.lastName}`
  },
  boxAmount: {
    name: 'boxAmount',
    header: ({t}) => t('boxAmount'),
    body: ({stop}) => stop.capacities
      ? roundCapacityValues(stop.capacities.boxAmount)
      : <NoCapacitiesAvailableWarning/>
  },
  weight: {
    name: 'weight',
    header: ({t}) => t('weight'),
    body: ({stop}) => stop.capacities
      ? roundCapacityValues(stop.capacities.weight)
      : <NoCapacitiesAvailableWarning/>
  },
  volume: {
    name: 'volume',
    header: ({t}) => t('volume'),
    body: ({stop}) => stop.capacities
      ? roundCapacityValues(stop.capacities.volume)
      : <NoCapacitiesAvailableWarning/>
  },
  internalCustomerId: {
    name: 'internalCustomerId',
    header: ({t}) => t('internalCustomerId'),
    body: ({stop}) => stop.customer.internalCustomerId
  },
  customerId: {
    name: 'customerId',
    header: ({t}) => t('customerId'),
    body: ({stop}) => stop.customer.externalCustomerId
  },
  streetAndNumber: {
    name: 'streetAndNumber',
    header: ({t}) => t('streetAndNumber'),
    body: ({stop}) => stop.streetAndNumber || ''
  },
  zipcode: {
    name: 'zipcode',
    header: ({t}) => t('zipcode'),
    body: ({stop}) => stop.zipcode || ''
  },
  delete: {
    name: 'delete',
    header: (_) => '',
    body: ({stop, classes, deleteStopFromTemplate, t}) => (
      <IconButton
        className={classes.deleteButton}
        onClick={() => deleteStopFromTemplate(stop.id)}
        size="small"
        title={t('deleteStopFromTourTemplate')}
      >
        <DeleteIcon/>
      </IconButton>
    )
  },
}

export default Columns;