import React, {Fragment, useState} from 'react'
import {
  Button,
  FormControl,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles
} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Pagination, Portlet, PortletContent, TextPlaceholderWrapper} from '../../../../../../components'
import {Add as AddIcon, DeleteForever as DeleteIcon} from '@material-ui/icons';
import useTablePagination from '../../../../../../hooks/useTablePagination';

function PackStationForShipperTable(props) {

  const [canAdd, setCanAdd] = useState(false);
  const [newPackStationForShipper, setNewPackStationForShipper] = useState({
    packStationId: '',
    packStationDescription: '',
    shipper: '',
  });

  const {
    classes,
    className,
    packStationsForShipper,
    t,
    deletePackStationForShipper,
    createPackStationForShipper,
    selectedShipper
  } = props;

  const {paginationProps, pagedObjects} = useTablePagination(packStationsForShipper);

  const createMapping = () => {
    newPackStationForShipper.shipper = selectedShipper;
    newPackStationForShipper.packStationId = parseInt(newPackStationForShipper.packStationId);
    createPackStationForShipper(newPackStationForShipper);
    setNewPackStationForShipper({
      packStationId: '',
      packStationDescription: '',
      shipper: '',
    });
    setCanAdd(false);
  };


  const rootClassName = classNames(classes.root, className);

  return (
    <TextPlaceholderWrapper
      active={!packStationsForShipper || !selectedShipper}
      text={t('noPackStationsForShipperFound')}
    >
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell className={classes.alignLeft}>{t('packStationIdForShipperId')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('shipper')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('packStationId')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('packStationDescription')}</TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedObjects.map((packStationForShipper, index) => (
                <TableRow
                  className={classes.tableRow}
                  hover
                  key={index}
                >
                  <TableCell className={classes.tableCell}>
                    {packStationForShipper.systemId}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {packStationForShipper.shipper}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {packStationForShipper.packStationId}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {packStationForShipper.packStationDescription}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Button
                      className={classes.deleteButton}
                      color="inherit"
                      onClick={() => {
                        if (window.confirm(t('confirmDeletePackStationForShipper'))) deletePackStationForShipper(packStationForShipper)
                      }}
                      title={t('deletePackStationForShipper')}
                    >
                      <DeleteIcon/>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                {canAdd ?
                  <Fragment>
                    <TableCell className={classes.tableCell}>
                      {'-'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {selectedShipper}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <FormControl className={classes.formControl}>
                        <Input
                          error={newPackStationForShipper.packStationId === '' || isNaN(newPackStationForShipper.packStationId) || parseInt(newPackStationForShipper.packStationId) < 0}
                          id="packStationId"
                          onChange={(event) => {
                            setNewPackStationForShipper({
                              ...newPackStationForShipper,
                              packStationId: event.target.value
                            });
                          }}
                          onClick={event => event.target.select()}
                          onWheel={event => event.target.blur()}
                          type="number"
                          value={newPackStationForShipper.packStationId}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <FormControl className={classes.formControl}>
                        <Input
                          id="packStationDescription"
                          onChange={(event) => {
                            setNewPackStationForShipper({
                              ...newPackStationForShipper,
                              packStationDescription: event.target.value
                            })
                          }}
                          value={newPackStationForShipper.packStationDescription}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Button
                        color="primary"
                        disabled={!newPackStationForShipper.packStationId}
                        onClick={createMapping}
                        style={{padding: 0}}
                        title={t('createPackStationForShipper')}
                      >
                        <AddIcon/>
                      </Button>
                    </TableCell>
                  </Fragment>
                  :
                  <Fragment>
                    <TableCell colSpan={5}>
                      <Button
                        color="primary"
                        onClick={() => setCanAdd(!canAdd)}
                        style={{padding: 0}}
                        title={t('createPackStationForShipper')}
                      >
                        <AddIcon/>
                      </Button>
                    </TableCell>
                  </Fragment>
                }
              </TableRow>
            </TableBody>
          </Table>
          <Pagination paginationProps={paginationProps}/>
        </PortletContent>
      </Portlet>
    </TextPlaceholderWrapper>
  );
}


PackStationForShipperTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  createPackStationForShipper: PropTypes.func.isRequired,
  deletePackStationForShipper: PropTypes.func.isRequired,
  packStationsForShipper: PropTypes.array.isRequired,
  selectedShipper: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

PackStationForShipperTable.defaultProps = {
  PackStationForShipper: [],
};


export default compose(withStyles(styles), withTranslation())(PackStationForShipperTable);
