import Columns from './columns';
import {displayModes} from '../../../services/enums/displayModes';

// CARE: ALWAYS INCREMENT THE VERSION IF THE SETTINGS FOR A COLUMN CHANGE!
// Config explanation
// optional: determines if the hidden status can be changed by the user
// hidden: determines if the field is shown or hidden
// conditional: indicates that the hidden status can be overriden using the conditions dictionary of the useTableConfig hook
const DefaultConfig = {
  [displayModes.admin]: {
    version: 1,
    columns: {
      driverFirstName: {
        columnName: Columns.driverFirstName.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 1,
      },
      driverLastName: {
        columnName: Columns.driverLastName.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 2
      },
      driverDisplayName: {
        columnName: Columns.driverDisplayName.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 3
      },
      driverPhoneNumber: {
        columnName: Columns.driverPhoneNumber.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 4
      },
      driverEmail: {
        columnName: Columns.driverEmail.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 5
      },
      driverType: {
        columnName: Columns.driverType.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 6
      },
      carrier: {
        columnName: Columns.carrier.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 7
      },
      donationButton: {
        columnName: Columns.donationButton.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 8
      },
    }
  },
  [displayModes.carrier]: {
    version: 1,
    columns: {
      driverFirstName: {
        columnName: Columns.driverFirstName.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 1,
      },
      driverLastName: {
        columnName: Columns.driverLastName.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 2
      },
      driverDisplayName: {
        columnName: Columns.driverDisplayName.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 3
      },
      driverPhoneNumber: {
        columnName: Columns.driverPhoneNumber.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 4
      },
      driverEmail: {
        columnName: Columns.driverEmail.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 5
      },
      driverType: {
        columnName: Columns.driverType.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 6
      },
      driverDelete: {
        columnName: Columns.driverDelete.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 7
      },
    }
  },
  [displayModes.shipper]: {
    version: 1,
    columns: {}
  },
}

export default DefaultConfig;