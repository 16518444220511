import React from 'react';


const NewsToUserDialogContext = React.createContext({
  dialogState: {open: false, newsToUser: null},
  open: () => {
  }
  ,
  close: () => {
  },
  markNewsAsRead: (newsToUserId) => {
  },
});

export default NewsToUserDialogContext;
