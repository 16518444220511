import React from 'react'
import {TablePagination} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

function Pagination(props) {

  const {
    paginationProps,
    t
  } = props;

  const {page, count, onPageChange, onRowsPerPageChange, rowsPerPage, rowsPerPageOptions} = paginationProps;

  return (
    <TablePagination
      component="div"
      count={count}
      labelRowsPerPage={t('labelRowsPerPage')}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
    />
  );
}


Pagination.propTypes = {
  paginationProps: PropTypes.shape({
    page: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onRowsPerPageChange: PropTypes.func.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired
  }).isRequired,
  t: PropTypes.func.isRequired
};

Pagination.defaultProps = {
  tours: [],
};


export default withTranslation()(Pagination);
