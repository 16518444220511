import {useEffect, useState} from 'react';
import {ShipperQueryService} from '../services/backend/shipperQueryService';
import {useQuery} from '@apollo/react-hooks';
import {ClientService} from '../services/client/clientService';
import {clientTypes} from '../services/client/clientTypes';


const clientShipper = ClientService.getClient(clientTypes.shipper);

export default function useShipperOptions(withShortNames = false) {

  const [shipperOptions, setShipperOptions] = useState([]);
  const [shipperOptionsWithShortNames, setShipperOptionsWithShortNames] = useState({});


  const queryShipper = ShipperQueryService.getAllShipperNames();
  const {data: dataShipper} = useQuery(queryShipper, {client: clientShipper});

  useEffect(() => {
    if (withShortNames) {
      if (dataShipper && dataShipper.shippers) {
        const shipperNames = {};
        dataShipper.shippers.forEach(shipper => {
          shipperNames[shipper.name] = shipper.shortName;
        });
        setShipperOptionsWithShortNames(shipperNames);
      }
    } else {
      if (dataShipper && dataShipper.shippers) {
        const shipperNames = [];
        dataShipper.shippers.forEach(shipper => {
          shipperNames.push(shipper.name)
        });
        setShipperOptions(shipperNames);
      }
    }

  }, [dataShipper, withShortNames]);


  return withShortNames ? {shipperOptions: shipperOptionsWithShortNames} : {shipperOptions};
}
