import Columns from './columns';
import {displayModes} from '../../../services/enums/displayModes';

// CARE: ALWAYS INCREMENT THE VERSION IF THE SETTINGS FOR A COLUMN CHANGE!
// Config explanation
// optional: determines if the hidden status can be changed by the user
// hidden: determines if the field is shown or hidden
// conditional: indicates that the hidden status can be overriden using the conditions dictionary of the useTableConfig hook
const DefaultConfig = {
  [displayModes.admin]: {
    version: 1,
    columns: {
      internalCustomerId: {
        columnName: Columns.internalCustomerId.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 1,
      },
      customerId: {
        columnName: Columns.customerId.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 2
      },
      firstName: {
        columnName: Columns.firstName.name,
        optional: true,
        hidden: false,
        conditional: false,
        order: 3
      },
      lastName: {
        columnName: Columns.lastName.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 4
      },
      email: {
        columnName: Columns.email.name,
        optional: true,
        hidden: true,
        conditional: false,
        order: 5
      },
      streetAndNumber: {
        columnName: Columns.streetAndNumber.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 6
      },
      zipcode: {
        columnName: Columns.zipcode.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 7
      },
      lastOrderDate: {
        columnName: Columns.lastOrderDate.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 8
      },
      delete: {
        columnName: Columns.delete.name,
        optional: false,
        hidden: false,
        conditional: true,
        order: 9
      },
    }
  },
  [displayModes.carrier]: {
    version: 1,
    columns: {
      internalCustomerId: {
        columnName: Columns.internalCustomerId.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 1,
      },
      customerId: {
        columnName: Columns.customerId.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 2
      },
      firstName: {
        columnName: Columns.firstName.name,
        optional: true,
        hidden: false,
        conditional: false,
        order: 3
      },
      lastName: {
        columnName: Columns.lastName.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 4
      },
      streetAndNumber: {
        columnName: Columns.streetAndNumber.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 5
      },
      zipcode: {
        columnName: Columns.zipcode.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 6
      },
      lastOrderDate: {
        columnName: Columns.lastOrderDate.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 7
      }
    }
  },
  [displayModes.shipper]: {
    version: 1,
    columns: {
      internalCustomerId: {
        columnName: Columns.internalCustomerId.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 1,
      },
      customerId: {
        columnName: Columns.customerId.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 2
      },
      firstName: {
        columnName: Columns.firstName.name,
        optional: true,
        hidden: false,
        conditional: false,
        order: 3
      },
      lastName: {
        columnName: Columns.lastName.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 4
      },
      email: {
        columnName: Columns.email.name,
        optional: true,
        hidden: true,
        conditional: false,
        order: 5
      },
      streetAndNumber: {
        columnName: Columns.streetAndNumber.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 6
      },
      zipcode: {
        columnName: Columns.zipcode.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 7
      },
      lastOrderDate: {
        columnName: Columns.lastOrderDate.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 8
      }
    }
  },
}

export default DefaultConfig;