export const TourStatus = {
  Planed: 1,
  InDelivery: 2,
  Delivered: 3,
};

export function getTourStatusSting(status) {
  switch (status) {
    case TourStatus.Planed:
      return 'tourStatusPlaned';
    case TourStatus.InDelivery:
      return 'tourStatusInDelivery';
    case TourStatus.Delivered:
      return 'tourStatusDelivered';
    default:
      return 'tourStatusUndefined'
  }
}
