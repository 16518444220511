export default theme => ({
  root: {
    padding: theme.spacing(3)
  },
  button: {
    marginBottom: theme.spacing(2),
  },
  buttonRight: {
    float: 'right',
  },
  helpWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  iconMargin: {
    marginLeft: '8px'
  },
  appBar: {
    left: '2px',
    width: 'calc(100% - 2px)',
    zIndex: 'unset'
  }
});
