import React from 'react'
import {Button, Grid, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';


function DataImportMethodSelection(props) {

  const {classes, t, setActiveStep, setUseExcelAsImportMethod} = props

  const handleDataImportMethodSelection = (useExcel) => {
    setUseExcelAsImportMethod(useExcel);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (

    <div className={classes.buttonWrapper}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={6}
          xs={12}
        >
          <div className={classes.iconWrapper}>
            <Typography
              className={classes.iconMargin}
              variant={'h5'}
            >
              {t('chooseMethod')}
            </Typography>
          </div>
          <div className={classes.actionsContainerImport}>
            <Button
              className={classes.importButton}
              color="primary"
              onClick={() => handleDataImportMethodSelection(false)}
              size="large"
              variant="contained"
            >
              {t('dataImport')}
            </Button>
            <Button
              className={classes.importButton}
              color="primary"
              onClick={() => handleDataImportMethodSelection(true)}
              size="large"
              variant="contained"
            >
              {t('dataUpload')}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );

}

DataImportMethodSelection.propTypes = {
  classes: PropTypes.object.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  setUseExcelAsImportMethod: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(DataImportMethodSelection);
