import React, {Fragment, useEffect, useState} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import SideNavLayout from '../../../layouts/SideNavLayout';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {
  BoxTypeDetail,
  BoxTypeFilter,
  BoxTypeTable,
  CreateBoxTypeDialog,
  DataLoadingWrapper,
  GeneralInfoIcon,
  TextPlaceholderWrapper
} from '../../../components';
import {useMutation, useQuery} from '@apollo/react-hooks';
import omitDeep from 'omit-deep';
import {Add as AddIcon} from '@material-ui/icons';
import {ClientService} from '../../../services/client/clientService';
import {clientTypes} from '../../../services/client/clientTypes';
import {ShipperQueryService} from '../../../services/backend/shipperQueryService';
import useShipperOptions from '../../../hooks/useShipperOptions';
import {displayModes} from '../../../services/enums/displayModes';


const client = ClientService.getClient(clientTypes.shipper);

function BoxAdministration(props) {


  const {classes, t} = props;

  const [selectedBoxType, setSelectedBoxType] = useState(null);
  const [filter, setFilter] = useState({
    selectedShipper: '',
    kind: '',
    stackable: ''
  });
  const {shipperOptions} = useShipperOptions(true);
  const [createBoxTypeDialogState, setCreateBoxTypeDialogState] = useState({
    open: false,
    shipperShortName: null,
  });

  const queryBoxTypes = ShipperQueryService.getBoxTypesByShipperNameAndIdentifierList(filter.selectedShipper, []);
  const {loading, error, data, refetch} = useQuery(queryBoxTypes, {client: client, skip: !filter.selectedShipper});

  const deleteBoxTypeQuery = ShipperQueryService.deleteBoxTypeByIdIdentifier();
  const [deleteBoxType] = useMutation(deleteBoxTypeQuery, {client: client});

  const updateBoxTypeQuery = ShipperQueryService.updateBoxTypeByIdentifierQuery();
  const [updateBoxType] = useMutation(updateBoxTypeQuery, {client: client});

  const createBoxTypeQuery = ShipperQueryService.createBoxTypeQuery();
  const [createBoxType] = useMutation(createBoxTypeQuery, {client: client});

  useEffect(() => {
    setSelectedBoxType(null)
  }, [filter]);
  
  const updateBoxTypeCallback = (updatedBoxType, originalIdentifier) => {
    const formattedUpdatedBoxType = omitDeep(updatedBoxType, '__typename');
    updateBoxType({variables: {identifier: originalIdentifier, updatedBoxType: formattedUpdatedBoxType}}).then(() => {
      refetch().then();
      setSelectedBoxType(null);
    }, () => {
      alert(t('errorUpdatingData'))
    });
  };

  const deleteBoxTypeCallback = (boxType, event) => {
    event.stopPropagation();
    deleteBoxType({variables: {identifier: boxType.identifier}}).then(() => {
      refetch().then();
      setSelectedBoxType(null);
    }, () => {
      alert(t('errorDeletingBoxType'))
    });
  };


  const handleCreateBoxTypeCancel = () => {
    setCreateBoxTypeDialogState({...createBoxTypeDialogState, open: false});
  };

  const handleCreateBoxTypeClose = (boxType) => {
    // handle float values
    boxType.volume = parseFloat(boxType.volume);
    boxType.height = parseFloat(boxType.height);
    boxType.depth = parseFloat(boxType.depth);
    boxType.width = parseFloat(boxType.width);
    createBoxType({variables: {shipperName: filter.selectedShipper, boxTypeToCreate: boxType}}).then(() => {
      refetch().then();
    }, () => {
      alert(t('errorCreatingBoxType'))
    });
    setCreateBoxTypeDialogState({...createBoxTypeDialogState, open: false});
  };

  const filterBoxTypes = (boxTypes) => {
    return boxTypes
      .filter(boxType => !filter.kind || boxType.kind === filter.kind)
      .filter(boxTypes => filter.stackable === '' || boxTypes.stackable === filter.stackable);
  };

  return (
    <SideNavLayout title={t('boxAdministration')}>
      <div className={classes.root}>
        <div className={classes.helpWrapper}>
          <GeneralInfoIcon/>
        </div>
        <BoxTypeFilter
          filter={filter}
          setFilter={setFilter}
          setSelectedBoxType={setSelectedBoxType}
          shipperOptions={Object.keys(shipperOptions)}
        />
        <div>
          {filter.selectedShipper &&
            <Button
              className={classes.addButton}
              color="primary"
              disabled={!filter.selectedShipper}
              onClick={() => setCreateBoxTypeDialogState({
                ...createBoxTypeDialogState,
                open: true,
                shipperShortName: shipperOptions[filter.selectedShipper]
              })}
              title={t('createBoxType')}
              variant="contained"
            >
              <AddIcon/>&nbsp;{t('createBoxType')}
            </Button>
          }
          <br/>
          <br/>
        </div>
        <TextPlaceholderWrapper
          active={!filter.selectedShipper}
          text={t('noShipperSelectedBoxAdministration')}
        >
          <DataLoadingWrapper
            error={error}
            isLoading={loading}
          >
            {data && data.boxTypes &&
              <Fragment>
                <BoxTypeTable
                  boxTypes={filterBoxTypes(data.boxTypes)}
                  className={classes.content}
                  deleteBoxType={deleteBoxTypeCallback}
                  selectBoxType={setSelectedBoxType}
                  selectedBoxType={selectedBoxType}
                  displayMode={displayModes.shipper}
                />
                {selectedBoxType &&
                  <BoxTypeDetail
                    boxType={selectedBoxType}
                    className={classes.content}
                    closeDetail={() => setSelectedBoxType(null)}
                    shipperShortName={shipperOptions[filter.selectedShipper]}
                    updateBoxType={updateBoxTypeCallback}
                  />
                }
              </Fragment>
            }
          </DataLoadingWrapper>
        </TextPlaceholderWrapper>
        <CreateBoxTypeDialog
          createBoxTypeDialogState={createBoxTypeDialogState}
          handleCancel={handleCreateBoxTypeCancel}
          handleClose={handleCreateBoxTypeClose}
        />
      </div>
    </SideNavLayout>
  );

}

BoxAdministration.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(BoxAdministration);
