export default theme => ({
  root: {

  },
  versionContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    alignItems: 'stretch'
  },
});
