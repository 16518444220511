import React, {Fragment} from 'react'
import {Button, Divider, FormControl, InputLabel, MenuItem, Select, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {PortletContent, PortletHeader} from '../../../../../../components';
import {Close as CloseIcon, Edit as EditIcon} from '@material-ui/icons';
import WeekDayShipperListShow from '../WeekDayShipperListShow';
import {dayOfWeekSorterFunction} from '../../../../../../services/enums/dayOfWeekHelper';
import OperatingTimeShow from '../OperatingTimeShow';

function MicroHubDetailsShow(props) {

  const {classes, className, microHub, edit, t, readonly, closeDetail, selectedCarrier, setSelectedCarrier} = props;

  const rootClassName = classNames(classes.root, className);  

  return (
    <Fragment>
      <PortletHeader className={rootClassName}>
        <Fragment>
          <div><strong>{microHub.name}</strong></div>
          <div>
            <Fragment>
              <Button
                className={classes.button}
                color="default"
                onClick={closeDetail}
                title={t('close')}
                variant="contained"
              >
                <CloseIcon/>
              </Button>
              {!readonly &&
              <Button
                className={classes.button}
                color="primary"
                onClick={edit}
                title={t('microHubEdit')}
                variant="contained"
              >
                <EditIcon/>
              </Button>
              }
            </Fragment>
          </div>
        </Fragment>
      </PortletHeader>
      <PortletContent noPadding>
        <div className={classes.wrapper}>
          <div className={classes.wrapperChild}>
            <div className={classes.vehicleDetail}><strong>{t('microHubName')}</strong>: {microHub.name}</div>
            <div className={classes.vehicleDetail}><strong>{t('email')}</strong>: {microHub.email}</div>
            <div className={classes.vehicleDetail}><strong>{t('carrier')}</strong>: {microHub.carriers.map(carrier => carrier.carrier.name).join(' ,')}</div>
            <div className={classes.vehicleDetail}><strong>{'B2B'}</strong>: {t(microHub.b2B ? t('yes') : t('no'))}</div>
            <div className={classes.vehicleDetail}><strong>{'B2C'}</strong>: {t(microHub.b2C ? t('yes') : t('no'))}</div>
          </div>
          <div className={classes.wrapperChild}>
            <div className={classes.vehicleDetail}>
              <div><strong>{t('address')}</strong>:</div>
              {microHub.address &&
              <Fragment>
                <div className={classes.vehicleDetail}><strong>{t('streetAndNumber')}</strong>: {`${microHub.address.street} ${microHub.address.houseNumber}`}
                </div>
                <div className={classes.vehicleDetail}><strong>{t('zipcode')}</strong>: {microHub.address.zipcode}</div>
                <div className={classes.vehicleDetail}><strong>{t('city')}</strong>: {microHub.address.city}</div>
                <div className={classes.vehicleDetail}><strong>{t('country')}</strong>: {microHub.address.country}</div>
              </Fragment>
              }
            </div>
          </div>
          <div className={classes.wrapperChild}>
            <div className={classes.vehicleDetail}>
              <div><strong>{t('contact')}</strong>:</div>
              {microHub.contact &&
              <Fragment>
                <div className={classes.vehicleDetail}><strong>{t('name')}</strong>: {microHub.contact.name}</div>
                <div className={classes.vehicleDetail}><strong>{t('email')}</strong>: {microHub.contact.email}</div>
                <div className={classes.vehicleDetail}><strong>{t('phoneNumber')}</strong>: {microHub.contact.phoneNumber}</div>
              </Fragment>
              }
            </div>
          </div>
        </div>
        <Divider />
        <div className={classes.wrapper}>
          <div className={classes.wrapperChild}>
            <FormControl className={classes.formControl}>
              <InputLabel id="detail-select-carrier">{t('carrier')}</InputLabel>
              <Select
                labelId="detail-select-carrier"
                onChange={(event) => {
                  setSelectedCarrier(event.target.value)
                }}
                value={selectedCarrier}
              >
                {microHub.carriers.map((carrier, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={index}
                    >
                      {carrier.carrier.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <OperatingTimeShow
            className={classes.wrapperChild}
            weekDayShipperLists={microHub.carriers[selectedCarrier].weekDayShipperLists}
          />
          <div className={classes.wrapperChild}>
            {microHub.carriers[selectedCarrier] &&
            <div>
              <div><strong>{t('disponent')}</strong>:</div>
              {microHub.carriers[selectedCarrier].disponent &&
              <Fragment>
                <div className={classes.vehicleDetail}><strong>{t('name')}</strong>: {microHub.carriers[selectedCarrier].disponent.name}</div>
                <div className={classes.vehicleDetail}><strong>{t('email')}</strong>: {microHub.carriers[selectedCarrier].disponent.email}</div>
                <div className={classes.vehicleDetail}><strong>{t('phoneNumber')}</strong>: {microHub.carriers[selectedCarrier].disponent.phoneNumber}</div>
              </Fragment>
              }
            </div>}
          </div>
        </div>
        <div className={classes.wrapper}>
          {microHub.carriers[selectedCarrier] && 
          microHub.carriers[selectedCarrier].weekDayShipperLists.sort((a, b) => dayOfWeekSorterFunction(a.weekDay, b.weekDay)).map((list, index) =>
            <WeekDayShipperListShow
              className={classes.wrapperChild}
              key={index}
              weekDayShipperList={list}
            />
          )
          }
        </div>
      </PortletContent>
    </Fragment>
  );
}


MicroHubDetailsShow.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  closeDetail: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  microHub: PropTypes.object.isRequired,
  readonly: PropTypes.bool,
  selectedCarrier: PropTypes.number.isRequired,
  setSelectedCarrier: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(MicroHubDetailsShow);
