export default theme => ({
  root: {
    border: '1px solid lightgrey',
    borderRadius: '3px'
  },
  tableCell: {
    whiteSpace: 'nowrap',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  },
  tableHeader: {
    backgroundColor: theme.palette.common.white,
  },
  alignLeft: {
    align: 'left',
    padding: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  warningColor: {
    color: theme.palette.warning.main,
    verticalAlign: 'middle'
  }
});

