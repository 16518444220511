export default theme => ({
  root: {
    border: '1px solid lightgrey',
    borderRadius: '3px'
  },
  tableSettings: {
    right: '0px',
    position: 'absolute',
    top: theme.spacing(-4)
  },
  tableCell: {
    whiteSpace: 'nowrap',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  },
  tableHeader: {
    backgroundColor: theme.palette.common.white,
  },
  alignLeft: {
    align: 'left',
    padding: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  idTableCell: {
    display: 'flex',
    alignItems: 'center'
  },
  amPmTag: {
    marginRight: theme.spacing(1)
  }
});

