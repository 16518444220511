import React from 'react'
import {TourAmPmTag, TourIdLabel, TourMetadataIcon, TourStoragePlaces} from '../../../../../../components';
import {CapacityHelperService} from '../../../../../../services/util/capacityHelper';
import {roundCapacityValues} from '../../../../../../services/util/helperFuncs';

const Columns = {
  tourId: {
    name: 'tourId',
    header: ({t}) => t('tourId'),
    body: ({tour, classes}) => (
      <div
        className={classes.idTableCell}
      >
        <TourMetadataIcon metadata={tour.tourMetaData}/>
        <TourAmPmTag
          className={classes.amPmTag}
          elevation={0}
          estimatedTourDeliveryWindow={tour.estimatedTourDeliveryWindow}
        />
        <TourIdLabel tour={tour}/>
      </div>
    )
  },
  microHub: {
    name: 'microHub',
    header: ({t}) => t('microHub'),
    body: ({tour}) => tour.microHubName
  },
  numStops: {
    name: 'numStops',
    header: ({t}) => t('numStops'),
    body: ({tour}) => tour.stops && tour.stops.length > 0 ? tour.stops.length : 0
  },
  storagePlace: {
    name: 'storagePlace',
    header: ({t}) => t('storagePlace'),
    body: ({tour}) => (
      <TourStoragePlaces
        maxDisplay={4}
        tour={tour}
      />
    )
  },
  boxAmount: {
    name: 'boxAmount',
    header: ({t}) => t('boxAmount'),
    body: ({tour}) => CapacityHelperService.getBoxAmountOfTour(tour)
  },
  weight: {
    name: 'weight',
    header: ({t}) => t('weight'),
    body: ({tour}) => roundCapacityValues(CapacityHelperService.getWeightOfTour(tour))
  },
  volume: {
    name: 'volume',
    header: ({t}) => t('volume'),
    body: ({tour}) => roundCapacityValues(CapacityHelperService.getVolumeOfTour(tour))
  },
}

export default Columns;