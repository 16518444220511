export default theme => ({
  root: {
    minHeight: '100%'
  },
  card: {
    height: '100%'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    alignItems: 'center'
  }
});
