import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {IconButton, Table, TableBody, TableCell, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import classNames from 'classnames';
import {
  InfoIcon,
  LoadingWrapper,
  Pagination,
  Portlet,
  PortletContent,
  PortletHeader,
  PortletLabel,
  TextPlaceholderWrapper
} from '../../index';
import React, {Fragment, useCallback, useEffect, useState} from 'react';
import moment from 'moment';
import PortletToolbar from '../../PortletToolbar';
import {ArrowBack, ArrowForward,} from '@material-ui/icons';
import {DateService} from '../../../services/util/DateService';
import ShipperApiEventTableRow from './components/ShipperApiEventTableRow';
import shipperDeclinedStopsService from '../../../services/backend/shipperDeclinedStopsService';
import useTablePagination from '../../../hooks/useTablePagination';


function ShipperApiEventsCard(props) {

  const {
    t,
    classes,
    className,
    shipperName
  } = props;

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [shipperApiEvents, setShipperAPiEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadShipperApiEventsData = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await shipperDeclinedStopsService.getShipperApiEventsByFilter(shipperName, moment(selectedDate).toDate());
      const sortedResult = result.sort((a, b) => new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime()).reverse();
      setShipperAPiEvents(sortedResult)
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.warn('Error loading shipper-api events', e)
    }
  }, [shipperName, selectedDate])

  useEffect(() => {
    loadShipperApiEventsData();
  }, [loadShipperApiEventsData])

  const {paginationProps, pagedObjects} = useTablePagination(shipperApiEvents);

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet
      className={rootClassName}
    >
      <PortletHeader className={classes.portletHeader}>
        <PortletLabel title={t('shipperApiEvents')}/>
        <PortletToolbar>
          <IconButton
            onClick={() => setSelectedDate(moment(selectedDate).subtract(1, 'day').toDate())}
            variant="text"
          >
            <ArrowBack/>
          </IconButton>
          <div>
            {DateService.displayDate(selectedDate)}
          </div>
          <IconButton
            onClick={() => setSelectedDate(moment(selectedDate).add(1, 'day').toDate())}
            variant="text"
          >
            <ArrowForward/>
          </IconButton>
        </PortletToolbar>
        <InfoIcon descriptionKey={'40'}/>
      </PortletHeader>
      <PortletContent
        className={classes.portletContent}
        noPadding
      >
        <Fragment>
          <LoadingWrapper loading={isLoading}>
            <TextPlaceholderWrapper
              active={!shipperApiEvents?.length}
              text={t('noShipperApiEventsAvailable')}
            >
              <Portlet>
                <PortletContent
                  className={classes.portletContent}
                  noPadding
                >
                  <Table
                    className={classes.declinedStopsTable}
                    size="small"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('timeStamp')}</TableCell>
                        <TableCell>{t('deliveryDate')}</TableCell>
                        <TableCell>{t('shipper')}</TableCell>
                        <TableCell>{t('shipperApiEventType')}</TableCell>
                        <TableCell>{t('responseCode')}</TableCell>
                        <TableCell>{t('objectCount')}</TableCell>
                        <TableCell/>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pagedObjects.map((shipperApiEvent, index) => (
                        <ShipperApiEventTableRow
                          key={index}
                          shipperApiEvent={shipperApiEvent}
                        />
                      ))}
                    </TableBody>
                  </Table>
                  <Pagination paginationProps={paginationProps}/>
                </PortletContent>
              </Portlet>
            </TextPlaceholderWrapper>
          </LoadingWrapper>
        </Fragment>
      </PortletContent>
    </Portlet>
  );
}

ShipperApiEventsCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  shipperName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(ShipperApiEventsCard);
