export default theme => ({
  root: {
    padding: theme.spacing(3),
  },
  map: {
    width: '100%',
    minHeight: 'calc(-195px + 100vh)',
  },
  helpWrapper: {
    float: 'right',
    marginBottom: theme.spacing(1),
  },
});
