import React from 'react'
import {FormControl, Input, InputLabel, MenuItem, Select, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {BoxTypeKind, getBoxTypeKindString} from '../../../services/enums/BoxTypeKinds';
import {BoolDropdownSelect, DropdownSelectSimple} from '../../index';

function BoxTypeFilter(props) {

  const {classes, className, t, filter, setFilter, setSelectedBoxType, shipperOptions} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <form className={classes.rowWrapper}>
        <DropdownSelectSimple
          error={!filter.selectedShipper}
          hideOnEmptyOptions
          name={t('shipper')}
          options={shipperOptions}
          required
          setValue={(value) => setFilter({...filter, selectedShipper: value})}
          sort
          value={filter.selectedShipper}
        />
        <BoolDropdownSelect
          name={t('boxTypeStackable')}
          setValue={(value) => {
            setFilter({...filter, stackable: value});
            setSelectedBoxType(null);
          }}
          value={filter.stackable}
        />
        <FormControl className={classes.formControl}>
          <InputLabel id="boxTypeKind">{t('boxTypeKind')}</InputLabel>
          <Select
            input={<Input id="boxTypeKind"/>}
            onChange={(event) => {
              setFilter({...filter, kind: event.target.value});
              setSelectedBoxType(null);
            }
            }
            value={filter.kind}
          >
            <MenuItem
              value={''}
            >
              {'-'}
            </MenuItem>
            {Object.getOwnPropertyNames(BoxTypeKind)
              .map(propertyName => BoxTypeKind[propertyName])
              .sort(((a, b) => t(getBoxTypeKindString(a)) > t(getBoxTypeKindString(b)) ? 1 : -1))
              .map((kind, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={kind}
                  >
                    {t(getBoxTypeKindString(kind))}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </form>
    </div>
  );
}


BoxTypeFilter.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  setSelectedBoxType: PropTypes.func.isRequired,
  shipperOptions: PropTypes.array,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(BoxTypeFilter);
