import React, {Fragment} from 'react'
import {Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {DateService} from '../../../services/util/DateService';
import {displayShipperAndShipperShortName, roundCapacityValues} from '../../../services/util/helperFuncs';
import {PreviewStopIdLabel} from '../../index';
import {AuthService} from '../../../services/auth/authService';
import {userRoles} from '../../../services/auth/userRoles';

function StopTableDetails(props) {

  const {classes, t, stop} = props;

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.wrapperChild}>
        <strong>{t('previewStopID')}</strong>:&nbsp;<PreviewStopIdLabel stop={stop}/><br/>
        <strong>{t('stopNumber')}</strong>:&nbsp;{stop.stopNumber}<br/>
        <strong>{t('deliveryDate')}</strong>:&nbsp;{DateService.displayDate(stop.deliveryDate)}<br/>
        <strong>{t('editableDeadline')}</strong>:&nbsp;{DateService.displayDate(new Date(stop.editDeadline)) + ' ' + DateService.displayTime(new Date(stop.editDeadline))}<br/>
        <strong>{t('carrier')}</strong>:&nbsp;{stop.assignedCarrierName}<br/>
        <strong>{t('microHub')}</strong>:&nbsp;{stop.assignedMicroHubName}<br/>
        <strong>{t('previewOrder')}</strong>:&nbsp;{stop.previewOrder}<br/>
      </Typography>
      <Typography className={classes.wrapperChild}>
        <strong>{t('boxAmount')}</strong>:&nbsp;{roundCapacityValues(stop.stopCapacities.boxAmount)}<br/>
        <strong>{t('weight')}</strong>:&nbsp;{roundCapacityValues(stop.stopCapacities.weight)}<br/>
        <strong>{t('volume')}</strong>:&nbsp;{roundCapacityValues(stop.stopCapacities.volume)}<br/>
        <br/>
        <strong>{t('estimatedDeliveryWindow')}</strong>: {stop.estimatedDeliveryWindow && DateService.displayTime(stop.estimatedDeliveryWindow.from)} - {stop.estimatedDeliveryWindow && DateService.displayTime(stop.estimatedDeliveryWindow.to)}
      </Typography>
      <Typography className={classes.wrapperChild}>
        <strong>{t('firstName')}</strong>:&nbsp;{stop.firstName}<br/>
        <strong>{t('lastName')}</strong>:&nbsp;{stop.lastName}<br/>
        <strong>{t('internalCustomerId')}</strong>:&nbsp;{stop.internalCustomerId}<br/>
        <strong>{t('customerId')}</strong>:&nbsp;{stop.customerId}<br/>
        <strong>{t('address')}</strong>:&nbsp;{`${stop.address.streetAndNumber} ${stop.address.zipcode} ${stop.address.city}`}<br/>
        <strong>{t('shipper')}</strong>:&nbsp;{displayShipperAndShipperShortName(stop)}<br/>
        {AuthService.getUserRole() !== userRoles.carrier && <Fragment><strong>{t('email')}</strong>:&nbsp;{stop.eMail}</Fragment>}
      </Typography>
    </div>
  );
}

StopTableDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  stop: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(StopTableDetails);
