import React, {useCallback, useEffect, useState} from 'react'
import {Button, Grid, Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import PropTypes from 'prop-types';
import SideNavLayout from '../../../layouts/SideNavLayout';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import PondUploader from '../../../components/PondUploader';
import {ExcelTemplateService} from '../../../services/excelTemplateService/excelTemplateService';
import {TourService} from '../../../services/backend/tourService';
import {AuthService} from '../../../services/auth/authService';
import {GeneralInfoIcon, InfoIcon, UploadedExcelsTable} from '../../../components';


function DataUpload(props) {

  const {classes, t} = props;

  const [excels, setExcels] = useState([]);
  const [requiresReload, setRequiresReload] = useState(false);
  if (requiresReload) {
    setRequiresReload(false);
  }

  const loadExcels = useCallback(() => {
    TourService.getUploadedExcels(3, 7).then(response => response.json()).then(response => setExcels(response)
      , () => {
        alert(t('errorLoadingExcels'))
      })
  }, [t]);

  useEffect(() => {
    loadExcels()
  }, [loadExcels, requiresReload]);

  return (
    <SideNavLayout title={t('dataUpload')}>
      <div className={classes.root}>
        <div className={classes.helpWrapper}>
          <GeneralInfoIcon/>
        </div>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            style={{marginTop: '-50px'}}
            xs={12}
          >
            <Button
              className={classes.button}
              color="secondary"
              onClick={() => ExcelTemplateService.downloadTemplateStopCarrier(AuthService.getUserOrganization())}
              variant="contained"
            >
              {t('downloadStopTemplate')}
            </Button>
            <div className={classes.iconWrapper}>
              <Typography
                variant="h3"
              >
                {t('uploadStops')}
              </Typography>
              <InfoIcon
                className={classes.iconMargin}
                descriptionKey={'2'}
              />
            </div>
            <br/>
            <PondUploader
              fileName={'stops'}
              onLoadCallback={() => setRequiresReload(true)}
              uploaderOrganization={AuthService.getUserOrganization()}
              uploadType="carrier"
              url={'/ExcelImport/UploadStopsCarrier'}
            />
            <Typography
              variant="h3"
            >
              {t('uploadedExcels')}
            </Typography>
            <UploadedExcelsTable
              excels={excels}
            />
          </Grid>
        </Grid>
      </div>
    </SideNavLayout>
  );

}

DataUpload.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(DataUpload);
