import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {TemplateStopService} from 'services/backend/templateStopService';
import {TemplateStopType} from '../services/enums/TemplateStopType';

export default function useLoadTemplateStopByIdCache() {
  const [loadingSelectedTemplateStop, setLoadingSelectedTemplateStop] = useState(false);
  const [selectedTemplateStop, setSelectedTemplateStop] = useState(null);
  const [selectedRelatedTemplateStops, setSelectedRelatedTemplateStops] = useState([]);
  const [cache, setCache] = useState({});

  const { t } = useTranslation();

  const getTemplateStopById = async (templateStopId) => {
    resetSelectedTemplateStop();
    if (cache[templateStopId.toString()]) {
      setSelectedTemplateStop(cache[templateStopId.toString()].templateStop);
      setSelectedRelatedTemplateStops(cache[templateStopId.toString()].relatedTemplateStops);
    } else {
      setLoadingSelectedTemplateStop(true);
      try {
        const templateStop = await TemplateStopService.getTemplateStopById(templateStopId);
        setSelectedTemplateStop(templateStop);
        let relatedTemplateStops;
        if (templateStop.stopType === TemplateStopType.Pickup) {
          relatedTemplateStops = templateStop.pickupRelations;
        } else {
          relatedTemplateStops = templateStop.deliveryRelations;
        }
        setSelectedRelatedTemplateStops(relatedTemplateStops);
        setCache({ ...cache, [templateStopId.toString()]: { templateStop, relatedTemplateStops } });
        setLoadingSelectedTemplateStop(false);
      } catch {
        setLoadingSelectedTemplateStop(false);
        alert(t('errorLoadingTemplateStop'))
      }
    }
  };

  const resetLoadTemplateStopByIdCache = () => {
    setCache({});
  };

  const resetSelectedTemplateStop = () => {
    setSelectedTemplateStop(null);
    setSelectedRelatedTemplateStops([]);
  };

  const updateCache = (templateStop, relatedTemplateStops) => {
    setCache({ ...cache, [templateStop.id.toString()]: { templateStop, relatedTemplateStops } });
    if (selectedTemplateStop && selectedTemplateStop.id === templateStop.id) {
      setSelectedTemplateStop(templateStop);
      setSelectedRelatedTemplateStops(relatedTemplateStops);
    }
  };

  return { selectedTemplateStop, selectedRelatedTemplateStops, loadingSelectedTemplateStop, getTemplateStopById, resetLoadTemplateStopByIdCache, resetSelectedTemplateStop, updateCache };
}
