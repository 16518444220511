import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react'
import {Tab, Tabs, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {SideNavLayout} from '../../../layouts';
import {useQuery} from '@apollo/react-hooks';
import {TourService} from '../../../services/backend/tourService';
import {ClientService} from '../../../services/client/clientService';
import {clientTypes} from '../../../services/client/clientTypes';
import {AuthService} from '../../../services/auth/authService';
import {StopStatus} from '../../../services/enums/StopStatus';
import {GeneralInfoIcon, LoadingWrapper, RefreshButton, TextPlaceholderWrapper, TourFilterSmall} from '../../../components';
import AppBar from '@material-ui/core/AppBar';
import {MapDispositionOptimization, MapDispositionPlanning} from './components';
import administrationService from '../../../services/backend/administrationService';
import {useTourHub} from '../../../hooks/useTourHub';
import {stopMessageRelevantForTourFilter, tourMessageRelevantForFilter} from '../../../services/util/signalRMessageHelper';
import {useStopHub} from '../../../hooks/useStopHub';
import useLoadTourByIdCache from '../../../hooks/useLoadTourByIdCache';
import useShipperOptions from '../../../hooks/useShipperOptions';
import {VehicleQueryService} from '../../../services/backend/vehicleQueryService';
import {uniqBy} from 'lodash';
import {DatePickerDefaultValueContext} from '../../../context';
import {WarningRounded as WarnIcon} from '@material-ui/icons';
import useMicroHubOptionsOfCarrier from '../../../hooks/useMicroHubOptionsOfCarrier';

const clientVehicle = ClientService.getClient(clientTypes.vehicle);

const tabStates = {
  planning: 0,
  optimization: 1,
}

function MapDisposition(props) {
  const {classes, t} = props;
  const {fromDateDefault, toDateDefault, updateDefaultDate} = useContext(DatePickerDefaultValueContext);

  const [refresh, setRefresh] = useState(false);
  const [tours, setTours] = useState([]);
  const [displayApprovedToursExistWarning, setDisplayApprovedToursExistWarning] = useState(false);
  const [notPlanedStops, setNotPlanedStops] = useState([]);
  const [canNotDeliverStops, setCanNotDeliverStops] = useState([]);
  const {shipperOptions} = useShipperOptions();
  const [filter, setFilter] = useState({
    carrierName: AuthService.getUserOrganization(),
    microHubName: '',
    shipperName: '',
    fromDateTime: fromDateDefault,
    toDateTime: toDateDefault
  });
  useEffect(() => updateDefaultDate(filter.fromDateTime), [filter.fromDateTime, updateDefaultDate]);

  const [tabState, setTabState] = useState(tabStates.planning);
  const [backendLoadingTours, setBackendLoadingTours] = useState(false);
  const [backendLoadingStops, setBackendLoadingStops] = useState(false);
  const [tourTemplateFilter, setTourTemplateFilter] = useState('');
  // this is needed since it can not be stored persistent in the child component due to the loading wrapper
  const [selectedShipperNameOptimizationPersistence, setSelectedShipperNameOptimizationPersistence] = useState(null);

  const {microHubOptions, microHubData} = useMicroHubOptionsOfCarrier(AuthService.getUserOrganization(), true);

  const {selectedTour, loadingSelectedTour, resetLoadTourByIdCache, getTourById, resetSelectedTour, updateCache, updateMultiple} = useLoadTourByIdCache();

  const setSelectedTour = (tour) => {
    getTourById(tour.tourId);
  };

  useTourHub(useCallback(event => {
    if (tourMessageRelevantForFilter(event, filter)) {
      setRefresh(true);
    }
  }, [filter]));

  useStopHub(useCallback(event => {
    if (stopMessageRelevantForTourFilter(event, filter)) {
      setRefresh(true);
    }
  }, [filter]));

  const handleTourUpdate = (tour, neededUpdateBefore) => {
    const index = tours.findIndex(x => x.tourId === tour.tourId);
    if (neededUpdateBefore || index < 0) {
      loadTours(selectedTour, true);
    } else {
      const newTours = JSON.parse(JSON.stringify(tours));
      newTours[index] = tour;
      setTours(newTours);
      updateCache(tour);
      setRefresh(false);
    }
  };

  const handleTourUpdateMultiple = (toursToUpdate, neededUpdateBefore) => {
    if (neededUpdateBefore) {
      loadTours(selectedTour, true);
    } else {
      const newTours = JSON.parse(JSON.stringify(tours));
      toursToUpdate.forEach(tour => {
        const index = tours.findIndex(x => x.tourId === tour.tourId);
        if (index >= 0) {
          newTours[index] = tour;
        }
      });
      updateMultiple(toursToUpdate);
      setTours(newTours);
      setRefresh(false);
    }
  };

  const queryVehicles = VehicleQueryService.getVehiclesByCarrierNameAndMicroHubListQuery(AuthService.getUserOrganization(), microHubOptions);
  const {data: dataVehicles, loading: loadingVehicles} = useQuery(queryVehicles, {client: clientVehicle, skip: microHubOptions.length === 0});

  const loadTours = useCallback((selectedTour, noLoadingIndicator) => {
    // do not load Tours for optimization without microhubName
    if (tabState === tabStates.optimization && !filter.microHubName) return;

    if (!noLoadingIndicator) {
      setBackendLoadingTours(true);
    }
    resetLoadTourByIdCache();

    TourService.getToursWithFilter(filter.carrierName, filter.shipperName, filter.microHubName, filter.fromDateTime, filter.toDateTime).then(response => response.json()).then(response => {
      const editableTours = response.filter(t => !t.approved);
      setDisplayApprovedToursExistWarning(response.filter(t => t.approved).length > 0);
      setTours(editableTours)
      if (tabState === tabStates.planning) {
        const index = editableTours.findIndex(tour => selectedTour && tour.tourId === selectedTour.tourId);
        if (index >= 0) {
          getTourById(selectedTour.tourId)
        } else {
          resetSelectedTour()
        }
      }
      if (!noLoadingIndicator) setBackendLoadingTours(false);
      setRefresh(false);
    }, () => {
      alert(t('errorLoadingTours'));
      if (!noLoadingIndicator) setBackendLoadingTours(false);
      setRefresh(false);
    });
    // eslint-disable-next-line
  }, [filter, tabState, t]);

  const loadStops = useCallback((noLoadingIndicator) => {
    // Do not load stops for optimization
    if (tabState === tabStates.optimization) return;

    if (!noLoadingIndicator) {
      setBackendLoadingStops(true);
    }
    TourService.getStopsWithFilterIncludeStoragePlaces(AuthService.getUserOrganization(), null, null, StopStatus.NotPlaned, null, filter.toDateTime).then(response => response.json()).then(response => {
      setNotPlanedStops(response);
      if (!noLoadingIndicator) setBackendLoadingStops(false);
      setRefresh(false);
    }, () => {
      alert(t('errorLoadingStops'));
      if (!noLoadingIndicator) setBackendLoadingStops(false);
      setRefresh(false);
    });
    TourService.getCanNotDeliverStopsWithEndedTourWithFilter(AuthService.getUserOrganization(), null, null, null, filter.toDateTime).then(response => response.json()).then(response => {
      setCanNotDeliverStops(response);
      setRefresh(false);
    }, () => {
      alert(t('errorLoadingStops'));
      setRefresh(false);
    });
  }, [filter, t, tabState]);

  useEffect(() => {
    if (microHubOptions.length > 0) {
      loadTours();
      loadStops();
    }
  }, [loadTours, loadStops, microHubOptions]);

  const deleteStopFromTour = (stopId) => {
    const needsRefresh = JSON.parse(JSON.stringify(refresh));
    TourService.deleteStopFromTourById(stopId, selectedTour.tourId).then(response => response.json()).then(response => {
      handleTourUpdate(response, needsRefresh);
      loadStops(true);
    }, () => {
      alert(t('errorDeletingStopFromTour'));
      loadTours(selectedTour);
      loadStops();
    })
  };

  const deleteTour = (tourId) => {
    const needsRefresh = JSON.parse(JSON.stringify(refresh));
    TourService.deleteTour(tourId).then(() => {
      const newTours = JSON.parse(JSON.stringify(tours));
      const index = newTours.findIndex(t => t.tourId === tourId);
      if (index > -1) {
        newTours.splice(index, 1);
      }
      setTours(newTours);
      resetSelectedTour();
      if (needsRefresh) {
        loadTours(selectedTour, true);
      }
      loadStops(true);
    }, () => {
      alert(t('errorDeletingTour'));
      loadTours(selectedTour);
      loadStops();
    })
  };

  const deleteAllTours = (tours) => {
    const filteredToursById = tours.map(tour => tour.tourId);
    TourService.deleteAllTours(filteredToursById).then(() => {
      loadStops();
      loadTours();
    }, () => {
      alert(t('errorDeletingAllTours'));
    })
  };

  const startAlgoWithStops = async (stopsForAlgo, doNotMixShippersOnTour, useAlgorithm, useTourTemplates) => {
    setBackendLoadingTours(true);
    try {
      const response = await administrationService.runAlgorithmForCarrierStops(filter.fromDateTime, stopsForAlgo, doNotMixShippersOnTour, useAlgorithm, useTourTemplates);
      loadTours(selectedTour);
      loadStops();
      alert(`${t('usedStops')}: ${response.usedStops} \n ${t('buildTours')}: ${response.tourIds ? response.tourIds.length : 0} \n ${t('unassignedStops')}: ${response.unassignedStops ? response.unassignedStops.length : 0}`)
    } catch (e) {
      alert(`${t('errorBuildingTours')} ${e}`);
      loadTours(selectedTour);
      loadStops();
    }
  };

  const updateTourOrder = (tourWithStops) => {
    tourWithStops.stops.forEach((stop, index) => {
      stop.stopNumber = index + 1;
    });
    const needsRefresh = JSON.parse(JSON.stringify(refresh));
    TourService.changeStopOrderOnTour(tourWithStops, tourWithStops.tourId).then(response => response.json()).then((response) => {
      handleTourUpdate(response, needsRefresh);
    }, () => {
      alert(t('errorUpdatingTourData'));
      loadTours(selectedTour);
      loadStops();
    })
  };

  const changeTourOfStop = (sourceTourId, destinationTourId, stopId) => {
    const needsRefresh = JSON.parse(JSON.stringify(refresh));
    TourService.moveStopsToTour(destinationTourId, [stopId]).then(response => response.json()).then((response) => {
      const toursToUpdate = [response];
      const index = selectedTour.stops.findIndex(stop => stop.tourStopId === stopId);
      if (index >= 0) {
        const updatedTour = JSON.parse(JSON.stringify(selectedTour));
        updatedTour.stops.splice(index, 1);
        updatedTour.stops.forEach((s, index) => s.stopNumber = index + 1);
        toursToUpdate.push(updatedTour);
      }
      handleTourUpdateMultiple(toursToUpdate, needsRefresh);
    }, () => {
      alert(t('errorUpdatingTourData'));
      loadTours(selectedTour);
      loadStops();
    });
  };

  const editTourName = async (editedTour) => {
    try {
      // manually update for faster UI response time
      const changedTour = JSON.parse(JSON.stringify(editedTour));
      const index = tours.findIndex(x => x.tourId === editedTour.tourId);
      if (index >= 0) {
        tours[index] = changedTour;
        setTours(tours);
        updateCache(editedTour);
      }
      await TourService.updateTourName(editedTour.tourId, editedTour.tourName);
    } catch (error) {
      alert(`${t('errorUpdatingTour')}, ${error}`);
      loadTours();
      loadStops();
    }
  };

  const createTour = (newTour, numTimesToCreate) => {
    const needsRefresh = JSON.parse(JSON.stringify(refresh));
    TourService.createTours(newTour, numTimesToCreate).then(response => response.json()).then((createdTours) => {
      let newTours = JSON.parse(JSON.stringify(tours));
      newTours = newTours.concat(createdTours);
      setTours(newTours);
      if (needsRefresh) {
        loadTours(selectedTour, true);
        loadStops(true);
      }
    }, () => {
      alert(t('errorCreatingTour'));
      loadTours(selectedTour);
      loadStops();
    });
  };

  const addStopsToTour = (stops, autoOrderOnAdd) => {
    const newStops = JSON.parse(JSON.stringify(selectedTour.stops));
    stops.forEach(stop => {
      stop.stopNumber = newStops.length > 0 ? newStops[newStops.length - 1].stopNumber + 1 : 1;
      newStops.push(stop);
    });
    const needsRefresh = JSON.parse(JSON.stringify(refresh));
    if (autoOrderOnAdd) {
      TourService.moveStopsToTour(selectedTour.tourId, stops.map(stop => stop.tourStopId)).then(response => response.json()).then((response) => {
        handleTourUpdate(response, needsRefresh);
        loadStops(true);
      }, () => {
        alert(t('errorUpdatingTourData'));
        loadTours(selectedTour);
        loadStops();
      });
    } else {
      TourService.moveStopsToTourByClickOrder(selectedTour.tourId, stops.map(stop => stop.tourStopId)).then(response => response.json()).then((response) => {
        handleTourUpdate(response, needsRefresh);
        loadStops(true);
      }, () => {
        alert(t('errorUpdatingTourData'));
        loadTours(selectedTour);
        loadStops();
      });
    }
  };

  const updateOptimizedTours = async (tours) => {

    const setTourName = (tourName) => {
      if (!tourName) return tourName;
      return tourName.startsWith(t('newTourName')) ? null : tourName;
    }

    const update = {
      deliveryDate: filter.toDateTime,
      carrierName: filter.carrierName,
      microHubName: filter.microHubName,
      tours: tours.map((tour, index) => ({
        tourNumber: index,
        tourId: tour.tourId,
        tourName: setTourName(tour.tourName),
        vehicleLicensePlate: tour.vehicleLicensePlate,
        stops: tour.stops.map(stop => ({
          tourStopId: stop.tourStopId,
          stopNumber: stop.stopNumber
        }))
      }))
    }
    try {
      setBackendLoadingTours(true);
      await TourService.updateMasterDisposition(update);
    } catch (e) {
      alert(`${t('errorUpdatingMasterDisposition')}: ${e}`);
    }
    await loadTours(null);
  }

  return (
    <SideNavLayout title={t('mapDisposition')}>
      <AppBar
        className={classes.appBar}
        color={'secondary'}
        position={'relative'}
      >
        <Tabs
          onChange={(event, newValue) => {
            setTabState(newValue);
          }}
          value={tabState}
        >
          <Tab
            label={t('planning')}
            value={tabStates.planning}
          />
          <Tab
            label={t('optimization')}
            value={tabStates.optimization}
          />
        </Tabs>
      </AppBar>
      <div className={classes.root}>
        <div className={classes.filterRow}>
          <TourFilterSmall
            filter={filter}
            microHubOptions={microHubOptions}
            microHubRequired={tabState === tabStates.optimization}
            setFilter={setFilter}
            setTourTemplateFilter={setTourTemplateFilter}
            tourTemplateFilter={tourTemplateFilter}
            tourTemplateOptions={tabState === tabStates.optimization ? uniqBy(tours.map(t => t.stops.map(s => s.stopMetaData).filter(s => s)).flat(), x => x.tourTemplateId) : []}
          />
          {displayApprovedToursExistWarning &&
          <Typography>
            <WarnIcon className={classes.warningColor}/><strong>&nbsp;{t('plannedToursPresent')}</strong>
          </Typography>
          }
          {Boolean(notPlanedStops?.length) &&
          <Typography>
            <WarnIcon className={classes.warningColor}/><strong>&nbsp;{t('notPlannedStopsPresent')}</strong>
          </Typography>
          }
          <div className={classes.helpWrapper}>
            {tabState === tabStates.planning &&
            <RefreshButton
              className={classes.buttonRight}
              refresh={refresh}
              refreshFunc={() => {
                loadTours(selectedTour);
                loadStops()
              }}
            />
            }
            <GeneralInfoIcon/>
          </div>

        </div>
        <LoadingWrapper loading={backendLoadingTours || backendLoadingStops || loadingVehicles}>
          <Fragment>
            {(() => {
              switch (tabState) {
                case 0:
                  return (
                    <MapDispositionPlanning
                      addStopsToTour={addStopsToTour}
                      changeTourOfStop={changeTourOfStop}
                      createTour={createTour}
                      deleteAllTours={deleteAllTours}
                      deleteStopFromTour={deleteStopFromTour}
                      deleteTour={deleteTour}
                      editTourName={editTourName}
                      loadingSelectedTour={loadingSelectedTour}
                      microHubOptions={microHubOptions}
                      microHubs={microHubData}
                      notPlanedStops={notPlanedStops ? notPlanedStops : []}
                      selectedTour={selectedTour}
                      setSelectedTour={setSelectedTour}
                      shipperOptions={shipperOptions}
                      spareStops={(notPlanedStops ? notPlanedStops : []).concat(canNotDeliverStops ? canNotDeliverStops : [])}
                      startAlgoWithStops={startAlgoWithStops}
                      tours={tours}
                      updateTourOrder={updateTourOrder}
                    />
                  );
                case 1:
                  return (
                    <TextPlaceholderWrapper
                      active={!filter.microHubName}
                      text={t('mapDispositionOptimizationNoMicroHubSelected')}
                    >
                      <MapDispositionOptimization
                        filter={filter}
                        microHubs={microHubData}
                        selectedShipperStorage={selectedShipperNameOptimizationPersistence}
                        setSelectedShipperStorage={setSelectedShipperNameOptimizationPersistence}
                        tours={tours}
                        tourTemplateFilter={tourTemplateFilter}
                        updateTours={updateOptimizedTours}
                        vehicles={dataVehicles && dataVehicles.getVehiclesByCarrierNameAndMicroHubList ? dataVehicles.getVehiclesByCarrierNameAndMicroHubList : []}
                      />
                    </TextPlaceholderWrapper>
                  );
                default:
                  return (<div>error</div>);
              }
            })()}
          </Fragment>
        </LoadingWrapper>
      </div>
    </SideNavLayout>
  );
}

MapDisposition.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(MapDisposition);
