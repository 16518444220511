import React, {Fragment, useState} from 'react'
import {Box, Collapse, IconButton, TableCell, TableRow, Tooltip, withStyles} from '@material-ui/core';
import styles from '../styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon} from '@material-ui/icons';
import {getProductTypeString} from '../../../../../services/enums/ProductType';
import {roundCapacityValues} from '../../../../../services/util/helperFuncs';

function OtherBody(props) {

  const [open, setOpen] = useState(false);
  const {classes, className, t, product, canExpand} = props;

  return (
    <Fragment>
      <TableRow className={className}>
        <TableCell>{product.description}</TableCell>
        <TableCell>{roundCapacityValues(product.boxId)}</TableCell>
        <TableCell>{roundCapacityValues(product.amount)}</TableCell>
        <TableCell>{roundCapacityValues(product.volume)}</TableCell>
        {canExpand &&
        <TableCell>
          <IconButton
            onClick={() => setOpen(!open)}
            size="small"
            style={{padding: 0}}
          >
            {open ? <KeyboardArrowUpIcon style={{width: '17px', height: '17px'}}/> : <KeyboardArrowDownIcon style={{width: '17px', height: '17px'}}/>}
          </IconButton>
        </TableCell>
        }
      </TableRow>
      {canExpand &&
      <TableRow>
        <TableCell
          className={classes.largeTableCell}
          colSpan={5}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <Box margin={1}>
              <div className={classes.wrapper}>
                <div className={classes.wrapperChild}>
                  <Tooltip title={t('productPerUnit')}>
                    <div className={classes.productDetails}><strong>{t('productWeight')}</strong>: {roundCapacityValues(product.weight)}</div>
                  </Tooltip>
                </div>
                <div className={classes.wrapperChild}>
                  <div className={classes.productDetails}><strong>{t('productType')}</strong>: {t(getProductTypeString(product.productType))}</div>
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      }
    </Fragment>
  );
}


OtherBody.propTypes = {
  canExpand: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default compose(withStyles(styles), withTranslation())(OtherBody);
