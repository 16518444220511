import React, {useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Portlet} from '../../index'
import BoxTypeDetailEdit from './BoxTypeDetailEdit';
import BoxTypeDetailsShow from './BoxTypeDetailShow';

function BoxTypeDetails(props) {

  const [editMode, setEditMode] = useState(false);
  const [editedBoxType, setEditedBoxType] = useState(null);
  const [originalIdentifier, setOriginalIdentifier] = useState(null);
  const {classes, className, boxType, updateBoxType, readonly, closeDetail, shipperShortName} = props;

  const editBoxType = () => {
    const copy = JSON.parse(JSON.stringify(boxType));
    setOriginalIdentifier(boxType.identifier);
    copy.identifier = copy.identifier.substring(copy.identifier.indexOf('-') + 1);
    setEditedBoxType(copy);
    setEditMode(true);
  };

  const update = () => {
    setEditMode(false);
    updateBoxType({...editedBoxType, identifier: `${shipperShortName}-${editedBoxType.identifier}`}, originalIdentifier);
    setOriginalIdentifier(null);
  };

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet className={rootClassName}>
      {editMode ?
        <BoxTypeDetailEdit
          editedBoxType={editedBoxType}
          setEditedBoxType={setEditedBoxType}
          setEditMode={setEditMode}
          shipperShortName={shipperShortName}
          updateBoxType={update}
        />
        :
        <BoxTypeDetailsShow
          boxType={boxType}
          closeDetail={closeDetail}
          edit={editBoxType}
          readonly={readonly}
        />
      }
    </Portlet>
  );
}


BoxTypeDetails.propTypes = {
  boxType: PropTypes.object.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  closeDetail: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
  shipperShortName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  updateBoxType: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(BoxTypeDetails);
