import {withStyles} from '@material-ui/core';
import styles from './styles'
import classNames from 'classnames';
import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import LegendInteractive from '../../MapComponents/LegendInteractive';
import {TourMap} from '../../../index';

const TourMapWithLegend = props => {

  const {classes, className, tours, microHubs, notPlanedStops} = props;
  const [legendCheckboxes, setLegendCheckboxes] = useState({
    displayNotPlanedStops: true,
    displayMicroHubs: true
  });

  const rootClassName = classNames(classes.root, className);

  return (
    <Fragment>
      <div className={classes.legendControl}>
        <LegendInteractive
          hideStopStatus
          legendCheckboxesState={legendCheckboxes}
          setLegendCheckboxesState={setLegendCheckboxes}
        />
      </div>
      <TourMap
        className={rootClassName}
        displayMicroHubs={legendCheckboxes.displayMicroHubs}
        displayNotPlanedStops={legendCheckboxes.displayNotPlanedStops}
        displayStopStatus={false}
        microHubs={microHubs}
        notPlanedStops={notPlanedStops}
        toursWithStops={tours}
      />
    </Fragment>
  );
};

TourMapWithLegend.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  microHubs: PropTypes.array,
  notPlanedStops: PropTypes.array,
  tours: PropTypes.array,
};


export default withStyles(styles)(TourMapWithLegend);

