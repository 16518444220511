import React, {Fragment, useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from '@material-ui/core';
import styles from './styles';
import {
  CountDownTimer,
  DataLoadingWrapper,
  InfoIcon,
  LoadingWrapper,
  Portlet,
  PortletContent,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
} from '../../../components';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import {ArrowBack, ArrowForward} from '@material-ui/icons';
import moment from 'moment';
import {DateService} from '../../../services/util/DateService';
import {displayModes} from '../../../services/enums/displayModes';
import DeadlineCardRow from './components/DeadlineCardRow';
import {TourService} from '../../../services/backend/tourService';


function DeadlineCard(props) {

  const {t, classes, className, isLoading, deadlineData, error, displayMode, filter} = props;

  const [date, setDate] = useState(new Date());
  const [isLoadingMigrationEvents, setIsLoadingMigrationEvents] = useState(false);
  const rootClassName = classNames(classes.root, className);

  const [migrationEvents, setMigrationEvents] = useState([]);

  const getMigrations = useCallback(async () => {
    if (!filter) return;
    try {
      setIsLoadingMigrationEvents(true);
      const hubList = filter.microHubName ? [filter.microHubName] : [];
      const result = await TourService.getMigrationsForDate(date, null, filter.carrierName, hubList);
      setMigrationEvents(result);
      setIsLoadingMigrationEvents(false);
    } catch (e) {
      setIsLoadingMigrationEvents(false);
    }
  }, [filter, date]);

  useEffect(() => {
    getMigrations();
  }, [getMigrations])

  const timeZoneOffsetSec = new Date().getTimezoneOffset() * 60;

  return (
    <Portlet
      className={rootClassName}
    >
      <PortletHeader className={classes.portletHeader}>
        <PortletLabel title={t('dashboardDeadlineOverview')}/>
        <PortletToolbar>
          <IconButton
            onClick={() => setDate(moment(date).subtract(1, 'day').toDate())}
            variant="text"
          >
            <ArrowBack/>
          </IconButton>
          <div>
            {DateService.displayDate(date)}
          </div>
          <IconButton
            onClick={() => setDate(moment(date).add(1, 'day').toDate())}
            variant="text"
          >
            <ArrowForward/>
          </IconButton>
        </PortletToolbar>
        <InfoIcon descriptionKey={'10_preFormat'}/>
      </PortletHeader>
      <PortletContent
        className={classes.portletContentStyle}
        noPadding
      >
        <DataLoadingWrapper
          error={error}
          isLoading={isLoading}
        >
          {(() => {
            switch (displayMode) {
              case displayModes.carrier:
              case displayModes.admin:
                return (
                  <div className={classes.contentContainer}>
                    <LoadingWrapper loading={isLoadingMigrationEvents}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell><Typography><strong>{t('shipper')}</strong></Typography></TableCell>
                            <TableCell><Typography><strong>{t('preview')}</strong></Typography></TableCell>
                            <TableCell><Typography><strong>{t('final')}</strong></Typography></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {deadlineData && deadlineData.map((shipper, index) =>
                            <DeadlineCardRow
                              date={date}
                              key={index}
                              migrationEvents={migrationEvents.filter(x => x.shipperName === shipper.name)}
                              shipper={shipper}
                            />
                          )}
                        </TableBody>
                      </Table>
                    </LoadingWrapper>

                  </div>
                );
              case displayModes.shipper:
                return (
                  <Grid
                    className={classes.grid}
                    container
                    spacing={0}
                  >
                    {deadlineData && deadlineData.length > 0 && [deadlineData[0]].map(shipper => {
                      const finalDeadline = moment(date).startOf('day').add(shipper.finalDeadline - timeZoneOffsetSec, 'seconds').add(15, 'minutes').toDate();
                      const finalDeadlineOver = moment(finalDeadline).diff(moment()) <= 0;
                      const previewDeadline = moment(date).startOf('day').add(shipper.previewDeadline - timeZoneOffsetSec, 'seconds').toDate();
                      const previewDeadlineOver = moment(previewDeadline).diff(moment()) <= 0;
                      const finalDeadlineStops = moment(date).startOf('day').add(shipper.finalDeadline - timeZoneOffsetSec, 'seconds').toDate();
                      const finalDeadlineStopsOver = moment(finalDeadlineStops).diff(moment()) <= 0;
                      return (
                        <Fragment key={shipper.id}>
                          <Grid
                            className={classes.gridItem}
                            item
                            lg={6}
                            sm={6}
                            xl={6}
                            xs={6}
                          >
                            <Typography
                              className={classes.centerHorizontal}
                              variant={'h5'}
                            >
                              <strong>{t('dashboardPreviewDeadlineStopsShipper')}</strong>
                            </Typography>
                            <div className={classes.centerHorizontalAndVertical}>
                              {previewDeadlineOver ? t('deadlineReached') : <CountDownTimer deadlineDate={previewDeadline}/>}
                            </div>
                          </Grid>
                          <Grid
                            className={classes.gridItem}
                            item
                            lg={6}
                            sm={6}
                            xl={6}
                            xs={6}
                          >
                            <Typography
                              className={classes.centerHorizontal}
                              variant={'h5'}
                            >
                              <strong>{t('dashboardPreviewDeadlineShipper')}</strong>
                            </Typography>
                            <div className={classes.centerHorizontalAndVertical}>
                              {previewDeadlineOver ? t('deadlineReachedShipper') : <CountDownTimer deadlineDate={previewDeadline}/>}
                            </div>
                          </Grid>
                          <Grid
                            className={classes.gridItem}
                            item
                            lg={6}
                            sm={6}
                            xl={6}
                            xs={6}
                          >
                            <Typography
                              className={classes.centerHorizontal}
                              variant={'h5'}
                            >
                              <strong>{t('dashboardFinalDeadlineStopsShipper')}</strong>
                            </Typography>
                            <div className={classes.centerHorizontalAndVertical}>
                              {finalDeadlineStopsOver ? t('deadlineReached') : <CountDownTimer deadlineDate={finalDeadlineStops}/>}
                            </div>
                          </Grid>
                          <Grid
                            className={classes.gridItem}
                            item
                            lg={6}
                            sm={6}
                            xl={6}
                            xs={6}
                          >
                            <Typography
                              className={classes.centerHorizontal}
                              variant={'h5'}
                            >
                              <strong>{t('dashboardFinalDeadlineShipper')}</strong>
                            </Typography>
                            <div className={classes.centerHorizontalAndVertical}>
                              {finalDeadlineOver ? t('deadlineReachedShipper') : <CountDownTimer deadlineDate={finalDeadline}/>}
                            </div>
                          </Grid>
                        </Fragment>
                      );
                    })}
                  </Grid>

                );
              default:
                return (<div>Error: invalid displayMode</div>)
            }
          })()}
        </DataLoadingWrapper>
      </PortletContent>
    </Portlet>
  );
}

DeadlineCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deadlineData: PropTypes.array.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  error: PropTypes.object,
  filter: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(DeadlineCard);
