import {MyUserManager} from './userManager';

let user = null;
let userHasLoaded = false;
let eventsAdded = false;

export class AuthService {

  static loadUser() {
    if (!eventsAdded) {
      MyUserManager.events.addUserSignedOut(() => {
        if (this.isLoggedIn()) {
          this.logout().then()
        }
      });
      eventsAdded = true;
    }
    return MyUserManager.getUser().then(x => user = x).then(() => userHasLoaded = true);
  }

  static getUser() {
    return user;
  }

  static getAuthToken() {
    return user ? user.access_token : null;
  }

  static getAuthHeader() {
    const access_token = this.getAuthToken();
    return access_token ? `Bearer ${access_token}` : '';
  }

  static getUserRole() {
    return user ? user.profile.role : null;
  }

  static getUserPermission() {
    return user ? user.profile.permission : null;
  }

  static getUserOrganization() {
    return user ? user.profile.organization : null;
  }

  static getUserEmail() {
    return user ? user.profile.email : null;
  }

  static getPrivacyConfirmation() {
    return user ? user.profile.privacyConfirmation : null;
  }

  static setPrivacyConfirmation(value) {
    user.profile.privacyConfirmation = value;
  }

  static getUserHasLoaded() {
    return userHasLoaded;
  }

  static isLoggedIn() {
    return user && user.access_token && !user.expired
  }

  static login() {
    return MyUserManager.signinRedirect();
  }

  static signinSilent() {
    return MyUserManager.signinSilent()
      .then(x => {
        user = x;
        document.cookie = 'WS-Authorization=' + AuthService.getAuthToken() + '; path=/; samesite=strict; secure';
      });
  }

  static logout() {
    return MyUserManager.signoutRedirect()
      .then(() => MyUserManager.removeUser())
      .then(() => {
        user = null;
        this.removeWsAuthCookie();
      });
  }

  static removeWsAuthCookie() {
    document.cookie.split(';').forEach((c) => {
      if (!c.includes('WS-Authorization')) return;
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
  }
}
