import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import SideNavLayout from '../../../layouts/SideNavLayout';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {GeneralInfoIcon, SelectMap, SelectMapFilter} from '../../../components';
import {AuthService} from '../../../services/auth/authService';
import {ClientService} from '../../../services/client/clientService';
import {clientTypes} from '../../../services/client/clientTypes';
import {useQuery} from '@apollo/react-hooks';
import DataLoadingWrapper from '../../../components/DataLoadingWrapper';
import {MicroHubQueryService} from '../../../services/backend/microHubQueryService';
import {ShipperQueryService} from '../../../services/backend/shipperQueryService';
import useMicroHubOptionsOfCarrier from '../../../hooks/useMicroHubOptionsOfCarrier';

const clientMicrohub = ClientService.getClient(clientTypes.microHub);
const shipperClient = ClientService.getClient(clientTypes.shipper);

function AreaSelection(props) {

  const {classes, t} = props;
  const [polygons, setPolygons] = useState([]);
  const [filter, setFilter] = useState({
    shipperName: '',
    carrierName: AuthService.getUserOrganization()
  });

  const {microHubOptions, microHubData} = useMicroHubOptionsOfCarrier(AuthService.getUserOrganization(), true);

  const queryShipperNames = ShipperQueryService.getAllShipperNames();
  const {data: shipperNames} = useQuery(queryShipperNames, {client: shipperClient});

  const queryHubs = MicroHubQueryService.getHubAreasByNameListQuery(microHubOptions, filter.carrierName, filter.shipperName);
  const {error, loading, data} = useQuery(queryHubs, {client: clientMicrohub, skip: microHubOptions.length === 0});

  useEffect(() => {
    if (data?.microhubs) {
      const assemblePolygons = [];
      data.microhubs.forEach(hub => {
        if (hub.carriers && hub.carriers.length > 0) {
          if (hub.carriers.length !== 1) {
            console.warn('Hub ' + hub.name + ' had multiple carriers')
          }
          const coordinates = [];
          hub.carriers[0].coordinates.sort((c1, c2) => (c1.order > c2.order) ? 1 : ((c2.order > c1.order) ? -1 : 0));
          hub.carriers[0].coordinates.forEach(coordinate => coordinates.push([coordinate.latitude, coordinate.longitude]));
          if (coordinates.length > 0) {
            assemblePolygons.push({name: hub.name, coordinates: coordinates})
          }
        }
      });
      setPolygons(assemblePolygons);
    }
  }, [data]);

  return (
    <SideNavLayout title={t('areaSelection')}>
      <div className={classes.root}>
        <DataLoadingWrapper
          error={error}
          isLoading={loading}
        >
          <GeneralInfoIcon className={classes.helpWrapper}/>
          <SelectMapFilter
            filter={filter}
            setFilter={setFilter}
            shipperOptions={shipperNames ? shipperNames.shippers.map(s => s.name) : []}
          />
          {data?.microhubs &&
          <SelectMap
            className={classes.map}
            dirty={false}
            microHubs={microHubData}
            polygons={polygons}
            readonly
            setNewPolygonsSet={() => {
            }}
          />
          }
        </DataLoadingWrapper>
      </div>
    </SideNavLayout>
  );

}

AreaSelection.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(AreaSelection);
