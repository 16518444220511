import {AppBar, Tab, Tabs, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {SideNavLayout} from 'layouts';
import React, {useContext, useState} from 'react';
import {
  TemplateStopFilter,
  TemplateStopInstanceFilter,
  TemplateStopInstanceOverview as TemplateStopInstanceOverviewComponent,
  TemplateStopOverview as TemplateStopOverviewComponent
} from 'components';
import {AuthService} from 'services/auth/authService';
import useCarriersWithHubs from 'hooks/useCarriersWithHubs';
import {displayModes} from 'services/enums/displayModes';
import { DatePickerDefaultValueContext } from 'context';

const tabStates = {
  templateStop: 0,
  templateStopInstance: 1,
}

function TemplateStopInstanceOverview(props) {
  const { classes, t } = props;
  const [tabState, setTabState] = useState(tabStates.templateStop);
  const { fromDateDefault, toDateDefault } = useContext(DatePickerDefaultValueContext);
  const [filter, setFilter] = useState({
    shipperName: AuthService.getUserOrganization(),
    fromDate: fromDateDefault,
    toDate: toDateDefault,
  });
  const [searchInput, setSearchInput] = useState('');
  const { carrierOptions } = useCarriersWithHubs();

  return (
    <SideNavLayout title={t('templateStopInstanceOverview')}>
      <AppBar
        className={classes.appBar}
        color={'secondary'}
        position={'relative'}
      >
        <Tabs
          onChange={(event, newValue) => {
            setTabState(newValue);
          }}
          value={tabState}
        >
          <Tab
            label={t('templateStop')}
          />
          <Tab
            label={t('templateStopInstance')}
          />
        </Tabs>
      </AppBar>
      <div className={classes.root}>
        {(() => {
          switch (tabState) {
            case tabStates.templateStop:
              return (
                <div>
                  <TemplateStopFilter
                    filter={filter}
                    searchInput={searchInput}
                    setFilter={setFilter}
                    setSearchInput={setSearchInput}
                    displayMode={displayModes.shipper}
                    useSearch
                  />
                  <br />
                  <TemplateStopOverviewComponent
                    filter={filter}
                    searchInput={searchInput}
                    displayMode={displayModes.shipper}
                  />
                </div>
              );
            case tabStates.templateStopInstance:
              return (
                <div><TemplateStopInstanceFilter
                  filter={filter}
                  searchInput={searchInput}
                  setFilter={setFilter}
                  setSearchInput={setSearchInput}
                  useSearch
                  carrierOptions={carrierOptions}
                  displayMode={displayModes.shipper}
                />
                  <br />
                  <TemplateStopInstanceOverviewComponent filter={filter} searchInput={searchInput} displayMode={displayModes.shipper} />
                </div>
              );
            default:
              return (<div>Error</div>);
          }
        })()}
      </div>
    </SideNavLayout>
  );
}

TemplateStopInstanceOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TemplateStopInstanceOverview);