import React from 'react'
import {InfoIcon, TourIdLabel} from '../../index';
import {DateService} from '../../../services/util/DateService';
import {roundCapacityValues} from '../../../services/util/helperFuncs';

const Columns = {
  tourId: {
    name: 'tourId',
    header: ({t}) => t('tourId'),
    body: ({statistic, classes}) => (
      <div className={classes.idTableCell}>
        {<TourIdLabel tour={{tourId: statistic.tourId}}/>}
      </div>
    )
  },
  length: {
    name: 'length',
    header: ({t}) => t('length'),
    body: ({statistic}) => statistic.length.toFixed(2)
  },
  statisticsDuration: {
    name: 'statisticsDuration',
    header: ({t}) => t('statisticsDuration'),
    body: ({statistic}) => DateService.displayTimeFromSeconds(statistic.duration * 60)
  },
  stops: {
    name: 'stops',
    header: ({t}) => t('stops'),
    body: ({statistic}) => statistic.stops
  },
  boxes: {
    name: 'boxes',
    header: ({t}) => t('boxes'),
    body: ({statistic}) => statistic.boxes
  },
  tourPacking: {
    name: 'tourPacking',
    header: ({t}) => t('tourPacking'),
    body: ({statistic}) => DateService.displayTimeFromSeconds(statistic.timeToPack)
  },
  avgTimeToPackPBox: {
    name: 'avgTimeToPackPBox',
    header: ({t}) => t('avgTimeToPackPBox'),
    body: ({statistic}) => `${Math.ceil(statistic.avgTimeToPackPBox)} s`
  },
  avgBoxesPStop: {
    name: 'avgBoxesPStop',
    header: ({t}) => t('avgBoxesPStop'),
    body: ({statistic}) => roundCapacityValues(statistic.avgBoxesPStop)
  },
  avgDistPStop: {
    name: 'avgDistPStop',
    header: ({t}) => t('avgDistPStop'),
    body: ({statistic}) => roundCapacityValues(statistic.avgDistPStop)
  },
  avgStopsPHour: {
    name: 'avgStopsPHour',
    header: ({t}) => t('avgStopsPHour'),
    body: ({statistic}) => roundCapacityValues(statistic.avgStopsPHour)
  },
  avgStopsPHourWTimeToPack: {
    name: 'avgStopsPHourWTimeToPack',
    header: ({t}) => t('avgStopsPHourWTimeToPack'),
    body: ({statistic}) => roundCapacityValues(statistic.avgStopsPHourWTimeToPack)
  },
  percentBikeUsageVolume: {
    name: 'percentBikeUsageVolume',
    header: ({t}) => t('percentBikeUsageVolume'),
    body: ({statistic}) => roundCapacityValues(statistic.percentBikeUsageVolume)
  },
  percentBikeUsageWeight: {
    name: 'percentBikeUsageWeight',
    header: ({t}) => (
        <div>
          {t('percentBikeUsageWeight')}
          <InfoIcon descriptionKey={'2'}/>
        </div>
    
    ),
    body: ({statistic}) => roundCapacityValues(statistic.percentBikeUsageWeight)
  }
}

export default Columns;