import React, {useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {AuthService} from '../../../services/auth/authService';
import {default as DriverEdit} from '../DriverEdit';
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import {onCloseWithBackdropCurry} from '../../../services/util/helperFuncs';

function CreateDriverDialog(props) {

  const [newDriver, setNewDriver] = useState({
    bicycle: false,
    car: false,
    carrierName: AuthService.getUserOrganization(),
    email: '',
    displayName: '',
    firstname: '',
    lastname: '',
    microhubs: [],
    phoneNumber: '',
    truck: false,
  });

  const {classes, t, createDriverDialogState, microHubOptions, handleClose, handleCancel} = props;

  const resetState = () => {
    setNewDriver({
      bicycle: false,
      car: false,
      carrierName: AuthService.getUserOrganization(),
      email: '',
      displayName: '',
      firstname: '',
      lastname: '',
      microhubs: [],
      phoneNumber: '',
      truck: false,
    });
  };

  const handleOk = () => {
    handleClose(newDriver);
    resetState();
  };

  const handleCancelInner = () => {
    handleCancel();
    resetState();
  };


  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={createDriverDialogState.open}
    >
      <DialogTitle>{t('createDriver')}</DialogTitle>
      <DialogContent>
        <div className={classes.container}>
          <DriverEdit
            className={classes.container}
            editedDriver={newDriver}
            microHubOptions={microHubOptions}
            setEditedDriver={setNewDriver}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={handleCancelInner}
        >
          <CancelIcon/>&nbsp;{t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          disabled={!newDriver.email || !newDriver.displayName || newDriver.displayName.length > 10 || newDriver.microhubs.length === 0}
          onClick={handleOk}
        >
          <SaveIcon/>&nbsp;{t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CreateDriverDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  createDriverDialogState: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  microHubOptions: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(CreateDriverDialog);
