import React, {Fragment, useState} from 'react';
import classNames from 'classnames';
import styles from './styles';
import {Pagination, Portlet, PortletContent, TextPlaceholderWrapper} from '../../../../../components';
import {
  Button,
  FormControl,
  Input,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles
} from '@material-ui/core';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import Select from '@material-ui/core/Select';
import {Add as AddIcon, DeleteForever as DeleteIcon} from '@material-ui/icons';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import useTablePagination from '../../../../../hooks/useTablePagination';


function TransferRulesForShippersTable(props) {

  const {
    classes,
    className,
    deleteTransferRuleForShipper,
    transferRulesForShippers,
    t,
    microHubs,
    createTransferRuleForShipper,
    selectedShipper
  } = props;

  const [allowedAdd, setAllowedAdd] = useState(false);
  const [newTransferRuleForShipper, setNewTransferRuleForShipper] = useState({
    shipperName: '',
    microhubName: '',
    reversedStopOrder: false,
    reversedTourOrder: false,
    reversedAllOrder: false
  });

  const {paginationProps, pagedObjects} = useTablePagination(transferRulesForShippers);


  const createTransferRule = () => {
    newTransferRuleForShipper.shipperName = selectedShipper;
    createTransferRuleForShipper(newTransferRuleForShipper);
    setNewTransferRuleForShipper({
      shipperName: '',
      microhubName: '',
      reversedStopOrder: false,
      reversedTourOrder: false,
      reversedAllOrder: false
    })
    setAllowedAdd(false);
  }

  const rootClassName = classNames(classes.root, className);

  return (
    <TextPlaceholderWrapper
      active={!transferRulesForShippers || !selectedShipper}
      text={t('noTransferRuleForShipperFound')}
    >
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell className={classes.alignLeft}>{t('transferRulesForShippersId')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('shipper')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('microHub')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('reversedStopOrder')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('reversedTourOrder')}</TableCell>
                <TableCell className={classes.alignLeft}/>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedObjects.map((transferRule, index) => (
                <TableRow
                  className={classes.tableRow}
                  hover
                  key={index}
                >
                  <TableCell className={classes.tableCell}>
                    {transferRule.systemId}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {transferRule.shipperName}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {transferRule.microhubName}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {
                      <Checkbox
                        checked={transferRule.reversedStopOrder}
                        disabled
                      />
                    }
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {
                      <Checkbox
                        checked={transferRule.reversedTourOrder}
                        disabled
                      />
                    }
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Button
                      className={classes.deleteButton}
                      color="inherit"
                      onClick={() => {
                        if (window.confirm(t('confirmDeleteTransferRuleForShippers'))) deleteTransferRuleForShipper(transferRule)
                      }}
                      title={t('deleteTransferRuleForShippers')}
                    >
                      <DeleteIcon/>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                {allowedAdd ?
                  <Fragment>
                    <TableCell className={classes.tableCell}>
                      {'-'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {selectedShipper}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <FormControl className={classes.formControl}>
                        <Select
                          input={<Input id="microhub"/>}
                          onChange={(event) => setNewTransferRuleForShipper({
                            ...newTransferRuleForShipper,
                            microhubName: event.target.value
                          })}
                          value={newTransferRuleForShipper.microhubName}
                        >
                          {microHubs && microHubs.filter(value => !transferRulesForShippers.map(e => e.microhubName).includes(value.name)).map((microhub, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={microhub.name}
                              >
                                {microhub.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <FormControl>
                        <Checkbox
                          input={<Input id="reversedStopOrder"/>}
                          onChange={(event) => setNewTransferRuleForShipper(({
                            ...newTransferRuleForShipper,
                            reversedStopOrder: event.target.checked
                          }))}
                          value={newTransferRuleForShipper.reversedStopOrder}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <FormControl>
                        <Checkbox
                          input={<Input id="reversedTourOrder"/>}
                          onChange={(event) => setNewTransferRuleForShipper(({
                            ...newTransferRuleForShipper,
                            reversedTourOrder: event.target.checked
                          }))}
                          value={newTransferRuleForShipper.reversedTourOrder}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Button
                        color="primary"
                        disabled={!newTransferRuleForShipper.microhubName}
                        onClick={createTransferRule}
                        style={{padding: 0}}
                        title={t('createTransferRuleForShipper')}
                      >
                        <AddIcon/>
                      </Button>
                    </TableCell>
                  </Fragment>
                  :
                  <Fragment>
                    <TableCell colSpan={6}>
                      <Button
                        color="primary"
                        onClick={() => setAllowedAdd((!allowedAdd))}
                        style={{padding: 0}}
                        title={t('createTransferRuleForShipper')}
                      >
                        <AddIcon/>
                      </Button>
                    </TableCell>
                  </Fragment>
                }
              </TableRow>
            </TableBody>
          </Table>
          <Pagination paginationProps={paginationProps}/>
        </PortletContent>
      </Portlet>
    </TextPlaceholderWrapper>
  );
}

TransferRulesForShippersTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  createTransferRuleForShipper: PropTypes.func.isRequired,
  deleteTransferRuleForShipper: PropTypes.func.isRequired,
  microHubs: PropTypes.array.isRequired,
  selectedShipper: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  transferRulesForShippers: PropTypes.array.isRequired,
};

TransferRulesForShippersTable.defaultProps = {
  transferRulesForShippers: []
}

export default compose(withStyles(styles), withTranslation())(TransferRulesForShippersTable);
