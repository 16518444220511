import React, {useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Portlet} from '../../index'
import MicroHubSensorConfigDetailEdit from './MicroHubSensorConfigDetailEdit';
import MicroHubSensorConfigDetailsShow from './MicroHubSensorConfigDetailShow';

function MicroHubSensorConfigDetails(props) {

  const [editMode, setEditMode] = useState(false);
  const [editedMicroHubSensorConfig, setEditedMicroHubSensorConfig] = useState(null);
  const {classes, className, microHubSensorConfig, updateMicroHubSensorConfig, microHubNames, readonly, closeDetail} = props;

  const editMicroHubSensorConfig = () => {
    const copy = JSON.parse(JSON.stringify(microHubSensorConfig));
    setEditedMicroHubSensorConfig(copy);
    setEditMode(true);
  };

  const update = () => {
    setEditMode(false);
    updateMicroHubSensorConfig({...editedMicroHubSensorConfig});
  };

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet className={rootClassName}>
      {editMode ?
        <MicroHubSensorConfigDetailEdit
          editedMicroHubSensorConfig={editedMicroHubSensorConfig}
          setEditedMicroHubSensorConfig={setEditedMicroHubSensorConfig}
          setEditMode={setEditMode}
          updateMicroHubSensorConfig={update}
          microHubNames={microHubNames}
        />
        :
        <MicroHubSensorConfigDetailsShow 
          microHubSensorConfig={microHubSensorConfig}
          closeDetail={closeDetail}
          edit={editMicroHubSensorConfig}
          readonly={readonly}
        />
      }
    </Portlet>
  );
}


MicroHubSensorConfigDetails.propTypes = {
  microHubSensorConfig: PropTypes.object.isRequired,
  microHubNames: PropTypes.array.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  closeDetail: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
  t: PropTypes.func.isRequired,
  updateMicroHubSensorConfig: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(MicroHubSensorConfigDetails);
