import React from 'react'
import {TextField, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import {Portlet, PortletContent} from '../../../../index';
import {containsInjectionChars} from '../../../../../services/util/helperFuncs';

function TrackingLinkInfoEdit(props) {
  const {classes, className, t, trackingLinkInfos, setTrackingLinkInfos} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet className={rootClassName}>
      <PortletContent>
        <Typography variant="h3">
          {t('trackingLinkInfos')}
        </Typography>
        <hr/>
        <div className={classes.container}>
          <TextField
            className={classes.formControl}
            error={containsInjectionChars(trackingLinkInfos.logoName)}
            label={t('trackingShipperLogoName')}
            onChange={(e) => setTrackingLinkInfos({...trackingLinkInfos, logoName: e.target.value})}
            value={trackingLinkInfos.logoName}
          />
        </div>
        <div className={classes.container}>
          <TextField
            className={classes.formControl}
            error={containsInjectionChars(trackingLinkInfos.marketingImageName)}
            label={t('trackingShipperMarketingImageName')}
            onChange={(e) => setTrackingLinkInfos({...trackingLinkInfos, marketingImageName: e.target.value})}
            value={trackingLinkInfos.marketingImageName}
          />
        </div>
        <div className={classes.container}>
          <TextField
            className={classes.formControl}
            error={containsInjectionChars(trackingLinkInfos.primaryColor)}
            label={t('trackingShipperPrimaryColor')}
            onChange={(e) => setTrackingLinkInfos({...trackingLinkInfos, primaryColor: e.target.value})}
            value={trackingLinkInfos.primaryColor}
          />
          <span style={{backgroundColor: trackingLinkInfos.primaryColor}}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </div>
        <div className={classes.container}>
          <TextField
            className={classes.formControl}
            error={containsInjectionChars(trackingLinkInfos.primaryTextColor)}
            label={t('trackingShipperPrimaryTextColor')}
            onChange={(e) => setTrackingLinkInfos({...trackingLinkInfos, primaryTextColor: e.target.value})}
            value={trackingLinkInfos.primaryTextColor}
          />
          <span style={{backgroundColor: trackingLinkInfos.primaryTextColor}}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </div>
        <div className={classes.container}>
          <TextField
            className={classes.formControl}
            error={containsInjectionChars(trackingLinkInfos.primaryContrastTextColor)}
            label={t('trackingShipperPrimaryContrastTextColor')}
            onChange={(e) => setTrackingLinkInfos({...trackingLinkInfos, primaryContrastTextColor: e.target.value})}
            value={trackingLinkInfos.primaryContrastTextColor}
          />
          <span style={{backgroundColor: trackingLinkInfos.primaryContrastTextColor}}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </div>
        <div className={classes.container}>
          <TextField
            className={classes.formControl}
            error={containsInjectionChars(trackingLinkInfos.secondaryColor)}
            label={t('trackingShipperSecondaryColor')}
            onChange={(e) => setTrackingLinkInfos({...trackingLinkInfos, secondaryColor: e.target.value})}
            value={trackingLinkInfos.secondaryColor}
          />
          <span style={{backgroundColor: trackingLinkInfos.secondaryColor}}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </div>
        <div className={classes.container}>
          <TextField
            className={classes.formControl}
            error={containsInjectionChars(trackingLinkInfos.secondaryTextColor)}
            label={t('trackingShipperSecondaryTextColor')}
            onChange={(e) => setTrackingLinkInfos({...trackingLinkInfos, secondaryTextColor: e.target.value})}
            value={trackingLinkInfos.secondaryTextColor}
          />
          <span style={{backgroundColor: trackingLinkInfos.secondaryTextColor}}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </div>
      </PortletContent>
    </Portlet>
  );
}

TrackingLinkInfoEdit.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  setTrackingLinkInfos: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  trackingLinkInfos: PropTypes.object.isRequired,
};


export default compose(withStyles(styles), withTranslation())(TrackingLinkInfoEdit);
