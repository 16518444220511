export default theme => ({
  root: {},
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dialog: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: '200px',
  },
  wrapper: {
    display: 'flex',
    margin: theme.spacing(2)
  },
  wrapperChild: {
    flex: 1,
  },
  vehicleDetail: {
    margin: theme.spacing(1)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  iconMargin: {
    marginLeft: '8px'
  }
});
