import React from 'react'
import {Table, TableBody, TableCell, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {Pagination, Portlet, PortletContent, TextPlaceholderWrapper} from '../../../components'
import classNames from 'classnames';
import {DateService} from '../../../services/util/DateService';
import useTablePagination from '../../../hooks/useTablePagination';

function DeletedStopsTable(props) {

  const {classes, t, deletedStops, className, selectedStop, setSelectedStop} = props;

  const {paginationProps, pagedObjects} = useTablePagination(deletedStops);

  const rootClassName = classNames(classes.root, className);

  return (
    <TextPlaceholderWrapper
      active={!deletedStops?.length}
      text={t('noDeletedStopSAvailable')}
    >
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell>{t('tourStopId')}</TableCell>
                <TableCell>{t('lastName')}</TableCell>
                <TableCell>{t('customerId')}</TableCell>
                <TableCell>{t('deliveryDate')}</TableCell>
                <TableCell>{t('shipper')}</TableCell>
                <TableCell>{t('carrier')}</TableCell>
                <TableCell>{t('microHub')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedObjects.map((stop, index) => (
                <TableRow
                  className={classNames(classes.tableRow, 'cursorPointer')}
                  hover
                  key={index}
                  onClick={() => setSelectedStop(stop)}
                  selected={selectedStop && stop.stopId === selectedStop.stopId}
                >
                  <TableCell>{stop.stopId}</TableCell>
                  <TableCell>{stop.name}</TableCell>
                  <TableCell>{stop.customerId}</TableCell>
                  <TableCell>{DateService.displayDate(stop.deliveryDate)}</TableCell>
                  <TableCell>{stop.shipperName}</TableCell>
                  <TableCell>{stop.carrierName}</TableCell>
                  <TableCell>{stop.microHubName}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination paginationProps={paginationProps}/>
        </PortletContent>
      </Portlet>
    </TextPlaceholderWrapper>
  );
}


DeletedStopsTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deletedStops: PropTypes.array.isRequired,
  selectedStop: PropTypes.object,
  setSelectedStop: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}
;

export default compose(withStyles(styles), withTranslation())(DeletedStopsTable);
