export default theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  inlineBlock: {
    display: 'inline-block',
    fontSize: '0.9rem'
  },
  innerTable: {
    border: `1px solid ${theme.palette.border}`
  },
  innerTableCell: {
    padding: 0
  },
  outerTableRow: {
    padding: 0
  },
  lastTableCell: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});
