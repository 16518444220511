export default theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '250px',
  },
  rowWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    flexWrap: 'inherit'
  },
  vehicleCapacities: {
    marginTop: 0,
  },
  templateType: {
    marginBottom: 0,
    display: 'flex',
    alignItems: 'flex-end'
  }
});
