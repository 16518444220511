export default theme => ({
  header: {},
  content: {
    minHeight: '100%',
    paddingBottom: '550px',
    [theme.breakpoints.up('md')]: {
      paddingBottom: '500px',
    }
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  }
});

