export default theme => ({
  root: {},
  button: {
    marginLeft: theme.spacing(1)
  },
  wrapper: {
    display: 'flex',
    margin: theme.spacing(2)
  },
  wrapperChild: {
    flex: 1,
  },
  stopDetail: {
    margin: theme.spacing(1)
  },
  weekdayContainer: {
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '5px'
  },
  tabRow: {
    borderBottom: `1px solid ${theme.palette.border}`,
  },
  warningColor: {
    color: theme.palette.warning.main,
    verticalAlign: 'bottom'
  },
  infoIcon: {
    margin: '0 5px -5px 5px',
  }
});
