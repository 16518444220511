export default theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    border: `1px solid ${theme.palette.border}`
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
  tourMap: {
    minWidth: '100%',
    minHeight: 'calc(-195px + 100vh)',
    border: '1px solid lightgrey',
    borderRadius: '3px'
  },
  portletRoot: {
    overflow: 'auto'
  },
  refreshButton: {
    float: 'right',
  },
  refreshButtonActivated: {
    float: 'right',
    backgroundColor: theme.palette.warning.main
  },
});

