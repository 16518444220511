// eslint-disable-next-line no-unused-vars
export default theme => ({
  dialog: {},
  dialogContent: {
    padding: 0,
    margin: 0,
  },
  map: {
    minWidth: '100%',
    minHeight: '85vh'
  },
});
