import React, {Fragment} from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import MicroHubWrapper from './components/MicroHubWrapper';
import PreviewStopTable from './components/PreviewStopTable';
import classNames from 'classnames'
import {ExpandMore as ExpandMoreIcon} from '@material-ui/icons';
import {PreviewMap, TextPlaceholderWrapper} from '../../../components';
import {roundCapacityValues} from '../../../services/util/helperFuncs';

function PreviewToursView(props) {

  const {
    classes,
    hubsWithToursAndStops,
    unassignedStops,
    t,
    className,
    rerunAlgoForHub,
    freezeTour,
    microHubs,
    microHubPlanData,
    changeStopAssignment,
    dataCarrierForChangeAssignment,
    deleteStop,
    mapTabState,
    setMapTabState
  } = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <TextPlaceholderWrapper
        active={!hubsWithToursAndStops?.length}
        text={t('noPreviewDataAvailable')}
      >
        <Fragment>
          {hubsWithToursAndStops.map((hubWithToursAndStops, index) => {
            const planDataForHub = microHubPlanData ? microHubPlanData.filter(plan => plan.carrierName === hubWithToursAndStops.carrierName && plan.microhubName === hubWithToursAndStops.microHubName) : [];
            const hubPlan = planDataForHub && planDataForHub.length > 0 && planDataForHub[0].hubPlans && planDataForHub[0].hubPlans.length > 0 ? planDataForHub[0].hubPlans[0] : null;
            return (
              <MicroHubWrapper
                changeStopAssignment={changeStopAssignment}
                className={classes.content}
                dataCarrierForChangeAssignment={dataCarrierForChangeAssignment}
                deleteStop={deleteStop}
                freezeTour={freezeTour}
                key={index}
                maxVolume={hubPlan ? hubPlan.maxVolume : 0}
                microHubsData={microHubs && microHubs.length > 0 ? microHubs : []}
                microHubWithToursAndStops={hubWithToursAndStops}
                rerunAlgoForHub={rerunAlgoForHub}
              />
            );
          })
          }
        </Fragment>
      </TextPlaceholderWrapper>
      {unassignedStops && unassignedStops.length > 0 &&
      <Accordion className={classes.content}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
        >
          <Typography className={classes.headerSection}>
            <strong>{t('unassignedStops')}</strong>
          </Typography>
          <Typography className={classes.headerSection}>
            <strong>{t('stops')}</strong>: {unassignedStops ? unassignedStops.length : 0}
          </Typography>
          <Typography className={classes.headerSection}>
            <strong>{t('boxAmount')}</strong>: {unassignedStops ? roundCapacityValues(unassignedStops.reduce((accumulatorStop, stop) => accumulatorStop + stop.stopCapacities.boxAmount, 0)) : 0}
          </Typography>
        </AccordionSummary>
        <hr className={classes.hr}/>
        <AccordionDetails>
          <PreviewStopTable
            changeAssignment={changeStopAssignment}
            className={classes.unassignedStopsTable}
            dataCarrierForChangeAssignment={dataCarrierForChangeAssignment}
            deleteStop={deleteStop}
            microHubsData={microHubs && microHubs.length > 0 ? microHubs : []}
            stops={unassignedStops}
          />
        </AccordionDetails>
      </Accordion>
      }
      <PreviewMap
        className={classes.map}
        hubsWithToursAndStops={hubsWithToursAndStops && hubsWithToursAndStops.length > 0 ? hubsWithToursAndStops : []}
        microHubs={microHubs && microHubs.length > 0 ? microHubs : []}
        persistedTabState={mapTabState}
        setPersistedTabState={setMapTabState}
        unassignedStops={unassignedStops && unassignedStops.length > 0 ? unassignedStops : []}
      />
    </div>
  );
}


PreviewToursView.propTypes = {
  changeStopAssignment: PropTypes.func,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  dataCarrierForChangeAssignment: PropTypes.object,
  deleteStop: PropTypes.func,
  freezeTour: PropTypes.func,
  hubsWithToursAndStops: PropTypes.array.isRequired,
  mapTabState: PropTypes.number,
  microHubPlanData: PropTypes.array.isRequired,
  microHubs: PropTypes.array.isRequired,
  rerunAlgoForHub: PropTypes.func,
  setMapTabState: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  unassignedStops: PropTypes.array,
};

export default compose(withStyles(styles), withTranslation())(PreviewToursView);
