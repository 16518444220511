export const ProductType = {
  Box: 1,
  BeverageBox: 2,
  PackageInserts: 3,
  Other: 4
};

export function getProductTypeString(type) {
  switch (type) {
    case ProductType.Box:
      return 'productTypeBox';
    case ProductType.BeverageBox:
      return 'productTypeBeverageBox';
    case ProductType.PackageInserts:
      return 'productTypePackageInserts';
    case ProductType.Other:
      return 'productTypeOther';
    default:
      return 'productTypeUndefined'
  }
}
