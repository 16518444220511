import React, {Fragment, useState} from 'react'
import {Button, Checkbox, FormControlLabel, Grid, Switch, withStyles,} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  LocalOffer as EditIcon,
  PlaylistAdd as AddMultipleIcon
} from '@material-ui/icons';
import {
  LoadingIndicator,
  Portlet,
  StopAddDialog,
  TourCreateDialog,
  TourEditNameDialog,
  TourMap
} from '../../../../../components';
import TourTableSmall from './TourTableSmall';
import TourStopTableSmall from './TourStopTableSmall'
import {StopStatus} from '../../../../../services/enums/StopStatus';
import {DragDropContext} from 'react-beautiful-dnd';
import {DndHelperService} from '../../../../../services/dndHelper/dndHelperService';
import {LegendInteractive} from '../../../../../components/Maps/MapComponents';
import {ShipperAllowedOnHubService} from '../../../../../services/util/shipperAllowedOnHubHelper';
import {presetsService} from '../../../../../services/presets/presetService';
import {AuthService} from '../../../../../services/auth/authService';
import StartAlgoControl from './StartAlgoControl';
import {scrollToElement} from '../../../../../services/util/helperFuncs';
import DeleteAllToursDialog from './DeleteAllToursDialog';
import DeleteTourDialog from "./DeleteTourDialog";


function MapDispositionPlanning(props) {
  const {
    classes,
    className,
    t,
    loadingSelectedTour,
    tours,
    microHubs,
    microHubOptions,
    addStopsToTour,
    notPlanedStops,
    deleteStopFromTour,
    createTour,
    editTourName,
    deleteAllTours,
    deleteTour,
    startAlgoWithStops,
    updateTourOrder,
    changeTourOfStop,
    shipperOptions,
    selectedTour,
    setSelectedTour,
    spareStops,
  } = props;
  const [legendCheckboxState, setLegendCheckboxState] = useState({
    displayNotPlanedStops: true,
    displayMicroHubs: true,
  });
  const [stopTableCapacityMode, setStopTableCapacityMode] = useState(false);
  const [showOnlySelectedTour, setShowOnlySelectedTour] = useState(false);

  // Dialogs
  const [createTourDialogState, setCreateTourDialogState] = useState({
    open: false,
    shipperName: '',
    microHubName: '',
    microHubSelectOptions: []
  });
  const [editTourNameDialogState, setEditTourNameDialogState] = useState({open: false, tour: null});
  const [addStopDialogState, setAddStopDialogState] = useState({open: false});
  const [selectedStopsForAlgo, setSelectedStopsForAlgo] = useState([]);

  const [deleteAllToursDialogState, setDeleteAllToursDialogState] = useState(false);
  const [deleteTourDialogState, setdeleteTourDialogState] = useState(false);

  if (selectedStopsForAlgo.length === 0 && notPlanedStops && notPlanedStops.length > 0) {
    setSelectedStopsForAlgo(notPlanedStops.map(stop => stop.tourStopId));
  }

  const rootClassName = classNames(classes.root, className);


  const handleCloseCreateTour = (tour, toursToCreate) => {
    createTour(tour, toursToCreate);
    setCreateTourDialogState({...createTourDialogState, open: false});
  }

  const handleCloseAddStops = (stopsToAdd, autoOrderOnAdd) => {
    addStopsToTour(stopsToAdd, autoOrderOnAdd);
    setAddStopDialogState({...addStopDialogState, open: false});
  }

  const handleCloseEditTourNameDialog = (editedTour) => {
    editTourName(editedTour);
    setEditTourNameDialogState({...editTourNameDialogState, open: false, tour: null})
  }

  const handleCreateTourCancel = () => {
    setCreateTourDialogState({...createTourDialogState, open: false});
  };

  const handleCancelAddStopToTourDialog = () => {
    setAddStopDialogState({...addStopDialogState, open: false});
  };

  const handleCancelEditTourNameDialog = () => {
    setEditTourNameDialogState({...editTourNameDialogState, open: false, tour: null});
  }

  const handleDeleteAllTours = () => {
    setDeleteAllToursDialogState(false);
    deleteAllTours(tours);
  }
  const handleDeleteTour = () => {
    setdeleteTourDialogState(false);
    deleteTour(selectedTour.tourId);
  }

  const selectStopOnMap = (stop) => {
    if (stop.stopStatus !== StopStatus.NotPlaned) {
      const tourWithStop = tours.filter(tour => tour.stops.map(s => s.tourStopId).includes(stop.tourStopId))[0];
      scrollToElement(`Tour-${tourWithStop.tourId}`);
      setSelectedTour(tourWithStop);
    }
  };

  const onDragEnd = (event) => {
    if (!event.destination) {
      return;
    }

    if (event.destination === event.source) {
      return;
    }

    const source = DndHelperService.parseUniqueId(event.source.droppableId);
    const destination = DndHelperService.parseUniqueId(event.destination.droppableId);
    const element = DndHelperService.parseUniqueId(event.draggableId);

    if (source.tourId === destination.tourId) {
      // stop was moved to the ne tour
      if (destination.isTourTable) return;
      // stop was dropped on same tour but order changed ==> change order
      const tourIndex = tours.findIndex(tour => tour.tourId === source.tourId);
      if (tourIndex < 0) return;
      let reorderedTour = JSON.parse(JSON.stringify(tours[tourIndex]));
      reorderedTour.stops = DndHelperService.reorder(reorderedTour.stops, event.source.index, event.destination.index);
      updateTourOrder(reorderedTour);
    } else {
      // stop was dropped on another tour ==> change tour
      const destTour = tours.find(tour => tour.tourId === destination.tourId);
      const sourceTour = tours.find(tour => tour.tourId === source.tourId);
      const destHub = microHubs.find(hub => hub.name === destTour.microHubName);
      const stop = sourceTour.stops.find(stop => stop.tourStopId === element.stopId);
      if (!ShipperAllowedOnHubService.checkShipperAllowedStop(destHub, stop, () => destTour.planedTourDelivery)) {
        alert(t('shipperNotAllowedStop'));
        return;
      }

      changeTourOfStop(source.tourId, destination.tourId, element.stopId);
    }
  };

  return (
    <Fragment>
      <div className={classes.controlsContainer}>
        <StartAlgoControl
          className={classes.tourBuildingControl}
          notPlanedStops={notPlanedStops}
          startAlgoWithStops={startAlgoWithStops}
        />
      </div>
      <div className={classes.controlsContainer}>
        <div className={classes.displaySelectedTourCheckboxAndLegend}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showOnlySelectedTour}
                color="secondary"
                onChange={(event) => setShowOnlySelectedTour(event.target.checked)}
                value={showOnlySelectedTour}
              />
            }
            label={t('showOnlySelectedTour')}
          />
          <LegendInteractive
            hideStopStatus
            legendCheckboxesState={legendCheckboxState}
            setLegendCheckboxesState={setLegendCheckboxState}
          />
        </div>
      </div>
      <Grid
        className={rootClassName}
        container
        spacing={1}
      >
        <Grid
          item
          lg={6}
          md={5}
          sm={12}
          xl={6}
          xs={12}
        >
          <TourMap
            className={classes.gridItem}
            displayMicroHubs={legendCheckboxState.displayMicroHubs}
            displayNotPlanedStops={legendCheckboxState.displayNotPlanedStops}
            displayStopStatus={false}
            microHubs={microHubs ? microHubs : []}
            notPlanedStops={notPlanedStops && notPlanedStops.length > 0 ? notPlanedStops : []}
            setSelectedStop={selectStopOnMap}
            toursWithStops={showOnlySelectedTour ? selectedTour ? [selectedTour] : [] : tours}
          />
        </Grid>
        <Grid
          item
          lg={6}
          md={7}
          sm={12}
          xl={6}
          xs={12}
        >
          <div
            className={classes.gridItem}
          >
            <DragDropContext
              onDragEnd={onDragEnd}
            >
              <TourTableSmall
                className={classes.tourTable}
                selectedTour={selectedTour}
                selectTour={setSelectedTour}
                tours={tours}
              />
              <Button
                className={classes.button}
                color="primary"
                onClick={() => setCreateTourDialogState({...createTourDialogState, open: true})}
                size="small"
                startIcon={<AddIcon/>}
                variant="contained"
              >
                {t('createTour')}
              </Button>
              <Button
                className={classNames(classes.button, classes.redButton)}
                disabled={!tours || !tours.length}
                onClick={() => setDeleteAllToursDialogState(true)}
                size="small"
                variant="contained"
              >
                <DeleteIcon/>&nbsp;{t('deleteAllTours')}
              </Button>
              <br/>
              <br/>
              {loadingSelectedTour &&
                <Portlet className={classes.content}>
                  <LoadingIndicator/>
                </Portlet>
              }
              {selectedTour &&
                <Fragment>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={stopTableCapacityMode}
                        color="secondary"
                        name="stopTableCapacityMode"
                        onChange={e => setStopTableCapacityMode(e.target.checked)}
                      />
                    }
                    label={t('capacityMode')}
                  />
                  <TourStopTableSmall
                    capacityMode={stopTableCapacityMode}
                    className={classes.tourTable}
                    deleteStopFromTour={deleteStopFromTour}
                    tour={selectedTour}
                  />
                  <Button
                    className={classes.button}
                    color="primary"
                    disabled={!spareStops?.length}
                    onClick={() => setAddStopDialogState({...addStopDialogState, open: true})}
                    size="small"
                    variant="contained"
                  >
                    <AddMultipleIcon/>
                  </Button>
                  <Button
                    className={classNames(classes.button)}
                    color="primary"
                    onClick={() => setEditTourNameDialogState({
                      ...editTourNameDialogState,
                      open: true,
                      tour: selectedTour
                    })}
                    size="small"
                    title={t('renameTour')}
                    variant="contained"
                  >
                    <EditIcon/>
                  </Button>
                  <Button
                    className={classNames(classes.button, classes.redButton)}
                    onClick={() => setdeleteTourDialogState(true)}
                    size="small"
                    variant="contained"
                  >
                    <DeleteIcon/>&nbsp;{t('deleteTour')}
                  </Button>
                </Fragment>
              }
            </DragDropContext>
          </div>
        </Grid>
      </Grid>
      <TourCreateDialog
        createTourDialogState={createTourDialogState}
        handleCancel={handleCreateTourCancel}
        handleClose={handleCloseCreateTour}
        microHubOptions={microHubOptions}
        shipperOptions={shipperOptions}
        tourPreset={presetsService.getTourPresetWithCarrier(AuthService.getUserOrganization())}
      />
      <DeleteAllToursDialog
        deleteAllToursDialogState={deleteAllToursDialogState}
        handleCancel={() => setDeleteAllToursDialogState(false)}
        handleClose={handleDeleteAllTours}
      />
      <DeleteTourDialog
          deleteAllToursDialogState={deleteTourDialogState}
          handleCancel={() => setdeleteTourDialogState(false)}
          handleClose={handleDeleteTour}
        />
      {selectedTour &&
        <Fragment>
          <StopAddDialog
            addStopDialogState={addStopDialogState}
            alreadyPresentStops={selectedTour ? selectedTour.stops : []}
            expectedHub={selectedTour ? selectedTour.microHubName : null}
            expectedHubData={selectedTour && microHubs.find(hub => hub.name === selectedTour.microHubName)}
            handleCancel={handleCancelAddStopToTourDialog}
            handleClose={handleCloseAddStops}
            microHubs={microHubs}
            selectedTour={selectedTour}
            spareStops={spareStops}
            tourDeliveryDate={selectedTour ? selectedTour.planedTourDelivery : ''}
            tours={tours}
          />
          <TourEditNameDialog
            dialogState={editTourNameDialogState}
            handleCancel={handleCancelEditTourNameDialog}
            handleClose={handleCloseEditTourNameDialog}
          />
        </Fragment>
      }
    </Fragment>
  );
}

MapDispositionPlanning.propTypes = {
  addStopsToTour: PropTypes.func.isRequired,
  changeTourOfStop: PropTypes.func.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  createTour: PropTypes.func.isRequired,
  deleteAllTours: PropTypes.func.isRequired,
  deleteStopFromTour: PropTypes.func.isRequired,
  deleteTour: PropTypes.func.isRequired,
  editTourName: PropTypes.func.isRequired,
  loadingSelectedTour: PropTypes.bool.isRequired,
  microHubOptions: PropTypes.array.isRequired,
  microHubs: PropTypes.array.isRequired,
  notPlanedStops: PropTypes.array.isRequired,
  selectedTour: PropTypes.object,
  setSelectedTour: PropTypes.func.isRequired,
  shipperOptions: PropTypes.array.isRequired,
  spareStops: PropTypes.array.isRequired,
  startAlgoWithStops: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  tours: PropTypes.array.isRequired,
  updateTourOrder: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(MapDispositionPlanning);
