import React, {useState} from 'react'
import {Collapse, IconButton, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Draggable} from 'react-beautiful-dnd'
import Stop from '../Stop';
import {DndHelperService} from '../../../../../services/dndHelper/dndHelperService';
import {CapacityHelperService} from '../../../../../services/util/capacityHelper';
import {
  AcUnitRounded as FreezeIcon,
  DragIndicatorOutlined as DragIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon
} from '@material-ui/icons'

function Tour(props) {

  const {classes, tour, index, uniqueHubId, t, className} = props;

  const rootClassName = classNames(tour.editable ? classes.root : classes.rootDisabled, className);
  const uniqueTourId = DndHelperService.getUniqueTourId(uniqueHubId, tour.id);

  const [open, setOpen] = useState(false);

  return (
    <Draggable
      draggableId={uniqueTourId}
      index={index}
      isDragDisabled={!tour.editable}
    >
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <div
            className={classNames(rootClassName)}
            style={tour.editable ? {backgroundColor: tour.color} : {}}
          >
            <div className={classes.tourHeader}>
              <div
                {...provided.dragHandleProps}
                style={tour.editable ? {height: '22px', maxHeight: '22px'} : {height: '22px', maxHeight: '22px', visibility: 'hidden'}}
              >
                <DragIcon/>
              </div>
              <Typography className={classes.headerSection}>
                <strong>{t('previewTourID')}: {tour.id}</strong>
              </Typography>
              <Typography className={classes.headerSection}>
                <strong>{t('stops')}</strong>: {tour.stops ? tour.stops.length : 0}
              </Typography>
              <Typography className={classes.headerSection}/>
              <Typography className={classes.headerSection}>
                <strong>{t('boxAmount')}</strong>: {CapacityHelperService.getBoxAmountOfTour(tour)}
              </Typography>
              <Typography className={classes.headerSection}>
                <strong>{t('weight')}</strong>: {CapacityHelperService.getWeightOfTour(tour)}
              </Typography>
              <Typography className={classes.headerSectionSmall}>
                <strong>{t('volume')}</strong>: {CapacityHelperService.getVolumeOfTour(tour)}
              </Typography>
              <IconButton
                className={classes.iconButton}
                onClick={() => setOpen(!open)}
                size="small"
              >
                {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
              </IconButton>
              <FreezeIcon
                style={!tour.frozen ? {visibility: 'hidden'} : {}}
                titleAccess={t('frozen')}
              />
            </div>
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
            >
              {tour.stops.map((stop, index) => {
                return (
                  <Stop
                    className={classes.stop}
                    key={stop.id}
                    stop={stop}
                  />
                );
              })}
              {provided.placeholder}
            </Collapse>
          </div>
        </div>
      )}
    </Draggable>
  );
}


Tour.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  tour: PropTypes.object.isRequired,
  uniqueHubId: PropTypes.string.isRequired
};

export default compose(withStyles(styles), withTranslation())(Tour);
