import React, {useState} from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  withStyles
} from '@material-ui/core';
import styles from './styles';
import classNames from 'classnames';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {Portlet, PortletContent, TextPlaceholderWrapper} from '../../index';
import Typography from '@material-ui/core/Typography';
import {WarningRounded as WarnIcon} from '@material-ui/icons';
import {onCloseWithBackdropCurry, roundCapacityValues} from '../../../services/util/helperFuncs';

function VehicleSelectDialog(props) {

  const {classes, t, vehicleSelectDialogState, handleCancel, handleClose, vehicles} = props;
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  const availableOnDay = (microHub, date) => {
    switch (new Date(date).getDay()) {
      case 1:
        return microHub.monday;
      case 2:
        return microHub.tuesday;
      case 3:
        return microHub.wednesday;
      case 4:
        return microHub.thursday;
      case 5:
        return microHub.friday;
      case 6:
        return microHub.saturday;
      case 0:
        return microHub.sunday;
      default:
        return '';
    }
  };

  const tourMicroHubName = vehicleSelectDialogState.tour ? vehicleSelectDialogState.tour.microHubName : null;
  const vehiclesToDisplay = vehicles.filter(vehicle => vehicle.microhubs.some(hub => hub.name === tourMicroHubName && availableOnDay(hub, vehicleSelectDialogState.tour?.planedTourDelivery || new Date())));

  const handleOk = () => {
    handleClose(selectedVehicle);
    setSelectedVehicle(null);
  };

  const handleNotOk = () => {
    handleCancel();
    setSelectedVehicle(null);
  };

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      maxWidth={'lg'}
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={vehicleSelectDialogState.open}
    >
      <DialogTitle>{t('selectVehicleDialogTitle')}</DialogTitle>
      <DialogContent>
        <TextPlaceholderWrapper
          active={!vehiclesToDisplay?.length}
          text={t('noVehiclesFound')}
        >
          <Portlet className={classes.stopTable}>
            <PortletContent noPadding>
              <strong>{t('tourCapacity')}</strong>:
              {vehicleSelectDialogState.tour && vehicleSelectDialogState.tourCapacities &&
              <Typography variant={'body1'}>
                {t('amountOfBoxes')}: {roundCapacityValues(vehicleSelectDialogState.tourCapacities.boxAmount)} {t('volume')}: {roundCapacityValues(vehicleSelectDialogState.tourCapacities.volume)} {t('weight')}: {roundCapacityValues(vehicleSelectDialogState.tourCapacities.weight)}
              </Typography>
              }
              <hr/>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.alignLeft}>{t('vehicleName')}</TableCell>
                    <TableCell className={classes.alignLeft}>{t('vehicleType')}</TableCell>
                    <TableCell className={classes.alignLeft}>{t('vehicleCapacity')}</TableCell>
                    <TableCell className={classes.alignLeft}>{t('vehicleVolume')}</TableCell>
                    <TableCell className={classes.alignLeft}>{t('vehiclePayloadWeight')}</TableCell>
                    <TableCell className={classes.alignLeft}>{t('speedKmH')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vehiclesToDisplay.map((vehicle, index) => (
                    <TableRow
                      className={classNames(classes.tableRow, 'cursorPointer')}
                      hover
                      key={index}
                      onClick={() => setSelectedVehicle(vehicle)}
                      selected={selectedVehicle && vehicle.licensePlate === selectedVehicle.licensePlate}
                    >
                      <TableCell className={classes.tableCell}>
                        {vehicle.licensePlate}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {vehicle.type}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Typography className={classes.alignText}>
                          {vehicle.capacity < vehicleSelectDialogState.tourCapacities.boxAmount &&
                          <Tooltip title={t('capacityProblem')}>
                            <WarnIcon className={classes.dangerColor}/>
                          </Tooltip>
                          }
                          &nbsp;{roundCapacityValues(vehicle.capacity)}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Typography className={classes.alignText}>
                          {vehicle.volume < vehicleSelectDialogState.tourCapacities.volume &&
                          <Tooltip title={t('capacityProblem')}>
                            <WarnIcon className={classes.dangerColor}/>
                          </Tooltip>
                          }
                          &nbsp;{roundCapacityValues(vehicle.volume)}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Typography className={classes.alignText}>
                          {vehicle.payloadWeight < vehicleSelectDialogState.tourCapacities.weight &&
                          <Tooltip title={t('capacityProblem')}>
                            <WarnIcon className={classes.dangerColor}/>
                          </Tooltip>
                          }
                          &nbsp;{roundCapacityValues(vehicle.payloadWeight)}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Typography className={classes.alignText}>
                          {vehicle.averageSpeedKmh}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </PortletContent>
          </Portlet>
        </TextPlaceholderWrapper>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleNotOk}
        >
          {t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          disabled={!selectedVehicle}
          onClick={handleOk}
        >
          {t('dialogOk')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

VehicleSelectDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  vehicleSelectDialogState: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    tour: PropTypes.object,
    tourCapacities: PropTypes.object
  }).isRequired,
  vehicles: PropTypes.array.isRequired,
};


export default compose(withStyles(styles), withTranslation())(VehicleSelectDialog);
