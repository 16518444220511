import React from 'react'
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {Portlet, PortletContent} from '../../../../index';

function TrackingLinkInfoView(props) {
  const {className, t, trackingLinkInfos} = props;

  return (
    <Portlet className={className}>
      <PortletContent>
        <Typography variant="h3">
          {t('trackingLinkInfos')}
        </Typography>
        <hr/>
        {trackingLinkInfos ?
          <Typography variant="body1">
            <strong>{t('trackingShipperLogoName')}</strong>: &nbsp;{trackingLinkInfos.logoName}<br/>
            <strong>{t('trackingShipperMarketingImageName')}</strong>: &nbsp;{trackingLinkInfos.marketingImageName}<br/>
            <strong>{t('trackingShipperPrimaryColor')}</strong>: &nbsp;{trackingLinkInfos.primaryColor}&nbsp;
            <span style={{backgroundColor: trackingLinkInfos.primaryColor}}>&nbsp;&nbsp;&nbsp;&nbsp;</span><br/>
            <strong>{t('trackingShipperPrimaryTextColor')}</strong>: &nbsp;{trackingLinkInfos.primaryTextColor}&nbsp;
            <span style={{backgroundColor: trackingLinkInfos.primaryTextColor}}>&nbsp;&nbsp;&nbsp;&nbsp;</span><br/>
            <strong>{t('trackingShipperPrimaryContrastTextColor')}</strong>: &nbsp;{trackingLinkInfos.primaryContrastTextColor}&nbsp;
            <span style={{backgroundColor: trackingLinkInfos.primaryContrastTextColor}}>&nbsp;&nbsp;&nbsp;&nbsp;</span><br/>
            <strong>{t('trackingShipperSecondaryColor')}</strong>: &nbsp;{trackingLinkInfos.secondaryColor}&nbsp;
            <span style={{backgroundColor: trackingLinkInfos.secondaryColor}}>&nbsp;&nbsp;&nbsp;&nbsp;</span><br/>
            <strong>{t('trackingShipperSecondaryTextColor')}</strong>: &nbsp;{trackingLinkInfos.secondaryTextColor}&nbsp;
            <span style={{backgroundColor: trackingLinkInfos.secondaryTextColor}}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </Typography>
          :
          <Typography variant="body1">-</Typography>
        }
      </PortletContent>
    </Portlet>
  );
}

TrackingLinkInfoView.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
  trackingLinkInfos: PropTypes.object.isRequired,
};


export default withTranslation()(TrackingLinkInfoView);
