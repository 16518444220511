import Button from '@material-ui/core/Button';
import Portlet from '../../../../../components/Portlet';
import PortletContent from '../../../../../components/PortletContent';
import PropTypes from 'prop-types';
import React, {Fragment, useState} from 'react';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import styles from '../TransferRulesForShippers/styles';
import {Add as AddIcon, DeleteForever as DeleteIcon} from '@material-ui/icons';
import {FormControl, Input, MenuItem, withStyles} from '@material-ui/core';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {Pagination, TextPlaceholderWrapper} from '../../../../../components';
import useTablePagination from '../../../../../hooks/useTablePagination';

function StandardsForShippersTable(props) {

  const {
    classes,
    className,
    createStandardForShipper,
    deleteStandardForShipper,
    shipperOptions,
    standardsForShippers,
    t
  } = props;

  const [allowedAdd, setAllowedAdd] = useState(false);
  const [newStandardForShipper, setNewStandardForShipper] = useState({
    shipperName: '',
    standardBoxAmount: 0.0,
    standardWeight: 0.0
  });

  const {paginationProps, pagedObjects} = useTablePagination(standardsForShippers);

  const createStandard = () => {
    createStandardForShipper(newStandardForShipper);
    setNewStandardForShipper({
      shipperName: '',
      standardBoxAmount: 0.0,
      standardWeight: 0.0
    })
    setAllowedAdd(false);
  }

  const rootClassName = classNames(classes.root, className);

  return (
    <TextPlaceholderWrapper
      active={!standardsForShippers}
      text={t('noStandardsForShippersFound')}
    >
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell className={classes.alignLeft}>{t('standardForShipperId')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('shipper')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('standardBoxAmount')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('standardWeight')}</TableCell>
                <TableCell className={classes.alignLeft}/>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedObjects.map((standardForShipper, index) => (
                <TableRow
                  className={classes.tableRow}
                  hover
                  key={index}
                >
                  <TableCell className={classes.tableCell}>
                    {standardForShipper.systemId}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {standardForShipper.shipperName}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {standardForShipper.standardBoxAmount}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {standardForShipper.standardWeight}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Button
                      className={classes.deleteButton}
                      color="inherit"
                      onClick={() => {
                        if (window.confirm(t('confirmDeleteStandardForShipper'))) deleteStandardForShipper(standardForShipper)
                      }}
                      title={t('deleteStandardForShipper')}
                    >
                      <DeleteIcon/>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                {allowedAdd ?
                  <Fragment>
                    <TableCell className={classes.tableCell}>
                      {'-'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <FormControl className={classes.formControl}>
                        <Select
                          input={<Input id="shipper"/>}
                          onChange={(event) => setNewStandardForShipper({
                            ...newStandardForShipper,
                            shipperName: event.target.value
                          })}
                          value={newStandardForShipper.shipperName}
                        >
                          {shipperOptions && shipperOptions.filter(value => !standardsForShippers.map(e => e.shipperName).includes(value)).map((shipper, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={shipper}
                              >
                                {shipper}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <FormControl className={classes.formControl}>
                        <Input
                          error={newStandardForShipper.standardBoxAmount === '' || isNaN(newStandardForShipper.standardBoxAmount) || newStandardForShipper.standardBoxAmount <= 0}
                          id="standardBoxAmount"
                          max="200"
                          min="0"
                          onChange={(event) => setNewStandardForShipper({
                            ...newStandardForShipper,
                            standardBoxAmount: event.target.valueAsNumber
                          })}
                          step="0.1"
                          type="number"
                          value={newStandardForShipper.standardBoxAmount}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <FormControl className={classes.formControl}>
                        <Input
                          error={newStandardForShipper.standardWeight === '' || isNaN(newStandardForShipper.standardWeight) || newStandardForShipper.standardWeight <= 0}
                          id="standardWeight"
                          max="200"
                          min="0"
                          onChange={(event) => setNewStandardForShipper({
                            ...newStandardForShipper,
                            standardWeight: event.target.valueAsNumber
                          })}
                          step="0.1"
                          type="number"
                          value={newStandardForShipper.standardWeight}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Button
                        color="primary"
                        disabled={!newStandardForShipper.shipperName || newStandardForShipper.standardBoxAmount <= 0 || newStandardForShipper.standardWeight <= 0}
                        onClick={createStandard}
                        style={{padding: 0}}
                        title={t('createStandardForShipper')}
                      >
                        <AddIcon/>
                      </Button>
                    </TableCell>
                  </Fragment>
                  :
                  <Fragment>
                    <TableCell colSpan={5}>
                      <Button
                        color="primary"
                        onClick={() => setAllowedAdd(!allowedAdd)}
                        style={{padding: 0}}
                        title={t('createStandardForShipper')}
                      >
                        <AddIcon/>
                      </Button>
                    </TableCell>
                  </Fragment>
                }
              </TableRow>
            </TableBody>
          </Table>
          <Pagination paginationProps={paginationProps}/>
        </PortletContent>
      </Portlet>
    </TextPlaceholderWrapper>
  );
}

StandardsForShippersTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  createStandardForShipper: PropTypes.func.isRequired,
  deleteStandardForShipper: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  shipperOptions: PropTypes.array.isRequired,
  standardsForShippers: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

StandardsForShippersTable.defaultProps = {
  standardsForShippers: []
}

export default compose(withStyles(styles), withTranslation())(StandardsForShippersTable);
