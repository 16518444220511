import React, {useState} from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  withStyles
} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {onCloseWithBackdropCurry} from '../../../../services/util/helperFuncs';
import {DateTimeRangeFilter, DropdownSelect, DropdownSelectSimple} from '../../../../components';
import useShipperOptions from '../../../../hooks/useShipperOptions';
import {getTrackingStepTypeString, TrackingStepType} from '../../../../services/enums/TrackingStepType';
import trackAndTraceService from '../../../../services/backend/trackAndTraceService';
import {AuthService} from '../../../../services/auth/authService';

function DummyTrackingEmailDialog(props) {

  const {close, t, classes, open} = props;
  const {shipperOptions} = useShipperOptions();
  const [selectedShipper, setSelectedShipper] = useState('');
  const [emailData, setEmailData] = useState({
    guid: '',
    lastName: '',
    firstName: '',
    email: AuthService.getUserEmail(),
    driverEmail: '',
    trackingStep: {
      trackingStepType: 1,
      deliveryWindow: {
        from: new Date(),
        to: new Date()
      },
      deliverySuccessful: false
    }
  });

  const sendEmail = async () => {
    await trackAndTraceService.sendDummyTrackingEmail(selectedShipper, emailData);
    close();
    setEmailData({
      guid: '',
      lastName: '',
      firstName: '',
      email: AuthService.getUserEmail(),
      driverEmail: '',
      trackingStep: {
        trackingStepType: 1,
        deliveryWindow: {
          from: new Date(),
          to: new Date()
        },
        deliverySuccessful: false
      }
    });
  }


  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      maxWidth={'sm'}
      onClose={onCloseWithBackdropCurry(() => close())}
      open={open}
    >
      <DialogTitle>{t('donationButtonApproveDialog')}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DropdownSelectSimple
          error={!selectedShipper}
          name={t('shipper')}
          options={shipperOptions}
          required
          setValue={value => setSelectedShipper(value)}
          sort
          value={selectedShipper}
        />
        <FormControl className={classes.formControl}>
          <InputLabel required>{t('trackingGuid')}</InputLabel>
          <Input
            error={!emailData.guid}
            onChange={(event) => {
              setEmailData({...emailData, guid: event.target.value})
            }}
            value={emailData.guid}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel required>{t('email')}</InputLabel>
          <Input
            error={!emailData.email}
            onChange={(event) => {
              setEmailData({...emailData, email: event.target.value})
            }}
            value={emailData.email}
          />
        </FormControl>
        <div className={classes.horizontalContainer}>
          <FormControl className={classes.formControl}>
            <InputLabel>{t('firstName')}</InputLabel>
            <Input
              onChange={(event) => {
                setEmailData({...emailData, firstName: event.target.value})
              }}
              value={emailData.firstName}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel>{t('lastName')}</InputLabel>
            <Input
              onChange={(event) => {
                setEmailData({...emailData, lastName: event.target.value})
              }}
              value={emailData.lastName}
            />
          </FormControl>
        </div>
        <FormControl className={classes.formControl}>
          <InputLabel>{t('emailDriver')}</InputLabel>
          <Input
            onChange={(event) => {
              setEmailData({...emailData, driverEmail: event.target.value})
            }}
            value={emailData.driverEmail}
          />
        </FormControl>
        <DateTimeRangeFilter
          fromRequired
          fromValue={emailData.trackingStep.deliveryWindow.from}
          multiLine
          setValue={(from, to) => setEmailData({...emailData, trackingStep: {...emailData.trackingStep, deliveryWindow: {from: from, to: to}}})}
          toRequired
          toValue={emailData.trackingStep.deliveryWindow.to}
        />
        <div className={classes.horizontalContainer}>
          <DropdownSelect
            className={classes.formControl}
            name={t('trackingStep')}
            options={Object.values(TrackingStepType).map(value => ({value: value, name: t(getTrackingStepTypeString(value))}))}
            required
            setValue={value => setEmailData({...emailData, trackingStep: {...emailData.trackingStep, trackingStepType: value}})}
            value={emailData.trackingStep.trackingStepType}
          />
          <FormControlLabel
            className={classes.formControl}
            control={
              <Checkbox
                checked={emailData.trackingStep.deliverySuccessful}
                color="primary"
                onChange={(event) => {
                  setEmailData({...emailData, trackingStep: {...emailData.trackingStep, deliverySuccessful: event.target.checked}})
                }}
                value={emailData.trackingStep.deliverySuccessful}
              />
            }
            label={t('deliverySuccessful')}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={close}
        >
          {t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          disabled={!selectedShipper || !emailData.guid || !emailData.email || !emailData.trackingStep.deliveryWindow.from || !emailData.trackingStep.deliveryWindow.to}
          onClick={sendEmail}
        >
          {t('sendEmail')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DummyTrackingEmailDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(DummyTrackingEmailDialog);
