export default theme => ({
  root: {
    padding: theme.spacing(3),
  },
  deadlineRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: `${theme.spacing(2)}px`,
  },
  microHubWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: '3px'
  },
  deadlineInfo: {
    border: `3px solid ${theme.palette.secondary.light}`,
    borderRadius: '5px',
    padding: theme.spacing(1),
    fontWeight: 'bold'
  },
  deadlineWarn: {
    border: `3px solid ${theme.palette.warning.main}`,
  },
});
