import React, {useCallback, useEffect, useState} from 'react'
import {Checkbox, FormControlLabel, Tooltip, useTheme, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {LoadingWrapper, Portlet, PortletContent, PortletHeader, TextPlaceholderWrapper} from '../../../components'
import {useResizeDetector} from 'react-resize-detector';
import moment from 'moment';
import {notPlanedIconColor} from '../../../services/icon/iconService';
import {DateService} from '../../../services/util/DateService';
import {TourService} from '../../../services/backend/tourService';
import {StopStatus} from '../../../services/enums/StopStatus';

const yOffsetPlanned = 48;
const yOffsetActual = 0;
const plannedHeightBar = 8;
const actualHeightBar = 8;
const markerHeightRounded = 20;
const markerWidthRounded = 20;
const markerWidth = 5;
const markerHeight = 25;

function TourTimeSummary(props) {

  const {classes, className, tour, t} = props;

  const rootClassName = classNames(classes.root, className);
  const [timedTourInfos, setTimedTourInfos] = useState(null);
  const [displayElements, setDisplayElements] = useState(null);
  const [plannedStopDeliveries, setPlannedStopDeliveries] = useState([]);
  const [plannedStopDeliveriesLoading, setPlannedStopDeliveriesLoading] = useState(false);
  const [displayPlanned, setDisplayPlanned] = useState(true);
  const [displayActual, setDisplayActual] = useState(true);

  const {width, ref} = useResizeDetector();
  const theme = useTheme();

  const loadPlannedStopDeliveries = useCallback(async () => {
    if (!tour) return;
    setPlannedStopDeliveriesLoading(true);
    try {
      const response = await TourService.getPlannedStopDeliveries(tour.tourId);
      setPlannedStopDeliveries(response.plannedStopsDeliveries);
      setPlannedStopDeliveriesLoading(false);
    } catch (e) {
      setPlannedStopDeliveriesLoading(false);
      console.warn('error loading planned stop deliveries', e);
    }
  }, [tour]);

  useEffect(() => {
    loadPlannedStopDeliveries();
  }, [loadPlannedStopDeliveries])

  useEffect(() => {
    if (!tour) return;

    // calculate event time stamps
    const plannedTourStart = tour.planedTourDelivery;
    const plannedTourEnd = moment(plannedTourStart).add(tour.duration, 'minutes').toDate().toJSON();
    const tourStart = tour.tourStart;
    const tourEnd = tour.tourEnd;
    const packStart = tour.timeToPack && tourStart ? moment(tourStart).subtract(tour.timeToPack, 'seconds').toDate().toJSON() : null;

    // calculate start event
    const relevantForStartEvent = [
      displayPlanned ? plannedTourStart : null,
      displayActual ? tourStart : null,
      displayActual ? packStart : null
    ];
    const startEvent = moment(relevantForStartEvent.filter(x => x).reduce((a, b) => new Date(a) < new Date(b) ? a : b));

    // calculate endEvent
    const relevantForEndEvent = [
      displayPlanned ? plannedTourEnd : null,
      displayActual ? tourEnd : null,
      displayActual && !tourEnd ? new Date() : null,
      ...tour.stops.filter(() => displayActual).map(s => s.deliverTime),
      ...plannedStopDeliveries.filter(() => displayPlanned).map(s => s.plannedDelivery)
    ]
    const endEvent = moment(relevantForEndEvent.filter(x => x).reduce((a, b) => new Date(a) > new Date(b) ? a : b));

    setTimedTourInfos({
      duration: moment.duration(moment(endEvent).diff(startEvent)).asSeconds(),
      plannedTourStart: {
        secondsToReference: moment.duration(moment(plannedTourStart).diff(startEvent)).asSeconds(), timeStamp: plannedTourStart
      },
      plannedTourEnd: {secondsToReference: moment.duration(moment(plannedTourEnd).diff(startEvent)).asSeconds(), timeStamp: plannedTourEnd},
      tourStart: tourStart ? {secondsToReference: moment.duration(moment(tourStart).diff(startEvent)).asSeconds(), timeStamp: tourStart} : null,
      tourEnd: tourEnd ? {secondsToReference: moment.duration(moment(tourEnd).diff(startEvent)).asSeconds(), timeStamp: tourEnd} : null,
      tmpTourEnd: !tourEnd ? {secondsToReference: moment.duration(moment(new Date()).diff(startEvent)).asSeconds(), timeStamp: new Date()} : null,
      packStart: packStart ? {secondsToReference: moment.duration(moment(packStart).diff(startEvent)).asSeconds(), timeStamp: packStart} : null,
      plannedStopDeliveries: plannedStopDeliveries.map(s => ({
        secondsToReference: moment.duration(moment(s.plannedDelivery).diff(startEvent)).asSeconds(),
        timeStamp: s.plannedDelivery,
        tourStopId: s.tourStopId,
        lastName: s.lastName,
        stopNumber: s.stopNumber
      })),
      stopDeliveries: tour.stops.filter(s => Boolean(s.deliverTime)).map(s => ({
        secondsToReference: moment.duration(moment(s.deliverTime).diff(startEvent)).asSeconds(),
        timeStamp: s.deliverTime,
        tourStopId: s.tourStopId,
        lastName: s.lastName,
        stopNumber: s.stopNumber,
        successful: s.stopStatus === StopStatus.Delivered,
      })),
    });
  }, [tour, plannedStopDeliveries, displayPlanned, displayActual])

  useEffect(() => {
    if (!timedTourInfos || !width) return;

    const factor = width / timedTourInfos.duration;


    setDisplayElements({
      timeSpans: [
        timedTourInfos.plannedTourStart && timedTourInfos.plannedTourEnd && {
          offsetX: timedTourInfos.plannedTourStart.secondsToReference * factor,
          offsetY: yOffsetPlanned,
          width: (timedTourInfos.plannedTourEnd.secondsToReference - timedTourInfos.plannedTourStart.secondsToReference) * factor,
          durationString: moment.duration((timedTourInfos.plannedTourEnd.secondsToReference - timedTourInfos.plannedTourStart.secondsToReference), 'seconds').format('hh:mm:ss', {trim: false}),
          hoverText: t('plannedTour'),
          color: notPlanedIconColor,
          height: plannedHeightBar,
          display: displayPlanned
        },
        timedTourInfos.tourStart && timedTourInfos.tmpTourEnd && {
          offsetX: timedTourInfos.tourStart.secondsToReference * factor,
          offsetY: yOffsetActual,
          width: (timedTourInfos.tmpTourEnd.secondsToReference - timedTourInfos.tourStart.secondsToReference) * factor,
          durationString: moment.duration((timedTourInfos.tmpTourEnd.secondsToReference - timedTourInfos.tourStart.secondsToReference), 'seconds').format('hh:mm:ss', {trim: false}),
          hoverText: t('actualTourInDelivery'),
          color: theme.palette.warning.main,
          height: actualHeightBar,
          display: displayActual
        },
        timedTourInfos.tourStart && timedTourInfos.tourEnd && {
          offsetX: timedTourInfos.tourStart.secondsToReference * factor,
          offsetY: yOffsetActual,
          width: (timedTourInfos.tourEnd.secondsToReference - timedTourInfos.tourStart.secondsToReference) * factor,
          durationString: moment.duration((timedTourInfos.tourEnd.secondsToReference - timedTourInfos.tourStart.secondsToReference), 'seconds').format('hh:mm:ss', {trim: false}),
          hoverText: t('actualTour'),
          color: theme.palette.primary.main,
          height: actualHeightBar,
          display: displayActual
        },
        timedTourInfos.packStart && timedTourInfos.tourStart && {
          offsetX: timedTourInfos.packStart.secondsToReference * factor,
          offsetY: yOffsetActual,
          width: (timedTourInfos.tourStart.secondsToReference - timedTourInfos.packStart.secondsToReference) * factor,
          durationString: moment.duration((timedTourInfos.tourStart.secondsToReference - timedTourInfos.packStart.secondsToReference), 'seconds').format('hh:mm:ss', {trim: false}),
          hoverText: t('tourPacking'),
          color: theme.palette.secondary.main,
          height: actualHeightBar,
          display: displayActual
        }
      ],
      pointsInTime: [
        timedTourInfos.plannedTourStart && {
          offsetX: (timedTourInfos.plannedTourStart.secondsToReference * factor) - (markerWidth / 2.0),
          offsetY: yOffsetPlanned + (plannedHeightBar / 2) - (markerHeight / 2),
          hoverText: t('plannedTourStart'),
          timeStamp: timedTourInfos.plannedTourStart.timeStamp,
          color: theme.palette.common.black,
          elementText: '',
          height: markerHeight,
          width: markerWidth,
          rounded: false,
          display: displayPlanned,
        },
        timedTourInfos.tourStart && {
          offsetX: (timedTourInfos.tourStart.secondsToReference * factor) - (markerWidth / 2.0),
          offsetY: yOffsetActual + (actualHeightBar / 2) - (markerHeight / 2),
          hoverText: t('actualTourStart'),
          timeStamp: timedTourInfos.tourStart.timeStamp,
          color: theme.palette.common.black,
          elementText: '',
          height: markerHeight,
          width: markerWidth,
          rounded: false,
          display: displayActual
        },
        timedTourInfos.plannedTourEnd && {
          offsetX: (timedTourInfos.plannedTourEnd.secondsToReference * factor) - (markerWidth / 2.0),
          offsetY: yOffsetPlanned + (plannedHeightBar / 2) - (markerHeight / 2),
          hoverText: t('plannedTourEnd'),
          timeStamp: timedTourInfos.plannedTourEnd.timeStamp,
          color: theme.palette.common.black,
          elementText: '',
          height: markerHeight,
          width: markerWidth,
          rounded: false,
          display: displayPlanned
        },
        timedTourInfos.tourEnd && {
          offsetX: (timedTourInfos.tourEnd.secondsToReference * factor) - (markerWidth / 2.0),
          offsetY: yOffsetActual + (actualHeightBar / 2) - (markerHeight / 2),
          hoverText: t('actualTourEnd'),
          timeStamp: timedTourInfos.tourEnd.timeStamp,
          color: theme.palette.common.black,
          elementText: '',
          height: markerHeight,
          width: markerWidth,
          rounded: false,
          display: displayActual
        },
        timedTourInfos.packStart && {
          offsetX: (timedTourInfos.packStart.secondsToReference * factor) - (markerWidth / 2.0),
          offsetY: yOffsetActual + (actualHeightBar / 2) - (markerHeight / 2),
          hoverText: t('tourPackStart'),
          timeStamp: timedTourInfos.packStart.timeStamp,
          color: theme.palette.common.black,
          elementText: '',
          height: markerHeight,
          width: markerWidth,
          rounded: false,
          display: displayActual
        },
        ...timedTourInfos.plannedStopDeliveries.map(stopDelivery => ({
          offsetX: (stopDelivery.secondsToReference * factor) - (markerWidthRounded / 2.0),
          offsetY: yOffsetPlanned + (plannedHeightBar / 2.0) - (markerHeightRounded / 2.0),
          hoverText: `${t('plannedStopDelivery')} ${stopDelivery.tourStopId}  ${stopDelivery.lastName}`,
          timeStamp: stopDelivery.timeStamp,
          color: '#5f5c5c',
          elementText: stopDelivery.stopNumber,
          height: markerHeightRounded,
          width: markerWidthRounded,
          rounded: true,
          display: displayPlanned
        })),
        ...timedTourInfos.stopDeliveries.map(stopDelivery => ({
          offsetX: (stopDelivery.secondsToReference * factor) - (markerWidthRounded / 2.0),
          offsetY: yOffsetActual + (actualHeightBar / 2.0) - (markerHeightRounded / 2.0),
          hoverText: `${t('actualStopDelivery')} ${stopDelivery.tourStopId}  ${stopDelivery.lastName}`,
          timeStamp: stopDelivery.timeStamp,
          color: stopDelivery.successful ? theme.palette.primary.dark : theme.palette.danger.dark,
          elementText: stopDelivery.stopNumber,
          height: markerHeightRounded,
          width: markerWidthRounded,
          rounded: true,
          display: displayActual
        })),
      ]
    });
  }, [width, timedTourInfos, displayPlanned, displayActual, t, theme])

  return (
    <Portlet className={rootClassName}>
      <PortletHeader>
        <strong>{t('tourTimeSummary')}</strong>
        <LoadingWrapper loading={plannedStopDeliveriesLoading}>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={displayPlanned}
                  color="default"
                  onChange={(event) => {
                    if ((!displayActual && !event.target.checked) || Boolean(!tour.tourStart)) return;
                    setDisplayPlanned(event.target.checked)
                  }}
                  value={displayPlanned}
                />
              }
              label={t('displayPlanned')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={displayActual}
                  color="primary"
                  onChange={(event) => {
                    if (!displayPlanned && !event.target.checked) return;
                    setDisplayActual(event.target.checked)
                  }}
                  value={displayActual}
                />
              }
              label={t('displayActual')}
            />
          </div>
        </LoadingWrapper>
      </PortletHeader>
      <PortletContent>
        <TextPlaceholderWrapper
          active={!tour.approved}
          text={t('tourTimeSummaryNotAvailable')}
        >
          <div
            className={classes.timeBar}
            ref={ref}
          >
            {displayElements && displayElements.timeSpans.filter(x => x).map((element, index) => (
              <Tooltip
                key={index}
                title={`[${element.durationString}] - ${element.hoverText}`}
              >
                <div
                  className={classes.timeSpan}
                  style={{
                    left: element.offsetX, bottom: element.offsetY, width: element.width, backgroundColor: element.color, height: element.height,
                    visibility: element.display ? 'visible' : 'hidden'
                  }}
                />
              </Tooltip>
            ))
            }
            {displayElements && displayElements.pointsInTime.filter(x => x).map((element, index) => (
              <Tooltip
                arrow
                key={index}
                title={`[${DateService.displayTime(element.timeStamp)}] - ${element.hoverText}`}
              >
                <div
                  className={element.rounded ? classes.pointInTimeRound : classes.pointInTime}
                  style={{
                    left: element.offsetX, bottom: element.offsetY, width: element.width, backgroundColor: element.color, height: element.height,
                    visibility: element.display ? 'visible' : 'hidden'
                  }}
                >{element.elementText}</div>
              </Tooltip>
            ))}
          </div>
        </TextPlaceholderWrapper>
      </PortletContent>
    </Portlet>
  );
}


TourTimeSummary.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  tour: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TourTimeSummary);
