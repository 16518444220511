const Columns = {
  microHubName: {
    name: 'microHubName',
    header: ({t}) => t('microHubName'),
    body: ({hub}) => hub.name
  },
  carrier: {
    name: 'carrier',
    header: ({t}) => t('carrier'),
    body: ({hub}) => hub.carriers.map(carrier => carrier.carrier.name).join(' ,')
  },
  streetAndNumber: {
    name: 'streetAndNumber',
    header: ({t}) => t('streetAndNumber'),
    body: ({hub}) => `${hub.address.street} ${hub.address.houseNumber}`
  },
  city: {
    name: 'city',
    header: ({t}) => t('city'),
    body: ({hub}) => hub.address.city
  },
  contact: {
    name: 'contact',
    header: ({t}) => t('contact'),
    body: ({hub}) => hub.contact.name ? hub.contact.name : '-'
  },
}

export default Columns;