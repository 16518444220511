import React from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {Refresh as RefreshIcon} from '@material-ui/icons';
import classNames from 'classnames'
import useDebounce from '../../hooks/useDebounce';


function RefreshButton(props) {

  const {classes, className, t, refresh, refreshFunc, disabled} = props;

  const rootClassName = classNames(classes.root, className);

  const refreshDebounced = useDebounce(refresh, 1000);

  return (
    <Button
      className={refreshDebounced ? classNames(rootClassName, 'buttonShine', classes.activated) : rootClassName}
      color="primary"
      disabled={disabled}
      onClick={refreshFunc}
      variant="contained"
    >
      <RefreshIcon/>&nbsp;{t('refresh')}
    </Button>
  );
}


RefreshButton.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  i18n: PropTypes.object.isRequired,
  refresh: PropTypes.bool.isRequired,
  refreshFunc: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(RefreshButton);
