import React from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {CarrierAndMicroHubDropdownSelect, DropdownSelectSimple,} from '../../../components';


function SpecialDeliveryDayFilter(props) {

  const {
    classes,
    className,
    t,
    filter,
    setFilter,
    carriersWithMicroHubs,
    shipperOptions,
    microHubOptions
  } = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <DropdownSelectSimple
        hideOnEmptyOptions
        name={t('shipper')}
        options={shipperOptions}
        setValue={(value) => setFilter({...filter, shipperName: value})}
        sort
        value={filter.shipperName}
      />
      <CarrierAndMicroHubDropdownSelect
        carriersWithMicroHubs={carriersWithMicroHubs}
        carrierValue={filter.carrierName}
        className={classes.row}
        microHubOptions={microHubOptions}
        microHubValue={filter.microHubName}
        setCarrierValue={(value) => setFilter({...filter, carrierName: value, microHubName: ''})}
        setMicroHubValue={(value) => setFilter({...filter, microHubName: value})}
      />
    </div>
  );
}


SpecialDeliveryDayFilter.propTypes = {
  carriersWithMicroHubs: PropTypes.object,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  filter: PropTypes.shape({
    shipperName: PropTypes.string.isRequired,
    carrierName: PropTypes.string.isRequired,
    microHubName: PropTypes.string.isRequired
  }).isRequired,
  microHubOptions: PropTypes.array,
  setFilter: PropTypes.func.isRequired,
  shipperOptions: PropTypes.array,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(SpecialDeliveryDayFilter);
