import {compose} from 'recompose';
import {Checkbox, Typography, withStyles} from '@material-ui/core';
import {Pets as MicroHubIcon, Place} from '@material-ui/icons';
import {withTranslation} from 'react-i18next';
import styles from './styles'
import classNames from 'classnames';
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {getStopStatusString, StopStatus} from '../../../../services/enums/StopStatus';


function LegendInteractive(props) {

  const {classes, className, t, legendCheckboxesState, setLegendCheckboxesState, hideStopStatus, hideNotPlannedStops} = props;

  const rootClassName = classNames(classes.root, className);


  return (
    <Fragment>
      <Typography
        className={rootClassName}
        variant="body1"
      >
        {!hideNotPlannedStops &&
          <Fragment>
            <Checkbox
              checked={legendCheckboxesState.displayNotPlanedStops}
              className={classes.checkbox}
              color="secondary"
              disabled={legendCheckboxesState.displayNotPlanedStopsDisabled}
              onChange={(event) =>
                setLegendCheckboxesState({...legendCheckboxesState, displayNotPlanedStops: event.target.checked})
              }
              value={legendCheckboxesState.displayNotPlanedStops}
            />
            <Place
              className={classNames(classes.materialIcon, classes.iconNotPlanned)}
            />
            {t(getStopStatusString(StopStatus.NotPlaned))}&nbsp;
          </Fragment>
        }

        {!hideStopStatus &&
        <Fragment>
          <Checkbox
            checked={legendCheckboxesState.displayPlanedStops}
            className={classes.checkbox}
            color="secondary"
            disabled={legendCheckboxesState.displayPlanedStopsDisabled}
            onChange={(event) => setLegendCheckboxesState({...legendCheckboxesState, displayPlanedStops: event.target.checked})}
            value={legendCheckboxesState.displayPlanedStops}
          />
          <Place
            className={classNames(classes.materialIcon, classes.iconPlanned)}
          />
          {t(getStopStatusString(StopStatus.Planed))}&nbsp;

          <Checkbox
            checked={legendCheckboxesState.displayInDeliveryStops}
            className={classes.checkbox}
            color="secondary"
            disabled={legendCheckboxesState.displayInDeliveryStopsDisabled}
            onChange={(event) => setLegendCheckboxesState({...legendCheckboxesState, displayInDeliveryStops: event.target.checked})}
            value={legendCheckboxesState.displayInDeliveryStops}
          />
          <Place
            className={classNames(classes.materialIcon, classes.iconInDelivery)}
          />
          {t(getStopStatusString(StopStatus.InDelivery))}&nbsp;

          <Checkbox
            checked={legendCheckboxesState.displayDeliveredStops}
            className={classes.checkbox}
            color="secondary"
            disabled={legendCheckboxesState.displayDeliveredStopsDisabled}
            onChange={(event) => setLegendCheckboxesState({...legendCheckboxesState, displayDeliveredStops: event.target.checked})}
            value={legendCheckboxesState.displayDeliveredStops}
          />
          <Place
            className={classNames(classes.materialIcon, classes.iconDelivered)}
          />
          {t(getStopStatusString(StopStatus.Delivered))}&nbsp;

          <Checkbox
            checked={legendCheckboxesState.displayCanNotDeliverStops}
            className={classes.checkbox}
            color="secondary"
            disabled={legendCheckboxesState.displayCanNotDeliverStopsDisabled}
            onChange={(event) => setLegendCheckboxesState({...legendCheckboxesState, displayCanNotDeliverStops: event.target.checked})}
            value={legendCheckboxesState.displayCanNotDeliverStops}
          />
          <Place
            className={classNames(classes.materialIcon, classes.iconCanNotDeliver)}
          />
          {t(getStopStatusString(StopStatus.CanNotDeliver))}&nbsp;

          <Checkbox
            checked={legendCheckboxesState.displayRedeliveryStops}
            className={classes.checkbox}
            color="secondary"
            disabled={legendCheckboxesState.displayRedeliveryStopsDisabled}
            onChange={(event) => setLegendCheckboxesState({...legendCheckboxesState, displayRedeliveryStops: event.target.checked})}
            value={legendCheckboxesState.displayRedeliveryStops}
          />
          <Place
            className={classNames(classes.materialIcon, classes.iconRedelivery)}
          />
          {t(getStopStatusString(StopStatus.Redelivery))}&nbsp;
        </Fragment>
        }
        <Checkbox
          checked={legendCheckboxesState.displayMicroHubs}
          className={classes.checkbox}
          color="secondary"
          onChange={(event) => setLegendCheckboxesState({...legendCheckboxesState, displayMicroHubs: event.target.checked})}
          value={legendCheckboxesState.displayMicroHubs}
        />
        <MicroHubIcon
          className={classes.materialIcon}
        />
        {t('microHub')}
      </Typography>
    </Fragment>
  );
}

LegendInteractive.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  hideNotPlannedStops: PropTypes.bool,
  hideStopStatus: PropTypes.bool,
  legendCheckboxesState: PropTypes.object.isRequired,
  setLegendCheckboxesState: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(LegendInteractive);

