import React, {useCallback, useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {SideNavLayout} from '../../../layouts';
import {ClientService} from '../../../services/client/clientService';
import {clientTypes} from '../../../services/client/clientTypes';
import {
  GeneralInfoIcon,
  LoadingWrapper,
  TextPlaceholderWrapper,
  TourTemplatesFilter,
  TourTemplatesView
} from '../../../components';
import {useQuery} from '@apollo/react-hooks';
import {getDayNumberByDayOfWeek} from '../../../services/enums/dayOfWeekHelper';
import tourTemplatesService from '../../../services/backend/tourTemplatesService';
import customersService from '../../../services/backend/customersService';
import {VehicleQueryService} from '../../../services/backend/vehicleQueryService';
import useShipperOptions from '../../../hooks/useShipperOptions';
import filterService from '../../../services/searchServices/filterService';
import useCarriersWithHubs from '../../../hooks/useCarriersWithHubs';
import useMicroHubData from '../../../hooks/useMicroHubData';
import {displayModes} from '../../../services/enums/displayModes';

const clientVehicle = ClientService.getClient(clientTypes.vehicle);

function TourTemplates(props) {
  const {classes, t} = props;

  const [tourTemplates, setTourTemplates] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [backendLoadingTourTemplates, setBackendLoadingTourTemplates] = useState(false);
  const [backendLoadingCustomers, setBackendLoadingCustomers] = useState(false);
  const [filter, setFilter] = useState({
    carrierName: '',
    microHubName: '',
    dayOfWeek: '',
    shipperName: '',
    templateType: ''
  });
  const {shipperOptions} = useShipperOptions();
  const {carriersWithHubs} = useCarriersWithHubs();
  const {microHubData} = useMicroHubData();

  const queryVehicles = VehicleQueryService.getVehiclesByCarrierNameAndMicroHubListQuery(filter.carrierName, [filter.microHubName]);
  const {data: dataVehicles} = useQuery(queryVehicles, {client: clientVehicle, skip: !filter.microHubName || !filter.carrierName});

  const loadTemplates = useCallback(async (withLoadingIndicator = true) => {
    if (withLoadingIndicator) setBackendLoadingTourTemplates(true);
    try {
      const templates = await tourTemplatesService.getTourTemplatesByFilter(filter.carrierName, filter.microHubName, getDayNumberByDayOfWeek(filter.dayOfWeek), filter.shipperName);
      setTourTemplates(templates);
      if (withLoadingIndicator) setBackendLoadingTourTemplates(false);
    } catch (error) {
      alert(`${t('errorLoadingTourTemplates')}, ${error}`);
      if (withLoadingIndicator) setBackendLoadingTourTemplates(false);
    }
  }, [filter.carrierName, filter.microHubName, filter.dayOfWeek, filter.shipperName, t]);

  const loadCustomers = useCallback(async () => {
    setBackendLoadingCustomers(true);
    try {
      const customers = await customersService.getCustomersByFilter(filter.shipperName, filter.carrierName, filter.microHubName, getDayNumberByDayOfWeek(filter.dayOfWeek));
      setCustomers(customers);
      setBackendLoadingCustomers(false);
    } catch (e) {
      alert(`${t('errorLoadingCustomers')}, ${e}`);
      setBackendLoadingCustomers(false);
    }
  }, [filter.carrierName, filter.microHubName, filter.dayOfWeek, filter.shipperName, t]);

  useEffect(() => {
    if (!filter.microHubName || !filter.carrierName || !filter.dayOfWeek) return;
    loadTemplates();
    loadCustomers();
  }, [loadTemplates, loadCustomers, filter.carrierName, filter.microHubName, filter.dayOfWeek, filter.shipperName]);

  return (
    <SideNavLayout title={t('tourTemplates')}>
      <div className={classes.root}>
        <div className={classes.filterWrapper}>
          <TourTemplatesFilter
            carrierWithMicroHubs={carriersWithHubs}
            className={classes.filter}
            filter={filter}
            setFilter={setFilter}
            shipperOptions={shipperOptions}
          />
          <div className={classes.helpWrapper}>
            <GeneralInfoIcon/>
          </div>
        </div>
        <LoadingWrapper loading={backendLoadingTourTemplates || backendLoadingCustomers}>
          <TextPlaceholderWrapper
            active={!filter.carrierName || !filter.microHubName || !filter.dayOfWeek}
            text={t('tourTemplatesNoCarrierAndMicroHubSelected')}
          >
            <TourTemplatesView
              carrierWithMicroHubs={carriersWithHubs}
              customers={customers}
              displayMode={displayModes.admin}
              filter={filter}
              microHubs={microHubData}
              reloadTourTemplates={loadTemplates}
              setTourTemplates={setTourTemplates}
              shipperOptions={shipperOptions}
              tourTemplates={filterService.filterTemplatesByType(tourTemplates, filter.templateType)}
              vehicles={dataVehicles?.getVehiclesByCarrierNameAndMicroHubList || []}
            />
          </TextPlaceholderWrapper>
        </LoadingWrapper>
      </div>
    </SideNavLayout>
  );
}

TourTemplates.propTypes = {
  classes: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(TourTemplates);
