import React from 'react'
import {Button, Dialog, DialogActions, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {StopAndTourMap} from '../index';

function PopoutDialog(props) {

  const {classes, t, dialogState, handleClose, stops, tours, displayTours, dataMicroHubs} = props;

  return (
    <Dialog
      className={classes.dialog}
      fullWidth
      maxWidth={'xl'}
      onClose={handleClose}
      open={dialogState.open}
    >
      <StopAndTourMap
        className={classes.map}
        dataMicroHubs={dataMicroHubs}
        displayTours={displayTours}
        stops={stops}
        tours={tours}
      />
      <DialogActions>
        <Button
          color="primary"
          onClick={handleClose}
        >
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PopoutDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dataMicroHubs: PropTypes.array.isRequired,
  dialogState: PropTypes.object.isRequired,
  displayTours: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  stops: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  tours: PropTypes.array.isRequired,
};


export default compose(withStyles(styles), withTranslation())(PopoutDialog);
