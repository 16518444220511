export default theme => ({
  root: {
    overflow: 'auto'
  },
  tableCell: {
    whiteSpace: 'nowrap'
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  alignLeft: {
    align: 'left',
  },
  paper: {
    padding: theme.spacing(1),
  },
});

