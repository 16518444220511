import {DayOfWeek} from '../enums/dayOfWeekHelper';
import {v4 as uuidv4} from 'uuid';
import {EmptyType} from '../enums/EmptyType';

export class presetsService {

  static getStopPresetWithCarrier(carrier) {
    const empty = JSON.parse(JSON.stringify(emptyStop));
    empty.assignedCarrierName = carrier;
    return empty;
  }

  static getStopPresetWithCarrierAndMicroHub(carrier, microHub) {
    const empty = JSON.parse(JSON.stringify(emptyStop));
    empty.assignedCarrierName = carrier;
    empty.assignedMicroHubName = microHub;
    return empty;
  }

  static getTourPresetWithCarrier(carrier) {
    const empty = JSON.parse(JSON.stringify(emptyTour));
    empty.carrierName = carrier;
    return empty;
  }

  static getPreviewTourPresetWithCarrier(carrier) {
    const emtpy = JSON.parse(JSON.stringify(emptyPreviewTour));
    emtpy.carrierName = carrier;
    return emtpy;
  }

  static getTourTemplatePresetWithCarrierMicroHubDayOfWeekAndShipper(carrier, microHub, dayOfWeek, shipper) {
    const empty = JSON.parse(JSON.stringify(emptyTourTemplate));
    empty.carrier = carrier;
    empty.microHub = microHub;
    empty.shipper = shipper;
    empty.dayOfWeek = dayOfWeek;
    return empty;
  }

  static getProductPreset = () => {
    return ({
      amount: 0,
      boxId: '',
      description: '',
      productType: 1,
      weight: 0,
      volume: 0,
      boxType: '',
      tempId: uuidv4()
    })
  }

  static getPackageInsertPreset() {
    return ({
      amount: 0,
      description: '',
      weight: 0,
      tempId: uuidv4()
    })
  }

  static getEmptyPreset() {
    return ({
      name: '',
      amount: 0,
      tempId: uuidv4(),
      emptyType: EmptyType.Other
    })
  }
}

const emptyPreviewTour = {
  carrierName: '',
  deliveryDate: new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0)),
  frozen: true,
  microHubName: ''
};

const emptyTour = {
  shipperName: '',
  carrierName: '',
  microHubName: '',
  driverEmail: '',
  vehicleLicensePlate: '',
  fix: false,
  approved: false,
  planedTourDelivery: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 6),
  stops: [],
  length: 0,
  duration: 0
};

const emptyStop = {
  customerId: '',
  firstName: '',
  lastName: '',
  address: {
    streetAndNumber: '',
    zipcode: '',
    city: '',
    country: '',
  },
  shipperName: '',
  assignedCarrierName: '',
  assignedMicroHubName: '',
  planedDeliveryDateCarrier: new Date(),
  planedDeliveryDateShipper: new Date(),
  deliverTime: null,
  stopCapacities: {
    volume: 0,
    weight: 0,
    boxAmount: 0
  },
  customerInfo: '',
  driverInfo: '',
  stairMoney: false,
  key: false,
  keyCode: '',
  contact: {
    name: '',
    phoneNumber: ''
  },
  products: [],
  packageInserts: [],
  empties: []
};

const emptyTourTemplate = {
  templateName: '',
  carrier: '',
  microHub: '',
  shipper: '',
  dayOfWeek: DayOfWeek.monday,
  vehicle: null,
  disabled: false,
  isAutoCutTemplate: true,
  min: {
    boxAmount: 0,
    weight: 0,
    volume: 0,
  },
  max: {
    boxAmount: 0,
    weight: 0,
    volume: 0,
  },
  stops: [],
  applyAtMin: {
    weight: null,
    volume: null,
  },
  maxApplicable: {
    weight: null,
    volume: null,
  },
};
