import React, {useEffect, useState} from 'react'
import {FormControl, InputAdornment, TextField, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import {getTrackingStepTypeString, TrackingStepType} from '../../../../../services/enums/TrackingStepType';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {InfoIcon, Portlet, PortletContent} from '../../../../index';

const getEmailPrefix = (email) => {
  if (!email) return '';
  return email.split('@')[0];
}

const emailEnd = '@grünestadtlogistik.com';

function TrackingNotificationsEdit(props) {
  const {classes, className, t, trackingNotifications, setTrackingNotifications} = props;

  const rootClassName = classNames(classes.root, className);


  const [senderEmailPrefix, setSenderEmailPrefix] = useState(getEmailPrefix(trackingNotifications.senderEmail));

  useEffect(() => {
    setSenderEmailPrefix(getEmailPrefix(trackingNotifications.senderEmail));
  }, [trackingNotifications.senderEmail])

  return (
    <Portlet className={rootClassName}>
      <PortletContent>
        <Typography variant="h3">
          {t('trackingNotifications')}
        </Typography>
        <hr/>
        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Checkbox
                checked={trackingNotifications.emailNotificationsDefaultValue}
                color="secondary"
                onChange={(event) => setTrackingNotifications({...trackingNotifications, emailNotificationsDefaultValue: event.target.checked})}
                value={trackingNotifications.emailNotificationsDefaultValue}
              />
            }
            label={<span>{t('emailNotificationsDefaultValue')}&nbsp;<InfoIcon
              descriptionKey={'50'}
              style={{marginBottom: '-5px'}}
            /></span>}
          />
        </FormControl>
        <div className={classes.container}>
          <TextField
            className={classes.formControl}
            error={!senderEmailPrefix}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {emailEnd}
                </InputAdornment>
              )
            }}
            label={t('trackingShipperSenderEmail')}
            onChange={(e) => setTrackingNotifications({...trackingNotifications, senderEmail: e.target.value + emailEnd})}
            required
            value={senderEmailPrefix}
          />
        </div>
        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Checkbox
                checked={trackingNotifications.previewStopUploadedNotifications}
                color="secondary"
                onChange={(event) => setTrackingNotifications({...trackingNotifications, previewStopUploadedNotifications: event.target.checked})}
                value={trackingNotifications.previewStopUploadedNotifications}
              />
            }
            label={t(getTrackingStepTypeString(TrackingStepType.PreviewStopUploaded))}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Checkbox
                checked={trackingNotifications.previewDeadlineExpiredNotifications}
                color="secondary"
                onChange={(event) => setTrackingNotifications({...trackingNotifications, previewDeadlineExpiredNotifications: event.target.checked})}
                value={trackingNotifications.previewDeadlineExpiredNotifications}
              />
            }
            label={t(getTrackingStepTypeString(TrackingStepType.PreviewDeadlineExpired))}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Checkbox
                checked={trackingNotifications.finalDeadlineExpiredNotifications}
                color="secondary"
                onChange={(event) => setTrackingNotifications({...trackingNotifications, finalDeadlineExpiredNotifications: event.target.checked})}
                value={trackingNotifications.finalDeadlineExpiredNotifications}
              />
            }
            label={t(getTrackingStepTypeString(TrackingStepType.FinalDeadlineExpired))}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Checkbox
                checked={trackingNotifications.tourApprovedNotifications}
                color="secondary"
                onChange={(event) => setTrackingNotifications({...trackingNotifications, tourApprovedNotifications: event.target.checked})}
                value={trackingNotifications.tourApprovedNotifications}
              />
            }
            label={t(getTrackingStepTypeString(TrackingStepType.TourApproved))}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Checkbox
                checked={trackingNotifications.tourStartedNotifications}
                color="secondary"
                onChange={(event) => setTrackingNotifications({...trackingNotifications, tourStartedNotifications: event.target.checked})}
                value={trackingNotifications.tourStartedNotifications}
              />
            }
            label={t(getTrackingStepTypeString(TrackingStepType.TourStarted))}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Checkbox
                checked={trackingNotifications.previousStopsDeliveredNotifications}
                color="secondary"
                onChange={(event) => setTrackingNotifications({...trackingNotifications, previousStopsDeliveredNotifications: event.target.checked})}
                value={trackingNotifications.previousStopsDeliveredNotifications}
              />
            }
            label={t(getTrackingStepTypeString(TrackingStepType.PreviousStopsDelivered))}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Checkbox
                checked={trackingNotifications.deliveredNotifications}
                color="secondary"
                onChange={(event) => setTrackingNotifications({...trackingNotifications, deliveredNotifications: event.target.checked})}
                value={trackingNotifications.deliveredNotifications}
              />
            }
            label={t(getTrackingStepTypeString(TrackingStepType.Delivered))}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Checkbox
                checked={trackingNotifications.problemNotifications}
                color="secondary"
                onChange={(event) => setTrackingNotifications({...trackingNotifications, problemNotifications: event.target.checked})}
                value={trackingNotifications.problemNotifications}
              />
            }
            label={t(getTrackingStepTypeString(TrackingStepType.Problem))}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Checkbox
                checked={trackingNotifications.problemNotificationsForShipper}
                color="secondary"
                onChange={(event) => setTrackingNotifications({...trackingNotifications, problemNotificationsForShipper: event.target.checked})}
                value={trackingNotifications.problemNotificationsForShipper}
              />
            }
            label={t('problemNotificationsForShipper')}
          />
        </FormControl>
        <div className={classes.container}>
          <TextField
            className={classes.formControl}
            label={t('problemNotificationText')}
            onChange={(e) => setTrackingNotifications({...trackingNotifications, problemNotificationText: e.target.value})}
            value={trackingNotifications.problemNotificationText}
          />
        </div>
      </PortletContent>
    </Portlet>
  );
}

TrackingNotificationsEdit.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  setTrackingNotifications: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  trackingNotifications: PropTypes.object.isRequired,
};


export default compose(withStyles(styles), withTranslation())(TrackingNotificationsEdit);
