import React from 'react'
import {TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Draggable} from 'react-beautiful-dnd';
import {DndHelperService} from '../../../../../../services/dndHelper/dndHelperService';
import Columns from '../columns';
import {TableCellWithConfig} from '../../../../../index';


function TemplateStopTableRow(props) {

  const {
    classes,
    t,
    stop,
    className,
    index,
    uniqueTemplateId,
    deleteStopFromTemplate,
    columnConfigs
  } = props;

  const rootClassName = classNames(className, classes.root);

  return (
    <Draggable
      draggableId={DndHelperService.getUniqueStopId(uniqueTemplateId, stop.id)}
      index={index}
    >
      {(provided, snapshot) => (
        <TableRow
          className={classNames(rootClassName, {[classes.dragging]: snapshot.isDragging})}
          hover
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          {columnConfigs.map(columnConfig => (
            <TableCellWithConfig
              key={columnConfig.order}
              config={columnConfig}
              className={classes.tableCell}
            >
              {Columns[columnConfig.columnName].body({
                stop, classes, provided, t, deleteStopFromTemplate
              })}
            </TableCellWithConfig>
          ))}
        </TableRow>
      )}
    </Draggable>
  );
}

TemplateStopTableRow.propTypes = {
  columnConfigs: PropTypes.array.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deleteStopFromTemplate: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  stop: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  uniqueTemplateId: PropTypes.string.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TemplateStopTableRow);
