import React, {useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Portlet} from 'components';
import TemplateStopInstanceDetailEdit from './TemplateStopInstanceDetailEdit';
import TemplateStopInstanceDetailShow from './TemplateStopInstanceDetailShow';

function TemplateStopInstanceDetail(props) {
  const {
    classes,
    className,
    templateStopInstance,
    relatedTemplateStopInstances,
    updateTemplateStopInstance,
    readOnly,
    closeDetailView,
    editDisplayMode,
    selectTemplateStopInstance
  } = props;

  const [editMode, setEditMode] = useState(false);
  const [editedTemplateStopInstance, setEditedTemplateStopInstance] = useState(false);

  const editTemplateStopInstance = () => {
    const newTemplateStopInstance = JSON.parse(JSON.stringify(templateStopInstance));
    setEditedTemplateStopInstance(newTemplateStopInstance);
    setEditMode(true);
  };

  const update = () => {
    setEditMode(false);
    updateTemplateStopInstance(editedTemplateStopInstance);
  };

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet className={rootClassName}>
      {editMode ?
        <TemplateStopInstanceDetailEdit
          editDisplayMode={editDisplayMode}
          editedTemplateStopInstance={editedTemplateStopInstance}
          setEditedTemplateStopInstance={setEditedTemplateStopInstance}
          setEditMode={setEditMode}
          updateTemplateStopInstance={update}
        />
        :
        <TemplateStopInstanceDetailShow
          closeDetailView={closeDetailView}
          edit={editTemplateStopInstance}
          readOnly={readOnly}
          includeRelatedTemplateStopInstances={true}
          relatedTemplateStopInstances={relatedTemplateStopInstances}
          selectTemplateStopInstance={selectTemplateStopInstance}
          templateStopInstance={templateStopInstance}
        />
      }
    </Portlet>
  );
}


TemplateStopInstanceDetail.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  closeDetailView: PropTypes.func,
  editDisplayMode: PropTypes.string,
  readOnly: PropTypes.bool,
  relatedTemplateStopInstances: PropTypes.array.isRequired,
  selectTemplateStopInstance: PropTypes.func.isRequired,
  templateStopInstance: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  updateTemplateStopInstance: PropTypes.func,
}
;

export default compose(withStyles(styles), withTranslation())(TemplateStopInstanceDetail);
