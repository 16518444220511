import React, {Fragment, useEffect, useState} from 'react'
import {IconButton, Popover, Table, TableBody, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Portlet,
  PortletContent,
  TableCellWithConfig,
  TableSettings,
  TourEditNameDialog
} from '../../../../../../components';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import {DndHelperElementTypes} from '../../../../../../services/dndHelper/dndHelperService';
import FluentTourTableRow from '../FluentTourTableRow';
import {buildIndexToElementMap} from '../../../../../../services/util/fluidTourTableDndUtils';
import {Add} from '@material-ui/icons';
import useTableConfig from '../../../../../../hooks/useTableConfig';
import DefaultConfig from './config';
import {getTableKey} from '../../../../../../services/util/tableConfigHelper';
import {TableKind} from '../../../../../../services/enums/TableKind';
import Columns from './columns';
import {displayModes} from '../../../../../../services/enums/displayModes';


function FluentTourTable(props) {
  const {
    classes,
    className,
    t,
    tours,
    shipperName,
    onDragEnd,
    vehicles,
    selectVehicle,
    deleteTour,
    addTour,
    editTourName,
  } = props;

  const rootClassName = classNames(classes.root, className);
  const [indexToElementMap, seIndexToElementMap] = useState(buildIndexToElementMap(tours, vehicles));
  const [popoverState, setPopoverState] = useState({anchor: null, index: 0});
  const [hiddenTourIds, setHiddenTourIds] = useState([]);
  const [editTourNameDialogState, setEditTourNameDialogState] = useState({open: false, tour: null});

  const {
    config,
    columnConfigs,
    updateConfig
  } = useTableConfig(
    DefaultConfig,
    displayModes.carrier,
    getTableKey(TableKind.FluentTourTable, displayModes.carrier)
  )


  useEffect(() => {
    seIndexToElementMap(buildIndexToElementMap(tours, vehicles));
  }, [tours, vehicles])

  const openAddTourPopover = (event, index) => {
    setPopoverState({anchor: event.currentTarget, index: index});
  }

  const onAddPress = () => {
    addTour(popoverState.index, indexToElementMap[popoverState.index].element.shipperName);
    setPopoverState({anchor: null, index: 0});
  }

  const handleToggleShowTour = (tourId) => {
    const index = hiddenTourIds.findIndex(a => a === tourId);
    if (index >= 0) {
      const newTourIds = [...hiddenTourIds];
      newTourIds.splice(index, 1);
      setHiddenTourIds(newTourIds);
    } else {
      setHiddenTourIds([...hiddenTourIds, tourId]);
    }
  }

  const handleShowOrHideAll = () => {
    if (hiddenTourIds.length === 0) {
      setHiddenTourIds(tours.map(t => t.tourId));
    } else {
      setHiddenTourIds([]);
    }
  }

  const handleCloseEditTourNameDialog = (tour) => {
    editTourName(tour.tourId, tour.tourName);
    setEditTourNameDialogState({open: false, tour: null});
  }

  return (
    <div style={{position: 'relative'}}>
      <TableSettings
        updateConfig={updateConfig}
        tableConfig={config}
        columns={Columns}
        useNamesAsTranslateString
        className={classes.tableSettings}
        buttonOptic
      />
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <div style={{overflow: 'auto', maxHeight: 'calc(-195px + 100vh)'}}>
            <Table
              size="small"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {columnConfigs.map(columnConfig => (
                    <TableCellWithConfig
                      key={columnConfig.order}
                      config={columnConfig}
                      className={classes.tableHeader}
                    >
                      {Columns[columnConfig.columnName].header({
                        t,
                        classes,
                        handleShowOrHideAll,
                        hiddenTourIds,
                        updateConfig,
                        config,
                        columns: Columns
                      })}
                    </TableCellWithConfig>
                  ))}
                </TableRow>
              </TableHead>
              <DragDropContext
                onDragEnd={onDragEnd}
              >
                <Droppable
                  droppableId={shipperName}
                  type={DndHelperElementTypes.Stop}
                >
                  {(provided) => {
                    return (
                      <TableBody
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <Fragment>
                          {Object.keys(indexToElementMap).map(key => {
                            const entry = indexToElementMap[key];
                            const index = parseInt(key);
                            const hideRow = hiddenTourIds.includes(entry.type === DndHelperElementTypes.Stop ? entry.tourId : entry.id)
                            return (
                              <FluentTourTableRow
                                className={classes.tableRow}
                                columnConfigs={columnConfigs}
                                deleteTour={deleteTour}
                                editTourName={(tour) => setEditTourNameDialogState({open: true, tour: tour})}
                                element={entry.element}
                                elementId={entry.id}
                                elementType={entry.type}
                                hideRow={hideRow}
                                index={index}
                                key={`${entry.type}-${entry.id}`}
                                openAddTourPopover={openAddTourPopover}
                                selectVehicle={selectVehicle}
                                toggleShowTour={handleToggleShowTour}
                                vehicleInfos={entry.vehicleInfos}
                              />
                            );
                          })}
                          {provided.placeholder}
                          <Popover
                            anchorEl={popoverState.anchor}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            classes={{
                              paper: classes.popoverPaper,
                            }}
                            elevation={1}
                            onClose={() => setPopoverState({anchor: null, index: 0})}
                            open={Boolean(popoverState.anchor)}
                            transformOrigin={{
                              vertical: 'center',
                              horizontal: 'right',
                            }}
                          >
                            <IconButton
                              color="primary"
                              onClick={onAddPress}
                              size={'small'}
                              title={t('createTour')}
                            >
                              <Add/>
                            </IconButton>
                          </Popover>
                        </Fragment>
                      </TableBody>
                    );
                  }}
                </Droppable>
              </DragDropContext>
            </Table>
          </div>
          <TourEditNameDialog
            dialogState={editTourNameDialogState}
            handleCancel={() => setEditTourNameDialogState({open: false, tour: null})}
            handleClose={handleCloseEditTourNameDialog}
          />
        </PortletContent>
      </Portlet>
    </div>
  );
}

FluentTourTable.propTypes = {
  addTour: PropTypes.func.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deleteTour: PropTypes.func.isRequired,
  editTourName: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  selectVehicle: PropTypes.func.isRequired,
  shipperName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  tours: PropTypes.array.isRequired,
  vehicles: PropTypes.array.isRequired
};

export default compose(withStyles(styles), withTranslation())(FluentTourTable);
