import {gql} from 'apollo-boost';

export class DriverQueryService {


  static getDriversByCarrierNameAndMicroHubListQuery(carrier, microHubNames, donationButtonConfirmed = null) {
    const startSymbol = microHubNames && microHubNames.length > 0 ? '"' : '';
    const donationButtonConfirmedArg = donationButtonConfirmed != null ? `, donationButtonConfirmed: ${donationButtonConfirmed}` : '';
    const carrierNameArg = carrier != null ? `carrierName: "${carrier}", `: '';
    return gql`
      {
        getDriversByCarrierNameAndMicroHubList(${carrierNameArg}microhubNames: [${startSymbol}${microHubNames.join('", "')}${startSymbol}]${donationButtonConfirmedArg}) {
          id
          bicycle
          car
          email
          displayName
          firstname
          lastname
          microhubs {
            name
            id
          }
          carrierName
          truck
          phoneNumber
          donationButton
          donationButtonConfirmed
        }
      }
    `;
  }


  static deleteDriverByIdQuery() {
    return gql`
       mutation ($driverId: Int!) {
        deleteDriver(driverId: $driverId)
      }
    `;
  }

  static updateDriverQuery() {
    return gql`
      mutation ($updatedDriver: DriverInputType!) {
        updateDriver(updatedDriver: $updatedDriver) {
          bicycle
          car
          email
          displayName
          firstname
          id
          lastname
          microhubs {
            name
            id
          }
          phoneNumber
          truck
          carrierName
        }
      }
    `;
  }

  static createDriverQuery() {
    return gql`
      mutation ($driverToCreate: DriverInputType!) {
        createDriver(driverToCreate: $driverToCreate) {
          bicycle
          car
          email
          displayName
          firstname
          id
          lastname
          microhubs {
            name
            id
          }
          phoneNumber
          truck
          carrierName
        }
      }
    `;
  }

  static updateDriverDonationButton() {
    return gql`
      mutation ($driverId: Int!, $donationButton: String) {
        updateDriverDonationButton(driverId: $driverId, donationButton: $donationButton) {
          bicycle
          car
          email
          displayName
          firstname
          id
          lastname
          microhubs {
            name
            id
          }
          phoneNumber
          truck
          carrierName
          donationButton
          donationButtonConfirmed
        }
      }
    `;
  }

  static approveDonationButton() {
    return gql`
      mutation ($driverId: Int!, $approved: Boolean!) {
        approveDonationButton(driverId: $driverId, approved: $approved) {
          bicycle
          car
          email
          displayName
          firstname
          id
          lastname
          microhubs {
            name
            id
          }
          phoneNumber
          truck
          carrierName
          donationButton
          donationButtonConfirmed
        }
      }
    `;
  }

}
