import {DndHelperElementTypes} from '../dndHelper/dndHelperService';
import {range, without} from 'lodash'

/*==================================HELPER===========================================================*/

// gets the stops of a tour after a stop was dropped reordering the tour
const getStopsOfReorderedTour = (map, tourIndex, stopSourceIndex, stopDestinationIndex, isDestinationTour = false) => {
  let stops = [];
  if (isDestinationTour && stopDestinationIndex === tourIndex) {
    // handle if dropped stop is first stop on another tour ==> it has the index of the tour
    stops.push(map[stopSourceIndex].element)
  }
  let index = tourIndex + 1;
  while (map[index] && map[index].type === DndHelperElementTypes.Stop) {
    if (index === stopDestinationIndex && stopSourceIndex < stopDestinationIndex) {
      // insert dropped stop after
      stops.push(map[index].element)
      index++;
      stops.push(map[stopSourceIndex].element);
    } else if (index === stopDestinationIndex && stopSourceIndex > stopDestinationIndex) {
      // insert dropped stop before
      stops.push(map[stopSourceIndex].element);
      stops.push(map[index].element)
      index++;
    } else if (index === stopSourceIndex) {
      // skipp dropped stop in original order
      index++;
    } else {
      // add unchanged stop
      stops.push(map[index].element)
      index++;
    }
  }
  if (isDestinationTour && index === stopDestinationIndex) {
    // handle if dropped stop is the last stop on another tour ==> it has the index of the next tour
    stops.push(map[stopSourceIndex].element)
  }
  return stops;
};

// get the tourId of the previous tour to a tour at elementIndex, or the tour of a stop at elementIndex
const getPreviousTourOrTourOfStop = (map, elementIndex) => {
  let index = elementIndex - 1;
  while (map[index] && map[index].type === DndHelperElementTypes.Stop) {
    index--;
  }
  return {
    index: index,
    tourId: map[index].id
  }
}

// finds all stops that surround a tour at tourIndex
const getSurroundingStops = (map, tourIndex) => {
  const stops = [];
  // find stops before the tour
  let index = tourIndex - 1;
  while (map[index] && map[index].type === DndHelperElementTypes.Stop) {
    stops.unshift(map[index].element);
    index--;
  }
  // find stops behind the tour
  index = tourIndex + 1;
  while (map[index] && map[index].type === DndHelperElementTypes.Stop) {
    stops.push(map[index].element);
    index++;
  }
  return stops;
}

/*==================================END HELPER=======================================================*/

// builds a map for tours that gives each tour and stop a consecutive index
const buildIndexToElementMap = (tours, vehicles = null) => {
  let index = 0;
  let indexToElementMap = {};
  tours.forEach(tour => {
    let vehicleCapacities = null;
    // find vehicle for tour to calculate capacities
    if (vehicles?.length) {
      const vehicleIndex = vehicles.findIndex(vehicle => vehicle.licensePlate === tour.vehicleLicensePlate);
      if (vehicleIndex >= 0) {
        vehicleCapacities = {
          weight: vehicles[vehicleIndex].payloadWeight,
          volume: vehicles[vehicleIndex].volume,
        }
      }
    }
    indexToElementMap[index] = {type: DndHelperElementTypes.Tour, id: tour.tourId, element: tour, vehicleInfos: JSON.parse(JSON.stringify(vehicleCapacities))};
    index++;
    // calculate if capacities of vehicle still match
    tour.stops.forEach(stop => {
      let stopFitsOnVehicle = true;
      if (vehicleCapacities) {
        vehicleCapacities.weight -= stop.stopCapacities.weight;
        vehicleCapacities.volume -= stop.stopCapacities.volume;
        stopFitsOnVehicle = vehicleCapacities.volume > 0 && vehicleCapacities.weight > 0;
      }
      indexToElementMap[index] = {type: DndHelperElementTypes.Stop, tourId: tour.tourId, id: stop.tourStopId, element: stop, vehicleInfos: stopFitsOnVehicle};
      index++;
    });
  });
  return indexToElementMap;
}

// Determine if it is allowed to drop a tour in a certain place
const isDropAllowedForTour = (map, sourceIndex, destinationIndex) => {
  // it is not allowed to move the first tour since it would produce not planned stops
  if (sourceIndex === 0) return false;

  // it is not allowed to drag a tour over another tour
  const indicesToCheck = sourceIndex < destinationIndex ? range(sourceIndex + 1, destinationIndex + 1, 1) : range(sourceIndex - 1, destinationIndex - 1, -1);
  return indicesToCheck.reduce((acc, i) => map[i].type !== DndHelperElementTypes.Tour && acc, true);
}

// Determine if it is allowed to drop a stop in a certain place
const isDropAllowedForStop = (map, sourceIndex, destinationIndex) => {
  // it is not allowed to move a stop in front of the first tour!
  return destinationIndex !== 0;

};

// gets the stops that are on the new Tour and the previous tour of a stop after dropping a stop
const getToursWithStopsAfterStopDrop = (map, sourceIndex, destinationIndex) => {
  const oldTourInfos = getPreviousTourOrTourOfStop(map, sourceIndex);
  const newTourInfos = getPreviousTourOrTourOfStop(map, sourceIndex < destinationIndex ? destinationIndex + 1 : destinationIndex);

  if (newTourInfos.tourId !== oldTourInfos.tourId) {
    return [
      {
        id: oldTourInfos.tourId,
        stops: getStopsOfReorderedTour(map, oldTourInfos.index, sourceIndex, destinationIndex),
      },
      {
        id: newTourInfos.tourId,
        stops: getStopsOfReorderedTour(map, newTourInfos.index, sourceIndex, destinationIndex, true),
      }
    ]
  } else {
    // Stop remains on old tour, only stop order changed
    return [
      {
        id: oldTourInfos.tourId,
        stops: getStopsOfReorderedTour(map, oldTourInfos.index, sourceIndex, destinationIndex)
      }
    ]
  }
};

// gets the stops that are on the tour and the previous tour after dropping a tour
const getToursWithStopsAfterTourDrop = (map, sourceIndex, destinationIndex) => {
  const affectedStops = getSurroundingStops(map, sourceIndex);
  const stopsOnTourAfterDrop = [];
  if (destinationIndex > sourceIndex) {
    // find all stops from destinationIndex to next tour
    let index = destinationIndex + 1;
    while (map[index] && map[index].type === DndHelperElementTypes.Stop) {
      stopsOnTourAfterDrop.push(map[index].element);
      index++;
    }
  } else {
    // find all stos from destinationIndex to the next but one tour
    let index = destinationIndex;
    let tourEncountered = false;
    while (map[index] && (map[index].type === DndHelperElementTypes.Stop || !tourEncountered)) {
      if (map[index].type === DndHelperElementTypes.Tour) {
        tourEncountered = true;
      } else {
        stopsOnTourAfterDrop.push(map[index].element);
      }
      index++
    }
  }

  return [
    {
      id: map[sourceIndex].id,
      stops: stopsOnTourAfterDrop,
    },
    {
      id: getPreviousTourOrTourOfStop(map, sourceIndex).tourId,
      stops: without(affectedStops, ...stopsOnTourAfterDrop),
    }
  ]
}

// gets the stops that are on the previous tour after deleting a tour
const getTourWithStopsOfDeletedTour = (map, indexOfDeletedTour) => {
  // if tour has no stops it can just be deleted
  if (!map[indexOfDeletedTour].element?.stops?.length) return [];
  // attack stops of tour to previous tour
  const affectedStops = getSurroundingStops(map, indexOfDeletedTour);
  const previousTour = getPreviousTourOrTourOfStop(map, indexOfDeletedTour);
  return [{
    id: previousTour.tourId,
    stops: affectedStops,
  }]
}

// gets the stops that are on the previous and new tour after adding a tour at indexOfNewTour
const getToursWithStopsAfterTourAdd = (map, indexOfNewTour, newTourId) => {

  // find all stops from index to previous tour
  const stopsForPreviousTour = [];
  let index = indexOfNewTour;
  while (map[index] && map[index].type === DndHelperElementTypes.Stop) {
    stopsForPreviousTour.unshift(map[index].element);
    index--;
  }
  const previousTourId = map[index].id;

  // find all stops from index to next tour
  const stopsForNewTour = [];
  index = indexOfNewTour + 1;
  while (map[index] && map[index].type === DndHelperElementTypes.Stop) {
    stopsForNewTour.push(map[index].element);
    index++;
  }

  return [
    {
      id: previousTourId,
      stops: stopsForPreviousTour,
    },
    {
      id: newTourId,
      stops: stopsForNewTour,
    }
  ]
}

export {
  buildIndexToElementMap,
  isDropAllowedForTour,
  getToursWithStopsAfterTourDrop,
  getToursWithStopsAfterStopDrop,
  isDropAllowedForStop,
  getTourWithStopsOfDeletedTour,
  getToursWithStopsAfterTourAdd
}
