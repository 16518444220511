import React from 'react';
import {compose} from 'recompose';
import {Checkbox, FormControl, FormControlLabel, Tooltip, withStyles} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import styles from './styles'
import classNames from 'classnames';
import PropTypes from 'prop-types';


function MapControls(props) {

  const {
    classes,
    className,
    t,
    useCluster,
    setUseCluster,
    highLiteDuplicates,
    setHighLiteDuplicates,
    useDuplicateSpiderfy,
    setUseDuplicateSpiderfy,
    additionalControls,
    clusteringDisabled
  } = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <div>
        <FormControl className={classes.formControl}>
          <Tooltip title={t('useClusterTooltip')}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={clusteringDisabled ? false : useCluster}
                  color="secondary"
                  disabled={clusteringDisabled}
                  onChange={(event) => setUseCluster(event.target.checked)}
                  value={clusteringDisabled ? false : useCluster}
                />
              }
              label={t('useCluster')}
            />
          </Tooltip>
        </FormControl>
        <FormControl className={classes.formControl}>
          <Tooltip title={t('useDuplicateSpiderfyDescription')}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={useDuplicateSpiderfy}
                  color="secondary"
                  onChange={(event) => setUseDuplicateSpiderfy(event.target.checked)}
                  value={useDuplicateSpiderfy}
                />
              }
              label={t('useDuplicateSpiderfy')}
            />
          </Tooltip>
        </FormControl>
        {useDuplicateSpiderfy &&
        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Checkbox
                checked={highLiteDuplicates}
                color="secondary"
                onChange={(event) => setHighLiteDuplicates(event.target.checked)}
                value={highLiteDuplicates}
              />
            }
            label={t('highLiteDuplicates')}
          />
        </FormControl>
        }
      </div>
      {additionalControls && additionalControls}
    </div>
  );
}

MapControls.propTypes = {
  additionalControls: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  clusteringDisabled: PropTypes.bool,
  highLiteDuplicates: PropTypes.bool.isRequired,
  setHighLiteDuplicates: PropTypes.func.isRequired,
  setUseCluster: PropTypes.func.isRequired,
  setUseDuplicateSpiderfy: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  useCluster: PropTypes.bool.isRequired,
  useDuplicateSpiderfy: PropTypes.bool.isRequired,
};


export default compose(withStyles(styles), withTranslation())(MapControls);

