import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {TemplateStopType} from '../services/enums/TemplateStopType';
import {TemplateStopService} from '../services/backend/templateStopService';

export default function useLoadTemplateStopInstanceByIdCache() {
  const [loadingSelectedTemplateStopInstance, setLoadingSelectedTemplateStopInstance] = useState(false);
  const [selectedTemplateStopInstance, setSelectedTemplateStopInstance] = useState(null);
  const [selectedRelatedTemplateStopInstances, setSelectedRelatedTemplateStopInstances] = useState([]);
  const [cache, setCache] = useState({});

  const { t } = useTranslation();

  const getTemplateStopInstanceById = async (templateStopInstanceId) => {
    resetSelectedTemplateStopInstance();
    if (cache[templateStopInstanceId.toString()]) {
      setSelectedTemplateStopInstance(cache[templateStopInstanceId.toString()].templateStopInstance);
      setSelectedRelatedTemplateStopInstances(cache[templateStopInstanceId.toString()].relatedTemplateStopInstances);
    } else {
      setLoadingSelectedTemplateStopInstance(true);
      try {
        const templateStopInstance = await TemplateStopService.getTemplateStopInstanceById(templateStopInstanceId);
        setSelectedTemplateStopInstance(templateStopInstance);
        let relatedTemplateStopInstances;
        if (templateStopInstance.stopType === TemplateStopType.Pickup) {
          relatedTemplateStopInstances = templateStopInstance.pickupRelations;
        } else {
          relatedTemplateStopInstances = templateStopInstance.deliveryRelations;
        }
        setSelectedRelatedTemplateStopInstances(relatedTemplateStopInstances);
        setCache({...cache, [templateStopInstanceId.toString()]: {templateStopInstance, relatedTemplateStopInstances}});
        setLoadingSelectedTemplateStopInstance(false);
      } catch {
        setLoadingSelectedTemplateStopInstance(false);
        alert(t('errorLoadingTemplateStopInstance'))
      }
    }
  };

  const resetLoadTemplateStopInstanceByIdCache = () => {
    setCache({});
  };

  const resetSelectedTemplateStopInstance = () => {
    setSelectedTemplateStopInstance(null);
    setSelectedRelatedTemplateStopInstances([]);
  };

  const updateCache = (TemplateStopInstance, relatedTemplateStopInstances) => {
    setCache({ ...cache, [TemplateStopInstance.id.toString()]: TemplateStopInstance });
    if (selectedTemplateStopInstance && selectedTemplateStopInstance.id === TemplateStopInstance.id) {
      setSelectedTemplateStopInstance(TemplateStopInstance);
      setSelectedRelatedTemplateStopInstances(relatedTemplateStopInstances);
    }
  };

  return {
    selectedTemplateStopInstance,
    selectedRelatedTemplateStopInstances,
    loadingSelectedTemplateStopInstance,
    getTemplateStopInstanceById,
    resetLoadTemplateStopInstanceByIdCache,
    resetSelectedTemplateStopInstance,
    updateCache
  };
}
