export const AgeVerificationStatus = {
  Success: 1,
  Failed: 2,
  NotPossible: 3
};

export function getAgeVerificationStatusString(status) {
  switch (status) {
    case AgeVerificationStatus.Success:
      return 'ageVerificationStatusSuccess';
    case AgeVerificationStatus.Failed:
      return 'ageVerificationStatusFailed';
    case AgeVerificationStatus.NotPossible:
      return 'ageVerificationStatusNotPossible';
    default:
      return 'ageVerificationStatusUndefined'
  }
}