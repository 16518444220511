import L from 'leaflet';
import {customerIconColor, IconService, notPlanedIconColor} from '../../../../../services/icon/iconService';
import {DateService} from '../../../../../services/util/DateService';
import {getColorByStatus, getStopStatusString} from '../../../../../services/enums/StopStatus';
import {
  displayDeliveryWeekDay,
  displayShipperAndShipperShortName,
  displayShipperShortName
} from '../../../../../services/util/helperFuncs';
import DOMPurify from 'dompurify';
import {getColorByType, getTemplateStopTypeString} from '../../../../../services/enums/TemplateStopType';

const getCustomerIcon = (customer, t, color) => {
  const title = `${t('customer')} ${customer.id} | ${customer.lastName || ''} | ${customer.address?.streetAndNumber || ''} | ${customer.address?.zipcode || ''}`;
  return IconService.getCustomerMarkerIcon(color, title);
};

const getTemplateStopIcon = (template, stop, t) => {
  const title = `${t('customer')} ${stop.customer?.internalCustomerId} | ${stop.streetAndNumber || ''} | ${stop.zipcode || ''} | ${stop.customer?.lastName || ''}`;
  return IconService.getTourStopMarkerIcon(template.color, stop.stopNumber, title);
};

const getPreviewStopIcon = (stop, t, color, displayStopNumber) => {
  const title = `${t('stop')}  ${stop.id} | ${stop.address?.streetAndNumber || ''}`;
  return IconService.getTourStopMarkerIcon(color, displayStopNumber ? stop.stopNumber : null, title);
};

const getStopIcon = (stop, t, color, displayStopNumber, forceDisplayPreviewOrder = false) => {
  const title = `${t('stop')}  ${stop.tourStopId} | ${stop.lastName || ''} | ${stop.address?.streetAndNumber || ''}`;
  const number = forceDisplayPreviewOrder ? stop.previewOrder : (displayStopNumber ? stop.stopNumber : null);
  if (color) return IconService.getTourStopMarkerIcon(color, number, title);
  else return IconService.getTourStopMarkerIcon(getColorByStatus(stop.stopStatus), number, title);
};

const getTemplateStopServiceIcon = (titleKey, stop, color, displayStopNumber) => {
  const title = `${titleKey}  ${stop.id} | ${stop.name || ''} | ${stop.address?.streetAndNumber || ''}`;
  const number = (displayStopNumber ? stop.stopNumber : null);
  if (color) return IconService.getTourStopMarkerIcon(color, number, title);
  else return IconService.getTourStopMarkerIcon(getColorByType(stop.stopType), number, title);
}

const getCustomerMarker = (customer, mcg, t, onSelectCustomer = undefined, color = customerIconColor) => {
  L.marker(new L.LatLng(customer.address.latitude, customer.address.longitude), {
    icon: getCustomerIcon(customer, t, color)
  })
    .addTo(mcg)
    .on('click', () => onSelectCustomer ? onSelectCustomer(customer) : {})
    .bindPopup(
      DOMPurify.sanitize(
        `<strong>${t('internalCustomerId')}</strong>: ${customer.id}<br/>` +
             `<strong>${t('customerId')}</strong>: ${customer.externalCustomerId}<br/>` +
             `<strong>${t('firstName')}</strong>: ${customer?.firstName || '-'}<br/>` +
             `<strong>${t('lastName')}</strong>: ${customer?.lastName || '-'}<br/>` +
             `<strong>${t('streetAndNumber')}</strong>: ${customer.address?.streetAndNumber || '-'}<br/>` +
             `<strong>${t('city')}</strong>: ${customer.address?.city}<br/>` +
             `<strong>${t('zipcode')}</strong>: ${customer.address?.zipcode}`
      )
    )
};

const getTemplateStopMarker = (template, stop, mcg, t, onSelectCustomer = undefined) => {
  L.marker(new L.LatLng(stop.customer.latitude, stop.customer.longitude), {
    icon: getTemplateStopIcon(template, stop, t)
  })
    .addTo(mcg)
    .on('click', () => onSelectCustomer ? onSelectCustomer(stop) : {})
    .bindPopup(
      DOMPurify.sanitize(
        `<strong>${t('tourTemplateStopId')}</strong>: ${stop.id}<br/>` +
             `<strong>${t('stopNumber')}</strong>: ${stop.stopNumber !== null ? stop.stopNumber : '-'}<br/>` +
             `<strong>${t('firstName')}</strong>: ${stop?.customer?.firstName || '-'}<br/>` +
             `<strong>${t('lastName')}</strong>: ${stop?.customer?.lastName || '-'}<br/>` +
             `<strong>${t('internalCustomerId')}</strong>: ${stop?.customer.internalCustomerId || '-'}`
      )
    )
};

const getPreviewStopMarker = (stop, mcg, t, onSelectStop = undefined, color = notPlanedIconColor, displayStopNumber = false) => {
  L.marker(new L.LatLng(stop.address.latitude, stop.address.longitude), {
    icon: getPreviewStopIcon(stop, t, color, displayStopNumber)
  })
    .addTo(mcg)
    .on('click', () => onSelectStop ? onSelectStop(stop) : {})
    .bindPopup(
      DOMPurify.sanitize(
        `<strong>${t('previewStopID')}</strong>: ${stop.id}<br/>` +
             `<strong>${t('stopNumber')}</strong>: ${stop.stopNumber !== null ? stop.stopNumber : ''}<br/>` +
             `<strong>${t('firstName')}</strong>: ${stop.firstName ? stop.firstName : ''}<br/>` +
             `<strong>${t('lastName')}</strong>: ${stop.lastName ? stop.lastName : ''}<br/>` +
             `<strong>${t('streetAndNumber')}</strong>: ${stop.address.streetAndNumber ? stop.address.streetAndNumber : ''}<br/>` +
             `<strong>${t('city')}</strong>: ${stop.address.city ? stop.address.city : ''}<br/>` +
             `<strong>${t('zipcode')}</strong>: ${stop.address.zipcode ? stop.address.zipcode : ''}<br/>` +
             `<strong>${t('shipper')}</strong>: ${displayShipperShortName(stop)}<br/>` +
             `<strong>${t('deliveryDate')}</strong>: ${DateService.displayDate(stop.deliveryDate)}<br/>` +
             `<strong>${t('microHub')}</strong>: ${stop.assignedMicroHubName ? stop.assignedMicroHubName : ''}<br/>` +
             `<strong>${t('orderIds')}</strong>: ${stop.orderIds.join(', ')}`
      )
    )
};

const getPreviewTourStopMarker = (tour, stop, mcg, t, onSelectStop = undefined) => {
  return getPreviewStopMarker(stop, mcg, t, onSelectStop, tour.color, true);
};

const getStopMarker = (stop, mcg, t, onSelectStop, color = '', displayStopNumber = false, forceDisplayPreviewOrder = false) => {
  L.marker(new L.LatLng(stop.address.latitude, stop.address.longitude), {
    icon: getStopIcon(stop, t, color, displayStopNumber, forceDisplayPreviewOrder),
  })
    .addTo(mcg)
    .on('click', () => onSelectStop ? onSelectStop(stop) : {})
    .bindPopup(
      DOMPurify.sanitize(
        `<strong>${t('tourStopId')}</strong>: ${stop.tourStopId}<br/>` +
             `<strong>${t('stopNumber')}</strong>: ${stop.stopNumber !== null ? stop.stopNumber : ''}<br/>` +
             `<strong>${t('firstName')}</strong>: ${stop.firstName ? stop.firstName : ''}<br/>` +
             `<strong>${t('lastName')}</strong>: ${stop.lastName ? stop.lastName : ''}<br/>` +
             `<strong>${t('streetAndNumber')}</strong>: ${stop.address.streetAndNumber ? stop.address.streetAndNumber : ''}<br/>` +
             `<strong>${t('city')}</strong>: ${stop.address.city ? stop.address.city : ''}<br/>` +
             `<strong>${t('zipcode')}</strong>: ${stop.address.zipcode ? stop.address.zipcode : ''}<br/>` +
             `<strong>${t('shipper')}</strong>: ${displayShipperAndShipperShortName(stop)}<br/>` +
             `<strong>${t('previewOrderLong')}</strong>: ${stop.previewOrder !== null ? stop.previewOrder : ''}<br/>` +
             `<strong>${t('planedDeliveryDateShipper')}</strong>: ${DateService.displayDate(stop.planedDeliveryDateShipper)}<br/>` +
             `<strong>${t('planedDeliveryDateCarrier')}</strong>: ${DateService.displayDate(stop.planedDeliveryDateCarrier)}<br/>` +
             `<strong>${t('estimatedDeliveryWindow')}</strong>: ${stop.estimatedDeliveryWindow?.from ? DateService.displayTime(stop.estimatedDeliveryWindow.from) : ''} - ${stop.estimatedDeliveryWindow?.to ? DateService.displayTime(stop.estimatedDeliveryWindow.to) : ''}<br/>` +
             `<strong>${t('stopStatus')}</strong>: ${t(getStopStatusString(stop.stopStatus))}<br/>` +
             `<strong>${t('microHub')}</strong>: ${stop.assignedMicroHubName ? stop.assignedMicroHubName : ''}<br/>`
      )
    )
};

const getTourStopMarker = (tour, stop, mcg, t, onSelectStop, useStopStatus = false, forceDisplayPreviewOrder = false) => {
  return getStopMarker(stop, mcg, t, onSelectStop, useStopStatus ? '' : tour.color, true, forceDisplayPreviewOrder);
};

// Markers for TemplateStop from the TemplateStopService
const getTemplateStopInstanceTourStopMarker = (tour, stop, mcg, t, onSelectStop, useStopType = false) => {
  return getTemplateStopServiceInstanceMarker(stop, mcg, t, onSelectStop, useStopType ? '' : tour.color, true);
};

// Markers for TemplateStop from the TemplateStopService
const getTemplateStopServiceMarker = (stop, mcg, t, onSelectStop, color = '', displayStopNumber = false) => {
  L.marker(new L.LatLng(stop.address.latitude, stop.address.longitude), {
    icon: getTemplateStopServiceIcon(t('templateStop'), stop, color, displayStopNumber),
  })
    .addTo(mcg)
    .on('click', () => onSelectStop ? onSelectStop(stop) : {})
    .bindPopup(
      DOMPurify.sanitize(
        `<strong>${t('templateStopId')}</strong>: ${stop.id}<br/>` +
        `<strong>${t('name')}</strong>: ${stop.name ?? ''}<br/>` +
        `<strong>${t('internalCustomerId')}</strong>: ${stop.internalCustomerId ?? ''}<br/>` +
        `<strong>${t('customerId')}</strong>: ${stop.customerId ?? ''}<br/>` +
        `<strong>${t('streetAndNumber')}</strong>: ${stop.address.streetAndNumber ? stop.address.streetAndNumber : ''}<br/>` +
        `<strong>${t('city')}</strong>: ${stop.address.city ? stop.address.city : ''}<br/>` +
        `<strong>${t('zipcode')}</strong>: ${stop.address.zipcode ? stop.address.zipcode : ''}<br/>` +
        `<strong>${t('shipper')}</strong>: ${displayShipperAndShipperShortName(stop)}<br/>` +
        `<strong>${t('deliveryWeekDay')}</strong>: ${displayDeliveryWeekDay(t, stop.deliveryWeekDay)}<br/>` +
        `<strong>${t('stopType')}</strong>: ${t(getTemplateStopTypeString(stop.stopType))}<br/>`
      )
    )
};

const getTemplateStopServiceInstanceMarker = (stop, mcg, t, onSelectStop, color = '', displayStopNumber = false) => {
  L.marker(new L.LatLng(stop.address.latitude, stop.address.longitude), {
    icon: getTemplateStopServiceIcon(t('templateStopInstance'), stop, color, displayStopNumber),
  })
    .addTo(mcg)
    .on('click', () => onSelectStop ? onSelectStop(stop) : {})
    .bindPopup(
      DOMPurify.sanitize(
        `<strong>${t('templateStopId')}</strong>: ${stop.id}<br/>` +
        `<strong>${t('tourId')}</strong>: ${stop.tourId ?? '-'}<br/>` +
        `<strong>${t('name')}</strong>: ${stop.name ?? ''}<br/>` +
        `<strong>${t('internalCustomerId')}</strong>: ${stop.internalCustomerId ?? ''}<br/>` +
        `<strong>${t('customerId')}</strong>: ${stop.customerId ?? ''}<br/>` +
        `<strong>${t('streetAndNumber')}</strong>: ${stop.address.streetAndNumber ? stop.address.streetAndNumber : ''}<br/>` +
        `<strong>${t('city')}</strong>: ${stop.address.city ? stop.address.city : ''}<br/>` +
        `<strong>${t('zipcode')}</strong>: ${stop.address.zipcode ? stop.address.zipcode : ''}<br/>` +
        `<strong>${t('shipper')}</strong>: ${displayShipperAndShipperShortName(stop)}<br/>` +
        `<strong>${t('carrier')}</strong>: ${stop.carrierName}<br/>` +
        `<strong>${t('microHub')}</strong>: ${stop.microHubName}<br/>` +
        `<strong>${t('deliveryDate')}</strong>: ${DateService.displayDate(stop.deliveryDate)}<br/>` +
        `<strong>${t('stopType')}</strong>: ${t(getTemplateStopTypeString(stop.stopType))}<br/>`
      )
    )
};


export default {
  getCustomerMarker,
  getTemplateStopMarker,
  getTemplateStopServiceMarker,
  getTemplateStopServiceInstanceMarker,
  getPreviewStopMarker,
  getPreviewTourStopMarker,
  getStopMarker,
  getTourStopMarker,
  getTemplateStopInstanceTourStopMarker
}
