import React, {Fragment, useState} from 'react'
import {
  Button,
  FormControl,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles
} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Pagination, Portlet, PortletContent, TextPlaceholderWrapper} from '../../../../../../components'
import {Add as AddIcon, DeleteForever as DeleteIcon} from '@material-ui/icons';
import useTablePagination from '../../../../../../hooks/useTablePagination';

function TourIdForShipperTable(props) {

  const [canAdd, setCanAdd] = useState(false);
  const [newTourIdForShipper, setNewTourIdForShipper] = useState({
    tourId: '',
    tourDescription: '',
    shipper: '',
  });

  const {
    classes,
    className,
    tourIdsForShipper,
    t,
    deleteTourIdForShipper,
    createTourIdForShipper,
    selectedShipper
  } = props;

  const createMapping = () => {
    newTourIdForShipper.shipper = selectedShipper;
    newTourIdForShipper.tourId = parseInt(newTourIdForShipper.tourId);
    createTourIdForShipper(newTourIdForShipper);
    setNewTourIdForShipper({
      tourId: '',
      tourDescription: '',
      shipper: '',
    });
    setCanAdd(false);
  };

  const {paginationProps, pagedObjects} = useTablePagination(tourIdsForShipper);

  const rootClassName = classNames(classes.root, className);

  return (
    <TextPlaceholderWrapper
      active={!tourIdsForShipper || !selectedShipper}
      text={t('noTourIdsForShipperFound')}
    >
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell className={classes.alignLeft}>{t('tourIdForShipperId')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('shipper')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('tourId')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('tourDescription')}</TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedObjects.map((tourIdForShipper, index) => (
                <TableRow
                  className={classes.tableRow}
                  hover
                  key={index}
                >
                  <TableCell className={classes.tableCell}>
                    {tourIdForShipper.systemId}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {tourIdForShipper.shipper}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {tourIdForShipper.tourId}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {tourIdForShipper.tourDescription}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Button
                      className={classes.deleteButton}
                      color="inherit"
                      onClick={() => {
                        if (window.confirm(t('confirmDeleteTourIdForShipper'))) deleteTourIdForShipper(tourIdForShipper)
                      }}
                      title={t('deleteTourIdForShipper')}
                    >
                      <DeleteIcon/>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                {canAdd ?
                  <Fragment>
                    <TableCell className={classes.tableCell}>
                      {'-'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {selectedShipper}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <FormControl className={classes.formControl}>
                        <Input
                          error={newTourIdForShipper.tourId === '' || isNaN(newTourIdForShipper.tourId) || parseInt(newTourIdForShipper.tourId) < 0}
                          id="tourId"
                          onChange={(event) => {
                            setNewTourIdForShipper({
                              ...newTourIdForShipper,
                              tourId: event.target.value
                            });
                          }}
                          onClick={event => event.target.select()}
                          onWheel={event => event.target.blur()}
                          type="number"
                          value={newTourIdForShipper.tourId}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <FormControl className={classes.formControl}>
                        <Input
                          id="tourDescription"
                          onChange={(event) => {
                            setNewTourIdForShipper({...newTourIdForShipper, tourDescription: event.target.value})
                          }}
                          value={newTourIdForShipper.tourDescription}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Button
                        color="primary"
                        disabled={!newTourIdForShipper.tourId}
                        onClick={createMapping}
                        style={{padding: 0}}
                        title={t('createTourIdForShipper')}
                      >
                        <AddIcon/>
                      </Button>
                    </TableCell>
                  </Fragment>
                  :
                  <Fragment>
                    <TableCell colSpan={5}>
                      <Button
                        color="primary"
                        onClick={() => setCanAdd(!canAdd)}
                        style={{padding: 0}}
                        title={t('createTourIdForShipper')}
                      >
                        <AddIcon/>
                      </Button>
                    </TableCell>
                  </Fragment>
                }
              </TableRow>
            </TableBody>
          </Table>
          <Pagination paginationProps={paginationProps}/>
        </PortletContent>
      </Portlet>
    </TextPlaceholderWrapper>
  );
}


TourIdForShipperTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  createTourIdForShipper: PropTypes.func.isRequired,
  deleteTourIdForShipper: PropTypes.func.isRequired,
  selectedShipper: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  tourIdsForShipper: PropTypes.array.isRequired,
};

TourIdForShipperTable.defaultProps = {
  boxToShipperMappings: [],
};


export default compose(withStyles(styles), withTranslation())(TourIdForShipperTable);
