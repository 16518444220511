import {compose} from 'recompose';
import {Checkbox, Typography, withStyles} from '@material-ui/core';
import {Place, ShowChart as ConnectionIcon} from '@material-ui/icons';
import {withTranslation} from 'react-i18next';
import styles from './styles'
import classNames from 'classnames';
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {getTemplateStopTypeString, TemplateStopType} from '../../../../services/enums/TemplateStopType';


function TemplateStopLegendInteractive(props) {

  const {
    classes,
    className,
    t,
    legendCheckboxesState,
    setLegendCheckboxesState,
    showNotPlanedStops,
    showConnections,
    showStopType
  } = props;

  const rootClassName = classNames(classes.root, className);


  return (
    <Fragment>
      <Typography
        className={rootClassName}
        variant="body1"
      >
        {showStopType &&
          <Fragment>
            <Checkbox
              checked={legendCheckboxesState.displayDeliveryStops}
              className={classes.checkbox}
              color="secondary"
              onChange={(event) => setLegendCheckboxesState({
                ...legendCheckboxesState,
                displayDeliveryStops: event.target.checked
              })}
              value={legendCheckboxesState.displayDeliveryStops}
            />
            <Place
              className={classNames(classes.materialIcon, classes.iconDelivery)}
            />
            {t(getTemplateStopTypeString(TemplateStopType.Delivery))}&nbsp;

            <Checkbox
              checked={legendCheckboxesState.displayPickupStops}
              className={classes.checkbox}
              color="secondary"
              onChange={(event) => setLegendCheckboxesState({
                ...legendCheckboxesState,
                displayPickupStops: event.target.checked
              })}
              value={legendCheckboxesState.displayPickupStops}
            />
            <Place
              className={classNames(classes.materialIcon, classes.iconPickup)}
            />
            {t(getTemplateStopTypeString(TemplateStopType.Pickup))}&nbsp;
          </Fragment>
        }
        {showConnections &&
          <Fragment>
            <Checkbox
              checked={legendCheckboxesState.displayPickupDeliveryConnections}
              className={classes.checkbox}
              color="secondary"
              onChange={(event) => setLegendCheckboxesState({
                ...legendCheckboxesState,
                displayPickupDeliveryConnections: event.target.checked
              })}
              value={legendCheckboxesState.displayPickupDeliveryConnections}
            />
            <ConnectionIcon
              className={classNames(classes.materialIcon, classes.iconConnections)}
            />
            {t('displayPickupDeliveryConnections')}&nbsp;
          </Fragment>
        }
        {showNotPlanedStops &&
          <Fragment>
            <Checkbox
              checked={legendCheckboxesState.displayNotPlanedStops}
              className={classes.checkbox}
              color="secondary"
              onChange={(event) => setLegendCheckboxesState({
                ...legendCheckboxesState,
                displayNotPlanedStops: event.target.checked
              })}
              value={legendCheckboxesState.displayNotPlanedStops}
            />
            <Place
              className={classNames(classes.materialIcon, classes.iconNotPlanned)}
            />
            {t('notPlanedTemplateStopInstances')}&nbsp;
          </Fragment>
        }
      </Typography>
    </Fragment>
  );
}

TemplateStopLegendInteractive.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  hideStopType: PropTypes.bool,
  legendCheckboxesState: PropTypes.object.isRequired,
  setLegendCheckboxesState: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  showNotPlanedStops: PropTypes.bool,
  showConnections: PropTypes.bool,
  showStopType: PropTypes.bool,
};


export default compose(withStyles(styles), withTranslation())(TemplateStopLegendInteractive);

