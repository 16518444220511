export default theme => ({
  active: {
    color: theme.palette.warning.main,
  },
  completed: {
    color: theme.palette.primary.main,
    opacity: 0.5,
  },
  error: {
    color: theme.palette.error.main,
  },
  errorCompleted: {
    color: theme.palette.error.main,
    opacity: 0.5,
  }
});
