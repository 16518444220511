import React from 'react'
import {Container, Grid, Typography, useMediaQuery, useTheme, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import classNames from 'classnames';

const cancelMailLink = window.location.href.endsWith('unsubscribe') ? window.location.href : `${window.location.href}/unsubscribe`;
const deliveryInfoLink = `${process.env.PUBLIC_URL}/gsl-info`;
const impressumLink = `${process.env.PUBLIC_URL}/impressum`;
const privacyLink = `${process.env.PUBLIC_URL}/privacy`;
const contactLink = 'mailto:info@zukunftsangelegenheiten.de';
const gslLink = 'https://www.xn--grnestadtlogistik-32b.de';

function Footer(props) {

  const {classes, className, shipperInfos} = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const rootClassName = classNames(classes.root, className);

  const link = (href, content) => {
    return (
      <a
        className={classes.a}
        href={href}
      >
        {content}
      </a>
    );
  }

  return (
    <div className={rootClassName}>
      <Container
        disableGutters={matches}
        maxWidth="md"
      >
        <Grid
          className={classes.grid}
          container
          spacing={2}
        >
          <Grid
            className={classes.gridItem}
            item
            md={4}
            xs={12}
          >
            <Typography variant={'h3'}>Zu viele Mails?</Typography>
            <Typography variant={'body1'}>{link(cancelMailLink, 'Hier')} klicken und wir ändern das!</Typography>
          </Grid>
          {shipperInfos &&
          <Grid
            className={classes.gridItem}
            item
            md={4}
            xs={12}
          >
            <Typography variant={'h3'}>Lieferservice</Typography>
            <Typography variant={'body1'}>{shipperInfos.name}</Typography>
            {shipperInfos.contactPhoneNumber && <Typography variant={'body1'}>Telefon: {shipperInfos.contactPhoneNumber}</Typography>}
            {shipperInfos.contactEmail && <Typography variant={'body1'}>{link(`mailto:${shipperInfos.contactEmail}`, shipperInfos.contactEmail)}</Typography>}
          </Grid>
          }
          <Grid
            className={classes.gridItem}
            item
            md={4}
            xs={12}
          >
            <Typography variant={'h3'}>Nachhaltig geliefert</Typography>
            <Typography variant={'body1'}>
              Die Lieferung kommt emissionsfrei auf dem Lastenrad bis vor die Haustür. Mehr dazu {link(deliveryInfoLink, 'hier')}.
            </Typography>
          </Grid>
          <Grid
            className={classes.gridItem}
            item
            md={4}
            xs={12}
          >
            <div className={classes.centerOnMobile}>
              <img
                alt={'Logo Grünestadtlogistik'}
                className={classes.gslLogo}
                src={`${process.env.PUBLIC_URL}/TrackingAssets/GslLogo.png`}
              />
            </div>
          </Grid>
          <Grid
            className={classes.gridItem}
            item
            md={4}
            xs={12}
          >
            <div className={classNames(classes.linkAndCopyRightGridItem, classes.centerOnMobile)}>
              <Typography variant={'body1'}>&copy; Zukunftsangelegenheiten GmbH</Typography>
              <div className={classes.centerOnMobile}>
                <Typography variant={'body1'}>{link(privacyLink, 'Datenschutz')}</Typography>
                <Typography variant={'body1'}>{link(impressumLink, 'Impressum')}</Typography>
                <Typography variant={'body1'}>{link(contactLink, 'Kontakt')}</Typography>
                <Typography variant={'body1'}>{link(gslLink, 'www.grünestadtlogistik.de')}</Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  shipperInfos: PropTypes.shape({name: PropTypes.string.isRequired, contactPhoneNumber: PropTypes.string.isRequired, contactEmail: PropTypes.string.isRequired})
};


export default withStyles(styles)(Footer);
