import React, {Fragment, useState} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {Button, ButtonGroup, LinearProgress, Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import {
  Portlet,
  PortletContent,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  TextPlaceholderWrapper
} from '../../../components';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import DataLoadingWrapper from '../../DataLoadingWrapper';
import {getDayOfWeekFromDate} from '../../../services/enums/dayOfWeekHelper';
import {UtilizationDoughnut} from './Components';
import {StopStatus} from '../../../services/enums/StopStatus';
import {displayModes} from '../../../services/enums/displayModes';
import {roundCapacityValues} from '../../../services/util/helperFuncs';


function MicroHubUtilizationCard(props) {

  const {t, classes, className, error, isLoading, dataMicroHubPlans, stops, filter, displayMode} = props;

  const rootClassName = classNames(classes.root, className);
  const [useCurrentHubState, setUseCurrentHubState] = useState(true);

  let planForDay = [];
  if (dataMicroHubPlans && dataMicroHubPlans.hubPlans) {
    planForDay = dataMicroHubPlans.hubPlans.filter(plan => plan.dayOfWeek === getDayOfWeekFromDate(filter.fromDate));
  }

  let filteredStops = stops;
  if (useCurrentHubState) {
    filteredStops = stops.filter(stop => stop.stopStatus !== StopStatus.Delivered && stop.stopStatus !== StopStatus.InDelivery);
  }

  const capacityStops = filteredStops.reduce((accumulator, stop) => accumulator + (stop.stopCapacities ? stop.stopCapacities.volume : 0), 0);


  return (
    <Portlet
      className={rootClassName}
    >
      <PortletHeader className={classes.portletHeader}>
        <PortletLabel title={t('dashboardMicroHubUtilization')}/>
        <PortletToolbar>
          <ButtonGroup
            color="secondary"
            size={'small'}
          >
            <Button
              onClick={() => setUseCurrentHubState(true)}
              variant={useCurrentHubState ? 'contained' : ''}
            >{t('microHubPlanStorageStateActual')}</Button>
            <Button
              onClick={() => setUseCurrentHubState(false)}
              variant={useCurrentHubState ? '' : 'contained'}
            >{t('microHubPlanStorageStateAll')}</Button>
          </ButtonGroup>
        </PortletToolbar>
      </PortletHeader>
      <PortletContent>
        <TextPlaceholderWrapper
          active={!filter.microHubName || !filter.carrierName}
          text={displayMode === displayModes.carrier ? t('noMicroHubSelectedDashboard') : t('noMicroHubAndCarrierSelectedDashboard')}
        >
          <DataLoadingWrapper
            error={error}
            isLoading={isLoading}
          >
            <TextPlaceholderWrapper
              active={!planForDay?.length || !stops?.length}
              text={t('noMicroHubUtilizationDataDashboard')}
            >
              {planForDay[0] &&
              <Fragment>
                <Typography>
                  <strong>{`${t('hubUtilization')}: ${((capacityStops / planForDay[0].maxVolume) * 100).toFixed(2)}% (${roundCapacityValues(capacityStops)} l / ${roundCapacityValues(planForDay[0].maxVolume)} l)`}</strong>
                </Typography>
                <div className={classes.capacityAllWrapper}>
                  <div className={classes.capacityAllIndicatorWrapper}>
                    <LinearProgress
                      classes={((capacityStops / planForDay[0].maxVolume) * 100) >= 95 ? {barColorPrimary: classes.progressBarRed} : {}}
                      className={classes.capacityAllIndicator}
                      value={Math.min(((capacityStops / planForDay[0].maxVolume) * 100), 100)}
                      variant="determinate"
                    />
                  </div>
                </div>
                <UtilizationDoughnut
                  className={classes.doughnut}
                  stops={filteredStops}
                />
              </Fragment>
              }
            </TextPlaceholderWrapper>
          </DataLoadingWrapper>
        </TextPlaceholderWrapper>
      </PortletContent>
    </Portlet>
  );
}

MicroHubUtilizationCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  dataMicroHubPlans: PropTypes.object,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  error: PropTypes.object,
  filter: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  stops: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(MicroHubUtilizationCard);
