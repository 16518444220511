import React, {Fragment} from 'react';
import {Icon, Typography, withStyles} from '@material-ui/core';
import styles from './style';
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import {LoadingIndicator} from '../index';


function DataLoadingWrapper(props) {

  const {classes, children, isLoading, error} = props;
  if (isLoading) {
    return (
      <LoadingIndicator/>
    );
  }
  if (error) {
    return (
      <div className={classes.errorWrapper}>
        <div>
          <div className={classes.errorIcon}>
            <Icon
              color="error"
              fontSize="large"
            >
              error_outlined
            </Icon>
          </div>
          <Typography>
            {error.message}
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {children}
    </Fragment>
  );
}

DataLoadingWrapper.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  error: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
};

export default compose(withStyles(styles), withTranslation())(DataLoadingWrapper);
