import React, {useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import {onCloseWithBackdropCurry} from '../../../services/util/helperFuncs';
import MicroHubSensorConfigEdit from "../MicroHubSensorConfigEdit";

function CreateMicroHubSensorConfigDialog(props) {

  const [newMicroHubSensorConfig, setNewMicroHubSensorConfig] = useState({
    sensorId: 0,
    microHubName: ''
  });

  const {classes, t, createMicroHubSensorConfigDialogState, microHubNames, handleClose, handleCancel} = props;

  const resetState = () => {
    setNewMicroHubSensorConfig({
      sensorId: 0,
      microHubName: ''
    });
  };

  const handleOk = () => {
    handleClose({...newMicroHubSensorConfig});
    resetState();
  };

  const handleCancelInner = () => {
    handleCancel();
    resetState();
  };

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={createMicroHubSensorConfigDialogState.open}
    >
      <DialogTitle>{t('createMicroHubSensorConfig')}</DialogTitle>
      <DialogContent>
        <div className={classes.container}>
          <MicroHubSensorConfigEdit
            className={classes.container}
            editedMicroHubSensorConfig={newMicroHubSensorConfig}
            setEditedMicroHubSensorConfig={setNewMicroHubSensorConfig}
            microHubNames={microHubNames}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={handleCancelInner}
        >
          <CancelIcon/>&nbsp;{t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          disabled={ 
            newMicroHubSensorConfig.sensorId === 0 || isNaN(newMicroHubSensorConfig.sensorId) 
            || newMicroHubSensorConfig.microHubName === ''
          }
          onClick={handleOk}
        >
          <SaveIcon/>&nbsp;{t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CreateMicroHubSensorConfigDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  createMicroHubSensorConfigDialogState: PropTypes.object.isRequired,
  microHubNames: PropTypes.array.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(CreateMicroHubSensorConfigDialog);
