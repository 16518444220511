export default theme => ({
  root: {
    boxShadow: props => `inset 10px 0px 0px 0px ${props.template.color}`,
    '& > *': {
      borderBottom: 'unset',
    },
  },
  tableCell: {
    whiteSpace: 'nowrap',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  },
  dropTableCell: {
    backgroundColor: (props) => props.template.color
  },
  dragIcon: {
    height: '22px',
    maxHeight: '22px',
    paddingLeft: 0,
    marginRight: theme.spacing(1),
  },
  firstTableCell: {
    alignItems: 'center',
    display: 'flex'
  },
  dragging: {
    backgroundColor: theme.palette.primary.selected
  },
  stopDraggingOver: {
    backgroundColor: 'red'
  },
  disabled: {
    backgroundColor: theme.palette.common.muted
  },
  iconButton: {
    float: 'right',
    padding: 0,
    marginTop: '-5px'
  },
  largeTableCell: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5)
  },
  infoWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  autoCutIcon: {
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1)
  },
  vehicleDetails: {
    marginLeft: theme.spacing(1)
  }
});

