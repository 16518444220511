export default theme => ({
  formControl: {
    marginBottom: theme.spacing(1),
    minWidth: '250px',
  },
  rowWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  infoIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  iconMargin: {
    marginLeft: '8px !important'
  },
  capacityLabel: {
    display: 'flex',
    verticalAlign: 'middle',
  },
  vehicleCapacities: {
    margin: theme.spacing(1),
    marginTop: 0
  }
});
