import React, {Fragment, useState} from 'react'
import {Button, FormControlLabel, Grid, Switch, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Add as AddIcon, Delete as DeleteIcon, PlaylistAdd as AddMultipleIcon} from '@material-ui/icons';
import {PreviewTourMap} from '../../../../../components';
import TourTableSmallPreview from './TourTableSmallPreview';
import TourStopTableSmallPreview from './TourStopTableSmallPreview'
import {DragDropContext} from 'react-beautiful-dnd';
import {DndHelperService} from '../../../../../services/dndHelper/dndHelperService';
import {ShipperAllowedOnHubService} from '../../../../../services/util/shipperAllowedOnHubHelper';
import Checkbox from '@material-ui/core/Checkbox';
import {scrollToElement} from '../../../../../services/util/helperFuncs';
import DeleteAllToursDialog from '../../../../../views/carrier/MapDisposition/components/Planning/DeleteAllToursDialog';


function MapDispositionPreviewPlanning(props) {
  const {classes, className, t, tours, microHubs, createTour, updateTourOrder, changeTourOfStop, selectedTour, setSelectedTour, addStopsToTour, deleteAllTours, deleteStopFromTour, deleteTour, stopsWithoutTour} = props;

  const rootClassName = classNames(classes.root, className);

  const [deleteAllToursDialogState, setDeleteAllToursDialogState] = useState(false);

  const [stopTableCapacityMode, setStopTableCapacityMode] = useState(false);
  const [showOnlySelectedTour, setShowOnlySelectedTour] = useState(false);

  const selectStopOnMap = (stop) => {
    const tourWithStop = tours.filter(tour => tour.stops.map(s => s.id).includes(stop.id))[0];
    scrollToElement(`Tour-${tourWithStop.id}`);
    setSelectedTour(tourWithStop);
  };

  const handleDeleteAllTours = () => {
    setDeleteAllToursDialogState(false);
    deleteAllTours(tours);
  }

  const onDragEnd = (event) => {
    if (!event.destination) {
      return;
    }

    if (event.destination === event.source) {
      return;
    }

    const source = DndHelperService.parseUniqueId(event.source.droppableId);
    const destination = DndHelperService.parseUniqueId(event.destination.droppableId);
    const element = DndHelperService.parseUniqueId(event.draggableId);

    if (source.tourId === destination.tourId) {
      // stop was moved to the ne tour
      if (destination.isTourTable) return;
      // stop was dropped on same tour but order changed ==> change order
      const tourIndex = tours.findIndex(tour => tour.id === source.tourId);
      if (tourIndex < 0) return;
      let reorderedTour = JSON.parse(JSON.stringify(tours[tourIndex]));
      reorderedTour.stops = DndHelperService.reorder(reorderedTour.stops, event.source.index, event.destination.index);
      updateTourOrder(reorderedTour);
    } else {
      // stop was dropped on another tour ==> check if allowed and change tour
      const destTourIndex = tours.findIndex(tour => tour.id === destination.tourId);
      const sourceTourIndex = tours.findIndex(tour => tour.id === source.tourId);
      if (sourceTourIndex < 0 || destTourIndex < 0) return;
      const destTour = tours[destTourIndex];
      const srcTour = tours[sourceTourIndex];
      const stop = tours[sourceTourIndex].stops.filter(stop => stop.id === element.stopId)[0];

      const destHub = microHubs.find(hub => hub.name === destTour.microHubName);

      if (!ShipperAllowedOnHubService.checkShipperAllowedStop(destHub, stop)) {
        alert(t('shipperNotAllowedStop'));
        return;
      }

      if (destTour.stops.some(stopOnDestTour => stopOnDestTour.shipperName !== stop.shipperName)) {
        alert(t('noShipperMixingInPreviewToursService'));
        return;
      }
      if (destTour.microHubName !== stop.assignedMicroHubName) {
        if (!window.confirm(t('confirmChangeMicroHubOfStop'))) return;
      }
      if (srcTour.stops.length <= 1) {
        if (!window.confirm(t('lastStopOnTour'))) return;
      }
      changeTourOfStop(destination.tourId, [element.stopId]);
    }
  };

  return (
    <Fragment>
      <FormControlLabel
        className={classes.displaySelectedTourCheckbox}
        control={
          <Checkbox
            checked={showOnlySelectedTour}
            color="secondary"
            onChange={(event) => setShowOnlySelectedTour(event.target.checked)}
            value={showOnlySelectedTour}
          />
        }
        label={t('showOnlySelectedTour')}
      />
      <Grid
        className={rootClassName}
        container
        spacing={1}
      >
        <Grid
          item
          lg={12}
          sm={12}
          xl={6}
          xs={12}
        >
          <PreviewTourMap
            className={classes.gridItem}
            microHubs={microHubs ? microHubs : []}
            setSelectedStop={selectStopOnMap}
            stopsWithoutTour={stopsWithoutTour}
            tours={showOnlySelectedTour ? selectedTour ? [selectedTour] : [] : tours}
          />
        </Grid>
        <Grid
          item
          lg={12}
          sm={12}
          xl={6}
          xs={12}
        >
          <div
            className={classes.gridItem}
          >
            <DragDropContext
              onDragEnd={onDragEnd}
            >
              <TourTableSmallPreview
                className={classes.tourTable}
                selectedTour={selectedTour}
                selectTour={setSelectedTour}
                tours={tours}
              />
              <Button
                className={classes.button}
                color="secondary"
                onClick={createTour}
                size="small"
                startIcon={<AddIcon/>}
                variant="contained"
              >
                {t('createTour')}
              </Button>
              <Button
                className={classNames(classes.button, classes.redButton)}
                disabled={!tours || !tours.length}
                onClick={() => setDeleteAllToursDialogState(true)}
                size="small"
                variant="contained"
              >
                <DeleteIcon/>&nbsp;{t('deleteAllTours')}
              </Button>
              <br/>
              <br/>
              {selectedTour &&
              <Fragment>
                <FormControlLabel
                  control={
                    <Switch
                      checked={stopTableCapacityMode}
                      color="secondary"
                      name="stopTableCapacityMode"
                      onChange={e => setStopTableCapacityMode(e.target.checked)}
                    />
                  }
                  label={t('capacityMode')}
                />
                <TourStopTableSmallPreview
                  capacityMode={stopTableCapacityMode}
                  className={classes.tourTable}
                  deleteStopFromTour={(stopId) => deleteStopFromTour(selectedTour.id, stopId)}
                  tour={selectedTour}
                />
                <Button
                  className={classes.button}
                  color="primary"
                  disabled={!stopsWithoutTour || stopsWithoutTour.length === 0}
                  onClick={() => addStopsToTour(selectedTour)}
                  size="small"
                  variant="contained"
                >
                  <AddMultipleIcon/>
                </Button>
                <Button
                  className={classNames(classes.button, classes.redButton)}
                  onClick={() => deleteTour(selectedTour.id)}
                  size="small"
                  variant="contained"
                >
                  <DeleteIcon/>&nbsp;{t('deleteTour')}
                </Button>
              </Fragment>
              }
            </DragDropContext>
          </div>
        </Grid>
      </Grid>
      <DeleteAllToursDialog
        deleteAllToursDialogState={deleteAllToursDialogState}
        handleCancel={() => setDeleteAllToursDialogState(false)}
        handleClose={handleDeleteAllTours}
      />
    </Fragment>
  );
}

MapDispositionPreviewPlanning.propTypes = {
  addStopsToTour: PropTypes.func.isRequired,
  changeTourOfStop: PropTypes.func.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  createTour: PropTypes.func.isRequired,
  deleteAllTours: PropTypes.func.isRequired,
  deleteStopFromTour: PropTypes.func.isRequired,
  deleteTour: PropTypes.func.isRequired,
  microHubs: PropTypes.array.isRequired,
  selectedTour: PropTypes.object,
  setSelectedTour: PropTypes.func.isRequired,
  stopsWithoutTour: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  tours: PropTypes.array.isRequired,
  updateTourOrder: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(MapDispositionPreviewPlanning);
