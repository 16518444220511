import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip, Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import {roundToFixed} from '../../services/util/helperFuncs';


function TrendNumber(props) {

  const {t, classes, number, reference, variant, decimalPlaces, inverseTrendMeaning} = props;

  const upTrend = inverseTrendMeaning ? number < reference : number > reference;
  const downTrend = inverseTrendMeaning ? number > reference : number < reference;
  const sideways = number === reference;


  return (
    <Tooltip
      title={`${t('referenceValue')}: ${isNaN(reference) || reference === null ? '-' : roundToFixed(reference, decimalPlaces)}`}
    >
      <Typography
        className={upTrend ? classes.green : downTrend ? classes.red : sideways ? '' : ''}
        variant={variant}
      >
        {isNaN(number) || number === null ? '-' : `${roundToFixed(number, decimalPlaces)} ${upTrend ? (inverseTrendMeaning ? '\u2193' : '\u2191') : ''}${downTrend ? (inverseTrendMeaning ? '\u2191' : '\u2193') : ''}`}
      </Typography>
    </Tooltip>
  );
}

TrendNumber.defaultProps = {
  decimalPlaces: 0,
  inverseTrendMeaning: false,
};

TrendNumber.propTypes = {
  classes: PropTypes.object.isRequired,
  decimalPlaces: PropTypes.number,
  inverseTrendMeaning: PropTypes.bool,
  number: PropTypes.number,
  reference: PropTypes.number,
  t: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TrendNumber);
