import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react'
import {FormControl, Grid, TextField, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {Group, Layer, Rect, Stage, Text, useStrictMode} from 'react-konva';
import {PanZoom} from 'react-easy-panzoom'
import {
  calculateBeverageInsertZoneText,
  calculateBlockerZoneText,
  calculateBoxZoneText,
  DoorOrientations,
  MicroHubElementTypes,
  Offset
} from '../../../services/hubAdministrationHelper/hubAdministrationHelper';
import classNames from 'classnames'
import {
  CreateMicroHubPlanElementDialog,
  MicroHubPlanEditControls,
  MicroHubPlanElementView,
  MicroHubPlanViewControls,
  UpdateMicroHubPlanElementDialog,
  UpdatePlansAtDaysDialog
} from './Components';
import {v4 as uuidv4} from 'uuid';
import {DateService} from '../../../services/util/DateService';
import {roundCapacityValues} from '../../../services/util/helperFuncs';

useStrictMode(true);

function MicroHubAdministrationEdit(props) {

  const {classes, t, microHubData, microHubPlan, updatePlansAtDays, createMicroHubPlan, shipperOptions, className, setDisableFilter, deleteMicroHubPlan, downloadMicroHubPlan, selectedDayOfWeek, shipperFilter, selectedCarrierName, hubCarrierData} = props;
  const rootClassName = classNames(classes.root, className);
  const panZoneRef = useRef(null);
  const stageRef = useRef(null);

  const [editMode, setEditMode] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);
  const [editedMicroHubPlan, setEditedMicroHubPlan] = useState(null);
  const [createMicroHubPlanElementDialogState, setCreateMicroHubPlanElementDialogState] = useState({
    open: false,
    elementType: null,
  });
  const [updateMicroHubPlanElementDialogState, setUpdateMicroHubPlanElementDialogState] = useState({
    open: false,
    elementType: null,
    element: null
  });
  const [updatePlansAtDaysDialogState, setUpdatePlansAtDaysDialogState] = useState({
    open: false,
    preselectedDay: selectedDayOfWeek,
  });
  const [dragAndDropMode, setDragAndDropMode] = useState(false);

  const hubData = editMode ? editedMicroHubPlan : microHubPlan;

  const autoCenter = useCallback(() => {
    if (panZoneRef && panZoneRef.current) {
      panZoneRef.current.autoCenter();
    }
  }, [panZoneRef]);

  useEffect(() => {
    autoCenter();
  }, [microHubPlan, autoCenter]);

  const edit = () => {
    setEditedMicroHubPlan(JSON.parse(JSON.stringify(microHubPlan)));
    setEditMode(true);
    setDisableFilter(true);
  };

  const cancelEdit = () => {
    setEditMode(false);
    setEditedMicroHubPlan(null);
    setDisableFilter(false);
  };

  const save = () => {
    // get all rects on the canvas
    const walls = editedMicroHubPlan.walls.map(wall => {
      return {
        tmpId: wall.tmpId,
        positionX: wall.positionX,
        positionY: wall.positionY,
        width: wall.orientation === DoorOrientations.horizontal ? wall.wallSize : 10,
        depth: wall.orientation === DoorOrientations.vertical ? wall.wallSize : 10,
        type: wall.type,
      }
    });
    const rects = editedMicroHubPlan.boxZones.concat(editedMicroHubPlan.beverageInsertZones).concat(editedMicroHubPlan.blockerZones).concat(walls).map(zone => {
      return {
        topLeft: [zone.positionX, zone.positionY],
        bottomRight: [(zone.positionX + zone.width), (zone.positionY + zone.depth)],
        ...zone
      }
    });
    // check if any of the recs is overlapping with another rect
    for (let i = 0; i < rects.length; i += 1) {
      for (let j = i + 1; j < rects.length; j += 1) {
        if (rectOverlap(rects[i], rects[j])) {
          alert(t('microHubPlanCanNotSave'));
          return;
        }
      }
    }

    openUpdatePlansAtDaysDialog()

  };

  const rectOverlap = (a, b) => {
    const aLeftOfB = a.bottomRight[0] < b.topLeft[0];
    const aRightOfB = a.topLeft[0] > b.bottomRight[0];
    const aAboveB = a.topLeft[1] > b.bottomRight[1];
    const aBelowB = a.bottomRight[1] < b.topLeft[1];

    return !(aLeftOfB || aRightOfB || aAboveB || aBelowB);
  };

  const onDragEndBox = e => {
    let x = Math.round(e.target.x());
    let y = Math.round(e.target.y());
    const tmpId = e.target.name();

    const newBoxZones = hubData.boxZones.slice();
    const boxZone = newBoxZones.find(zone => zone.tmpId === tmpId);

    //check if zone is inside the hub
    if (x + boxZone.width > hubData.hubBase.width + Offset.x || x < Offset.x || y + boxZone.depth > hubData.hubBase.depth + Offset.y || y < Offset.y) {
      x = boxZone.positionX;
      y = boxZone.positionY;
    }

    const index = hubData.boxZones.indexOf(boxZone);
    // update item position
    newBoxZones[index] = {
      ...boxZone,
      positionX: x,
      positionY: y,
    };
    setEditedMicroHubPlan({...hubData, boxZones: newBoxZones})
  };

  const onDragEndBeverageInsert = e => {
    let x = Math.round(e.target.x());
    let y = Math.round(e.target.y());
    const tmpId = e.target.name();

    const newBeverageInsertZones = hubData.beverageInsertZones.slice();
    const beverageInsertZone = newBeverageInsertZones.find(zone => zone.tmpId === tmpId);

    //check if zone is inside the hub
    if (x + beverageInsertZone.width > hubData.hubBase.width + Offset.x || x < Offset.x || y + beverageInsertZone.depth > hubData.hubBase.depth + Offset.y || y < Offset.y) {
      x = beverageInsertZone.positionX;
      y = beverageInsertZone.positionY;
    }

    const index = hubData.beverageInsertZones.indexOf(beverageInsertZone);
    // update item position
    newBeverageInsertZones[index] = {
      ...beverageInsertZone,
      positionX: x,
      positionY: y,
    };
    setEditedMicroHubPlan({...hubData, beverageInsertZones: newBeverageInsertZones})
  };

  const onDragEndDoor = e => {
    let x = Math.round(e.target.x());
    let y = Math.round(e.target.y());

    const newDoor = JSON.parse(JSON.stringify(hubData.hubBase.door));
    // update item position
    newDoor.positionX = x;
    newDoor.positionY = y;
    setEditedMicroHubPlan({...hubData, hubBase: {...hubData.hubBase, door: newDoor}})
  };

  const preventPan = (event) => {
    // if the target is the content container then prevent panning
    if (event.target.tagName === 'CANVAS' && dragAndDropMode) {
      return true
    }
  };

  const onDragEndWall = e => {
    let x = Math.round(e.target.x());
    let y = Math.round(e.target.y());
    const tmpId = e.target.name();

    const newWalls = hubData.walls.slice();
    const wall = newWalls.find(zone => zone.tmpId === tmpId);

    const index = hubData.walls.indexOf(wall);
    // update item position
    newWalls[index] = {
      ...wall,
      positionX: x,
      positionY: y,
    };
    setEditedMicroHubPlan({...hubData, walls: newWalls})
  };

  const onDragEndBlockerZone = e => {
    let x = Math.round(e.target.x());
    let y = Math.round(e.target.y());
    const tmpId = e.target.name();

    const newBlockerZones = hubData.blockerZones.slice();
    const blockerZone = newBlockerZones.find(zone => zone.tmpId === tmpId);

    //check if zone is inside the hub
    if (x + blockerZone.width > hubData.hubBase.width + Offset.x || x < Offset.x || y + blockerZone.depth > hubData.hubBase.depth + Offset.y || y < Offset.y) {
      x = blockerZone.positionX;
      y = blockerZone.positionY;
    }

    const index = hubData.blockerZones.indexOf(blockerZone);
    // update item position
    newBlockerZones[index] = {
      ...blockerZone,
      positionX: x,
      positionY: y,
    };
    setEditedMicroHubPlan({...hubData, blockerZones: newBlockerZones})
  };

  const canDeleteElement = (element) => {
    switch (element.type) {
      case MicroHubElementTypes.hubBase:
        return (hubData.hubBase && !hubData.hubBase.door) && hubData.boxZones.length === 0 && hubData.beverageInsertZones.length === 0;
      case MicroHubElementTypes.door:
        return true;
      case MicroHubElementTypes.boxZone:
        return true;
      case MicroHubElementTypes.beverageInsertZone:
        return true;
      case MicroHubElementTypes.wall:
        return true;
      case MicroHubElementTypes.blockerZone:
        return true;
      default :
        return false;
    }
  };

  const deleteElement = (element) => {
    switch (element.type) {
      case MicroHubElementTypes.hubBase: {
        setEditedMicroHubPlan({...editedMicroHubPlan, hubBase: null});
        setSelectedElement(null);
        break;
      }
      case MicroHubElementTypes.door: {
        setEditedMicroHubPlan({...editedMicroHubPlan, hubBase: {...editedMicroHubPlan.hubBase, door: null}});
        setSelectedElement(null);
        break;
      }
      case MicroHubElementTypes.boxZone: {
        const newBoxZones = editedMicroHubPlan.boxZones.slice();
        const index = newBoxZones.findIndex(zone => zone.tmpId === element.tmpId);
        if (index < 0) return;
        newBoxZones.splice(index, 1);
        setEditedMicroHubPlan({...editedMicroHubPlan, boxZones: newBoxZones});
        setSelectedElement(null);
        break;
      }
      case MicroHubElementTypes.beverageInsertZone: {
        const newBeverageInsertZones = editedMicroHubPlan.beverageInsertZones.slice();
        const index = newBeverageInsertZones.findIndex(zone => zone.tmpId === element.tmpId);
        if (index < 0) return;
        newBeverageInsertZones.splice(index, 1);
        setEditedMicroHubPlan({...editedMicroHubPlan, beverageInsertZones: newBeverageInsertZones});
        setSelectedElement(null);
        break;
      }
      case MicroHubElementTypes.blockerZone: {
        const newBlockZones = editedMicroHubPlan.blockerZones.slice();
        const index = newBlockZones.findIndex(zone => zone.tmpId === element.tmpId);
        if (index < 0) return;
        newBlockZones.splice(index, 1);
        setEditedMicroHubPlan({...editedMicroHubPlan, blockerZones: newBlockZones});
        setSelectedElement(null);
        break;
      }
      case MicroHubElementTypes.wall: {
        const newWalls = editedMicroHubPlan.walls.slice();
        const index = newWalls.findIndex(zone => zone.tmpId === element.tmpId);
        if (index < 0) return;
        newWalls.splice(index, 1);
        setEditedMicroHubPlan({...editedMicroHubPlan, walls: newWalls});
        setSelectedElement(null);
        break;
      }
      default :
        return;
    }
  };

  const createElement = (elementType) => {
    setCreateMicroHubPlanElementDialogState({...createMicroHubPlanElementDialogState, open: true, elementType: elementType});
  };

  const handelCloseCreateElement = (newElement) => {
    switch (newElement.type) {
      case MicroHubElementTypes.hubBase: {
        setEditedMicroHubPlan({...editedMicroHubPlan, hubBase: newElement});
        autoCenter();
        break;
      }
      case MicroHubElementTypes.door: {
        setEditedMicroHubPlan({...editedMicroHubPlan, hubBase: {...editedMicroHubPlan.hubBase, door: newElement}});
        break;
      }
      case MicroHubElementTypes.boxZone: {
        setEditedMicroHubPlan({...editedMicroHubPlan, boxZones: editedMicroHubPlan.boxZones.concat([newElement])});
        break;
      }
      case MicroHubElementTypes.beverageInsertZone: {
        setEditedMicroHubPlan({...editedMicroHubPlan, beverageInsertZones: editedMicroHubPlan.beverageInsertZones.concat([newElement])});
        break;
      }
      case MicroHubElementTypes.wall: {
        setEditedMicroHubPlan({...editedMicroHubPlan, walls: editedMicroHubPlan.walls.concat([newElement])});
        break;
      }
      case MicroHubElementTypes.blockerZone: {
        setEditedMicroHubPlan({...editedMicroHubPlan, blockerZones: editedMicroHubPlan.blockerZones.concat([newElement])});
        break;
      }
      default : //NOP
    }
    setCreateMicroHubPlanElementDialogState({...createMicroHubPlanElementDialogState, open: false, elementType: null});
  };

  const handleCloseUpdateElement = (updatedElement) => {
    switch (updatedElement.type) {
      case MicroHubElementTypes.hubBase: {
        setEditedMicroHubPlan({...editedMicroHubPlan, hubBase: updatedElement});
        autoCenter();
        break;
      }
      case MicroHubElementTypes.door: {
        setEditedMicroHubPlan({...editedMicroHubPlan, hubBase: {...editedMicroHubPlan.hubBase, door: updatedElement}});
        break;
      }
      case MicroHubElementTypes.boxZone: {
        const newBoxZones = editedMicroHubPlan.boxZones.slice();
        const index = newBoxZones.findIndex(zone => zone.tmpId === updatedElement.tmpId);
        if (index >= 0) {
          newBoxZones[index] = updatedElement;
          setEditedMicroHubPlan({...editedMicroHubPlan, boxZones: newBoxZones});
        }
        break;
      }
      case MicroHubElementTypes.beverageInsertZone: {
        const newBeverageInserts = editedMicroHubPlan.beverageInsertZones.slice();
        const index = newBeverageInserts.findIndex(zone => zone.tmpId === updatedElement.tmpId);
        if (index >= 0) {
          newBeverageInserts[index] = updatedElement;
          setEditedMicroHubPlan({...editedMicroHubPlan, beverageInsertZones: newBeverageInserts});
        }
        break;
      }
      case MicroHubElementTypes.blockerZone: {
        const newBlockerZones = editedMicroHubPlan.blockerZones.slice();
        const index = newBlockerZones.findIndex(zone => zone.tmpId === updatedElement.tmpId);
        if (index >= 0) {
          newBlockerZones[index] = updatedElement;
          setEditedMicroHubPlan({...editedMicroHubPlan, blockerZones: newBlockerZones});
        }
        break;
      }
      case MicroHubElementTypes.wall: {
        const newWalls = editedMicroHubPlan.walls.slice();
        const index = newWalls.findIndex(zone => zone.tmpId === updatedElement.tmpId);
        if (index >= 0) {
          newWalls[index] = updatedElement;
          setEditedMicroHubPlan({...editedMicroHubPlan, walls: newWalls});
        }
        break;
      }
      default : //NOP
    }
    setUpdateMicroHubPlanElementDialogState({...updateMicroHubPlanElementDialogState, open: false, elementType: null, element: null});
    setSelectedElement(updatedElement);
  };

  const downLoadPlan = () => {
    downloadMicroHubPlan(stageRef.current.toDataURL())
  };

  const openUpdatePlansAtDaysDialog = () => {
    setUpdatePlansAtDaysDialogState({...updatePlansAtDaysDialogState, open: true, preselectedDay: selectedDayOfWeek})
  };

  const closeUpdatePlansAtDaysDialog = () => {
    setUpdatePlansAtDaysDialogState({...updatePlansAtDaysDialogState, open: false, preselectedDay: selectedDayOfWeek})
  };

  const handleCloseUpdatePlansAtDaysDialog = (days) => {
    if (editMode) {
      updatePlansAtDays(editedMicroHubPlan, days);
      setEditMode(false);
      setEditedMicroHubPlan(null);
      setDisableFilter(false);
      setDragAndDropMode(false);
    } else {
      updatePlansAtDays(hubData, days);
    }
    closeUpdatePlansAtDaysDialog()
  };

  const deletePlan = () => {
    if (window.confirm(t('microHubPlanDeleteConfirm'))) {
      setSelectedElement(null);
      deleteMicroHubPlan()
    }
  };

  const updateSelectedElement = () => {
    setUpdateMicroHubPlanElementDialogState({
      ...updateMicroHubPlanElementDialogState,
      open: true,
      elementType: selectedElement.type,
      element: selectedElement
    })
  };

  const duplicateSelectedElement = () => {
    const newElement = JSON.parse(JSON.stringify(selectedElement));
    newElement.tmpId = uuidv4();
    newElement.positionX = Offset.x;
    newElement.positionY = Offset.y;
    switch (newElement.type) {
      case MicroHubElementTypes.hubBase: {
        return; //NOP
      }
      case MicroHubElementTypes.door: {
        return; //NOP
      }
      case MicroHubElementTypes.boxZone: {
        newElement.shipperInfos.forEach(shipperInfo => {
          const ordersOfShipper = editedMicroHubPlan.boxZones
            .filter(zone => zone.shipperInfos.map(info => info.name).includes(shipperInfo.name))
            .map(zone => zone.shipperInfos.filter(info => info.name === shipperInfo.name)[0].order);
          shipperInfo.order = Math.max(...ordersOfShipper) + 1;
        });
        break;
      }
      case MicroHubElementTypes.beverageInsertZone: {
        newElement.order = Math.max(...editedMicroHubPlan.beverageInsertZones.map(zone => zone.order)) + 1;
        break;
      }
      case MicroHubElementTypes.wall: {
        newElement.positionX += Offset.x;
        newElement.positionY += Offset.y;
        break;
      }
      case MicroHubElementTypes.blockerZone: {
        //NOP
        break;
      }
      default : //NOP
    }
    handelCloseCreateElement(newElement);
  };

  return (
    <Grid
      className={rootClassName}
      container
      spacing={1}
    >
      <Grid
        item
        lg={9}
        sm={9}
        xl={9}
        xs={9}
      >
        <PanZoom
          autoCenter
          disableKeyInteraction
          enableBoundingBox
          preventPan={preventPan}
          ref={panZoneRef}
          style={{height: '100vh', overflow: 'hidden'}}
        >
          {
            hubData && hubData.hubBase &&
            <Stage
              height={hubData.hubBase.depth + Offset.x * 2}
              id={'stage'}
              ref={stageRef}
              width={hubData.hubBase.width + Offset.y * 2}
            >
              <Layer>
                {
                  hubData.hubBase &&
                  <Fragment>
                    <Rect
                      fill={'white'}
                      height={hubData.hubBase.depth}
                      name={'hubBase'}
                      onClick={() => setSelectedElement(hubData.hubBase)}
                      stroke={'black'}
                      strokeWidth={10}
                      width={hubData.hubBase.width}
                      x={Offset.x}
                      y={Offset.y}
                    />
                    {
                      hubData.hubBase.door &&
                      <Rect
                        draggable={editMode && dragAndDropMode}
                        height={hubData.hubBase.door.orientation === DoorOrientations.vertical ? hubData.hubBase.door.doorSize : 10}
                        name={'hubDoor'}
                        onClick={() => setSelectedElement(hubData.hubBase.door)}
                        onDragEnd={onDragEndDoor}
                        stroke={'red'}
                        strokeWidth={10}
                        width={hubData.hubBase.door.orientation === DoorOrientations.horizontal ? hubData.hubBase.door.doorSize : 10}
                        x={hubData.hubBase.door.positionX}
                        y={hubData.hubBase.door.positionY}
                      />
                    }
                  </Fragment>
                }
              </Layer>
              <Layer>
                {
                  hubData.beverageInsertZones && hubData.beverageInsertZones.map((zone, index) => {
                    //center text and calculate how much of the text and how big can be rendered
                    const [text, heightOfWrittenText, fontSize] = calculateBeverageInsertZoneText(zone.width, zone.depth, zone.order, t);
                    return (
                      <Group
                        draggable={editMode && dragAndDropMode}
                        height={zone.depth}
                        key={index}
                        name={zone.tmpId}
                        onClick={() => setSelectedElement(zone)}
                        onDragEnd={onDragEndBeverageInsert}
                        width={zone.width}
                        x={zone.positionX}
                        y={zone.positionY}
                      >
                        <Rect
                          fill="black"
                          height={zone.depth}
                          width={zone.width}
                        />
                        <Text
                          align={'center'}
                          fill={'white'}
                          fontSize={fontSize}
                          text={text}
                          width={zone.width}
                          y={(zone.depth - heightOfWrittenText) / 2}
                        />
                      </Group>
                    )
                  })
                }
              </Layer>
              <Layer>
                {
                  hubData.boxZones && hubData.boxZones.map((zone, index) => {
                    //center text and calculate how much of the text can be rendered
                    const [text, heightOfWrittenText, fontSize] = calculateBoxZoneText(zone.width, zone.depth, zone.shipperInfos.filter(info => !shipperFilter || shipperFilter === info.name));
                    return (
                      <Group
                        draggable={editMode && dragAndDropMode}
                        key={index}
                        name={zone.tmpId}
                        onClick={() => setSelectedElement(zone)}
                        onDragEnd={onDragEndBox}
                        x={zone.positionX}
                        y={zone.positionY}
                      >
                        <Rect
                          fill={zone.shipperInfos.some(info => !shipperFilter || info.name === shipperFilter) ? 'black' : 'lightGrey'}
                          height={zone.depth}
                          width={zone.width}
                        />
                        <Text
                          align={'center'}
                          fill={'white'}
                          fontSize={fontSize}
                          text={text}
                          width={zone.width}
                          y={(zone.depth - heightOfWrittenText) / 2}
                        />
                      </Group>
                    )
                  })
                }
              </Layer>
              <Layer>
                {
                  hubData.blockerZones && hubData.blockerZones.map((zone, index) => {
                    //center text and calculate how much of the text can be rendered
                    const [text, heightOfWrittenText, fontSize] = calculateBlockerZoneText(zone.width, zone.depth, zone.text);
                    return (
                      <Group
                        draggable={editMode && dragAndDropMode}
                        key={index}
                        name={zone.tmpId}
                        onClick={() => setSelectedElement(zone)}
                        onDragEnd={onDragEndBlockerZone}
                        x={zone.positionX}
                        y={zone.positionY}
                      >
                        <Rect
                          fill={'grey'}
                          height={zone.depth}
                          width={zone.width}
                        />
                        <Text
                          align={'center'}
                          fill={'white'}
                          fontSize={fontSize}
                          text={text}
                          width={zone.width}
                          y={(zone.depth - heightOfWrittenText) / 2}
                        />
                      </Group>
                    )
                  })
                }
              </Layer>
              <Layer>
                {
                  hubData.walls && hubData.walls.map((wall, index) => {
                    return (
                      <Rect
                        draggable={editMode && dragAndDropMode}
                        height={wall.orientation === DoorOrientations.vertical ? wall.wallSize : 10}
                        key={index}
                        name={wall.tmpId}
                        onClick={() => setSelectedElement(wall)}
                        onDragEnd={onDragEndWall}
                        stroke={'black'}
                        strokeWidth={10}
                        width={wall.orientation === DoorOrientations.horizontal ? wall.wallSize : 10}
                        x={wall.positionX}
                        y={wall.positionY}
                      />
                    );
                  })
                }
              </Layer>
            </Stage>
          }
        </PanZoom>
      </Grid>
      <Grid
        className={classes.controlGrid}
        item
        lg={3}
        sm={3}
        xl={3}
        xs={3}
      >
        {
          editMode ?
            <Fragment>
              <MicroHubPlanEditControls
                autoCenter={autoCenter}
                cancelEdit={cancelEdit}
                className={classes.controlElement}
                createElement={createElement}
                dragAndDropMode={dragAndDropMode}
                hubData={hubData}
                save={save}
                selectedElement={selectedElement}
                setDragAndDropMode={setDragAndDropMode}
              />
              <CreateMicroHubPlanElementDialog
                createMicroHubPlanElementDialogState={createMicroHubPlanElementDialogState}
                handleCancel={() => setCreateMicroHubPlanElementDialogState({...createMicroHubPlanElementDialogState, open: false, elementType: null})}
                handleClose={handelCloseCreateElement}
                hubCarrierData={hubCarrierData}
                hubData={hubData}
                selectedCarrierName={selectedCarrierName}
                selectedDayOfWeek={selectedDayOfWeek}
                shipperOptions={shipperOptions}
              />
              <UpdateMicroHubPlanElementDialog
                handleCancel={() => setUpdateMicroHubPlanElementDialogState({
                  ...updateMicroHubPlanElementDialogState,
                  open: false,
                  elementType: null,
                  element: null
                })}
                handleClose={handleCloseUpdateElement}
                hubData={hubData}
                shipperOptions={shipperOptions}
                updateMicroHubPlanElementDialogState={updateMicroHubPlanElementDialogState}
              />
            </Fragment>
            :
            <Fragment>
              <MicroHubPlanViewControls
                autoCenter={autoCenter}
                className={classes.controlElement}
                copyPlanToDays={openUpdatePlansAtDaysDialog}
                createMicroHubPlan={createMicroHubPlan}
                deleteMicroHubPlan={deletePlan}
                downLoadPlan={downLoadPlan}
                edit={edit}
                noHubPlanAvailable={!hubData}
              />
            </Fragment>
        }
        <UpdatePlansAtDaysDialog
          allMicroHubData={microHubData}
          editedMicroHubPlan={hubData}
          handleCancel={closeUpdatePlansAtDaysDialog}
          handleClose={handleCloseUpdatePlansAtDaysDialog}
          hubCarrierData={hubCarrierData}
          selectedCarrierName={selectedCarrierName}
          updatePlansAtDaysDialogState={updatePlansAtDaysDialogState}
        />
        <hr/>
        {
          selectedElement &&
          <MicroHubPlanElementView
            canDeleteElement={canDeleteElement(selectedElement)}
            className={classes.controlElement}
            deleteElement={deleteElement}
            duplicateSelectedElement={duplicateSelectedElement}
            readonly={!editMode}
            selectedElement={selectedElement}
            updateSelectedElement={updateSelectedElement}
          />
        }
        <hr/>
        <Typography variant={'body1'}>
          <strong>{t('microHubPlanMaxVolume')}</strong>: {hubData && hubData.maxVolume > 0 ? roundCapacityValues(hubData.maxVolume) : '-'}
        </Typography>
        <Typography variant={'body1'}>
          <strong>{t('microHubPlanLastModified')}</strong>: {hubData && hubData.lastModified ? DateService.displayDateTime(new Date(hubData.lastModified)) : '-'}
        </Typography>
        <Typography variant={'body1'}>
          <strong>{t('microHubPlanModifiedBy')}</strong>: {hubData && hubData.modifiedBy ? hubData.modifiedBy : '-'}
        </Typography>
        {editMode ?
          <FormControl className={classes.descriptionForm}>
            <TextField
              label={t('microHubPlanDescription')}
              multiline
              onChange={event => setEditedMicroHubPlan({...editedMicroHubPlan, description: event.target.value})}
              value={editedMicroHubPlan.description ? editedMicroHubPlan.description : ''}
              variant="outlined"
            />
          </FormControl>
          :
          <Fragment>
            <Typography variant={'body1'}>
              <strong>{t('microHubPlanDescription')}</strong>:
            </Typography>
            <Typography className={classes.hubPlanDescription}>
              {hubData && hubData.description ? hubData.description : '-'}
            </Typography>
          </Fragment>
        }
      </Grid>
    </Grid>
  );
}

MicroHubAdministrationEdit.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  createMicroHubPlan: PropTypes.func.isRequired,
  deleteMicroHubPlan: PropTypes.func.isRequired,
  downloadMicroHubPlan: PropTypes.func.isRequired,
  hubCarrierData: PropTypes.object.isRequired,
  microHubData: PropTypes.array.isRequired,
  microHubPlan: PropTypes.object,
  selectedCarrierName: PropTypes.string.isRequired,
  selectedDayOfWeek: PropTypes.string.isRequired,
  setDisableFilter: PropTypes.func.isRequired,
  shipperFilter: PropTypes.string.isRequired,
  shipperOptions: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  updatePlansAtDays: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(MicroHubAdministrationEdit);
