import React from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {dayOfWeekSorterFunction} from '../../../../../../services/enums/dayOfWeekHelper';

function OperatingTimeShow(props) {

  const {classes, className, weekDayShipperLists, t} = props;

  const rootClassName = classNames(classes.root, className);  

  return (
    <div className={rootClassName}>
      <div><strong>{t('operatingTime')}</strong>:</div>
      {weekDayShipperLists.sort((a, b) => dayOfWeekSorterFunction(a.weeekDay, b.weekDay)).map((list, index) =>
        <div
          className={classes.vehicleDetail}
          key={index}
        ><strong>{t(list.weekDay.toLowerCase())}</strong>: {list.microHubIsOpen ? t('yes') : t('no')}</div>
      )}
    </div>
  );
}


OperatingTimeShow.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  weekDayShipperLists: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(OperatingTimeShow);
