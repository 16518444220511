import React from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import Hub from '../Hub';


function HubList(props) {

  const {classes, hubListData, className} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      {hubListData.map(hub => {
        return (
          <Hub
            className={classes.content}
            hub={hub}
            key={hub.microHubName}
          />
        )
      })
      }
    </div>
  );
}


HubList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  hubListData: PropTypes.array.isRequired,
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(HubList);
