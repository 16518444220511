import React from 'react';
import PropTypes from 'prop-types';
import {MarkerCreateFuncs, OverlappingMarkerCreateFuncs} from '../../MapComponents';
import BaseTourMap from '../../BaseMaps/BaseTourMap';
import {filterTemplatesForCoords, getPolyLinesFromTemplates} from '../../../../services/util/mapUtils';
import {customerIconColor, IconService} from '../../../../services/icon/iconService';

const TourTemplateMap = React.memo(props => {

  const {className, templateTours, microHubs, customers, displayCustomers, displayMicroHubs, onSelectStop, onSelectCustomer, displayControls} = props;

  return (
    <BaseTourMap
      className={className}
      displayControls={displayControls}
      displayMicroHubs={displayMicroHubs}
      displayStops={displayCustomers}
      displayToursInFront
      filterToursForCoords={filterTemplatesForCoords}
      getOverLappingMarkerClusterIcon={(overlappingElements, highLiteDuplicates) => OverlappingMarkerCreateFuncs.getForTemplateMap(overlappingElements, highLiteDuplicates)}
      getPolyLines={getPolyLinesFromTemplates}
      getStopClusterIcon={() => IconService.getMarkerClusterIcon(customerIconColor)}
      getStopMarkerAndAddToMcg={(customer, mcg, t) => MarkerCreateFuncs.getCustomerMarker(customer, mcg, t, onSelectCustomer)}
      getTourClusterIcon={(tour) => IconService.getMarkerClusterIcon(tour.color, tour.id)}
      getTourStopMarkerAndAddToMcg={(template, stop, mcg, t) => MarkerCreateFuncs.getTemplateStopMarker(template, stop, mcg, t, onSelectStop)}
      microHubs={displayMicroHubs ? microHubs : []}
      stops={customers}
      tours={templateTours}
    />
  );
});

TourTemplateMap.displayName = 'TourTemplateMap';

TourTemplateMap.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired,
  displayControls: PropTypes.bool,
  displayCustomers: PropTypes.bool,
  displayMicroHubs: PropTypes.bool,
  microHubs: PropTypes.array.isRequired,
  onSelectCustomer: PropTypes.func,
  onSelectStop: PropTypes.func,
  templateTours: PropTypes.array.isRequired,
};

export default TourTemplateMap;

