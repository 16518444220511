export default theme => ({
  root: {},
  tabs: {
    marginBottom: '4px'
  },
  tabsWithoutData: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  tabWithData: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  }
});
