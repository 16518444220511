import React from 'react'
import {Button} from '@material-ui/core';
import {DeleteForever as DeleteIcon,} from '@material-ui/icons';
import {getBoxTypeKindString} from '../../../services/enums/BoxTypeKinds';

const Columns = {
  boxTypeIdentifier: {
    name: 'boxTypeIdentifier',
    header: ({t}) => t('boxTypeIdentifier'),
    body: ({boxType}) => boxType.identifier
  },
  boxTypeKind: {
    name: 'boxTypeKind',
    header: ({t}) => t('boxTypeKind'),
    body: ({boxType, t}) => t(getBoxTypeKindString(boxType.kind))
  },
  boxTypeVolume: {
    name: 'boxTypeVolume',
    header: ({t}) => t('boxTypeVolume'),
    body: ({boxType}) => boxType.volume
  },
  boxTypeStackable: {
    name: 'boxTypeStackable',
    header: ({t}) => t('boxTypeStackable'),
    body: ({boxType, t}) => boxType.stackable ? t('yes') : t('no')
  },
  boxTypeDimensionsUnit: {
    name: 'boxTypeDimensionsUnit',
    header: ({t}) => t('boxTypeDimensionsUnit'),
    body: ({boxType}) => `${boxType.depth} x ${boxType.width} x ${boxType.height}`
  },
  deleteBoxType: {
    name: 'deleteBoxType',
    header: ({t}) => t('deleteBoxType'),
    body: ({boxType, t, classes, deleteBoxType}) => (
      <Button
        className={classes.deleteColor}
        color="primary"
        onClick={(event) => {
          if (window.confirm(t('confirmDeleteBoxType'))) deleteBoxType(boxType, event)
        }}
        style={{padding: 0}}
        title={t('deleteBoxType')}
      >
        <DeleteIcon/>
      </Button>
    )
  },
}

export default Columns;