import React, {Fragment, useState} from 'react'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  withStyles
} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {PlayCircleFilledOutlined as StartIcon} from '@material-ui/icons';
import {TourService} from '../../../../services/backend/tourService';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {displayModes} from '../../../../services/enums/displayModes';
import {InfoTooltip, LoadingIndicator} from '../../../../components';


function RunSimulation(props) {

  const {classes, t, displayMode, setActiveStep, stops, unassignedStops, setUnassignedStops, tours, setTours} = props;

  const [runDate, setRunDate] = useState(new Date());
  const [doNotMixShippersOnTour, setDoNotMixShippersOnTour] = useState(true);
  const [useAlgorithm, setUseAlgorithm] = useState('null');
  const [useTourTemplates, setUseTourTemplates] = useState(true);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [showAlgoError, setShowAlgoError] = useState(false);


  const startSimulation = () => {
    setShowLoadingIndicator(true);
    TourService.startSimulation(stops, new Date(Date.UTC(runDate.getFullYear(), runDate.getMonth(), runDate.getDate())), {
      doNotMixShippersOnTours: doNotMixShippersOnTour,
      useAlgorithm: useAlgorithm === 'null' ? null : useAlgorithm,
      useTourTemplates: useTourTemplates
    }).then(response => response.json()).then(response => {
      setTours(response.tours);
      setUnassignedStops(response.unassignedStops);
      setShowLoadingIndicator(false);
      setShowAlgoError(false);
    }, () => {
      setShowLoadingIndicator(false);
      setShowAlgoError(true);
      setTours([]);
      setUnassignedStops([]);
    });
  };

  if (showLoadingIndicator) {
    return (
      <div className={classes.contentWithoutBorder}>
        <LoadingIndicator/>
      </div>
    );
  }

  return (
    <Fragment>
      <div className={classes.contentWithoutBorder}>
        {((tours && tours.length > 0) || (unassignedStops && unassignedStops.length > 0)) &&
        <div className={classes.infoDiv}>
          <Typography
            color={'primary'}
            variant={'h5'}
          >
            {t('algorithmRunSuccessfully')}
          </Typography>
          <Typography variant={'body1'}>
            {`${t('buildTours')}: ${tours ? tours.length : 0}`}
          </Typography>
          <Typography variant={'body1'}>
            {`${t('unassignedStops')}: ${unassignedStops ? unassignedStops.length : 0}`}
          </Typography>
        </div>
        }
        {showAlgoError &&
        <div className={classes.infoDiv}>
          <Typography
            color={'error'}
            variant={'h5'}
          >
            {t('algorithmRunFailed')}
          </Typography>
          <Typography variant={'body1'}>
            {t('algorithmRunFailedInfo1')}
          </Typography>
          <Typography variant={'body1'}>
            {t('algorithmRunFailedInfo2')}
          </Typography>
          <Typography variant={'body1'}>
            {t('algorithmRunFailedInfo3')}
          </Typography>
        </div>
        }
        {displayMode !== displayModes.shipper &&
        <FormGroup row>
          <InfoTooltip descriptionKey={'40'}>
            <FormControl className={classes.formControlSelect}>
              <InputLabel>{t('useAlgorithm')}</InputLabel>
              <Select
                onChange={(event) => setUseAlgorithm(event.target.value)}
                value={useAlgorithm}
              >
                <MenuItem
                  value={'null'}
                >
                  {t('default')}
                </MenuItem>
                <MenuItem
                  value
                >
                  {t('yes')}
                </MenuItem>
                <MenuItem
                  value={false}
                >
                  {t('no')}
                </MenuItem>
                })}
              </Select>
            </FormControl>
          </InfoTooltip>
          <InfoTooltip descriptionKey={'39'}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={useTourTemplates}
                  color="secondary"
                  onChange={(event) => setUseTourTemplates(event.target.checked)}
                  value={useTourTemplates}
                />
              }
              label={t('useTourTemplates')}
            />
          </InfoTooltip>
          <InfoTooltip descriptionKey={'38'}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={doNotMixShippersOnTour}
                  color="secondary"
                  onChange={(event) => setDoNotMixShippersOnTour(event.target.checked)}
                  value={doNotMixShippersOnTour}
                />
              }
              label={t('doNotMixShippersOnTour')}
            />
          </InfoTooltip>
        </FormGroup>
        }
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <InfoTooltip descriptionKey={'7'}>
            <FormControl>
              <KeyboardDatePicker
                cancelLabel={t('dialogCancel')}
                className={classes.picker}
                format="dd.MM.yyyy"
                id="DatePicker"
                margin="normal"
                onChange={(date) => setRunDate(date)}
                value={runDate}
              />
            </FormControl>
          </InfoTooltip>
        </MuiPickersUtilsProvider>
        <br/>
        <Button
          className={classes.startButton}
          color="primary"
          onClick={startSimulation}
          variant="contained"
        >
          <StartIcon/>&nbsp;{t('startSimulationNow')}
        </Button>
      </div>
      <div>
        <Button
          className={classes.navButton}
          color="default"
          onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
        >
          {t('back')}
        </Button>
        <Button
          className={classes.navButton}
          color="primary"
          disabled={(!tours || tours.length === 0) && !unassignedStops.length > 0}
          onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
          variant="contained"
        >
          {t('next')}
        </Button>
      </div>
    </Fragment>
  );
}

RunSimulation.propTypes = {
  classes: PropTypes.object.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  setActiveStep: PropTypes.func.isRequired,
  setTours: PropTypes.func.isRequired,
  setUnassignedStops: PropTypes.func.isRequired,
  stops: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  tours: PropTypes.array.isRequired,
  unassignedStops: PropTypes.array.isRequired,
};


export default compose(withStyles(styles), withTranslation())(RunSimulation);
