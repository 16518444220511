import React, {Fragment, useState} from 'react'
import {
  Button,
  FormControl,
  Input,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles
} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Pagination, Portlet, PortletContent, TextPlaceholderWrapper} from '../../../../../../components'
import {Add as AddIcon, DeleteForever as DeleteIcon} from '@material-ui/icons';
import useTablePagination from '../../../../../../hooks/useTablePagination';

function BoxToShipperMappingTable(props) {

  const [canAdd, setCanAdd] = useState(false);
  const [newBoxToShipperMapping, setNewBoxToShipperMapping] = useState({
    boxIdentifierShipper: '',
    boxIdentifierZA: '',
    shipperName: '',
  });

  const {
    classes,
    className,
    boxToShipperMappings,
    t,
    deleteBoxToShipperMapping,
    createBoxToShipperMapping,
    selectedShipper,
    boxOptions
  } = props;

  const {paginationProps, pagedObjects} = useTablePagination(boxToShipperMappings);

  const createMapping = () => {
    newBoxToShipperMapping.shipperName = selectedShipper;
    createBoxToShipperMapping(newBoxToShipperMapping);
    setNewBoxToShipperMapping({
      boxIdentifierShipper: '',
      boxIdentifierZA: '',
      shipperName: '',
    });
    setCanAdd(false);
  };

  const rootClassName = classNames(classes.root, className);

  return (
    <TextPlaceholderWrapper
      active={!boxToShipperMappings || !selectedShipper}
      text={t('noBoxToShipperMappingsFound')}
    >
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell className={classes.alignLeft}>{t('boxToShipperMappingId')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('shipper')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('boxIdentifierZA')}</TableCell>
                <TableCell className={classes.alignLeft}>{t('boxIdentifierShipper')}</TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedObjects.map((mapping, index) => (
                <TableRow
                  className={classes.tableRow}
                  hover
                  key={index}
                >
                  <TableCell className={classes.tableCell}>
                    {mapping.systemID}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {mapping.shipperName}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {mapping.boxIdentifierZA}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {mapping.boxIdentifierShipper}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Button
                      className={classes.deleteButton}
                      color="inherit"
                      onClick={() => {
                        if (window.confirm(t('confirmDeleteBoxToShipperMapping'))) deleteBoxToShipperMapping(mapping)
                      }}
                      title={t('deleteBoxToShipperMapping')}
                    >
                      <DeleteIcon/>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                {canAdd ?
                  <Fragment>
                    <TableCell className={classes.tableCell}>
                      {'-'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {selectedShipper}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <FormControl className={classes.formControl}>
                        <Select
                          input={<Input id="box"/>}
                          onChange={(event) => setNewBoxToShipperMapping({
                            ...newBoxToShipperMapping,
                            boxIdentifierZA: event.target.value
                          })}
                          value={newBoxToShipperMapping.boxIdentifierZA}
                        >
                          {boxOptions && boxOptions.map((box, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={box}
                              >
                                {box}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <FormControl className={classes.formControl}>
                        <Input
                          error={!newBoxToShipperMapping.boxIdentifierShipper}
                          id="boxIdentifierShipper"
                          onChange={(event) => {
                            setNewBoxToShipperMapping({
                              ...newBoxToShipperMapping,
                              boxIdentifierShipper: event.target.value
                            })
                          }}
                          value={newBoxToShipperMapping.boxIdentifierShipper}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Button
                        color="primary"
                        disabled={!newBoxToShipperMapping.boxIdentifierZA || !newBoxToShipperMapping.boxIdentifierShipper}
                        onClick={createMapping}
                        style={{padding: 0}}
                        title={t('createBoxToShipperMapping')}
                      >
                        <AddIcon/>
                      </Button>
                    </TableCell>
                  </Fragment>
                  :
                  <Fragment>
                    <TableCell colSpan={5}>
                      <Button
                        color="primary"
                        onClick={() => setCanAdd(!canAdd)}
                        style={{padding: 0}}
                        title={t('createBoxToShipperMapping')}
                      >
                        <AddIcon/>
                      </Button>
                    </TableCell>
                  </Fragment>
                }
              </TableRow>
            </TableBody>
          </Table>
          <Pagination paginationProps={paginationProps}/>
        </PortletContent>
      </Portlet>
    </TextPlaceholderWrapper>
  );
}


BoxToShipperMappingTable.propTypes = {
  boxOptions: PropTypes.array.isRequired,
  boxToShipperMappings: PropTypes.array.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  createBoxToShipperMapping: PropTypes.func.isRequired,
  deleteBoxToShipperMapping: PropTypes.func,
  selectedShipper: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

BoxToShipperMappingTable.defaultProps = {
  boxToShipperMappings: [],
};


export default compose(withStyles(styles), withTranslation())(BoxToShipperMappingTable);
