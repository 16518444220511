import React, {useEffect, useState} from 'react'
import {Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, Grid, Tooltip, Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {AddStopsToPreviewTourMap} from '../../../../../../components';
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import {ShipperAllowedOnHubService} from '../../../../../../services/util/shipperAllowedOnHubHelper';
import {CapacityHelperService} from '../../../../../../services/util/capacityHelper';
import StopAddTable from './StopAddTable';
import {onCloseWithBackdropCurry} from '../../../../../../services/util/helperFuncs';

function AddStopsWithoutTourDialog(props) {

  const {classes, t, dialogState, handleCancel, handleClose, stopsWithoutTour, microHubs, tours} = props;
  const [stopsToAdd, setStopsToAdd] = useState([]);
  const [selectedTourInternal, setSelectedTourInternal] = useState(null);
  const [displayAllTours, setDisplayAllTours] = useState(false);
  const [autoOrderOnAdd, setAutoOrderOnAdd] = useState(false);

  useEffect(() => {
    if (dialogState.open && dialogState.tour) {
      setSelectedTourInternal(JSON.parse(JSON.stringify(dialogState.tour)));
    }
  }, [dialogState]);


  const addOrRemoveStop = (stop) => {
    if (stopsWithoutTour.findIndex(s => s.id === stop.id) >= 0) {
      const newStopsToAdd = JSON.parse(JSON.stringify(stopsToAdd));
      const index = newStopsToAdd.findIndex(presentStop => presentStop.id === stop.id);
      if (index >= 0) {
        newStopsToAdd.splice(index, 1);
      } else {
        if (dialogState.tour.stops.some(s => s.shipperName !== stop.shipperName)) {
          alert(t('noShipperMixingInPreviewToursService'));
          return;
        }
        if (dialogState.tour.microHubName !== stop.assignedMicroHubName && ShipperAllowedOnHubService.checkShipperAllowedStop(microHubs.find(hub => hub.name === dialogState.tour.microHubName), stop)) {
          if (!window.confirm(t('confirmChangeMicroHubOfStop'))) return;
        }
        newStopsToAdd.push(stop);
      }
      newStopsToAdd.forEach(stop => stop.stopNumber = 0);
      setStopsToAdd(newStopsToAdd);
      const newStops = dialogState.tour.stops.concat(newStopsToAdd);
      const newStoragePlaces = [...new Set(newStops.map(stop => stop.storagePlaces).flat())];
      setSelectedTourInternal({...selectedTourInternal, stops: newStops, storagePlaces: newStoragePlaces});
    }
  };

  const handleOk = () => {
    handleClose(stopsToAdd.map(stop => stop.id), autoOrderOnAdd);
    setStopsToAdd([]);
    setSelectedTourInternal(null);
  };

  const handleNotOk = () => {
    handleCancel();
    setStopsToAdd([]);
    setSelectedTourInternal(null);
  };

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      fullWidth
      maxWidth={'xl'}
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={dialogState.open}
    >
      <div
        className={classes.headerContainer}
        style={selectedTourInternal ? {backgroundColor: selectedTourInternal.color} : {}}
      >
        <Typography><strong>{`${t('stopAdd')} ${selectedTourInternal ? `${t('toTour')} ${selectedTourInternal.id}` : ''}`}</strong></Typography>
        <Typography><strong>{`${t('microHub')}: ${selectedTourInternal ? selectedTourInternal.microHubName : '-'}`}</strong></Typography>
        <Typography><strong>{`${t('boxAmount')}: ${selectedTourInternal ? CapacityHelperService.getBoxAmountOfTour(selectedTourInternal) : '-'}`}</strong></Typography>
        <Typography><strong>{`${t('weight')}: ${selectedTourInternal ? CapacityHelperService.getWeightOfTour(selectedTourInternal) : '-'}`}</strong></Typography>
        <Typography><strong>{`${t('volume')}: ${selectedTourInternal ? CapacityHelperService.getVolumeOfTour(selectedTourInternal) : '-'}`}</strong></Typography>
      </div>
      <DialogContent>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            lg={5}
            sm={12}
            xl={5}
            xs={12}
          >
            <AddStopsToPreviewTourMap
              addedStops={stopsToAdd}
              className={classes.map}
              displayAllTours={displayAllTours}
              microHubs={microHubs ? microHubs : []}
              notPlanedStops={stopsWithoutTour ? stopsWithoutTour.filter(stop => stopsToAdd.findIndex(s => s.id === stop.id) < 0) : []}
              previewMap
              selectedTour={dialogState.tour ? dialogState.tour : null}
              setDisplayAllTours={setDisplayAllTours}
              setSelectedStop={addOrRemoveStop}
              toursWithStops={displayAllTours ? tours : (dialogState.tour ? [dialogState.tour] : [])}
            />
          </Grid>
          <Grid
            item
            lg={7}
            sm={12}
            xl={7}
            xs={12}
          >
            <StopAddTable
              addOrRemoveStop={addOrRemoveStop}
              className={classes.table}
              dialogState={dialogState}
              microHubs={microHubs}
              stopsToAdd={stopsToAdd}
              stopsWithoutTour={stopsWithoutTour}
            />
          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions>
        <FormControl>
          <Tooltip title={t('autoOrderOnAddTooltip')}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={autoOrderOnAdd}
                  color="secondary"
                  onChange={(event) => setAutoOrderOnAdd(event.target.checked)}
                  value={autoOrderOnAdd}
                />
              }
              label={t('autoOrderOnAdd')}
            />
          </Tooltip>
        </FormControl>
        <Button
          color="default"
          onClick={handleNotOk}
        >
          <CancelIcon/>&nbsp;{t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          disabled={!stopsToAdd || stopsToAdd.length === 0 || stopsToAdd.some(stop => !ShipperAllowedOnHubService.checkShipperAllowedStop(microHubs.find(hub => hub.name === dialogState.tour.microHubName), stop))}
          onClick={handleOk}
        >
          <SaveIcon/>&nbsp;{t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddStopsWithoutTourDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogState: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  microHubs: PropTypes.array.isRequired,
  selectedTour: PropTypes.object,
  stopsWithoutTour: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  tours: PropTypes.array.isRequired,
};

export default compose(withStyles(styles), withTranslation())(AddStopsWithoutTourDialog);
