import React, {Fragment, useState} from 'react'
import {Box, Collapse, IconButton, TableCell, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon,} from '@material-ui/icons';
import StopTableDetails from '../../PreviewTours/StopTableDetails';
import MigrationStatus from '../MigrationStatus';


function StopTableRow(props) {

  const {classes, stop, className, previewDeadlineElapsed, finalDeadlineElapsed} = props;
  const [open, setOpen] = useState(false);

  // stop migration status
  const migrated = stop.isMigrated && previewDeadlineElapsed && !finalDeadlineElapsed;
  const hasFinalStop = stop.hasFinalStop && !migrated;
  const noFinalStop = !stop.hasFinalStop;
  const declined = stop.hasDeclinedStop && !stop.hasFinalStop; // if a declined stop and a final stop are present the problem is probably solved!

  const rootClassName = classNames(className, classes.root, {[classes.deadlinesInvalid]: !previewDeadlineElapsed || finalDeadlineElapsed});

  return (
    <Fragment>
      <TableRow
        className={rootClassName}
        hover={previewDeadlineElapsed && !finalDeadlineElapsed && !open}
        selected={open && previewDeadlineElapsed && !finalDeadlineElapsed}
      >
        <TableCell>{stop.stopNumber}</TableCell>
        <TableCell>{stop.id}</TableCell>
        <TableCell>
          <MigrationStatus
            declinedStop={declined}
            finalDeadlineElapsed={finalDeadlineElapsed}
            migrated={migrated}
            migrationNotPossible={noFinalStop}
            previewDeadlineNotElapsed={!previewDeadlineElapsed}
            readyForMigration={hasFinalStop}
          />
        </TableCell>
        <TableCell>{stop.lastName}</TableCell>
        <TableCell>{stop.address?.streetAndNumber}</TableCell>
        <TableCell className={classes.lastTableCell}>
          {stop.customerId}
          <IconButton
            onClick={() => setOpen(!open)}
            size="small"
          >
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={9}
          style={{paddingBottom: 0, paddingTop: 0}}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <Box margin={1}>
              <StopTableDetails
                stop={stop}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

StopTableRow.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  finalDeadlineElapsed: PropTypes.bool.isRequired,
  previewDeadlineElapsed: PropTypes.bool.isRequired,
  stop: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(StopTableRow);
