export default theme => ({
  contentWithoutBorder: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  navButton: {
    marginRight: theme.spacing(1)
  },
  startButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  infoDiv: {
    margin: theme.spacing(1),
  },
  formControlSelect: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: '150px',
    width: '150px',
    verticalAlign: 'middle'
  },
  picker: {
    marginLeft: theme.spacing(1),
  }
});
