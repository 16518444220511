import React from 'react'
import { withStyles } from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CarrierAndMicroHubDropdownSelect, DateRangeFilter, DropdownSelectSimple } from 'components';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { displayModes } from 'services/enums/displayModes';

function TemplateStopTourOverviewFilter(props) {

  const { classes, className, filter, setFilter, disabled, carriersWithMicroHubs, shipperOptions, t, displayMode, microHubs } = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <form>
        <DateRangeFilter
          fromError={!filter.fromDate}
          fromRequired
          fromValue={filter.fromDate}
          setValue={(fromValue, toValue) => setFilter({ ...filter, fromDate: fromValue, toDate: toValue })}
          toError={!filter.toDate}
          toRequired
          toValue={filter.toDate}
        />
        <div className={classes.rowWrapper}>
          <DropdownSelectSimple
            disabled={disabled}
            hideOnEmptyOptions
            name={t('shipper')}
            options={shipperOptions}
            setValue={(value) => setFilter({ ...filter, shipperName: value })}
            sort
            required
            error={filter.shipperName === ''}
            value={filter.shipperName}
          />
          {displayMode === displayModes.admin &&
            <CarrierAndMicroHubDropdownSelect
              carriersWithMicroHubs={carriersWithMicroHubs}
              carrierValue={filter.carrierName}
              className={classes.marginWrapper}
              disabled={disabled}
              microHubValue={filter.microHubName}
              setCarrierValue={(value) => setFilter({ ...filter, carrierName: value, microHubName: '' })}
              setMicroHubValue={(value) => setFilter({ ...filter, microHubName: value })}
            />
          }
          {displayMode === displayModes.carrier &&
            <DropdownSelectSimple
              hideOnEmptyOptions
              name={t('microHub')}
              options={microHubs}
              setValue={(value) => setFilter({ ...filter, microHubName: value })}
              sort
              value={filter.microHubName}
            />
          }
        </div>
      </form>
    </div>
  );
}


TemplateStopTourOverviewFilter.propTypes = {
  carriersWithMicroHubs: PropTypes.object,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  shipperOptions: PropTypes.array.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  microHubs: PropTypes.array
};

export default compose(withStyles(styles), withTranslation())(TemplateStopTourOverviewFilter);
