export default theme => ({
  root: {
    padding: theme.spacing(0.5)
  },
  rootDisabled: {
    backgroundColor: theme.palette.common.muted,
    padding: theme.spacing(0.5)
  },
  dragging: {
    maxWidth: '82%'
  },
  tourHeader: {
    display: 'flex',
    paddingLeft: '0px',
    paddingRight: '5px'
  },
  headerSection: {
    flexBasis: '18.5%',
    flexShrink: 0,
    overflowX: 'hidden'
  },
  headerSectionSmall: {
    flexBasis: '16%',
    flexShrink: 0,
    overflowX: 'hidden'
  },
  headerSectionLarge: {
    flexBasis: '25%',
    flexShrink: 0,
    overflowX: 'hidden'
  },
  stopContainer: {
    width: '100%'
  },
  stop: {
    borderRadius: '5px',
    marginTop: theme.spacing(0.5),
  }
});

