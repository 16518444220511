export default theme => ({
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '30vh',
  },
  paper: {
    padding: theme.spacing(3)
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(3),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4)
  }
});

