import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import React, {useEffect, useState} from 'react';
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import {cloneDeep} from 'lodash';
import {onCloseWithBackdropCurry} from '../../../services/util/helperFuncs';


function TourEditNameDialog(props) {
  const {classes, t, handleCancel, handleClose, dialogState} = props;
  const [tourName, setTourName] = useState(dialogState.tour?.tourName || '');

  useEffect(() => {
    if (dialogState.open) {
      setTourName(dialogState.tour?.tourName || '');
    }
  }, [dialogState.open, dialogState.tour]);

  const handleOk = () => {
    const newTour = cloneDeep(dialogState.tour);
    newTour.tourName = tourName;
    handleClose(newTour);
  }

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      maxWidth={'sm'}
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={dialogState.open}
    >
      <DialogTitle>{`Tour ${dialogState.tour?.tourId}${t('renameTour')}`}
      </DialogTitle>
      <DialogContent>
        <form>
          <div className={classes.rowWrapper}>
            <TextField
              className={classes.formControl}
              inputProps={{maxLength: 8}}
              label={t('tourName')}
              onChange={(e) => setTourName(e.target.value)}
              value={tourName}
            />
            <TextField
              className={classes.hidden}
            />
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={handleCancel}
        >
          <CancelIcon/>&nbsp;{t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          onClick={handleOk}
        >
          <SaveIcon/>&nbsp;{t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

TourEditNameDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogState: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TourEditNameDialog);
