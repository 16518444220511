import React, {Fragment} from 'react'
import {Button, Grid, Paper, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';


function NotFound(props) {

  const {t, classes, history} = props;

  return (
    <Grid
      alignItems="center"
      className={classes.grid}
      container
      justifyContent="center"
    >
      <Grid
        className={classes.content}
        item
      >
        <Grid
          alignItems="center"
          container
          direction="column"
          justifyContent="center"
          spacing={3}
        >
          <Fragment>
            <Grid item>
              <Paper className={classes.paper}>
                <Typography variant="h4">
                  {t('siteNotFound')}
                </Typography>
                <Button
                  color="primary"
                  onClick={() => history.push('/')}
                >
                  {t('backToStart')}
                </Button>
              </Paper>
            </Grid>
          </Fragment>
        </Grid>
      </Grid>
    </Grid>
  );
}

NotFound.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation(), withRouter)(NotFound);
