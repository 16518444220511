import {compose} from 'recompose';
import {Typography, withStyles} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import styles from './styles'
import classNames from 'classnames';
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {getStopStatusString, StopStatus} from '../../../../services/enums/StopStatus';
import {Pets as MicroHubIcon, Place} from '@material-ui/icons';


function Legend(props) {

  const {classes, className, t} = props;

  const rootClassName = classNames(classes.root, className);


  return (
    <Fragment>
      <Typography
        className={rootClassName}
        variant="body1"
      >
        <Place
          className={classNames(classes.materialIcon, classes.iconNotPlanned)}
        />
        {t(getStopStatusString(StopStatus.NotPlaned))}&nbsp;
        <Place
          className={classNames(classes.materialIcon, classes.iconPlanned)}
        />
        {t(getStopStatusString(StopStatus.Planed))}&nbsp;
        <Place
          className={classNames(classes.materialIcon, classes.iconInDelivery)}
        />
        {t(getStopStatusString(StopStatus.InDelivery))}&nbsp;

        <Place
          className={classNames(classes.materialIcon, classes.iconDelivered)}
        />
        {t(getStopStatusString(StopStatus.Delivered))}&nbsp;
        <Place
          className={classNames(classes.materialIcon, classes.iconCanNotDeliver)}
        />
        {t(getStopStatusString(StopStatus.CanNotDeliver))}&nbsp;
        <Place
          className={classNames(classes.materialIcon, classes.iconRedelivery)}
        />
        {t(getStopStatusString(StopStatus.Redelivery))}&nbsp;
        <MicroHubIcon
          className={classes.materialIcon}
        />
        {t('microHub')}
      </Typography>
    </Fragment>
  );
}

Legend.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(Legend);

