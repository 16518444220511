import React from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Button, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {ExpandMore as ExpandMoreIcon} from '@material-ui/icons';
import classNames from 'classnames';
import TourTable from '../TourTable';
import {TextPlaceholderWrapper} from '../../index';
import MigrationStatus from '../MigrationStatus';
import ProgressDisplay from '../ProgressDisplay';
import {getMigrationStatus} from '../../../services/util/migrationStatusHelper';


function MicroHubWrapper(props) {

  const {classes, t, microHubWithToursAndStops, className, migrateTour, migrateHub, previewDeadlineElapsed, finalDeadlineElapsed} = props;

  const rootClassName = classNames(className, classes.root);

  // calculate counts
  const totalStopsCount = microHubWithToursAndStops.tours.reduce((acc, tour) => acc + tour.stops.length, 0)
    + microHubWithToursAndStops.stopsWithoutTour.length;
  const migratedStopsCount = microHubWithToursAndStops.tours.reduce((acc, tour) => acc + tour.stops.filter(s => s.isMigrated).length, 0)
    + microHubWithToursAndStops.stopsWithoutTour.filter(s => s.isMigrated).length;
  const stopsWithFinalStopCount = microHubWithToursAndStops.tours.reduce((acc, tour) => acc + tour.stops.filter(s => s.hasFinalStop).length, 0)
    + microHubWithToursAndStops.stopsWithoutTour.filter(s => s.hasFinalStop).length;
  const declinedStopsCount = microHubWithToursAndStops.tours.reduce((acc, tour) => acc + tour.stops.filter(s => s.hasDeclinedStop && !s.hasFinalStop).length, 0)
    + microHubWithToursAndStops.stopsWithoutTour.filter(s => s.hasDeclinedStop && !s.hasFinalStop).length;

  const {
    migrationNotPossible,
    migrated,
    readyForMigration,
    migrationPossible,
    additionalMigrationRequired,
    declinedStopsPresent
  } = getMigrationStatus(totalStopsCount, migratedStopsCount, stopsWithFinalStopCount, previewDeadlineElapsed, finalDeadlineElapsed, declinedStopsCount);

  return (
    <Accordion className={rootClassName}>
      <AccordionSummary
        classes={
          {content: classes.accordionSummaryContent}
        }
        expandIcon={<ExpandMoreIcon/>}
      >
        <Typography>
          <strong>{microHubWithToursAndStops.microHubName} - {microHubWithToursAndStops.carrierName}</strong>
        </Typography>
        <Typography className={classes.statusDisplay}>
          <strong>{t('migrationStatus')}:</strong>&nbsp;
          <MigrationStatus
            additionalMigrationRequired={additionalMigrationRequired}
            declinedStop={declinedStopsPresent}
            finalDeadlineElapsed={finalDeadlineElapsed}
            migrated={migrated}
            migrationNotPossible={migrationNotPossible}
            migrationPossible={migrationPossible}
            previewDeadlineNotElapsed={!previewDeadlineElapsed}
            readyForMigration={readyForMigration}
          />
        </Typography>
        <Typography><strong>{t('migrationStoppsPreview')}:</strong>&nbsp;{totalStopsCount}</Typography>
        <Typography><strong>{t('migrationStoppsMigrated')}:</strong>&nbsp;{migratedStopsCount}</Typography>
        <div>
          <Typography><strong>{t('migrationStoppsFinal')}:</strong>&nbsp;</Typography>
          <ProgressDisplay
            currentValue={stopsWithFinalStopCount}
            maxValue={totalStopsCount}
            width={'200px'}
          />
        </div>
        <Typography>
          <Button
            color="primary"
            disabled={migrated || migrationNotPossible || !previewDeadlineElapsed || finalDeadlineElapsed}
            onClick={() => migrateHub(microHubWithToursAndStops.carrierName, microHubWithToursAndStops.microHubName)}
            variant={'contained'}
          >
            {t('migrate')}
          </Button>
        </Typography>
      </AccordionSummary>
      <hr className={classes.hr}/>
      <AccordionDetails className={classes.accordionDetail}>
        <TextPlaceholderWrapper
          active={!microHubWithToursAndStops.tours?.length && !microHubWithToursAndStops.stopsWithoutTour?.length}
          text={t('noPreviewToursForThisHub')}
        >
          <TourTable
            className={classes.previewTourTable}
            finalDeadlineElapsed={finalDeadlineElapsed}
            migrateTour={migrateTour}
            previewDeadlineElapsed={previewDeadlineElapsed}
            stopsWithoutTour={microHubWithToursAndStops.stopsWithoutTour}
            toursWithStops={microHubWithToursAndStops.tours}
          />
        </TextPlaceholderWrapper>
      </AccordionDetails>
    </Accordion>
  );
}


MicroHubWrapper.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  finalDeadlineElapsed: PropTypes.bool.isRequired,
  microHubWithToursAndStops: PropTypes.object.isRequired,
  migrateHub: PropTypes.func.isRequired,
  migrateTour: PropTypes.func.isRequired,
  previewDeadlineElapsed: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(MicroHubWrapper);
