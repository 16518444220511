import React from 'react';
import PropTypes from 'prop-types';
import {MarkerCreateFuncs, OverlappingMarkerCreateFuncs} from '../../MapComponents';
import {IconService, notPlanedIconColor} from '../../../../services/icon/iconService';
import {AddStopsToTourBaseMap} from '../../../index';

function AddStopsToPreviewTourMap(props) {

  const {className, toursWithStops, microHubs, notPlanedStops, setSelectedStop, selectedTour, addedStops, displayAllTours, setDisplayAllTours} = props;

  return (
    <AddStopsToTourBaseMap
      addedStops={addedStops}
      className={className}
      displayAllTours={displayAllTours}
      displayControls
      getOverLappingMarkerClusterIcon={(overlappingElements, highLiteDuplicates) => OverlappingMarkerCreateFuncs.getForAddStopToTourMap(overlappingElements, highLiteDuplicates, () => {
        return selectedTour ? selectedTour.color : notPlanedIconColor
      })}
      getStopClusterIcon={() => IconService.getMarkerClusterIcon(notPlanedIconColor)}
      getStopMarkerAndAddToMcg={(stop, mcg, t) => MarkerCreateFuncs.getPreviewStopMarker(stop, mcg, t, setSelectedStop)}
      getToAddStopMarkerAndAddToMcg={(stop, mcg, t) => MarkerCreateFuncs.getPreviewStopMarker(stop, mcg, t, setSelectedStop, selectedTour ? selectedTour.color : notPlanedIconColor)}
      getTourClusterIcon={(tour) => IconService.getMarkerClusterIcon(tour.color, tour.id)}
      getTourStopMarkerAndAddToMcg={(tour, stop, mcg, t) => MarkerCreateFuncs.getPreviewTourStopMarker(tour, stop, mcg, t, setSelectedStop)}
      microHubs={microHubs}
      notAddedStops={notPlanedStops}
      setDisplayAllTours={setDisplayAllTours}
      tours={toursWithStops}
    />
  );
}

AddStopsToPreviewTourMap.propTypes = {
  addedStops: PropTypes.array.isRequired,
  className: PropTypes.string,
  displayAllTours: PropTypes.bool.isRequired,
  microHubs: PropTypes.array,
  notPlanedStops: PropTypes.array,
  selectedTour: PropTypes.object,
  setDisplayAllTours: PropTypes.func.isRequired,
  setSelectedStop: PropTypes.func,
  toursWithStops: PropTypes.array,
};


export default AddStopsToPreviewTourMap;

