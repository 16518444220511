import React from 'react'
import {LinearProgress, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classNames from 'classnames'
import {roundCapacityValues} from '../../../../../services/util/helperFuncs';


function HubShipperUtilizationIndicator(props) {

  const {classes, shipperName, currentCapacity, maxCapacity, className} = props;

  const rootClassName = classNames(classes.root, className);

  const utilization = (currentCapacity / maxCapacity) * 100;
  const visualisation = Math.min(utilization, 100);

  return (
    <div className={rootClassName}>
      <Typography variant="body1">
        <strong>{shipperName}</strong>
      </Typography>
      <div className={classes.capacityAllWrapper}>
        <div className={classes.capacityAllIndicatorWrapper}>
          <LinearProgress
            classes={utilization > 100 ? {barColorPrimary: classes.progressBarRed} : {}}
            className={classes.capacityAllIndicator}
            value={visualisation}
            variant="determinate"
          />
        </div>
        <Typography variant="body2">
          {`${roundCapacityValues(currentCapacity)} l / ${roundCapacityValues(maxCapacity)} l`}
        </Typography>
      </div>
    </div>
  );
}

HubShipperUtilizationIndicator.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  currentCapacity: PropTypes.number.isRequired,
  maxCapacity: PropTypes.number.isRequired,
  shipperName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(HubShipperUtilizationIndicator);
