import React, {useCallback, useEffect, useState} from 'react'
import {AppBar, Button, Grid, Tab, Tabs, Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import PropTypes from 'prop-types';
import SideNavLayout from '../../../layouts/SideNavLayout';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {GeneralInfoIcon, InfoIcon, PondUploader, UploadedExcelsTable} from '../../../components';
import {ExcelTemplateService} from '../../../services/excelTemplateService/excelTemplateService';
import {TourService} from '../../../services/backend/tourService';
import {AuthService} from '../../../services/auth/authService';


function DataUpload(props) {

  const {classes, t} = props;

  const [stopExcels, setStopExcels] = useState([]);
  const [tourExcels, setTourExcels] = useState([]);
  const [tabState, setTabState] = useState(0);
  const [requiresReload, setRequiresReload] = useState(false);
  const [resetFiles, setResetFiles] = useState(false);

  const loadStopExcels = useCallback(() => {
    TourService.getUploadedExcels(2, 7).then(response => response.json()).then(response => setStopExcels(response)
      , () => {
        alert(t('errorLoadingExcels'))
      })
  }, [t]);

  const loadTourExcels = useCallback(() => {
    TourService.getUploadedExcels(1, 7).then(response => response.json()).then(response => setTourExcels(response)
      , () => {
        alert(t('errorLoadingExcels'))
      })
  }, [t]);

  if (requiresReload) {
    loadStopExcels();
    setRequiresReload(false);
  }

  useEffect(() => {
    loadStopExcels()
  }, [loadStopExcels]);

  useEffect(() => {
    loadTourExcels()
  }, [loadTourExcels]);

  return (
    <SideNavLayout title={t('dataUpload')}>
      <AppBar
        className={classes.appBar}
        color={'secondary'}
        position={'relative'}
      >
        <Tabs
          onChange={(event, newValue) => {
            if (newValue !== tabState) {
              setTabState(newValue);
              setResetFiles(true);
            }
          }}
          value={tabState}
        >
          <Tab
            label={t('stopUpload')}
          />
          <Tab
            label={t('tourUpload')}
          />
        </Tabs>
      </AppBar>
      <div className={classes.root}>
        <div className={classes.helpWrapper}>
          <GeneralInfoIcon/>
        </div>
        {(() => {
          switch (tabState) {
            case 0:
              return (
                <Grid
                  container
                  spacing={4}
                  style={{marginTop: '-50px'}}
                >
                  <Grid
                    item
                    lg={12}
                    xs={12}
                  >
                    <Button
                      className={classes.button}
                      color="secondary"
                      onClick={() => ExcelTemplateService.downloadTemplateStopsShipper(AuthService.getUserOrganization())}
                      variant="contained"
                    >
                      {t('downloadStopTemplate')}
                    </Button>
                    <div className={classes.iconWrapper}>
                      <Typography
                        variant="h3"
                      >
                        {t('uploadStops')}
                      </Typography>
                      <InfoIcon
                        className={classes.iconMargin}
                        descriptionKey={'2'}
                      />
                    </div>
                    <PondUploader
                      fileName={'stops'}
                      onLoadCallback={() => setRequiresReload(true)}
                      onResetFile={() => setResetFiles(false)}
                      resetFiles={resetFiles}
                      uploaderOrganization={AuthService.getUserOrganization()}
                      uploadType="shipper"
                      url={'/ExcelImport/UploadStops'}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={12}
                  >
                    <Typography
                      variant="h3"
                    >
                      {t('uploadedExcels')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                  >
                    <UploadedExcelsTable
                      excels={stopExcels}
                    />
                  </Grid>
                </Grid>
              );
            case 1:
              return (
                <Grid
                  container
                  spacing={4}
                  style={{marginTop: '-50px'}}
                >
                  <Grid
                    item
                    lg={12}
                    xs={12}
                  >
                    <Button
                      className={classes.button}
                      color="secondary"
                      onClick={() => ExcelTemplateService.downloadTemplateTourShipper(AuthService.getUserOrganization())}
                      variant="contained"
                    >
                      {t('downloadTourTemplate')}
                    </Button>
                    <div className={classes.iconWrapper}>
                      <Typography
                        variant="h3"
                      >
                        {t('uploadTour')}
                      </Typography>
                      <InfoIcon
                        className={classes.iconMargin}
                        descriptionKey={'1'}
                      />
                    </div>
                    <PondUploader
                      fileName={'tour'}
                      onLoadCallback={() => setRequiresReload(true)}
                      onResetFile={() => setResetFiles(false)}
                      resetFiles={resetFiles}
                      uploaderOrganization={AuthService.getUserOrganization()}
                      uploadType="shipper"
                      url={'/ExcelImport/UploadTour'}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={12}
                  >
                    <Typography
                      variant="h3"
                    >
                      {t('uploadedExcels')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                  >
                    <UploadedExcelsTable
                      excels={tourExcels}
                    />
                  </Grid>
                </Grid>
              );
            default:
              return (<div>error</div>);
          }
        })()}
      </div>
    </SideNavLayout>
  );

}

DataUpload.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(DataUpload);
