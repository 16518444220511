import React from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'

function StopEditShipper(props) {

  const {classes, className, t} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      {t('NoEditForShipper')}
    </div>
  );
}


StopEditShipper.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(StopEditShipper);
