import React, {useState} from 'react'
import {Button, Checkbox, FormControlLabel, Grid, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {InfoIcon, Portlet, PortletContent} from '../../../../index';
import Typography from '@material-ui/core/Typography';
import {Cancel as CancelIcon, DeleteForever as DeleteForeverIcon, Save as SaveIcon} from '@material-ui/icons';
import classNames from 'classnames';
import {CreateAddress, CreatePerson} from '../../../Shared';

function EditCarrier(props) {
  const {classes, t, carrier, saveClick, cancelClick, deleteClick} = props;

  const [info, setInfo] = useState({
    name: carrier.name,
    useAlgorithmForTourPlanning: carrier.useAlgorithmForTourPlanning
  });

  const [address, setAddress] = useState({
    street: carrier.address.street,
    houseNumber: carrier.address.houseNumber,
    zipcode: carrier.address.zipcode,
    city: carrier.address.city,
    country: carrier.address.country,
  });

  const [billingAddress, setbillingAddress] = useState({
    street: carrier.billingAddress.street,
    houseNumber: carrier.billingAddress.houseNumber,
    zipcode: carrier.billingAddress.zipcode,
    city: carrier.billingAddress.city,
    country: carrier.billingAddress.country,
  });

  const [contact, setContact] = useState({
    name: carrier.contact.name,
    email: carrier.contact.email,
    phoneNumber: carrier.contact.phoneNumber,
  });

  const [disponent, setDisponent] = useState({
    name: carrier.disponent.name,
    email: carrier.disponent.email,
    phoneNumber: carrier.disponent.phoneNumber,
  });

  const activateOk = () => {
    return info.name && address.street && address.houseNumber && address.zipcode && address.city && address.country &&
      billingAddress.street && billingAddress.houseNumber && billingAddress.zipcode && billingAddress.city && billingAddress.country &&
      contact.email && contact.phoneNumber;
  };

  return (
    <React.Fragment>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          xs={12}
        >
          <Button
            className={classes.button}
            color="default"
            onClick={cancelClick}
            title={t('cancel')}
            variant="contained"
          >
            <CancelIcon/>&nbsp;{t('cancel')}
          </Button>
          <Button
            className={classNames(classes.button, classes.deleteButton)}
            onClick={
              () => {
                if (window.confirm(t('confirmDeleteCarrier'))) {
                  deleteClick()
                }
              }
            }
            title={t('deleteCarrier')}
            variant="contained"
          >
            <DeleteForeverIcon/>
          </Button>
          <Button
            className={classes.button}
            color="primary"
            disabled={!activateOk()}
            onClick={() => saveClick(info, address, billingAddress, contact, disponent)}
            title={t('save')}
            variant="contained"
          >
            <SaveIcon/>
          </Button>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Portlet className={classes.content}>
            <PortletContent>
              <Typography variant="h3">
                {t('info')}
              </Typography>
              <hr/>
              <Typography variant="h4">
                {t('name')}
              </Typography>
              <Typography variant="body1">
                {carrier.name}
              </Typography>
              <div className={classes.iconWrapper}>
                <Typography variant="h4">
                  {t('useAlgorithmForTourPlanning')}
                </Typography>
                <InfoIcon
                  className={classes.iconMargin}
                  descriptionKey={'35'}
                />
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={info.useAlgorithmForTourPlanning}
                    color="primary"
                    onChange={(event) => {
                      setInfo({...info, useAlgorithmForTourPlanning: event.target.checked})
                    }}
                    value={info.useAlgorithmForTourPlanning}
                  />
                }
                label={t('useAlgorithmForTourPlanning')}
              />
            </PortletContent>
          </Portlet>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <CreateAddress
            address={address}
            className={classes.content}
            required
            setAddress={setAddress}
            title={t('address')}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <CreateAddress
            address={billingAddress}
            className={classes.content}
            required
            setAddress={setbillingAddress}
            title={t('billingAddress')}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <CreatePerson
            className={classes.content}
            person={contact}
            required
            setPerson={setContact}
            title={t('contact')}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <CreatePerson
            className={classes.content}
            person={disponent}
            required
            setPerson={setDisponent}
            title={t('disponent')}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}


EditCarrier.propTypes = {
  cancelClick: PropTypes.func.isRequired,
  carrier: PropTypes.object.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deleteClick: PropTypes.func.isRequired,
  saveClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(EditCarrier);
