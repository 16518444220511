import React, {useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Portlet} from 'components';
import TemplateStopDetailEdit from './TemplateStopDetailEdit';
import TemplateStopDetailShow from './TemplateStopDetailShow';

function TemplateStopDetail(props) {
  const {
    classes,
    className,
    templateStop,
    relatedTemplateStops,
    updateTemplateStop,
    readOnly,
    closeDetailView,
    editDisplayMode,
    selectTemplateStop
  } = props;

  const [editMode, setEditMode] = useState(false);
  const [editedTemplateStop, setEditedTemplateStop] = useState(null);
  const [editedRelatedTemplateStops, setEditedRelatedTemplateStops] = useState([]);

  const editTemplateStop = () => {
    const newTemplateStop = JSON.parse(JSON.stringify(templateStop));
    const newRelatedTemplateStops = JSON.parse(JSON.stringify(relatedTemplateStops));
    setEditedTemplateStop(newTemplateStop);
    setEditedRelatedTemplateStops(newRelatedTemplateStops);
    setEditMode(true);
  };

  const update = () => {
    setEditMode(false);
    updateTemplateStop(editedTemplateStop, relatedTemplateStops, editedRelatedTemplateStops);
  };

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet className={rootClassName}>
      {editMode ?
        <TemplateStopDetailEdit
          editDisplayMode={editDisplayMode}
          editedTemplateStop={editedTemplateStop}
          editedRelatedTemplateStops={editedRelatedTemplateStops}
          setEditedTemplateStop={setEditedTemplateStop}
          setEditedRelatedTemplateStops={setEditedRelatedTemplateStops}
          setEditMode={setEditMode}
          updateTemplateStop={update}
        />
        :
        <TemplateStopDetailShow
          closeDetailView={closeDetailView}
          edit={editTemplateStop}
          selectTemplateStop={selectTemplateStop}
          readOnly={readOnly}
          templateStop={templateStop}
          relatedTemplateStops={relatedTemplateStops}
        />
      }
    </Portlet>
  );
}


TemplateStopDetail.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  closeDetailView: PropTypes.func,
  editDisplayMode: PropTypes.string,
  readOnly: PropTypes.bool,
  relatedTemplateStops: PropTypes.array.isRequired,
  selectTemplateStop: PropTypes.func.isRequired,
  templateStop: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  updateTemplateStop: PropTypes.func,
}
  ;

export default compose(withStyles(styles), withTranslation())(TemplateStopDetail);
