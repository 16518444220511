import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {FormControl, IconButton, Input, TableCell, TableRow, withStyles} from '@material-ui/core';
import styles from '../../../styles';
import {withTranslation} from 'react-i18next';
import React, {Fragment} from 'react';
import {DeleteForever as DeleteRowIcon} from '@material-ui/icons';


function InsertAddRow(props) {
  const {classes, t, productToAdd, updateItem, deleteProduct} = props;
  
  return(
    <TableRow className={classes.addRow}>
      <Fragment>
        <TableCell colSpan={2}>
          <FormControl>
            <Input
              error={!productToAdd.description}
              id="addInsertDescription"
              onChange={(event) =>
                updateItem(productToAdd.tempId, 'description', event.target.value)
              }
              value={productToAdd.description}
            />
          </FormControl>
        </TableCell>
        <TableCell>
          <FormControl>
            <Input
              error={productToAdd.amount <= 0}
              id="addInsertAmount"
              onChange={(event) =>
                updateItem(productToAdd.tempId, 'amount', (parseInt(event.target.value) && parseInt(event.target.value) >= 0) ? parseInt(event.target.value) : 0)
              }
              onClick={event => event.target.select()}
              onWheel={event => event.target.blur()}
              type="number"
              value={productToAdd.amount}
            />
          </FormControl>
        </TableCell>
        <TableCell>
          <FormControl>
            <Input
              error={productToAdd.weight <= 0}
              id="addInsertWeight"
              onChange={(event) =>
                updateItem(productToAdd.tempId, 'weight', (parseFloat(event.target.value) && parseFloat(event.target.value) >= 0) ? parseFloat(event.target.value) : 0)
              }
              onClick={event => event.target.select()}
              onWheel={event => event.target.blur()}
              type="number"
              value={productToAdd.weight}
            />
          </FormControl>
        </TableCell>
        <TableCell>
          <IconButton
            className={classes.buttonRight}
            color="primary"
            onClick={() => deleteProduct(productToAdd)}
            title={t('delete')}
          >
            <DeleteRowIcon className={classes.redIcon}/>
          </IconButton>
        </TableCell>
      </Fragment>
    </TableRow>
  )
}

InsertAddRow.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deleteProduct: PropTypes.func.isRequired,
  productToAdd: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(InsertAddRow);
