import React from 'react'
import {Button} from '@material-ui/core';
import {DeleteForever as DeleteIcon,} from '@material-ui/icons';
import classNames from 'classnames';
import {DateService} from '../../../services/util/DateService';

const Columns = {
  internalCustomerId: {
    name: 'internalCustomerId',
    header: ({t}) => t('internalCustomerId'),
    body: ({customer}) => customer.id
  },
  customerId: {
    name: 'customerId',
    header: ({t}) => t('customerId'),
    body: ({customer}) => customer.externalCustomerId
  },
  firstName: {
    name: 'firstName',
    header: ({t}) => t('firstName'),
    body: ({customer}) => customer.firstName || ''
  },
  lastName: {
    name: 'lastName',
    header: ({t}) => t('lastName'),
    body: ({customer}) => customer.lastName || ''
  },
  email: {
    name: 'email',
    header: ({t}) => t('email'),
    body: ({customer}) => customer.email || ''
  },
  streetAndNumber: {
    name: 'streetAndNumber',
    header: ({t}) => t('streetAndNumber'),
    body: ({customer}) => customer.address.streetAndNumber
  },
  zipcode: {
    name: 'zipcode',
    header: ({t}) => t('zipcode'),
    body: ({customer}) => customer.address.zipcode
  },
  lastOrderDate: {
    name: 'lastOrderDate',
    header: ({t}) => t('lastOrderDate'),
    body: ({customer}) => DateService.displayDate(customer.lastOrderDate)
  },
  delete: {
    name: 'delete',
    header: ({t}) => t('delete'),
    body: ({customer, classes, deleteCustomer, t}) => (
      <Button
        className={classNames(classes.tableButton, classes.deleteButton)}
        onClick={(event) => deleteCustomer(event, customer.id)}
        title={t('customerDelete')}
      >
        <DeleteIcon/>
      </Button>
    )
  },
}

export default Columns;