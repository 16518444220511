import React, {Fragment} from 'react'
import {Table, TableBody, TableCell, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {getEmptyTypeString} from '../../../../services/enums/EmptyType';

function EmptiesTable(props) {

  const {t, classes, className, empties} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <Fragment>
      {empties && empties.length > 0 &&
      <Fragment>
        <Table
          className={rootClassName}
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell>{t('emptyName')}</TableCell>
              <TableCell>{t('amount')}</TableCell>
              <TableCell>{t('emptyType')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {empties.map((empty, index) =>
              <TableRow key={index}>
                <TableCell>{empty.name}</TableCell>
                <TableCell>{empty.amount}</TableCell>
                <TableCell>{t(getEmptyTypeString(empty.emptyType))}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Fragment>
      }
    </Fragment>
  );
}


EmptiesTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  empties: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
}
;

export default compose(withStyles(styles), withTranslation())(EmptiesTable);
