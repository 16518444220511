import React from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {DragDropContext, Droppable} from 'react-beautiful-dnd'
import Tour from '../Tour';
import {DndHelperElementTypes, DndHelperService} from '../../../../../services/dndHelper/dndHelperService';
import {ExpandMore as ExpandMoreIcon} from '@material-ui/icons';

function Hub(props) {

  const {classes, hub, t, className, toursOfHubChanged} = props;

  const rootClassName = classNames(classes.root, className);
  const uniqueHubId = DndHelperService.getUniqueHubId(hub.microHubName);

  const dragEnd = (event) => {
    // dropped outside the context
    if (!event.destination) {
      return;
    }

    //nothing has changed
    if (event.destination.droppableId === event.source.droppableId && event.destination.index === event.source.index) {
      return;
    }

    const destination = DndHelperService.parseUniqueId(event.destination.droppableId);
    const source = DndHelperService.parseUniqueId(event.source.droppableId);

    let newTours = JSON.parse(JSON.stringify(hub.tours));
    switch (event.type) {
      case DndHelperElementTypes.Tour: {
        //Reorder Tours
        newTours = DndHelperService.reorder(newTours, event.source.index, event.destination.index);
        return toursOfHubChanged(hub, newTours);
      }
      case DndHelperElementTypes.Stop: {
        const destinationTourIndex = newTours.findIndex(tour => tour.id === destination.tourId);
        const sourceTourIndex = newTours.findIndex(tour => tour.id === source.tourId);
        if (destinationTourIndex < 0 || sourceTourIndex < 0) {
          return;
        }
        if (source.tourId === destination.tourId) {
          //Reorder stops on tour
          let reorderedTour = JSON.parse(JSON.stringify(newTours[destinationTourIndex]));
          reorderedTour.stops = DndHelperService.reorder(reorderedTour.stops, event.source.index, event.destination.index);
          newTours[destinationTourIndex] = reorderedTour;
        } else {
          // move stop to another tour
          const stop = newTours[sourceTourIndex].stops.splice(event.source.index, 1);
          // check if destination tour contains stops with another shipper ==> dont mix shippers in preview tour service!!
          if (newTours[destinationTourIndex].stops.some(stopOnDestTour => stopOnDestTour.shipperName !== stop[0].shipperName)) {
            alert(t('noShipperMixingInPreviewToursService'));
            return;
          }
          newTours[destinationTourIndex].stops.splice(event.destination.index, 0, ...stop);
        }
        return toursOfHubChanged(hub, newTours);
      }
      default: {
        // wrong event type
        return;
      }
    }
  };

  return (
    <DragDropContext
      onDragEnd={dragEnd}
    >
      <Accordion className={rootClassName}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
        >
          <Typography className={classes.headerSection}>
            <strong>{t('microHub')}: {hub.microHubName}</strong>
          </Typography>
        </AccordionSummary>
        <hr className={classes.hr}/>
        <AccordionDetails>
          <Droppable
            droppableId={uniqueHubId}
            type={DndHelperElementTypes.Tour}
          >
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={classes.tourContainer}
              >
                {hub.tours.map((tour, index) => {
                  return (
                    <Tour
                      className={classes.tour}
                      index={index}
                      key={tour.id}
                      tour={tour}
                      uniqueHubId={uniqueHubId}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </AccordionDetails>
      </Accordion>
    </DragDropContext>
  );
}


Hub.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  hub: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  toursOfHubChanged: PropTypes.func.isRequired
};

export default compose(withStyles(styles), withTranslation())(Hub);
