import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {Cancel as CancelIcon, DeleteForever as DeleteIcon, Info as InformationIcon} from '@material-ui/icons';
import {onCloseWithBackdropCurry} from '../../../../../services/util/helperFuncs';

function DeleteStopDialog(props) {

  const {classes, t, dialogState, handleCancel, handleClose} = props;


  const handleOk = () => {
    handleClose(dialogState.stop.id, dialogState.stop.assignedCarrierName, dialogState.stop.assignedMicroHubName);
  };

  const handleNotOk = () => {
    handleCancel();
  };

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      maxWidth={'md'}
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={dialogState.open}
    >
      <DialogTitle>{t('stopDelete')}</DialogTitle>
      <DialogContent>
        <InformationIcon/>
        <Typography>
          {t('deletePreviewStop1')}
        </Typography>
        <Typography>
          {t('deletePreviewStop2')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={handleNotOk}
        >
          <CancelIcon/>&nbsp;{t('dialogCancel')}
        </Button>
        <Button
          className={classes.dangerColor}
          color="primary"
          disabled={!dialogState.stop}
          onClick={handleOk}
        >
          <DeleteIcon/>{t('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteStopDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogState: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(DeleteStopDialog);
