import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stepper, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Cancel as CancelIcon} from '@material-ui/icons';
import {LoadingWrapper, TextPlaceholderWrapper} from '../../index';
import {ApprovedTourStep, StopDeliveredStep, StopInDeliveryStep, TourEndStep, TourStartStep} from './components';
import {TourService} from '../../../services/backend/tourService';
import {cloneDeep, sortBy, uniqBy} from 'lodash';
import {TrackingStepType} from '../../../services/enums/TrackingStepType';
import {StopStatus} from '../../../services/enums/StopStatus';
import {onCloseWithBackdropCurry} from '../../../services/util/helperFuncs';


const trackingStopKey = 'trackingStop';
const plannedStopDeliveryKey = 'plannedStopDelivery';
const approvedStepsKey = 'approvedSteps';

const TourSummaryDialog = (props) => {
  const {classes, t, handleClose, open, tour, trackingStops, loading} = props;
  const [plannedStopDeliveries, setPlannedStopDeliveries] = useState([]);
  const [plannedStopDeliveriesLoading, setPlannedStopDeliveriesLoading] = useState(false);
  const [enrichedTour, setEnrichedTour] = useState(null);

  const loadPlannedStopDeliveries = useCallback(async () => {
    if (!tour || !tour.approved) return;
    setPlannedStopDeliveriesLoading(true);
    try {
      const response = await TourService.getPlannedStopDeliveries(tour.tourId);
      setPlannedStopDeliveries(response.plannedStopsDeliveries);
      setPlannedStopDeliveriesLoading(false);
    } catch (e) {
      setPlannedStopDeliveriesLoading(false);
      console.warn('error loading planned stop deliveries', e);
    }
  }, [tour]);

  useEffect(() => {
    loadPlannedStopDeliveries();
  }, [loadPlannedStopDeliveries])

  useEffect(() => {
    if (!open || !tour || !trackingStops?.length || !plannedStopDeliveries?.length) return;

    // build map with [stopId -> planedDeliveryDate]
    const plannedStopDeliveryMap = plannedStopDeliveries.reduce((map, element) => {
      map[element.tourStopId] = element.plannedDelivery;
      return map;
    }, {});

    // build map with [stopId -> trackingStop]
    const trackingStopMap = trackingStops.reduce((map, element) => {
      map[element.finalStopId] = element;
      return map;
    }, {});

    // calculate all approved eventTimeStamps
    const approvedSteps = sortBy(uniqBy(trackingStops
      .map(s => s.trackingSteps)
      .flat()
      .filter(ts => ts.trackingStepType === TrackingStepType.TourApproved), ts => ts.eventTimeStamp), ts => new Date(ts.eventTimeStamp));

    // enrich tour with new data
    const clonedTour = cloneDeep(tour);
    clonedTour[approvedStepsKey] = approvedSteps;
    clonedTour.stops.forEach(stop => {
      stop[trackingStopKey] = trackingStopMap[stop.tourStopId];
      stop[plannedStopDeliveryKey] = plannedStopDeliveryMap[stop.tourStopId];
    });

    setEnrichedTour(clonedTour);


  }, [open, tour, trackingStops, plannedStopDeliveries]);

  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      maxWidth={'sm'}
      onClose={onCloseWithBackdropCurry(handleClose)}
      open={open}
    >
      <DialogTitle>{t('tourSummary')}</DialogTitle>
      <DialogContent>
        <TextPlaceholderWrapper
          active={!tour.approved}
          text={t('tourSummaryDialogNotAvailable')}
        >
          <LoadingWrapper loading={loading || plannedStopDeliveriesLoading}>
            <Fragment>
              {enrichedTour &&
              <Stepper
                className={classes.stepper}
                orientation="vertical"
              >
                {enrichedTour[approvedStepsKey] && enrichedTour[approvedStepsKey].map((approvedStep, index) =>
                  <ApprovedTourStep
                    isLastApproval={index === enrichedTour[approvedStepsKey].length - 1}
                    key={index}
                    timeStamp={approvedStep.eventTimeStamp}
                    tour={tour}
                    user={approvedStep.eventUser}
                  />
                )}
                {enrichedTour.tourStart &&
                <TourStartStep tour={tour}/>
                }
                {sortBy(enrichedTour.stops.filter(s => s.deliverTime), s => s.deliverTime).map((stop, index) =>
                  <StopDeliveredStep
                    key={index}
                    plannedStopDelivery={stop[plannedStopDeliveryKey]}
                    stop={stop}
                    trackingStop={stop[trackingStopKey]}
                  />
                )}
                {enrichedTour.stops.filter(s => s.stopStatus === StopStatus.InDelivery).map((stop, index) =>
                  <StopInDeliveryStep
                    key={index}
                    plannedStopDelivery={stop[plannedStopDeliveryKey]}
                    stop={stop}
                    trackingStop={stop[trackingStopKey]}
                  />
                )}
                {enrichedTour.tourEnd &&
                <TourEndStep tour={tour}/>
                }
              </Stepper>
              }
            </Fragment>
          </LoadingWrapper>
        </TextPlaceholderWrapper>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleClose}
        >
          <CancelIcon/>&nbsp;{t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

TourSummaryDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  tour: PropTypes.object.isRequired,
  trackingStops: PropTypes.array.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TourSummaryDialog);
