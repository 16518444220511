import React, {Fragment} from 'react'
import {Table, TableBody, TableCell, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TourStopTableRowSmall from './TourStopTableRowSmallPreview';
import {Portlet, PortletContent} from '../../../../../../components';
import {Droppable} from 'react-beautiful-dnd';
import {DndHelperElementTypes, DndHelperService} from '../../../../../../services/dndHelper/dndHelperService';


function TourStopTableSmallPreview(props) {

  const {classes, t, tour, className, capacityMode, deleteStopFromTour} = props;
  const rootClassName = classNames(className, classes.root);
  const uniqueTourId = DndHelperService.getUniqueTourId('{}', tour.id);

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <div style={{overflow: 'auto', maxHeight: '326px'}}>
          <Table
            size="medium"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.tableHeader}
                  style={{boxShadow: `inset 10px 0px 0px 0px ${tour.color}`}}
                >{t('stopNumber')}</TableCell>
                {!capacityMode
                  ?
                  <Fragment>
                    <TableCell className={classes.tableHeader}>{t('tourStopId')}</TableCell>
                    <TableCell className={classes.tableHeader}>{t('shipper')}</TableCell>
                    <TableCell className={classes.tableHeader}>{t('microHub')}</TableCell>
                    <TableCell className={classes.tableHeader}>{t('deliveryDate')}</TableCell>
                    <TableCell className={classes.tableHeader}>{t('remove')}</TableCell>
                  </Fragment>
                  :
                  <Fragment>
                    <TableCell className={classes.tableHeader}>{t('streetAndNumber')}</TableCell>
                    <TableCell className={classNames(classes.tableHeader, classes.tableHeaderSmall)}>{t('boxAmount')}</TableCell>
                    <TableCell className={classNames(classes.tableHeader, classes.tableHeaderSmall)}>{t('weight')}</TableCell>
                    <TableCell className={classNames(classes.tableHeader, classes.tableHeaderSmall)}>{t('volume')}</TableCell>
                    <TableCell className={classes.tableHeader}>{t('remove')}</TableCell>
                  </Fragment>
                }
              </TableRow>
            </TableHead>
            <Droppable
              droppableId={uniqueTourId}
              type={DndHelperElementTypes.Tour}
            >
              {(provided) => (
                <TableBody
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {tour.stops.map((stop, index) => {
                    return (
                      <TourStopTableRowSmall
                        capacityMode={capacityMode}
                        deleteStopFromTour={deleteStopFromTour}
                        index={index}
                        key={stop.id}
                        stop={stop}
                        uniqueTourId={uniqueTourId}
                      />
                    );
                  })}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </Table>
        </div>
      </PortletContent>
    </Portlet>
  );
}

TourStopTableSmallPreview.propTypes = {
  capacityMode: PropTypes.bool.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deleteStopFromTour: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  tour: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TourStopTableSmallPreview);
