import React, {Fragment} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classNames from 'classnames'
import {
  Add as CreateButton,
  Delete as DeleteHubPlanIcon,
  Edit as EditHubButton,
  FileCopy as CopyPlanToDaysIcon,
  GetApp as DownloadIcon,
  SettingsOverscan as AutoCenterIcon
} from '@material-ui/icons';

function MicroHubPlanViewControls(props) {

  const {classes, t, createMicroHubPlan, className, autoCenter, edit, noHubPlanAvailable, downLoadPlan, deleteMicroHubPlan, copyPlanToDays} = props;
  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      {
        noHubPlanAvailable ?
          <Button
            className={classes.controlButton}
            color="primary"
            onClick={createMicroHubPlan}
            title={t('microHubPlanCreate')}
            variant="contained"
          >
            <CreateButton className={classes.controlButtonIcon}/>&nbsp;{t('microHubPlanCreate')}
          </Button>
          :
          <Fragment>
            <Button
              className={classes.controlButton}
              color="primary"
              onClick={edit}
              title={t('microHubPlanEdit')}
              variant="contained"
            >
              <EditHubButton className={classes.controlButtonIcon}/>&nbsp;{t('microHubPlanEdit')}
            </Button>
            <Button
              className={classes.controlButton}
              color="primary"
              onClick={copyPlanToDays}
              title={t('microHubPlanCopyToDays')}
              variant="contained"
            >
              <CopyPlanToDaysIcon className={classes.controlButtonIcon}/>&nbsp;{t('microHubPlanCopyToDays')}
            </Button>
            <Button
              className={classNames(classes.controlButton, classes.deleteButton)}
              color="primary"
              onClick={deleteMicroHubPlan}
              title={t('microHubPlanDelete')}
              variant="contained"
            >
              <DeleteHubPlanIcon className={classes.controlButtonIcon}/>&nbsp;{t('microHubPlanDelete')}
            </Button>
            <Button
              className={classes.controlButton}
              color="secondary"
              onClick={downLoadPlan}
              title={t('microHubPlanDownload')}
              variant="contained"
            >
              <DownloadIcon className={classes.controlButtonIcon}/>&nbsp;{t('microHubPlanDownload')}
            </Button>
            <Button
              className={classes.controlButton}
              color="secondary"
              onClick={autoCenter}
              title={t('autoCenter')}
              variant="contained"
            >
              <AutoCenterIcon className={classes.controlButtonIcon}/>&nbsp;{t('autoCenter')}
            </Button>
          </Fragment>
      }
    </div>
  );

}

MicroHubPlanViewControls.propTypes = {
  autoCenter: PropTypes.func.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  copyPlanToDays: PropTypes.func.isRequired,
  createMicroHubPlan: PropTypes.func.isRequired,
  deleteMicroHubPlan: PropTypes.func.isRequired,
  downLoadPlan: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  noHubPlanAvailable: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(MicroHubPlanViewControls);
