import {useEffect, useState} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {ClientService} from '../services/client/clientService';
import {clientTypes} from '../services/client/clientTypes';
import {DriverQueryService} from 'services/backend/driverQueryService';


const clientDriver = ClientService.getClient(clientTypes.driver);

export default function useCarriersMicroHubsDrivers(carrier = '') {

  // shape of {driver@c1.com: "Bom (SuperCarrier)"}
  const [driverNames, setDriverNames] = useState({});
  const [microHubOptions, setMicroHubOptions] = useState([]);
  // shape of {superCarrier: {KADowntown: ["driver@c1.com", "driver@c2.com"...],...},...}
  const [carriersMicroHubsDrivers, setCarriersMicroHubsDrivers] = useState({});

  const queryDrivers = DriverQueryService.getDriversByCarrierNameAndMicroHubListQuery(carrier, [])
  const {data} = useQuery(queryDrivers, {client: clientDriver});

  useEffect(() => {
    if (!data?.getDriversByCarrierNameAndMicroHubList?.length) return;

    const carriers = {};
    const microhubs = [];
    const drivers = {};

    data.getDriversByCarrierNameAndMicroHubList.forEach(driver => {
      drivers[driver.email] = `${driver.displayName} (${driver.carrierName})`;
      let carrier = driver.carrierName
      if (!(carrier in carriers)) {
        carriers[carrier] = {}
      }
      driver.microhubs.forEach(microHub => {
        if (!(microHub.name in carriers[carrier])) {
          carriers[carrier][microHub.name] = []
        }
        carriers[carrier][microHub.name].push(driver.email)
        if (!microhubs.includes(microHub.name)) {
          microhubs.push(microHub.name)
        }
      });
    });

    setDriverNames(drivers);
    setMicroHubOptions(microhubs);
    setCarriersMicroHubsDrivers(carriers);
  }, [data]);


  return {driverNames, microHubOptions, carriersMicroHubsDrivers};
}
