import React from 'react'
import {Table, TableBody, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {
  Pagination,
  Portlet,
  PortletContent,
  TableCellWithConfig,
  TableSettings,
  TextPlaceholderWrapper,
} from '../../../components'
import useTablePagination from '../../../hooks/useTablePagination';
import useTableConfig from '../../../hooks/useTableConfig';
import DefaultConfig from './config';
import {getTableKey} from '../../../services/util/tableConfigHelper';
import {TableKind} from '../../../services/enums/TableKind';
import Columns from './columns';
import {displayModes} from '../../../services/enums/displayModes';

function TimeDispositionTourTable(props) {

  const {
    classes,
    className,
    tours,
    t,
    showDetails,
    approveTour,
    selectDriver,
    selectVehicle,
    selectTourStart,
    selectedTour
  } = props;

  const {
    config,
    columnConfigs,
    updateConfig
  } = useTableConfig(DefaultConfig, displayModes.carrier, getTableKey(TableKind.TimeDispositionTourTable, displayModes.carrier))

  const {paginationProps, pagedObjects} = useTablePagination(tours);

  const rootClassName = classNames(classes.root, className);

  return (
    <TextPlaceholderWrapper
      active={!tours?.length}
      text={t('noToursAvailable')}
    >
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <TableSettings
            updateConfig={updateConfig}
            tableConfig={config}
            columns={Columns}
          />
          <Table size="medium">
            <TableHead>
              <TableRow>
                {columnConfigs.map(columnConfig => (
                  <TableCellWithConfig
                    key={columnConfig.order}
                    config={columnConfig}
                  >
                    {Columns[columnConfig.columnName].header({t})}
                  </TableCellWithConfig>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedObjects.map(tour => (
                <TableRow
                  className={classNames(classes.tableRow, 'cursorPointer')}
                  hover
                  key={tour.tourId}
                  onClick={() => showDetails(tour)}
                  selected={selectedTour && selectedTour.tourId === tour.tourId}
                  style={{boxShadow: `inset 10px 0px 0px 0px ${tour.color}`}}
                >
                  {columnConfigs.map(columnConfig => (
                    <TableCellWithConfig
                      key={columnConfig.order}
                      config={columnConfig}
                      className={classes.tableCell}
                    >
                      {Columns[columnConfig.columnName].body({
                        tour,
                        classes,
                        t,
                        selectDriver,
                        selectVehicle,
                        approveTour,
                        selectTourStart
                      })}
                    </TableCellWithConfig>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination paginationProps={paginationProps}/>
        </PortletContent>
      </Portlet>
    </TextPlaceholderWrapper>
  );
}


TimeDispositionTourTable.propTypes = {
  approveTour: PropTypes.func,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  selectDriver: PropTypes.func,
  selectTourStart: PropTypes.func,
  selectVehicle: PropTypes.func,
  selectedTour: PropTypes.object,
  showDetails: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  tours: PropTypes.array.isRequired
};

TimeDispositionTourTable.defaultProps = {
  tours: [],
};


export default compose(withStyles(styles), withTranslation())(TimeDispositionTourTable);
