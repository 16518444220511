import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tooltip, withStyles} from '@material-ui/core';
import styles from './styles';
import {LoadingWrapper, Portlet, PortletContent, PortletHeader, PortletLabel, TextPlaceholderWrapper} from '../../../components';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import {Error as ProblemNotificationIcon, GetApp as ExcelDownloadIcon} from '@material-ui/icons';
import {DateService} from '../../../services/util/DateService';
import {getProblemNotificationTypeString} from '../../../services/enums/ProblemNotificationType';
import {displayModes} from '../../../services/enums/displayModes';
import {TourService} from '../../../services/backend/tourService';
import {AuthService} from '../../../services/auth/authService';
import FileSaver from 'file-saver';

function ProblemNotificationCard(props) {

  const {t, classes, className, isLoading, problems, displayMode} = props;

  const rootClassName = classNames(classes.root, className);

  const getCarrierString = (problem) => {
    if (displayMode === displayModes.carrier) return '';
    return ` - ${problem.carrierName}`
  }

  const downloadStopExcel = async (problem) => {
    const shipperName = displayMode === displayModes.shipper ? AuthService.getUserOrganization() : null;
    try {
      const response = await TourService.getByProblemAffectedStopsExcel(shipperName, problem.id);
      if (response.status === 204) {
        alert(t('noStopDataAvailable'));
        return;
      }
      let filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
      FileSaver.saveAs(response.data, filename)
    } catch (e) {
      alert(`${t('errorDownloadingExcel')}: ${e}`)
    }
  }

  return (
    <Portlet
      className={rootClassName}
    >
      <PortletHeader className={classes.portletHeader}>
        <PortletLabel title={t('Problemmeldungen')}/>
      </PortletHeader>
      <PortletContent
        className={classes.portletContentStyle}
        noPadding
      >
        <LoadingWrapper loading={isLoading}>
          <TextPlaceholderWrapper
            active={!problems?.length}
            text={t('noProblemNotificationsAvailableDashboard')}
          >
            <div className={classes.contentContainer}>
              <List>
                {problems.map((problem, index) => {
                  return (
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <Avatar className={classes.listAvatarError}>
                          <ProblemNotificationIcon/>
                        </Avatar>
                      </ListItemAvatar>
                      <Tooltip title={problem.userEmail ? problem.userEmail : ''}>
                        <ListItemText
                          primary={`[${DateService.displayDateTime(problem.deliveryDate)}${getCarrierString(problem)}]: ${t(getProblemNotificationTypeString(problem.problemNotificationType))} - ${problem.identifier}`}
                          secondary={problem.message}
                        />
                      </Tooltip>
                      <IconButton
                        color="secondary"
                        onClick={() => downloadStopExcel(problem)}
                        title={t('downloadAffectedStoppsExcel')}
                      >
                        <ExcelDownloadIcon/>
                      </IconButton>
                    </ListItem>
                  );
                })}
              </List>
            </div>
          </TextPlaceholderWrapper>
        </LoadingWrapper>
      </PortletContent>
    </Portlet>
  );
}

ProblemNotificationCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  isLoading: PropTypes.bool.isRequired,
  problems: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired
};

export default compose(withStyles(styles), withTranslation())(ProblemNotificationCard);
