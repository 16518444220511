import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import {
  InfoIcon,
  LoadingWrapper,
  Portlet,
  PortletContent,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  TextPlaceholderWrapper,
  TrendNumber
} from '../../../components';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import moment from 'moment';
import {ArrowBack, ArrowForward, Functions as SumIcon} from '@material-ui/icons';
import {DateService} from '../../../services/util/DateService';
import {roundCapacityValues} from '../../../services/util/helperFuncs';


function KPICard(props) {

  const {
    t,
    classes,
    className,
    stopsPerHourReference,
    stopsPerHourSelectedDate,
    tourPackTimeReference,
    tourPackTimeSelectedDate,
    setSelectedDate,
    selectedDate,
    isLoading,
    referenceTimeSpan
  } = props;

  const rootClassName = classNames(classes.root, className);

  const microHubKpis = {};
  const overallKpis = {
    tourPackTime: {
      forDate: 0,
      reference: 0,
    },
    stopPerHour: {
      forDate: 0,
      reference: 0,
    }
  };
  if (stopsPerHourSelectedDate) {
    Object.getOwnPropertyNames(stopsPerHourSelectedDate).forEach(hub => {
      if (!microHubKpis[hub]) {
        microHubKpis[hub] = {};
      }
      microHubKpis[hub].stopPerHour = {
        forDate: stopsPerHourSelectedDate[hub],
        reference: 0,
      };
      overallKpis.stopPerHour.forDate += stopsPerHourSelectedDate[hub]
    });
  }
  if (tourPackTimeSelectedDate) {
    Object.getOwnPropertyNames(tourPackTimeSelectedDate).forEach(hub => {
      if (!microHubKpis[hub]) {
        microHubKpis[hub] = {};
      }
      microHubKpis[hub].tourPackTime = {
        forDate: tourPackTimeSelectedDate[hub],
        reference: 0,
      };
      overallKpis.tourPackTime.forDate += tourPackTimeSelectedDate[hub]
    });
  }
  if (stopsPerHourReference) {
    Object.getOwnPropertyNames(stopsPerHourReference).forEach(hub => {
      if (microHubKpis[hub] && microHubKpis[hub].stopPerHour) {
        microHubKpis[hub].stopPerHour.reference = stopsPerHourReference[hub];
        overallKpis.stopPerHour.reference += stopsPerHourReference[hub];
      }
    });
  }
  if (tourPackTimeReference) {
    Object.getOwnPropertyNames(tourPackTimeReference).forEach(hub => {
      if (microHubKpis[hub] && microHubKpis[hub].tourPackTime) {
        microHubKpis[hub].tourPackTime.reference = tourPackTimeReference[hub];
        overallKpis.tourPackTime.reference += tourPackTimeReference[hub];
      }
    });
  }

  return (
    <Portlet
      className={rootClassName}
    >
      <PortletHeader className={classes.portletHeader}>
        <PortletLabel title={t('dashboardKPI')}/>
        <PortletToolbar>
          <IconButton
            onClick={() => setSelectedDate(moment(selectedDate).subtract(1, 'day').toDate())}
            variant="text"
          >
            <ArrowBack/>
          </IconButton>
          <Tooltip title={`${t('referenceTimeSpan')}: ${referenceTimeSpan && DateService.displayDate(referenceTimeSpan.from)} - ${referenceTimeSpan && DateService.displayDate(referenceTimeSpan.to)}`}>
            <div>
              {DateService.displayDate(selectedDate)}
            </div>
          </Tooltip>
          <IconButton
            onClick={() => setSelectedDate(moment(selectedDate).add(1, 'day').toDate())}
            variant="text"
          >
            <ArrowForward/>
          </IconButton>
        </PortletToolbar>
        <InfoIcon descriptionKey={'20_preFormat'}/>
      </PortletHeader>
      <PortletContent
        className={classes.portletContent}
        noPadding
      >
        <LoadingWrapper loading={isLoading}>
          <TextPlaceholderWrapper
            active={!microHubKpis || Object.getOwnPropertyNames(microHubKpis).length <= 0}
            text={t('noKpiDataAvailableDashboard')}
          >
            <Table size={Object.getOwnPropertyNames(microHubKpis).length > 3 ? 'small' : 'medium'}>
              <TableHead>
                <TableRow>
                  <TableCell/>
                  <TableCell>
                    <Typography variant={'h6'}>
                      <strong>{t('kpiStopsPerHour')}</strong>
                      <br/>
                      {t('kpiStopsPerHourUnit')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant={'h6'}>
                      <strong>{t('kpiTourPackTime')}</strong>
                      <br/>
                      {t('kpiTourPackTimeUnit')}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow hover>
                  <TableCell>
                    <SumIcon/>
                  </TableCell>
                  <TableCell>
                    <TrendNumber
                      decimalPlaces={2}
                      number={roundCapacityValues(overallKpis.stopPerHour.forDate)}
                      reference={roundCapacityValues(overallKpis.stopPerHour.reference)}
                      variant={'h6'}
                    />
                  </TableCell>
                  <TableCell>
                    <TrendNumber
                      inverseTrendMeaning
                      number={Math.round(overallKpis.tourPackTime.forDate)}
                      reference={Math.round(overallKpis.tourPackTime.reference)}
                      variant={'h6'}
                    />
                  </TableCell>
                </TableRow>
                {Object.getOwnPropertyNames(microHubKpis).map((hub, index) => {
                  return (
                    <TableRow
                      hover
                      key={index}
                    >
                      <TableCell>
                        <Typography>
                          <strong>{hub}</strong>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <TrendNumber
                          decimalPlaces={2}
                          number={microHubKpis[hub].stopPerHour ? roundCapacityValues(microHubKpis[hub].stopPerHour.forDate) : undefined}
                          reference={microHubKpis[hub].stopPerHour ? roundCapacityValues(microHubKpis[hub].stopPerHour.reference) : undefined}
                          variant={'body1'}
                        />
                      </TableCell>
                      <TableCell>
                        <TrendNumber
                          inverseTrendMeaning
                          number={microHubKpis[hub].tourPackTime ? Math.round(microHubKpis[hub].tourPackTime.forDate) : undefined}
                          reference={microHubKpis[hub].tourPackTime ? Math.round(microHubKpis[hub].tourPackTime.reference) : undefined}
                          variant={'body1'}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TextPlaceholderWrapper>
        </LoadingWrapper>
      </PortletContent>
    </Portlet>
  );
}

KPICard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  referenceTimeSpan: PropTypes.object.isRequired,
  selectedDate: PropTypes.object.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  stopsPerHourReference: PropTypes.object,
  stopsPerHourSelectedDate: PropTypes.object,
  t: PropTypes.func.isRequired,
  tourPackTimeReference: PropTypes.object,
  tourPackTimeSelectedDate: PropTypes.object,
};

export default compose(withStyles(styles), withTranslation())(KPICard);
