import React from 'react'
import {TableCell, TableRow, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classNames from 'classnames'
import {roundCapacityValues} from '../../../../../../services/util/helperFuncs';
import {StopIdLabel} from '../../../../../index';


function StopTableRow(props) {

  const {classes, className, t, stop} = props;
  const rootClassName = classNames(classes.root, className);

  return (
    <TableRow
      className={rootClassName}
      hover
    >
      <TableCell className={classes.tableCell}>
        <Typography variant="body1"><strong>{t('tourStopId')}: <StopIdLabel stop={stop}/></strong></Typography>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <Typography variant="body1">{`${roundCapacityValues(stop.stopCapacities ? stop.stopCapacities.boxAmount : 0)} [${t('boxes')}]`}</Typography>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <Typography variant="body1">{`${roundCapacityValues(stop.stopCapacities ? stop.stopCapacities.weight : 0)} [kg]`}</Typography>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <Typography variant="body1">{`${roundCapacityValues(stop.stopCapacities ? stop.stopCapacities.volume : 0)} [l]`}</Typography>
      </TableCell>
    </TableRow>
  );
}

StopTableRow.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  stop: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(StopTableRow);
