import {compose} from 'recompose';
import {Tab, Tabs, withStyles} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import styles from './styles'
import classNames from 'classnames';
import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {HubDistributionMap, InfoIcon, PreviewTourMap} from '../../../index';

const previewMapTabState = {
  microHubAssignment: 0,
  previewTours: 1,
}

const PreviewMap = props => {

  const {classes, className, t, hubsWithToursAndStops, unassignedStops, microHubs, persistedTabState, setPersistedTabState} = props;
  const [tabState, setTabState] = useState(previewMapTabState.microHubAssignment);

  const displayMicroHubAssignment = persistedTabState !== undefined ? persistedTabState === previewMapTabState.microHubAssignment : tabState === previewMapTabState.microHubAssignment;

  const rootClassName = classNames(classes.root, className);

  const stopsOnHubsWithoutTour = hubsWithToursAndStops.map(hub => hub.stopsWithoutTour).flat();

  return (
    <Fragment>
      <div className={classes.legendControl}>
        <div className={classes.tabCheckbox}>
          <Tabs
            onChange={(event, newValue) => {
              if (setPersistedTabState) setPersistedTabState(newValue);
              setTabState(newValue);
            }}
            value={persistedTabState !== undefined ? persistedTabState : tabState}
          >
            <Tab
              icon={<InfoIcon
                className={classes.infoTab}
                descriptionKey={'50'}
              />}
              label={t('hubOverview')}
            />
            <Tab
              icon={<InfoIcon
                className={classes.infoTab}
                descriptionKey={'60'}
              />}
              label={t('tourOverview')}
            />
          </Tabs>
        </div>
      </div>
      {displayMicroHubAssignment ?
        <HubDistributionMap
          className={rootClassName}
          hubsWithToursAndStops={hubsWithToursAndStops}
          microHubs={microHubs}
          unassignedStops={unassignedStops}
        />
        :
        <PreviewTourMap
          className={rootClassName}
          microHubs={microHubs}
          setSelectedStop={() => {
          }}
          stopsWithoutTour={stopsOnHubsWithoutTour}
          tours={hubsWithToursAndStops.map(hub => hub.tours).flat()}
        />
      }
    </Fragment>
  );
};

PreviewMap.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  hubsWithToursAndStops: PropTypes.array,
  microHubs: PropTypes.array,
  persistedTabState: PropTypes.number,
  setPersistedTabState: PropTypes.func,
  t: PropTypes.func.isRequired,
  unassignedStops: PropTypes.array,
};


export default compose(withStyles(styles), withTranslation())(PreviewMap);

