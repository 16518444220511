export default theme => ({
  box: {
    background: theme.palette.primary.main,
    minHeight: '30vh',
  },
  header: {
    color: theme.palette.background.paper,
    textAlign: 'center',
    fontSize: '4em',
    paddingTop: '1.2em',
    paddingBottom: '1em',
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});
