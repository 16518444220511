import {useState} from 'react'
import {presetsService} from '../../../../services/presets/presetService';
import {ProductType} from '../../../../services/enums/ProductType';

export const StopEditTableItemKinds = {
  products: 'products',
  packageInserts: 'packageInserts',
  empties: 'empties',
};

export function useStopEditTableItems(editedStop) {

  const [existingItems, setExistingItems] = useState({
    products: editedStop.products,
    packageInserts: editedStop.packageInserts,
    empties: editedStop.empties
  });

  const [newItems, setNewItems] = useState({
    products: [],
    packageInserts: [],
    empties: []
  });

  const reset = () => {
    setNewItems({
      products: [],
      packageInserts: [],
      empties: []
    });
    setExistingItems({
      products: editedStop.products,
      packageInserts: editedStop.packageInserts,
      empties: editedStop.empties
    });
  }

  const resetEmptiesItems = () => {
    setNewItems({
      ...newItems,
      empties: []
    });
    setExistingItems({
      ...existingItems,
      empties: editedStop.empties
    });
  }

  // Works only for newItems!!
  const updateItem = (tempUuid, targetObj, targetProp, targetValue) => {
    const targetItems = JSON.parse(JSON.stringify(newItems[targetObj]));
    const targetIndex = targetItems.findIndex(obj => obj.tempId === tempUuid);
    if (targetIndex < 0) {
      console.warn('tried to update not existing item');
      return;
    }
    targetItems[targetIndex] = {...targetItems[targetIndex], [targetProp]: targetValue};
    setNewItems({...newItems, [targetObj]: targetItems});
  }


  // Works for newItems and existingItems
  const deleteItem = (item, targetObj) => {
    if (item.tempId) {
      const tmpUuid = item.tempId;
      const targetItems = JSON.parse(JSON.stringify(newItems[targetObj]));
      const targetIndex = targetItems.findIndex(obj => obj.tempId === tmpUuid);
      if (targetIndex >= 0) {
        targetItems.splice(targetIndex, 1);
      }
      setNewItems({...newItems, [targetObj]: targetItems});
    } else {
      const id = item.id;
      const targetItems = JSON.parse(JSON.stringify(existingItems[targetObj]));
      const targetIndex = targetItems.findIndex(obj => obj.id === id);
      if (targetIndex >= 0) {
        targetItems.splice(targetIndex, 1);
      }
      setExistingItems({...existingItems, [targetObj]: targetItems});
    }
  }

  const addProduct = () => {
    setNewItems({
      ...newItems,
      products: [...newItems.products, presetsService.getProductPreset()]
    });
  }

  const addPackageInsert = () => {
    setNewItems({
      ...newItems,
      packageInserts: [...newItems.packageInserts, presetsService.getPackageInsertPreset()]
    });
  }

  const addEmpty = () => {
    setNewItems({
      ...newItems,
      empties: [...newItems.empties, presetsService.getEmptyPreset()]
    });
  };


  const areProductsValid = () => {
    // only needs to check newItems
    const isProductValid = (product) => {
      return (
        (product.productType === ProductType.Box && (product.boxType && product.boxId && product.amount > 0 && product.volume > 0 && product.weight > 0))
        || (product.productType === ProductType.BeverageBox && (product.boxType && product.description && product.amount > 0 && product.volume > 0 && product.weight > 0))
        || (product.productType === ProductType.Other && (product.description && product.amount > 0 && product.volume > 0 && product.weight > 0))
      )
    }
    return !newItems.products.some(product => !isProductValid(product));
  }

  const arePackageInsertsValid = () => {
    // only needs to check newItems
    const isPackageInsertValid = (packageInsert) => {
      return (
        packageInsert.description && packageInsert.amount > 0 && packageInsert.weight > 0
      )
    }
    return !newItems.packageInserts.some(packageInsert => !isPackageInsertValid(packageInsert));
  };

  const areEmptiesValid = () => {
    // only needs to check newItems
    const isEmptyValid = (empty) => {
      return (
        empty.amount > 0 && empty.name
      )
    }
    return !newItems.empties.some(empty => !isEmptyValid(empty));
  }

  const areAllValid = () => {
    return areProductsValid() && arePackageInsertsValid() && areEmptiesValid();
  }

  return {
    existingProducts: existingItems.products,
    existingPackageInserts: existingItems.packageInserts,
    existingEmpties: existingItems.empties,
    newProducts: newItems.products,
    newPackageInserts: newItems.packageInserts,
    newEmpties: newItems.empties,
    resetItems: reset,
    resetEmptiesItems: resetEmptiesItems,
    areAllValid,
    areProductsValid,
    arePackageInsertsValid,
    areEmptiesValid,
    addEmpty,
    addProduct,
    addPackageInsert,
    updateProduct: (tempUuid, targetProp, targetValue) => updateItem(tempUuid, StopEditTableItemKinds.products, targetProp, targetValue),
    updatePackageInsert: (tempUuid, targetProp, targetValue) => updateItem(tempUuid, StopEditTableItemKinds.packageInserts, targetProp, targetValue),
    updateEmpty: (tempUuid, targetProp, targetValue) => updateItem(tempUuid, StopEditTableItemKinds.empties, targetProp, targetValue),
    deleteProduct: (tempUuid) => deleteItem(tempUuid, StopEditTableItemKinds.products),
    deletePackageInsert: (tempUuid) => deleteItem(tempUuid, StopEditTableItemKinds.packageInserts),
    deleteEmpty: (tempUuid) => deleteItem(tempUuid, StopEditTableItemKinds.empties),
  }
}
