import {customerIconColor, IconService, notPlanedIconColor} from '../../../../../services/icon/iconService';
import {markerTypes} from '../../../../../services/util/mapUtils';
import {getColorByStatus} from '../../../../../services/enums/StopStatus';
import {getColorByType} from '../../../../../services/enums/TemplateStopType';

const getForStopMap = (overlappingElements, highLiteDuplicates) => {
  if (highLiteDuplicates) return IconService.getMarkerClusterIcon('#ff0000', '+');
  const colors = overlappingElements.map(e => getColorByStatus(e.stop.stopStatus));
  return IconService.getOverlappingMarkersIcon(colors, overlappingElements.length);
};

const getForTemplateStopMap = (overlappingElements, highLiteDuplicates) => {
  if (highLiteDuplicates) return IconService.getMarkerClusterIcon('#ff0000', '+');
  const colors = overlappingElements.map(e => getColorByType(e.stop.stopType));
  return IconService.getOverlappingMarkersIcon(colors, overlappingElements.length);
};
const getForHubDistributionMap = (overlappingElements, highLiteDuplicates, stopToColorFunc) => {
  if (highLiteDuplicates) return IconService.getMarkerClusterIcon('#ff0000', '+');
  const colors = overlappingElements.map(e => stopToColorFunc(e.stop));
  return IconService.getOverlappingMarkersIcon(colors, overlappingElements.length);
};

const getForAddCustomerToTemplateMap = (overlappingElements, highLiteDuplicates, stopToColorFunc) => {
  if (highLiteDuplicates) return IconService.getMarkerClusterIcon('#ff0000', '+');
  let colors = [];
  if (overlappingElements.some(e => e.type === markerTypes.toAddStop)) colors.push(stopToColorFunc());
  if (overlappingElements.some(e => e.type === markerTypes.stop)) colors.push(customerIconColor);
  else if (!colors.length) colors = overlappingElements.map(e => e.tour.color);
  return IconService.getOverlappingMarkersIcon(colors, overlappingElements.length);
};

const getForAddStopToTourMap = (overlappingElements, highLiteDuplicates, stopToColorFunc) => {
  if (highLiteDuplicates) return IconService.getMarkerClusterIcon('#ff0000', '+');
  let colors = [];
  if (overlappingElements.some(e => e.type === markerTypes.toAddStop)) colors.push(stopToColorFunc());
  if (overlappingElements.some(e => e.type === markerTypes.stop)) colors.push(notPlanedIconColor);
  else if (!colors.length) colors = overlappingElements.sort().map(e => e.tour.color);
  return IconService.getOverlappingMarkersIcon(colors, overlappingElements.length);
};

const getForTourMap = (overlappingElements, highLiteDuplicates) => {
  if (highLiteDuplicates) return IconService.getMarkerClusterIcon('#ff0000', '+');
  let colors = [];
  if (overlappingElements.some(e => e.type === markerTypes.stop)) colors = [notPlanedIconColor];
  else colors = overlappingElements.map(e => e.tour.color);
  return IconService.getOverlappingMarkersIcon(colors, overlappingElements.length);
};

const getForTemplateMap = (overlappingElements, highLiteDuplicates) => {
  if (highLiteDuplicates) return IconService.getMarkerClusterIcon('#ff0000', '+');
  let colors = [];
  colors = overlappingElements.filter(e => e.type === markerTypes.tour).map(e => e.tour.color);
  if (overlappingElements.some(e => e.type === markerTypes.stop)) colors = [customerIconColor].concat(colors);
  return IconService.getOverlappingMarkersIcon(colors, overlappingElements.length);
};

const getForTourWithStopStatusMap = (overlappingElements, highLiteDuplicates, overrideNotPlanedDefaultColor = false) => {
  if (highLiteDuplicates) return IconService.getMarkerClusterIcon('#ff0000', '+');
  let colors = [];
  if (overlappingElements.some(e => e.type === markerTypes.stop) && !overrideNotPlanedDefaultColor) colors = [notPlanedIconColor];
  else colors = overlappingElements.map(e => getColorByStatus(e.stop.stopStatus));
  return IconService.getOverlappingMarkersIcon(colors, overlappingElements.length);
};

const getForTourWithStopTypeMap = (overlappingElements, highLiteDuplicates, overrideNotPlanedDefaultColor = false) => {
  if (highLiteDuplicates) return IconService.getMarkerClusterIcon('#ff0000', '+');
  let colors = [];
  if (overlappingElements.some(e => e.type === markerTypes.stop) && !overrideNotPlanedDefaultColor) colors = [notPlanedIconColor];
  else colors = overlappingElements.map(e => getColorByType(e.stop.stopType));
  return IconService.getOverlappingMarkersIcon(colors, overlappingElements.length);
};

export default {
  getForTourWithStopStatusMap,
  getForTemplateMap,
  getForTourMap,
  getForAddStopToTourMap,
  getForAddCustomerToTemplateMap,
  getForHubDistributionMap,
  getForStopMap,
  getForTemplateStopMap,
  getForTourWithStopTypeMap
}
