import React, {useCallback, useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import useShipperOptions from 'hooks/useShipperOptions';
import tourTemplatesService from 'services/backend/tourTemplatesService';
import customersService from 'services/backend/customersService';
import {LoadingWrapper, TextPlaceholderWrapper, TourTemplatesView} from 'components';
import {getDayNumberByDayOfWeek} from 'services/enums/dayOfWeekHelper';
import {displayModes} from 'services/enums/displayModes';
import useCarriersWithHubs from 'hooks/useCarriersWithHubs';

// This is a modified TourTemplate view to easily work with templateStops
// This View will only work as admin, since it requires loading templates and Customers without the carrier filter
function TemplateStopTourTemplatesOverview(props) {
  const { classes, t, filter, displayMode } = props;

  const [tourTemplates, setTourTemplates] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [backendLoadingTourTemplates, setBackendLoadingTourTemplates] = useState(false);
  const [backendLoadingCustomers, setBackendLoadingCustomers] = useState(false);
  const { shipperOptions } = useShipperOptions();
  const { carriersWithHubs } = useCarriersWithHubs(filter.carrierName);

  const loadTemplates = useCallback(async (withLoadingIndicator = true) => {
    if (withLoadingIndicator) setBackendLoadingTourTemplates(true);
    try {
      const templates = await tourTemplatesService.getTourTemplatesByFilterObject({
        dayOfWeek: getDayNumberByDayOfWeek(filter.dayOfWeek),
        shipperName: filter.shipperName,
        carrierName: filter.carrierName,
        microHubName: filter.microHubName
      });
      setTourTemplates(templates);
      if (withLoadingIndicator) setBackendLoadingTourTemplates(false);
    } catch (error) {
      alert(`${t('errorLoadingTourTemplates')}, ${error}`);
      if (withLoadingIndicator) setBackendLoadingTourTemplates(false);
    }
  }, [filter, t]);

  const loadCustomers = useCallback(async () => {
    setBackendLoadingCustomers(true);
    try {
      const customers = await customersService.getCustomersByFilter(filter.shipperName, '', '', getDayNumberByDayOfWeek(filter.dayOfWeek));
      setCustomers(customers);
      setBackendLoadingCustomers(false);
    } catch (e) {
      alert(`${t('errorLoadingCustomers')}, ${e}`);
      setBackendLoadingCustomers(false);
    }
  }, [filter, t]);

  useEffect(() => {
    if (!filter.dayOfWeek || !filter.shipperName) return;
    loadTemplates();
    loadCustomers();
  }, [loadTemplates, loadCustomers, filter]);

  return (
    <div>
      <div className={classes.root}>
        <LoadingWrapper loading={backendLoadingTourTemplates || backendLoadingCustomers}>
          <TextPlaceholderWrapper
            active={!filter.dayOfWeek || !filter.shipperName}
            text={t('tourTemplatesNoWeekDayAndShipperSelected')}
          >
            <TourTemplatesView
              carrierWithMicroHubs={carriersWithHubs}
              customers={customers}
              displayMode={displayMode}
              filter={filter}
              microHubs={[]}
              reloadTourTemplates={loadTemplates}
              setTourTemplates={setTourTemplates}
              shipperOptions={shipperOptions}
              tourTemplates={tourTemplates}
              vehicles={[]}
              templateStopMode={true}
            />
          </TextPlaceholderWrapper>
        </LoadingWrapper>
      </div>
    </div>
  );
}

TemplateStopTourTemplatesOverview.propTypes = {
  filter: PropTypes.object.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  classes: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(TemplateStopTourTemplatesOverview);
