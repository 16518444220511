import Columns from './columns';
import {displayModes} from '../../../../../services/enums/displayModes';

// CARE: ALWAYS INCREMENT THE VERSION IF THE SETTINGS FOR A COLUMN CHANGE!
// Config explanation
// optional: determines if the hidden status can be changed by the user
// hidden: determines if the field is shown or hidden
// conditional: indicates that the hidden status can be overriden using the conditions dictionary of the useTableConfig hook
const DefaultConfig = {
  [displayModes.admin]: {
    version: 1,
    columns: {
      tourInstanceId: {
        columnName: Columns.tourInstanceId.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 1,
      },
      pcgTourId: {
        columnName: Columns.pcgTourId.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 2
      },
      packingDate: {
        columnName: Columns.packingDate.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 3
      },
      deliveryDate: {
        columnName: Columns.deliveryDate.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 4
      },
      descriptionInternal: {
        columnName: Columns.descriptionInternal.name,
        optional: true,
        hidden: false,
        conditional: false,
        order: 5
      },
      note: {
        columnName: Columns.note.name,
        optional: true,
        hidden: true,
        conditional: false,
        order: 6
      },
      numCustomers: {
        columnName: Columns.numCustomers.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 7
      },
      numOrders: {
        columnName: Columns.numOrders.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 8
      },
      import: {
        columnName: Columns.import.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 9
      },
    }
  },
  [displayModes.carrier]: {
    version: 1,
    columns: {}
  },
  [displayModes.shipper]: {
    version: 1,
    columns: {}
  },
}

export default DefaultConfig;