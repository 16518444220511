import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {IconButton, Table, TableBody, TableCell, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import classNames from 'classnames';
import {
  InfoIcon,
  LoadingWrapper,
  Pagination,
  Portlet,
  PortletContent,
  PortletHeader,
  PortletLabel,
  TextPlaceholderWrapper
} from '../../index';
import React, {Fragment, useCallback, useEffect, useState} from 'react';
import moment from 'moment';
import PortletToolbar from '../../PortletToolbar';
import {ArrowBack, ArrowForward,} from '@material-ui/icons';
import {DateService} from '../../../services/util/DateService';
import DeclinedStopsDialog from './components/DeclinedStopsDialog';
import {DeclinedStopsTableRow} from './components';
import {displayModes} from '../../../services/enums/displayModes';
import shipperDeclinedStopsService from '../../../services/backend/shipperDeclinedStopsService';
import useTablePagination from '../../../hooks/useTablePagination';


function DeclinedStopsCard(props) {

  const {
    t,
    classes,
    className,
    shipperShortNames,
    displayMode,
    shipperName
  } = props;
  const [popoutDialogState, setPopoutDialogState] = useState({open: false, source: ''});

  const [selectedDate, setSelectedDate] = useState(moment().startOf('day').toDate());
  const [declinedStops, setDeclinedStops] = useState([]);
  const [backendLoadingDeclinedStops, setBackendLoadingDeclinedStops] = useState(false);

  const {paginationProps, pagedObjects} = useTablePagination(declinedStops);

  const handleClick = (declinedStop) => {
    setPopoutDialogState({...popoutDialogState, open: true, source: declinedStop.stopSource})
  };

  const loadDeclinedStopsData = useCallback(async () => {
    setBackendLoadingDeclinedStops(true);
    try {
      const result = await shipperDeclinedStopsService.getDeclinedStopsByFilter(shipperName, moment(selectedDate).toDate());
      const sortedResult = result.sort((a, b) => new Date(a.declinedTime).getTime() - new Date(b.declinedTime).getTime()).reverse();
      setDeclinedStops(sortedResult)
      setBackendLoadingDeclinedStops(false);
    } catch (e) {
      console.warn(t('errorLoadingDeclinedStopsData'), e);
      setBackendLoadingDeclinedStops(false);
    }
  }, [shipperName, selectedDate, t])

  useEffect(() => {
    loadDeclinedStopsData();
  }, [loadDeclinedStopsData])

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet
      className={rootClassName}
    >
      <PortletHeader className={classes.portletHeader}>
        <PortletLabel title={t('declinedStopsInfo')}/>
        <PortletToolbar>
          <IconButton
            onClick={() => setSelectedDate(moment(selectedDate).subtract(1, 'day').toDate())}
            variant="text"
          >
            <ArrowBack/>
          </IconButton>
          <div>
            {DateService.displayDate(selectedDate)}
          </div>
          <IconButton
            onClick={() => setSelectedDate(moment(selectedDate).add(1, 'day').toDate())}
            variant="text"
          >
            <ArrowForward/>
          </IconButton>
        </PortletToolbar>
        <InfoIcon descriptionKey={'30'}/>
      </PortletHeader>
      <PortletContent
        className={classes.portletContent}
        noPadding
      >
        <Fragment>
          <LoadingWrapper loading={backendLoadingDeclinedStops}>
            <TextPlaceholderWrapper
              active={!declinedStops?.length}
              text={t('noDeclinedStopsAvailable')}
            >
              <Portlet>
                <PortletContent
                  className={classes.portletContent}
                  noPadding
                >
                  <Table
                    className={classes.declinedStopsTable}
                    size="small"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('declinedTime')}</TableCell>
                        <TableCell>{t('declinedStopsId')}</TableCell>
                        {displayMode !== displayModes.shipper &&
                        <TableCell>{t('shipper')}</TableCell>
                        }
                        <TableCell>{t('customerId')}</TableCell>
                        <TableCell>{t('declinedStopsSource')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pagedObjects.map((declinedStop, index) => (
                        <DeclinedStopsTableRow
                          declinedStop={declinedStop}
                          displayMode={displayMode}
                          handleClick={handleClick}
                          key={index}
                          shipperShortNames={shipperShortNames}
                        />
                      ))}
                    </TableBody>
                  </Table>
                  <Pagination paginationProps={paginationProps}/>
                </PortletContent>
              </Portlet>
            </TextPlaceholderWrapper>
          </LoadingWrapper>
          <DeclinedStopsDialog
            dialogState={popoutDialogState}
            onClose={() => setPopoutDialogState({...popoutDialogState, open: false, source: ''})}
          />
        </Fragment>
      </PortletContent>
    </Portlet>
  );
}

DeclinedStopsCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  shipperName: PropTypes.string,
  shipperShortNames: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(DeclinedStopsCard);
