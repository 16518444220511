import React, {Fragment} from 'react';
import {Button} from '@material-ui/core';
import {DeleteForever as DeleteIcon,} from '@material-ui/icons';
import classNames from 'classnames';
import {ShipperLabel} from 'components';
import {displayAddress, displayDeliveryWindow} from 'services/util/helperFuncs';
import {getTemplateStopTypeString} from '../../../services/enums/TemplateStopType';

const Columns = {
  templateStopId: {
    name: 'templateStopId',
    header: ({ t }) => t('templateStopId'),
    body: ({ templateStop }) => templateStop?.id
  },
  shipper: {
    name: 'shipper',
    header: ({ t }) => t('shipper'),
    body: ({ templateStop }) => <ShipperLabel stop={templateStop} />
  },
  stopType: {
    name: 'stopType',
    header: ({ t }) => t('stopType'),
    body: ({templateStop, t}) => t(getTemplateStopTypeString(templateStop.stopType))
  },
  name: {
    name: 'name',
    header: ({ t }) => t('name'),
    body: ({ templateStop }) => templateStop?.name
  },
  streetAndNumber: {
    name: 'streetAndNumber',
    header: ({ t }) => t('streetAndNumber'),
    body: ({ templateStop }) => templateStop?.address.streetAndNumber
  },
  zipcode: {
    name: 'zipcode',
    header: ({ t }) => t('zipcode'),
    body: ({ templateStop }) => templateStop?.address.zipcode
  },
  address: {
    name: 'address',
    header: ({ t }) => t('address'),
    body: ({ templateStop }) => displayAddress(templateStop?.address)
  },
  deliveryWindow: {
    name: 'deliveryWindow',
    header: ({ t }) => t('deliveryWindow'),
    body: ({ templateStop }) => displayDeliveryWindow(templateStop?.deliveryWindow)
  },
  options: {
    name: 'options',
    header: ({ t }) => t('options'),
    body: ({
      templateStop,
      classes,
      t,
      deleteTemplateStop,
      setDeleteTemplateStopDialogState,
      deleteTemplateStopDialogState
    }) => <Fragment>
        {deleteTemplateStop &&
          <Button
            className={classNames(classes.tableButton, classes.deleteButton)}
            onClick={(event) => {
              event.stopPropagation();
              setDeleteTemplateStopDialogState({ ...deleteTemplateStopDialogState, open: true, templateStop: templateStop })
            }}
            title={t('stopDelete')}
          >
            <DeleteIcon />
          </Button>
        }
      </Fragment>
  }
}

export default Columns;