import React, {Fragment} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Portlet, PortletContent, PortletHeader} from '../../../components'
import {Close as CloseIcon} from '@material-ui/icons';
import {DateService} from '../../../services/util/DateService';
import {displayAddress} from '../../../services/util/helperFuncs';

function DeletedStopDetails(props) {

  const {classes, className, stop, closeDetailView, t} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet className={rootClassName}>
      <PortletHeader>
        <Fragment>
          <div><strong>{t('tourStopId')}: {stop.stopId}</strong></div>
          <div>
            <Button
              className={classes.button}
              color="default"
              onClick={closeDetailView}
              title={t('close')}
              variant="contained"
            >
              <CloseIcon/>
            </Button>
          </div>
        </Fragment>
      </PortletHeader>
      <PortletContent noPadding>
        <div className={classes.wrapper}>
          <div className={classes.wrapperChild}>
            <div className={classes.stopDetail}><strong>{t('tourStopId')}</strong>: {stop.stopId}</div>
            <div className={classes.stopDetail}><strong>{t('lastName')}</strong>: {stop.name}</div>
            <div className={classes.stopDetail}><strong>{t('customerId')}</strong>: {stop.customerId}</div>
            <div className={classes.stopDetail}><strong>{t('deliveryDate')}</strong>: {DateService.displayDate(stop.deliveryDate)}</div>
            <div className={classes.stopDetail}><strong>{t('address')}</strong>: {displayAddress(stop.address)}</div>
            <div className={classes.stopDetail}><strong>{t('shipper')}</strong>: {stop.shipperName}</div>
            <div className={classes.stopDetail}><strong>{t('carrier')}</strong>: {stop.carrierName}</div>
            <div className={classes.stopDetail}><strong>{t('microHub')}</strong>: {stop.microHubName}</div>
            <div className={classes.stopDetail}><strong>{t('previewStopIdentifier')}</strong>: {stop.previewStopIdentifier}</div>
          </div>
          <div className={classes.wrapperChild}>
            <div><strong>{t('deletedAt')}</strong>: {new Date(stop.deleteTime).toLocaleString()}</div>
            <br/>
            {stop.user &&
            <Fragment>
              <div><strong>{t('deleteUser')}</strong>:</div>
              <div className={classes.stopDetail}><strong>{t('userEmail')}</strong>: {stop.user.email ? stop.user.email : '-'}</div>
              <div className={classes.stopDetail}><strong>{t('userOrganization')}</strong>: {stop.user.organization ? stop.user.organization : '-'}</div>
              <div className={classes.stopDetail}><strong>{t('userPermission')}</strong>: {stop.user.permission ? stop.user.permission : '-'}</div>
              <div className={classes.stopDetail}><strong>{t('userRole')}</strong>: {stop.user.role ? stop.user.role : '-'}</div>
            </Fragment>
            }

          </div>
        </div>
      </PortletContent>
    </Portlet>
  );
}


DeletedStopDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  closeDetailView: PropTypes.func.isRequired,
  stop: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}
;

export default compose(withStyles(styles), withTranslation())(DeletedStopDetails);
