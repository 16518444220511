import {AuthService} from '../auth/authService';
import axios from 'axios';

const baseUri = `${process.env.REACT_APP_API_URI}preview`;

export class PreviewToursService {

  static async getMicroHubsWithToursByFilter(carrierName, shipperName, microHubList, date, frozen) {
    const response = await axios.put(`${baseUri}/previewTour/byFilter/`,
      {
        carrierName: carrierName,
        shipperName: shipperName,
        microHubList: microHubList,
        date: date ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) : null,
        frozen: frozen
      },
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        }
      }
    );
    return response.data;
  }

  static async getUnassignedStopsByFilter(carrierName, shipperName, date) {
    const response = await axios.put(`${baseUri}/previewStop/unassigned/byFilter/`,
      {
        carrierName: carrierName,
        shipperName: shipperName,
        date: date ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) : null
      },
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        }
      }
    );
    return response.data;
  }

  static changeStopAssignment(stopId, carrierName, microHubName) {
    return fetch(`${baseUri}/previewStop/changeAssignment`, {
      method: 'PUT',
      body: JSON.stringify({
        stopId: stopId,
        microHubName: microHubName,
        carrierName: carrierName
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  }

  static deleteStopById(stopId) {
    return fetch(`${baseUri}/previewStop/${stopId}`, {
      method: 'DELETE',
      body: JSON.stringify(
        {
          email: AuthService.getUserEmail(),
          organization: AuthService.getUserOrganization(),
          permission: AuthService.getUserPermission(),
          role: AuthService.getUserRole()
        }),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  }

  static freezeTour(tourId, frozen) {
    return fetch(`${baseUri}/previewTour/freeze/${tourId}/${frozen}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  }

  static changeTourOrder(newOrder) {
    return fetch(`${baseUri}/previewTour/changeOrder/`, {
      method: 'PUT',
      body: JSON.stringify(newOrder),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  }

  static changeMicroHubAssignment(newAssignment) {
    return fetch(`${baseUri}/previewTour/changeAssignment/`, {
      method: 'PUT',
      body: JSON.stringify(newAssignment),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  }

  static rerunAlgoForHub(deliveryDate, carrierName, microHubName, useTourTemplates = true, useAlgorithm = null) {
    const date = deliveryDate ? new Date(Date.UTC(deliveryDate.getFullYear(), deliveryDate.getMonth(), deliveryDate.getDate())).toISOString() : null;
    return fetch(`${baseUri}/previewTour/${date}/${carrierName}/${microHubName}`, {
      method: 'PUT',
      body: JSON.stringify({
        useTourTemplates: useTourTemplates,
        useAlgorithm: useAlgorithm
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  }

  static downloadPreviewAsExcel(shipper, microHubs, stops, date) {
    const request = {
      Shipper: shipper,
      MicroHubNames: microHubs,
      UnassignedStopIds: stops,
      DeliveryDate: date ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) : null
    };
    return fetch(`${baseUri}/previewTour/downloadExcel`, {
      method: 'POST',
      body: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  }

  static downloadPreviewAsExcelAsCarrier(carrier, shipper, microHubs, stops, date) {
    const request = {
      Carrier: carrier,
      Shipper: shipper,
      MicroHubNames: microHubs,
      UnassignedStopIds: stops,
      DeliveryDate: date ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) : null
    };
    return fetch(`${baseUri}/previewTour/carrier/downloadExcel`, {
      method: 'POST',
      body: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  }

  static getDeadlineForPreview(shipperName, date) {
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString();
    return fetch(`${baseUri}/previewTour/deadline/${shipperName}/${date}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  }

  static addFixedTours(tour, numTimesToCreate = 1) {
    const date = new Date(tour.deliveryDate);
    tour.deliveryDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    return fetch(`${baseUri}/previewTour/${numTimesToCreate}`, {
      method: 'POST',
      body: JSON.stringify(tour),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  }

  static changeStopOrderOnTour(tour, tourId) {
    return fetch(`${baseUri}/previewTour/${tourId}/changeStopOrder`, {
      method: 'PUT',
      body: JSON.stringify(tour),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    })
  }

  static moveStopsToTour(tourIdDestination, stopIds) {
    return fetch(`${baseUri}/previewTour/move/to/${tourIdDestination}`, {
      method: 'PUT',
      body: JSON.stringify(stopIds),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    })
  }

  static moveStopsToTourByClickOrder(tourIdDestination, stopIds) {
    return fetch(`${baseUri}/previewTour/move/in-click-order/to/${tourIdDestination}`, {
      method: 'PUT',
      body: JSON.stringify(stopIds),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    })
  }

  static getDeletedStopsByFilter(carrierName, shipperName, microHubName, fromDate, toDate) {
    return fetch(`${baseUri}/previewStop/deletedStops/byFilter/`, {
      method: 'PUT',
      body: JSON.stringify({
        carrierName: carrierName,
        shipperName: shipperName,
        microHubName: microHubName,
        fromDate: fromDate,
        toDate: toDate
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  }

  static deleteStopFromTourById(tourId, stopId) {
    return fetch(`${baseUri}/previewTour/${tourId}/${stopId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  }

  static deleteTourById(tourId) {
    return fetch(`${baseUri}/previewTour/${tourId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  }

  static deleteAllTours(tourIds) {
    return fetch(`${baseUri}/previewTour/toDelete`, {
      method: 'PUT',
      body: JSON.stringify(tourIds),
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  }


  static async getStopById(stopId) {
    const response = await axios.get(`${baseUri}/previewStop/byId/${stopId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        }
      });
    return response.data;
  }

  static async getPreviewStopByPreviewStopIdentifier(previewStopIdentifier) {
    const response = await axios.get(`${baseUri}/previewStop/byPreviewStopIdentifier/${previewStopIdentifier}`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        }
      });
    return response.data;
  }
}
