import React, {Fragment, useCallback, useEffect, useState} from 'react'
import {Button, Tab, Tabs, Tooltip, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {DataLoadingWrapper, InfoIcon, PortletContent, PortletHeader} from '../../../../components';
import {Close as CloseIcon, Edit as EditIcon, WarningRounded as WarnIcon} from '@material-ui/icons';
import 'moment-duration-format';
import {displayAddress, roundCapacityValues} from '../../../../services/util/helperFuncs';
import {DateService} from '../../../../services/util/DateService';
import {getDayOfWeekFromNumber, getDayOfWeekString} from '../../../../services/enums/dayOfWeekHelper';
import {displayModes} from '../../../../services/enums/displayModes';
import trackAndTraceService from '../../../../services/backend/trackAndTraceService';
import {AuthService} from '../../../../services/auth/authService';
import {userRoles} from '../../../../services/auth/userRoles';

function CustomerDetailShow(props) {

  const {classes, className, customer, edit, t, readOnly, closeDetailView, displayMode} = props;
  const [tabState, setTabState] = useState(customer?.weekDays.map(wd => wd.dayOfWeek).sort()[0] || 0);
  const [trackingEstimate, setTrackingEstimate] = useState(null);
  const [trackingEstimateLoading, setTrackingEstimateLoading] = useState(false);

  const rootClassName = classNames(classes.root, className);

  const loadTrackingEstimate = useCallback(async () => {
    if (!customer) return;
    const weekDay = customer.weekDays.filter(wd => wd.dayOfWeek === tabState)[0];
    if (!weekDay) return;
    if (!weekDay.deliveryTiming.variance || !weekDay.deliveryTiming.averageDeliveryTime) {
      setTrackingEstimate(null);
      return;
    }
    setTrackingEstimateLoading(true);
    try {
      const result = await trackAndTraceService.getTrackingEstimateForCustomerByVarianceAndMean(weekDay.deliveryTiming.variance, weekDay.deliveryTiming.averageDeliveryTime);
      setTrackingEstimate(result);
      setTrackingEstimateLoading(false);
    } catch (e) {
      setTrackingEstimate(null);
      setTrackingEstimateLoading(false);
      console.warn('Could not load tracking estimate for customer', e);
    }
  }, [customer, tabState]);

  useEffect(() => {
    loadTrackingEstimate();
  }, [loadTrackingEstimate])

  useEffect(() => {
    setTabState(customer?.weekDays.map(wd => wd.dayOfWeek).sort()[0] || 0);
  }, [customer])

  const selectedWeekDay = customer?.weekDays.filter(wd => wd.dayOfWeek === tabState)[0];

  const getFormattedVariance = (variance) => {
    if (variance == null) return '-';
    const timeString = DateService.displayTimeFromSeconds(Math.sqrt(variance));
    return `${timeString}h`
  };

  const getFormattedAverageDeliveryTime = (averageDeliveryTime, withT = true) => {
    if (averageDeliveryTime == null) return '-';
    const timeZoneOffset = new Date().getTimezoneOffset() * 60;
    const time = DateService.displayTimeFromSeconds(averageDeliveryTime - timeZoneOffset);
    return withT ? 't + ' + time : time;
  };

  return (
    <Fragment>
      <PortletHeader className={rootClassName}>
        <Fragment>
          <div><strong>{t('internalCustomerId')}: {customer.id}</strong></div>
          <div>
            <Button
              className={classes.button}
              color="default"
              onClick={closeDetailView}
              title={t('close')}
              variant="contained"
            >
              <CloseIcon/>
            </Button>
            {!readOnly &&
            <Button
              className={classes.button}
              color="primary"
              onClick={edit}
              title={t('customerEdit')}
              variant="contained"
            >
              <EditIcon/>
            </Button>
            }
          </div>
        </Fragment>
      </PortletHeader>
      <PortletContent noPadding>
        <div className={classes.wrapper}>
          <div className={classes.wrapperChild}>
            <div className={classes.stopDetail}><strong>{t('customerId')}</strong>: {customer.externalCustomerId}</div>
            <div className={classes.stopDetail}><strong>{t('firstName')}</strong>: {customer.firstName}</div>
            <div className={classes.stopDetail}><strong>{t('lastName')}</strong>: {customer.lastName}</div>
            {AuthService.getUserRole() !== userRoles.carrier && <div className={classes.stopDetail}><strong>{t('email')}</strong>: {customer.email || ''}</div>}
            <div className={classes.stopDetail}><strong>{t('address')}</strong>: {displayAddress(customer.address)}</div>
            <div className={classes.stopDetail}><strong>{t('shipper')}</strong>: {customer.shipper}</div>
            <div className={classes.stopDetail}><strong>{t('lastOrderDate')}</strong>: {DateService.displayDate(customer.lastOrderDate)}</div>
            <div className={classes.stopDetail}><strong>{t('emailNotifications')}</strong>: {customer.emailNotifications ? t('yes') : t('no')}</div>
            <div className={classes.stopDetail}>
              <strong>{t('additionalStopDeliveryTime')}</strong>: {Math.round(customer.additionalStopDeliveryTime / 60) || '-'}
            </div>
            <div className={classes.stopDetail}><strong>{t('customerCarrierAndMicroHub')}</strong>:
              {customer.carrierWithMicroHub.map((cm, index) => (
                <div
                  className={classes.stopDetail}
                  key={index}
                >
                  {`${cm.carrier} - ${cm.microHub}`}
                </div>
              ))}
            </div>
            {displayMode !== displayModes.shipper &&
            <div className={classes.stopDetail}><strong>{t('driverHint')}</strong>: {customer.driverHint}</div>
            }
          </div>
          <div className={classes.wrapperChild}>
            <div className={classes.stopDetail}><strong>{t('weekDayData')}:</strong></div>
            <div className={classes.weekdayContainer}>
              <Tabs
                className={classes.tabRow}
                onChange={(event, newValue) => setTabState(newValue)}
                value={tabState}
              >
                {customer.weekDays.map((wd, index) => (
                  <Tab
                    key={index}
                    label={t(getDayOfWeekString(getDayOfWeekFromNumber(wd.dayOfWeek)))}
                    value={wd.dayOfWeek}
                  />
                ))}
              </Tabs>
              {selectedWeekDay &&
              <div className={classes.stopDetail}>
                <div className={classes.stopDetail}><strong>{t('lastOrderDate')}</strong>: {DateService.displayDate(selectedWeekDay.lastOrderDate)}</div>
                <div className={classes.stopDetail}>
                  <strong>{t('numOrdersOnWeekDay')}</strong>
                  <InfoIcon
                    className={classes.infoIcon}
                    descriptionKey={'10'}
                  />
                  :&nbsp;
                  {selectedWeekDay.capacities
                    ? selectedWeekDay.capacities.numValues
                    :
                    <Tooltip title={t('noCapacitiesForCustomerAvailable')}>
                      <WarnIcon className={classes.warningColor}/>
                    </Tooltip>
                  }
                </div>
                <div className={classes.stopDetail}><strong>{t('capacityEstimates')}</strong>:
                  {selectedWeekDay.capacities &&
                  <Fragment>
                    <div className={classes.stopDetail}><strong>{t('boxAmount')}</strong>: {roundCapacityValues(selectedWeekDay.capacities.boxAmount)}</div>
                    <div className={classes.stopDetail}><strong>{t('weight')}</strong>: {roundCapacityValues(selectedWeekDay.capacities.weight)}</div>
                    <div className={classes.stopDetail}><strong>{t('volume')}</strong>: {roundCapacityValues(selectedWeekDay.capacities.volume)}</div>
                  </Fragment>
                  }
                </div>
                <div className={classes.stopDetail}><strong>{t('customerDeliveryTimings')}</strong>:
                  {selectedWeekDay.deliveryTiming &&
                  <Fragment>
                    <div className={classes.stopDetail}>
                      <strong>{t('customerAverageDeliveryTime')}</strong>: {getFormattedAverageDeliveryTime(selectedWeekDay.deliveryTiming.averageDeliveryTime)}
                    </div>
                    <div className={classes.stopDetail}>
                      <strong>{t('customerNumDeliveries')}</strong>
                      <InfoIcon
                        className={classes.infoIcon}
                        descriptionKey={'20'}
                      />
                      :&nbsp;{selectedWeekDay.deliveryTiming.numDeliveries}</div>
                    <div className={classes.stopDetail}>
                      <strong>{t('customerNumSameDayDeliveries')}</strong>
                      <InfoIcon
                        className={classes.infoIcon}
                        descriptionKey={'30'}
                      />
                      :&nbsp;{selectedWeekDay.deliveryTiming.numSameDayDeliveries}</div>
                    <div className={classes.stopDetail}>
                      <strong>{t('standardDeviation')}</strong>: {getFormattedVariance(selectedWeekDay.deliveryTiming.variance)}</div>
                    <DataLoadingWrapper isLoading={trackingEstimateLoading}>
                      <Fragment>
                        {trackingEstimate &&
                        <div className={classes.stopDetail}>
                          <strong>{t('estimatedDeliveryWindowCustomer')}</strong>: {getFormattedAverageDeliveryTime(trackingEstimate.from, false)} - {getFormattedAverageDeliveryTime(trackingEstimate.to, false)}
                        </div>
                        }
                      </Fragment>
                    </DataLoadingWrapper>
                    <div className={classes.stopDetail}>
                      <strong>{t('customerLastDeliveredStopId')}</strong>: {selectedWeekDay.deliveryTiming.lastDeliveredStopId || '-'}</div>
                  </Fragment>
                  }
                </div>
              </div>
              }
            </div>
          </div>
        </div>
      </PortletContent>
    </Fragment>
  );
}


CustomerDetailShow.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  closeDetailView: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  edit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(CustomerDetailShow);
