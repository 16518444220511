export default theme => ({
  root: {
    padding: theme.spacing(0),
  },
  gridItem: {
    padding: theme.spacing(0),
    minWidth: '350px',
    minHeight: 'calc(-195px + 100vh)',
  },
  controlContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(-2)
  },
  buttonGroupMember: {
    width: '50%'
  },
  controlButtonIcon: {
    verticalAlign: 'middle'
  },
  previewOrderCheckbox: {
    float: 'right',
    marginTop: theme.spacing(-6.25)
  },
  warningColor: {
    color: theme.palette.warning.main,
    verticalAlign: 'middle'
  },
});
