import Columns from './columns';
import {displayModes} from '../../../services/enums/displayModes';

// CARE: ALWAYS INCREMENT THE VERSION IF THE SETTINGS FOR A COLUMN CHANGE!
// Config explanation
// optional: determines if the hidden status can be changed by the user
// hidden: determines if the field is shown or hidden
// conditional: indicates that the hidden status can be overriden using the conditions dictionary of the useTableConfig hook
const DefaultConfig = {
  [displayModes.admin]: {
    version: 1,
    columns: {
      specialDeliveryDayShipperName: {
        columnName: Columns.specialDeliveryDayShipperName.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 1,
      },
      specialDeliveryDayCarrierName: {
        columnName: Columns.specialDeliveryDayCarrierName.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 2
      },
      specialDeliveryDayMicroHubName: {
        columnName: Columns.specialDeliveryDayMicroHubName.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 3
      },
      specialDeliveryDayDay: {
        columnName: Columns.specialDeliveryDayDay.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 4
      },
      specialDeliveryDayHasBeenHandled: {
        columnName: Columns.specialDeliveryDayHasBeenHandled.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 5
      },
      deleteSpecialDeliveryDay: {
        columnName: Columns.deleteSpecialDeliveryDay.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 6
      },
    }
  },
  [displayModes.carrier]: {
    version: 1,
    columns: {
      specialDeliveryDayShipperName: {
        columnName: Columns.specialDeliveryDayShipperName.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 1,
      },
      specialDeliveryDayCarrierName: {
        columnName: Columns.specialDeliveryDayCarrierName.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 2
      },
      specialDeliveryDayMicroHubName: {
        columnName: Columns.specialDeliveryDayMicroHubName.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 3
      },
      specialDeliveryDayDay: {
        columnName: Columns.specialDeliveryDayDay.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 4
      },
      specialDeliveryDayHasBeenHandled: {
        columnName: Columns.specialDeliveryDayHasBeenHandled.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 5
      },
      deleteSpecialDeliveryDay: {
        columnName: Columns.deleteSpecialDeliveryDay.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 6
      },
    }
  },
  [displayModes.shipper]: {
    version: 1,
    columns: {}
  },
}

export default DefaultConfig;