import React, {useCallback, useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Portlet} from '../../../components'
import {default as StopDetailEdit} from './StopDetailEdit';
import {default as StopDetailShow} from './StopDetailShow';
import trackAndTraceService from '../../../services/backend/trackAndTraceService';
import {TourService} from '../../../services/backend/tourService';
import {ClientService} from '../../../services/client/clientService';
import {clientTypes} from '../../../services/client/clientTypes';
import {ShipperQueryService} from '../../../services/backend/shipperQueryService';
import {useQuery} from '@apollo/react-hooks';

const clientShipper = ClientService.getClient(clientTypes.shipper);

function StopDetails(props) {
  const {classes, className, stop, updateStop, readOnly, closeDetailView, editDisplayMode, microHubSelectOptions, trackingStop, trackingStopLoading} = props;

  const [editMode, setEditMode] = useState(false);
  const [editedStop, setEditedStop] = useState(false);
  const [shallowTour, setShallowTour] = useState(null);
  const [trackingStopInternal, setTrackingStopInternal] = useState(null);
  const [trackingStopLoadingInternal, setTrackingTopLoadingInternal] = useState(false);

  const queryShipper = ShipperQueryService.getAllShipperEmptiesQuery();
  const {data: dataShipper} = useQuery(queryShipper, {client: clientShipper, skip: !editMode});

  const loadShallowTour = useCallback(async () => {
    if (!stop?.tourId) return;
    try {
      const result = await TourService.getTourByIdShallow(stop.tourId);
      setShallowTour(result);
    } catch (e) {
      console.warn('Error loading shallow tour', e)
    }
  }, [stop])

  const loadTrackingStop = async (stopId) => {
    try {
      setTrackingTopLoadingInternal(true);
      const response = await trackAndTraceService.getTrackAndTraceInfoByStopId(stopId);
      setTrackingStopInternal(response);
      setTrackingTopLoadingInternal(false);
    } catch (e) {
      setTrackingTopLoadingInternal(false);
      console.warn('Error loading track and trace information for stop-details', e)
    }
  };

  const editStop = () => {
    const newStop = JSON.parse(JSON.stringify(stop));
    if (!newStop.contact) {
      newStop.contact = {
        name: '',
        phoneNumber: ''
      }
    }
    setEditedStop(newStop);
    setEditMode(true);
  };

  const update = () => {
    setEditMode(false);
    updateStop(editedStop);
  };

  useEffect(() => {
    // if there is an external tracking stop use it
    if (trackingStop && !trackingStopLoading) {
      setTrackingStopInternal(trackingStop);
      setTrackingTopLoadingInternal(false);
      return;
    }
    // if external tracking stop is loading, wait for it
    if (trackingStopLoading) {
      setTrackingTopLoadingInternal(true);
      return;
    }
    // if there is no external tracking stop loading load it yourself
    if (!trackingStop && !trackingStopLoading) {
      loadTrackingStop(stop.tourStopId);
    }
  }, [stop, trackingStop, trackingStopLoading])

  useEffect(() => {
    loadShallowTour();
  }, [loadShallowTour])

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet className={rootClassName}>
      {editMode ?
        <StopDetailEdit
          editDisplayMode={editDisplayMode}
          editedStop={editedStop}
          initialStop={stop}
          microHubSelectOptions={microHubSelectOptions}
          setEditedStop={setEditedStop}
          setEditMode={setEditMode}
          shipperEmptiesSelectOptions={dataShipper ? dataShipper.shippers.find(shipper => shipper.name === stop.shipperName).empties : []}
          updateStop={update}
        />
        :
        <StopDetailShow
          closeDetailView={closeDetailView}
          edit={editStop}
          readOnly={readOnly}
          shallowTour={shallowTour}
          stop={stop}
          trackingStop={trackingStopInternal}
          trackingStopLoading={trackingStopLoadingInternal}
        />
      }
    </Portlet>
  );
}


StopDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  closeDetailView: PropTypes.func,
  editDisplayMode: PropTypes.string,
  microHubSelectOptions: PropTypes.array,
  readOnly: PropTypes.bool,
  stop: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  trackingStop: PropTypes.object,
  trackingStopLoading: PropTypes.bool,
  updateStop: PropTypes.func,
}
;

export default compose(withStyles(styles), withTranslation())(StopDetails);
