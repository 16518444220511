import {TableCell} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const TableCellWithConfig = (props) => {

  const {config, children, className, boxShadowColor} = props;

  if (!config.hidden) {
    if (children) {
      return (
        <TableCell
          className={className}
          style={boxShadowColor ? {boxShadow: `inset 10px 0px 0px 0px ${boxShadowColor}`} : {}}
        >
          {children}
        </TableCell>
      )
    } else {
      return (
        <TableCell
          className={className}
          style={boxShadowColor ? {boxShadow: `inset 10px 0px 0px 0px ${boxShadowColor}`} : {}}
        >
        </TableCell>
      )
    }
  }

  return null;
}

TableCellWithConfig.propTypes = {
  config: PropTypes.shape({hidden: PropTypes.bool.isRequired}).isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  boxShadowColor: PropTypes.string,
};

export default TableCellWithConfig;