import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react'
import {Button, Grid, LinearProgress, Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {PanZoom} from 'react-easy-panzoom'
import classNames from 'classnames'
import {HubShipperUtilizationIndicator, MicroHubPlanCanvas, StopsWithoutStoragePlaceDialog, ZoneDetails} from './Components';
import {List as StopsWithoutStoragePlaceIcon, SettingsOverscan as AutoCenterIcon} from '@material-ui/icons';
import {roundCapacityValues} from '../../../services/util/helperFuncs';


function MicroHubPlanWithToursView(props) {

  const {classes, t, microHubPlan, className, capacitiesShippers, capacityAll, otherStops, toursWithStops, displayBeverageInsertZones, shipperFilter} = props;
  const rootClassName = classNames(classes.root, className);
  const panZoneRef = useRef(null);

  const [zonesWithToursAndStops, setZonesWithToursAndStops] = useState({});
  const [stopsWithoutZones, setStopsWithoutZones] = useState([]);
  const [stopsWithoutStoragePlaceDialogState, setStopsWithoutStoragePlaceDialogState] = useState({open: false});
  const [selectedZoneInfos, setSelectedZoneInfos] = useState(null);

  const autoCenter = useCallback(() => {
    if (panZoneRef && panZoneRef.current) {
      panZoneRef.current.autoCenter();
    }
  }, [panZoneRef]);

  useEffect(() => {
    autoCenter();
  }, [microHubPlan, autoCenter]);

  useEffect(() => {
    if (!microHubPlan) return;
    setSelectedZoneInfos(null);
    const zonesWithTourDict = {};

    // Initialize with zones
    microHubPlan.boxZones.forEach(zone => {
      zone.shipperInfos.forEach(info => {
        zonesWithTourDict[`${info.shortName}-${info.order}`] = {toursWithStops: [], stopsWithoutTour: []};
      })
    });


    // fill with tours and their stops
    toursWithStops.forEach(tour => {
      tour.storagePlaces.forEach(storagePlace => {
        const newTour = JSON.parse(JSON.stringify(tour));
        newTour.stops = newTour.stops.filter(stop => stop.storagePlaces.includes(storagePlace));
        if (newTour.stops.length > 0) {
          if (zonesWithTourDict[storagePlace]) {
            zonesWithTourDict[storagePlace].toursWithStops.push(newTour);
          }
        }
      })
    });


    // fill with stops without tours
    otherStops.forEach(stop => {
      stop.storagePlaces.forEach(storagePlace => {
        if (zonesWithTourDict[storagePlace]) {
          zonesWithTourDict[storagePlace].stopsWithoutTour.push(JSON.parse(JSON.stringify(stop)));
        }
      })
    });

    setZonesWithToursAndStops(zonesWithTourDict);

    const stopsWithoutStoragePlaces = otherStops
      .concat(toursWithStops.map(tour => tour.stops).flat())
      .filter(stop => !stop.storagePlaces || stop.storagePlaces.length === 0);

    setStopsWithoutZones(stopsWithoutStoragePlaces);

  }, [otherStops, toursWithStops, microHubPlan]);

  const preventPan = (event) => {
    // if the target is the content container then prevent panning
    if (event.target.tagName === 'CANVAS') {
      return false
    }
  };

  const utilizationPercent = capacityAll.max !== 0 ?
    `${((capacityAll.current / capacityAll.max) * 100).toFixed(2)}% ` : '';

  return (
    <Fragment>
      <div className={classes.capacityAllWrapper}>
        <div className={classes.capacityAllIndicatorWrapper}>
          <LinearProgress
            className={classes.capacityAllIndicator}
            value={Math.min(((capacityAll.current / capacityAll.max) * 100), 100)}
            variant="determinate"
          />
        </div>
        <Typography variant="body1">
          {`${t('hubUtilization')}: ${utilizationPercent}(${roundCapacityValues(capacityAll.current)} l / ${roundCapacityValues(capacityAll.max)} l)`}
        </Typography>
      </div>
      <Grid
        className={rootClassName}
        container
        spacing={1}
      >
        <Grid
          item
          lg={9}
          sm={9}
          xl={9}
          xs={9}
        >
          <PanZoom
            autoCenter
            disableKeyInteraction
            enableBoundingBox
            preventPan={preventPan}
            ref={panZoneRef}
            style={{height: '100vh', overflow: 'hidden'}}
          >
            <MicroHubPlanCanvas
              displayBeverageInsertZones={displayBeverageInsertZones}
              microHubPlan={microHubPlan}
              selectedZoneInfos={selectedZoneInfos}
              setSelectedZoneInfos={setSelectedZoneInfos}
              shipperFilter={shipperFilter}
              zonesWithToursAndStops={zonesWithToursAndStops}
            />
          </PanZoom>
        </Grid>
        <Grid
          className={classes.controlGrid}
          item
          lg={3}
          sm={3}
          xl={3}
          xs={3}
        >
          {capacitiesShippers && Object.getOwnPropertyNames(capacitiesShippers).map(shipper => {
            return (
              <HubShipperUtilizationIndicator
                currentCapacity={capacitiesShippers[shipper].current}
                key={shipper}
                maxCapacity={capacitiesShippers[shipper].max}
                shipperName={shipper}
              />
            )
          })}
          <Button
            className={classes.controlButton}
            color="secondary"
            onClick={() => setStopsWithoutStoragePlaceDialogState({...stopsWithoutStoragePlaceDialogState, open: true})}
            title={t('autoCenter')}
            variant="contained"
          >
            <StopsWithoutStoragePlaceIcon className={classes.controlButtonIcon}/>&nbsp;{t('showStopsWithoutStoragePlace')}
          </Button>
          <Button
            className={classes.controlButton}
            color="secondary"
            onClick={autoCenter}
            title={t('autoCenter')}
            variant="contained"
          >
            <AutoCenterIcon className={classes.controlButtonIcon}/>&nbsp;{t('autoCenter')}
          </Button>
          <hr/>
          {selectedZoneInfos &&
          <ZoneDetails
            className={classes.zoneDetails}
            selectedZoneInfos={selectedZoneInfos}
          />
          }
        </Grid>
      </Grid>
      <StopsWithoutStoragePlaceDialog
        dialogState={stopsWithoutStoragePlaceDialogState}
        handleClose={() => setStopsWithoutStoragePlaceDialogState({...stopsWithoutStoragePlaceDialogState, open: false})}
        stops={stopsWithoutZones}
      />
    </Fragment>
  );
}

MicroHubPlanWithToursView.propTypes = {
  capacitiesShippers: PropTypes.object.isRequired,
  capacityAll: PropTypes.object.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  displayBeverageInsertZones: PropTypes.bool.isRequired,
  microHubPlan: PropTypes.object.isRequired,
  otherStops: PropTypes.array.isRequired,
  shipperFilter: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  toursWithStops: PropTypes.array.isRequired,
};

export default compose(withStyles(styles), withTranslation())(MicroHubPlanWithToursView);
