import {useEffect, useState} from 'react';


/*
defaultConfig - default config of the table
displayMode - display mode of the table
configKey - key to get table settings from local storage (see getTableKey in tableConfigHelper)
conditionDictionary -  columns that are marked as conditional can be displayed using a dictionary of key - value pairs
                       the key is the name of the config section for the column, the value determines if the colum should
                       be displayed. E.g. {options: true} shows the options column while {options: false} hides it.
 */
export default function useTableConfig(defaultConfig, displayMode, configKey, conditionDictionary = {}) {

  const [config, setConfig] = useState(defaultConfig[displayMode]);

  useEffect(() => {
    const newConfig = JSON.parse(JSON.stringify(defaultConfig[displayMode]));
    // 1) Apply conditions from conditionDictionary
    Object.keys(newConfig.columns).forEach(key => {
      if (newConfig.columns[key].conditional) {
        const conditionPresent = conditionDictionary[key] !== null && conditionDictionary[key] !== undefined;
        newConfig.columns[key].hidden = conditionPresent ? !conditionDictionary[key] : newConfig.columns[key].hidden
      }
    });

    // 2) Load user config and override optional fields
    if (!configKey) {
      setConfig(newConfig);
      return;
    }
    // Load user config from local storage
    const userConfigRaw = window.localStorage.getItem(configKey);
    // No User Config found --> use default config
    if (!userConfigRaw) {
      setConfig(newConfig);
      return;
    }
    let userConfig = null;
    try {
      userConfig = JSON.parse(userConfigRaw);
    } catch (error) {
      // if config can not be parsed, delete the config
      console.warn(`User table config with key ${configKey} could not be parsed, restoring default config version ${defaultConfig[displayMode].version}`)
      window.localStorage.removeItem(configKey);
    }
    // User config is old --> use default config
    if (!userConfig || !userConfig.version || userConfig.version !== defaultConfig[displayMode].version) {
      console.warn(`User table config with key ${configKey} is to old, using default config version ${defaultConfig[displayMode].version}`)
      setConfig(newConfig);
      return;
    }

    // merge user config with default config
    Object.keys(userConfig.columns).forEach(key => {
      // only optional columns may be overridden
      if (newConfig.columns[key]?.optional) {
        newConfig.columns[key] = userConfig.columns[key]
      }
    });
    setConfig(newConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configKey, defaultConfig, displayMode]);

  const updateConfig = (configColumnName, updatedColumnConfig) => {
    const newConfig = {...config};
    newConfig.columns[configColumnName] = updatedColumnConfig;
    setConfig(newConfig);
    localStorage.setItem(configKey, JSON.stringify(newConfig));
  }
  
  const columnConfigs = Object
    .values(config.columns)
    .sort((a, b) => a.order - b.order);

  return {columnConfigs,  updateConfig, config}
}