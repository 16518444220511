import React, {Fragment, useEffect, useState} from 'react'
import {Box, Collapse, TableCell, TableRow, withStyles, withWidth,} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {LoadingWrapper, TableCellWithConfig, TourStopTableSmallDetails} from '../../../../../../components';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import useLoadStopByIdCache from '../../../../../../hooks/useLoadStopByIdCache';
import Columns from '../FluentTourTable/columns';


function FluentTourTableRowStop(props) {
  const {
    classes,
    className,
    stop,
    provided,
    snapshot,
    fitsOnVehicle,
    openAddTourPopover,
    t,
    hideRow,
    columnConfigs
  } = props;

  const rootClassName = classNames(classes.root, className);
  const [open, setOpen] = useState(false);
  const {getStopById, selectedStop, loadingSelectedStop} = useLoadStopByIdCache();


  useEffect(() => {
    if (open) {
      getStopById(stop.tourStopId);
    }
  }, [open, stop, getStopById])

  return (
    <Fragment>
      <TableRow
        className={classNames(rootClassName, {
          [classes.stopDoesNotFitOnVehicle]: !fitsOnVehicle,
          [classes.dragging]: snapshot.isDragging,
          [classes.rowHidden]: hideRow
        })}
        hover
        id={stop.tourStopId}
        onClick={openAddTourPopover}
        title={t('clickToAddTour')}
        {...provided.draggableProps}
        ref={provided.innerRef}
      >
        {columnConfigs.map(columnConfig => {
          return (
            <TableCellWithConfig
              key={columnConfig.order}
              config={columnConfig}
            >
              {Columns[columnConfig.columnName].body({
                stop,
                classes,
                t,
                dragHandleProps: provided.dragHandleProps,
                snapshot,
                setOpen,
                open
              })}
            </TableCellWithConfig>
          );
        })}
      </TableRow>
      <TableRow className={classNames({[classes.rowHidden]: hideRow || snapshot.isDragging})}>
        <TableCell
          className={classes.largeTableCell}
          colSpan={columnConfigs.filter(val => !val.hidden).length}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <Box margin={1}>
              <LoadingWrapper loading={loadingSelectedStop}>
                <Fragment>
                  {selectedStop &&
                    <TourStopTableSmallDetails stop={selectedStop}/>
                  }
                </Fragment>
              </LoadingWrapper>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

FluentTourTableRowStop.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  columnConfigs: PropTypes.arrayOf(PropTypes.shape({
    hidden: PropTypes.bool.isRequired,
    optional: PropTypes.bool.isRequired,
    columnName: PropTypes.string.isRequired
  })).isRequired,
  fitsOnVehicle: PropTypes.bool.isRequired,
  hideRow: PropTypes.bool.isRequired,
  openAddTourPopover: PropTypes.func.isRequired,
  provided: PropTypes.object.isRequired,
  snapshot: PropTypes.object.isRequired,
  stop: PropTypes.object,
  t: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
};

export default compose(withStyles(styles), withTranslation(), withWidth())(FluentTourTableRowStop);
