import React from 'react';
import {Doughnut} from 'react-chartjs-2';
import PropTypes from 'prop-types';
import palette from '../../../../../theme/palette';
import {withTranslation} from 'react-i18next';
import {options} from './chart';
import {groupBy} from 'lodash';
import {ColorService} from '../../../../../services/color/colorService';
import {roundCapacityValues} from '../../../../../services/util/helperFuncs';
import {TextPlaceholderWrapper} from '../../../../index';


function UtilizationDoughnut(props) {

  const {stops, t, className} = props;

  const shippersWithStops = groupBy(stops, stop => stop.shipperName);

  const data = {
    datasets: [
      {
        data: Object.getOwnPropertyNames(shippersWithStops).map(shipperName => roundCapacityValues(shippersWithStops[shipperName].reduce((accumulator, stop) => accumulator + (stop.stopCapacities ? stop.stopCapacities.volume : 0), 0))),
        backgroundColor: Object.getOwnPropertyNames(shippersWithStops).map((_, index) => ColorService.getColorByNumber(index)),
        hoverBorderColor: palette.common.white,
        borderColor: palette.common.white
      }
    ],
    labels: Object.getOwnPropertyNames(shippersWithStops)
  };

  return (
    <div className={className}>
      <TextPlaceholderWrapper
        active={!stops?.length}
        text={t('noStopsAvailableDashboard')}
      >
        <Doughnut
          data={data}
          options={options}
        />
      </TextPlaceholderWrapper>
    </div>
  );
}

UtilizationDoughnut.propTypes = {
  className: PropTypes.string,
  stops: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(UtilizationDoughnut);
