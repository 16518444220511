import React, {useEffect, useState} from 'react'
import {Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, Grid, Tooltip, Typography, withStyles} from '@material-ui/core';
import styles from './styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {AddStopsToTourMap, TourStoragePlaces} from '../../../components';
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import {ShipperAllowedOnHubService} from '../../../services/util/shipperAllowedOnHubHelper';
import {CapacityHelperService} from '../../../services/util/capacityHelper';
import StopAddTable from './StopAddTable';
import {onCloseWithBackdropCurry} from '../../../services/util/helperFuncs';
import moment from 'moment';

function StopAddDialog(props) {

  const {
    classes,
    t,
    addStopDialogState,
    handleCancel,
    handleClose,
    spareStops,
    alreadyPresentStops,
    expectedHub,
    expectedHubData,
    tourDeliveryDate,
    tours,
    microHubs,
    selectedTour
  } = props;
  const [stopsToAdd, setStopsToAdd] = useState([]);
  const [selectedTourInternal, setSelectedTourInternal] = useState(null);
  const [displayAllTours, setDisplayAllTours] = useState(false);

  const [autoOrderOnAdd, setAutoOrderOnAdd] = useState(true);
  const [hideOldStops, setHideOldStops] = useState(true);

  const [stopsToDisplay, setStopsToDisplay] = useState([]);

  useEffect(() => {
    if (addStopDialogState.open && selectedTour) {
      setSelectedTourInternal(JSON.parse(JSON.stringify(selectedTour)));
    }
  }, [addStopDialogState, selectedTour]);

  useEffect(() => {
    let newStopsToDisplay = spareStops.filter(spareStop => !alreadyPresentStops.some(stop => stop.tourStopId === spareStop.tourStopId));
    if (hideOldStops) {
      newStopsToDisplay = newStopsToDisplay.filter(spareStop => {
        const plannedStopDelivery = spareStop.planedDeliveryDateCarrier ?? spareStop.planedDeliveryDateShipper;
        return moment(new Date(plannedStopDelivery)).startOf('day').isAfter(moment().startOf('day').subtract(3, 'd'));
      });
    }
    setStopsToDisplay(newStopsToDisplay);
  }, [hideOldStops, spareStops, alreadyPresentStops])

  const addOrRemoveStop = (stop) => {
    if (stopsToDisplay.findIndex(s => s.tourStopId === stop.tourStopId) >= 0) {
      const newStopsToAdd = JSON.parse(JSON.stringify(stopsToAdd));
      const index = newStopsToAdd.findIndex(presentStop => presentStop.tourStopId === stop.tourStopId);
      if (index >= 0) {
        newStopsToAdd.splice(index, 1);
      } else {
        newStopsToAdd.push(stop);
      }
      newStopsToAdd.forEach(stop => stop.stopNumber = 0);
      setStopsToAdd(newStopsToAdd);
      const newStops = selectedTour.stops.concat(newStopsToAdd);
      const newStoragePlaces = [...new Set(newStops.map(stop => stop.storagePlaces).flat())];
      setSelectedTourInternal({...selectedTourInternal, stops: newStops, storagePlaces: newStoragePlaces});
    }
  };

  const handleOk = () => {
    handleClose(stopsToAdd, autoOrderOnAdd);
    setStopsToAdd([]);
    setSelectedTourInternal(null);
  };

  const handleNotOk = () => {
    handleCancel();
    setStopsToAdd([]);
    setSelectedTourInternal(null);
  };

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      fullWidth
      maxWidth={'xl'}
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={addStopDialogState.open}
    >
      <div
        className={classes.headerContainer}
        style={selectedTourInternal ? {backgroundColor: selectedTourInternal.color} : {}}
      >
        <Typography><strong>{`${t('stopAdd')} ${selectedTourInternal ? `${t('toTour')} ${selectedTourInternal.tourId}` : ''}`}</strong></Typography>
        <Typography><strong>{`${t('microHub')}: ${selectedTourInternal ? selectedTourInternal.microHubName : '-'}`}</strong></Typography>
        <div className={classes.storagePlaceHeader}>
          <Typography><strong>{t('storagePlace')}:&nbsp;</strong></Typography>
          <TourStoragePlaces
            maxDisplay={4}
            tour={selectedTourInternal}
          />
        </div>
        <Typography><strong>{`${t('boxAmount')}: ${selectedTourInternal ? CapacityHelperService.getBoxAmountOfTour(selectedTourInternal) : '-'}`}</strong></Typography>
        <Typography><strong>{`${t('weight')}: ${selectedTourInternal ? CapacityHelperService.getWeightOfTour(selectedTourInternal) : '-'}`}</strong></Typography>
        <Typography><strong>{`${t('volume')}: ${selectedTourInternal ? CapacityHelperService.getVolumeOfTour(selectedTourInternal) : '-'}`}</strong></Typography>
      </div>
      <DialogContent>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            lg={5}
            sm={12}
            xl={5}
            xs={12}
          >
            <AddStopsToTourMap
              addedStops={stopsToAdd}
              className={classes.map}
              displayAllTours={displayAllTours}
              microHubs={microHubs ? microHubs : []}
              notPlanedStops={stopsToDisplay ? stopsToDisplay.filter(stop => stopsToAdd.findIndex(s => s.tourStopId === stop.tourStopId) < 0) : []}
              selectedTour={selectedTour}
              setDisplayAllTours={setDisplayAllTours}
              setSelectedStop={addOrRemoveStop}
              toursWithStops={displayAllTours ? tours : (selectedTour ? [selectedTour] : [])}
            />
          </Grid>
          <Grid
            item
            lg={7}
            sm={12}
            xl={7}
            xs={12}
          >
            <FormControl className={classes.hideOldStopsFormControl}>
              <Tooltip title={t('hideOldStopsTooltip')}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hideOldStops}
                      color="secondary"
                      onChange={(event) => setHideOldStops(event.target.checked)}
                      value={hideOldStops}
                    />
                  }
                  label={t('hideOldStops')}
                />
              </Tooltip>
            </FormControl>
            <StopAddTable
              addOrRemoveStop={addOrRemoveStop}
              className={classes.table}
              expectedHub={expectedHub}
              expectedHubData={expectedHubData}
              stopsToAdd={stopsToAdd}
              stopsToDisplay={stopsToDisplay}
              tourDeliveryDate={tourDeliveryDate}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FormControl className={classes.formControlAlign}>
          <Tooltip title={t('autoOrderOnAddTooltip')}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={autoOrderOnAdd}
                  color="secondary"
                  onChange={(event) => setAutoOrderOnAdd(event.target.checked)}
                  value={autoOrderOnAdd}
                />
              }
              label={t('autoOrderOnAdd')}
            />
          </Tooltip>
        </FormControl>
        <Button
          color="default"
          onClick={handleNotOk}
        >
          <CancelIcon/>&nbsp;{t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          disabled={!stopsToAdd || stopsToAdd.length === 0 || stopsToAdd.some(stop => !ShipperAllowedOnHubService.checkShipperAllowedStop(expectedHubData, stop, () => tourDeliveryDate))}
          onClick={handleOk}
        >
          <SaveIcon/>&nbsp;{t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

StopAddDialog.propTypes = {
  addStopDialogState: PropTypes.object.isRequired,
  alreadyPresentStops: PropTypes.array,
  classes: PropTypes.object.isRequired,
  expectedHub: PropTypes.string,
  expectedHubData: PropTypes.object,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  microHubs: PropTypes.array.isRequired,
  selectedTour: PropTypes.object,
  spareStops: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  tourDeliveryDate: PropTypes.string,
  tours: PropTypes.array.isRequired,
};


export default compose(withStyles(styles), withTranslation())(StopAddDialog);
