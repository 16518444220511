import React, {Fragment, useCallback, useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {TourService} from '../../../services/backend/tourService';
import {
  GeneralInfoIcon,
  LoadingWrapper,
  RefreshButton,
  TourDetail,
  TourDetailsMap,
  TourTimeSummary
} from '../../../components';
import {useTourHub} from '../../../hooks/useTourHub';
import {useStopHub} from '../../../hooks/useStopHub';
import {SideNavLayout} from '../../../layouts';
import trackAndTraceService from '../../../services/backend/trackAndTraceService';
import useMicroHubOptionsOfCarrier from '../../../hooks/useMicroHubOptionsOfCarrier';
import {AuthService} from '../../../services/auth/authService';
import {userRoles} from '../../../services/auth/userRoles';
import {displayModes} from '../../../services/enums/displayModes';

function TourDetailPage(props) {
  const {classes, t} = props;
  const [loading, setLoading] = useState(false);
  const [tour, setTour] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [trackingStops, setTrackingStops] = useState([]);
  const [trackingStopsLoading, setTrackingStopsLoading] = useState(false);
  // use the hook for carrier with empty organization since the carrier name needs to be pressent if this page is accessed by a carrier
  const {microHubData} = useMicroHubOptionsOfCarrier(AuthService.getUserRole() === userRoles.carrier ? AuthService.getUserOrganization() : null, true);

  useTourHub(useCallback(event => {
    const eventData = JSON.parse(event.toString());
    if (eventData.tourId === tour.tourId) setRefresh(true);
  }, [tour]));

  useStopHub(useCallback(event => {
    const eventData = JSON.parse(event.toString());
    if (tour.stops.map(s => s.tourStopId).includes(eventData.stopId)) {
      setRefresh(true);
    }
  }, [tour]));

  const {tourId} = useParams();

  const loadTour = useCallback(() => {
    setLoading(true);
    TourService.getTourById(tourId)
      .then(response => response.json())
      .then(response => {
        setTour(response);
        setLoading(false);
        setRefresh(false);
      }, () => {
        alert(t('errorLoadingTour'));
        setLoading(false);
        setRefresh(false);
      })
  }, [tourId, t])

  const loadTrackingStops = useCallback(async () => {
    if (!tour) return;
    try {
      setTrackingStopsLoading(true);
      const response = await trackAndTraceService.getTrackAndTraceInfoByStopIds(tour.stops.map(s => s.tourStopId));
      setTrackingStops(response);
      setTrackingStopsLoading(false);
    } catch (e) {
      setTrackingStopsLoading(false);
      console.warn('Error loading track and trace information for tour details', e)
    }
  }, [tour]);

  useEffect(() => {
    loadTour();
  }, [loadTour])

  useEffect(() => {
    loadTrackingStops();
  }, [loadTrackingStops])


  return (
    <SideNavLayout title={`${t('tourDetails')} - ${tour ? (tour.tourName ? tour.tourName : tour.tourId) : tourId}`}>
      <div className={classes.root}>
        <div className={classes.helpWrapper}>
          <RefreshButton
            refresh={refresh}
            refreshFunc={() => {
              loadTour();
            }}
          />
          <GeneralInfoIcon/>
        </div>
        <LoadingWrapper loading={loading}>
          {tour !== null &&
          <Fragment>
            <TourTimeSummary
              className={classes.pageElement}
              tour={tour}
              trackingStops={trackingStops}
            />
            <TourDetail
              className={classes.pageElement}
              tour={tour}
              trackingStops={trackingStops}
              trackingStopsLoading={trackingStopsLoading}
              displayMode={displayModes.shared}
            />
            <TourDetailsMap
              className={classes.map}
              hideNotPlannedStops
              microHubs={microHubData.filter(hub => tour.microHubName === hub.name)}
              tours={[tour]}
            />
          </Fragment>
          }
        </LoadingWrapper>
      </div>
    </SideNavLayout>
  );
}

TourDetailPage.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(TourDetailPage);
