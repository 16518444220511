import {LoadingWrapper, ShipperLabel, StopIdLabel, StoragePlaces,} from '../../index';
import React from 'react';
import {getStopStatusString} from '../../../services/enums/StopStatus';
import {DateService} from '../../../services/util/DateService';

const Columns = {
  stopNumber: {
    name: 'stopNumber',
    header: ({t}) => t('stopNumber'),
    body: ({stop}) => stop.stopNumber
  },
  tourStopId: {
    name: 'tourStopId',
    header: ({t}) => t('tourStopId'),
    body: ({stop}) => <StopIdLabel stop={stop}/>
  },
  shipper: {
    name: 'shipper',
    header: ({t}) => t('shipper'),
    body: ({stop}) => <ShipperLabel stop={stop}/>
  },
  storagePlace: {
    name: 'storagePlace',
    header: ({t}) => t('storagePlace'),
    body: ({stop}) => (
      <StoragePlaces
        maxDisplay={4}
        stop={stop}
      />
    )
  },
  lastName: {
    name: 'lastName',
    header: ({t}) => t('lastName'),
    body: ({stop}) => stop.lastName
  },
  streetAndNumber: {
    name: 'streetAndNumber',
    header: ({t}) => t('streetAndNumber'),
    body: ({stop}) => stop.address.streetAndNumber
  },
  zipcode: {
    name: 'zipcode',
    header: ({t}) => t('zipcode'),
    body: ({stop}) => stop.address.zipcode
  },
  planedDeliveryDateShipper: {
    name: 'planedDeliveryDateShipper',
    header: ({t}) => t('planedDeliveryDateShipper'),
    body: ({stop}) => DateService.displayDate(stop.planedDeliveryDateShipper)
  },
  stopStatus: {
    name: 'stopStatus',
    header: ({t}) => t('stopStatus'),
    body: ({stop, t}) => t(getStopStatusString(stop.stopStatus))
  },
  estimatedDeliveryWindow: {
    name: 'estimatedDeliveryWindow',
    header: ({t}) => t('estimatedDeliveryWindow'),
    body: ({lastTrackingStep, trackingStopsLoading}) =>
      (
        <LoadingWrapper
          loading={trackingStopsLoading}
          noPadding
        >
          {lastTrackingStep ? `${DateService.displayTime(lastTrackingStep.deliveryWindow.from)} - ${DateService.displayTime(lastTrackingStep.deliveryWindow.to)}` : '-'}
        </LoadingWrapper>
      )
  },
  deliveryTimeWindowShipper: {
    name: 'deliveryTimeWindowShipper',
    header: ({t}) => t('deliveryTimeWindowShipper'),
    body: ({stop}) => stop.deliveryWindowShipper ? `${DateService.displayTime(stop?.deliveryWindowShipper?.from)} - ${DateService.displayTime(stop?.deliveryWindowShipper?.to)}` : '-'
  },
}

export default Columns;