import {compose} from 'recompose';
import {Checkbox, FormControl, FormControlLabel, Tab, Tabs, withStyles} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import styles from './styles'
import classNames from 'classnames';
import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import LegendInteractive from '../../MapComponents/LegendInteractive';
import {StopStatus} from '../../../../services/enums/StopStatus';
import {InfoIcon, TourMap} from '../../../index';

const tourAndStopMapTabState = {
  tourOverview: 0,
  stopStatus: 1,
}

const TourAndStopMap = props => {

  const {classes, className, tours, t, microHubs, notPlanedStops, persistedTabState, setPersistedTabState} = props;
  const [legendCheckboxes, setLegendCheckboxes] = useState({
    displayNotPlanedStops: true,
    displayPlanedStops: true,
    displayInDeliveryStops: true,
    displayDeliveredStops: true,
    displayCanNotDeliverStops: true,
    displayRedeliveryStops: true,
    displayMicroHubs: true
  });
  const [displayNotApprovedTours, setDisplayNotApprovedTours] = useState(true);
  const [tabState, setTabState] = useState(tourAndStopMapTabState.tourOverview);

  const displayStopStatus = persistedTabState !== undefined ? persistedTabState !== tourAndStopMapTabState.tourOverview : tabState !== tourAndStopMapTabState.tourOverview;

  const rootClassName = classNames(classes.root, className);

  const filteredTours = JSON.parse(JSON.stringify(tours)).filter(tour => displayNotApprovedTours ? true : tour.approved);
  filteredTours.forEach(
    tour => tour.stops = tour.stops.filter(
      stop => {
        switch (stop.stopStatus) {
          case StopStatus.NotPlaned: {
            return legendCheckboxes.displayNotPlanedStops;
          }
          case StopStatus.Planed: {
            return legendCheckboxes.displayPlanedStops || !displayStopStatus;
          }
          case StopStatus.InDelivery: {
            return legendCheckboxes.displayInDeliveryStops || !displayStopStatus;
          }
          case StopStatus.Delivered: {
            return legendCheckboxes.displayDeliveredStops || !displayStopStatus;
          }
          case StopStatus.CanNotDeliver: {
            return legendCheckboxes.displayCanNotDeliverStops || !displayStopStatus;
          }
          case StopStatus.Redelivery: {
            return legendCheckboxes.displayRedeliveryStops || !displayStopStatus;
          }
          default: {
            return false;
          }
        }
      }
    )
  );

  return (
    <Fragment>
      <div className={classes.legendControl}>
        <div className={classes.tabCheckbox}>
          <Tabs
            onChange={(event, newValue) => {
              if (setPersistedTabState) setPersistedTabState(newValue);
              setTabState(newValue);
            }}
            value={persistedTabState !== undefined ? persistedTabState : tabState}
          >
            <Tab
              icon={<InfoIcon
                className={classes.infoTab}
                descriptionKey={'27'}
              />}
              label={t('tourOverview')}
              value={tourAndStopMapTabState.tourOverview}
            />
            <Tab
              icon={<InfoIcon
                className={classes.infoTab}
                descriptionKey={'28'}
              />}
              label={t('stopStatus')}
              value={tourAndStopMapTabState.stopStatus}
            />
          </Tabs>
          <FormControl className={classes.formControlAlign}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={displayNotApprovedTours}
                  color="secondary"
                  onChange={(event) => setDisplayNotApprovedTours(event.target.checked)}
                  value={displayNotApprovedTours}
                />
              }
              label={t('displayNotApprovedTours')}
            />
          </FormControl>
        </div>
        <LegendInteractive
          hideStopStatus={!displayStopStatus}
          legendCheckboxesState={legendCheckboxes}
          setLegendCheckboxesState={setLegendCheckboxes}
        />
      </div>
      <TourMap
        className={rootClassName}
        displayMicroHubs={legendCheckboxes.displayMicroHubs}
        displayNotPlanedStops={legendCheckboxes.displayNotPlanedStops}
        displayStopStatus={displayStopStatus}
        microHubs={microHubs}
        notPlanedStops={notPlanedStops}
        toursWithStops={filteredTours}
      />
    </Fragment>
  );
};

TourAndStopMap.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  microHubs: PropTypes.array,
  notPlanedStops: PropTypes.array,
  persistedTabState: PropTypes.number,
  setPersistedTabState: PropTypes.func,
  t: PropTypes.func.isRequired,
  tours: PropTypes.array,
};


export default compose(withStyles(styles), withTranslation())(TourAndStopMap);

