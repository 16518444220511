import React from 'react'
import {TableCell, TableRow, Tooltip, withStyles} from '@material-ui/core';
import styles from '../styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';


function InsertHeaderEdit(props) {

  const {className, t} = props;

  return (
    <TableRow className={className}>
      <TableCell
        colSpan={2}
      >{t('productDescription')}</TableCell>
      <TableCell>{t('productAmount')}</TableCell>
      <Tooltip title={t('productPerUnit')}>
        <TableCell>{t('productWeight')}</TableCell>
      </Tooltip>
      <TableCell/>
    </TableRow>
  );
}


InsertHeaderEdit.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}
;

export default compose(withStyles(styles), withTranslation())(InsertHeaderEdit);
