import React, {Fragment, useContext} from 'react'
import ProblemNotificationDialogContext from '../../../context/Dialogs/problemNotificationDialogContext';
import {ProblemNotificationDialog} from '../../index';
import NewToUserDialogContext from '../../../context/Dialogs/newsToUserDialogContext';
import NewsToUserDialog from '../NewsToUserDialog';


function GlobalDialogContainer() {

  const {
    close: closeProblemNotification,
    displayMode: displayModeProblemNotification,
    dialogState: dialogStateProblemNotification
  } = useContext(ProblemNotificationDialogContext);
  const {
    close: closeNewsToUser,
    dialogState: dialogStateNewsToUser,
    markNewsAsRead
  } = useContext(NewToUserDialogContext);

  return (
    <Fragment>
      {dialogStateProblemNotification.open &&
      <ProblemNotificationDialog
        dialogState={dialogStateProblemNotification}
        displayMode={displayModeProblemNotification}
        handleClose={closeProblemNotification}
      />
      }
      {dialogStateNewsToUser.open &&
      <NewsToUserDialog
        dialogState={dialogStateNewsToUser}
        onClose={closeNewsToUser}
        onMarkNewsAsRead={markNewsAsRead}
      />
      }
    </Fragment>
  )
}


GlobalDialogContainer.propTypes = {};


export default (GlobalDialogContainer);
