import React, {Fragment} from 'react'
import {FormControl, IconButton, InputAdornment, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {AccessTime as TimeIcon, Today as DateIcon} from '@material-ui/icons'
import {InfoTooltip} from '../../../components';

function DateTimeRangeFilter(props) {

  const {
    classes,
    className,
    t,
    fromValue,
    toValue,
    setValue,
    fromDescriptionKey,
    toDescriptionKey,
    disabled,
    fromRequired,
    toRequired,
    fromError,
    toError,
    multiLine
  } = props;

  const rootClassName = classNames(classes.root, className);

  const handleFromChange = (value) => {
    if (value > toValue) {
      setValue(value, new Date(value.getFullYear(), value.getMonth(), value.getDate(), 19, 0, 0));
    } else {
      setValue(value, toValue);
    }
  }

  const handleToChange = (value) => {
    if (value < fromValue) {
      setValue(new Date(value.getFullYear(), value.getMonth(), value.getDate(), 6, 0, 0), value);
    } else {
      setValue(fromValue, value);
    }
  }

  return (
    <div className={rootClassName}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Fragment>
          <InfoTooltip descriptionKey={fromDescriptionKey}>
            <FormControl className={classes.formControlMedium}>
              <DatePicker
                cancelLabel={t('dialogCancel')}
                disabled={disabled}
                error={fromError}
                format="dd.MM.yyyy"
                id="tourDeliveryDateFrom"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton><DateIcon/> </IconButton>
                    </InputAdornment>
                  )
                }}
                label={t('dateFrom')}
                margin="normal"
                onChange={(event) => handleFromChange(event)}
                required={fromRequired}
                value={fromValue}
              />
            </FormControl>
          </InfoTooltip>
          <FormControl className={classes.formControlSmall}>
            <TimePicker
              ampm={false}
              cancelLabel={t('dialogCancel')}
              disabled={disabled}
              error={fromError}
              id="time-picker_from"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton><TimeIcon/> </IconButton>
                  </InputAdornment>
                )
              }}
              label={t('timeFrom')}
              margin="normal"
              onChange={(event) => setValue(new Date(event), toValue)}
              required={fromRequired}
              value={fromValue}
            />
          </FormControl>
          {multiLine && <br/>}
          <InfoTooltip descriptionKey={toDescriptionKey}>
            <FormControl className={classes.formControlMedium}>
              <DatePicker
                cancelLabel={t('dialogCancel')}
                disabled={disabled}
                error={toError}
                format="dd.MM.yyyy"
                id="tourDeliveryDateTo"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton><DateIcon/> </IconButton>
                    </InputAdornment>
                  )
                }}
                label={t('dateTo')}
                margin="normal"
                onChange={(event) => handleToChange(event)}
                required={toRequired}
                value={toValue}
              />
            </FormControl>
          </InfoTooltip>
          <FormControl className={classes.formControlSmall}>
            <TimePicker
              ampm={false}
              cancelLabel={t('dialogCancel')}
              disabled={disabled}
              error={toError}
              id="time-picker_to"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton><TimeIcon/> </IconButton>
                  </InputAdornment>
                )
              }}
              label={t('timeTo')}
              margin="normal"
              onChange={(event) => setValue(fromValue, new Date(event))}
              required={toRequired}
              value={toValue}
            />
          </FormControl>
        </Fragment>
      </MuiPickersUtilsProvider>
    </div>
  );
}

DateTimeRangeFilter.defaultProps = {
  fromDescriptionKey: '',
  toDescriptionKey: '',
  toError: false,
  toRequired: false,
  fromError: false,
  fromRequired: false,
  multiLine: false,
};

DateTimeRangeFilter.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  fromDescriptionKey: PropTypes.string,
  fromError: PropTypes.bool,
  fromRequired: PropTypes.bool,
  fromValue: PropTypes.instanceOf(Date).isRequired,
  multiLine: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  toDescriptionKey: PropTypes.string,
  toError: PropTypes.bool,
  toRequired: PropTypes.bool,
  toValue: PropTypes.instanceOf(Date).isRequired,
};

export default compose(withStyles(styles), withTranslation())(DateTimeRangeFilter);
