import React from 'react'
import {Typography, useMediaQuery, useTheme, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {ImageWithFallback} from '../../../index';

function CustomerInfo(props) {

  const {classes, className, stopAddress, stopCustomer, shipperName, logo} = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <div className={classes.container}>
        <div>
          {matches &&
          <div className={classes.section}>
            <Typography
              color={'primary'}
              variant={'h3'}
            >
              Versender
            </Typography>
            <Typography variant={'body1'}>{shipperName}</Typography>
          </div>
          }
          <div className={classes.section}>
            <Typography
              color={'primary'}
              variant={'h3'}
            >
              Lieferadresse
            </Typography>
            <Typography variant={'body1'}>{stopCustomer.firstName}&nbsp;{stopCustomer.lastName}</Typography>
            <Typography variant={'body1'}>{stopAddress.streetAndNumber}</Typography>
            <Typography variant={'body1'}>{stopAddress.zipCode}&nbsp;{stopAddress.city}</Typography>
          </div>
          {matches &&
          <div>
            <Typography
              color={'primary'}
              variant={'h3'}
            >
              Kundennummer
            </Typography>
            <Typography variant={'body1'}>{stopCustomer.externalCustomerId}</Typography>
          </div>
          }
        </div>
        <ImageWithFallback
          alt={'Versender Logo'}
          className={classes.logo}
          fallbackImageName={'GslLogo.png'}
          imageName={logo}
        />
      </div>
    </div>
  );
}

CustomerInfo.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  logo: PropTypes.string,
  shipperName: PropTypes.string.isRequired,
  stopAddress: PropTypes.object.isRequired,
  stopCustomer: PropTypes.object.isRequired
};


export default withStyles(styles)(CustomerInfo);
