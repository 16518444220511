import React, {useEffect, useState} from 'react'
import {Tooltip, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Battery20,
    Battery30,
    Battery50,
    Battery60,
    Battery80,
    Battery90,
    BatteryAlert,
    BatteryFull,
} from "@material-ui/icons";

const BatteryIconMapping = [
    { percentage: 0, icon: BatteryAlert },
    { percentage: 20, icon: Battery20 },
    { percentage: 30, icon: Battery30 },
    { percentage: 50, icon: Battery50 },
    { percentage: 60, icon: Battery60 },
    { percentage: 80, icon: Battery80 },
    { percentage: 90, icon: Battery90 },
    { percentage: 100, icon: BatteryFull }
];

const getClosestBatteryIcon = (batteryPercentage) => {
    const closest = BatteryIconMapping.reduce((prev, curr) => {
        return Math.abs(curr.percentage - batteryPercentage) < Math.abs(prev.percentage - batteryPercentage) ? curr : prev;
    });
    return closest.icon;
};
function BatteryStatus(props) {

    const {classes, className, t, sensorData} = props;
    const rootClassName = classNames(classes.root, className);
    const [batteryInformation, setBatteryInformation] = useState({
        batteryPercentage: null,
        batteryIcon: null
    });

    useEffect(() => {
        const getBatteryInformation = (data) => {
            const latestDataWithBatteryInformation = data && data.findLast(e => e.batteryPercentage !== null && e.batteryPercentage - new Date() <= 3);
            if (latestDataWithBatteryInformation === undefined) return;
            const batteryIcon = getClosestBatteryIcon(latestDataWithBatteryInformation.batteryPercentage);
            setBatteryInformation({
                batteryPercentage: latestDataWithBatteryInformation.batteryPercentage,
                batteryIcon: batteryIcon
            });
        }
        
        getBatteryInformation(sensorData);
    }, [sensorData]);

    return (
        <div className={rootClassName}>
            {batteryInformation.batteryPercentage !== null && (
                <Tooltip title={t('batteryStatus') + ': ' + batteryInformation.batteryPercentage + '%'}>
                    <div>
                        {React.createElement(batteryInformation.batteryIcon, {
                            className: classes.batteryStatus,
                        })}
                    </div>
                </Tooltip>
            )}
        </div>
    );
}

BatteryStatus.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    sensorData: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(BatteryStatus);