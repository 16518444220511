export default theme => ({
  root: {},
  noErrorClass: {
    color: 'inherit !important'
  },
  stepperLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  }
});
