import {displayModes} from 'services/enums/displayModes';
import Columns from './columns';

// CARE: ALWAYS INCREMENT THE VERSION IF THE SETTINGS FOR A COLUMN CHANGE!
// Config explanation
// optional: determines if the hidden status can be changed by the user
// hidden: determines if the field is shown or hidden
// conditional: indicates that the hidden status can be overriden using the conditions dictionary of the useTableConfig hook
const TemplateStopModeConfig = {
    [displayModes.admin]: {
        version: 1,
        columns: {
            internalCustomerId: {
                columnName: Columns.internalCustomerId.name,
                optional: false,
                hidden: false,
                conditional: false,
                order: 1,
            },
            streetAndNumber: {
                columnName: Columns.streetAndNumber.name,
                optional: false,
                hidden: false,
                conditional: false,
                order: 2
            },
            zipcode: {
                columnName: Columns.zipcode.name,
                optional: false,
                hidden: false,
                conditional: false,
                order: 3
            },
        }
    },
    [displayModes.carrier]: {
        version: 1,
        columns: {}
    },
    [displayModes.shipper]: {
        version: 1,
        columns: {}
    },
}

export default TemplateStopModeConfig;