import React, {Fragment, useState} from 'react'
import {Table, TableBody, TableCell, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PreviewStopTableRow from '../PreviewStopTableRow';
import ChangeAssignmentDialog from '../ChangeAssignmentDialog';
import DeleteStopDialog from '../DeleteStopDialog';


function PreviewStopTable(props) {

  const {classes, t, stops, className, dataCarrierForChangeAssignment, changeAssignment, deleteStop, microHubsData} = props;
  const rootClassName = classNames(className, classes.root);
  const [changeAssignmentDialogState, setChangeAssignmentDialogState] = useState({open: false, stop: null});
  const [deleteStopDialogState, setDeleteStopDialogState] = useState({open: false, stop: null});

  const changeAssignmentDialogOK = (stopId, carrier, microHub) => {
    setChangeAssignmentDialogState({...changeAssignmentDialogState, open: false, stop: null});
    changeAssignment(stopId, carrier, microHub);
  };

  const deleteStopDialogOk = (stopId, carrierName, microHubName) => {
    setDeleteStopDialogState({...deleteStopDialogState, open: false, stop: null});
    deleteStop(stopId, carrierName, microHubName);
  };

  return (
    <Fragment>
      <Table
        className={rootClassName}
        size="small"
      >
        <TableHead>
          <TableRow>
            <TableCell className={classes.alignRight}>{t('stopNumber')}</TableCell>
            <TableCell className={classes.alignRight}>{t('previewStopID')}</TableCell>
            <TableCell className={classes.alignRight}>{t('deliveryDate')}</TableCell>
            <TableCell className={classes.alignRight}>{t('shipper')}</TableCell>
            <TableCell className={classes.alignRight}>{t('lastName')}</TableCell>
            <TableCell className={classes.alignRight}>{t('boxAmount')}</TableCell>
            <TableCell className={classes.alignRight}>{t('weight')}</TableCell>
            <TableCell className={classes.alignRight}>{t('volume')}</TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody>
          {stops.map((stop, index) => {
            return (
              <PreviewStopTableRow
                changeStopAssignment={changeAssignment ? (stop) => setChangeAssignmentDialogState({
                  ...changeAssignmentDialogState,
                  open: true,
                  stop: stop
                }) : null}
                className={classes.previewStopTableRow}
                deleteStop={deleteStop ? (stop) => setDeleteStopDialogState({...deleteStopDialogState, open: true, stop: stop}) : null}
                key={index}
                stop={stop}
              />
            );
          })}
        </TableBody>
      </Table>
      <ChangeAssignmentDialog
        carriersWithHubs={dataCarrierForChangeAssignment}
        dialogState={changeAssignmentDialogState}
        handleCancel={() => setChangeAssignmentDialogState({...changeAssignmentDialogState, open: false, stop: null})}
        handleClose={changeAssignmentDialogOK}
        microHubsData={microHubsData}
      />
      <DeleteStopDialog
        dialogState={deleteStopDialogState}
        handleCancel={() => setDeleteStopDialogState({...deleteStopDialogState, open: false, stop: null})}
        handleClose={deleteStopDialogOk}
      />
    </Fragment>
  );
}

PreviewStopTable.propTypes = {
  changeAssignment: PropTypes.func,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  dataCarrierForChangeAssignment: PropTypes.object,
  deleteStop: PropTypes.func,
  microHubsData: PropTypes.array,
  stops: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(PreviewStopTable);
