import React, {Fragment} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core';
import styles from './styles';
import {StopMap, TourMap,} from '../../../../../components';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';


function StopAndTourMap(props) {

  const {classes, className, stops, tours, dataMicroHubs, displayTours} = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <Fragment>

      {displayTours
        ?
        <TourMap
          className={rootClassName}
          displayMicroHubs
          displayNotPlanedStops={false}
          displayStopStatus={false}
          microHubs={dataMicroHubs}
          noControls
          notPlanedStops={[]}
          setSelectedStop={() => {
          }}
          toursWithStops={tours}
        />
        :
        <StopMap
          className={rootClassName}
          microHubs={dataMicroHubs}
          noControls
          stops={stops}
        />
      }
    </Fragment>
  );
}

StopAndTourMap.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  dataMicroHubs: PropTypes.array.isRequired,
  displayTours: PropTypes.bool.isRequired,
  stops: PropTypes.array.isRequired,
  tours: PropTypes.array.isRequired,
};

export default compose(withStyles(styles), withTranslation())(StopAndTourMap);
